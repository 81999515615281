<template>
  <div class="tree-box">
    <!-- 一级目录 -->
    <div class="first-ul" v-for="(item ) in arr" :key="item.sectionId">
      <div class="li-div li-div-hover" >
        <div class="fist-name flex" @click="onDetails(item)">
          <span  :class="{'nameHover' : (video == 1) && (sectionId == item.sectionId)}">
            {{item.sectionName}}
				  	<img v-if="((video == 1) && (sectionId == item.sectionId))" style="margin-left:3px;width:11px;" src="@/assets/image/selected/playimg_icon.gif" />
          </span>
          <van-icon v-show="item.childList" @click="onShowTree(item)" :name="item.show ? 'arrow' : 'arrow-down'" size="14" color="#999" />
        </div>
        <Option @setTry="onDetails" :courseId="courseId" :isOrder="isOrder" v-show="item.fileId" :item="item" :isFree="isFree" :video="video" :isHave="isHave" :sectionId="sectionId" />
      </div>
      <!-- 二级目录 -->
      <div class="child-li" v-show="item.isMenuOpen">
        <div class="second-ul" v-for="(item) in item.childList" :key="item.sectionId">
          <div class="li-div" >
            <div class="fist-name flex" @click="onDetails(item)">
              <span :class="{'nameHover' : (video == 1) && (sectionId == item.sectionId)}">
                {{item.sectionName}}
				  	    <img v-if="((video == 1) && (sectionId == item.sectionId))" style="margin-left:3px;width:11px;" src="@/assets/image/selected/playimg_icon.gif" />
              </span>
              <van-icon v-show="item.childList" @click="onShowTree(item)" :name="item.show ? 'arrow' : 'arrow-down'" size="14" color="#999" />
            </div>
            <Option @setTry="onDetails" :courseId="courseId" :isOrder="isOrder" v-show="item.fileId" :item="item" :isFree="isFree" :video="video" :isHave="isHave" :sectionId="sectionId" />
          </div>
          <!-- 三级目录 -->
          <div class="child-li" v-show="item.isMenuOpen">
            <div class="three-ul" v-for="(item ) in item.childList" :key="item.sectionId">
              <div class="li-div" @click="onDetails(item)">
                <div class="fist-name flex" >
                  <span  :class="{'nameHover' : (video == 1) && (sectionId == item.sectionId)}">
                    {{item.sectionName}}
				  	        <img v-if="((video == 1) && (sectionId == item.sectionId))" style="margin-left:3px;width:11px;" src="@/assets/image/selected/playimg_icon.gif" />
                  </span>
                  <van-icon v-show="item.childList" @click="onShowTree(item)" :name="item.show ? 'arrow' : 'arrow-down'" size="14" color="#999" />
                </div>
                <Option @setTry="onDetails" :courseId="courseId" :isOrder="isOrder" v-show="item.fileId" :item="item" :isFree="isFree" :video="video" :isHave="isHave" :sectionId="sectionId" />
              </div>
              <!-- 四级目录 -->
              <div class="child-li" v-show="item.isMenuOpen">
                <div class="three-ul" v-for="(item) in item.childList" :key="item.sectionId">
                  <div class="li-div" @click="onDetails(item)">
                    <div class="fist-name flex" >
                      <span  :class="{'nameHover' : (video == 1) && (sectionId == item.sectionId)}">
                        {{item.sectionName}}
				  	            <img v-if="((video == 1) && (sectionId == item.sectionId))" style="margin-left:3px;width:11px;" src="@/assets/image/selected/playimg_icon.gif" />
                        </span>
                      <van-icon v-show="item.childList" @click="onShowTree(item)" :name="item.show ? 'arrow' : 'arrow-down'" size="14" color="#999" />
                    </div>
                    <Option @setTry="onDetails" :courseId="courseId" :isOrder="isOrder" v-show="item.fileId" :item="item" :isFree="isFree" :video="video" :isHave="isHave" :sectionId="sectionId" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Option from './option.vue'
export default {
  // 可用组件的哈希表
  components: {Option},
  // 接收传值
  props: {
    zindex: {
			type: Number,
			default: 0
		},
		arr: {
			type: Array,
			default: ()=>[]
		},
		// 正在播放视频id
		sectionId: {
			type: Number,
			default: null
    },
    // 课程章节id
		courseId: {
			type: null,
			default: null
		},
    // 课程是否拥有
		isHave: {
			type: Number,
			default: null
    },
    // 课程是否免费
		isFree: {
			type: Number,
			default: null
    },
    // 当前是课程详情还是播放 1 播放
		video: {
			type: Number,
			default: null
    },
    // 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
  },
  // 数据对象
  data () {
    return {
      sectionIdArry: []
    }
  },
  // 事件处理器
  methods: {
    // 点击播放组件
		onDetails (e) {
      if(!e.fileId) return
			let json = JSON.stringify(e)
			json = JSON.parse(json)
			this.$emit('nodeClick', json)
		},
    // 展开收起
		onShowTree(e){
      // let closeSectionIdArry = []
      this.$set(e,'isMenuOpen',!e.isMenuOpen)

      let i = this.sectionIdArry.indexOf(e.sectionId)
      if(e.isMenuOpen && i < 0){
        this.sectionIdArry.push(e.sectionId)
      } else if(!e.isMenuOpen && (i != -1)) {
        this.sectionIdArry.splice(i, 1)
      }
      

      // 记录已关闭的目录
      // this.arr.forEach(one => {
      //   if(one.show){
      //     closeSectionIdArry.push(one.sectionId)
      //   }
      //   if(one.childList){
      //     one.childList.forEach(two => {
      //       if(two.show){
      //         closeSectionIdArry.push(two.sectionId)
      //       }
      //       if(two.childList){
      //         two.childList.forEach(three => {
      //           if(three.show){
      //             closeSectionIdArry.push(three.sectionId)
      //           }
      //           if(three.childList){
      //             three.childList.forEach(four => {
      //               if(four.show){
      //                 closeSectionIdArry.push(four.sectionId)
      //               }
      //             });
      //           }
      //         });
      //       }
      //     });
      //   }
      // })

      // // 记录已关闭的目录
      // function fors(arr){
      //   if (arr.length !== 0) {
      //     arr.forEach((item) => {
      //       if(item.show){
      //         closeSectionIdArry.push(item.sectionId)
      //       }
      //       if (item.childList) {
      //         item.childList = fors(item.childList);
      //       }
      //     });
      //   }
      //   return arr;
      // }
      // fors(this.arr)

      // this.$store.commit('updatecloseSectionIdArry', closeSectionIdArry);
    },
    // 打开当前播放的所有父级章节
    changeOpenSelection(){
      let parentList = []  // 存放所有父级关系集合
      let _that = this
      parentList = _that.getParentAreas(_that.sectionId, _that.flatTreeAndSetLevel(_that.arr))
      parentList.forEach(element => {
        if(element.parentId && (_that.sectionIdArry.indexOf(element.parentId) < 0)){
          _that.sectionIdArry.push(element.parentId)
        }
      });

      function fors(arr){
        if (arr.length !== 0) {
          arr.forEach((item) => {
            if(_that.sectionIdArry.indexOf(item.sectionId) != -1){
              item.isMenuOpen = true
            }
            if (item.childList) {
              item.childList = fors(item.childList);
            }
          });
        }
        return arr;
      }

      this.arr = fors(this.arr);
    },

    // 1. 先将目录树形结构扁平化，处理为同级
    flatTreeAndSetLevel(tree, level = 1) {
      const list = []
      tree.forEach(item => {
        const o = JSON.parse(JSON.stringify(item))
        if(o.childList) delete o.childList
        o.level = level
        list.push(o)
        if(item.childList && item.childList.length) {
          list.push(...this.flatTreeAndSetLevel(item.childList, level + 1))
        }
      })
      return list
    },
    // 2. 找出当前播放章节id的所有父级关系
    getParentAreas(sectionId, list) {
      const target = []
      const o = list.find(item => item.sectionId == sectionId) || {}
      if(JSON.stringify(o) != '{}') target.push(o)
      if(o.parentId) target.push(...this.getParentAreas(o.parentId, list))
      return target
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
   },
  // 生命周期-实例挂载后调用
  mounted () { 
    setTimeout(()=>{
      // 找出当前播放的章节id所有父级关系
      this.changeOpenSelection()
    },700)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    sectionId(){
      this.changeOpenSelection()
    },

    arr(){
      this.changeOpenSelection()
    }
  }
}
</script>

<style lang="less" scoped>
.first-ul {
  border-radius: 10px;
  background-color: #fff;
  .li-div{
    padding: 15px 18px;
    border-bottom: 1px solid #F5F5F5;
    .fist-name{
      >span{
        display: inline-block;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 500 ;
        color: #333333;
        max-width: 95%;
        img{
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  .li-div-hover{
    background: #F7F7F7;
  }
}
.second-ul{
  .fist-name{
    >span{
      // font-weight: bold;
      font-size: 14px !important;;
    }
  }
}
.three-ul{
  .fist-name{
    >span{
      font-weight: 400;
      font-size: 14px !important;
    }
  }
}
.nameHover{
  color: #15B7DD !important;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
