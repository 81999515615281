<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="图书教具"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="content-box">
      <van-tabs v-model="active" class="van-tabs-default">
        <van-tab v-for="(item, index) in list" :key="index" :title="item.title"></van-tab>
      </van-tabs>

      <div class="condition" v-show="active == 1">
        <span class="item" v-for="i in 3" :key="i" @click="classIndex = i" :class="{'itemActive':i == classIndex}">分类一</span>
      </div>
      <div class="list-box">
        <div class="item-box" v-for="i in 10" :key="i">
          <img class="cover-img" src="" />
          <div class="bottom-box">
            <div class="title all-font-ellipsis">卡罗来纳特殊教育图卡罗来纳特殊教育图</div>
            <div class="source">让你更懂特殊儿童的教育</div>
            <div class="price">￥<span>1500.00</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      classIndex: 0,
      active: 0,
      list: [
        {
          title: '图书教材'
        }, {
          title: '教学用具'
        }
      ],
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  /deep/.van-tab {
    flex: none;
    margin-right: 24px;
  }

  .content-box{
    padding: 0 18px 18px 18px;
    /deep/.tabBlock{
      padding-left: 0;
      .tab{
        font-size: 14px;
      }
      .tab__item--active{
        font-size: 15px;
      }
      .tab__item{
        margin-right: 12px;
        padding: 0;
      }
    }
    .condition{
      display: flex;
      margin-bottom: 18px;
      .item{
        padding: 0 12px;
        font-size: 12px;
        margin-right: 18px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        border-radius: 5px;
        border: 1px solid #999999;
      }
      .itemActive{
        border: 1px solid #15B7DD;
        color: #15B7DD;
      }
    }
    .list-box{
      display: flex;
      flex-wrap: wrap;
      .item-box{
        width: calc((100% - 14px) / 2);
        border-radius: 10px;
        background: #FFFFFF;
        margin-right: 14px;
        margin-bottom: 14px;
        overflow: hidden;
        .cover-img{
          width: 100%;
          height: 162.5px;
        }
        .bottom-box{
          padding: 0 10px 10px;
          .title{
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 21px;
            -webkit-line-clamp: 1;
          }
          .source{
            margin-top: 5px;
            font-size: 10px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 15px;
          }
          .price{
            margin-top: 5px;
            font-size: 11px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #EC6C01;
            line-height: 13px;
            span{
              font-size: 14px;
              font-family: PingFangMedium;
            }
          }
        }
      }
      .item-box:nth-child(2n){
        margin-right:0;
      }
    }
  }
</style>
