<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="确认订单"
      left-text=""
      right-text=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-wrap">
      <!-- 订单详情 -->
      <div class="bg detail-box">
        <img class="cover-img" src="" />
        <div class="side-right">
          <span class="title all-font-ellipsis">电子照片费用</span>
          <span class="type">一次性费用</span>
          <span class="money">￥<span>20.00</span></span>
        </div>
      </div>
      <!-- 是否开具发票 -->
      <div class="bg line-box">
        <span class="line-name">开具发票</span>
        <div class="line-select">
          <span>不开发票</span>
          <van-icon name="arrow" size="15" color="#999999"/>
          <!-- <uni-icons type="arrowright" size="18" color="#999999"></uni-icons> -->
        </div>
      </div>
      <!-- 优惠券 -->
      <div class="bg line-box">
        <span class="line-name">优惠券</span>
        <div class="line-select">
          <span>暂时无可使用优惠券</span>
        </div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit-box">
      <div class="inner">
        <div class="price">共1件，总价<span>20.00</span>元</div>
        <span class="all-btn-middle btn">支付</span>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {}
		},
		// 事件处理器
		methods: {}
	}
</script>

<style>
  page{
    background: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  .content-wrap{
    padding: 9px 18px;
    .bg{
      border-radius: 4.5px 4.5px 4.5px 4.5px;
      padding: 12.5px;
      background: #FFFFFF;
    }
    .detail-box{
      display: flex;
      .cover-img{
        width: 79.5px;
        height: 79.5px;
      }
      .side-right{
        flex: 1;
        margin-left: 12.5px;
        .title{
          min-height: 29px;
          font-size: 13.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 20.5px;
        }
        .type{
          font-size: 9px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #999999;
          line-height: 13.5px;
        }
        .money{
          display: block;
          margin-top: 10px;
          text-align: right;
          font-size: 11px;
          font-family: PingFangMedium;
          color: #EC6C01;
          span{
            line-height: 12.5px;
            font-weight: bold;
            font-size: 16.5px;
            font-family: PingFangMedium;
          }
        }
      }
    }
    .line-box{
      margin-top: 12.5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line-name{
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 15px;
      }
      .line-select{
        font-size: 13px;
        color: #999999;
        display: flex;
        align-items: center;
      }
    }
  }
  
  .submit-box{
    position: fixed;
    padding: 11px 18px 0;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    height: 85.5px;
    background: #FFFFFF;
    box-shadow: 0px -2px 3.5px .5px rgba(0,0,0,0.05);
    .inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price{
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        span{
          font-family: PingFangMedium;
        }
      }
      .btn {
        width: 132px;
        height: 36px;
        line-height: 36px;
        font-size: 13px;
      }
    }
  }
</style>
