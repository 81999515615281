<template>
  <!-- 图书教具 -->
  <div class="container-box">
    <template v-if="listType == '1'">
      <van-swipe-cell
        :disabled="listType == '2'"
        v-for="(i, index) in listData"
        :key="index"
      >
        <div class="bookItem" @click="goDetail(i.productId)">
          <div class="bookborder" :class="{ bookfirst: index == 0 }">
            <img :src="i.listPhoto" />
            <div class="itemMessage">
              <span v-if="listType == '1'" class="title all-font-ellipsis">{{
                i.name
              }}</span>
              <span
                v-else
                v-html="i.bookName"
                class="title all-font-ellipsis"
              ></span>
              <!-- <span class="describe">{{i.abstracts}}</span> -->
              <div class="price_box">
                ￥<span class="price">{{
                  i.couponPrice ? i.couponPrice.toFixed(2) : ""
                }}</span>
                <span class="original" v-if="i.original"
                  >￥{{ i.originalPrice.toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <template #right>
          <div class="all_cancel" @click="cancelOk(i.productId, index)">
            取消收藏
          </div>
        </template>
      </van-swipe-cell>
    </template>

    <template v-if="listType == '2'">
      <div
        v-for="(i, index) in listData"
        :key="i.productId"
        class="bookItem"
        @click="goDetail(i.productId)"
      >
        <div class="bookborder" :class="{ bookfirst: index == 0 }">
          <img :src="i.picture ? i.picture : i.photo" />
          <div class="itemMessage">
            <span v-html="i.productName" class="title all-font-ellipsis"></span>
            <!-- <span class="price">￥{{i.couponPrice}}</span> -->
            <div class="price_box">
              <span class="price">
                <span v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >￥</span
                >
                <span
                  class="present"
                  v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >{{ i.couponPrice }}</span
                >
                <span class="free" v-else>免费</span>
                <span class="original" v-if="i.original"
                  >￥{{ i.originalPrice.toFixed(2) }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {},
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 类型 1收藏 2搜索
    listType: {
      type: String,
      default: "1",
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    goDetail(id) {
      jumpToShopDetail(2,id)
      // this.$router.push({
      //   path: "/curriculum/BookDdetails",
      //   query: { id: id },
      // });
    },
    // 取消收藏
    cancelOk(id, index) {
      this.$emit("cancelOk", id, index);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";
</style>
