<template>
  <div class="shopList">
    <div
      v-for="(item, index) in shopList"
      :key="index"
      :class="
        item.type == 1 || item.type == 2 || item.type == 3 || item.type == 4
          ? 'layout-one'
          : 'layout-two'
      "
    >
      <!-- 样式一 -->
      <div
        v-if="
          item.type == 1 || item.type == 2 || item.type == 3 || item.type == 4
        "
        class="layout-item"
        @click="goDetail(item)"
      >
        <div class="side-left">
          <van-image class="cover-img" fit="cover" :src="item.listPhoto" />
        </div>
        <div class="side-right">
          <div class="h4 all-font-ellipsis">
            {{ item.name }}
          </div>
          <div class="tag" v-if="item.type == 1 && item.courseCategory == '线下培训' && setTime(item)">
            {{setTime(item)}}
          </div>
          <div class="tag" v-else>
            {{
              item.type == 1
                ? item.courseCategory
                : item.type == 4
                ? "模拟考试"
                : ""
            }}
            <span v-if="item.type == 1">丨{{ item.courseHour }}</span>
            <span v-if="item.type == 4">丨{{ item.answerNumber }}</span>
          </div>
          <div class="price">
            <span v-if="item.couponPrice != 0">￥</span>
            <span class="present" v-if="item.couponPrice">{{
              item.couponPrice.toFixed(2)
            }}</span>
            <span class="free" v-else>免费</span>
            <span class="original" v-if="!!item.original && item.couponPrice"
              >￥{{ item.originalPrice.toFixed(2) }}</span
            >
          </div>
        </div>
        <div
          class="add-btn"
          v-if="item.couponPrice"
          @click.stop="joinShopCar(item,$event)"
        >
          <!-- <img class="icon" :src="item.isOpenCar?carHoverImg:carImg" /> -->
          <img class="icon" :src="carImg" />
          <!-- <i class='num' v-show="item.isOpenCar">{{item.num}}</i> -->
        </div>
      </div>
      <!-- 样式二 -->
      <div v-else class="layout-item" @click="goDetail(item)">
        <van-image class="cover-img" fit="cover" :src="item.listPhoto" />
        <div class="side-right">
          <div class="h4 all-font-ellipsis">
            {{ item.name }}
          </div>
          <div class="tag">图书教具</div>
          <!-- <div class="tag">作者：我是个作者</div> -->
          <!-- <div class="tag">译者：我是个作者</div> -->
          <div class="price">
            ￥<span class="present">{{
              item.couponPrice || item.couponPrice === 0
                ? item.couponPrice.toFixed(2)
                : "免费"
            }}</span>
            <span class="original" v-if="!!item.original && item.couponPrice"
              >￥{{ item.originalPrice.toFixed(2) }}</span
            >
          </div>
        </div>
        <div class="add-btn" @click.stop="joinShopCar(item,$event)">
          <img class="icon" :src="carImg" />
        </div>
      </div>
    </div>
    <!-- 请选择培训场次 -->
    <n-transition
      ref="trainPop"
      speed="ease-in-out"
      :height="612"
      :maskVal="0.35"
    >
      <div class="popContentBox">
        <div class="popBox">
          <div class="titleBox">
            <span class="title">请选择培训场次</span>
            <van-icon name="cross" size="20" color="#999" @click="close" />
          </div>
          <div class="popContent">
            <div
              class="popItem"
              v-for="(item, index) in classList"
              @click="selected(item)"
              :key="index"
              :class="{
                pick: openTimeId == item.openTimeId,
                disabled: !item.currentNumber,
              }"
            >
              <div class="up">
                <div class="left">
                  <span>{{ item.className }}</span>
                </div>
                <div class="right">
                  <span
                    >剩余：<span class="num">{{
                      item.currentNumber ? item.currentNumber : 0
                    }}</span></span
                  >
                </div>
              </div>
              <div class="center">
                <span>{{
                  selectTime(item.startTime) + " - " + selectTime(item.endTime)
                }}</span>
              </div>
              <div class="down" v-if="item.address">
                <span
                  >{{ item.provinceTxt }}{{ item.cityTxt
                  }}{{ item.districtTxt ? item.districtTxt : ""
                  }}{{ item.address }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="popbutton">
          <span class="btn all-btn-bigCircle" @click="joinShopCar(handleItem)"
            >确 定</span
          >
        </div>
      </div>
    </n-transition>
    <!-- 提示信息 -->
    <van-popup v-model="showPopup">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">购买本课程需要完成身份认证</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showPopup = false"
            >取消</span
          >
          <span class="all-btn-middle btn2" @click="goIdent()">去认证</span>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="onPopup">
      <div class="box_popup" style="z-index: 9002">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onPopup = false"
            >下次再报</span
          >
          <span
            class="all-btn-middle btn2"
            @click="
              onPopup = false,
              $router.push(
                '/curriculum/seniority?courseId=' + handleItem.courseId
              )
            "
            >提交资质</span
          >
        </div>
      </div>
    </van-popup>
    <van-popup v-model="onPopupOk">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="onPopupOk = false"
            >我知道了</span
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
import { goIdentityAuthentication, jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
import { FunJudgDevice } from "@/unit/fun.js";
const DeviceType = FunJudgDevice();
export default {
  components: { nTransition },
  props: ["shopList"],
  data() {
    return {
      message: "",
      onPopup: false,
      onPopupOk: false,
      showPopup: false, // 认证弹框状态
      handleItem: null,
      openTimeId: null,
      classList: [], // 班期列表
      carImg: require("@/assets/image/icon/car.png"),
      carHoverImg: require("@/assets/image/icon/car-hover.png"),
    };
  },
  methods: {    
    // 返回时间
    setTime(e) {
      if(!e.openStartTime){
        return false;
      }
      let start = e.openStartTime.substring(0,10).split('-').join('/');
      let end = e.openEndTime.substring(0,10).split('-').join('/');
      return start + ' - ' + end
    },
    // 去认证
    goIdent() {
      this.showPopup = false;
      this.$router.push({
        path: "/mine/identityAuthentication",
        query: { url: "/examination/examSignList" },
      });
    },
    selected(e) {
      if (e.currentNumber && e.status == 1) {
        this.openTimeId = e.openTimeId;
      } else {
				this.$Toast({message:'该班级已报满，请选择其他班级',className:'toastClass'})
      }
    },
    // 跳转详情页面
    goDetail(e) {
      // console.log(e,'eeeeeeeeee')
      // 教育课程
      if (e.type == 1) {
        jumpToShopDetail(e.type,e.courseId);
        // this.$router.push("/curriculum/course?id=" + e.courseId);
      }
      // // 图书教具
      else if (e.type == 2 || e.type == 3) {
        jumpToShopDetail(e.type,e.productId);
        // this.$router.push("/curriculum/BookDdetails?id=" + e.productId);
      }
      // // 模拟考试
      else if (e.type == 4) {
        jumpToShopDetail(e.type,e.configId);
        // this.$router.push("/examination/examDetail?id=" + e.configId);
      }
    },

    // 班期弹框显隐
    popUp() {
      this.$refs["trainPop"].show();
    },
    close() {
      this.$refs["trainPop"].hide();
      this.openTimeId = null;
      this.handleItem = null;
    },
    selectTime(e) {
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date(e).getTime();
      var date = new Date(time + 8 * 3600 * 1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace("T", " ");
    },
    // 商品加入购物车
    async joinShopCar(e,el) {
      if (!this.$store.state.userInfo.userId) {
        return this.$router.push('/login/login');
      }
      if (
        e.type == 1 &&
        !!e.userAuthentication &&
        !this.$store.state.userInfo.certified
      ) {
        return this.showPopup = true;
      }
      // console.log(e);
      if(e.type == 1 || e.type == 4){
        let shopUsestatus = false;
        await this.$ajax({
          url:'/hxclass-mobile/app-mall/app/shop/use',
          method:"get",
          params:{
            openTimeId:'',
            productId:e.productId,
            type:e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
          }
        }).then(res=>{
          if(res.code == 200){
            shopUsestatus = true;
            return this.$Toast('该商品已加购，不可重复购买');
          }else{}
        })
        if(shopUsestatus){
          return ;
        }
      }
      

      // if(e.type == 1 || e.type == 4){
      //   if(!!e.noSHop){
      //     return this.$Toast('商品已加购，不可重复购买')
      //   }
      //   if(!!e.noPay){
      //     return this.$Toast('商品已购买，不可重复购买')
      //   }
      // }
      if (e.type == 4) {
        this.handleItem = e;
        let status = await this.$ajax({
          url: "/hxclass-mobile/exam/mock/config/mall-purchase-verifi",
          method: "get",
          params: {
            configId: e.configId,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            return true;
          } else {
            this.$Toast(res.message);
            return false;
          }
        });
        if (!status) {
          return false;
        }
      }
      if (e.type == 1) {
        this.handleItem = e;
        let status = await this.$ajax({
          url: "/hxclass-mobile/course/check-can-buy",
          method: "get",
          params: {
            courseId: e.courseId,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            return true;
          } else if (res.code == 1000) {
            // 需购买课程
            this.onPopupOk = true;
            this.message =
              '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">' +
              res.data.courseNameList.join("、") +
              "</span>。";
            return false;
          } else if (res.code == 1001) {
            // 需提交资质
            this.onPopup = true;
            this.message = "购买该课程需要验证资质，是否提交？";
            return false;
          } else if (res.code == 1002) {
            // 已提交资质待审核
            this.onPopupOk = true;
            this.message =
              "您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。";
            return false;
          } else if (res.code == 1003) {
            // 审核被驳回
            this.onPopup = true;
					  this.message = '购买该课程需要验证资质，是否提交？';
            return false;
          } else if (res.code == 1004) {
            // 需购买课程且提交资质
            this.onPopup = true;
            this.message =
              '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">' +
              res.data.courseNameList.join("、") +
              "</span>，如您已获得上述资质，请提交您的资质信息，后台审核通过后方可报名。";
            return false;
          } else if (res.code == 1005) {
            // 課程所有班期都以過有效期
            this.$Toast(res.message);
            return false;
          } else if (res.code == 1006) {
            // 課程所有班期都以過有效期
            this.$Toast(res.message);
            return false;
          }
        });
        if (!status) {
          return false;
        }
        if (!!e.noClass && !this.openTimeId) {
          await this.$ajax({
            url: "/hxclass-mobile/course/course-open-time/by-code",
            method: "get",
            params: {
              productCode: e.code,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              if (res.data && res.data.length) {
                this.classList = res.data;
                this.popUp();
              } else {
                return this.$alert("暂无可选择班级期数无法进行购买");
              }
            } else {
              this.$Toast(res.message);
            }
          });
          return false;
        }
      }

      await this.$ajax({
        url: "/hxclass-mobile/app-mall/shop/save",
        method: "post",
        params: {
          count: 1, // 数量
          couponCode: "", // 优惠劵编码
          openTimeId: this.openTimeId ? this.openTimeId : "", // 开班设置id
          price: e.couponPrice ? e.couponPrice : e.originalPrice, // 价格
          productId: e.productId, // 商品id
          type: e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
          userNo: this.$store.state.userInfo.userId, // 用户
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 执行贝塞尔曲线函数
          this.$emit('addCart',el,e)
          if (this.handleItem) {
            // this.$set(this.handleItem,'noSHop',1)
          }
          this.close();
          this.openTimeId = null;
          this.handleItem = null;
          this.$Toast('添加成功');
          this.$emit("getShopCarList");
        } else {
          this.$Toast(res.message);
        }
      });
    },
  },
};
</script>

<style>
	.toastClass{
		z-index: 100000 !important;
	}
</style>

<style scoped lang="less">
.shopList{
  padding-top: 2px;
}
.layout {
  margin-top: 15px;
  padding-bottom: 25px;
  overflow-y: scroll;
  max-height: 80vh;
}
.layout-item {
  position: relative;
  display: flex;
  margin-bottom: 14px;
  padding-right: 4px;
  margin-right: 2px;
  box-shadow: 0px 2px 5px 1px rgba(93, 131, 167, 0.1);
  border-radius: 5px;
  font-family: PingFangMedium;
  .side-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
  }
  .h4 {
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
    margin-top: -1px;
  }
  .tag {
    margin-top: 4px;
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    line-height: 15px;
    height: 15px;
  }
  .price {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    color: #EC6C01;
    line-height: 14px;
    font-family: PingFangMedium;
    .present {
      font-size: 14px;
      font-weight: bold;
    }
    .free {
      font-weight: 400;
      font-size: 12px;
    }
    .original {
      margin-left: 6px;
      font-size: 10px;
      color: #999999;
      font-weight: 400;
      text-decoration: line-through;
      font-family: PingFangMedium;
    }
  }
  .add-btn {
    position: absolute;
    right: 10px;
    bottom: 6px;
    .icon {
      width: 20px;
    }
    .num {
      position: absolute;
      font-style: normal;
      text-align: center;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: -6px;
      right: -6px;
      background: #EC6C01;
      font-size: 10px;
      font-weight: bold;
      color: #ffffff;
      line-height: 14px;
    }
    // text{
    //   display: inline-block;
    //   color: #FFFFFF;
    //   font-weight: bold;
    //   border-radius: 50%;
    //   background: #15B7DD;
    //   box-shadow: 0px 1.5px 1.5px 1px rgba(93,131,167,0.2);
    //   width: 20px;
    //   height: 20px;
    //   text-align: center;
    //   line-height: 20px;
    // }
  }
}
.layout-one {
  .layout-item {
    margin-left: 25px;
    padding: 14px 14px 12px 0;
    background-color: #fff;
    .side-left {
      width: 45px;
      height: 68.5px;
      .cover-img {
        position: relative;
        right: 25px;
        width: 70px;
        height: 70px;
        /deep/.van-image__img,
        .van-image__error,
        .van-image__loading {
          border-radius: 5px;
        }
      }
    }
  }
}
.layout-two {
  .layout-item {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .cover-img {
      width: 70px;
      height: 85px;
      border-radius: 4px;
    }
    .side-right {
      flex: 1;
      padding: 7px 0;
    }
  }
}
// 培训地点选择
.popContentBox {
  .popBox {
    .titleBox {
      padding: 28px 34px 0 34px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 25px;
      display: flex;
      span {
        display: inline-block;
      }
      .title {
        flex: 1;
      }
    }
    .popContent {
      height: 503px;
      overflow-y: scroll;
      padding: 0 18px;
      .popItem {
        padding: 16px 12px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-bottom: 8px;
        .up {
          display: flex;
          .left {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 20px;
            flex: 1;
          }
          .right {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            .num {
              color: #15b7dd;
            }
          }
        }
        .center {
          padding: 8px 0;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
        .down {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
        &.pick {
          background: #15b7dd;
          // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
          .up {
            .left,
            .right {
              color: #ffffff;
            }
          }
          .center,
          .down,
          .num {
            color: #ffffff !important;
          }
        }
      }
      .popItem:first-child {
        margin-top: 16px;
      }
      .disabled {
        .up {
          .left {
            span {
              color: #666666;
            }
          }
          .right {
            color: #666666;
            .num {
              color: #666666;
            }
          }
        }
        .center,
        .down {
          span {
            color: #666666;
          }
        }
      }
    }
  }
  .popbutton {
    box-shadow: 0px -1px 1px 0px rgba(153, 153, 153, 0.3);
    padding: 5px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    .btn {
      width: 295px;
      height: 40px;
      display: block;
      text-align: center;
      margin: 0 auto;
      line-height: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 22.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
/deep/.van-overlay {
  z-index: 9001 !important;
}
/deep/.van-popup--center {
  z-index: 9001 !important;
}
</style>