<template>
  <!-- 更换开课时间 -->
  <div class="changeClassTime">
    <!-- 导航栏 -->
    <van-nav-bar
      title="更换开课时间"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 开课时间 -->
    <div class="topinfo">
      <span class="span1">当前班级期数</span>
      <div class="title">
        <span class="span2">{{ formData.className }}</span>
        <span class="span3">
          {{ formData.startTime + "-" + formData.endTime }}</span
        >
      </div>
    </div>
    <div class="box1">
      <span v-if="!classList.length" class="span1">没有可更换的班级期数</span>
      <span v-else class="span1">以下是可更换的班级期数</span>
      <div class="box3">
        <div
          class="box_item"
          :class="{ pick: openTimeId == item.openTimeId }"
          v-for="item in classList"
          @click="onSelect(item)"
          :key="item.openTimeId"
        >
          <div class="item_title">{{ item.className }}</div>
          <div class="item_date">
            {{ item.startTime + " - " + item.endTime }}
          </div>
          <div class="item_num">
            剩余：<a>{{ item.currentNumber ? item.currentNumber : 0 }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 温馨提示 -->
    <div class="box2">
      <div class="box_title">温馨提示：</div>
      <div class="box_con">开班前可更换班级，开课后无法更换。</div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit-box" v-if="classList.length">
      <span class="all-btn-bigCircle" @click="onDelayPopupShow = true"
        >确定更换</span
      >
    </div>
    <van-popup v-model="onDelayPopupShow" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">确认要更换班级吗？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onDelayPopupShow = false"
            >取消</span
          >
          <span class="all-btn-middle btn2" @click="onReplace()">确认</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { refreshChangeClass } from '@/assets/js/NativeWebKit.js'

export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      onDelayPopupShow: false,
      selectIndex: 0,
      classList: [],
      formData: {},
      openTimeId: this.$route.query.openTimeId,
    };
  },
  // 事件处理器
  methods: {
    onSelect(e) {
      if (e.currentNumber) {
        this.openTimeId = e.openTimeId;
      } else {
        this.$Toast("名额剩余为 0，无法选择该班级");
      }
    },
    // 获取班期列表
    getClassList() {
      this.$ajax({
        // url: "/hxclass-mobile/course/valid-class/by-id", // 有可选数量的班期
        url: "/hxclass-mobile/course/course-open-time/by-id", // 课程下所有班期
        method: "get",
        params: {
          courseId: this.$route.query.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classList = res.data;
          let index = 0;
          for (index; index < res.data.length; index++) {
            if (res.data[index].openTimeId == this.openTimeId) {
              this.formData = res.data[index];
              break;
            }
          }
          // 筛选有效班期列表 - 有数量并且未开班
          this.classList = this.classList.filter(item=>{
            let nowTime = new Date().getTime();
            let itemTime = new Date(item.startTime).getTime();
            return item.currentNumber > 0 && nowTime < itemTime && this.formData.openTimeId != item.openTimeId
          })
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 换班
    onReplace() {
      if (this.$route.query.openTimeId == this.openTimeId) {
        this.$Toast("您当前已在该班级,无需更换");
        return;
      }
      this.$ajax({
        url: "/hxclass-mobile/course/change-class",
        method: "PUT",
        params: {
          courseId: this.$route.query.courseId,
          openTimeId: this.openTimeId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast("更换成功");
          this.onDelayPopupShow = false;
          refreshChangeClass()
          this.$onReturn(this.$route.query.fromMenu == '1');
        } else {
          this.$Toast(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getClassList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.grade {
  background: #f7f7f9;
}
.span1 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}
// 头部
.topinfo {
  padding: 13.5px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(93, 131, 167, 0.05);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 20px 13.5px 24px 13.5px;

  .title {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .span2 {
      width: 122px;
      display: block;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
    .span3 {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
  }
}
// 开课时间
.box1 {
  padding: 13.5px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(93, 131, 167, 0.05);
  margin: 0px 13.5px 0px 13.5px;
  border-radius: 20px 20px 20px 20px;
  .box3 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
    font-family: PingFangMedium;
    justify-content: space-between;
  }
  .box_item {
    width: calc((100% - 9px) / 2);
    color: #333333;
    font-weight: 400;
    background: #ffffff;
    border-radius: 9px;
    padding: 5.5px 0px;
    text-align: center;
    border: 1px solid #dbdbdb;
    margin-bottom: 9px;
    &.pick {
      color: #ffffff;
      background: #1cb9de;
      a {
        color: #ffffff !important;
      }
    }
    .item_title {
      font-size: 14px;
      line-height: 21.5px;
    }
    .item_date {
      font-size: 12px;
      line-height: 19px;
      margin-top: 7px;
    }
    .item_num {
      font-size: 12px;
      font-weight: 500;
      a {
        color: #1cb9de;
      }
      margin-top: 3.5px;
    }
  }
}

// 温馨提示
.box2 {
  padding: 0 18px;
  margin-top: 24px;
  .box_title {
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 13px;
      margin-right: 3.5px;
      background: #15b7dd;
      border-radius: 4px;
    }
  }
  .box_con {
    font-size: 12.5px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #ec6c01;
    line-height: 19px;
    margin-top: 14px;
  }
}
.submit-box {
  position: fixed;
  box-sizing: border-box;
  text-align: center;
  padding-top: 20px;
  bottom: 0;
  width: 100%;
  height: 85.5px;
  padding-left: 40px;
  padding-right: 40px;
  // background: #FFFFFF;
  // box-shadow: 0px -2px 3.5px .5px rgba(0,0,0,0.05);
  .all-btn-bigCircle {
    width: 100%;
  }
  .all-btn-middle {
    width: 267px;
    font-size: 14.5px;
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>
