<template>
  <!-- 报考指南 -->
  <div class="examCourse">
    <div class="box1" v-if="dataList.length !== 0">
      <img
        class="box_img"
        src="@/assets/image/examination/guide_bg.png"
        alt="报考指南"
        title="报考指南"
      />
      <div class="box_con">
        <div
          class="con_item"
          v-show="showGuide || index == 0"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="item_title">
            <div class="title_order">{{ index + 1 }}</div>
            <div class="title_bg">
              <span class="span1">
                <img class="img1" src="@/assets/image/examination/20220620-183019.png" alt="">
                <div class="bluediv">
                  <span class="a1">{{ item.name }} </span>
                </div>
                <img class="img2" src="@/assets/image/examination/20220620-183014.png" alt="">

              </span>
            </div>
          </div>
          <div class="con_txt" :class="{ txt_conceal: !showGuide }">
            {{ item.context }}
          </div>
        </div>
      </div>
      <div class="box_state">
        <div
          style="display: inline-block"
          @click="changeShow()"
          v-if="this.dataList.length !== 1"
        >
          <span class="state_txt">{{ showGuide ? "收起" : "展开" }}</span>
          <van-icon
            v-if="!showGuide"
            name="arrow-down"
            color="#15B7DD"
            size="12"
          />
          <van-icon v-else name="arrow-up" color="#15B7DD" size="12" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    configId: {
      type: String,
      default: "",
    },
  },
  // 数据对象
  data() {
    return {
      showGuide: false, //是否展开
      dataList: [],
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 报考指南
    this.getGuide();
  },
  // 事件处理器
  methods: {
    // 展开
    changeShow() {
      this.showGuide = !this.showGuide;
    },
    // 报考指南
    getGuide() {
      this.$ajax({
        url: "/hxclass-mobile/exam/guide/list?configId=" + this.configId,
        method: "get",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.dataList = res.data;
          // 展开全部
          if (this.dataList.length == 1) {
            this.showGuide = true;
          }
        } else {
          this.$alert(res.message);
        }
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.box1 {
  margin: 18px 18px 0 18px;
  border: 1px solid #abeaf9;
  border-radius: 9px 9px 0 0;
  .box_img {
    display: block;
    width: 100%;
    border-radius: 9px 9px 0 0;
  }
  .box_con {
    padding: 0 14px;
    .con_item {
      margin-top: 20px;
      .item_title {
        padding-left: 15px;
        .title_order {
          position: relative;
          top: -5px;
          left: 0px;
          display: inline-block;
          width: 14px;
          height: 14px;
          line-height: 14px;
          text-align: center;
          font-size: 10px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #1bb9de;
          border: 1px solid #15b7dd;
        }
        .title_bg {
          position: relative;
          left: -5px;
          display: inline-block;
          height: 20px;
          text-align: center;
          background-size: 100%;
          // background-repeat: no-repeat;
          // background-position: center;
          // padding: 0 10px;
          border-bottom: rgb(217 250 255) dashed 1px;
          .span1 {
            // width: 100%;
            display: flex;
            position: relative;
            .a1 {
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #15b7dd;
              // z-index: 11;
              // position: relative;
            }
            .bluediv {
              background-color: rgb(217 250 255);
              height: 10px;
              display: inline-block;
              width: 100%;
              // position: absolute;
              // left: 0px;
              // z-index: -11;
              // top: 0px;
            }
            .img1{
              width: 5px;
              height: 10px;
            }
            .img2{
              width: 5px;
              height: 10px;
              position: relative;
              top: 10px; 
            }
          }
        }
      }
      .con_txt {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 10px;
        &.txt_conceal {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .box_state {
    text-align: center;
    font-size: 11px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #15b7dd;
    margin: 16px 0;
    .state_txt {
      margin-right: 2px;
    }
  }
}
</style>
