<template>
  <div id="window">
    <van-nav-bar
      title="页面出错"
      left-arrow
      @click-left="$onReturn(isAPP)"
    />
    <div id="content">
      <div>
        <img :src="require('@/assets/image/lookup.png')" alt="">
        <p>404，页面走丢了！</p>
        <div class="all-btn-bigCircle" @click="onReturnHome">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { FunGoHome } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      isAPP: localStorage.getItem("isApp"), // 是否是原生一级页面跳转
    }
  },
  // 事件处理器
  methods: {
    onReturnHome () {
      FunGoHome();
      // this.$router.replace('/')
    }
  },

  // 路由守卫
  beforeRouteEnter(to,from,next) {
    if(from.path == "/") {
      localStorage.setItem("isApp",true)
    }
    next()
  },
  // 生命周期-实例创建完成后调用
  created () {},
  // 生命周期-实例挂载后调用
  mounted () {
    if(localStorage.getItem("isApp")) {
      let path = this.$route.fullPath;
      if(path.indexOf("?") != -1) {
        path = path + "&fromMenu=1"
      }else{
        path = path + "?fromMenu=1"
      }
      this.$router.replace(path)
    }
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  destroyed() {
    localStorage.setItem("isApp",false)
  }
}
</script>

<style lang="less" scoped>
#window{
  height: 100vh;
  display: flex;
  flex-direction: column;
  #content{
    flex: 1;
    background-color: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    >div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        width: 154px;
      }
      p{
        font-size: 16px;
        color: #666;
        margin: 20px 0 26px;
      }
    }
  }
}
</style>
