<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="考试报名"
      left-span=""
      right-span=""
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    
    <div class="content-box">
      <div class="sign-in-list">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishTxt"
          @load="getDetail"
        >
          <div 
            class="sign-item" 
            v-for="(item, index) in dataList" 
            :key="index" 
            @click="goDetail(item.configId, item.examName, index)">
            <img class="right-bottom-icon" src="@/assets/image/examination/examList_bg.png" />
            <!-- <img class="left-top-icon" src="@/assets/image/examination/20220217041502.png" /> -->
            <div class="top_line"></div>
            <div class="title">{{item.examName}}</div>
            <div v-if="isLogin">
              <!-- <div class="time">报名时间：{{manageSignup(item.signupDate)}}</div> -->
              <div class="time">报名时间：{{item.signupDate}}</div>
              <div class="time">考试时间：{{item.examDate}} </div>
            </div>
            <div v-else>
              <div class="detail">了解考试详情 》</div>
            </div>
          </div>
        </van-list>
        <noMessage class="nomessage" type=1 v-if="dataList.length == 0" message='暂无考试报名~'></noMessage>
      </div>

      <!-- 提示信息 -->
      <van-popup v-model="showPopup">
        <div class="box_popup">
          <div class="popup_title">
            <van-icon name="warning-o" color="#EB3C31" size="26" />
            <span class="title_con">提示信息</span>
          </div>
          <div class="popup_con">{{popupTxt}}</div>
          <div class="popup_button middle" v-if="verifiType==1">
            <span class="all-btn-middle btn2" @click="showPopup=false">知道了</span>
          </div>
          <!-- <div class="popup_button" v-if="verifiType==2">
            <span class="all-btn-middle btn1" @click="showPopup=false">取消</span>
            <span class="all-btn-middle btn2" @click="goIdent()">去认证</span>
          </div>
          <div class="popup_button" v-if="verifiType==3">
            <span class="all-btn-middle btn1" @click="showPopup=false">取消</span>
            <span class="all-btn-middle btn2" @click="goBuyClass()">去购买</span>
          </div> -->
        </div>
      </van-popup>

    </div>
  </div>
</template>

<script>
  import noMessage from "@/components/nomessage.vue";
  import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
	export default {
		// 可用组件的哈希表
		components: {
      noMessage
    },
		// 数据对象
		data() {
			return {
        isLogin: false, //是否登录
        loading: false,
        finished: false,
        finishTxt: '', 
        current: 1, //页数
        size: 10, //条数
        dataList: [],

        // 提示信息
        showPopup: false,
        popupTxt: '',
        courseId: '', //课程id
        // 校验状态
        verifiType: 0, //1接口校验未通过 2未认证 3购买
      }
		},
    created() {
      // 考试列表
      // this.getDetail()
      // 判断是否登录
      this.isLogin = this.$store.state.userInfo.isLogin
    },
		// 事件处理器
		methods: {
      // 考试列表
      getDetail() {
        this.$ajax({
          url: '/hxclass-mobile/exam/config/list',
          method: "get",
          params: {
            userId: this.$store.state.userInfo.userId, //用户id
            current: this.current, //页数
            size: this.size, //条数
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.dataList = this.dataList.concat(res.data.records)
            // console.log(this.dataList)
            this.loading = false
            this.finishTxt = ''
            if (this.dataList.length == res.data.total) {
              this.finished = true
              
              this.$nextTick(() => {
                let pageHeight = document.documentElement.clientHeight //页面高度
                let navHeight = document.getElementsByClassName('van-nav-bar')[0].clientHeight //导航栏高度
                let conHeight = document.getElementsByClassName('content-box')[0].clientHeight //内容高度
                // console.log(pageHeight, navHeight, conHeight)
                if (pageHeight > (navHeight+conHeight)) {
                  this.finishTxt = ''
                } else {
                  this.finishTxt = '没有更多了'
                }
              })
            } else {
              this.current ++
            }
            // 无数据
            if (this.dataList.length == 0) {
              this.finishTxt = ''
            }
          } else {
            this.$alert(res.message);
          }
        })
      },
      // 详情
      goDetail(id, name, index){
        // 未登录
        let isLogin = this.$store.state.userInfo.isLogin
        if (!isLogin) {
          this.$router.push({
            path:'/examEnroll/registration',
            query:{
              id: id, 
              type: 1
            }
          });
          return
        }

        // 校验
        this.$ajax({
          url: '/hxclass-mobile/exam/config/list/verifi',
          method: "get",
          params: {
            configId: id, //考试id
            userId: this.$store.state.userInfo.userId, //用户id
          }
        }).then((res) => {
          // console.log(res)
          if (res.code == 200 && res.success) {
            this.$router.push({
              path:'/examEnroll/registration',
              query:{
                id: id, //考试id
                type: 1, //考试报名列表
              }
            });
            // console.log(this.$store.state.userInfo.certified)
            // let certified = this.$store.state.userInfo.certified
            // // 未认证
            // if (!certified) {
            //   // 提示信息
            //   // this.popupTxt = '购买本课程需要完成身份认证'
            //   this.popupTxt = '请先去身份认证'
            //   this.showPopup = true
            //   // 未认证
            //   this.verifiType = 2
            // }
            // // 已认证
            // else if (!!certified) {
            //   this.$router.push({
            //     path:'/examEnroll/registration',
            //     query:{
            //       id: id, 
            //       type: 1,
            //     }
            //   });
            // }
          } else {
            // 接口校验未通过
            this.verifiType = 1
            this.popupTxt = res.message
            this.showPopup = true
            // // 课程id
            // this.courseId = this.dataList[index].courseId
            // // 提示信息
            // this.popupTxt = res.message
            // this.showPopup = true
            // console.log(this.popupTxt.indexOf('仅限'))
            // // 未购买提示
            // if (this.popupTxt.indexOf('仅限') > -1) {
            //   this.verifiType = 3
            // } else {
            //   // 接口校验未通过
            //   this.verifiType = 1
            // }
          }
        })
      },
      // 去认证
      goIdent() {
        this.$router.push({
          path:'/mine/identityAuthentication',
          query:{url:'/examination/examSignList'}
        });
      },
      // 去购买
      goBuyClass(courseId) {
        jumpToShopDetail(1,this.courseId)
        // this.$router.push({
        //   path:'/curriculum/course',
        //   query:{'id': this.courseId}
        // });
      },
      // 处理报名日期
      manageSignup(con) {
        if (!con) {return}
        if (con == '暂无安排') {return con}
        let startDate = con.split('至')[0]
        let endDate = con.split('至')[1]
        return startDate + ' 至 ' + endDate
      },
    },
	}
</script>

<style lang="less" scoped>
  /deep/.van-popup {
    background-color: transparent;
  }
  // /deep/.van-nav-bar__placeholder {
  //   height: @states-height !important;
  // }
  // /deep/.van-nav-bar--fixed {
  //   // top: 80px;
  //   // bottom: 0;
  //   height: @states-height;
  // }
  // /deep/.van-nav-bar__content {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  // }
  .content-box{
    padding: 18px;
    .sign-in-list{
      .sign-item{
        margin-bottom: 16.5px;
        box-sizing: border-box;
        padding: 12.5px 0 9px 12.5px;
        background: #FFFFFF;
        box-shadow: 0px 2.5px 4.5px 1px rgba(0,0,0,0.05);
        border-radius: 9px;
        overflow: hidden;
        position: relative;
        // .left-top-icon{
        //   position: absolute;
        //   z-index: 0;
        //   top: 0;
        //   left: 0;
        //   width: 122px;
        // }
        .top_line {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: #6DCDE3;
          // border-radius: 5px 5px 0px 0px;
        }
        .right-bottom-icon{
          position: absolute;
          z-index: 0;
          right: 0;
          bottom: 0;
          width: 61px;
        }
        .title,.time{
          position: relative;
          font-family: PingFangMedium;
        }
        .title{
          font-size: 14.5px;
          font-weight: 500;
          color: #333333;
          line-height: 21.5px;
        }
        .time{
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 19px;
        }
        .detail {
          font-size: 13px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #15B7DD;
          margin: 14px 0 14px 0;
        }
      }
      .nomessage {
        margin-top: 50px;
      }
    }
    // 提示信息
    .box_popup {
      box-sizing: border-box;
      position: relative;
      width: 305px;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 24px 16px;
      z-index: 10;
      .popup_title {
        display: flex;
        align-items: center;
        .title_con {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFangMedium;
          font-weight: 500;
          color: #333333;
          margin-left: 7px;
        }
      }
      .popup_con {
        line-height: 25px;
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        margin-top: 22.5px;
      }
      .popup_button {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        &.middle {
          justify-content: center;
        }
        .all-btn-middle {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFangMedium;
          font-weight: 500;
        }
        .btn1 {
          color: #666666;
          background: #EEEEEE;
          box-shadow: none;
        }
      }
    }
  }
</style>
