<template>
  <div class="active-share">
    <div 
      class="back" 
      @click="onBack()">
      <img  src="@/assets/image/blackLeft.png" />
    </div>
    <div class="bg-box">
      <img
        class="beat-img beat1"
        src="@/assets/image/activity/20240822-113224.png"
        alt=""
      />
      <img
        class="beat-img beat2"
        src="@/assets/image/activity/20240822-114450.png"
        alt=""
      />
      <img
        class="beat-img beat3"
        src="@/assets/image/activity/20240822-114453.png"
        alt=""
      />
      <img
        class="beat-img beat4"
        src="@/assets/image/activity/20240822-114458.png"
        alt=""
      />
      <img
        class="beat-img beat5"
        src="@/assets/image/activity/20240822-114936.png"
        alt=""
      />
      <img
        class="beat-img beat6"
        src="@/assets/image/activity/20240822-114941.png"
        alt=""
      />
      <img
        class="bg-img"
        src="@/assets/image/activity/20240822-112225.png"
        alt=""
      />
      <div @click="shareFile" class="btn-img">
        <img
          src="@/assets/image/activity/20240822-112412.png"
          alt=""
        />
      </div>
      <img
        class="btn-gif"
        src="@/assets/image/activity/hand.gif"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getAppVersion, FunSendShare } from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice  } from "@/unit/fun.js";
const DeviceType = FunJudgDevice()
import config from '@/unit/config.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      config,
			AppVersion: getAppVersion(),  //获取版本号
    };
  },
  // 事件处理器
  methods: {
    onBack(){
      if(DeviceType == 'android'){
        this.$onReturn(true)
      } else {
        this.$onReturn(this.$route.query.fromMenu == '1')
      }
    },
    // 分享
		shareFile() {
      const userId = this.$store.state.userInfo.userId
      // 不同设备版本限制
      if((DeviceType == 'ios' && window.webkit && this.AppVersion.code > 77) || (DeviceType == 'android' && this.AppVersion.code > 107)){
        let json = {
          WebUrl: this.config.shareWxUrl + '/pages/active/teacherShare?source=' + userId,
          title: '免费领取新人三重礼包',
          description: '学习专业的特殊儿童康复教育知识，领取权威资料、免费课程、50元优惠券...',
          imageUrl: 'https://cos.hxclass.cn/test/ueditor/banner/c96073aa99d3449d99275d05a3c49644.jpg'
        }
        FunSendShare(JSON.stringify(json));
      } else{
        this.$Toast('请更新app版本')
      }
		},
  },
};
</script>

<style lang="less" scoped>
.active-share {
  min-height: 100vh;
  overflow: hidden;
  background-color: #27d0f2;
  position: relative;
  .back{
    position: absolute;
    left: 26px;
    top: 60px;
    z-index: 9;
    img{
      width: 26px;
    }
  }
  .bg-box {
    position: relative;
    margin-bottom: 70px;
    .beat-img {
      position: absolute;
      width: 11%;
      top: 8%;
    }
    .beat1{
      left: 17%;
      -webkit-animation: Tada 2s both infinite;
      -moz-animation: Tada 2s both infinite;
      -ms-animation: Tada 2s both infinite;
      animation: Tada 2s both infinite;
    }
    .beat2{
      left: 28%;
      -webkit-animation: Tada 3s both infinite;
      -moz-animation: Tada 3s both infinite;
      -ms-animation: Tada 3s both infinite;
      animation: Tada 3s both infinite;
    }
    .beat3{
      left: 38%;
      -webkit-animation: Tada 4s both infinite;
      -moz-animation: Tada 4s both infinite;
      -ms-animation: Tada 4s both infinite;
      animation: Tada 4s both infinite;
    }
    .beat4{
      left: 52%;
      -webkit-animation: Tada 5s both infinite;
      -moz-animation: Tada 5s both infinite;
      -ms-animation: Tada 5s both infinite;
      animation: Tada 5s both infinite;
    }
    .beat5{
      left: 62%;
      -webkit-animation: Tada 2s both infinite;
      -moz-animation: Tada 2s both infinite;
      -ms-animation: Tada 2s both infinite;
      animation: Tada 2s both infinite;
    }
    .beat6{
      left: 73%;
      -webkit-animation: Tada 4s both infinite;
      -moz-animation: Tada 4s both infinite;
      -ms-animation: Tada 4s both infinite;
      animation: Tada 4s both infinite;
    }
    @keyframes Tada {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
    .bg-img {
      width: 100%;
    }
    .btn-img {
      position: absolute;
      bottom: -6%;
      left: 50%;
      width: 60%;
      transform: translate(-50%, 0);
      img{
        width: 100%;
      }
    }
    .btn-gif{
      position: absolute;
      bottom: -9%;
      right: 14%;
      width: 20%;
    }
  }
}
</style>
