<template>
	<div class="container" v-show="showMask">
		<div class="mask" :style="{'opacity': maskVal}" @click="hide"></div>
		<div class="main" :style="{transform: `translateY(${transY}px)`,'transition-timing-function': speed,'height': height+'px'}" @transitionend="onTransitionend">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		/**
		 *  @ speed [String] {运动曲线}
		 *  @ height [Number] {弹框高度 单位：rpx}
		 *  @ maskVal [Number] {蒙层透明度}
		 */
		props:{
			speed:{
				type:String,
				default: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)'
			},
			height: {
				type:Number,
				default: 500
			},
			maskVal:{
				type: Number,
				default: 0.4
			}
		},
		data () {
			return {
				showMask: false,
				transY: this.height
			}
		},
		methods: {
			show () {
				this.showMask = true
				document.documentElement.style.overflow = "hidden";
				setTimeout(() => {
					this.transY = 0
				},17)
			},
			hide () {
				this.transY = this.height;
				this.$parent.handleItem = null; // 清除操作对象数据
				this.$parent.openTimeId = null; // 清除班期数据
				this.$parent.reason = '我不想要了'; // 清除取消原因数据
				this.$parent.modelcouponList = [];
      	this.$parent.modelcurrentOffer = 0;
				if(this.$route.path == "/setUp/myCopy"){
					this.$parent.closeCouponModel();
				}
				document.documentElement.style.overflow = "scroll";
				// console.log(this.$parent,this.$parent.arr = 1)
			},
			onTransitionend () {
				if(!this.transY) return;
				this.showMask = false
			}
		}
	}
</script>

<style scoped lang="less">
.mask {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgb(0,0,0);
	z-index: 999;
}

.main {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	background-color: #FFFFFF;
	border-radius: 24px 24px 0 0;
	transition: transform 0.4s;
	z-index: 9001;
}
</style>
