// 订单
export default [
  { // 退款
    path: 'refundList',
    component: () => import('../views/refund/list.vue')
  },
  { // 申请退款
    path: 'refundApplyFor',
    component: () => import('../views/refund/applyFor.vue')
  },
  { // 退款详情
    path: 'refundDetail',
    component: () => import('../views/refund/refundDetail.vue')
  },
  { // 填写寄回信息
    path: 'returnMessage',
    component: () => import('../views/refund/returnMessage.vue')
  },
  { // 订单列表
    path: 'myOrder',
    component: () => import('../views/order/myOrder.vue'),
    name:'keepAliveMyOrder',
    meta: {
      keepAlive: true // 需要缓存
    },
    beforeEnter: (to, from, next) => {
      // ...
      if(from.path == '/order/evaluate'){
        sessionStorage.setItem('involceStatus',true);
      }else{
        sessionStorage.setItem('involceStatus','');
      }
      next();
    }
  },
  { // 课程订单详情
    path: 'classOrderDetail',
    component: () => import('../views/order/classOrderDetail.vue')
  },
  { // 确认订单
    path: 'confirmOrder',
    component: () => import('../views/order/confirmOrder.vue')
  },
  { // 选择优惠券
    path: 'coupon',
    component: () => import('../views/order/coupon.vue')
  },
  { // 订单评价
    path: 'evaluate',
    component: () => import('../views/order/evaluate.vue')
  },
  { // 物流信息
    path: 'logistics',
    component: () => import('../views/order/logistics.vue')
  },
  { // 确认订单
    path: 'orderCreatAll',
    component: () => import('../views/order/orderCreatAll.vue')
  },
  { // 订单详情
    path: 'orderDetail',
    component: () => import('../views/order/orderDetail.vue')
  },
  { // 支付
    path: 'orderPayAll',
    component: () => import('../views/order/orderPayAll.vue')
  },
  { // 支付-第三方
    path: 'orderPayAllOther',
    component: () => import('../views/order/orderPayAllOther.vue')
  },
  { // 确认付款-对公转账
    path: 'orderPayConfirm',
    component: () => import('../views/order/orderPayConfirm.vue')
  },
  { // 包裹列表
    path: 'parcelList',
    component: () => import('../views/order/parcelList.vue')
  },
  { // 对公转账成功页面
    path: 'corporatetransfer',
    component: () => import('../views/order/corporatetransfer.vue')
  },
]
