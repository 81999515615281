<template>
  <div class="main">
    <div class="content-inner">
      <van-nav-bar
        title="注销账户"
        :fixed='true'
        :placeholder='true'
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>
    <div class="content">
      <div class="item">
        <p>为保证你的账号安全，在你提交的注销申请生效前，需同时满足以下条件：</p>
      </div>
      <div class="item">
        <p>1.账号处于安全状态</p>
        <span>账号处于正常使用状态，无被盗风险</span>
      </div>
      <div class="item">
        <p>2.账号财产已结清</p>
        <span>没有资产、欠款、未结清的资金和虚拟权益，本账号及通过本账号接入的第三方中没有未完成或存在争议的服务</span>
      </div>
      <div class="item">
        <p>3.账号权限解除</p>
        <span>账号已解除与其他产品的授权登录或绑定关系</span>
      </div>
      <div class="item">
        <p>4.账号无任何纠纷，包括投诉举报</p>
      </div>
    </div>
    <div class="onSave-foo">
      <!-- 协议条款 -->
      <div class="box_clause" :class="{'shake_slow':isShake&&!clauseAgree}">
        <div class="clause_icons" @click="changeClause()">
          <van-icon v-if="!clauseAgree" name="circle" color="#858585" size="15" />
          <van-icon v-else name="passed" color="#2CBEE0" size="15" />
        </div>
        <div class="clause_con">
          <span @click="changeClause()">我已阅读并同意</span>
          <span class="light" @click="goClause()">《华夏云课堂注销须知》</span>
        </div>
      </div>
      <span class="all-btn-bigCircle" @click="submit">下一步</span>
    </div>
    <van-popup v-model="onPopup" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">注销提示</span>
        </div>
        <div class="popup_con">为防止误操作，请再次确认是否注销账号，并确认注销后的影响。我们对您的注销决定深表遗憾，最后善意的提醒您，<span class="txet_imp">注销账号为不可恢复的操作。</span></div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onPopup = false">取消</span>
          <span class="all-btn-middle btn2" @click="onConfirm()">确认</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { FunLogOut } from '@/assets/js/NativeWebKit.js';
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        isShake: false,
        clauseAgree: false,
        onPopup:false
      }
		},
		// 事件处理器
		methods: {
      // 注销协议
      goClause(){
        this.$router.push('/all/cancellation');
      },
      // 同意条款
      changeClause() {
        this.clauseAgree = !this.clauseAgree;
      },
      // 下一步
      submit(){
        if(!this.clauseAgree){
          // 如果未勾选协议
          this.isShake = true
          let timer = setTimeout(() => {
            this.isShake = false
            clearTimeout(timer)
          },1000)
        } else {
          // 弹出最后的确认框
          this.onPopup = true
        }
      },
      // 注销
      onConfirm(){
        this.$ajax({
          url: '/hxclass-mobile/user/info/cancell/account?mobile=' + this.$store.state.userInfo.mobile,
          method: "put",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$alert('账户已注销');
            this.$store.commit('clearUserInfo')
            // this.$router.replace({path: '/home'})
            // 原生返回首页
            FunLogOut()
            localStorage.setItem('searchInfo', JSON.stringify(''))
            
            // 音频关闭
            if(this.$store.state.audio.src){
              this.$store.commit('updateAudio',{
                src: '',
                currentTime: 0,
                show: false
              })
            }
          } else {
            this.$Toast(res.message);
          }
        })
      }
    },
    computed: {
    },
    created(){
    },
    beforeCreate() {
      this.$nextTick(()=>{
        document.querySelector('body').setAttribute('style', 'background: #ffffff;')
      })
    },
    // 生命周期-实例销毁离开后调用
    destroyed () {
      document.querySelector('body').removeAttribute('style')
    },
	}
</script>

<style lang="less" scoped>
  @import "./style.less";
  .content-inner{
    border-bottom: 1px solid #F7F7F9 !important;
  }

  .content{
    padding: 18px;
    .item{
      margin-bottom: 24px;
      p{
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
      }
      span{
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
    }
  }

  // 保存按钮
  .onSave-foo{
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 40px;
    text-align: center;
    padding: 0 40px;
    // 条款
    .box_clause {
      margin-bottom: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3%;
      .clause_con {
        font-size: 12px;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.6);
        margin-left: 5px;
        line-height: 20px;
        text-align: justify;
        .light {
          color: #15b7dd;
          // white-space: nowrap;
        }
      }
    }
    .all-btn-bigCircle {
      width: 100%;
    }
  }

  // 协议抖动
  @keyframes shake_slow {
    14% {
      transform: translate(-10px, 0px);
    }
    30% {
      transform: translate(10px, 0px);
    }
    48% {
      transform: translate(-7px, 0px);
    }
    58% {
      transform: translate(7px, 0px);
    }
    70% {
      transform: translate(-3px, 0px);
    }
    84% {
      transform: translate(3px, 0px);
    }
    0%,
    100% {
      transform: translate(0, 0);
    }
  }
  .shake_slow {
    // animation: shake-slow 5s infinite ease-in-out;
    animation: shake_slow 1s ease-in-out;
  }

  // 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 80vw;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
    .txet_imp{
      font-weight: 600;
    }
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>

