export default [
  {
    path: '/',
    redirect: 'home'
  },
  // // 引导页
  // {
  //   path: 'guidePage',
  //   beforeEnter: ((to, from, next) => {
  //     const launchFlag = localStorage.getItem('storage_key')
  //     // 判断是否已存在，如果存在直接去首页
  //     if (launchFlag) {
  //       next('/home')
  //     } else {
  //       next()
  //     }
  //   }),
  //   component: () => import('@/views/home/guidePage.vue')
  // },
  // 首页
  {
    path: 'home',
    component: () => import('@/views/home/home.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  // 精选
  {
    path: 'selected',
    component: () => import('@/views/home/selected.vue')
  },
  // 学习
  {
    path: 'study',
    component: () => import('@/views/home/study.vue')
  },
  // 我的
  {
    path: 'my',
    component: () => import('@/views/home/my.vue')
  },
]
