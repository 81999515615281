<template>
  <div class="infobox">
    <div :style="funHead()">
      <van-nav-bar
        v-if="$route.query.head != '0'"
        title="华夏云课堂服务协议"
        :fixed="true"
        :placeholder="true"
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>
    <iframe src="https://app.download.hxclass.cn/ServiceAgreement.html" frameborder="0" class="box_iframe"></iframe>
  </div>
</template>

<script>
import { FunJudgDevice } from '@/unit/fun.js'

export default {
  data(){
    return{
      
    }
  },
  methods: {
    funHead(){
      if(this.$route.query.head != 0){return false}
      const type = FunJudgDevice()
      if(type == 'ios' && window.webkit){
        // ios端
        return 'height:100px;'
      }else if(type == 'android'){
        // android端
        return 'height:20px;'
      }else{
        // 其他端
        return 'height:80px;'
      }
    }
  }
}
</script>


<style lang="less" scoped>
.box_iframe{
  width: 100%;
  height: 92vh;
}
</style>