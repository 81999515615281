<template>
  <!-- 发票信息 -->
  <div class="invoiceMessage">
    <van-nav-bar
      title="发票信息"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 选择是否开发票 -->
    <div class="invoiceFlag">
      <p :class="{active:isInvoice===1}" @click="isInvoice = 1">电子发票</p>
      <p :class="{active:isInvoice===0}" @click="isInvoice = 0">无需发票</p>
    </div>
    <!-- 抬头信息 -->
    <div class="titleH4" v-show="isInvoice===1">
      <div class="left">
        <div class="icon"></div>
        <div class="text">抬头信息</div>
      </div>
      <div class="right">
        <div class="btn" @click="$router.push('/invoice/InvoiceHeader')">
          <van-icon name="plus" />
          <span>添加抬头</span>
        </div>
      </div>
    </div>
    <!-- 抬头列表 -->

    <div class="riseList" v-show="isInvoice===1">
      <div class="item" :class="zIndex==item.userInvoiceId?'click':''" @click="zIndex=item.userInvoiceId" v-for="(item,index) in invoiceData" :key="index">
        <div class="label">{{item.headerType == 3?'个人':'企业'}}</div>
        <div class="name">{{item.invoiceHeader}}</div>
        <div class="mailbox">
          <span>{{item.dutyParagraph}}</span>
        </div>
        <div class="mailbox" style="margin-top: 5px;">
          <span>{{item.email}}</span>
        </div>
      </div>
      <no-message class="nomessage" v-if="!invoiceData.length" type='5'></no-message>
    </div>
    <div class="footerView" :class="{'footerStyle': footerShow}">
      <van-button plain type="primary" class="buttonsubmit" @click="submit">确认</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      zIndex: null,
      isInvoice: 1, // 是否开具发票1.是 0.否
      footerShow: false,
      invoiceData:[], // 抬头列表
    }
  },
  methods:{
    // 修改确认订单发票id
    submit(){
      if(this.isInvoice){
        if(this.zIndex){
          this.$store.commit('updateInvoiceId',this.zIndex);
          this.$store.commit('updateShortInvoiceId',this.zIndex);
        }else{
          return this.$Toast('请选择发票抬头')
        }
      } else {  // 无需发票
        this.$store.commit('updateShortInvoiceId',null);
			  this.$store.commit('updateInvoiceId',null);
      }
      this.$onReturn(this.$route.query.fromMenu == '1');
    },
    // 获取发票抬头列表
    getInvoiceList(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/list",
        }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data
        }
      });
    },
  },
  created(){
    this.isInvoice = this.$store.state.confirmOrder.InvoiceId ? 1:0;
    this.zIndex = this.$store.state.confirmOrder.InvoiceId;
    // 如果有暂存发票id
    if(this.$store.state.confirmOrder.shortInvoiceId){
      this.zIndex = this.$store.state.confirmOrder.shortInvoiceId
      this.isInvoice = this.$store.state.confirmOrder.shortInvoiceId ? 1:0;
    }
    this.getInvoiceList();
  }
}
</script>

<style scoped lang="less">
  .footerView {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px 18px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
		z-index: 1000;
		&.footerStyle {
			display: none;
		}
    /deep/.buttonsubmit {
      height: 40px;
      flex: 1;
      line-height: 40px;
      color: #ffffff;
      background-color: #15b7dd;
      border-color: #15b7dd;
      border-radius: 20px;
      margin: 0;
    }
    /deep/.buttonsubmit:after {
      border: 0;
    }
  }
  .invoiceFlag{
    margin: 18px 18px 0;
    background: #fff;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-radius: 5px;
    align-items: center;
    p{
      border-radius: 5px;
      width: 150px;
      height: 40px;
      border: 2px solid transparent;
      background-color: #f7f7f9;
      text-align: center;
      line-height: 38px;
      box-sizing: border-box;
    }
    .active{
      // padding: 9px 29px;
      // line-height: 38px;
      border: 2px solid #15B7DD;
      background-image: url('../../assets/image/icon/active.png');
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 20px;
    }
  }
  .riseList{
    margin: 0 18px;
    padding-top: 17px;
    .nomessage{
      padding-top: 100px;
    }
    .item{
      background-color: #fff;
      position: relative;
      padding: 47px 16px 16px 16px;
      font-weight: 400;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      border-radius: 12px;
      margin-bottom: 14px;
      border: 1px solid rgba(255,255,255,0);
      &.click{
        border-color: #15B7DD;
        background-image: url('../../assets/image/address/icon2.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 20px;
      }
      .label{
        position: absolute;
        left: 0;
        top: 0;
        width: 56px;
        height: 24px;
        background: #15B7DD;
        box-shadow: 2px 0px 0px 0px rgba(26,164,196,1);
        border-radius: 8px 0px 8px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
      .name{
        font-size: 16px;
        margin-bottom: 11px;
      }
      .mailbox{
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        color: #333;
        .icon{
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }
  .titleH4{
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 25px;
    margin-top: 10px;
    .left{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon{
        background-color: #15B7DD;
        display: block;
        width: 3px;
        height: 13px;
        border-radius: 1.5px;
        margin-right: 3.5px;
      }
      .text{
        font-size: 16.5px;
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      align-items: center;
      .text{
        font-size: 12px;
        color: #999;
      }
      .btn{
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333;
        .icon{
          margin-right: 2px;
        }
      }
    }
  }
</style>