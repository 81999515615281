//考试报名
export default [
  { // 考试报名列表
    path: 'registration',
    component: () =>
      import('../views/examEnroll/registration.vue')
  }, { // 更换考试场次
    path: 'changeExam',
    component: () =>
      import('../views/examEnroll/changeExam.vue')
  }, { // 确认信息详情
    path: 'signUpConfirmDetail',
    component: () =>
      import('../views/examEnroll/signUpConfirmDetail.vue'),
  }, { // 确认信息
    path: 'signUpConfirm1',
    component: () =>
      import('../views/examEnroll/signUpConfirm1.vue'),
      // meta: {
      //   keepAlive: true // 需要缓存
      // }
  }, { // 确认信息
    path: 'signUpConfirm2',
    component: () =>
      import('../views/examEnroll/signUpConfirm2.vue')
  },
  { // 确认信息
    path: 'signUpConfirm3',
    component: () =>
      import('../views/examEnroll/signUpConfirm3.vue')
  },
  { // 机构报名-个人确认信息
    path: 'signUpConfirmOk',
    component: () =>
      import('../views/examEnroll/signUpConfirmOk.vue')
  },
  { // 个人-报名信息
    path: 'enrollMessage',
    component: () =>
      import('../views/examEnroll/enrollMessage.vue'),
      meta: {
        keepAlive: true // 需要缓存
      }
  },
  { // 机构-报名信息
    path: 'enrollMessageGroup',
    component: () =>
      import('../views/examEnroll/enrollMessageGroup.vue'),
      meta: {
        keepAlive: true // 需要缓存
      }
  }

]