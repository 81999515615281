export default{
	data(){
		return{
			thisData: {}, // 当前按下数据
			changeData: {}, // 当前改变的数据
			thisOffset: 0 // 当前的偏移量
		}
	},
	methods:{
		// 触摸开始
		drag_hstart(e){
			const tag = e.touches[0]
			this.thisData = tag
			this.thisOffset = this.LoopTabOffset
		},
		
		// 触摸改变
		drag_hmove(e){
			const tag = e.touches[0]
			this.changeData = tag
			this.LoopTabOffset = this.thisOffset - (this.thisData.pageX - tag.pageX)
			this.FunIsCenter()
		},
		
		// 触摸结束
		drag_hend(e){
			this.thisData = this.changeData
			this.ResetImmediately()
		},
		
		// 判断元素是否移动到中心位置
		FunIsCenter(){
			// 可视坐标中心值
			this.showCenterNum = this.LoopTabOffset - (this.thisWidth / 2)
			this.AllList.forEach((item,index)=>{
				if(this.showCenterNum + item.leftMinPx < 0 && this.showCenterNum + item.leftMaxPx > 0){
					// 当前显示中心的元素下标
					this.CenterIndex = index
				}
			})
		},
		
		// 点击切换当前选中
		onClickTab(e){
			this.CenterIndex = e
			this.ResetImmediately()
		},
		
		// 立刻复位
		ResetImmediately(){
			clearTimeout(this.setDelayedReset)
			this.setDelayedReset = null
			this.$set(this, 'delayed', false)
			// 如果当前下标小于第二组则复位到第二组，如果大于第二组，则同理
			if((this.AllList.length / 3) > this.CenterIndex){
				this.LoopTabOffset = ~ ((this.TotalWidth / 3) - this.LoopTabOffset) - -1
				this.CenterIndex = (this.AllList.length / 3) + this.CenterIndex
			}else if(((this.AllList.length / 3) * 2) < this.CenterIndex){
				// 得到偏移量
				const leftOffset = ~this.LoopTabOffset - ((this.TotalWidth / 3) * 2)
				this.LoopTabOffset = ~ ((this.TotalWidth / 3) + leftOffset) - 1
				this.CenterIndex = this.CenterIndex - (this.AllList.length / 3)
			}
			this.$emit('change', this.CenterIndex - (this.AllList.length / 3))
			this.DelayedReset()
		},
		
		// 延迟复位，有一个延迟1s的动画
		DelayedReset(){
			setTimeout(()=>{
				const item = this.AllList[this.CenterIndex]
				// 计划坐标
				this.delayed = true
				this.LoopTabOffset = ~ (item.leftMinPx - ((this.thisWidth - item.width) / 2))
				this.setDelayedReset = setTimeout(()=>{
					this.delayed = false
				},100)
			})
		}
	}
}