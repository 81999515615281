<template>
  <div>
    <div class="content">
      <div>
        <span class="content-info" v-if="item.teacherList && item.teacherList.length">主讲人：<span v-for="name in item.teacherList" :key="name">{{name}} </span></span>
      </div>
      <!-- 未购买 -->
      <div v-if="isHave==0" class="nobuy">
        <span class="timer">时长：{{item.duration=='-' ?  item.duration : formatSeconds(item.duration)}}</span>
        <!-- 可以试看    免费课程不能试看 -->
        <span style="font-size:12px" @click.stop="onTry(item)" v-if="item.isFreeTrial == 1 && isFree==0" :class="{'tryColor' : sectionId == item.sectionId}">试看</span>
        <!-- 否则加锁 -->
        <img v-else class="tree_icon" style="margin-left: 0;" src="@/assets/image/selected/lock-999.png" />
      </div>
      <!-- 已购买 -->
      <div v-else-if="isHave==1" class="nobuy">
        <span class="timer">时长：{{item.duration=='-' ?  item.duration : formatSeconds(item.duration)}}</span>
        <!-- 如果该章节未解锁-那就加锁 -->
        <img v-if="item.lock==1" class="tree_icon" style="margin-left: 0;" v-show="item.lock==1" src="@/assets/image/selected/lock-999.png" />
        <template v-else>
          <div class="flexDiv">
            <!-- <div class="tree_icon_box" v-show="((item.watchProgress) * 100).toFixed(0) == 100">
              <span class="tree_txt tryColor">已看完</span>
            </div> -->
            <!-- 播放 -->
            <div class="tree_icon_box" >
              <img class="tree_icon" style="width:10px;" src="@/assets/image/selected/play_icon.png" />
              <span class="tree_txt tryColor">进度：{{item.watchProgress ? ((item.watchProgress) * 100).toFixed(0):'0'}}%</span>
            </div>
            <!-- 自测 -->
            <div class="tree_icon_box" v-show="item.isHaveSelfTest == 1">
              <img class="tree_icon" style="width:9px;" src="@/assets/image/selected/test_icon.png" />
              <span class="tree_txt tryColor" @click.stop="onSelfTest">自测</span>
              <!-- 自测通过图标 -->
              <img class="icon" v-show="item.studyStatus==4" src="@/assets/image/selected/test_icon1.png" />
              <!-- 自测失败图标 -->
              <img class="icon" v-show="item.studyStatus==5" src="@/assets/image/selected/test_icon2.png" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { formatSeconds, FunJudgDevice } from '@/unit/fun.js'
const DeviceType = FunJudgDevice();
import { FunSelfTest,getAppVersion } from '@/assets/js/NativeWebKit.js';

export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    item: {},
    // 正在播放视频id
		sectionId: {
			type: Number,
			default: null
    },
    // 课程是否拥有
		isHave: {
			type: Number,
			default: null
    },
    // 课程是否免费
		isFree: {
			type: Number,
			default: null
    },
    // 课程章节id
		courseId: {
			type: null,
			default: null
    },
    // 当前是课程详情还是播放 1 播放
		video: {
			type: Number,
			default: null
    },
    // 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
  },
  // 数据对象
  data () {
    return {
      formatSeconds,
      AppVersion: null,
    }
  },
  // 事件处理器
  methods: {
    onTry(e){
			// this.$emit('setTry', e)
    },
    //自测入口
    onSelfTest(){
      if((this.item.watchProgress && ((this.item.watchProgress) * 100).toFixed(0) == 100) || !this.isOrder){
        // 安卓用原生方法跳转小节自测 this.AppVersion.code >= '107'  (this.AppVersion.code >= '114' ? 1 : 0)
        if (DeviceType == "android") {
          FunSelfTest(this.courseId, this.item.sectionId, this.item.sectionName, (this.isOrder ? 1 : 0), 1)
        } else {
          this.$router.push(
            {
              path:'/examination/selfTest',
              query:{
                courseId: this.courseId,
                sectionId: this.item.sectionId,
                sectionName: this.item.sectionName,
                isOrder: (this.isOrder ? 1 : 0),
                video: this.video
              }
            }
          );
        }
      } else {
        this.$alert('请先学完课程后再进行自测')
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.query.share) {
      return this.AppVersion = null;
    }
    this.AppVersion = getAppVersion();
  },
  // 生命周期-实例挂载后调用
  mounted () { 
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content{
  margin-top: 4.5px;
  font-size: 10px;
  color: #999;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  >div{
    image{
      width: 13px;
      margin-right: 5px;
      max-height: 13px;
    }
  }
  .nobuy{
    // min-width: 150px;
    flex: 1;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .timer{
      white-space:nowrap; 
    }
  }
  >span{
    // color: $theme;
  }
  .content-info {
    margin-right: 24px;
  }
  image {
    width: 12px;
  }
  .tryColor{
    color: #15B7DD;
    font-size: 10px;
  }
  .icon{
    position: relative;
    top: 0px;
    width: 10px;
    margin-left: 2px;
  }
  .tree_icon {
		width: 14px;
		margin-left: 14px;
  }
  .flexDiv{
    display: flex;
    align-items: center;
    .tree_icon_box {
      display: inline-flex;
      align-items: center;
      white-space:nowrap; 
      .tree_icon{
        margin-right: 3px;
      }
    }
  }
}
</style>
