export default [
  {
    path: 'addressList', // 收货地址管理
    component: () => import('../views/address/index.vue')
  },
  {
    path: 'addAddress', // 添加地址
    component: () => import('../views/address/addAddress.vue')
  },
  {
    path: 'adjust', // 修改地址
    component: () => import('../views/address/adjust.vue')
  }
]