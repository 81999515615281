<template>
  <div class="introduce">
    <div class="content" v-html="data.content"></div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      data:{},
      xuanId: 0
    }
  },
  // 事件处理器
  methods: {
    // 查询详情
    getDetails(xuanId){
      this.xuanId = parseInt(xuanId)
      this.$ajax({
        url: '/hxclass-mobile/speak/details',
        params: {
          xuanId: xuanId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.data = res.data
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getDetails(this.$route.query.xuanId)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.introduce{
  // margin: 0 15px;
	margin-top: 20px;
  min-height: calc(100vh - 524px);
  background: #FFFFFF;
  border-radius: 22px 22px 0 0;
  padding: 20px;
	line-height: 20px;
  /deep/.content{
     img{
      width: 100%;
    }
    strong{
      font-weight: bold;
    }
    em{
      font-style: italic;
    }
    img{
      max-width: 100%;
    }
    .remarks{
      max-width: 100%;
      height: auto !important;
      -webkit-line-clamp: 10000 !important;
    }
    p {
      word-break: break-all;
      word-wrap: break-word;
    }
    h4 {
      font-weight: bold;
    }
  }
  
}
</style>
