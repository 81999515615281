export default [
    /*
      子模块的路由前面不需要添加‘/’
    */
    {
        path: 'BookDdetails', // 图书详情
        component: () =>
            import ('../views/curriculum/BookDdetails.vue')
    },
    {
        path: 'bookTeachAids', // 图书教具
        component: () =>
            import ('../views/curriculum/bookTeachAids.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    {
        path: 'collage', // 亲友拼团
        component: () =>
            import ('../views/curriculum/collage.vue')
    },
    {
        path: 'course', // 课程详情
        component: () =>
            import ('../views/curriculum/course.vue')
    },
    {
        path: 'courseBuy', // 课程详情-第三方购课
        component: () =>
            import ('../views/curriculum/courseBuy.vue')
    },
    {
        path: 'courseVideo', // 学习视频页面
        component: () =>
            import ('../views/curriculum/courseVideo.vue')
    },
    {
        path: 'certificates', // 相关资质
        name:'certificatesDetail',
        component: () =>
            import ('../views/curriculum/certificates.vue')
    },
    {
        path: 'courseList', // 教育课程
        component: () =>
            import ('../views/curriculum/courseList.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    {
        path: 'teacher', // 授课教师
        name: "courseTeacher",
        component: () =>
            import ('../views/curriculum/teacher.vue')
    },
    { // 群聊二维码
        path: 'QRcode',
        component: () =>
            import ('../views/curriculum/QRcode.vue')
    },
    { // 课程延期申请成功
        path: 'courseDelay',
        component: () =>
            import ('../views/curriculum/courseDelay.vue')
    },
    { // 课程延期申请成功
        path: 'courseDelayOther',
        component: () =>
            import ('../views/curriculum/courseDelayOther.vue')
    },
    { // 专属客服页面
        path: 'courseQrode',
        component: () =>
            import ('../views/curriculum/courseQrode.vue')
    },
    { // 更多套餐
        path: 'setMealList',
        component: () =>
            import ('../views/curriculum/setMealList.vue')
    },
    { // 套餐详情
        path: 'Packagedetails',
        component: () =>
            import ('../views/curriculum/Packagedetails.vue')
    },
    { // 提交资质
        path: 'seniority',
        component: () =>
            import ('../views/curriculum/seniority.vue')
    },
    { // 学生活动
        path: 'studentActive',
        component: () =>
            import ('../views/curriculum/studentActive.vue')
    },
    { // 学生学历提交
        path: 'studentAuth',
        component: () =>
            import ('../views/curriculum/studentAuth.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    { // 学历获取步骤
        path: 'educationStep',
        component: () =>
            import ('../views/curriculum/educationStep.vue')
    },
    { // 相关图书教具
        path: 'relatedTeachAids',
        component: () =>
            import ('../views/curriculum/relatedTeachAids.vue')
    },
    { // 订单详情
        path: 'courseOrder',
        component: () =>
            import ('../views/curriculum/courseOrder.vue')
    },
    { // 选择班级
        path: 'selectShift',
        component: () =>
            import ('../views/curriculum/selectShift.vue')
    },
    { // 分享课程跳转页面
        path: 'shareCourse',
        component: () =>
            import ('../views/curriculum/shareCourse.vue')
    },
    { // 分享课程图书教具页面
        path: 'shareBookDdetails',
        component: () =>
            import ('../views/curriculum/shareBookDdetails.vue')
    },
    { // 分享课程套餐页面
        path: 'sharePackagedetails',
        component: () =>
            import ('../views/curriculum/sharePackagedetails.vue')
    }
]