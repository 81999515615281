<template>
	<div class="container-box">
		<div class="header-box">
			<div class="header-left" @click="goBack()">
				<!-- <uni-icons type="left" color="#fff" size="30"></uni-icons> -->
				<van-icon color="#fff" size="15" name="arrow-left" />
			</div>
			<div class="header-pic">
				{{type == 1?'申请成功':'支付成功'}}
			</div>
			<img class="header-image" src="@/assets/image/study/bgc_delay2.png"/>
		</div>
		<div 
			class="main-box"
			:class="{'main-box1': orderType==2 || orderType==3}">
			<div v-if="type == 1">
				<p>您已成功延长学习时间</p>
				<p>本次延期有效期{{delayForm.delayDays}}天，将于{{delayForm.courseEndTime}}到期</p>
				<p>请在有效期内完成学习</p>
			</div>
			<span v-if="type == 2">
				<!-- 电子照订单 -->
				<span v-if="orderType==2">
					冲印费用已支付成功，{{ isOrgOrder ? '请去确认考试证书信息。':'请去填写证书收货地址。'}}
					<!-- <a>{{second}}</a>秒后自动<a @click="$router.go(-4)">返回确认信息</a> -->
				</span>
				<!-- 补考订单 -->
				<span v-else-if="orderType == 3">
					考试申请将于{{manageAuditTime(auditTime)}}统一审核，如在该时间前未学完课程，本次考试申请将被驳回
					<!-- <a>{{second}}</a>秒后自动<a @click="$router.go(-4)">返回考试报名</a> -->
				</span>
				<!-- 其他订单 -->
				<span v-else>
					<a>{{second}}</a>秒后自动<a @click="goHome">返回首页</a>
				</span>
			</span>
		</div>
		<!-- 补考 -->
		<div v-if="orderType==3" class="btn-box">
			<span class="all-btn-bigCircle box_button" @click="toPageUrl()">查看订单</span>
			<span class="all-btn-bigCircle" style="margin-left: 25px;" @click="goBack()">查看报名信息</span>
		</div>
		<div v-else class="btn-box">
			<span v-if="orderType==2" class="all-btn-bigCircle" @click="goBack()">{{ isOrgOrder ? '去确认':'去填写'}}</span>
			<span v-else class="all-btn-bigCircle" @click="toPageUrl()">查看订单</span>
		</div>
	</div>
</template>

<script>
	import { FunJudgDevice } from '@/unit/fun.js'
  import { FunGoHome, FunCloseDelay, onGetStorage, getAppVersion, FunClosePage, onStartActivity, backPicturePage, backExamPage } from '@/assets/js/NativeWebKit.js';
	export default {
		data() {
			return {
				type: 1,//1:审核成功 2：支付成功
				orderType: 0, //订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
				payReturnsign: 0, //返回页面 1考试报名 2订单列表
				second: 3,
				orderId:'',
				delayForm:{},

				signupUserId: null, //补考-用户报名id
				auditTime: '', //系统自动审核时间

				paySignupObj: {}, //电子照支付信息
				isOrgOrder: sessionStorage.getItem('isOrgOrder'), // 是否机构确认冲印订单   true 是  false 否
			}
		},
		methods: {
			// 查看订单
			toPageUrl(){
        this.$router.push({name:"keepAliveMyOrder",params:{keepAlive:true}})
      },
      goHome(){
        // this.$router.push("/")
        FunGoHome()
			},

			// 查询延期的结果
			getDelayTxt(){
				this.$ajax({ 
          url: '/hxclass-mobile/order/delay/finish/copy-writing?orderId=' + this.orderId,
				}).then((res) => {
					// console.log(res)
          if(res.code == '200' && res.success){
						this.delayForm = res.data
					}
				});
			},
			// 返回上一页
			goBack() {
				// 获取原生本地登录信息
				// 版本号控制
				const DeviceType = FunJudgDevice()
				const AppVersion = getAppVersion();
				
				// 电子照-返回考试
				if (this.orderType == 2) {
					// 确认信息页进入
					if (this.payReturnsign==1) {
						if(sessionStorage.getItem('paySignupObj')) {
							this.paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
						}else{
							this.paySignupObj = {}
						}
						
						if (this.paySignupObj && this.paySignupObj.invoice) {
							if (DeviceType == "android") {
								if(AppVersion.code >= 122) {
									backPicturePage()
								}else {
									this.$router.go(-5)
								}
							} else if (DeviceType == "ios" && window.webkit) {
								if(AppVersion.code >= 102) {
									backPicturePage()
								}else {
									this.$router.go(-5)
								}
							}
						} else {
							if (DeviceType == "android") {
								if(AppVersion.code >= 122) {
									backPicturePage()
								}else {
									this.$router.go(-4)
								}
							} else if (DeviceType == "ios" && window.webkit) {
								if(AppVersion.code >= 102) {
									backPicturePage()
								}else {
									this.$router.go(-4)
								}
							}
						}
					}
					// 我的订单页进入
					else {
            // this.$router.push({name:'keepAliveMyOrder',params:{keepAlive:true, type:'paySignupObj'}})
            if (sessionStorage.getItem('paySignupObj')) {
							this.paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj')); //电子照信息
							if(this.isOrgOrder){
								// 跳转机构报名-信息确认页
								this.$router.push({
									path: "/examEnroll/signUpConfirmOk",
									query: {
										signupUserId: this.paySignupObj.signupUserId,
										examineeId: this.paySignupObj.examineeId,
									}
								});
							} else {
								// 跳转个人报名-信息确认页
								this.$router.push({
									path: "/examEnroll/signUpConfirm1",
									query: {
										signupUserId: this.paySignupObj.signupUserId,
										examineeId: this.paySignupObj.examineeId,
										pay: true, //支付成功
									}
								});
							}
            }
					}
					return
				}
				// 补考
				if (this.orderType == 3) {
					// 报名列表进入
					if (this.payReturnsign==1) {
						let payMakeObj
						if(sessionStorage.getItem('payMakeObj')) {
							payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
						}else{
							payMakeObj = {}
						}
						
						if (payMakeObj.invoice) {
							if (DeviceType == "android") {
								if(AppVersion.code >= 122) {
									backExamPage()
								}else {
									FunCloseDelay()
									this.$router.go(-6)
								}
							} else if (DeviceType == "ios" && window.webkit) {
								if(AppVersion.code >= 102) {
									backExamPage()
								}else {
									FunCloseDelay()
									this.$router.go(-6)
								}
							}
						} else {
							if (DeviceType == "android") {
								if(AppVersion.code >= 122) {
									backExamPage()
								}else {
									FunCloseDelay()
									this.$router.go(-5)
								}
							} else if (DeviceType == "ios" && window.webkit) {
								if(AppVersion.code >= 102) {
									backExamPage()
								}else {
									FunCloseDelay()
									this.$router.go(-5)
								}
							}
						}
					} 
					// 我的订单进入
					else {
						// this.$router.push({name:'keepAliveMyOrder',params:{keepAlive:true, type:'payMakeObj'}})
            if (sessionStorage.getItem('payMakeObj')) {
							if (DeviceType == "android") {
								if(AppVersion.code >= 999) {
									onStartActivity(2)
								}else {
									let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj')); //补考信息
									this.$router.push({
										path: "/examEnroll/registration",
										query: {
											id: payMakeObj.configId,
											type: 2,
											pay: true, //支付成功
										}
									});
								}
							} else if (DeviceType == "ios" && window.webkit) {
								if(AppVersion.code >= 999) {
									onStartActivity(2)
								}else {
									let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj')); //补考信息
									this.$router.push({
										path: "/examEnroll/registration",
										query: {
											id: payMakeObj.configId,
											type: 2,
											pay: true, //支付成功
										}
									});
								}
							}
              return
            }
					}
					return
				}

				this.$router.push({name:'keepAliveMyOrder',params:{keepAlive:true}})
			},
			// 补考支付-查看审核时间
			getAuditTime() {
				console.log(this.signupUserId)
				this.$ajax({ 
					url: '/hxclass-mobile/exam/signUp/getAuditTime',
					method: 'GET',
					params: {
						signUpUserId: this.signupUserId,
					}
				}).then((res) => {
					if (res.code == 200 && res.success) {
						this.auditTime = res.data;
					}
				});
			},
			// 处理审核时间
			manageAuditTime(con) {
				if (!!con) {
					let text = con.split('-')
					if (text[1][0] == 0) {
						text[1] = text[1][1]
					}
					if (text[2][0] == 0) {
						text[2] = text[2][1]
					}
					return text[1]+'月'+text[2]+'日'
				}
			},
      // 保存电子照信息
      savePicture() {
        let params = {
          examineeId: this.paySignupObj.examineeId, //考生id
          mode: 2, //照片提交方式 1.邮寄纸质照片 2.使用上传电子照片	
          // isPay: 1, //是否支付 1.是 0.否
          picture: this.paySignupObj.picture, //	电子照片
        }
        this.$ajax({ 
          url: '/hxclass-mobile/exam/examinee/submit',
          method:'PUT',
          params
        }).then(async (res) => {
          if (res.code == 200 && res.success) {
            // 电子照支付成功
          }
        });
      },
    },
		beforeCreate() {
			document.querySelector('body').setAttribute('style', 'background:#ffffff')
		},
    created () {
			/**
			 * 从原生获取信息 -- start
			 */
				// 获取原生本地登录信息
				// 版本号控制
				const DeviceType = FunJudgDevice()
				const AppVersion = getAppVersion();
				if (DeviceType == "android") {
					if(AppVersion.code >= 999) {
						// 补考
						let payMakeObj = onGetStorage('payMakeObj')
						sessionStorage.setItem('payMakeObj',payMakeObj)

						// payReturnsign  1跳回考试报名页 2跳到首页
						let payReturnsign = onGetStorage('payReturnsign')
						localStorage.setItem('payReturnsign',payReturnsign)

						// isOrgOrder  是否机构报名-照片冲印订单
						let isOrgOrder = onGetStorage('isOrgOrder')
						sessionStorage.setItem('isOrgOrder',isOrgOrder)

						// 电子照的考试id
						let paySignupObj = onGetStorage('paySignupObj')
						sessionStorage.setItem('paySignupObj',paySignupObj)
					}
				} else if (DeviceType == "ios" && window.webkit) {
					if(AppVersion.code >= 999) {
						// 补考
						let payMakeObj = onGetStorage('payMakeObj')
						sessionStorage.setItem('payMakeObj',payMakeObj)

						// payReturnsign  1跳回考试报名页 2跳到首页
						let payReturnsign = onGetStorage('payReturnsign')
						localStorage.setItem('payReturnsign',payReturnsign)

						// isOrgOrder  是否机构报名-照片冲印订单
						let isOrgOrder = onGetStorage('isOrgOrder')
						sessionStorage.setItem('isOrgOrder',isOrgOrder)

						// 电子照的考试id
						let paySignupObj = onGetStorage('paySignupObj')
						sessionStorage.setItem('paySignupObj',paySignupObj)
					}
				}
			/**
			 * 从原生获取信息 -- end
			 */

      this.type = this.$route.query.type
      this.orderId = this.$route.query.orderId
      this.orderType = this.$route.query.orderType //订单类型
			// 获取是否需要返回考试报名页面 1返回考试报名 2返回首页
			this.payReturnsign = localStorage.getItem('payReturnsign')
			
			// 电子照支付状态修改
			if (this.orderType == 2) {
				this.paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
				this.paySignupObj.pay = true; //电子照支付成功
				sessionStorage.setItem('paySignupObj', JSON.stringify(this.paySignupObj))
				// 保存电子照信息
				this.savePicture();
			}

			// 补考支付状态修改
			if (this.orderType == 3) {
				let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
				payMakeObj.pay = true; //补考支付成功
				this.signupUserId = payMakeObj.signupUserId;
				sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
				// 查询审核时间
				this.getAuditTime();
			}

      // 当是支付成功时
      if(this.type == 2){
				// 电子照和补考不用倒计时返回
				if (this.orderType == 2 || this.orderType == 3) {
					return;
				}
        this.timeout = setInterval(() => {
          this.second -- 
          if(this.second == 0){
						// 电子照或补考
						if ((this.orderType == 2 || this.orderType == 3) && this.payReturnsign==1) {
							this.$router.go(-4)
						} else {
              // this.$router.push("/home")
              FunGoHome();
						}
            clearTimeout(this.timeout)
          }
				}, 1000);
      } else if(this.type == 1){
				// 延期文案查询
				this.getDelayTxt()
			}
    },
    // 生命周期-实例销毁离开前调用
    beforeDestroy () {
    	document.querySelector('body').removeAttribute('style')
      clearTimeout(this.timeout)
    },
	}
</script>
<style lang="less" scoped>
	* {box-sizing: border-box;}
	.container-box {
		.header-box {
			position: relative;
			width: 100%;
			height: 262px;
			background: url(~@/assets/image/study/bgc_delay1.png) no-repeat;
			background-size: 100%;
			overflow: hidden;
			
			.header-left{
				position: absolute;
				top: 32px;
				left: 18px;
			}

			.header-pic {
				position: relative;
				margin: 86.5px auto 0;
				width: 96.5px;
				height: 96.5px;
				background: url(~@/assets/image/study/pic_delay.png) no-repeat;
				background-size: 100%;
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				line-height: 121.5px;
			}

			.header-image {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 39px;
			}
		}

		.main-box {
			margin: 46px 18px 0;
			font-size: 16px;
			font-family: PingFangMedium;
			font-weight: 500;
			color: #666666;
			line-height: 24px;
      text-align: center;
      a{
        color: #15B7DD;
      }
		}
		.main-box1 {
			margin: 46px 50px 0;
			line-height: 27px;
			text-align: left;
		}
		
		.btn-box{
			box-sizing: border-box;
			position: fixed;
			bottom: 0;
			display: flex;
			justify-content: space-around;
			width: 100%;
			height: 75px;
			padding: 0 40px;
			.box_button {
				border: 1px solid #333333;
				background-image: none;
				background-color: #fff;
				color: #333333;
			}
			.all-btn-bigCircle{
				width: 100%;
			}
		}
	}
</style>
