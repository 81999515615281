<template>
  <!-- 上传证书 -->
  <div class="certificateUpload">
    <!-- 导航栏 -->
    <van-nav-bar
      title="上传证书"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="box">
      <div class="box_list">
        <div class="list_title">选择证书类型</div>
        <div class="list_pick" @click="showPicker = true">

          <!-- <picker @change="bindPickerChange" :value="index" :range="array"> -->
            <div class="pick_con">{{index === -1?'选择证书类型':array[index].name}}</div>
            <van-icon name="arrow"  color="666666" size="11"/>
          <!-- </picker> -->
          <!-- <uni-icons type="right" color="666666" size="23"></uni-icons> -->
        </div>
        <van-popup v-model="showPicker" position="bottom" round :style="{ height: '40%' }">
        <van-picker
          title="证书类型"
          show-toolbar
          value-key="name"
          :columns="array"
          @cancel="showPicker = false"
          @confirm="bindPickerChange"
        />
        </van-popup>
      </div>
      
      <div class="box_list column_box">
        <div class="list_title">上传证书</div>
        <van-uploader :before-read="beforeRead" :after-read="uploadImg">
          <div class="list_upload" v-if="picture==''">
            <img class="upload_icon" src="../../assets/image/study/cer_upload.png" />
            <span class="upload_txt">上传照片</span>
          </div>
          <img class="list_upload_img" :src="picture" v-else />
        </van-uploader>
      </div>
      
      
    </div>
    
    <!-- 证书要求 -->
    <div class="box_info">
      <div class="info_title">证书要求：</div>
      <div class="info_con">证书要求原件，且图片不得涂改，需要确保文字图片，证书号码清晰可辨2.图片大小不得大于1M。3.图片为ipg、png格式</div>
    </div>
    
    <!-- 提交 -->
    <div class="box_button">
      <span class="all-btn-middle" @click="submit">提交</span>
    </div>
    
  </div>
</template>

<script>
  import { dataURLtoFile } from '@/unit/fun.js'
  export default{
    data(){
      return{
        // 证书类型
        array: [],
        index: -1,
        showPicker:false,
        // 证书
        picture: '',
      }
    },
    created() {
      // 证书类型
      this.getTye()
      // 安卓选择照片后回调
      window.returnPhotoUrl = (url)=>{
        var file = dataURLtoFile(url, 'fileName.jpg')
        // 上传图片
        this.uploadImg(file, 1)
      }
    },
    methods:{
      bindPickerChange(e,index) {
        // console.log('picker发送选择改变，携带值为', e,index)
        this.index = index
        this.showPicker = false;
      },
      // 证书类型
      getTye() {
        this.$ajax({
          url: '/hxclass-mobile/user-certif/personal-certif-type',
          method: 'GET'
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.array = res.data
          }
        })
      },
      // 上传图片
      uploadImg(e, type) {
        // console.log(e)
        // type 1安卓上传
        let file = type == 1 ? e : e.file
        // return
        this.$upload({
          file: file,
          type: 'image',
          progress: (e)=>{
          },
          success: (e)=>{
            // console.log(e)
            this.picture = e;
          }
        })
      },

      beforeRead(file){
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";
        if (!isJpgOrPng) {
          this.$Toast("请上传正确格式图片文件");
        }
        const isLt2M = file.size / 1024 / 1024 < 2.5;
        if (!isLt2M) {
          this.$Toast("图片内存大小需在2MB之内");
        }
        return isJpgOrPng && isLt2M;
      },

      // 提交证书
      submit() {
        // 证书类型非空判断
        if (this.index == -1) {
          this.$Toast('请选择证书类型');
          return
        }
        // 照片非空判断
        else if (this.picture == '') {
          this.$Toast('请上传证书照片');
          return
        }
        // 提交
        this.$ajax({
          url: '/hxclass-mobile/user-certif/',
          method: 'POST',
          params: {
            upcertType: this.array[this.index].id,
            picture: this.picture,
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$Toast.success('个人证书提交成功，7个工作日内将完成审核');
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.$onReturn(this.$route.query.fromMenu == '1')
            }, 1000);
          }else{
            this.$Toast(res.message);
          }
        })
      },
    }
  }
</script>

<style>
  page{
    background-color: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  * {box-sizing: border-box;}
  .certificateUpload {
    min-height: 100vh;
    background: #F7F7F9;
    padding-bottom: 50px;
  }

  .box {
    .box_list {
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      margin-top: 9px;
      padding: 15px 18px;
      &.column_box {
        flex-direction: column;
      }
      .list_title {
        display: inline-block;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        &::before {
          display: inline-block;
          content: '';
          width: 3px;
          height: 13px;
          background: #15B7DD;
          border-radius: 4px;
          margin-right: 3.5px;
        }
      }
      .list_pick {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 21.5px;
        .pick_con {
          line-height: 1;
          font-size: 14.5px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
        }
      }
      .list_upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 302.5px;
        height: 181px;
        background: #F5FDFF;
        margin: 12.5px 18px 0 18px;
        .upload_icon {
          width: 33.5px;
        }
        .upload_txt {
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #15B7DD;
          margin-top: 9px;
        }
      }
      /deep/.van-uploader__input-wrapper {
        width: 100%;
      }
      .list_upload_img {
        box-sizing: border-box;
        width: 100%;
        margin-top: 12.5px;
      }
    }
  }

  // 证书要求
  .box_info {
    padding: 12.5px 18px;
    .info_title {
      font-size: 14.5px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
    }
    .info_con {
      font-size: 11px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 16.5px;
      margin-top: 9px;
    }
  }

  // 提交
  .box_button {
    width: 100%;
    padding: 0 40px;
    position: fixed;
    left: 0;
    bottom: 10px;
    text-align: center;
    .all-btn-middle {
      width: 100%;
      height: 36px;
      line-height: 36px;
      font-size: 14.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

</style>
