<template>
  <div id="window">
    <van-nav-bar
      :fixed="true"
      title="发票详情"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div id="content">
      <div class="box">
        <!-- 发票信息 -->
        <div class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">发票信息</div>
          </div>
        </div>
        <!-- 详情 -->
        <div class="details">
          <div class="images" v-show="form.png">
            <!-- 发票预览图片 -->
            <img class="img" :src="form.png + '?ci-process=doc-preview&dstType=png&copyable=0'"/>
          </div>
          <div class="item">
            <div class="left">抬头类型</div>
            <div class="right">{{form.typeTxt}}</div>
          </div>
          <div class="item">
            <div class="left">发票抬头</div>
            <div class="right">{{form.title}}</div>
          </div>
          <div class="item" v-show="form.taxNo">
            <div class="left">税号</div>
            <div class="right">{{form.taxNo}}</div>
          </div>
          <div class="item">
            <div class="left">电子邮箱</div>
            <div class="right">{{form.email}}</div>
          </div>
          <div class="item" v-show="form.openBank">
            <div class="left">开户银行</div>
            <div class="right">{{form.openBank}}</div>
          </div>
          <div class="item" v-show="form.bankNo">
            <div class="left">银行账户</div>
            <div class="right">{{form.bankNo}}</div>
          </div>
          <div class="item" v-show="form.companyPhone">
            <div class="left">公司电话</div>
            <div class="right">{{form.companyPhone}}</div>
          </div>
          <div class="item" v-show="form.companyAddress">
            <div class="left">公司地址</div>
            <div class="right">{{form.companyAddress}}</div>
          </div>
          <div class="item">
            <div class="left">申请时间</div>
            <div class="right">{{form.applyTime}}</div>
          </div>
          <div class="item">
            <div class="left">发票内容</div>
            <div class="right">{{form.invoiceContent}}</div>
          </div>
          <div class="item">
            <div class="left">开票金额</div>
            <div class="right money">
              <span>¥</span>
              <span>{{form.price.toFixed(2)}}</span>
            </div>
          </div>
        </div>
        
        <!-- 商品信息 -->
        <div class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">商品信息</div>
          </div>
        </div>
        
        <div class="aggregate">
          <div class="commodity">
            <div class="list" v-for="item in form.productList" :key="item.id + 'productId'">
              <div class="image" v-show="item.productPicture">
                <img class="img" :src="item.productPicture"/>
              </div>
              <div class="data">
                <div class="name">{{item.productName}}</div>
                <div class="num" v-if="!form.isFreight">x{{item.productCount}}</div>
                <div class="num" v-else>x1</div>
              </div>
            </div>
          </div>
          <!-- 合计 -->
          <div class="total">
            <span>实付金额：¥{{form.price.toFixed(2)}}</span>
            <span>共{{form.productList.length}}件商品</span>
          </div>
          <div class="item" v-if="!form.isFreight">
            <div class="left">商品类型</div>
            <div class="right">{{form.productTypeTxt}}</div>
          </div>
          <div class="item">
            <div class="left">订单编号</div>
            <div class="right">{{form.subOrderCode}}</div>
          </div>
          <!-- <div class="item">
            <div class="left">订单状态</div>
            <div class="right">{{form.productTypeTxt}}</div>
          </div> -->
          <div class="item">
            <div class="left">下单时间</div>
            <div class="right">{{form.createOrderTime}}</div>
          </div>
        </div>
      </div>
      <van-overlay :show="show">
        <div class="wrapper">
          <div class="block">
            <van-icon name="cross" @click="show=false" size="20px" class="close" color="#666666" />
            <h4>发送邮箱</h4>
            <p>请输入您的接收邮箱：</p>
            <van-field v-model="email" placeholder="请输入邮箱" />
            <div class="btn-box">
              <span @click="show=false">取消</span>
              <span @click="onResetEmail">确认</span>
            </div>
          </div>
        </div>
      </van-overlay>
      <!-- 底部操作栏 -->
      <div class="btnSave">
        <div class="all-btn-bigCircle" @click="show = true">发送邮箱</div>
      </div>
      <div class="heiSave"></div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      email:'',
      show:false,
      form:{
        price:0,
        productList:[]
      }
    }
  },
  // 事件处理器
  methods: {
    // 获取发票详情
    getInvoiceDetail(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/" + this.$route.query.invoiceRecordId,
        }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data
        }
      });
    },
    // 发送邮箱
    onResetEmail(){
      if(!this.email){
        this.$Toast("请填写邮箱");
        return
      }
      if (!this.$regular.email.reg.test(this.email)) {
        this.$Toast(this.$regular.email.msg);
        return
      }
      this.$ajax({
        url: "/hxclass-mobile/invoice/email/resend",
        method:'post',
        params: {
          email: this.email,
          invoiceRecordId: this.$route.query.invoiceRecordId
        }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.email = ''
            this.show = false
            this.$Toast('发送成功');
          } else {
            this.$Toast(res.message);
          }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getInvoiceDetail()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import '../../assets/css/invoice.less';

.box{
  padding: 0 18px;
}
.titleH4{
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 25px;
  margin-top: 10px;
  .left{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon{
      background-color: @theme;
      display: block;
      width: 3px;
      height: 13px;
      border-radius: 1.5px;
      margin-right: 3.5px;
    }
    .text{
      font-size: 16.5px;
    }
  }
  .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
      font-size: 12px;
      color: #999;
    }
  }
}
// 底部操作栏
.heiSave{
  height: 50px;
}
.btnSave{
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 40px;
  .all-btn-bigCircle {
    width: 100%;
  }
  .save{
    width: 271px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: @theme;
    font-size: 14px;
  }
}
.details{
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 0;
  margin: 12px 0 20px;
  .item{
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    line-height: 22px;
    margin-bottom: 16px;
    &:last-child{
      margin: 0;
    }
    .left{
      color: #333;
      font-size: 14px;
      min-width: 80px;
      &::after{
        content: '：';
      }
    }
    .right{
      font-size: 14px;
      color: #666;
      &.money{
        // color: #EC6C01;
        // font-family: PingFangMedium;
        // font-size: 16px;
        // font-weight: 500;
      }
      .link{
        margin-left: 15px;
        color: @theme;
      }
    }
  }
}
.images{
  padding: 0 12px 12px;
  border-radius: 8px;
  overflow: hidden;
  .img{
    width: 100%;
  }
}
.aggregate{
  background-color: #FFFFFF;
  padding: 12px 0 ;
  margin-top: 12px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-radius: 8px;
  .commodity{
    .list{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 12px;
      padding: 12px 0;
      border-bottom: .5px solid #eee;
      &:last-child{
        border: none;
      }
      .image{
        .img{
          width: 125px;
          border-radius: 8.5px;
        }
      }
      .data{
        flex: 1;
        margin-left: 10px;
        .name{
          font-size: 14px;
          color: #333;
          font-weight: 400;
          line-height: 22px;
        }
        .num{
          font-weight: 400;
          color: #999;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 12px;
          line-height: 17px;
        }
      }
    }
  }
  .total{
    border-bottom: .5px solid #eee;
    margin: 0 18px;
    text-align: right;
    font-size: 12px;
    color: #666;
    padding-bottom: 16px;
    margin-bottom: 16px;
    span{
      margin-left: 20px;
    }
  }
  .item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    line-height: 22px;
    margin-bottom: 16px;
    &:last-child{
      margin: 0;
    }
    .left{
      color: #333;
      font-size: 14px;
      &::after{
        content: '：';
      }
    }
    .right{
      font-size: 14px;
      color: #666;
      &.money{
        // color: #EC6C01;
        // font-family: PingFangMedium;
        // font-size: 16px;
        // font-weight: 500;
      }
      .link{
        margin-left: 15px;
        color: @theme;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  padding: 25px 16px;
  width: 80%;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  color: #333333;
  .close{
    position: absolute;
    right: 16px;
    top:16px;
  }
  h4{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 18px;
  }
  .van-cell{
    margin-top: 16px;
    padding: 8px 14px;
    background: #EEEEEE;
    border-radius: 4px;
  }
  .btn-box{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    >span{
      display: inline-block;
      height: 40px;
      background: #EEEEEE;
      border-radius: 20px;
      line-height: 40px;
      color: #666666;
      text-align: center;
      width: calc((100% - 30px) / 2);
    }
    >span:nth-child(2){
      color: #fff;
      background: #15B7DD;
    }
  }
}
</style>
