import $ajax from './ajax.js'
import store from './../store'
import { FunPlay, toast} from '@/assets/js/NativeWebKit.js';

export function courseVideo({courseId,sectionId,isOrder}){
  let url =""
  if(isOrder === 0){
    // 查询不按顺序播放源信息
    url = "/hxclass-mobile/course/getClickSectionVod"
  } else {
    // 查询顺序播放源信息
    url = "/hxclass-mobile/course/section-vod-info"
  }
  $ajax({
    url: url,
    params: {
      courseId: courseId,
      sectionId: sectionId,
      userId: store.state.userInfo.userId
    }
  }).then(res=>{
    // 查询下一章节的播放信息
    if(res.code == 200 && res.success && res.data.fileId){
      FunPlay({
        title: res.data.sectionName, // 章节名称
        courseId: res.data.courseId, // 课程id
        isFast: res.data.isFast, // 是否能快进 1.能快进，0.不能快进
        isSpeed: res.data.isSpeed, // 是否能快 1.能倍速，0.不能倍速
        currStudyFileId: res.data.fileId, // 当前学习的云点播fileid
        currPcfg: res.data.pcfg, // 云点播的播放器签名
        currStudySeconds: res.data.currSeconds, // 当前学习的视频历史进度秒数
        currStudySectionId: res.data.sectionId, // 当前学习的章节id
        SectionTest: 0, // 是否试看 1/0
        isHaveSelfTest: res.data.isSelfTest, // 是否有小节自测 1/0
        // res.data.passStatus 章节自测状态1.未开始 2.未看完 3.已看完 4.自测通过 5.自测失败
        // res.data.nextSectionId 下一个的章节id
        // (有下一章节id && 自测状态通过) || (有下一章节id && 没有小节自测)
        isNextSectionId: ((res.data.nextSectionId && res.data.passStatus == 4) || (res.data.nextSectionId && res.data.isSelfTest == 0)) ? 1 : 0, // 是否有下一个章节 1/0
        isOrder: ((isOrder === 0 || isOrder === 1) ? isOrder : 1),  // 章节是否按正常顺序播放 1.顺序播放  0 无序播放
      })
    }else{
      toast('未找到视频源信息')
    }
  })
}