import { render, staticRenderFns } from "./studyCensus.vue?vue&type=template&id=a387f6e8&scoped=true&"
import script from "./studyCensus.vue?vue&type=script&lang=js&"
export * from "./studyCensus.vue?vue&type=script&lang=js&"
import style0 from "./studyCensus.vue?vue&type=style&index=0&id=a387f6e8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a387f6e8",
  null
  
)

export default component.exports