<template>
	<div>
		<tree :arr="tree" @nodeClick="outClick" :isOrder="isOrder" :video="video" :trys="trys" :isFree="isFree" :courseId="courseId" :isHave="have" :sectionId="sectionId" />
	</div>
</template>

<script>
import tree from './tree.vue'
export default {
	// 可用组件的哈希表
	components: {tree},
	// 接收传值
	props: {
		tree: {
			type: Array,
			default: ()=>[]
		},
		// 是否试看
		trys: {
			type: Boolean,
			default: false
		},
		// 正在播放视频id
		sectionId: {
			type: null,
			default: null
		},
		// 课程章节id
		courseId: {
			type: null,
			default: null
		},
    // 课程是否拥有
		have: {
			type: Number,
			default: null
		},
		// 课程是否免费
		isFree: {
			type: Number,
			default: null
		},
    // 当前是课程详情还是播放 1 播放
		video: {
			type: Number,
			default: null
		},
		// 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
	},
	// 数据对象
	data () {
		return {}
	},
	// 事件处理器
	methods: {
		outClick(e){
			this.$emit('nodeClick', e)
		}
	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped></style>
