const COS = require('cos-js-sdk-v5');
import ajax from './ajax.js'
import { Toast } from 'vant'

// 图片格式限制
let imgTypeArr = ['png', 'jpg', 'jpeg'];
// 文件格式限制
let fileTypeArr = ['docx', 'doc', 'pdf'];

// 获取临时权限
function getToKen(name){
  return ajax({
    // url: '/hxclass-mobile/cos/security/token',
    url: '/hxclass-mobile/cos/get-credential',
    params: {
      fileName: name
    }
  }).then(res=>{
    if(res.code == 200 && res.success){
      return Promise.resolve(res)
    }
  })
}

/*
  文件上传
  调用方式：
    upload({
      file: fileObject,
      type: 'image|file',
      progress: (e)=>{},
      success: (e)=>{}
    })
*/
export default function upload({
  file,
  type,
  progress,
  success,
  fail
}){
  // 图片、文件格式限制
  let lock = true; //锁
  let FileType = ''; //文件类型
  type = !type ? 'image' : type;
  // 图片
  if (type == 'image') {
    FileType = file.type.split('/')[1].toLowerCase();
    imgTypeArr.map((item, index) => {
      if (item == FileType) {
        lock = false;
      }
    })
  } 
  // 文件
  else if (type == 'file') {
    FileType = file.name.split('.')[1].toLowerCase();
    fileTypeArr.map((item, index) => {
      if (item == FileType) {
        lock = false;
      }
    })
  }
  // 不可上传
  if (!!lock) {
    let hint = type == 'image' ? imgTypeArr.map((item, index) => {return item}) : fileTypeArr.map((item, index) => {return item})
    Toast('请上传格式为'+hint+'的'+ (type == 'image' ? '图片' : '文件'));
    return
  }
  // console.log('可以通过')
  // return
  
  Toast.loading({
    forbidClick: true,
    duration: 0
  });
  let config = new Object()
  getToKen(file.name).then(res=>{
    config = res.data
    const cos = new COS({
      getAuthorization: (options, callback)=>{
        callback({
          TmpSecretId: config.credentials.tmpSecretId,
          TmpSecretKey: config.credentials.tmpSecretKey,
          SecurityToken: config.credentials.sessionToken,
          StartTime: config.startTime,
          ExpiredTime: config.expiredTime,
        });
      }
    });
    cos.uploadFile({
      Bucket: config.bucket,
      Region: config.region,
      Key: config.key,
      StorageClass: 'STANDARD',
      SliceSize: 1024 * 1024 * 5,
      Body: file, // 上传文件对象
      onProgress: function(progressData) {
        // 上传进度
        progress(progressData)
      }
    }, function(err, data) {
      Toast.clear();
      // 上传结果
      if(data.statusCode == 200){
        // success('https://'+data.Location)
        success(config.host+config.key)
      } else {
        fail(err)
      }
    })
  })
}