<template>
  <div class="page">
    <!--  -->
    <div class="content bgf" v-if="logisticsData">
      <!-- <div class="content bgf"> -->
      <div>
        <!-- 快递 -->
        <!-- <div class="flex list">
					<div class="info flex1">
						<img class="info_txt" :src="logisticsData.logo"/>
						<div class="title">
							<span class="title_name">{{logisticsData.logisticsInfo.comName}} {{logisticsData.logisticsInfo.nu}}</span>
							<span class="title_info" @click="copyPost()">复制</span>
						</div>
					</div>
				</div> -->
        <div class="list">
          <div class="info flex1 titles">
            <img
              class="info_logo"
              src="../../assets/image/order/logistics_icon0.png"
            />
            <span v-if="logisticsData.logisticsInfo.comName" class="title_name">{{ logisticsData.logisticsInfo.comName }} {{ logisticsData.logisticsInfo.nu }}</span>
            <span v-else class="title_name">{{ logisticsData.logisticsCompany }} {{ logisticsData.logisticsCode }}</span>
            <span class="title_info" v-if="isShowMore" @click="copyPost()"
              >复制单号</span
            >
            <span class="title_info" v-else @click="showMore()">
              展开
              <van-icon
                name="arrow"
                class="more_icon"
                color="#15B7DD"
                size="10"
              />
            </span>
          </div>
        </div>
        <!-- 收货地址 -->
        <div class="flex list" style="margin-top: 14px" v-if="isShowMore">
          <div
            class="info flex2 icons"
            :class="{ pick: logisticsData.logisticsInfo.state == 3, 'no_line': !logisticsData.logisticsInfo.comName }"
          >
            <div class="info_txt">收</div>
            <!-- <div class="span">[收货地址] {{logisticsData.address.provinceName+logisticsData.address.cityName+logisticsData.address.districtName+logisticsData.address.address}}{{logisticsData.address.phone}}</div> -->
            <div class="span">
              [收货地址] {{ logisticsData.address }}
              {{ logisticsData.tellPhone }}
            </div>
          </div>
        </div>

        <!-- 物流 -->
        <div v-if="isShowMore">
          <div
            class="flex list icons"
            v-for="(item, index) in logisticsList"
            :key="index"
          >
            <!-- v-show="isShowMore || index==0"> -->
            <div class="info  icons" :class="{ pick: index == 0 }">
              <div class="info_txt" v-if="item.stateImg">
                <img class="info_img" :src="item.stateImg" />
              </div>
              <div class="title">
                <span class="title_txt" v-if="item.status">{{
                  item.status
                }}</span>
                <span class="title_time">{{ item.ftime }}</span>
              </div>
              <div class="span">{{ item.context }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 查看更多 -->
      <!-- <div class="con_more" @click="showMore()" v-if="!isShowMore"> -->
      <div
        class="con_more"
        v-if="isShowMore && showType !== 3"
        @click="closeMore()"
      >
        <!-- <div v-if="!isShowMore">
					<span class="more_txt">点击查看更多物流详情</span>
					<van-icon name="arrow-down" class="more_icon" color="#999999" size="16"/>
				</div> -->
        <div>
          <span class="more_txt pick">收起</span>
          <van-icon
            name="arrow-up"
            class="more_icon"
            color="#15B7DD"
            size="16"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 1证书 2成绩单 3物流信息
    showType: {
      type: Number,
      default: -1,
    },
    // 证书index
    showIndex: {
      type: Number,
      default: -1,
    },
    // 物流数据
    logisticsData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      logisticsList: {}, //物流
      isShowMore: false,
      // 快递状态
      logisticsState: {
        签收: "已签收",
        派件: "派件中",
        在途: "运输中",
        揽收: "已揽件",
      },
      // 状态对应的图片logo
      logisticsImg: {
        签收: require("../../assets/image/order/logistics_icon1.png"),
        派件: require("../../assets/image/order/logistics_icon2.png"),
        在途: require("../../assets/image/order/logistics_icon3.png"),
        揽收: require("../../assets/image/order/logistics_icon4.png"),
        已下单: require("../../assets/image/order/logistics_icon5.png"),
        疑难: require("../../assets/image/order/logistics_icon6.png"),
        退签: require("../../assets/image/order/logistics_icon7.png"),
        清关: require("../../assets/image/order/logistics_icon8.png"),
        拒签: require("../../assets/image/order/logistics_icon9.png"),
      },
    };
  },
  created() {},

  watch: {
    logisticsData: {
      // console.log(89878)
      // if(newVal){
      // 	// 处理数据
      // 	this.changeData()
      // }
      handler: function (val) {
        // 处理数据
        this.changeData();
      },
      immediate: true,
      deep: true,
    },
    // 页面类型
    showType: {
      handler: function (val) {
        // 物流信息
        if (val == 3) {
          this.isShowMore = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 处理数据
    changeData() {
      if (!this.logisticsData) {
        return;
      }
      if (this.showType == 1 && !this.logisticsData.logisticsInfo.state) {
        return;
      }
      let logisticsList = JSON.parse(
        JSON.stringify(this.logisticsData.logisticsInfo.data)
      );
      if (!logisticsList) {
        return;
      }
      let logisticsItem = {};
      logisticsList = logisticsList.map((item, index) => {
        // 默认每一条数据参数
        logisticsItem = {
          areaCode: item.areaCode,
          areaName: item.areaName,
          context: item.context,
          ftime: item.ftime,
          time: item.time,
        };
        // 第一条加状态和状态logo
        if (index == 0) {
          return {
            ...logisticsItem,
            status: this.logisticsState[item.status],
            stateImg: this.logisticsImg[item.status],
          };
        } else if (index !== 0) {
          // 当前状态与上一条状态一致
          if (item.status == logisticsList[index - 1].status) {
            return { ...logisticsItem };
          } else {
            return {
              ...logisticsItem,
              status: this.logisticsState[item.status],
              stateImg: this.logisticsImg[item.status],
            };
          }
        }
      });
      // 物流信息
      if (this.showType == 3) {
        // 添加已下单状态
        logisticsList.push({
          context: "商品已经下单",
          ftime: this.logisticsData.paymentTime,
          status: "已下单",
          stateImg: this.logisticsImg["已下单"],
        });
      }

      this.logisticsList = logisticsList;
    },
    // 复制单号
    copyPost() {
      var input = document.createElement("input"); // 直接构建input
      input.value = this.logisticsData.logisticsInfo.nu; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$Toast("复制成功");
    },
    // 查看更多物流消息
    showMore() {
      // 证书
      if (this.showType == 1) {
        // 获取物流
        if (!this.logisticsData.logisticsInfo.state) {
          this.$emit("getLogisticsData", this.showIndex);
        } else {
          this.isShowMore = true;
        }
      }
      // 成绩单
      else if (this.showType == 2) {
        this.isShowMore = true;
      } else {
        this.isShowMore = true;
      }
    },
    // 收起
    closeMore() {
      this.isShowMore = false;
    },
  },
};
</script>

<style lang="less" scoped>
/*flex 转换成flex容器*/
.flex {
  display: flex;
  flex-direction: row;
}

/*flex1 自动填充*/
.flex1 {
  flex: 1;
  // display: flex;
  // align-items: center;
}

/*ali-c 竖直居中*/
.ali-c {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bgf {
  // background-color: #fff;
}

.express {
  //圆通快递
  background-color: #ffffff;
  border-radius: 10px;
  margin: 15px 10px 15px 10px;

  .top {
    padding: 14px 14.5px 12.5px 14.5px;

    .img {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    .span {
      margin-left: 10px;

      .span1 {
        margin-bottom: 3px;
        font-size: 14px;
        color: #000000;
      }

      .span2 {
        font-size: 10px;
        color: #000000;
      }
    }
  }

  .right {
    background-color: rgba(8, 175, 254, 0.02);
    border-radius: 0px 0px 10px 10px;
    padding: 7.5px 15px 10px 15px;

    .span {
      color: #666666;
      font-size: 11px;
    }
  }
}

//收货地址
.content {
  // margin: 10px;
  // margin-left: 15.5px;
  // padding: 28px 23px 28px 2.5px;
  padding: 18px 0px 0 2.5px;
  border-radius: 10px;

  .list {
    .flex1 {
      display: flex;
      align-items: center;
      padding-top: 0;
      .info::before {
        bottom: -10px;
        margin-top: 20px;
        border-left: 0.5px dashed #e5e5e5;
      }

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        // margin-bottom: 28px;
        margin-left: -18px;
        .title_info {
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #15b7dd;
          margin-right: -10px;
         
        }
      }
    }
    .flex2 {
      .info::before {
        bottom: -10px;
        margin-top: 20px;
        border-left: 0.5px dashed #e5e5e5;
      }

      .title {
        margin-bottom: 28px;
      }
    }
    // &:first-child {

    // }

    &:last-child {
      .info::before {
        height: 16px;
      }
    }

    &.one {
      .info::before {
        margin-top: 10px;
      }

      .title {
        color: #1a1a1a;
      }

      .span {
        color: #666;
      }
    }
  }

  .info {
    position: relative;
    padding-top: 6px;
    color: #999;
    .info_txt {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 31px;
      height: 31px;
      top: 0;
      left: -15.5px;
      border-radius: 50%;
      z-index: 2;
      color: #ffffff;
      background-color: #cccccc;
      .info_img {
        display: inline-block;
        width: 34px;
        height: 34px;
      }
    }

    &::before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 0.5px;
      background: #e5e5e5;
      display: block;
      // border-left: 0.5px solid #e5e5e5;
    }
    &.no_line {
      &::before {
        border-left: 0;
      }
    }

    .title {
      position: relative;
      margin-bottom: 5px;
      padding-left: 27.5px;
      font-size: 11px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;

      // &::before {
      // 	content: "";
      // 	position: absolute;
      // 	left: -2.5px;
      // 	top: 0;
      // 	bottom: 0;
      // 	z-index: 1;
      // 	width: 5px;
      // 	height: 5px;
      // 	margin: auto 0;
      // 	border-radius: 50%;
      // 	background-color: #cecece;
      // }
      .title_txt {
        display: inline-block;
        width: 56px;
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
      }
      .title_time {
        font-size: 11px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #666666;
      }
    }

    .span {
      font-size: 11px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 16.5px;
      padding-left: 27.5px;
      &:last-child {
        padding: 0 0 22px 27.5px;
      }
    }
    &.pick {
      .info_txt {
        background-color: #15b7dd;
      }
      .title {
        .title_txt {
          font-size: 14.5px;
          color: #15b7dd;
        }
      }
      .span {
        color: #333333;
      }
    }
  }
  // 查看更多
  .con_more {
    text-align: center;
    font-size: 11px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #999999;
    .more_txt {
      vertical-align: middle;
      &.pick {
        color: #15b7dd;
      }
    }
    .more_icon {
      margin-left: 5.5px;
      vertical-align: middle;
    }
  }
}

.titles {
  position: relative;
  margin-bottom: 5px;
  font-size: 11px;
  font-family: PingFangMedium;
  font-weight: 500;
  color: #666666;
	// left: -15px;

  .title_txt {
    display: inline-block;
    width: 56px;
    font-size: 12.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
  }
  .title_time {
    font-size: 11px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #666666;
  }
  .title_info {
    font-size: 11px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #15b7dd;
    // margin-right: 10px;
   
  }
  .title_name {
    font-weight: 400;
    flex: 1;
  }
  .info_logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    z-index: 2;
		margin-right: 5px;
  }
}
.icons{
	left: 8px;
}
</style>
