export default{
	methods: {
		// 计算当前容器宽度
		FunThisWidth(){
			this.$nextTick(()=>{
				this.thisWidth = 0
				// uni.createSelectorQuery().in(this).select('').boundingClientRect(data => {
				// 	this.thisWidth = data.width
				// }).exec();
				this.thisWidth = document.getElementById('LoopTab').clientWidth
				this.FunTotalWidth()
			})
		},
		
		// 计算总宽度
		FunTotalWidth(){
			this.$nextTick(()=>{
				this.TotalWidth = 0
				this.AllList = []
				this.dataList.forEach((item,index)=>{
					const thisWidth = document.getElementsByClassName('LoopTabSlide'+index)[0].clientWidth
					let data = new Object()
					data.leftMinPx = this.TotalWidth
					data.width = thisWidth
					this.TotalWidth += thisWidth
					data.leftMaxPx = this.TotalWidth
					this.AllList.push(data)
				})
				this.FunInfoOffset()
			})
		},
		
		// 计算第一次的偏移量
		FunInfoOffset(){
			// 中位数的坐标
			let offsetIndex = this.dataList.length / 2
			offsetIndex = Math.floor(offsetIndex) - 1
			// this.LoopTabOffset = ~ this.AllList[offsetIndex].right - -1
			// this.LoopTabOffset = this.LoopTabOffset + (this.thisWidth - this.AllList[offsetIndex].width) / 2
			// this.CenterIndex = offsetIndex + 1
			// this.$emit('change', this.CenterIndex - (this.AllList.length / 3))
			this.CenterIndex = offsetIndex
			this.ResetImmediately()
		}
	}
}