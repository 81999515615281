<template>
  <div>
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="作业详情"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <!-- tab -->
    <van-tabs color="#F7F7F9" background="#F7F7F9" v-model="tabIndex" v-if="historyDate && historyDate.length">
      <van-tab
        v-for="(item, index) in tabList"
        :title="item.name"
        :key="index"
      >
      </van-tab>
    </van-tabs>

    <div v-show="tabIndex == 0" :style="{marginTop: historyDate && historyDate.length?'0':'10px'}">
      <!-- 作业详情 -->
      <div class="homeWorkDetail" :class="{heightFlag: heightFlag}">
        <div class="workName">
          <img src="@/assets/image/task/submitTitle.png" alt="">
          <p>{{date.homeworkTitle}}</p>
          <span v-if="date.status != 0 && date.status != 1 && !heightFlag" @click="heightFlag = true">收起<van-icon name="arrow-up" /></span>
          <span v-if="date.status != 0 && date.status != 1 && heightFlag" @click="heightFlag = false">详情<van-icon name="arrow-down" /></span>
        </div>
        <div class="title">
          <img src="@/assets/image/task/detailIcon.png" alt="">
          <p>详情：</p>
        </div>
        <div class="libraryContent">
          <!-- <p class="warn">请根据给出的以下目标，编写教案。</p> -->
          <div class="content" v-html="date.libraryContent"></div>
        </div>
        <div class="title" v-show="date.attachment">
          <img src="@/assets/image/task/filesIcon.png" alt="">
          <p>附件：</p>
        </div>

        <!-- 文件列表 -->
        <div class="files">
          <div class="file" v-for="(item,index) in date.attachment" :key="index">
            <!-- fileIcon -->
            <img src="@/assets/image/task/fileIcon.png" v-if="isworkfileType(item.type,'file')" alt="">
            <!-- wordIcon -->
            <!-- <img src="@/assets/image/task/wordIcon.png" v-if="isfileType(item.url,'word')" alt=""> -->
            <!-- videoIcon -->
            <img src="@/assets/image/task/videoIcon.png" v-if="isworkfileType(item.type,'mp4')" alt="">
            <!-- txt -->
            <!-- <img src="@/assets/image/task/txtIcon.png" v-if="isfileType(item.url,'txt')" alt=""> -->
            <!-- pdf -->
            <!-- <img src="@/assets/image/task/pdfIcon.png" v-if="isfileType(item.url,'pdf')" alt=""> -->
            <!-- 图片 -->
            <img :src="item.url" v-if="isworkfileType(item.type,'img')" alt="">
            <p class="fileName">{{item.name}}</p>
            <div class="Lookbtn" @click="openworkFile(item)">
              <p>点击查看</p>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>

        <!-- 提交说明 -->
        <div class="answerNoteTitle">
          <img src="@/assets/image/task/answerNote.png" alt="">
          <p>提交说明</p>
        </div>
        <div class="answerNote" v-html="date.answerNote"></div>
      </div>

      <!-- 提交的作业 -->
      <div class="submited" v-if="date.status != 0 && date.status != 1">
        <img class="hwStatus" v-if="date.status == 3 || date.status == 7" src="@/assets/image/task/statusDetail1.png" alt="">
        <img class="hwStatus" v-if="date.status == 5" src="@/assets/image/task/statusDetail2.png" alt="">
        <img class="hwStatus" v-if="date.status == 8" src="@/assets/image/task/statusDetail3.png" alt="">
        <div class="title">
          <img src="@/assets/image/task/myHomeWork.png" alt="">
          <p>我的作业</p>
        </div>
        <p class="testBox">{{date.answerContent}}</p>
        <div class="hwLine"></div>
        <div class="files">
          <div class="file" @click="openFile(item)" v-for="(item,index) in date.attachlist" :key="index">
            <!-- fileIcon -->
            <img src="@/assets/image/task/fileIcon.png" v-if="isfileType(item.type,'file')" alt="">
            <!-- wordIcon -->
            <!-- <img src="@/assets/image/task/wordIcon.png" v-if="isfileType(item.url,'word')" alt=""> -->
            <!-- videoIcon -->
            <img src="@/assets/image/task/videoIcon.png" v-if="isfileType(item.type,'mp4')" alt="">
            <!-- txt -->
            <!-- <img src="@/assets/image/task/txtIcon.png" v-if="isfileType(item.url,'txt')" alt=""> -->
            <!-- pdf -->
            <!-- <img src="@/assets/image/task/pdfIcon.png" v-if="isfileType(item.url,'pdf')" alt=""> -->
            <!-- 图片 -->
            <img :src="item.url" v-if="isfileType(item.type,'img')" alt="">
            <p v-if="!isfileType(item.type,'mp4') && !isfileType(item.type,'img')">{{item.name}}</p>
          </div>
        </div>
        <div class="datebox">
          <div class="line"></div>
          <div class="time">
            <img width="9" src="@/assets/image/task/task_icon8.png" alt="" />
            作业上传时间：{{endTime(date.submitTime)}}
          </div>
        </div>
      </div>

      <!-- 驳回原因 -->
      <div class="submited" v-if="date.status == 5">
        <div class="title">
          <img src="@/assets/image/task/reject.png" alt="">
          <p>驳回原因</p>
        </div>
        <p class="testBox">{{date.reply}}</p>
      </div>

      <!-- 批阅信息 -->
      <div class="submited" v-if="date.status == 8">
        <div class="title">
          <img src="@/assets/image/task/looked.png" alt="">
          <p>教师批阅</p>
        </div>
        <p class="scord">评分：{{date.score}}</p>
        <div class="hwLine"></div>
        <p class="testBox">评语：{{date.reply}}</p>
      </div>

      <!-- 占位盒子 -->
      <div class="box" v-if="date.status == 0 || date.status == 1 || date.status == 5"></div>

      <!-- 底部操作栏 -->
      <div class="footBtn" v-if="date.status == 0 || date.status == 1 || date.status == 5">
        <div class="elseTime" v-if="!date.overdue">
          <div class="time">
            <img src="@/assets/image/task/task_icon8.png" alt="">
            <p>{{!btnShow(date.answerStartdate)?'距离提交还有：':'提交剩余时间：'}}</p>
          </div>
          <div class="inter">
            <p class="value">{{day}}</p>
            <p class="type">天</p>
            <p class="value">{{hour}}</p>
            <p class="type">时</p>
            <p class="value">{{min}}</p>
            <p class="type">分</p>
            <p class="value">{{s}}</p>
            <p class="type">秒</p>
          </div>
        </div>
        <div class="overDue" v-else>
          <img src="@/assets/image/task/overdue.png" alt="">
          <p>提交结束，若有问题请联系教务老师</p>
        </div>
        <div class="all-btn-bigCircle" :class="{overdueBtn: date.overdue || !btnShow(date.answerStartdate)}" @click="submit">{{date.status == 5?'重新提交':'提交作业'}}</div>
      </div>
    </div>

    <div v-show="tabIndex == 1">
      <div class="history" v-for="(item,index) in historyDate" :key="index">
        <div class="back"></div>
        <div class="left">
          <img src="@/assets/image/task/timeEnd.png" alt="">
          <div v-if="index != historyDate.length-1" class="historyLine"></div>
        </div>
        <div class="content">
          <div class="timeStatus">
            <p class="time">{{item.createTime}}</p>
            <p class="status">{{item.status == 1?'已提交':item.status == 2?'已驳回':item.status == 3?'已批阅':''}}</p>
          </div>

          <!-- 作业内容 -->
          <!-- 1-已提交 2-已驳回 3-已批阅 -->
          <template v-if="item.status == 1">
            <div class="homeWorkText">
              {{item.answerContent}}
            </div>
            <div class="homeWorkLine"></div>
            <div class="homeWorkFile">
              <div class="file" @click="openFile(child)" v-for="(child,index) in item.attachlist" :key="index">
                <!-- fileIcon -->
                <img src="@/assets/image/task/fileIcon.png" v-if="isfileType(child.type,'file')" alt="">
                <!-- wordIcon -->
                <!-- <img src="@/assets/image/task/wordIcon.png" v-if="isfileType(child.url,'word')" alt=""> -->
                <!-- videoIcon -->
                <img src="@/assets/image/task/videoIcon.png" v-if="isfileType(child.type,'mp4')" alt="">
                <!-- txt -->
                <!-- <img src="@/assets/image/task/txtIcon.png" v-if="isfileType(child.url,'txt')" alt=""> -->
                <!-- pdf -->
                <!-- <img src="@/assets/image/task/pdfIcon.png" v-if="isfileType(child.url,'pdf')" alt=""> -->
                <!-- 图片 -->
                <img :src="child.url" v-if="isfileType(child.type,'img')" alt="">
                <p v-if="!isfileType(child.type,'mp4') && !isfileType(child.type,'img')">{{child.name}}</p>
              </div>
            </div>
          </template>

          <!-- 已驳回 -->
          <template v-if="item.status == 2">
            <div class="rejectText">驳回原因：{{item.reply}}</div>
          </template>

          <!-- 已批阅 -->
          <template v-if="item.status == 3">
            <div class="grade">评分：{{item.score ? item.score : '-'}}</div>
            <div class="evaluate">评语：{{item.reply ? item.reply : '-'}}</div>
          </template>
        </div>
      </div>
    </div>

    <!-- 视频播放组件 -->
    <div v-if="showVideoModal">
      <videoModal :show="showVideoModal" :src="fileSrc" @closeModel="closeModel" />
    </div>

    <!-- 视频播放组件 -->
    <div v-if="showImgModal">
      <imgPeiviewModal :show="showImgModal" :src="fileSrc" @closeModel="closeModel" />
    </div>
  </div>
</template>

<script>
import videoModal from "@/components/videoModal.vue"
import imgPeiviewModal from "@/components/imgPeiviewModal.vue"
export default {
  components: { videoModal,imgPeiviewModal },
  data() {
    return {
      showVideoModal: false,
      showImgModal: false,
      fileSrc: null,
      day: null,
      hour: null,
      min: null,
      s: null,
      Interval: null,
      time: 0,
      date: {},
      historyDate: null,
      heightFlag: false,
      answerId: null,
      tabIndex: 0,
      tabList: [
        {
          name: '详情'
        },
        {
          name: '批阅'
        }
      ]
    }
  },
  created() {
    this.answerId = this.$route.query.id;
    if(this.$route.query.type){
      this.tabIndex = this.$route.query.type*1;
    }
    this.getHomeWorkData();
  },
  watch: {
    time(newVal,oldVal){
			const time = newVal;
      let d = parseInt(time / 1000 / 3600 / 24);
      if (d < 10) {
        d = "0" + d;
      }
      let h = parseInt((time / 1000 / 60 / 60) % 24);
      if (h < 10) {
        h = "0" + h;
      }
      let m = parseInt((time / 1000 / 60) % 60);
      if (m < 10) {
        m = "0" + m;
      }
      let s = parseInt((time / 1000) % 60);
      if (s < 10) {
        s = "0" + s;
      }
      this.day = d;
      this.hour = h;
      this.min = m;
      this.s = s;
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(this.fileSrc)
  //   if (this.fileSrc) {
  //     return
  //   }
  //   next()
  // },

  methods: {
    closeModel(e) {
      this.showVideoModal = false;
      this.showImgModal = false;
      this.fileSrc = '';
    },
    // 打开文件
    openFile(e) {
      if(this.isfileType(e.type,'file')){
        return this.$router.push({
          path: "/knowledge/specialAreaCon?name=" + e.name + "&url=" + e.url,
        });
      }
      if(this.isfileType(e.type,'mp4')) {
        this.showVideoModal = true;
        return this.fileSrc = e.url;
      }
      else if(this.isfileType(e.type,'img')) {
        this.showImgModal = true;
        return this.fileSrc = e.url;
      }
      window.open(e.url);
    },
    openworkFile(e) {
      if(this.isfileType(e.type,'file')){
        return this.$router.push({
          path: "/knowledge/specialAreaCon?name=" + e.name + "&url=" + e.url,
        });
      }
      if(this.isfileType(e.type,'mp4')) {
        this.showVideoModal = true;
        return this.fileSrc = e.url;
      }
      else if(this.isfileType(e.type,'img')) {
        this.showImgModal = true;
        return this.fileSrc = e.url;
      }
      window.open(e.url);
    },
    // 返回是否符合
    isfileType(e,type) {
      if(type == 'file'){
        if(e == 3) {
          return true;
        }
        return false;
      }else if(type == 'img') {
        if(e == 1) {
          return true;
        }
        return false;
      }else if(type == 'mp4') {
        if(e == 2) {
          return true;
        }
        return false;
      }
    },
    isworkfileType(e,type) {
      if(type == 'file'){
        if(e == 1 || e == 3) {
          return true;
        }
        return false;
      }else if(type == 'img') {
        if(e == 2) {
          return true;
        }
        return false;
      }else if(type == 'mp4') {
        if(e == 4) {
          return true;
        }
        return false;
      }
    },
    submit() {
      if(this.date.overdue || !this.btnShow(this.date.answerStartdate)) {
        return 
      }
      this.$router.push('/task/submitHomeWork?id='+this.answerId)
    },

    changeTab(e) {
      console.log(e)
    },

    // 时间处理
    endTime(e) {
      if(!e){
        return '';
      }
      let str = e.split('T').join(' ');
      str = str.substring(0,16);
      return str;
    },
    // 获取详情数据
    getHomeWorkData() {
      this.$ajax({
        url: '/hxclass-mobile/homework/userAnswer/details',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.date = res.data;
          if(res.data.status != 0 && res.data.status != 1){
            this.heightFlag = true;
          }
          if(res.data.attachment){
            this.date.attachment = JSON.parse(res.data.attachment);
          }
          if(res.data.attachlist){
            this.date.attachlist = JSON.parse(res.data.attachlist);
          }
          
          // 开始提交作业且未到结束时间
          if(!res.data.overdue && this.btnShow(res.data.answerStartdate) && (res.data.status == 0 || res.data.status == 1 || res.data.status == 5)) {
            this.time = new Date((res.data.answerEnddate).replace(/-/g,'/')).getTime() - new Date().getTime();
            this.Interval = setInterval(()=>{
              if(this.time <= 0) {
                clearInterval(this.Interval);
                this.getHomeWorkData();
              }
              this.time -= 1000;
            },1000)
          }
          
          // 未到开始时间
          if(!this.btnShow(res.data.answerStartdate)){
            this.time = new Date((res.data.answerStartdate).replace(/-/g,'/')).getTime() - new Date().getTime();
            this.Interval = setInterval(()=>{
              if(this.time <= 0) {
                clearInterval(this.Interval);
                this.getHomeWorkData();
              }
              this.time -= 1000;
            },1000)
          }
        }else {
          this.$alert(res.message);
        }
        this.getHistoryData();
      })
    },

    // 是否展示提交按钮
    btnShow(e) {
      if(new Date(e).getTime() > new Date().getTime()) {
        return false;
      }
      return true;
    },

    // 获取历史数据
    getHistoryData() {
      this.$ajax({
        url: '/hxclass-mobile/homework/history/list',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          // 过滤显示已提交和已驳回的数据，已批阅需满足成绩发布展示
          let arry = []
          arry = res.data.filter(item=>{
            return item.status != 3 || (item.status == 3 && this.date.status == 8);
          })
          arry.forEach(element => {
            if(element.attachlist){
              element.attachlist = JSON.parse(element.attachlist)
            } else {
              element.attachlist = []
            }
          });
          this.historyDate = arry;
          }else {
          this.$alert(res.message);
        }
      })
    }
  },
  beforeDestroy(){
    clearInterval(this.Interval);
  }
};
</script>

<style lang="less" scoped>
/deep/.van-tab{
  // max-width: 90px;
  font-size: 14px;
  font-family: PingFangMedium;
  font-weight: 400;
  color: #666666;
  line-height: 27px;
}
/deep/.van-tabs__wrap {
  margin: 0 20%;
  height: 55px;
}
/deep/.van-tab--active {
  font-size: 16px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #333333;
  line-height: 27px;
  background-image: url(~@/assets/image/examination/certificate_icon1.png);
  background-size: 41px 6px;
  background-repeat: no-repeat;
  background-position: 50% 33px;
}
/deep/.van-tabs__line {
  z-index: 0;
}
.overDue{
  display: flex;
  align-items: center;
  background: #F7F7F9;
  border-radius: 4px;
  padding: 0 5px;
  img{
    width: 14px !important;
    height: 14px !important;
    margin-right: 2px !important;
  }
  p{
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
  }
}
.overdueBtn{
  background: #A1E2F1;
}
.heightFlag{
  height: 35px;
}
.homeWorkDetail{
  padding-bottom: 14px;
  margin: 0 15px;
  background: #FFFFFF;
  box-shadow: 3px 0px 5px 0px rgba(93,131,167,0.05);
  border-radius: 10px;
  overflow: hidden;
  .files{
    margin: 12px 15px 0;
    background: #FCFBFC;
    border-radius: 5px;
    .file{
      border-bottom: 1px solid #EFEFEF;
      height: 52px;
      margin: 0 12px;
      display: flex;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
      .fileName{
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .Lookbtn{
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #15B7DD;
      }
    }
    .file:last-child{
      border: 0;
    }
  }
  .workName{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    background: #FCFBFC;
    img{
      width: 13px;
      height: 15px;
      margin-right: 6px;
    }
    p{
      flex: 1;
      font-size: 17px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    span{
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #15B7DD;
      line-height: 20px;
      .van-icon{
        margin-left: 3px;
      }
    }
  }
  .title{
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-left: 15px;
    img{
      width: 13px;
      height: 15px;
      margin-right: 4px;
    }
    p{
      font-size: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 23px;
    }
  }
  .libraryContent{
    margin: 12px 15px 0;
    padding: 12px ;
    background: #FCFBFC;
    border-radius: 5px;
    .warn{
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .content{
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-top: 6px;
      /deep/img{
        width: 100% !important;
      }
    }
  }
  .answerNoteTitle{
    display: flex;
    align-items: center;
    height: 48px;
    margin: 12px 15px 0;
    border-top: 1px solid #EFEFEF;
    img{
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
    p{
      font-size: 17px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
  }
  .answerNote{
    margin: 0 15px;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 23px;
    /deep/img{
      width: 100% !important;
    }
  }
}
.submited{
  margin: 10px 15px;
  padding: 12px 15px 14px;
  background: #FFFFFF;
  box-shadow: 3px 0px 5px 0px rgba(93,131,167,0.05);
  border-radius: 10px;
  position: relative;
  .hwStatus{
    position: absolute;
    right: -15px;
    top: -15px;
    width: 88px;
    height: 88px;
  }
  .title{
    display: flex;
    align-items: center;
    img{
      width: 13px;
      height: 15px;
      margin-right: 6px;
      margin-bottom: 1px;
    }
    p{
      font-size: 17px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
  }
  .scord{
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 21px;
    margin-top: 10px;
  }
  .testBox{
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 23px;
    margin-top: 10px;
  }
  .hwLine{
    height: 1px;
    background: #EFEFEF;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .files{
    .file{
      display: flex;
      align-items: center;
      margin-top: 10px;
      img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      p{
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    .file:first-child{
      margin-top: 0;
    }
  }
  .datebox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .line {
      // width: 40%;
      flex: 1;
      height: 1px;
      background: #efefef;
    }
    .time {
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      margin-left: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
}
.box{
  margin-top: 80px;
}
.footBtn{
  width: 100vw;
  padding: 11px 15px;
  box-sizing: border-box;
  background: #ccc;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  box-shadow: 0px -1px 1px 1px rgba(153,153,153,0.3);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255,255,255,1);
  box-shadow: 0px -2px 2px 0px rgba(0,0,0,0.04);
  .elseTime{
    .time{
      display: flex;
      align-items: center;
    }
    .inter{
      margin-top: 4px;
      display: flex;
      align-items: center;
      .type{
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 10px;
      }
      .value{
        padding: 8px 6px;
        background: #F7F7F7;
        border-radius: 5px 5px 5px 5px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #F90000;
        line-height: 14px;
        letter-spacing: 2px;
        margin-right: 4px;
        margin-left: 4px;
      }
      .value:first-child{
        margin-left: 0;
      }
    }
  }
  img{
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }
  p{
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .all-btn-bigCircle{
    min-width: 100px;
    flex: 1;
    margin-left: 14px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}

.history{
  display: flex;
  padding: 0 32px 0 28px;
  position: relative;
  .back{
    position: absolute;
    left: 25px;
    top: 10px;
    width: 32px;
    height: 22px;
    background: red;
    border-radius: 11px 0 0 11px;
    z-index: -1;
    background: #FCFBFC;
  }
  .left{
    display: flex;
    flex-direction: column;
    padding-top: 13px;
    img{
      width: 16px;
      height: 16px;
      margin-bottom: 14px;
    }
    .historyLine{
      flex: 1;
      margin-left: 7px;
      width: 1px;
      background-image: url(~@/assets/image/task/line.png);
      background-size: 100% auto;
    }
  }
  .content{
    flex: 1;
    background: #FCFBFC;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 13px;
    padding: 10px 10px 12px;
    .homeWorkLine{
      height: 1px;
      background: #EFEFEF;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .timeStatus{
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .time{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-right: 14px;
      }
      .status{
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 23px;
      }
    }
    .homeWorkText{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    .homeWorkFile{
      display: flex;
      flex-wrap: wrap;
      .file{
        display: flex;
        align-items: center;
        margin-right: 13px;
        margin-bottom: 10px;
        img{
          width: 40px;
          height: 40px;
        }
        p{
          margin-left: 10px;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
    .rejectText{
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
    .grade{
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
    }
    .evaluate{
      margin-top: 21px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

.history:last-child{
  .content {
    margin-bottom: 0;
  }
} 
</style>