<template>
  <div class="e-book-wrap" :style="{background: viewBackground}">
    
    
    <!-- 电子书渲染 -->
    <div id="viewer" class="epub-viewer" @click="show_edit= !show_edit"></div>
    <!-- 底部功能栏 -->
    
    <!-- 笔记弹窗 -->
    
    

  </div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
import {FunCloseControl,FunCatalogueList,FunBookShow,FunGetNote,FunGetProgress,toast} from "@/assets/js/NativeWebKit.js"
import ePub from 'epubjs';
import { goLoginPage } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {nTransition},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      // 笔记id
      id:0,
      // 指导图显隐
      guideShow: false,
      // 电子书加载状态
      loading: false,
      // 电子书实例
      rendition: null,
      // epub电子书
      book: null,
      // 电子书样式
      themes: null,
      // 控制功能栏显隐
      show_edit: false,
      // 控制目录菜单显隐
      show_menu: false,
      // 是否开启自动翻页
      turnRadio: "0",
      // 自动翻页间隔
      turnSeconds: 10,
      // 自动翻页计时器
      incrementTimer: null,
      // 是否夜间模式
      isNight: false,
      // 最后一次批注位置
      lastcfiRange:'',
      // 最后一次选中的内容
      lastChoosed:'',
      // 最后一次选中的内容详情
      lastRangeContents: {},
      // 存放批注内容
      uniqueEntryPositions: [],
      // 笔记文本内容
      annotationContent: '',
      //原生获取笔记内容
      appNoteContent:{},
      // 切换功能
      tabsList: [
        { name:'目录', icon: require('./icon/icon_caidan.png') },
        { name:'夜间', icon: require('./icon/icon_yejian.png') },
        { name:'主题', icon: require('./icon/icon_zhuti.png') },
        { name:'阅读设置', icon: require('./icon/icon_shezhi.png') },
      ],
      // 功能下标
      tabsIndex: null,
      // 菜单/笔记下标
      menusIndex: 0,
      // 字号大小
      defaultFontSize: 16,
      // 主题色
      themeList: [
        {
          name: 'yellow',  // 主题色名称
          itemBg: '#f5e8c6',  // 主题色按钮背景  值应与style下的background相同
          style: { // 主题色样式
            body: {
              'color': '#301d00', 'background': '#f5e8c6'
            }
          }
        },
        {
          name: 'green',
          itemBg: '#d1e3d0',
          style: {
            body: {
              'color': '#333333', 'background': '#cbdbc0',
            }
          }
        },
        {
          name: 'eye', // 护眼模式要放到数组最后
          itemBg: '#242424',
          style: {
            body: {
              'color': '#868c8f', 'background': '#242424',
            }
          }
        },
      ],
      // 目录主题色
      themeMenu: {},
      // 默认主题设置
      defaultTheme: 0,
      
      locations:{},
      // 目录
      toc: [],
      // 阅读进度
      progress: 0,

      isAppLoad: true, // 加载loading
      viewBackground: '', // 背景色
    }
  },
  // 事件处理器
  methods: {
    // 生成电子书
    initializeEpub(){
      // 电子书文件地址
      // const bookPath = 'https://cos.hxclass.cn/test/user/homework/20230803/369b77de7806402d92620f7f7decc8dc.epub';
      // const bookPath = 'https://cos.hxclass.cn/test/user/homework/20230803/人类群星闪耀时1.epub';
      const bookPath = this.$route.query.url

      // 利用epubjs解析文件
      this.book = ePub(bookPath);

      let options = {
        flow: "scrolled-doc",  // paginated   翻页方式   scrolled-doc：上下滚动   paginated：左右分页
        manager: "continuous",
        width: '100vw',  // 设置阅读板块宽度
        height: 'calc(100vh - 20px)', // 设置阅读板块高度
        snap: true,
        spreads: true,
        stylesheet: '/iframe.css',
        allowScriptedContent: true,
        restore: false,
        spread: "none",
      }
      
      // 解决ios展示部署后错误问题
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        options['allowScriptedContent'] = true;
      }
      
      // 挂载渲染电子书
      this.rendition = this.book.renderTo('viewer', options);

      // 内容样式
      this.themes = this.rendition.themes;
      this.rendition.display();
      
      // 添加选中事件监听器
      this.rendition.on("selected", (cfiRange, contents) => {
        let range = contents.window.getSelection().getRangeAt(0);
        this.lastRangeContents = range;
        this.lastcfiRange = cfiRange;
      });

      // 添加电子书写入事件
      this.rendition.on("rendered", async () => {
        const cfiList = this.uniqueEntryPositions; //位置数组
        cfiList.forEach((cfi) => {
          const marker = document.createElement("span");
          // marker.style.backgroundColor = "yellow"; // 设置高亮颜色
          marker.style.textDecoration = "underline"; // 设置下划线
          marker.style.textDecorationStyle = (cfi.annotationType == 1 ? "solid" : "wavy"); // 设置下划线样式  // solid单行  double双线  dotted点线  dashed虚线  wavy波浪线  
          marker.style.textDecorationColor = "#E34442"; // 设置下划线颜色
          // marker.style.lineHeight = "1.5"; // 设置行高为1.5倍
          marker.classList.add("my-marker");

          const range = this.rendition.getRange(cfi.local); //这是cfi位置
          if (range) {
            range.surroundContents(marker);
          }
        });
      });

      //图书解析完毕回调
      this.book.ready
        .then(() => {
          this.isAppLoad = false
          this.loading = true
          FunBookShow(this.loading);
          // 生成目录
          this.toc = this.flatTreeAndSetLevel(this.book.navigation.toc);
          FunCatalogueList(JSON.stringify(this.flatTreeAndSetLevel(this.book.navigation.toc)));
          // console.log(this.flatTreeAndSetLevel(this.book.navigation.toc));
          // 生成Locations对象
          let generate = this.book.locations.generate();
          return generate;
        })
        .then((result) => {
          // 保存locations对象
          this.locations = this.book.locations;
          this.loading = false
          
          // 当重新加载或打开书籍时，设置初始位置
          var lastLocation = localStorage.getItem("bookLocation"+this.$route.query.id);
         if (lastLocation) {
          var location=JSON.parse(lastLocation);
          var cfi = location.start.cfi       //cfi记录当前页面起始位置

          var percentage = this.locations.percentageFromCfi(cfi)     
          //percentage记录当前页面在全书的位置，cfi与percentage一一对应
          //配合rendition.display()可以在书籍渲染时显示指定的位置，
          this.rendition.display(percentage)
          };
          FunBookShow(this.loading);
          
        });

      // 设置默认字体
      this.defaultFontSize = this.$route.query.fonts?this.$route.query.fonts:16;
      this.setFontSize(this.defaultFontSize);
      //初始化主题
      this.registerTheme();
      // 设置默认主题
      this.defaultTheme = this.$route.query.defaultTheme?this.$route.query.defaultTheme:0;
      this.setTheme(this.defaultTheme);
      //设置默认是否翻页
      this.turnRadio = this.$route.query.turnRadio?this.$route.query.turnRadio:0;
      this.onOpenTrunPage(this.turnRadio)
      
      // 监听手指触摸开始
      this.rendition.on('touchstart', (event) => {
        this.touchStartX = event.changedTouches[0].clientX;
        this.touchStartY = event.changedTouches[0].clientY;
        this.touchTime = event.timeStamp;
      });

      // 监听手指触摸结束
      this.rendition.on('touchend', (event) => {
        if(this.guideShow){
          this.guideShow = false
          localStorage.setItem('storage_ebook', 'yes')
        }
        FunBookShow(this.loading);
        if(this.loading)return
        const offsetX = event.changedTouches[0].clientX - this.touchStartX; //手指左右滑动偏移量
        const offsetY = event.changedTouches[0].clientY - this.touchStartY; //手指上下滑动偏移量
        const time = event.timeStamp - this.touchTime; //手指滑动时间
        if(offsetY !== 0){
          this.showProgress() 
          this.show_edit = false
        }
        if (time < 500 && offsetX > 40) { //滑动时间小于500ms 滑动距离大于40,向上翻页
          // this.prevPage();
          this.show_edit = false
          FunCloseControl(this.show_edit);
        } else if (time < 500 && offsetX < -40) { //滑动时间小于500ms 滑动距离大于40,向下翻页
          // this.nextPage();
          this.show_edit = false
          FunCloseControl(this.show_edit);
        } else if (offsetX == 0) {  //滑动距离为0,视屏幕点击
          if(!this.show_menu){ // 目录打开状态下不做功能栏弹出处理
            if(this.show_edit === 'false') {
              this.show_edit = false;
            }
            this.show_edit = !this.show_edit
            FunCloseControl(this.show_edit);
          }
          // 关闭目录抽屉
          this.show_menu = false
        }
        event.passive = false
        event.stopPropagation() //阻止事件传播
      });
    },

    // 功能切换
    onTabsClick(index){
      // 0.菜单
      if(index == 0 || index == 5){
        // 目录调起
        this.show_menu = true
        // 功能栏隐藏
        this.show_edit = false

        // 暂时写法
        this.menusIndex = (index == 5 ? 1 : 0)
        return
      }
      // 1.夜间
      if(index == 1){
        this.isNight = !this.isNight
      }
      this.tabsIndex = index
    },

    // 将目录树形结构扁平化，处理为同级
    flatTreeAndSetLevel(tree, level = 1) {
      const list = []
      tree.forEach(item => {
        const o = JSON.parse(JSON.stringify(item))
        if(o.subitems) delete o.subitems
        o.level = level
        list.push(o)
        if(item.subitems && item.subitems.length) {
          list.push(...this.flatTreeAndSetLevel(item.subitems, level + 1))
        }
      })
      return list
    },

    // 下一页
    nextPage() {
      this.rendition.next().then(() => {
        this.showProgress() 
      })
    },
    // 上一页
    prevPage() {
      this.rendition.prev().then(() => {
        this.showProgress() 
      })
    },

    // 改变翻页速度
    onCtrlSpeed(type){
      if(type == '+'){
        if(this.turnSeconds == 100) return  // 字号大于40禁用
        this.turnSeconds ++
      } else if (type == '-'){
        if(this.turnSeconds == 1) return  // 字号小于12禁用
        this.turnSeconds --
      }

      // 重置计时器
      clearInterval(this.incrementTimer);
      this.onOpenTrunPage(1)
    },

    // 是否开启自动翻页
    onOpenTrunPage(e){
      if(e == 1){
        this.incrementTimer = setInterval(() => {
          this.nextPage()
        }, this.turnSeconds * 1000);
      } else {
        // 关闭自动翻页计时器
        clearInterval(this.incrementTimer);
      }
    },

  
    // 根据目录跳转指定章节
    goTo(location) {
      this.rendition.display(location);
      this.show_menu = false

      // 更新进度
      this.$nextTick(()=>{
        this.showProgress();
      })
    },

    // 进度条跳转更新
    showProgress(){
      var currentLocation = this.rendition.currentLocation();
      //var progress = Math.floor(((this.locations.percentageFromCfi(currentLocation.start.cfi)).toFixed(5)) * 10000) / 100;
      var progress = Math.floor(((this.locations.percentageFromCfi(currentLocation.start.cfi))) * 10000) / 100;
      this.progress = progress;
      FunGetProgress(this.progress);
    },

    // 改变字号大小
    onCtrlSize(type){
      if(type == '+'){
        if(this.defaultFontSize == 40) return  // 字号大于40禁用
        this.defaultFontSize ++
      } else if (type == '-'){
        if(this.defaultFontSize == 12) return  // 字号小于12禁用
        this.defaultFontSize --
      }
      this.setFontSize(this.defaultFontSize)
    },

    // 设置字体大小
    setFontSize(fontSize) {
      if (this.themes) {
        this.themes.fontSize(fontSize + 'px')
      }
    },

    //注册背景色（初始化主题）
    registerTheme() {
      this.themeList.forEach((theme) => {
        this.themes.register(theme.name, theme.style);
      });
    },

    //设置背景色
    setTheme(index) {
      this.defaultTheme = index
      this.viewBackground = this.themeList[index].style.body.background;
      this.themes.select(this.themeList[index].name);

      // 同步目录主题色
      this.themeMenu = this.themeList[index].style.body
      // 夜间模式关闭初始
      this.isNight = false
    },

    // 拖动进度条换章节
    progressChange(progress){
      const percentage = progress / 100
      const location = percentage > 0 ? this.locations.cfiFromPercentage(percentage) : 0
      this.rendition.display(location)
      
    },

    // 点击跳转到指定位置
    jumpTo(href) {
      // 收起目录
      this.show_menu = false;
      this.rendition.display(href).then(() => {
        this.showProgress()
      })
    },

    // 插入批注
    addAnnotation(type){
      if (this.lastRangeContents.toString() != this.lastChoosed) {
        // 创建标记
        let marker = document.createElement("span");
        // marker.style.backgroundColor = "yellow"; // 设置高亮颜色
        marker.style.textDecoration = "underline"; // 设置下划线
        marker.style.textDecorationStyle = (type == 1 ? "solid" : "wavy"); // 设置下划线样式
        marker.style.textDecorationColor = "#E34442"; // 设置下划线颜色
        marker.style.lineHeight = "1.5"; // 设置行高为1.5倍
        marker.classList.add("highlight"); // 可选，添加自定义类名

        // 将选中范围用标记包裹起来
        if (this.lastRangeContents) {
          const PromiseAll = async () => {
            try {
              // 执行代码
              this.lastRangeContents.surroundContents(marker);
            } catch (error) {
              // 抛出异常
              throw new Error()
            }
          }

          PromiseAll().then(() => {
            // 函数执行成功
            // 记录最后一次选中内容
            this.lastChoosed = this.lastRangeContents.toString()

            // type 1.下划线  2.笔记
            this.addNoteData(type)
            if(type == 1){
            } else if (type == 2){
			        // this.$refs['trainPop'].show()
            }
          }).catch(() => {
            // 函数执行失败
            this.$alert('文本选取失败，请重新选择');
          });
        }
      }
    },

    // 笔记列表
    getNoteList(){
      this.$ajax({
        url: "/hxclass-mobile/cms-article/anntotions/list",
        method: "get",
        params:{
					articleId: this.$route.query.id,
					userId: this.$store.state.userInfo.userId,
				}
      }).then((res) => {
        if (res) {
          if(res.code == '401') {
            this.isAppLoad = false
            goLoginPage();
          }else{
            this.uniqueEntryPositions = res
            // console.log("笔记---"+JSON.stringify(this.uniqueEntryPositions));
          }
        }
      });
    },

    // 删除笔记
    delNoteList(id, index){
      this.$ajax({
        url: "/hxclass-mobile/cms-article/anntotions/del?id=" + id + '&userId=' + this.$store.state.userInfo.userId,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.uniqueEntryPositions.splice(index, 1)
        }
      });
    },

    // 编辑笔记
    editNOte(type,txt,id){
      this.$ajax({
        url: "/hxclass-mobile/cms-article/anntotions/edit",
        method: "put",
        params:{
					id: id,
					userId: this.$store.state.userInfo.userId,
          annotationContent: txt,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(type == 2){
            this.annotationContent = ''
            this.$refs['trainPop'].hide()
            this.getNoteList()
          }
        }
      });
    },

    // 增加笔记
    addNoteData(type){

      this.appNoteContent.annotationContent='';// 批注内容
      this.appNoteContent.annotationType=type;// 	批注类型 1.下划线 2.批注
      this.appNoteContent.articleId=this.$route.query.id;
      this.appNoteContent.local=this.lastcfiRange;// 文档位置
      this.appNoteContent.originalText=this.lastRangeContents.toString();// 原文
      this.appNoteContent.userId=this.$store.state.userInfo.userId;
      FunGetNote(JSON.stringify(this.appNoteContent));
      return;
      this.$ajax({
        url: "/hxclass-mobile/cms-article/anntotions/add",
        method: "post",
        params:{
					annotationContent: '', // 批注内容
					annotationType: type, // 	批注类型 1.下划线 2.批注
					articleId: this.$route.query.id,
					local: this.lastcfiRange, // 文档位置
					originalText: this.lastRangeContents.toString(),  // 原文
					userId: this.$store.state.userInfo.userId,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.id = res.data
          this.getNoteList()
        }
      });
    },

    // 下划线
    onUnderline(){
      this.addAnnotation(1);
    },

    // 笔记
    onNote(){
      this.addAnnotation(2);
    }

  },
  // 生命周期-实例创建完成后调用
  created () {
    let _that = this
    // 下划线
    window.onUnderline = function () {
      _that.onUnderline();
    };

    window.onNote = function () {
      _that.onNote();
    };
    //原生调取下一章
    window.onNextPage = function () {
      const currentLoction = _that.rendition.currentLocation();
      const tocs = _that.toc;
      const toc = tocs.filter(tocs => tocs.level ==1);
      var index=currentLoction.start.index;
      index++;
      if (index >= toc.length) {
          index = toc.length - 1;
          toast("已到达最后一章");
         return;
      }
      _that.jumpTo(toc[index].href);
    };
    //原生调取上一章
    window.onPrevPage = function () {
      const currentLoction = _that.rendition.currentLocation();
      const tocs = _that.toc;
      const toc = tocs.filter(tocs => tocs.level ==1);
      var index=currentLoction.start.index;
      index--;
      if (index <=0) {
          index = 0;
          toast("已到达第一章");
         return;
      }
      _that.jumpTo(toc[index].href);
    };

    //获取电子书阅读记录
    window.getBookLabel = function (){
          const currentLoction = _that.rendition.currentLocation();
          var cfi = _that.rendition.currentLocation().start.cfi
          var percentage = _that.locations.percentageFromCfi(cfi)
          // console.log("全文位置---"+percentage);
          // _that.rendition.display(0.08207343412526998)
        localStorage.setItem("bookLocation"+_that.$route.query.id, JSON.stringify(currentLoction));


    };

    //设置电子书主题
    window.setBookTheme =  (index) =>{
      _that.setTheme(index);
    };

    //设置电子书字号
    window.setBookFonts =  (fonts) =>{
      _that.setFontSize(fonts)
    };
    //设置电子书自动翻页
    window.setBookTurn =  (turnRadio) =>{
      _that.turnRadio=turnRadio;
      _that.onOpenTrunPage(turnRadio)
    };

    //关闭控制栏
    window.onCloseControl =  (close) =>{
      _that.show_edit=close;
      FunCloseControl(_that.show_edit);

    };


    //设置电子书笔记跳转
    window.setNoteJump =  (label) =>{
      _that.jumpTo(label)
    };

    //设置电子书笔记列表
    window.setNoteList =  (list) =>{
      _that.uniqueEntryPositions = JSON.parse(list);
    };


    //原生设置电子书进度
    window.setBookProgress =  (progress) =>{
      _that.progressChange(progress);
    };
    const storage_ebook = localStorage.getItem("storage_ebook")
    if(!storage_ebook){
      this.guideShow = true
    }
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // 加载电子书
    this.$nextTick(()=>{
      this.initializeEpub();
    })
  },
  // 生命周期-实例销毁离开前调用
  beforeDestroy(){
    clearInterval(this.incrementTimer);
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    // 监听功能栏控制属性
    show_edit(){
      if(!this.show_edit){
        setTimeout(()=>{
          this.tabsIndex = null
        },500)
      }
    },

    // 监听是否开启夜间模式
    isNight(){
      this.tabsList[1].name = this.isNight ? '白天' : '夜间'
      this.tabsList[1].icon = this.isNight ? require('./icon/icon_baitian.png') : require('./icon/icon_yejian.png')

      const theme = this.isNight ? 'eye' : this.themeList[this.defaultTheme].name
      this.themes.select(theme);
    },
  }
}
</script>

<style>
</style>
<style lang="less" scoped>
.e-book-wrap{
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-top: 20px;
  box-sizing: border-box;
  .guide{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .overlay{
    position: fixed;
    z-index: 7;
    left: 0;
    top: 0;
    width: 100vh;
    height: 100vh;
    background: rgba(0, 0, 0, .54);
  }
  .flex-around{
    display: flex;
    align-items: center;
  }
  // .epub-viewer{
  // }
  
  // 遮罩
  .mask-cover{
    background-color: #333333;
    color: #b5b5b5;
    position: fixed;
    z-index: 10;
    padding: 20px;
    width: calc(100% - 40px);
    transition: transform 0.5s ease;
  }
  // 顶部功能栏
  .top-back{
    top: 40px;
    transform: translateY(-180%);
    .back{
      font-size: 14px;
    }
  }
  // 图书目录
  .left-menu{
    width: calc(100% - 100px);
    height: calc(100vh - 60px);
    transform: translateX(-110%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    .menu-list{
      max-height: 97%;
      overflow-y: scroll;
      .menu-item{
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    .note-list{
      .title-top{
        position: absolute;
        width: calc(100% - 40px);
        background: #ffffff;
        padding-bottom: 14px;
        border-bottom: 1px solid #DFDFDF;
        z-index: 9;
        .h4{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 14px;
        }
        .num{
          font-size: 11px;
        }
      }
      .note-tree{
        margin-top: 60px;
        .note-item{
          padding: 18px 0;
          border-bottom: 1px solid #DFDFDF;
          .time{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            color: #999999;
          }
          .txt{
            margin-top: 10px;
            font-size: 13px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
          }
          .comment{
            margin-top: 15px;
            border-left: 2px solid #15B7DD;
            background: #FAFAFA;
            color: #666666;
            padding: 3px 6px 3px 10px;
            .textarea{
              font-size: 12px;
              background: #FAFAFA;
              padding: 5px;
              line-height: 20px;
            }
          }
        }
        .note-item:last-child{
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
    .menu-tab{
      position: fixed;
      width: 100%;
      bottom: 20px;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      .item-tab{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        line-height: 48px;
      }
      .item-tab-active{
        color: #15B7DD;
      }
    }
  }

  // 底部功能栏
  .bot_edit{
    bottom: 0;
    transform: translateY(100%);

    // 阅读设置
    .read-set{
      border-bottom: 1px solid #444444;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .read-item{
        margin-bottom: 20px;
        font-size: 12px;
        .name{
          margin-right: 20px;
        }
        // 字号设置
        .word-size{
          .ctrl-size{
            display: inline-block;
            border-radius: 20px;
            text-align: center;
            background: #4f4f4f;
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
            height: 30px;
            width: 70px;
            color: #cccccc;
          }
          .ctrl-size-dis{
            color: #5f5f5f;
          }
          .num-size{
            margin: 0 20px;
          }
        }

        .auto-turn{
          /deep/ .van-radio__label{
            color: #b5b5b5;
          }
        }

        // 主题色设置
        .theme-bg{
          .theme-item{
            text-align: center;
            font-size: 20px;
            color: #f89f0e;
            font-weight: bold;
            line-height: 30px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 15px;
            border: 3px solid transparent;
          }
          .theme-item-active{
            border-color: #f89f0e;
          }
        }

        // 进度条设置
        .progress-step{
          justify-content: space-between;
          .progress{
            flex: 1;
            margin: 0 10px;
          }
        }
      }
      .read-item:last-child{
        margin-bottom: 0;
      }
    }
    
    // 功能选项
    .bot_tabs{
      justify-content: space-between;
      .tabs-item{
        width: calc((100% - 30px) / 4);
        text-align: center;
        font-size: 12px;
        .tabs_img{
          margin-bottom: 5px;
          width: 18px;
        }
        .tabs-font{
          color: #e9a033;
        }
      }
    }
  }
  // 上下抽屉效果
  .drawer-effect{
    transform: translateY(0);
  }
  // 左抽屉效果
  .drawer-left{
    transform: translateX(0);
  }

  .windowCoupons{
    padding: 20px 10px;
    text-align: center;
    .center{
      margin-bottom: 20px;
      text-align: center;
      font-size: 18px;
      line-height: 25px;
      color: #333;
    }
    .textarea{
      width: 100%;
      background: #FAFAFA;
    }
    .btn{
      margin-top: 20px;
    }
  }
}
</style>
