<template>
  <div class="bindPhone">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="绑定手机号"
      left-text=""
      right-text=""
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content">
      <div class="form">
        <p @click="showPopup" class="phoneType">{{ popCode[popIndex].code }}</p>
        <img @click="showPopup" src="@/assets/image/login/icon1.png" />
        <van-field v-model="phone" label="" placeholder="请输入手机号" />
      </div>
      <div class="form">
        <van-field v-model="code" maxlength="6" label="" placeholder="请输入验证码" />
        <div @click="onDxCode()">
          <p class="getCode" v-if="isDxCode" style="color: #00b3c5">
            获取验证码
          </p>
          <p class="getCode" v-else style="color: #999">
            重新发送 {{ countdown }}
          </p>
        </div>
      </div>
      <div class="warnText">
        <span>注:</span
        >您是第—次使用该微信登录，绑定成功后再次登录无需此操作。若您在平台已存在账号，请绑定已注册的手机号，若您使用新手机号登录，将判断为新账号。
      </div>
      <button type="default" class="btn" @click="onLogin()">登录</button>
    </div>
    <!-- 区号选择 -->
    <n-transition
      ref="pop"
      speed="ease-in-out"
      :height="popHeight"
      :maskVal="0.35"
    >
      <div class="pop_box">
        <div class="box_list">
          <div
            class="list_item"
            v-for="(item, index) in popCode"
            :key="index"
            @click="changeCode(index)"
          >
            <p class="item_txt">{{ item.country }}</p>
            <p class="item_txt">{{ item.code }}</p>
          </div>
        </div>
        <div class="box_button" @click="hidePopup()">取消</div>
      </div>
    </n-transition>
  </div>
</template>

<script>
  import nTransition from "@/components/n-transition/n-transition.vue";
  import { FunGoHome,FunSetUserInfo } from '@/assets/js/NativeWebKit.js';
  import { FunJudgDevice } from '@/unit/fun.js'
  export default {
    // 可用组件的哈希表
    components: { nTransition },
    data() {
      return {
        type: 0, //类型 1微信 2苹果
        unionid: "", //微信id
        appleId: "", //苹果id

        phone: "", //手机号
        code: "", //验证码
        isDxCode: true,
        countdown: 60, // 验证码时间
        popHeight: 260, //弹框高度
        // 用户数据
        obj: {
          isLogin: false,
        },

        popIndex: 0, //选中index
        popCode: [
          {
            id: "4",
            country: "中国",
            code: "+86",
          },
          {
            id: "1",
            country: "中国香港",
            code: "+852",
          },
          {
            id: "2",
            country: "中国澳门",
            code: "+853",
          },
          {
            id: "3",
            country: "中国台湾",
            code: "+886",
          },
        ],
        // 手机类型
        phoneloginType: '', // 2是安卓 3是ios
      };
    },
    created() {
      this.type = this.$route.query.type; //类型
      this.unionid = this.$route.query.unionid; //微信id
      this.appleId = this.$route.query.appleId; //苹果id
      if(FunJudgDevice() == 'ios' && window.webkit){
        this.phoneloginType = 3;
      }else if(FunJudgDevice() == 'android'){
        this.phoneloginType = 2;
      }
    },
    methods: {
      // 登录
      onLogin() {
        if (this.phone == "") {
          this.$Toast("请输入手机号");
          return
        } else if (!this.$regular.phone.reg.test(this.phone)) {
          this.$Toast("手机号码格式有误");
          return
        } else if (this.code == "") {
          this.$Toast("请输入验证码");
          return
        } else if (!this.$regular.dxcode.reg.test(this.code)) {
          this.$Toast("验证码为6位纯数字");
          return
        }

        let url = ''
        let mobileType = this.popCode[this.popIndex].id //区号
        if (this.type == 1) {
          // 微信
          url = "/hxclass-mobile/login/wechat/bindmobile?mobile=" + this.phone 
            + "&mobileType=" + mobileType 
            + '&unionid=' + this.unionid 
            + '&verCode=' + this.code
            + '&loginType=' + this.phoneloginType
        } else if (this.type == 2) {
          // apple
          url = "/hxclass-mobile/login/apple/bindmobile?mobile=" + this.phone 
            + "&mobileType=" + mobileType 
            + '&appleId=' + this.appleId 
            + '&verCode=' + this.code
            + '&loginType=' + this.phoneloginType
        }

        // 绑定手机号
        this.$ajax({
          url,
          method: "POST",
        }).then((res) => {
          // console.log('绑定手机号',res)
          if (res.code == 200 && res.success) {
            // 登录成功
            this.$Toast.success('登录成功！');
            // 老用户
            if (res.data == 0) {
              // console.log('老用户')
              // 获取用户信息
              this.getUserInfo()
            }
            // 新用户
            else if (res.data == 1) {
              // console.log('新用户')
              // 获取用户信息
              this.getUserInfo(1)
            }
          } else {
            this.$alert(res.message)
          }
        });
      },
      // 获取用户信息
      getUserInfo(type) {
        this.$ajax({
          url: '/hxclass-mobile/user/info',
          method: "get"
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // console.log(this.$store.state.userInfo.tolink)
            this.obj = res.data
            // 保存手机号
            localStorage.setItem('loginPhone', res.data.mobile)
            this.obj.isLogin = true;
            this.$store.commit("updateUserInfo", this.obj);
            // 查询身份认证
            this.checkIdent(type);
          }
        })
      },
      // 查询是否已身份认证
      checkIdent(type) {
        this.$ajax({
          url: '/hxclass-mobile/user/anthen/'+this.$store.state.userInfo.userId,
          method: "PUT",
        }).then((res) => {
          // 向原生传递登录信息
          FunSetUserInfo(JSON.stringify(this.$store.state.userInfo))
          if (res.code == 200 && res.success) {
            // 未认证
            if (res.data == 0) {
              this.$store.commit('changecertified',false);
            }
            // 已认证
            else if (res.data == 1) {
              this.$store.commit('changecertified',true);
            }
            // 新用户进入
            if (type == 1) {
              // 偏好设置
              // this.$router.push('/setUp/interest?type=2');
              this.$router.replace('/setUp/interest?type=2');
              return
            }
            // 登录前地址
            let tolink = this.$store.state.userInfo.tolink
            if (tolink.indexOf('/login') > -1) {
              // 原生返回首页
              FunGoHome();
              // this.$router.push('/home');
              return
            }
            this.$router.push(this.$store.state.userInfo.tolink);
          }
        })
      },
      // 打开弹框
      showPopup() {
        this.$refs["pop"].show();
      },
      // 关闭弹框
      hidePopup() {
        this.$refs["pop"].hide();
      },
      // 选择区号
      changeCode(index) {
        this.popIndex = index;
        this.hidePopup();
      },
      sendemail(s) {
        if (s == 0) {
          this.countdown = 60;
          this.isDxCode = true;
          s = 60;
          return;
        } else {
          this.countdown = s;
          this.isDxCode = false;
          s--;
        }
        setTimeout(() => {
          this.sendemail(s);
        }, 1000);
      },
      // 发送验证码
      onDxCode() {
        if (this.isDxCode) {
          if (!this.phone) {
            this.$Toast("请输入手机号");
            return false;
          } else if (!this.$regular.phone.reg.test(this.phone)) {
            this.$Toast("请输入正确的手机号");
            return false;
          }

          let url = '' 
          let mobileType = this.popCode[this.popIndex].id //区号
          if (this.type == 1) {
            // 微信
            url = "/hxclass-mobile/user/sms/bindwx?mobileType=" + mobileType + "&phone=" + this.phone
          } else if (this.type == 2) {
            // apple
            url = "/hxclass-mobile/user/sms/bindapple?mobileType=" + mobileType + "&phone=" + this.phone
          }
          
          // 获取验证码
          this.$ajax({
            url,
            method: "get",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              // 倒计时
              this.sendemail(this.countdown);
            } else {
              this.$Toast(res.message)
            }
          });
        }
      },
    },
  };
</script>

<style scoped lang="less">
/deep/.van-cell {
  padding: 0;
  flex: 1;
}
.bindPhone {
  min-height: 100vh;
  background-color: #f7f7f9;
  .content {
    padding: 9px 34px;
    height: calc(100vh - 60px);
    margin-top: 14px;
    border-radius: 10px 10px 0px 0px;
    background: #ffffff;
    box-sizing: border-box;
    .btn {
      height: 45px;
      margin-top: 35px;
      background: linear-gradient(
        120deg,
        #15b7dd 0%,
        rgba(21, 183, 221, 0.83) 100%
      );
      border-radius: 25px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #ffffff;
      border: 0;
      width: 100%;
    }
    .warnText {
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 18px;
      margin-top: 18px;
      span {
        color: rgba(91, 205, 231, 1);
      }
    }
    .form {
      display: flex;
      align-items: center;
      padding: 9.5px 0;
      border-bottom: 1px solid #eeeeee;
      .phoneType {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 19px;
      }
      img {
        width: 8px;
        height: 6px;
        margin-left: 5px;
        margin-right: 9px;
      }
      input {
        flex: 1;
      }
      .getCode {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: rgba(91, 205, 231, 1);
        line-height: 14px;
        padding-left: 6px;
        border-left: 1px solid #c4c4c4;
      }
      /deep/.uni-input-placeholder {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #cccccc;
        line-height: 24px;
      }
      /deep/.uni-input-input {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
// 选择区号
.pop_box {
  .box_list {
    padding: 0 18px;
    .list_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      padding: 14px 0;
      &:last-child {
        border: 0;
      }
      .item_txt {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .box_button {
    font-size: 16px;
    font-weight: bold;
    color: #15b7dd;
    text-align: center;
    border-top: 10px solid #f7f7f9;
    padding: 14px 0;
  }
}
</style>
