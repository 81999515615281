<template>
  <div class="setMealList">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="更多套餐"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="collocationList">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        :finished-text="finishedText"
        @load="getList"
      >
        <div class="courseItem" v-for="(item, index) in list" :key="'packageId'+item.packageId">
          <p class="title">{{ item.packageName }}</p>
          <div class="up">
            <div class="left">
              <div class="tagbox">
                <span class="tag"><img src="@/assets/image/selected/tag.png" alt=""></span>
                <span class="price"
                  ><span class="moneySign">￥</span>{{ item.packagePrice.toFixed(2) }}</span
                >
              </div>
            </div>
            <div class="right">
              <router-link
                  style="width: 76px"
                  :to="'/curriculum/Packagedetails?packageId=' + item.packageId + '&from=2'"
                  hover-class="none"
                  class="all-btn-small"
                >
                <span>立即购买</span>
              </router-link>
            </div>
          </div>
          <div class="down">
            <div
              class="item"
              v-for="(items, i) in item.productList"
              :key="i"
              v-show="i < (isShow === index ? list[index].productList.length : 2)"
              @click="goDetail(items)"
            >
              <div class="left">
                <img class="courseImg" :src="items.picture" />
              </div>
              <div class="right">
                <span class="title">{{ items.name }}</span>
                <span class="identification" v-if="items.type == 1">{{items.courseCategoryName}}丨视频数量：{{items.classHour}}个</span>
                <span class="identification" v-if="items.type == 4">{{items.isExam ? items.examNum + '次考试次数':"不限考试次数"}}</span>
                <div class="pricestander">
                  ￥<span class="present">{{items.couponPrice ? items.couponPrice.toFixed(2) : '0.00'}}</span>
                  <span class="original" v-if="items.original">￥{{items.originalPrice ? items.originalPrice.toFixed(2) : '0.00'}}</span>
                </div>
              </div>
            </div>
            <div class="more" v-if="item.productList.length > 2" @click="showMore(index)">
              <span>{{ isShow === index ? "收起" : "更多商品" }}</span>
              <van-icon
                name="arrow-up"
                v-if="isShow === index"
                size="10"
                color="#999"
              />
              <van-icon name="arrow-down" v-else size="10" color="#999" />
            </div>
          </div>
        </div>
      </van-list>
      <noMessage v-if="list.length==0 && finished" type=1></noMessage>
    </div>
  </div>
</template>

<script>
import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
export default {
  data() {
    return {
      isShow: "",
      pageNumber: 1,
      pageSize: 10,
      list: [],
      loading: false,
      finished: false,
      finishedText: '',
    };
  },
  methods: {
    showMore(index) {
      if (this.isShow === index) {
        this.isShow = "";
      } else {
        this.isShow = index;
      }
    },

    // 查询数据列表
    getList(){
      this.$ajax({
        url: '/hxclass-mobile/packages/',
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
					this.list = [...this.list, ...res.data.records]
          // 加载完成
          if (this.list.length == res.data.total) {
            this.finished = true
            this.finishedText = '没有更多了'
            this.$nextTick(() => {
              // 屏幕高度
              let clientHeight = document.documentElement.clientHeight;
              // 页面高度
              let pageHeight = document.getElementsByClassName('collocationList')[0].clientHeight;
              if (pageHeight > clientHeight) {
                this.finishedText = "没有更多了";
              } else {
                this.finishedText = "";
              }
            })
          }
          // 无数据
          if (this.list.length == 0) {
            this.finished = true
            this.finishedText = ''
          }
          this.pageNumber++
          this.loading = false
        }else{
          this.$alert(res.message)
        }
      }).catch(err=>{
        console.error(err)
      })
    },

    // 查看详情
    goDetail(item) {
      if(item.type == 1){ //课程
      jumpToShopDetail(item.type,item.dataId)
        // this.$router.push('/curriculum/course?id=' + item.dataId)
      }else if(item.type == 2){ // 图书
      jumpToShopDetail(item.type,item.productId)
        // this.$router.push('/curriculum/BookDdetails?id=' + item.productId)
      }else if(item.type == 3){ // 教具
      jumpToShopDetail(item.type,item.productId)
        // this.$router.push('/curriculum/BookDdetails?id=' + item.productId)
      }else if(item.type == 4){ // 模拟考试
      // jumpToShopDetail(item.type,item.dataId,'cart')
        this.$router.push('/examination/exam?id=' + item.dataId)
      }else if(item.type == 5){ // 电子照片
        
      }else if(item.type == 6){ // 延期
        
      }else if(item.type == 7){ // 补考
        
      }else if(item.type == 8){ // 直播
        this.$router.push('/live/liveDetail?id=' + item.productId)
      }else if(item.type == 9){ // 其他
        
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created(){
    this.getList()
  }
};
</script>

<style lang="less" scoped>
page {
  background-color: #f7f7f9;
}
.pricestander{
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #FF7A00;
  line-height: 14px;
  font-family: PingFangMedium;
  .present{
    font-size: 14px;
    // font-weight: bold;
  }
  .free{
    font-size: 14px;
  }
  .original{
    margin-left: 8px;
    font-size: 12px;
    color: #999999;
    font-weight: 400;
    text-decoration: line-through;
    font-family: PingFangMedium, PingFang S
  }
}
.setMealList {
  padding: 0 18px;
  padding-bottom: 18px;
  .collocationList {
    .courseItem {
      margin-top: 11px;
      padding: 12.5px 12.5px 0 12.5px;
      background: #fff;
      border-radius: 18px;
      .title {
        font-size: 16.5px;
        font-weight: 500;
        color: #333333;
        line-height: 25.5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .up {
        display: flex;
        font-family: PingFangMedium;
        .left {
          flex: 1;
          .tagbox{
            display: flex;
            align-items: center;
          }
          .tag {
            width: 42px;
            height: 19px;
            display: inline-block;
            // background: linear-gradient(80deg, #FFC243 0%, rgba(255, 188, 52, 0) 100%);
            // background: url(~@/assets/image/selected/tag.png) no-repeat;
            // border-radius: 0px 7px 7px 7px;
            // margin-right: 12px;
            img{
              width: 100%;
              height: 100%;
            }

          }
          .price {
            font-size: 16.5px;
            // font-weight: bold;
            font-family: PingFangMedium;
            color: #FF7A00;
            line-height: 27px;
            .moneySign{
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
      }
      .down {
        margin-top: 10px;
        .item {
          display: flex;
          padding-bottom: 14.5px;
          .courseImg {
            width: 90.5px;
            height: 63.5px;
            border-radius: 6px;
            background-repeat: no-repeat;
            object-fit: cover;
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1px 0 1px 11px;
            .title {
              display: flex;
              font-size: 14.5px;
              font-weight: 400;
              color: #333333;
              line-height: 25.5px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 1;
            }
            .price {
                display: flex;
                font-size: 10px;
                font-weight: 400;
                line-height: 14.5px;
                margin-top: 7px;
                span:nth-child(1){
                  font-weight: bold;
                  font-family: PingFangMedium;
                  color: #FF7A00;
                  margin-right: 5px;
                }
                span:nth-child(2){
                  color: #999999;
                  text-decoration: line-through;
                }
              }
            .identification {
              font-size: 11px;
              font-weight: 400;
              color: #1e97b4;
              line-height: 15.5px;
            }
          }
        }
        .more {
          border-top: 1px solid #eeeeee;
          padding: 13.5px 0 14.5px 0;
          text-align: center;
          font-size: 11px;
          font-weight: 400;
          color: #666666;
          line-height: 15.5px;
        }
      }
    }
  }
}
</style>
