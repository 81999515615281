<template>
  <div class="myOrder">
    <!-- 我的订单 -->
    <!-- <div
			:style="{ height: iStatusBarHeight + 'px'}"
			style="position: fixed;left: 0;right: 0;top: 0;background: #fff;z-index: 10;"></div>
		<div :style="{ height: iStatusBarHeight + 'px'}"></div> -->

    <van-nav-bar class="nav-circular" title="我的订单" left-text="" right-text="" :fixed="true" :placeholder="true"
      left-arrow @click-left="goBack()" />

    <!-- <div class="nav" style="position: fixed;top:0;left: 0;right: 0;z-index: 10;">
			<img @click="back" src="../../assets/image/blackLeft.png" />
			<div class="findInput" v-show="findStatus">
				<img src="../../assets/image/icon/findIcon.png" @click="findItem"/>
				<van-field v-model="keyword" @blur="inputBlur" label="" placeholder="请输入搜索内容" />
			</div>
			<span v-show="!findStatus">我的订单</span>
			<img @click="findStatus = true" v-show="findStatus" src="../../assets/image/icon/findIcon.png" />
			<span style="width:16px;"></span>
		</div> -->

    <div class="tabNav">
      <div class="tabItem" :class="{ active: index == '' }" @click="index = ''">
        全部
      </div>
      <div class="tabItem" :class="{ active: index == 1 }" @click="index = 1">
        待支付
      </div>
      <div class="tabItem" :class="{ active: index == 2 }" @click="index = 2">
        待收货
      </div>
      <!-- <div class="tabItem" :class="{ active: index == 3 }" @click="index = 3">
        待评价
      </div> -->
      <!-- <span class="tabItem" :class="{'active' : index==5}" @click="index=5"
        >退款</span> -->
    </div>
    <!-- v-infinite-scroll="load" -->
    <div class="orderList">
      <no-message style="margin-top: 50px" v-if="!orderList.length && visible" type="1" message="暂无订单" />
      <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="load">
        <div class="orderItem" v-for="(item, index) in orderList" :key="index">
          <div class="orderCode-Type" @click="
            $router.push(
              '/order/orderDetail?id=' +
              item.orderId +
              '&stateHeight=' +
              stateHeight
            ),
            session(item)
            ">
            <span class="orderCode">订单编号：{{ item.code }}</span>
            <span class="type" v-if="item.refundStatus">{{ item.refundStatus == 1 ? '退款中' : item.refundStatus == 2 ?
              '部分退款' : item.refundStatus == 3 ? '全部退款' : '' }}</span>
            <span class="type" v-else>{{ item.statusTxt }}</span>
          </div>
          <div class="shopList" @click="
            $router.push(
              '/order/orderDetail?id=' +
              item.orderId +
              '&stateHeight=' +
              stateHeight
            ),
            session(item)
            ">
            <div class="shopItem" v-show="!item.source"
              v-for="(ite, ind) in item.productList.filter(item => { return item.type == 1 })" :key="ind">
              <img v-if="ite.productPicture" :src="ite.productPicture" />
              <div class="rightMess">
                <div class="titlePrice">
                  <span class="title">{{ ite.productName }}</span>
                  <span class="price">{{
                    (ite.type == 2 || ite.payPrice) && ite.productPrice
                      ? "¥" + ite.productPrice.toFixed(2)
                      : "￥0.00"
                  }}</span>
                </div>
                <div class="navNum">
                  <span class="navTime" v-if="ite.productType == 1">
                    <div class="temp-box" style="display: flex; align-items: center;">
                      <img src="@/assets/image/order/validity-icon.png" alt="" v-if="!ite.openTime && (ite.validDayType == 1 || ite.validDayType == 2)"
                        style="width: 34px; height: 15px; border-radius: 0px; margin-right: 6px;">
                      {{ ite.openTime ? ite.openTime : ite.validDayType == 1 ? (ite.validDays + '天') : ite.validDayType
                        == 2 ? '长期有效' : ite.otherNotes }}
                    </div>

                    <p class="offlineAddress" v-if="ite.offlineAddress">
                      {{ ite.offlineAddress }}
                    </p>
                  </span>
                  <span class="navTime" v-if="ite.productType == 4">{{
                    ite.examNumberNotes
                    }}</span>
                  <span class="navTime" v-if="ite.productType == 5">{{
                    ite.otherNotes
                    }}</span>
                  <span class="navTime" v-if="ite.productType == 6">{{ ite.courseDeferralDaysNotes }}
                    <br v-if="ite.examNumberNotes && ite.courseDeferralDaysNotes" />
                    {{ ite.examNumberNotes }}</span>
                  <span class="navTime" v-if="ite.productType == 7">{{ ite.examNumberNotes }}
                    <br v-if="ite.examNumberNotes && ite.courseDeferralDaysNotes" />
                    {{ ite.courseDeferralDaysNotes }}</span>
                  <!-- <div
                    class="reimburse"
                    v-if="ite.productType == 2 || ite.productType == 3"
                  >
                    {{
                      ite.productType == 2 || ite.productType == 3
                        ? "支持七天无理由退货"
                        : "不支持退款及转让"
                    }}
                  </div> -->
                  <div class="box" v-if="ite.productType == 2 || ite.productType == 3 || ite.productCount > 1"
                    style="height:20px"><!-- 占位盒子 --></div>
                  <span class="num" v-if="ite.productType == 2 || ite.productType == 3 || ite.productCount > 1">x{{
                    ite.productCount }}</span>
                </div>
                <div class="box" v-if="ite.productType != 2 && ite.productType != 3" style="height:20px"><!-- 占位盒子 -->
                </div>
                <!-- <div v-if="ite.productType != 2 && ite.productType != 3">
                  <span class="reimburse">
                    {{
                      ite.productType == 2 || ite.productType == 3
                        ? "支持七天无理由退货"
                        : "不支持退款及转让"
                    }}
                  </span>
                </div> -->
              </div>
            </div>
            <div class="shopItem" v-if="item.source">
              <img :src="item.orderPackage.pcMobileListPicture" />
              <div class="rightMess">
                <div class="titlePrice">
                  <span class="title">{{ item.orderPackage.packageName }}</span>
                  <span class="price">￥{{
                    item.orderPackage.packagePrice.toFixed(2)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="actual-payment">
            <span></span>
            <span class="payment" v-if="item.status">实付 ¥
              <a>{{ item.payPrice ? item.payPrice.toFixed(2) : "0.00" }}</a>
            </span>
            <span class="payment" v-else>退款金额 ¥
              <a>{{ item.payPrice ? item.payPrice.toFixed(2) : "免费" }}</a>
            </span>
          </div>
          <div class="btns" v-if="item.status != 6">
            <!-- 待支付 -->
            <span v-if="item.status == 1 && item.showBtnAddress" class="ordinary all_span"
              @click="$router.push('/address/adjust?orderId=' + item.orderId)">修改地址</span>
            <span v-if="item.status == 1" class="ordinary all_span" @click="popUp(), (handleItem = item)">取消订单</span>
            <!-- 待支付 微信、支付宝 -->
            <span v-if="item.status == 1 && item.payMethod != 3" @click="checkPay(item)"
              class="crux ordinary all_span">去支付</span>
            <!-- 待支付 对公转账 -->
            <span v-if="
              item.payMethod == 3 &&
              item.status == 1 &&
              item.transferStatus == 0
            " class="crux ordinary all_span" @click="warningModel = true">支付审核中</span>

            <!-- 部分发货、待收货 -->
            <router-link v-if="item.status == 3 || item.status == 4" :to="item.parcelNumber == 1
                ? '/order/logistics?orderId=' + item.orderId
                : '/order/parcelList?orderId=' + item.orderId
              " class="ordinary all_span">查看物流</router-link>
            <!-- <span
            v-if="item.status == 3"
            @click="$alert('如需退款，请联系客服')"
            class="ordinary all_span"
            >申请退款</span
          > -->
            <!-- ,showReceiving() -->
            <!-- 交易完成 -->
            <span v-if="item.canInvoice && item.status != 1 && item.status != 4 && item.invoiceBtnStatus == 1"
              class="ordinary all_span" @click="submit(item)">申请发票</span>
            <span v-if="item.canInvoice && item.status != 1 && item.status != 4 && item.invoiceBtnStatus == 2"
              class="ordinary all_span" @click="$router.push('/invoice/list?orderId=' + item.orderId)">发票明细</span>
            <span v-if="item.canInvoice && item.status != 1 && item.status != 4 && item.invoiceBtnStatus == 3"
              class="ordinary all_span" @click="toDetail(item)">查看发票</span>
            <span v-if="item.status == 3 || item.status == 4" class="crux ordinary all_span"
              @click="showReceiving(item)">确认收货</span>
            <router-link v-if="item.status == 5 && getStatus(item.productList)" :to="item.parcelNumber == 1
                ? '/order/logistics?orderId=' + item.orderId
                : '/order/parcelList?orderId=' + item.orderId
              " class="ordinary all_span">查看物流</router-link>
            <!-- <span
            v-if="item.status == 5 && getStatus(item.productList)"
            @click="$alert('如需退款，请联系客服')"
            class="ordinary all_span"
            >申请退款</span
          > -->
            <!-- <router-link v-if="item.status == 5" hover-class="none" to="/order/refundList" class="ordinary all_span">申请退款</router-link> -->
            <!-- <router-link
              v-if="
                item.showBtnComment &&
                item.status == 5 &&
                getpjStatus(item.productList)
              "
              @click.native="session(item)"
              :to="'/order/evaluate?orderId=' + item.orderId"
              class="crux ordinary all_span"
              >评价</router-link
            > -->
          </div>
          <div class="refund" v-if="false">
            <span class="refundType">退款成功</span>
            <span class="refundExplain">退款成功￥{{
              item.payPrice ? item.payPrice.toFixed(2) : "免费"
            }}</span>
          </div>
        </div>
      </van-list>
    </div>
    <!-- 取消订单 -->
    <n-transition ref="pop" speed="ease-in-out" :height="401" :maskVal="0.35">
      <div class="popContentBox">
        <div class="popBox">
          <div class="titleBox">
            <span class="title">取消订单</span>
            <van-icon name="cross" size="20" color="#999" @click="close" />
          </div>
          <div class="popContent">
            <div class="question">
              <span class="mess">请选择取消订单的原因</span>
              <span class="warning">取消后将无法恢复，优惠券可退回，有效期内可使用</span>
            </div>
            <div class="option" @click="reason = '我不想要了'">
              <span>我不想要了</span>
              <img v-if="reason == '我不想要了'" src="@/assets/image/2022021110551.png" />
              <img v-if="reason != '我不想要了'" src="@/assets/image/2022021110552.png" />
            </div>
            <div class="option" @click="reason = '价格有点贵'">
              <span>价格有点贵</span>
              <img v-if="reason == '价格有点贵'" src="@/assets/image/2022021110551.png" />
              <img v-if="reason != '价格有点贵'" src="@/assets/image/2022021110552.png" />
            </div>
            <div class="option" @click="reason = '暂时不需要了'">
              <span>暂时不需要了</span>
              <img v-if="reason == '暂时不需要了'" src="@/assets/image/2022021110551.png" />
              <img v-if="reason != '暂时不需要了'" src="@/assets/image/2022021110552.png" />
            </div>
            <div class="option" @click="reason = '其他'">
              <span>其他</span>
              <img v-if="reason == '其他'" src="@/assets/image/2022021110551.png" />
              <img v-if="reason != '其他'" src="@/assets/image/2022021110552.png" />
            </div>
          </div>
        </div>
        <div class="popbutton">
          <span class="btn all-btn-bigCircle" @click="cancelOrder">确认取消</span>
        </div>
      </div>
    </n-transition>
    <!-- 提示信息 -->
    <van-popup v-model="warningModel">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="info-o" color="#EB3C31" size="25" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">
          您已提交对公转账信息，工作人员确认收款后，会在1个工作日内联系您。
        </div>
        <div class="popup_button">
          <span class="all-btn-middle btn2" @click="closeModel()">我知道了</span>
        </div>
      </div>
    </van-popup>
    <!-- 确认收货 -->
    <n-transition ref="pop_receiving" speed="ease-in-out" :height="283" :maskVal="0.35">
      <div class="popContentBox">
        <div class="popBox">
          <div class="titleBox">
            <span class="title">确认收到货了吗</span>
            <van-icon name="cross" size="20" color="#999" @click="closeReceiving" />
          </div>
          <div class="popBox_con">
            <div class="con_main">
              <img class="main_img" :src="confirmImg" />
              <div class="main_info">共{{ confirmCount }}件</div>
            </div>
            <div class="con_info">请收到商品确认无误后再确认收货</div>
          </div>
        </div>
        <div class="popbutton">
          <span class="btn all-btn-bigCircle" @click="confirmreceive">确 认</span>
        </div>
      </div>
    </n-transition>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
import { popToMineTab, CouponReceive } from "@/assets/js/NativeWebKit.js"
export default {
  // 可用组件的哈希表
  components: { nTransition },
  data() {
    return {
      finishedText: '',
      stateHeight: 0, //信息条高度
      orderId: "",
      keyword: "", // 关键字
      reason: "我不想要了", // 取消原因
      handleItem: null, // 当前操作对象
      findStatus: false,
      index: "",
      iStatusBarHeight: 0,
      warningModel: false, // 提示信息弹框
      pageNum: 1,
      pageSize: 10,
      total: 0,
      orderList: [], // 订单列表
      visible: false,
      confirmCount: 0,
      confirmImg: "",
      loading: false,
      finished: true,
    };
  },
  watch: {
    // tab切换
    index(e, old) {
      this.orderList = [];
      this.pageNum = 1;
      this.finishedText = '';
      this.getOrderList();
      this.visible = false;
      if (document.getElementsByClassName("van-list")) {
        document
          .getElementsByClassName("van-list")[0]
          .removeEventListener("scroll", this.FunHandleScroll);
      }
      window.removeEventListener('scroll', this.scrollToTop)

      this.$nextTick(() => {
        if (!document.getElementsByClassName("van-list")) { return false }
        document.getElementsByClassName("van-list")[0].addEventListener("scroll", this.FunHandleScroll);
      })
    },
  },
  // 页面生命周期-页面加载
  created() {

  },
  // 销毁滚动条绑定
  destroyScroll() {
    if (document.getElementsByClassName("van-list")) {
      document
        .getElementsByClassName("van-list")[0]
        .removeEventListener("scroll", this.FunHandleScroll);
    }
    window.removeEventListener('scroll', this.scrollToTop)
  },
  // keepAlive
  activated() {
    if (!!sessionStorage.getItem('handleorderID')) {
      this.getOrderDetail(sessionStorage.getItem('handleorderID'));
    }
    // if(!!sessionStorage.getItem('involceStatus')){
    //   // this.index = 3;
    //   // this.orderList = [];
    //   // this.pageNum = 1;
    //   // this.getOrderList();
    //   this.visible = false;
    // }
    if (this.$route.params.keepAlive || this.$route.query.keepAlive) {
      this.iStatusBarHeight = "20px";
      if (this.index) {
        this.index = '';
      } else {
        this.pageNum = 1;
        this.orderList = [];
        this.getOrderList();
      }
      // 获取信息条高度
      this.stateHeight = this.$route.query.stateHeight;
    } else if (!this.orderList.length) {
      // 如果没有数据默认请求一次 防止订单列表白屏问题
      this.pageNum = 1;
      this.orderList = [];
      this.getOrderList();
    }
    if (this.$route.query.keepAlive) {
      return this.$router.replace({ name: 'keepAliveMyOrder', params: { keepAlive: true }, query: { fromMenu: this.$route.query.fromMenu } })
    }

    // 进入页面后赋值滚动位置
    this.$nextTick(() => {
      // 监听页面返回
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.goBack, false);
      }

      if (!document.getElementsByClassName("van-list")) {
        return false
      }
      document.getElementsByClassName("van-list")[0].scrollTop = sessionStorage.getItem('scrollTop-courseList')

      console.log(this.$route.params.type == 'paySignupObj')
      // 电子照支付返回
      // if (this.$route.params.type == 'paySignupObj') {
      //   let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj')); //电子照信息
      //   // 支付成功
      //   if (paySignupObj.pay) {
      //     this.$router.push({
      //       path: "/examEnroll/signUpConfirm1",
      //       query: {
      //         signupUserId: paySignupObj.signupUserId,
      //         examineeId: paySignupObj.examineeId,
      //         pay: true, //支付成功
      //       }
      //     });
      //   }
      //   return
      // }

      // // 补考支付返回
      // if (this.$route.params.type == 'payMakeObj') {
      //   let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj')); //补考信息
      //   // 支付成功
      //   if (payMakeObj.pay) {
      //     this.$router.push({
      //       path: "/examEnroll/registration",
      //       query: {
      //         id: payMakeObj.configId,
      //         type: 2,
      //       }
      //     });
      //   }
      //   return
      // }
    })
  },
  deactivated() {
    // 离开页面去掉监听
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    // 申请发票
    submit(item) {
      return this.$Toast("请联系工作人员线下开具发票，联系电话：400-800-9002转3。");
      this.$router.push('/invoice/apply?orderId=' + item.orderId);
      this.session(item)
    },
    goBack() {
      console.log('popToMineTab')
      // this.$router.push('/my'); 
      popToMineTab(); // h5跳转原生我的页面
    },
    // 存储订单id
    session(item) {
      sessionStorage.setItem('handleorderID', item.orderId)
    },
    getOrderDetail(e) {
      this.$ajax({
        url: '/hxclass-mobile/order/' + e,
        method: 'get'
      }).then(res => {
        if (res.code == 200 && res.success) {
          for (let i = 0; i < this.orderList.length; i++) {
            if (this.orderList[i].orderId == e) {
              if (res.data.status == 1) {
                this.$set(this.orderList[i], 'statusTxt', '待付款')
              } else if (res.data.status == 2) {
                this.$set(this.orderList[i], 'statusTxt', '待发货')
              } else if (res.data.status == 3) {
                this.$set(this.orderList[i], 'statusTxt', '待收货')
              } else if (res.data.status == 4) {
                this.$set(this.orderList[i], 'statusTxt', '部分发货')
              } else if (res.data.status == 5) {
                this.$set(this.orderList[i], 'statusTxt', '交易完成')
              } else if (res.data.status == 6) {
                this.$set(this.orderList[i], 'statusTxt', '交易关闭')
              }

              this.$set(this.orderList[i], 'status', res.data.status)
              this.$set(this.orderList[i], 'invoiceBtnStatus', res.data.invoiceBtnStatus)
              this.$set(this.orderList[i], 'showBtnComment', res.data.showBtnComment)
              // index == 1
              // 待支付
              // index == 2
              // 待收货
              // index == 3
              // 待评价
              if (this.index == 1 && this.orderList[i].status != 1) {
                this.orderList.splice(i, 1);
              } else if (this.index == 2 && this.orderList[i].status != 3 && this.orderList[i].status != 4) {
                this.orderList.splice(i, 1);
              } else if (this.index == 3 && this.orderList[i].status == 5 && !this.orderList[i].showBtnComment) {
                this.orderList.splice(i, 1);
              }
            }
          }
          sessionStorage.setItem('handleorderID', '');
          // if(res.data.kuaidiInfo){
          //   res.data.kuaidiInfo = JSON.parse(res.data.kuaidiInfo)
          // }
          // this.orderDetail = res.data;
          // let str = res.data.createTime.split('-').join('/');
          // this.time = 1000*60*60*24*7 - (new Date().getTime() - new Date(str).getTime())
        } else {
          this.$Toast(res.message);
        }
      })
    },
    // 发票详情
    toDetail(e) {
      if (e.invoiceStatus == 1) {
        this.$Dialog.alert({
          title: "提示信息",
          message: "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。",
        });
      } else {
        this.$router.push(
          "/invoice/details?invoiceRecordId=" + e.invoiceRecordId
        );
      }
    },
    // 确认收货弹框
    showReceiving(e) {
      this.confirmCount = 0;
      this.confirmImg = "";
      this.handleItem = e;
      let arr = this.orderList.filter((item) => {
        return item.orderId == e.orderId;
      });
      arr[0].productList.map((item) => {
        if (item.productType != 1 && item.productType != 4) {
          this.confirmCount += item.productCount;
          if (!this.confirmImg) {
            this.confirmImg = item.productPicture;
          }
        }
      });
      this.$refs["pop_receiving"].show();
    },
    // 关闭确认收货弹框
    closeReceiving() {
      this.$refs["pop_receiving"].hide();
    },
    // 下拉加载
    load() {
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.loading = true;
        this.pageNum++;
        this.getOrderList();
      }
      // if (this.pageNum * this.pageSize < this.total) {
      //   this.pageNum++;
      //   this.getOrderList();
      // }
    },
    // 搜索框失焦
    inputBlur() {
      if (!this.keyword) {
        // this.findStatus = false;
      }
    },
    // 订单搜索
    findItem() {
      // console.log(111);
      if (!this.keyword) {
        return this.$alert("请输入搜索内容");
      }
    },
    // 确认收货
    confirmreceive() {
      this.$ajax({
        url: "/hxclass-mobile/order/confirm-receive",
        method: "put",
        params: {
          orderId: this.handleItem.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (this.index == 2) {
            this.orderList.map((item, index) => {
              if (item.orderId == this.handleItem.orderId) {
                this.orderList.splice(index, 1)
              }
            })
          } else {
            this.getOrderDetail(this.handleItem.orderId);
          }

          // this.handleItem.statusTxt = "交易完成";
          // this.handleItem.status = 5;
          this.closeReceiving();
        }
      });
    },
    // 订单是否包含实物
    getStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i].productType == 2 || e[i].productType == 3) {
          return true;
        }
      }
    },
    // 评价按钮是否展示  商品id 1 2 3 4
    getpjStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (
          e[i].productType == 1 ||
          e[i].productType == 2 ||
          e[i].productType == 3 ||
          e[i].productType == 4
        ) {
          return true;
        }
      }
    },
    // 校验当前商品关联的订单是否支付
    async checkPay(item) {
      // 电子照片
      if (item.orderType == 2) {
        // 电子照-通过id查询电子照链接
        await this.getPicture(item);
        // 电子照-通过id查询用户报名id
        await this.getPhotoSignUpUserId(item);
      }

      // 补考类型
      if (item.orderType == 3) {
        // 补考-通过id查询用户报名id
        await this.getMaskSignUpUserId(item);
      }

      this.$ajax({
        url: "/hxclass-mobile/order/pay/check/product/" + item.orderId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 1跳回考试报名 2跳到收到
          if (item.orderType == 2 || item.orderType == 3) {
            localStorage.setItem('payReturnsign', 2)
            // isOrgOrder 是否机构报名-照片冲印订单
            if (item.orgId) {
              sessionStorage.setItem('isOrgOrder', true)
            } else {
              sessionStorage.setItem('isOrgOrder', false)
            }
          }
          this.$router.push({
            path:
              "/order/orderPayAll?orderId=" +
              item.orderId +
              "&payPrice=" +
              item.payPrice +
              '&orderType=' +
              item.orderType,
          });
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 电子照-通过id查询电子照链接
    async getPicture(item) {
      await this.$ajax({
        url: "/hxclass-mobile/order/get/picture",
        method: "get",
        params: {
          orderId: item.orderId
        }
      }).then((res) => {
        if (res) {
          // 修改用户报名id
          if (sessionStorage.getItem('paySignupObj')) {
            let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
            paySignupObj.picture = res; //电子照
            sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
          } else {
            let paySignupObj = {
              picture: res, //电子照
            };
            sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
          }
        } else {
          // this.$alert(res.message);
        }
      });
    },
    // 电子照-通过id查询用户报名id
    async getPhotoSignUpUserId(item) {
      await this.$ajax({
        url: "/hxclass-mobile/exam/signUp/getExamSignUpInfoByOrderId",
        method: "get",
        params: {
          orderId: item.orderId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 修改用户报名id
          if (sessionStorage.getItem('paySignupObj')) {
            let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
            paySignupObj.signUpId = res.data.signUpId;
            paySignupObj.signupUserId = res.data.signUpUserId;
            paySignupObj.examineeId = res.data.examineeId;
            sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
          } else {
            let paySignupObj = {
              id: res.data.signUpId,
              invoice: false,
              pay: false,
              signUpId: res.data.signUpId,
              signupUserId: res.data.signUpUserId,
              examineeId: res.data.examineeId,
            };
            sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
          }
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 补考-通过id查询用户报名id
    async getMaskSignUpUserId(item) {
      await this.$ajax({
        url: "/hxclass-mobile/exam/signUp/getSignUpUserId",
        method: "get",
        params: {
          orderId: item.orderId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 修改用户报名id
          if (sessionStorage.getItem('payMakeObj')) {
            let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
            payMakeObj.signupUserId = res.data.signUpUserId;
            payMakeObj.configId = res.data.configId;
            sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
          } else {
            let payMakeObj = {
              configId: res.data.configId,
              id: null,
              invoice: false,
              pay: false,
              signupUserId: res.data.signUpUserId
            };
            sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
          }
        } else {
          this.$alert(res.message);
        }
      });
    },
    popUp() {
      this.$refs["pop"].show();
    },
    close() {
      this.reason = "我不想要了";
      this.handleItem = null;
      this.$refs["pop"].hide();
    },
    // 取消订单
    cancelOrder() {
      if (!this.reason) {
        return this.$alert("请选择取消原因");
      }
      this.$ajax({
        url: "/hxclass-mobile/order/cancel",
        method: "put",
        params: {
          orderId: this.handleItem.orderId, // 订单id
          closeReason: this.reason, // 用户取消交易关闭原因：我不想要了，价格有点贵，暂时不需要了，其它
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(this.handleItem);

          // 通知原生刷新优惠券数量
          CouponReceive()

          this.handleItem.statusTxt = "交易关闭";
          this.handleItem.status = 6;
          this.close();
          // this.getOrderList();
        }
      });
    },
    // 关闭弹框
    closeModel() {
      this.warningModel = false;
    },
    // 返回
    back() {
      this.$onReturn(this.$route.query.fromMenu == '1');
    },
    copyOrderCode(e) {
      uni.setClipboardData({
        data: e,
        success: function () {
          // console.log("success");
        },
      });
    },

    // 获取订单列表
    getOrderList() {
      this.$ajax({
        url: "/hxclass-mobile/order/",
        method: "get",
        params: {
          keyword: this.keyword, // 关键字
          type: this.index, //	类型：1.待支付，2.待收货，3.待评价
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.visible = true;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          if (this.pageNum * this.pageSize < this.total) {
            this.finished = false;
          }
          // orderList
          if (this.pageNum == 1) {
            this.orderList = [];
          }
          this.orderList.push(...res.data.records);
          this.loading = false;
          this.$nextTick(() => {
            let contentHeight = document.getElementsByClassName('orderList')[0].clientHeight;
            let listHeight = 0;
            let arr = document.getElementsByClassName('orderItem');
            Object.values(arr).map((item) => {
              listHeight += item.scrollHeight
            })
            if (contentHeight - this.$store.state.stateHeight > listHeight) {
              this.finishedText = '';
            } else {
              this.finishedText = '没有更多了';
            }
          })
        }
        document.getElementsByClassName("van-list")[0].addEventListener("scroll", this.FunHandleScroll);
      });
    },
    // 页面滚动监听方法
    FunHandleScroll() {
      const scrollTop = document.getElementsByClassName("van-list")[0].scrollTop;
      sessionStorage.setItem('scrollTop-courseList', scrollTop)
    },
  },
};
</script>

<style scoped lang="less">
/deep/.main {
  z-index: 1000 !important;
}

/deep/.van-cell {
  padding: 0;
  flex: 1;
  background-color: #eeeeee;
  font-size: 12px;
  margin-left: 7px;
}

/deep/.van-popup--center {
  border-radius: 10px;
}

.popContentBox {
  .popBox {
    .popBox_con {
      text-align: center;

      .con_main {
        position: relative;
        width: 125px;
        font-size: 0;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;

        .main_img {
          width: 125px;
          height: 88px;
          border-radius: 8.5px;
        }

        .main_info {
          position: absolute;
          bottom: 0;
          color: #fff;
          width: 125px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          font-family: PingFangSC-Medium, PingFangMedium;
          background: rgba(0, 0, 0, 0.34);
          border-radius: 0 0 9px 9px;
        }
      }

      .con_info {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 24px;
      }
    }

    .titleBox {
      padding: 28px 18px 0 34px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 25px;
      display: flex;

      span {
        display: inline-block;
      }

      .title {
        flex: 1;
      }
    }

    .popContent {
      clear: both;
      padding: 16px 18px;

      .option {
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
        align-items: center;

        span {
          font-size: 16px;
          color: #333333;
          line-height: 22px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }

      .question {
        span {
          display: block;
        }

        .mess {
          font-size: 16px;
          font-weight: bold;
          color: #666666;
          line-height: 22px;
        }

        .warning {
          margin-top: 3px;
          font-size: 12px;
          color: #EC6C01;
          line-height: 17px;
        }
      }
    }
  }

  .popbutton {
    box-sizing: border-box;
    padding: 5px 40px;
    position: fixed;
    width: 100%;
    bottom: 0;

    .btn {
      // width: 295px;
      width: 100%;
      height: 40px;
      display: block;
      text-align: center;
      margin: 0 auto;
      line-height: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.myOrder {
  flex: 1;
  background: #f7f7f9;
  padding: 0 18px;
  // padding-top: 44px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .orderList {
    flex: 1;
    position: relative;

    /deep/.van-list {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding-bottom: 44px;
    }

    .orderItem {
      padding: 8px 12px 16px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 8px;

      .refund {
        margin-top: 8px;
        display: flex;
        padding: 12px 12px 13px;
        background-color: #f9f9f9;
        border-radius: 4px;

        .refundType {
          font-size: 12px;
          font-weight: bold;
          color: #15b7dd;
          line-height: 17px;
          margin-right: 16px;
        }

        .refundExplain {
          font-size: 12px;
          color: #333333;
          line-height: 17px;
        }
      }

      .orderCode-Type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFangMedium;

        .orderCode {
          font-size: 12px;
          color: #666666;
          line-height: 28px;
          font-weight: 400;
        }

        .type {
          font-size: 12px;
          // font-weight: bold;
          color: #15b7dd;
          line-height: 28px;
        }
      }

      .actual-payment {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        font-weight: 400;
        line-height: 22px;

        a {
          font-size: 14px;
        }
      }

      .shopList {
        .shopItem {
          border-bottom: 1px solid #eee;
          padding-bottom: 12px;
          padding-top: 12px;
          display: flex;

          // align-items: flex-end;
          &:first-child {
            padding-top: 8px;
          }

          &:last-child {
            border: 0;
            padding-bottom: 0;
          }

          img {
            width: 100px;
            height: 70px;
            border-radius: 7px;
            // padding-right: 12px;
            margin-right: 12px;
            background-repeat: no-repeat;
            object-fit: cover;
          }

          .rightMess {
            display: flex;
            justify-content: space-between;
            // flex-wrap: wrap;
            position: relative;
            flex-direction: column; //纵向变横向
            // flex-direction: row-reverse;
            min-height: 70px;
            flex: 1;

            .price {
              position: absolute;
              bottom: 0;
              right: 0;
            }

            .titlePrice {
              display: flex;
              justify-content: space-between;
              font-family: PingFangMedium;
              align-items: center;

              .title {
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                font-weight: 500;
                -webkit-box-orient: vertical;
                /* 设置对齐模式 */
                -webkit-line-clamp: 1;
                /* 设置行数 */
                color: #333333;
                height: 16px;
                line-height: 18px;
              }
            }

            .navNum {
              display: flex;
              justify-content: space-between;
              // margin-top: 9px;
              align-items: center;

              .navTime {
                // padding: 4px 8.5px;
                padding-left: 0;
                border-radius: 2px;
                font-size: 10px;
                color: #999999;
                line-height: 14px;
                width: calc(100% - 30px);
              }

              .num {
                position: relative;
                top: -20px;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
              }
            }

            .offlineAddress {
              // padding: 4px 8.5px;
              padding-left: 0;
              border-radius: 2px;
              font-size: 10px;
              color: #999999;
              line-height: 14px;
            }

            .reimburse {
              // display: inline-block;
              padding: 1px 4px;
              border-radius: 2px;
              // opacity: 0.5;
              border: 1px solid rgba(0, 179, 197, 0.5);
              font-size: 8px;
              color: #15b7dd;
              line-height: 13px;
              font-family: PingFang HK-Regular;
            }
          }
        }
      }

      .btns {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid #eeeeee;
        display: flex;
        justify-content: flex-end;

        .all_span {
          padding: 6px 12px;
          border-radius: 16px;
          font-size: 12px;
          line-height: 17px;
        }

        .ordinary {
          border: 1px solid #eeeeee;
          color: #333333;
          margin-left: 9px;
        }

        .crux {
          border: 1px solid #15b7dd;
          color: #15b7dd;
          min-width: 72px;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }

  .tabNav {
    display: flex;
    // justify-content: space-between;
    padding: 12px 0;

    .tabItem {
      margin-right: 22px;
      font-size: 14px;
      color: #666666;
      line-height: 27px;
      position: relative;
      padding: 0 4px;
    }

    .active {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 2px;
        background-color: rgba(21, 183, 221, 0.4);
        width: 80%;
        height: 4px;
        border-radius: 3.5px;
        filter: blur(2px);
        padding: 0;
        margin: 0 auto;
        margin-top: -4px;
      }
    }
  }
}

.nav {
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;

  img {
    width: 16px;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    padding: 0 4px;
  }

  .findInput {
    display: flex;
    align-items: center;
    margin-left: 8px;
    flex: 1;
    height: 32px;
    box-sizing: border-box;
    padding: 9px 15px;
    background: #eeeeee;
    border-radius: 6px;

    img {
      width: 14px;
    }

    /deep/.uni-input-input {
      margin-left: 7px;
      width: 266px;
      font-size: 12px;
    }

    .uni-input-placeholder {
      font-size: 12px;
      margin-left: 7px;
    }
  }
}

// 提示信息
.box_popup {
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;

  .popup_title {
    display: flex;
    align-items: center;

    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }

  .popup_con {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    margin-top: 22.5px;
  }

  .popup_button {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }

    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}

// 支付文字
.price {
  font-size: 10px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  float: right;
}
</style>
