<template>
	<div class="container-box">
		<div class="main-box">
			<div class="title-box">
				{{data.title || data.articleTitle}}
			</div>
			<div class="msg-box">
				<div class="source">{{data.source}}</div>
				<!-- <div class="num">{{data.collectionNum==null?'0':data.collectionNum}}人已预览</div> -->
				<div class="num" v-if="!type">{{data.views==null?'0':data.views}}人已预览</div>
				<div class="num" v-else>{{data.pageviews==null?'0':data.pageviews}}人已预览</div>
				<div class="date" v-if="!type">{{data.createTime}}</div>
				<div class="date" v-else>{{data.publishTime}}</div>
			</div>
			<div class="content-box">
				<span v-if="!type" v-html="data.appDetails"></span>
				<span v-else v-html="data.mobileContent"></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Object,
				default: () => ({}),
			},
			type: {
				type: Boolean,
				default: () => (false),
			},
		}
	}
</script>



<style lang="less" scoped>
	.container-box{
		border-top: 1px solid #F8F8F8;
	}
	.main-box {
		margin: 15px 16px;

		.title-box {
			font-size: 22px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
			color: #333333;
			line-height: 33px;
		}

		.msg-box {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;
			font-size: 13px;
			font-family: PingFangMedium;
			font-weight: 400;
			color: #666666;
			line-height: 18px;
		}

		.content-box {
			margin-top: 32px;
			font-size: 16px;
			font-family: PingFangMedium;
			font-weight: 400;
			color: #333333;
			line-height: 30px;
			/deep/ .v-note-wrapper{
				box-shadow: none !important;
			}
			/deep/ .v-show-content{
				background-color: unset !important;
				box-shadow: none;
				padding: 0 !important;
			}
			/deep/ .v-note-wrapper{
				background-color: unset !important;
			}
			/deep/strong{
				font-weight: bold;
			}
			/deep/em{
				font-style: italic;
			}
			/deep/img{
				max-width: 100%;
			}
			/deep/.remarks{
				max-width: 100%;
				height: auto !important;
				-webkit-line-clamp: 10000 !important;
			}
			/deep/p {
        word-break: break-all;
        word-wrap: break-word;
      }
      /deep/h4 {
        font-weight: bold;
      }
		}
	}
</style>
