<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="支付成功"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="goHome()"
    />
    <div class="header-box">
      <!-- <div class="header-left" @click="goHome()">
        <van-icon color="#fff" size="15" name="arrow-left" />
      </div> -->
      <div style="padding: 0 50px">
        <div class="imgbox">
          <div class="title">
            <img src="@/assets/image/config/Group9140.png" width="22" alt="" />
            支付成功
          </div>
          <div class="figure">
            <div class="circular" style="left: -5px"></div>
            <div class="thread"></div>
            <div class="circular" style="right: -5px"></div>
          </div>
          <div class="qrcode" @touchstart="touchStart()">
            <img :src="imgUrl" alt="" />
          </div>

          <div class="Tip">长按二维码保存图片</div>
        </div>
      </div>
      <div class="txt2">
        <div>{{ txt }}</div>
      </div>
      <div class="btn">
        <div class="next-btn">
          <button
            type="default"
            class="all-btn-bigCircle"
            @click="goHome()"
            native-type="submit"
          >
            返回首页
          </button>
        </div>
      </div>
    </div>

    <!-- 提示信息 -->
    <van-popup v-model="show" :round="true">
      <div class="box_popup">
        <div class="popup_con">是否保存二维码到本地相册？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="show=false">取消</span>
          <span class="all-btn-middle btn2" @click="downImg()">保存二维码</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import { FunGoHome, downLoadImg } from "@/assets/js/NativeWebKit.js";
import { FunJudgDevice } from "@/unit/fun.js";
export default {
  data() {
    return {
      imgUrl: null,
      txt: null,
      imgUrl64: "",
      show: false,
    };
  },
  methods: {
    // 查询有无客服
    getcustomer(e) {
      this.$ajax({
        url: "/hxclass-mobile/order/product/getCustomer?orderId=" + e,
      }).then((res) => {
        // console.log(res)
        if (res.code == "200" && res.success) {
          this.imgUrl = res.data.customerCode;
          this.txt = res.data.customerIntroduce;
        }
      });
    },

    goHome() {
      FunGoHome();
    },
    //手指离开
    touchEnd() {
      console.log("ese");

      clearTimeout(this.Loop);
    },
    //手指触摸
    touchStart() {
      clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
      this.Loop = setTimeout(() => {
        this.show = true;
      }, 1000);
    },

    // 下载
    downImg() {
      const type = FunJudgDevice();
      // ios 安卓处理
      if ((type == "ios" && window.webkit) || type == "android") {
        downLoadImg(this.imgUrl);
      } else {
        this.downloadIamge(this.imgUrl, this.txt);
      }
      this.show = false;
    },
  },
  mounted() {
    let orderId = this.$route.query.orderId;
    this.getcustomer(orderId);
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
html,
body,
#app {
  height: 100%;
}
.container-box {
  overflow: hidden;
  height: 100vh;
  .header-box {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f9f8fd;
    overflow: hidden;
    .header-left {
      position: absolute;
      top: 32px;
      left: 18px;
    }
    .imgbox {
      width: 100%;
      border-radius: 10px;
      padding: 16px 0;
      margin: 0 auto;
      margin-top: 10%;
      position: relative;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      .qrcode {
        margin: 8px auto;
        margin-top: 25px;
        text-align: center;
        position: relative;
        height: 203px;
        width: 203px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: url(~@/assets/image/config/back143027.png) no-repeat;
        background-size: 100% 100%;
        > img {
          position: relative;
          z-index: 4;
          width: 185px;
          height: 185px;
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .title {
        width: 100%;
        height: 42px;
        text-align: center;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 11px;
        letter-spacing: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          margin-right: 3px;
        }
      }

      .figure {
        position: relative;
        width: 100%;
        height: 10px;
        .circular {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: #f9f8fd;
          position: absolute;
          top: -5px;
        }

        .thread {
          width: 100%;
          background-color: #f9f8fd;
          height: 2px;
        }
      }
      .Tip {
        text-align: center;
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        letter-spacing: 5px;
      }
    }
    .txt1 {
      text-align: center;
      margin-top: 19px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #ffffff;
    }
    .txt2 {
      text-align: center;
      margin-top: 10px;
      font-family: "PingFang SC";
      font-style: normal;
      line-height: 150%;
      word-wrap: break-word;
      color: #333333;
      padding: 0 50px;
      > div {
        background-color: #ffffff;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        padding: 10px;
      }
    }
    .btn {
      position: fixed;
      bottom: 39px;
      width: 100%;
      text-align: center;
      > span {
        width: 295px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        margin: 0 auto;
        display: inline-block;
        border-radius: 30px;
        background-color: #15b7dd;
        color: #ffffff;
      }
    }
  }
  // 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
}

.popup {
  margin: 0 auto;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 18px;
  border-top: #999999 1px solid;
}
.next-btn {
  text-align: center;
  padding-bottom: 30px;
}
.all-btn-bigCircle {
  border: #ffffff solid 1px;
}
</style>