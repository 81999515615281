export default{
  // 公共的状态
  state: {
    /**
     * 音频配置
     * @param {Object} Audio // 音频对象
     * @param {String} id // 唯一标识
     * @param {String} src // 音频的数据链接，用于直接播放
     * @param {Number} startTime // 开始播放的位置（单位：s），默认 0
     * @param {Boolean} autoplay // 是否自动开始播放，默认 false
     * @param {Number} duration // 当前音频的长度（单位：s），只有在当前有合法的 src 时返回
     * @param {Number} currentTime // 当前音频的播放位置（单位：s），只有在当前有合法的 src 时返回，时间不取整，保留小数点后 6 位
     * @param {Boolean} paused // 当前是是否暂停或停止状态，true 表示暂停或停止，false 表示正在播放
     * @param {Number} volume // 音量。范围 0~1。
     * @param {Boolean} loop // 是否循环播放，默认 false
     * @param {String} title // 当前音频的名称
     * @param {Boolean} show // 是否显示当前音频播放栏，默认 false
     */
		Audio: null,
    id: null,
    src: '',
    startTime: 0,
    autoplay: false,
    duration: 0,
    currentTime: 0,
    paused: true,
    volume: 0,
    loop: false,
    title: '',
    show: false,
  },
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {
    // 初始化
    initAudio(state,params){
      let audio = document.createElement('audio')
      let body = document.getElementsByTagName('body')[0]
      audio.setAttribute('id','Audio')
      audio.setAttribute('autoplay', state.autoplay)
      if(state.loop){
        audio.setAttribute('loop', state.loop)
      }else{
        audio.removeAttribute('loop')
      }
      audio.setAttribute('src', state.src)
      body.appendChild(audio)
      let Audio = document.getElementById('Audio')
      // 播放事件
      Audio.addEventListener('play', res=>{
        state.paused = false
      })
      // 停止事件
      Audio.addEventListener('pause', res=>{
        state.paused = true
      })
      // 时间更新
      Audio.addEventListener('timeupdate', res=>{
        state.currentTime = res.target.currentTime
      })
      // 加载元数据
      Audio.addEventListener('loadedmetadata', res=>{
        state.duration = res.target.duration
      })
      state.Audio = Audio
    },

    /**
     * 更新播放信息
     */
    updateAudio(state, params){
      if(params && params.src){ // 更新播放路径
        state.src = params.src
        state.Audio.src = params.src
      }else if(params && params.src==''){
        state.src = params.src
        state.paused = true
        state.Audio.src = params.src
      }
      if(params && params.title){ // 更新标题
        state.title = params.title
      }
      if(params && params.show!=undefined){ // 更新显示隐藏状态
        state.show = params.show
      }
      if(params && params.currentTime){ // 更新播放时间
        state.currentTime = params.currentTime
        state.Audio.currentTime = params.currentTime
      }
    },

    /**
     * 播放
     */
    play(state, params){
      if(state.Audio){
        state.Audio.play()
      }else{
        this.commit('initAudio')
      }
    },
    
    /**
     * 暂停
     */
    pause(state, params){
      state.Audio.pause()
    },

    /**
     * 跳转到指定位置，单位 s
     */
    seek(state, params){
      state.currentTime = params.currentTime
    }
  },
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {},
  // 嵌套模块
  modules: {}
}