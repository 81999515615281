<template>
  <div class="homeNewTab">
    <div class="border"></div>
    <!-- tab选项 -->
    <div class="tab">
      <div class="children">
        <div class="item" 
          :style="{
            width: item.label ? item.label.length + 'em' : ''
          }"
          v-for="item in tabList"
          :key="item.value"
          @click="onClickTabs(item.value, $event)"
          :class="value == item.value ? 'click' : ''"
        >
          {{ item.label }}
        </div>
      </div>
      <slot name="link">
        <router-link v-if="link.to" :to="link.to" tag="a">
          <span>{{link.title}}</span>
          <van-icon name="arrow" color="#999" size="14" />
        </router-link>
      </slot>
    </div>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    tabList: {
      type: Array,
      default: []
    },
    defaultValue: {
      type: String,
      default: null
    },
    link: {
      type: Object,
      default: ()=>{}
    }
  },
  // 数据对象
  data () {
    return {
      value: null,
    }
  },
  // 事件处理器
  methods: {
    // 点击切换tab
    onClickTabs(e) {
      this.value = e;
      this.$emit("change", e);
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.value = this.defaultValue
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    defaultValue(e){
      this.value = e
    },
    link(e){
      this.link = e
    },
  }
}
</script>

<style lang="less" scoped>
.homeNewTab{
  padding: 20px 0 0 25px;
  position: relative;
  background-color: #fff;
  min-height: 160px;
  margin-top: 8px;
  .border{
    pointer-events: none;
    display: block;
    position: absolute;
    left: 10px;
    top: 44px;
    right: 10px;
    bottom: 0;
    background-image: url(./../assets/image/20220626-155640.png);
    background-size: 18px;
    background-repeat: no-repeat;
    &::before{
      content: '';
      background-image: url(./../assets/image/20220626-155656.png);
      background-size: 100% 100%;
      display: block;
      position: absolute;
      left: 18px;
      top: 0;
      right: 0;
      height: 4px;
    }
    &::after{
      content: '';
      background-image: url(./../assets/image/20220626-155651.png);
      background-size: 100% 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 17px;
      width: 4px;
      bottom: 10%;
    }
  }
  .tab{
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    .children{
      white-space: nowrap;
      overflow: hidden;
      flex: 1;
      .item{
        display: inline-block;
        white-space: nowrap;
        background: linear-gradient(
          to top,
          rgba(@theme, 0.64),
          @theme,
          rgba(@theme, 0.64)
        );
        color: #fff;
        font-size: 14px;
        // font-family: PingFangMedium;
        font-family: PingFang SC-Medium, PingFang SC;
        align-items: center;
        position: relative;
        padding: 0 26px;
        border-radius: 9px 9px 0 0;
        height: 20px;
        line-height: 20px;
        vertical-align: bottom;
        &:first-child{
          border-top-left-radius: 9px !important;
          padding-left: 20px !important;
        }
        &.click{
          font-size: 16px;
          font-weight: 600;
          text-shadow: 0px 2px 2px rgba(1, 49, 61, 0.19);
          position: relative;
          z-index: 9;
          background: #1E97B4;
          border-radius: 0;
          padding: 0 10px;
          bottom: -1px;
          height: 24px;
          line-height: 30px;
          &::after,
          &::before {
            content: "";
            width: 22px;
            display: block;
            background-image: url("../assets/image/202201261157.png");
            position: absolute;
            top: 0;
            right: -22px;
            background-size: 100%;
            z-index: 9;
            height: 100%;
          }
          &::before {
            left: -22px;
            transform: rotateY(180deg);
          }
        }
      }
    }
    a{
      font-size: 12px;
      color: #999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-right: 18px;
    }
  }
}
</style>
