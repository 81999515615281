<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="证书详情"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <div class="all-title">基本信息：</div>
      <div class="all-white-bg">
        <div class="detail-box">
          <div class="header-inner">
            <div class="side-left"></div><div class="name"><span class="span">{{form.name}}</span></div>
            <div class="side-right"></div><div class="card">{{EncryptCert(form.idNumber)}}</div>
          </div>
          <!-- <span class="company">工作单位：星空教育</span> -->
        </div>
      </div>
      
      <!-- 证书信息 -->
      <div class="information-box">
        <div class="all-title">证书信息：</div>
        <img class="cover-img" :src="form.certificatePhoto" />
        <div class="foo-inner">
          <div class="certificate-name">{{form.certificateName}}</div>
          <div class="flex">
            <span class="number">证书编号：{{form.certificateCode}}</span>
            <span class="time">发证时间：{{form.certificateTime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      form:{}
    }
  },
  // 事件处理器
  methods: {
    // 查询证书
    getSearchDetail(id){
      this.$ajax({
        url: '/hxclass-mobile/cncard/certificate/query/' + id,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data
        }
      })
    },
    // 身份证号加密
    EncryptCert(cert){
      return cert.replace(/^(.{3})(?:\d+)(.{3})$/, "$1*********$2")
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getSearchDetail(this.$route.query.id)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import "./select.less";
  .information-box{
    margin-top: 18px;
    .all-title{
      margin-bottom: 14px;
    }
    .cover-img{
      width: 100%;
    }
    .foo-inner{
      margin-top: 14px;
      font-family: PingFangMedium;
      .certificate-name{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
      }
      .flex{
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        font-size: 10px;
        font-weight: 500;
        color: #666666;
        line-height: 15px;
        span:nth-child(1){
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
</style>
