<template>
  <!-- 确认付款 -->
  <div class="orderPayConfirm">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="确认付款"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$router.push({name:'keepAliveMyOrder',params:{keepAlive:true}})"
    />

    <div class="box1">
      <van-icon class="box_icon" name="question" size="14px" color="#6E6F70" />
      <div class="box_info">您需要转账<span class="light">{{$route.query.payPrice}}</span>元至以下账户，转账成功后及时填写回款凭证（请勿使用微信或支付宝进行汇款）</div>
    </div>

    <div class="box2">
      <div class="box_item">收款户名：华夏出版社有限公司</div>
      <div class="box_item">收款银行：中国工商银行北京东四支行</div>
      <div class="box_item">银行账号：0200 0041 0900 4621 597</div>
    </div>

    <div class="box3">
      已完成转账请填写以下信息：
    </div>

    <div class="box4">
      <div class="box_item">
        <span class="item_name"><span class="item_name_star">*</span>付款人户名：</span>
        <van-field v-model="accountName" placeholder="请输入付款人户名" />
      </div>
      <div class="box_item">
        <span class="item_name"><span class="item_name_star">*</span>开户行：</span>
        <van-field v-model="bankName" placeholder="请输入开户行" />
      </div>
      <div class="box_item">
        <span class="item_name"><span class="item_name_star">*</span>付款人账号：</span>
        <van-field v-model="accountNumber" placeholder="请输入付款人账号" type="number" />
      </div>
      <!-- <div class="box_item">
        <span class="item_name">*应付金额：</span>
        <van-field v-model="price" format-trigger="onBlur" :formatter="formatter" placeholder="请输入应付金额" type="number" />
      </div> -->
      <p class="item_title">上传支付凭证：</p>
      <div class="box_item noneBorder">
        <van-uploader :before-read="beforeRead" :after-read="uploadImg" v-show="picture.length<3">
          <div class="list_upload">
            <img class="upload_icon" src="@/assets/image/study/cmr_upload.png" />
            <span class="upload_txt">上传图片</span>
          </div>
        </van-uploader>
        <div class="list_upload_imgbox" v-for="(item,index) in picture" :key="index">
          <img class="list_upload_img" :src="item"/>
          <img class="closeIcon" @click="deletePictrue(index)" src="@/assets/image/study/closeIcon.png" />
        </div>
      </div>
      <p class="warn"><van-icon name="warning-o" /><span>最多上传3张凭证照片</span></p>
    </div>

    <div class="box_button">
      <div class="all-btn-bigCircle" @click="submitTransfer">提交</div>
    </div>
  </div>
</template>

<script>
import { dataURLtoFile } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      accountName: '', //户名
      bankName: '', //开户行
      accountNumber: '', //付款人账号
      price: '', //应付金额
      orderId:null, // 订单id
      picture:[], // 支付凭证
      orderType: 0, //订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
      signupUserId: null, //用户报名id
    }
  },
  // 事件处理器
  methods: {
    deletePictrue(i){
      console.log(i)
      this.picture.splice(i,1);
    },
    // 价格格式化
    formatter(e){
      if(!e){
        return '';
      }
      return (e*1).toFixed(2)
    },
    // 上传图片
    uploadImg(e, type) {
      // console.log(e)
      // type 1安卓上传
      let file = type == 1 ? e : e.file
      // return
      this.$upload({
        file: file,
        type: 'image',
        progress: (e)=>{
        },
        success: (e)=>{
          this.picture.push(e);
        }
      })
    },
    beforeRead(file){
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$Toast("请上传正确格式图片文件");
      }
      const isLt2M = file.size / 1024 / 1024 < 2.5;
      if (!isLt2M) {
        this.$Toast("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 提交对公转账信息
    submitTransfer(){
      if(!this.accountName){
        return this.$Toast('请输入户名')
      }
      if(!this.bankName){
        return this.$Toast('请输入开户行')
      }
      if(!this.accountNumber){
        return this.$Toast('请输入付款人账号')
      }
      // if(!this.price){
      //   return this.$Toast('请输入应付金额')
      // }
      // if(this.price<0.01){
      //   return this.$Toast('应付金额不能小于0.01')
      // }

      this.$ajax({
        url:"/hxclass-mobile/order/transfer",
        method:'post',
        params:{
          accountName:this.accountName, // 户名
          bankName:this.bankName, // 开户行
          accountNumber:this.accountNumber, // 付款人账号
          // price:(this.price*1).toFixed(2), // 应付金额
          img:this.picture.join(','),
          orderId:this.$route.query.orderId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$router.push('/order/corporatetransfer')
          // this.$Toast('您已成功提交支付信息，我们会尽快为您处理，请耐心等候。');
        }else{
          this.$Toast(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.query.orderId){
      this.orderId = this.$route.query.orderId;
      this.orderType = this.$route.query.orderType;
    }

    // 安卓选择照片后回调
    window.returnPhotoUrl = (url)=>{
      var file = dataURLtoFile(url, 'fileName.jpg')
      // 上传图片
      this.uploadImg(file, 1)
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box}
  .list_upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 86px;
    height: 86px;
    background: #F2F2F2;
    .upload_icon {
      width: 36px;
    }
    .upload_txt {
      margin-top: 3px;
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 15px;
    }
  }
  .van-uploader{
    // flex: 1;
    margin-right: 14px;
  }
  /deep/.van-uploader__input-wrapper {
    width: 100%;
  }
  .list_upload_imgbox{
    width: 86px;
    height: 86px;
    position: relative;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    .closeIcon{
      pointer-events: auto !important;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
    }
    
  }
  .list_upload_imgbox:last-child{
    margin-right: 0;
  }
  .list_upload_img {
    box-sizing: border-box;
    max-width: 86px;
    max-height: 86px;
  }
  .orderPayConfirm {
    padding: 18px 18px 100px 18px;
    .box1 {
      display: flex;
      .box_icon {
        line-height: 21px;
        margin-right: 4px;
      }
      .box_info {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        .light {
          color: #EC6C01;
          margin: 0 5px;
        }
      }
    }
    .box2 {
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 1px rgba(93,131,167,0.1);
      border-radius: 5px;
      margin-top: 14px;
      padding: 18px;
      .box_item {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-top: 18px;
        &:first-child {
          margin: 0;
        }
      }
    }
    .box3 {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-top: 18px;
    }
    .box4 {
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 1px rgba(93,131,167,0.1);
      border-radius: 10px;
      margin-top: 14px;
      padding: 0 14px;
      .item_title{
        width: 100px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        margin-top: 13px;
      }
      .box_item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E5E5E5;
        padding: 14px 0;
        &:last-child {
          border: 0;
        }
        .item_name {
          width: 100px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          text-align-last:justify;
          .item_name_star {
            color: red;
          }
        }
        .van-cell {
          flex: 1;
          padding: 0;
        }
      }
      .warn{
        color: @theme;
        font-size: 10px;
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        span{
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 12px;
          margin-left: 2px;
        }
      }
      .noneBorder{
        border: 0;
        padding-bottom: 10px;
        flex-wrap: wrap;
      }
    }
    .box_button {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 10px 40px;
      .all-btn-bigCircle {
        width: 100%;
      }
    }
  }

</style>
