var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"retrievePassword"},[_c('van-nav-bar',{staticClass:"nav-circular",attrs:{"title":"找回密码","left-text":"","right-text":"","fixed":true,"placeholder":true,"left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.nextStatus),expression:"!nextStatus"}],staticClass:"content"},[_c('div',{staticClass:"describe"},[_vm._v("请输入您要找回密码的手机号")]),_c('div',{staticClass:"form"},[_c('p',{staticClass:"phoneType",on:{"click":_vm.showPopup}},[_vm._v(_vm._s(_vm.popCode[_vm.popIndex].code))]),_c('img',{attrs:{"src":require("@/assets/image/login/icon1.png")},on:{"click":_vm.showPopup}}),_c('van-field',{attrs:{"label":"","placeholder":"请输入手机号","clearable":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"form"},[_c('van-field',{attrs:{"label":"","maxlength":"6","placeholder":"请输入验证码"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{on:{"click":function($event){return _vm.onDxCode()}}},[(_vm.isDxCode)?_c('p',{staticClass:"getCode",staticStyle:{"color":"#00b3c5"}},[_vm._v("获取验证码")]):_c('p',{staticClass:"getCode",staticStyle:{"color":"#999"}},[_vm._v("重新发送 "+_vm._s(_vm.countdown))])])],1),_c('van-button',{ref:"gosetButton",staticClass:"btn",style:({"background": _vm.status?"linear-gradient(120deg, #15B7DD 0%, rgba(21, 183, 221, 0.83) 100%)"
            : "#eeeeee",
            color: _vm.status ? "#FFFFFF" : "#666666"}),attrs:{"plain":"","type":""},on:{"click":_vm.gosetPassword}},[_vm._v("下一步")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dragShow),expression:"dragShow"}],staticClass:"drag_box"},[_c('drag-verify',{ref:"dragVerify7",attrs:{"width":_vm.pageWidth,"isPassing":_vm.isPassing7,"handlerBg":"#C7C7C7","text":"请按住滑块拖动","successText":"验证通过"},on:{"update:isPassing":function($event){_vm.isPassing7=$event},"update:is-passing":function($event){_vm.isPassing7=$event},"passcallback":_vm.passcallback}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nextStatus),expression:"nextStatus"}],staticClass:"content"},[_c('div',{staticClass:"describe"},[_vm._v("请为您的账号设置新密码")]),_c('div',{staticClass:"form"},[_c('van-field',{class:{'item_password':_vm.inputStatus},attrs:{"maxlength":"16","label":"","placeholder":"8-16位数字、字母的组合密码"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{on:{"click":function($event){_vm.inputStatus = !_vm.inputStatus}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputStatus),expression:"inputStatus"}],staticClass:"inputStatus",attrs:{"src":require("@/assets/image/login/password_icon1.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inputStatus),expression:"!inputStatus"}],staticClass:"inputStatus",attrs:{"src":require("@/assets/image/login/password_icon2.png")}})])],1),_c('div',{staticClass:"form"},[_c('van-field',{class:{'item_password':_vm.inputStatus},attrs:{"maxlength":"16","label":"","placeholder":"再次输入密码"},model:{value:(_vm.enterpassword),callback:function ($$v) {_vm.enterpassword=$$v},expression:"enterpassword"}})],1),_c('button',{staticClass:"btn",style:({
          background: _vm.passwordStatus
            ? "linear-gradient(120deg, #15B7DD 0%, rgba(21, 183, 221, 0.83) 100%)"
            : "#eeeeee",
          color: _vm.passwordStatus ? "#FFFFFF" : "#666666"
        }),attrs:{"type":"default"},on:{"click":_vm.changePassword}},[_vm._v(" 确定 ")])]),(_vm.verificationShow)?_c('div',{staticClass:"puzzle-box"},[_c('div',{staticClass:"puzzle_content",staticStyle:{"width":"295px","height":"500px"}},[_c('puzzleVerification',{staticStyle:{"display":"block"},attrs:{"verificationShow":true,"blockType":"puzzle","onSuccess":_vm.handleSuccess,"onError":_vm.handleError,"puzzleImgList":_vm.puzzleImgList}})],1)]):_vm._e(),_c('n-transition',{ref:"pop",attrs:{"speed":"ease-in-out","height":_vm.popHeight,"maskVal":0.35}},[_c('div',{staticClass:"pop_box"},[_c('div',{staticClass:"box_list"},_vm._l((_vm.popCode),function(item,index){return _c('div',{key:index,staticClass:"list_item",on:{"click":function($event){return _vm.changeCode(index)}}},[_c('p',{staticClass:"item_txt"},[_vm._v(_vm._s(item.country))]),_c('p',{staticClass:"item_txt"},[_vm._v(_vm._s(item.code))])])}),0),_c('div',{staticClass:"box_button",on:{"click":function($event){return _vm.hidePopup()}}},[_vm._v("取消")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }