<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="教师详情"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <div class="all-title">基本信息：</div>
      <div class="all-white-bg">
        <div class="detail-box">
          <div class="line"><span>姓名：</span>{{name}}</div>
          <div class="line"><span>性别：</span>{{sex}}</div>
          <div class="line"><span>身份证：</span>{{manageIdcard(idcard)}}</div>
        </div>
      </div>
      <!-- 证书信息 -->
      <div class="information-box">
        <!-- 切换框 -->
        <SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />

        <!-- <div class="tab">
          <div 
            class="tab_item" 
            :class="{'pick': tabIndex==index}"
            v-for="(item, index) in tabList" 
            :key="index"
            @click="changeTab(index)">{{item.type}}</div>
        </div> -->
        <div 
          class="box_con"
          v-for="(item, index) in certifyVoList" 
          :key="index">
          <img 
            class="cover-img" 
            :src="item.picture"
            @click="showImg(item.picture)" />
          <div class="foo-inner">
            <div class="certificate-name">{{item.name}}</div>
            <div class="flex" v-if="item.typeTxt!=='个人证书'">
              <span class="number">证书编号：{{item.code}}</span>
              <span class="time">所获时间：{{manageDate(item.getcertifTime)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwichTab from "@/components/SwichTab.vue";

export default {
  // 可用组件的哈希表
  components: { SwichTab },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      userId: '', //用户id
      name: '', //姓名
      sex: '', //性别
      idcard: '', //身份证号

      // tab
      tabIndex:0,
      tabList:[
        // {title:'学习证书'},
        // {title:'考试证书'},
        // {title:'网络证书'}
      ],

      // 列表
      certifyVoList: [],
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    let query = this.$route.query
    // 用户id
    this.userId = query.id
    // 姓名
    this.name = query.name
    // 性别
    this.sex = query.sex
    // 身份证号
    this.idcard = query.idcard
    // 教师详情
    this.getDetail()
  },
  // 事件处理器
  methods: {
    // 教师详情
    getDetail() {
      this.$ajax({
        url: '/hxclass-mobile/user/certify/user/detail',
        method: "get",
        params: {
          userId: this.userId, //用户id
        }
      }).then((res) => { 
        // console.log(res)
        if (res.code == 200 && res.success) {
          // this.tabList = res.data
          this.tabList = res.data.map(item=>{
           	return {
              ...item,
				    	name: item.type,
				    }
          })
          this.certifyVoList = this.tabList[0].certifyVoList
        } else {
          this.$alert(res.message);
        }
      })
    },
    // 处理身份证
    manageIdcard(con) {
      if (!con) {return}
      return con.substring(0,6)+'********'+con.substring(14,con.length)
    },
    // 处理日期
    manageDate(con) {
      if (!con) {return}
      let year = con.split('-')[0]
      let month = con.split('-')[1]
      return year+'年'+month+'月'
    },
    // 预览证书
    showImg(img) {
      this.$ImagePreview([img]);
    },
    // 切换tab
    changeTab(index) {
      this.tabIndex = index
      // this.certifyVoList = this.tabList[index].certifyVoList
    },
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    tabIndex(newValue){
      this.certifyVoList = this.tabList[newValue].certifyVoList
    }
  }
}
</script>

<style lang="less" scoped>
@import "./select.less";
  .detail-box{
    padding-left: 14px !important;
    padding-bottom: 14px !important;
    padding-top: 0 !important;
  }
  .information-box{
    margin-top: 18px;
    .tab {
      padding: 0;
    }
    // 切换框
    // .tab {
    //   display: flex;
    //   align-items: center;
    //   font-family: PingFangMedium;
    //   padding: 11.5px 6px;
    //   .tab_item {
    //     font-size: 14px;
    //     font-weight: 400;
    //     color: #666666;
    //     margin-right: 27px;
    //     &.pick {
    //       font-size: 16px;
    //       font-weight: bold;
    //       color: #333333;
    //       line-height: 27px;
    //       background-image: url(../../assets/image/examination/certificate_icon1.png);
    //       background-size: 36.5px;
    //       background-repeat: no-repeat;
    //       background-position: bottom center;
    //     }
    //   }
    // }
    .box_con {
      margin-bottom: 20px;
    }
    .all-title{
      margin-bottom: 14px;
    }
    .cover-img{
      width: 100%;
    }
    .foo-inner{
      margin-top: 14px;
      font-family: PingFangMedium;
      .certificate-name{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
      }
      .flex{
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        font-size: 10px;
        font-weight: 500;
        color: #666666;
        line-height: 15px;
        span:nth-child(1){
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
</style>
