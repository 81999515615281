<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="学习打卡"
      right-text="打卡记录"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="onRight"
    />
    <div class="content-box">
      <!-- 周打卡 日历 -->
      <div class="week-record-box">
        <div class="item" v-for="(item,index) in weekDayArry" :key="index">
          <div class="week">{{item.week}}</div>
          <div class="num" :class="item.isClock?'clockNow':item.isToday?'today':''">{{item.isToday && item.isClock?'今':item.number}}</div>
        </div>
      </div>
      <!-- 签到记录 -->
      <div class="front-end-box">
        <div class="top-wrap">
          <!-- <div class="side side-left">
            <div class="div" @click="onShare()">
              <img class="share-icon" src="@/assets/image/icon/20220215041509.png" />
              分享我的成就
            </div>
          </div> -->
          <div></div>
          <div class="side">
            签到提醒<van-switch class="swich" active-color="#15B3DD" @change="onSwichChange" v-model="isSignIn" size="17" />
          </div>
        </div>
        <div class="bottom-wrap">
          <div class="center">
            <img class="rili" src="@/assets/image/icon/20220215041510.png" />
            <div class="font">
              您已连续打卡<span >{{sumCount}}</span>天
            </div>
          </div>
          <span class="clock-btn" :class="{'clock-btn-hover' : isClock}" @click="onOpenPopup()">{{isClock?'已打卡':'打卡'}}</span>
          <div class="circle-wrap">
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
        </div>
      </div>
      <!-- 奖项设置 -->
      <div class="all-bg prize-box" v-show="false">
        <!-- 连接线 -->
        <div class="circle-wrap">
          <span class="circle"></span>
          <span class="circle"></span>
        </div>
        <span class="title">奖项设置</span>
        
        <!-- 奖项列表 -->
        <div class="line-list">
          <div class="line-item" v-for="item in prizeData" :key="item.prizeId">
            <van-image
              class="cover-img"
              fit="cover"
              :src="require('@/assets/image/icon/20220215041511.png')"
            />
            <div class="center">
              <div class="name">{{item.prizeName}}</div>
              <div class="clock">连续打卡{{item.punchDays}}天</div>
            </div>
            <span class="btn" @click="onReceive(item)" :class="{'success':(item.status == 1 || sumCount >= item.punchDays)}">{{item.status ? '已领取' : ( sumCount >= item.punchDays) ? '领取' :'未完成'}}</span>
          </div>
        </div>
      </div>
      <!-- 打卡规则 -->
      <div class="all-bg rule-box">
        <!-- 连接线 -->
        <div class="circle-wrap">
          <span class="circle"></span>
          <span class="circle"></span>
        </div>
        <div class="block">
          <div class="title">
            <img class="icon" src="@/assets/image/icon/20220216041501.png"  />
            <span>打卡说明</span>
          </div>
          <div class="font">
            <div class="p">每日完成规定的打卡规则后可进行打卡，每日打卡可获得五个学习积分。</div>
          </div>
        </div>
        <div class="block" style="margin-top: 9px;">
          <div class="title">
            <img class="icon" src="@/assets/image/icon/20220216041501.png" />
            <span>打卡规则</span>
          </div>
          <div class="font">
            <div class="p" style="margin-bottom: 8px;">激活当日打卡规则</div>
            <div class="p">1、当日登录APP即可激活当日打卡。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 打卡弹窗 -->
    <van-overlay :show="isPopupClock">
      <div class="wrapper">
        <div class="flip-container"  >
          <!-- :class="{'flipActive':rotateClock}"  -->
          <div class="flipper">
            <div class="front">
              <!-- 前面内容 -->
              <div class="content">
                <div class="success">打卡成功</div>
                <div class="sign-in">恭喜您，签到成功</div>
                <div class="integral">签到积分：<span>+5</span></div>
                <!-- <div class="share-btn" @click="onShare()">分享成就</div> -->
              </div>
            </div>
            <div class="back">
              <!-- 背面内容 -->
              <div class="content">
                <div class="success">打卡成功</div>
                <div class="sign-in">恭喜您，签到成功</div>
                <div class="integral">签到积分：<span>+5</span></div>
                <!-- <div class="share-btn" @click="onShare()">分享成就</div> -->
              </div>
            </div>
          </div>
          <van-icon class="close-icon" @click="onClosePopup()" size="32" name="close" color="#fff" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        isSignIn:false, // 是否提醒签到
        weekDayArry:[ // 本周日期
          {week:'一', number:'',isClock:false,isToday:false},
          {week:'二', number:'',isClock:false,isToday:false},
          {week:'三', number:'',isClock:false,isToday:false},
          {week:'四', number:'',isClock:false,isToday:false},
          {week:'五', number:'',isClock:false,isToday:false},
          {week:'六', number:'',isClock:false,isToday:false},
          {week:'日', number:'',isClock:false,isToday:false},
        ],
        isClock:true, //是否打卡
        isPopupClock:false,
        rotateClock: false,
        sumCount:0,
        prizeData:[], // 奖品列表
      }
		},
		// 事件处理器
		methods: {
      // 获取本周打卡记录
      getWeekClockList(weekDate){
        this.$ajax({
          url: "/hxclass-mobile/clock/days",
          params: {
            startTime: weekDate[0],
            endTime: weekDate[6]
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            var day = new Date();
            var today = day.format('yyyy-MM-dd');//今天
            today = parseInt(today.split("-")[2]) // 截取今天几号
            
            if(res.data.length){
              res.data.forEach(item => {
                this.weekDayArry.forEach(element => { 
                  if(element.number == parseInt(item.split("-")[2])){
                    element.isClock = true
                  }
                  if(parseInt(item.split("-")[2]) == today){
                    this.isClock = true
                  } else {
                    this.isClock = false
                  }
                });
              });
            } else {
              this.isClock = false
            }
            
            this.weekDayArry.forEach(element => { 
              if(element.number == today){
                element.isToday = true
              } else {
                element.isToday = false
              }
            });
          }
        })
      },
      // 去分享
      onShare(){
        this.$router.push('/myStudy/clockShare')
      },

      // 打卡
      onOpenPopup(){
        if(this.isClock)return
        this.$ajax({
          url: "/hxclass-mobile/clock/punch",
          method: 'post',
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.isClock = true
            this.isPopupClock = true
            this.getDates()
            this.getDays()
          } else {
            this.$Toast(res.message);
          }
        })
        setTimeout(()=>{
          this.rotateClock = true
        },300)
      },

      // 关闭打卡窗
      onClosePopup(){
        this.isPopupClock = false
        setTimeout(()=>{
          this.rotateClock = false
        },1000)
      },
      // 打卡记录
      onRight(){
        this.$router.push('/myStudy/clockRecord');
      },
      getDates() {
        var new_Date = new Date()
        var timesStamp = new_Date.getTime();
        var currenDay = new_Date.getDay();
        var dates = [];
        var transDateAarry = [] // 年月日
        var days=[]  // 日
        for (var i = 0; i < 7; i++) {   // 获取本周的日期
          dates.push(new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/[年月]/g, '-').replace(/[日上下午]/g, ''));
        }

        dates.forEach(str => {  // 将日期格式化为 年-月-日 格式
          let item = str.split("/")
          item[1] = item[1] < 10 ? ('0' + item[1]) : item[1]
          item[2] = item[2] < 10 ? ('0' + item[2]) : item[2]
          transDateAarry.push(item[0] + '-' + item[1] + '-' + item[2])

          days.push(str.split("/")[2])
        })
        // days.unshift( days.pop() )  // 周日提取到首位
        this.weekDayArry.forEach((element,i) => {
          element.number = days[i]
        });

        this.getWeekClockList(transDateAarry)  // 获取本周打卡记录
      },
      // 获取连续打卡天数
      getDays(){
        this.$ajax({
          url: "/hxclass-mobile/clock/punch/days",
          }).then((res) => {
          if (res.code == 200 && res.success) {
            this.sumCount = res.data.punchDay
          }
        });
      },

      // 设置打卡提醒
      onSwichChange(e){
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {  // 防抖
          this.$ajax({
          url: "/hxclass-mobile/clock/reminder",
          method: 'put',
          params:{
            type: e ? 0 : 1
          }
          }).then((res) => {});
        }, 400);
      },
      // 获取签到提醒状态
      getReminderStatus(){
        this.$ajax({
          url: "/hxclass-mobile/clock/status",
          }).then((res) => {
          if (res.code == 200 && res.success) {
            this.isSignIn = res.data
          }
        });
      },

      // 领取奖品
      onReceive(e){
        if(e.status) return  // 已领取不做操作
        if(this.sumCount >= e.punchDays){
          this.$ajax({
            url: "/hxclass-mobile/prize/receive",
            method: 'POST',
            params:{
              prizeId: e.prizeId
            }
            }).then((res) => {
              if (res.code == 200 && res.success) {
                this.$Toast.success("领取成功");
                e.status = 1
              }
            });
          }
      },
      // 获取奖品列表
      getPrizeList(){
        this.$ajax({
          url: "/hxclass-mobile/prize/list",
          }).then((res) => {
          if (res.code == 200 && res.success) {
            this.prizeData = res.data
          }
        });
      },
      
    },
		// 生命周期-实例创建完成后调用
    created () { },
    // 生命周期-实例挂载后调用
    mounted () {
      this.getDates()  // 获取本周日期
      this.getDays()  // 获取连续打卡天数
      this.getReminderStatus()
      this.getPrizeList()
     },
    // 生命周期-实例销毁离开后调用
    destroyed () { },
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {}
	}
</script>

<style lang="less" scoped>
  .content-box{
    padding: 16.5px 18px;
    .week-record-box{
      display: flex;
      justify-content: space-between;
      .item{
        text-align: center;
        font-family: PingFangMedium;
        color: rgba(51, 51, 51, 0.7);
        font-weight: 500;
        .week{
          font-size: 14px;
          line-height: 21px;
        }
        .num{
          width: 20px;
          height: 20px;
          margin-top: 7.5px;
          font-size: 12px;
          line-height: 20px;
        }
        // 已打卡
        .clockNow{
          color: #fff;
          border-radius: 50%;
          background: #15B3DD;
        }
        .today{
          color: #000000;
          font-weight: bold;
        }
      }
    }
    .front-end-box{
      .top-wrap{
        margin-top: 13.5px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        .side-left{
          position: relative;
          top: 10px;
          height: 23px;
          padding-left: 11px;
          padding-right: 2.5px;
          background: #FFFFFF;
          border-radius: 10px 0 0 0 ;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            right: -23px;
            bottom: 0;
            width: 0;
            height: 0;
            border-bottom: 23px solid #FFFFFF;
            border-right: 23px solid transparent;
          }
          .div{
            display: flex;
            align-items: center;
            position: relative;
            top: 4px;
          }
        }
        .side{
          display: flex;
          align-items: center;
          .share-icon{
            margin-right: 5px;
            width: 14px;
          }
          .swich{
            margin-left: 5px;
          }
          /deep/.uni-switch-input{
            border: none;
            width: 38px;
            height: 19px;
            margin-right: 0;
            &::before{
              width: 38px;
              height: 19px;
            }
            &::after{
              width: 18px;
              height: 18px;
            }
          }
          /deep/uni-switch .uni-switch-input.uni-switch-input-checked:after{
            transform: translateX(10.5px);
          }
        }
      }
      .bottom-wrap{
        box-shadow: 0px 2px 2.5px 1px rgba(0,0,0,0.05);
        position: relative;
        top: 10px;
        background: #FFFFFF;
        border-radius:10px;
        text-align: center;
        position: relative;
        .center{
          padding: 26px 60px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rili{
            width: 41px;
          }
          .font{
            font-size: 18px;
            font-family:  PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 27px;
            span{
              font-size: 21px;
              margin: 0 3px;
              color: #FFC243;
              font-family: PingFangMedium;
            }
          }
        }
        .clock-btn{
          display: inline-block;
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          margin: auto;
          width: 210px;
          height: 40px;
          line-height: 40px;
          color: #ffffff;
          font-size: 16px;
          font-family: PingFangMedium;
          font-weight: bold;
          text-align: center;
          border: 5px solid #F7F7F9;
          background: linear-gradient(90deg, #DAAD71 43%, #FFDBAF 100%);
          border-radius: 50px;
        }
        .clock-btn-hover{
          background: #E5E5E5;
          color: #666666;
        }
        .circle-wrap{
          margin: 0 29px;
          position: relative;
          bottom: 7px;
          display: flex;
          justify-content: space-between;
          .circle{
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #15B3DD;
            border-radius: 50%;
          }
        }
      }
    }
    .all-bg{
      border-radius: 10px;
      background: #FFFFFF;
      padding: 14px;
      box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05);
    }
    .prize-box{
      margin-top: 42px;
      position: relative;
      .circle-wrap{
        position: relative;
        top: -6px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        .circle{
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #15B3DD;
          border-radius: 50%;
          &::after{
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            margin-left: 3.5px;
            margin-bottom: 4px;
            width: 3px;
            height: 59px;
            border-radius: 10px;
            background: #FFC243;
          }
        }
      }
      .title{
        display: inline-block;
        position: relative;
        right: 14px;
        text-align: center;
        width: 100px;
        height: 34px;
        color: #FFFFFF;
        line-height: 34px;
        background: #15B3DD;
        border-radius: 0px 25px 25px 0px;
      }
      .line-list{
        .line-item{
          padding: 15px 0;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .cover-img{
            width: 53.5px;
          }
          .center{
            flex: 1;
            margin-left: 18px;
            .name{
              font-size: 12px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.75);
              line-height: 18px;
            }
            .clock{
              display: inline-block;
              margin-top: 7.5px;
              background: rgba(21, 183, 221, 0.2);
              border-radius: 12px;
              padding: 3px 15px;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #333333;
            }
          }
          .btn{
            text-align: center;
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 500;
            width: 60.5px;
            height: 23px;
            line-height: 23px;
            border-radius: 11.5px;
            color: #999999;
            border: 1px solid #999999;
          }
          .success{
            color: #FFC243;
            border: 1px solid #FFC243;
          }
        }
        .line-item:last-child{
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
    .rule-box{
      // margin-top: 14px;
      margin-top: 42px;
      position: relative;
      .circle-wrap{
        position: relative;
        top: -6px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        .circle{
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #15B3DD;
          border-radius: 50%;
          &::after{
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            margin-left: 3.5px;
            margin-bottom: 4px;
            width: 3px;
            height: 59px;
            border-radius: 10px;
            background: #FFC243;
          }
        }
      }
      .block{
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        .title{
          display: flex;
          align-items: center; 
          font-size: 16px;
          line-height: 24px;
          .icon{
            margin-right: 6px;
            width: 16px;
          }
        }
        .font{
          margin-top: 8px;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  /* 打卡 卡片 */
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .flip-container {
    perspective: 1000;
    position: relative;
    text-align: center;
  }
  .flip-container, .front, .back {
    width: 212px;
    height: 266px;
  }
  .flipActive .flipper, .flipActive.hover .flipper {
    transform: rotateY(360deg);
  }
  .flipper {
    transition: 1.6s;
    height: 266px;
    transform-style: preserve-3d;
    position: relative;
  }
  .back,.front {
    background: url(../../assets/image/icon/20220216041502.png) ;
    background-repeat: repeat-x;
    background-size:100%;
    z-index: 2;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    .content{
      position: relative;
      top: 115px;
      text-align: center;
      font-family: PingFangMedium;
      .success{
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
      .sign-in{
        margin-top: 9px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        line-height: 21px;
      }
      .integral{
        margin-top: 5px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        line-height: 15px;
        text{
          font-size: 18px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #15B7DD;
          line-height: 27px;
        }
      }
      .share-btn{
        display: inline-block;
        margin-top: 7.5px;
        width: 88px;
        height: 29px;
        line-height: 29px;
        border-radius: 20px;
        border: 1px solid #15B7DD;
        font-size: 14px;
        font-weight: 500;
        color: #15B7DD;
      }
    }
  }
  .back {
    transform: rotateY(180deg);
    text-align: center;
  }
  .close-icon{
    position: absolute;
    bottom: -35px;
    left: 50%;
    width: 212px;
    margin-left: -106px;
  }
</style>
