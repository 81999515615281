<template>
  <div class="container-box" :style="{backgroundColor: activityDetail?activityDetail.source:'#C9F5FF'}">
    <van-nav-bar
      class="nav-circular"
      :title="title || '活动详情'"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="activityContent" v-if="activityDetail" v-html="activityDetail.mobileContent"></div>
    <div class="coupontitle" v-if="couponList.length">
      <img src="@/assets/image/activity/couponIcon.png" alt="">
      <p>优惠券领取</p>
    </div>
    <div class="couponList">
      <div class="couponItem" v-for="(item, index) in couponList" :key="index">
        <div class="back">
          <div class="content">
            <div class="left" :style="{backgroundColor: activityDetail?activityDetail.source:'#C9F5FF'}">
              <div class="detail_l">
                <p class="name">{{ item.name }}</p>
                <div class="time">
                  <img src="@/assets/image/activity/timeIcon.png" alt="">
                  <p>{{item.times == 1?'不限使用时间':item.times == 3?'领取后'+item.days+'天内使用':(item.startTime?item.startTime.split(' ')[0]:'')+'~'+item.lapseTime.split(' ')[0]}}</p>
                </div>
              </div>
              <div class="detail_r">
                <p class="couponPrice"><span>￥</span>{{ item.couponPrice }}</p>
                <p class="condition">满 {{ item.fullPrice }} 可用</p>
              </div>
            </div>
            <div class="right" @click="receiveCoupon(item)">
              <p>{{item.flag || item.drawId?'已领':'立即领取'}}</p>
              <img src="@/assets/image/activity/getIcon.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="modelContent">
          <p class="modelTitle">领取成功</p>
          <p class="textMess" v-if="activityDetail">{{ activityDetail.overview }}</p>
          <van-button class="btn" type="primary" @click="show = false">确 认</van-button>
        </div>
      </div>
      
    </van-overlay>
  </div>
</template>

<script>
import { receiveACoupon } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      id: null,
      title: null,
      activityDetail: null,
      show: false,
      couponList: []
    }
  },
  // 事件处理器
  methods: {
    getDetail() {
      this.$ajax({
        url: '/hxclass-mobile/Article/detail/' + this.id
      }).then(res=>{
        if(res.code == 200) {
          this.title = res.data.articleTitle;
          this.activityDetail = res.data;
          let label = this.activityDetail.label.split(',');
          this.getCoupons(label); // 获取优惠券列表
        }
      })
    },

    // 获取优惠券详情
    getCoupons(e) {
      this.$ajax({
        url: '/hxclass-mobile/app-mall/detail',
        method: 'post',
        params: e
      }).then(res=>{
        if(res.code == 200) {
          this.couponList = res.data;
        }
      })
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(e.flag || e.drawId) {
        return this.$Toast('此优惠券已领取！');
      }
      this.$ajax({
        url: '/hxclass-mobile/app-mall/app/save-draw',
        method: 'post',
        params: {
          couponId: e.couponId
        }
      }).then(res=>{
        if(res.code == 200) {
          this.$set(e,'flag',true);
          this.show = true;
          receiveACoupon();
        }else{
          this.$Toast(res.message)
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.container-box{
  height: 100vh;
  overflow-y:scroll;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modelContent{
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 0 54px;
  padding-bottom: 24px;
  .modelTitle{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 78px;
    background-image: url(~@/assets/image/activity/successBack.png);
    background-size: 100% 100%;
    text-align: center;
  }
  .textMess{
    padding: 0 14px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
    margin-top: -6px;
    margin-bottom: 28px;
  }
  .btn{
    width: calc(100% - 40px);
    height: 34px;
    display: block;
    margin: 0 auto;
    background: #15B7DD;
    border-radius: 20px 20px 20px 20px;
    border-color: #15B7DD;
  }
}
.couponList{
  margin-top: 6px;
  padding: 0 20px 34px;
  .couponItem{
    background: rgba(255,255,255,0.4);
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #FFFFFF;
    padding: 10px;
    margin-bottom: 10px;
    .back{
      background: #FFFFFF;
      border-radius: 9px;
      padding: 12px 10px;
      .content{
        position: relative;
        z-index: 0;
        .left{
          height: 73px;
          width: calc(100% - 61px);
          background-color: #fff;
          position: relative;
          z-index: 1;
          border-radius: 10px 10px 10px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          padding-right: 13px;
          box-sizing: border-box;
          .detail_l{
            width: 70%;
            overflow: hidden;
            .name{
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp:2;//设置几行溢出
              overflow: hidden;
            }
            .time{
              display: flex;
              align-items: center;
              margin-top: 6px;
              img{
                width: 9px;
                height: 9px;
                margin-right: 3px;
              }
              p{
                font-size: 10px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 15px;
              }
            }
          }
          .detail_r{
            flex: 1;
            text-align: center;
            .couponPrice{
              font-size: 30px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 45px;
              span{
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
              }
            }
            .condition{
              margin-top: -4px;
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 15px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp:2;//设置几行溢出
              overflow: hidden;
            }
          }
        }
        .right{
          position: absolute;
          right: 0;
          top: 0;
          z-index: 0;
          width: 50%;
          height: 71px;
          border-radius: 10px;
          border: 1px solid #000000;
          padding-right: 13px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          p{
            width: 38px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
            margin-bottom: 4px;
            text-align: center;
          }
          img{
            width: 28px;
            height: 8px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.coupontitle{
  display: flex;
  max-width: 157px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 5px 18px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  img{
    width: 28px;
    height: 21px;
    margin-right: 3px;
  }
  p{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
  }
}
.activityContent{
  padding: 17px 20px 20px;
  font-size: 16px;
  font-family: PingFangMedium;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  /deep/ .v-note-wrapper{
    box-shadow: none !important;
  }
  /deep/ .v-show-content{
    background-color: unset !important;
    box-shadow: none;
    padding: 0 !important;
  }
  /deep/ .v-note-wrapper{
    background-color: unset !important;
  }
  /deep/strong{
    font-weight: bold;
  }
  /deep/em{
    font-style: italic;
  }
  /deep/img{
    max-width: 100%;
  }
  /deep/.remarks{
    max-width: 100%;
    height: auto !important;
    -webkit-line-clamp: 10000 !important;
  }
  /deep/p {
    word-break: break-all;
    word-wrap: break-word;
  }
  /deep/h4 {
    font-weight: bold;
  }
}
</style>
