import $store from './../store'
// 登录注册、修改密码
export default [
  { // 协议条款
    path: 'clause',
    component: () => import('../views/login/clause.vue')
  },
  { // 找回密码
    path: 'retrievePassword',
    component: () => import('../views/login/retrievePassword.vue')
  },
  { // 绑定手机号
    path: 'bindPhone',
    component: () => import('../views/login/bindPhone.vue'),
    beforeEnter: (to,from,next) => {
      $store.state.userInfo.tolink = from.fullPath
      next()
    }
  },
  { // 找回密码
    path: 'createUser',
    component: () => import('../views/login/createUser.vue')
  },
  { // 登录注册
    path: 'login',
    component: () => import('../views/login/login.vue'),
    beforeEnter: (to,from,next) => {
      $store.state.userInfo.tolink = from.fullPath
      next()
    }
  }
]
