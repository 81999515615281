<template>
  <div class="main">
    <div class="content-inner">
      <van-nav-bar
        title="账号验证"
        :fixed='true'
        :placeholder='true'
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>
    <div class="single-menu-list">
      <div class="single-menu-item">
        <span class="p side-left-title">验证码将发送至：{{phoneStar}}</span>
      </div>

      <div class="single-menu-item code">
        <div class="side-left">
          <van-field 
            class="input" 
            v-model="code"
            maxlength="6" 
            type="tel" 
            placeholder="请输入验证码" />
        </div>
        <div class="get_code">
          <span v-show="!show">重新发送 {{count}} 秒</span>
          <span  v-show="show" @click="funCode()">获取验证码</span>
        </div>
      </div>
    </div>
    <div class="onSave-foo">
      <span class="all-btn-bigCircle" @click="submit">确认</span>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        code: '', //验证码
        show:true,
        count: 60,
      }
		},
		// 事件处理器
		methods: {
      // 获取验证码
      funCode(){
        // 获取验证码
        this.$ajax({
          url: '/hxclass-mobile/user/info/cancell/sms',
          method: "get",
          params: {
            mobile: this.$store.state.userInfo.mobile, //手机号
            mobileType: 4  // 暂时写死  手机号类：1.港 2.澳 3.台 4.内地
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$Toast.success('发送成功');
            // 倒计时
            this.funSMS()
          } else {
            this.$alert(res.message);
          }
        })
      },
      // 下一步
      submit() {
        if(!this.code){
          this.$alert('请输入验证码');
          return
        }
        this.$ajax({
          url: '/hxclass-mobile/user/info/cancell/sms/verifcode',
          method: "get",
          params: {
            mobile: this.$store.state.userInfo.mobile, //手机号
            code: this.code
          }
        }).then((res) => {
          if(res){
            this.$router.replace({path: '/setUp/verificationConfirm'})
          } else {
            this.$alert('验证码错误');
          }
        })
        
      },
      // 倒计时
      funSMS(){
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
    },
    computed: {
      // 手机号加密
      phoneStar(){
        var tel = String(this.$store.state.userInfo.mobile);
        var dh=tel.substr(0,3)+"*****"+tel.substr(7);
        return dh;
      }
    },
    beforeCreate() {
      this.$nextTick(()=>{
        document.querySelector('body').setAttribute('style', 'background: #ffffff;')
      })
    },
    // 生命周期-实例销毁离开后调用
    destroyed () { 
      // console.log(document.querySelector('body'))
      document.querySelector('body').removeAttribute('style')
    },
	}
</script>

<style lang="less" scoped>
  @import "./style.less";
  .single-menu-item:first-child{
    border-bottom: none;
  }
  .content-inner{
    border-bottom: 1px solid #F7F7F9 !important;
  }
  /deep/ .single-menu-list{
    padding: 4px 18px;
  }
  .code{
    border-bottom: 1px solid #F5F5F5 !important;
  }
  .side-left-title{
    margin: 0 auto;
    color: #333333;
    font-weight: 500 !important;
  }
  .side-left{
    flex: 1;
    display: flex;
    align-items: center;
    .left_phone {
      display: flex;
      align-items: center;
      .phoneType {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
      }
      .phoneType_icon {
        width: 8px;
        height: 6px;
        margin-left: 5px;
      }
    }
    .input{
      // margin-left: 11px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
  
  .get_code{
    border-left: 2px solid #15B7DD;
    padding-left: 7.5px;
    font-size: 12px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #15B7DD;
    line-height: 15px;
  }
  // 保存按钮
  .onSave-foo{
    box-sizing: border-box;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    padding: 0 40px;
    .all-btn-bigCircle {
      width: 100%;
    }
  }
</style>

