<template>
  <!-- 收货地址管理 -->
  <div class="index">
    <!-- 导航栏 -->
    <div class="nav">
      <van-nav-bar
        class="nav-circular"
        :title="$route.query.isSelect?'选择地址':'收货地址管理'"
        left-text=""
        right-text="添加新地址"
        :fixed="true"
        :placeholder="true"
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
        @click-right="addAddress('')"
      />
    </div>
    <no-message
      style="margin-top: 100px"
      v-if="!addressList.length && visible"
      type="1"
      message="您还没有添加收货地址"
    />
    <!-- 地址列表 -->
    <div class="box" v-infinite-scroll="load">
      <div
        @click="getAddress(item)"
        class="box_con"
        v-for="(item, index) in addressList"
        :key="index"
      >
        <div class="con_top">
          <img class="top_icon" src="../../assets/image/address/icon1.png" />
          <span class="top_name">{{ item.consignee }}</span>
          <span class="top_phone"
            >{{ item.phone.substr(0, 3) }}****{{
              item.phone.substr(7, 11)
            }}</span
          >
        </div>
        <div class="con_main">
          {{ item.proviceName }}{{ item.cityName }}{{ item.areaName
          }}{{ item.address }}
        </div>
        <div class="con_operate">
          <div class="operate_left">
            <div class="left_item">
              <img
                class="item_icon"
                src="../../assets/image/address/index_icon1.png"
              />
              <span class="item_con" @click.stop="addAddress(item.id)"
                >编辑</span
              >
            </div>
            <div class="left_item" @click.stop="showPop(1, item.id)">
              <img
                class="item_icon"
                src="../../assets/image/address/index_icon2.png"
              />
              <span class="item_con">删除</span>
            </div>
          </div>
          <div class="operate_right" @click.stop="showPop(item.isDefault==0?2:3, item.id)">
            <!-- v-if="isDefault !== '' ? isDefault == index : item.isDefault == 1" -->
            <img
              v-if="item.isDefault == 1"
              class="right_icon"
              src="../../assets/image/address/index_icon3.png"
            />
            <div
              v-else
              class="right_info"
            ></div>
            <span class="right_txt">设为默认</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="box_info">
			最多可添加5个收货地址，还可添加3个
		</div> -->

    <!-- 提示信息 -->
    <van-popup v-model="showPopup">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="info-o" color="#EB3C31" size="25" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">{{popupTxt}}</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showPopup = false"
            >取消</span
          >
          <span class="all-btn-middle btn2" @click="popupOk()">确认</span>
        </div>
      </div>
    </van-popup>

    <!-- <div class="box_button">
			<div class="button_info">最多可添加5个收货地址，还可添加3个</div>
			<span class="all-btn-middle">添加新地址</span>
		</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 1, //1删除地址 2设为默认 3取消默认
      defaultIndex: 0,
      showPopup: false,
      popupTxt: '', //弹框提示语
      addressList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      visible: false,
      changeItem: "", // 当前操作id
      isDefault: "",
    };
  },
  methods: {
    // 下拉加载
    load() {
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++;
        this.getManageList();
      }
    },
    // 打开提示框
    showPop(type, index) {
      this.state = type;
      this.changeItem = index;
      this.showPopup = true;
      if (type == 1) {
        // 删除地址
        this.popupTxt = '确认删除该地址吗？'
      } else if (type == 2) {
        // 设为默认
        this.popupTxt = '确认是否将该地址设置为默认地址？'
      } else if (type == 3) {
        // 取消默认
        this.popupTxt = '确认是否取消该默认地址？'
      }
    },
    // 获取地址后返回详情页
    getAddress(e) {
      // console.log()
      // return
      if (this.$route.query.isSelect) {
        const obj = this.$store.state.userInfo;
        obj.harvestAddress = e;
        this.$store.commit("updateUserInfo", obj);
        this.$router.back();
      }
    },
    // 设为默认
    changeAddress() {
      this.showPopup = false;
      if (this.state == 2) {
        this.$Toast.success("已设置为默认地址");
      } else if (this.state == 3) {
        this.$Toast.success("已取消默认地址");
      }
    },
    // 确认
    popupOk() {
      // 删除
      if (this.state == 1) {
        this.$ajax({
          url: "/hxclass-mobile/ship/address/delete?id=" + this.changeItem,
          method: "delete",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$Toast(res.message);
            if(this.$store.state.userInfo.harvestAddress && this.changeItem == this.$store.state.userInfo.harvestAddress.id){
              const obj = this.$store.state.userInfo;
              obj.harvestAddress = null;
              this.$store.commit("updateUserInfo", obj);
            }
            this.addressList.map((item, index) => {
              if (item.id == this.changeItem) {
                this.addressList.splice(index, 1);
                if (
                  this.addressList.length &&
                  this.pageSize == this.addressList.length
                ) {
                  this.pageNum--;
                }
              }
            });
            this.showPopup = false;
          } else {
            this.$Toast(res.message);
          }
        });
      } else if (this.state == 2 || this.state == 3) {
        let type = this.state == 2 ? 1 : 0 //1设置 0取消
        // 2设为默认 3取消默认
        this.$ajax({
          url: "/hxclass-mobile/ship/address/isdefault?id=" + this.changeItem + '&type=' + type,
          method: "PUT",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$Toast(res.message);
            // 设为默认
            this.addressList.map((item, index) => {
              if (item.id == this.changeItem) {
                let isDefault = this.state == 2 ? 1 : 0
                this.$set(this.addressList[index], 'isDefault', isDefault)
              } else {
                this.$set(this.addressList[index], 'isDefault', 0)
              }
            });
            this.showPopup = false;
          } else {
            this.$Toast(res.message);
          }
        });
        this.changeAddress();
      }
    },
    // 添加新地址
    addAddress(id) {
      this.$router.push("/address/addAddress?id=" + id);
    },
    // 获取地址列表
    getManageList() {
      this.$ajax({
        url: "/hxclass-mobile/ship/address/list",
        method: "get",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.visible = true;
        if (res.code == 200 && res.success) {
          this.addressList.push(...res.data.records);
          this.total = res.data.total;
        } else {
          this.$Toast(res.message);
        }
      });
    },
  },
  created() {
    this.getManageList();
  },
};
</script>

<style>
page {
  background-color: #f7f7f9;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.van-popup--center {
  border-radius: 9px;
}
.nav {
  position: relative;
  z-index: 9;
}

.index {
  background: #f7f7f9;
  padding-bottom: 60px;
}

.box {
  padding: 0 18px 0 18px;
  .box_con {
    position: relative;
    background: #ffffff;
    border-radius: 9px;
    border: 1px solid #fff;
    margin-top: 12px;
    padding: 16px 12px;
    .con_top {
      display: flex;
      align-items: center;
      .top_icon {
        width: 14px;
      }
      .top_name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin: 0 8px;
      }
      .top_phone {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
      }
    }
    .con_main {
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      margin-top: 12.5px;
    }
    .con_operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
      margin-top: 16px;
      padding-top: 12px;
      .operate_left {
        display: flex;
        align-items: center;
        .left_item {
          &:nth-child(2) {
            margin-left: 40px;
          }
          .item_icon {
            width: 16px;
            vertical-align: middle;
          }
          .item_con {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            margin-left: 7px;
            vertical-align: middle;
          }
        }
      }
      .operate_right {
        display: flex;
        align-items: center;
        .right_info {
          width: 16px;
          height: 16px;
          border: 1px solid #979797;
          border-radius: 50%;
        }
        .right_icon {
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
        .right_txt {
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          vertical-align: middle;
          margin-left: 6px;
        }
      }
    }
  }
}

.box_info {
  text-align: center;
  font-size: 14px;
  font-family: PingFangMedium;
  font-weight: 400;
  color: #666666;
  margin-top: 25px;
}

// 提示信息
.box_popup {
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 22.5px;
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .all-btn-middle {
      height: 39px;
      line-height: 39px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>
