<template>
  <div class="container-wrap">
    
    <van-nav-bar
      v-if="!isHidden"
			title="授课老师"
			left-arrow
			class="nav-circular"
			:fixed="true"
			:placeholder="true"
			@click-left="onReturn()"
		/>
    <div v-if="isHidden" style="height:50px"></div>
    <div class="navTop" v-if="isHidden">
      <van-icon @click="onReturn()" class="back"  name="arrow-left" size="16" color="#333" />
      <span>授课老师</span>
    </div>
    <div class="content-box">
      <!-- 教师介绍列表 -->
      <div class="teacher-list">
        <div 
          class="teacher-item" 
          v-for="(item, index) in dataList"
          :key="index">
          <!-- 上部分 -->
          <div class="view-top" ref="viewTop">
            <div class="side-left">
              <div class="cover-img">
                <img class="header-img" :src="item.squarePicture" />
              </div>
              <span class="name">{{item.name.substring(0,12)}}</span>
            </div>
            
            <!-- style="height: 110px;" -->
            <!-- :height="20" -->
            <van-swipe 
              v-if="isShowSwiper"
              class="side-right" 
              :autoplay="8000"
              indicator-color="#15b7dd">
              <van-swipe-item v-for="(itemI, indexI) in newLabelList[index]" :key="indexI">
                <div class="p" v-for="(itemII, indexII) in itemI" :key="indexII">{{itemII}}</div>
              </van-swipe-item>
            </van-swipe>

            <div class="side-right" :id="'labelTag'+index" :ref="'labelTag'+index"></div>

          </div>
          <div class="view-info" v-if="item.introduction">
            <TextOverflow :type=1 :height="54" :text="item.isUnfold?item.introduction.replace(/<[^>]+>/g,''):manageInfo(item.introduction, index)" ref="TextOver" />

            <!-- <div class="introduction" v-html="item.isUnfold?item.introduction.replace(/<[^>]+>/g,''):manageInfo(item.introduction, index)"></div>
            <div class="info_tag" v-if="item.introduction && item.introduction.replace(/<[^>]+>/g,'').length > 110" @click="changeUnfold(index)">
              <span v-if="item.isUnfold">
                {{'收起'}}
                <van-icon name="arrow-up" />
              </span>
              <span v-else>
                {{'展开'}}
                <van-icon name="arrow-down" style="margin" />
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FunReturnPlay} from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice } from '@/unit/fun.js'
import TextOverflow from '@/components/TextOverflow.vue'

export default {
  // 可用组件的哈希表
  components: {TextOverflow},
  // 数据对象
  data() {
    return {
      isHidden: false,
      isUnfold: false, //是否展开
      isShowSwiper: false, //是否展示轮播图
      dataList: [],
      newDataList: [], //存放标签-用于数据处理
      lableListDom: [], //所有老师标签的dom节点
      newLabelList: [], //新的轮播图-用来页面展示
      showIntroduction: false,
    }
  },
  created() {
    
  },
  beforeMount() {
    this.getList()
    // // 标签
    // this.manageLable()
  },
  mounted() {
    // console.log(this.$route.params.teacherList, '45454545')
    // if(this.$route.params.teacherList){
    //   this.dataList = this.$route.params.teacherList;
    // }
    // 标签放入页面
    // 检测手机型号
    // console.log(this.$route.query.type)
    if(FunJudgDevice()== 'ios'){
      if(this.$route.query.type == 'true'){
        this.isHidden = true
      }else {
        this.isHidden = false
      }
    } 
  },
  // 事件处理器
  methods: {
    // 手动处理返回
    onReturn(){
      // console.log(this.$route.query.type=='false')
      if(this.$route.query.type != 'false'){
        FunReturnPlay()
      }else{
        this.$onReturn(this.$route.query.fromMenu == '1')
      }
    },

    // 处理文字
    manageInfo(con, index) {
      con = con.replace(/<[^>]+>/g,'')
      return con
      // let conNew = ''
      // if (con && con.length < 110) {
      //   return con
      // } else {
      //   conNew = con.substring(0,110)+'...'
      //   return conNew
      // }
    },
    // 展开收起
    changeUnfold(index) {
      this.$set(this.dataList[index],'isUnfold',!this.dataList[index].isUnfold)
    },
    // 创建标签
    creatLabel() {
      // 取出每一组标签
      this.dataList.map((item, index) => {
        this.newDataList.push(item.title.split(','))
        // label标签初始化
        this.lableListDom.push([])
        // label最终版初始化
        this.newLabelList.push([])
      })

      // 标签、文本声明
      let lableItem,node
      // 所有老师
      this.newDataList.map((item, index) => {
        // 每个老师的标签
        item.map((itemI, indexI) => {
          lableItem = document.createElement("div")  //创建标签
          node = document.createTextNode(itemI) //文案
          lableItem.appendChild(node); //文案放入标签
          lableItem.setAttribute("class", "p") //添加类名
          this.lableListDom[index].push(lableItem) //标签放入数组
        })
      })
      document.getElementById('teacherListStyles').innerHTML = 
        `.side-right .p{
          padding-left: 7.5px;
          margin-bottom: 8px;
          font-size: 13px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 18px;
          position: relative;
        }
        .side-right .p::before{
          content: '';
          position: absolute;
          top: 6.5px;
          left: 0;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #15B7DD;
        }`
        // console.log(this.newDataList, this.lableListDom)
    },      
    // 标签
    manageLable() {
      // console.log(this.dataList,this.$refs.labelTag0)
      // 标签可用高度
      let inItHeight = 0 //原本高度
      let nowHeight = 0 //现有高度
      let newLabelItem = [] //每位老师的每条label

      let labelTag //父级
      let labelRef //父级
      // 全部老师
      for ( var i = 0 ; i < this.lableListDom.length ; i++ ) {
        labelTag = document.getElementById('labelTag'+i) //父级
        labelRef = 'labelTag'+i //父级
        inItHeight = this.$refs[labelRef][0].offsetHeight; //原本高度
        // 老师的标签
        for ( var j = 0 ; j < this.lableListDom[i].length ; j++ ) {
          // 插入类名
          labelTag.appendChild(this.lableListDom[i][j])
          nowHeight = this.$refs[labelRef][0].offsetHeight;
          // console.log(inItHeight, nowHeight, labelRef, this.lableListDom[i][j])
          // 超出高度
          if (inItHeight < nowHeight) {
            this.newLabelList[i].push(newLabelItem)
            // console.log(this.newDataList[i][j].length, i, j)
            // 判断是不是一条的字数太多
            if (this.newDataList[i][j].length >= 80) {
              newLabelItem.push(this.newDataList[i+1][j])
              newLabelItem = []
              labelTag.innerHTML = ''
              break
            } else {
              newLabelItem = []
              labelTag.innerHTML = ''
              j--
            }
          } else {
            // 可继续加标签
            newLabelItem.push(this.newDataList[i][j])
          }
          // 最后一次循环
          if (j == (this.lableListDom[i].length-1)) {
            this.newLabelList[i].push(newLabelItem)
            newLabelItem = []
            labelTag.innerHTML = ''
          }
        }
      }
      // 打开轮播图
      this.isShowSwiper = true
    },

    // 查询老师列表
    getList(){
      this.$ajax({
        url: `/hxclass-mobile/course/course/${this.$route.query.courseId}`
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.dataList = res.data
          // 创建标签
          this.creatLabel()
          this.$nextTick(() => {
            setTimeout(()=>{
              this.manageLable()
            },100)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.introduction{
  /deep/strong{
    font-weight: bold;
  }
  /deep/em{
    font-style: italic;
  }
  /deep/img{
    max-width: 100%;
  }
  /deep/.remarks{
    max-width: 100%;
    height: auto !important;
    -webkit-line-clamp: 10000 !important;
  }
}
.navTop{
  position: fixed;
  z-index: 9999;
  top: 0;
  padding-top: 10px;
  height: 40px;
  width: 100%;
  background: #FFFFFF;
  text-align: center;
  span{
    line-height: 30px;
  }
  .back{
    position: absolute;
    left: 13px;
    top: 16px;
  }
}
  /deep/.van-swipe {
    width: 100%;
    padding-bottom: 10px;
  }
  /deep/.van-swipe__track {
    width: 100% !important;
    height: 131px;
  }
  /deep/.van-swipe-item {
    width: 100% !important;
    height: auto;
  }
  /deep/.van-swipe__indicators {
    bottom: 2px;
  }
  /deep/.van-swipe__indicator {
    background-color: #0888A6;
  }
  .content-box{
    padding: 0 18px;
    .teacher-list{
      .teacher-item{
        margin-top: 33px;
        background: #FFFFFF;
        padding: 14px 18px;
        .view-top{
          display: flex;
          .side-left{
            // height: 110px;
            // position: relative;
            margin-top: -24px;
            margin-right: 14px;
            // bottom: 24px;
            .cover-img{
              width: 96px;
              height: 126px;
              .header-img{
                border-radius: 10px 10px 0 0;
                // position: relative;
                // top: -17px;
                width: 96px;
                height: 126px;
              }
            }
            .name{
              display: inline-block;
              margin-top: 5px;
              text-align: center;
              background: #15B7DD;
              box-shadow: 0px 2px 2px 1px rgba(0,0,0,0.1);
              width: 96px;
              // height: 24px;
              line-height: 24px;
              border-radius: 5px;
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: bold;
              color: #FFFFFF;
            }
          }
          .side-right{
            // margin-left: 14px;
            .p{
              padding-left: 7.5px;
              margin-bottom: 8px;
              font-size: 13px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #333333;
              line-height: 18px;
              position: relative;
              &::before{
                content: '';
                position: absolute;
                top: 6.5px;
                left: 0;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #15B7DD;
              }
            }
          }
        }
        .view-info{
          position: relative;
          margin-top: 10px;
          font-size: 10px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          padding-bottom: 20px;
          .info_tag {
            position: absolute;
            right: 0;
            bottom: -2px;
            // font-size: 13px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #0888A6;
            width: 45px;
            text-align: right;
            background: #FFFFFF;
          }
          /deep/ .overflow-hidden{
            max-height: 54px;
          }
          /deep/ .textOver{
            font-size: 12px;
            line-height: 18px;
          }
          /deep/ .link{
            line-height: 18px;
          }
        }
      }
    }
  }
</style>
