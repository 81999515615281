import { render, staticRenderFns } from "./cancellation.vue?vue&type=template&id=e0480d72&scoped=true&"
var script = {}
import style0 from "./cancellation.vue?vue&type=style&index=0&id=e0480d72&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0480d72",
  null
  
)

export default component.exports