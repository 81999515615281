<template>
  <!-- 身份认证 -->
  <div class="identityAuthentication">
    <!-- 导航栏 -->
    <van-nav-bar
      v-if="type==1"
      class="nav-circular"
      title="身份认证"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <!-- 跳过 -->
    <div class="skip_box" v-if="type==2">
      <div class="box_con">
        <div class="con_txt">认证身份，获取更多服务</div>
        <div class="con_info">
          <div class="info_con">独家<span class="info_light">优质好课、</span>权威<span class="info_light">专业知识、</span>官方<span class="info_light">考试认证</span></div>
          <div class="info_long"></div>
          <div class="info_short"></div>
        </div>
      </div>
      <div class="box_button" @click="goSkip()">跳过</div>
    </div>

    <van-form v-if="isShowAll" @submit="onSubmit" @failed="onFailed">
      <div class="detailedtitle">
        <span class="colorDiv"></span>
        <span class="title"
          >身份<span v-if="!authenticationMessage">（可多选）</span></span
        >
      </div>
      <div class="identity">
        <div class="identityItem" v-if="!authenticationMessage">
          <div class="leftmess">
            <span class="option">从业人员</span>
            <span class="describe">（含学生、科研人员等）</span>
          </div>
          <div class="rightIcon" @click="changeEmployees()">
            <img
              v-if="employees"
              src="@/assets/image/icon/checkoutIcon.png"
            />
            <span v-if="!employees"></span>
          </div>
        </div>
        <div class="identityItem" v-if="!authenticationMessage">
          <div class="leftmess">
            <span class="option">家长</span>
          </div>
          <div class="rightIcon" @click="changeParent()">
            <img
              v-if="parent"
              src="@/assets/image/icon/checkoutIcon.png"
            />
            <span v-if="!parent"></span>
          </div>
        </div>
        <div
          class="identityItem"
          v-if="authenticationMessage && (authenticationMessage.userType==2 || authenticationMessage.userType==4)"
        >
          <div class="leftmess">
            <span class="option">从业人员</span>
          </div>
        </div>
        <div
          class="identityItem"
          v-if="authenticationMessage && (authenticationMessage.userType==1 || authenticationMessage.userType==4)"
        >
          <div class="leftmess">
            <span class="option">家长</span>
          </div>
        </div>
      </div>
      <div
        class="detailedtitle"
        v-if="employees || parent || authenticationMessage"
      >
        <span class="colorDiv"></span>
        <span class="title">实名信息<span>（认证后无法更换）</span></span>
      </div>
      <div
        class="detailHint"
        v-if="employees || parent || authenticationMessage"
      >
        <span class="title-red">
          <van-icon name="question-o" color="#EC6C01" size="12px" />
          <!-- 证件信息仅用于验证您的身份 -->
          <!-- 证件信息仅用于验证身份,云课堂将严格保密 -->
          信息将用于核实您的真实身份，与账号唯一绑定，验证成功后不可修改；我们对信息进行严格保密，请放心使用。
        </span>
      </div>
      <div class="identity" v-if="employees || parent || authenticationMessage">
        <div class="identityItem">
          <span class="title">姓名</span>
          <span v-if="authenticationMessage||isAuthentication" class="value">{{name}}</span>
          <van-field
            name="name"
            class="inputtext"
            v-else
            v-model="name"
            placeholder="请输入真实姓名"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <!-- :rules="[{ pattern: $regular.name.reg}]" -->
        </div>
        <div class="identityItem">
          <span class="title">证件类型</span>
          <span class="value" v-if="authenticationMessage||isAuthentication">
            {{identityArray[idType]&&identityArray[idType].name}}
          </span>
          <div class="picker_box" v-else @click="showPicker = true">
            <van-field
              readonly
              class="placehold"
              :value="identityArray[idType]&&identityArray[idType].name"
              input-align="right"
              placeholder="请选择证件类型"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :columns="identityArray"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </div>
        <!-- 身份证 -->
        <div class="identityItem">
          <span class="title">证件号码</span>
          <span class="value" v-if="authenticationMessage||isAuthentication">{{idNo}}</span>
          <van-field
            class="inputtext"
            name="idNo"
            v-else
            v-model="idNo"
            placeholder="请填写证件号码"
            input-align="right"
            :rules="idcardDefult"
            @blur="blurIdCode"
          />
        </div>
        <div class="identityItem">
          <span class="title">性别</span>
          <span class="value" v-if="authenticationMessage||isAuthentication">{{sexArray[sex]&&sexArray[sex].name}}</span>
          <!-- @click="showPicker1 = true" -->
          <div class="picker_box" v-else @click="changeSex()">
            <van-field
              readonly
              name="sex"
              :disabled="sexDisabled"
              class="placehold"
              :value="sexArray[sex]&&sexArray[sex].name"
              input-align="right"
              placeholder="请选择性别"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker1" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :columns="sexArray"
              @cancel="showPicker1 = false"
              @confirm="onConfirm1"
            />
          </van-popup>
        </div>
      </div>
      <div
        class="detailedtitle"
        v-if="employees || parent || authenticationMessage"
      >
        <span class="colorDiv"></span>
        <span class="title">个人信息</span>
      </div>
      <div class="identity" v-if="employees || parent || authenticationMessage">
        <div class="identityItem">
          <span class="title">籍贯</span>
          <span class="value" v-if="authenticationMessage">{{pgAddressName}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker2 = true">
            <van-field
              readonly
              name="pgAddressName"
              class="placehold"
              :value="pgAddressName"
              input-align="right"
              placeholder="请选择籍贯（选填）"
            />
            <!-- :rules="[{ required: true }]" -->
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker2" round position="bottom">
            <provinceCity
              :defaultIndex="[pgAddressProvincial, pgAddressCity]"
              :title="picker2Title"
              @onConfirm="onConfirm5"
              @closeCityPop="closeCityPop"></provinceCity>
          </van-popup>
        </div>
        <div class="identityItem">
          <span class="title">民族</span>
          <span class="value" v-if="authenticationMessage">{{nationArray[nation]&&nationArray[nation].name}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker3 = true">
            <van-field
              readonly
              name="nation"
              class="placehold"
              :value="nationArray[nation]&&nationArray[nation].name"
              input-align="right"
              placeholder="请选择民族（选填）"
            />
            <!-- :rules="[{ required: true }]" -->
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker3" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :columns="nationArray"
              :default-index="nation"
              @cancel="showPicker3 = false"
              @confirm="onConfirm3"
            />
          </van-popup>
        </div>
        <div class="identityItem">
          <span class="title">学历</span>
          <span class="value" v-if="authenticationMessage">{{educationArray[degree]&&educationArray[degree].name}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker4 = true">
            <van-field
              readonly
              name="degree"
              class="placehold"
              :value="educationArray[degree]&&educationArray[degree].name"
              input-align="right"
              placeholder="请选择学历"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker4" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :default-index="degree"
              :columns="educationArray"
              @cancel="showPicker4 = false"
              @confirm="onConfirm4"
            />
          </van-popup>
        </div>
        <div class="identityItem">
          <span class="title">邮箱</span>
          <span class="value" v-if="authenticationMessage">{{
            authenticationMessage.email
          }}</span>
          <!-- :rules="[{ pattern: $regular.email.reg}]" -->
          <van-field
            class="inputtext"
            name="email"
            v-if="!authenticationMessage"
            v-model="email"
            placeholder="请填写邮箱（选填）"
            input-align="right"
            :rules="[{ pattern: $regular.email.reg}]"
          />
        </div>
        <div class="identityItem">
          <span class="title">所在地</span>
          <span class="value" v-if="authenticationMessage">{{locationName}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker5 = true">
            <van-field
              readonly
              class="placehold"
              name="locationName"
              :value="locationName"
              input-align="right"
              placeholder="请选择所在地"
              :rules="[{ required: true  }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker5" round position="bottom">
            <provinceCity
              :defaultIndex="[addressProvincial, addressCity, addressArea]"
              :title="picker5Title"
              @onConfirm="onConfirm5"
              @closeCityPop="closeCityPop"></provinceCity>
          </van-popup>
        </div>
        <div
          class="identityItem"
          v-if="
            employees ||
            (authenticationMessage && authenticationMessage.employees)
          "
        >
          <span class="title">所在行业</span>
          <!-- <span class="value" v-if="authenticationMessage">{{industryArray[industryName].name}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker6 = true">
            <van-field
              readonly
              class="placehold"
              name="industryName"
              :value="industryArray[industryName]&&industryArray[industryName].name"
              input-align="right"
              placeholder="请选择所在行业"
              :rules="[{ required: true }]"
            /> -->
            <span class="value" v-if="authenticationMessage && industryName!=-1">{{industryArray[0].values[industryName].name}} - {{industryArray[1].values[industrySubName].name}}</span>
            <div class="picker_box" v-if="!authenticationMessage" @click="showPicker6 = true">
              <van-field
                readonly
                class="placehold"
                name="industryName"
                :value="industryArray[0]&&industryNameAll"
                input-align="right"
                placeholder="请选择所在行业"
                :rules="[{ required: true }]"
              />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker6" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :default-index="industryName"
              :columns="industryArray"
              @cancel="showPicker6 = false"
              @confirm="onConfirm6"
              @change="changeIndustry"
            />
          </van-popup>
        </div>
        <div
          class="identityItem"
          v-if="
            employees ||
            (authenticationMessage && authenticationMessage.employees)
          "
        >
          <span class="title">职业</span>
          <span class="value" v-if="authenticationMessage">{{authenticationMessage.occupationName}}</span>
          <!-- <div class="picker_box" @click="showPicker7 = true"> -->
          <div class="picker_box" v-if="!authenticationMessage" @click="showOccupation()">
            <van-field
              readonly
              class="placehold"
              name="occupationName"
              :value="occupationArray[occupationName]&&occupationArray[occupationName].name"
              input-align="right"
              placeholder="请选择职业"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker7" round position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :default-index="occupationName"
              :columns="occupationArray"
              @cancel="showPicker7 = false"
              @confirm="onConfirm7"
            />
          </van-popup>
        </div>
        <div
          class="identityItem"
          v-if="
            employees ||
            (authenticationMessage && authenticationMessage.employees)
          "
        >
          <span class="title">工作单位</span>
          <span class="value" v-if="authenticationMessage">{{
            authenticationMessage.work
          }}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="goWorkUnit()">
            <van-field
              readonly
              class="placehold"
              name="work"
              :value="work"
              input-align="right"
              placeholder="请选择工作单位"
              :rules="[{ required: true }]"
              @input="changeUnit"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
        </div>
        <div
          class="identityItem"
          v-if="
            employees ||
            (authenticationMessage && authenticationMessage.employees)
          "
        >
          <span class="title">工作年限</span>
          <span class="value" v-if="authenticationMessage">{{
            authenticationMessage.workYears
          }}</span>
          <van-field
            class="inputtext"
            name="workYears"
            type="number"
            v-if="!authenticationMessage"
            v-model="workYears"
            placeholder="请填写工作年限"
            input-align="right"
            :rules="[{ pattern: $regular.number.reg }]"
          />
        </div>
        <div
          class="identityItem"
          v-if="
            parent || (authenticationMessage && authenticationMessage.parent)
          "
        >
          <span class="title">孩子生日</span>
          <span class="value" v-if="authenticationMessage">{{authenticationMessage.childBirthday}}</span>
          <div class="picker_box" v-if="!authenticationMessage" @click="showPicker9 = true">
            <van-field
              readonly
              name="childBirthday"
              class="placehold"
              :value="childBirthday"
              input-align="right"
              placeholder="请选择孩子生日"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
          </div>
          <van-popup v-model="showPicker9" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="请选择孩子生日"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showPicker9 = false"
              @confirm="onConfirm9"
            />
          </van-popup>
        </div>
      </div>

      <div 
        id="oFooter"
        class="footer" 
        :style="{top: (pageHeight - 50) + 'px'}">
        <van-button
          class="all-btn-bigCircle btn"
          v-if="!authenticationMessage"
          native-type="submit">
          保 存
        </van-button>
        <div 
          class="all-btn-bigCircle btn" 
          v-if="authenticationMessage" 
          @click="editData()">
          修 改
        </div>
      </div>
    </van-form>

    <div class="load_box" v-if="!isShowAll">
      <!-- <van-loading color="#0094ff" /> -->
      <span class="box_load">加载中...</span>
    </div>

    <!-- 提示信息 -->
    <van-popup v-model="showPopup">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">
          <!-- {{popupTxt}} -->
          {{!notCertified?'修改成功':'身份认证成功'}}
        </div>
        <div class="popup_button middle">
          <span class="all-btn-middle btn2" @click="notCertified=false, showPopup=false, $onReturn(this.$route.query.fromMenu == '1')">我知道了</span>
        </div>
      </div>
    </van-popup>
    
  </div>
</template>

<script>
import $regular from '@/assets/js/regular'
import provinceCity from '@/components/provinceCity.vue'
import { FunJudgDevice, getClientHeight } from '@/unit/fun.js'
import { FunGoHome } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: {
    provinceCity
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      pageHeight: 0, //页面视图高度
      footHeight: 0, //按钮高度
      phoneModel: 1, //1ios 2安卓
      type: 1, //1正常进入 2注册进入
      isShowAll: false, //请求完接口后显示页面
      isAuthentication: false, //是否认证
      isRefresh: true, //是否刷新页面 
      showPopup: false, //提示框

      showPicker: false, // 身份认证
      showPicker1: false, // 性别
      showPicker2: false, // 籍贯
      showPicker3: false, // 民族
      showPicker4: false, // 学历
      showPicker5: false, // 所在地
      showPicker6: false, // 所在行业
      showPicker7: false, // 职业
      showPicker8: false, // 工作单位
      showPicker9: false, // 孩子生日

      // 校验
      idcardDefult: [{ required: true }], //默认校验
      idcardReq: [{ required: true }], //必填校验
      idCardReg: [{ pattern: $regular.idCard.reg }], //身份证
      idCard1Reg: [{ pattern: $regular.idCard1.reg }], //港澳台身份证
      passportReg: [{ pattern: $regular.passport.reg }], //护照

      // 性别禁选
      sexDisabled: false,

      // 孩子生日
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),

      // 所在地
      // fieldValue: '',
      locationName: '',
      addressProvincial: '', // 省编码
      addressCity: '', // 市编码
      addressArea: '', // 区编码
      picker5Title: '请选择所在地区',

      employees: true, // 从业人员
      parent: true, // 家长
      name: '', // 姓名
      idType: '', //  证件类型
      identityArray: [
        {
          id: 1,
          name: '中国居民身份证'
        },
        {
          id: 2,
          name: '港澳台居民居住证'
        },
        {
          id: 3,
          name: '护照'
        },
      ],
      // idNo: '142729199908089889', // 证件号码
      idNo: '', // 证件号码
      passport: 'G59370156', // 护照
      sexArray: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 2,
          name: '女'
        },
      ],
      sex: '', // 性别
      // pgAddressName: '', // 籍贯
      pgAddressName: '', // 籍贯
      pgAddressProvincial: '', // 籍贯省
      pgAddressCity: '', // 籍贯市
      picker2Title: '请选择籍贯',
      nationArray: [], // 民族
      nation: -1, // 民族
      educationArray: [],
      degree: -1, // 学历
      email: '', // 邮箱
      industryArray: [],
      industryName: -1, // 所在行业-大类
      industrySubName: -1, // 所在行业-小类
      industryNameAll: '', // 所在行业大类小类
      occupationArray: [],
      occupationName: -1, // 职业
      workSeo: [], //搜索匹配公司
      workArray: [],
      work: '', // 工作单位
      workCom: '', //工作单位code
      workYears: '', // 工作年限
      childBirthday: '', // 孩子生日
      authenticationMessage: null, //展示列表
      notCertified: false, // 是否未认证
    }
  },
  // 生命周期-实例创建完成后调用
  async created () {
    // 商城列表进入
    if (this.$route.query.url == '/examination/examSignList') {
      // 获取工作单位
      await this.getWorkUnit()

      this.$nextTick(() => {
        // 获取页面视图高度
        this.pageHeight = getClientHeight()
      })
      
      if(FunJudgDevice() == 'ios' && window.webkit){
        this.phoneModel = 1;
      }else if(FunJudgDevice() == 'android'){
        this.phoneModel = 2;
      }
    }
  },
  // 进入页面
  async activated() {
    // 注册进入
    this.type = this.$route.query.type || 1

    // 获取工作单位
    await this.getWorkUnit()

    this.$nextTick(() => {
      // 获取页面视图高度
      this.pageHeight = getClientHeight()
    })
    
    if(FunJudgDevice() == 'ios' && window.webkit){
      this.phoneModel = 1;
    }else if(FunJudgDevice() == 'android'){
      this.phoneModel = 2;
    }
  },
  // 离开页面
  deactivated() {

  },
  // 事件处理器
  methods: {
    // 工作单位回显
    async getWorkUnit() {
      // 工作单位
      let workUnitObj = localStorage.getItem('workUnitObj')
      // console.log(workUnitObj)
      if (workUnitObj) {
        // console.log(JSON.parse(workUnitObj))
        workUnitObj = JSON.parse(workUnitObj)
        localStorage.removeItem('workUnitObj')
        if (!!workUnitObj.flag) {
          // 转为修改状态
          this.authenticationMessage = null
          return
        }
        this.work = workUnitObj.name // 工作单位
        this.workCom = workUnitObj.workUnitId || '' // 工作单位
        // console.log(this.work, this.workCom)
        // 转为修改状态
        this.authenticationMessage = null
      } else {
        // 初始化
        await this.onInit();
        // 民族
        await this.getNationList()
        // 学历
        await this.getEducationList()
        // 所在行业 - 大类
        await this.getIndustryList()
        // 所在行业 - 小类
        if (this.industryArray[0].values.length !== 0) {
          await this.getIndustryList1(this.industryArray[0].code)
        }
        // 身份认证信息
        await this.getIdentityAuthen()
      }
      // 可刷新页面
      // this.isRefresh = true
    },
    // 初始化
    onInit() {
      this.isShowAll = false
      this.industryArray = []
      this.employees = true //从业人员 默认选中
      this.parent = false //家长
      this.name = '' //姓名
      this.idType = '' //证件类型
      this.idNo = '' //证件号码
      this.passport = '' //护照
      this.sex = '' //性别
      this.pgAddressName = '' //籍贯
      this.pgAddressProvincial = '' //籍贯省
      this.pgAddressCity = '' //籍贯市
      // this.picker2Title = '请选择籍贯'
      this.nation = -1 //民族
      this.degree = -1 //学历
      this.email = '' //邮箱
      this.locationName = '' //所在地
      this.addressProvincial = '' //省编码
      this.addressCity = '' //市编码
      this.addressArea = '' //区编码
      // this.picker5Title = '请选择所在地区'
      this.industryName = -1 //所在行业-大类
      this.industrySubName = -1 //所在行业-小类
      this.industryNameAll = '' //所在行业大类小类
      this.occupationName = -1 //职业
      this.work = '' //工作单位
      this.workCom = '' //工作单位code
      this.workYears = '' //工作年限
      this.childBirthday = '' //孩子生日

    },
    // 跳过
    goSkip() {
      // 注册进入
      // let tolink = this.$store.state.userInfo.tolink;
      // if (tolink.indexOf('/login') > -1) {
      //   FunGoHome()
      //   return
      // }
      // this.$router.push(tolink);
      FunGoHome()
    },  
    // 从业人员
    changeEmployees() {
      this.$set(this, 'employees', !this.employees)
    },
    // 家长
    changeParent() {
      this.$set(this, 'parent', !this.parent)
    },
    // 性别选择
    changeSex () {
      if (this.idType === 0 && $regular.idCard.reg.test(this.idNo)) {
        return
      }
      this.showPicker1 = true
    },
    // 选择证件类型
    onConfirm (value, index) {
      this.idType = index
      this.showPicker = false
    },
    // 选择性别
    onConfirm1 (value, index) {
      this.sex = index
      this.showPicker1 = false
    },
    // 选择籍贯
    onConfirm2 (value, index) {
      this.pgAddressName = index
      this.showPicker2 = false
    },
    // 选择民族
    onConfirm3 (value, index) {
      this.nation = index
      this.showPicker3 = false
    },
    // 选择学历
    onConfirm4 (value, index) {
      this.degree = index
      this.showPicker4 = false
    },
    // 工作单位匹配
    changeUnit(value) {
      this.workSeo = []
      // console.log(value)
      this.work = value
      this.workArray.map((item, index) => {
        if (item.name.indexOf(value) > -1 && this.workSeo.indexOf(item) == -1) {
          this.workSeo.push(item)
        }
      })
    },
    // 跳往工作单位
    goWorkUnit() {
      // 关闭刷新页面
      this.isRefresh = false
      this.$router.push({
        path:'/mine/workUnit',
        query: {
          name: this.work
        }
      })
    },
    // 关闭所在地区
    onConfirm5 (value) {
      // console.log(value)
      if (this.showPicker2) {
        this.showPicker2 = false
        this.pgAddressName = value.selectedOptions[0].name // 籍贯
        this.pgAddressProvincial = value.selectedOptions[0].code // 籍贯省
        this.pgAddressCity = value.selectedOptions[1].code // 籍贯省
        this.pgAddressName = value.selectedOptions.map((option) => option.name).join('/')
      } else if (this.showPicker5) {
        this.addressProvincial = value.selectedOptions[0].code // 省编码
        this.addressCity = value.selectedOptions[1].code // 市编码
        this.addressArea = value.selectedOptions[2]?.code // 区编码
        this.showPicker5 = false
        this.locationName = value.selectedOptions.map((option) => option.name).join('/')
      }
    },
    // 关闭省市区弹框
    closeCityPop() {
      this.showPicker2 = false
      this.showPicker5 = false
    }, 
    // 选择行业
    onConfirm6 (value, index) {
      // console.log(value, index)
      this.industryName = index[0]
      if (!!value[1]) {
        this.industrySubName = index[1]
        this.industryNameAll = this.industryArray[0].values[this.industryName].name + '-' + this.industryArray[1].values[this.industrySubName].name
      } else {
        this.industrySubName = -1
        this.industryNameAll = this.industryArray[0].values[this.industryName].name
      }
      this.showPicker6 = false
      this.occupationName = -1
      // 职业
      this.getOccupation()
    },
    // 行业选项
    changeIndustry(e) {
      // console.log(e.children[0].currentIndex, e.children[1].currentIndex)
      let industryIndex1 = e.children[0].currentIndex
      let industryIndex2 = e.children[1].currentIndex
      // defaultIndex
      this.industryArray[1].defaultIndex = 0
      // console.log(this.industryArray[0].values[industryIndex1].code)
      this.getIndustryList1(this.industryArray[0].values[industryIndex1].code)
    },
    // 打开职业
    showOccupation() {
      if (this.industryName === -1) {
        this.$Toast('请选择所在行业')
      } else {
        this.showPicker7 = true
      }
    },
    // 选择职业
    onConfirm7 (value, index) {
      this.occupationName = index
      this.showPicker7 = false
    },
    // 选择工作单位
    // onConfirm8 (value, index) {
    //   this.work = index
    //   this.showPicker8 = false
    // },
    // 选择孩子生日
    onConfirm9 (value, index) {
      // console.log(value)
      this.childBirthday = value.format('yyyy-MM-dd')
      this.showPicker9 = false
    },
    // 提交
    onSubmit (values) {
      // console.log(values)
      // return;
      // 校验
      if (!this.employees && !this.parent) {
        this.$Toast('请选择身份')
        return 
      }

      // 用户类型
      if (this.employees && this.parent) {
        values.userType = 4
      } else if (this.employees) {
        values.userType = 2
      } else if (this.parent) {
        values.userType = 1
      }

      // 籍贯省市区
      // values.pgAddressName = this.pgAddressName // 籍贯
      values.pgAddressProvincial = this.pgAddressProvincial // 籍贯省
      values.pgAddressCity = this.pgAddressCity // 籍贯省

      // 所在地省市区
      values.addressProvincial = this.addressProvincial //所在地-省
      values.addressCity = this.addressCity //所在地-市
      values.addressArea = this.addressArea //所在地-区

      // console.log(values)
      // values = JSON.stringify(values)
      let params = values
      params.userType = values.userType //用户类型
      params.name = values.name //姓名
      params.idType = this.identityArray[this.idType].id //  证件类型
      // params.idNo = values.idNo //证件号码
      params.idNo = values.idNo ? values.idNo.toUpperCase() : undefined; //证件号码
      params.sex = this.sexArray[this.sex].id //性别
      params.pgAddressProvincial = values.pgAddressProvincial //籍贯-省
      params.pgAddressCity = values.pgAddressCity //籍贯-市
      console.log(this.nation)
      params.nationCode = (this.nation && this.nation>-1) || this.nation===0 || this.nation==='0' ? this.nationArray[this.nation].code : null //民族
      params.degreeCode = this.educationArray[this.degree].code //学历code
      params.degree = values.degree //学历
      params.email = values.email //邮箱
      params.addressProvincial = values.addressProvincial //所在地-省
      params.addressCity = values.addressCity //所在地-市
      params.addressArea = values.addressArea //所在地-区

      if (this.employees) {
        // params.industryCode = this.industryArray[this.industryName].code //所在行业code-大类
        // params.industry = values.industryName //所在行业-大类
        params.industryCode = this.industryArray[0].values[this.industryName].code //所在行业code-大类
        params.industry = this.industryNameAll.split('-')[0] //所在行业-大类
        params.industryCodeSub = this.industryArray[1].values[this.industrySubName].code //所在行业code-小类
        params.industrySub = this.industryNameAll.split('-')[1] //所在行业-小类
        params.occupationCode = this.occupationArray[this.occupationName].code //职业code
        params.occupationName = values.occupationName //职业
        // params.workCom = this.workArray[this.work].id //工作单位code
        params.work = values.work //工作单位
        params.workCom = this.workCom //工作单位code
        params.workYears = values.workYears //工作年限
      }

      if (this.parent) {
        params.childBirthday = values.childBirthday //孩子生日
      }
      
      // console.log(params)

      // return

      this.$ajax({
        url: '/hxclass-mobile/user/info/add',
        method: 'POST', 
        params: params
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          // this.$Toast('认证成功')
          this.getIdentityAuthen()
          // this.checkIdent();
          this.showPopup = true
          // 更新用户认证信息
          let obj = JSON.parse(localStorage.getItem('userInfo'))
          obj.userType = params.userType
          this.$store.state.userInfo = obj
          localStorage.setItem('userInfo', JSON.stringify(obj))
          // 查询是否已身份认证
          this.checkIdent();
          // 正常进入
          if (this.type == 1) {
            // 获取身份信息
            this.getIdentityAuthen()
          } 
          // 注册进入
          else if (this.type == 2) {
            let tolink = this.$store.state.userInfo.tolink;
            if (tolink.indexOf('/login') > -1) {
              FunGoHome();
              return
            }
            this.$router.push(tolink);
          }
          
        } else {
          this.$Toast(res.message)
        }
      })
    },
    // 提交校验不通过
    onFailed({values, errors}) {
      // 姓名判空
      if (this.name === '') {
        this.$Toast('请填写姓名')
      } 
      // 姓名校验
      else if (!this.$regular.name.reg.test(this.name)) {
        this.$Toast('请输入真实姓名')
      }
      // 证件类型
      else if (this.idType === '') {
        this.$Toast('请选择证件类型')
      }
      // 证件号码判空
      else if (this.idNo === '') {
        this.$Toast('请填写证件号码')
      }
      // 身份证校验
      else if (this.idType == 0 && !$regular.idCard.reg.test(this.idNo)) {
        this.$Toast('无法认证，请核实身份证号码是否准确')
      }
      // 港澳台居民居住证校验
      // else if (this.idType == 1 && !$regular.idCard1.reg.test(this.idNo)) {
      //   this.$Toast('请填写正确的港澳台居民居住证')
      // }
      // 护照校验
      else if (this.idType == 2 && !$regular.passport.reg.test(this.idNo)) {
        this.$Toast('请填写正确的护照')
      }
      // 性别
      else if (this.sex === '') {
        this.$Toast('请选择性别')
      }
      // // 籍贯
      // else if (this.pgAddressName === '') {
      //   this.$Toast('请选择籍贯')
      // }
      // 民族
      else if (this.nation === -1 || this.nation === '') {
        this.$Toast('请选择民族')
      }
      // 学历
      else if (this.degree === -1 || this.degree === '') {
        this.$Toast('请选择学历')
      }
      // 邮箱填写后校验
      else if (this.email !== '' && !$regular.email.reg.test(this.email)) {
        this.$Toast('请填写正确的邮箱地址')
      }
      // 所在地
      else if (this.locationName === '') {
        this.$Toast('请选择所在地')
      }

      // 从业人员
      else if (!!this.employees) {
        // 所在行业大类
        if (this.industryName === -1 || this.industryName === '') {
          this.$Toast('请选择所在行业')
        } 
        // 职业
        else if (this.occupationName === -1 || this.occupationName === '') {
          this.$Toast('请选择职业')
        }
        // 工作单位
        else if (this.work === '') {
          this.$Toast('请选择工作单位')
        }
        // 工作年限判空
        else if (this.workYears === '') {
          this.$Toast('请填写工作年限')
        }
        // 工作年限校验
        else if (!$regular.number.reg.test(this.workYears)) {
          this.$Toast('请输入正确的工作年限数字')
        }
      }

      // 家长
      else if (!!this.parent) {
        // 孩子生日
        if (this.childBirthday === '') {
          this.$Toast('请选择孩子生日')
        }
      }
      
    },
    // 查询是否已身份认证
    checkIdent() {
      this.$ajax({
        url: '/hxclass-mobile/user/anthen/'+this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 未认证
          if (res.data == 0) {
            this.$store.commit('changecertified',false);
          }
          // 已认证
          else if (res.data == 1) {
            this.$store.commit('changecertified',true);
          }
        }
      })
    },
    // 修改
    editData() {
      // document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.authenticationMessage = null
    },
    // 根据身份证判断性别
    blurIdCode () {
      if (this.idType !== 0) {
        this.sexDisabled = false
        return
      }
      if ($regular.idCard.reg.test(this.idNo)) {
        this.sexDisabled = true
        if (parseInt(this.idNo.substr(16, 1)) % 2 == 1) {
          this.sex = 0
        } else {
          this.sex = 1
        }
      }
    },
    // 身份认证信息
    getIdentityAuthen() {
      this.$ajax({
        url: '/hxclass-mobile/user/info/infodetail',
        method: 'GET',
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.authenticationMessage = res.data
          // 回显
          this.showIdentityAuthen()
        }
      })
    },
    // 回显身份认证
    async showIdentityAuthen() {
      if (this.authenticationMessage == null) {
        this.notCertified = true;
        return this.isShowAll = true
      }
      this.isAuthentication = true //已认证
      // 从业人员、家长
      if (this.authenticationMessage.userType == 1) {
        this.parent = true
        this.employees = false
      } else if (this.authenticationMessage.userType == 2) {
        this.employees = true
        this.parent = false
      } else if (this.authenticationMessage.userType == 4) {
        this.parent = true
        this.employees = true
      }
      // console.log(this.industryArray)
      // this.industryName = this.educationArray.findIndex((value)=>value.code==this.authenticationMessage.industryCode) // 所在行业
      if (!!this.authenticationMessage.industryCode) {
        this.industryName = this.industryArray[0].values.findIndex((value)=>value.code==this.authenticationMessage.industryCode) //所在行业 - 大类
        await this.getIndustryList1(this.authenticationMessage.industryCode) //行业 - 小类
        this.industrySubName = this.industryArray[1].values.findIndex((value)=>value.code==this.authenticationMessage.industryCodeSub) //所在行业 - 小类
        // console.log(this.industryName,this.industrySubName, this.industryArray)
        this.$set(this.industryArray[0], 'defaultIndex', this.industryName)
        this.$set(this.industryArray[1], 'defaultIndex', this.industrySubName)
        let industryName = this.industryName == -1 ? '' : this.industryArray[0].values[this.industryName].name
        let industrySubName = this.industryName == -1 ? '' : this.industryArray[1].values[this.industrySubName].name || ''
        // console.log(this.industryArray[1].values[this.industrySubName].name)
        this.industryNameAll = industryName + '-' + industrySubName
        // console.log(this.industryArray, this.industryNameAll)
        await this.getOccupation() //职业
      } else {
        this.industryName = -1 //所在行业 - 大类
        await this.getIndustryList1() //行业 - 小类
        this.industrySubName = -1 //所在行业 - 小类
        // console.log(this.industryName,this.industrySubName, this.industryArray)
        // this.$set(this.industryArray[0], 'defaultIndex', this.industryName)
        // this.$set(this.industryArray[1], 'defaultIndex', this.industrySubName)
        let industryName = this.industryName == -1 ? '' : this.industryArray[0].values[this.industryName].name
        let industrySubName = this.industryName == -1 ? '' : this.industryArray[1].values[this.industrySubName].name || ''
        // console.log(this.industryArray[1].values[this.industrySubName].name)
        this.industryNameAll = industryName + '-' + industrySubName
        // console.log(this.industryArray, this.industryNameAll)
        await this.getOccupation() //职业
      }
      
      
      this.idType = this.identityArray.findIndex((value)=>value.id==this.authenticationMessage.idType) // 证件类型
      this.sex = this.sexArray.findIndex((value)=>value.id==this.authenticationMessage.sex) // 性别
      this.nation = this.authenticationMessage.nationCode && this.nationArray.findIndex((value)=>value.code==this.authenticationMessage.nationCode) // 民族
      this.degree = this.authenticationMessage.degreeCode && this.educationArray.findIndex((value)=>value.code==this.authenticationMessage.degreeCode) // 学历
      // this.industryName = this.educationArray.findIndex((value)=>value.code==this.authenticationMessage.industryCode) // 所在行业
      this.occupationName = this.authenticationMessage.occupationCode && this.occupationArray.findIndex((value)=>value.code==this.authenticationMessage.occupationCode) // 职业
      this.pgAddressName = this.authenticationMessage.pgAddressName // 籍贯
      this.pgAddressProvincial = this.authenticationMessage.pgAddressProvincial // 籍贯-省code
      this.pgAddressCity = this.authenticationMessage.pgAddressCity // 籍贯-市code
      this.locationName = this.authenticationMessage.locationName // 所在地
      this.addressProvincial = this.authenticationMessage.addressProvincial // 所在地-省
      this.addressCity = this.authenticationMessage.addressCity // 所在地-市
      this.addressArea = this.authenticationMessage.addressArea // 所在地-区
      this.name = this.authenticationMessage.name // 姓名
      this.idNo = this.authenticationMessage.idNo // 证件号码
      this.email = this.authenticationMessage.email // 邮箱
      this.work = this.authenticationMessage.work // 工作单位
      this.workCom = this.authenticationMessage.workCom // 工作单位code
      this.workYears = this.authenticationMessage.workYears // 工作年限
      if (this.authenticationMessage.childBirthday) {
        let currentArr = this.authenticationMessage.childBirthday.split('-')
        this.currentDate = new Date(currentArr[0],currentArr[1],currentArr[2]) // 孩子生日
        this.childBirthday = this.authenticationMessage.childBirthday // 孩子生日
      }
      // addressProvincial, addressCity, addressArea
      // 展示页面
      this.isShowAll = true      
      // 工作单位回显
      // this.getWorkUnit()
    },
    // 民族
    async getNationList() {
      await this.$ajax({
        url: '/hxclass-mobile/nation/list',
        method: 'GET',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.nationArray = res.data
        }
      })
    },
    // 学历
    async getEducationList() {
      await this.$ajax({
        url: '/hxclass-mobile/nation/degree/list',
        method: 'GET',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.educationArray = res.data
        }
      })
    },
    // 所在行业 - 大类
    async getIndustryList() {
      await this.$ajax({
        url: '/hxclass-mobile/nation/industry/list',
        method: 'GET',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // this.industryArray = res.data
          this.industryArray.push({values:  res.data})
          this.industryArray.push({values:  []})
          // console.log(this.industryArray)
        }
      })
    },
    // 所在行业 - 小类
    async getIndustryList1(code) {
      let pcode = ''
      if (!code) {
        pcode = this.industryArray[0].values[0].code
      } else {
        pcode = code
      }
      await this.$ajax({
        url: '/hxclass-mobile/nation/industry/list?pcode=' + pcode,
        method: 'GET',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res)
          this.$set(this.industryArray[1], 'values', res.data)
          // console.log(this.industryArray)
        }
      })
    },
    // 职业
    async getOccupation() {
      let index = this.industrySubName == -1 ? 0 : this.industrySubName
      await this.$ajax({
        url: '/hxclass-mobile/nation/position/list',
        method: 'GET',
        params: {
          // industryId: this.industryArray[0].values[this.industrySubName].code,
          industryId: this.industryArray[1].values[index].code
          // industryId: 2
        }
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.occupationArray = res.data
        }
      })
    },
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () {},
  // 计算属性监听
  computed: { },
  // 自定义的侦听器
  watch: {
    // 证件类型
    idType(newVal, oldVal) {
      // 身份证
      if (newVal === 0) {
        this.idcardDefult = this.idCardReg
      } 
      // 港澳台身份证
      else if (newVal === 1) {
        // this.idcardDefult = this.idCard1Reg
        this.idcardDefult = this.idcardReq
      } 
      // 护照
      else if (newVal === 2) {
        // this.idcardDefult = this.passportReg
        this.idcardDefult = this.idcardReq
      }
      // 必填
      else {
        this.idcardDefult = this.idcardReq
      }
      // this.idNo = ''
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-popup {
  background-color: transparent;
}
/deep/.van-cell {
  padding: 0;
  &::after {
    border: 0;
  }
}
.identityAuthentication {
  // min-height: calc(100vh - 44px);
  // min-height: 100vh;
  // height: 100vh;
  // background-color: #fafafa;
  padding: 12px 18px 76px 18px;
  box-sizing: border-box;
  // 跳过
  .skip_box {
    position: relative;
    padding-top: 60px;
    padding-bottom: 30px;
    .box_con {
      text-align: center;
      .con_txt {
        font-size: 20px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #15B7DD;
        line-height: 23px;
      }
      .con_info {
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #C4C4C4;
        line-height: 21px;
        margin-top: 3px;
        .info_con {
          position: relative;
          z-index: 3;
          .info_light {
            color: #79DEFF;
          }
        }
        .info_long {
          position: absolute;
          left: -6px;
          bottom: -3px;
          width: 102%;
          height: 18px;
          border-left: 1px solid rgba(121, 222, 255, 0.5);
          border-bottom: 1px solid rgba(121, 222, 255, 0.5);
          // background-color: #ffffff;
          z-index: 2;
        }
        .info_short {
          position: absolute;
          left: -10px;
          bottom: -6px;
          width: 7px;
          height: 7px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          border: 1px solid #79DEFF;
          z-index: 1;
        }
      }
    }
    .box_button {
      position: absolute;
      top: 30px;
      right: 0;
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #999999;
      border: 1px solid #D2D2D2;
      border-radius: 19px;
      padding: 6px 10px;
    }
  }
  // 加载
  .load_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    text-align: center;
    .box_load {
      display: inline-block;
      font-size: 15px;
      margin-top: 10px;
      color: #666666;
    }
  }
  .footer {
    position: fixed;
    // bottom: 0;
    left: 0;
    width: 100vw;
    padding: 5px 40px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: 100px;
    .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }
  .identity {
    margin-top: 8px;
    padding: 16px 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    .identityItem {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      padding-top: 12px;
      border-bottom: 1px solid #f5f5f5;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      .title {
        // min-width: 70px;
        white-space: nowrap;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 22px;
      }
      .value {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .placehold {
        // font-size: 12px;
        // font-family: PingFangMedium;
        // font-weight: 400;
        // color: #999999;
        // line-height: 22px;
        margin-right: 8px;
      }
      .picker_box {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .pick_show {
        position: absolute;
        top: 45px;
        right: 0;
        width: 80%;
        height: 100px;
        overflow-y: scroll;
        background-color: #f5f5f5;
        z-index: 4;
        .show_item {
          font-size: 12px;
          color: #666666;
          border-bottom: 1px solid #d3d3d3;
          padding: 12px;
        }
      }
      // uni-picker{
      // 	.uni-input{
      // 		display: flex;
      // 		.pickerValue{
      // 			font-size: 12px;
      // 			font-family: PingFangMedium;
      // 			font-weight: 400;
      // 			color: #333333;
      // 			line-height: 22px;
      // 		}

      // 		img{
      // 			width: 16px;
      // 			height: 22px;
      // 		}
      // 	}
      // }
      .inputtext {
        flex: 1;
        margin-left: 20px;
        .uni-input-placeholder {
          span-align: right;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
        /deep/.uni-input-input {
          span-align: right;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
      }
      .leftmess {
        display: flex;
        flex-direction: column;
        .option {
          font-size: 16px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
        .describe {
          margin-top: 4px;
          font-size: 10px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
        }
      }
      .rightIcon {
        padding-top: 2px;
        width: 18px;
        height: 18px;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 2px;
          border: 1px solid #999999;
          box-sizing: border-box;
        }
      }
    }
  }
  .detailedtitle {
    height: 24.5px;
    display: flex;
    align-items: center;
    .colorDiv {
      width: 3px;
      height: 13px;
      background-color: rgba(21, 183, 221, 1);
      margin-right: 3.5px;
      border-radius: 4px;
    }
    .title {
      font-weight: bold;
      font-size: 16.5px;
      line-height: 24.5px;
      span {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    // .redDiv{
    //   margin: 0 3px 0 6px;
    //   width: 13px;
    //   height: 12px;
    //   border-radius: 50%;
    //   border: 1px solid #EC6C01;
    //   text-align: center;
    // }
  }
  .detailHint {
    padding: 4px 9px;
    .title-red{
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #EC6C01;
      line-height: 17px;
    }
  }
  // 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
}
</style>
