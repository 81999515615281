<template>
  <div v-html="html"></div>
</template>

<script>
export default {
  data() {
    return {
      html: ''
    }
  },
  created() {
    console.log(11111)
    window.VHTML = function (e) {
      console.log(e)
      this.html = e;
    };
  }
}
</script>

<style>

</style>