<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      title="个人信息"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="main-box">
      <div class="info-box">
        <div class="list">
          <div class="left">头像</div>
          <div class="right" @click="gomyimg()">
            <van-image
              class="right-image"
              fit="cover"
              :src="$store.state.userInfo.photo || defaultPhoto"
            />
            <img
              class="right-icon"
              src="../../assets/image/my/icon_right.png"
            />
          </div>
        </div>
        <div class="list">
          <div class="left">用户名</div>
          <div class="right" @click="toNickName()">
            <span
              :class="$store.state.userInfo.userName ? 'account' : 'name'"
              >{{
                $store.state.userInfo.userName
                  ? $store.state.userInfo.userName
                  : "请输入用户名"
              }}</span
            >
            <img
              class="right-icon"
              src="../../assets/image/my/icon_right.png"
            />
          </div>
        </div>
        <div class="list">
          <div class="left">账号</div>
          <div class="right" style="margin-right: 8px">
            <span class="account">{{ $store.state.userInfo.mobile }}</span>
            <!-- <img class="right-icon" src="../../assets/image/my/icon_right.png" /> -->
          </div>
        </div>
      </div>
      <div class="address-box">
        <div class="list">
          <div class="left">收货地址</div>
          <router-link to="/address/addressList" class="right">
            <span class="account">管理收货地址</span>
            <img
              class="right-icon"
              src="../../assets/image/my/icon_right.png"
            />
          </router-link>
        </div>
      </div>
    </div>
    <n-transition ref="pop" speed="ease-in-out" :height="192" :maskVal="0.5">
      <div class="card-box" v-if="pageType == 3">
        <div class="select">
          <van-uploader type="file" :after-read="uploadImg"
            >从手机相册选择</van-uploader
          >
        </div>
        <div class="select">
          <van-uploader capture="camera" :after-read="uploadImg"
            >拍照</van-uploader
          >
        </div>
        <div class="select cancel" @click="onCancel()">取消</div>
      </div>
      <!-- v-if="pageType==2" -->
      <div class="card-box" v-if="pageType == 1 || pageType == 2">
        <div class="select" @click="onPhoto()">从手机相册选择</div>
        <div class="select" @click="onCamera()">拍照</div>
        <div class="select cancel" @click="onCancel()">取消</div>
      </div>
    </n-transition>

    <!-- 上传中 -->
    <div class="box_load" v-if="isLoading">
      <!-- <van-loading size="24px" color="#1e97b4" vertical>正在上传...</van-loading> -->
    </div>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
import { dataURLtoFile } from "@/unit/fun.js"; //base64转为对象
import { FunJudgDevice } from "@/unit/fun.js";
import { onTakePhoto, onChoosePhoto } from "@/assets/js/NativeWebKit.js";
const DeviceType = FunJudgDevice();
export default {
  // 可用组件的哈希表
  components: { nTransition },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      pageType: 0, //1安卓 2ios 3浏览器
      isName: false, //是否设置昵称
      // 默认头像
      defaultPhoto: require("@/assets/image/my/default_photo.png"),
      // 头像
      photo: "",
      isLoading: false, //是否正在上传
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 安卓
    if (DeviceType == "android") {
      this.pageType = 1;
    } else if (DeviceType == "ios" && window.webkit) {
      // ios
      this.pageType = 2;
    } else {
      // 浏览器
      this.pageType = 3;
    }

    // app选择照片后回调
    window.returnPhotoUrl = (url) => {
      // console.log('返回图片地址',url)
      var file = dataURLtoFile(url, "fileName.jpg");
      // 上传图片
      this.uploadImg(file, 1);
    };

    //// console.log
    window.returnOnCancel = () => {
      this.onCancel();
    };
  },
  // 事件处理器
  methods: {
    // 修改用户名
    toNickName() {
      this.$router.push("/mine/setNickName");
    },
    // 查看大头像
    gomyimg() {
      this.$router.push("/mine/myimg");
    },
    onHeadClick() {
      this.$refs["pop"].show();
    },
    onCancel() {
      this.$refs["pop"].hide();
    },
    // 打开相册
    onPhoto() {
      // 1裁剪图片
      // window.webkit.messageHandlers.photo.postMessage('1')
      // 1裁剪 0不裁剪
      // 1方的不能随意拖动 0可以随意拖动
      onChoosePhoto(1, 1);
    },
    // 拍照
    onCamera() {
      // 1裁剪图片
      // window.webkit.messageHandlers.camera.postMessage('1')
      // 1裁剪 0不裁剪
      // 1方的不能随意拖动 0可以随意拖动
      onTakePhoto(1, 1);
    },
    // 从相册选择
    uploadImg(e, type) {
      let file = type == 1 ? e : e.file;
      this.isLoading = true;
      this.$upload({
        file: file,
        progress: (e) => {},
        success: (e) => {
          // console.log(e)
          this.photo = e;
          // 修改头像
          this.setPhoto();
        },
        fail: (err) => {
          this.isLoading = false;
          this.$Toast("上传失败", err);
        },
      });
    },
    // 修改头像
    setPhoto() {
      this.$ajax({
        url:
          "/hxclass-mobile/user/update?isSysUser=0&photo=" +
          this.photo +
          "&userId=" +
          this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.isLoading = false;
          this.$Toast.success("修改成功");
          // 修改缓存昵称
          let obj = JSON.parse(localStorage.getItem("userInfo"));
          obj.photo = this.photo;
          this.$store.state.userInfo = obj;
          localStorage.setItem("userInfo", JSON.stringify(obj));
          // console.log
          this.$refs["pop"].hide();
        }
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.container-box {
  .main-box {
    margin: 8px 18px;

    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      // background-color: lightgreen;
      border-bottom: 0.5px solid #f5f5f5;

      .left {
      }

      .right {
        display: flex;
        align-items: center;

        .right-image {
          overflow: hidden;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .right-icon {
          margin-left: 8px;
          width: 22px;
          height: 22px;
        }

        .name {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }

        .account {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }
    }

    .info-box {
      margin-bottom: 12px;
      padding: 0 12px;
      height: 162px;
      background: #ffffff;
      border-radius: 8px;
    }

    .address-box {
      padding: 0 12px;
      height: 54px;
      background: #ffffff;
      border-radius: 8px;
    }
  }

  .card-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 18px 0;

    .select {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      text-align: center;
      line-height: 54px;
    }

    .cancel {
      border-bottom: none;
      color: #666666;
    }
  }

  // 上传
  .box_load {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
</style>
