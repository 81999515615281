//知识专区
export default [
  { //知识专区
    path: 'specialArea',
    component: () =>
      import('../views/knowledge/specialArea.vue')
  },
  { //自闭症pdf阅读页
    path: 'specialAreaCon',
    name: 'specialAreaCon',
    component: () =>
      import('../views/knowledge/specialAreaCon.vue')
  },
  { //自闭症之声
    path: 'autismSpeaks',
    component: () =>
      import('../views/knowledge/autismSpeaks.vue')
  },
]