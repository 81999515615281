// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/examination/certificate_icon1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tab[data-v-2cc0709d]{line-height:47px;padding:0 18px;height:47px;font-family:PingFangMedium;overflow-y:scroll;white-space:nowrap}.tab .tab_item[data-v-2cc0709d]{margin-right:22px;display:inline-block;font-size:14px;color:#666;line-height:27px}.tab .tab_item.pick[data-v-2cc0709d]{font-size:16px;color:#333;font-weight:700;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:36.5px;line-height:20px;background-repeat:no-repeat;background-position:bottom}.tab .tab_item[data-v-2cc0709d]:last-child{margin-right:0}.tab[data-v-2cc0709d]::-webkit-scrollbar{display:none}", ""]);
// Exports
module.exports = exports;
