// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/study/bgc_delay1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/image/study/pic_delay.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".container-box .header-box[data-v-53afe4ab]{position:relative;width:100%;height:262px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100%;overflow:hidden}.container-box .header-box .header-left[data-v-53afe4ab]{position:absolute;top:32px;left:18px}.container-box .header-box .header-pic[data-v-53afe4ab]{position:relative;margin:86.5px auto 0;width:96.5px;height:96.5px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100%;font-size:14px;font-family:PingFangMedium;font-weight:500;color:#fff;text-align:center;line-height:121.5px}.container-box .header-box .header-image[data-v-53afe4ab]{position:absolute;left:0;bottom:0;width:100%;height:39px}.container-box .main-box[data-v-53afe4ab]{margin:46px 63px 0;font-size:16px;font-family:PingFangMedium;font-weight:500;color:#666;line-height:24px;text-align:center}.container-box .main-box a[data-v-53afe4ab]{color:#15b7dd}.container-box .btn-box[data-v-53afe4ab]{position:fixed;bottom:0;display:flex;justify-content:space-around;width:100%;height:75px}.container-box .btn-box .all-btn-bigCircle[data-v-53afe4ab]{width:323.5px}", ""]);
// Exports
module.exports = exports;
