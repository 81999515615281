<template>
  <div class="content">
    <van-nav-bar
      class="nav-circular"
      title="满意度调查问卷"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="">
      <div class="top">
        为了提供更加优质的服务，请在确认证书信息前填写以下满意度调查问卷，问卷调查结果后续将提交中国残疾人康复协会，感谢您的配合！
      </div>
      <div class="center">
        <div
          class="gradebox"
          v-for="(item, index) in surveyContent"
          :key="index"
        >
          <div class="boxtop">
            <div class="img">{{ index + 1 }}</div>
            <div class="txt">{{ item.title }}</div>
          </div>
          <div class="dlex" v-if="item.type == 1">
            <div class="boxconter">
              <div
                class="fraction"
                v-for="i in 5"
                :key="i"
                @click="item.answer = i"
              >
                <img
                  src="@/assets/image/config/Star33.png"
                  alt=""
                  width="20"
                  v-if="i > item.answer"
                />
                <img
                  v-else
                  src="@/assets/image/config/Star31.png"
                  alt=""
                  width="20"
                />
              </div>
            </div>
            <div class="boxbottom">
              <ul>
                <li v-if="item.answer == 5">非常满意</li>
                <li v-if="item.answer == 4">满意</li>
                <li v-if="item.answer == 3">一般</li>
                <li v-if="item.answer == 2">不满意</li>
                <li v-if="item.answer == 1">非常不满意</li>
              </ul>
            </div>
          </div>

          <div class="input" v-if="item.type == 2">
            <van-field
              v-model="proJect.advice"
              rows="8"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="请输入留言"
              show-word-limit
            />
          </div>
        </div>
      </div>
      <!-- 下一步 -->
      <div class="next-btn">
        <button
          type="default"
          class="all-btn-bigCircle"
          @click="pushAnswer()"
          native-type="submit"
        >
          下一步
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      surveyContent: [],
      // 问卷提交对象
      proJect: {
        advice: "", // 调查问卷建议
        surveyPaperId: "", // 调查问卷id
        examConfigId: null, // 关联id
      },

      examineeId: null,
      examineeId: null,
    };
  },
  methods: {
    // 获取题目
    exportReport() {
      this.$ajax({
        url: "/hxclass-mobile/survey/msg/1",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arrject = JSON.parse(res.data.surveyContent);
          this.proJect.surveyPaperId = res.data.id;
          arrject.title_list.forEach((element) => {
            element.answer = 5;
            this.surveyContent.push(element);
          });
          // console.log(this.surveyContent);
        }
      });
    },
    // 提交问卷调查答案
    pushAnswer() {
      for (let index = 0; index < this.surveyContent.length; index++) {
        this.proJect["answer" + index] = this.surveyContent[index].answer + "";
      }
      // console.log(this.proJect);
      if (this.proJect.examConfigId) {
        this.proJect.examConfigId = parseInt(this.proJect.examConfigId);
      }
      // 存入sessionStorage 一次性提交数据
      sessionStorage.setItem("surveyContent", JSON.stringify(this.proJect));
      this.toConfirm();
      return;
      this.$ajax({
        url: "/hxclass-mobile/survey/submit",
        method: "PUT",
        params: this.proJect,
      }).then((res) => {
        if (res.code == 200 && res.success) {
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 个人-确认信息
    toConfirm() {
      this.$router.push({
        path: "/examEnroll/signUpConfirm1",
        query: {
          signupUserId: this.signupUserId,
          examineeId: this.examineeId,
        },
      });
    },
  },
  created() {
    // 考生id
    this.examineeId = this.$route.query.examineeId;
    // 用户报名id
    this.signupUserId = this.$route.query.signupUserId;
    this.proJect.examConfigId = this.$route.query.id;
    this.exportReport();
  },
};
</script>


<style lang="less" scoped>
.content {
  min-height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
}

.top {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding: 0 15px;
  padding-top: 10px;
}
.center {
  padding: 0 15px;
  padding-top: 16px;
}
.gradebox {
  overflow: hidden;
  margin-bottom: 14px;
  .boxtop {
    display: flex;
    align-items: baseline;
    .img {
      width: 18px;
      background-image: url(~@/assets/image/examination/Vector14.png);
      background-repeat: no-repeat;
      background-size: 100%;
      text-align: center;
      font-size: 11px;
      flex-shrink: 0;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #ffffff;
      line-height: 17px;
    }
    .txt {
      margin-left: 5px;
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 27px;
    }
  }
  .dlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .boxconter {
      margin-left: 18px;
      margin-top: 10px;
      // overflow: hidden;
      // border-radius: 8px 8px 8px 8px;
      opacity: 1;
      // border: 1px solid #d7d7d7;
      display: flex;
      align-items: center;
      .fraction {
        margin: 0;
        padding: 0;
        width: 20px;
        text-align: center;
        margin-right: 16px;
      }
    }
    .boxbottom {
      margin-right: 35px;
      ul {
        display: flex;
        align-items: center;
        li {
          display: block;
          text-align: center;
          display: block;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #999999;
        }
      }
    }
  }

  .input {
    /deep/.van-cell {
      background-color: #f7f7f9 !important;
    }
    background: #f7f7f9;
  }
}

.next-btn {
  text-align: center;
  padding-bottom: 30px;
}
.all-btn-bigCircle {
  border: #ffffff solid 1px;
}
</style>