<template>
  <!-- 修改地址 -->
  <div class="login">
    <div 
      class="login_return" 
      @click="goBack" 
      :style="{'top':stateHeight+20+'px'}">
      <img class="return_img" src="@/assets/image/close.png" alt="关闭登录页面">
    </div>
    <!-- 标语 -->
    <div class="box1">
      <div class="box_top">
        <img class="top_icon" src="@/assets/image/login/login_bg.png" />
      </div>
      <div class="box_img">
        <img class="img_icon" src="@/assets/image/icon/logo.png" />
      </div>
    </div>

    <!-- 登录 -->
    <div class="box2" v-if="LoginOneClick==0">
      <!-- 输入框 -->
      <div class="box_input">
        <div class="input_item" v-if="loginType==0 || loginType==2">
          <div class="item_num" @click="showPopup()">
            <p>{{popCode[popIndex].code}}</p>
            <img class="code_icon" src="@/assets/image/login/icon1.png" />
          </div>
          <van-field
            class="item_txt"
            v-model="phone"
            placeholder="请输入手机号码"
            placeholder-class="item_pla"
          />
        </div>
        <div class="input_item" v-if="loginType==0 || loginType==2">
          <van-field
            maxlength="6"
            class="item_txt"
            v-model="dxcode"
            @input="changeDxcode"
            placeholder="请输入验证码"
            placeholder-class="item_pla"
          />
          <div @click="onDxCode()">
            <p class="item_code" v-if="isDxCode" style="color:#00B3C5;">获取验证码</p>
            <p class="item_code" v-else style="color:#999;">重新发送 {{countdown}}</p>
          </div>
        </div>
        <div class="input_item" v-if="loginType==1">
          <van-field 
            class="item_txt" 
            v-model="username"
            placeholder="请输入手机号码" 
            placeholder-class="item_pla" />
        </div>
        <div class="input_item" style="margin-bottom: 6%;" v-if="loginType==0 || loginType==1">
          <!-- :type="!passWordShow?'password':'text'" -->
          <van-field
            v-model="password"
            class="item_txt"
            :class="{'item_password':!passWordShow}"
            @input="changePassword"
            placeholder="8-16位数字、字母的组合密码"
            placeholder-class="item_pla"
          />
          <div @click="showPassWord()">
            <img
              v-if="!passWordShow"
              class="item_icon"
              src="@/assets/image/login/password_icon1.png"
            />
            <img v-else class="item_icon" src="@/assets/image/login/password_icon2.png" />
          </div>
        </div>
      </div>
      <!-- 手机号码 -->
      <div class="box_phone" v-if="loginType==3">{{phone.substring(0,3)}}****{{phone.substring(7,11)}}</div>
      <!-- 提示语 -->
      <div class="box_hint" v-if="loginType==2">未注册的手机号登录自动创建云课堂账号</div>
      <!-- 注册按钮 -->
      <div class="box_button" v-if="loginType==0">
        <p class="all-btn-middle btn" @click="onEnroll()">注册</p>
      </div>
      <!-- 一键按钮 -->
      <div class="box_button box_button1" v-if="loginType==3">
        <p class="all-btn-middle btn" @click="oneLogin()">本机号码一键登录</p>
        <!-- <div style="font-size: 13px;color: #15B3DD;">
          <div>如果未登录，并且不是白名单页面，则跳转登录页面</div>
          <div>白名单页面集合存放在js文件里面</div>
          <div>assets/js/WhiteList.js</div>
        </div>
        <div style="color: #E79F33;">如果不想设置白名单，请点击按钮生成假数据</div>
        <p class="all-btn-middle btn" @click="onSave()">登录</p>
        <p class="all-btn-middle btn" @click="onClear()">清除状态</p> -->
      </div>
      <!-- 短信登录按钮 -->
      <div class="box_button box_button2" v-if="loginType==2">
        <p class="all-btn-middle btn" @click="onLoginNote()">短信登录</p>
      </div>
      <!-- 协议条款 -->
      <div class="box_clause" :class="{'shake_slow':isShake&&!clauseAgree}">
        <div class="clause_icons" @click="changeClause()">
          <van-icon v-if="!clauseAgree" name="circle" color="#858585" size="15" />
          <van-icon v-else name="passed" color="#2CBEE0" size="15" />
        </div>
        <div class="clause_con">
          <span @click="changeClause()">我已阅读并同意</span>
          <!-- <router-link to="/login/clause?type=1" class="light">《华夏云课堂服务协议》</router-link> -->
          <span class="light" @click="goClause(1)">《华夏云课堂服务协议》</span>
          <span @click="changeClause()">和</span>
          <!-- <router-link to="/login/clause?type=2" class="light">《华夏云课堂隐私条款》</router-link> -->
          <span class="light" @click="goClause(2)">《华夏云课堂隐私协议》</span>
        </div>
      </div>
      <!-- 密码登录按钮 -->
      <div class="box_button box_button3" v-if="loginType==1">
        <p class="all-btn-middle btn" @click="onLoginPassword()">密码登录</p>
      </div>
      <!-- 操作 -->
      <div class="box_operate" v-if="loginType==1">
        <router-link to="/login/retrievePassword" class="operate_item">忘记密码？</router-link>
        <div class="operate_item" @click="changeLoginType(0)">新用户注册</div>
      </div>
    </div>
    
    <!-- 登录方式 -->
    <div 
      class="box3" 
      :style="{top: (clientHeight - LoginTypeHeight) + 'px'}"
      id="box3" 
      v-if="isLoginType">
      <div class="box_title" v-if="loginType==0">
        <p class="title_line"></p>
        <div class="title_main">
          已有账号，立即<span class="light" @click="loginType=1">登录</span>
        </div>
        <p class="title_line"></p>
      </div>
      <div class="box_title" v-else>
        <p class="title_line"></p>
        <p class="title_main">其他登录方式</p>
        <p class="title_line"></p>
      </div>
      <div class="box_con">
        <div v-if="loginType!==2">
          <div class="con_item" @click="changeLoginType(2)">
            <img class="item_icon" src="@/assets/image/login/type_icon1.png" />
            <!-- <div class="item_con">验证码登录</div> -->
          </div>
        </div>
        <div v-if="loginType!==0 && loginType!==1">
          <div class="con_item" @click="changeLoginType(1)">
            <img class="item_icon" src="@/assets/image/login/type_icon2.png" />
            <!-- <div class="item_con">密码登录</div> -->
          </div>
        </div>
        <div v-if="ifonelogin==1 && !isH5">
          <div class="con_item" @click="onLoginOneClick()">
          <!-- <div class="con_item" @click="onOneClickLogin()"> -->
            <img class="item_icon" src="@/assets/image/login/type_icon3.png" />
            <!-- <div class="item_con">一键登录</div> -->
          </div>
        </div>
        <div v-if="ifwxlogin==1 && !isH5">
          <div class="con_item" @click="onLoginWeChat()">
          <!-- <div class="con_item" @click="onWxLogin()"> -->
            <img class="item_icon" src="@/assets/image/login/type_icon4.png" />
            <!-- <div class="item_con">微信登录</div> -->
          </div>
        </div>
        <div v-if="ifapplelogin==1 && !isH5">
          <div class="con_item" @click="onLoginApple()">
          <!-- <div class="con_item" @click="onAppleLogin()"> -->
            <img class="item_icon" src="@/assets/image/login/type_icon5.png" />
            <!-- <div class="item_con">Apple ID</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 区号选择 -->
    <n-transition ref="pop" speed="ease-in-out" :height="popHeight" :maskVal="0.35">
      <div class="pop_box">
        <div class="box_list">
          <div
            class="list_item"
            v-for="(item, index) in popCode"
            :key="index"
            @click="changeCode(index)"
          >
            <p class="item_txt">{{item.country}}</p>
            <p class="item_txt">{{item.code}}</p>
          </div>
        </div>
        <div class="box_button" @click="hidePopup()">取消</div>
      </div>
    </n-transition>
  </div>
</template>

<script>
import WhiteList from '@/assets/js/WhiteList.js'
import nTransition from "@/components/n-transition/n-transition.vue";
import config from '@/unit/config.js'
import { FunLoginEnvironment, FunLogin, FunSetUserInfo, FunGoHome, goBack, onInitOnLogin } from '@/assets/js/NativeWebKit.js'
import { FunJudgDevice, getClientHeight } from '@/unit/fun.js'
// 加密解密
// import AESCipher from '@/unit/AESCipher.js'
export default {
  // 可用组件的哈希表
  components: { nTransition },
  data() {
    return {
      isH5: false, //是否公众号进入
      stateHeight: 0, //信息条高度
      LoginOneClick: 0,
      token: '',
      operator: '',
      text: '',
      text1: '',
      isLoginType: true, //是否展示其他登录方式

      ifwxlogin: 0, // 是否支持微信登录
      accessTokenWx: '' , //微信accessToken
      openIdWx: '', //微信openid
      unionid: '', //unionid

      ifapplelogin: 0, // 是否支持apple登录
      identityToken: '', //appleToken
      appleId: '', //appleid

      ifonelogin: 0, // 是否支持一剑登录

      // 假数据
      obj: {
        isLogin: false,
        // userName: "测试账户张三",
        // account: "ceshizhanghao",
        // password: "aa123456",
        // userType: 1,
        // photo: "https://www.longyc.cn/logo.jpg",
        // phone: "", 
        // userName: "测试账户张三",
        // sex: 1,
        // age: 30,
        // birthday: "1990-02-22",
        // address: "北京市朝阳区颐和路怡和阳光大厦C座1304号"
      },

      phone: "", //手机号
      dxcode: "", //短信验证码
      password: "", //密码
      passwordAES: "", //aes密码
      username: "", //用户名/手机号

      isDxCode: true,
      countdown: 60, // 验证码时间
      popHeight: 260, //弹框高度
      popIndex: 0,
      popCode: [
        {
          id: '4',
          country: "中国",
          code: "+86"
        },
        {
          id: '1',
          country: "中国香港",
          code: "+852"
        },
        {
          id: '2',
          country: "中国澳门",
          code: "+853"
        },
        {
          id: '3',
          country: "中国台湾",
          code: "+886"
        }
      ],
      timeOut: null,
      // 密码显示
      passWordShow: false,
      // 同意条款
			clauseAgree: false,
			isShake: false, //抖动

      // 登录状态
      loginType: 2, //0注册 1密码登录 2短信登录 3一键登录

      // 手机类型
      phoneloginType: '', // 2是安卓 3是ios
      // 加密
      aes: {},

      // sdk等待
      SDKLoading: false,

      clientHeight: 0, // 视口高度
      LoginTypeHeight: 0, //登录方式高度
    };
  },
  created() {
    if(FunJudgDevice() == 'ios' && window.webkit){
      this.phoneloginType = 3;
    }else if(FunJudgDevice() == 'android'){
      this.phoneloginType = 2;
    }else{
      this.phoneloginType = 8;
    }
    // 是否公众号进入
    if(sessionStorage.getItem('isH5')){
			this.isH5 = true;
		}

    // this.onOneClickLogin({token:'2F5608CE63734083A3E3B6E813301EF4',carrier:'unicom'})
    // 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
    
    this.ifEnvironment()
    let _this = this;
    // 一键登录页面关闭回调
    window.returnShowLogin = ()=>{
      // 显示内容
      _this.LoginOneClick = 0
    }
    
    // 微信登录回调
    window.returnLoginWeChatOpenId = (openId, accessToken, unionid)=>{
      // console.log(openId, accessToken, unionid)
      _this.openIdWx = openId
      _this.accessTokenWx = accessToken
      _this.unionid = unionid
      
      // 微信登录
      _this.onWxLogin()
    }
    // apple登录返回
    window.returnLoginApple = (appleId, identityToken)=>{ 
      _this.appleId = appleId
      _this.identityToken = identityToken
      
      // apple登录
      _this.onAppleLogin()
    }

    let _window = window;
    let webkit = window.webkit;
    // 一键登录返回
    window.returnLoginOneClick = (token, operator, phone)=>{
      // this.$Toast.loading({
      //   message: '登录中...',
      //   forbidClick: true,
      //   duration: 0,
      // });
      // ios
      if(FunJudgDevice() == 'ios' && webkit){
        // ios需要解析一下手机号
        _this.iosGetPhone({token,carrier:operator,phone})
      }else if(FunJudgDevice() == 'android'){
        _this.onOneClickLogin({token,carrier:operator,phone})
      }
    }

    // 切换登录方式
    window.changeLoginType = (res)=>{
      _this.LoginOneClick = 0
      _this.loginType = parseInt(res)
    }

    // 回显登录方式
    if (sessionStorage.getItem('loginType')) {
      this.changeLoginType(sessionStorage.getItem('loginType'))
      this.loginType = JSON.parse(sessionStorage.getItem('loginType'))
    }

    // 手机号或用户名不为空
    if (this.username!=='' || this.phone!=='') {
      return
    }
    // 回显手机号、验证码、用户名、密码
    let LoginInfo = localStorage.getItem('LoginInfo')
    if (LoginInfo) {
      LoginInfo = JSON.parse(LoginInfo)
      this.phone = LoginInfo.phone
      this.dxcode = LoginInfo.dxcode
      this.username = LoginInfo.username
      this.password = LoginInfo.password
      localStorage.removeItem('LoginInfo')
    }
    // 回显手机号
    else if (!!localStorage.getItem('loginPhone')) {
      this.phone = localStorage.getItem('loginPhone')
      this.username = localStorage.getItem('loginPhone')
      localStorage.removeItem('loginPhone')
    }
  },
  mounted(){
    // 获取页面视图高度
    this.clientHeight = getClientHeight()
    // 获取切换栏高度
    this.LoginTypeHeight = document.getElementById("box3").clientHeight
  },
  methods: {
    // 返回按钮
    goBack() {
      if(this.$route.query.fromMenu == 1){
        goBack();
      }else{
        onInitOnLogin();
        this.$onReturn(this.$route.query.fromMenu == '1');
      }
    },
    // 获取用户信息
    async getUserInfo(type) {
      await this.$ajax({
        url: '/hxclass-mobile/user/info',
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.obj = res.data
          this.obj.isLogin = true;

          this.$store.commit("updateUserInfo", this.obj);
          // 保存手机号
          localStorage.setItem('loginPhone', res.data.mobile)
          // 查询身份认证
          this.checkIdent(type, res.data.userId);
          // if (tolink == '/login/login' || tolink.indexOf('/login/clause') > -1 || tolink == '/login/retrievePassword' || tolink.indexOf('/login/bindPhone') > -1) {
          //   this.$router.push('/home');
          //   return
          // }
        }
      })
    },
    // 查询是否已身份认证
    checkIdent(type, userId) {
      this.$ajax({
        url: '/hxclass-mobile/user/anthen/' + userId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 向原生传递登录信息
          FunSetUserInfo(JSON.stringify(this.$store.state.userInfo))

          // 未认证
          if (res.data == 0) {
            this.$store.commit('changecertified',false);
          }
          // 已认证
          else if (res.data == 1) {
            this.$store.commit('changecertified',true);
          }
          let tolink = this.$store.state.userInfo.tolink;
          // 注册进入
          if (type == 1) {
            // 偏好设置
            this.$router.push('/setUp/interest?type=2');
            return
          }
          // if (tolink.indexOf('/login') > -1 || tolink == "/") {
            // this.$router.push('/home');
            // 跳到原生首页
          //   FunGoHome(this.$route.query.index)
          //   return
          // }
          // this.$router.push(this.$store.state.userInfo.tolink);
          // this.$onReturn(this.$route.query.fromMenu == '1');
          // 返回上一页
          this.$onReturn(this.$route.query.fromMenu == '1');
        }
      })
    },
    // 输入密码
    changePassword(value) {
      this.password = value.replace(/\s+/g,"")
    },
    // 输入验证码
    changeDxcode(value) {
      this.dxcode = value.replace(/\s+/g,"")
    },
    // 获取验证码
    onDxCode() {
      console.log(this.popCode[this.popIndex].id)
      // return
      if (this.isDxCode) {
        if (!this.phone) {
          this.$Toast("请输入手机号");
          return false;
        } else if (!this.$regular.phone.reg.test(this.phone)) {
          this.$Toast("请输入正确的手机号");
          return false;
        }

        let url = ''
        if (this.loginType == 0) {
          // 注册
          url = '/hxclass-mobile/user/sendSM'
        } else if (this.loginType == 2) {
          // 短信登录
          url = '/hxclass-mobile/user/loginSendSMS'
        }

        this.$ajax({
          url: url,
          method: "get",
          params: {
            phone: this.phone, //手机号
            mobileType: this.popCode[this.popIndex].id, //区号
          }
        }).then((res) => {
          console.log(res)
          if (res.code == 200 && res.success) {
            this.$Toast.success('短信已发送，请注意查收！');
            // 倒计时
            this.sendemail(this.countdown);
          } else {
            this.$Toast(res.message);
          }
        })
      }
    },
    // 注册
    onEnroll() {
      if (!this.phone) {
        this.$Toast("请输入手机号");
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$Toast(this.$regular.phone.msg);
        return false;
      } else if (!this.dxcode) {
        this.$Toast("请输入验证码");
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.dxcode)) {
        this.$Toast(this.$regular.dxcode.msg);
        return false;
      } else if (!this.password) {
        this.$Toast("请输入密码");
        return false;
      } else if (!this.$regular.password.reg.test(this.password)) {
        this.$Toast(this.$regular.password.msg);
        return false;
      } else if (!this.clauseAgree) {
        // 协议条款抖动
        this.onShake()
        return false;
      }

      // aes加密
      // this.passwordAES = this.aes.encode_data(this.password)
      // // let decode = this.aes.decode_data(this.passwordAES)
      // console.log(this.passwordAES)
      // return

      this.$ajax({
        url: '/hxclass-mobile/user/register?phone=' + this.phone + '&verCode=' + this.dxcode + '&password=' + this.password + '&mobileType=' + this.popCode[this.popIndex].id + '&loginType=' + this.phoneloginType,
        method: "POST",
        // params: {
        //   phone: this.phone,
        //   verCode: this.dxcode,
        //   password: this.passwordAES,
        // }
      }).then((res) => {
        console.log(res)
        if (res.code == 200 && res.success) {
          // 注册成功
          this.$Toast.success('注册成功！');
          // 获取用户信息
          this.getUserInfo(1)
          this.$onReturn(this.$route.query.fromMenu == '1')
        } else {
          this.$alert(res.message);
        }
      })

    },
    // 密码登录
    onLoginPassword() {
      // if (!this.username) {
      //   this.$Toast("请输入手机号");
      //   return false;
      // } else if (!this.$regular.phone.reg.test(this.username)) {
      //   this.$Toast(this.$regular.phone.msg);
      //   return false;
      // } 
      if (!this.username) {
        this.$Toast("请输入用户名或者手机号");
        return false;
      } else if (!this.password) {
        this.$Toast("请输入密码");
        return false;
      } 
      // else if (!this.$regular.password.reg.test(this.password)) {
      //   this.$Toast(this.$regular.password.msg);
      //   return false;
      // } 
      else if (!this.clauseAgree) {
        // 协议条款抖动
        this.onShake()
        return false;
      }

      // aes加密
      // this.passwordAES = this.aes.encode_data(this.password)
      // console.log(this.passwordAES)

      this.$ajax({
        url: '/hxclass-mobile/security/login?username=' + this.username + '&password=' + this.password,
        method: "POST",
      }).then((res) => {
        if (res.code !== 500) {
          // 登录成功
          // this.$Toast.success('登录成功！');
          // 获取用户信息
          this.getUserInfo()
        } else {
          this.$alert(res.message);
        }
      })

    },
    // 短信登录
    onLoginNote() {
      if (!this.phone) {
        this.$Toast("请输入手机号");
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$Toast(this.$regular.phone.msg);
        return false;
      } else if (!this.dxcode) {
        this.$Toast("请输入验证码");
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.dxcode)) {
        this.$Toast(this.$regular.dxcode.msg);
        return false;
      } else if (!this.clauseAgree) {
        // 协议条款抖动
        this.onShake()
        return false;
      }

      // console.log('mobile', this.phone)
      // console.log('smsCode', this.dxcode)
      // console.log('mobileType', this.popCode[this.popIndex].id)
      
      this.$ajax({
        url: '/hxclass-mobile/security/sms/login?mobile=' + this.phone + '&smsCode=' + this.dxcode + '&mobileType=' + this.popCode[this.popIndex].id + '&loginType=' + this.phoneloginType,
        method: "POST",
      }).then((res) => {
        console.log(res)
        if (res.code !== 500) {
          // 登录成功
          // this.$Toast.success('登录成功！');
          // 老用户
          if (res.data.credentials == 1) {
            // 获取用户信息
            this.getUserInfo()
          }
          // 新用户
          else if (res.data.credentials == 2) {
            // 获取用户信息
            this.getUserInfo(1)
          }
        } else {
          this.$alert(res.message);
        }
      })
    },
		// 一键登录
		oneLogin() {
      console.log('一键登录',this.phone)
			// 服务条款抖动
			this.onShake()
      // 一键登录
      if (this.clauseAgree) {
        this.$ajax({
          url: '/hxclass-mobile/security/one/click/login?mobile=' + this.phone + '&loginType=' + this.phoneloginType,
          method: "POST",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // 登录成功
            // this.$Toast.success('登录成功！');
            // 获取用户信息
            this.getUserInfo()
          } else {
            this.$alert(res.message);
          }
        })
      }
		},
    // 微信登录
    onWxLogin() {
      // 服务条款抖动
      // this.onShake()
      // console.log('openIdWx',this.openIdWx )
      // console.log('accessTokenWx', this.accessTokenWx )
      // console.log('unionid', this.unionid )
      
      // 显示内容
      this.LoginOneClick = 0

      // 微信登录
      // if (this.clauseAgree) {
        this.$ajax({
          url: '/hxclass-mobile/login/callback?accessToken=' + this.accessTokenWx + '&openId=' + this.openIdWx,
          method: "get"
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // 登录成功
            // this.$Toast.success('登录成功！');
            // 获取用户信息
            this.getUserInfo()
          } else if (res.code == 900) {
            // 绑定手机号
            this.$router.push({
              path:'/login/bindPhone', 
              query:{
                type: 1, //type 1微信 2apple
                unionid: this.unionid
              }
            })
          }
        })
      // }
    },
    // Apple登录
    onAppleLogin() {
      // 服务条款抖动
			// this.onShake()

      // 显示内容
      this.LoginOneClick = 0

      // apple登录
      // if (this.clauseAgree) {
        this.$ajax({
          url: '/hxclass-mobile/login/appleid?identityToken=' + this.identityToken,
          method: "get"
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // 登录成功
            // this.$Toast.success('登录成功！');
            // 获取用户信息
            this.getUserInfo()
          } else if (res.code == 900) {
            // 绑定手机号
            this.$router.push({
              path:'/login/bindPhone', 
              query:{
                type: 2, //type 1微信 2apple
                appleId: this.appleId
              }
            })
          }
        })
      // }
    },

    // ios需要先解析下手机号
    iosGetPhone({token, carrier}){
      this.$ajax({
        url: `/hxclass-mobile/login/getphone?token=${token}&carrier=${carrier}`,
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let phone = res.data
          this.onOneClickLogin({phone})
        }
      })
    },

    // 一键登录
    onOneClickLogin({phone}){
      this.LoginOneClick = 0
      this.$ajax({
        // url: `/hxclass-mobile/security/one/click/login?token=${token}&carrier=${carrier}`,
        url: `/hxclass-mobile/security/one/click/login?mobile=${phone}` + '&loginType=' + this.phoneloginType,
        method: "post",
        params: {
          // token: this.token,
          // carrier: this.operator
          // token: '9884FDDC43D542DE8E5AA55038FA4E1C',
          // carrier: 'unicom'
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // return
          // 登录成功
          // this.$Toast.success('登录成功！');
          
          // 老用户
          if (res.data.credentials == 1) {
            // 获取用户信息
            this.getUserInfo()
          }
          // 新用户
          else if (res.data.credentials == 2) {
            // 获取用户信息
            this.getUserInfo(1)
          }
        } else {
          this.$alert(res.message);
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    // 协议条款抖动
    onShake() {
      if (!this.clauseAgree) {
				this.isShake = true
				let timer = setTimeout(() => {
					this.isShake = false
					clearTimeout(timer)
				},1000)
			}
    },

    // 打开弹框
    showPopup() {
      this.$refs["pop"].show();
    },
    // 关闭弹框
    hidePopup() {
      this.$refs["pop"].hide();
    },
    // 选择区号
    changeCode(index) {
      this.popIndex = index;
      this.hidePopup();
    },
    // 密码可看/隐藏
    showPassWord() {
      this.$nextTick(() => {
        this.passWordShow = !this.passWordShow;
        // 处理ios手机兼容
        this.password += '.'
        setTimeout(() => {
          this.password = this.password.substring(0, this.password.length-1)
        },1)
      })
    },
    // 同意条款
    changeClause() {
      this.clauseAgree = !this.clauseAgree;
    },
    // 去协议条款
    goClause(type) {
      // 记录手机号、验证码、用户名、密码
      let LoginInfo = {
        phone: this.phone, //手机号
        dxcode: this.dxcode, //短信验证码
        username: this.username, //用户名/手机号
        password: this.password, //密码
      }
      LoginInfo = JSON.stringify(LoginInfo)
      localStorage.setItem('LoginInfo', LoginInfo)
      // 协议条款
      if(type == 1){
        this.$router.push('/all/ServiceAgreement');
      }else if(type == 2){
        this.$router.push('/all/privacyPolicy');
      }
      // this.$router.push({
      //   path: '/login/clause',
      //   query: {
      //     type: type
      //   }
      // })
    },
    // 改变登录方式
    changeLoginType(type) {
      // 记录登录方式
      sessionStorage.setItem('loginType', type)
      this.LoginOneClick = 0
      // this.phone = "";
      // this.countdown = 60;
      // this.isDxCode = true;
      // clearTimeout(this.timeOut);
      this.loginType = type;
    },
    // 倒计时
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        clearTimeout(this.timeOut);
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      this.timeOut = setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },

    // // 登录
    // onSave() {
    //   // 同步更新登录状态
    //   if (this.$store.state.userInfo.isLogin) {
    //     // 如果当前是已登录状态，则直接进入
    //     this.$router.push("/my");
    //   } else {
    //     this.obj.isLogin = true;
    //     this.$store.commit("updateUserInfo", this.obj);
    //     this.$router.push(this.$store.state.userInfo.tolink);
    //   }
    // },
    // // 清除登录状态
    // onClear() {
    //   this.$store.commit("clearUserInfo");
    // },
    
    // 登录环境检测
    ifEnvironment(){
      // 判断微信登录
      this.ifwxlogin = FunLoginEnvironment({loginType:1})
      // 判断apple登录
      this.ifapplelogin = FunLoginEnvironment({loginType:2})
      if(FunJudgDevice() == 'ios' && window.webkit){
        // 判断一键登录
        this.ifonelogin = FunLoginEnvironment({loginType:3})
      }else if(FunJudgDevice() == 'android'){
        // 判断一键登录 state 1可以 0不可以
        window.returnLoginOneState = (state)=>{
          this.ifonelogin = state
        }
      }
    },
    // 微信登录
    onLoginWeChat(){
      if (!this.clauseAgree) {
        // 协议条款抖动
        this.onShake()
        return false;
      }
      FunLogin({loginType:1})
    },
    // apple登录
    onLoginApple(){
      if (!this.clauseAgree) {
        // 协议条款抖动
        this.onShake()
        return false;
      }
      FunLogin({loginType:2})
    },
    // 一键登录
    onLoginOneClick(){
      if (!!this.SDKLoading) {
        this.$Toast({
          duration: 2000,
          message: '切换的太频繁啦，稍等两秒再切换'
        });
        return;
      } 
      // 设置两秒定时器
      this.SDKLoading = true
      // 两秒后可点击
      let SDKTimer = setTimeout(() => {
        this.SDKLoading = false
        clearTimeout(SDKTimer)
      }, 2000);
      this.LoginOneClick = 1
      FunLogin({loginType:3})
    }
  },
  computed: {},
  watch: {}
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.van-cell {
  padding: 0;
  flex: 1;
}

.login {
  position: relative;
  // min-height: 100vh;
  height: 100vh;
  background-color: #fff;
}
// 协议抖动
@keyframes shake_slow {
  14% {
    transform: translate(-10px, 0px);
	}
	30% {
		transform: translate(10px, 0px);
	}
	48% {
		transform: translate(-7px, 0px);
	}
	58% {
		transform: translate(7px, 0px);
	}
	70% {
		transform: translate(-3px, 0px);
	}
	84% {
		transform: translate(3px, 0px);
	}
  0%,
  100% {
    transform: translate(0, 0);
  }
}
.shake_slow {
  // animation: shake-slow 5s infinite ease-in-out;
  animation: shake_slow 1s ease-in-out;
}

// 返回按钮
.login_return {
  position: absolute;
  left: 20px;
  .return_img {
    width: 16px;
  }
}

// 标语
.box1 {
  display: inline-block;
  margin-top: 58.5px;
  width: 100%;
  .box_top {
    text-align: center;
    .top_icon {
      width: 137px;
    }
  }
  .box_img {
    text-align: center;
    margin-top: 18%;
    .img_icon {
      width: 72px;
      // border-radius: 50%;
    }
  }
}

// 登录
.box2 {
  margin-top: 10px;
  padding: 0 34px;
  // 输入框
  .box_input {
    .input_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #eeeeee;
      padding: 10px 0;
      .item_pla {
        color: #cccccc;
      }
      .item_num {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        .code_icon {
          width: 8px;
          margin-left: 7px;
          margin-right: 6px;
        }
      }
      .item_txt {
        width: 275px;
      }
      .item_password {
        /deep/.van-field__control {
          -webkit-text-security: disc !important;
        }
      }
      .item_icon {
        flex-shrink: 0;
        width: 20px;
        margin-right: 27.5px;
      }
      .item_code {
        // width: 81px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        border-left: 0.5px solid #c4c4c4;
        padding-left: 6px;
        &.count {
          font-size: 14px;
          font-weight: 500;
          color: #b7b7b7;
        }
      }
    }
  }
  // 手机号码
  .box_phone {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-top: 26px;
  }
  // 提示语
  .box_hint {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 7%;
  }
  // 按钮
  .box_button {
    text-align: center;
    .btn {
      display: inline-block;
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      width: 100%;
    }
    &.box_button1 {
      margin-top: 42px;
    }
    &.box_button2 {
      margin-top: calc(11% + 1px);
    }
    &.box_button3 {
      margin-top: 10%;
    }
  }
  // 条款
  .box_clause {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3%;
    .clause_con {
      font-size: 12px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.6);
      margin-left: 5px;
      line-height: 16px;
      text-align: justify;
      .light {
        color: #15b7dd;
        // white-space: nowrap;
      }
    }
  }
  // 操作
  .box_operate {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    .operate_item {
      font-size: 12px;
      font-weight: 500;
      color: rgba(26, 185, 222, 0.9);
    }
  }
}

// 登录方式
.box3 {
  position: fixed;
  // bottom: 0;
  width: 100%;
  padding: 0px 30px;
  background-color: #fff;
  .box_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(196, 196, 196, 0.5);
    .title_line {
      width: 26px;
      height: 1px;
      background-color: #C4C4C4;
    }
    .title_main {
      color: rgba(153, 153, 153, 0.8);
      margin: 0 30px;
    }
    .light {
      color: #37c1e2;
    }
  }
  .box_con {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 35px;
    text-align: center;
    >div{
      width: 25%;
      display: inline-block;
    }
    .con_item {
      width: 60px;
      text-align: center;
      margin: 0 auto;
      .item_icon {
        width: 37px;
      }
      .item_con {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 6px;
      }
    }
  }
}

// 选择区号
.pop_box {
  .box_list {
    padding: 0 18px;
    .list_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #e5e5e5;
      padding: 14px 0;
      &:last-child {
        border: 0;
      }
      .item_txt {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .box_button {
    font-size: 16px;
    font-weight: bold;
    color: #15b7dd;
    text-align: center;
    border-top: 10px solid #f7f7f9;
    padding: 14px 0;
  }
}
</style>
