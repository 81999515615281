<template>
  <div>
    <div class="content-wrap" :class="{'content-wrap-active' : topShow}">
      <!-- 顶部详情图 -->
      <div class="banner-item">
        <img :src="form.cover" alt=""> 
      </div>
      <!-- 直播信息 -->
      <div class="information-item">
        <h4 class="title">{{form.title}}</h4>
        <div class="brief-inner" v-text="form.summary"></div>
        <div class="time-inner">
          <div><van-icon class="icon" name="underway-o" />直播时间：{{form.liveStartDate}}</div>
          <div><van-icon class="icon" name="user-o" />主讲老师：{{form.teacherName}}</div>
        </div>
      </div>
      <!-- 直播详情 -->
      <div class="content-item" v-html="form.content"></div>
      <div style="height:70px"></div>  <!-- 占位 -->
      <div class="foo-fixed">
        <span class="btn" @click="showSharePageModel = true">立即预约</span>
      </div>
    </div>
    <!-- 分享提示弹框 -->
    <SharePageModel @closeSharePageModel="closeSharePageModel()" :show="showSharePageModel"/>
    
    <!-- 分享顶部提示 -->
    <TopAlert @closeFlag = "closeFlag" />
  </div>
</template>

<script>
import SharePageModel from '@/components/sharePageModel.vue';  /* 分享提示弹框 */
import TopAlert from '@/components/share/topAlert.vue';  /* 分享顶部提示 */
export default {
  // 可用组件的哈希表
  components: { SharePageModel, TopAlert },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      showSharePageModel:false,
      form:{},
      topShow:true,
      id:''
    }
  },
  // 事件处理器
  methods: {
    closeSharePageModel() {
			this.showSharePageModel = false;
    },
    
    // 获取直播详情
		getLiveDetail(){
			this.$ajax({
				url:"/hxclass-mobile/liveInfo/getLiveInfoDetail?id=" + this.id,
				method:'get'
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.form = res.data
				}
			})
    },
    
    closeFlag(){
      this.topShow = false
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
		this.id = this.$route.query.id
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getLiveDetail();
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content-wrap{
  margin: 10px 18px 0 18px;
  padding: 10px 15px 0 15px;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  position: relative;

  .banner-item img{
    width: 100%;
    border-radius: 10px;
  }

  .information-item{
    margin-top: 10px;
    .title{
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
      margin-bottom: 6px;
    }
    .brief-inner{
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
    }
    .time-inner{
      margin-top: 15px;
      >div{
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
        .icon{
          margin-right: 4px;
        }
      }
      >div:last-child{
        margin-bottom: 0;
      }
    }
  }

  .content-item{
    margin-top: 15px;
    /deep/strong{
      font-weight: bold;
    }
    /deep/em{
      font-style: italic;
    }
    /deep/img{
      max-width: 100%;
    }
    /deep/.remarks{
      max-width: 100%;
      height: auto !important;
      -webkit-line-clamp: 10000 !important;
    }
  }

  .foo-fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 30px);
    padding: 15px;
    background: #ffffff;
    .btn{
      display: block;
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      background: #15B7DD;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
.content-wrap-active{
  margin-top: 56px;
}
</style>
