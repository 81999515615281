<template>
	<div class="container-box">
		<van-nav-bar
			title="模考记录"
			left-arrow
			class="nav-circular"
			:fixed="true"
			:placeholder="true"
			@click-left="toExam()"
		/>
		<van-list
          v-model="newLoading"
          :finished="finished"
          :immediate-check="false"
          @load="getRecordList"
        >
		<div v-for="(item,index) in recordList" :key='index'  class="main-box">
			<div class="main-list">
				<div class="list-header">
					{{item.name}}
				</div>
				<div class="list-content">
					考试成绩：<span>{{item.examScore}}</span>
				</div>
				<div class="list-footer">
					<div class="footer-left" @click="toTest(item.userpPaperId)">
						查看试卷
					</div>
					<div class="footer-right">
						{{item.examDate}}
					</div>
				</div>
			</div>
		</div>
		</van-list>
    <noMessage v-if="recordList.length==0" type=1></noMessage>
	</div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
	export default {
  components: { noMessage },
		data() {
			return {
				newCurrent: 1, //当前页数
      			newSize: 10, //条数
      			newLoading: false,
      			finished: false,
				recordList: [],
				examList:[
					{
						title:'VB-MAPP语言行为里程碑及安置程序培训课程',
						achievement:"98",
						full:'100',
						date:'2021/05/18 12:12',
						
					},
					{
						title:'VB-MAPP语言行为里程碑及安置程序培训课程二',
						achievement:"98",
						full:'100',
						date:'2021/05/18 12:12',
						
					}
				]
			}
		},
		methods: {
			toExam () {
				if(this.$route.query.fromMenu) {
					this.$onReturn(this.$route.query.fromMenu == '1');
				}else{
					this.$router.push('/examination/exam?courseId=' + this.id )
				}
    	},
			toTest(id) {
				this.$router.push('/examination/testPaper?userPaperId=' + id + "&type=2")
			},
			getRecordList() {
       			this.$ajax({
       			  url: "/hxclass-mobile/exam/mock/config/user/list?userId=" + this.$store.state.userInfo.userId +'&current=' + this.newCurrent + '&size=' + this.newSize + "&configId=" + this.configId ,
					//  url: "/hxclass-mobile/exam/mock/config/user/list?userId=" + 132 +'&current=' + 1 + '&size=' + 10 ,
       			}).then((res) => {
       			  if (res.code == 200 && res.success) {
       			    this.recordList = this.recordList.concat(res.data.records);
					this.newCurrent ++
            		this.newLoading = false
            		// 加载完成
            		if (this.recordList.length == res.data.total) {
            		  this.finished = true
            		}
            		// 无数据
            		if (this.recordList.length == 0) {
            		  this.finished = true
            		}
       			  }
       			});
    		},
		},
		created() {
      this.id = this.$route.query.courseId;
      this.configId = this.$route.query.configId;
  		this.getRecordList();
  	},
	}
</script>

<style>
	page {
		background: #F7F7F9;
	}
</style>

<style lang="less" scoped>
/deep/.nomessage{
  margin-top: 100px;
}
	.container-box {
		.main-box{
			margin-top: 12.5px;
			background: #FFFFFF;
			overflow: hidden;
			.main-list{
				margin: 12.5px 18px;
				overflow: hidden;
				.list-header{
					margin-bottom: 9px;
					// width: 287px;
					font-size: 16.5px;
					font-family: PingFangMedium;
					font-weight: bold;
					font-weight: 500;
					color: #333333;
					line-height: 24.5px;
					white-space: pre-wrap;
				}
				.list-content{
					margin-bottom: 16.5px;
					height: 24.5px;
					font-size: 16.5px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #333333;
					span{
					font-weight: 500;
					}
					line-height: 24.5px;
				}
				.list-footer{
					margin-bottom: 12.5px;
					.footer-left{
						float: left;
						width: 110.5px;
						height: 36px;
						background-color: #15B7DD;
						border-radius: 18px;
						font-size: 14.5px;
						font-family: PingFangMedium;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 36px;
						text-align: center;
						box-shadow: 0px 2px 4.5px .5px rgba(21,183,221, 0.2);
					}
					.footer-right{
						float: right;
						height: 16.5px;
						font-size: 11px;
						font-family: PingFangMedium;
						font-weight: 400;
						color: #666666;
						line-height: 44px;
					}
				}
			}
		}
	}
</style>