<template>
  <div id="app">
    <!-- <div class="states_box" :style="{'height':stateHeight+'px'}"></div> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive" />

    <!-- 音频播放器 -->
    <AudioMin />

    <!-- 全局loading -->
    <AppLoad v-if="$store.state.isAppLoad" />
  </div>
</template>

<script>
import AudioMin from "@/components/Audio/indexMin.vue";
import AppLoad from "@/components/appLoading.vue";
import { FunJudgDevice } from "@/unit/fun.js";
import wxConfig from '@/unit/wx-js-sdk.vue';
const DeviceType = FunJudgDevice();
export default {
  // 可用组件的哈希表
  components: {
    AudioMin,
    AppLoad,
  },
  mixins: [ wxConfig ],
  // 接收传值
  props: {},
  provide() {
    // 注册一个方法
    return {
      reload: this.reload,
    };
  },
  // 数据对象
  data() {
    return {
      isRouterAlive: true,
      // 信息条高度
      stateHeight: 0,
    };
  },
  // 事件处理器
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // js 监听页面刷新并存储globalData数据
    // window.addEventListener("beforeunload", () => {
    //   localStorage.setItem("stateInfo", JSON.stringify(getApp().globalData));
    // });
    // 判断还原globalData的数据
    if (localStorage.getItem("userInfo")) {
      this.$store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    // 删除历史缓存globalData数据
    // localStorage.removeItem("userInfo");

    // 初始化音频
    this.$store.commit("initAudio");

    // 移动网络播放状态下，原生调用h5方法 1允许0不允许
    window.networkPlay = function () {
      let networkPlayState = this.$store.state.userInfo.enableMobileNetwork;
      return networkPlayState;
    };
    let _this = this;
    // 修改身份认证信息 - ios原生认证
    window.setUserInfo = function (userType,certified) {
      let obj = JSON.parse(localStorage.getItem('userInfo'))
      obj.userType = userType
      _this.$store.state.userInfo = obj
      localStorage.setItem('userInfo', JSON.stringify(obj))
      if (certified == 0) {
        _this.$store.commit('changecertified',false);
      }
      // 已认证
      else if (certified == 1) {
        _this.$store.commit('changecertified',true);
      }
      return ;
    };
    // 错误弹窗
    window.errToast = (message) => {
      this.$alert(message);
    };

    // 判断是否是浏览器打开 如果不是微信浏览器就获取导航栏高度
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
       console.log("weixin提示: 您在使用的浏览器内核版本可能导致浏览异常,请安装Chrome(谷歌浏览器）、Firefox（火狐）浏览器、夸克浏览器、UC浏览器来使用本系统")
    } 
    // else if (ua.match(/QQ/i) == "qq") {
    //   console.log("QQ提示: 您在使用的浏览器内核版本可能导致浏览异常,请安装Chrome(谷歌浏览器）、Firefox（火狐）浏览器、夸克浏览器、UC浏览器来使用本系统")
    // }
    else {
      // 安卓导航栏高度
      if (DeviceType == "android") {
        this.stateHeight = Android.getStatusBarHeight();
      } else if (DeviceType == "ios" && window.webkit) {
        if(location.search.indexOf("&share=true") != -1) {
          return this.stateHeight = 0;
        }
        // ios导航栏高度
        this.stateHeight = window.prompt("getStatusBarHeight");
      }
    }
    // var browser = {
    //   versions: function() {
    //     var u = navigator.userAgent,
    //       app = navigator.appVersion;
    //     return { //移动终端浏览器版本信息
    //       trident: u.indexOf('Trident') > -1, //IE内核
    //       presto: u.indexOf('Presto') > -1, //opera内核
    //       webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    //       gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
    //       mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    //       ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    //       android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
    //       iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    //       iPad: u.indexOf('iPad') > -1, //是否iPad
    //       webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
    //     };
    //   }(),
    //   language: (navigator.browserLanguage || navigator.language).toLowerCase()
    // }
    // if (browser.versions.mobile) { //判断是否是移动设备打开.
    //   var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
    //   console.log(navigator,window,window.plus,'window.plus')
    //   if (window.plus) {//app内打开
        
    //   } else if (ua.match(/MicroMessenger/i) == "micromessenger") { //在微信中打开
    //     // ImagePreview({images:self.filePath,closeable: true});
    //   } else if (browser.versions.ios) { //安卓浏览器打开
    //     // ImagePreview({images:self.filePath,closeable: true});
    //   } else { // (browser.versions.android)IOS浏览器打开
    //     // ImagePreview({images:self.filePath,closeable: true});
    //   }
    // } else { //否则就是PC浏览器打开
    //   // ImagePreview({images:self.filePath,closeable: true});
    // }

    // 将所有 Toast 的展示时长设置为2秒
    this.$Toast.setDefaultOptions({ duration: 2000 });

    //解决ios双击页面上移问题
    //在项目中测试不仅input/button这些表单控件有这个问题，p,div等也有问题，于是乎就直接在body开刀了
    (function () {
      var iLastTouch = null; //缓存上一次tap的时间
      if (DeviceType == "ios" && window.webkit) {
        document.body.addEventListener(
          "touchend",
          function (event) {
            var iNow = new Date().getTime();
            iLastTouch =
              iLastTouch || iNow + 1 /** 第一次时将iLastTouch设为当前时间+1 */;
            var delta = iNow - iLastTouch;
            if (delta < 500 && delta > 0) {
              event.preventDefault();
              return false;
            }
            iLastTouch = iNow;
          },
          false
        );
      }
    })();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // 设置导航栏信息条高度
    if (this.stateHeight !== 0) {
      this.$store.commit("updatestateHeight", parseInt(this.stateHeight));
      document.getElementById("styles").innerHTML = `.van-nav-bar::before {
        display: inline-block;
        content: '';
        width: 100%;
        height: ${this.stateHeight}px;
        background-color: #fff;
      }`;
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    // $route(newVal,oldVal){
    //   console.log(newVal)
    // }
  },
};
</script>
<style lang="less">
html,
body,
#app {
  min-height: 100vh;
  &#app {
    display: flex;
    flex-direction: column;
  }
}
body {
  background-color: #f7f7f9;
  overscroll-behavior-y: none;
  -moz-user-select: none;

  -khtml-user-select: none;

  user-select: none;

  font-style: italic;
}
// 信息条样式
.states_box {
  position: fixed;
  top: 0;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.58) 51%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.04) 100%
  );
  // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.58) 51%, rgba(255, 255, 255, 0.08) 100%, rgba(255, 255, 255, 0.04) 100%);
  // background: rgba(255, 255, 255, .5);
  z-index: 10;
}
// 隐藏滚动条
* ::-webkit-scrollbar {
  width: 0 !important;
}
* ::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
* ::-webkit-scrollbar {
  display: none;
}
</style>
