export default [
  /*
    子模块的路由前面不需要添加‘/’
  */
  {
    path: 'globalSearch', // 全局搜索
    component: () => import('../views/search/globalSearch.vue')
  },
  {
    path: 'index', // 综合查询入口
    component: () => import('../views/search/index.vue')
  },
  {
    path: 'teacherQuery', // 教师查询
    component: () => import('../views/search/teacherQuery.vue')
  },
  {
    path: 'teacherDetail', // 教师详情
    component: () => import('../views/search/teacherDetail.vue')
  },
  {
    path: 'scoreQuery', // 成绩查询
    component: () => import('../views/search/scoreQuery.vue')
  },
  {
    name: 'cardQuery',
    path: 'cardQuery', // 证书查询
    component: () => import('../views/search/cardQuery.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: 'cardDetail', // 证书详情
    component: () => import('../views/search/cardDetail.vue'),
  },
  {
    path: 'meshanQuery', // 机构查询
    component: () => import('../views/search/meshanQuery.vue')
  },
  {
    path: 'zhongkangQuery', // 中康协会证书查询
    component: () => import('../views/search/zhongkangQuery.vue')
  },
  {
    path: 'zhongkangDetail', // 中康协会证书详情
    component: () => import('../views/search/zhongkangDetail.vue')
  },
  {
    path: 'kfxhTemplateDetail', // 康复协会证书详情
    component: () => import('../views/search/kfxhTemplateDetail.vue')
  }
]
