<template>
	<!-- 申请退款 -->
	<div class="applyFor">
		<!-- 导航栏 -->
		<van-nav-bar
			title="申请退款"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<div class="box">
			<div class="box_item">
				<div class="item_title">
					<div class="title_con">
						<span class="light">*</span>
						<span>申请数量</span>
					</div>
					<div class="title_info">（最多退2个）</div>
				</div>
				<div class="item_con">
					<van-stepper theme="round" min="1" max="2" disable-input />
				</div>
			</div>
			<div class="box_item" @click="showPopup(1)">
				<div class="item_title">
					<div class="title_con">
						<span class="light">*</span>
						<span>货物状态</span>
					</div>
				</div>
				<div class="item_con">
					<span class="con_info">选择货物状态</span>
					<van-icon class="con_icon" name="arrow" color="#999999" size="10" />
				</div>
			</div>
			<div class="box_item" @click="showPopup(2)">
				<div class="item_title">
					<div class="title_con">
						<span class="light">*</span>
						<span>退款原因</span>
					</div>
				</div>
				<div class="item_con">
					<span class="con_info">不想要了</span>
					<van-icon class="con_icon" name="arrow" color="#999999" size="10" />
				</div>
			</div>
			<div class="box_item">
				<div class="item_title">
					<div class="title_con">
						<span class="light">*</span>
						<span>退款金额</span>
					</div>
					<div class="title_info">不可修改，最多¥90.00</div>
				</div>
				<div class="item_con">
					<div class="con_price">
						<span>¥</span>
						<span>90.00</span>
					</div>
				</div>
			</div>
			<div class="box_item column">
				<div class="item_title">
					<div class="title_con">
						<span>补充描述：</span>
					</div>
				</div>
				<div class="item_txt">
					<textarea class="txt_con" placeholder="请补充描述，有助于为您更好的处理问题。"></textarea>
					<div class="txt_info">0/200</div>
					<div class="txt_imgs">
						<van-uploader 
							class="imgs_item" 
							v-model="fileList" 
							multiple 
							:max-count="2" 
							:max-size="500 * 1024" 
							@oversize="onOversize">
							<img class="item_img" src="../../assets/image/refund/applyFor_icon1.png" />
						</van-uploader>
					</div>
				</div>
			</div>
		</div>
		<!-- 提交 -->
		<div class="box_button">
			<router-link to='/order/refundDetail'>
				<div class="all-btn-bigCircle">提   交</div>
			</router-link>
		</div>
		
		<!-- 货物状态 -->
		<n-transition ref="pop" speed="ease-in-out" :height="popHeight" :maskVal="0.35">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">{{popType==1?'货物状态':'退款原因'}}</span>
					</div>
					<div class="popContent">
						<div class="option" v-for="(item, index) in popArr" :key="index">
							<span>未收到货</span>
							<img v-show="reason == index" src="@/assets/image/2022021110551.png" mode="scaleToFill" />
							<img v-show="reason != index" @click="reason = index" src="@/assets/image/2022021110552.png" mode="scaleToFill" />
						</div>
						<!-- <div class="option">
							<span>已收到货</span>
							<img v-show="reason == 2" src="@/assets/image/2022021110551.png" mode="scaleToFill"></img>
							<img v-show="reason != 2" @click="reason = 2" src="@/assets/image/2022021110552.png" mode="scaleToFill"></img>
						</div> -->
					</div>
				</div>
				<div class="popbutton">
					<span class="btn all-btn-bigCircle">确    认</span>
				</div>
			</div>
		</n-transition>
	</div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
export default {
	// 可用组件的哈希表
	components: {nTransition},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			reason: '',
			popType: 1, //1货物状态  2退款原因
			popHeight: 271,  //弹框高度
			popArr: [],
			popArr1: ['未收到货','已收到货'],
			popArr2: ['我不想要了','价格有点贵','暂时不需要了','其他'],

			fileList: [
				{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
			]
		}
	},
	// 事件处理器
	methods: {
		// 预览图片
		// showImg() {
		// 	uni.previewImage({
		// 		urls: ['../../assets/image/datas/2022012811491.png','../../assets/image/datas/2022012811492.png'],
		// 	});
		// },
		// 货物状态
		showPopup(state) {
			this.popType = state  //展示状态
			this.popArr = state == 1 ? this.popArr1 : this.popArr2  //选项
			this.popHeight = state == 1 ? 271 : 345  //高度
			// this.$refs.pop.open()
			this.$refs['pop'].show()
		},
		close(){
			this.$refs['pop'].hide()
		},

		onOversize(file) {
      // console.log(file);
			this.$toast('文件大小不能超过 500kb');
    },

	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	* {box-sizing: border-box;}
	.applyFor {
		height: 100vh;
		background: #F7F7F9;
	}

	/deep/.van-uploader__preview-image {
		width: 44px;
		height: 44px;
		border-radius: 8px;
	}
	
	.box {
		padding: 4px 18px;
		.box_item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// width: 339px;
			background: #FFFFFF;
			border-radius: 8px;
			margin-top: 8px;
			padding: 16px 12px;
			&.column {
				flex-direction: column;
				align-items: flex-start;
			}
			.item_title {
				.title_con {
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					.light {
						color: #EC6C01;
						font-size: 17px;
					}
				}
				.title_info {
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					margin-top: 8px;
				}
			}
			.item_con {
				.con_info {
					font-size: 12px;
					font-weight: 400;
					color: #333333;
				}
				.con_icon {
					margin-left: 3px;
				}
				.con_price {
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: bold;
					color: #EC6C01;
					span{
						&:last-child {
							font-size: 16px;
							margin-left: 2.5px;
						}
					}
				}
			}
			.item_txt {
				width: 100%;
				border-radius: 8px;
				border: 1px solid #CCCCCC;
				margin-top: 12px;
				padding: 16px;
				.txt_con {
					width: 100%;
					height: 107px;
					font-size: 12px;
					resize:none;
					font-weight: 400;
					color: #666666;
					border: 0;
				}
				.txt_info {
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
					margin-top: 19px;
				}
				.txt_imgs {
					display: flex;
					margin-top: 8px;
					.imgs_item {
						position: relative;
						margin-right: 9px;
						.item_img {
							width: 44px;
							height: 44px;
							border-radius: 8px;
						}
					}
				}
			}
		}
	}
	
	// 提交
	.box_button {
		position: fixed;
		left: 50%;
		bottom: 10px;
		margin-left: -147.5px;
		.all-btn-bigCircle {
			width: 295px;
			height: 40px;
			line-height: 40px;
		}
	}

	.popContentBox{
		// background-color: #fff;
		.popBox{
			.titleBox{
				padding: 28px 34px 0 34px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				line-height: 25px;
				display: flex;
				span{
					display: inline-block;
				}
				.title{
					flex: 1;
				}
			}
			.popContent{
				clear: both;
				padding: 16px 18px;
				.option{
					display: flex;
					justify-content: space-between;
					margin-top: 28px;
					align-items: center;
					span{
						font-size: 16px;
						color: #333333;
						line-height: 22px;
					}
					img{
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		.popbutton{
			padding: 5px 0;
			position: fixed;
			width: 100%;
			bottom: 0;
			.btn{
				width: 295px;
				height: 40px;
				display: block;
				text-align: center;
				margin: 0 auto;
				line-height: 40px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
	}
</style>
