<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      :title="type == 1 ? '康复救助定点机构查询' : '康复救助经办机构查询'"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 查询条件输入框 -->
    <div class="content-box">
      <!-- 数据来源 -->
      <div class="box_source">
        <span>—</span>
        <span class="source_txt">数据来源：中国残疾人联合会</span>
        <span>—</span>
      </div>
      <div class="all-white-bg">
        <div class="input-box">
          <van-field class="input" :border='false' :value="title" :readonly='true' @click="addressShow = true" placeholder="请选择地区" />
          <van-icon color="#999999" name="arrow" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入机构名称" v-model="name" />
        </div>
      </div>
      <div class="select-btn">
        <span class="all-btn-bigCircle" @click="initData()">立即查询</span>
      </div>
      
      <!-- 查询结果 -->
      <div class="meshan-box" v-if="isShowData">
        <span class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">查询结果</div>
          </div>
        </span>
        <div class="all-count">搜索数据：<span>{{total}}</span>条</div>
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="finishTxt"
          @load="queryData"
        >
          <div 
            class="meshan-item" 
            v-for="(item, index) in dataList" 
            :key="index">
            <div class="meshan-title">
              <span class="name">{{item.name}}</span>
              <span class="point">{{item.type==1 ? '定点' : '经办'}}</span>
            </div>
            <div class="address">
              <img class="icon" src="@/assets/image/icon/20220222041501.png" />
              <span>{{item.localName}}</span>
            </div>
            <div class="line" v-if="item.type==2"><span>经办人：</span>{{item.operator}}</div>
            <div class="line"><span>服务项目：</span>{{item.item}}</div>
            <div class="line"><span>详细地址：</span>{{item.address}}</div>
            <div class="line"><span>联系电话：</span>{{item.mobile}}</div>
          </div>
        </van-list>
      </div>
    </div>
    <!-- 省市区 -->
    <van-popup v-model="addressShow" position="bottom" class="provincePop">
      <!-- <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="addressShow = false"
      /> -->
      <provinceCity
        :title="pickerTitle"
        :pageType="'1'"
        @onConfirm="onConfirm"
        @closeCityPop="closeCityPop"></provinceCity>
    </van-popup>
    <!-- 暂无数据 -->
    <noMessage v-if="isNoMessage" message="暂无机构信息" type=1></noMessage>
  </div>
</template>

<script>
import provinceCity from '@/components/provinceCity.vue'
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {
    provinceCity,
    noMessage
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      title: '', //地区
      addressShow: false,
      pickerTitle: '请选择地区',

      type: 0, //机构类型
      province: '', //省code
      city: '', //市code
      area: '', //区code
      name: '', //机构名称
      pageNum: 1, //页数
      pageSize: 10, //条数

      loading: false,
      finished: false,
      finishTxt: '',
      total: 0, //总数
      dataList: [], 
      isNoMessage: false, //暂无数据
      isShowData: false, //搜索结果

    }
  },
  // 事件处理器
  methods: {
    // 选择地区
    onConfirm(value) {
      if (!value.selectedOptions) {
        this.addressShow = false;
        return
      }
      this.title = ''
      this.addressShow = false;
      value.selectedOptions.map((item) => {
        this.title += item.name
      })
      this.province = value.selectedOptions[0] ? value.selectedOptions[0].code : ''
      this.city = value.selectedOptions[1] ? value.selectedOptions[1].code : ''
      this.area = value.selectedOptions[2] ? value.selectedOptions[2].code : ''
    },
    // 初始化
    initData() {
      if (this.$store.state.userInfo.isLogin) {
        // 输入一个即可查询
        if (this.title == '' && this.name == '') {
          if (this.title == '') {
            this.$Toast('请选择地区进行查询');
            return
          } 
          // else if (this.name == '') {
          //   this.$Toast('请输入机构名称进行查询');
          //   return
          // }
        }

        this.pageNum = 1
        this.dataList = []
        // 获取列表
        this.queryData()
      }else{
        this.$router.push('/login/login');
      }
    },
    // 立即查询
    queryData() {
      let params = {
        type: this.type, //机构类型
        province: this.province, //省code
        city: this.city, //市code
        area: this.area, //区code
        name: this.name, //机构名称
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //条数
      }
      // console.log(params)
      // return
      this.$ajax({
        url: '/hxclass-mobile/institution/list',
        method: 'get', 
        params
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.total = res.data.total
          this.dataList = this.dataList.concat(res.data.records)
          // console.log(this.dataList)
          this.loading = false
          this.finishTxt = ''
          this.isNoMessage = false //暂无数据
          this.isShowData = true //搜索结果
          if (this.dataList.length == res.data.total) {
            this.finished = true
            this.finishTxt = '没有更多了'
          } else {
            this.finished = false
            this.pageNum ++ 
          }
          // 数据为空
          if (this.dataList.length == 0) {
            // this.$Toast('暂无数据');
            this.finishTxt = ''
            this.isNoMessage = true //暂无数据
          }
        }
      })
    },
    // 关闭省市区
    closeCityPop() {
      this.addressShow = false
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 机构类型
    let query = this.$route.query
    this.type = query.type
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
 @import "./select.less";
  .input-box{
    display: flex;
    align-items: center;
  }
  // 省市区组件
  .provincePop {
    /deep/.van-cascader__close-icon {
      color: #ff0000;
      font-size: 15px;
    }
    /deep/.van-icon-cross:before {
      content: '确定';
    }
  }
  .meshan-box{
    margin-top: 18px;
    .meshan-item{
      margin-top: 13px;
      background: #FFFFFF;
      box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
      border-radius: 10px;
      padding: 18px 14px;
      .meshan-title{
        display: flex;
        align-items: center;
        text{
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
        }
        .point{
          display: inline-block;
          margin-left: 10px;
          text-align: center;
          line-height: 22px;
          font-size: 12px;
          font-weight: 500;
          border-radius: 10px 0 10px 0;
          color: #000000;
          width: 42px;
          height: 22px;
          background-image: linear-gradient(to right, #DAAD71 , #FFDBAF);
        }
      }
      .address{
        font-size: 12px;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        margin-top: 8px;
        border-bottom: 1px solid #E5E5E5;
        .icon{
          margin-right: 2.5px;
          width: 12px;
        }
      }
      .line{
        padding-top: 14px;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        line-height: 21px;
        span{
          color: #999;
        }
      }
    }

  .titleH4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 25px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        background-color: @theme;
        display: block;
        width: 3px;
        height: 13px;
        border-radius: 1.5px;
        margin-right: 3.5px;
      }

      .text {
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
</style>
