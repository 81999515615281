<template>
  <!-- 我的考试 -->
  <div class="myExam">
    <van-nav-bar
      class="nav-circular"
      title="我的考试"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="box" v-for="(item, index) in list" :key="index">
      <div class="box_title">{{ item.examConfigName || "— —" }}</div>
      <div class="box_list_con">
        <div
          class="box_list"
          v-for="(itemI, indexI) in item.signupList"
          :key="indexI"
        >
          <div class="list_title" @click="changeShow(index, indexI)">
            <div class="title_left">
              <img src="@/assets/image/examination/myExam_icon1.png" />
              <span>{{ itemI.examDate }}考试</span>
              <p v-if="itemI.orgId">
                <img src="@/assets/image/examination/myExam_icon3.png" />
                机构报名
              </p>
            </div>
            <div class="title_right">
              <van-icon
                v-if="itemI.show"
                name="arrow-down"
                color="#666666"
                size="18"
              />
              <van-icon v-else name="arrow" color="#666666" size="18" />
            </div>
          </div>
          <div class="list_box" v-if="itemI.show">
            <div class="list_con">
              <div
                class="con_item"
                v-if="itemI.isExamEnd == 1 && itemI.auditStatus != 3 && itemI.auditStatus != 1"
              >
              <!-- (itemI.auditStatus == 1 || itemI.auditStatus == 2) &&
              itemI.isExamEnd == 1 -->
                <div class="item_icon"><div class="item_icon_con"></div></div>
                <div class="item_title">考试结果</div>
                <!-- examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过 -->
                <div
                  class="item_con"
                  :class="{
                    con_red: itemI.examStatus == 4,
                    con_green: itemI.examStatus == 3,
                  }"
                >
                  {{ itemI.examStatusText }}
                </div>
              </div>
              <div class="con_item">
                <div class="item_icon"><div class="item_icon_con"></div></div>
                <div class="item_title">审核状态</div>
                <!-- auditStatus  审核状态 1未审核 2已审核 3已驳回 -->
                <div
                  class="item_con"
                  :class="{
                    con_green: itemI.auditStatus == 2,
                    con_red: itemI.auditStatus == 3,
                  }"
                >
                  {{ itemI.auditStatusText }}
                </div>
                <div class="item_con" v-if="itemI.rejecReason">
                  驳回原因：{{ itemI.rejecReason }}
                </div>
              </div>
              <div class="con_item">
                <div class="item_img">
                  <img
                    class="item_img_con"
                    src="@/assets/image/examination/myExam_icon2.png"
                  />
                </div>
                <div class="item_title">系统审核时间</div>
                <div class="item_con">{{ itemI.auditTime }}</div>
              </div>
              <div class="con_item">
                <div class="item_icon"><div class="item_icon_con"></div></div>
                <div class="item_title">报名信息</div>
                <div class="item_con">{{ itemI.examDate }}考试</div>
                <div class="item_info">
                  <span>考试时间：{{ itemI.examDateTime }} </span>
                  <span class="span1"
                    >考试时长：{{ manageDuration(itemI.duration) }}</span
                  >
                </div>
              </div>
            </div>
            <div class="list_button">
              <!-- auditStatus  审核状态 1未审核 2已审核 3已驳回 4.待完善 -->
              <!-- examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过 -->
              <!-- isExamEnd 考试是否结束  1.是 0.否 -->
              <!-- isHideTimes 是否隐藏场次按钮 1.是  0.否 -->
              <!-- orgId 是否机构报名 有值：机构报名   空值：个人报名 -->
              <!-- mailingMode 机构确认状态  0.个人确认 1.统一邮寄 2.机构未确认 -->
              <span 
                class="button_item" 
                @click="toMessage(itemI.signupUserId, 2)" 
                v-if="itemI.auditStatus==1 && itemI.isExamEnd==0 && !itemI.orgId">修改信息</span>
              <span 
                class="button_item" 
                @click="toMessage(itemI.signupUserId, 3)" 
                v-if="itemI.auditStatus==3 || (itemI.auditStatus==1 && itemI.isExamEnd==1) || (itemI.auditStatus==2 && itemI.examStatus!==3)">查看报名信息</span>
              <span 
                class="button_item" 
                @click="toChangeExam(itemI.signupId, itemI.signupUserId)" 
                v-if="itemI.auditStatus==1 && itemI.isHideTimes==0 && !itemI.orgId">更换场次</span>
              <span
                class="button_item" 
                @click="toGroupMessage(itemI.signupUserId,itemI.signupId,itemI.configId, 2)" 
                v-if="(itemI.auditStatus==1 || itemI.auditStatus==4) && itemI.isExamEnd==0 && itemI.orgId">{{itemI.auditStatus==1 ? '修改信息' : '完善信息'}}</span>
              <span
                class="button_item"
                v-if="itemI.examStatus == 3 && itemI.isConfirm == 0 && !itemI.orgId"
                @click="toConfirm(itemI.signupUserId, itemI.examineeId,itemI.configId)"
                >确认信息</span>
              <span
                class="button_item"
                v-if="itemI.examStatus == 3 && itemI.isConfirm == 0 && itemI.orgId && itemI.mailingMode == 0"
                @click="toConfirmOk(itemI.signupUserId, itemI.examineeId)"
                >确认信息</span>
              <span
                class="button_item"
                v-if="itemI.examStatus == 3 && itemI.orgId && itemI.mailingMode == 2"
                >待机构确认</span>
              <span
                class="button_item"
                v-if="itemI.isConfirm == 1 || (itemI.orgId && itemI.mailingMode == 1)"
                @click="toConfirmDetail(itemI.signupUserId)"
                >查看证书信息</span>
              <span
                class="button_item"
                @click="toSignUp(itemI.configId, item.courseName)"
                v-if="item.isSignup == 1 && !itemI.orgId"
                >重新报名</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <noMessage v-if="list.length == 0" type="1"></noMessage>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  components: { noMessage },
  data() {
    return {
      list: [],
    };
  },
  created() {
    // 我的考试
    this.getData();
  },
  methods: {
    // 我的考试
    getData() {
      this.$ajax({
        url: "/hxclass-mobile/exam/user/signup/list",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId, //userId
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.list = res.data;
          // 数据处理
          this.manageData();
        }
      });
    },
    // 我的考试数据处理
    manageData() {
      /**
       * auditStatus  审核状态 1未审核 2已审核 3已驳回 4待完善
       * examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过
       */
      let isSignup = 1; //是否需要重新报名 1需要 2不需要
      this.list.map((item, index) => {
        item.signupList.map((itemI, indexI) => {
          // 添加收起状态
          this.$set(this.list[index].signupList[indexI], "show", false);
          // 展开第一条
          this.$set(this.list[index].signupList[0], "show", true);
          // 未审核&&考试未结束
          if (itemI.auditStatus == 1 && itemI.isExamEnd == 0) {
            isSignup = 0; //不需要报名按钮
          }
          // 已审核
          if (itemI.auditStatus == 2) {
            // 未考试、考试中、已考试
            if (itemI.examStatus !== 4) {
              isSignup = 0; //不需要报名按钮
            }
          }
          // 已驳回并且是机构报名
          if (itemI.auditStatus == 3 && itemI.orgId) {
            isSignup = 0; //不需要报名按钮
          }
          // 待完善
          if (itemI.auditStatus == 4) {
            isSignup = 0; //不需要报名按钮
          }
        });
        this.$set(this.list[index], "isSignup", isSignup);
      });
      // console.log(this.list);
    },
    // 个人-查看、修改报名信息
    toMessage(signupUserId, type) {
      this.$router.push({
        path: "/examEnroll/enrollMessage",
        query: {
          type: type, //1报名 2修改 3详情
          signupUserId: signupUserId,
        },
      });
    },
    // 机构-完善、查看、修改报名信息
    toGroupMessage(signupUserId,signupId,configId, type) {
      this.$router.push({
        path: "/examEnroll/enrollMessageGroup",
        query: {
          type: type, //2修改/完善  3详情
          signupUserId: signupUserId,
          signupId: signupId,
          configId: configId,
        },
      });
    },
    // 更换考试场次
    toChangeExam(signupId, signupUserId) {
      this.$router.push({
        path: "/examEnroll/changeExam",
        query: {
          signupId: signupId,
          signupUserId: signupUserId,
        },
      });
    },
    // 个人-确认信息
    toConfirm(signupUserId, examineeId,configId) {
      this.$router.push({
        path: "/myStudy/questionnaire",
        query: {
          signupUserId: signupUserId,
          examineeId: examineeId,
          id: configId
        },
      });
    },
    // 机构个人确认信息
    toConfirmOk(signupUserId, examineeId) {
      this.$router.push({
        path: "/examEnroll/signUpConfirmOk",
        query: {
          signupUserId: signupUserId,
          examineeId: examineeId,
        },
      });
    },
    // 查看证书信息
    toConfirmDetail(signupUserId) {
      this.$router.push({
        path: "/examEnroll/signUpConfirmDetail",
        query: {
          signupUserId: signupUserId,
        },
      });
    },
    // 重新报名
    toSignUp(configId, courseName) {
      this.$router.push({
        path: "/examEnroll/registration",
        query: {
          id: configId,
          type: 1,
          name: courseName,
        },
      });
    },
    // 展开/收起
    changeShow(index, indexI) {
      this.list[index].signupList[indexI].show =
        this.list[index].signupList[indexI].show == true ? false : true;
    },
    // 考试时长处理
    manageDuration(con) {
      if (!con) {
        return;
      }
      let time = "";
      let hour = con.split(":")[0];
      let minute = con.split(":")[1];
      let second = con.split(":")[2];
      if (hour !== "00") {
        if (hour.charAt(0) == 0) {
          time += hour.substring(1, 2) + "小时";
        } else {
          time += hour + "小时";
        }
      }
      if (minute !== '00') {
        if (minute.charAt(0) == 0) {
          time += minute.substring(1,2)+'分钟'
        } else {
          time += minute+'分钟'
        }
        // time += minute+'分钟'
      }
      if (second !== "00") {
        time += second + "秒";
      }
      return time;
    },
  },
  onLoad() {},
  onPageScroll(e) {},
};
</script>

<style lang="less" scoped>
/deep/.van-nav-bar--fixed {
  z-index: 3;
}
* {
  box-sizing: border-box;
}
/deep/.nomessage {
  margin-top: 120px;
}
.myExam {
  background: #f7f7f9;
  padding-bottom: 50px;
  line-height: normal;
}
// 小号按钮
.all-btn-small {
  width: 108.5px;
}

.box {
  .box_title {
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    // margin-bottom: 9px;
    padding: 18px 18px 14px 18px;
  }
  .box_list_con {
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    // padding: 18px 18px 14px 18px;
    padding: 0 18px;
    .box_list {
      padding: 18px 0 14px 0;
      border-bottom: 0.5px solid #eeeeee;
      &:nth-last-child(1) {
        border: 0;
      }
      .list_title {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 12.5px;
        .title_left {
          img {
            width: 14.5px;
            vertical-align: middle;
          }
          span {
            font-size: 14.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            vertical-align: middle;
            margin-left: 5.5px;
          }
          p{
            display: inline-block;
            margin-left: 8px;
            height: 16px;
            line-height: 16px;
            background: #D6F7FF;
            font-size: 10px;
            color: #025B70;
            padding-right: 3px;
            border-radius: 10px 6px 6px 10px;
            img{
              vertical-align:middle;
              margin-top:-1px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .list_box {
        position: relative;
      }
      .list_con {
        margin-top: 12.5px;
        .con_item {
          position: relative;
          padding-left: 12.5px;
          padding-bottom: 18px;
          margin-left: 5px;
          border-left: 0.5px solid #c4c4c4;
          &:last-child {
            border: 0;
          }
          .item_icon {
            position: absolute;
            top: -3px;
            left: -13.5px;
            background-color: #fff;
            padding: 7.5px;
            z-index: 2;
            .item_icon_con {
              width: 11px;
              height: 11px;
              border: 1px solid #15b7dd;
              border-radius: 50%;
            }
          }
          .item_img {
            position: absolute;
            top: -2.5px;
            left: -13px;
            padding: 7px;
            background-color: #fff;
            z-index: 2;
            .item_img_con {
              display: block;
              width: 12.5px;
            }
          }
          .item_title {
            font-size: 14.5px;
            font-weight: 500;
            font-family: PingFangMedium;
            color: #333333;
          }
          .item_con {
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            margin-top: 6px;
            &.con_green {
              color: #15b7dd;
            }
            &.con_red {
              color: #ea3c31;
            }
          }
          .item_info {
            font-size: 12.5px;
            font-weight: 500;
            color: #15b7dd;
            margin-top: 5px;
            font-family: PingFangMedium;
            .span1 {
              font-weight: 400;
            }
          }
        }
      }
      .list_button {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        // margin-top: 2px;
        padding: 0 18px;
        .button_item {
          position: relative;
          display: inline-block;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #15B7DD;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 22px;
          padding: 0 7px;
          margin-right: 18px;
          &::after {
            position: absolute;
            top: 4px;
            right: -10px;
            content: '';
            width: 1px;
            height: 14px;
            line-height: 22px;
            background: rgba(21,183,221,0.65);
          }
          &:nth-last-child(1) {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
