<template>
  <div>
    <!-- 新知共享分类 -->
    <div class="scroll-view">
      <div class="scroll-item" :class="{'score-hover' : scoreIndex==i}" @click="scoreIndex=i" v-for="(item,i) in speakList" :key="item.autismClassificationId">{{item.name}}</div>
    </div>
    <div class="autismContent">
      <div class="scroll-x">
        <div
          class="item"
          v-for="i in speakList[scoreIndex].autismVOList"
          :key="i.autismId"
          @click="getDetail(i.name, i.fileUrl)">
          <!-- @click="getDetail(item.autismId)"> -->
          <div class="imgs">
            <img :src="i.img" alt="" />
          </div>
          <div class="title">{{ i.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 渲染数据
    speakList: {
      type: Array,
      default: ()=>[]
    },
  },
  // 数据对象
  data () {
    return {
      scoreIndex: 0
    }
  },
  // 事件处理器
  methods: {
    getDetail(name, url) {
      this.$router.push({ path: "/knowledge/specialAreaCon?name=" + name + '&url=' + url});
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
 .scroll-view{
    box-sizing: border-box;
    padding-bottom: 0;
    width: 100%;
    overflow-y: scroll;
    white-space: nowrap;
    .scroll-item{
      display: inline-block;
      text-align: center;
      padding-bottom: 7.5px;
      margin-right: 24px;
      font-size: 13px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;
      line-height: 19.5px;
    }
    .score-hover{
      color: #15B7DD;
      position: relative;
    }
    .score-hover::after{
      content: '';
      display: inline-block;
      background: url(../../assets/image/icon/20220218041506.png) no-repeat;
      background-size:100%;
      width: 5.5px;
      height: 6.5px;
      position: absolute;
      right: -4px;
      bottom: 7px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

.autismContent {
  padding: 8px 0;
  .scroll-x {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: 8px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .item {
    flex: none;
    width: calc((100% - 34px) / 3);
    overflow: hidden;
    margin-right: 17px;
    &:last-child {
      margin-right: 0;
    }
    .imgs {
      width: 100%;
      height: 134px;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        object-fit: cover;
      }
    }
    .title {
      margin-top: 10px;
      font-size: 14px;
      line-height: 1.4;
      color: #333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
