<template>
  <div>
		<!-- 导航栏 -->
		<van-nav-bar
			class="nav-circular"
			title="云课头条"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

    <div class="player">
      <Audio @setType="getType" :id="xuanId" :src="data.audioUrl" :cover="data.mobileImg" :title="data.title" :lecturer="data.lecturer"/>
    </div>

		<div class="introduce">
			<div class="content" v-html="data.content"></div>
		</div>
  </div>
</template>

<script>
import Audio from '@/components/Audio/index.vue'
export default {
  // 可用组件的哈希表
  components: { Audio },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      data: {},
      xuanId: 0
    }
  },
  // 事件处理器
  methods: {
    // 查询详情
    getDetails(xuanId){
      this.xuanId = parseInt(xuanId)
      this.$ajax({
        url: '/hxclass-mobile/speak/details',
        params: {
          xuanId: xuanId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.data = res.data

          // 如果没有音频封面用默认的
          if(!this.data.mobileImg){
            this.data.mobileImg = require('@/assets/image/icon/zhangxuan.png')
          }
          
          if(this.$store.state.audio.id != res.data.xuanId){ // 不属于同一个音频
            this.$store.commit('updateAudio',{
              title: res.data.title,
              src: res.data.audioUrl,
            })
            this.$store.state.audio.id = res.data.xuanId
            this.$store.commit('pause')
          }else{ // 属于同一个音频
            if(this.$store.state.audio.paused){
              // 暂停中
              if(!this.$store.state.audio.src){
                this.$store.commit('updateAudio',{
                  title: res.data.title,
                  src: res.data.audioUrl,
                })
              }
              this.$store.state.audio.id = res.data.xuanId
              // this.$store.commit('play')
            }else{
              // 播放中
              // this.$store.commit('pause')
            }
          }
        }else{

        }
      })
    },
    getType(e){
      if(e == 'up'){ 
        if(this.data.upXuan) {
          this.getDetails(this.data.upXuan)
        } else {
          this.$Toast('已经是第一首啦');
        }
      } else if (e == 'down'){
        if(this.data.downXuan) {
          this.getDetails(this.data.downXuan)
        } else {
          this.$Toast('已经是最后一首啦');
        }
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {},
  // 生命周期-实例挂载后调用
  mounted () {
    this.getDetails(this.$route.query.xuanId)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.introduce{
  // margin: 0 15px;
	margin-top: 20px;
  min-height: calc(100vh - 524px);
  background: #FFFFFF;
  border-radius: 22px 22px 0 0;
  padding: 20px;
	line-height: 20px;
  /deep/.content{
     img{
      width: 100%;
    }
    strong{
      font-weight: bold;
    }
    em{
      font-style: italic;
    }
    img{
      max-width: 100%;
    }
    .remarks{
      max-width: 100%;
      height: auto !important;
      -webkit-line-clamp: 10000 !important;
    }
    p {
      word-break: break-all;
      word-wrap: break-word;
    }
    h4 {
      font-weight: bold;
    }
  }
  
}
</style>
