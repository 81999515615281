      
<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      v-if="type == 1"
      class="nav-circular"
      :title="data.paperName"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="onBack()"
    />
    <van-nav-bar
      v-if="type == 2"
      class="nav-circular"
      :title="data.paperName"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="toRecord()"
    />

    <div class="header-box">
      <div class="header-left">
        <span class="header-num">{{ indexNumber + 1 }}</span>
        / {{ allNumber }}【{{
          subjectType == 1
            ? "单项选择题"
            : subjectType == 2
            ? "多项选择题"
            : "判断题"
        }}】
      </div>
      <div class="header-left header-right" v-if="type == 1">
        <img
          class="header-time"
          src="@/assets/image/examination/pic_time.png"
          mode=""
        />
        {{ thisCount }}
      </div>
    </div>
    <div class="content-box">
      <div class="content-question">
        <div class="question-span">
          {{ topicJson.title }}
          <span class="question-score">({{ topicJson.score }}分)</span>
        </div>
        <div class="question-option">
          <div
            class="option-list"
            v-for="(item, index) in opList"
            :key="index"
            @click="onOptionClick(index)"
          >
            <div
              v-if="type == 1"
              class="option-left"
              :class="item.state ? 'option-default' : ''"
            >
              {{
                index == 0 ? "A" : index == 1 ? "B" : index == 2 ? "C" : index == 3 ? "D" : index == 4 ? "E" : index == 5 ? "F" : index == 6 ? "G" 
                : index == 7 ? "H" : index == 8 ? "I" : index == 9 ? "J" : index == 10 ? "k" : index == 11 ? "L" : index == 12 ? "M" : index == 13 ? "N" 
                : index == 14 ? "O" : index == 15 ? "P" : index == 16 ? "Q" : index == 17 ? "R" : index == 18 ? "S" : index == 19 ? "T" : index == 20 ? "U" 
                : index == 21 ? "V" : index == 22 ? "W" : index == 23 ? "X" : index == 24 ? "Y" : "Z"
              }}
            </div>
            <div
              v-if="type == 2"
              class="option-left"
              :class="
                item.isCheked == 1 && item.isCorrect == 1
                  ? 'option-correct'
                  : item.isCheked != item.isCorrect && item.isCheked == 1
                  ? 'option-wrong'
                  : ''
              "
            >
              {{
                index == 0
                  ? "A"
                  : index == 1
                  ? "B"
                  : index == 2
                  ? "C"
                  : index == 3
                  ? "D"
                  : index == 4
                  ? "E"
                  : index == 5
                  ? "F"
                  : "G"
              }}
            </div>

            <div
              v-if="type == 1"
              class="question-span option-right"
              :class="item.state ? 'question-default' : ''"
            >
              {{ item.optionName }}
            </div>
            <div
              v-if="type == 2"
              class="question-span option-right"
              :class="
                item.isCheked == 1 && item.isCorrect == 1
                  ? 'question-correct'
                  : item.isCheked != item.isCorrect && item.isCheked == 1
                  ? 'question-wrong'
                  : ''
              "
            >
              {{ item.optionName }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 2" class="content-answer">
        <div
          class="answer-examinee"
          :class="topicJson.isCorrect == 1 ? 'answer-right' : 'answer-wrong'"
        >
          考生答案:【{{ topicJson.checked ? topicJson.checked : '未选' }}】
        </div>
        <div class="answer-examinee">正确答案:【{{ topicJson.answer }}】</div>
        <div class="analysis" v-show="topicJson.analysis">
          <span>题目解析：</span>
          <p>{{topicJson.analysis}}</p>
        </div>
        <div
          class="answer-pic"
          :class="topicJson.isCorrect == 1 ? '' : 'answer-wrongpic'"
        >
          {{ topicJson.isCorrect == 1 ? "正确" : "错误" }}
        </div>
      </div>
    </div>
    <div class="footer-box">
      <div
        class="footer-list"
        v-for="(item, index) in footerList"
        :key="index"
        @click="onTabClick(index)"
      >
        <div class="footer-image">
          <img class="footer-icon" :src="item.img" />
        </div>
        <div class="footer-span">
          {{ item.span }}
        </div>
      </div>
    </div>

    <!-- 答题卡弹窗 -->
    <n-transition ref="pop" speed="ease-in-out" :height="580" :maskVal="0.5">
      <div class="card-box" style="overflow-y: scroll">
        <div class="card-title">
          答题卡
          <van-icon
            class="title-icon"
            name="close"
            size="26"
            color="#A3A3A3"
            @click="onCancel()"
          />
        </div>
        <div class="card-content">
          <div class="scroll-y">
            <div class="card-list">
              <div class="card-type">
                <div class="type-border"></div>
                单选题
              </div>
              <div class="card-num">共{{ data.radioList.length }}题</div>
              <div class="card-order">
                <div
                  class="card-orderbox"
                  v-for="(i, index) in data.radioList"
                  :key="index"
                >
                  <div
                    v-if="type == 2"
                    class="card-orderlist"
                    :class="
                      i.checked && i.isCorrect == 1
                        ? 'card-orderright'
                        : i.checked && i.isCorrect == 0
                        ? 'card-orderwrong'
                        : ''
                    "
                    @click="onToNumber(index)"
                  >
                    {{ index + 1 }}
                  </div>
                  <div
                    v-else
                    class="card-orderlist"
                    :class="
                      FunIsAll(i, index) == 1
                        ? 'card-orderright'
                        : FunIsAll(i, index) == 2
                        ? 'card-ordernormal'
                        : ''
                    "
                    @click="onToNumber(index)"
                  >
                    {{ index + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list">
              <div class="card-type">
                <div class="type-border"></div>
                多选题
              </div>
              <div class="card-num">共{{ data.checkedList.length }}题</div>
              <div class="card-order">
                <div
                  class="card-orderbox"
                  v-for="(i, index) in data.checkedList"
                  :key="index"
                >
                  <div
                    v-if="type == 2"
                    class="card-orderlist"
                    :class="
                      i.checked && i.isCorrect == 1
                        ? 'card-orderright'
                        : i.checked && i.isCorrect == 0
                        ? 'card-orderwrong'
                        : ''
                    "
                    @click="onToNumber(data.radioList.length + index)"
                  >
                    {{ data.radioList.length + index + 1 }}
                  </div>
                  <div
                    v-else
                    class="card-orderlist"
                    :class="
                      FunIsAll(i, data.radioList.length + index) == 1
                        ? 'card-orderright'
                        : FunIsAll(i, data.radioList.length + index) == 2
                        ? 'card-ordernormal'
                        : ''
                    "
                    @click="onToNumber(data.radioList.length + index)"
                  >
                    {{ data.radioList.length + index + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list">
              <div class="card-type">
                <div class="type-border"></div>
                判断题
              </div>
              <div class="card-num">共{{ data.switchList.length }}题</div>
              <div class="card-order">
                <div
                  class="card-orderbox"
                  v-for="(i, index) in data.switchList"
                  :key="index"
                >
                  <div
                    v-if="type == 2"
                    class="card-orderlist"
                    :class="
                      i.checked && i.isCorrect == 1
                        ? 'card-orderright'
                        : i.checked && i.isCorrect == 0
                        ? 'card-orderwrong'
                        : ''
                    "
                    @click="
                      onToNumber(
                        data.radioList.length + data.checkedList.length + index
                      )
                    "
                  >
                    {{
                      data.radioList.length +
                      data.checkedList.length +
                      index +
                      1
                    }}
                  </div>
                  <div
                    v-else
                    class="card-orderlist"
                    :class="
                      FunIsAll(
                        i,
                        data.radioList.length + data.checkedList.length + index
                      ) == 1
                        ? 'card-orderright'
                        : FunIsAll(
                            i,
                            data.radioList.length +
                              data.checkedList.length +
                              index
                          ) == 2
                        ? 'card-ordernormal'
                        : ''
                    "
                    @click="
                      onToNumber(
                        data.radioList.length + data.checkedList.length + index
                      )
                    "
                  >
                    {{
                      data.radioList.length +
                      data.checkedList.length +
                      index +
                      1
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </n-transition>

    <!-- 答完题交卷弹窗 -->
    <van-popup v-model="finishedPopup" :close-on-click-overlay="false">
      <div class="popup-content">
        <div class="popup-box">
          <div class="title">确认交卷？</div>
          <div class="specs">
            <span>考试剩余时间：{{ thisCount }}</span>
          </div>
        </div>
        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="onSubmit(1)">现在交卷</span>
          </div>
          <div class="div">
            <span class="all-btn-middle" @click="finishedPopup = false"
              >继续答题</span
            >
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 退出交卷弹窗 -->
    <van-popup v-model="unFinishedPopup" :close-on-click-overlay="false">
      <div class="popup-content">
        <div class="popup-box">
          <div class="title title-noimg">您正在考试，是否交卷退出？</div>
        </div>
        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="onSubmit(2)">交卷退出</span>
          </div>
          <div class="div">
            <span class="all-btn-middle" @click="unFinishedPopup = false"
              >继续答题</span
            >
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 时间到交卷弹窗 -->
    <van-popup v-model="timeOutPopup" :close-on-click-overlay="false">
      <div class="popup-content">
        <div class="popup-box">
          <div class="title title-noimg">您的考试时间已结束</div>
        </div>
        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="onSubmit(3)">立即交卷</span>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 考试成绩弹窗 -->
    <van-popup v-model="achievementPopup" :close-on-click-overlay="false">
      <div class="popup-content">
        <div class="popup-box">
          <img
            v-if="isPass"
            class="cover-img"
            src="@/assets/image/examination/banner_pass.png"
            mode=""
          />
          <img
            v-else
            class="cover-img"
            src="@/assets/image/examination/banner_nopass.png"
            mode=""
          />

          <div class="title">
            {{ isPass ? "恭喜您考试通过" : "考试未通过" }}
          </div>
          <div class="specs">
            <span>用时：{{ Math.ceil(thisTime / 60) }}分钟</span>
          </div>
          <div class="achievement-box">
            <div class="achievement-list">
              <div class="achievement-top">{{ this.paperScore }}</div>
              <div class="achievement-bottom">试卷总分</div>
            </div>
            <div class="achievement-list">
              <div
                class="achievement-span"
                :class="isPass ? 'achievement-pass' : 'achievement-fail'"
              >
                {{ this.queryParams.examScore }}
              </div>
              <div class="achievement-bottom achievement-passtips">
                您的成绩
              </div>
            </div>
            <div class="achievement-list">
              <div class="achievement-top">{{ this.passScore }}</div>
              <div class="achievement-bottom">及格分数</div>
            </div>
          </div>
        </div>

        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="toExam()">返回列表</span>
          </div>
          <div class="div">
            <span class="all-btn-middle" @click="toTest()">查看试卷</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
export default {
  // 可用组件的哈希表
  components: { nTransition },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      achievementPopup: false, // 成绩
      timeOutPopup: false, // 时间到交卷
      unFinishedPopup: false, // 未答完题
      finishedPopup: false, // 答完题交卷

      type: "", //类型 1：考试 2：试卷详情
      indexNumber: 0, // 当前题号
      allNumber: 1, // 总题数
      subjectType: "", // 题目类型：1：单选 2：多选 3：判断
      countDown: 0, // 答题时长
      thisCount: "00:00:00", // 剩余时间
      thisTime: 0, // 考试用时
      topicJson: {}, // 当前题目
      opList: [], // 选项列表
      num: [], // 已答题数
      examScore: "", // 总成绩
      isPass: "", // 是否通过
      paperScore: "", // 试卷总分
      passScore: "", // 及格分数
      uid: "", // 试卷记录

      data: {
        paperName: "",
        radioList: [],
        checkedList: [],
        switchList: [],
      },

      queryParams: {
        duration: "",
        examId: "",
        examScore: 0,
        examineeId: this.$store.state.userInfo.userId,
        paperId: "",
        startTime: "",
        endTime: "",
        stemJson: [],
        type: 2,
        totalScore: 0,
      },

      footerList: [],
      examList: [
        {
          span: "答题卡",
          img: require("../../assets/image/examination/icon_card.png"),
        },
        {
          span: "交卷",
          img: require("../../assets/image/examination/icon_submit.png"),
        },
        {
          span: "上一题",
          img: require("../../assets/image/examination/icon_back.png"),
        },
        {
          span: "下一题",
          img: require("../../assets/image/examination/icon_next.png"),
        },
      ],
      recordList: [
        {
          span: "答题卡",
          img: require("../../assets/image/examination/icon_card.png"),
        },
        {
          span: "上一题",
          img: require("../../assets/image/examination/icon_back.png"),
        },
        {
          span: "下一题",
          img: require("../../assets/image/examination/icon_next.png"),
        },
      ],
      fromMenu: false
    };
  },
  beforeRouteEnter(to, from, next) {
    if(from.query.fromMenu) {
      sessionStorage.setItem('fromMenu',1)
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    sessionStorage.removeItem('fromMenu')
    next()
  },
  // 事件处理器
  methods: {
    toExam() {
      if(this.$route.query.fromMenu || this.fromMenu) {
        this.$onReturn(this.$route.query.fromMenu == '1');
      }else if(this.$route.query.from){
        this.$router.go(-2)
      }else{
        this.$router.push("/examination/exam?courseId=" + this.cid + "&fromMenu=" + (this.fromMenu?1:''));
      }
    },

    toTest() {
      this.achievementPopup = false;
      this.fromMenu = this.$route.query.fromMenu;
      this.$router.push(
        "/examination/testPaper?userPaperId=" + this.uid + "&type=2&fromMenu="+this.$route.query.fromMenu
      );
    },

    toRecord() {
      // this.$router.push('/examination/examRecord')
      this.$router.push(
        "/examination/examRecord?courseId=" +
          this.couId +
          "&configId=" +
          this.conId + "&fromMenu=" + (this.fromMenu?1:'')
      );
    },

    // 点击题目选项
    onOptionClick(i) {
      let checked = false;
      let arr = [];
      this.topicJson.optionVoList.forEach((item, index) => {
        if (item.optionId == this.topicJson.optionVoList[i].optionId) {
          if (item.state != 1) {
            checked = true;
            arr.push(item);
            this.topicJson.optionVoList[i].state = 1;
          } else {
            this.topicJson.optionVoList[i].state = 0;
          }
        } else {
          if (this.subjectType == 2) {
            // 多选
            if (item.state == 1 && arr.indexOf(item.optionId) == -1) {
              checked = true;
              arr.push(item.optionId);
              this.topicJson.optionVoList[index].state = 1;
            }
          } else {
            this.topicJson.optionVoList[index].state = 0;
          }
        }
      });

      let libraryId = this.topicJson.libraryId;
      if (checked) {
        if (!this.num.includes(libraryId)) {
          this.num.push(libraryId);
        }
      } else {
        if (this.num.indexOf(libraryId) > -1) {
          this.num.splice(this.num.indexOf(libraryId), 1);
        }
      }
    },

    // 关闭答题卡
    onCancel() {
      this.$refs.pop.hide();
    },

    // 计算剩余时间
    FunExcessTime() {
      const time = this.countDown;
      let h = parseInt(time / 3600);
      if (h < 10) {
        h = "0" + h;
      }
      let m = parseInt((time % 3600) / 60);
      if (m < 10) {
        m = "0" + m;
      }
      let s = time % 60;
      if (s < 10) {
        s = "0" + s;
      }
      this.thisCount = h + ":" + m + ":" + s;
    },

    // 判断是否答题
    FunIsAll(item, n) {
      let i = 0;
      item.optionVoList.forEach((el) => {
        if (el.state == 1) {
          i++;
        }
        if (el.state == 0 && this.indexNumber == n) {
          i--;
        }
      });
      if (i > 0) {
        return (i = 1);
      } else if (i < 0) {
        return (i = 2);
      } else {
        i = 0;
      }
    },
    onBack() {
      this.unFinishedPopup = true;
    },
    // 点击tab栏
    onTabClick(i) {
      if (this.type == 1) {
        if (i == "0") {
          // console.log(this.$refs)
          this.$refs.pop.show();
        } else if (i == "1") {
          this.finishedPopup = true;
          // if(this.num.length<this.allNumber){
          //   this.unFinishedPopup = true
          // }else{
          //   this.finishedPopup = true
          // }
        } else if (i == "2") {
          this.onPage(-1);
        } else if (i == "3") {
          this.onPage(1);
        }
      } else if (this.type == 2) {
        if (i == "0") {
          this.$refs.pop.show();
        } else if (i == "1") {
          this.onPage(-1);
        } else if (i == "2") {
          this.onPage(1);
        }
      }
    },

    // 快速跳转
    onToNumber(i) {
      this.indexNumber = i;
      this.FunTopic(i);
      this.$refs.pop.hide();
    },

    // 上下一题
    onPage(e) {
      const i = this.indexNumber + e;
      if (i < 0) {
        this.$Toast("已经是第一题");
      } else if (i > this.allNumber - 1) {
        this.$Toast("已经是最后一题");
      } else {
        this.indexNumber = i;
        this.FunTopic(i);
      }
    },

    // 组卷
    onErgodic() {
      let arry = [
        this.data.radioList,
        this.data.checkedList,
        this.data.switchList,
      ];
      let x = 0;
      arry.forEach((element, j) => {
        if (j == 1) {
          x = arry[j - 1].length;
        } else if (j == 2) {
          x += arry[j - 1].length;
        }
        element.forEach((item, i) => {
          this.queryParams.stemJson.push({
            libraryId: item.libraryId,
            isCorrect: 0,
            sort: i + x,
            score: item.score,
            stemOptionJson: [],
          });

          let n = 0;
          item.optionVoList.forEach((subItem, k) => {
            if (subItem.isCorrect != subItem.state) {
              n++;
            }
            if (n > 0) {
              this.queryParams.stemJson[i + x].isCorrect = 0;
            } else {
              this.queryParams.stemJson[i + x].isCorrect = 1;
            }

            let obj = {
              isCorrect: subItem.isCorrect,
              isCheked: subItem.state,
              sort: k,
              optionId: subItem.optionId,
            };
            this.queryParams.stemJson[i + x].stemOptionJson.push(obj);
          });
          this.queryParams.stemJson[i + x].stemOptionJson = JSON.stringify(
            this.queryParams.stemJson[i + x].stemOptionJson
          );
          if (this.queryParams.stemJson[i + x].isCorrect == 1) {
            this.queryParams.examScore += item.score;
          }
        });
      });
    },

    // 提交试卷
    onSubmit(x) {
      this.onErgodic();
      this.queryParams.endTime = new Date().format("yyyy-MM-dd hh:mm:ss");
      this.queryParams.stemJson = JSON.stringify(this.queryParams.stemJson);
      this.queryParams.totalScore = this.queryParams.examScore;
      // console.log(this.queryParams.stemJson);
      this.$ajax({
        url: "/hxclass-mobile/exam/mock/config/save-paper",
        method: "post",
        params: this.queryParams,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          clearInterval(this.set);
          if (x == 2) {
            this.cid = res.data.courseId;
            this.toExam();
          } else {
            this.isPass = res.data.pass;
            this.paperScore = res.data.paperScore;
            this.passScore = res.data.passScore;
            this.uid = res.data.userPaperId;
            this.cid = res.data.courseId;
            this.achievementPopup = true;
            this.timeOutPopup = false;
            this.finishedPopup = false;
            this.unFinishedPopup = false;
          }
        }
      });
    },

    // 返回当前题目信息
    FunTopic(e) {
      const radio = this.data.radioList; // 单选题
      const checkbox = this.data.checkedList; // 多选题
      const judge = this.data.switchList; // 判断题

      let json = {};
      if (e <= radio.length - 1) {
        json = radio[e];
        this.subjectType = 1;
      } else if (e <= radio.length + checkbox.length - 1) {
        const i = e - radio.length;
        json = checkbox[i];
        this.subjectType = 2;
      } else if (e <= radio.length + checkbox.length + judge.length - 1) {
        const i = e - radio.length - checkbox.length;
        json = judge[i];
        this.subjectType = 3;
      }
      console.log(json);
      this.topicJson = json;

      if (this.type == 1) {
        this.opList = this.topicJson.optionVoList;
      }
      if (this.type == 2) {
        this.opList = this.topicJson.optionList;
      }
    },

    getExamList(id) {
      let url = "";
      if (this.type == 1) {
        url =
          "/hxclass-mobile/exam/mock/config/mock-paper?paperId=" +
          id +
          "&configId=" +
          this.queryParams.examId;
      } else if (this.type == 2) {
        url =
          "/hxclass-mobile/exam/mock/config/user/paper/detail?userPaperId=" +
          id;
      }
      this.$ajax({
        url: url,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (this.type == 1) {
            res.data.radioList.forEach((item) => {
              item.optionVoList.forEach((subItem) => {
                subItem.state = 0;
              });
            });
            res.data.checkedList.forEach((item) => {
              item.optionVoList.forEach((subItem) => {
                subItem.state = 0;
              });
            });
            res.data.switchList.forEach((item) => {
              item.optionVoList.forEach((subItem) => {
                subItem.state = 0;
              });
            });
            this.data = res.data;
          } else if (this.type == 2) {
            this.data = res.data;
            this.couId = res.data.courseId;
            this.conId = res.data.configId;
          }

          // 查询题目解析
          let libraryIds = [...this.data.radioList, ...this.data.checkedList, ...this.data.switchList]
          let IdsArry = []
          libraryIds.forEach(item => {
            IdsArry.push(item.libraryId)
          });
          this.getAnalysisList(IdsArry)


          this.allNumber =
            res.data.checkedList.length +
            res.data.radioList.length +
            res.data.switchList.length;
          this.FunTopic(this.indexNumber);
        }
      });
    },

    // 查询题目解析
    getAnalysisList(arry){
      this.$ajax({
        url: '/hxclass-mobile/library/getLibraryAnswer',
				method: 'post',
        params: {
          libraryIdList: arry,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          res.data.forEach(item => {
            // 单选
            this.data.radioList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.analysis = item.answer
              }
            });
            // 多选
            this.data.checkedList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.analysis = item.answer
              }
            });
            // 判断
            this.data.switchList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.analysis = item.answer
              }
            });
          });
          this.$forceUpdate();
        }
      })
    },
  },

  // 生命周期-实例创建完成后调用
  created() {
    if(sessionStorage.getItem('fromMenu')) {
      this.fromMenu = sessionStorage.getItem('fromMenu');
    }
    this.id = this.$route.query.userPaperId;
    this.type = this.$route.query.type;
    this.queryParams.examId = Number(this.$route.query.configId);
    this.queryParams.paperId = Number(this.$route.query.paperId);
    this.queryParams.startTime = new Date().format("yyyy-MM-dd hh:mm:ss");

    if (this.type == 1) {
      this.queryParams.duration = this.$route.query.duration;
      this.footerList = this.examList;
      let a = this.queryParams.duration.substring(0, 2);
      let b = this.queryParams.duration.substring(3, 5);
      let c = this.queryParams.duration.substring(6, 8);
      // console.log(a*3600 + b*60 + c*1 +'秒');
      this.countDown = a * 3600 + b * 60 + c * 1;
      this.set = setInterval(() => {
        this.countDown--;
        this.thisTime++;
        if (this.countDown <= 0) {
          this.countDown = 0;
          clearInterval(this.set);
          this.set = null;
          this.timeOutPopup = true;
        }
        this.FunExcessTime();
      }, 1000);
    }
    if (this.type == 2) {
      this.footerList = this.recordList;
    }
    this.getExamList(this.id);
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route() {
      this.id = this.$route.query.userPaperId;
      this.type = this.$route.query.type;
      if (this.type == 2) {
        this.indexNumber = 0;
        this.footerList = this.recordList;
        this.getExamList(this.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container-box {
  min-height: 100vh;
  .header-box {
    margin: 12.5px 18px;
    height: 16.5px;
    overflow: hidden;

    .header-left {
      float: left;
      font-size: 11px;
      font-family: PingFangMedium;
      // font-weight: bold;
      color: #666666;
      line-height: 16.5px;

      .header-num {
        color: #15b7dd;
      }
    }

    .header-right {
      float: right;

      .header-time {
        margin-right: 3.5px;
        width: 11px;
        height: 11px;
      }
    }
  }

  /deep/.van-popup {
    background-color: transparent;
  }

  .content-box {
    // position: relative; //绝对定位
    padding-bottom: 140px;
    min-height: calc(100vh - 306px);
    // top: 80px;
    width: 100%;
    background: #ffffff;
    border-radius: 22.5px 22.5px 0px 0px;
    overflow: auto;
    overflow-y: scroll; //纵向滑动
    -webkit-overflow-scrolling: touch; //实现快速滚动和回弹的效果

    .question-span {
      font-size: 14.5px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 21.5px;
      white-space: pre-wrap;

      .question-score {
        color: #666666;
      }
    }

    .question-default {
      color: #1bb9de;
    }

    .question-correct {
      color: #8cbd0b;
    }

    .question-wrong {
      color: #ea3c31;
    }

    .content-question {
      margin: 16.5px 18px;

      .question-option {
        .option-list {
          margin: 27px 0 5.5px 0;

          .option-left {
            display: inline-block;
            float: left;
            margin-right: 9px;
            width: 18px;
            height: 18px;
            background: #ffffff;
            opacity: 1;
            border: 0.5px solid #999999;
            border-radius: 50% 50%;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            text-align: center;
            line-height: 19px;
          }

          .option-default {
            border: 0.5px solid #1bb9de;
            color: #1bb9de;
          }

          .option-correct {
            border: 0.5px solid #8cbd0b;
            color: #8cbd0b;
          }

          .option-wrong {
            border: 0.5px solid #ea3c31;
            color: #ea3c31;
          }

          .option-right {
            display: inline-block;
            width: calc(90% - 18px);
          }
        }
      }
    }

    .content-answer {
      position: relative;
      margin: 18.5px 18px;
      background: #f7f7f9;
      box-shadow: 0px 2.5px 4.5px 0.5px rgba(0, 0, 0, 0.05);
      border-radius: 9px 9px 9px 9px;
      overflow: hidden;

      .answer-examinee {
        margin: 18px 18.5px 18px;
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 21.5px;
      }

      .analysis{
        margin: 18px 18.5px 18px;
        font-size: 14.5px;
        font-size: 14px;
        line-height: 21px;
        color: #444444;
        span{
          display: inline-block;
          margin-bottom: 5px;
          color: @theme;
        }
      }

      .answer-right {
        color: #8cbd0b;
      }

      .answer-wrong {
        color: #ea3c31;
      }

      .answer-pic {
        position: absolute;
        top: 0;
        right: 0px;
        width: 45.5px;
        height: 21.5px;
        background: url(~@/assets/image/examination/pic_answer1.png) no-repeat;
        background-size: 100%;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 21.5px;
      }

      .answer-wrongpic {
        background: url(~@/assets/image/examination/pic_answer2.png) no-repeat;
        background-size: 100%;
      }
    }
  }

  .footer-box {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 75px;
    background: #ffffff;
    box-shadow: 0px -2px 3.5px 0.5px rgba(0, 0, 0, 0.05);

    .footer-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 7.5px;

      .footer-image {
        .footer-icon {
          width: 18px;
          height: 18px;
        }
      }

      .footer-span {
        font-size: 11px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 16.5px;
      }
    }
  }
  /deep/.main {
    overflow-x: scroll;
  }
  .card-box {
    margin: 16.5px 18px;
    .card-title {
      position: relative;
      font-size: 18px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      text-align: center;
      line-height: 27px;
      .title-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .card-content {
      .card-list {
        .card-type {
          margin-bottom: 7px;
          font-size: 16.5px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          line-height: 24.5px;

          .type-border {
            display: flex;
            display: inline-block;
            margin-right: 4px;
            width: 3px;
            height: 13px;
            border-radius: 4px;
            background-color: #15b7dd;
          }
        }

        .card-num {
          margin-bottom: 16.5px;
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #666666;
          line-height: 16.5px;
        }

        .card-order {
          display: flex;
          flex-wrap: wrap;
          margin-left: 13px;

          .card-orderbox {
            margin-bottom: 18px;
            width: calc(100% / 6);
          }

          .card-orderlist {
            width: 40px;
            height: 40px;
            background: #ffffff;
            opacity: 1;
            border: 1px solid #999999;
            border-radius: 50%;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            text-align: center;
            line-height: 40px;
          }

          .card-orderright {
            background: #15b7dd;
            border: 1px solid #ffffff;
            box-shadow: 0px 2px 4.5px 0.5px rgba(49, 188, 221, 0.24);
            color: #ffffff;
          }

          .card-orderwrong {
            background: #EC6C01;
            border: 1px solid #ffffff;
            color: #ffffff;
          }

          .card-ordernormal {
            background: #ffffff;
            border: 1px solid #15b7dd;
            color: #15b7dd;
          }
        }
      }
    }
  }

  .popup-content {
    background: #ffffff;
    width: 285.5px;
    border-radius: 10px;
    padding: 18px 10px 0;
    position: relative;

    .popup-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cover-img {
        width: 135px;
        height: 81px;
        border-radius: 9px;
      }

      .title {
        margin: 0 auto;
        margin-top: 21.5px;
        font-size: 20px;
        font-family: PingFangMedium;
        // font-weight: bold;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
      }

      .title-noimg {
        font-size: 20px;
        line-height: 30px;
      }

      .specs {
        // margin-top: 7px;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #15b7dd;
        line-height: 24.5px;
      }

      .achievement-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .achievement-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 33.33%;

          .achievement-top {
            font-size: 16.5px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 30px;
          }

          .achievement-span {
            font-size: 36px;
            font-family: PingFangMedium;
            font-weight: bold;
            line-height: 54.5px;
          }

          .achievement-pass {
            color: #15b7dd;
          }

          .achievement-fail {
            color: #ea3c31;
          }

          .achievement-bottom {
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #666666;
            line-height: 19px;
          }

          .achievement-passtips {
            color: #333333;
            font-size: 14.5px;
            line-height: 21.5px;
          }
        }
      }
    }

    .btn-inner {
      display: flex;
      justify-content: space-around;
      border-radius: 32px;

      .div {
        display: inline-block;
        border-radius: 32px;
        padding: 23.5px 10px;
        background: #fff;

        .all-btn-middle {
          width: 110.5px;
          height: 35.5px;
          line-height: 35.5px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>

    