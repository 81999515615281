<template>
  <div id="window">
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="换开发票"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div id="content">
      <div class="box">
        <div class="title">已选择{{form.invoiceRecordIds.length}}个订单</div>
        <div class="item">
          <div class="left">抬头类型</div>
          <div class="right">
            <div class="btn" :class="form.type==1?'click':''" @click="form.type=1">企业</div>
            <div class="btn" :class="form.type==3?'click':''" @click="form.type=3">个人</div>
          </div>
        </div>
        <div class="item">
          <div class="left">发票抬头</div>
          <div class="right">
            <input v-model="form.title" @input="searchInvoiceHeader(form.title)" placeholder="请填写发票抬头" />
            <div class="searchList">
              <p class="searchitem" @click="enterinvoice(item)" v-for="(item,index) in invoiceHeaderList" :key="index">{{item.nsrmc}}</p>
            </div>
          </div>
        </div>
        <template v-if="form.type=='1'">
          <div class="item">
            <div class="left">税号</div>
            <div class="right">
              <input v-model="form.taxNo" placeholder="请填写税号" />
            </div>
          </div>
        </template>
        <div class="item">
          <div class="left">电子邮箱</div>
          <div class="right">
            <input v-model="form.email" placeholder="请填写电子邮箱" />
          </div>
        </div>
        <!-- <div class="item">
          <div class="left">联系电话</div>
          <div class="right">
            <input v-model="form.companyPhone" placeholder="请填写接收信息电话" />
          </div>
        </div> -->
      </div>
      <template v-if="form.type=='1'">
        <div class="box">
          <div class="item">
            <div class="left">开户银行</div>
            <div class="right">
              <input v-model="form.openBank" placeholder="请填写开户银行" />
            </div>
          </div>
          <div class="item">
            <div class="left">银行账户</div>
            <div class="right">
              <input v-model="form.bankNo" placeholder="请填写银行账户" />
            </div>
          </div>
        </div>
      </template>

      <!-- 操作 -->
      <div class="operation">
        <div class="all-btn-bigCircle btn" @click="onSave()">提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      form:{
        type: 1, 
        title: '',
        taxNo: '',
        email: '',
        companyPhone: '',
        openBank: '',
        bankNo: '',
        invoiceRecordIds:[]
      },
      isSubmit:true,
      invoiceHeaderList: []
    }
  },
  // 事件处理器
  methods: {
    // 选择抬头
    enterinvoice(e) {
      this.$set(this.form,'title',e.nsrmc)
      if(this.form.type == 1){
        this.$set(this.form,'taxNo',e.nsrsbh)
      }
      this.invoiceHeaderList = [];
    },

    // 发票抬头搜索
    searchInvoiceHeader(e){
      // console.log(e)
      if(e.length >= 6){
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$ajax({
            url: '/hxclass-mobile/order/getCompanyInfo',
            method: 'get',
            params: {
              nsrmc: e
            }
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.invoiceHeaderList = res.data;
            }
          })
        }, 200)        
      }else{
        this.invoiceHeaderList = [];
      }
    },

    FunCheck(){
      let isCheck = false
      if(!this.form.title){
        isCheck = true
        this.$Toast("请填写发票抬头");
        return isCheck
      }
      if (!this.$regular.invoiceTitle.reg.test(this.form.title)) {
        isCheck = true
        this.$Toast(this.$regular.invoiceTitle.msg);
        return isCheck
      }
      if(this.form.type == 1 && !this.form.taxNo){
        isCheck = true
        this.$Toast("请填写税号");
        return isCheck
      }
      if (this.form.type == 1 && !this.$regular.TaxNumber.reg.test(this.form.taxNo)) {
        isCheck = true
        this.$Toast(this.$regular.TaxNumber.msg);
        return isCheck
      }
      if(!this.form.email){
        isCheck = true
        this.$Toast("请填写电子邮箱");
        return isCheck
      }
      if (!this.$regular.email.reg.test(this.form.email)) {
        isCheck = true
        this.$Toast(this.$regular.email.msg);
        return isCheck
      }
    },
		// 换开
		onSave(){
      if(this.FunCheck())return
      if(this.isSubmit){
        this.isSubmit = false
        this.$ajax({
          url: "/hxclass-mobile/invoice/change",
          method: 'post',
          params: this.form
        }).then((res) => {
          this.isSubmit = true
          if (res.code == 200 && res.success) {
            this.$Dialog.alert({
              title: '提示信息',
              message: '发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。',
            }).then(() => {
              this.$router.back()
            });
          } else {
            this.$Toast(res.message);
          }
        });
      }
		}
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.form.invoiceRecordIds =this.$route.query.invoiceRecordIds.split(",")
    // console.log(this.form.invoiceRecordIds)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import '../../assets/css/invoice.less';
#window{
  height: 100vh;
  display: flex;
  flex-direction: column;
  #content{
    flex: 1;
  }
}
.box{
	background-color: #fff;
	margin: 12px 18px;
	border-radius: 8px;
	padding: 0 12px;
	.title{
		font-size: 14px;
		color: #333;
		font-weight: 500;
		padding: 12px 0;
		line-height: 28px;
	}
	.item{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 54px;
		border-bottom: 1px solid #f5f5f5;
		&:last-child{
			border: none;
		}
		.left{
			font-size: 16px;
			color: #333333;
			font-weight: 400;
		}
		.right{
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
      position: relative;
			input{
				flex: 1;
				height: 100%;
				text-align: right;
				font-size: 12px;
        border: none;
			}
      .searchList{
        max-height: 200px;
        overflow: auto;
        position: absolute;
        top: 54px;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #f5f5f5;
        .searchitem{
          border-bottom: 1px solid #f5f5f5;
          padding: 10px;
          line-height: 20px;
          font-size: 13px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
			.btn{
				width: 72px;
				height: 25px;
				background: #EEEEEE;
				border-radius: 12.5px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: #333;
				font-weight: 400;
				margin-left: 24px;
				&.click{
					background-color: @theme;
					color: #fff;
				}
			}
		}
	}
}
.operation{
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  padding: 0 40px;
	.btn{
		width: 100%;
		height: 40px;
		font-size: 14px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-weight: 500;
	}
}
</style>
