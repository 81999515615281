<template>
  <div>
    <!-- 专辑封面 -->
    <div class="Album">
      <img class="cover" :src="cover" alt="">

      <!-- 收听人数 -->
      <!-- <p class="listeners">21321人在收听</p> -->

      <!-- 音频名称 -->
      <div class="name">
        <!-- <img src="./image/like.png" alt=""> -->
        <span>{{title}}</span>
      </div>

      <div class="speaker">
        <img src="@/assets/image/icon/icon_audio.png" alt="">
        <span>{{lecturer}}</span>
      </div>
    </div>
    <!-- 状态条 -->
    <div class="state">
      <div class="line">
        <!-- <div class="back" :style="'right:'+(100-(($store.state.audio.currentTime/$store.state.audio.duration)*100))+'%;'">
          <div class="btn">
            <img src="./image/current.png" alt="">
          </div>
        </div> -->
        <van-slider bar-height='5px' button-size="15px" @drag-start="onStartDrag" @drag-end="onStopDrag" v-model="value" @change="onChange">
          <template #button>
            <img class="btn-img" src="./image/current.png" alt="">
          </template>
        </van-slider>
      </div>
      <div class="date">
        <div>{{formatSeconds($store.state.audio.currentTime)}}</div>
        <div>{{formatSeconds($store.state.audio.duration)}}</div>
      </div>
    </div>
    <!-- 操作栏 -->
    <div class="operation">
      <!-- 后退15秒 -->
      <div class="date" @click="onSeek($store.state.audio.currentTime - 15)">
        <img src="./image/backOff.png" alt="">
      </div>
      <!-- 上一首 -->
      <div class="page" style="margin-left:38px;" @click="onSwitch('up')">
        <img src="./image/previous.png" alt="">
      </div>
      <!-- 播放/暂停 -->
      <div class="play" @click="onPausd(!$store.state.audio.paused)">
        <img v-if="!$store.state.audio.paused" src="./image/Playing.png" alt="">
        <img v-else src="./image/Suspend.png" alt="">
      </div>
      <!-- 下一首 -->
      <div class="page" style="margin-right:38px;" @click="onSwitch('down')">
        <img src="./image/next.png" alt="">
      </div>
      <!-- 前进15秒 -->
      <div class="date" @click="onSeek($store.state.audio.currentTime + 15)">
        <img src="./image/forward.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { formatSeconds } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    cover:  {
      type: String,
      default: null
    },
    lecturer: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    }
  },
  // 数据对象
  data () {
    return {
      value: 0,
      formatSeconds,
      // title: '',
      // cover: ''
		}
  },
  // 事件处理器
  methods: {
    // 进度条拖动时暂停更新进度条的取值
    onStartDrag(){
      clearInterval(this.timer)
    },
    // 拖动完毕后继续获取进度条值
    onStopDrag(){
      if(!this.$store.state.audio.paused){
        this.updataData()
      }
    },
    onChange(e){
      let time = (e / 100) * this.$store.state.audio.duration
      if (e == 0) {
        time = 0.1
      } else {
        time = (e / 100) * this.$store.state.audio.duration
      }
      this.$store.commit('updateAudio',{
        currentTime: time
      })
      
    },
    // 播放/暂停
    onPausd(e){
      if(e){
        clearInterval(this.timer)
        this.$store.commit('pause')
      }else{
        this.updataData()
        if(!this.$store.state.audio.src){
          this.$store.commit('updateAudio',{
            title: this.title,
            src: this.src
          })
        }
        this.$store.state.audio.id = this.id
        this.$store.commit('play')
      }
    },
    onDestroy(){
      this.$store.commit('destroy')
    },
    // 上下首切换
    onSwitch(e){
			this.$emit('setType', e)
    },

    // 播放进度调节
    onSeek(e){
      if(e < 0) {  // 音频进度小于0时默认0.1
        e = 0.1
      } else if (e > this.$store.state.audio.duration){
        // 音频进度大于总长度时给最大值
        e = this.$store.state.audio.duration
      }
      this.$store.commit('updateAudio',{
        currentTime: e
      })
      this.value = (this.$store.state.audio.currentTime/this.$store.state.audio.duration)*100
    },

    // 更新音频进度
    updataData(){
      this.timer = setInterval(()=>{
        this.value = (this.$store.state.audio.currentTime/this.$store.state.audio.duration)*100
        if(this.value==100) {
          clearInterval(this.timer)
        }
      },50)
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.updataData()
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    audioSrc(){
      return this.$store.state.audio.src
    }
  },
  // 自定义的侦听器
  watch: { 
    audioSrc(){
      clearInterval(this.timer)
      this.updataData()
    }
  }
}
</script>

<style lang="less" scoped>
.Album{
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cover{
    border-radius: 9px;
    width: 170px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    height: 170px;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  .listeners{
    font-size: 11px;
    color: #666;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  .name{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 22px;
    img{
      width: 14px;
      margin-right: 9px;
    }
    span{
      font-size: 14px;
      font-weight: bold;
      font-family: PingFangMedium;
    }
  }
  .speaker{
    margin-top: 13px;
    font-size: 13px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #15B7DD;
    line-height: 19px;
    img{
      width: 12px;
      height: 11px;
      margin-right: 4px;
    }
  }
}
.state{
	margin: 0 40px;
	.line{
		position: relative;
		height: 5px;
		background: #E9E9E9;
		border-radius: 0px 5px 5px 0px;
		margin-bottom: 9px;
		margin-top: 30px;
		.back{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 50%;
			border-radius: 0px 5px 5px 0px;
			background: linear-gradient(270deg, #16B7DD 0%, rgba(244, 244, 244, 0) 100%);
			.btn{
				height: 25px;
				width: 25px;
				position: absolute;
				top: 50%;
				right: 0;
        transform: translate(50%,-50%);
        img{
          width: 100%;
          height: 100%;
        }
			}
    }
    /deep/.van-slider__bar{
			background: linear-gradient(270deg, #16B7DD 0%, rgba(244, 244, 244, 0) 100%);
    }
    .btn-img{
      width: 25px;
      height: 25px;
      position: relative;
      top: 3px;
    }
	}
	.date{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 10px;
		color: #666;
	}
}
.operation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
	margin-top: 20px;
  .play{
    margin: 0 27px;
    img{
      width: 50px;
      height: 50px;
    }
  }
  .date{
    img{
      width: 22px;
      height: 22px;
    }
  }
  .page{
    img{
      width: 18px;
      height: 18px;
    }
  }
}
</style>
