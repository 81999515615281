var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"LoopTab",attrs:{"id":"LoopTab"}},[_c('img',{staticClass:"tablink",attrs:{"src":require("./img/tablink.png")}}),_c('div',{ref:"LoopTabWrapper",staticClass:"LoopTabWrapper",style:([
			{transform: 'translate3d('+_vm.LoopTabOffset+'px, 0px, 0px)'},
			{width: _vm.TotalWidth+'px'},
			{transitionDuration: (_vm.delayed?'500ms':'0ms')}
		]),on:{"touchstart":_vm.drag_hstart,"touchend":_vm.drag_hend,"touchmove":function($event){$event.preventDefault();return _vm.drag_hmove.apply(null, arguments)}}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"LoopTabSlide",class:[
				'LoopTabSlide'+index,
				_vm.CenterIndex==index?'click':''
			],on:{"click":function($event){return _vm.onClickTab(index)}}},[_vm._v(_vm._s(item.label))])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }