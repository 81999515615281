<template>
  <div class="enroll">
    <!-- 个人-报名信息 -->
    <van-nav-bar
      class="nav-circular"
      title="报名信息"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="onBack()"
    />
    <div class="remind-box" :class="{'disabled':type==3}" v-if="showPage">
      <div class="title">
        <div class="title-border"></div>
        考试提醒
      </div>
      <div class="content">
        <span class="warning">
          1、以下信息将打印在您的证书上，请仔细填写。<br />
          2、如无工作单位，请填写“个人”。<br />
          3、仅限本人自己报名，不得替他人报名，如经发现，取消考试资格
        </span>
      </div>
    </div>
    <van-form @submit="onSubmitVerify" @failed="onFailed" v-if="showPage">
      <div class="remind-box whiteBox" :class="{'disabled':type==3}">
        <div class="title">
          <div class="title-border"></div>
          上传证件照
        </div>
        <div class="content" @click="changeUpImg(1)">
          <div class="uploadimg">
            <!-- 浏览器、安卓上传 -->
            <van-uploader
              :before-read="beforeRead"
              v-if="pageType==1 || pageType==3"
              class="image"
              :class="{'imagesize': certifiMobile}"
              ResultType="file"
              :max-size="500 * 1024"
              :after-read="uploadCertifiMobile">
              <img v-if="!certifiMobile" src="../../assets/image/enroll/picture.png" />
              <img v-else class="certifiImg" :src="certifiMobile" />
            </van-uploader>
            <!-- ios上传 -->
            <div 
              v-if="pageType==2"
              class="image"
              :class="{'imagesize': certifiMobile}"
              @click="onPhoto()">
              <img v-if="!certifiMobile" src="../../assets/image/enroll/picture.png" />
              <img v-else class="certifiImg" :src="certifiMobile" />
            </div>
            <div class="uploadMessage">
              <span class="uploadTitle">照片要求</span>
              <span class="warnMessage"
                >请上传1寸蓝底或白底电子照片，尺寸为2.5cm×3.5cm</span
              >
              <span class="warnMessage"
                >请勿使用手机翻拍纸质版照片，否则考试申请将因照片不合格被驳回</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="remind-box whiteBox" :class="{'disabled':type==3}">
        <div class="title">
          <div class="title-border"></div>
          基本信息：
        </div>
        <div class="content">
          <div class="messageItem">
            <span class="type">姓名</span>
            <van-field 
              :disabled="true"
              :rules="[{ required: true}]"
              placeholder="请输入您的姓名" 
              v-model="name"
              name="name" />
          </div>
          <div class="messageItem">
            <span class="type">性别</span>
            <van-field name="sex">
              <template #input>
                <van-radio-group 
                  :disabled="true"
                  v-model="sex" 
                  name="sex" 
                  direction="horizontal">
                  <van-radio checked-color="#15B7DD" :name="1" icon-size="14px">男</van-radio>
                  <van-radio checked-color="#15B7DD" :name="0" icon-size="14px">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div class="messageItem">
            <span class="type">出生日期</span>
            <div class="item_input">
              <van-field 
                name="birthday"
                :rules="[{ required: true}]"
                @click="openDateFlag()"
                readonly
                v-model="birthday" 
                placeholder="选择您的出生日期" />
              <van-icon name="arrow" color="#999999" size="6" />
            </div>
            <!-- 日期弹框 -->
            <van-popup v-model="dateFlag" position="bottom">
              <van-datetime-picker
                type="date"
                title="选择年月日"
                v-model="currentDate"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="ondateConfirm"
                @cancel="dateFlag = false"
              />
            </van-popup>
          </div>
          <div class="messageItem">
            <span class="type">联系方式</span>
            <van-field 
              :disabled="true"
              :rules="[{ required: true}]"
              name="mobile"
              placeholder="请输入您的联系方式" 
              v-model="mobile" />
          </div>
          <div class="messageItem">
            <span class="type">身份证号</span>
            <van-field 
              :disabled="true"
              :rules="[{ required: true}]"
              name="idcard"
              placeholder="请输入您的身份证号" 
              v-model="idcard" />
          </div>
          <div class="messageItem">
            <span class="type">学历</span>
            <div class="item_input">
              <van-field 
                name="education"
                :rules="[{ required: true}]"
                @click="openEducationFlag()"
                readonly
                v-model="education" 
                placeholder="请选择您的学历" />
              <van-icon name="arrow" color="#999999" size="6" />
            </div>
            <van-popup v-model="educationFlag" position="bottom">
              <van-picker
                title="学历"
                value-key="name"
                show-toolbar
                :columns="educationColumns"
                :default-index="educationIndex"
                @confirm="oneducationConfirm"
                @cancel="educationFlag = false"
              />
            </van-popup>
          </div>
          <div class="messageItem">
            <span class="type">职称/职务</span>
            <div class="item_input">
              <van-field 
                name="post"
                :rules="[{ required: true}]"
                @click="openWorkFlag()"
                readonly
                v-model="post" 
                placeholder="请选择您的职务" />
              <van-icon name="arrow" color="#999999" size="6" />
            </div>
            <!-- 职称弹框 -->
            <van-popup v-model="workFlag" position="bottom">
              <van-picker
                title="职称"
                show-toolbar
                value-key="name"
                :columns="workColumns"
                :default-index="workIndex"
                @confirm="onworkConfirm"
                @cancel="workFlag = false"
              />
            </van-popup>
          </div>
          <div class="messageItem">
            <span class="type">工作单位</span>
            <van-field
              readonly
              name="workUnit"
              :rules="[{ required: true}]"
              placeholder="请填写工作单位名称" 
              v-model="workUnit" 
              @click="goWorkUnit()"/>
            <van-icon name="arrow" color="#999999" size="6" />
          </div>
        </div>
      </div>

      <div class="remind-box whiteBox" :class="{'disabled':type==3}">
        <div class="title">
          <div class="title-border"></div>
          身份认证：
        </div>
        <div class="content">
          <div class="identity">
            <div class="left">
              <span class="major">国徽面</span>
              <span class="place">上传您身份证国徽面</span>
            </div>
            <div class="right" @click="changeUpImg(2)">
              <div class="leftTop"></div>
              <div class="rightBottom"></div>
              <!-- 浏览器、安卓上传 -->
              <div 
                v-if="pageType==1 || pageType==3"
                class="Mask" 
                :class="{'pick': reversePicture!==''}">
                <van-uploader
                  :before-read="beforeRead"
                  :max-size="500 * 1024"
                  :after-read="uploadReversePicture">
                    <span>{{reversePicture==''?'上传照片':' '}}</span>
                    <img v-if="reversePicture!==''" :src="reversePicture" />
                </van-uploader>
              </div>
              <!-- ios上传 -->
              <div 
                v-if="pageType==2"
                class="Mask" 
                :class="{'pick': reversePicture!=='', 'MaskIos': pageType==2}"
                @click="onPhoto()">
                <span>{{reversePicture==''?'上传照片':' '}}</span>
                <img v-if="reversePicture!==''" :src="reversePicture" />
              </div>
              <img v-if="reversePicture==''" src="../../assets/image/enroll/back.png" />
              <img v-else :src="reversePicture" />
            </div>
          </div>
          <div class="identity">
            <div class="left">
              <span class="major">头像面</span>
              <span class="place">上传您身份证头像面</span>
            </div>
            <div class="right" @click="changeUpImg(3)">
              <div class="leftTop"></div>
              <div class="rightBottom"></div>
              <!-- 浏览器、安卓上传 -->
              <div 
                v-if="pageType==1 || pageType==3"
                class="Mask" 
                :class="{'pick': frontPicture!==''}">
                <van-uploader
                  :before-read="beforeRead"
                  :max-size="500 * 1024"
                  :after-read="uploadFrontPicture">
                    <span>{{frontPicture==''?'上传照片':' '}}</span>
                    <img v-if="frontPicture!==''" :src="frontPicture" />
                </van-uploader>
              </div>
              <!-- ios上传 -->
              <div 
                v-if="pageType==2"
                class="Mask" 
                :class="{'pick': frontPicture!=='', 'MaskIos': pageType==2}"
                @click="onPhoto()">
                <span>{{frontPicture==''?'上传照片':' '}}</span>
                <img v-if="frontPicture!==''" :src="frontPicture" />
              </div>
              <img v-if="frontPicture==''" src="../../assets/image/enroll/positive.png" />
              <img v-else :src="frontPicture" />
            </div>
          </div>
          <div class="identity">
            <div class="left">
              <span class="major">手持身份证照片</span>
            </div>
            <div class="right" @click="changeUpImg(4)">
              <div class="leftTop"></div>
              <div class="rightBottom"></div>
              <!-- 浏览器、安卓上传 -->
              <div 
                v-if="pageType==1 || pageType==3"
                class="Mask" 
                :class="{'pick': heldPhotos!==''}">
                <van-uploader
                  :before-read="beforeRead"
                  :max-size="500 * 1024"
                  :after-read="uploadHeldPhotos">
                  <span>{{heldPhotos==''?'上传照片':' '}}</span>
                  <img v-if="heldPhotos!==''" :src="heldPhotos" />
                </van-uploader>
              </div>
              <!-- ios上传 -->
              <div 
                v-if="pageType==2"
                class="Mask" 
                :class="{'pick': heldPhotos!=='', 'MaskIos': pageType==2}"
                @click="onPhoto()">
                <span>{{heldPhotos==''?'上传照片':' '}}</span>
                <img v-if="heldPhotos!==''" :src="heldPhotos" />
              </div>
              <img v-if="heldPhotos==''" src="../../assets/image/enroll/certificate.png" />
              <img v-else :src="heldPhotos" />
            </div>
          </div>
          <div class="term">
            <img src="../../assets/image/enroll/star.png" />必须本人手持身份证
          </div>
          <div class="term">
            <img
              src="../../assets/image/enroll/star.png"
            />人脸、身份证上的信息需清晰可见
          </div>
        </div>
        <div class="submitbox">
        <button
          v-if="type!=3" 
          type="default" 
          class="submit" 
          native-type="submit">提交</button>
          <!-- <img class="pic-button" src="@/assets/image/icon/pic_button.png" alt=""> -->
        </div>
        <!-- <div class="submit all-btn-small" native-type="submit">提交</div> -->
      </div>
    </van-form>

    <!-- 确认补考弹窗 -->
    <van-popup v-model="confirmPopup" :round="true">
      <div class="popup-content">
        <div class="popup-box">
          <div class="title title-noimg">{{ confirmTxt }}</div>
        </div>
        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="goPay()">确认</span>
          </div>
          <div class="div">
            <span class="all-btn-middle" @click="confirmPopup = false"
              >取消</span
            >
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 提示信息 -->
    <van-popup v-model="showPopup" :close-on-click-overlay="false">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="passed" color="#52C41A" size="26" />
          <span class="title_con">申请成功</span>
        </div>
        <div class="popup_con">{{popupTxt}}</div>
        <div class="popup_button middle">
          <span class="all-btn-middle btn2" @click="goBack()">我知道了</span>
        </div>
      </div>
    </van-popup>

    <!-- 提示信息 -->
		<van-popup v-model="onPopupOk" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="onPopupOk=false">我知道了</span>
        </div>
      </div>
    </van-popup>

    <!-- 加载 -->
    <div class="load_box" v-if="!showPage">
      <!-- <van-loading type="spinner" color="#1989fa" />
      <div class="box_con">加载中…</div> -->
    </div>
  </div>
</template>

<script>
import { dataURLtoFile } from '@/unit/fun.js' //base64转为对象
import { FunJudgDevice } from '@/unit/fun.js'
import { onChoosePhoto } from '@/assets/js/NativeWebKit.js';
const DeviceType = FunJudgDevice()
export default {
  data() {
    return {
      lock: false, //防抖
      message: '',
      onPopupOk: false,
      pageType: 0, //1安卓 2ios 3浏览器
      showPage: true, //展示页面
      type: 1, //1报名 2修改 3详情
      configId: '', //考试id
      signupId: '', //考试报名id
      timeId: '', //场次id
      userId: '', //用户id
      signupUserId: '', //用户报名id
      auditTime: '', //审核时间
      upImgType: 0, //上传图片方式 1证件照 2国徽面 3头像面 4手持身份证

      certifiMobile: '', //证件照片
      name: '', //姓名
      sex: '', //性别
      birthday: '', //出生日期
      mobile: '', //联系方式
      idcard: '', //身份证号
      education: "", //学历
      educationId: '', //学历id
      post: '', //职称/职务
      workUnit: '', //工作单位
      workunitId: '', //工作单位id
      reversePicture: '', //身份证反面照片
      frontPicture: '', //身份证正面照片
      heldPhotos: '', //手持身份证照片

      educationFlag: false, // 学历状态
      educationColumns: [], // 学历
      educationIndex: -1,
      workFlag: false, // 职位职称
      workColumns: [], // 职位职称
      workIndex: -1,
      nowTime: '',
      dateFlag: false, // 日期状态
      currentDate: new Date(), //当前时间
      minDate: new Date(1900, 0, 1), //最小时间
      maxDate: new Date(), //最大时间

      // 报名信息
      submitParams: {},

      // 提示框
      showPopup: false,
      popupTxt: '', //内容

      // 补考
      isMake: false, //是否需要补考
      confirmPopup: false,
      confirmTxt: '补考',

			imageTypeAll: ['jpg','jpeg','png']
    };
  },
  created() {
    // 获取当前时间
    let year = new Date().getFullYear();
    let month =new Date().getMonth() + 1 < 10? "0" + (new Date().getMonth() + 1): new Date().getMonth() + 1;
    let date =new Date().getDate() < 10? "0" + new Date().getDate(): new Date().getDate();
    this.nowTime = year + '-' + month + '-' + date
  },
  // 进入页面
  activated() {
    // 获取工作单位
    this.getWorkUnit()

    // 安卓
    if (DeviceType == 'android') {
      this.pageType = 1
    } else if (DeviceType == 'ios' && window.webkit) {     // ios
      this.pageType = 2
    } else {     // 浏览器
      this.pageType = 3
    }

    // app选择照片后回调
    window.returnPhotoUrl = (url)=>{
      var file = dataURLtoFile(url, 'fileName.jpg')
      // 证件照
      if (this.upImgType == 1) {
        this.uploadCertifiMobile(file, 1)
      } 
      // 国徽面
      else if (this.upImgType == 2) {
        this.uploadReversePicture(file, 1)
      }
      // 头像面
      else if (this.upImgType == 3) {
        this.uploadFrontPicture(file, 1)
      }
      // 手持身份证
      else if (this.upImgType == 4) {
        this.uploadHeldPhotos(file, 1)
      }
    }
  },
  // 离开页面
  deactivated() {
    
  },
  methods: {
    // 图片上传的前置操作
    beforeRead(file){
    //   const type = file.type.split('/')[1]
    //   const index = this.imageTypeAll.indexOf(type.toLowerCase())
    //   if(index == -1){
    //     this.message = '上传图片格式不正确，请上传为jpg、jpeg、png格式后缀的图片。'
    //     this.onPopupOk = true
    //   }
      return true
    },

    // 上一页
    onBack() {
      // return
      this.$onReturn(this.$route.query.fromMenu == '1')
    },
    // ios打开相册
    onPhoto() {
      window.webkit.messageHandlers.photo.postMessage('')
    },
    // 上传图片
    changeUpImg(type) {
      this.upImgType = type
    },
    // 初始化
    async initData() {
      this.showPage = false
      let params = this.$route.query
      this.configId = params.configId //考试id
      this.signupId = params.signupId //考试报名id
      this.signupUserId = params.signupUserId //考试报名用户id
      this.timeId = params.timeId //场次id
      this.type = params.type || 1 //1报名 2修改 3详情
      this.auditTime = params.auditTime //审核时间
      this.userId = this.$store.state.userInfo.userId //报名用户id
      // console.log(this.configId, this.signupId, this.timeId, this.userId)
      // 学历列表
      await this.getEducation()
      // 职称列表
      await this.getWork()

      // 查询最近报名信息
      if (this.type == 1) {
        await this.getLatelySignUp()
      } 
      // 修改-获取报名信息
      else if (this.type == 2 || this.type == 3) {
        await this.getSignUp()
      }
    },
    // 提交校验
    async onSubmitVerify(values) {
      // console.log('submit', values, this.sex, this.sex == '');
      // 判空校验-证件照
      if (!this.certifiMobile) {
        this.$toast('请上传证件照')
        return
      }
      // 性别
      // if (this.sex == '') {
      //   this.$toast('请选择性别')
      //   return
      // }
      // 国徽面
      if (this.reversePicture == '') {
        this.$toast('请上传身份证照片')
        return
      }
      // 头像面
      if (this.frontPicture == '') {
        this.$toast('请上传身份证照片')
        return
      }
      // 手持身份证
      if (this.heldPhotos == '') {
        this.$toast('请上传手持身份证照片')
        return
      }
      
      let params = values
      params.sex = parseInt(params.sex) //性别
      params.educationId = this.educationId //学历
      params.workunitId = this.workunitId //工作单位id
      params.certifiMobile = this.certifiMobile //证件照
      params.reversePicture = this.reversePicture //身份证反面照片
      params.frontPicture = this.frontPicture //身份证正面照片
      params.heldPhotos = this.heldPhotos //手持身份证照片
      params.configId = parseInt(this.configId) //考试id
      params.signupId = parseInt(this.signupId) //考试报名id
      params.timeId = parseInt(this.timeId) //场次id
      params.signupUserId = parseInt(this.signupUserId) //用户报名id
      params.userId = this.userId //报名用户id
      params.auditStatus = 1 //审核状态
      this.submitParams = params; //报名信息
      // console.log(params, this.submitParams)
      // return

      // 是否需要补考（新增）
      if (this.type == 1) {
        await this.getIsMake();
      } else if (this.type == 2) {
        // 提交报名信息
        this.onSubmit();
      }
    },
    // 是否需要补考
    async getIsMake() {
      let userId = this.$store.state.userInfo.userId;
      await this.$ajax({
        url: '/hxclass-mobile/exam/verify/make-up-exam',
        method: "get",
        params: {
          signupId: this.signupId,
          userId: userId,
        }
      }).then((res) => {
        // this.isMake = res.success; //是否需要补考
        this.confirmTxt = res.message; //补考提示语
        // console.log('是否需要补考', this.isMake)
        if (res.code == 200 && res.success) {
          // 不用补考
          this.isMake = false;
          this.confirmPopup = false;
          // 提交报名信息
          this.onSubmit();
        } else {
          // 需要补考
          this.isMake = true;
          this.confirmPopup = true;
        }
      });
    },
    // 提交报名信息
    async onSubmit() {
      // 上锁
      if (!!this.lock) {return}
      this.lock = true; //打开锁

      // 支付补考费用
      // this.lock = false;
      // return

      let url = ''
      let method = ''
      // 新增
      if (this.type == 1) {
        // 需要补考
        if (!!this.isMake) {
          url = '/hxclass-mobile/exam/signUp/makeUpSave'
        } else {
          url = '/hxclass-mobile/exam/signup/save'
        }
        method = "POST"
      } 
      // 修改
      else if (this.type == 2) {
        url = '/hxclass-mobile/exam/signup/edit'
        method = "PUT"
      }

      // 报名
      await this.$ajax({
        url,
        method,
        params: this.submitParams
      }).then((res) => {
        this.lock = false
        if (res.code == 200 && res.success) {
          // 新增
          if (this.type == 1) {
            // 需要补考
            if (!!this.isMake) {
              this.confirmPopup = true;
              this.signupUserId = res.data; //用户报名id
            } else {
              // 提交成功
              this.showPopup = true;
              this.popupTxt = '考试申请将于'+ this.auditTime +'统一审核，如在该时间前未学完课程，本次考试申请将被驳回。'
            }
          } 
          // 修改
          else {
            this.$Toast.success("修改成功");
            this.$onReturn(this.$route.query.fromMenu == '1');
          }
        } else {
          this.$Toast(res.message)
          this.$onReturn(this.$route.query.fromMenu == '1');
        }
      });
    },
    // 提交失败
    onFailed({values, errors}) {
      // console.log(values)
      // 职务
      if (this.post == '') {
        this.$Toast('请选择您的职务')
      }
    },
    // 支付补考费用
    async goPay() {
      this.confirmPopup = false
      // 提交报名信息
      await this.onSubmit();
      
      // 补考标识
      sessionStorage.setItem('payMakeObj', JSON.stringify({
        id: this.signupId, //考试id
        configId: this.configId, //课程id
        signupUserId: this.signupUserId, //用户报名id
        pay: false, //是否支付
        invoice: false, //是否跳了发票页
      }))
      // payReturnsign 1跳回考试报名页 2跳到首页
      localStorage.setItem('payReturnsign', 1)
      let productList = [
        {
          count: 1, //数量
          noGift: 0, //是否为赠品 0否1是
          productId: this.configId, //商品id-考试id
          type: 7, //商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        },
      ];
      
      // return
      this.$store.commit("updatelist", productList); //订单列表
      this.$store.commit("updateType", 3); // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
      this.$store.commit("updateInvoiceId", null); //发票id
      this.$store.commit('updateShortInvoiceId',null);
      this.$store.commit("updateAddress", null); //收货地址id
      this.$store.commit("updateCouponList", null);
      this.$router.push("/order/confirmOrder");
    },
    // 我知道了
    goBack() {
      this.showPopup = false
      this.$onReturn(this.$route.query.fromMenu == '1');
    },
    // 查询最近报名信息
    async getLatelySignUp() {
      await this.$ajax({
        url: '/hxclass-mobile/exam/user/last-signup-info',
        method: 'GET',
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 回显报名信息
          if (res.data !== null) {
            // 数据回显
            this.echoData(res.data)
            this.showPage = true
            // 工作单位页面传入
            // this.getWorkUnit()
          } else {
            // 获取基础信息
            this.getBasics()
          }
        }
      })
    },
    // 获取基础信息
    async getBasics() {
      await this.$ajax({
        url: '/hxclass-mobile/user/info/infodetail',
        method: 'GET',
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.showPage = true
          // 回显报名信息
          if (res.data !== null) {
            this.name = res.data.name //姓名
            // this.sex = res.data.sex == '2' ? '0' : '1'  //性别
            // 性别 全部改为int类型
            if (res.data.sex == '2' || res.data.sex == 2) {
              this.sex = 0
            } else {
              this.sex = parseInt(res.data.sex)
            }
            // console.log('sex', this.sex, typeof this.sex)
            this.birthday = res.data.birthday //出生日期
            let birthday = this.birthday ? this.birthday.split('-') : this.nowTime.split('-')
            this.currentDate = new Date(birthday[0], birthday[1]-1, birthday[2]), //当前时间
            this.mobile = res.data.mobile //联系方式
            this.idcard = res.data.idNo //身份证号
            this.education = res.data.degree //学历
            this.educationId = res.data.degreeCode //学历id
            this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==this.education}) //学历索引
            this.workUnit = res.data.work //工作单位
            // this.workUnitId = res.data.workCom //工作单位id
            this.workunitId = res.data.workCom //工作单位id

            // 工作单位页面传入
            // this.getWorkUnit()
          }
        }
      })
    },
    // 修改-获取报名信息
    async getSignUp() {
      await this.$ajax({
        url: '/hxclass-mobile/exam/user-signup-info',
        method: 'GET',
        params: {
          signupUserId: this.signupUserId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.showPage = true
          // 数据回显
          this.echoData(res.data)
          // 工作单位页面传入
          // this.getWorkUnit()
        }
      })
    },
    // 数据回显
    echoData(data) {
      this.certifiMobile = data.certifiMobile //证件照片
      this.name = data.name //姓名
      // 全部改为int类型
      if (data.sex == '2' || data.sex == 2) {
        this.sex = 0
      } else {
        this.sex = parseInt(data.sex) //性别
      }
      // this.sex = JSON.stringify(data.sex) //性别
      // console.log('sex', this.sex, typeof this.sex)
      this.birthday = data.birthday //出生日期
      let birthday = this.birthday ? this.birthday.split('-') : this.nowTime.split('-')
      this.currentDate = new Date(birthday[0], birthday[1]-1, birthday[2]), //当前时间
      this.mobile = data.mobile //联系方式
      this.idcard = data.idcard //身份证号
      this.education = data.education //学历
      // this.educationId = data.educationId //学历
      // 博士后(处理老数据)
      if (this.education == '博士后') {
        this.education = '博士研究生'
      }
      this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==this.education}) //学历索引
      this.educationId = this.educationIndex!==-1 ? this.educationColumns[this.educationIndex].code : -1 //学历
      this.post = data.post //职称/职务
      this.workIndex = this.workColumns.findIndex((item)=>{return item.name==this.post}) //学历索引
      this.workUnit = data.workUnit //工作单位
      this.workunitId = data.workunitId //工作单位
      // console.log(data, data.workunitId, this.workunitId)
      this.reversePicture = data.reversePicture || '' //身份证反面照片
      this.frontPicture = data.frontPicture || '' //身份证正面照片
      this.heldPhotos = data.heldPhotos || '' //手持身份证照片
    },
    // 学历列表
    async getEducation() {
      await this.$ajax({
        url: "/hxclass-mobile/nation/degree/list",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.educationColumns = res.data
        }
      });
    },
    // 职务列表
    async getWork() {
      await this.$ajax({
        url: "/hxclass-mobile/job/list/all",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workColumns = res.data
        }
      });
    },
    // 上传证件照
    uploadCertifiMobile(e, type) {
      let file = type == 1 ? e : e.file
      const index = this.imageTypeAll.indexOf(file.type.split('/')[1].toLowerCase())
      // console.log(index,file.type.split('/')[1],file.type,file)
      // if(index == -1){
      //   this.message = '上传图片格式不正确，请上传为jpg、jpeg、png格式后缀的图片。'
      //   this.onPopupOk = true
      //   return false
      // }
      // this.certifiMobile = 'https://pic3.zhimg.com/50/v2-36312659ea7d01604bd43f2c0ebdba47_720w.jpg?source=1940ef5c'
      // return
      // type 1安卓上传
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          this.certifiMobile = e
        },
        fail: (err) => {
          this.$Toast(err)
        }
      });
    },
    // 出生日期
    openDateFlag() {
      this.dateFlag = true
    },
    // 学历
    openEducationFlag() {
      this.educationFlag = true
    },
    // 职称/职务
    openWorkFlag() {
      this.workFlag = true
    },
    // 工作单位
    // 反面照片
    uploadReversePicture(e, type) {
      let file = type == 1 ? e : e.file
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          this.reversePicture = e
        },
        fail: (err) => {
          this.$Toast(err)
        }
      });
    },
    // 头像面
    uploadFrontPicture(e, type) {
      let file = type == 1 ? e : e.file
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          this.frontPicture = e
        },
        fail: (err) => {
          this.$Toast(err)
        }
      });
    },
    // 手持身份证照片
    uploadHeldPhotos(e, type) {
      let file = type == 1 ? e : e.file
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          this.heldPhotos = e
        },
        fail: (err) => {
          this.$Toast(err)
        }
      });
    },
    // 选择学历
    oneducationConfirm(e) {
      // console.log(e)
      this.education = e.name;
      this.educationId = e.code
      this.educationFlag = false;
    },
    // 选择职位职称
    onworkConfirm(e) {
      this.post = e.name;
      this.workFlag = false;
    },
    // 选择出生日期
    ondateConfirm(e) {
      this.birthday = e;
      this.dateFlag = false
      // console.log(this.birthday)
      // 转换时间
      this.switchTime()
    },
    // 跳往工作单位
    goWorkUnit() {
      if (this.type == 3) {return}
      this.$router.push({path:'/mine/workUnit', query:{name:this.workUnit}})
    },
    // 工作单位获取
    getWorkUnit() {
      // 工作单位
      let workUnitObj = localStorage.getItem('workUnitObj')
      if (workUnitObj) {
        workUnitObj = JSON.parse(workUnitObj)
        if (!!workUnitObj.flag) {
          localStorage.removeItem('workUnitObj')
          return
        }
        // console.log(JSON.parse(workUnitObj))
        this.workUnit = workUnitObj.name // 工作单位
        this.workunitId = workUnitObj.workUnitId || '' // 工作单位id
        localStorage.removeItem('workUnitObj')
        // console.log(this.workUnit, this.workunitId)
        // console.log(workUnitObj.name, workUnitObj.workUnitId)
        // 转为修改状态
        // this.authenticationMessage = null
      }
      // 初始化
      else {
        this.initData()
      }
    },
    // 转换时间
    switchTime() {
      let year = new Date(this.birthday).getFullYear();
      let mouth =
        new Date(this.birthday).getMonth() + 1 < 10
          ? "0" + (new Date(this.birthday).getMonth() + 1)
          : new Date(this.birthday).getMonth() + 1;
      let day =
        new Date(this.birthday).getDate() < 10
          ? "0" + new Date(this.birthday).getDate()
          : new Date(this.birthday).getDate();
      this.birthday =  year + "-" + mouth + "-" + day;
    },
  },
};
</script>

<style scoped lang="less">
/deep/.van-cell {
  padding: 0;
  flex: 1;
}
/deep/.submit {
  width: calc(100vw - 80px);
  height: 36px;
  margin: 27px auto;
  margin-bottom: 0px;
  border: 0;
  line-height: 36px;
  font-size: 14.5px;
  font-weight: bold;
  color: #fff;
  border-radius: 18px 18px 18px 18px;
  background-color: #15B7DD;
  // background: url('~@/assets/image/icon/pic_button.png') no-repeat;
  // background-size: 160% 100%; 
  // background-size: cover;
  // &.pick {

  // }
}
.nav-circular {
  position: relative;
  z-index: 100;
}
.submitbox{
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  button {
    background-image: url('~@/assets/image/icon/pic_button.png');
    background-position: bottom;
    background-size: 100%;
  }
}
.pic-button{
  position: absolute;
  left: -4px;
  bottom: -10px;
  width: calc(100vw - 2px);
  height: 46px;
}
.submit::after {
  border: 0;
}
/deep/.van-popup {
  background-color: transparent;
}
.enroll {
  background-color: rgba(247, 247, 249, 1);
  .remind-box {
    padding: 22px 16px 0;
    &.disabled { 
      pointer-events: none;
    }
    &:last-child {
      padding-bottom: 40.5px;
    }
    .content {
      margin-top: 8px;
      .warning {
        display: block;
        font-size: 11px;
        font-weight: 500;
        color: #ea3c31;
        line-height: 16.5px;
        margin-bottom: 12.5px;
      }
      .uploadimg {
        display: flex;
        .image {
          margin-left: 29px;
          background-color: rgba(242, 242, 242, 1);
          padding: 34px 19px 33.5px 18.5px;
          img {
            width: 35.5px;
          }
        }
        .imagesize {
          display: flex;
          align-items: center;
          // margin-left: 10px;
          background-color: transparent;
          padding: 0;
          .certifiImg {
            width: 70px;
          }
        }
        .uploadMessage {
          padding: 0 29px 0 12.5px;
          display: flex;
          flex-direction: column;
          .uploadTitle {
            font-size: 14.5px;
            font-weight: 500;
            color: #333333;
            line-height: 21.5px;
            margin-bottom: 12.5px;
          }
          .warnMessage {
            font-size: 11px;
            font-weight: 500;
            color: #EC6C01;
            line-height: 16.5px;
            margin-bottom: 8px;
          }
        }
      }
      .messageItem {
        height: 46.5px;
        border-bottom: 0.5px solid #eeeeee;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .type {
          font-size: 14.5px;
          font-weight: 500;
          color: #333333;
        }
        /deep/.van-field__control {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          font-size: 14px;
        }
        &:last-child {
          border: 0;
        }
        .item_input {
          display: flex;
          align-items: center;
          flex: 1;
          .van-icon {
            // vertical-align: ;
            padding-top: 2px;
          }
        }
      }
      .term {
        display: block;
        margin-left: 116.5px;
        margin-top: 6px;
        font-size: 11px;
        font-weight: 500;
        color: #333333;
        line-height: 16.5px;
        img {
          width: 8px;
          margin-right: 4px;
        }
      }
      .identity {
        margin-top: 12.5px;
        display: flex;
        align-items: center;
        .left {
          width: 115.5px;
          display: flex;
          flex-direction: column;
          .major {
            font-size: 14.5px;
            font-weight: 500;
            color: #333333;
            line-height: 21.5px;
          }
          .place {
            margin-top: 9px;
            font-size: 11px;
            font-weight: 500;
            color: #666666;
            line-height: 16.5px;
          }
        }
        .right {
          margin-left: 11px;
          position: relative;
          z-index: 1;
          .Mask {
            width: 201px;
            height: 127px;
            position: absolute;
            z-index: 2;
            text-align: center;
            line-height: 127px;
            background-color: rgba(245, 253, 255, 0.5);
            &.pick {
              background-color: transparent;
            }
            /deep/.van-uploader {
              width: 100%;
            }
            /deep/.van-uploader__wrapper {
              width: 100%;
            }
            /deep/.van-uploader__input-wrapper {
              // width: 100%;
              width: 201px;
              min-height: 127px;
            }
            span {
              font-size: 12.5px;
              font-weight: 500;
              color: #15b7dd;
              line-height: 19px;
            }
          }
          .MaskIos {
            height: auto;
          }
          .rightBottom {
            width: 14.5px;
            height: 14.5px;
            background-color: rgba(151, 235, 255, 1);
            position: absolute;
            left: -6px;
            top: -5px;
            z-index: 0;
          }
          .leftTop {
            width: 14.5px;
            height: 14.5px;
            background-color: rgba(151, 235, 255, 1);
            position: absolute;
            right: -5px;
            bottom: -4px;
            z-index: 0;
          }
          img {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            background-color: #ffffff;
            width: 201px;
            border: 2px solid #ffffff;
          }
        }
      }
    }
  }
  .whiteBox {
    background-color: #ffffff;
  }
}
.title-border {
  display: flex;
  display: inline-block;
  margin-right: 4px;
  width: 3px;
  height: 13px;
  border-radius: 4px;
  background-color: #15b7dd;
}
/deep/uni-radio-group {
  display: flex !important;
}
.radios {
  display: flex;
  /deep/.van-radio__label {
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #666666;
    // line-height: 22px;
  }
  /deep/.van-radio__icon {
    height: 14px;
    margin-left: 14px;
  }
  /deep/.van-radio__icon .van-icon {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 1;
  }
}
.uni-list-cell {
  display: flex;
  margin-left: 14.5px;
  /deep/.uni-radio-input {
    border-color: rgba(21, 183, 221, 1);
  }
  div {
    font-size: 14.5px;
    font-weight: 500;
    color: #666666;
    line-height: 21.5px;
  }
}
.uni-input {
  font-size: 12.5px;
  font-weight: 500;
  color: #666666;
  line-height: 21.5px;
}
// 补考
.popup-content {
  background: #ffffff;
  width: 300.5px;
  border-radius: 10px;
  padding: 18px 15px 0;
  position: relative;
  .popup-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin: 0 auto;
      font-size: 20px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 30px;
    }
    .title-noimg {
      font-size: 16px;
      line-height: 30px;
    }
  }
  .btn-inner {
    display: flex;
    justify-content: space-around;
    border-radius: 32px;
    .div {
      display: inline-block;
      border-radius: 32px;
      padding: 23.5px 10px;
      background: #fff;
      .all-btn-middle {
        width: 110.5px;
        height: 35.5px;
        font-size: 14px;
        line-height: 35.5px;
      }
    }
  }
}

// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 22.5px;
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #EEEEEE;
      box-shadow: none;
    }
  }
}
// 加载
.load_box {
  width: 100%;
  margin-top: 40%;
  text-align: center;
  .box_con {
    margin-top: 20px;
    font-size: 14px;
    color: #666666;
  }
}

.popup_button_center {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  &.middle {
    justify-content: center;
  }
  .all-btn-middle {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFangMedium;
    font-weight: 500;
  }
  .btn1 {
    color: #666666;
    background: #EEEEEE;
    box-shadow: none;
  }
}
</style>
