<template>
  <!-- 设置昵称 -->
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      title="设置用户名"
      :fixed="true"
      :placeholder="true"
      left-arrow
      right-text="完成"
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="setNickName"
    />

    <div class="content-box">
      <div class="single-menu-list">
        <div class="single-menu-item">
          <div class="side-left">
            <van-field class="input" :maxlength="20" v-model="val" placeholder="请设置用户名" />
            <van-icon
              name="clear"
              size="18px"
              color="#D8D8D8"
              @click="onClear"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      val: "",
    };
  },
  // 事件处理器
  methods: {
    onInput(e) {
      this.val = e.target.value;
    },
    onClear(e) {
      this.val = "";
      this.$emit("input", "");
    },
    // 修改昵称
    setNickName() {
      if (this.val == '') {
        this.$Toast('请输入用户名');
        return
      }
      
      this.$ajax({
        url: "/hxclass-mobile/user/update?isSysUser=0&userName=" + this.val + '&userId=' + this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.$Toast.success("修改成功");
          // 修改缓存昵称
          let obj = JSON.parse(localStorage.getItem('userInfo'))
          obj.userName = this.val
          this.$store.state.userInfo = obj
          localStorage.setItem('userInfo', JSON.stringify(obj))
          
          let timer = setInterval(() => {
            this.$onReturn(this.$route.query.fromMenu == '1');
            clearInterval(timer);
          }, 1000);
        } else {
          this.$Toast.fail(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$store.state.userInfo.userName) {
      this.val = this.$store.state.userInfo.userName
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.van-cell {
  padding: 0;
  &::after {
    border: 0;
  }
}
/deep/.van-nav-bar__text {
  color: #15b7dd;
}
.content-box {
  padding: 8px 18px;
}

.single-menu-list {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 12px;

  .single-menu-item {
    padding: 12px 0;
    font-family: PingFangMedium;

    .side-left {
      display: flex;
      align-items: center;
    }
  }
}
</style>
