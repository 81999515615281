// 我的
export default [
  { // 个人信息
    path: 'personal',
    component: () => import('../views/mine/personal.vue')
  },
  { // 个人信息
    path: 'myimg',
    component: () => import('../views/mine/myimg.vue')
  },
  { // 设置昵称
    path: 'setNickName',
    component: () => import('../views/mine/setNickName.vue')
  },
  { // 我的收藏
    name: 'collection',
    path: 'collection',
    component: () => import('../views/mine/collection.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  { // 优惠券
    path: 'coupon',
    component: () => import('../views/mine/coupon.vue')
  },
  { // 优惠券可用商品
    path: 'couponGoods',
    component: () => import('../views/mine/couponGoods.vue')
  },
  { // 身份认证
    path: 'identityAuthentication',
    component: () => import('../views/mine/identityAuthentication.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  { // 工作单位
    path: 'workUnit',
    component: () => import('../views/mine/workUnit.vue')
  },
  { // 帮助与反馈
    path: 'feedback',
    component: () => import('../views/mine/feedback.vue')
  },
  { // 常见问题列表
    name: 'problemList',
    path: 'problemList',
    component: () => import('../views/mine/problemList.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  { // 常见问题详情
    path: 'problemDetail',
    component: () => import('../views/mine/problemDetail.vue')
  },
  { // 意见反馈
    path: 'feedbackSubmit',
    component: () => import('../views/mine/feedbackSubmit.vue')
  },
  { // 机构绑定信息
    path: 'institutional',
    component: () => import('../views/mine/institutional.vue')
  }
  
]
