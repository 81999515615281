<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <van-nav-bar
      title="设置"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="content-box">
      <!-- 账号安全  偏好设置 -->
      <div class="single-menu-list">
        <router-link to="/setUp/account" class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">账号安全</span>
            <span class="p side-left-tips">管理手机号及密码</span>
          </div>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link>
        <router-link to="/setUp/interest" class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">偏好设置</span>
            <span class="p side-left-tips">设置我的学习兴趣方向</span>
          </div>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link>
      </div>
      <!-- 流量管理 -->
      <div class="single-menu-list">
        <div class="single-menu-item">
          <span class="p side-left-title">允许移动网络播放视频、音频</span>
          <van-switch 
            class="swich" 
            active-color="#15B3DD" 
            v-model="networkPlayState" 
            @change="changeNetWork"
            size="17" />
        </div>
        <div class="single-menu-item">
          <span class="p side-left-title">个性化消息推送</span>
          <van-switch 
            class="swich" 
            v-model="Personalized" 
            @change="changePersonalized"
            active-color="#15B3DD" 
            size="17" />
        </div>
        <!-- <div class="single-menu-item">
          <span class="p side-left-title">允许移动网络缓存视频、音频</span>
          <van-switch class="swich" active-color="#15B3DD" v-model="isOpen2" size="17" />
        </div> -->
      </div>
      <!-- 关于我们 -->
      <div class="single-menu-list">
        <router-link to="/setUp/aboutUs" class="single-menu-item">
          <span class="p side-left-title">关于我们</span>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link>
      </div> 
      <!-- 清除缓存 -->
      <!-- v-if="isDevelopment" -->
      <div 
        class="single-menu-list" 
        @click="clear()">
        <div class="single-menu-item">
          <span class="p side-left-title">清除缓存</span>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </div>
      </div> 
    </div>

    <div class="box_button">
      <div class="all-btn-bigCircle btn" @click="clearLogin()">退出登录</div>
    </div>
    
    <!-- 提示信息 -->
    <van-popup v-model="showPopup">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">
          {{popupTxt}}
        </div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showPopup=false">取消</span>
          <span class="all-btn-middle" @click="popupSure">确定</span>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
  import {clearCache,FunLogOut,Personalized,onMobileView} from '@/assets/js/NativeWebKit.js';
	export default {
		// 可用组件的哈希表
		components: { },
    // 数据对象
    data() {
      return {
        isDevelopment: false, //是否为开发环境
        networkPlayState: true, //允许移动网络播放
        Personalized: false,
        // isOpen1: false,
        isOpen2: false,

        // 提示信息
        typePopup: 1, //1退出登录 2清除缓存
        showPopup: false,
        popupTxt: '确定退出登录吗？',
      }
    },
    
    created() {
      this.networkPlayState = this.$store.state.userInfo.enableMobileNetwork == 1 ? true : false
      this.Personalized = this.$store.state.userInfo.enablePushMsg == 1 ? true : false
      // 是否为开发环境
      if(process.env.NODE_ENV == 'development') {
        // 开发环境
        this.isDevelopment = true
      } else {
        // 生产环境
        this.isDevelopment = false
      }
    },
    beforeCreate() {
      this.$nextTick(()=>{
        if(!this.$store.state.userInfo.userId){
          return this.$router.push('/login/login');
        }
      })
    },
		// 事件处理器
		methods: {
      // 清除缓存
      clear(){
        this.showPopup = true
        this.typePopup = 2
        this.popupTxt = '确定清除所有缓存吗？'
      },
      // 退出登录
      clearLogin() {
        this.showPopup = true
        this.typePopup = 1
        this.popupTxt = '确定退出登录吗？'
      },
      // 改变网络播放状态
      changeNetWork(value) {
        console.log(value)
        this.$ajax({
          url: '/hxclass-mobile/user/login/edit',
          method: "PUT",
          params: {
            enableMobileNetwork: value?1:0
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            const obj = this.$store.state.userInfo;
            obj.enableMobileNetwork = value?1:0;
            this.$store.commit("updateUserInfo", obj);

            // 流量播放视频交互
            onMobileView(value?1:0);
          }else{
            this.networkPlayState = value? false : true;
          }
        })
        // // 允许
        // if (!!value) {
        //   localStorage.setItem('networkPlayState', 1) 
        // }
        // // 不允许
        // else {
        //   localStorage.setItem('networkPlayState', 0) 
        // }
      },
      // 改变个性化消息推送
      changePersonalized(value) {
        this.$ajax({
          url: '/hxclass-mobile/user/login/edit',
          method: "PUT",
          params: {
            enablePushMsg: value?1:0
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            const obj = this.$store.state.userInfo;
            obj.enablePushMsg = value?1:0;
            this.$store.commit("updateUserInfo", obj);
            Personalized(value?1:0)
          }else {
            this.Personalized = value? false : true;
          }
        })
        // // 允许
        // if (!!value) {
        //   localStorage.setItem('Personalized', 1) 
        //   Personalized(1)
        // }
        // // 不允许
        // else {
        //   localStorage.setItem('Personalized', 0) 
        //   Personalized(0)
        // }
      },
      // 退出登录、清除缓存
      popupSure() {
        this.showPopup = false
        // 退出登录
        if (this.typePopup == 1) {
          this.$store.commit('clearUserInfo')
          // this.$router.push('/home')
          // this.$router.go(0)
          FunLogOut()
          localStorage.setItem('searchInfo', JSON.stringify(''))
          
          // 音频关闭
          if(this.$store.state.audio.src){
            this.$store.commit('updateAudio',{
              src: '',
              currentTime: 0,
              show: false
            })
          }
        }
        // 清除缓存
        if (this.typePopup == 2) {
          clearCache()
          // 清除用户vuex数据
          this.$store.commit('clearUserInfo')
          this.$Toast('清除缓存成功');
          setTimeout(() => {
            // FunLogOut()
            // this.$router.push('/home');
            // 是否允许移动网络播放 1允许 0不允许
            localStorage.setItem('networkPlayState', 1)
            // 协议 1已同意
            localStorage.setItem('firstEntry', 1)
            // 引导页 yes已看过
            localStorage.setItem('storage_key', 'yes')
          },1000)
        }
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./style.less";
  /deep/.van-popup {
    background-color: transparent;
  }
  // 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
  .box_button {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 5px 40px;
    .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }
</style>
