<template>
	<div class="myCourse">
    <!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
			title="模拟考试详情"
			:fixed="true"
			:placeholder="true"
			:style="{
				height: stateOpacity+'px',
      }"
		/>

		<Skeleton v-if="loading"/>
		<div v-else>
			<div >
				<!-- 顶部课程轮播图 -->
				<div class="courseHeader">
					<van-swipe :autoplay="5000" class="swiper">
						<van-swipe-item>
							<div class="swiper-item uni-bg-red">
								<img class="swiperImg" :src="fromObj.webPicture" />
							</div>
						</van-swipe-item>
					</van-swipe>
					<div class="btnGroup">
						<div class="collectBox" @click="showSharePageModel = true">
							<img class="collect" :src="fromObj.isCollection ? collected : collect" />
							<span class="collectText" >{{fromObj.isCollection ? '已收藏' : '收藏'}}</span>
						</div>
					</div>
				</div>
				<!-- 课程标题以及介绍 -->
				<div class="courseExplain">
					<div class="explainUp">
						<div class="left">
							<span class="price" v-if="fromObj.specialPrice"><span class="moneySign">¥</span>{{fromObj.specialPrice.toFixed(2)}}</span>
							<span class="price" v-else>免费</span>
							<span class="originalPrice" v-if="fromObj.isMarket==1">
								<!-- <span>原价：</span> -->
								<span style="text-decoration:line-through;">¥{{fromObj.marketPrice.toFixed(2)}}</span>
							</span>
						</div>
					</div>
					<div class="explainCenter">
						{{fromObj.name}}
					</div>
					<div class="explainDown view-info">
						<TextOverflow :type=2 :height="18" :text="fromObj.appDesc" />
					</div>
				</div>

				<!-- 答题次数，模拟考试时长 -->
				<div class="relatedIntroduction">
					<div class="item">
						<div class="left" style="min-width:60px;">
							答题次数
						</div>
						<div class="right">
							<div class="span">{{fromObj.isExam ? fromObj.examNum + '次' : '无限'}}</div>
						</div>
					</div>
					<div class="item">
						<div class="left" style="min-width:60px;">
							考试时长
						</div>
						<div class="right">
							<div class="span">{{manageDuration(fromObj.duration)}}</div>
						</div>
					</div>
				</div>

				<!-- 优惠卷 -->
				<!-- <div class="couponBox" v-if="couponList.length">
					<div class="item" @click="popUp1">
						<div class="left" style="width:60px;">
							优惠券
						</div>
						<div class="right">
							<div class="rightBox">
								<div class="couponList">
									<div class="downitem" v-for="(item,index) in couponList" :key="index">
										{{item.type == 1?('满'+item.fullPrice+'减'+item.couponPrice):item.type == 3?('满'+item.discountPrice+(item.discountType==1?'件':'元')+item.discount+'折'):''}}
									</div>
								</div>
								<div class="tag">
									领劵
								</div>
							</div>
							<van-icon name="arrow" size="10" color="#999" />
						</div>
					</div>
				</div> -->
				
				<!-- 课程介绍、目录、评论... -->
				<div class="tabsBox"  ref="menu"  :class="{'menuHeight' : isMenuShow}">
					<div style="height:55px" v-if="isMenuShow"></div>
					<div class="vanTabs">
						<van-tabs v-model="active" class="van-tabs-default" :style="{top: isMenuShow ? (navHeight - 1) + 'px' : ''}"  :class="{'menuFixed' : isMenuShow}" >
							<van-tab
								title-inactive-color="#666666"
								title-active-color="#333333"
								v-if="fromObj.appGoodsDesc"
								title="详情介绍">
							</van-tab>
							<van-tab
								title-inactive-color="#666666"
								title-active-color="#333333"
								v-if="fromObj.problemList && fromObj.problemList.length"
								title="常见问题">
							</van-tab>
							<!-- <van-tab
								title-inactive-color="#666666"
								title-active-color="#333333"
								title="评价">
							</van-tab> -->
						</van-tabs>
					</div>
					<div class="tabsContent" :style="'min-height:'+ minHeight +'px'" :class="{'questTab': active==1}">
						<!-- 详情介绍 -->
						<div class="info" v-show="active==0 && fromObj.appGoodsDesc" v-html="fromObj.appGoodsDesc"></div>
						<!-- 常见问题 -->
						<div class="container-box" v-show="(active==1 && fromObj.appGoodsDesc) || (active==0 && !fromObj.appGoodsDesc)">
							<div v-for="(item,index) in fromObj.problemList" :key="index" :class="item.isOpen?'content-boxopen':'content-box'">
                <div class="content-first">
                  <div class="question">
                    问：{{item.name}}
                  </div>
                  <div class="answer" :class="item.isOpen?'':'all-font-ellipsis'" ref="content">
                    答：{{item.context}}
                  </div>
                  <div class="header-closebtn" @click="item.isOpen = !item.isOpen">
                    <!-- {{item.isOpen?'收起':'展开'}} -->
                    <!-- <van-icon :name="item.isOpen?'arrow-up':'arrow-down'" size="10" color="#0888A6"/> -->
                  </div>
                </div>
                <!-- <img class="content-icon" src="@/assets/image/examination/icon-question.png" /> -->
              </div>
						</div>
						<!-- 评价 -->
						<div class="evaluate" v-if="false">
						<!-- <div class="evaluate" v-show="active==2 || (active==0 && !fromObj.appGoodsDesc && (!fromObj.problemList || !fromObj.problemList.length)) || (active==1 && (!fromObj.appGoodsDesc || (!fromObj.problemList || !fromObj.problemList.length)))"> -->
							<div class="ev_tab">
								<van-tabs 
									v-model="evActive" 
									class="van-tabs-circle" 
									swipe-threshold="2"
									@change="onEvaChange"
									sticky>
									<van-tab 
										v-for="(item, index) in evaluateList" 
										:key="index" 
										:title="item"></van-tab>
								</van-tabs>
								<div class="ev_list" v-for="(item, index) in evaluationList" :key="index">
									<div class="list_top">
										<img class="top_photo" v-if="item.userIcon" :src="item.userIcon" alt="">
										<img v-else class="top_photo" src="@/assets/image/my/default_photo.png" />
										<span class="top_phone">{{item.nickName}}</span>
										<img class="top_flower" v-for="i in item.evaluation" :key="i + '-' + item.evaluationId" src="@/assets/image/icon/good_icon.png" alt="好评">
										<!-- <img class="top_flower" v-for="j in (5 - item.evaluation)" :key="j + '+' + item.evaluationId" src="@/assets/image/icon/good1_icon.png" alt="好评"> -->
									</div>
									<div class="list_con">
										<div class="con_main">{{item.comment?item.comment:'此用户没有填写评价'}}</div>
										<div class="con_data">{{timeFormatSeconds(item.createTime.split("-").join('/'))}}</div>
									</div>
									<div class="list_reply" v-show="item.isReply">
										<span class="reply_main">小编回复：</span>
										<span>{{item.reply}}</span>
									</div>
								</div>
								<noMessage v-if="!evaluationList.length" type=1></noMessage>
							</div>
						</div>
					</div>
				</div>
			</div>
      <div class="footBtn">
				<div class="btn_info" v-show="freightObj.code == 2">
					<!-- <span class="info_con">所选商品库存不足，请联系客服确认。</span> -->
					<span class="info_con">此商品不支持在当前地区销售</span>
					<span class="info_btn" @click="showSharePageModel = true">更改地址</span>
				</div>
				<div class="btn_box">
						<div class="collect" @click="showSharePageModel = true">
						<img class="collectImg" src="@/assets/image/selected/2022062610411.png" />
						<span>客服</span>
					</div>
					<div class="freediving" @click="showSharePageModel = true">
						<img class="freedivingImg" src="@/assets/image/selected/2022062610412.png" />
						<span>购物车</span>
						<span class="num" v-if="shopCarList.length">{{getNum(shopCarList)}}</span>
					</div>
					<!-- ishaveopcity -->
					<div :class="fromObj.isBuy?'ishave':'btnGroup'">
						<!-- {{ fromObj.isBuy? '已购买' : ''}} -->
						<div @click="showSharePageModel = true" class="join"  v-if="fromObj.isBuy">
							<span class="buyText" >去考试</span>
						</div>
						<div class="shoppingCart" @click="showSharePageModel = true" v-if="!fromObj.isBuy">
							<span class="buyText" >加入购物车</span>
						</div>
						<div class="purchase" @click="showSharePageModel = true" v-if="!fromObj.isBuy">
							<span class="buyText">立即购买</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 优惠券弹窗 -->
			<n-transition ref="trainPop1" speed="ease-in-out" :height="415" :maskVal="0.35">
				<div class="windowCoupons">
					<div class="navBar">
						<div class="center">领取优惠券</div>
						<div class="right" @click="showSharePageModel = true">
							<van-icon name="cross" color="#666" size="20" />
						</div>
					</div>
					<div class="coupon">
						<div class="couponItem" v-for="(item,index) in couponList" :key="index">
							<div class="left">
								<template v-if="item.type == 1">
									<span class="price"><span>¥</span>{{item.couponPrice}}</span>
									<span class="condition">满{{item.fullPrice}}元可用</span>
								</template>
								<template v-if="item.type == 3">
									<span class="price">{{item.discount}}<span>折</span></span>
									<span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
								</template>
								<span class="rightTop"></span>
								<span class="rightBottom"></span>
								<img v-show="false" src="@/assets/image/my/overdue.png" />
							</div>
							<div class="right">
								<span class="title">{{item.name}}</span>
								<span class="time" v-if="item.times == 2">{{item.startTime.split('-').join('.')}}-{{item.endTime.split('-').join('.')}}</span>
                <span class="time" v-if="item.times == 1">不限使用时间</span>
								<span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':'指定商品'}}]</span>
								<span class="btn" @click="showSharePageModel = true">{{!!item.noReceive?'可用商品':'点击领取'}}</span>
								<img v-show="!!item.noReceive" src="@/assets/image/my/Received.png" />
							</div>
						</div>
					</div>
				</div>
			</n-transition>
			<ShpCarModel ref="shpCarModel" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarFlag="shopCarFlag" :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
		</div>
		<van-dialog @confirm="confirm" v-model="show" show-cancel-button cancel-button-color="#666666" confirm-button-color="#15B7DD" confirm-button-text="去认证">
			<template slot="title">
				<img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
				<span>提示信息</span>
			</template>
			<span class="nocertified">购买本课程需要完成身份认证</span>
		</van-dialog>
		<a class="gotopBox"  v-if="showGoTop <= 0" @click="goTop()">
			<van-icon name="arrow-up" color="#fff" size="20px" />
		</a>
		<!-- 考试信息弹窗 -->
    <van-popup v-model="examMessagemodel" round>
      <div class="popup-content">
        <!-- <van-icon name="cross" @click="examMessagemodel = false" size="38" class="close-icon" color="#fff"/> -->
        <!-- <img class="cover-img" src="@/assets/image/study/banner_study.png" /> -->
        <div class="title">{{ popList.name }}</div>
        <div class="specs">
          <span
            >及格分数：<a>{{ popList.passingScore }}分</a></span
          >
          <span class="line"></span>
          <span
            >时长：<a>{{ popList.time }}分钟</a></span
          >
        </div>
        <!-- 提示 -->
        <div class="tips">
          <img class="icon" src="../../assets/image/icon/20220127041502.png" />
          <span class="font"
            >注：考试过程中退出当前页面或关闭华夏云课堂均按交卷处理</span
          >
        </div>
        <!-- 开始考试按钮 -->
        <div class="btn-inner">
          <div class="div" @click="showSharePageModel = true">
            <span class="all-btn-middle">开始考试</span>
          </div>
        </div>
        <van-icon
          name="close"
          @click="showSharePageModel = true"
          size="38"
          class="close-icon"
          color="#fff"
        />
      </div>
    </van-popup>

		<!-- 分享提示弹框 -->
		<SharePageModel @closeSharePageModel="closeSharePageModel()" :show="showSharePageModel"/>
		<!-- 分享顶部提示 -->
		<TopAlert />
	</div>
</template>

<script>
import SharePageModel from '@/components/sharePageModel.vue';
import TopAlert from '@/components/share/topAlert.vue';
import nTransition from '@/components/n-transition/n-transition.vue'
import noMessage from "@/components/nomessage.vue";
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
import Skeleton from '@/components/Skeleton/index.vue';
import {CallCustomer} from '@/assets/js/NativeWebKit.js';
import TextOverflow from '@/components/TextOverflow.vue'

export default {
	// 可用组件的哈希表
	components: { TopAlert, SharePageModel,noMessage,ShpCarModel,nTransition,Skeleton,TextOverflow},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			showSharePageModel: false,
			showGoTop: 0,
			stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
			navHeight: 0, //导航栏高度
			minHeight: 350,
			loading:false,
      isUnfold: false, //是否展开
			shopCarFlag:true, // 购物车弹框状态
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
			shopList:[],
			
			couponList:[],
			paddingStyle:{
				'box-sizing' : 'border-box',
				'padding-bottom': '50px'
			},
			paddingStyle1:{
				'box-sizing' : 'border-box',
				'padding-bottom': '90px'
			},
			fromObj: {
				couponPrice:0,
				originalPrice:0,
			},
      evActive:0,
      configId: '', // 模拟考试id
			productId: '', // 商品id
			FirstCollect: 0, // 首次收藏状态
			collected:require('@/assets/image/selected/collected.svg'),
      collect:require('@/assets/image/selected/collect.svg'),
			evaluateList: [],  // 评价头部中好评菜单
			evaluationList: [], // 评价记录
			bookType: ['课程', '图书', '教具', '模拟考试', '模拟考试', '延期', '补考', '直播', '其他'], // 商品类型

			classIndex: 0,
      active: 0,
      list: [
        {
          title: '图书教材'
        }, {
          title: '教学用具'
        }
      ],
			harvestAddress: this.$store.state.userInfo.harvestAddress,   // 收获地址
			freightObj: {
				code: 1
			},  // 运费
			activeName: '',
			indicatorDots: true,
			autoplay: false,
			interval: 2000,
			duration: 500,
			current: 0,
			swiperCurrent: 0,
			swiperList:4,
			list: [
				{ title: '详情介绍' }, { title: '常见问题' }, { title: '评价'}
			],

			appraiseForm:{  // 评价
				productId: '',
				evaluation: '',  // 1.差评，2.中评，3.好评
				pageNum: 1,
				pageSize: 100000,
			},
      appraiseData: [],
			show:false, // 认证弹框
			isMenuShow: false,
			popList: {},
			examMessagemodel: false
		}
	},
	// 事件处理器
	methods: {
		goTop() {
			document.getElementsByClassName('myCourse')[0].scrollTop = 0
		},
		
		closeSharePageModel() {
			this.showSharePageModel = false;
		},

		// 页面滚动监听
    FunHandleScroll() {
      // 信息条渐变值
      let pageScrollTop = document.getElementsByClassName('myCourse')[0].scrollTop
			this.stateOpacity = pageScrollTop/100;
			let pageOffsetHeight = document.getElementsByClassName("myCourse")[0].offsetHeight;
			this.showGoTop = pageOffsetHeight - pageScrollTop;

			// 导航栏添加样式
			document.getElementsByClassName('van-nav-bar')[0].style.opacity = this.stateOpacity

			let menuTop = 0
			// 监听切换菜单是否到顶
			menuTop = this.$refs.menu ? this.$refs.menu.getBoundingClientRect().top : 300
			if(menuTop <= this.navHeight){
				this.isMenuShow = true
			} else {
				this.isMenuShow = false
			}
    },
		getNum(e){
			let num = 0;
			e.map(item=>{
				num += item.count;
			})
			if (num > 99) {
				num = '99+'
			}
			return num;
		},
		// 处理文字
    manageInfo(con, index) {
			con = con.replace(/<[^>]+>/g,'')
			return con
      // let conNew = ''
      // if (con && con.length < 55) {
      //   return con
      // } else {
      //   conNew = con.substring(0,55)+'...'
      //   return conNew
      // }
    },
    // 展开收起
    changeUnfold(index) {
      this.$set(this,'isUnfold',!this.isUnfold)
    },
     // 唤起微信客服
		CallCustomer,
		goback(){
			// console.log(11111111)
			this.$onReturn(this.$route.query.fromMenu == '1')
		},
		// 去考试
		onExam() {
			this.examMessagemodel = true;
			this.popList = this.fromObj;
			let a = this.fromObj.duration.substring(0, 2);
			let b = this.fromObj.duration.substring(3, 5);
			let time = a * 60 + b * 1;
			this.popList.time = time;
		},
		// 加入购物车
		joinShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.fromObj.isBuy){
				return this.$Toast('该考试已购买，不可重复购买');
			}
			this.$ajax({
				url:'/hxclass-mobile/exam/mock/config/mall-purchase-verifi',
				method:'get',
				params:{
					configId:this.fromObj.configId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.$ajax({
						url:'/hxclass-mobile/app-mall/app/shop/use',
						method:"get",
						params:{
							openTimeId:'',
							productId:this.fromObj.productArchivesId,
							type:4, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
						}
					}).then(res=>{
						if(res.code == 200){
							return this.$Toast('该考试已加购，不可重复购买');
						}else{
							this.$ajax({
								url:'/hxclass-mobile/app-mall/shop/save',
								method:'post',
								params:{
									count:1, // 数量
									couponCode:'', // 优惠劵编码
									openTimeId:'', // 开班设置id
									price:this.fromObj.marketPrice, // 价格  优惠价格-还没有计算优惠券
									productId:this.fromObj.productArchivesId, // 商品id
									type:4, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
									userNo:this.$store.state.userInfo.userId, // 用户
								}
							}).then(res=>{
								if(res.code == 200 && res.success){
									this.getShopCarList();
									return this.$Toast('添加购物车成功');
								}else{
									this.$Toast(res.message)
								}
							})
						}
					})
				}else{
					this.$Toast(res.message);
				}
			})
			
		},
		// 无认证信息弹框
		confirm(){
			this.$router.push('/mine/identityAuthentication')
		},
		// 立即购买
		buyNow(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			this.$ajax({
				url:'/hxclass-mobile/exam/mock/config/mall-purchase-verifi',
				method:'get',
				params:{
					configId:this.fromObj.configId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					// this.$ajax({
					// 	url:'/hxclass-mobile/app-mall/app/shop/use',
					// 	method:"get",
					// 	params:{
					// 		openTimeId:'',
					// 		productId:this.fromObj.productArchivesId,
					// 		type:4, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
					// 	}
					// }).then(res=>{
						// if(res.code == 200){
						if(this.fromObj.isBuy){
							return this.$Toast('该考试已购买，不可重复购买');
						}
						// }else{
							let list = [];
							list.push({
								productId:this.fromObj.productArchivesId, // 商品id
								count:1, // 数量")
								openTimeId:'', // 班级id
								type:4, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
								province:'', // 省
								city:'',// 市
								noGift:0, // 是否为赠品 0否1是t
								productList:[]
							})
							this.$store.commit('updatelist',list);
							this.$store.commit('updateType',1);
          		this.$store.commit('updateShortInvoiceId',null);
							this.$store.commit('updateInvoiceId',null);
							this.$store.commit('updateAddress',null);
        			this.$store.commit('updateCouponList',null);
							this.$router.push('/order/confirmOrder')
						// }
					// })
				}else{
					this.$Toast(res.message);
				}
			})
		},
		// 领券
		popUp1 () {
			this.$refs['trainPop1'].show()
		},
		close1(){
			this.$refs['trainPop1'].hide()
		},
		// 打开购物车
		openShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.shopCarList.length){
        this.shopCarFlag = !this.shopCarFlag;
      }else{
        this.$Toast('请添加商品');
      }
		},
		SwiperTab(e){
			this.current =Number(e.target.current)
		},
		selected: function(gameName) {
			this.activeName = gameName
		},
		// 收藏/取消
    onCollection(isCollection){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			// 收藏列表是否刷新
			this.isCollectionLoad()
      this.fromObj.isCollection = this.fromObj.isCollection ? 0 : 1
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if(this.FirstCollect != this.fromObj.isCollection){
          this.$ajax({
            url:"/hxclass-mobile/collection/app?key=" + this.fromObj.isCollection + '&objectId=' + this.configId +'&type=4' ,
            method: 'POST',
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.FirstCollect = this.fromObj.isCollection
            } else {
              this.fromObj.isCollection = this.fromObj.isCollection ? 0 : 1
              this.FirstCollect = this.fromObj.isCollection
            }
          })
        }
      }, 500);
		},
		// 收藏列表是否刷新
		isCollectionLoad() {
			let collectionObj = {}
			let isLoad = true
			if (sessionStorage.getItem('collectionObj')) {
				collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
			}
			collectionObj.isLoad = isLoad
			sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
		},
		// 查询评价数量
		getAppraiseCount(){
      this.$ajax({
        url: "/hxclass-mobile/order-comment/count",
        params: {
					productId: this.productId,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluateList = [
						'全部(' + res.data.all + ')',
						'好评(' + res.data.good + ')',
						'中评(' + res.data.medium + ')',
						'差评(' + res.data.bad + ')',
					]
        }
      });
		},
		timeFormatSeconds(time){
			//传入日期//例：2020-10-27T14:36:23
			var d = time ? new Date(time) : new Date();
			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			var day = d.getDate();
			var hours = d.getHours();
			var min = d.getMinutes();
			var seconds = d.getSeconds();
	
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (hours < 0) hours = '0' + hours;
			if (min < 10) min = '0' + min;
			if (seconds < 10) seconds = '0' + seconds;
	
			return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
		},
		// 查询评价列表
		getAppraiseList(){
      this.$ajax({
        url: "/hxclass-mobile/order-comment/",
        params: this.appraiseForm
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluationList = res.data.records
        }
      });
		},
		// 评价头部菜单切换
		onEvaChange(e){
			if (e==1) {
				this.appraiseForm.evaluation = 3
			} else if (e==2) {
				this.appraiseForm.evaluation = 2
			} else if (e==3) {
				this.appraiseForm.evaluation = 1
			} else if (e==0) {
				this.appraiseForm.evaluation = ''
			}
			this.getAppraiseList()
		},
		// 查询详情
		getDetail(){
			this.loading = true
			let url = '';
			let params = null;
			if(this.$route.query.id){
				url = "/hxclass-mobile/exam/mock/config/get";
				params = {configId: this.$route.query.id}
			}else if(this.$route.query.productId){
				url = "/hxclass-mobile/exam/mock/config/product-get";
				params = {productId: this.$route.query.productId}
			}
      this.$ajax({
        url: url,
        params
      }).then((res) => {
				this.loading = false
        if (res.code == 200 && res.success) {
					this.configId = res.data.configId;
					this.fromObj = res.data
					this.FirstCollect = res.data.collect  // 记录第一次收藏状态

					if(this.harvestAddress){ // 计算运费
						this.sumFreightCharge()
					}
					
					var reg = /[/]/g;
          this.desc=this.fromObj.appDesc.replace(reg," ");
          this.fromObj.appDesc=this.fromObj.appDesc.replace(reg,"<br>");

          this.productId =  res.data.productArchivesId
          // 查询优惠券
          this.getCoupons()

          setTimeout(()=>{
            this.appraiseForm.productId = this.productId
            this.getAppraiseList()
            this.getAppraiseCount()
					},1000)
					this.$nextTick(()=>{
						const hei = document.getElementsByClassName('tabsContent')[0].offsetHeight
						const MaxHeight = parseInt(window.innerHeight) - 55 - this.navHeight
						if(hei < window.innerHeight){
							this.minHeight = hei > MaxHeight ? MaxHeight : hei
						}else{
							this.minHeight = parseInt(window.innerHeight) - 55 - this.navHeight
						}
					})
        }
      });
		},
		// 选择收获地址
		onSelectAddress(){
			this.$router.push({path:'/address/addressList?isSelect=true'});
		},
		// 计算运费
		sumFreightCharge(){
			this.$ajax({
        url: "/hxclass-mobile/app-mall/app/product-freight",
        params: {
          province: this.harvestAddress.provice,
          city: this.harvestAddress.city,
          count: 1,
          weight: this.fromObj.weight,
          freightId: this.fromObj.freightId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.freightObj = res.data
        }
      });
		},

		toTest(x) {
      this.$router.push(
        "/examination/testPaper?type=1&userPaperId=" +
          x.paperId +
          "&configId=" +
          x.configId +
          "&duration=" +
          x.duration +
          "&paperId=" +
          x.paperId
      );
      this.examMessagemodel = false;
		},
		
		// 立即购买
		onBuy(){
			if(this.freightObj.code == 2){
				this.$Toast('此商品不支持在当前地区销售');
				return
			}
		},
		// 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
		},
		// 获取优惠卷
    getCoupons(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type: 1,
          page: 1,
          size: 100,
          productIds: this.productId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.couponList = res.data.records;
        }
      })
		},
		selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },
    // 领取优惠券/查看可用商品
    couponReceive(e){
      if(!e.noReceive){
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/coupon-receive',
          method:'post',
          params:{
            couponId:e.couponId,
            receiver:this.$store.state.userInfo.userId,
            receiverTime:this.selectTime(),
            state:1
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            e.noReceive = 1;
						this.$refs.shpCarModel.pageNum = 1;
						this.$refs.shpCarModel.getCoupons();
          }
        })
      }else{
        if(e.scope == 1){
					this.$router.push('/shop/shoppingMall');
          this.close1();
				}else{
					this.$router.push('/mine/couponGoods?couponId='+e.couponId);
          this.close1();
				}
      }
		},
		// 考试时长处理
		manageDuration(con) {
			let time = ''
			let hour = con.split(':')[0]
			let minute = con.split(':')[1]
			let second = con.split(':')[2]
			if (hour !== '00') {
				if (hour[0] == '0') {
					time += hour.substring(1, hour.length)+'小时'
				} else {
					time += hour+'小时'
				}
			}
			if (minute !== '00') {
				time += minute+'分钟'
			}
			if (second !== '00') {
				time += second+'秒'
			}
			return time
		},
	},
	// 生命周期-实例创建完成后调用
	created () {
		this.getDetail()
		if(this.$store.state.userInfo.userId){
			this.getShopCarList();
		}
		// 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
	 },
	// 生命周期-实例挂载后调用
	mounted () {
		this.$nextTick(() => {
			// 导航栏高度
			this.navHeight = document.getElementsByClassName('van-nav-bar')[0].offsetHeight
			document.getElementsByClassName('myCourse')[0].addEventListener("scroll", this.FunHandleScroll);
      this.FunHandleScroll();
    })
	},
	// 生命周期-实例销毁离开后调用
	destroyed () { 
		if (document.getElementsByClassName('myCourse')[0]) {
      document.getElementsByClassName('myCourse')[0].removeEventListener("scroll", this.FunHandleScroll);
    }
	},
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {
		active(){
			// 列表新内容添加后，把最小高度更新
			this.$nextTick(()=>{
				const hei = document.getElementsByClassName('tabsContent')[0].offsetHeight
				const MaxHeight = parseInt(window.innerHeight) - 55 - this.navHeight
				if(hei < window.innerHeight){
					this.minHeight = hei > MaxHeight ? MaxHeight : hei
				}else{
					this.minHeight = parseInt(window.innerHeight) - 55 - this.navHeight
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
  .gotopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .box_con {
      font-size: 14px;
      color: #fff;
      margin-top: px;
    }
  }
	// tab
	// /deep/.van-tabs__nav {
	// 	background-color: transparent;
	// 	.van-tab--active {
	// 		font-weight: 600;
	// 		background-image: url(~@/assets/image/examination/certificate_icon1.png);
	// 		background-repeat: no-repeat;
	// 		background-size: 40px;
	// 		background-position: center 26px;
	// 	}
	// 	.van-tab {
	// 		font-size: 16px;
	// 	}
	// 	.van-tabs__line {
	// 		display: none;
	// 	}
	// }
	/deep/.textOver{
		clear: #666;
		line-height: 18px;
	}
	/deep/.link{
		color: #333;
		line-height: 18px;
	}
	/deep/ .overflow-hidden{
		max-height: 18px;
		
	}

	/deep/.van-dialog__header{
		padding: 16px 20px;
		line-height: 1;
		text-align: left;
		display: flex;
		align-items: center;
		img{
			width: 18px;
			height: 18px;
			margin-right: 5px;
		}
		span{
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			line-height: 27px;
		}
	}
	/deep/.van-dialog__footer{
		margin-top: 20px;
		/deep/.van-button--default{
			width: 122px !important;
			background-color: #EEEEEE !important;
		}
	}
	.nocertified{
		font-size: 16px;
		color: #333333;
		line-height: 24px;
		display: block;
		padding-left: 20px;
	}
	.myCourse{
		// 信息条
		.box_status {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background-color: #fff;
			opacity: 0;
			z-index: 100;
		}
		.courseHeader{
			// height: 265px;
			// border-radius: 0 0 17px 17px;
			background: #fff;
			position: relative;
			.btnGroup{
				position: absolute;
				right: 18px;
				// top: 0;
				display: flex;
				bottom: -34px;
				align-items: center;
				.collectBox{
					text-align: center;
					min-width: 40px;
					.collect{
						width: 32px;
						height: 32px;
					}
					.collectText{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}
				.tryBox{
					text-align: center;
					margin-right: 20px;
					.tryImg{
						width: 32px;
						height: 32px;
					}
					.tryspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}

			}
			.back{
				position: fixed;
				// left: 16px;
				// top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.share{
				position: absolute;
				right: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
			}
			.swiper{
				// height: 265px;
				.swiperImg{
					background-repeat: no-repeat;
					object-fit: cover;
					width: 100%;
					// height: 265px;
					// border-radius: 0 0 17px 17px;
				}
				/deep/ .uni-swiper-dot-active {
					width: 16px !important;
					height: 4px !important;
				    background-color: #fff !important;
					border-radius: 2px;
				}
				/deep/ .uni-swiper-dot {
				    display: inline-block;
				    width: 4px;
				    height: 4px;
				    cursor: pointer;
				    background: rgba(255, 255, 255, 0.6);
				    // border-radius: 0%;
				}
			}
			/* 指示器样式 */
			.u-indicator-item-number {
				width: 20px;
				padding: 7.5px;
				line-height: 1;
				background-color: rgba(0, 0, 0, 0.35);
				border-radius: 50px;
				font-size: 11px;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				right: 20px;
				bottom: 15px;
				text-align: center;
				letter-spacing: 1.5px;
			}
		}
		.courseExplain{
			padding: 16px 18px;
			// height: 116.5px;
			background: #fff;
			// margin-top: 8px;
			.explainUp{
				display: flex;
				.left{
					flex: 1;
					line-height: 19px;
					align-items: center;
					display: flex;
					.label{
						width: 42.5px;
						height: 19px;
						padding: 2px 6px;
						display: inline-block;
						background: url(~@/assets/image/selected/tag.svg) no-repeat;
						background-size:100% 100%;
						font-size: 10px;
						font-weight: 400;
						color: #F0A300;
						text-align: center;
						margin-right: 9px;
						align-items: center;
					}
					.price{
						font-size: 20px;
						font-weight: 400;
						color: #FF7A00;
    				font-family: PingFang SC-Regular, PingFang SC;
						line-height: 13px;
					}
					.originalPrice{
						font-size: 10px;
						font-weight: 400;
						color: #999999;
						// font-family: PingFangMedium;
						font-family: PingFangMedium;
						margin-left: 10px;
						margin-top: 7px;
					}
				}
				.right{
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}
			.explainCenter{
				font-size: 20px;
				font-weight: 500;
				color: #333333;
				line-height: 28px;    
				font-family: PingFangMedium;
				margin-top: 12px;
			}
			.explainDown{
        margin-top: 12px;
				margin-bottom: 8px;
					font-family: PingFangMedium;

				.fload{
					// display: -webkit-box;
					word-break: break-all;
					// -webkit-box-orient: vertical;
					// /* 要显示多少行就改变line-clamp的数据,此处折叠起来显示一行*/
					// -webkit-line-clamp: 3;
					// overflow: hidden;
					// text-overflow: ellipsis;
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
					line-height: 15px;
					/deep/img{
						max-width: 100%;
					}
				}
			}
		}
		.couponBox{
			padding: 18px;
			background: #fff;
			font-family: PingFangMedium;
			margin-top: 8px;
			.item{
				display: flex;
				.left{
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					line-height: 20px;
				}
				.right{
					flex: 1;
					// margin-right: 12px;
					display: flex;
					align-items: center;
					.rightBox{
						display: flex;
						justify-content: space-between;
						flex: 1;
						padding-right: 12px;
						.couponList{
							height: 20px;
							overflow: hidden;
							flex: 1;
							display: flex;
							flex-wrap: wrap;
						}
						.tag{
							width: 28px;
							height: 16px;
							background: rgba(#F93C00, 0.1);
							border-radius: 2px;
							font-size: 10px;
							font-weight: bold;
							color: #F93C00;
							line-height: 16px;
							text-align: center;
							padding: 1px 4px;
						}
						.downitem{
							margin: 1px 10px 5px;
							font-size: 10px;
							font-weight: 400;
							color: #F93C00;
							line-height: 18px;
							height: 18px;
							text-align: center;
							position: relative;
							padding: 0 5px;
							background-image: url(~@/assets/image/selected/20220610-145020.png);
							background-position: center;
							background-size: 1px 18px;
							&::before,&::after{
								content: '';
								width: 4px;
								display: block;
								position: absolute;
								top: 0;
								left: -4px;
    						height: 100%;
								background-image: url(~@/assets/image/selected/left.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 4px 18px;
								transform: rotate(180deg);
							}
							&::after{
								content: '';
								left: auto;
								right: -4px;
								transform: rotate(0deg);
							}
						}
					}
				}
			}
		}
		.windowCoupons{
			background-color: #fff;
			border-radius: 24px 24px 0 0;
			padding: 0 5px;
			.navBar{
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 18px 0;
				.center{
				font-family: PingFangMedium;
					font-size: 18px;
					line-height: 25px;
					color: #333;
				}
				.right{
					position: absolute;
					right: 11px;
				}
			}
		}
		.coupon{
			padding: 0 13px;
			padding-bottom: 25px;
			overflow-y: scroll;
			height: 320px;
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			border-radius: 6px;
			// border: 1px solid #F93C00;
			display: flex;
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
				flex-direction: column;
				text-align: center;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
        width: 90px;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-bottom: 1px solid #EC6C01;
				// 	// border-top: 1px solid #F7F7F9;
				// 	border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -1px;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #fff;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-top: 1px solid #EC6C01;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	bottom: -1px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #fff;
				// }
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 9;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
		.combination{
			padding: 18px;
			padding-bottom: 0;
			background: #fff;
			margin-top: 8px;
			.combinationTitle{
				span{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					font-weight: bold;
				}
			}
			.combinationBox{
				margin-top: 22px;
				.item{
					display: flex;
					padding-bottom: 16px;
					.courseImg{
						width: 100px;
						height: 70px;
					}
					.right{
						padding: 2.5px 0 0 11px;
						.title{
							display: flex;
							font-size: 14.5px;
							font-weight: 400;
							color: #333333;
							line-height: 25.5px;
						}
						.price{
							display: flex;
							font-size: 10px;
							font-weight: 400;
							color: #999999;
							line-height: 14.5px;
							margin-top: 6px;
						}
						.identification{
							font-size: 11px;
							font-weight: 400;
							color: #1E97B4;
							line-height: 15.5px;
						}
					}
				}
			}

		}
		.relatedIntroduction{
			    margin-top: 8px;
			padding: 12px  19px;
			background: #fff;
			// margin-top: 8px;
			.item{
				display: flex;
				width: 100%;
				padding-bottom: 7px;
				// border-bottom: 1px solid #F5F5F5;
				font-family:PingFangMedium;
				align-items: center;
				.left{
					font-size: 13px;
					font-weight: 400;
					color: #8B8B8D;
					line-height: 20px;
					text-align: left;
				}
				.right{
					flex: 1;
					margin-left: 12px;
					font-size: 13px;
					font-weight: 400;
					color: #333333;
					font-family: PingFang SC-Regular, PingFang SC;
					.span{
						flex: 1;
						color: #333;
					}
					.copy{
						font-size: 13px;
						font-weight: 400;
						color: #15B7DD;
						line-height: 22px;
					}
				}
			}
			.item:not(:first-child) {
				padding-top: 7px;
			}
			.item:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
		.details{
			// padding: 18px;
			background: #fff;
			margin-top: 8px;
			.detailsTitle{
				.up{
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
					padding: 16px 0 0 0;
					text-align: center;
					image{
						width: 56px;
						height: 8px;
						vertical-align: middle;
					}
				}
				.down{
					padding: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
				}
			}
		}
		.tabsBox{
			margin-top: 8px;
			background: #fff;
			/deep/.van-tabs__nav{
				justify-content: space-around;
			}
			/deep/.van-tab {
					margin-left: 0; 
					padding: 0 16px;
			}
			/deep/.van-tab:first-child {
			    margin-left: 0;
			}
			/deep/.van-tabs-default .van-tab--active{
				// background-size: 40px 13px;
				background-position: center 30px;
 			}
			.menuHeight{
  			transition: all 0.3s;
				height: 55px;
			}
			.tabsContent{
				box-sizing: border-box;
				padding-bottom: 55px;
				// background: #F7F7F9;
				&.questTab {
				  background-color: #fff;
					// background: transparent;
				}
				.catalogue{
					.catalogueList{
						padding: 10px 0;
					}
				}
				.info{
					padding: 0 18px 18px;
					background-color: #fff;
					/deep/img{
						max-width: 100%;
					}
        }
        .container-box {
          overflow: hidden;
					background: #fff;
					// padding: 18px 0;
          .content-box {
            position: relative;
            margin: 12.5px 18px;
            // height: 120px;
            background: #FFFFFF;
            border-radius: 9px 9px 9px 9px;
            overflow: hidden;
          }
					.content-box:nth-child(1){
						margin-top: 0px;
					}

          .content-boxopen {
            position: relative;
            margin: 12.5px 18px;
            background: #FFFFFF;
            border-radius: 9px 9px 9px 9px;
            overflow: hidden;
          }

          .content-first {
						position: relative;
						padding-bottom: 12px;
						border-bottom: 1px solid #F5F5F5;
            // margin: 11px 12.5px 18px;
            .question {
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 400;
							color: #333333;
							margin-bottom: 4px;
              line-height: 21px;
            }

            .answer {
              font-size: 13px;
              font-family: PingFangMedium;
							font-weight: 400;
              color: #333;
              line-height: 20px;
            }

            .all-font-ellipsis {
              -webkit-line-clamp: 20;
            }

            .header-closebtn {
              position: absolute;
              right: 0px;
              bottom: -17px;
              font-size: 12.5px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #0888A6;
              line-height: 19px;
              z-index: 2;
            }
          }

          .content-icon {
            position: absolute;
            right: 0;
            bottom: -9px;
            width: 53px;
            height: 53px;
            z-index: 1;
          }
        }
				.evaluate {
					padding: 0 0 20px;
					background: #ffffff;
					.ev_list {
						border-bottom: 1px solid #F0F0F0;
						margin: 0 18px 24px 18px;
						padding-bottom: 20px;
						&:last-child {
							border: 0;
						}
						.list_top {
							display: flex;
							align-items: center;
							.top_photo {
								width: 32px;
								height: 32px;
								border-radius: 50%;
							}
							.top_phone {
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFangMedium;
								font-weight: 500;
								color: #333333;
								margin-left: 8px;
							}
							.top_flower {
								width: 16px;
								margin-left: 15px;
							}
						}
						.list_con {
							padding-left: 38px;
							.con_main {
								font-size: 15px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #666666;
								line-height: 26px;
							}
							.con_data {
								font-size: 12px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #999999;
								margin-top: 12px;
							}
						}
						.list_reply {
							font-size: 15px;
							font-family: PingFangMedium;
							font-weight: 400;
							color: #666666;
							line-height: 26px;
							margin-left: 16px;
							padding: 16px;
							background: #F5F7FA;
							border-radius: 4px;
							margin-top: 8px;
							.reply_main {
								color: #333333;
							}
						}
					}
				}
			}
		}
		.footBtn{
			width: 100%;
			position: fixed;
			bottom: 0;
			z-index: 10;
			overflow: hidden;
			z-index: 2000;
			box-shadow: 0px -1px 1px 1px rgba(153,153,153,0.3);
			.btn_info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 40px;
				line-height: 40px;
				padding: 0 16px;
				background: #FFF4E1;
				.info_con {
					font-size: 14px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #333333;
				}
				.info_btn {
					height: 26px;
					line-height: 26px;
					font-size: 14px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #FFFFFF;
					padding: 0 12px;
					background: #FFCC62;
					border-radius: 4px;
					border: 1px solid #FFCA5B;
				}
			}
			.btn_box {
				display: flex;
				box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				background: #FFFFFF;
			}
			.collect{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				margin-left: 5px;
				padding: 8px 0;
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
				}
				.collectImg{
					height: 18px;
				}
			}
			.freediving{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				padding: 8px 0;
        position: relative;
				.freedivingImg{
					height: 18px;
				}
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
        }
				.num{
          position: absolute;
          font-style: normal;
          text-align: center;
          // width: 14px;
          // height: 14px;
					min-width: 14px;
          border-radius: 999px;
					padding: 0 3px;
          top: 0px;
          right: 4px;
          background: #EC6C01;
          font-size: 10px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 14px;
        }
			}
			.btnGroup{
				flex: 4;
				display: flex;
				background: url(~@/assets/image/selected/20220626-112349.png) no-repeat;
				background-size:100% 100%;
				height: 40px;
				line-height: 40px;
				margin: 2px 7.5px 0 7.5px;
				.join{
					width: 100%;
					text-align: center;
				}
				.shoppingCart{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyText{
						font-size: 14px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
				.purchase{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyText{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.moneySign{
			font-size: 18px;
			font-weight: 400;
		}
	}
	/deep/.tabBlock{
		padding: 0 15px;
		.tab{
			justify-content: space-between;
		}
	}
	// 评价导航栏
	/deep/.van-tabs-circle {
		padding-left: 18px;
		.van-tabs__nav--line {
			height: 28px;
		}
		.van-tab {
			height: 28px;
			line-height: 28px;
			font-size: 12px;
			color: #666666;
			border-radius: 13px;
			background: rgba(21, 183, 221, 0.1);
			margin-right: 24px;
			padding: 4px 12px !important;
		  font-weight: 500;
		}
		.van-tab--active {
			color: #fff;
			background: #15B7DD;
		}
		.van-tabs__line {
			display: none;
		}
	}
	.ishave{
		flex: 4;
		display: flex;
		background: #0CA4C8;
		height: 40px;
		line-height: 40px;
		margin: 2px 7.5px 0 7.5px;
		justify-content: center;
		border-radius: 20px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFangMedium;
		font-weight: 500;
		color: #FFFFFF;
	}
	.join{
		width: 100%;
		text-align: center;
	}
	.ishaveopcity{
		opacity: 0.5;
	}
	.view-info{
		position: relative;
		margin-top: 10px;
		font-size: 10px;
		font-family: PingFangMedium;
		font-weight: 400;
		color: #666666;
		line-height: 15px;
		.info_tag {
			position: absolute;
			right: 0;
			bottom: 3px;
			// font-size: 13px;
			font-family: PingFangMedium;
			font-weight: 500;
			color: #0888A6;
			width: 45px;
			text-align: right;
			background: #FFFFFF;
		}
	}


	/deep/.van-tabs-default{
	  height: 55px;
		.van-tab{
	   	height: 55px;
		}
	}
	/deep/.van-tabs-default .van-tab--active{
 	   background-position: center 30px;
	 }
	 /deep/.van-tabs__nav{
			.van-tab{
				flex: none;
				padding: 0 4px;
				margin-left: 20px;
				&:first-child{
					margin-left: 14px;
				}
			}
		}
		/deep/.van-tabs--line{
			.van-tabs__wrap{
				height: 55px;
			}
		}

		/* 遮罩内容 */
  .uni-popup {
    z-index: 1000;
	}
	/deep/.van-popup {
		overflow-y: initial;
	}

  .popup-content {
    background: #ffffff;
    width: 290px;
    border-radius: 10px;
    padding: 20px 20px 0;
    position: relative;

    .close-icon {
      position: absolute;
      right: 150px;
      top: 350px;
    }

    .cover-img {
      width: 100%;
      border-radius: 10px;
    }

    .title {
      // width: calc(100% - 50px);
      // margin: 0 auto;
      margin-top: 7px;
      font-size: 22px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }

    .specs {
      margin-top: 18px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      text-align: center;
      a {
        font-weight: 500;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #666666;
        margin: 0 8px;
        border-radius: 1px;
      }
    }

    .tips {
      display: flex;
      align-items: center;
      margin-top: 18px;

      .icon {
        width: 20px;
        margin-right: 6px;
      }

      .font {
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
      }
    }

    .btn-inner {
      border-radius: 32px;
      text-align: center;
      height: 60px;
      .div {
        display: inline-block;
        position: relative;
        top: 20px;
        border-radius: 32px;
        padding: 10px;
        background: #fff;

        .all-btn-middle {
          width: 143px;
          height: 47px;
          line-height: 47px;
        }
      }
    }
  }
</style>
