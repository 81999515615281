<template>
	<div class="myCourse">
		<!-- 导航栏 -->
		<div class="box_nav">
			<van-nav-bar
				v-if="video!=1"
				class="nav-circular"
				title="课程详情"
				:fixed="true"
				:placeholder="true"
				:style="{
					height: stateOpacity+'px',
				}"
			/>
		</div>

		<Skeleton v-if="loadingBg"/>
		<div v-else id="boxCon">
			<div style="padding-bottom: 50px;" v-if="courseDetail">
				<!-- 是视频播放页面 -->
				<template v-if="video==1">
					<div class="courseVideo">
						<div class="courseName">{{courseDetail.courseName}}</div>
						<div class="progress">
							<span v-show="courseDetail.have == 1 && courseDetail.studyProgress != 0" class="Learned">已学{{courseDetail.studyProgress ? (courseDetail.studyProgress*100).toFixed(0) : 0}} %</span>
							<span v-show="courseDetail.have == 1 && courseDetail.studyProgress == 0" class="Learned">观看不足1%</span>
							<span class="classHour">{{courseDetail.categoryName}}｜视频数量：{{courseDetail.classHour}}个</span>
						</div>
						<!-- 课程有效期 -->
						<div class="videoDates" v-if="courseDetail.validDayType==2">
							<img src="@/assets/image/20220506-141113.png" alt="">
							<span>长期</span>
						</div>
						<div class="videoDates" v-else-if="courseDetail.startTime">
							<img src="@/assets/image/20220506-141113.png" alt="">
							<span>{{courseDetail.startTime}}</span>
							<span> - </span>
							<span>{{courseDetail.endTime}}</span>
						</div>
						<!-- 班期 -->
						<div class="videoDates" v-if="courseDetail.className">
							<img src="@/assets/image/20220506-141106.png" alt="">
							<span>{{courseDetail.className}}</span>
							<span>（{{courseDetail.openStartTime}}</span>
							<span> - </span>
							<span>{{courseDetail.openEndTime}}）</span>
						</div>
						<!-- 课程介绍 -->
						<TextOverflow :type=1 style="margin-top:5px" :height="18" :text="courseDetail.introduction" />
					</div>
				</template>

				<!-- 不是视频播放页面的时候显示 -->
				<template v-else>
					<!-- 顶部课程轮播图 -->
					<div class="courseHeader">
						<van-swipe @change="SwiperTab" :autoplay="5000" class="swiper">
							<van-swipe-item>
								<div class="swiper-item uni-bg-red">
									<img class="swiperImg" :src="courseDetail.picture" />
								</div>
							</van-swipe-item>
						</van-swipe>

						<div class="btnGroup">
							<div class="tryBox" v-if="!ishave && !!courseDetail.isSetFreeTrial && !courseDetail.isFree" @click="showSharePageModel = true">
								<img class="tryImg" src="@/assets/image/selected/tryIcon.svg" />
								<span class="tryspan">试看</span>
							</div>
							<div class="collectBox" @click="showSharePageModel = true">
								<img class="collect" v-show="courseDetail.collect == 1" src="@/assets/image/selected/collected.svg" />
								<span class="collectspan" v-show="courseDetail.collect == 1">已收藏</span>
								<img class="collect" v-show="courseDetail.collect == 0" src="@/assets/image/selected/collect.svg" />
								<span class="collectspan" v-show="courseDetail.collect == 0">收藏</span>
							</div>
						</div>
						<!-- <block class="number">
							<div class="u-indicator-item-number">{{ current + 1 }}/{{ swiperList }}</div>
						</block> -->
					</div>
					<!-- 课程标题以及介绍 -->
					<div class="courseExplain">
						<div class="explainUp">
							<div class="left">
								<span class="label" v-if="false">套餐</span>
								<span class="price"><span class="moneySign" v-if="courseDetail.couponPrice">¥</span>{{courseDetail.couponPrice?courseDetail.couponPrice.toFixed(2):'免费'}}</span>
								<span class="originalPrice" v-if="courseDetail.original">
									<!--  v-if="!!courseDetail.original" -->
									<!-- <span style="font-weight: 400;">原价：</span> -->
									<span style="text-decoration:line-through;font-family: PingFangMedium;">
										<template v-if="courseDetail.originalPrice">¥</template>
										{{courseDetail.originalPrice?courseDetail.originalPrice.toFixed(2):'免费'}}
									</span>
								</span>
							</div>
						</div>
						<div class="explainCenter">
							{{courseDetail.courseName}}
						</div>
						<div class="explainDown">
							<div class="courseType">
								<div class="left">
									{{courseDetail.categoryName}}｜视频数量：{{courseDetail.classHour}}个
								</div>
								<div class="right">
									<span>{{courseDetail.salesVolume}}人已学习</span>
								</div>
							</div>
							<!-- 课程介绍 -->
							<TextOverflow :type=1 :height="18" :text="courseDetail.introduction" ref="TextOver" />
						</div>
					</div>
					<!-- 套餐组合 -->
					<div class="combination" v-if="false">
						<div class="combinationTitle">
							<span class="line"></span>
							<span>套餐组合</span>
						</div>
						<div class="combinationBox">
							<div class="item" v-for="(items,i) in 2" :key="i">
								<div class="left">
									<img class="courseImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng15e093fc9f889d38975bf98b1d484440e6581999ca1f3fb89ec3f86acbfe377a" />
								</div>
								<div class="right">
									<span class="title">孤独症康复教育上岗培训课程</span>
									<span class="identification">上岗课程丨共209节课</span>
									<span class="price">原价：￥1324.00</span>
								</div>
							</div>
						</div>
					</div>
					<div class="relatedIntroduction" v-if="courseDetail.setOpenClass">
						<div class="item">
							<div class="left">选择班级</div>
							<div class="right" style="display: flex;align-items:center;" @click="showSharePageModel = true">
								<div class="span">
									{{classItem.className?classItem.className:'请选择班级'}}
									<p style="align-items:center;" v-if="classItem && classItem.address">
										<van-icon name="location-o" />
										{{classItem.provinceTxt}}{{classItem.cityTxt}}{{classItem.districtTxt}}{{classItem.address}}
									</p>
								</div>
								<van-icon name="arrow" size="10" color="#333" />
							</div>
						</div>
					</div>
					<div class="relatedIntroduction" v-if="courseDetail.courseCategoryId == 3">
						<div class="item">
							<div class="left">上课地点</div>
							<div class="right" style="display: flex;align-items:center;">
								<div class="span">
									{{courseDetail.province}}{{courseDetail.city}}{{courseDetail.district}}{{courseDetail.address}}
								</div>
							</div>
						</div>
					</div>
					<!-- 相关证书、图书教具、附赠 -->
					<div class="relatedIntroduction" v-if="(courseDetail.relevantCertificateList && courseDetail.relevantCertificateList.length) || (giftList && giftList.length && courseDetail.couponPrice)">
						<router-link @click.native="session()" :to="{name:'certificatesDetail',params:{relevantCertificateList:courseDetail.relevantCertificateList}}" class="item" v-if="courseDetail.relevantCertificateList && courseDetail.relevantCertificateList.length">
							<div class="left">
								相关证书
							</div>
							<div class="right" style="display: flex;align-items:center;">
								<div class="span">{{courseDetail.relevantCertificateList[0].name}}</div>
								<van-icon name="arrow" size="10" color="#333" />
							</div>
						</router-link>
						<div class="item" v-if="giftList.length && courseDetail.couponPrice">
							<div class="left">
								附赠
							</div>
							<div class="right" style="align-items:center;">
								<div @click="showSharePageModel = true" class="shoplist style" v-for="(item,index) in giftList" :key="index">
									<div class="span">{{item.name}}</div>
									<van-icon name="arrow" size="10" color="#333" />
								</div>
							</div>
						</div>
					</div>
					<!-- 图书教具 -->
					<div class="relatedIntroduction" v-if="productList.length && courseDetail.couponPrice">
						<div class="item">
							<p class="left produceTitle">相关商品</p>
							<div class="right">
								<van-icon class="vanIcon" v-if="productList.length>1 && produceShopstatus" @click="showSharePageModel = true" name="arrow-up"  size="10" color="#333" />
								<van-icon class="vanIcon" v-if="productList.length>1 && !produceShopstatus" @click="showSharePageModel = true" name="arrow-down"  size="10" color="#333" />
								<div class="productcontent" :class="produceShopstatus?'':'produceOne'" v-show="produceShopstatus || index === 0" v-for="(item,index) in productList" :key="index">
									<p class="productTitle" @click="showSharePageModel = true">{{item.name}}</p>
									<div class="changenum">
										<p class="price" @click="showSharePageModel = true" v-if="item.couponPrice"><b class="pricesign">￥</b>{{item.couponPrice.toFixed(2)}} <span v-if="!!item.original">￥{{item.originalPrice.toFixed(2)}}</span></p>
										<van-stepper v-model="item.num" :min="0" theme="round" button-size="20" disable-input />
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>

				<!-- 讲师信息部分 -->
				<div class="teacher">
					<div class="title">
						<div class="left">授课老师</div>
						<div class="rightBox">
							<div class="scroll-div">
								<div class="scroll-item" v-for="(item,index) in courseDetail.teacherList" :key="index">
									<div class="item">
										<div class="portrait" @click="FunLeavePlay('/curriculum/teacher?courseId='+ courseDetail.courseId + '&type=' + (video==1))" v-if="video==1">
											<img class="portraitImg" :src="item.roundPicture" />
										</div>
										<div class="portrait" v-else @click="goTeacherList(courseDetail.courseId)">
											<img class="portraitImg" :src="item.roundPicture" />
										</div>
										<div class="name">
											{{item.name}}
										</div>
									</div>
								</div>
							</div>
							<template v-if="FunJudgDevice() == 'ios' || FunJudgDevice() == 'android'">
								<div class="right" @click="showSharePageModel = true" v-if="video==1">
									<van-icon name="arrow" size="10" color="#333" />
								</div>
								<div class="right" v-else @click="goTeacherList(courseDetail.courseId)">
									<van-icon name="arrow" size="10" color="#333" />
								</div>
							</template>
							<template v-else>
								<router-link :to="'/curriculum/teacher?courseId='+ courseDetail.courseId + '&type=' + (video==1)">
									<div class="right">
										<van-icon name="arrow" size="10" color="#333" />
									</div>
								</router-link>
							</template>
						</div>
					</div>
				</div>
				<!-- 课程介绍、目录、评论... -->
				<div class="tabsBox"  ref="menu"  :class="{'menuHeight' : isMenuShow}">
					<!-- 占位 -->
					<div style="height:55px" v-if="isMenuShow"></div>
					<van-tabs v-model="active" class="van-tabs-default tabsBox_tab" :style="{top: isMenuShow ? (navHeight - 1) + 'px' : ''}"  :class="{'menuFixed' : isMenuShow}">
						<van-tab title="详情介绍" name="详情介绍"></van-tab>
						<van-tab title="目录" name="目录" v-if="courseDetail.hasSectionFlag || courseDetail.hasVideoFlag"></van-tab>
						<van-tab title="学习指导" v-if="courseDetail.studentInstructions" name="学习指导"></van-tab>
						<van-tab title="报名流程" v-if="courseDetail.registrationGuide" name="报名流程"></van-tab>
						<van-tab title="评价" name="评价" v-if="false && !courseDetail.isFree"></van-tab>
					</van-tabs>
					<div class="tabsContent"  :style="'min-height:'+ minHeight +'px'" :class="{'tabbg': active=='目录','tabbg4':active=='报名流程'}">
						<div class="info" v-show="active == '详情介绍'">
							<div v-html="courseDetail.detail"></div>
						</div>
						<div class="catalogue" v-show="active == '目录'">
							<div class="catalogueList">
								<forTree  :tree="tree" :have="courseDetail.have" :isOrder="courseDetail.watchByCatalogueOrder ? true : false" :isFree="courseDetail.isFree" :video="parseInt(video)" :courseId="parseInt(courseDetail.courseId)" :sectionId="parseInt(sectionId)" @nodeClick="treeClick" />
							</div>
						</div>
						<div class="info" v-show="active == '学习指导'" v-html="courseDetail.studentInstructions">
						</div>
						<div class="" v-show="active == '报名流程'" v-html="courseDetail.registrationGuide">
						</div>
						<div class="evaluate" v-show="active == '评价'">
							<div class="ev_tab">
								<van-tabs 
									v-model="evActive" 
									class="van-tabs-circle" 
									swipe-threshold="2"
									@change="onEvaChange"
									sticky>
									<van-tab 
										v-for="(item, index) in evaluateList" 
										:key="index" 
										:title="item"></van-tab>
								</van-tabs>
								<div class="ev_list" v-for="(item, index) in evaluationList" :key="index">
									<div class="list_top">
										<img class="top_photo" v-if="item.userIcon" :src="item.userIcon" alt="">
										<img v-else class="top_photo" src="@/assets/image/my/default_photo.png" />
										<span class="top_phone">{{item.nickName}}</span>
										<img class="top_flower" v-for="i in item.evaluation" :key="i + '-' + item.evaluationId" src="@/assets/image/icon/good_icon.png" alt="好评">
									</div>
									<div class="list_con">
										<div class="con_main">{{item.comment?item.comment:'此用户没有填写评价'}}</div>
										<div class="con_data">{{timeFormatSeconds(item.createTime.split("-").join('/'))}}</div>
									</div>
									<div class="list_reply" v-show="item.isReply">
										<span class="reply_main">小编回复：</span>
										<span>{{item.reply}}</span>
									</div>
								</div>
								<no-message v-if="!evaluationList.length" type=1></no-message>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 底部操作栏 - 不是视频播放页的时候显示 -->
			<div class="footBtn" v-if="video!=1 && courseDetail">
				<div class="collect" @click="showSharePageModel = true">
					<img class="collectImg" src="@/assets/image/selected/2022062610411.png" />
					<span>客服</span>
				</div>
				<div class="freediving" @click="showSharePageModel = true">
					<img class="freedivingImg" src="@/assets/image/selected/2022062610412.png" />
					<span>购物车</span>
          <span class="num" v-show="shopCarList.length">{{getNum(shopCarList)}}</span>
				</div>

				<div :class="!courseDetail.isFree?courseDetail.have?'ishave ishaveopcity':'btnGroup':courseDetail.have?'ishave ishaveopcity':'ishave'">
					{{ !courseDetail.isFree ? (courseDetail.have ? courseDetail.hasVideoFlag?'已购买':'已报名' : '') : (courseDetail.have ? '已加入学习' : '')}}
					<span class="join" v-show="!courseDetail.have && courseDetail.isFree" @click="showSharePageModel = true">加入学习</span>
					<div class="shoppingCart" @click="showSharePageModel = true" v-show="!courseDetail.have && !courseDetail.isFree">
						<span class="buyspan">加入购物车</span>
					</div>
					<div class="purchase" v-show="!courseDetail.have && !courseDetail.isFree" @click="showSharePageModel = true">
						<span class="buyspan">立即购买</span>
					</div>
				</div>
			</div>

			<ShpCarModel ref="shpCarModel" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarFlag="shopCarFlag" :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
			<!-- 优惠券弹窗 -->
			<n-transition ref="trainPop1" class="couponModel" speed="ease-in-out" :height="415" :maskVal="0.35">
				<div class="windowCoupons">
					<div class="navBar">
						<div class="center">领取优惠券</div>
						<div class="right" @click="showSharePageModel = true">
							<van-icon name="cross" color="#666" size="20" />
						</div>
					</div>
					<div class="coupon">
						<van-list
							v-model="loading"
							:finished="finished"
							finished-text="没有更多了"
							@load="onLoad"
						>
							<div class="couponItem" v-for="(item,index) in couponList" :key="index">
								<div class="left">
									<template v-if="item.type == 1">
										<span class="price"><span>¥</span>{{item.couponPrice}}</span>
										<span class="condition">满{{item.fullPrice}}元可用</span>
									</template>
									<template v-if="item.type == 3">
										<span class="price">{{item.discount}}<span>折</span></span>
										<span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
									</template>
									<span class="rightTop"></span>
									<span class="rightBottom"></span>
									<img v-show="false" src="@/assets/image/my/overdue.png" />
								</div>
								<div class="right">
									<span class="title">{{item.name}}</span>
									<span class="time" v-if="item.times == 2">{{item.startTime.split('-').join('.')}}-{{item.endTime.split('-').join('.')}}</span>
                	<span class="time" v-if="item.times == 1">不限使用时间</span>
									<span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':'指定商品'}}]</span>
									<span class="btn" @click="showSharePageModel = true">{{!!item.noReceive?'可用商品':'点击领取'}}</span>
									<img v-show="!!item.noReceive" src="@/assets/image/my/Received.png" />
								</div>
							</div>
						</van-list>
					</div>
				</div>
			</n-transition>

			<!-- 请选择培训场次 -->
			<n-transition ref="trainPop" speed="ease-in-out" :height="612" :maskVal="0.35">
				<div class="popContentBox">
					<div class="popBox">
						<div class="titleBox">
							<span class="title">请选择培训场次</span>
							<van-icon name="cross" size="20" color="#999" @click="showSharePageModel = true" />
						</div>
						<div class="popContent">
							<div class="popItem" v-for="(item,index) in classList" @click="showSharePageModel = true" :key="index" :class="{pick: openTimeId == item.openTimeId,disabled:!item.currentNumber}">
								<div class="up">
									<div class="left">
										<span>{{item.className}}</span>
									</div>
									<div class="right">
										<span>剩余：<span class="num">{{item.currentNumber?item.currentNumber:0}}</span></span>
									</div>
								</div>
								<div class="center">
									<span>{{item.startTime+' - '+item.endTime}}</span>
								</div>
								<div class="down" v-if="item.provinceTxt">
									<span><van-icon style="margin-right:5px;" :color="openTimeId == item.openTimeId?'#ffffff':'#666666'" name="location-o" />{{item.provinceTxt}}{{item.cityTxt}}{{item.districtTxt}}{{item.address}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="popbutton">
						<span class="btn all-btn-bigCircle" @click="showSharePageModel = true">确 定</span>
					</div>
				</div>
			</n-transition>
			<!-- 提示信息 -->
			<van-popup v-model="show" :round="true">
				<div class="box_popup">
					<div class="popup_title">
						<van-icon name="warning-o" color="#EB3C31" size="26" />
						<span class="title_con">提示信息</span>
					</div>
					<div class="popup_con">购买本课程需要完成身份认证</div>
					<div class="popup_button">
						<span class="all-btn-middle btn1" @click="showSharePageModel = true">取消</span>
						<span class="all-btn-middle btn2" @click="showSharePageModel = true">去认证</span>
					</div>
				</div>
			</van-popup>
		</div>
		<van-popup v-model="onPopup" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showSharePageModel = true">下次再报</span>
          <span class="all-btn-middle btn2" @click="showSharePageModel = true">提交证书</span>
        </div>
      </div>
    	</van-popup>
		<van-popup v-model="onPopupOk" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="showSharePageModel = true">我知道了</span>
        </div>
      </div>
    	</van-popup>
			<a class="gotopBox"  v-if="showGoTop <= 0" @click="goTop()">
        <van-icon name="arrow-up" color="#fff" size="20px" />
      </a>
			<!-- 分享提示弹框 -->
			<SharePageModel @closeSharePageModel="closeSharePageModel()" :show="showSharePageModel"/>
			
			<!-- 分享顶部提示 -->
			<TopAlert />
	</div>
</template>

<script>
import SharePageModel from '@/components/sharePageModel.vue';
import TopAlert from '@/components/share/topAlert.vue';
import nTransition from '@/components/n-transition/n-transition.vue'
import forTree from '@/components/forTree/index.vue'
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
import Skeleton from '@/components/Skeleton/index.vue';
import {FunPlay,FunLeavePlay,CallCustomer, FunGetSectionId} from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice } from '@/unit/fun.js'
import { courseVideo } from '@/unit/courseVideo.js'
import TextOverflow from '@/components/TextOverflow.vue'
export default {
	// 可用组件的哈希表
	components: { TopAlert, nTransition, forTree, ShpCarModel,Skeleton,TextOverflow, SharePageModel},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			produceShopstatus: false,
			showSharePageModel: false,
			showGoTop: 0,
			stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
			navHeight: 0, //导航栏高度
			onceRecord: true, //是否第一次记录
			minHeight: 400,
			tabMinHeight: 0,
      // 是否展示所有文本内容
      showTotal: false,
      // 显示展开还是收起
			message:'',
			onPopup:false,
			onPopupOk:false,
			loadingBg:false,
			collected:require('@/assets/image/selected/collected.svg'),
			collect:require('@/assets/image/selected/collect.svg'),
      FirstCollect: 0, // 首次收藏状态
			video: 1,
      loading: false,
      finished: false,
			shopCarFlag:true, // 购物车弹框状态
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
      shopList:[],
			classItem:null,
			classItemId:'',
			handleItem:null,
      openTimeId:'', // 选中 班期id
			autoplay: false,
			duration: 500,
			current: 0,
			swiperCurrent: 0,
			swiperList:4,
			productList:[], // 相关商品
			giftList:[], // 赠品
			couponList:[], // 优惠券列表
			list: [{
					title: '课程介绍'
				}, {
					title: '目录'
				},{
					title: '学习指导'
				}, {
					title: '报名流程'
				},{
					title: '评价'
				}
			],
			evActive: 0,
			evaluation: '',  // 1.差评，2.中评，3.好评
			evaluateList: [],  // 评价头部中好评菜单
			evaluationList: [], // 评价列表
			classList:[],
			courseDetail:{
				couponPrice: 0
			},
			active: 0,
			pageNum:1,
			pageSize:10,
			total:0,
			coupon:{
				pageNum:1,
				pageSize:10,
				total:0
			},
			comment:{
				pageNum:1,
				pageSize:10,
				total:0
			},
			tree:[],
			video: false,
			ishave:null, // true 拥有  false 未拥有
			show:false, // 未认证弹框
			courseId: null, // 当前播放的课程id
			sectionId: null, // 当前播放的课程章节id
			isStopUpdate: true,
			isMenuShow: false
		}
	},
	// 事件处理器
	methods: {
		closeSharePageModel() {
			this.showSharePageModel = false;
		},
		CallCustomer,
		FunLeavePlay,
		FunJudgDevice,
		// 判断是否需要存储
		session(){
			let arr = this.productList.filter(item=>{
				return item.num != 1;
			})
			if(arr.length){
				sessionStorage.setItem('couseproductList',JSON.stringify(this.productList));
			}
		},
		goTop() {
			document.getElementsByClassName('myCourse')[0].scrollTop = 0
		},
		// 页面滚动监听
    	FunHandleScroll() {
      	// 信息条渐变值
				this.$nextTick(() => {
					let pageScrollTop = document.getElementsByClassName('myCourse')[0].scrollTop;
					this.stateOpacity = pageScrollTop/100;
					let pageOffsetHeight = document.getElementsByClassName("myCourse")[0].offsetHeight;
					this.showGoTop = pageOffsetHeight - pageScrollTop;
					if(this.video != 1){
						// 导航栏添加样式
						document.getElementsByClassName('van-nav-bar')[0].style.opacity = this.stateOpacity
					}


					let menuTop = 0
					// 监听切换菜单是否到顶
					menuTop = this.$refs.menu ? this.$refs.menu.getBoundingClientRect().top : 300
					if(menuTop <= this.navHeight){
						this.isMenuShow = true
						if(this.onceRecord){
							this.topRecord = document.getElementsByClassName('myCourse')[0].scrollTop
						}
						this.onceRecord = false
					} else {
						this.isMenuShow = false
						this.topRecord = undefined
						this.onceRecord = true
					}
				})
    	},
		getNum(e){
			let num = 0;
			e.map(item=>{
				num += item.count;
			})
			if (num > 99) {
				num = '99+'
			}
			return num;
		},
		// 跳转详情页面
   		goDetail(e) {
   		  	// 教育课程
   		  	if (e.productType == 1) {
						this.$router.push('/curriculum/course?id=' + e.dataId);
   		  	}
   		  	// // 图书教具
   		  	else if (e.productType == 2 || e.productType == 3) {
   		  	  this.$router.push('/curriculum/BookDdetails?id=' + e.productId)
						this.session();
   		  	}
   		  	// // 模拟考试
   		  	else if (e.productType == 4) {
   		  	  this.$router.push('/examination/examDetail?id=' + e.dataId)
						this.session();
   		  	}
   		},
		// 试看
		onFreeTrial(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}

			this.$ajax({
				url: '/hxclass-mobile/course/course-free-trial-section/' + this.courseId
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.courseId = this.courseDetail.courseId
					this.sectionId = res.data.sectionId
					// courseVideo({
					// 	courseId: this.courseId,
					// 	sectionId: this.sectionId
					// })
					FunPlay({
						courseId: this.courseId,
						currStudySectionId: this.sectionId,
						isFast: 1,
						currStudyFileId: res.data.fileId,
						currPcfg: res.data.pcfg,
						currStudySeconds: 0,
						SectionTest: res.data.isFreeTrial,
						isHaveSelfTest: 0,
						freeTrialSeconds: res.data.freeTrialSeconds,
						title: res.data.sectionName,
						isOrder: 1,
					})
				}
			})
		},
		// 立即购买 e：true（商品，赠品）   false（商品，赠品，相关商品）
		buyNow(e){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.courseDetail.setOpenClass && !this.classList?.length){
				return this.$alert('暂无可选择班级期数无法进行购买');
			}
			if(!!this.courseDetail.userAuthentication && !this.$store.state.userInfo.certified){
				return this.show = true;
			}
			this.$ajax({
				url:'/hxclass-mobile/course/check-can-buy',
				method:'get',
				params:{
					courseId:this.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					if(this.classList?.length && !this.classItemId){
						return this.popUp();
					}
					let list = [];
					let productList = [];
					if(e){
						this.giftList.map(item=>{
							productList.push({
								productId:item.productId, // 商品id
								count:1, // 数量")
								openTimeId:'', // 班级id
								type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
								province:'', // 省
								city:'',// 市
								noGift:1, // 是否为赠品 0否1是t
							})
						})
						list.push({
							productId:this.courseDetail.productId, // 商品id
							count:1, // 数量")
							openTimeId:this.classItemId, // 班级id
							type:1, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
							province:'', // 省
							city:'',// 市
							noGift:0, // 是否为赠品 0否1是t
							productList:productList
						})
					}else{
						this.giftList.map(item=>{
							productList.push({
								productId:item.productId, // 商品id
								count:1, // 数量")
								openTimeId:'', // 班级id
								type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
								province:'', // 省
								city:'',// 市
								noGift:1, // 是否为赠品 0否1是t
							})
						})
						list.push({
							productId:this.courseDetail.productId, // 商品id
							count:1, // 数量")
							openTimeId:this.classItemId, // 班级id
							type:1, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
							province:'', // 省
							city:'',// 市
							noGift:0, // 是否为赠品 0否1是t
							productList:productList
						})
						// 相关商品
						this.productList.map(item=>{
							if(item.num>0){
								list.push({
									productId:item.productId, // 商品id
									count:item.num, // 数量")
									openTimeId:'', // 班级id
									type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
									province:'', // 省
									city:'',// 市
									noGift:0, // 是否为赠品 0否1是t
								})
							}
						})
					}
					this.$store.commit('updatelist',list);
					this.$store.commit('updateType',1);
					this.$store.commit('updateInvoiceId',null);
					this.$store.commit('updateShortInvoiceId',null);
					this.$store.commit('updateAddress',null);
        	this.$store.commit('updateCouponList',null);
					this.$router.push('/order/confirmOrder')
				}else if(res.code == 1000){ // 需购买课程
					this.onPopupOk = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
				}else if(res.code == 1001){ // 需提交证书
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
				}else if(res.code == 1002){ // 已提交证书待审核
					this.onPopupOk = true;
					this.message = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
				}else if(res.code == 1003){ // 审核被驳回
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
				}else if(res.code == 1004){ // 需购买课程且提交证书
					this.onPopup = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述课程证书，请提交您的证书信息，后台审核通过后方可报名。';
				}else if(res.code == 1005){ // 课程无可用班期
					this.$Toast(res.message);
				}
			})
			// if(!!this.courseDetail.setBuyLimit){
			// 	return this.$alert('有购买限制');
			// }
			// this.$ajax({
			// 	url:'/hxclass-mobile/app-mall/app/shop/use',
			// 	method:"get",
			// 	params:{
			// 		openTimeId:this.classItemId,
			// 		productId:this.courseDetail.productId,
			// 		type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
			// 	}
			// }).then(res=>{
			// 	if(res.code == 200){
			// 		return this.$alert('该课程已加购，不可重复购买');
			// 	}else{
					
			// 	}
			// })
		},
		// 无认证信息弹框
		confirm(){
			this.$router.push('/mine/identityAuthentication')
		},
		// FunPlay,
		conproductList(){
			// console.log(this.productList)
		},
		// 更新用户观看进度
		onChangeProgress(obj){
			if(this.isFreeTrial == 1){
				return false
			}
			if(typeof obj != 'object'){
				obj = JSON.parse(obj)
			}
			this.$ajax({
				url: '/hxclass-mobile/course/change-progress',
				method: 'put',
				params: {
					courseId: obj.courseId,
					currentSeconds: parseInt(obj.currentSeconds),
					isWatch: obj.isWatch,
					sectionId: obj.sectionId,
					userId: this.$store.state.userInfo.userId
				}
			}).then(res=>{

				// 更新进度
				function fors(arr){
					if (arr.length !== 0) {
						arr.forEach((item) => {
							if((item.sectionId == obj.sectionId)  && Number(item.studyStatus) < 3){
								item.watchProgress = parseInt(obj.currentSeconds) / item.duration
							}
							if (item.childList) {
								item.childList = fors(item.childList);
							}
						});
					}
					return arr;
				}
				this.tree = fors(this.tree)
        // this.getCourseTree();
				// console.log('更新成功：',res)
			}).catch(err=>{
				// console.log('失败',err)
			})
		},

		calculateText() {
			let abstractHeight = window.getComputedStyle(this.$refs.deta).height.replace('px', '');
			// console.log(abstractHeight)
			if (parseInt(abstractHeight) * 2 > 63) {
				this.changeButton = true;
				this.showTotal = false;
			} else {
				this.changeButton = false;
				this.showTotal = true;
			}
		},

		// 加入购物车
		async addCarList(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.courseDetail.setOpenClass && !this.classList?.length){
				return this.$alert('暂无可选择班级期数无法进行购买');
			}
			this.isinShopcar()
		},
		// 批量加入购物车判断
		mollJoinCar(){
			let record = [];
			if(this.productList && this.productList.length){
				this.productList.map(item=>{
					if(item.num>0){
						record.push({
							count:item.num, // 数量
							couponCode:'', // 优惠劵编码
							openTimeId:'', // 开班设置id
							price:item.couponPrice, // 价格  优惠价格-还没有计算优惠券
							productId:item.productId, // 商品id
							type:item.productType, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
							userNo:this.$store.state.userInfo.userId, // 用户
						})
					}
				})
			}
			if(record.length){
				// 相关商品批量加入购物车
				this.joinCarAll(record);
			}
		},
		// 批量加入购物车
		joinCarAll(e){
			this.$ajax({
				url:'/hxclass-mobile/app-mall/app/batch/save',
				method:'post',
				params:e
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.getShopCarList();
				}else{
					this.$alert(res.message)
				}
			})
		},
		timeFormatSeconds(time){
			//传入日期//例：2020-10-27T14:36:23
			var d = time ? new Date((time).replace(/-/g,'/')) : new Date();
			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			var day = d.getDate();
			var hours = d.getHours();
			var min = d.getMinutes();
			var seconds = d.getSeconds();
	
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (hours < 0) hours = '0' + hours;
			if (min < 10) min = '0' + min;
			if (seconds < 10) seconds = '0' + seconds;
			return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
		},
		// 打开购物车
		openShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.shopCarList.length){
        this.shopCarFlag = !this.shopCarFlag;
      }else{
        this.$alert('请添加商品');
      }
		},
		// 判断商品是否存在购物车
		isinShopcar(){
			if(!this.$store.state.userInfo.userId){
				this.$router.push('/login/login');
				return false
			}
			if(!!this.courseDetail.userAuthentication && !this.$store.state.userInfo.certified){
				this.show = true;
				return false
			}
			// if(!!this.courseDetail.setBuyLimit){
			// 	return this.$alert('有购买限制');
			// }
			this.$ajax({
				url:'/hxclass-mobile/course/check-can-buy',
				method:'get',
				params:{
					courseId:this.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					if(this.classList?.length && !this.classItemId){
						this.popUp();
						return false;
					}
					this.$ajax({
						url:'/hxclass-mobile/app-mall/app/shop/use',
						method:"get",
						params:{
							openTimeId:this.classItemId,
							productId:this.courseDetail.productId,
							type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
						}
					}).then(res=>{
						if(res.code == 200){
							this.$alert('该课程已加购，不可重复购买')
							return false;
						}else{
							this.$ajax({
								url:'/hxclass-mobile/app-mall/shop/save',
								method:'post',
								params:{
									count:1, // 数量
									couponCode:'', // 优惠劵编码
									openTimeId:this.classItemId, // 开班设置id
									price:this.courseDetail.couponPrice, // 价格  优惠价格-还没有计算优惠券
									productId:this.courseDetail.productId, // 商品id
									type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
									userNo:this.$store.state.userInfo.userId, // 用户
								}
							}).then(res=>{
								if(res.code == 200 && res.success){
									this.getShopCarList();
									this.$alert('添加购物车成功');
									this.mollJoinCar();
									return true;
								}else{
									this.$alert(res.message)
									return false;
								}
							})
						}
					})
				}else if(res.code == 1000){ // 需购买课程
					this.onPopupOk = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
					return false;
				}else if(res.code == 1001){ // 需提交证书
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
					return false;
				}else if(res.code == 1002){ // 已提交证书待审核
					this.onPopupOk = true;
					this.message = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
					return false;
				}else if(res.code == 1003){ // 审核被驳回
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
					return false;
				}else if(res.code == 1004){ // 需购买课程且提交证书
					this.onPopup = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述课程证书，请提交您的证书信息，后台审核通过后方可报名。';
					return false;
				}else if(res.code == 1005){ // 课程无可用班期
					this.$Toast(res.message);
					return false;
				}
			})			
		},
		// 判断用户是否拥有商品
		isHaveShop(e){
			this.$ajax({
				url:'/hxclass-mobile/app-mall/order/product/have',
				method:'get',
				params:{
					productId:e, // 商品id
					productType:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.ishave = res.data;
				}else{
					this.$alert(res.message);
				}
			})
		},
		// 选择班期
		joinShopCar(e){
			// console.log(e)
			this.classItem = e;
			this.classItemId = this.openTimeId;
			this.handleItem = null;
			this.openTimeId = '';
			this.close();
		},
		treeClick(e){
			return this.showSharePageModel = true;
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}

			// 点击的章节id跟播放的id同样时不做处理
			if((e.sectionId == this.sectionId) && this.video == 1){return}

			this.isStopUpdate = false
			setTimeout(()=>{
				this.isStopUpdate = true
			},2000)

			if(this.courseDetail.have == 0 && e.isFreeTrial == 1 && this.courseDetail.isFree == 0){
				this.isFreeTrial = 1
    		clearTimeout(this.getSectionTimer)
				this.getSectionId()
				// 当前没买课，并且此章节是试看
				this.sectionId = e.sectionId
				this.$ajax({
					url: '/hxclass-mobile/course/freetrial-section-vod-info',
					params: {
						courseId: this.courseId,
						sectionId: e.sectionId
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						FunPlay({
							courseId: this.courseId,
							currStudySectionId: e.sectionId,
							isFast: 1,
							isSpeed: 1,
							currStudyFileId: res.data.fileId,
							currPcfg: res.data.pcfg,
							currStudySeconds: 0,
							SectionTest: res.data.isFreeTrial,
							isHaveSelfTest: 0,
							freeTrialSeconds: res.data.freeTrialSeconds,
							title: res.data.sectionName,
							isOrder: 1,
						})
					}else{
      			this.$alert('未找到视频源信息')
					}
				})
				return false
			}
			this.isFreeTrial = 0
      // 未购买
      if(this.courseDetail.have == 0){
				// 如果为购买，且当前课程是免费，不可试看
				if(this.courseDetail.isFree == 1){
          this.$alert('您当前没有加入此课程，请点击"加入学习"，观看视频')
				}else if(e.isFreeTrial == 1){
					clearTimeout(this.getSectionTimer)
					this.getSectionId()
					this.sectionId = e.sectionId
					courseVideo({
						courseId: this.courseId,
						sectionId: this.sectionId,
					})
          // FunPlay(JSON.stringify({
          //   path: this.$route.path,
          //   courseId: this.courseId,
          //   isFast: e.isFast,
          //   currStudyFileId: e.fileId,
          //   currPcfg: e.pcfg,
          //   currStudySeconds: e.currentSeconds,
          //   currStudySectionId: e.sectionId,
          //   SectionTest: 0,
          //   isHaveSelfTest: e.isHaveSelfTest,
          // 	isNextSectionId: e.isHaveNextSection
          // }))
        } else if (e.lock == 1 && this.courseDetail.isSetFreeTrial == 1){
          this.$alert('您当前没有购买此课程，请点击"试看"，观看视频')
        } else if (e.lock == 1 && this.courseDetail.isSetFreeTrial == 0){
          this.$alert('该课程需要购买后学习')
				}
			} else if(this.courseDetail.have == 1) {  // 已购买
				if(this.courseDetail.isOpenClass == 1){
					let arr = this.courseDetail.startTime.split('/')
					this.$alert('课程学习尚未开始，开始时间为'+ arr[0] +'年'+ arr[1] +'月'+ arr[2] +'日')
					return
				}
				// 课程过期
				if(this.courseDetail.validStatus == 2){
					this.$alert('请前往学习模块-我的课程中申请延期')
					return
				}
        if(e.lock == 1 && e.isHaveSelfTest == 1){  // 章节加锁  有小节自测
          this.$alert('请先学习上一节课程并通过小节测试')
        } else if(e.lock == 1 && e.isHaveSelfTest == 0) {
          this.$alert('请先学习完上一节课程')
        } else {
					clearTimeout(this.getSectionTimer)
					this.getSectionId()
          this.sectionId = e.sectionId
					courseVideo({
						courseId: this.courseId,
						sectionId: this.sectionId,
						isOrder: this.courseDetail.watchByCatalogueOrder
					})
          // FunPlay(JSON.stringify({
          //   path: this.$route.path,
          //   courseId: this.courseId,
          //   isFast: e.isFast,
          //   currStudyFileId: e.fileId,
          //   currPcfg: e.pcfg,
          //   currStudySeconds: e.currentSeconds,
          //   currStudySectionId: e.sectionId,
          //   SectionTest: 0,
          //   isHaveSelfTest: e.isHaveSelfTest,
          // 	isNextSectionId: e.isHaveNextSection
          // }))
        }
      }
      
      // if(e.studyStatus == 1){
      //   // 未开始
      //   this.$alert('视频未开始')
      // }else if(e.studyStatus == 2){
      //   // 未看完
      //   this.$alert('视频未看完')
      // }else if(e.studyStatus == 3 && e.isHaveSelfTest == 0 || e.studyStatus == 4){
      //   // 已看完 && 没有有小节自测 || 自测通过
      //   FunPlay(JSON.stringify({
			// 		path: this.$route.path,
      //     courseId: this.courseId,
      //     isFast: e.isFast,
      //     currStudyFileId: e.fileId,
      //     currPcfg: e.pcfg,
      //     currStudySeconds: e.currentSeconds,
      //     currStudySectionId: e.sectionId,
      //     SectionTest: 0,
      //     isHaveSelfTest: e.isHaveSelfTest
      //   }))
      // }else{
			// 	// console.log(e)
      //   // 小节自测未通过
      //   this.$alert('上一小节自测未通过')
			// }
		},
		goBack(){
			this.$onReturn(this.$route.query.fromMenu == '1')
		},
		SwiperTab(e){
			this.current = e
		},
		popUp () {
			if(!this.classList.length){
				return this.$Toast({message:'暂无可选择班级期数', className:'toast'});
			}
			this.$refs['trainPop'].show()
		},
		close(){
			this.$refs['trainPop'].hide()
		},

		popUp1 () {
			this.$refs['trainPop1'].show()
		},
		close1(){
			this.$refs['trainPop1'].hide()
		},
		selected(e){
    	  if(e.currentNumber && e.status == 1){
					this.handleItem = e;
    	    this.openTimeId = e.openTimeId;
    	  }else{
					this.$Toast({message:'该班级已报满，请选择其他班级',className:'toastClass'})
    	  }
    	},
    	goTeacherList(courseId){
    	  this.$router.push('/curriculum/teacher?courseId='+ courseId + '&type=false')
				this.session();
    	},
		// 收藏取消收藏
		// collect(e){
		// 	this.$ajax({
		// 		url:"/hxclass-mobile/collection/app",
		// 		method:'post',
		// 		params:{
		// 			key:!!e?0:1, // 操作key 1收藏 0取消收藏
		// 			objectId:this.courseDetail.courseId, // 收藏对象id
		// 			type:1// 收藏类型 1.教育课程，2.图书教具，3.教育直播，4.模拟考试 5.套餐
		// 		}
		// 	})
		// },
		// 收藏/取消
    onCollection(collect){
			// 收藏列表是否刷新
			this.isCollectionLoad()
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
      this.courseDetail.collect = this.courseDetail.collect ? 0 : 1
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if(this.FirstCollect != this.courseDetail.collect){
          this.$ajax({
            url:"/hxclass-mobile/collection/app?key=" + this.courseDetail.collect + '&objectId=' + this.courseDetail.courseId +'&type=1' ,
						method: 'POST',
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.FirstCollect = this.courseDetail.collect
            } else {
              this.courseDetail.collect = this.courseDetail.collect ? 0 : 1
              this.FirstCollect = this.courseDetail.collect
            }
          })
        }
      }, 500);
    },
		// 收藏列表是否刷新
		isCollectionLoad() {
			let collectionObj = {}
			let isLoad = true
			if (sessionStorage.getItem('collectionObj')) {
				collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
			}
			collectionObj.isLoad = isLoad
			sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
		},
		// 复制培训地点
		copyAddress() {
			var input = document.createElement("input");   // 直接构建input
			input.value = '北京市朝阳区';  // 设置内容
			document.body.appendChild(input);    // 添加临时实例
			input.select();   // 选择实例内容
			document.execCommand("Copy");   // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$alert('复制成功');
		},
		// 获取课程详情
		getCourseDetail(){
			this.loadingBg = true
			this.$ajax({
				url:"/hxclass-mobile/course/"+this.courseId,
				method:'get'
			}).then(res=>{
				this.loadingBg = false
				if(res.code == 200 && res.success){
					this.classItem = {
						className: res.data.className,
						openStartTime: res.data.openStartTime,
						openEndTime: res.data.openEndTime
					}
					this.courseDetail = res.data;
					this.getClassList();
          this.FirstCollect = res.data.collect  // 记录第一次收藏状态
					this.getCoupons(res.data.productId); // 获取优惠卷
					if(this.$store.state.userInfo.userId){
						this.isHaveShop(res.data.productId); // 判断是否已拥有该课程
					}
					// console.log(sessionStorage.getItem('couseproductList'))
					if(sessionStorage.getItem('couseproductList')){
						let arr = JSON.parse(sessionStorage.getItem('couseproductList'))
						let produceIds = arr.map(item=>{
							return item.produceId;
						})
						let list = this.courseDetail.relevantProductList.filter(item=>{
							return item.type == 1;
						})
						if(arr.length == list.length){
							let status = false;
							list.map(item=>{
								if(produceIds.indexOf(item.produceId) == -1){
									status = true;
								}
							})
							if(status){
								// 相关商品
								this.productList = this.courseDetail.relevantProductList.filter(item=>{
									return item.type == 1;
								})
								this.productList.map(item=>{
									item['num'] = 1;
									return item;
								})
							}else{
								this.productList = arr;
							}
						}else{
							// 相关商品
							this.productList = this.courseDetail.relevantProductList.filter(item=>{
								return item.type == 1;
							})
							this.productList.map(item=>{
								item['num'] = 1;
								return item;
							})
						}
						sessionStorage.setItem('couseproductList','')
					}else{
						// 相关商品
						this.productList = this.courseDetail.relevantProductList.filter(item=>{
							return item.type == 1;
						})
						this.productList.map(item=>{
							item['num'] = 1;
							return item;
						})
					}
					
					// 赠品
					let arr = this.courseDetail.relevantProductList.filter(item=>{
						return item.type == 2;
					})
					this.$set(this,'giftList',arr);
				}else{
					this.$alert(res.message);
				}
			})
		},
		dataListhandle(e){
			if(!e || !e.length){
				return null;
			}
			let arr = e.map(item=>{
				return {
					...item,
					id:item.sectionId,
					name:item.sectionName,
					code:null,
					speaker:null,
          duration:item.duration,
					isHaveQuestion:0,
					isWatch:0,
					isPass:null,
					hourId:null,
					children:item.childList?this.dataListhandle(item.childList):null
				}
			})
			return arr
		},
		selectTime(e){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date(e).getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },
    onLoad() {
      if(this.pageNum * this.pageSize >= this.total){
        this.finished = true;
      }else{
        this.pageNum++;
        this.loading = true;
        this.getCoupons();
      }
    },
		// 课程加入学习
		courseJoin(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login')
			}
			if(!!this.courseDetail.userAuthentication && !this.$store.state.userInfo.certified){
				return this.show = true;
			}
			this.$ajax({
				url:'/hxclass-mobile/course/check-can-buy',
				method:'get',
				params:{
					courseId:this.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					if(this.classList?.length && !this.classItemId){
						return this.popUp();
					}
					this.$ajax({
						url:'/hxclass-mobile/course/join-study',
						method:'post',
						params:{
							courseId:this.courseDetail.courseId, //	课程id
							openTimeId:this.classItemId, //	开课时间id
							userId:this.$store.state.userInfo.userId, //	用户id
						}
					}).then(res=>{
						if(res.code == 200 && res.success){
							this.$alert('已成功加入');
							this.courseDetail.have = 1
							this.courseDetail.couponPrice = 0
							this.getCourseTree();
						}else{
							this.$alert(res.message);
						}
					})
				}else if(res.code == 1000){ // 需购买课程
					this.onPopupOk = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
				}else if(res.code == 1001){ // 需提交证书
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
				}else if(res.code == 1002){ // 已提交证书待审核
					this.onPopupOk = true;
					this.message = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
				}else if(res.code == 1003){ // 审核被驳回
					this.onPopup = true;
					this.message = '购买该课程需要提交证书，是否提交？';
				}else if(res.code == 1004){ // 需购买课程且提交证书
					this.onPopup = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述课程证书，请提交您的证书信息，后台审核通过后方可报名。';
				}else if(res.code == 1005){ // 课程无可用班期
					this.$Toast(res.message);
				}
			})
		},
		// 获取优惠卷
    getCoupons(e){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type:1,
          page:this.pageNum,
          size:this.pageSize,
          productIds:e
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          this.couponList = res.data.records;
        }
      })
    },
		// 领取优惠券/查看可用商品
    couponReceive(e){
			if(!this.$store.state.userInfo.userId){
        this.close1();
				return this.$router.push('/login/login');
			}
			// if(!this.$store.state.userInfo.certified){
			// 	return this.show = true;
			// }
      if(!e.noReceive){
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/coupon-receive',
          method:'post',
          params:{
            couponId:e.couponId,
            receiver:this.$store.state.userInfo.userId,
            receiverTime:this.selectTime(new Date()),
            state:1
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            e.noReceive = 1;
						this.$refs.shpCarModel.pageNum = 1;
						this.$refs.shpCarModel.getCoupons();
          }
        })
      }else{
        if(e.scope == 1){
					this.$router.push('/shop/shoppingMall');
          this.close1();
				}else{
					this.$router.push('/mine/couponGoods?couponId='+e.couponId);
          this.close1();
				}
      }
    },
		// 获取课程目录
		getCourseTree(){
			this.$ajax({
				url:'/hxclass-mobile/course/course-section/tree',
				method:'get',
				params:{
					courseId:this.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){

					// 利用递归  将目录已关闭的重置到目录数据中
					let arry = this.$store.state.closeSectionIdArry
					
					function fors(arr){
						if (arr.length !== 0) {
							arr.forEach((item) => {
								if(arry.indexOf(item.sectionId) != -1){
									item.show = true
								}
								if (item.childList) {
									item.childList = fors(item.childList);
								}
							});
						}
						return arr;
					}
					this.tree = this.dataListhandle(fors(res.data));
					// 列表新内容添加后，把最小高度更新
					this.$nextTick(()=>{
						if(!document.getElementsByClassName('tabsContent')[0]){
							return false
						}
					})
				}
			})
		},

		// 获取班期列表
		getClassList(){
			this.$ajax({
				url:"/hxclass-mobile/course/course-open-time/by-id",
				method:'get',
				params:{
					courseId:this.courseDetail.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.classList = res.data;
					if(this.$store.state.openTimeId){
						res.data?.map(item=>{
							if(item.openTimeId == this.$store.state.openTimeId){
								if(item.currentNumber && !this.classItemId){
									this.classItem = item;
									return this.classItemId = item.openTimeId;
								}
							}
						})
					}
				}else{
					this.$alert(res.message);
				}
			})
		},
		// 获取评价列表
		getCommentList(){
			this.$ajax({
				url:'/hxclass-mobile/order-comment/',
				method:'get',
				params:{
          page:this.comment.pageNum,
          size:100000,
          productId:this.courseDetail.productId,
					evaluation:this.evaluation
        }
			}).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluationList = res.data.records
					// 列表新内容添加后，把最小高度更新
					this.$nextTick(()=>{
						if(!document.getElementsByClassName('tabsContent')[0]){
							return false
						}
					})
        }
      });
		},
		// 查询评价数量
		getAppraiseCount(e){
      this.$ajax({
        url: "/hxclass-mobile/order-comment/count",
        params: {
					productId: e,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluateList = [
						'全部(' + res.data.all + ')',
						'好评(' + res.data.good + ')',
						'中评(' + res.data.medium + ')',
						'差评(' + res.data.bad + ')',
					]
        }
      });
		},
		// 评价头部菜单切换
		onEvaChange(e){
			if (e==1) {
				this.evaluation = 3
			} else if (e==2) {
				this.evaluation = 2
			} else if (e==3) {
				this.evaluation = 1
			} else if (e==0) {
				this.evaluation = ''
			}
			this.getCommentList()
		},
		// 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
    },
		// 切换当前视频播放
		onChangeVideo(e){
			if(typeof e != 'object'){
				e = JSON.parse(e)
			}
			let sectionId = null
			this.$ajax({
				url: '/hxclass-mobile/course/section-vod-info',
				params: {
					courseId: e.courseId,
					sectionId: e.sectionId,
					userId: this.$store.state.userInfo.userId
				}
			}).then(res=>{
				sectionId = res.data.nextSectionId
				this.sectionId = res.data.nextSectionId  // 获取下一章节id
				return this.$ajax({
					url: '/hxclass-mobile/course/section-vod-info',
					params: {
						courseId: e.courseId,
						sectionId: res.data.nextSectionId,
						userId: this.$store.state.userInfo.userId
					}
				})
			}).then(res=>{
				// 查询下一章节的id
				if(res.code == 200 && res.success && res.data.fileId){ // 下一章节视频有内容
					courseVideo({
						courseId: res.data.courseId,
						sectionId: sectionId,
						isOrder: this.courseDetail.watchByCatalogueOrder
					})
				}else{
					this.$alert('没有可播放的视频')
				}
			})
		},

		// 更新学习时长
		onUpdateLearningDuration(obj){
			if(typeof obj != 'object'){
				obj = JSON.parse(obj)
      }
			this.$ajax({
				url: '/hxclass-mobile/course/course-study-record/to-update',
				method: 'post',
				params: {
					courseId: obj.courseId,
					seconds: parseInt(obj.seconds),
					sectionId: obj.sectionId,
					// userId: this.$store.state.userInfo.userId
				}
			}).then(res=>{
        this.getCourseTree();
			})
    },
		// 获取章节id
		getSectionId(){
			this.getSectionTimer = setInterval(()=>{
				if(FunGetSectionId()){
					this.sectionId = FunGetSectionId()
				}
			},3000)
		}
	},
	// 生命周期-实例创建完成后调用
	created () {
		if(this.$route.query.id){
			
			this.courseId = this.$route.query.id;
			this.video = this.$route.query.video;
      this.getCourseDetail();
			if(this.$store.state.userInfo.userId){
				// 更新视频时长
				window.UpdateProgress = (res)=>{
          if(typeof res != 'object' && !this.sectionId){
            this.sectionId = JSON.parse(res).sectionId
          } else if(!this.sectionId) {
            this.sectionId = res.sectionId
          }
          if(this.isStopUpdate){
						this.onChangeProgress(res)
					}
				}
				// 更新学习时长
				window.UpdateLearningDuration = (res)=>{
					clearTimeout(this.tiemID);
					this.tiemID = setTimeout(() => {
						this.onUpdateLearningDuration(res)
					}, 1000);
				}
				// 播放下一节视频内容
				window.NextVideo = (res)=>{
					// 播放下一节时先暂停更新视频进度
					this.isStopUpdate = false
          this.getCourseTree();
					// 3秒后解除暂停更新
					setTimeout(()=>{
            this.isStopUpdate = true
          },3000)
					this.onChangeVideo(res)
				}
				this.getShopCarList();
				this.getSectionId()
			}
    }
    // 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // this.calculateText()
    this.$nextTick(() => {
			if(this.video!=1){
				// 导航栏高度
				this.navHeight = document.getElementsByClassName('van-nav-bar')[0].offsetHeight
			}
      this.FunHandleScroll();
			document.getElementsByClassName('myCourse')[0].addEventListener("scroll", this.FunHandleScroll);
		})

  },
  beforeDestroy() {
    clearTimeout(this.getSectionTimer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { 
    if (document.getElementsByClassName('myCourse')[0]) {
      document.getElementsByClassName('myCourse')[0].removeEventListener("scroll", this.FunHandleScroll);
    }
  },
  // 页面离开调用
  beforeRouteLeave(to,form,next){
    if(this.$route.query.video == 1){
      FunLeavePlay(to.fullPath)
      next(false)
    }else{
      next()
    }
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    active(){
      if(this.active == '目录' && this.tree.length == 0){

        this.getCourseTree();
			}

			if(this.active == '评价' && this.evaluationList.length == 0){
				this.getAppraiseCount(this.courseDetail.productId); // 查询评价数量
				this.getCommentList(); // 获取评价列表
			}

			const hei = document.getElementsByClassName('tabsContent')[0].offsetHeight
			if(hei < window.innerHeight){
				this.minHeight = hei
			}else{
				this.minHeight = parseInt(window.innerHeight ) - 55 - this.navHeight
			}

			if(this.topRecord){
				document.getElementsByClassName('myCourse')[0].scrollTop = this.topRecord
			}

    },
    $route(newVal,oldVal){
			this.active = '详情介绍'
			this.tree = []
      this.courseId = this.$route.query.id;
      this.getCourseDetail();
    }
  }
}
</script>

<style>
  .toastClass{
    z-index: 100000 !important;
  }
</style>

<style lang="less" scoped>
  .gotopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .box_con {
      font-size: 14px;
      color: #fff;
      margin-top: px;
    }
	}
	/deep/.textOver{
		clear: #666;
		line-height: 18px;
		.link{
			color: #333;
			line-height: 18px;
		}
	}
	/deep/ .overflow-hidden{
		max-height: 18px;
		
	}

/deep/.van-dialog__header{
	padding: 16px 20px;
	line-height: 1;
	text-align: left;
	display: flex;
	align-items: center;
	img{
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	span{
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		line-height: 27px;
	}
}
/deep/.van-dialog__footer{
	margin-top: 20px;
	/deep/.van-button--default{
		width: 122px !important;
		background-color: #EEEEEE !important;
	}
}
.nocertified{
	font-size: 16px;
	color: #333333;
	line-height: 24px;
	display: block;
	padding-left: 20px;
}
.courseVideo{
	padding: 18px 18px 14px;
	background-color: #fff;
	.courseName{
		font-size: 20px;
		line-height: 30px;
		font-weight: bold;
	}
	.progress{
		margin-top: 4px;
		height: 15px;
		display: flex;
		align-items: center;
		.Learned{
			font-size: 10px;
			color: @theme;
			line-height: 15px;
			margin-right: 18px;
		}
		.classHour{
			font-size: 10px;
			color: #666;
			line-height: 15px;
		}
	}
	.introduction{
		font-size: 12px;
		line-height: 18px;
		color: #666;
		margin-top: 9px;
	}
}
	.ev_tab {
		/deep/.van-tabs {
			position: relative;
			z-index: 2;
		}
	}
	.myCourse{
		.box_nav {
			position: relative;
			z-index: 100;
		}
		.courseHeader{
			// height: 265px;
			// border-radius: 0 0 17px 17px;
			background: #fff;
			position: relative;
			.btnGroup{
				position: absolute;
				right: 18px;
				// top: 0;
				display: flex;
				bottom: -34px;
				align-items: center;
				.collectBox{
					text-align: center;
					min-width: 40px;
					.collect{
						width: 32px;
						height: 32px;
					}
					.collectspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}
				.tryBox{
					text-align: center;
					margin-right: 20px;
					.tryImg{
						width: 32px;
						height: 32px;
					}
					.tryspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}

			}
			.back{
				position: fixed;
				// left: 16px;
				// top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.share{
				position: absolute;
				right: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
			}
			.swiper{
				// height: 265px;
				.swiperImg{
					width: 100%;
					// height: 265px;
					// border-radius: 0 0 17px 17px;
				}
				/deep/ .uni-swiper-dot-active {
					width: 16px !important;
					height: 4px !important;
				    background-color: #fff !important;
					border-radius: 2px;
				}
				/deep/ .uni-swiper-dot {
				    display: inline-block;
				    width: 4px;
				    height: 4px;
				    cursor: pointer;
				    background: rgba(255, 255, 255, 0.6);
				    // border-radius: 0%;
				}
			}
			/* 指示器样式 */
			.u-indicator-item-number {
				width: 20px;
				padding: 7.5px;
				line-height: 1;
				background-color: rgba(0, 0, 0, 0.35);
				border-radius: 50px;
				font-size: 11px;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				right: 20px;
				bottom: 15px;
				text-align: center;
				letter-spacing: 1.5px;
			}
		}
		.courseExplain{
			padding: 16px 18px;
			// height: 116.5px;
			background: #fff;
			// margin-top: 8px;
			.explainUp{
				display: flex;
				margin-top: 10px;
				.left{
					flex: 1;
					align-items: center;
					display: flex;
					.label{
						width: 42.5px;
						height: 19px;
						padding: 2px 6px;
						display: inline-block;
						background: url(~@/assets/image/selected/tag.svg) no-repeat;
						background-size:100% 100%;
						font-size: 10px;
						font-weight: 400;
						color: #F0A300;
						text-align: center;
						margin-right: 9px;
						align-items: center;
					}
					.price{
						font-size: 20px;
						font-weight: 400;
						color: #FF7A00;
    				font-family: PingFang SC-Regular, PingFang SC;
						line-height: 13px;
					}
					.originalPrice{
						font-size: 10px;
						font-weight: 400;
						color: #999999;
						// font-family: DIN-Bold;
						font-family: PingFangMedium;
						margin-left: 10px;
						margin-top: 7px;
					}
				}
				.right{
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}
			.explainCenter{
				font-size: 18px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #333333;
				line-height: 27px;
				margin-top: 6px;
			}
			.explainDown{
					font-family: PingFangMedium;

				.courseType{
					display: flex;
					align-items: center;
					margin: 8px 0 10px 0;
					.left{
						flex: 1;
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #666666;
						line-height: 21px;
					}
					.right{
						font-size: 11px;
						font-weight: 400;
						color: #15B7DD;
						line-height: 13px;
						font-family: PingFang SC-Regular, PingFang SC;
					}

				}
				.fload{
					display: -webkit-box;
					word-break: break-all;
					-webkit-box-orient: vertical;
					/* 要显示多少行就改变line-clamp的数据,此处折叠起来显示一行*/
					-webkit-line-clamp: 4;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
					line-height: 15px;
        }

			}
		}
		.couponBox{
			padding: 16px 18px;
			font-family: PingFangMedium;
			background: #fff;
			margin-top: 8px;
			.item{
				display: flex;
				.left{
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					line-height: 20px;
					flex: 1;
				}
				.right{
					flex: 5;
					// margin-right: 12px;
					display: flex;
					align-items: center;
					.rightBox{
						display: flex;
						justify-content: space-between;
						flex: 1;
						padding-right: 12px;
						.couponList{
							height: 20px;
							overflow: hidden;
							flex: 1;
							display: flex;
							flex-wrap: wrap;
						}
						.tag{
							width: 28px;
							height: 16px;
							background: rgba(#F93C00, 0.1);
							border-radius: 2px;
							font-size: 10px;
							font-weight: bold;
							color: #F93C00;
							line-height: 16px;
							text-align: center;
							padding: 1px 4px;
						}
						.downitem{
							margin: 1px 10px 5px;
							font-size: 10px;
							font-weight: 400;
							color: #F93C00;
							line-height: 18px;
							height: 18px;
							text-align: center;
							position: relative;
							padding: 0 5px;
							background-image: url(~@/assets/image/selected/20220610-145020.png);
							background-position: center;
							background-size: 1px 18px;
							&::before,&::after{
								content: '';
								width: 4px;
								display: block;
								position: absolute;
								top: 0;
								left: -4px;
    						height: 100%;
								background-image: url(~@/assets/image/selected/left.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 4px 18px;
								transform: rotate(180deg);
							}
							&::after{
								content: '';
								left: auto;
								right: -4px;
								transform: rotate(0deg);
							}
						}
					}
				}
			}
		}
		.combination{
			padding: 16px 18px;
			padding-bottom: 0;
			background: #fff;
			margin-top: 8px;
			.combinationTitle{
				span{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					font-weight: bold;
				}
			}
			.combinationBox{
				margin-top: 22px;
				.item{
					display: flex;
					padding-bottom: 16px;
					.courseImg{
						width: 100px;
						height: 70px;
					}
					.right{
						padding: 2.5px 0 0 11px;
						.title{
							display: flex;
							font-size: 14.5px;
							font-weight: 400;
							color: #333333;
							line-height: 25.5px;
						}
						.price{
							display: flex;
							font-size: 10px;
							font-weight: 400;
							color: #999999;
							line-height: 14.5px;
							margin-top: 6px;
						}
						.identification{
							font-size: 11px;
							font-weight: 400;
							color: #1E97B4;
							line-height: 15.5px;
						}
					}
				}
			}

		}
		.relatedIntroduction{
			padding: 12px 19px;
			background: #fff;
			margin-top: 8px;
			.produceTitle{
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #333333;
				line-height: 18px;
			}
			.productImg{
				width: 70px;
				height: 70px;
				border: 5px;
			}
			.productcontent{
				padding: 0 20px 10px 0;
				margin-bottom: 10px;
				border-bottom: 1px solid #F5F5F5;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;
				.productTitle{
					font-weight: 400;
					font-size: 13px;
					color: #333333;
					line-height: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					font-family: PingFang SC-Regular, PingFang SC;
					-webkit-line-clamp: 1;
					/*! autoprefixer: off */
					-webkit-box-orient: vertical;
				}
				.changenum{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 9px;
			   	font-family: PingFang SC-Regular, PingFang SC;
					.price{
						// font-weight: bold;
						font-size: 12px;
						color: #FF7A00;
						span{
							font-size: 10px;
							display: inline-block;
							color: #999999;
							text-decoration:line-through;
						}
						.pricesign{
							font-size: 10px;
							font-weight: 400;
							// font-family: PingFang SC-Medium;
						}
					}
				}
			}
			.productcontent:last-child{
				padding-bottom: 0;
				border: 0;
				margin-bottom: 0;
			}
			.produceOne{
				margin-bottom: 0;
				border-bottom: 0 solid #F5F5F5;
			}
			.item{
				display: flex;
				padding-bottom: 16px;
				border-bottom: 1px solid #F5F5F5;
				font-family:PingFangMedium;
				.left{
					font-size: 13px;
					font-weight: 400;
					color: #8B8B8D;
					line-height: 20px;
					width: 68px;
					text-align: left;
				}
				.right{
					position: relative;
					flex: 4;
					margin-left: 12px;
					font-size: 13px;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					.vanIcon{
						position: absolute;
						right: 0;
						top: 0;
					}
					.shoplist{
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex: 1;
					}
					.span{
						display: inline-block;
						flex: 1;
					}
					.copy{
						font-size: 13px;
						font-weight: 400;
						color: #15B7DD;
						line-height: 22px;
					}
					.style{
						padding: 16px 0;
						border-bottom: 1px solid #F5F5F5;
					}
					.style:first-child{
						padding-top: 0;
					}
					.style:last-child{
						padding-bottom: 0;
						border-bottom: 0;
					}
				}
			}
			.item:not(:first-child) {
				padding-top: 18px;
			}
			.item:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
		.teacher{
			padding: 12px 19px;
			background: #fff;
			margin-top: 8px;
			width: 100%;
			box-sizing: border-box;
			.title{
				width: 100%;
				display: flex;
				.left{
					width: 68px;
					text-align: left;
					font-size: 13px;
					color: #8B8B8D;
					line-height: 48px;
					white-space: nowrap;
					font-family: PingFangMedium;
				}
				.rightBox{
					width: calc(100% - 80px);
					margin-left: 12px;
					display: flex;
					height: 48px;
					.scroll-div{
						flex: 1;
						display: flex;
						overflow-x: scroll;
						overflow-y: hidden;
						padding-top: 0px;
						.scroll-item{
							text-align: center;
							height: 48px;
							.item{
								display: flex;
								align-items: center;
								height: 48px;
								.portrait{
									width: 48px;
									height: 48px;
									border-radius: 100%;
									.portraitImg{
										width: 48px;
										height: 48px;
										background-repeat: no-repeat;
										object-fit: cover;
										border-radius: 100%;
									}
								}
								.name{
									margin-left: 8px;
									padding-right: 10px;
									margin-right: 10px;
									font-size: 14px;
									font-weight: 400;
									color: #333333;
									white-space: nowrap;
									font-family: PingFangMedium;
									line-height: 25px;
									text-overflow: ellipsis;
									border-right: 1px solid #DADADA;;
								}
							}
						}
						.scroll-item:last-child{
							.item{
								padding: 0;
								.name{
									padding-right: 0;
									margin-right: 0;
									border-right: 0;
								}
							}
						}
						&::-webkit-scrollbar {
							display: none;
						}
					}
					.right{
						width: 12px;
						display: flex;
						align-items: center;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
						.span{
							margin-right: 3px;
						}
					}
				}
			}
		}
		.details{
			// padding: 18px;
			background: #fff;
			margin-top: 8px;
			.detailsTitle{
				.up{
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
					padding: 16px 0 0 0;
					text-align: center;
					img{
						width: 56px;
						height: 8px;
						vertical-align: middle;
					}
				}
				.down{
					padding: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
				}
			}
		}
		.tabsBox{
			margin-top: 8px;
			background: #fff;
			/deep/.van-tabs__nav{
				justify-content: space-around;
			}
			/deep/.van-tab {
					margin-left: 0; 
					padding: 0 16px;
			}
			/deep/.van-tab:first-child {
			    margin-left: 0;
			}
			.tabsBox_tab {
				// position: relative;
				z-index: 10;
			}
			
			.tabsContent{
				box-sizing: border-box;
				// min-height: 50vh;
				padding: 0 18px;
				padding-bottom: 55px;
				background: #fff;
				line-height: normal;
				/deep/strong{
					font-weight: bold;
				}
				/deep/em{
					font-style: italic;
				}
				/deep/img{
					max-width: 100%;
				}
				/deep/.remarks{
					max-width: 100%;
					height: auto !important;
					-webkit-line-clamp: 10000 !important;
				}
        .info{
          // padding-top: 10px;
        }
				// .catalogue{
				// 	.catalogueList{
				// 		// padding: 10px 0;
				// 	}
				// }
				.evaluate {
					padding: 20px 0;
					padding-top: 0;
					.ev_list {
						border-bottom: 1px solid #F0F0F0;
						margin-right: 18px;
						margin-bottom: 24px;
						padding-bottom: 20px;
						&:last-child {
							border: 0;
						}
						.list_top {
							display: flex;
							align-items: center;
							.top_photo {
								width: 32px;
								height: 32px;
								border-radius: 50%;
							}
							.top_phone {
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFangMedium;
								font-weight: 500;
								color: #333333;
								margin-left: 8px;
							}
							.top_flower {
								width: 16px;
								margin-left: 15px;
							}
						}
						.list_con {
							padding-left: 38px;
							.con_main {
								font-size: 15px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #666666;
								line-height: 26px;
							}
							.con_data {
								font-size: 12px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #999999;
								margin-top: 12px;
							}
						}
						.list_reply {
							font-size: 15px;
							font-family: PingFangMedium;
							font-weight: 400;
							color: #666666;
							line-height: 26px;
							margin-left: 16px;
							padding: 16px;
							background: #F5F7FA;
							border-radius: 4px;
							margin-top: 8px;
							.reply_main {
								color: #333333;
							}
						}
					}
				}
      }
      .tabsMenu{
        background: none;
      }
			.tabbg {
				// padding: 0;
				// background: transparent;
			}
			.tabbg4 {
				// padding: 0 0 0 18px;
			}
		}
		.menuHeight{
			transition: all 0.3s;
			height: 55px;
			// box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
		}
		.footBtn{
			width: 100%;
			position: fixed;
			bottom: 0;
			z-index: 10;
			// padding: 5px;
			overflow: hidden;
			background: #FFFFFF;
			display: flex;
			box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
			.collect{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				margin-left: 5px;
				padding: 8px 0;
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
				}
				.collectImg{
					height: 18px;
				}
			}
			.freediving{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
        padding: 8px 0;
        position: relative;
				.freedivingImg{
					height: 18px;
				}
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
        }
        .num{
          position: absolute;
          font-style: normal;
          text-align: center;
          // width: 14px;
          // height: 14px;
					min-width: 14px;
          border-radius: 999px;
          top: 0px;
					padding: 0 3px;
          right: 4px;
          background: #f90000;
          font-size: 10px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 14px;
        }
			}
			.ishave{
				flex: 4;
				display: flex;
				background: #0CA4C8;
				height: 40px;
				line-height: 40px;
				margin: 2px 7.5px 0 7.5px;
				justify-content: center;
				border-radius: 20px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFangMedium;
				font-weight: 500;
				color: #FFFFFF;
      }
      .join{
        width: 100%;
        text-align: center;
      }
			.ishaveopcity{
				opacity: 0.5;
			}
			.btnGroup{
				flex: 4;
				display: flex;
				background: url(~@/assets/image/selected/20220626-112349.png) no-repeat;
				background-size:100% 100%;
				height: 40px;
				line-height: 40px;
        margin: 2px 7.5px 0 7.5px;
        
				.shoppingCart{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyspan{
						font-size: 14px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
				.purchase{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyspan{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.moneySign{
			font-size: 18px;
			font-weight: 400;
		}
		// 培训地点选择
		.popContentBox{
			.popBox{
				.titleBox{
					padding: 28px 34px 0 34px;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 25px;
					display: flex;
					span{
						display: inline-block;
					}
					.title{
						flex: 1;
					}
				}
				.popContent{
					height: 503px;
					overflow-y: scroll;
					padding: 0 18px;
					.popItem{
						padding: 16px 12px;
						box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
						border-radius: 8px;
						margin-bottom: 8px;
						.up{
							display: flex;
							.left{
								font-size: 14px;
								font-weight: bold;
								color: #333333;
								line-height: 20px;
								flex: 1;
							}
							.right{
								font-size: 12px;
								font-weight: 400;
								color: #666666;
								line-height: 17px;
								.num{
									color: #15B7DD;
								}
							}
						}
						.center{
							padding: 8px 0;
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 17px;
						}
						.down{
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 20px;
						}
						&.pick {
							background: #15B7DD;
							box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
							.up{
								.left,.right{
									color: #FFFFFF
								}
							}
							.center,.down,.num{
								color: #FFFFFF !important;
							}
						}
					}
					.popItem:first-child{
						margin-top: 16px;
					}
					.disabled{
						.up{
							.left{
								span{
									color: #666666;
								}
							}
							.right{
								color: #666666;
								.num{
									color: #666666;
								}
							}
						}
						.center,.down{
							span{
								color: #666666;
							}
						}
					}
				}
			}
			.popbutton{
				box-sizing: border-box;
				// box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				padding: 5px 40px;
				position: fixed;
				width: 100%;
				bottom: 0;
				.btn{
					width: 100%;
					height: 40px;
					display: block;
					text-align: center;
					margin: 0 auto;
					line-height: 40px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
				}
				
			}
		}
	}
	.windowCoupons{
		background-color: #fff;
		border-radius: 24px 24px 0 0;
		padding: 0 18px;
		.navBar{
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 18px 0;
			.center{
				font-size: 18px;
				line-height: 25px;
				color: #333;
			}
			.right{
				position: absolute;
				right: 0;
			}
		}
	}
	.coupon{
		padding: 0 13px;
		padding-bottom: 25px;
		overflow-y: scroll;
		height: 320px;
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			height: 84px;
			border-radius: 6px;
			display: flex;
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
				// border-top: 1px solid #F93C00;
				// border-left: 1px solid #F93C00;
				// border-bottom: 1px solid #F93C00;
				flex-direction: column;
				text-align: center;
        width: 90px;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #F90000;
				// 	border-right: 1px solid #F90000;
				// 	border-bottom: 1px solid #F90000;
				// 	border-top: 1px solid #F7F7F9;
				// 	border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -1.4px;
				// 	z-index: 99;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #fff;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #F90000;
				// 	border-right: 1px solid #F90000;
				// 	border-top: 1px solid #F90000;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	z-index: 99;
				// 	bottom: -1.8px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #fff;
				// }
				.price{
					font-size: 28px;
					font-family: DIN-Bold;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
			 	// border-top: 1px solid #F93C00;
				// border-right: 1px solid #F93C00;
				// border-bottom: 1px solid #F93C00;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0px;
					top: 0px;
					z-index: 9;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
	/deep/.van-swipe__indicators{
		.van-swipe__indicator{
			background-color: #fff;
			opacity: 0.6;
			border-radius: 3px;
			transition: width 0.3s;
			height: 4px;
			width: 4px;
		}
		.van-swipe__indicator--active{
			opacity: 1;
			width: 16px;
		}
	}
	// 评价导航栏
	/deep/.van-tabs-circle {
		.van-tabs__nav--line {
			height: 28px;
		}
		.van-tabs__nav{
			padding: 0;
		}
		.van-tab {
			height: 28px;
			line-height: 28px;
			font-size: 12px;
			color: #666666;
			border-radius: 13px;
			background: rgba(21, 183, 221, 0.1);
			margin-left: 0 !important;
			margin-right: 24px;
			padding: 4px 12px !important;
			&:nth-child(4){
				margin-right: 0 !important;
			}
		}
		.van-tab--active {
			color: #fff;
			background: #15B7DD;
		}
		.van-tabs__line {
			display: none;
		}
	}
	// 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 12.5px;
    }
		.popup_button_center {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
	.videoDates{
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 12px;
		color: #666;
		line-height: 18px;
		margin-top: 9px;
		img{
			width: 10px;
			margin-right: 6px;
		}
  }
  // 展开
 .upper-part {
	margin-top: 9px;
  .abstract {
    line-height: 19.6px;
    overflow: hidden;
    zoom:1;
    .content-deta {
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
    }
  }
  .unfold {
    float: right;
    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #15B7DD;
    }
  }
 }
 // 收起
 .pack-up {
    position: relative;
		margin-top: 9px;
    .abstract {
        // 最大高度设为3倍的行间距
        max-height: 63px;
        line-height: 21px;
        word-wrap: break-word;
        /*强制打散字符*/
        word-break: break-all;
        background: #ffffff;
        /*同背景色*/
        color: #ffffff;
        overflow: hidden;
        .content-deta {
            width: 100%;
            padding: 0 21px;
        }
        &:after,
        // 这是展开前实际显示的内容
        &:before {
            content: attr(title);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #666666;
        }
        // 把最后最后一行自身的上面三行遮住
        &:before {
            display: block;
            overflow: hidden;
            z-index: 1;
            max-height: 42px;
            background: #ffffff;
        }
        &:after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 60px;
            /*截取行数*/
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            /*行首缩进字符数，值为[(截取行数-1)*尾部留空字符数]*/
            text-indent: -12px;
            /*尾部留空字符数*/
            padding-right: 3em;

        }
        .unfold {
            z-index: 11;
            outline: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            p {
                margin: 0;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFangMedium;
                font-weight: 500;
                color: #15B7DD;
            }
        }
    }
 }
 /deep/.van-tabs__nav{
	 .van-tab{
		 flex: none;
		 padding: 0 4px;
		 margin-left: 20px;
		 &:first-child{
			 margin-left: 14px;
		 }
	 }
 }
 /deep/.van-tabs--line{
	 .van-tabs__wrap{
		 	height: 55px;
	 }
 }
 /deep/.van-tabs-default .van-tab--active{
		// background-size: 40px 13px;
    background-position: center 30px;
 }
</style>
