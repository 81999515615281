<template>
	<div class="myCourse">
		<!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
			title="套餐详情"
			:fixed="true"
			:placeholder="true"
			:style="{
				height: stateOpacity+'px',
      }"
		/>

		<Skeleton v-if="loadingBg"/>
		<div v-else>
			<div style="padding-bottom: 50px;">
				<!-- 顶部课程轮播图 -->
				<div class="courseHeader">
					<!-- <uni-icons class="back" custom-prefix="iconfont" type="icon-Arrowleftback" size="40"></uni-icons> -->
					<!-- <div 
						v-if="stateOpacity<1"
						class="back" 
						:style="{top: 11+stateHeight+'px', left: '9px', opacity: 1-(stateOpacity*5)}"
						@click="goBack">
            <img src="@/assets/image/selected/back.png" />
          </div> -->
					<!-- <img class="share" src="@/assets/image/selected/share.png" /> -->
					<van-swipe @change="SwiperTab" :autoplay="5000" class="swiper">
						<van-swipe-item v-for="(item,index) in fromObj.pcDetailPicture" :key="index">
							<div class="swiper-item uni-bg-red">
								<img class="swiperImg" :src="item" />
							</div>
						</van-swipe-item>
					</van-swipe>

					<!-- <div class="btnGroup">
						<div class="collectBox" @click="onCollection(fromObj.collect)">
							<img class="collect" :src="fromObj.collect ? collected : collect" />
							<span class="collectspan" >{{fromObj.collect ? '已收藏' : '收藏'}}</span>
						</div>
					</div> -->
				</div>
				<!-- 课程标题以及介绍 -->
				<div class="courseExplain">
					<div class="explainUp">
						<div class="left">
							<span class="label"><img src="@/assets/image/selected/tag.png" alt=""></span>
							<span class="price"><span class="moneySign">¥</span> {{fromObj.packagePrice ? fromObj.packagePrice.toFixed(2) : '0.00'}}</span>
							<span class="originalPrice">
								<span style="text-decoration:line-through" >¥{{fromObj.originalPrice ? fromObj.originalPrice.toFixed(2) : '0.00'}}</span>
							</span>
						</div>
					</div>
					<div class="explainCenter">
						{{fromObj.packageName}}
					</div>
					<div>
						<TextOverflow :type=1 :height="30" :text="fromObj.introduction" ref="TextOver" />
					</div>
				</div>
				<!-- 优惠卷 -->
				<!-- <div class="couponBox" v-if="couponList.length">
					<div class="item" @click="popUp1">
						<div class="left">
							优惠券
						</div>
						<div class="right">
							<div class="rightBox">
								<div class="downitem" v-if="couponList[0].type == 1">
									满{{couponList[0].fullPrice}}减{{couponList[0].couponPrice}}
								</div>
								<div class="downitem" v-show="couponList[0].type == 3">
									满{{couponList[0].discountPrice}}{{couponList[0].discountType==1?'件':'元'}}{{couponList[0].discount}}折
								</div>
								<div class="tag">
									领劵
								</div>
							</div>
							<van-icon name="arrow" size="10" color="#999" />
						</div>
					</div>
				</div> -->
				<!-- 套餐组合 -->
				<div class="combination">
					<div class="combinationTitle">
						<span class="line"></span>
						<span>套餐组合</span>
					</div>
					<div class="combinationBox">
						<div class="item" v-for="(items) in fromObj.productList" :key="items.productId" @click="showSharePageModel = true">
							<div class="left">
								<img class="courseImg" :src="items.picture" />
							</div>
							<div class="right">
								<span class="title">{{items.productName}}</span>
								<span class="identification" v-if="items.type == 1">{{items.courseCategoryName}}丨视频数量：{{items.classHour}}个</span>
								<span class="identification" v-if="items.type == 4">{{items.isExam?items.examNum+'次考试次数':"不限考试次数"}}</span>
								<div class="dlex">
							   	<span class="price">{{items.price ? '￥'+(items.price).toFixed(2) : '免费'}}</span>
							   	<span class="cuntent">x{{items.count }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 详情介绍部分 -->
				<div class="details">
					<div class="detailsTitle">
						<div class="up">
							<img class="detailsLeft" src="@/assets/image/selected/detailsLeft.png" />
							详情介绍
							<img class="detailsRight" src="@/assets/image/selected/detailsRight.png" />
						</div>
						<div class="down" v-html="fromObj.mobileDetail"></div>
					</div>
				</div>
			</div>
			<div class="footBtn">
				<div class="collect" @click="showSharePageModel = true">
					<img class="collectImg" src="@/assets/image/selected/2022062610411.png" />
					<span>客服</span>
				</div>
				<div class="freediving" @click="showSharePageModel = true">
					<img class="freedivingImg" src="@/assets/image/selected/2022062610412.png" />
					<span>购物车</span>
          <span class="num" v-if="shopCarList.length">{{getNum(shopCarList)}}</span>
				</div>
				<div class="btnGroup" @click="showSharePageModel = true">
					<!-- <div class="shoppingCart">
						<span class="buyspan" >加入购物车</span>
					</div> -->
					<div class="purchase">
						<span class="buyspan">立即购买</span>
					</div>
				</div>

			</div>
			<!-- 优惠券弹窗 -->
			<n-transition ref="trainPop1" speed="ease-in-out" :height="415" :maskVal="0.35">
				<div class="windowCoupons">
					<div class="navBar">
						<div class="center">领取优惠券</div>
						<div class="right" @click="showSharePageModel = true">
							<van-icon name="cross" color="#666" size="20" />
						</div>
					</div>
					<div class="coupon">
						<div class="couponItem" v-for="(item,index) in couponList" :key="index">
							<div class="left">
								<template v-if="item.type == 1">
									<span class="price"><span>¥</span>{{item.couponPrice}}</span>
									<span class="condition">满{{item.fullPrice}}元可用</span>
								</template>
								<template v-if="item.type == 3">
									<span class="price">{{item.discount}}<span>折</span></span>
									<span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
								</template>
								<span class="rightTop"></span>
								<span class="rightBottom"></span>
								<img v-show="false" src="@/assets/image/my/overdue.png" />
							</div>
							<div class="right">
								<span class="title">{{item.name}}</span>
								<span class="time" v-if="item.times == 2">{{item.startTime.split('-').join('.')}}-{{item.endTime.split('-').join('.')}}</span>
                <span class="time" v-if="item.times == 1">不限使用时间</span>
								<span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':'指定商品'}}]</span>
								<span class="btn" @click="showSharePageModel = true">{{!!item.noReceive?'可用商品':'点击领取'}}</span>
								<img v-show="!!item.noReceive" src="@/assets/image/my/Received.png" />
							</div>
						</div>
					</div>
				</div>
			</n-transition>
			<ShpCarModel ref="shpCarModel" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarFlag="shopCarFlag" :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
		</div>
		<van-popup v-model="onPopup" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showSharePageModel = true">下次再报</span>
          <span class="all-btn-middle btn2" @click="showSharePageModel = true">提交证书</span>
        </div>
      </div>
    </van-popup>
		<van-popup v-model="onPopupOk" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="showSharePageModel = true">我知道了</span>
        </div>
      </div>
    </van-popup>
		<!-- 提示信息 -->
		<van-popup v-model="show" :round="true">
			<div class="box_popup">
				<div class="popup_title">
					<van-icon name="warning-o" color="#EB3C31" size="26" />
					<span class="title_con">提示信息</span>
				</div>
				<div class="popup_con">购买本套餐需要完成身份认证</div>
				<div class="popup_button">
					<span class="all-btn-middle btn1" @click="showSharePageModel = true">取消</span>
					<span class="all-btn-middle btn2" @click="showSharePageModel = true">去认证</span>
				</div>
			</div>
		</van-popup>
		<!-- 分享提示弹框 -->
		<SharePageModel @closeSharePageModel="closeSharePageModel()" :show="showSharePageModel"/>
			
		<!-- 分享顶部提示 -->
		<TopAlert />
		<!-- <van-dialog @confirm="confirm" v-model="show" show-cancel-button cancel-button-color="#666666" confirm-button-color="#15B7DD" confirm-button-text="去认证">
			<template slot="title">
				<img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
				<span>提示信息</span>
			</template>
			<span class="nocertified">购买本套餐需要完成身份认证</span>
		</van-dialog> -->
	</div>
</template>

<script>
import SharePageModel from '@/components/sharePageModel.vue';
import TopAlert from '@/components/share/topAlert.vue';
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
import nTransition from '@/components/n-transition/n-transition.vue'
import Skeleton from '@/components/Skeleton/index.vue';
import {CallCustomer} from '@/assets/js/NativeWebKit.js';
import TextOverflow from '@/components/TextOverflow.vue'
export default {
	// 可用组件的哈希表
	components: { TopAlert, ShpCarModel,nTransition,Skeleton,SharePageModel,TextOverflow },
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			showSharePageModel: false,
			stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
			navHeight: 0, //导航栏高度
			show: false,
			message:'',
			onPopup:false,
			onPopupOk:false,
			loadingBg:false,
			shopCarFlag:true, // 购物车弹框状态
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
			shopList:[],
			couponList:[],
      collected:require('@/assets/image/selected/collected.svg'),
      collect:require('@/assets/image/selected/collect.svg'),
      packageId: 0,
			activeName: '',
			autoplay: false,
			duration: 500,
			current: 0,
			swiperList:4,
			evActive: 0,
			evaluateList: ['全部（21312）','好评（21312）','中评（21312）'],
			courseDetail:null,
      active: 0,
      fromObj: {},
      FirstCollect: 0, // 首次收藏状态
			courseId:'', // 课程id
		}
	},
	// 事件处理器

	methods: {
		closeSharePageModel() {
			this.showSharePageModel = false;
		},
		// 返回
    goBack() {
      if(this.$route.query.fromMenu){
        this.$onReturn(this.$route.query.fromMenu == '1');
      }else{
        this.$onReturn(this.$route.query.fromMenu == '1');
      }
    },
		// 页面滚动监听
    FunHandleScroll() {
      // 信息条渐变值
      let pageScrollTop = document.getElementsByClassName('myCourse')[0].scrollTop
      this.stateOpacity = pageScrollTop/100
			// 导航栏添加样式
			document.getElementsByClassName('van-nav-bar')[0].style.opacity = this.stateOpacity
    },
		getNum(e){
			let num = 0;
			e.map(item=>{
				num += item.count;
			})
			if (num > 99) {
				num = '99+'
			}
			return num;
		},
		// 无认证信息弹框
		confirm(){
			this.$router.push('/mine/identityAuthentication')
		},
		// 判断该套餐是否可以购买
		isCanBuy(){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			this.$ajax({
				url:'/hxclass-mobile/course/packages/check-can-buy',
				method:'get',
				params:{
					packageId:this.fromObj.packageId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					let status = false;
					this.fromObj.productList.map(item=>{
						if(item.haveClass == 1 && item.type == 1 && !item.isBuy){
							status = true;
						}
					})
					if(status){
						this.goSelectShift();
					}else{
						this.goBuy();
					}
				}else if(res.code == 1000){ // 需购买课程
					this.onPopupOk = true;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
				}else if(res.code == 1001){ // 需提交证书
					this.onPopup = true;
					this.courseId = res.data;
					this.message = '购买该课程需要提交证书，是否提交？';
				}else if(res.code == 1002){ // 已提交证书待审核
					this.onPopupOk = true;
					this.message = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
				}else if(res.code == 1003){ // 审核被驳回
					this.onPopup = true;
					this.courseId = res.data;
					this.message = '审核被驳回了，需重新提交';
				}else if(res.code == 1004){ // 需购买课程且提交证书
					this.onPopup = true;
					this.courseId = res.data.courseId;
					this.message = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述课程证书，请提交您的证书信息，后台审核通过后方可报名。';
				}else if(res.code == 1005){ // "暂无可选择班级期数，无法进行购买"
					this.$Toast(res.message);
				}else if(res.code == 1006){// "“"+tCourse.getCourseName()+"”已购买过"
					this.$Toast(res.message);
				}else if(res.code == 1007){ // "课程”"+tCourse.getCourseName()+"“需要完成身份认证，无法购买"
					this.show = true;
				}else if(res.code == 1008){ // "您已购买本套餐中的“"+productArchives.getName()+"”无需重复购买。您可点击所选套餐中其他商品，单独下单购买。"
					this.$Toast(res.message);
				}
			})
		},
		// 套餐购买
		goBuy(){
			let productList = [];
			let list = [];
			this.fromObj.productList.map(item=>{
				productList.push({
					productId:item.productId, // 商品id
					count:item.count, // 数量")
					openTimeId:'', // 班级id
					type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
					province:'', // 省
					city:'',// 市
					noGift:1, // 是否为赠品 0否1是t
				})
			})
			list.push({
				productId:this.fromObj.packageId, // 商品id
				count:1, // 数量")
				openTimeId:'', // 班级id
				type:9, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:productList
			})
			// console.log(list);
			// this.$router.push('/curriculum/selectShift?id='+this.fromObj.packageId)
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
			this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateInvoiceId',null);
			this.$store.commit('updateAddress',null);
			this.$store.commit('updateCouponList',null);
			this.$router.push('/order/confirmOrder')
		},
		goSelectShift(){
			// let productList = [];
			// let list = [];
			// this.fromObj.productList.map(item=>{
			// 	productList.push({
			// 		productId:item.productId, // 商品id
			// 		count:item.count, // 数量")
			// 		openTimeId:'', // 班级id
			// 		type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
			// 		province:'', // 省
			// 		city:'',// 市
			// 		noGift:1, // 是否为赠品 0否1是t
			// 	})
			// })
			// list.push({
			// 	productId:this.fromObj.packageId, // 商品id
			// 	count:1, // 数量")
			// 	openTimeId:'', // 班级id
			// 	type:9, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
			// 	province:'', // 省
			// 	city:'',// 市
			// 	noGift:0, // 是否为赠品 0否1是t
			// 	productList:productList
			// })
			// console.log(list);
			// console.logmmit('updatelist',list);
			// this.$store.commit('updateType',1);
			// this.$store.commit('updateInvoiceId',null);
			// this.$store.commit('updateAddress',null);
			// this.$store.commit('updateCouponList',null);
			this.$router.push('/curriculum/selectShift?id='+this.fromObj.packageId + "&from="+this.$route.query.from)
		},
    // 唤起微信客服
		CallCustomer,
		// 选择优惠券
		onSelectCoupons(){
			this.$refs.Coupons.open()
			this.Coupons = show
		},
		treeClick(e){
			this.$emit('nodeClick', e)
		},
		// goBack(){
		// 	this.$onReturn(this.$route.query.fromMenu == '1')
		// },
		SwiperTab(e){
			this.current = e
    },
    // 跳转详情页面
    goDetail(e) {
      // 教育课程
      if (e.type == 1) {
        this.$router.push('/curriculum/course?id=' + e.dataId);
      }
      // // 图书教具
      else if (e.type == 2 || e.type == 3) {
        this.$router.push('/curriculum/BookDdetails?id=' + e.productId)
      }
      // // 模拟考试
      else if (e.type == 4) {
        this.$router.push('/examination/examDetail?id=' + e.dataId)
      }
    },
		popUp () {
			this.$refs['trainPop'].show()
		},
		close(){
			this.$refs['trainPop'].hide()
		},

		popUp1 () {
			this.$refs['trainPop1'].show()
		},
		close1(){
			this.$refs['trainPop1'].hide()
		},
		selected: function(gameName) {
			this.activeName = gameName
		},
		// 打开购物车
		openShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.shopCarList.length){
        this.shopCarFlag = !this.shopCarFlag;
      }else{
        this.$Toast('请添加商品');
      }
		},
		// 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
					this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
    },
    // 收藏/取消
    onCollection(collect){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
      this.fromObj.collect = this.fromObj.collect ? 0 : 1
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if(this.FirstCollect != this.fromObj.collect){
          this.$ajax({
            url:"/hxclass-mobile/collection/app?key=" + this.fromObj.collect + '&objectId=' + this.packageId +'&type=5' ,
            method: 'POST',
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.FirstCollect = this.fromObj.collect
            } else {
              this.fromObj.collect = this.fromObj.collect ? 0 : 1
              this.FirstCollect = this.fromObj.collect
            }
          })
        }
      }, 500);
    },
		// 获取课程详情
		getCourseDetail(){
			this.loadingBg = true
			this.$ajax({
				url:"/hxclass-mobile/packages/" +  this.packageId + "?from="+this.$route.query.from,
        method:'get',
			}).then(res=>{
				this.loadingBg = false
				if(res.code == 200 && res.success){
          this.fromObj = res.data
          this.fromObj.pcDetailPicture = JSON.parse(res.data.pcDetailPicture)
          this.FirstCollect = res.data.collect  // 记录第一次收藏状态
				}
			})
		},
		// 获取优惠卷
    getCoupons(){
			this.loadingBg = true
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type: 2, // 
          page: 1,
          size: 100,
          productIds: this.packageId
        }
      }).then(res=>{
				this.loadingBg = false
        if(res.code == 200 && res.success){
					this.couponList = res.data.records;
        }
      })
		},
		selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },
    // 领取优惠券/查看可用商品
    couponReceive(e){
      if(!e.noReceive){
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/coupon-receive',
          method:'post',
          params:{
            couponId: e.couponId,
            receiver:this.$store.state.userInfo.userId,
            receiverTime:this.selectTime(),
            state:1
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            e.noReceive = 1;
						this.$refs.shpCarModel.pageNum = 1;
						this.$refs.shpCarModel.getCoupons();
          }
        })
      }else{
        if(e.scope == 1){
					this.$router.push('/shop/shoppingMall');
          this.close1();
				}else{
					this.$router.push('/mine/couponGoods?couponId='+e.couponId);
          this.close1();
				}
      }
    },
	},
	// 生命周期-实例创建完成后调用
	created () {
		if(this.$route.query.packageId){
			this.packageId = this.$route.query.packageId;
			this.getCourseDetail();
		}

		if(this.$store.state.userInfo.userId){
			this.getShopCarList();
			this.getCoupons()
		}
		// 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
	},
	// 生命周期-实例挂载后调用
	mounted () { 
		this.$nextTick(() => {
			// 导航栏高度
			this.navHeight = document.getElementsByClassName('van-nav-bar')[0].offsetHeight
      this.FunHandleScroll();
      document.getElementsByClassName('myCourse')[0].addEventListener("scroll", this.FunHandleScroll);
    })
	},
	// 生命周期-实例销毁离开后调用
	destroyed () { 
		if (document.getElementsByClassName('myCourse')[0]) {
      document.getElementsByClassName('myCourse')[0].removeEventListener("scroll", this.FunHandleScroll);
    }
	},
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	.nocertified{
		font-size: 16px;
		color: #333333;
		line-height: 24px;
		display: block;
		padding-left: 20px;
	}
	// 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 12.5px;
    }
		.popup_button_center {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
	.myCourse{
		// 信息条
		.box_status {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background-color: #fff;
			opacity: 0;
			z-index: 100;
		}
		.courseHeader{
			height: 265px;
			// border-radius: 0 0 17px 17px;
			background: #fff;
			position: relative;
			.btnGroup{
				position: absolute;
				right: 18px;
				// top: 0;
				display: flex;
				bottom: -34px;
				align-items: center;
				.collectBox{
          text-align: center;
          min-width: 40px;
					.collect{
						width: 32px;
						height: 32px;
					}
					.collectspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}
				.tryBox{
					text-align: center;
					margin-right: 20px;
					.tryImg{
						width: 32px;
						height: 32px;
					}
					.tryspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
					}
				}

			}
			.back{
				position: fixed;
				// left: 16px;
				// top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.share{
				position: absolute;
				right: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
			}
			.swiper{
				height: 265px;
				.swiperImg{
					width: 100%;
					height: 265px;
					background-repeat: no-repeat;
          object-fit: cover;
					// border-radius: 0 0 17px 17px;
				}
				/deep/ .uni-swiper-dot-active {
					width: 16px !important;
					height: 4px !important;
				    background-color: #fff !important;
					border-radius: 2px;
				}
				/deep/ .uni-swiper-dot {
				    display: inline-block;
				    width: 4px;
				    height: 4px;
				    cursor: pointer;
				    background: rgba(255, 255, 255, 0.6);
				    // border-radius: 0%;
				}
			}
			/* 指示器样式 */
			.u-indicator-item-number {
				width: 20px;
				padding: 7.5px;
				line-height: 1;
				background-color: rgba(0, 0, 0, 0.35);
				border-radius: 50px;
				font-size: 11px;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				right: 20px;
				bottom: 15px;
				text-align: center;
				letter-spacing: 1.5px;
			}
		}
		.courseExplain{
			padding: 18px;
			// height: 116.5px;
			background: #fff;
			// margin-top: 8px;
			.explainUp{
				display: flex;
				margin-top: 10px;
				.left{
					flex: 1;
					line-height: 19px;
					align-items: center;
					display: flex;
					.label{
					  width: 42px;
            height: 19px;
            display: inline-block;
            // background: linear-gradient(80deg, #FFC243 0%, rgba(255, 188, 52, 0) 100%);
            // background: url(~@/assets/image/selected/tag.png) no-repeat;
            // border-radius: 0px 7px 7px 7px;
            margin-right: 4px;
            img{
              width: 100%;
              height: 100%;
            }
					}
					.price{
						font-size: 20px;
						font-weight: 400;
						color: #FF7A00;
    				font-family: PingFang SC-Regular, PingFang SC;
						line-height: 13px;
					}
					.originalPrice{
						font-size: 10px;
						font-weight: 400;
						color: #999999;
						font-family: PingFangMedium-Regular;
						margin-left: 10px;
						margin-top: 7px;
					}
				}
				.right{
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}
			.explainCenter{
				font-size: 18px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #333333;
				line-height: 27px;
				margin-top: 6px;
			}
			.explainDown{
					font-family: PingFangMedium;

				.courseType{
					display: flex;
					margin: 12px 0 10px 0;
					.left{
						flex: 1;
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						line-height: 20px;
					}
					.right{
						font-size: 11px;
						font-weight: 500;
						color: #15B7DD;
						line-height: 16px;
					}

				}
				.fload{
					display: -webkit-box;
					word-break: break-all;
					-webkit-box-orient: vertical;
					/* 要显示多少行就改变line-clamp的数据,此处折叠起来显示一行*/
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 6px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
					line-height: 15px;
				}
			}
		}
		.couponBox{
			padding: 18px;
			background: #fff;
			margin-top: 8px;
			font-family: PingFangMedium;
			.item{
				display: flex;
				align-items: center;
				.left{
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					line-height: 20px;
					flex: 1;
				}
				.right{
					flex: 5;
					// margin-right: 12px;
					display: flex;
					align-items: center;
					.rightBox{
						display: flex;
						justify-content: space-between;
						flex: 1;
						padding-right: 12px;
						.tag{
							width: 28px;
							height: 16px;
							background: rgba(#F93C00, 0.1);
							border-radius: 2px;
							font-size: 10px;
							font-weight: bold;
							color: #F93C00;
							line-height: 16px;
							text-align: center;
							padding: 1px 4px;
						}
						.downitem{
							// margin: 1px 10px 5px;
							font-size: 10px;
							font-weight: 400;
							color: #F93C00;
							line-height: 18px;
							height: 18px;
							text-align: center;
							position: relative;
							padding: 0 5px;
							background-image: url(~@/assets/image/selected/20220610-145020.png);
							background-position: center;
							background-size: 1px 18px;
							&::before,&::after{
								content: '';
								width: 4px;
								display: block;
								position: absolute;
								top: 0;
								left: -4px;
    						height: 100%;
								background-image: url(~@/assets/image/selected/left.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 4px 18px;
								transform: rotate(180deg);
							}
							&::after{
								content: '';
								left: auto;
								right: -4px;
								transform: rotate(0deg);
							}
						}
					}
				}
			}
		}
		.combination{
			padding: 18px;
			padding-bottom: 0;
			background: #fff;
			margin-top: 8px;
			.combinationTitle{
				span{
					font-size: 18px;
				font-family:  PingFangMedium;
					color: #333333;
					line-height: 28px;
					font-weight: bold;
				}
			}
			.combinationBox{
				margin-top: 12px;
				font-family:  PingFangMedium;
				.item{
					display: flex;
					padding-bottom: 16px;
					.courseImg{
						width: 100px;
						height: 70px;
            border-radius: 7px;
					}
					.right{
						flex: 1;
						padding: 2.5px 0 0 11px;
						position: relative;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.title{
							display: flex;
							font-size: 14.5px;
							font-weight: 400;
							color: #333333;
							// line-height: 18px;
						}
						.dlex{
							display: flex;
							justify-content: space-between;
								margin-top: 6px;
							align-items: center;
							.price{
								font-weight: 500;
								color: #FF7A00;
								line-height: 14px;
								font-family: PingFangMedium;
								font-size: 14px;
								// font-weight: bold;
							}
							.cuntent{
								font-size: 12px;
								font-weight: 400;
								color: #999999;
								// line-height: 14.5px;
							}
						}
						
						.identification{
							font-size: 11px;
							font-weight: 400;
							color: #1E97B4;
							line-height: 15.5px;
						}
					}
				}
			}

		}
		.details{
			// padding: 18px;
			background: #fff;
			margin-top: 8px;
			.detailsTitle{
				.up{
					font-size: 18px;
					font-family: PingFang SC-Medium, PingFang SC;
					font-weight: 500;
					color: #333333;
					line-height: 28px;
					padding: 16px 0 0 0;
					text-align: center;
					img{
						width: 56px;
						height: 8px;
						vertical-align: middle;
					}
				}
				.down{
					padding: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
          line-height: 28px;
          /deep/strong{
						font-weight: bold;
					}
					/deep/em{
						font-style: italic;
					}
					/deep/img{
						max-width: 100%;
					}
					/deep/.remarks{
						max-width: 100%;
						height: auto !important;
						-webkit-line-clamp: 10000 !important;
					}
				}
			}
		}
		.footBtn{
			width: 100%;
			position: fixed;
			bottom: 0;
			z-index: 10;
			// padding: 5px;
			overflow: hidden;
			background: #FFFFFF;
			display: flex;
			box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
			.collect{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				margin-left: 5px;
				padding: 8px 0;
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
				}
				.collectImg{
					height: 18px;
				}
			}
			.freediving{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
        padding: 8px 0;
        position: relative;
				.freedivingImg{
					height: 18px;
				}
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
        }
        .num{
          position: absolute;
          font-style: normal;
          text-align: center;
          // width: 14px;
          // height: 14px;
					min-width: 14px;
          border-radius: 999px;
					padding: 0 3px;
          top: 0px;
          right: 4px;
          background: #EC6C01;
          font-size: 10px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 14px;
        }
			}
			.btnGroup{
				flex: 4;
				display: flex;
				// background: url(~@/assets/image/selected/btnGroup.svg) no-repeat;
				background-size:100% 100%;
				height: 40px;
				line-height: 40px;
				margin: 2px 7.5px 0 7.5px;
				.shoppingCart{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyspan{
						font-size: 10px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
				.purchase{
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: right;
          padding-right: 10px;
					text-align: right;
					line-height: 36px;
					.buyspan{
            display: inline-block;
            background: #0CA4C8;
            width: 100%;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.moneySign{
			font-size: 18px;
			font-weight: 400;
		}
		// 培训地点选择
		.popContentBox{
			.popBox{
				.titleBox{
					padding: 28px 34px 0 34px;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 25px;
					display: flex;
					span{
						display: inline-block;
					}
					.title{
						flex: 1;
					}
				}
				.popContent{
					padding: 0 18px;
					.popItem{
						padding: 16px 12px;
						box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
						border-radius: 8px;
						margin-bottom: 8px;
						.up{
							display: flex;
							.left{
								font-size: 14px;
								font-weight: bold;
								color: #333333;
								line-height: 20px;
								flex: 1;
							}
							.right{
								font-size: 12px;
								font-weight: 400;
								color: #666666;
								line-height: 17px;
								.num{
									color: #15B7DD;
								}
							}
						}
						.center{
							padding: 8px 0;
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 17px;
						}
						.down{
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 20px;
						}
						&.pick {
							background: #15B7DD;
							box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
							.up{
								.left,.right{
									color: #FFFFFF
								}
							}
							.center,.down,.num{
								color: #FFFFFF !important;
							}
						}
					}
					.popItem:first-child{
						margin-top: 16px;
					}
				}
			}
			.popbutton{
				box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				padding: 5px 0;
				position: fixed;
				width: 100%;
				bottom: 0;
				.btn{
					width: 295px;
					height: 40px;
					display: block;
					text-align: center;
					margin: 0 auto;
					line-height: 40px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
		}
	}
	.windowCoupons{
			background-color: #fff;
			border-radius: 24px 24px 0 0;
			padding: 0 5px;
			.navBar{
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 18px 0;
				.center{
					font-size: 18px;
					line-height: 25px;
					color: #333;
				}
				.right{
					position: absolute;
					right: 11px;
				}
			}
		}
		.coupon{
			padding: 0 13px;
			padding-bottom: 25px;
			overflow-y: scroll;
			height: 320px;
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			border-radius: 6px;
			// border: 1px solid #F93C00;
			display: flex;
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
				flex-direction: column;
				text-align: center;
        width: 90px;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-bottom: 1px solid #EC6C01;
				// 	// border-top: 1px solid #F7F7F9;
				// 	border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -1px;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #fff;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-top: 1px solid #EC6C01;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	bottom: -1px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #fff;
				// }
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 9;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
	/deep/.van-swipe__indicators{
		.van-swipe__indicator{
			background-color: #fff;
			opacity: 0.6;
			border-radius: 3px;
			transition: width 0.3s;
			height: 4px;
			width: 4px;
		}
		.van-swipe__indicator--active{
			opacity: 1;
			width: 16px;
		}
	}
	// 评价导航栏
	/deep/.van-tabs-circle {
		.van-tabs__nav--line {
			height: 28px;
		}
		.van-tab {
			height: 28px;
			line-height: 28px;
			font-size: 12px;
			color: #666666;
			border-radius: 13px;
			background: rgba(21, 183, 221, 0.1);
			margin-right: 24px;
			padding: 4px 12px;
		}
		.van-tab--active {
			color: #fff;
			background: #15B7DD;
		}
		.van-tabs__line {
			display: none;
		}
	}
</style>
