<template>
  <div>
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="提交作业"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="content">
      <div class="homeWorkDetail" :class="{boxHeight: flag}">
        <div class="title-box">
          <div class="flag" @click="flag = false" v-if="flag">
            <p></p>
            <p><span>展开</span><van-icon name="arrow-down" /></p>
          </div>
          <div class="title">
            <img src="@/assets/image/task/submitTitle.png" alt="">
            <p>{{data.homeworkTitle}}</p>
          </div>
          <!-- <p class="warn">请根据给出的以下目标，编写教案。</p> -->
          <div class="timeEnd">
            <img src="@/assets/image/task/timeEnd.png" alt="">
            <p>截止上传时间：{{endTime(data.answerEnddate)}}</p>
          </div>
        </div>
        <div class="note" v-html="data.libraryContent"></div>
      </div>
      
      <div class="form" v-if="data.submitFormatList">
        <!-- 文件上传 -->
        <div class="uploadFile" v-if="data.submitFormatList.indexOf(1) != -1 || data.submitFormatList.indexOf(2) != -1 || data.submitFormatList.indexOf(3) != -1">
          <div class="flag">
            <img src="@/assets/image/task/fileIcon.png" alt="">
            <p>上传附件</p>
            <span class="warn">* 附件必须以{{fileType(1)}}的形式上传</span>
          </div>
          <div class="list_upload" v-if="!files.length" @click="openuploadModel">
            <img class="upload_icon" src="@/assets/image/task/uploadLogo.png" />
            <span class="upload_txt">
              (支持{{fileType()}}格式)</span>
          </div>
          <template v-else>
            <div class="fileList">
              <div class="fileItem" v-for="(item,index) in files" :key="index">
                <div class="closeIcon" @click="delFile(index)"><img src="@/assets/image/task/closeIcon.png" alt=""/></div>
                <!-- fileIcon -->
                <img src="@/assets/image/task/fileIcon.png" v-if="isfileType(item.type,'file')" alt="">
                <!-- wordIcon -->
                <!-- <img src="@/assets/image/task/wordIcon.png" v-if="isfileType(item.url,'file')" alt=""> -->
                <!-- videoIcon -->
                <img src="@/assets/image/task/videoIcon.png" v-if="isfileType(item.type,'mp4')" alt="">
                <!-- txt -->
                <!-- <img src="@/assets/image/task/txtIcon.png" v-if="isfileType(item.url,'txt')" alt=""> -->
                <!-- pdf -->
                <!-- <img src="@/assets/image/task/pdfIcon.png" v-if="isfileType(item.url,'pdf')" alt=""> -->
                <!-- 图片 -->
                <img :src="item.url" v-if="isfileType(item.type,'img')" alt="">
                <p v-if="!isfileType(item.type,'mp4') && !isfileType(item.type,'img')">{{item.name}}</p>
              </div>
              <div class="uploadBtn" @click="openuploadModel">
                <img src="@/assets/image/task/add.png">
                <p>上传文件</p>
              </div>
            </div>
          </template>
        </div>

        <!-- 作业文本 -->
        <div class="testInput" v-if="data.submitFormatList.indexOf(0) != -1">
          <div class="flag">
            <img src="@/assets/image/task/testIcon.png" alt="">
            <p>作业文本</p>
          </div>
          <van-field
            v-model="content"
            rows="2"
            autosize
            label=""
            type="textarea"
            placeholder="请输入作业内容文本"
            input-align="left"
          />
        </div>
      </div>
    </div>
    <div class="footBtn" :style="{top: (pageHeight - 62) + 'px'}">
      <van-button class="staging" block type="info" @click="submit(1)">保存编辑</van-button>
      <van-button class="submit" block type="info" @click="submit(3)">提交作业</van-button>
    </div>

    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block">
          <p class="title">请选择上传格式</p>
          <div class="type">
            <div class="album" @click.stop="upload(1)">
              <img src="@/assets/image/task/album.png" alt="">
              <p>相册</p>
            </div>
            <div class="file" @click.stop="upload(4
            )">
              <img src="@/assets/image/task/file.png" alt="">
              <p>文件</p>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getClientHeight } from '@/unit/fun.js'
import { uploadFile, getIOSClientHeight } from '@/assets/js/NativeWebKit.js'
import { FunJudgDevice } from "@/unit/fun.js";
const DeviceType = FunJudgDevice();
export default {
  data() {
    return {
      show: false,
      pageHeight: 0, //页面视图高度
      answerId: null, // 作业Id
      data: {}, // 作业详情
      files: [],
      content: '',
      flag: true
    }
  },
  created() {
    this.answerId = this.$route.query.id;
    this.getData();
    // 选择照片后回调
    window.returnFileUrl = (url,name,type)=>{
      this.files.push({
        url,
        name,
        type
      })
    }

    window.getClientHeight = (e)=>{
      console.log(1)
      this.pageHeight = e;
    }

    this.$nextTick(() => {
      if(DeviceType == "ios" && window.webkit) {
        return getIOSClientHeight();
      }
      console.log(2)
      this.pageHeight = getClientHeight();
    })
  },
  mounted() {
  },
  methods: {
    // 删除文件
    delFile(e) {
      this.files.splice(e,1);
    },

    // 返回是否符合
    isfileType(e,type) {
      if(type == 'file'){
        if(e == 3) {
          return true;
        }
        return false;
      }else if(type == 'img') {
        if(e == 1) {
          return true;
        }
        return false;
      }else if(type == 'mp4') {
        if(e == 2) {
          return true;
        }
        return false;
      }
    },

    // isfileType(url,type) {
    //   let arr = url.split('.');
    //   arr[arr.length-1] = arr[arr.length-1].toLowerCase();
    //   if(type == 'word'){
    //     if(arr[arr.length-1] == 'doc' || arr[arr.length-1] == 'docx') {
    //       return true;
    //     }
    //     return false;
    //   }else if(type == 'img') {
    //     if(arr[arr.length-1] == 'jpg' || arr[arr.length-1] == 'png' || arr[arr.length-1] == 'gif' || arr[arr.length-1] == 'jpeg') {
    //       return true;
    //     }
    //     return false;
    //   }else if(type == 'txt') {
    //     if(arr[arr.length-1] == 'txt') {
    //       return true;
    //     }
    //     return false;
    //   }else if(type == 'pdf') {
    //     if(arr[arr.length-1] == 'pdf') {
    //       return true;
    //     }
    //     return false;
    //   }else if(type == 'mp4') {
    //     if(arr[arr.length-1] == 'mp4') {
    //       return true;
    //     }
    //     return false;
    //   }
    // },
    upload(e) {
      if(e == 1) {
        if(this.data.submitFormatList.indexOf(1) != -1 && this.data.submitFormatList.indexOf(2) != -1) {
          // 上传图片和视频
          uploadFile(3)
        }else if(this.data.submitFormatList.indexOf(1) != -1) {
          // 上传视频
          uploadFile(2)
        }else if(this.data.submitFormatList.indexOf(2) != -1) {
          // 上传图片
          uploadFile(1)
        }
      }else {
        // 上传文件
        uploadFile(4)
      }
      this.show = false;
    },

    // 打开上传弹框
    openuploadModel() {
      if((this.data.submitFormatList.indexOf(1) != -1 || this.data.submitFormatList.indexOf(2) != -1) && this.data.submitFormatList.indexOf(3) != -1){
        // 图片视频和文件
        this.show = true;
      }else if(this.data.submitFormatList.indexOf(1) != -1 || this.data.submitFormatList.indexOf(2) != -1) {
        // 图片或视频
        this.upload(1);
      }else if(this.data.submitFormatList.indexOf(3) != -1) {
        // 文件
        this.upload(4);
      }
    },

    fileType(e) {
      let arr = [];
      let textArr = [];
      if(this.data.submitFormatList.indexOf(1) != -1) {
        arr.push('mp4');
        textArr.push('视频');
      }
      if(this.data.submitFormatList.indexOf(2) != -1) {
        arr.push('jpg、png、jpeg');
        textArr.push('图片');
      }
      if(this.data.submitFormatList.indexOf(1) != -1 && this.data.submitFormatList.indexOf(2) != -1 && this.data.submitFormatList.indexOf(3) != -1) {
        arr[1] = arr[1]+'、doc...'
        textArr.push('文件');
      }else if(this.data.submitFormatList.indexOf(3) != -1) {
        arr.push('doc、docx、txt、pdf');
        textArr.push('文件');
      }
      if(e) {
        return textArr.join('和')
      }
      return arr.join('、')
    },
    endTime(e) {
      if(!e){
        return '';
      }
      let str = e.split('T').join(' ');
      str = str.substring(0,16);
      return str;
    },

    // 3：提交 / 1：暂存 
    submit(e) {
      if(!this.files.length && (this.data.submitFormatList.indexOf(1) != -1 || this.data.submitFormatList.indexOf(2) != -1 || this.data.submitFormatList.indexOf(3) != -1)){
        return this.$Toast("请上传文件");
      }else if(this.data.submitFormatList.indexOf(0) != -1 && !this.content) {
        return this.$Toast("请输入作业文本！");
      }
      for(let i = 0;i < this.data.submitFormatList.length;i++){
        let arr = [];
        if(this.data.submitFormatList[i] == 1) {
          arr = this.files.filter(file => {
            return this.isfileType(file.type,'mp4');
          })
          if(!arr.length) {
            return this.$alert('请上传视频')
          }
        }
        if(this.data.submitFormatList[i] == 2) {
          arr = this.files.filter(file => {
            return this.isfileType(file.type,'img');
          })
          if(!arr.length) {
            return this.$alert('请上传图片')
          }
        }
        if(this.data.submitFormatList[i] == 3) {
          arr = this.files.filter(file => {
            return this.isfileType(file.type,'file');
          })
          if(!arr.length) {
            return this.$alert('请上传文件')
          }
        }
      }
      
      let params = {
        answerId: this.answerId,
        attachlist: JSON.stringify(this.files),
        content: this.content,
        status: e
      }
      this.$ajax({
        url: '/hxclass-mobile/homework/submit',
        method: 'POST',
        params
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.$onReturn(this.$route.query.fromMenu == '1');
        }else {
          this.$alert(res.message);
        }
      })
    },

    // 富文本取文本
		getText(str) {
      if (!str) {
        return
      }
      return str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "");
    },

    // 获取详情数据
    getData() {
      this.$ajax({
        url: '/hxclass-mobile/homework/userAnswer/details',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.data = res.data;
          this.content = res.data.answerContent;

          let text = this.getText(res.data.libraryContent)
          if(text.length < 50) {
            this.flag = false;
          }
          if(res.data.attachlist){
            this.files = JSON.parse(res.data.attachlist);
          }
        }else {
          this.$alert(res.message);
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }

  .fileList{
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    .fileItem{
      width: 70px;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      .closeIcon{
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: 0;
        img{
          width: 20px;
          height: 20px;
        }
      }
      img{
        width: 70px;
        height: 70px;
      }
      p{
        margin-top: 6px;
        width: 70px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 15px;
      }
    }
    .uploadBtn{
      width: 70px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding-top: 17px;
      text-align: center;
      img{
        width: 19px;
        height: 19px;
      }
      p{
        margin-top: 4px;
        font-size: 10px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #C5C5C5;
        line-height: 15px;
      }
    }
  }

  .block {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background-color: #fff;
    padding: 23px 25px;
    .title{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: 18px;
      text-align: center;
    }
    .type{
      display: flex;
      justify-content: space-around;
      padding-top: 25px;
      border-top: 1px solid #EFEFEF;
      margin-top: 12px;
      .album,
      .file{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px 10px 10px 10px;
        img{
          width: 56px;
          height: 48px;
          margin-bottom: 14px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }
      }
    }
  }
  /deep/.van-cell{
    background: #FCFBFC;
    border-radius: 5px 5px 5px 5px;
    margin-top: 12px;
  }
  .list_upload_img {
    box-sizing: border-box;
    width: 100%;
    margin-top: 12.5px;
  }
  /deep/.van-uploader{
    margin-top: 12px;
  }
  /deep/.van-uploader,/deep/.van-uploader__wrapper,/deep/.van-uploader__input-wrapper{
    width: 100%;
    height: 70px;
    background: #FCFBFC;
    border-radius: 5px 5px 5px 5px;
  }
  .list_upload{
    margin-top: 12px;
    background: #FCFBFC;
    border-radius: 5px 5px 5px 5px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 32px;
      height: 24px;
      margin-right: 13px;
    }
    .upload_txt{
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #CDCDCD;
      line-height: 17px;
    }
  }
  .content{
    padding: 15px 15px 90px;
    .boxHeight{
      height: 174px;
    }
    .homeWorkDetail{
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      background: #FFFFFF;
      box-shadow: 3px 0px 5px 0px rgba(93,131,167,0.05);
      border-radius: 10px 10px 10px 10px;
      .title-box{
        padding: 12px 15px;
        background: #FCFBFC;
      }
      .flag{
        color: #15B7DD;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        left: 0;
        bottom: 0;
        text-align: center;
        height: 51px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 51%, #FFFFFF 100%);
      }
      .title{
        display: flex;
        align-items: center;
        img{
          width: 13px;
          height: 15px;
          margin-right: 5px;
        }
        p{
          font-size: 17px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 26px;
        }
      }
      .warn{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-top: 16px;
      }
      .timeEnd{
        display: flex;
        align-items: center;
        img{
          width: 9px;
          height: 9px;
          margin-right: 3px;
        }
        p{
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 15px;
        }
      }
      .note{
        padding: 12px 15px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 23px;
        /deep/img{
          width: 100% !important;
        }
      }
    }
    .form{
      background: #FFFFFF;
      box-shadow: 3px 0px 5px 0px rgba(93,131,167,0.05);
      border-radius: 10px 10px 10px 10px;
      margin-top: 10px;
      padding: 12px 15px 14px;
    }
    .uploadFile,
    .testInput{
      .flag{
        display: flex;
        align-items: center;
        p{
          white-space: nowrap;
        }
        img{
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }
        .warn{
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #F90000;
          line-height: 15px;
          margin-top: 4px;
          margin-left: 12px;
        }
      }
    }
    .testInput{
      margin-top: 14px;
      padding-top: 12px;
      border-top: 1px solid #EFEFEF;;
    }
  }
  .footBtn{
    width: 100vw;
    padding: 11px 31px;
    box-sizing: border-box;
    background: #ccc;
    position: fixed;
    // bottom: 0;
    overflow: hidden;
    box-shadow: 0px -1px 1px 1px rgba(153,153,153,0.3);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background: rgba(255,255,255,0.8);
    box-shadow: 0px -2px 2px 0px rgba(0,0,0,0.04);
    .staging{
      width: calc((100vw - 87px) / 2);
      height: 40px;
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid #333333;
      background: #ffffff;
      color: #333333;
    }
    .submit{
      width: calc((100vw - 87px) / 2);
      height: 40px;
      border-radius: 20px 20px 20px 20px;
      border: 1px solid #15B7DD;
      background: #15B7DD;
      color: #ffffff;
    }
  }
</style>