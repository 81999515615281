// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/icon/20220216041503.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-box[data-v-459cf898]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x;background-size:100%;height:219px;text-align:center;font-size:18px;font-family:PingFangMedium;font-weight:700;color:#fff;line-height:13.5px;position:relative;padding-top:60px}.header-box .back[data-v-459cf898]{width:32px;height:32px;position:absolute;left:20px;top:68px;z-index:9999}.header-box .back img[data-v-459cf898]{width:100%;height:100%}.header-box .auto[data-v-459cf898]{margin-top:28px;line-height:30px}.header-box .auto .num[data-v-459cf898]{margin-right:5px;font-size:25px}.all-bg[data-v-459cf898]{padding-top:1px;margin:0 18px;padding-bottom:5px;border-radius:10px;background:#fff;box-shadow:0 2px 5px 1px rgba(0,0,0,.05);position:relative;top:-82px}.date-box .bg-img[data-v-459cf898]{position:absolute;left:-5px;top:-5px;width:50px}.date-box .count .daynumber[data-v-459cf898]{display:flex;flex-direction:row;justify-content:center}.date-box .count .daynumber .day[data-v-459cf898]{margin-top:25px}.date-box .count[data-v-459cf898]{padding:15px;text-align:center;border-radius:5px;background-color:#fff;align-items:center}.date-box .count[data-v-459cf898],.date-box .count .number[data-v-459cf898]{margin:10px;display:flex;flex-direction:column;justify-content:center}.date-box .count .number[data-v-459cf898]{color:#fff;font-size:30px;background-color:#94db98;width:50px;height:50px;border-radius:50%}.date-box .monthSum[data-v-459cf898]{color:red;font-size:20px}.date-box .count text[data-v-459cf898]{margin:5px}.title[data-v-459cf898]{position:relative;top:10px;font-size:18px}", ""]);
// Exports
module.exports = exports;
