<template>
  <div class="container-box">
    <!-- 导航栏 -->
    <!-- <van-nav-bar
      title="个人头像"
      :fixed="true"
      :placeholder="true"
      right-text="修改"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="onHeadClick()"
      class="list"
    /> -->
    <van-sticky :offset-top="30">
      <div class="vtop ">
        <div class="list">
          <div class="left" @click="$onReturn($route.query.fromMenu == '1')">
            <img src="@/assets/image/my/20220812.png" alt="" />
          </div>
          <div class="centent">个人头像</div>
          <div class="right" @click="onHeadClick()">修改</div>
        </div>
      </div>
    </van-sticky>

    <div class="main-box">
      <div class="info-box">
        <van-image
          class="right-image"
          fit="cover"
          :src="$store.state.userInfo.photo || defaultPhoto"
        />
      </div>
    </div>
    <n-transition ref="pop" speed="ease-in-out" :height="192" :maskVal="0.5">
      <div class="card-box" v-if="pageType == 3">
        <div class="select">
          <van-uploader type="file" :before-read="beforeRead" :after-read="uploadImg"
            >从手机相册选择</van-uploader
          >
        </div>
        <div class="select">
          <van-uploader capture="camera"  :after-read="uploadImg"
            >拍照</van-uploader
          >
        </div>
        <div class="select cancel" @click="onCancel()">取消</div>
      </div>
      <!-- v-if="pageType==2" -->
      <div class="card-box" v-if="pageType == 1 || pageType == 2">
        <div class="select" @click="onPhoto()">从手机相册选择</div>
        <div class="select" @click="onCamera()">拍照</div>
        <div class="select cancel" @click="onCancel()">取消</div>
      </div>
    </n-transition>

    <!-- 上传中 -->
    <div class="box_load" v-if="isLoading">
      <!-- <van-loading size="24px" color="#1e97b4" vertical>正在上传...</van-loading> -->
    </div>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
import { dataURLtoFile } from "@/unit/fun.js"; //base64转为对象
import { FunJudgDevice } from "@/unit/fun.js";
import { onTakePhoto, onChoosePhoto } from "@/assets/js/NativeWebKit.js";
const DeviceType = FunJudgDevice();
export default {
  // 可用组件的哈希表
  components: { nTransition },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      pageType: 0, //1安卓 2ios 3浏览器
      isName: false, //是否设置昵称
      // 默认头像
      defaultPhoto: require("@/assets/image/my/20220815.png"),
      // 头像
      photo: "",
      isLoading: false, //是否正在上传
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 安卓
    if (DeviceType == "android") {
      this.pageType = 1;
    } else if (DeviceType == "ios" && window.webkit) {
      // ios
      this.pageType = 2;
    } else {
      // 浏览器
      this.pageType = 3;
    }

    // app选择照片后回调
    window.returnPhotoUrl = (url) => {
      // console.log('返回图片地址',url)
      var file = dataURLtoFile(url, "fileName.jpg");
      // 上传图片
      this.uploadImg(file, 1);
    };

    //// console.log
    window.returnOnCancel = () => {
      this.onCancel();
    };
  },
  // 事件处理器
  methods: {
    // 修改用户名
    toNickName() {
      this.$router.push("/mine/setNickName");
    },
    onHeadClick() {
      this.$refs["pop"].show();
    },
    onCancel() {
      this.$refs["pop"].hide();
    },
    // 打开相册
    onPhoto() {
      // 1裁剪图片
      // window.webkit.messageHandlers.photo.postMessage('1')
      // 1裁剪 0不裁剪
      // 1方的不能随意拖动 0可以随意拖动
      onChoosePhoto(1, 1);
    },
    // 拍照
    onCamera() {
      // 1裁剪图片
      // window.webkit.messageHandlers.camera.postMessage('1')
      // 1裁剪 0不裁剪
      // 1方的不能随意拖动 0可以随意拖动
      onTakePhoto(1, 1);
    },
    // 从相册选择
    uploadImg(e, type) {
      let file = type == 1 ? e : e.file;
      this.isLoading = true;
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          // console.log(e)
          this.photo = e;
          // 修改头像
          this.setPhoto();
        },
        fail: (err) => {
          this.isLoading = false;
          this.$Toast("上传失败", err);
        },
      });
    },
    beforeRead(file){
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$Toast("请上传正确格式图片文件");
      }
      const isLt2M = file.size / 1024 / 1024 < 2.5;
      if (!isLt2M) {
        this.$Toast("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 修改头像
    setPhoto() {
      this.$ajax({
        url:
          "/hxclass-mobile/user/update?isSysUser=0&photo=" +
          this.photo +
          "&userId=" +
          this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.isLoading = false;
          this.$Toast.success("修改成功");
          // 修改缓存昵称
          let obj = JSON.parse(localStorage.getItem("userInfo"));
          obj.photo = this.photo;
          this.$store.state.userInfo = obj;
          localStorage.setItem("userInfo", JSON.stringify(obj));
          // console.log
          this.$refs["pop"].hide();
        }
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.info-box {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  img {
    background-repeat: no-repeat;
    object-fit: cover;
  }
}

/deep/.van-nav-bar {
  position: relative;
  z-index: 1;
  line-height: 22px;
  text-align: center;
  background-color: #000000;
  -webkit-user-select: none;
  user-select: none;
}
/deep/.van-nav-bar .van-icon {
  color: #ffffff;
}
/deep/.van-nav-bar__placeholder {
  background-color: #ffffff;
}
/deep/ .van-nav-bar__text {
  color: #ffffff;
}
/deep/.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}
.container-box {
  height: 100vh;
  background-color: #000000;
  .vtop {
    height: 46px;
    z-index: 19;
    line-height: 22px;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
  }
  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 18px;
    position: relative;

    .left {
      width: 10%;
      text-align: left;
      img {
        width: 12px;
      }
    }
    .centent {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .right {
      text-align: right;
      width: 12%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .card-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 18px 0;

    .select {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      text-align: center;
      line-height: 54px;
    }

    .cancel {
      border-bottom: none;
      color: #666666;
    }
  }

  // 上传
  .box_load {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
</style>
