import { render, staticRenderFns } from "./shareInformation.vue?vue&type=template&id=4ab8ef46&scoped=true&"
import script from "./shareInformation.vue?vue&type=script&lang=js&"
export * from "./shareInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab8ef46",
  null
  
)

export default component.exports