<template>
  <div id="window" ref="window">
    <div id="content">
      <router-view/>
    </div>
    <van-tabbar 
      v-if="isBottomBar"
      v-model="active" 
      active-color="#00B3C5" 
      inactive-color="#999" 
      @nav-bar-height="75"
      :fixed="false">
      <!-- <van-tabbar-item to="/" badge="20"> -->
      <van-tabbar-item to="/home">
        <template #icon="props">
          <img :src="
            props.active ?
            require('@/assets/image/tabBar/icon_home_cked.png') :
            require('@/assets/image/tabBar/icon_home.png')"
          />
        </template>
        <span>首页</span>
      </van-tabbar-item>
      <van-tabbar-item to="/selected">
        <template #icon="props">
          <img :src="
            props.active ?
            require('@/assets/image/tabBar/icon_selected_cked.png') :
            require('@/assets/image/tabBar/icon_selected.png')"
          />
        </template>
        <span>精选</span>
      </van-tabbar-item>
      <van-tabbar-item @click="toUrl()">
        <template #icon="props">
          <img :src="
            props.active ?
            require('@/assets/image/tabBar/icon_study_cked.png') :
            require('@/assets/image/tabBar/icon_study.png')"
          />
        </template>
        <span>学习</span>
      </van-tabbar-item>
      <van-tabbar-item to="/my" :dot="messageNum">
        <template #icon="props">
          <img :src="
            props.active ?
            require('@/assets/image/tabBar/icon_my_cked.png') :
            require('@/assets/image/tabBar/icon_my.png')"
          />
        </template>
        <span>我的</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import AudioMin from '@/components/Audio/indexMin.vue'
import { onGoStudy } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: {
    AudioMin
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      messageNum: false,
      active: 0,
      stateHeight: 0,
      isBottomBar: true
    }
  },
  // 事件处理器
  methods: {
    // 获取未读消息数量
    getMessageNum(){
      if(!this.$store.state.userInfo.userId){
        return
      }
      this.$ajax({
        url:'/hxclass-mobile/user/msg/app/num',
        method:'get'
      }).then(res=>{
        if (res.code == 200 && res.success) {
          this.messageNum = res.data?true:false;
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 重置首页四个tab的位置
    FunResetFullPath(){
      const fullPath = this.$route.fullPath

      // 回到页面顶部
      if(fullPath == '/home' || fullPath == '/selected' || fullPath == '/study' || fullPath == '/my'){
        document.getElementById("content").scrollTop = 0
      }

      if(fullPath == '/home'){
        this.$set(this, 'active', 0)
      }else if(fullPath == '/selected'){
        this.$set(this, 'active', 1)
      }else if(fullPath == '/study'){
        this.$set(this, 'active', 2)
      }else if(fullPath == '/my'){
        this.$set(this, 'active', 3)
      }
    },
    toUrl(){
      if (this.$store.state.userInfo.isLogin) {
        // this.$router.push("/study");
        onGoStudy();
      }else{
        this.$router.push('/login/login');
      }
    },

    watchUrl(){
      if(this.$route.path == '/guidePage'){
        this.isBottomBar = false
      } else {
        this.isBottomBar = true
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.watchUrl()
    this.getMessageNum()
    // this.FunResetFullPath()
    this.stateHeight = this.$parent.$data.stateHeight
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.FunResetFullPath()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(){
      this.watchUrl()
      this.FunResetFullPath()
    }
  }
}
</script>

<style lang="less" scoped>
#window{
  height: 100vh;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  #content{
    flex: 1;
    background-color: #f5f5f5;
    overflow: auto;
  }
}
.van-tabbar-item__icon img {
  height: 24px;
}
/deep/.van-tabbar-item--active .van-tabbar-item__text {
  color: #333;
}
/deep/.van-tabbar{
  height: 58px;
}
/deep/.van-tabbar-item__icon{
  margin-bottom: 1px;
}
/deep/.van-tabbar-item__text{
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}
</style>
