<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="教师查询"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 查询条件输入框 -->
    <div class="content-box">
      <div class="all-white-bg">
        <div class="input-box">
          <van-field class="input" placeholder="请输入姓名" v-model="name" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入证件号" v-model="idcard" />
        </div>
      </div>
      <div class="select-btn">
        <span class="all-btn-bigCircle" @click="queryData()">立即查询</span>
      </div>
      
      <!-- 查询结果 -->
      <div class="result-box" v-if="isShowData">
        <span class="all-title">查询结果</span>
        <!-- <div class="all-count">搜索数据：<span>1</span>条</div> -->
        <div class="result-item">
          <div class="child-item" v-if="dataObj.name">
            <div class="p title">{{dataObj.name}}</div>
            <div class="p idcard">身份证号：{{manageIdcard(dataObj.idNo)}}</div>
            <span class="type detail" @click="goDetail(dataObj.userId)">详情</span>
          </div>
          <!-- {{JSON.stringify(dataObj) === '{}'}} -->
          <noMessage class="empty" v-if="JSON.stringify(dataObj) === '{}'" type=1 message="暂无教师信息"></noMessage>
          <!-- <noMessage class="empty" v-if="dataObj.length==0" message="暂无教师信息"></noMessage> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {noMessage},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      name: '', //姓名 测试号zyn22
      idcard: '', //身份证号 12345678X
      dataObj: {}, 
      isShowData: false, //搜索结果
    }
  },
  // 事件处理器
  methods: {
    // 立即查询
    queryData() {
      if (this.$store.state.userInfo.isLogin) {
        if (this.name == '') {
          this.$Toast('请输入姓名进行查询');
          return
        } else if (this.idcard == '') {
          this.$Toast('请输入身份证号进行查询');
          return
        } 
        // else if (!this.$regular.idCard.reg.test  (this.idcard)) {
        //   this.$Toast('请输入正确的身份证号');
        //   return
        // }

        this.$ajax({
          url: '/hxclass-mobile/user/certify/vague',
          method: "get",
          params: {
            name: this.name, //姓名
            idcard: this.idcard, //身份证
          }
        }).then((res) => { 
          // console.log(res)
          if (res.code == 200 && res.success) {
            this.dataObj = res.data
            this.isShowData = true //搜索结果
            // 数据为空
            if (!res.data) {
              // this.$Toast('暂无数据');
              this.dataObj = {}
            }
          } else {
            this.$alert(res.message);
          }
        })
      }else{
        this.$router.push('/login/login');
      }
    },
    // 处理身份证
    manageIdcard(con) {
      if (!con) {return}
      return con.substring(0,6)+'********'+con.substring(14,con.length)
    },
    // 证书详情
    goDetail(userId){
      this.$router.push({
        path:'/search/teacherDetail',
        query:{
          id: userId,
          name: this.name,
          idcard: this.idcard,
          sex: this.dataObj.sex,
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>


<style lang="less" scoped>
@import "./select.less";
  .child-item{
    .idcard{
      font-size: 12px !important;
      color: #999999 !important;
    }
  }
</style>
