import { render, staticRenderFns } from "./teacherGiftPack.vue?vue&type=template&id=03be8004&scoped=true&"
import script from "./teacherGiftPack.vue?vue&type=script&lang=js&"
export * from "./teacherGiftPack.vue?vue&type=script&lang=js&"
import style0 from "./teacherGiftPack.vue?vue&type=style&index=0&id=03be8004&prod&lang=css&"
import style1 from "./teacherGiftPack.vue?vue&type=style&index=1&id=03be8004&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03be8004",
  null
  
)

export default component.exports