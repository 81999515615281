<template>
  <!-- 常见问题详情 -->
  <div class="problemDetail">
    <!-- 导航栏 -->
    <van-nav-bar
      title="常见问题"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="box">
      <div class="box_title">{{ title }}</div>
      <!-- {{content}} -->
      <div class="box_con">
        <span v-html="content"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      title: null,
      content: null,
      id: null,
    };
  },
  // 事件处理器
  methods: {
    // 获取消息详情
    getDetail() {
      this.$ajax({
        url: "/hxclass-mobile/information/problem/view?id="+this.id,
        method: "put",
        // params: {
        //   id: this.id,
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.content = res.data.content;
          // console.log(this.content)
          this.title = res.data.title;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.problemDetail {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: 60px;
}

.box {
  width: 100%;
  padding: 15px 16px;
  .box_title {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
  }
  .box_con {
    // font-size: 16px;
    // font-weight: 400;
    // text-indent: 2em;
    // color: #333333;
    line-height: 30px;
    margin-top: 10px;
    /deep/strong{
      font-weight: bold;
    }
    /deep/em{
      font-style: italic;
    }
    /deep/img{
      max-width: 100%;
    }
    /deep/.remarks{
      max-width: 100%;
      height: auto !important;
      -webkit-line-clamp: 10000 !important;
    }
  }
}
</style>
