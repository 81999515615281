<template>
	<!-- 退款详情 -->
	<div class="refundDetail">
		<!-- 信息条 -->
		<div
			:style="{ height: iStatusBarHeight + 'px'}"
			style="position: fixed;left: 0;right: 0;top: 0;background: #15B7DD;z-index: 10;"></div>
		<div :style="{ height: iStatusBarHeight + 'px'}"></div>
		<!-- 导航栏 -->
		<div class="nav" :style="{top: iStatusBarHeight + 'px'}" style="position: fixed;left: 0;right: 0;z-index: 10;">
			<img @click="$onReturn($route.query.fromMenu == '1')" src="../../assets/image/whiteLeft.png" mode="widthFix" />
			<div class="orderType">
				<span class="typeSpan">退款关闭</span>
			</div>
		</div>
		
		<!-- 退款去向 -->
		<div class="box1">
			<div class="box_con refundMessage">
				<div class="con_top">
					<span class="top_main">退款去向</span>
					<span class="top_info">退回支付宝</span>
				</div>
				<div class="con_hint">
					卖家同意退款后交至银行处理，退款通常会原路退回，预计1-7个工作日到账
				</div>
			</div>
		</div>
		
		<!-- 撤销退款提示 -->
		<div class="box2">
			<div class="box_con refundMessage">
				因您撤销了退款申请，退款已关闭，交易正常进行。
			</div>
		</div>
		
		<!-- 退货物流 -->
		<div class="box3">
			<div class="box_con refundMessage">
				<div class="con_item">
					<div class="item_icon"></div>
					<div class="item_title">
						<span class="title_main">退款成功</span>
					</div>
					<div class="item_con">
						<span>退款返回原路返回。金额：</span>
						<span class="light">￥120.00</span>
					</div>
				</div>
				<div class="con_item pick">
					<div class="item_icon"></div>
					<div class="item_title">
						<span class="title_main">等待寄回</span>
						<span class="title_date">2021-12-12-12:23:23</span>
					</div>
					<div class="item_con">您的包裹还在路上，请耐心等待卖家确认收货后为您处理退款。</div>
				</div>
			</div>
		</div>
		
		<!-- 收货信息 -->
		<div class="box4">
			<div class="box_title">
				<div class="detailedtitle">
					<span class="colorDiv"></span>
					<span class="title">收货信息</span>
				</div>
				<div class="title_info" @click="copyAddress()">
					<img class="info_img" src="../../assets/image/refund/detail_icon1.png" mode="widthFix" />
					<span class="info_con">复制</span>
				</div>
			</div>
			<div class="box_con refundMessage">
				<div class="con_item">
					<span>收货人：</span>
					<span>王小国</span>
				</div>
				<div class="con_item">
					<span>手机号码：</span>
					<span>142541562567</span>
				</div>
				<div class="con_item">
					<span>收货地址：</span>
					<span>北京市市北京市朝阳区一盒阳光大厦</span>
				</div>
			</div>
		</div>
		
		<!-- 填写单号 -->
		<router-link to="./returnMessage" class="oddNo">
			<span class="title"><span>*</span>填写单号</span>
			<div class="value">
				<span class="value_item">填写单号</span>
				<van-icon name="arrow" class="value_icon" size="9" color="#999999" />
			</div>
		</router-link>
		
		<!-- 退款信息 -->
		<div class="detailedtitle">
			<span class="colorDiv"></span>
			<span class="title">退款信息</span>
		</div>
		<div class="refundMessage">
			<div class="shopItem">
				<div class="commodity">
					<img src="" mode="aspectFill" />
					<div class="rightMess">
						<div class="titlePrice">
							<span class="title all-font-ellipsis">孤独症康复教育上岗培孤独症康复教育上岗培训</span>
							<span class="price">¥ 64.00</span>
						</div>
						<div class="navNum">
							<span></span>
							<span class="num">x1</span>
						</div>
						<span class="refundPrice">退款金额 ¥ 64.00</span>
					</div>
				</div>
			</div>
			<div class="form">
				<div class="formItem">
					<span class="title">退款原因：</span>
					<span class="value">不想要了</span>
				</div>
				<div class="formItem">
					<span class="title">订单类型：</span>
					<span class="value price">¥120.00</span>
				</div>
				<div class="formItem">
					<span class="title">申请件数：</span>
					<span class="value">1</span>
				</div>
				<div class="formItem">
					<span class="title">货物状态：</span>
					<span class="value">未收货</span>
				</div>
				<div class="formItem">
					<span class="title">申请时间：</span>
					<span class="value">2022-01-14 23:00:00</span>
				</div>
				<div class="formItem">
					<span class="title">退款编号：</span>
					<span class="value">HUAXIAYUNKETANG</span>
				</div>
			</div>
		</div>
		
		<!-- 寄回信息 -->
		<div class="box4">
			<div class="detailedtitle">
				<span class="colorDiv"></span>
				<span class="title">寄回信息</span>
			</div>
			<div class="box_con refundMessage">
				<div class="con_item">
					<span>收货人：</span>
					<span>王小国</span>
				</div>
				<div class="con_item">
					<span>手机号码：</span>
					<span>142541562567</span>
				</div>
				<div class="con_item">
					<span>快递公司：</span>
					<span>中国邮政快递</span>
				</div>
				<div class="con_item">
					<span>运单号：</span>
					<span>213213123123</span>
				</div>
			</div>
		</div>
		
		<!-- 撤销退款 -->
		<div class="box_button">
			<span class="btn all-btn-bigCircle" @click="showPopup">撤销退款</span>
		</div>
		
		<!-- 提示信息 -->
		<van-popup v-model="show">
			<div class="box_popup">
				<div class="popup_title">
					<van-icon name="warning-o" color="#EB3C31" size="26" />
					<span class="title_con">提示信息</span>
				</div>
				<div class="popup_con">您是否确认撤销退款申请？</div>
				<div class="popup_button">
					<span class="all-btn-middle btn1" @click="showPopup">取消</span>
					<span class="all-btn-middle btn2" @click="showPopup">确认</span>
				</div>
			</div>
		</van-popup>
		
	</div>
</template>

<script>
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			iStatusBarHeight:0,
			show: false,
		}
	},
	// 事件处理器
	methods: {
		// 撤销退款
		showPopup() {
      this.show = !this.show;
    },
		
		// 复制地址
		copyAddress() {
			var input = document.createElement("input");   // 直接构建input
			input.value = '王小国 142541562567 北京市市北京市朝阳区一盒阳光大厦';  // 设置内容
			document.body.appendChild(input);    // 添加临时实例
			input.select();   // 选择实例内容
			document.execCommand("Copy");   // 执行复制
			document.body.removeChild(input); // 删除临时实例

			this.$Toast('复制成功');
		},
	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	*{box-sizing: border-box;}
	.refundDetail{
		background: #F7F7F9;
		padding: 56px 18px 82px;
		box-sizing: border-box;
		min-height: 100vh;

		/deep/.van-popup {
			background-color: transparent;
		}
		
		// 退款去向
		.box1 {
			.box_con {
				margin-top: 8px;
				.con_top {
					.top_main {
						font-size: 14px;
						font-weight: 500;
						color: #15B7DD;
					}
					.top_info {
						font-size: 10px;
						font-weight: 400;
						color: #15B7DD;
						margin-left: 30px;
					}
				}
				.con_hint {
					font-size: 12px;
					font-weight: 400;
					color: #EC6C01;
					line-height: 17px;
					margin-top: 8px;
				}
			}
		}
		
		// 撤销退款提示
		.box2 {
			.box_con {
				font-size: 14px;
				font-weight: 500;
				color: #333333;
				margin-top: 8px;
			}
		}
		
		// 退货物流
		.box3 {
			margin-top: 8px;
			.box_con {
				.con_item {
					position: relative;
					border-left: 1px solid #15B7DD;
					margin-left: 6px;
					padding-left: 19px;
					padding-bottom: 25px;
					&:last-child {
						border: 0;
						padding-bottom: 0;
					}
					.item_icon {
						position: absolute;
						left: -6.5px;
						width: 13px;
						height: 13px;
						border: 1px solid #979797;
						border-radius: 50%;
						background-color: #fff;
					}
					.item_title {
						color: #333333;
						line-height: 1;
						.title_main {
							font-size: 14px;
							font-weight: 500;
						}
						.title_date {
							font-size: 10px;
							font-weight: 400;
							margin-left: 40px;
						}
					}
					.item_con {
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
						margin-top: 7.5px;
						.light {
							color: #EC6C01;
							font-size: 14px;
							font-family: PingFangMedium;
							font-weight: bold;
						}
					}
					&.pick {
						.item_icon {
							background: #15B7DD;
							border: 1px solid #15B7DD;
						}
						.item_title {
							color: #15B7DD;
						}
					}
				}
			}
		}
		
		// 收货信息
		.box4 {
			.box_title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.title_info {
					.info_img {
						width: 14px;
						vertical-align: middle;
					}
					.info_con {
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						margin-left: 4px;
						vertical-align: middle;
					}
				}
			}
			.box_con {
				.con_item {
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					margin-bottom: 16px;
					&:last-child {
						margin: 0;
					}
					span {
						&:last-child {
							font-weight: bold;
						}
					}
				}
			}
		}
		
		// 单号
		.oddNo{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 8px;
			padding: 16px 12px;
			background-color: #ffffff;
			border-radius: 8px;
			.title{
				font-size: 16px;
				color: rgba(51, 51, 51, 1);
				line-height: 22px;
				span{
					color: rgba(249, 0, 0, 1);
				}
			}
			.value {
				font-size: 12px;
				font-weight: 400;
				color: #666666;
				.value_icon {
					margin-left: 3px;
				}
			}
		}
		.form{
			margin-top: 16px;
			.formItem{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
				&:last-child{
					margin: 0;
				}
				.title{
					font-size: 14px;
					color: #333333;
					line-height: 22px;
				}
				.value{
					font-size: 14px;
					color: #666666;
					line-height: 22px;
				}
				.price{
					font-family: PingFangMedium;
				}
			}
		}
		.refundMessage{
			padding: 16px 12px;
			background-color: #ffffff;
			border-radius: 8px;
			.shopItem{
				border-bottom: 1px solid #EEEEEE;
				padding-bottom: 12px;
				margin-bottom: 11px;
				&:last-child{
					// border: 0;
					// padding: 0;
				}
				.refundButton{
					margin-top: 4px;
					display: flex;
					justify-content: flex-end;
					span{
						padding: 6px 12px;
						border-radius: 16px;
						border: 1px solid #EEEEEE;
						font-size: 12px;
						color: #333333;
						line-height: 17px;
					}
				}
				.commodity{
					display: flex;
					img{
						width: 100px;
						height: 70px;
						border-radius: 7px;
					}
					.rightMess{
						flex: 1;
						padding-left: 12px;
						.titlePrice{
							display: flex;
							justify-content: space-between;
							align-items: center;
							.title{
								width: 160px;
								-webkit-line-clamp: 1;
								font-size: 12px;
								font-weight: bold;
								color: #333333;
								line-height: 22px;
							}
							.price{
								font-size: 10px;
								font-family: PingFangMedium;
								color: #333333;
								line-height: 22px;
							}
						}
						.navNum{
							display: flex;
							justify-content: space-between;
							margin-top: 9px;
							align-items: center;
							.navTime{
								padding: 4px 8.5px;
								background: #EEEEEE;
								border-radius: 2px;
								font-size: 10px;
								color: #666666;
								line-height: 14px;
							}
							.num{
								font-size: 12px;
								color: #999999;
								line-height: 17px;
							}
						}
						.refundPrice{
							font-size: 12px;
							font-weight: bold;
							font-family: PingFangMedium;
							color: #333333;
							line-height: 22px;
						}
					}
				}
			}
		}
		.detailedtitle{
			height: 24.5px;
			display: flex;
			align-items: center;
			margin-top: 16px;
			margin-bottom: 12px;
			.colorDiv{
				width: 3px;
				height: 13px;
				background-color: rgba(21, 183, 221, 1);
				margin-right: 3.5px;
				margin-top: 3.5px;
				border-radius: 4px;
			}
			.title{
				font-weight: bold;
				font-size: 18px;
				line-height: 28px;
				color: #333333;
			}
		}
		.box_button {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 5px 38px;
			text-align: center;
			background-color: #F7F7F9;
			.btn {
				width: 295px;
				height: 40px;
				line-height: 40px;
			}
		}
	
		// 提示信息
		.box_popup {
			position: relative;
			width: 305px;
			background: #FFFFFF;
			border-radius: 10px;
			padding: 24px 16px;
			z-index: 10;
			.popup_title {
				display: flex;
				align-items: center;
				.title_con {
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
					color: #333333;
					margin-left: 7px;
				}
			}
			.popup_con {
				font-size: 16px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #333333;
				margin-top: 22.5px;
			}
			.popup_button {
				display: flex;
				justify-content: space-between;
				margin-top: 32px;
				.all-btn-middle {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
				}
				.btn1 {
					color: #666666;
					background: #EEEEEE;
					box-shadow: none;
				}
			}
		}
	}
	.nav{
		background: #15B7DD;
		padding-left: 16px;
		display: flex;
		align-items: center;
		height: 44px;
		img{
			width: 16px;
		}
		.orderType{
			display: flex;
			flex-direction: column;
			margin-left: 13px;
			.typeSpan{
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 25px;
			}
			.enclosure{
				font-size: 10px;
				color: #FFFFFF;
				line-height: 14px;
			}
		}
	}
</style>
