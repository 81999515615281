<template>
  <div>
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="作业"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="conter">
      <noMessage class="nomessage" v-if="!list.length && !loading" type='8'></noMessage>
      <div class="item" v-for="item in list" :key="item.answerId">
        <div class="top-bg">
          <p class="statusIcon" v-if="item.status == 1 || item.status == 0">· 待提交</p>
          <p class="statusIcon green" v-if="item.status == 8">· 已批阅</p>
          <p class="statusIcon red" v-if="item.status == 5">· 已驳回</p>
          <p class="statusIcon" v-if="item.status == 3 || item.status == 7">· 待批阅</p>
          <!-- <img class="statusIcon" src="@/assets/image/task/status1.png" alt="" />
          <img class="statusIcon" src="@/assets/image/task/status2.png" alt="" />
          <img class="statusIcon" src="@/assets/image/task/status3.png" alt="" />
          <img class="statusIcon" src="@/assets/image/task/status4.png" alt="" /> -->
          <p class="title">{{item.homeworkTitle}}</p>
          <p class="link">
            <img width="9" src="@/assets/image/task/task_icon3.png" alt="" />
            {{item.courseName}}
          </p>
        </div>
        <div class="bottom-inner">
          <p class="text">{{item.summary}}</p>
          <div class="datebox">
            <div class="line"></div>
            <div class="time">
              <img width="9" src="@/assets/image/task/task_icon8.png" alt="" />
              截止上传时间：{{endTime(item.answerEnddate)}}
            </div>
          </div>
        </div>
        <div class="btns">
          <span
            @click="homeWorkDetail(item.answerId,0)"
            >查看详情</span
          >
          <span
            v-if="item.status != 0 && item.status != 1"
            @click="homeWorkDetail(item.answerId,1)"
            >查看反馈
          </span>
          <span
            class="submitbtn"
            v-if="!item.overdue && item.status != 3 && item.status != 7 && item.status != 8 && btnShow(item.answerStartdate)"
            @click="submitHomeWork(item.answerId)"
            >提交作业</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  components: { noMessage },
  data() {
    return {
      pageHeight: 0,
      loading: false,
      list: []
    }
  },
  methods: {
    endTime(e) {
      if(!e){
        return '';
      }
      let str = e.split('T').join(' ');
      str = str.substring(0,16);
      return str;
    },

    // 是否展示提交按钮
    btnShow(e) {
      if(new Date(e).getTime() > new Date().getTime()) {
        return false;
      }
      return true;
    },

    // 获取作业data
    getHomeWorkList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-mobile/homework/student/list",
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then(res=>{
        this.loading = false;
        if(res.code == 200 && res.success) {
          this.list = res.data;
        }else {
          this.$alert(res.message);
        }
      })
    },

    // 跳转作业详情
    homeWorkDetail(e,type) {
      this.$router.push('/task/homeWorkDetail?id='+e+"&type="+type);
    },

    // 跳转提交作业
    submitHomeWork(e) {
      this.$router.push('/task/submitHomeWork?id='+e);
    }
  },
  created() {
    this.getHomeWorkList();
  }
};
</script>

<style lang="less" scoped>
.conter {
  min-height: 60vh;
  overflow: hidden;
  padding: 10px 15px;
  .item {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(93, 131, 167, 0.15);
    border-radius: 10px;
    margin-bottom: 14px;
    position: relative;
    background-image: url(~@/assets/image/task/listBack.png);
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: top;
    background-position-y: 0;
    .top-bg{
      padding: 14px;
    }
    .bottom-inner{
      padding: 10px 14px 15px;
    }
    .statusIcon{
      width: 61px;
      height: 20px;
      background: linear-gradient(270deg, #EFEFEF 18%, rgba(255,255,255,0) 100%);
      position: absolute;
      top: 17px;
      right: 0;
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    .red{
      background: linear-gradient(270deg, rgba(250,62,62,0.1) 18%, rgba(249,0,0,0) 100%);
      color: #FA3E3E;
    }
    .green{
      background: linear-gradient(270deg, #A7FFDF 18%, rgba(255,255,255,0) 100%, rgba(255,255,255,0) 100%);
      color: #15DD95;
    }
    .title {
      font-size: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
    }
    .link {
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #C4C4C4;
      line-height: 15px;
    }
    .text {
      padding: 0 10px 10px;
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 23px;
    }
    // 时间
    .datebox {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .line {
        // width: 40%;
        flex: 1;
        height: 1px;
        background: #efefef;
      }
      .time {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        margin-left: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    // 按钮
    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: #FCFBFC;
      > span {
        width: 33%;
        height: 48px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #15B7DD;
        line-height: 48px;
        > a {
          line-height: 22px;
          margin-left: 4px;
        }
      }
      span:last-child{
        background: #15B7DD;
        color: #FFFFFF;
      }
      .submitbtn{
        background: #15B7DD;
        color: #FFFFFF;
      }
    }
  }
}
.nomessage{
  margin-top: 100px;
}
</style>