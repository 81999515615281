<template>
  <div></div>
</template>

<script>
import { FunClearToken } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      timer:null
    }
  },
  // 事件处理器
  methods: {
    // 监听h5登录信息是否存在
    funWatcToken(){
      this.timer = setInterval(()=>{
        if(!this.$store.state.userInfo.userId){
          FunClearToken()
        }
      },30000)
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.funWatcToken()
  },
  beforeDestroy(){ 
    clearInterval(this.timer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
