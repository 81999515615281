<template>
	<!-- 修改地址 -->
	<div class="adjust">
		<!-- 导航栏 -->
		<van-nav-bar
			class="nav-circular"
			:title="orderId?'修改地址':'选择地址'"
			left-text=""
			right-text="添加新地址"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
			@click-right="addAddress()"
		/>
		<!-- 修改地址提示信息 -->
		<p v-if="orderId" class="messWarn"><van-icon class="infoIcon" name="info-o" color="red" />若因运费变更原因导致修改失败，请您谅解。</p>
		<!-- 原地址 -->
		<div class="box" v-if="orderId">
			<div class="box_title">原地址</div>
			<div class="box_con">
				<div class="con_top">
					<img class="top_icon" src="../../assets/image/address/icon1.png" />
					<span class="top_name">{{oldAddress.consignee || oldAddress.name}}</span>
					<span class="top_phone" v-if="oldAddress.phone">{{oldAddress.phone.substr(0,3)}}****{{oldAddress.phone.substr(7,11)}}</span>
				</div>
				<div class="con_main">{{(oldAddress.proviceName||oldAddress.provinceName)+oldAddress.cityName+(oldAddress.districtName?oldAddress.districtName:'')+oldAddress.address}}</div>
			</div>
		</div>
		<!-- 新地址 -->
		<div class="box" style="padding-bottom:100px;">
			<div class="box_title" v-if="orderId">选择新地址</div>
			<no-message style="padding-top:50px;" v-if="!addressList.length && visible" type="1" message="您还没有添加收货地址" />
			<div v-infinite-scroll="load">
				<div 
					class="box_con" 
					:class="{'pick': addressIndex===index}"
					v-for="(item, index) in addressList" 
					:key="index"
					@click="changeAddress(index)">
					<div class="con_top">
						<img class="top_icon" src="../../assets/image/address/icon1.png" />
						<span class="top_name">{{item.consignee}}</span>
						<span class="top_phone" v-if="item.phone">{{item.phone.substr(0,3)}}****{{item.phone.substr(7,11)}}</span>
						<span class="isDefault" v-if="!!item.isDefault">默认</span>
					</div>
					<div class="con_main">{{item.proviceName+item.cityName+(item.areaName?item.areaName:'')+item.address}}</div>
					<img 
						v-if="addressIndex==index"
						class="con_pick" 
						src="../../assets/image/address/icon2.png" 
						/>
				</div>
			</div>
		</div>
		
		<div class="box_button">
			<span class="all-btn-bigCircle" @click="changeOrderAddress">确认</span>
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return{
				type: null,// 修改地址模块类型  1：考试报名  2：确认订单
				addressId: '', //地址id
				confirmaddressId:'', // 确认订单原地址
				visible: false,
				orderId: '', // 我的订单原地址
				oldAddress: null, // 原地址
				addressList: [],
				addressIndex: null,
				pageNum: 1,
				pageSize: 10,
				total: 0
			}
		},
		methods:{
			// 下拉加载
			load () {
				if(this.pageNum * this.pageSize < this.total){
					this.pageNum++;
					this.getAddressList();
				}
      },
			// 地址详情
			async getAddressDetail() {
				await this.$ajax({
					url:'/hxclass-mobile/ship/address/detail',
					method:'get',
					params: {
						id: this.addressId || this.confirmaddressId
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.oldAddress = res.data
						this.addressIndex = this.addressList.findIndex((item)=>{return item.id == this.oldAddress.id})
					}
				})
			},
			// 选择新地址
			changeAddress(index) {
				this.addressIndex = index
			},
			// 添加新地址
			addAddress(){
				this.$router.push('/address/addAddress')
			},
			// 获取订单地址
			getOrderDetail(){
				this.$ajax({
					url:'/hxclass-mobile/order/' + this.orderId,
					method:'get'
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.oldAddress = res.data.address;
					}
				})
			},
			// 获取用用户地址列表
			async getAddressList(){
				await this.$ajax({
					url:'/hxclass-mobile/ship/address/list',
					method:'get',
					params:{
						pageNum:this.pageNum,
						pageSize:this.pageSize
					}
				}).then(res=>{
					this.visible = true;
					if(res.code == 200 && res.success){
						this.addressList.push(...res.data.records);
						this.total = res.data.total;
					}else{
						this.$Toast(res.message);
					}
				})
			},
			// 修改地址
			changeOrderAddress(){
				if(this.addressIndex === null){
					return this.$Toast('请选择新地址')
				}
				// 考试报名
				if (this.type == 1) {
					let addressId = this.addressList[this.addressIndex].id;
					localStorage.setItem('addressId', addressId); //存储
					this.$onReturn(this.$route.query.fromMenu == '1');
					return
				}
				// 确认订单
				if (this.type == 2) {
					let addressId = this.addressList[this.addressIndex].id;
					this.$store.commit('updateAddress',addressId);
					this.$onReturn(this.$route.query.fromMenu == '1');
					return
				}
				this.$ajax({
					url:'/hxclass-mobile/order/address',
					method:'put',
					params:{
						orderId:this.orderId,
						receiveAddress:this.addressList[this.addressIndex].address, // 收货人详细地址
						receiveCity:this.addressList[this.addressIndex].city, // 收货人市编码
						receiveDistrict:this.addressList[this.addressIndex].area, // 收货人区编码
						receiveName:this.addressList[this.addressIndex].consignee, // 收货人姓名
						receivePhone:this.addressList[this.addressIndex].phone, // 收货人手机号
						receiveProvince:this.addressList[this.addressIndex].provice, // 收货人省编码
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.$Toast('修改成功')
						this.$onReturn(this.$route.query.fromMenu == '1');
					}else{
						this.$Toast(res.message)
					}
				})
			}
		},
		async created(){
			await this.getAddressList();
			if(this.$route.query.type){
				this.type = this.$route.query.type; // 修改地址模块类型  1：考试报名  2：确认订单
			}
			if(this.$route.query.orderId){
				this.orderId = this.$route.query.orderId;
				this.getOrderDetail();
			} else if (this.$route.query.addressId) {
				this.addressId = this.$route.query.addressId
				// 地址详情
				await this.getAddressDetail()
			} else if (this.$route.query.confirmaddressId) {
				this.confirmaddressId = this.$route.query.confirmaddressId
				// 地址详情
				await this.getAddressDetail()
			}
		}
	}
</script>

<style>
	page{
		background-color: #F7F7F9;
	}
</style>
<style lang="less" scoped>
	* {box-sizing: border-box;}
	.adjust {
		min-height: 100vh;
		background: #F7F7F9;
		padding-bottom: 60px;
	}
	.messWarn{
		padding: 16px 18px 0;
		line-height: 20px;
		font-size: 14px;
		font-family: PingFangMedium;
		font-weight: 400;
		color: #666666;
		.infoIcon{
			margin-right: 5px;
		}
	}
	.box {
		padding: 16px 18px 0 18px;
		.box_title {
			line-height: 1;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
			color: #333333;
		}
		.box_con {
			position: relative;
			background: #FFFFFF;
			border-radius: 9px;
			border: 1px solid #fff;
			margin-top: 12px;
			padding: 16px 12px;
			&.pick {
				border: 1px solid #15B7DD;
			}
			.con_top {
				width: 100%;
				display: flex;
				align-items: center;
				.top_icon {
					width: 14px;
				}
				.top_name {
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
					color: #333333;
					margin: 0 8px;
				}
				.top_phone {
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
					color: #333333;
				}
				.isDefault{
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #15B7DD;
					border: 1px solid #15B7DD;
					padding: 2px;
					border-radius: 3px;
					margin-left: 8px;
				}
			}
			.con_main {
				line-height: 20px;
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #666666;
				margin-top: 12.5px;
			}
			.con_pick {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 16px;
			}
		}
	}
	
	.box_button {
		position: fixed;
		width: 100vw;
		height: 56px;
		background-color: #F7F7F9;
		display: flex;
		justify-content: center;
		align-items: center;
		// left: calc((100vw - 295px)/2);
		// bottom: 10px;
		left: 0;
		bottom: 0;
		.all-btn-bigCircle {
			width: 295px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
			color: #FFFFFF;
		}
	}
</style>
