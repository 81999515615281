import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from './../store'
import Ajax from '@/unit/ajax.js'
import WhiteList from '@/assets/js/WhiteList.js'
import login from './login.js' // 登录
import home from './home.js' // 首页4个tab
import selected from './selected.js' // 精选
import curriculum from './curriculum.js' // 课程
import examination from './examination.js' // 考试相关
import shop from './shop.js' // 购买
import live from './live' // 直播
import order from './order.js' // 订单
import invoice from './invoice.js' // 发票
import search from './search.js' // 综合查询
import knowledge from './knowledge.js' // 知识专区
import myStudy from './myStudy.js' // 我的学习
import mine from './mine.js' // 我的
import setUp from './setUp.js' // 设置
import address from './address.js' // 地址
import info from './info.js' // 资讯列表
import examEnroll from './examEnroll.js' // 考试报名
import all from './all.js' // 其他页面集合
import task from './task.js' // 作业页面集合
import propagandaHeadlines from './propagandaHeadlines.js'  // 音频播放
import activity from './activity.js'  //活动页

import { FunJudgDevice } from '@/unit/fun.js'
import { goIdentityAuthentication, getAppVersion, onGetStorage, onStartOrder, onSaveStorage, onStartActivity } from '@/assets/js/NativeWebKit.js'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: () =>
        import('../views/home/index.vue'),
    children: home
},
{
    // 登录模块
    path: '/login',
    component: () =>
        import('@/views/routerView.vue'),
    children: login
},
{
    // 购买模块
    path: '/shop',
    component: () =>
        import('@/views/routerView.vue'),
    children: shop
},
{
    // 教育课程
    path: '/curriculum',
    component: () =>
        import('@/views/routerView.vue'),
    children: curriculum
},
{
    // 直播模块
    path: '/live',
    component: () =>
        import('../views/routerView.vue'),
    children: live
},
{
    // 知识专区
    path: '/knowledge',
    component: () =>
        import('../views/routerView.vue'),
    children: knowledge
},
{
    // 订单
    path: '/order',
    component: () =>
        import('@/views/routerView.vue'),
    children: order
},
{
    // 发票相关
    path: '/invoice',
    component: () =>
        import('@/views/routerView.vue'),
    children: invoice
},
{
    // 考试相关
    path: '/examination',
    component: () =>
        import('@/views/routerView.vue'),
    children: examination
},
{
    // 综合查询
    path: '/search',
    component: () =>
        import('@/views/routerView.vue'),
    children: search
},
{
    // 我的学习
    path: '/myStudy',
    component: () =>
        import('@/views/routerView.vue'),
    children: myStudy
},
{
    // 精选
    path: '/selected',
    component: () =>
        import('@/views/routerView.vue'),
    children: selected
},
{
    // 我的
    path: '/mine',
    component: () =>
        import('@/views/routerView.vue'),
    children: mine
},
{
    // 设置
    path: '/setUp',
    component: () =>
        import('@/views/routerView.vue'),
    children: setUp
},
{
    // 资讯列表
    path: '/info',
    component: () =>
        import('@/views/routerView.vue'),
    children: info
},
{
    // 地址
    path: '/address',
    component: () =>
        import('@/views/routerView.vue'),
    children: address
},
{
    // 考试报名
    path: '/examEnroll',
    component: () =>
        import('@/views/routerView.vue'),
    children: examEnroll
},
{
    // 其他页面集合
    path: '/all',
    component: () =>
        import('@/views/routerView.vue'),
    children: all
},
{
    // 作业页面集合
    path: '/task',
    component: () =>
        import('@/views/routerView.vue'),
    children: task
},
{
    // 活动页面
    path: '/activity',
    component: () =>
        import('@/views/routerView.vue'),
    children: activity
},


{   // 音频播放
    path: '/propagandaHeadlines',
    component: () => import('@/views/routerView.vue'),
    children: propagandaHeadlines
},
{   // 富文本页面
    path: '/VHTML',
    component: () => import('@/components/VHTML.vue'),
},
{ // 404 页面
    path: '*',
    component: () => import('@/views/error.vue')
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

function nativeStorage() {
    // 补考
    let payMakeObj = sessionStorage.getItem('payMakeObj')
    onSaveStorage('payMakeObj',payMakeObj)
    
    // payReturnsign 1跳回考试报名页 2跳到首页
    let payReturnsign = localStorage.getItem('payReturnsign')
    // isOrgOrder  是否机构报名-照片冲印订单
    let isOrgOrder = sessionStorage.getItem('isOrgOrder')
    // 电子照的考试id
    let paySignupObj = sessionStorage.getItem('paySignupObj')
    if(paySignupObj) {
        let obj = JSON.parse(paySignupObj)
        // 获取考生信息
        let confirmObjName = 'confirmObj' + obj.id
        let confirmObj = localStorage.getItem(confirmObjName)
        onSaveStorage(confirmObjName,confirmObj)
    }
    onSaveStorage('payReturnsign',payReturnsign)
    onSaveStorage('isOrgOrder',isOrgOrder)
    onSaveStorage('paySignupObj',paySignupObj)
}
// 路由守卫
router.beforeEach(async (to, from, next) => {
    if(to.query.nativeId){
        let res = await Ajax({
            url: '/hxclass-mobile/user/info/get/caching/info',
            params: {
                userId: to.query.nativeId
            }
        })
        if(res.code == 200 && res.success) {
            if(res.data) {
                let obj = JSON.parse(res.data)
                $store.state.userInfo = obj;
                localStorage.setItem("userInfo", res.data)
            }else{
                localStorage.removeItem("userInfo")
            }
        }else{
            
        }
    }
    // 获取原生本地登录信息
    // 版本号控制
    // if(to.path != '/myStudy/studyCopy') {
    //     const DeviceType = FunJudgDevice()
    //     const AppVersion = getAppVersion();
    //     if (DeviceType == "android") {
    //         if(AppVersion.code > 118) {
    //             let info = onGetStorage('info');
    //             if(info){
    //                 // 存储globalData数据
    //                 localStorage.setItem('userInfo', info);
    //                 $store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //             }else{
    //                 // 删除历史缓存globalData数据
    //                 localStorage.removeItem("userInfo");
    //             }
    //         }
    //     } else if (DeviceType == "ios" && window.webkit) {
    //         // iOS暂时不用这种方式存储
    //     //   if(AppVersion.code > 97) {
    //     //     let info = onGetStorage('info');
    //     //     if(info){
    //     //       // 存储globalData数据
    //     //       localStorage.setItem('userInfo', info);
    //     //       $store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //     //     }else{
    //     //       // 删除历史缓存globalData数据
    //     //       localStorage.removeItem("userInfo");
    //     //     }
    //     //   }
    //     }
    // }

    // 如果页面路径不是404，则进入判断
    if (!to.params.pathMatch) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
        const isLogin = $store.state.userInfo.isLogin
        // 判断是否需要登录 或者 是免登录白名单页面
        if (WhiteList.indexOf(to.path) != -1 || isLogin || userInfo.isLogin) {
            if(to.path == '/activity/couponActivity' && to.query.id == 'active'){
                return next('/curriculum/studentActive?fromMenu=1');
            }
            // 判断跳转认证并且是ios跳转原生身份认证页
            if(to.path == '/mine/identityAuthentication') {
                const DeviceType = FunJudgDevice()
                const AppVersion = getAppVersion();
                if(DeviceType == 'ios' && window.webkit) {
                    if(true || AppVersion.code >= 84) {
                        if(from.path == "/setUp/interest") {
                            return goIdentityAuthentication(2)
                        }
                        return goIdentityAuthentication()
                    }else {
                        next();
                    }
                }else {
                    if(true || AppVersion.code >= 111) {
                        if(from.path == "/setUp/interest") {
                            return goIdentityAuthentication(2)
                        }
                        return goIdentityAuthentication()
                    }else {
                        next();
                    }
                }
            }

            // 判断跳转确认订单页面
            // if(to.path == '/order/confirmOrder') {
            //     const DeviceType = FunJudgDevice()
            //     const AppVersion = getAppVersion();
            //     let productList = $store.state.confirmOrder.list,
            //         type = $store.state.confirmOrder.type;
            //     if(DeviceType == 'ios' && window.webkit) {
            //         if(AppVersion.code >= 120) {
            //             return onStartOrder(JSON.stringify(productList),type)
            //         }else {
            //             next();
            //         }
            //     }else {
            //         if(AppVersion.code >= 120) {
            //             nativeStorage() // 存储信息
            //             return onStartOrder(JSON.stringify(productList),type)
            //         }else {
            //             next();
            //         }
            //     }
            // }

            // 判断跳转我的订单列表页面
            // if(to.path == '/order/myOrder') {
            //     const DeviceType = FunJudgDevice()
            //     const AppVersion = getAppVersion();
            //     if(DeviceType == 'ios' && window.webkit) {
            //         if(AppVersion.code >= 120) {
            //             return onStartActivity(1)
            //         }else {
            //             next();
            //         }
            //     }else {
            //         if(AppVersion.code >= 120) {
            //             return onStartActivity(1)
            //         }else {
            //             next();
            //         }
            //     }
            // }
            next()
        } else {
            next({
                path: '/login/login'
            })
        }
    } else {
        next()
    }
})


export default router