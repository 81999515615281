<template>
	<div class="certificates">
		<!-- 导航栏 -->
		<van-nav-bar
			title="相关证书"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<div class="content" v-for="(item,index) in certificatesList" :key="index">
			<img :src="item.picture" />
			<p>{{item.name}}</p>
		</div>
	</div>
</template>

<script>
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			certificatesList:[], // 资质列表
		}
	},
	// 事件处理器
	methods: {},
	// 生命周期-实例创建完成后调用
	created () {
		// console.log(this.$route)
		this.certificatesList = this.$route.params.relevantCertificateList;
	},
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	.certificates{
		padding-bottom: 60px;
		.content{
			margin: 9px 18px 8px 18px;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
			img{
				width: 100%;
			}
			p{
				line-height: 50px;
				text-align: center;
			}
		}
	}
</style>
