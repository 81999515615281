<template>
  <!-- 成绩单 -->
  <div class="grade">
    <!-- 导航栏 -->
    <van-nav-bar
      title="成绩单"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="box box1" v-if="isAchievement">
      <div
        class="box_item"
        v-if="
          scoreList.userMarkList != null && scoreList.userMarkList.length != 0
        "
      >
        <div class="item_title">平时成绩</div>
        <div class="item_con">
          <div class="con_txt">
            <!-- <span class="txt_con" v-if="scoreList.personalAchievement != null">个人成绩：<span class="con_num">{{scoreList.personalAchievement}}</span>分</span>
						<span class="txt_con" v-if="scoreList.groupAchievement != null">小组成绩：<span class="con_num">{{scoreList.groupAchievement}}</span>分</span> -->
            <span
              class="txt_con"
              v-for="(item, index) in scoreList.userMarkList"
              :key="index"
              >{{ item.name }}：<span class="con_num">{{ item.val }}</span
              >分</span
            >
          </div>
        </div>
      </div>
      <div class="box_item" v-if="scoreList.achievement != null">
        <div class="item_title">考试成绩</div>
        <div class="item_con">
          <div
            class="con_txt"
            v-for="(item, index) in scoreList.achievement"
            :key="index"
          >
            <span class="txt_con con_width">
              <span class="con_num">{{ item.achievement }}</span>
              分
            </span>
            <span class="txt_info">{{ createtime(item.time) }}</span>
          </div>
        </div>
      </div>
      <div class="box_item" v-if="scoreList.allAchievement != null">
        <div class="item_title">总成绩</div>
        <div class="item_con">
          <div
            class="con_txt"
            v-for="(item, index) in scoreList.allAchievement"
            :key="index"
          >
            <span class="txt_con con_width">
              <span class="con_num">{{ item.achievement }}</span>
              分
            </span>
            <span class="txt_info">{{ createtime(item.time) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <van-loading color="#15B7DD" class="loading" type="spinner"  v-if="loadSpin" /> -->
    <noMessage v-if="!isAchievement && !loadSpin" type="1"></noMessage>

    <div class="box box2" v-if="scoreList.certificateUrl != null">
      <div class="box_item">
        <div class="item_title">所获证书</div>
        <div class="item_con">
          <div class="con_imgBox">
            <div class="img_type">
              <img
                class="type_bg"
                src="../../assets/image/examination/grade_icon1.png"
              />
              <div class="type_txt">考试证书</div>
            </div>
            <img class="img_main" :src="scoreList.certificateUrl" />
          </div>
          <div class="con_title">{{ scoreList.certificateName }}</div>
          <div class="con_info">
            所获时间：{{ handleTime(scoreList.certificateTime) }}
          </div>
        </div>
      </div>
    </div>

    <!-- 证书物流 -->
    <div class="box3" v-if="scoreList.logistics != null && isLogistics">
      <div class="box_title">证书物流</div>
      <div class="box_name">{{ scoreList.certificateName }}</div>
      <div class="box_con">
        <logistics
          v-if="isShow"
          :logisticsData="logisticsData"
          :showType="2"
        ></logistics>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import logistics from "@/components/xinyu-logistics/xinyu-logistics.vue";
export default {
  components: { logistics, noMessage },
  data() {
    return {
      id: null, //课程id
      scoreList: [],
      isShow: false,
      isAchievement: false,
      isLogistics: false,
      logisticsData: {},
      loadSpin:false
    };
  },
  methods: {
    repair0(m) {
      return m < 10 ? "0" + m : m;
    },
    createtime(times) {
      if (!times) {
        return
      }
      var time = new Date(times.replace(/-/g, "/"));
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.repair0(m) +
        "-" +
        this.repair0(d) +
        " " +
        this.repair0(h) +
        ":" +
        this.repair0(mm) +
        ":" +
        this.repair0(s)
      );
    },
    getScoreList() {
      this.loadSpin = true
      this.$ajax({
        url: "/hxclass-mobile/report/card?course_id=" + this.id,
      }).then((res) => {
        this.loadSpin = false
        if (res.code == 200 && res.success) {
          this.scoreList = res.data;
          this.logisticsData.tellPhone = res.data.consigneeMobile; //手机号码
          this.logisticsData.address = res.data.consigneeAddress; //地址
          this.logisticsData.logisticsInfo = res.data.logistics; //物流
          this.logisticsData.logisticsCompany = res.data.logisticsCompany; //物流公司
          this.logisticsData.logisticsCode = res.data.logisticsCode; //物流单号
          this.isShow = true;
          // this.scoreList.personalAchievement != null && this.scoreList.groupAchievement != null &&
          if (
            this.scoreList.achievement != null ||
            this.scoreList.allAchievement != null
          ) {
            this.isAchievement = true;
          }
          // if(this.scoreList.userMarkList.length != 0 || !this.scoreList.userMarkList){
          // 	this.isAchievement = true;
          // }
          if (this.scoreList.logistics.comName != null || this.logisticsData.logisticsCompany!=null) {
            this.isLogistics = true;
          }
        }
      });
    },
    // 处理证书所获时间
    handleTime(con) {
      let txt = con.split("-")[0] + "-" + con.split("-")[1];
      return txt;
    },
  },
  created() {
    this.id = this.$route.query.courseId;
    this.getScoreList();
  },
  onLoad() {},
  onPageScroll(e) {},
};
</script>

<style>
page {
  background-color: #f7f7f9;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.van-nav-bar--fixed {
  z-index: 99;
}

/deep/.nomessage {
  margin-top: 120px;
}
.grade {
  background: #f7f7f9;
}
.box {
  margin-top: 9px;
  padding: 16.5px 18px 0 18px;
  .box_item {
    .item_title {
      font-size: 16.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      // vertical-align: middle;
      &::before {
        content: "";
        display: inline-block;
        width: 3px;
        height: 13px;
        background: #15b7dd;
        border-radius: 4px;
        margin-right: 3.5px;
        // vertical-align: middle;
      }
    }
    .item_con {
      margin-top: 7px;
      padding-bottom: 14px;
      .con_txt {
        line-height: 27.5px;
        font-size: 14.5px;
        font-weight: 500;
        .txt_con {
          color: #333333;
          margin-right: 18px;
          .con_num {
            font-size: 16.5px;
            font-weight: bold;
            &.fail {
              color: #ea3c31;
            }
            &.pass {
              color: #8cbd0b;
            }
          }
          &.con_width {
            display: inline-block;
            width: 56px;
          }
        }
        .txt_info {
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
.box1 {
  border-radius: 9px;
  background-color: #ffffff;
}
.box2 {
  .box_item {
    .item_con {
      margin-top: 14px;
      // padding: 0 18px;
      .con_imgBox {
        position: relative;
        width: 100%;
        .img_type {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
          .type_bg {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
          }
          .type_txt {
            position: absolute;
            top: 16px;
            right: -2.5px;
            width: 48px;
            transform: rotate(47deg);
            font-size: 10px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
          }
        }
        .img_main {
          width: 100%;
        }
      }
      .con_title {
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 21.5px;
        margin-top: 9px;
      }
      .con_info {
        font-size: 11px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 16.5px;
        margin-top: 5.5px;
      }
    }
  }
}
.loading {
  position: absolute;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 150px;
  color: #fff;
}
// 物流
.box3 {
  position: relative;
  background: #ffffff;
  border-radius: 9px 9px 0 0;
  padding: 16.5px 18px;
  z-index: 0;
  .box_title {
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    &::before {
      display: inline-block;
      content: "";
      width: 3px;
      height: 13px;
      background: #15b7dd;
      border-radius: 4px;
      margin-right: 3.5px;
    }
  }
  .box_name {
    font-size: 14.5px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    margin-top: 12.5px;
  }
  .box_con {
    margin-top: 10px;
  }
}
</style>
