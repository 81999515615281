<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      :title="title ? title : '活动详情'"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <img :src="img" alt="">
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      title: '',
      img:''
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { 
    this.title = this.$route.query.title
    this.img = this.$route.query.img
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.container-box{
  height: 100vh;
  overflow-y:scroll;
}
img{
  width: 100%;
}
</style>
