<template>
  <!-- 证书 -->
  <div class="certificate">
    <van-nav-bar
      title="证书"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 切换框 -->
    <div class="tab" :style="{top: 46+stateHeight+'px'}">
       <SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />
      <!-- <div
        class="tab_item"
        :class="{ pick: tabIndex == index }"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(index)"
      >
        {{ item.name }}
      </div> -->
    </div>

    <!-- 证书列表 -->
    <div class="box" v-if="courseList.length !== 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getList"
      >
      <!-- :to="'./certificatePreview?name=' + item.name + '&picture=' + item.picture" -->
        <div
          class="box_item"
          v-for="(item, index) in courseList"
          :key="index"
        >
          <router-link 
            class="con_imgBox"
            hover-class="none"
            :to="{
              path: 'certificatePreview',
              query: {'name': item.name, 'picture': item.picture}
            }"
            tag="div">
            <!-- <div class="img_type">
              <image class="type_bg" src="../../static/image/examination/grade_icon1.png" mode="widthFix"></image>
              <div class="type_txt">课程证书</div>
            </div> -->
            <img class="img_main" :src="item.picture" />
          </router-link>
          <div class="item_title">
            <div>{{item.name}}</div>
            <!-- <div class="title_info">
              <img
                class="info_img"
                src="../../assets/image/study/cer_icon2.png"
              />
              <span class="info_txt">证书修改</span>
            </div> -->
          </div>
          <div class="item_con" v-if="tabIndex!==2">
            <span class="con_num" v-if="item.code">证书编号：{{item.code}}</span>
            <!-- <span class="con_date">所获时间：{{item.getcertifTime&&item.getcertifTime.split('T')[0]}}</span> -->
            <span class="con_date" v-if="item.getcertifTime">所获时间：{{handleTime(item.getcertifTime)}}</span>
          </div>
          <div v-if="tabIndex==0 && !!item.logisticsNumber">
            <logistics 
              ref="eLogistics"
              :logisticsData="item.logisticsData" 
              :showType=1
              :showIndex="index"
              @getLogisticsData="getLogisticsData"></logistics>
          </div>
        </div>
      </van-list>

      
    </div>

    <!-- 暂无数据 -->
    <!-- <div class="box_empty" v-if="courseList.length==0 && tabIndex!==2">
      <img class="empty_img" src="../../assets/image/study/noCourse.png" />
      <span class="empty_txt">暂无证书哦~</span>
    </div> -->
    
    <!-- <van-loading color="#15B7DD" class="loading" type="spinner"  v-if="loadSpin" /> -->
    <noMessage class="box_empty" v-if="courseList.length==0 && tabIndex!==2 && !loadSpin" type=1></noMessage>

    <!-- 个人证书上传流程 -->
    <div class="box1" v-if="tabIndex==2 && courseList.length==0">
      <div class="box_info">
        上传您的有竞争力的资质证件，我们会认真审核，通过后会在认证查询系统中为您增加资质勋章
      </div>
      <div class="box_title">上传流程</div>
      <div class="box_con">
        <img class="con_icon" src="../../assets/image/study/cer_icon1.png" />
        <div class="con_info">
          <span>上传证书</span>
          <span>选择证书类型</span>
          <span>证书审核</span>
          <span>认证成功</span>
        </div>
        <router-link
          class="con_upload"
          hover-class="none"
          to="./certificateUpload"
        >
          <img
            class="upload_icon"
            src="../../assets/image/study/cer_upload.png"
          />
          <span class="upload_txt">上传证书</span>
        </router-link>
      </div>
    </div>

    <!-- 上传 -->
    <router-link
      class="box_button"
      hover-class="none"
      to="./certificateUpload"
      v-if="tabIndex==2 && courseList.length!==0"
    >
      <span class="all-btn-middle">上传个人证书</span>
    </router-link>
  </div>
</template>

<script>
  import noMessage from "@/components/nomessage.vue";
  import logistics from '@/components/xinyu-logistics/xinyu-logistics.vue'
  import SwichTab from "@/components/SwichTab.vue";
	export default{
    components: {
      noMessage,
      logistics,
      SwichTab
    },
		data(){
			return{
        stateHeight: 0, //信息条高度
        tabList: [
          {
            id: 1,
            name: '考试证书',
          },
          {
            id: 2,
            name: '课程证书',
          },
          {
            id: 3,
            name: '个人证书',
          },
        ],
				tabIndex: 0,
        pageNum: 1, //页数
        pageSize: 10, //条数
        courseList: [], 
        loading: false,
        finished: false,
        loadSpin: false
			}
		},
    activated(){
      // 证书列表
      this.getList()
    },
    created() {
      // // 证书列表
      // this.getList()
      // 获取信息条高度
      this.stateHeight = this.$parent.$data.stateHeight
    },
		methods:{
			// 切换tab
			changeTab(e) {
        this.tabIndex = e
				// this.tabIndex = index
        // 初始化
        this.pageNum = 1
        this.loading = false
        this.finished = false
        this.courseList = []
        // 证书列表
        this.getList()
			},
			// 证书列表
      getList() {
        this.loadSpin = true
        this.$ajax({
          url: '/hxclass-mobile/user-certif/my',
          method: 'GET',
          params: {
            type: this.tabList[this.tabIndex].id,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          }
        }).then((res) => {
          this.loadSpin = false
          if (res.code == 200 && res.success) {
            // 处理物流数据
            res.data.records.map((item, index) => {
              // 有物流
              if (item.logisticsNumber || item.logisticsNumber !== '') {
                item.logisticsData = {
                  logisticsInfo: {
                    comName: item.logisticsCompany, //物流名称
                    nu: item.logisticsNumber, //单号
                  }
                }
              }
            })
            this.courseList = this.courseList.concat(res.data.records)
            // console.log(this.courseList)
            this.loading = false
            this.pageNum++
            if (res.data.total == this.courseList.length) {
              this.loading = false
              this.finished  = true
              return 
            }
          }
        })
      },
      // 获取物流信息
      getLogisticsData(index) {
        this.$ajax({
          url: '/hxclass-mobile/exam/certif-logistics',
          method: 'GET',
          params: {
            certifId: this.courseList[index].certifId
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // 物流
            this.courseList[index].logisticsData = {
              tellPhone: res.data.consigneeMobile, //收货电话
              address: res.data.consigneeAddress, //收货地址
              logisticsInfo: res.data.logistics, //物流
              logisticsCompany: res.data.logisticsCompany, //快递公司
              logisticsCode: res.data.logisticsCode, //快递单号
            }
            // 找到该证书是第几个物流
            let count = 0; //物流计数
            for ( var i = 0 ; i < this.courseList.length ; i++ ) {
              // 有物流
              if (this.courseList[i].logisticsNumber && this.courseList[i].logisticsNumber!=='') {
                // 与点击的id相同
                if ( this.courseList[index].certifId == this.courseList[i].certifId ) {
                  break
                } else (
                  count ++
                )
              }
            }
            
            // 展开
            this.$refs.eLogistics[count].isShowMore = true
          }
        })
      },
      // 处理证书获取时间格式
      handleTime(con) {
        if (!con) {
          return;
        }
        let txt = con.split('-')[0] + '-' + con.split('-')[1]
        return txt
      },
		},
		onPageScroll(e){}
	}
</script>

<style>
page {
  background-color: #f7f7f9;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.certificate {
  background: #f7f7f9;
  min-height: 100vh;
  line-height: normal;
}
.nav-circular {
  position: relative;
  z-index: 99;
}
// 切换框
.tab {
  width: calc(100% - 29px);
  background: #f7f7f9;
  position: fixed;
  overflow: hidden;
  // top: 46px;
  // display: flex;
  // align-items: center;
  // width: 100%;
  // font-family: PingFangMedium;
  // background: #f7f7f9;
  z-index: 10;
  padding-left: 11px;
  // padding: 22px 0px 0;
}

/deep/.van-tabs-default .van-tab {
  margin-right: 29px;
}

// 证书列表
.box {
  padding: 6.5px 18px;
  padding-top: 45px;
  .box_item {
    display: inline-block;
    width: 100%;
    padding: 12.5px 0;
    border-bottom: 0.5px solid #eeeeee;
    &:nth-last-child(1) {
      border: 0;
    }
    .con_imgBox {
      position: relative;
      // width: 302.5px;
      // .img_type {
      // 	position: absolute;
      // 	top: 0;
      // 	right: 0;
      // 	z-index: 10;
      // 	.type_bg {
      // 		position: absolute;
      // 		top: 0;
      // 		right: 0;
      // 		width: 60px;
      // 	}
      // 	.type_txt {
      // 		position: absolute;
      // 		top: 15px;
      // 		right: -2.5px;
      // 		width: 47px;
      // 		transform: rotate(45deg);
      // 		font-size: 10px;
      // 		font-family: PingFangMedium;
      // 		font-weight: 500;
      // 		color: #FFFFFF;
      // 		text-align: center;
      // 	}
      // }
      .img_main {
        width: 100%;
      }
    }
    .item_title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      font-size: 14.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      margin-top: 9px;
      .title_info {
        .info_img {
          width: 11px;
          vertical-align: middle;
        }
        .info_txt {
          font-size: 12px;
          font-weight: 500;
          margin-left: 3.5px;
          color: #636364;
          line-height: 17.5px;
          vertical-align: middle;
        }
      }
    }
    .item_con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;
      margin-top: 4px;
      .con_num {
        font-weight: 400;
        font-size: 12px;
      }
      .con_date {
        font-weight: 400;
        font-size: 11px;
      }
    }
  }
}

.loading {
  position: absolute;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 150px;
  color: #fff;
}

// 暂无数据
.box_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 160px;
  // .empty_img {
  //   width: 105px;
  // }
  // .empty_txt {
  //   font-size: 14.5px;
  //   font-weight: 500;
  //   color: #666666;
  //   margin-top: -3px;
  // }
}

// 证书上传流程
.box1 {
  overflow: hidden;
  display: block;
  margin-top: 15px;
  padding: 35px 18px 27.5px 18px;
  .box_info {
    font-size: 12.5px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }
  .box_title {
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    margin-top: 16.5px;
    &::before {
      display: inline-block;
      content: "";
      width: 3px;
      height: 13px;
      background: #15b7dd;
      border-radius: 4px;
      margin-right: 3.5px;
    }
  }
  .box_con {
    text-align: center;
    margin-top: 16.5px;
    padding: 0 8px;
    .con_icon {
      // width: 287px;
      width: 100%;
      padding: 0 10px;
    }
    .con_info {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;
      margin-top: 3.5px;
      // padding: 0 15px;
    }
    .con_upload {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 302.5px;
      height: 181px;
      background: #f5fdff;
      margin-top: 27px;
      .upload_icon {
        width: 33.5px;
      }
      .upload_txt {
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15b7dd;
        margin-top: 9px;
      }
    }
  }
}

// 上传
.box_button {
  background: #f7f7f9;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 37.5px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 40px;
  .all-btn-middle {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14.5px;
  }
}
</style>
