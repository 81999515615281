<template>
  <div class="container-box">
    <div class="banner-box">
      <div class="banner-circle" @click="$onReturn($route.query.fromMenu == '1')">
        <van-icon name="arrow-left" />
      </div>
      <div class="banner-title text">{{ $route.query.name }}</div>
      <!-- 介绍+背景 -->
      <div class="banner-photo">
        <div class="Mask"></div>
        <img src="@/assets/image/knowledge/banner_autismSpeaks.png" alt="">
        <!-- <div class="banner-introduce text">{{ $route.query.name }}介绍</div> -->
        <!-- 占位 -->
        <div style="height:110px"></div>
        <div class="banner-content" v-if="introduce">
          <p>{{ introduce }}</p>
        </div>
      </div>
    </div>
    <div class="">
      <van-list
        class="main-box"
        v-model="newLoading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getSpeakList"
      >
        <div
          v-for="item in speakList"
          :key="item.autismId"
          class="main-list"
          @click="getDetail(item.name, item.fileUrl)"
        >
          <!-- <a target="_blank"> -->
          <van-image class="list-cover" fit="cover" :src="item.img" />
          <!-- </a> -->
          <!-- <div class="rectangle"></div> -->
          <div class="list">
            <div class="list-first all-font-ellipsis">
              {{ item.name }}
            </div>
            <!-- <div class="list-first list-second">作者：{{ item.author }}</div>
            <div class="list-first list-second">译者：{{ item.translator }}</div> -->
          </div>
        </div>
      </van-list>
    </div>
    <noMessage v-if="speakList.length == 0" type="1"></noMessage>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      introduce: "",
      speakList: [],
      fileUrl: "",

      newCurrent: 0, //当前页数
      newSize: 10, //条数
      newLoading: false,
      finished: false,
      //   data: {
      //     autisticList: [
      //       {
      //         mobileImg: require("../../assets/image/datas/banner_live.jpg"),
      //         name: "自闭症之声名称",
      //         author: "我是个作者",
      //         translator: "我是个作者",
      //       },
      //     ],
      //   },
    };
  },
  // 事件处理器
  methods: {
    getIntroduce() {
      this.$ajax({
        url:
          "/hxclass-mobile/autism/classification/introduce?autismClassificationId=" +
          this.$route.query.classId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = res.data.name;
        }
      });
    },
    getSpeakList() {
      this.newCurrent++;
      this.$ajax({
        url:
          "/hxclass-mobile/autism/classification/select/list?autismClassificationId=" +
          this.$route.query.classId +
          "&pageNum=" +
          this.newCurrent +
          "&pageSize=" +
          this.newSize,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.speakList = this.speakList.concat(res.data.records);
          this.newLoading = false;
          // 加载完成
          if (this.speakList.length == res.data.total) {
            this.finished = true;
          }
          // 无数据
          if (this.speakList.length == 0) {
            this.finished = true;
          }
        }
      });
    },
    getDetail(name, url) {
      this.$router.push({
        path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 判断入口
    if (this.$route.query.type == '1') {
      this.type = 1
    } else {
      this.type = 2
    }
    this.newCurrent = 0;
    this.getIntroduce();
    this.getSpeakList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style>
body {
  box-sizing: border-box;
  overflow-y: auto;
}
body::-webkit-scrollbar {
  width: 4px;
}
body::-webkit-scrollbar-track {
  background-color: #ccc;
}
body::-webkit-scrollbar-thumb {
  background-color: #666;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="less" scoped>
/deep/.nomessage {
  margin-top: 66px;
}
.container-box {
  width: 100%;
  height: 100vh;
  overflow: auto;
  /* 隐藏滚动条 */
  * ::-webkit-scrollbar {
    width: 0 !important;
  }

  * ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  * ::-webkit-scrollbar {
    display: none;
  }

  /deep/.van-image__img {
    display: block;
    border-radius: 20px;
    object-fit: fill !important;
  }

  .banner-box {
    position: relative;
    margin-bottom: 12.5px;

    .text {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #ffffff;
    }
    .banner-photo {
      width: 100%;
      min-height: 336px;
      position: relative;
      padding-bottom: 20px;
      img{
        position: absolute;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .Mask{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
    .banner-circle {
      position: absolute;
      top: 50px;
      left: 11px;
      width: 27px;
      height: 27px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
      z-index: 2;
      /deep/.van-icon {
        color: #fff;
      }
    }
    .banner-title {
      position: absolute;
      display: inline-block;
      max-width: 240px;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 18px;
      font-family: PingFangMedium;
      color: #ffffff;
      text-align: center;
      z-index: 2;
    }
    .banner-introduce {
      position: absolute;
      top: 106px;
      left: 25.5px;
      line-height: 27px;
      font-weight: 500;
      z-index: 2;
    }
    .banner-content {
      position: relative;
      margin: 0 auto;
      padding: 10px 14px;
      width: calc(100% - 72px);
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      z-index: 2;
    }
  }
  .main-box {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin: 18px auto;
    width: calc(100% - 36px);
    overflow: hidden;
    .main-list {
      float: left;
      font-size: 0;
      position: relative;
      margin-bottom: 18px;
      width: calc((100% - 15px) / 2);
      margin-right: 15px;
      .list-cover {
        width: 100%;
        height: 210.5px;
      }
      .list {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 57px;
        background: #3189C9;
        border-radius: 0px 0px 20px 20px;
        .list-first {
          padding: 3px 10px;
          font-size: 15px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #ffffff;
          line-height: 23px;
        }
        .list-second {
          font-size: 10px;
          font-weight: 500;
          line-height: 15px;
        }
      }
    }
    .main-list:nth-child(2n) {
      margin-right: 0;
    }
  }
}
/deep/ .van-list__finished-text {
  display: inline-block;
  width: 100%;
  margin: auto;
}
.rectangle {
  position: absolute;
  bottom: 57.5px;
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.09);
}
</style>
