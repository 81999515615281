<template>
  <div class="my">
    <!-- 背景图 -->
    <div class="box1">
      <img class="bg_img" src="@/assets/image/my/my_bg.png" mode="aspectFill" />
      <div class="box_con">
        <div class="con_top" @click="goHomePage()">
          <div class="top_left">
            <van-image
              v-if="$store.state.userInfo.isLogin&&$store.state.userInfo.photo"
              class="left_photo"
              :src="$store.state.userInfo.photo"
              fit="cover"
            />
            <img
              v-else
              class="left_photo"
              src="@/assets/image/my/default_photo.png"
              mode="aspectFill"
            />
            <div class="left_main">
              <div class="main_name">
                <!-- {{ $store.state.userInfo.isLogin ? $store.state.userInfo.nickName || $store.state.userInfo.mobile : "登录/注册" }} -->
                {{ $store.state.userInfo.isLogin ? ($store.state.userInfo.userName ? $store.state.userInfo.userName : $store.state.userInfo.mobile) : "登录/注册" }}
              </div>
              <div v-if="isShow" class="main_label">
                <!-- 个人普通用户 -->
                <img 
                  v-if="!$store.state.userInfo.certified" 
                  class="label_item" 
                  src="@/assets/image/my/label_icon1.png" 
                  mode="widthFix" />
                <!-- 家长 -->
                <img
                  v-if="userType==1 || userType==4 || userType==5 || userType==7" 
                  class="label_item" 
                  src="@/assets/image/my/label_icon2.png" 
                  mode="widthFix" />
                <!-- 机构 -->
                <img 
                  v-if="userType==3 || userType==5 || userType==6 || userType==7" 
                  class="label_item" 
                  src="@/assets/image/my/label_icon3.png" 
                  mode="widthFix" />
                  <!-- 从业者 -->
                <img 
                  v-if="userType==2 || userType==4 || userType==6 || userType==7" 
                  class="label_item" 
                  src="@/assets/image/my/label_icon4.png" 
                  mode="widthFix" />
              </div>
            </div>
          </div>
          <div class="top_right">
            <van-icon name="arrow" color="#fff" size="20" />
          </div>
        </div>
        <div class="con_bottom">
          <router-link to="/mine/coupon" class="bo_item">
            <!-- pick -->
            <div v-if="isShow" class="item_num">{{$store.state.userInfo.couponTotal||couponTotal}}</div>
            <div v-else class="item_line">—</div>
            <div class="item_txt">优惠券</div>
          </router-link>
          <router-link 
            :to="{
              path: '/mine/collection',
              query: {
                isLoad: true
              }
            }"
            class="bo_item">
            <div v-if="isShow" class="item_num">{{$store.state.userInfo.collectionTotal||collectionTotal}}</div>
            <div v-else class="item_line">—</div>
            <div class="item_txt">收藏</div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="box2">
      <!-- <router-link to="/order/myOrder" class="box_item"> -->
      <router-link 
        :to="{name:'keepAliveMyOrder',params:{keepAlive:true}}"
        class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon1.png"
            mode="widthFix"
          />
          <div class="main_txt">我的订单</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <router-link to="/invoice/InvoiceCenter" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon2.png"
            mode="widthFix"
          />
          <div class="main_txt">发票中心</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <router-link to="/mine/identityAuthentication" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon3.png"
            mode="widthFix"
          />
          <div class="main_txt">身份认证</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <router-link to="/mine/feedback" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon4.png"
            mode="widthFix"
          />
          <div class="main_txt">帮助与反馈</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <!-- <router-link to="/mine/institutional" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon5.png"
            mode="widthFix"
          />
          <div class="main_txt pick">机构绑定信息</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link> -->
      <router-link to="/setUp/index" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="@/assets/image/my/my_icon6.png"
            mode="widthFix"
          />
          <div class="main_txt pick">设置</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
    </div>
    
    <!-- 消息提醒 -->
    <div 
      v-if="!isShow || $store.state.userInfo.messageNum == 0 ||messageNum == 0" 
      class="box3">
      <router-link 
        to="/all/myNews" 
        @click.native="$store.commit('updatenewsActive','')" 
        tag="div" 
        class="news">
        <img
          class="box_icon"
          src="@/assets/image/my/my_hint.png"
          mode="widthFix"
        />
      </router-link>
    </div>

    <van-badge 
      v-else 
      :content="($store.state.userInfo.messageNum < 100 ? $store.state.userInfo.messageNum : '99+')||(messageNum < 100 ? messageNum : '99+')" 
      class="box3">
      <!-- <router-link :to="!!messageNum?'/all/myNews':''" tag="div" class="news"> -->
      <router-link 
        to="/all/myNews" 
        tag="div" 
        class="news">
        <img
          class="box_icon"
          src="@/assets/image/my/my_hint.png"
          mode="widthFix"
        />
      </router-link>
    </van-badge>
    <div class="shopCarIcon" @click="showShopCar" v-if="isShow">
      <img src="@/assets/image/icon/shopCarIcon.png" alt="">
      <span class="num" v-show="shopCarList.length">{{num > 99 ? '99+' : num}}</span>
    </div>
    <ShpCarModel ref="ShpCarModel" :shopCarFlag="shopCarFlag" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
  </div>
</template>

<script>
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
export default {
  
  // 可用组件的哈希表
  components: { ShpCarModel },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 类型：01.家长，02从业者，03.机构，04.家长+从业者，05.家长+机构，06.从业者+机构，07 家长+从业者+机构
      userType: 0, //用户类型
      src: "",
      messageNum:0, // 未读消息数量
      couponTotal: 0, // 优惠券数量
      collectionTotal:0, // 收藏数量
      isShow: true,
      offShelfItems:[], // 购物车下架商品
      shopList:[], // 商品列表
      shopCarList:[], // 购物车列表
      num: 0, // 购物车数量
			shopCarFlag:true, // 购物车弹框状态
      // showShopCarStatus: false, // 商城弹框展开
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$store.state.userInfo.isLogin) {
      // this.userType = this.$store.state.userInfo.userType
      // 获取个人信息
      this.getUserInfo()
      // 获取未读消息数量
      this.getMessageNum();
      // 查询优惠券数量
      this.getCouponTotal()
      // 查询收藏数量
      this.getCollectionTotal()
      // 获取购物车列表
      this.getShopCarList()
    }else if (!this.$store.state.userInfo.isLogin){
      this.isShow = false;
    }
  },
  // 事件处理器
  methods: {
    showShopCar(){
      if(this.shopCarList.length || this.offShelfItems.length){
        this.shopCarFlag = !this.shopCarFlag;
        this.$nextTick(()=>{
          // console.log(this.$refs.ShpCarModel)
          this.$refs.ShpCarModel.showPopup = true;
        })
      }else{
        this.$Toast('请添加商品')
      }
    },
    // 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
		},
    // 获取个人信息
    getUserInfo() {
      this.$ajax({
        url: '/hxclass-mobile/user/info',
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let obj = res.data
          obj.isLogin = true;
          this.$store.commit("updateUserInfo", obj);
          this.userType = res.data.userType
        }
      })
    },
    // 去主页
    goHomePage() {
      if (this.$store.state.userInfo.isLogin) {
        this.$router.push("/mine/personal");
      } else {
        this.$router.push('/login/login');
      }
    },

    goOrderDetail() {
      this.$router.push("/order/orderDetail");
    },
    onRemove() {
      this.showModal({
        title: "提示信息",
        content: "确定要删除该发票抬头？",
        success: (e) => {
          if (e.confirm) {
            // console.log("点击了确定");
          } else {
            // console.log("点击了取消");
          }
        },
      });
    },
    // 获取未读消息数量
    getMessageNum(){
      this.$ajax({
        url:'/hxclass-mobile/user/msg/app/num',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.messageNum = res.data;
          this.$store.commit('changeMessageNum', this.messageNum);
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 查询优惠券数量
		getCouponTotal(){
			this.$ajax({
				url: '/hxclass-mobile/app-mall/user/coupon-list',
				params: {
					page: 1,
					size: 1,
					type: 1,
				}
			}).then(res=>{
        if(res.code == 200 && res.success){
          this.couponTotal = res.data.total
          this.$store.commit('changeCouponTotal', this.couponTotal);
        }
      }).catch(err=>{
        console.error(err)
      })
    },
    // 查询优惠券数量
		getCollectionTotal(){
			this.$ajax({
				url: '/hxclass-mobile/collection/app/num',
			}).then(res=>{
        if(res.code == 200 && res.success){
          this.collectionTotal = res.data
          this.$store.commit('changeCollectionTotal', this.collectionTotal);
        }
      }).catch(err=>{
        console.error(err)
      })
    }
    
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  updated() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {
    sessionStorage.setItem('handleorderID','')
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    shopCarList(newVal,oldVal){
      this.num = 0
      // console.log(newVal)
      newVal.map(item=>{
        this.num += item.count;
      })
    }
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.my {
  min-height: 100%;
  background: #f7f7f9;
  // padding-bottom: 55px;
}
/deep/.van-badge--fixed {
	top: 6px;
	right: 6px;
}

.box1 {
  position: relative;

  .bg_img {
    position: absolute;
    width: 100%;
    height: 221px;
  }
  .box_con {
    position: relative;
    z-index: 1;
    padding: 88px 18px 0 18px;
    .con_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .top_left {
        display: flex;
        align-items: center;
        .left_photo {
          width: 72px;
          height: 72px;
          overflow: hidden;
          border-radius: 50%;
        }
        .left_main {
          flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					// height: 57px;
          margin-left: 12px;
          .main_name {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
          }
          .main_label {
            margin-top: 18px;
            .label_item {
              width: 20px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .con_bottom {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      margin-top: 16px;
      padding: 16px 0;
      .bo_item {
        position: relative;
        width: 50%;
        font-family: PingFangMedium;
        text-align: center;
        &:last-child {
          &::before {
            position: absolute;
            left: 0;
            top: 58%;
            margin-top: -19px;
            display: inline-block;
            content: "";
            width: 1px;
            height: 28px;
            background-color: #eeeeee;
          }
        }
        .item_num {
          font-family: PingFangMedium;
          position: relative;
					line-height: 28px;
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          &.pick::after {
            position: absolute;
            top: 0;
            display: inline-block;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #EC6C01;
          }
        }
        .item_txt {
					line-height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-top: 2px;
        }
        .item_line{
          height: 28px;
          font-size: 24px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #333333;
          line-height: 28px;
        }
      }
    }
  }
}

.box2 {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  margin: 14px 18px 0 18px;
  padding: 10px 12px 4px 12px;
  .box_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding: 16px 0 12px 0;
    &:last-child {
      border: 0;
    }
    .item_main {
      display: flex;
      align-items: center;
      .main_icon {
        width: 20px;
      }
      .main_txt {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        font-family: PingFangMedium;
        margin-left: 10px;
        // &.pick::after {
        //   position: absolute;
        //   top: -2px;
        //   right: -6px;
        //   content: "";
        //   display: inline-block;
        //   width: 6px;
        //   height: 6px;
        //   border-radius: 50%;
        //   background: #EC6C01;
        // }
      }
    }
  }
}

// 消息提醒
.box3 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 49px;
  right: 15px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 11;
  .box_icon {
    width: 16px;
  }
}
.shopCarIcon{
  padding: 16px 12px 12px 7px;
  position: fixed;
  bottom: 98px;
  right: 18px;
  background: #ffffff;
  border-radius: 50%;
  img{
    width: 31px;
    height: 22px;
  }
  .num{
    position: absolute;
    top: 0;
    right: 0;
    font-style: normal;
    text-align: center;
    // width: 15px;
    // height: 15px;
    min-width: 15px;
    padding: 0 3px;
    border-radius: 999px;
    background: #FF7A00;
    font-size: 10px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 15px;
  }
}
</style>
