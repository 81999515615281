export default [
    /*
      子模块的路由前面不需要添加‘/’
    */
    {
        path: 'examSignList', // 考试报名
        component: () =>
            import('../views/examination/examSignList.vue')
    },
    {
        path: 'examCourse', // 报考指南
        component: () =>
            import('../views/examination/examCourse.vue')
    },
    {
        path: 'changeClassTime', // 更换开课时间
        component: () =>
            import('../views/examination/changeClassTime.vue')
    },
    {
        path: 'grade', // 成绩单
        component: () =>
            import('../views/examination/grade.vue')
    },
    {
        path: 'exam', // 模拟考试
        component: () =>
            import('../views/examination/exam.vue')
    },
    {
        path: 'examMask', // 考试弹框
        component: () =>
            import('../views/examination/examMask.vue')
    },
    {
        path: 'examRecord', // 考试结果
        component: () =>
            import('../views/examination/examRecord.vue')
    },
    {
        path: 'index', // 模拟考试
        component: () =>
            import('../views/examination/index.vue')
    },
    {
        path: 'question', // 常见问题
        component: () =>
            import('../views/examination/question.vue')
    },
    {
        path: 'selfTest', // 小节自测
        component: () =>
            import('../views/examination/selfTest.vue')
    },
    {
        path: 'testPaper', // 模拟考试答案
        component: () =>
            import('../views/examination/testPaper.vue')
    },
    {
        path: 'examDetail', // 模拟考试详情
        component: () =>
            import('../views/examination/examDetail.vue')
    },
    {
        path: 'shareExamDetail', // 模拟考试详情分享页面
        component: () =>
            import('../views/examination/shareExamDetail.vue')
    }
]