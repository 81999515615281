<template>
  <!-- 资讯速递 -->
  <div class="container-box">
    <div class="informationitem" v-for="i in listData" :key="i.newsId" @click="goDetail(i.newsId)">
    	<div class="left">
    		<div class="title" v-html="i.title"></div>
    		<div class="content">{{i.summary}}</div>
    		<div class="date">{{i.createTime.substring(0, 10)}}</div>
    	</div>
    	<div class="right" v-if="i.appImg">
    		<img class="img" :src="i.appImg" />
    	</div>
    </div>
  </div>
</template>
<script>
	export default {
		// 可用组件的哈希表
		components: {},
   		props: {
   			// 渲染数据
   			listData: {
   				type: Array,
   				default: ()=>[]
   			},
   		},
		// 数据对象
		data() {
			return {}
		},
		// 事件处理器
		methods: {
			goDetail(id){
				if(!this.$store.state.userInfo.userId){
          return this.$router.push('/login/login');
        }
				this.$router.push({path:'/info/information',query:{id:id}});
			}
    },
	}
</script>

<style lang="less" scoped>
  @import "./searchSeo.less";
</style>
