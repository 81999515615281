<template>
  <div class="container-box">
    <van-nav-bar
      :title="type==1?'修改密码':type==2?'设置密码':''"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <!-- 修改密码 -->
      <div class="single-menu-list" v-if="type==1">
        <div class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">原密码：</span>
            <van-field 
              class="input item_password" 
              v-model="oldPassword" 
              placeholder="请输入原密码" />
          </div>
        </div>
        <div class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">新密码：</span>
            <van-field 
              class="input item_password" 
              v-model="newPassword" 
              placeholder="请输入密码" />
          </div>
        </div>
        <div class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">确认新密码：</span>
            <van-field 
              class="input item_password" 
              v-model="confirm" 
              placeholder="请确认新密码" />
          </div>
        </div>
      </div> 
      
      <!-- 首次设置密码 -->
      <div class="single-menu-list" v-else-if="type==2">
        <div class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">设置密码：</span>
            <van-field 
              class="input item_password" 
              v-model="newPassword"
              placeholder="请输入密码" />
              <!-- placeholder="请输入8-14位，字母，数字的组合密码" /> -->
          </div>
        </div>
        <div class="single-menu-item">
          <div class="side-left">
            <span class="p side-left-title">确认新密码：</span>
            <van-field 
              class="input item_password" 
              v-model="confirm"
              placeholder="请确认新密码" />
          </div>
        </div>
      </div> 
    </div>
    <div class="onSave-foo">
      <span 
        class="all-btn-bigCircle" 
        @click="modifyCode">{{type==1?'确认修改':type==2?'确认密码':''}}</span>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        type: 0, //1修改密码 2设置密码
        oldPassword: '', //原密码
        newPassword: '', //新密码
        confirm: '', //确认新密码
      }
		},
    created() {
      this.type = this.$route.query.type
    },
		// 事件处理器
		methods: {
      // 修改密码、设置密码
      modifyCode() {
        // 修改密码
        if (this.type == 1) {
          if (!this.oldPassword) {
            this.$Toast("请输入原密码");
            return false;
          }
          //  else if (!this.$regular.password.reg.test(this.oldPassword)) {
          //   this.$Toast("原密码格式错误，密码为8~16位的数字、字母组成");
          //   return false;
          // } 
          else if (!this.newPassword) {
            this.$Toast("请输入新密码");
            return false;
          } else if (!this.$regular.password.reg.test(this.newPassword)) {
            this.$Toast("新密码格式错误，密码为8~16位的数字、字母组成");
            return false;
          } else if (!this.confirm) {
            this.$Toast("请再次输入新密码");
            return false;
          } else if (this.newPassword !== this.confirm) {
            this.$Toast('新密码两次输入不同');
            return false;
          }
        }
        // 设置密码
        if (this.type == 2) {
          if (!this.newPassword) {
            this.$Toast("请输入密码");
            return false;
          } else if (!this.$regular.password.reg.test(this.newPassword)) {
            this.$Toast("密码格式错误，密码为8~16位的数字、字母组成");
            return false;
          } else if (!this.confirm) {
            this.$Toast("请再次输入密码");
            return false;
          } else if (this.newPassword !== this.confirm) {
            this.$Toast('密码两次输入不同');
            return false;
          }
        }

        this.$ajax({
          url: '/hxclass-mobile/user/updatePassword',
          method: "POST",
          params: {
            oldPassword: this.oldPassword, //原密码
            newPassword: this.newPassword, //新密码
            confirm: this.confirm, //确认新密码
            phone: this.$store.state.userInfo.mobile
          }
        }).then((res) => { 
          if (res.code == 200 && res.success) {
            this.$Toast.success('修改成功');
            this.oldPassword = ''
            this.newPassword = ''
            this.confirm = ''
            // 用户信息
            this.getUserInfo()
          } else {
            this.$alert(res.message);
          }
        })
      },
      // 获取用户信息
      getUserInfo() {
        this.$ajax({
          url: '/hxclass-mobile/user/info',
          method: "get"
        }).then((res) => {
          if (res.code == 200 && res.success) {
            let updateUserInfo = res.data
            this.$store.commit("updateUserInfo", updateUserInfo);
            this.$onReturn(this.$route.query.fromMenu == '1')
          }
        })
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./style.less";
  .side-left{
    display: flex;
    align-items: center;
    width: 100%;
    .input{
      flex: 1;
      margin-left: 7.5px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .item_password {
      /deep/.van-field__control {
        -webkit-text-security: disc !important;
      }
    }
  }
  // 保存按钮
  .onSave-foo{
    box-sizing: border-box;
    position: absolute;
    bottom: 35px;
    width: 100%;
    text-align: center;
    padding: 0 40px;
    .all-btn-bigCircle {
      width: 100%;
    }
  }
</style>
