<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      :style="{height: 46 + stateHeight + 'px'}"
      title="确认信息"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="goBack"
    />
    <!-- 步骤 -->
    <div class="step-box">
      <div class="step-item step-hover" >
        <span class="num">1</span>
        <span class="font">证书信息确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex != 1}">
        <span class="num">2</span>
        <span class="font">照片提交方式确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex == 3}">
        <span class="num">3</span>
        <span class="font">证书邮寄地址确认</span>
      </div>
    </div>
    <!-- 主功能区 -->
    
    <!-- 第一步 -->
    <van-form @submit="onSubmit">
      <div class="content-box" v-show="stepIndex==1">
        <div class="all-bg">
          <div class="all-title">基本信息：</div>
          <div class="tips">
            <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
            <span>提示：以下信息均体现在证书上，请认真核实</span>
          </div>
          <div class="child-item">
            <span class="item-L-name">姓名</span>
            <van-field 
              disabled
              class="input" 
              v-model="name"
              placeholder="请输入您的姓名"
              name="name"
              :rules="[{ required: true}]"/>
          </div>
          <div class="child-item">
            <span class="item-L-name">性别</span>
            <div class="item-R-select">
              <van-field name="sex" class="sex">
                <template #input>
                  <van-radio-group 
                    disabled
                    v-model="sex" 
                    direction="horizontal">
                    <van-radio checked-color="#15B7DD" name="1" icon-size="14px">男</van-radio>
                    <van-radio checked-color="#15B7DD" name="0" icon-size="14px">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
          </div>
          <div class="child-item">
            <span class="item-L-name">出生日期</span>
            <!-- <van-field class="input" value="2022-15-15"/> -->
            <div class="item-L-flex" @click="dateFlag=true">
              <van-field 
                readonly
                class="input" 
                v-model="birthday"  
                placeholder="请选择您的出生日期"
                name="birthday"
                :rules="[{ required: true}]" />
              <van-icon name="arrow" class="iconR" color="#999999" />
            </div>
            <!-- 日期弹框 -->
            <van-popup v-model="dateFlag" position="bottom">
              <van-datetime-picker
                type="date"
                title="选择年月日"
                v-model="currentDate"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="ondateConfirm"
                @cancel="dateFlag = false"
              />
            </van-popup>
          </div>
          <div class="child-item">
            <span class="item-L-name">联系方式</span>
            <van-field 
              disabled
              class="input" 
              placeholder="请输入您的联系方式" 
              v-model="mobile"
              name="mobile"
              :rules="[{ pattern: $regular.phone.reg }]"/>
          </div>
          <div class="child-item">
            <span class="item-L-name">身份证号</span>
            <van-field 
              disabled
              class="input" 
              v-model="idcard" 
              placeholder="请输入您的身份证号"
              name="idcard"
              :rules="[{ required: true }]" />
              <!-- :rules="[{ pattern: $regular.idCardAll.reg }]" /> -->
          </div>
          <div class="child-item">
            <span class="item-L-name">学历</span>
            <div class="item-L-flex">
              <van-field 
                class="input" 
                :border='false' 
                :value="education" 
                :readonly='true' 
                @click="educationFlag = true" 
                placeholder="请选择您的学历"
                name="education"
                :rules="[{ required: true}]" />
              <van-icon name="arrow" class="iconR" color="#999999" />
            </div>
            <van-popup v-model="educationFlag" position="bottom" >
              <van-picker
                title="标题"
                show-toolbar
                value-key="name"
                :columns="educationColumns"
                :default-index="educationIndex"
                @confirm="oneducationConfirm"
                @cancel="addressShow = false"
              />
            </van-popup>
          </div>
          <div class="child-item">
            <span class="item-L-name">职称/职务</span>
            <div class="item-L-flex">
              <van-field 
                class="input" 
                @click="workFlag = true"
                readonly
                v-model="post" 
                placeholder="请选择您的职务"
                name="post"
                :rules="[{ required: true}]" />
              <van-icon name="arrow" class="iconR" color="#999999" />
            </div>
            <!-- 职称弹框 -->
            <van-popup v-model="workFlag" position="bottom">
              <van-picker
                title="职称"
                show-toolbar
                value-key="name"
                :columns="workColumns"
                :default-index="workIndex"
                @confirm="onworkConfirm"
                @cancel="workFlag = false"
              />
            </van-popup>
          </div>
          <div class="child-item" style="border-bottom: none;">
            <span class="item-L-name">工作单位</span>
            <van-field 
              readonly
              class="input" 
              v-model="workUnit"
              placeholder="请填写工作单位"
              @click="goWorkUnit()"
              name="workUnit"
              :rules="[{ required: true}]" />
            <van-icon name="arrow" class="iconR" color="#999999" />
          </div>
          <!-- 下一步 -->
          <div class="next-btn">
            <button
              type="default" 
              class="all-btn-bigCircle" 
              native-type="submit">下一步</button>
          </div>
        </div>
      </div>
    </van-form>
    
  </div>
</template>

<script>
  import { onGoStudy, onRemoveStorage } from '@/assets/js/NativeWebKit.js'
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        stateHeight: 0, //信息条高度
        stepIndex:1 ,// 控制步骤样式
        educationShow:false,
        examineeId: '', //考生id
        signupUserId: '', //用户报名id
        addressId: '', //退货地址id
        verifyType: '', // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式
        certifiMobile: '', //电子照片

        name: '', //姓名
        sex: '', //性别
        birthday: '', //出生日期
        mobile: '', //联系方式
        idcard: '', //身份证号
        education: "", //学历
        post: '', //职称/职务
        workUnit: '', //工作单位

        dateFlag: false, //日期选择器
        currentDate: new Date(2000, 0, 1), //当前时间
        minDate: new Date(1900, 0, 1), //最小时间
        maxDate: new Date(), //最大时间
        educationFlag: false, //学历选择器
        educationColumns: ['小学', '初中', '高中', '大学'], //学历
        educationIndex: -1,
        workFlag: false, // 职位职称
        workColumns: [1,1,1], // 职位职称
        workIndex: -1,
      }
		},
    created() {
      // 获取信息条高度
      this.stateHeight = this.$store.state.stateHeight;
      // 考生id
      this.examineeId = this.$route.query.examineeId
      // 用户报名id
      this.signupUserId = this.$route.query.signupUserId
      
      // 学历列表
      this.getEducation()
      // 职称列表
      this.getWork()
      // 获取报名信息
      this.getSignUp()

      this.$nextTick(() => {
        // 监听页面返回
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL);
          window.addEventListener('popstate', this.goBack, false);
        }
      })
    },
    destroyed() {
      // 离开页面去掉监听
      window.removeEventListener('popstate', this.goBack, false);
    },
    activated() {
      // console.log(111)
      // 获取工作单位
      this.getWorkUnit()

      this.$nextTick(() => {
        // 监听页面返回
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL);
          window.addEventListener('popstate', this.goBack, false);
        }
      })
    },
    deactivated() {
      // console.log(222)
      // 离开页面去掉监听
      window.removeEventListener('popstate', this.goBack, false);
    },
		// 事件处理器
		methods: {
      // 返回
      goBack() {
        // 我的订单页支付成功后跳入
        if (this.$route.query.pay) {
          // this.$router.push('/study')
          onGoStudy();
        } else {
          this.$onReturn(this.$route.query.fromMenu == '1');
        }
      },
      // 提交
      onSubmit(values) {
        // console.log(values)
        // 性别
        if (this.sex == '') {
          this.$toast('请选择性别')
          return
        }

        let params = values
        params.sex = parseInt(params.sex) //性别
        params.signupUserId = parseInt(this.signupUserId) //用户报名id
        params.userId = this.$store.state.userInfo.userId //报名用户id
        params.auditStatus = 2 //审核状态
        // console.log(params)

        // 存储信息
        this.setConfirm()

        // 报名
        this.$ajax({
          url: '/hxclass-mobile/exam/signup/edit',
          method: 'PUT',
          params: params
        }).then((res) => {
          // console.log(res);
          if (res.code == 200 && res.success) {
            // this.$Toast.success("提交成功");
            this.$router.push({
              path:'/examEnroll/signUpConfirm2',
              query:{
                signupId: this.signupId, //考试id
                signupUserId: this.signupUserId, //用户报名id
                verifyType: this.verifyType, //照片确认方式
                // examineeId: this.examineeId, //考生id
                // addressId: this.addressId,  //退货地址id
                // certifiMobile: this.certifiMobile //电子照片
              }
            });
          } else {
            this.$toast(res.message)
          }
        });
      },
      // 存储信息
      setConfirm() {
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = localStorage.getItem(confirmObjName)
        
        // 存储
        if (!confirmObj) {
          localStorage.setItem(confirmObjName, JSON.stringify({
            signupId: this.signupId, //考试id
            examineeId: this.examineeId, //考生id
            refundAddressId: this.addressId,  //退货地址id
            certifiMobile: this.certifiMobile //电子照片
          }))
        }
      },
      // 获取报名信息
      async getSignUp() {
        await this.$ajax({
          url: '/hxclass-mobile/exam/user-signup-info',
          method: 'GET',
          params: {
            signupUserId: this.signupUserId
          }
        }).then((res) => {
          // console.log(res)
          if (res.code == 200 && res.success) {
            // 数据回显
            this.echoData(res.data)
            // 工作单位页面传入
            this.getWorkUnit()
          }
        })
      },
      // 数据回显
      echoData(data) {
        this.signupId = data.signupId //考试id
        this.addressId = data.address //退货地址id
        this.verifyType = data.verifyType // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式
        this.certifiMobile = data.certifiMobile //电子照片
        this.name = data.name //姓名
        // this.sex = JSON.stringify(data.sex)==2?0:1 //性别
        if (data.sex == 2) {
          this.sex = 0 //性别
        } else {
          this.sex = JSON.stringify(data.sex) //性别
        }
        // console.log(this.sex)
        this.birthday = data.birthday //出生日期
        let birthday = this.birthday.split('-')
        this.currentDate = new Date(birthday[0], birthday[1]-1, birthday[2]), //当前时间
        this.mobile = data.mobile //联系方式
        this.idcard = data.idcard //身份证号
        this.education = data.education //学历
        this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==data.education}) //学历索引
        this.post = data.post //职称/职务
        this.workIndex = this.workColumns.findIndex((item)=>{return item.name==data.post}) //学历索引
        this.workUnit = data.workUnit //工作单位

        // 我的订单支付完成后跳入
        if (this.$route.query.pay) {
          let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
          this.$router.push({
            path:'/examEnroll/signUpConfirm2',
            query:{
              signupId: paySignupObj.signUpId, //报名id
              signupUserId: paySignupObj.signupUserId, //用户报名id
              verifyType: this.verifyType, //用户报名id
              pay: true, //支付成功
            }
          });
        } else {
          // 删除电子照支付标识
          sessionStorage.removeItem('paySignupObj');
          // onRemoveStorage('payMakeObj')
        }
      },
      // 学历列表
      async getEducation() {
        await this.$ajax({
          url: "/hxclass-mobile/nation/degree/list",
          method: "get"
        }).then((res) => {
          // console.log(res);
          if (res.code == 200 && res.success) {
            this.educationColumns = res.data
          }
        });
      },
      // 职务列表
      async getWork() {
        await this.$ajax({
          url: "/hxclass-mobile/job/list/all",
          method: "get"
        }).then((res) => {
          // console.log(res);
          if (res.code == 200 && res.success) {
            this.workColumns = res.data
          }
        });
      },
      // 选择出生日期
      ondateConfirm(e) {
        this.birthday = e;
        this.dateFlag = false
        // console.log(this.birthday)
        // 转换时间
        this.switchTime()
      },
      // 选择学历
      oneducationConfirm(e) {
        this.education = e.name;
        this.educationFlag = false;
      },
      // 选择职位职称
      onworkConfirm(e) {
        this.post = e.name;
        this.workFlag = false;
      },
      // 跳往工作单位
      goWorkUnit() {
        this.$router.push({path:'/mine/workUnit', query:{name:this.workUnit}})
      },
      // 工作单位获取
      getWorkUnit() {
        // 工作单位
        let workUnitObj = localStorage.getItem('workUnitObj')
        if (workUnitObj) {
          // console.log(JSON.parse(workUnitObj))
          workUnitObj = JSON.parse(workUnitObj)
          localStorage.removeItem('workUnitObj')
          this.workUnit = workUnitObj.name // 工作单位
          // this.workCom = workUnitObj.workUnitId || '' // 工作单位
          // console.log(this.workUnit)
        }
      },
      // 转换时间
      switchTime() {
        let year = new Date(this.birthday).getFullYear();
        let mouth =
          new Date(this.birthday).getMonth() + 1 < 10
            ? "0" + (new Date(this.birthday).getMonth() + 1)
            : new Date(this.birthday).getMonth() + 1;
        let day =
          new Date(this.birthday).getDate() < 10
            ? "0" + new Date(this.birthday).getDate()
            : new Date(this.birthday).getDate();
        this.birthday =  year + "-" + mouth + "-" + day;
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./signUpConfirm.less";
  .container-wrap {
    min-height: 100vh;
    background: #FFFFFF;
  }
  .content-box{
    // position: absolute;
    width: 100%;
    // height: 100%;
    // top: 150px;
    // bottom: 0px;
    // right: 0;
    // background: #FFFFFF;
  }
  .all-btn-bigCircle {
    border: 0;
  }
</style>
