import Vue from 'vue'
import Vuex from 'vuex'
// 音频
import audio from './audio.js'
// 登录的所有状态
import userInfo from './userInfo.js'
// 确认订单数据
import confirmOrder from './confirmOrder.js';

Vue.use(Vuex)

export default new Vuex.Store({
  // 公共的状态
  state: {
    isAppLoad: false, // 全局Loading
    findKeyWord:'', // 全局搜索关键字
    searchIndex: '',// 全局搜索索引
    tabIndex: '', // 课程列表一级分类
    scoreIndex: '', // 课程列表分类id
    sectionId: '',  // 课程播放章节id
    newsActive: '', // 我的消息tab
    closeSectionIdArry: [],
    stateHeight: 0, // 顶部高度
    openTimeId: null, // 班期id
    invoiceIndex: 0,
    isReturnBack: true,  // 是否使用原生返回
  },
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {
    // 更新数据
    updateword(state, props){
      state['findKeyWord'] = props;
    },
    updateIndex(state, props){
      state['searchIndex'] = props;
    },
    updatetabIndex(state, props){
      state['tabIndex'] = props;
    },
    updatescoreIndex(state, props){
      state['scoreIndex'] = props;
    },
    updatesectionId(state, props){
      state['sectionId'] = props;
    },
    updatenewsActive(state, props){
      state['newsActive'] = props;
    },
    updatecloseSectionIdArry(state, props){
      state['closeSectionIdArry'] = props;
    },
    updatestateHeight(state, props){
      state['stateHeight'] = props;
    },
    updateopenTimeId(state, props){
      state['openTimeId'] = props;
    },
    updateinvoiceIndex(state, props){
      state['invoiceIndex'] = props;
    },
    // 全局loading
    updateAppLoad(state, props){
      state['isAppLoad'] = props;
    },
  },
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {},
  // 嵌套模块
  modules: {
    audio,
    userInfo,
    confirmOrder
  }
})
