<template>
	<div class="myCourse">
		<!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
			title="图书详情"
			:fixed="true"
			:placeholder="true"
			:style="{
				height: stateOpacity+'px',
      }"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<Skeleton v-if="loading"/>
		<div v-else>
			<div >
				<!-- 顶部课程轮播图 -->
				<div class="courseHeader">
					<!-- <uni-icons class="back" custom-prefix="iconfont" type="icon-Arrowleftback" size="40"></uni-icons> -->
					<div 
						v-if="stateOpacity<1"
						class="back" 
						:style="{top: 11+stateHeight+'px', left: '9px', opacity: 1-(stateOpacity*5)}"
						@click="$onReturn($route.query.fromMenu == '1')">
            <img src="@/assets/image/selected/back.png"  />
          </div>
					<!-- <img class="share" src="@/assets/image/selected/share.png" /> -->

					<van-swipe :autoplay="5000" class="swiper">
						<van-swipe-item v-for="(image, index) in fromObj.photoList" :key="index">
							<div class="swiper-item uni-bg-red">
								<img class="swiperImg" :src="image.photo" />
							</div>
						</van-swipe-item>
					</van-swipe>

					<div class="btnGroup">
						<div class="tryBox" @click="shareFile()">
							<img class="tryImg" src="@/assets/image/selected/shareFile.png" />
							<span class="tryText">分享</span>
						</div>
						<div class="collectBox" @click="onCollection(fromObj.noCollection)">
							<img class="collect" :src="fromObj.noCollection ? collected : collect" />
							<span class="collectText" >{{fromObj.noCollection ? '已收藏' : '收藏'}}</span>
						</div>
					</div>
					<!-- <block class="number">
						<div class="u-indicator-item-number">{{ current + 1 }}/{{ swiperList }}</div>
					</block> -->
				</div>
				<!-- 课程标题以及介绍 -->
				<div class="courseExplain">
					<div class="explainUp">
						<div class="left">
							<span class="price"><span class="moneySign">¥</span>{{fromObj.couponPrice ? fromObj.couponPrice.toFixed(2) : '0.00'}}</span>
							<span class="originalPrice" v-if="fromObj.original">
								<!-- <span>原价：</span> -->
								<span style="text-decoration: line-through">¥{{fromObj.originalPrice ? fromObj.originalPrice.toFixed(2) : '0.00'}}</span>
							</span>
						</div>

					</div>
					<div class="explainCenter">
						{{fromObj.name}}
					</div>
					<div class="explainDown">
						<div class="courseType" v-if="fromObj.slogan">
							<!-- <div class="left">
								{{bookType[fromObj.type - 1]}}｜共20课时
							</div> -->
							<div class="right">
								<span>{{fromObj.slogan}}</span>
							</div>
						</div>
						<div class="fload" v-if="fromObj.abstracts">
              <div class="icon">
								<img src="@/assets/image/20220617-114749.png" alt="">
                <!-- <span>Editor's Pick</span>
                <i></i> -->
              </div>
              <!-- <span >Editor's Pick<i></i></span> -->
              {{fromObj.abstracts}}
            </div>
					</div>
				</div>

				<!-- 答题次数，模拟考试时长 -->
				<!-- <div class="relatedIntroduction">
					<div class="item">
						<div class="left" style="width:84px;">
							答题次数
						</div>
						<div class="right">
							<div class="span">无限次</div>
						</div>
					</div>
					<div class="item">
						<div class="left" style="width:84px;">
							模拟考试时长
						</div>
						<div class="right">
							<div class="span">48分钟</div>
						</div>
					</div>
				</div> -->

				<!-- 优惠卷 -->
				<!-- <div class="couponBox" v-if="couponList.length">
					<div class="item" @click="popUp1">
						<div class="left">
							优惠券
						</div>
						<div class="right">
							<div class="rightBox">
								<div class="couponList">
									<div class="downitem" v-for="(item,index) in couponList" :key="index">
										{{item.type == 1?('满'+item.fullPrice+'减'+item.couponPrice):item.type == 3?('满'+item.discountPrice+(item.discountType==1?'件':'元')+item.discount+'折'):''}}
									</div>
								</div>
								<div class="downitem" v-show="couponList[0].type == 3">
									满{{couponList[0].discountPrice}}{{couponList[0].discountType==1?'件':'元'}}{{couponList[0].discount}}折
								</div>
								<div class="tag">
									领劵
								</div>
							</div>
							<van-icon name="arrow" size="10" color="#999" />
						</div>
					</div>
				</div> -->
				
				<!-- 相关证书、图书教具、附赠 -->
				<div class="relatedIntroduction" style="padding: 0px 18px;margin-bottom: 8px;" v-if="productList.filter(item=>{return item.nexus == 2}).length">
					<div class="item">
						<div class="left" style="line-height: 52px;">
							附赠
						</div>
						<div class="right" style="align-items:center;">
							<div @click="goDetail(item)" class="shoplist style" v-for="(item,index) in productList.filter(item=>{return item.nexus == 2})" :key="index">
								<div class="span">{{item.name}}</div>
								<van-icon name="arrow" size="10" color="#333" />
							</div>
						</div>
					</div>
				</div>
				<!-- 发货地址，发货时间 -->
				<div class="relatedIntroduction" style="margin-bottom: 8px;">
					<div class="item">
						<div class="left">
							发货地址
						</div>
						<div class="right">
							<div class="span">{{fromObj.proviceName}}{{fromObj.cityName}}{{fromObj.areaName}}</div>
						</div>
					</div>
				</div>
				<div class="relatedIntroduction" style="margin-bottom: 8px;">
					<div class="item">
						<div class="left">
							发货时间
						</div>
						<div class="right">
							<div class="span">{{fromObj.shipments}}</div>
						</div>
					</div>
				</div>
				<div class="relatedIntroduction" style="margin-bottom: 8px;">
					<div class="item">
						<div class="left" >
							<span>运</span>
							<span style="display: inline-block;width: 1em;"></span>
							<span style="display: inline-block;width: 1em;"></span>
							<span>费</span>
						</div>
						<div class="right" style="display: flex;align-items: center;">
							<div style="display: flex;flex:1;">
								<template v-if="harvestAddress">
									<span style="color: #FF7A00;font-size: 10px;padding-top:1px;" v-if="freightObj.code != 1">¥</span>
									<span style="color: #FF7A00;" v-else>包邮</span>
								</template>
								<span style="color: #666;" v-else>-	</span>
								<div class="span" style="font-size: 12px;color: #FF7A00;">{{funFreight(freightObj)}}</div>
							</div>
							<div style="display: flex;align-items: center;">
								<span class="span" style="color:#8B8B8D" @click="onSelectAddress">配送至：{{ harvestAddress? harvestAddress.proviceName + harvestAddress.cityName :'请选择地址' }}</span>
								<van-icon name="arrow" size="10" color="#999" />
							</div>
						</div>
					</div>
				</div>
				<!--  -->
				<div class="relatedIntroduction" style="margin-bottom: 8px;">
					<div class="item">
						<div class="left" >
							<span>数</span>
							<span style="display: inline-block;width: 1em;"></span>
							<span style="display: inline-block;width: 1em;"></span>
							<span>量</span>
						</div>
						<div class="right" style="display: flex;align-items: center;">
							<div style="display: flex;flex:1;"></div>
							<div style="display: flex;align-items: center;">
								<van-stepper v-model="count" :min="1" :max="10000" theme="round" button-size="22" />
							</div>
						</div>
					</div>
				</div>
				<!-- 课程介绍、目录、评论... -->
				<div class="tabsBox">
					<div ref="menu" :class="{'menuHeight' : isMenuShow}">
						<SwichTab class="van-tabs-default" :style="{top: isMenuShow ? (navHeight - 1) + 'px' : ''}" :class="{'menuFixed' : isMenuShow}"  @setTab="changeTab" :tabIndex="active" :menuArry="list" />
					</div>
					<div class="tabsContent" :style="'min-height:'+minHeight+'px'" :class="{'questTab': active==0 && productList && productList.filter(item=>{return item.nexus == 1}).length}">
						<!-- 详情介绍 -->
						<div class="info" v-show="list[active].name=='详情介绍'" v-html="fromObj.h5Detail"></div>
						<!-- 书籍信息 -->
						<!-- <div style="height:54px" v-if="isMenuShow"></div> -->
						<div class="catalogue relatedIntroduction" v-if="list[active].name=='书籍信息' && fromObj.book">
							<div class="item" v-if="fromObj.book.bookName">
								<div class="left">
									<span>书</span>
									<span>名</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.bookName}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.isbnCode">
								<div class="left">
									<span>I</span>
									<span>S</span>
									<span>B</span>
									<span>N</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.isbnCode}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.bookCname">
								<div class="left">
									<span>从</span>
									<span>书</span>
									<span>名</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.bookCname}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.edition">
								<div class="left">
									<span>版</span>
									<span>次</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.edition}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.press">
								<div class="left">
									<span>出</span>
									<span>版</span>
									<span>社</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.press}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.author">
								<div class="left">
									<span>作</span>
									<span>者</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.author}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.publicationTime">
								<div class="left">
									<span>出</span>
									<span>版</span>
									<span>时</span>
									<span>间</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.publicationTime}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.authorRegion">
								<div class="left">
									<span>作</span>
									<span>者</span>
									<span>地</span>
									<span>区</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.authorRegion}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.bookSize">
								<div class="left">
									<span>开</span>
									<span>本</span>
									<span>/</span>
									<span>尺</span>
									<span>寸</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.bookSize}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.cipCategory">
								<div class="left">
									<span>C</span>
									<span>I</span>
									<span>P</span>
									<span>分</span>
									<span>类</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.cipCategory}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.editor">
								<div class="left">
									<span>编</span>
									<span>者</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.editor}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.keyword">
								<div class="left">
									<span>关</span>
									<span>键</span>
									<span>字</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.keyword}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.numberPage">
								<div class="left">
									<span>页</span>
									<span>数</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.numberPage}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.numberWord">
								<div class="left">
									<span>字</span>
									<span>数</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.numberWord}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.packageCount">
								<div class="left">
									<span>包</span>
									<span>册</span>
									<span>数</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.packageCount}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.price">
								<div class="left">
									<span>定</span>
									<span>价</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.price}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.reader">
								<div class="left">
									<span>读</span>
									<span>者</span>
									<span>对</span>
									<span>象</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.reader}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.stamper">
								<div class="left">
									<span>印</span>
									<span>张</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.stamper}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.suit !== null">
								<div class="left">
									<span>是</span>
									<span>否</span>
									<span>套</span>
									<span>装</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.suit === 0 ? '否':fromObj.book.suit == 1?'是':''}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.texture">
								<div class="left">
									<span>内</span>
									<span>文</span>
									<span>用</span>
									<span>纸</span>
									<span>材</span>
									<span>质</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.texture}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.trademark">
								<div class="left">
									<span>品</span>
									<span>牌</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.trademark}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.translator">
								<div class="left">
									<span>译</span>
									<span>者</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.translator}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.wrap">
								<div class="left">
									<span>包</span>
									<span>装</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.wrap}}</div>
								</div>
							</div>
							<div class="item" v-if="fromObj.book.recommend">
								<div class="left">
									<span>编</span>
									<span>辑</span>
									<span>推</span>
									<span>荐</span>
								</div>
								<div class="right">
									<div class="span">{{fromObj.book.recommend}}</div>
								</div>
							</div>
						</div>
						<!-- 评价 -->
						<div class="evaluate" v-show="list[active].name=='评价'">
							<div class="ev_tab">
								<van-tabs 
									v-model="evActive" 
									class="van-tabs-circle" 
									swipe-threshold="2"
									@change="onEvaChange"
									sticky>
									<van-tab 
										v-for="(item, index) in evaluateList" 
										:key="index" 
										:title="item"></van-tab>
								</van-tabs>
								<div class="ev_list" v-for="(item, index) in evaluationList" :key="index">
									<div class="list_top">
										<img class="top_photo" v-if="item.userIcon" :src="item.userIcon" alt="">
										<img v-else class="top_photo" src="@/assets/image/my/default_photo.png" />
										<span class="top_phone">{{item.nickName}}</span>
										<img class="top_flower" v-for="i in item.evaluation" :key="i + '-' + item.evaluationId" src="@/assets/image/icon/good_icon.png" alt="好评">
										<!-- <img class="top_flower" v-for="j in (5 - item.evaluation)" :key="j + '+' + item.evaluationId" src="@/assets/image/icon/good1_icon.png" alt="好评"> -->
									</div>
									<div class="list_con">
										<div class="con_main">{{item.comment?item.comment:'此用户没有填写评价'}}</div>
										<div class="con_data">{{timeFormatSeconds(item.createTime.split("-").join('/'))}}</div>
									</div>
									<div class="list_reply" v-show="item.isReply">
										<span class="reply_main">小编回复：</span>
										<span>感谢亲的支持感谢亲的支持感谢亲的支持。</span>
									</div>
								</div>
								<noMessage v-if="!evaluationList.length" type=1></noMessage>
							</div>
						</div>
					</div>
				</div>
				<div class="car-list" v-if="active==0 && productList && productList.filter(item=>{return item.nexus == 1}).length">
					<p class="title">商品推荐</p>
					<ShopList class="productList" :shopList="productList.filter(item=>{return item.nexus == 1})" @getShopCarList="getShopCarList" @addCart="addCart"/>
					<div class="ball-container">
						<!--小球-->
						<transition name="drop" @before-enter="beforeDrop" @enter="dropping" @after-enter="afterDrop">
							<div class="ball" v-show="ball.show">
								<div class="inner inner-hook">1</div>
							</div>
						</transition>
					</div>
					<!-- <div class="car-item" v-for="(item,index) in productList.filter(item=>{return item.nexus == 1})" :key="index">
						<van-image
							class="car-img"
							fit="cover"
							:src="item.listPhoto"
						/>
						<div class="car-right">
							<div class="car-title" >
								{{item.name}}
							</div>
							<div class="car-foo">
								<div class="price">
									￥<span class="present">{{item.couponPrice.toFixed(2)}}</span>
									<span class="original" v-if="item.original">￥{{item.originalPrice.toFixed(2)}}</span>
								</div>
								<van-stepper v-model="item.num" :max="item.type == 1 || item.type == 4?1:100" default-value="0" theme="round" :min="0" button-size="22" disable-input />
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="footBtn" v-if="!fromObj.isGift">
				<div class="btn_info" v-show="freightObj.code == 2">
					<!-- <span class="info_con">所选商品库存不足，请联系客服确认。</span> -->
					<span class="info_con">此商品不支持在当前地区销售</span>
					<span class="info_btn" @click="onSelectAddress">更改地址</span>
				</div>
				<div class="btn_box">
          <div class="collect" @click="CallCustomer()">
						<img class="collectImg" src="@/assets/image/selected/2022062610411.png" />
						<span>客服</span>
					</div>
					<div class="freediving" @click="openShopCar">
						<img class="freedivingImg" src="@/assets/image/selected/2022062610412.png" />
						<span>购物车</span>
						<span class="num" v-if="shopCarList.length">{{getNum(shopCarList)}}</span>
					</div>
					<div class="btnGroup">
						<div class="shoppingCart" @click="joinShopCar">
							<span class="buyText" >加入购物车</span>
						</div>
						<div class="purchase">
							<span class="buyText" @click="buyNow()">立即购买</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 优惠券弹窗 -->
			<n-transition ref="trainPop1" speed="ease-in-out" :height="415" :maskVal="0.35">
				<div class="windowCoupons">
					<div class="navBar">
						<div class="center">领取优惠券</div>
						<div class="right" @click="close1">
							<van-icon name="cross" color="#666" size="20" />
						</div>
					</div>
					<div class="coupon">
						<div class="couponItem" v-for="(item,index) in couponList" :key="index">
							<div class="left">
								<template v-if="item.type == 1">
									<span class="price"><span>¥</span>{{item.couponPrice}}</span>
									<span class="condition">满{{item.fullPrice}}元可用</span>
								</template>
								<template v-if="item.type == 3">
									<span class="price">{{item.discount}}<span>折</span></span>
									<span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
								</template>
								<span class="rightTop"></span>
								<span class="rightBottom"></span>
								<img v-show="false" src="@/assets/image/my/overdue.png" />
							</div>
							<div class="right">
								<span class="title">{{item.name}}</span>
								<span class="time" v-if="item.times == 2">{{item.startTime.split('-').join('.')}}-{{item.endTime.split('-').join('.')}}</span>
                <span class="time" v-if="item.times == 1">不限使用时间</span>
								<span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':'指定商品'}}]</span>
								<span class="btn" @click="couponReceive(item)">{{!!item.noReceive?'可用商品':'点击领取'}}</span>
								<img v-show="!!item.noReceive" src="@/assets/image/my/Received.png" />
							</div>
						</div>
					</div>
				</div>
			</n-transition>
			
			<ShpCarModel ref="shpCarModel" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarFlag="shopCarFlag" :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
		</div>
		<van-dialog @confirm="confirm" v-model="show" show-cancel-button cancel-button-color="#666666" confirm-button-color="#15B7DD" confirm-button-text="去认证">
			<template slot="title">
				<img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
				<span>提示信息</span>
			</template>
			<span class="nocertified">购买本商品需要完成身份认证</span>
		</van-dialog>
		<a class="gotopBox"  v-if="showGoTop <= 0" @click="goTop()">
        	<van-icon name="arrow-up" color="#fff" size="20px" />
      	</a>
	</div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
import noMessage from "@/components/nomessage.vue";
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
import ShopList from '@/components/shopMall/shopList.vue';
import Skeleton from '@/components/Skeleton/index.vue';
import {getAppVersion, FunSendShare, CallCustomer, refreshCollection} from '@/assets/js/NativeWebKit.js';
import SwichTab from "@/components/SwichTab.vue";
import config from '@/unit/config.js'
export default {
	// 可用组件的哈希表
	components: {ShopList,noMessage,ShpCarModel,nTransition,Skeleton,SwichTab},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			config,
			AppVersion: getAppVersion(),
			count: 1,
			showGoTop: 0,
			stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
			navHeight: 0, //导航栏高度
			loading:false,
			minHeight: 250,
			onceRecord: true, //是否第一次记录
			ball: {
        show: false,
        el:null
      },
			shopCarFlag:true, // 购物车弹框状态
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
			shopList:[],
			
			couponList:[],
			fromObj: {
				couponPrice:0,
				originalPrice:0,
			},
			evActive:0,
			productId: '', // 商品id
			FirstCollect: 0, // 首次收藏状态
			collected:require('@/assets/image/selected/collected.svg'),
      collect:require('@/assets/image/selected/collect.svg'),
			evaluateList: [],  // 评价头部中好评菜单
			evaluationList: [], // 评价记录
			bookType: ['课程', '图书', '教具', '模拟考试', '模拟考试', '延期', '补考', '直播', '其他'], // 商品类型

			classIndex: 0,
      active: 0,
      list: [
        {
          title: '图书教材'
        }, {
          title: '教学用具'
        }
      ],
			harvestAddress: this.$store.state.userInfo.harvestAddress,   // 收获地址
			freightObj: {
				code: 1
			},  // 运费
			activeName: '',
			indicatorDots: true,
			autoplay: false,
			interval: 2000,
			duration: 500,
			current: 0,
			swiperCurrent: 0,
			swiperList:4,
			list: [
				{title:''}
			],
			productList:null,
			appraiseForm:{  // 评价
				productId: this.$route.query.id,
				evaluation: '',  // 1.差评，2.中评，3.好评
				pageNum: 1,
				pageSize: 100000,
			},
			appraiseData: [],
			show:false,
			isMenuShow:false,
		}
	},
	// 事件处理器
	methods: {
		// 分享
		shareFile() {
			if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
				if(this.AppVersion.code > 77){
					let imageArr = this.fromObj.photoList.filter(item=>{return item.type == 1})
					let imageUrl = imageArr[0]?.photo;
					let json = {
						WebUrl: this.config.shareUrl + '/curriculum/shareBookDdetails?id='+this.productId+"&share=true",
						title: this.fromObj.name,
						description: this.fromObj.abstracts,
						imageUrl: imageUrl
					}
					FunSendShare(JSON.stringify(json));
				}else{
					this.$Toast('请更新app版本')
				}
			}else{
				if(this.AppVersion.code > 107){
					let imageArr = this.fromObj.photoList.filter(item=>{return item.type == 1})
					let imageUrl = imageArr[0];
					let json = {
						WebUrl: this.config.shareUrl + '/curriculum/shareBookDdetails?id='+this.productId+"&share=true",
						title: this.fromObj.name,
						description: this.fromObj.abstracts,
						imageUrl: imageUrl
					}
					FunSendShare(JSON.stringify(json));
				}else{
					this.$Toast('请更新app版本')
				}
			}
		},
		changeTab(e){
			this.active = e
			const hei = document.getElementsByClassName('tabsContent')[0].offsetHeight
			if(hei < window.innerHeight){
				this.minHeight = hei
			}else{
				this.minHeight = parseInt(window.innerHeight ) - 54 - this.navHeight
			}
			if(this.topRecord){
				document.getElementsByClassName('myCourse')[0].scrollTop = this.topRecord
			}
		},
		goTop() {
        	document.getElementsByClassName('myCourse')[0].scrollTop = 0
      	},
		// 页面滚动监听
    	FunHandleScroll() {
    	  // 信息条渐变值
    	  let pageScrollTop = document.getElementsByClassName('myCourse')[0].scrollTop
    	  this.stateOpacity = pageScrollTop/100;
				let pageOffsetHeight = document.getElementsByClassName("myCourse")[0].offsetHeight;
				this.showGoTop = pageOffsetHeight - pageScrollTop;
				this.showGoTop = pageOffsetHeight - pageScrollTop;

				// 导航栏添加样式
				document.getElementsByClassName('van-nav-bar')[0].style.opacity = this.stateOpacity

				let menuTop = 0
				// 监听切换菜单是否到顶
				menuTop = this.$refs.menu ? this.$refs.menu.getBoundingClientRect().top : 300
				if(menuTop <= this.navHeight){
					this.isMenuShow = true
					if(this.onceRecord){
						this.topRecord = document.getElementsByClassName('myCourse')[0].scrollTop
					}
					this.onceRecord = false
				} else {
					this.topRecord = undefined
					this.onceRecord = true
					this.isMenuShow = false
				}
    	},
		addCart(event,item){
      		this.drop(event.target)
    	},
    drop(el) {
      //抛物
      let ball = this.ball;
      if (!ball.show) {
        //当小球显示状态为隐藏时
        ball.show = true; //将这个小球的显示状态设置为true
        ball.el = el; // 将cartControl传过来的对象挂载到ball的el属性上
        // this.dropBalls.push(ball); // 将这个小球放入到dropBalls数组中
        return;
      }
    },
    beforeDrop(el) {
      /* 购物车小球动画实现 */
      // let rednum = this.balls.length;
      // while (rednum--) {
        let ball = this.ball;
        if (ball.show) {
          let rect = ball.el.getBoundingClientRect(); //元素相对于视口的位置
          let x = rect.left - 32; //x初始值  // 小球x方向位移= 小球距离屏幕左侧的距离-外层盒子距离水平的距离
          let y = -(window.innerHeight - rect.top - 22); //获取y
          el.style.display = "";
          el.style.webkitTransform = "translateY(" + y + "px)"; //translateY  // 设置外层盒子，即小球垂直方向的位移
          el.style.transform = "translateY(" + y + "px)";
          let inner = el.getElementsByClassName("inner-hook")[0];
          inner.style.webkitTransform = "translateX(" + x + "px)"; // 设置内层盒子，即小球水平方向的距离
          inner.style.transform = "translateX(" + x + "px)";
        }
      // }
    },
    dropping(el, done) {
      /*重置小球数量 样式重置*/
      el.offsetHeight;
      el.style.webkitTransform = "translate3d(50px,0,0)"; // 设置小球掉落后最终的位置
      el.style.transform = "translate3d(50px,0,0)";
      let inner = el.getElementsByClassName("inner-hook")[0];
      inner.style.webkitTransform = "translate3d(0,0,0)";
      inner.style.transform = "translate3d(0,0,0)";
      el.addEventListener("transitionend", done); // Vue为了知道过渡的完成，必须设置相应的事件监听器。它可以是transitionend或 animationend
    },
    afterDrop(el) {
      /*初始化小球*/
      // let ball = this.dropBalls.shift(); // 完成一次动画就删除一个dropBalls的小球
      // if (ball) {
        this.ball.show = false;
        el.style.display = "none"; //完成一次之后就让小球隐藏
      // }
    },
    // 跳转详情页面
    goDetail(e) {
      // 教育课程
      if (e.type == 1) {
        this.$router.push('/curriculum/course?id=' + e.courseId);
      }
      // // 图书教具
      else if (e.type == 2 || e.type == 3) {
        this.$router.push('/curriculum/BookDdetails?id=' + e.productId)
      }
      // // 模拟考试
      else if (e.type == 4) {
        this.$router.push('/examination/examDetail?id=' + e.configId)
      }
    },
		getNum(e){
			let num = 0;
			e.map(item=>{
				num += item.count;
			})
			if (num > 99) {
				num = '99+'
			}
			return num;
		},
		// 获取用户默认地址
		getDefaultAddress(){
      this.$ajax({
        url:'/hxclass-mobile/ship/address/getdefault',
        method:'get',
        params:{
          userId:this.$store.state.userInfo.userId
        }
      }).then(res=>{
        this.visible = true;
        if(res.code == 200 && res.success && res.data){
					const obj = this.$store.state.userInfo;
					// 如果信息表存着地址用信息的地址
					if(obj.harvestAddress){
						this.harvestAddress = obj.harvestAddress
					} else {
						// 否则用查出的默认地址
						this.harvestAddress = res.data;
						obj.harvestAddress = res.data;
						this.$store.commit("updateUserInfo", obj);
					}
        }else{
          // this.getOrderMessage();
        }
      })
		},
    // 唤起微信客服
		CallCustomer,
		// 加入购物车
		joinShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			// if(!this.$store.state.userInfo.certified){
			// 	return this.show = true;
			// }
			this.$ajax({
				url:'/hxclass-mobile/app-mall/shop/save',
				method:'post',
				params:{
					count:this.count, // 数量
					couponCode:'', // 优惠劵编码
					openTimeId:'', // 开班设置id
					price:this.fromObj.couponPrice, // 价格  优惠价格-还没有计算优惠券
					productId:this.fromObj.productId, // 商品id
					type:this.fromObj.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
					userNo:this.$store.state.userInfo.userId, // 用户
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.getShopCarList();
					return this.$Toast('添加购物车成功');
				}else{
					this.$Toast(res.message)
				}
      })
      let record = [];
			if(this.productList && this.productList.length){
				this.productList.map(item=>{
					if(item.num>0){
						record.push({
							count:item.num, // 数量
							couponCode:'', // 优惠劵编码
							openTimeId:'', // 开班设置id
							price:item.couponPrice, // 价格  优惠价格-还没有计算优惠券
							productId:item.productId, // 商品id
							type:item.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
							userNo:this.$store.state.userInfo.userId, // 用户
						})
					}
				})
      }
      if(record.length){
        this.$ajax({
          url:'/hxclass-mobile/app-mall/app/batch/save',
          method:'post',
          params: record
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.getShopCarList();
          }else{
            this.$Toast(res.message)
          }
        })
      }
      // console.log('Hai Yang Msg:',this.productList)
      // console.log('Hai Yang Msg:',record)
		},
		// 无认证信息弹框
		confirm(){
			this.show = false;
			this.$router.push('/mine/identityAuthentication')
		},
		// 立即购买
		buyNow(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			// if(!this.$store.state.userInfo.certified){
			// 	return this.show = true;
			// }
			if(this.freightObj.code == 2){
				this.$Toast('此商品不支持在当前地区销售');
				return
			}
			if(!this.harvestAddress){
				return this.$Toast('请选择收货地址')
			}
			let list = [];
			list.push({
				productId:this.fromObj.productId, // 商品id
				count:this.count, // 数量")
				openTimeId:'', // 班级id
				type:this.fromObj.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:[]
			})
			// 相关商品
			this.productList?.map(item=>{
				if(item.nexus == 2){
					list[0].productList.push({
						productId:item.productId, // 商品id
						count:item.num, // 数量")
						openTimeId:'', // 班级id
						type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
						province:'', // 省
						city:'',// 市
						noGift:item.nexus-1, // 是否为赠品 0否1是t
					})
				}

			})
      this.$store.commit('updateCouponList',null);
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
			this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateInvoiceId',null);
			this.$store.commit('updateAddress',this.harvestAddress.id);
			this.$router.push('/order/confirmOrder')
		},
		// 领券
		popUp1 () {
			this.$refs['trainPop1'].show()
		},
		close1(){
			this.$refs['trainPop1'].hide()
		},
		// 打开购物车
		openShopCar(){
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
			if(this.shopCarList.length || this.offShelfItems.length){
        this.shopCarFlag = !this.shopCarFlag;
      }else{
        this.$Toast('请添加商品');
      }
		},
		SwiperTab(e){
			this.current =Number(e.target.current)
		},
		selected: function(gameName) {
			this.activeName = gameName
		},
		// 收藏/取消
    onCollection(noCollection){
			// 收藏列表是否刷新
			this.isCollectionLoad()
			if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
			}
      this.fromObj.noCollection = this.fromObj.noCollection ? 0 : 1
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if(this.FirstCollect != this.fromObj.noCollection){
          this.$ajax({
            url:"/hxclass-mobile/collection/app?key=" + this.fromObj.noCollection + '&objectId=' + this.productId +'&type=2' ,
            method: 'POST',
          }).then(res=>{
            if(res.code == 200 && res.success){
							refreshCollection();
              this.FirstCollect = this.fromObj.noCollection
            } else {
              this.fromObj.noCollection = this.fromObj.noCollection ? 0 : 1
              this.FirstCollect = this.fromObj.noCollection
            }
          })
        }
      }, 1000);
		},
		// 收藏列表是否刷新
		isCollectionLoad() {
			let collectionObj = {}
			let isLoad = true
			if (sessionStorage.getItem('collectionObj')) {
				collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
			}
			collectionObj.isLoad = isLoad
			sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
		},
		// 查询评价数量
		getAppraiseCount(){
      this.$ajax({
        url: "/hxclass-mobile/order-comment/count",
        params: {
					productId: this.$route.query.id,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluateList = [
						'全部(' + res.data.all + ')',
						'好评(' + res.data.good + ')',
						'中评(' + res.data.medium + ')',
						'差评(' + res.data.bad + ')',
					]
        }
      });
		},
		timeFormatSeconds(time){
			//传入日期//例：2020-10-27T14:36:23
			var d = time ? new Date(time) : new Date();
			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			var day = d.getDate();
			var hours = d.getHours();
			var min = d.getMinutes();
			var seconds = d.getSeconds();
	
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (hours < 0) hours = '0' + hours;
			if (min < 10) min = '0' + min;
			if (seconds < 10) seconds = '0' + seconds;
	
			return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
		},
		// 查询评价列表
		getAppraiseList(){
      this.$ajax({
        url: "/hxclass-mobile/order-comment/",
        params: this.appraiseForm
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.evaluationList = res.data.records
        }
      });
		},
		// 评价头部菜单切换
		onEvaChange(e){
			if (e==1) {
				this.appraiseForm.evaluation = 3
			} else if (e==2) {
				this.appraiseForm.evaluation = 2
			} else if (e==3) {
				this.appraiseForm.evaluation = 1
			} else if (e==0) {
				this.appraiseForm.evaluation = ''
			}
			this.getAppraiseList()
		},
		// 查询详情
		getDetail(){
			this.loading = true
      this.$ajax({
        url: "/hxclass-mobile/app-mall/app/mall/product-detail",
        params: {
          productId: this.productId
        },
      }).then((res) => {
				this.loading = false
        if (res.code == 200 && res.success) {
					this.fromObj = res.data
					this.fromObj.photoList = res.data.photoList ? res.data.photoList : []
					this.FirstCollect = res.data.collect  // 记录第一次收藏状态
					this.productList = res.data.productList ? res.data.productList : []; // 储存相关商品
					// 如果产品时图书类型，则显示书籍信息
					if(this.fromObj.isBook && (this.fromObj.book.author || this.fromObj.book.authorRegion || this.fromObj.book.bookCname || this.fromObj.book.bookName || this.fromObj.book.bookSize || this.fromObj.book.cipCategory || this.fromObj.book.edition || this.fromObj.book.editor || this.fromObj.book.isbnCode || this.fromObj.book.keyword || this.fromObj.book.numberPage || this.fromObj.book.numberWord || this.fromObj.book.packageCount || this.fromObj.book.press || this.fromObj.book.price || this.fromObj.book.publicationTime || this.fromObj.book.reader || this.fromObj.book.recommend || this.fromObj.book.stamper || this.fromObj.book.suit || this.fromObj.book.texture || this.fromObj.book.trademark || this.fromObj.book.translator || this.fromObj.book.wrap)){
						// this.list = [ { name: '详情介绍' }, { name: '书籍信息' }, { name: '评价'} ]
						this.list = [ { name: '详情介绍' }, { name: '书籍信息' }]
					} else {
						// this.list = [ { name: '详情介绍' }, { name: '评价'} ]
						this.list = [ { name: '详情介绍' } ]
					}

					if(this.harvestAddress){ // 计算运费
						this.sumFreightCharge()
					}
        }
      });
		},
		// 选择收获地址
		onSelectAddress(){
			this.$router.push({path:'/address/addressList?isSelect=true'});
		},
		// 计算运费
		sumFreightCharge(){
			this.$ajax({
        url: "/hxclass-mobile/app-mall/app/product-freight",
        params: {
          province: this.harvestAddress.provice,
          city: this.harvestAddress.city,
          count: 1,
          weight: this.fromObj.weight,
          freightId: this.fromObj.freightId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
					this.freightObj = res.data
        }
      });
		},
		// 判断是否包邮
		funFreight(e){
			//   1 包邮  2 不配送 3 需要运费
			if(e.code == 1){
				return ''
			} else if(e.code == 2){
				return '9999.99'
			} else if(e.code == 3){
				return e.price.toFixed(2)
			}
		},
		// 立即购买
		onBuy(){
			if(this.freightObj.code == 2){
				this.$Toast('此商品不支持在当前地区销售');
				return
			}
		},
		// 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
		},
		// 获取优惠卷
    getCoupons(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type: 1,
          page: 1,
          size: 100,
          productIds: this.productId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.couponList = res.data.records;
        }
      })
		},
		selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },
    // 领取优惠券/查看可用商品
    couponReceive(e){
      if(!e.noReceive){
				let _this = this;
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/coupon-receive',
          method:'post',
          params:{
            couponId:e.couponId,
            receiver:this.$store.state.userInfo.userId,
            receiverTime:this.selectTime(),
            state:1
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            e.noReceive = 1;
						_this.$refs.shpCarModel.pageNum = 1;
						_this.$refs.shpCarModel.getCoupons();
          }
        })
      }else{
				if(e.scope == 1){
					this.$router.push('/shop/shoppingMall');
          this.close1();
				}else{
					this.$router.push('/mine/couponGoods?couponId='+e.couponId);
          this.close1();
				}
      }
    },
	},
	// 生命周期-实例创建完成后调用
	created () {
		this.productId = this.$route.query.id
		this.getDetail()
		if(this.$store.state.userInfo.userId){
			this.getShopCarList();
			this.getCoupons()
			// 获取用户默认地址
			this.getDefaultAddress()
		}
		// 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
	 },
	// 生命周期-实例挂载后调用
	mounted () {
		setTimeout(()=>{
			this.getAppraiseList()
			this.getAppraiseCount()
		},1000)
		this.$nextTick(() => {
			// 导航栏高度
			this.navHeight = document.getElementsByClassName('van-nav-bar')[0].offsetHeight
      this.FunHandleScroll();
      document.getElementsByClassName('myCourse')[0].addEventListener("scroll", this.FunHandleScroll);
    })
	},
	// 生命周期-实例销毁离开后调用
	destroyed () { 
		if (document.getElementsByClassName('myCourse')[0]) {
      document.getElementsByClassName('myCourse')[0].removeEventListener("scroll", this.FunHandleScroll);
    }
	},
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {
		$route(newVal,oldVal){
			this.productId = this.$route.query.id
			this.getDetail();
		}
	}
}
</script>

<style lang="less" scoped>
  .gotopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .box_con {
      font-size: 14px;
      color: #fff;
      margin-top: px;
    }
  }
  .tab{
		height: 54px;
		line-height: 54px;
  }
	// tab
	// /deep/.van-tabs__nav {
	// 	background-color: transparent;
	// 	.van-tab--active {
	// 		font-weight: 600;
	// 		background-image: url(~@/assets/image/examination/certificate_icon1.png);
	// 		background-repeat: no-repeat;
	// 		background-size: 40px;
	// 		background-position: center 26px;
	// 	}
	// 	.van-tab {
	// 		font-size: 16px;
	// 	}
	// 	.van-tabs__line {
	// 		display: none;
	// 	}
	// }
	.ball {
    position: fixed;
    // 小球落下来的位置
    left: 32px;
    bottom: 22px;
    // margin-top: 32px;
    // margin-left: 22px;
    z-index: 200;
    transition: all 1s cubic-bezier(0.49, -0.29, 0.75, 0.41); /*贝塞尔曲线*/
  }
  .inner {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #EC6C01;
    transition: all 1s linear;
    text-align: center;
    color: #FFFFFF;
  }
  .cart {
    position: fixed;
    bottom: 22px;
    left: 32px;
    width: 30px;
    height: 30px;
    background-color: #EC6C01;
    color: rgb(255, 255, 255);
  }
	.productList{
		padding: 14px 18px 1px;
		background: #fff;
	}
	/deep/.van-dialog__header{
		padding: 16px 20px;
		line-height: 1;
		text-align: left;
		display: flex;
		align-items: center;
		img{
			width: 18px;
			height: 18px;
			margin-right: 5px;
		}
		span{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			line-height: 27px;
		}
	}
	/deep/.van-dialog__footer{
		margin-top: 20px;
		/deep/.van-button--default{
			width: 122px !important;
			background-color: #EEEEEE !important;
		}
	}
	.nocertified{
		font-size: 16px;
		color: #333333;
		line-height: 24px;
		display: block;
		padding-left: 20px;
	}
	.myCourse{
		// 信息条
		.box_status {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background-color: #fff;
			opacity: 0;
			z-index: 100;
		}
		.courseHeader{
			// height: 265px;
			// border-radius: 0 0 17px 17px;
			background: #fff;
			// overflow: hidden;
			position: relative;
			.btnGroup{
				position: absolute;
				right: 18px;
				// top: 0;
				display: flex;
				bottom: -34px;
				align-items: center;
				z-index: 199;
				.collectBox{
					text-align: center;
					min-width: 40px;
					.collect{
						width: 32px;
						height: 32px;
					}
					.collectText{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						// line-height: 17px;
					}
				}
				.tryBox{
					text-align: center;
					margin-right: 20px;
					.tryImg{
						width: 32px;
						height: 32px;
					}
					.tryText{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
					}
				}

			}
			.back{
				position: fixed;
				// left: 16px;
				// top: 50px;
				z-index: 999;
				width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
        }
			}
			.share{
				position: absolute;
				right: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
			}
			.swiper{
				// height: 265px;
				.swiperImg{
					width: 100%;
					// height: 265px;
					// border-radius: 0 0 17px 17px;
				}
				/deep/ .uni-swiper-dot-active {
					width: 16px !important;
					height: 4px !important;
				    background-color: #fff !important;
					border-radius: 2px;
				}
				/deep/ .uni-swiper-dot {
				    display: inline-block;
				    width: 4px;
				    height: 4px;
				    cursor: pointer;
				    background: rgba(255, 255, 255, 0.6);
				    // border-radius: 0%;
				}
			}
			/* 指示器样式 */
			.u-indicator-item-number {
				width: 20px;
				padding: 7.5px;
				line-height: 1;
				background-color: rgba(0, 0, 0, 0.35);
				border-radius: 50px;
				font-size: 11px;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				right: 20px;
				bottom: 15px;
				text-align: center;
				letter-spacing: 1.5px;
			}
		}
		.courseExplain{
      margin-bottom: 8px;
			padding: 18px;
			// height: 116.5px;
			background: #fff;
			// margin-top: 8px;
			.explainUp{
				display: flex;
				margin-top: 10px;
				.left{
					flex: 1;
					line-height: 19px;
					align-items: center;
					display: flex;
					.label{
						width: 42.5px;
						height: 19px;
						padding: 2px 6px;
						display: inline-block;
						background: url(~@/assets/image/selected/tag.svg) no-repeat;
						background-size:100% 100%;
						font-size: 10px;
						font-weight: 400;
						color: #F0A300;
						text-align: center;
						margin-right: 9px;
						align-items: center;
					}
					.price{
						font-size: 20px;
						font-weight: 400;
						color: #FF7A00;
    				font-family: PingFang SC-Regular, PingFang SC;
						line-height: 13px;
					}
					.originalPrice{
						font-size: 10px;
						font-weight: 400;
						color: #999999;
						// font-family: PingFangMedium;
						font-family: PingFangMedium;
						margin-left: 10px;
						margin-top: 7px;
					}
				}
				.right{
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}
			.explainCenter{
				font-size: 18px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #333333;
				line-height: 27px;
				margin-top: 6px;
			}
			.explainDown{
				.courseType{
					// display: flex;
				  font-family: PingFangMedium;
					width: 100%;
					margin: 4px 0 8px 0;
					span {
						display: block;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						// display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.left{
						flex: 1;
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						line-height: 20px;
					}
					.right{
						margin-top: 12px;
						font-size: 14px;
						font-weight: 500;
						color: #666666;
						line-height: 16px;
					}

				}
				.fload{
					display: -webkit-box;
					word-break: break-all;
					-webkit-box-orient: vertical;
					/* 要显示多少行就改变line-clamp的数据,此处折叠起来显示一行*/
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
          line-height: 15px;
          .icon{
            display: inline-block;
            width: 80px;
            height: 15px;
            font-size: 11px;
            position: relative;
						top: 2px;
						img{
							width: 100%;
							height: 100%;
						}
          }
          // >span{
          //   display: inline-block;
          //   height: 15px;
          //   width: 80px;
          //   background: #FF7800;
          //   line-height: 15px;
          //   padding-left: 5px;
          //   font-size: 10px;
          //   color: #ffffff;
          //   position: relative;
          //   >
          //   &::after{
          //     content: '';
          //     display:inline-block;
          //     position: absolute;
          //     right: 10px;
          //     top: 5px;
          //     width: 5px;
          //     height: 5px;
          //     background: #fff;
          //     border-radius: 50%;
          //   }
          // }
				}
			}
		}
		.couponBox{
			padding: 18px;
			background: #fff;
			margin-bottom: 8px;
			font-family: PingFangMedium;
			.item{
				display: flex;
				.left{
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					line-height: 20px;
					flex: 1;
				}
				.right{
					flex: 5;
					// margin-right: 12px;
					display: flex;
					align-items: center;
					.rightBox{
						display: flex;
						justify-content: space-between;
						flex: 1;
						padding-right: 12px;
						.couponList{
							height: 20px;
							overflow: hidden;
							flex: 1;
							display: flex;
							flex-wrap: wrap;
						}
						.tag{
							width: 28px;
							height: 16px;
							background: rgba(#F93C00, 0.1);
							border-radius: 2px;
							font-size: 10px;
							font-weight: 500;
							color: #F93C00;
							line-height: 16px;
							text-align: center;
							padding: 1px 4px;
						}
						.downitem{
							margin: 1px 10px 5px;
							font-size: 10px;
							font-weight: 400;
							color: #F93C00;
							line-height: 18px;
							height: 18px;
							text-align: center;
							position: relative;
							padding: 0 5px;
							background-image: url(~@/assets/image/selected/20220610-145020.png);
							background-position: center;
							background-size: 1px 18px;
							&::before,&::after{
								content: '';
								width: 4px;
								display: block;
								position: absolute;
								top: 0;
								left: -4px;
    						height: 100%;
								background-image: url(~@/assets/image/selected/left.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 4px 18px;
								transform: rotate(180deg);
							}
							&::after{
								content: '';
								left: auto;
								right: -4px;
								transform: rotate(0deg);
							}
						}
					}
				}
			}
		}
		.windowCoupons{
			background-color: #fff;
			border-radius: 24px 24px 0 0;
			padding: 0 5px;
			.navBar{
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 18px 0;
				.center{
					font-size: 18px;
					line-height: 25px;
					color: #333;
				}
				.right{
					position: absolute;
					right: 11px;
				}
			}
		}
		.coupon{
			padding: 0 13px;
			padding-bottom: 25px;
			overflow-y: scroll;
			height: 320px;
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			border-radius: 6px;
			// border: 1px solid #F93C00;
			display: flex;
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
				flex-direction: column;
				text-align: center;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
        width: 90px;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-bottom: 1px solid #EC6C01;
				// 	// border-top: 1px solid #F7F7F9;
				// 	border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -2px;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #fff;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-top: 1px solid #EC6C01;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	bottom: -2px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #F7F7F9;
				// }
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 9;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
		.combination{
			padding: 18px;
			padding-bottom: 0;
			background: #fff;
			margin-top: 8px;
			.combinationTitle{
				span{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					font-weight: 500;
				}
			}
			.combinationBox{
				margin-top: 22px;
				.item{
					display: flex;
					padding-bottom: 16px;
					.courseImg{
						width: 100px;
						height: 70px;
					}
					.right{
						padding: 2.5px 0 0 11px;
						.title{
							display: flex;
							font-size: 14.5px;
							font-weight: 400;
							color: #333333;
							line-height: 25.5px;
						}
						.price{
							display: flex;
							font-size: 10px;
							font-weight: 400;
							color: #999999;
							line-height: 14.5px;
							margin-top: 6px;
						}
						.identification{
							font-size: 11px;
							font-weight: 400;
							color: #1E97B4;
							line-height: 15.5px;
						}
					}
				}
			}

		}
		.relatedIntroduction{
			padding: 12px 19px;
			background: #fff;
			margin-bottom: 8px;
			.item{
				display: flex;
				width: 100%;
				font-family: PingFangMedium;
				padding-bottom: 16px;
				border-bottom: 1px solid #F5F5F5;
				// align-items: center;
				.left{
					font-size: 13px;
					font-weight: 400;
					color: #8B8B8D;
					line-height: 20px;
					text-align: left;
					width: 54px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					flex-flow: wrap;
				}
				.right{
					flex: 1;
					margin-left: 20px;
					font-size: 13px;
					font-weight: 400;
					color: #333333;
					line-height: 20px;
					.span{
						flex: 1;
						font-size: 13px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
					}
					.copy{
						font-size: 13px;
						font-weight: 400;
						color: #15B7DD;
						line-height: 22px;
					}
					.shoplist{
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex: 1;
					}
					.style{
						padding: 16px 0;
						border-bottom: 1px solid #F5F5F5;
					}
				}
			}
			.item:not(:first-child) {
				padding-top: 16px;
			}
			.item:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
		.details{
			// padding: 18px;
			background: #fff;
			margin-top: 8px;
			.detailsTitle{
				.up{
					font-size: 18px;
					font-weight: 500;
					color: #333333;
					line-height: 28px;
					padding: 16px 0 0 0;
					text-align: center;
					image{
						width: 56px;
						height: 8px;
						vertical-align: middle;
					}
				}
				.down{
					padding: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
				}
			}
		}
		.tabsBox{
			box-sizing: border-box;
			margin-top: 8px;
			background: #fff;
			.tab {
				display: flex;
				padding: 0;
				align-items: center;
				justify-content: space-around;
			}
			/deep/.tab_item{
			    line-height: 47px; 
			}
			/deep/.pick{
				background-position: 14.5px 28.5px;
			}
			// /deep/.van-tabs__nav{
			// 	justify-content: space-around;
			// }
			// /deep/.van-tab {
			// 		margin-left: 0; 
			// 		padding: 0 16px;
			// }
			// /deep/.van-tab:first-child {
			//     margin-left: 0;
			// }
			// margin-top: 10px;
			.menuHeight{
				transition: all 0.3s;
				height: 54px;
				box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
			}
			.van-tabs {
				padding: 5.5px 0;
			}
			.tabsContent{
				padding-bottom: 55px;
				box-sizing: border-box;
				background: #fff;
				&.questTab {
					padding-bottom: 0;
				}
				.catalogue{
					padding: 0 18px;
					.catalogueList{
						padding: 10px 0;
					}
				}
				.info{
          padding: 0 18px;
          overflow: hidden;
          /deep/strong{
						font-weight: 500;
					}
					/deep/em{
						font-style: italic;
					}
					/deep/img{
						max-width: 100%;
					}
					/deep/ video{
						width: 100%;
					}
					/deep/.remarks{
						max-width: 100%;
						height: auto !important;
						-webkit-line-clamp: 10000 !important;
					}
				}
				.questList {
					.content-box {
						position: relative;
						margin: 12.5px 18px;
						height: 120px;
						background: #FFFFFF;
						border-radius: 9px 9px 9px 9px;
						overflow: hidden;
					}
					.content-boxopen {
						position: relative;
						margin: 12.5px 18px;
						background: #FFFFFF;
						border-radius: 9px 9px 9px 9px;
						overflow: hidden;
					}

					.content-first {
						position: relative;
						margin: 11px 12.5px 18px;


						.question {
							margin-bottom: 8px;
							font-size: 16.5px;
							font-family: PingFangMedium;
							font-weight: 500;
							color: #333333;
							line-height: 24.5px;
						}

						.answer {
							font-size: 13.5px;
							font-family: PingFangMedium;
							font-weight: 500;
							color: #666666;
							line-height: 20.5px;
						}

						.all-font-ellipsis {
							-webkit-line-clamp: 3;
						}

						.header-closebtn {
							position: absolute;
							right: 0px;
							bottom: 2px;
							font-size: 12.5px;
							font-family: PingFangMedium;
							font-weight: 500;
							color: #0888A6;
							line-height: 19px;
							z-index: 2;
							background-color: #fff;
						}
					}
					.content-icon {
						position: absolute;
						right: 0;
						bottom: -6px;
						width: 53px;
						height: 53px;
						z-index: 1;
					}
				}
				.evaluate {
					padding: 0 20px 20px 20px;
					.ev_list {
						border-bottom: 1px solid #F0F0F0;
						margin: 0 18px 24px 18px;
						padding-bottom: 20px;
						&:last-child {
							border: 0;
						}
						.list_top {
							display: flex;
							align-items: center;
							.top_photo {
								width: 32px;
								height: 32px;
								border-radius: 50%;
							}
							.top_phone {
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFangMedium;
								font-weight: 500;
								color: #333333;
								margin-left: 8px;
							}
							.top_flower {
								width: 16px;
								margin-left: 15px;
							}
						}
						.list_con {
							padding-left: 38px;
							.con_main {
								font-size: 15px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #666666;
								line-height: 26px;
							}
							.con_data {
								font-size: 12px;
								font-family: PingFangMedium;
								font-weight: 400;
								color: #999999;
								margin-top: 12px;
							}
						}
						.list_reply {
							font-size: 15px;
							font-family: PingFangMedium;
							font-weight: 400;
							color: #666666;
							line-height: 26px;
							margin-left: 16px;
							padding: 16px;
							background: #F5F7FA;
							border-radius: 4px;
							margin-top: 8px;
							.reply_main {
								color: #333333;
							}
						}
					}
				}
			}
		}
		.car-list{
      margin-bottom: 55px;
			.title{
				position: relative;
				line-height: 56px;
				color: #323233;
				padding: 0 20px;
				&::before{
					content: '';
					display: inline-block;
					position: relative;
					right: 5px;
					top: 2px;
					width: 4px;
					height: 15px;
					background: #15B7DD;
					border-radius: 2px;
				}
			}
      .car-item{
				background: #fff;
				padding: 14px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        .car-img{
          width: 70px;
					height: 70px;
					overflow: hidden;
          border-radius: 10px;
        }
        .car-right{
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          height: 70px;
          margin-left: 14px;
          .car-title{
            width: 80%;
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 18px;
          }
          .car-foo{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 13px;
            .price{
              margin-top: 4px;
              font-size: 12px;
              font-weight: 500;
              color: #EC6C01;
              line-height: 14px;
              .present{
                font-size: 11px;
                font-family: PingFangMedium;
              }
              .original{
                margin-left: 8px;
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                text-decoration: line-through;
              }
            }
            /deep/ .van-stepper--round .van-stepper__minus{
              width:16px !important;
              height: 16px !important;
              color: #fff;
              background-color: rgb(21, 183, 221);
              border: 1px solid rgb(21, 183, 221);
            }
            /deep/ .van-stepper--round .van-stepper__plus{
              width:16px !important;
              height: 16px !important;
              color: #fff;
              background-color: rgb(21, 183, 221);
            }
          }
        }
      }
      .car-item:last-child{
        border-bottom: none;
      }
    }
		.footBtn{
			width: 100%;
			position: fixed;
			z-index: 10;
			bottom: 0;
			overflow: hidden;
			box-shadow: 0px -1px 1px 1px rgba(153,153,153,0.3);
			z-index: 2000;
			.btn_info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 40px;
				line-height: 40px;
				padding: 0 16px;
				background: #FFF4E1;
				.info_con {
					font-size: 14px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #333333;
				}
				.info_btn {
					height: 26px;
					line-height: 26px;
					font-size: 14px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #FFFFFF;
					padding: 0 12px;
					background: #FFCC62;
					border-radius: 4px;
					border: 1px solid #FFCA5B;
				}
			}
			.btn_box {
				display: flex;
				box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				background: #FFFFFF;
			}
			.collect{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				margin-left: 5px;
				padding: 8px 0;
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
				}
				.collectImg{
					height: 18px;
				}
			}
			.freediving{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
        padding: 8px 0;
        position: relative;
				.freedivingImg{
					height: 18px;
				}
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
        }
        .num{
          position: absolute;
          font-style: normal;
          text-align: center;
          // width: 14px;
          // height: 14px;
					min-width: 14px;
          border-radius: 999px;
					padding: 0 3px;
          top: 0px;
          right: 4px;
          background: #EC6C01;
          font-size: 10px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 14px;
        }
			}
			.btnGroup{
				flex: 4;
				display: flex;
				background: url(~@/assets/image/selected/20220626-112349.png) no-repeat;
				background-size:100% 100%;
				height: 40px;
				line-height: 40px;
				margin: 2px 7.5px 0 7.5px;
				.shoppingCart{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyText{
						font-size: 14px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
				.purchase{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyText{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.moneySign{
			font-size: 18px;
		}
	}
	/deep/.tabBlock{
		padding: 0 15px;
		.tab{
			justify-content: space-between;
		}
	}
	// 评价导航栏
	/deep/.van-tabs-circle {
		padding-left: 18px;
		.van-tabs__nav--line {
			height: 28px;
		}
		.van-tab {
			height: 28px;
			line-height: 28px;
			font-size: 12px;
			color: #666666;
			border-radius: 13px;
			background: rgba(21, 183, 221, 0.1);
			margin-right: 24px;
			padding: 4px 12px;
		}
		.van-tab--active {
			color: #fff;
			background: #15B7DD;
		}
		.van-tabs__line {
			display: none;
		}
	}
	.swiper-item{
		padding-bottom: 100%;
		overflow: hidden;
		.swiperImg{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			min-height: 100%;
			min-width: 100%;
			// width: auto !important;
		}
	}
</style>
