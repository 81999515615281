<template>
  <div class="infobox">
    <div :style="funHead()">
      <van-nav-bar
        v-if="$route.query.head != '0'"
        title="会员权益协议"
        :fixed="true"
        :placeholder="true"
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>
    <div class="h1s">
      <h1>购买指定课程赠与中国残疾人康复协会个人会员的通知</h1>
    </div>
     <!-- <div class="righttxt">
      <p>
        <br />
        版本更新日期：2023年02月01日
        <br />
        版本生效日期：2023年02月01日
      </p>
    </div> -->
    <div>
      <br />
      <p class="textinfo">
        （针对2023年新购买课程的学员，用于购买课程时的说明）</br></br>
        &nbsp;&nbsp;&nbsp;为发展残疾人康复事业，壮大康复工作队伍，提升康复服务质量，经与中国残疾人康复协会商议，自2023年开始，在华夏云课堂购买由中国残疾人康复协会颁发证书的课程时，将赠与中国残疾人康复协会个人会员资格，具体规则为：</br></br>
        1.会员有效期规则：</br></br>
        &nbsp;&nbsp;&nbsp;1）华夏云课堂用户，每购买一门指定课程后，赠与一年有效期中国残疾人康复协会个人会员，在会员有效期内可享受中国残疾人康复协会个人会员权益（个人会员权益见：附表）。</br></br>
        &nbsp;&nbsp;&nbsp;2）通过购买指定课程获得中国残疾人康复协会个人会员有效期最高累计三年，有效期从第一次购买课程时间为起始时间。</br></br>
        举例：一名华夏云课堂学员，在2023年1月1日买指定课程，获得会员有效期为1年，2023年12月31日到期；在2023年3月3日再次购买了一门指定课程，会员延期1年，即延期2024年12月31日；在2024年4月5日再次购买指定课程，会员延期1年，即延期2025年12月31日。</br></br>
        </br></br>
        2.购买课程规则：</br></br>
        &nbsp;&nbsp;&nbsp;指定课程名录：《孤独症康复教育上岗培训课程》、《智力障碍康复教育上岗培训课程》、《VB-MAPP评估初阶培训课程》、《VB-MAPP评估进阶培训课程》、《卡罗来纳特殊教育评估培训课程CCPSN》、《卡罗来纳特殊教育评估培训课程CCITSN》、《孤独症儿童言语技能培训课程》。</br></br>
        &nbsp;&nbsp;&nbsp;注：如指定课程名录有更新，请您关注华夏云课堂相关通知。</br></br>
        附表：《中国残疾人康复协会个人会员权利和义务》</br></br>
        摘自：《中国残疾人康复协会个人会员注册须知》</br></br>
        来源：https://www.cncard.org.cn/vip/personageNotice</br></br>
        一、个人会员的权利</br></br>
        1.有选举权、被选举权和表决权；</br></br>
        2.参选分支机构委员、常务委员、副主任委员和主任委员；</br></br>
        3.对协会工作提出批评、建议和监督；</br></br>
        4.获得使用“中国残疾人康复协会会员”名称作为个人荣誉和从业、社会交往的资质；</br></br>
        5.优惠参加协会举办的国内外有关学术交流、专题会议、继续教育、专业培训等活动；</br></br>
        6.优惠征订协会主办或出版的报刊书籍和学术资料；</br></br>
        7.享有协会举办的评奖活动以及分支机构举办优秀论文和优秀会员的评选；</br></br>
        8.入会自愿、退会自由。</br></br>
        二、个人会员的义务</br></br>
        1.遵守国家法律法规和制度，遵守本会章程，执行协会决议；</br></br>
        2.接受协会监督、管理、依法开展业务活动；</br></br>
        3.遵守职业道德、维护本会的合法权益和会员的声誉；</br></br>
        4.积极参与协会及其分支机构的工作和活动，完成协会委托的工作；</br></br>
        5.向协会反映情况，提供相关资讯；</br></br>
        6.缴纳会费。
      </p>
    </div>
  </div>
</template>

<script>
import { FunJudgDevice } from '@/unit/fun.js'

export default {
  data(){
    return{
      
    }
  },
  methods: {
    funHead(){
      if(this.$route.query.head != 0){return false}
      const type = FunJudgDevice()
      if(type == 'ios' && window.webkit){
        // ios端
        return 'height:100px;'
      }else if(type == 'android'){
        // android端
        return 'height:20px;'
      }else{
        // 其他端
        return 'height:80px;'
      }
    }
  }
}
</script>


<style lang="less" scoped>
.infobox {
  padding: 16px 24px;
}
.h1s {
  text-align: left;
  line-height: 33px;
  font-family: PingFangMedium;
  font-size: 22px;
  h1 {
    font-weight: 600;
  }
}
.span1 {
  line-height: 16px;
  display: block;
  font-family: PingFangMedium;
  font-size: 16px;
}
.textinfo {
  line-height: 24px;
  display: block;
  font-family: PingFangMedium;
  font-size: 16px;
}
.blod{
  font-weight: bold;
}
.righttxt{
  text-align: right;
   line-height: 18px;
  display: block;
  font-family: PingFangMedium;
  font-size: 12px;
}
</style>