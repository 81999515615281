<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="新知共享"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="main-box">
      <!-- <button @click="onShare">分享</button> -->
      <div
        class="know-how-item"
        v-for="item in speakList"
        :key="item.autismClassificationId + 'pid'"
      >
        <div class="course-title">
          <div class="title">
            <span class="title-border"></span>
            {{ item.name }}
          </div>
          <router-link
            class="course-titler"
            :to="
              '/knowledge/autismSpeaks?classId=' +
              item.autismClassificationId +
              '&name=' +
              item.name
            "
            v-show="item.autismVOList.length"
          >
            <span>更多</span>
            <van-icon name="arrow" />
          </router-link>
        </div>

        <div class="autismContent">
          <div class="scroll-x" v-if="item.autismVOList.length">
            <div
              class="item"
              v-for="i in item.autismVOList"
              :key="i.autismId"
              @click="getDetail(i.name, i.fileUrl)"
            >
              <!-- @click="getDetail(item.autismId)"> -->
              <div class="imgs">
                <img :src="i.img" alt="" />
              </div>
              <div class="title">{{ i.name }}</div>
            </div>
          </div>
          <noMessage style="margin-top: 0" v-else type="1"></noMessage>
        </div>

        <!-- 样式更改 -->
        <div class="content-box" v-if="false">
          <div class="container-innerbox">
            <div class="content-header">
              <div class="content-left">
                <img
                  class="content-pic"
                  src="../../assets/image/knowledge/pic_authentication.png"
                />
                美国自闭症之声授权文件
              </div>
              <div class="content-right">
                <img
                  class="content-icon"
                  src="../../assets/image/knowledge/icon_autism.png"
                />
              </div>
            </div>
            <div class="content-footer">
              <div
                v-for="item in speakList"
                :key="item.autismId"
                class="content-list"
                @click="getDetail(item.name, item.fileUrl)"
              >
                <!-- @click="getDetail(item.autismId)" > -->
                <div class="content-top">
                  <div target="_blank">
                    <van-image
                      class="content-image"
                      fit="cover"
                      :src="item.mobileImg"
                    />
                  </div>
                </div>
                <div class="content-bottom title">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-box">
        <div class="title">
          <div class="title-border"></div>
          <!-- /FAQS -->
          <span class="texts">常见问答<span class="grey"></span></span>
        </div>
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          @load="getQAList"
          class="van-list"
        >
          <div
            class="QandAItem"
            :class="bgColor[index]"
            v-for="(item, index) in qaList"
            :key="item.qaId"
            @click="goPage(item.answerId)"
          >
            <span>{{ item.categoryName }}</span>
            <img
              class="bg-icon1"
              src="@/assets/image/knowledge/QA-icon1.png"
              alt=""
            />
            <img
              class="bg-icon2"
              src="@/assets/image/knowledge/QA-icon2.png"
              alt=""
            />
            <img
              class="bg-icon3"
              src="@/assets/image/knowledge/QA-icon3.png"
              alt=""
            />
          </div>
        </van-list>
        <noMessage
          v-if="qaList.length == 0"
          type="1"
          style="padding-bottom: 80px"
        ></noMessage>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import { FunSendShare } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      speakList: [],
      fileUrl: "",
      bgColor: [
        "QandAItem-bg1",
        "QandAItem-bg2",
        "QandAItem-bg3",
        "QandAItem-bg4",
        "QandAItem-bg5",
        "QandAItem-bg6",
        "QandAItem-bg7",
        "QandAItem-bg1",
        "QandAItem-bg2",
        "QandAItem-bg3",
        "QandAItem-bg4",
        "QandAItem-bg5",
        "QandAItem-bg6",
        "QandAItem-bg7",
        "QandAItem-bg1",
        "QandAItem-bg2",
        "QandAItem-bg3",
        "QandAItem-bg4",
        "QandAItem-bg5",
        "QandAItem-bg6",
        "QandAItem-bg7",
      ],
      qaList: [], //百问百答
      page: 1, //当前页数
      size: 10, //条数
      loading: false,
      finished: false,
      finishedText: "",
    };
  },
  // 事件处理器
  methods: {
    onShare(){
      let json = {
        WebUrl: 'https://www.baidu.com',
        title: '百度',
        description: '这是百度简介',
        imageUrl: 'https://cos.hxclass.cn/prod/management/20220708/69efd0b0e44f46768be0303a5c5d96aa.jpg'
      }
      FunSendShare(JSON.stringify(json))
    },
    goPage(id) {
      this.$router.push({ path: "/selected/problemList?qaTypeId=" + id });
    },
    // 自闭症之声
    getSpeakList() {
      this.$ajax({
        url: "/hxclass-mobile/autism/classification/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.speakList = res.data;
        }
      });
    },
    // 百问百答
    //  onSwichChange(e){
    //     clearTimeout(this.qa);
    //     this.qa = setTimeout(() => {  // 防抖

    //     }, 400);
    //   },
    getQAList() {
      clearTimeout(this.qa);
      this.qa = setTimeout(() => {
        // 防抖
        this.$ajax({
          url: "/hxclass-mobile/information/answer/category/list",
          params: {
            // qaTypeId: 1,
            page: this.page,
            size: this.size,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // this.qaList = res.data.records;
            this.qaList = [...this.qaList, ...res.data.records];
            // 加载完成
            if (this.qaList.length == res.data.total) {
              this.finished = true;
              // this.finishedText = "没有更多了";
            }
            // 无数据
            if (this.qaList.length == 0) {
              this.finished = true;
              // this.finishedText = "暂无数据";
            }
            this.page++;
            this.loading = false;
          }
        });
      }, 400);
    },
    getDetail(name, url) {
      this.$router.push({
        path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getSpeakList();
    this.getQAList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>


<style lang="less" scoped>
/deep/.nomessage {
  margin-top: 36px;
}
.container-box {
  height: 100vh;
  overflow: auto;
  /* 隐藏滚动条 */
  * ::-webkit-scrollbar {
    width: 0 !important;
  }

  * ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  * ::-webkit-scrollbar {
    display: none;
  }
  margin: 0 auto;
  width: calc(100% - 36px);

  /deep/.van-nav-bar--fixed {
    z-index: 2;
  }

  /deep/.van-image__img {
    border-radius: 5px;
  }

  .main-box {
    .title {
      font-size: 16.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 24.5px;
      display: flex;
      align-items: baseline;
    }

    .title-border {
      display: inline-block;
      margin-right: 4px;
      width: 3px;
      height: 13px;
      border-radius: 4px;
      background-color: #15b7dd;
    }

    .know-how-item {
      .course-title {
        display: flex;
        justify-content: space-between;
        margin: 18px 0px 12px 0px;

        .course-titler {
          font-size: 12px;
          font-family: PingFangMedium;
          // font-weight: 500;
          color: #999999;
          line-height: 24.5px;

          img {
            width: 13px;
            margin-left: 5px;
            vertical-align: sub;
          }
        }
      }

      .autismContent {
        padding: 16px;
        background: #e8f5fd;
        border-radius: 20px;
        .scroll-x {
          width: 100%;
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          padding-right: 10px;
        }
        .item {
          width: 92.5px;
          flex: none;
          // overflow: hidden;
          margin-right: 17px;
          &:last-child {
            margin-right: 0;
          }
          .imgs {
            width: 100%;
            height: 135px;
            overflow: hidden;
            box-shadow: 6px 6px 6px 1px rgba(0, 0, 0, 0.12);
            // border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              object-fit: cover;
            }
          }
          .title {
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.4;
            color: #333;
            font-weight: 400;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
      }

      .content-box {
        position: relative;
        margin-bottom: 14px;
        width: 100%;
        height: 227px;
        background: url(../../assets/image/knowledge/bcg_knowledge.png)
          no-repeat;
        background-size: 100% 102%;
        border-radius: 10px;
        z-index: 1;

        .container-innerbox {
          position: absolute;
          top: 6.5px;
          left: 7px;
          box-sizing: border-box;
          padding: 14px 18px;
          width: calc(100% - 18px);
          height: 213px;
          background-color: #ffffff;
          border-radius: 10px;
          z-index: 2;

          .content-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content-left {
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #15b7dd;
              line-height: 21px;

              .content-pic {
                margin-right: 4px;
                width: 12px;
                height: 12px;
              }
            }

            .content-right {
              .content-icon {
                width: 64px;
                height: 25.5px;
              }
            }
          }

          .content-footer {
            display: flex;
            justify-content: space-between;
            margin: 9px 0;

            .content-list {
              width: 86px;

              .content-top {
                .content-image {
                  width: 100%;
                  height: 111.5px;
                  box-shadow: 0px 2.5px 5px 0.5px rgba(93, 131, 167, 0.1);
                }
              }

              .content-bottom {
                width: 96.5px;
                height: 36px;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
              }
            }
          }
        }
      }
    }

    .footer-box {
      margin-top: 18px;
      .van-list {
        margin-top: 18.5px;
        overflow: hidden;
      }
      .QandAItem {
        float: left;
        margin-right: 14px;
        width: calc((100% - 14px) / 2);
        height: 85px;
        border-radius: 8px;
        position: relative;
        margin-bottom: 10px;
        .bg-icon1 {
          position: absolute;
          bottom: 4px;
          left: 12px;
          width: 72px;
          height: 60px;
        }
        .bg-icon2 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 60px;
          height: 58px;
        }
        .bg-icon3 {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 36px;
          height: 18px;
        }
        span {
          display: inline-block;
          padding: 21px 13px 0;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      .QandAItem-bg1 {
        background: linear-gradient(117deg, #abdcff 0%, #b7adff 100%);
      }
      .QandAItem-bg2 {
        background: linear-gradient(117deg, #abdcff 0%, #0396ff 100%);
      }
      .QandAItem-bg3 {
        background: linear-gradient(117deg, #b3ff9d 0%, #41c9ff 100%);
      }
      .QandAItem-bg4 {
        background: linear-gradient(270deg, #ff9a9e 0%, #fad0c4 100%);
      }
      .QandAItem-bg5 {
        background: linear-gradient(117deg, #7dbbcc 0%, #99ecff 100%);
      }
      .QandAItem-bg6 {
        background: linear-gradient(117deg, #a8edea 0%, #fed6e3 100%);
      }
      .QandAItem-bg7 {
        background: linear-gradient(120deg, #a8edea 0%, #41c9ff 100%);
      }
      .QandAItem:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.texts {
  font-size: 16.5px;
  .grey {
    font-size: 10px;
    font-weight: 300;
    font-family: Light;
    color: #999999;
  }
}
</style>

