<template>
  <div class="collection">
    <!-- 导航栏 -->
    <div ref="headBox">
      <van-nav-bar
        class="nav-circular"
        title="可用商品"
        :fixed="true"
        :placeholder="true"
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>

    <!-- 当前优惠券 -->
    <div class="coupon">
      <div class="box">
        <div class="left">
          <div class="money">
            <span class="num" v-if="formData.type == 3">{{formData.discount}}</span>
            <span :style="{'font-size':formData.type == 3?'12px':formData.type == 1?'14px':''}">{{formData.type == 3?'折':formData.type == 1?'￥':''}}</span>
            <span class="num" v-if="formData.type == 1">{{formData.couponPrice}}</span>
          </div>
        </div>
        <div class="right">
          <!-- 满减券 -->
					<div class="money" v-if="formData.type == 1">
            <span>满</span>
            <span class="num">{{formData.fullPrice}}</span>
            <span>减</span>
            <span class="num">{{formData.couponPrice}}</span>
					</div>
          <!-- 折扣券 -->
					<div class="money" v-if="formData.type == 3">
            <span>满</span>
            <span class="num">{{formData.discountPrice}}</span>
            <span>{{formData.discountType==1?'件':'元'}}</span>
            <span class="num">{{formData.discount}}</span>
            <span>折</span>
					</div>
          <div class="date" v-if="formData.times == 2">{{formData.startTime}} 至 {{formData.endTime}}</div>
          <div class="date" v-if="formData.times == 1">不限使用时间</div>
        </div>
      </div>
      <p v-if="formData.scope == 2">使用规则：限{{producttype}}商品使用，{{formData.overly?'':'不'}}可叠加使用。</p>
    </div>

    <!-- tab切换按钮 -->
    <div class="scroll-view">
      <div class="scroll-item"
        :class="{'score-hover' : scoreIndex==index}"
        @click="onTabs(index)"
        v-for="(item,index) in formData.productList" :key="'productList'+item.id"
      >{{item.name}}</div>
    </div>

    <!-- 优惠券列表 -->
    <div class="list" :style="{height: `calc(100vh - ${210 + $store.state.stateHeight}px`}" v-if="list.length">
      <ShopList :shopList="list" @getShopCarList="getShopCarList" @addCart="addCart"/>
      <div class="ball-container">
        <!--小球-->
        <transition name="drop" @before-enter="beforeDrop" @enter="dropping" @after-enter="afterDrop">
          <div class="ball" v-show="ball.show">
            <div class="inner inner-hook">1</div>
          </div>
        </transition>
      </div>
      <div class="item" v-for="item in list" :key="item.productId" v-show="false">
        <!-- 课程 -->
        <template v-if="item.type == 1">
          课程
        </template>
        <!-- 图书 -->
        <template v-if="item.type == 2">
          图书
        </template>
        <!-- 教具 -->
        <template v-if="item.type == 3">
          教具
        </template>
        <!-- 模拟考试 -->
        <template v-if="item.type == 4">
          模拟考试
        </template>
        <!-- 电子照片 -->
        <template v-if="item.type == 5">
          电子照片
        </template>
        <!-- 延期 -->
        <template v-if="item.type == 6">
          延期
        </template>
        <!-- 补考 -->
        <template v-if="item.type == 7">
          补考
        </template>
        <!-- 直播 -->
        <template v-if="item.type == 8">
          <router-link :to="'/curriculum/course?id='+item.productId">
            <EduVideo-List :listData="FunEduVideo(item)" listType="2" />
          </router-link>
        </template>
        <!-- 其他 -->
        <template v-if="item.type == 9">
          其他
        </template>
      </div>
        <!-- <EduClass-List
          :listData="EduClassList"
          listType="2" />
      </div>
      <div class="item">
        <Book-List 
          :listData="BookList" 
          listType="2" />
      </div>
      <div class="item">
        <EduVideo-List
          :listData="EduVideoList"
          listType="2" />
      </div>
      <div class="item">
        <MockExam-List
          :listData="MockExamList"
          listType="2" />
      </div>
      <div class="item" v-show="showIndex==4">
        <LeadNews-List />
      </div>
      <div class="item" v-show="showIndex==5">
        <QA-List />
      </div>
      <div class="item" v-show="showIndex==6">
        <Infomation-List />
      </div>
      <div class="item" v-show="showIndex==7">
        <Autistic-List />
      </div>
      <div class="item" v-show="showIndex==8">
        <Exam-List />
      </div> -->
    </div>
    <ShpCarModel :shopList="list" :offShelfItems='offShelfItems' :shopCarList="shopCarList" @getShopCarList="getShopCarList" :num="num" />
  </div>
</template>

<script>
import ShpCarModel from '@/components/shopMall/shopCarModel.vue'; // 购物车弹框
import ShopList from '@/components/shopMall/shopList.vue'; // 商品列表
import SwiperMenu from "@/components/swiperMenu.vue"; // 导航组件
import BookList from "@/components/searchSeo/BookList.vue"; // 图书教具
import EduClassList from "@/components/searchSeo/eduClassList.vue"; // 教育课程
import MockExamList from "@/components/searchSeo/mockExamList.vue"; // 模拟考试
import EduVideoList from "@/components/searchSeo/eduVideoList.vue"; // 教育直播
import LeadNewsList from "@/components/searchSeo/leadNewsList.vue"; // 云课头条
import QAList from "@/components/searchSeo/QAList.vue"; // 百问百答
import InfomationList from "@/components/searchSeo/infomationList.vue"; // 资讯速递
import AutisticList from "@/components/searchSeo/autisticList.vue"; // 自闭之声
import ExamList from "@/components/searchSeo/examList.vue"; // 行业考试
export default {
  // 可用组件的哈希表
  components: {
    ShpCarModel,
    ShopList,
    SwiperMenu,
    EduClassList, // 教育课程
    BookList, // 图书教具
    EduVideoList, // 教育直播
    MockExamList, // 模拟考试

    LeadNewsList, // 云课头条
    QAList, // 百问百答
    InfomationList, // 资讯速递
    AutisticList, // 自闭之声
    ExamList, // 行业考试
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      ball: {
        show: false,
        el:null
      },
      typeList:['课程','图书','教具','模拟考试','电子照片','延期','补考','直播','其他'],
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
      num:0, // 购物车商品总数
      windowHeight: document.documentElement.clientHeight, // 屏幕的高度
      swiperHeight: 0, // swiper盒子的高度
      menuArry: [
        // 菜单
        { id: 1, name: "教育课程" },
        { id: 2, name: "图书教具" },
        { id: 3, name: "教育直播" },
        { id: 4, name: "模拟考试" },

        // {id:1,name:'图书教具'},
        // {id:2,name:'教育课程'},
        // {id:3,name:'模拟考试'},
        // {id:4,name:'教育直播'},
        // {id:5,name:'云课头条'},
        // {id:6,name:'百问百答'},
        // {id:7,name:'资讯速递'},
        // {id:8,name:'自闭之声'},
        // {id:9,name:'行业考试'},
      ],
      showIndex: 2,
      scoreIndex:0,

      EduClassList: [{title:1}],
      BookList: [{title:1}],
      EduVideoList: [{title:1}],
      MockExamList: [{title:1}],
      formData: {},
      list: []
    };
  },
  // 事件处理器
  methods: {
    addCart(event,item){
      this.drop(event.target)
    },
    drop(el) {
      //抛物
      let ball = this.ball;
      if (!ball.show) {
        //当小球显示状态为隐藏时
        ball.show = true; //将这个小球的显示状态设置为true
        ball.el = el; // 将cartControl传过来的对象挂载到ball的el属性上
        // this.dropBalls.push(ball); // 将这个小球放入到dropBalls数组中
        return;
      }
    },
    beforeDrop(el) {
      /* 购物车小球动画实现 */
      // let rednum = this.balls.length;
      // while (rednum--) {
        let ball = this.ball;
        if (ball.show) {
          let rect = ball.el.getBoundingClientRect(); //元素相对于视口的位置
          let x = rect.left - 32; //x初始值  // 小球x方向位移= 小球距离屏幕左侧的距离-外层盒子距离水平的距离
          let y = -(window.innerHeight - rect.top - 22); //获取y
          el.style.display = "";
          el.style.webkitTransform = "translateY(" + y + "px)"; //translateY  // 设置外层盒子，即小球垂直方向的位移
          el.style.transform = "translateY(" + y + "px)";
          let inner = el.getElementsByClassName("inner-hook")[0];
          inner.style.webkitTransform = "translateX(" + x + "px)"; // 设置内层盒子，即小球水平方向的距离
          inner.style.transform = "translateX(" + x + "px)";
        }
      // }
    },
    dropping(el, done) {
      /*重置小球数量 样式重置*/
      el.offsetHeight;
      el.style.webkitTransform = "translate3d(20px,-30px,0)"; // 设置小球掉落后最终的位置
      el.style.transform = "translate3d(20px,-30px,0)";
      let inner = el.getElementsByClassName("inner-hook")[0];
      inner.style.webkitTransform = "translate3d(0,0,0)";
      inner.style.transform = "translate3d(0,0,0)";
      el.addEventListener("transitionend", done); // Vue为了知道过渡的完成，必须设置相应的事件监听器。它可以是transitionend或 animationend
    },
    afterDrop(el) {
      /*初始化小球*/
      // let ball = this.dropBalls.shift(); // 完成一次动画就删除一个dropBalls的小球
      // if (ball) {
        this.ball.show = false;
        el.style.display = "none"; //完成一次之后就让小球隐藏
      // }
    },
    // 查询数据列表
    getList(){
      this.$ajax({
        url: `/hxclass-mobile/app-mall/shop/coupon-detail/${this.$route.query.couponId}`
      }).then(res=>{
        if(res.code == 200 && res.success){
					this.formData = res.data
          if(res.data.productList && res.data.productList[0]){
            this.list = res.data.productList[0].productList
          }
        }else{
          this.$alert(res.message)
        }
      }).catch(err=>{
        console.error(err)
      })
    },

    // 点击tab
    onTabs(index){
      this.scoreIndex = index
      if(this.formData.productList[index]){
        this.list = this.formData.productList[index].productList
      }
    },

    // 数据转换 - 直播
    FunEduVideo(e){
      let obj = new Object()
      obj.title = e.name
      obj.cover = e.listPhoto
      obj.couponPrice = e.couponPrice
      obj.liveId = e.productId
      // console.log(e)
      return [obj]
    },
    // 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
          let num = 0;
          this.shopCarList.map(item=>{
            num+=item.count;
          })
          this.num = num;
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getList();
    this.getShopCarList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.swiperHeight =
      this.windowHeight - this.$refs.headBox.offsetHeight - 46;
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    producttype(){
      let arr = this.formData.categoryType.split(',');
      let str = '';
      if(arr.length>1){
        arr.map((item,index)=>{
          if(index+1 == arr.length){
            str+=this.typeList[item-1]+'类'
          }else{
            str+=this.typeList[item-1]+'类、'
          }
        })
        return str;
      }else{
        return this.typeList[arr[0]-1]+'类'
      }
    }
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.ball {
  position: fixed;
  // 小球落下来的位置
  left: 32px;
  bottom: 22px;
  // margin-top: 32px;
  // margin-left: 22px;
  z-index: 200;
  transition: all 1s cubic-bezier(0.49, -0.29, 0.75, 0.41); /*贝塞尔曲线*/
}
.inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #EC6C01;
  transition: all 1s linear;
  text-align: center;
  color: #FFFFFF;
}
.cart {
  position: fixed;
  bottom: 22px;
  left: 32px;
  width: 30px;
  height: 30px;
  background-color: #EC6C01;
  color: rgb(255, 255, 255);
}
.coupon{
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 20px 10px;
  overflow: hidden;
  .box{
    display: flex;
    flex-direction: row;
    .left{
      flex: none;
      margin-right: 20px;
      .money{
        background-image: url('./../../assets/image/icon/20220412-180005.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 58px;
        color: #fff;
        line-height: 70px;
        padding: 0 20px 0 10px;
        .icon{
          font-size: 14px;
        }
        .num{
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .right{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .money{
        font-size: 14px;
        line-height: 21px;
        .num{
          font-size: 22px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #FCA084;
          line-height: 33px;
          background: linear-gradient(145deg, #FF8B6F 0%, #E44C26 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0 6px;
        }
      }
      .date{
        font-size: 10px;
        color: #666;
        line-height: 15px;
      }
    }
  }
  >p{
    margin-top: 13px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
.collection {
  max-height: 100vh;
  overflow: hidden;
  .menu-list {
    .menuSwiper-box {
      background: url("../../components/LoopTab/img/tablink.png");
      /deep/.swiper-slide {
        margin-top: 13px;
        color: #333333;
      }
    }
  }
  .list {
    box-sizing: border-box;
    // background: #ffffff;
    overflow-y: scroll;
    padding: 15px;
    padding-bottom: 100px;
    .item{
      margin-top: 10px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.scroll-view{
  box-sizing: border-box;
  padding: 18px;
  padding-bottom: 0;
  width: 100%;
  overflow-y: scroll;
  white-space: nowrap;
  .scroll-item{
    display: inline-block;
    text-align: center;
    padding-bottom: 7.5px;
    margin-right: 24px;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #666666;
    line-height: 19.5px;
  }
  .score-hover{
    color: #15B7DD;
    position: relative;
    font-weight: 500;
  }
  .score-hover::after{
    content: '';
    display: inline-block;
    background: url(../../assets/image/icon/20220218041506.png) no-repeat;
    background-size:100%;
    width: 5.5px;
    height: 6.5px;
    position: absolute;
    right: -4px;
    bottom: 7px;
  }
}
</style>
