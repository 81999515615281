<template>
  <div class="signUpConfirmDetail">
    <van-nav-bar
      class="nav-circular"
      title="查看信息"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <div class="content-box">
      <!-- 基本信息 -->
      <div class="all-bg">
        <div class="all-title">基本信息：</div>
        <div class="child-item">
          <span class="item-L-name">姓名</span>
          <span class="item_con">{{ confirmObj.name }}</span>
        </div>
        <div class="child-item">
          <span class="item-L-name">性别</span>
          <div class="item-R-select">
            <van-field name="sex" class="sex">
              <template #input>
                <van-radio-group
                  v-model="confirmObj.sex"
                  direction="horizontal"
                >
                  <van-radio checked-color="#15B7DD" name="1" icon-size="14px"
                    >男</van-radio
                  >
                  <van-radio checked-color="#15B7DD" name="0" icon-size="14px"
                    >女</van-radio
                  >
                </van-radio-group>
              </template>
            </van-field>
          </div>
        </div>
        <div class="child-item">
          <span class="item-L-name">出生日期</span>
          <span class="item_con">{{ confirmObj.birthday }}</span>
        </div>
        <div class="child-item">
          <span class="item-L-name">联系方式</span>
          <span class="item_con">{{ confirmObj.mobile }}</span>
        </div>
        <div class="child-item">
          <span class="item-L-name">身份证号</span>
          <span class="item_con">{{ confirmObj.idcard }}</span>
        </div>
        <div class="child-item">
          <span class="item-L-name">学历</span>
          <span class="item_con">{{ confirmObj.education }}</span>
        </div>
        <div class="child-item">
          <span class="item-L-name">职称/职务</span>
          <span class="item_con">{{ confirmObj.post }}</span>
        </div>
        <div class="child-item" style="border-bottom: none">
          <span class="item-L-name">工作单位</span>
          <span class="item_con">{{ confirmObj.workUni }}</span>
        </div>
      </div>

      <!-- 邮寄纸质照片 -->
      <div class="all-bg" v-if="confirmObj.mode == 1">
        <div class="all-title">邮寄纸质照片</div>
        <div class="child-item">
          <span class="item-L-name">快递公司</span>
          <span class="item_con">{{ confirmObj.logisticsCompany }}</span>
        </div>
        <div class="child-item" style="border-bottom: none; padding: 25rpx 0 0">
          <span class="item-L-name">物流单号</span>
          <span class="item_con">{{ confirmObj.logisticsNumber }}</span>
        </div>
      </div>

      <!-- 电子照片 -->
      <div class="all-bg" v-if="confirmObj.mode == 2">
        <div class="all-title">电子照片</div>
        <img class="icon" :src="confirmObj.picture" />
      </div>

      <!-- 收货地址 -->
      <div class="all-bg">
        <div class="all-title">收货地址</div>
        <div class="all-con">
          <div class="con_title">
            <span class="title_name">{{ confirmObj.userName }}</span>
            <span class="title_phone">{{ confirmObj.userMobile }}</span>
          </div>
          <div class="con_detail">
            <!-- 北京市朝阳区和平里街道一盒阳光大厦北京市朝阳区和平里街道一盒阳光大厦 -->
            {{ confirmObj.userAddressText }}{{ confirmObj.userAddress }}
          </div>
        </div>
      </div>

      <!-- <div class="footer">
        <van-button class="all-btn-bigCircle btn" @click="$onReturn($route.query.fromMenu == '1')">
          知道了
        </van-button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      confirmObj: {},
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 用户报名id
    this.signupUserId = this.$route.query.signupUserId;
    // 获取详情
    this.getSignUpDetail();
  },
  // 事件处理器
  methods: {
    // 确认信息详情
    getSignUpDetail() {
      this.$ajax({
        url: "/hxclass-mobile/exam/confirm-info",
        method: "GET",
        params: {
          signupUserId: this.signupUserId,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.confirmObj = res.data;
          this.confirmObj.sex = JSON.stringify(this.confirmObj.sex);
        }
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./signUpConfirm.less";
.signUpConfirmDetail {
  padding-bottom: 16px;
}
.content-box {
  .all-title {
    font-size: 16px;
  }
  .footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 10px 40px;
    .all-btn-bigCircle {
      width: 100%;
      border: 0;
    }
  }
}
.content-box {
  margin-top: 20px;
  .all-bg {
    margin-top: 16px;
    padding-bottom: 8px;
    position: relative;
    box-shadow: 0px 2px 5px 0px rgba(93, 131, 167, 0.1);
    font-family: PingFangMedium;
    font-weight: 500;
    .icon {
      width: 100%;
      margin-top: 13px;
    }
    .child-item {
      .item_con {
        font-size: 14px;
        color: #323233;
        font-family: PingFangMedium;
      }
      .item-R-select {
        pointer-events: none;
      }
    }
    .all-con {
      .con_title {
        margin-top: 13px;
        .title_name {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
        }
        .title_phone {
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          margin-left: 13px;
        }
      }
      .con_detail {
        font-size: 13px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #000000;
        line-height: 19px;
        margin-top: 13px;
        margin-bottom: 10px;
      }
    }
  }
}
.item-L-name {
  font-family: PingFangMedium;
  font-weight: 400;
  color: #333333;
}
</style>
