<template>
  <!-- 我的学习 -->
  <div class="container-box">
    <van-popup v-model="onDelayPopupShow" :round="true" :lazy-render="true" :close-on-click-overlay="false">
      <div class="box_popup Delay">
        <h4 class="title">申请延期须知</h4>
        <!-- 免费延期 -->
        <template v-if="delayCode == 1005">
          <p>1.本次延期为您首次对本课程进行延期，无需缴纳费用；</p>
          <p>2.申请延期后课程有效期顺延{{delayForm.days}}天，申请成功后即生效，有效期最后一天23:59:59秒失效；</p>
          <p>3.有效期内课程观看次数、章节自测题答题次数均不受限制；</p>
          <p>4.超过有效期后如需再次学习，每次延期需缴纳{{delayForm.nextPrice}}元延期费，请您合理安排学习时间；</p>
          <p>5.支付成功后不支持退款。</p>
        </template>
        <!-- 收费延期 -->
        <template v-else>
          <p>1.本次延期为您第{{delayForm.delayCount}}次对课程进行延期，延期费为{{delayForm.price}}元，缴纳费用后课程有效期顺延{{delayForm.days}}天；</p>
          <p>2.支付成功后即生效，有效期最后一天23:59:59秒失效;</p>
          <p>3.有效期内课程观看次数、章节自测题答题次数均不受限制；</p>
          <p>
            4.超过有效期后如需再次学习，则需以本计费规则再次缴纳延期费用，请您合理安排学习时间；
          </p>
          <p>5.支付成功后不支持退款。</p>
        </template>
        <!-- <div class="popup_con">您的课程于{{delayForm.date}}到期，本次延期{{delayCode==1006 ? ('费用为' + delayForm.price) + '元' : '为免费'}}，延期有效期为{{delayForm.days}}天，从付费时算起，是否申请？</div> -->
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onDelayPopupShow = false,onCloseDelay()"
            >取消</span
          >
          <span class="all-btn-middle btn2" @click="onDelay">申请</span>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="PromptShow" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">{{Prompt}}</div>
        <div class="popup_button" style="justify-content: center;">
          <span class="all-btn-middle btn2" @click="PromptShow = false">我知道了</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import { FunConfirmOrder, studyListRefresh, FunCloseDelay } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      delayLoad:false,
      Prompt: '',
      PromptShow: false,
      stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
      onPopup: false,
      onDelayPopupShow: false,
      isFirstDelay: true,
      delayForm: {},
      delayCode: 0,
      popItem: {},
      indicatorDots: true,
      // indicatorColor: rgba(0, 0, 0, .3),
      autoplay: true,
      interval: 3000,
      duration: 500,
      courseTab: 0, //0：未结课，1：已结课，2：附赠视频
      courseList: [],
      liveTab: 0, //0：直播，1：回放
      liveList: null,
      remindList: {},
      loading: false,
      clockObj: {
        punchDay: 0,
        integral: 0,
      },
      studyHour: {},
      data: {
        clock: "21",
        pointsnum: "1000",
        coursenum: "50",
        hoursnum: "1000",
        livingList: [
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
        ],
        backList: [
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
        ],
      },
    };
  },
  // 事件处理器
  methods: {
    PromptConfirm(){
      this.PromptShow = false
    },

   
    // 获取课程列表
    getChiefList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-mobile/course/user-course/my-study",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          res.data.forEach((item) => {
            item.isOpen = true;
          });
          this.courseList = res.data;
        }
      });
    },
    FunFormatTime(time) {
      var d = time ? new Date(time) : new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var hours = d.getHours();
      var min = d.getMinutes();
      var seconds = d.getSeconds();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (hours < 0) hours = "0" + hours;
      if (min < 10) min = "0" + min;
      if (seconds < 10) seconds = "0" + seconds;

      // return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
      return year + "-" + month + "-" + day;
    },

    // 延期关闭弹窗
    onCloseDelay(){
      FunCloseDelay()
    },
    // 延期查询
    toCourseDelay(courseId) {
      this.$ajax({
        url: "/hxclass-mobile/course/check-delay-course",
        params: {
          courseId: courseId,
        },
      }).then((res) => {
        if (res.success) {
          this.delayCode = res.code;
          this.delayForm = res.data;
          this.delayForm.courseId = courseId;
          this.onDelayPopupShow = true;
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 延期
    async onDelay() {
      if (this.delayCode == 1005) {
        if(this.delayLoad || !this.isFirstDelay) return  // 延期防抖
        this.delayLoad = true
        // 免费延期直接走接口
        this.$ajax({
          url: "/hxclass-mobile/course/delay-course",
          method: "POST",
          params: {
            courseDelayId: this.delayForm.courseDelayId,
            courseId: this.delayForm.courseId,
            days: this.delayForm.days,
            price: this.delayForm.price,
          },
        }).then(res=>{
          this.delayLoad = false
          if(res.code == 200 && res.success){
            this.onDelayPopupShow = false
            this.isFirstDelay = false
            this.$alert('申请成功');
            // 免费课程课程延期后通知原生刷新列表并关闭弹框
            studyListRefresh();
          } else {
            this.$alert(res.message);
          }
        })
      } else { // 付费延期走订单
        let list = [];
        list.push({
          productId: this.delayForm.courseDelayId, // 商品id
          count: 1, // 数量")
          openTimeId: "", // 班级id
          type: 6, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province: "", // 省
          city: "", // 市
          noGift: 0, // 是否为赠品 0否1是t
          productList: [],
        });
        let obj = {
          list:list, // 确认订单商品列表
          type:4, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
          address:null, // 地址id
          InvoiceId:null, // 发票id
          shortInvoiceId:null, // 暂存发票id
          couponList:null, // 优惠券列表
        }
        await this.$ajax({
          url:'/hxclass-mobile/order/saveOrderJSON',
          method:'put',
          params:{
            orderJSON: JSON.stringify(obj),
            userId: this.$store.state.userInfo.userId
          }
        })
        // 关闭弹窗
        FunCloseDelay()
        // 调原生方法去结算
        FunConfirmOrder()
        // this.$store.commit("updatelist", list);
        // this.$store.commit("updateType", 4);
        // this.$store.commit("updateInvoiceId", null);
        // this.$store.commit('updateShortInvoiceId',null);
        // this.$store.commit("updateAddress", null);
        // this.$store.commit("updateCouponList", null);
        // this.$router.push("/order/confirmOrder");
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.toCourseDelay(this.$route.query.courseId)
    })
    document.querySelector('body').setAttribute('style', 'background-color:transparent')
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    document.querySelector('body').removeAttribute('style')
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.container-box {
  background-color: #f7f7f9;
  overflow: hidden;
  min-height: 100%;
  .box_status {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    opacity: 0;
    z-index: 100;
  }

  /deep/.tabBlock {
    padding-left: 0;

    .tab__item {
      margin-right: 12px;
      padding: 0 3px;
    }
  }

  .title {
    margin-bottom: 12.5px;
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    line-height: 24.5px;
  }

  .title-border {
    display: flex;
    display: inline-block;
    margin-right: 4px;
    width: 3px;
    height: 13px;
    border-radius: 4px;
    background-color: #15b7dd;
  }

  .nav {
    margin-bottom: 12.5px;

    .default {
      display: inline-block;
      margin-right: 21.5px;
      height: 16.5px;
      font-size: 13.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #666666;
      line-height: 20.5px;
    }

    .hover {
      display: inline-block;
      margin-right: 21.5px;
      height: 16.5px;
      font-size: 13.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 20.5px;
      box-shadow: 0px -2px 0px 0px rgba(43, 216, 212, 0.35) inset;
    }
  }

  .banner-box {
    position: relative;
    margin-bottom: 12.5px;

    .banner-photo {
      width: 100%;
      height: 199px;
      font-weight: bold;
      background: url(../../assets/image/study/banner_study.png) no-repeat;
      background-size: 100%;
      font-size: 18px;
      font-family:  PingFangMedium;
      color: #ffffff;
      line-height: 125px;
      text-align: center;
    }

    .banner-menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 96px;
      right: 0;
      margin: 0 18px;
      width: calc(100% - 36px);
      height: 103px;
      background: url(../../assets/image/study/bcg_study.png) no-repeat;
      background-size: 100%;
      border-radius: 13.5px;

      .banner-menubox {
        .banner-icon {
          width: 38.5px;
          height: 38.5px;
        }

        .banner-menutitle {
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 19px;
        }
      }
    }
  }

  .clock-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px auto 12.5px;
    width: calc(100% - 36px);
    height: 54.5px;
    box-shadow: 0px 2px 2px 1px rgba(26, 63, 98, 0.05);
    background-color: #ffffff;
    border-radius: 13.5px;

    .clock-left {
      .clock-pic {
        width: 27.5px;
        height: 26px;
        vertical-align: middle;
      }

      .clock-msg {
        margin-left: 17.5px;
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 21.5px;
      }
    }

    .clock-right {
      width: 94px;
      height: 31px;
      background: url(../../assets/image/study/bcg_clock.png) no-repeat;
      background-size: 100%;
      font-size: 14.5px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 31px;
      text-align: center;
      a{
        color: #ffffff;
      }
    }
  }

  .score-box {
    display: flex;
    justify-content: space-between;
    margin: 15px 18px 0px;
    width: calc(100% - 36px);
    // height: 65px;
    overflow: hidden;

    .score-points {
      position: relative;
      flex: 1;
      border-radius: 5px;
      height: 70px;
      background: url(../../assets/image/study/bcg_score1.png) no-repeat;
      background-size: 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      .score-pointsnum {
        line-height: 41.5px;
          font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    font-size: 18px;
        text-align: center;
         color: #ffffff;
        .small {
          font-size: 14px;
          // font-weight: 400;
        }
      }

      .score-pointstitle {
        position: absolute;
        bottom: 5px;
        width: 100%;
        padding-top: 6px;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 17.5px;
        text-align: center;
      }
    }

    .score-hours {
      position: relative;
      border-radius: 5px;
      margin: 0 12.8px;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      width: 24%;
      height: 70px;
      background: url(../../assets/image/study/bcg_score2.png) no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;

      .score-hourstitle {
        position: absolute;
        bottom: 5px;
        width: 100%;
        padding-top: 6px;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #ffffff;
        line-height: 17.5px;
        text-align: center;
      }
    }

    .score-hoursnum {
      border-radius: 5px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 41.5px;
      font-size: 18px;
      text-align: center;
      /deep/span {
        font-size: 14px;
      }
    }

    .score-minutes {
      position: relative;
      // width: 36%;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      flex: 1;
      height: 70px;
      background-color: #ffffff;
      border-radius: 4.5px;

      .score-minutesmsg {
        position: relative;
        float: left;
        min-width: 78px;
        margin-left: 5px;
        height: 100%;
        .score-minutestitle {
          position: absolute;
          bottom: 5px;
          width: 100%;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 17.5px;
          text-align: center;
        }
      }

      .score-pic {
        position: absolute;
        bottom: -7px;
        right: -4px;
        width: 50.5px;
        height: 43px;
      }
    }
  }

  .emptylist {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 135px;

    .emptyimage {
      margin: 0 auto;
      width: 105px;
    }

    .emptytext {
      font-size: 14.5px;
      margin-top: -3px;
      font-weight: 500;
      color: #666666;
      line-height: 21.5px;
    }

    .goclass {
      display: block;
      width: 110.5px;
      height: 35.5px;
      margin: 0 auto;
      margin-top: 26.5px;
      background-color: rgba(21, 183, 221, 1);
      border-radius: 22.5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 35.5px;
    }
  }

  .remind-box {
    margin: 18px auto 0px;
    width: calc(100% - 36px);
    min-height: 75px;
    box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
    display: block;

    .remind-content {
      width: 100%;
      min-height: 75px;
      background: #ffffff;
      border-radius: 4.5px;
      .title {
        margin-bottom: 0px;
      }
      .uni-bg-red {
        position: relative;
        padding: 10.5px 18px;
        min-height: 54.5px;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        margin-bottom: 0 !important;
        // overflow: hidden;
        .swiper-title {
          display: flex;
          align-items: center;
          .swiper-img {
            width: 14.5px;
            height: 14.5px;
            vertical-align: sub;
          }

          .swiper-text {
            margin: 0 12.5px 0 3.5px;
          }
          .swiper-date {
            font-size: 11px;
            font-weight: 400;
          }
        }

        .swiper-content {
          margin-top: 6px;
          width: calc(100% - 60px);
          height: 44px;
          font-size: 11px;
          font-weight: 400;
          line-height: 18px;

          .swiper-course {
          font-weight: 500;
            color: #1cb9de;
          }
        }

        .swiper-flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .swiper-left {
            width: calc(100% - 78px);
          }

          .swiper-right {
            position: relative;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // width: 104px;
            width: 72px;
            text-align: right;
          }
        }

        .remind-bcg {
          position: absolute;
          bottom: 0px;
          right: 22px;
          width: 59.5px;
          height: 49px;
        }

        .remind-bcg2 {
          margin-bottom: 4px;
          width: 50px;
          height: 40px;
        }

        .website {
          position: relative;
          z-index: 9999;
        }
        .remind-bcg3 {
          position: absolute;
          right: -10px;
          bottom: -5px;
          // top: 25px;
          width: 264px;
          height: 66px;
        }
      }
    }

    /deep/.van-swipe {
      overflow: inherit;
    }

    /deep/.van-swipe__indicator {
      background-color: #666666;
    }

    /deep/.van-swipe__indicators {
      bottom: -14px;
    }

    /deep/.van-swipe__indicator:not(:last-child) {
      margin-right: 13px;
    }
  }

  .course-box {
    margin: 0 18px 16.5px;
    position: relative;
    .course-title {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 10px;
      .course-titlel {
        display: inline-block;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 24.5px;
      }

      .course-titler {
        display: inline-block;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #999999;
        line-height: 17.5px;

        image {
          width: 10px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }

    .loading {
      // position: absolute;
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // left: 50%;
      // top: 50%;
      color: #fff;
      // transform: translate(-50%, -50%)
    }
    .course-content {
      margin-bottom: 14px;
      .course-leftbox {
        position: relative;
        margin: 0 10px;
        height: 76px;
        height: 76px;
        overflow: hidden;
        .course-left {
          width: 76px;
          height: 76px;
          border-radius: 5px;
          background-repeat: no-repeat;
          object-fit: cover;
        }

        .course-speed {
          position: absolute;
          // left: 10px;
          bottom: 0px;
          width: 76px;
          height: 18px;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.35) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          border-radius: 0 0 5px 5px;
          overflow: hidden;

          .speed-top {
            margin: 4px 4px 0 0;
            font-size: 9px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #ffffff;
            line-height: 9px;
            text-align: right;
          }
          .speed-bot {
            position: relative;
            bottom: -3px;
          }
        }
      }

      .course-never {
        display: flex;
        align-items: center;
        margin: 0 auto;
        // height: 105px;
        background-color: #ffffff;
        border-radius: 10px 10px 10px 10px;
        padding-top: 12px;
        position: relative;
        .course-neverright {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 74px;
          .course-nevertop {
            // margin-bottom: 9px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
          }

          .course-nevercenter {
            // margin-bottom: 8.5px;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            .course-pic {
              margin-right: 3px;
              width: 9px;
              height: 9px;
            }

            .course-nevertime {
              margin-right: 6px;
              font-size: 11px;
              font-family:  PingFangMedium;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
            }
            .course-nevertime:nth-child(2) {
              margin-right: 0;
            }
          }

          .course-neverbot {
            height: 25px;

            .all-btn-small {
              margin-right: 18px;
              height: 25px;
              line-height: 25px;
              width: 53px;
            }
          }
        }
        .label {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          width: 36px;
          color: #fff;
          text-align: center;
          line-height: 17px;
          height: 17px;
          border-radius: 5px 0 10px 0;
          background: linear-gradient(180deg, #ffbd60 0%, #eaa037 100%);
        }
        .label-blue {
          background: linear-gradient(180deg, #3ac5e5 0%, #64e2ff 100%);
        }
        .openbox {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 0;
          .open {
            width: 42px;
          }
        }
      }
      .course-never-open {
        border-radius: 0 10px 0px 0;
        padding-right: 10px;
        padding-bottom: 10px;
        border-top-left-radius: 10px;
      }

      .course-open {
        display: flex;
        flex-wrap: wrap;
        // height: 132px;
        background: linear-gradient(180deg, #ffffff 0%, #ffecbb 100%);
        border-radius: 0px 0px 13.5px 13.5px;
        position: relative;
        .openbox {
          position: absolute;
          right: 0;
          bottom: 0;
          .open {
            width: 42px;
          }
        }

        .course-openlist {
          margin: 6.5px 0 10px 0;
          width: 33%;

          .course-openicon {
            text-align: center;

            .course-opicon {
              width: 28px;
              height: 28px;
            }
          }

          .course-opentitle {
            font-size: 11px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 16.5px;
            text-align: center;
          }
        }
      }

      .course-nbonus {
        display: flex;
        align-items: center;
        height: 107px;
        background-color: #ffffff;
        border-radius: 0px 0px 13.5px 13.5px;

        .course-nbonusright {
          .course-nbonustop {
            margin-bottom: 4px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
          }

          .course-nbonuscenter {
            margin-bottom: 20px;

            .course-nbonustime {
              margin-right: 21.5px;
              font-size: 11px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #999999;
              line-height: 12.5px;
            }
          }

          .course-nbonusbot {
            font-size: 11px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 12.5px;
          }
        }
      }
    }
  }

  .live-box {
    margin: 0 18px 16.5px;

    .live-content {
      white-space: nowrap;
      padding: 9px 0 0;
      overflow: auto;

      .live-scroll {
        white-space: nowrap;

        .live-list {
          display: inline-block;
          width: 138.5px;
          margin-right: 12.5px;

          .live-image {
            position: relative;
            margin-bottom: 11px;
            width: 138.5px;
            height: 169px;
            // background: url(../../assets/image/datas/banner_live.jpg) no-repeat;
            background-size: 100%;
            border-radius: 11.5px;

            .live-living {
              width: 50.5px;
              height: 22px;
            }

            .live-wave {
              position: absolute;
              top: 5.5px;
              right: 13.5px;
              width: 12px;
              height: 11px;
            }

            .live-play {
              position: absolute;
              top: 68px;
              right: 53px;
              width: 33px;
              height: 33px;
            }
          }

          .live-txet {
            margin-bottom: 5.5px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 19px;
            white-space: normal;
            text-overflow: ellipsis;
          }

          .live-date {
            .live-time {
              margin-right: 18px;
              font-size: 11px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #333333;
              line-height: 16.5px;
            }
          }
        }
      }
    }
  }
  /deep/.van-popup {
    background-color: transparent;
  }
  .popup-content {
    background: #ffffff;
    width: 285.5px;
    border-radius: 10px;
    padding: 18px 10px 0;
    position: relative;

    .popup-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cover-img {
        width: 135px;
        height: 81px;
        border-radius: 9px;
      }

      .title {
        margin: 0 auto;
        margin-top: 21.5px;
        font-size: 22px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 33px;
      }

      .title-noimg {
        font-size: 20px;
        line-height: 30px;
      }

      .specs {
        margin-top: 7px;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15b7dd;
        line-height: 24.5px;
      }

      .achievement-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .achievement-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0px 18px;

          .achievement-top {
            font-size: 16.5px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 24.5px;
          }

          .achievement-span {
            font-size: 36px;
            font-family: PingFangMedium;
            font-weight: bold;
            line-height: 54.5px;
          }

          .achievement-pass {
            color: #15b7dd;
          }

          .achievement-fail {
            color: #ea3c31;
          }

          .achievement-bottom {
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 19px;
          }

          .achievement-passtips {
            color: #333333;
            font-size: 14.5px;
            line-height: 21.5px;
          }
        }
      }
    }

    .btn-inner {
      display: flex;
      justify-content: space-around;
      border-radius: 32px;

      .div {
        display: inline-block;
        border-radius: 32px;
        padding: 23.5px 10px;
        background: #fff;

        .all-btn-middle {
          width: 110.5px;
          height: 35.5px;
          line-height: 35.5px;
          font-size: 18px;
        }
      }
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 80vw;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}

.Delay {
  .title {
    text-align: center;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
  }
  p {
    margin-top: 10px;
    font-size: 13px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 19.5px;
  }
}
.texts {
  font-size: 16.5px;
  .grey {
    font-size: 10px;
    font-weight: 300;
    font-family: Light;
    color: #999999;
  }
}
</style>
