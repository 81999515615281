<template>
  <div class="menuSwiper-box">
    <!-- 轮播图菜单 -->
      <swiper
        class="swiper"
        ref="mySwiper"
        :options="swiperOption"
        v-if="menuArry.length > 0"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="(item, index) in menuArry"
          :key="index"
        >
          <span>{{item.name}}</span>
        </swiper-slide>
      </swiper>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
    props:{
      // 页面 1首页 2收藏
      pageType: {
        type: Number,
        default: 0,
      },
      // 菜单数组
      menuArry: {
      	type: Array,
      	default: ()=>[]
      },
      // 列表位置
      listCurren: {
        type:Number,
        default: 0
      }
    },
		// 数据对象
		data() {
			return {
        slides:[],
        swiperOption: {
          loop: true,
          slidesPerView: 4,
          // loopedSlides: 15,
          notNextTick: true,
          centeredSlides : true,
          watchSlidesProgress : true,
          on: {
            slideChange:() => {
              this.swiperChange(this.$refs.mySwiper.swiper.realIndex)
            }
          }
        },
      }
		},
		// 事件处理器
		methods: {
      // 滑动时触发
      swiperChange(index) {
        /* 菜单的位置返给列表 */
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {
          this.$emit('setMenuCurrent',index)
        }, 200);
      },
      
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted () {
    },
    watch:{
      // 页面 1首页 2收藏
      pageType: {
        handler: function(val) {
          if ((this.pageType == 1 || this.pageType == 2) && this.menuArry.length <= 4) {
            // 禁止循环
            this.swiperOption.loop = false
          }
        },
        immediate: true,
        deep: true	
      },
      // 列表位置
      listCurren: {
        handler: function(val) {
          // 初始化索引
          this.swiperOption.initialSlide = this.listCurren
        },
        immediate: true,
        deep: true	
      }
    }
	}
</script>

<style lang="less" scoped>
  .menuSwiper-box {
    width: 100vw;
    height: 47px;
    position: relative;
    background: url(../assets/image/icon/menu-bg.png);
    background-size: 340px 75% !important;
    background-position-x: center !important;
    background-repeat: no-repeat !important;
    will-change: transform;
    .swiper-slide {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      line-height: 22.5px;
      position: relative;
      margin-top: 25px;
      will-change: transform;
    }
    /deep/ .swiper-slide-active{
      transform: scale(1.1);
      bottom: 9.5px;
      transition: all 0.2s ease-in 0s;
      font-size: 18px;
      color: #ffffff;
      will-change: transform;
    }
  }
</style>
