<template>
  <div class="container-box">
    <!-- <uni-nav-bar title="模拟考试" fixed=true left-icon="left">
			<div slot="right" @click="toRecord()">
				<uni-icons custom-prefix="iconfont" type="icon-record" size="14"></uni-icons>
			</div>
		</uni-nav-bar> -->
    <van-nav-bar
      title="模拟考试"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="toStudy()"
      @click-right="toRecord()"
    >
      <!-- <template #right v-if="detailList.appDesc">
        <van-icon class-prefix="iconfont" name="record" size="14" />
      </template> -->
    </van-nav-bar>
    <!-- 模考简介 -->
    <div class="openbox" ref="openbox" v-if="detailList.appDesc">
      <TextOverflow :type=2 :height="105" :text="detailList.appDesc" ref="TextOver" />
    </div>

    <div class="column-box" ref="columnbox" v-if="examList.length != 0">
      <div class="column-right">
        <img
          class="column-icon"
          src="@/assets/image/examination/icon-exam.png"
        />
        <div class="column-content">
          <div class="column-title">常见问题</div>
          <div class="column-span">例如：模拟试题谁可以做？</div>
        </div>
      </div>
      <div class="column-left" @click="toPageUrl(detailList.configId)">
        <van-icon name="arrow" size="20" color="#999" />
      </div>
    </div>

    <div class="problemList" ref="problemList">
      <van-list
        v-model="newLoading"
        :finished="finished"
        :immediate-check="false"
        @load="getExamList()"
      >
        <div v-for="(item, index) in examList" :key="index" class="main-box">
          <div class="main-left">
            <img class="main-banner" :src="item.appPicture" />
            <!-- <img
          class="main-banner"
          src="../../assets/image/datas/banner_course.jpg"
        /> -->
            <!-- <div v-if="item.isPurchase" class="main-tagout">
          <div class="main-tag">已购买</div>
        </div> -->
          </div>
          <div class="main-right">
            <div class="icon" :class="{'icon-pay' : item.type == 1}">{{item.type == 1 ? '付费' : '免费'}}</div>
            <div class="main-title">{{item.name}}</div>
            <div class="main-msg">
                <div class="main-time">时长：{{ item.time }}分钟丨{{item.isExam==0? '不限次数': item.examNum + '次答题'}}</div>
                <!-- <div :class="item.isFree?'main-free':'main-pay'">
							{{item.isFree?'免费':'￥'+item.price}}
						</div> -->
              </div>
            <div class="main-content">
              
              <div class="main-btn">
                <span class="all-btn-small" @click="onExam(item)">{{
                  item.isExam == 1 && item.examNum == 0
                    ? "解锁考试"
                    : "去模考"
                }}</span>
                <span class="record" @click="toRecord(item.configId)">答题记录</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <noMessage v-if="examList.length == 0" type="1"></noMessage>
    <div v-if="examList.length !=0 && show" class="bottom-box">-已是底部-</div>

    <!-- 考试信息弹窗 -->
    <van-popup v-model="examMessagemodel" round>
      <div class="popup-content">
        <!-- <van-icon name="cross" @click="examMessagemodel = false" size="38" class="close-icon" color="#fff"/> -->
        <!-- <img class="cover-img" src="@/assets/image/study/banner_study.png" /> -->
        <div class="title">{{ popList.name }}</div>
        <div class="specs">
          <span
            >及格分数：<a>{{ popList.passingScore }}分</a></span
          >
          <span class="line"></span>
          <span
            >时长：<a>{{ popList.time }}分钟</a></span
          >
        </div>
        <!-- 提示 -->
        <div class="tips">
          <img class="icon" src="../../assets/image/icon/20220127041502.png" />
          <span class="font"
            >注：考试过程中退出当前页面或关闭华夏云课堂均按交卷处理</span
          >
        </div>
        <!-- 开始考试按钮 -->
        <div class="btn-inner">
          <div class="div" @click="toTest(popList)">
            <span class="all-btn-middle">开始考试</span>
          </div>
        </div>
        <van-icon
          name="close"
          @click="examMessagemodel = false"
          size="38"
          class="close-icon"
          color="#fff"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import TextOverflow from '@/components/TextOverflow.vue'
import { onGoStudy } from '@/assets/js/NativeWebKit.js'
import noMessage from "@/components/nomessage.vue";
export default {
  components: { noMessage, TextOverflow },
  data() {
    return {
      desc: '',
    
      examMessagemodel: false, // 考试信息弹框
      examList: [],
      detailList: [],
      popList: [],
      show: false,
      newCurrent: 1, //当前页数
      newSize: 6, //条数
      newLoading: false,
      finished: false,
      getDataLock: false, //获取列表上锁
    };
  },
  methods: {
    toStudy() {
      if(this.$route.query.fromMenu == '1'){
        this.$onReturn(true)
      } else {
        // this.$router.push("/study");
        onGoStudy()
      }
    },
    toRecord(configId) {
      this.$router.push(
        "/examination/examRecord?courseId=" +
          this.id +
          "&configId=" +
          configId
      );
    },
    toPageUrl(id) {
      this.$router.push("/examination/question?configId=" + id);
    },
    toTest(x) {
      this.$router.push(
        "/examination/testPaper?type=1&userPaperId=" +
          x.paperId +
          "&configId=" +
          x.configId +
          "&duration=" +
          x.duration +
          "&paperId=" +
          x.paperId
      );
      this.examMessagemodel = false;
    },
    // 解锁考试弹窗
    onExam(i) {
      if (i.isExam == 0) {
        this.examMessagemodel = true;
        this.popList = i;
      } else if (i.isExam == 1) {
        if (i.examNum > 0) {
          this.examMessagemodel = true;
          this.popList = i;
        } else {
          let list = [];
          list.push({
            productId: i.productId, // 商品id
            count: 1, // 数量")
            openTimeId: "", // 班级id
            type: 4, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            province: "", // 省
            city: "", // 市
            noGift: 0, // 是否为赠品 0否1是t
            productList: [],
          });
          this.$store.commit("updatelist", list);
          this.$store.commit("updateType", 1);
          this.$store.commit("updateInvoiceId", null);
          this.$store.commit('updateShortInvoiceId',null);
          this.$store.commit("updateAddress", null);
          this.$store.commit("updateCouponList", null);
          this.$router.push("/order/confirmOrder");
        }
      }
    },
    // 手写遮罩弹窗-暂无用
    onShowModal() {
      this.$router.push("/examination/examMask");
    },

    getExamList() {
      if (!!this.getDataLock) {
        return
      }
      // 上锁
      this.getDataLock = true
      this.$ajax({
        url:
          "/hxclass-mobile/exam/mock/config/list?courseId=" +
          this.id +
          "&userId=" +
          this.$store.state.userInfo.userId +
          "&current=" +
          this.newCurrent +
          "&size=" +
          this.newSize,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 解锁
          this.getDataLock = false
          if(!res.data.records.length){return}
          res.data.records.forEach((item) => {
            let a = item.duration.substring(0, 2);
            let b = item.duration.substring(3, 5);
            let time = a * 60 + b * 1;
            item.time = time;
          });
          this.examList = this.examList.concat(res.data.records);
          this.detailList = res.data.records[0];

          var reg = /[/]/g;
          this.desc=this.detailList.appDesc.replace(reg," ");
          this.detailList.appDesc=this.detailList.appDesc.replace(reg,"<br>");

          this.newCurrent++;
          this.newLoading = false;
          // 加载完成
          if (this.examList.length == res.data.total) {
            this.finished = true;
          }
          // 无数据
          if (this.examList.length == 0) {
            this.finished = true;
          }


          this.$nextTick(()=>{
            // 模拟考试简介高度
            let penbox = this.$refs.openbox.clientHeight;
            // 常见问题文字高度
            let columnbox = this.$refs.columnbox.clientHeight;
            // 屏幕高度
            let clientHeight = document.documentElement.clientHeight;
            // 页面高度
            let pageHeight = this.$refs.problemList.clientHeight;
            if (pageHeight > (clientHeight - penbox - columnbox)) {
              this.show = true
            }
          })
          
        }
      });
    },
  },
  created() {
    this.id = this.$route.query.courseId;
    this.getExamList();
  },
};
</script>

<style lang="less" scoped>
/deep/.van-popup {
  overflow-y: initial;
}
/deep/.nomessage {
  margin-top: 100px;
}

.container-box {
  background-color: #f7f7f9;
  // overflow: hidden;

  .openbox{
    margin-top: 9px;
    background: #ffffff;
    border-radius: 22.5px 22.5px 0px 0px;
    padding: 11px 18px 12px;
    /deep/ .overflow-hidden{
      max-height: 105px;
    }
    /deep/ .textOver{
      color: #333333;
      line-height: 21px;
      font-size: 13px;
    }
    /deep/ .link{
      line-height: 21px;
      font-size: 13px;
    }
  }

  .header-box {
    display: flex;
    margin-top: 9px;
    // height: 148px;
    background: #ffffff;
    border-radius: 22.5px 22.5px 0px 0px;

    .header-content {
      display: flex;
      position: relative;

      .header-spanbox {
        .header-span {
          margin-bottom: 28px;
          padding: 11px 18px 12px;
          height: 99px;
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 19px;
        }
        
        .all-font-ellipsis {
          // position: relative;
          -webkit-line-clamp: 6;
          // &::after {
          //   content: "...";
          //   display: inline-block;
          //   position: absolute;
          //   right: 0;
          //   bottom: 0;
          //   width: 84px;
          //   height: 12px;
          //   line-height: 12px;
          //   font-size: 12px;
          //   color: #000;
          //   background-color: #fff;
          // }
        }
      }   

      .header-closebtn {
        position: absolute;
        right: 20px;
        bottom: 4px;
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #0888a6;
        line-height: 19px;
      }
    }
  }

  .header-boxopen {
    margin-top: 9px;
    background: #ffffff;
    border-radius: 22.5px 22.5px 20px 20px;
    max-height: 280px;
    overflow-y: scroll;

    .header-content {
      position: relative;

      .header-spanboxopen::before {
        content: "";
        float: right;
        height: 100%;
        margin-bottom: 58px;
      }

      .header-spanboxopen {
        .header-span {
          display: block;
          padding: 11px 18px 25px;
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 19px;
        }
      }

      .header-closebtn {
        position: absolute;
        right: 20px;
        bottom: 5px;
        font-size: 12.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #0888a6;
        line-height: 19px;
      }
    }
  }

  .column-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 18px;

    .column-right {
      .column-icon {
        display: inline-block;
        width: 34.5px;
        height: 34.5px;
      }

      .column-content {
        display: inline-block;
        margin-left: 5.5px;

        .column-title {
          margin-main: 5.5px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #000000;
          line-height: 21.5px;
        }

        .column-span {
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 16.5px;
        }
      }
    }

    .column-left {
    }
  }

  .main-box {
    display: flex;
    align-items: center;
    margin: 16.5px 18px 27px;
    min-height: 114px;
    background-color: #ffffff;
    box-shadow: 0px 2.5px 4.5px 0.5px rgba(93, 131, 167, 0.05);
    border-radius: 4.5px;

    .main-left {
      position: relative;
      height: 100%;
      .main-banner {
        display: block;
        width: 120px;
        min-height: 120px;
        height: 100%;
        border-radius: 4.5px;
      }

      .main-tagout {
        position: absolute;
        top: 0;
        left: 12.5px;
        width: 31.5px;
        height: 12.5px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        border-radius: 4.5px 0 4.5px 0;
        box-shadow: 0px 2px 2px 0.5px rgba(27, 185, 222, 0.24);

        .main-tag {
          width: 30.5px;
          height: 11.5px;
          background: #1bb9de;
          font-size: 9px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 11px;
          border-radius: 4.5px 0 4.5px 0;
        }
      }
    }

    .main-right {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      position: relative;
      min-height: 94px;
      margin-left: 12.5px;
      flex: 1;
      padding: 18px 0 9px 0;
      .icon{
        position: absolute;
        right: -1px;
        top: -1px;
        width: 32px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        font-size: 10px;
        color: #fff;
        border-radius: 0px 4.5px 0px 4.5px;
        background: linear-gradient(to right, #52C41A, #8CEF5B);
      }
      .icon-pay{
        background: linear-gradient(to right, #FFAD00, #FFD432);
      }
      .main-title {
        margin-bottom: 3px;
        padding-right: 5px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        // width: 177.5px;
        // height: 41px;
        font-size: 13.5px;
        padding-right: 10px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 17.5px;
      }

      .main-msg {
        padding-top: 5px;
        .main-time {
          font-size: 11px;
          height: 12px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #666666;
        }

        .main-free {
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #1e97b4;
          line-height: 19px;
        }

        .main-pay {
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #EC6C01;
          line-height: 12.5px;
        }
      }

      .main-content {
        position: absolute;
        bottom: 7px;
        width: calc(100% - 15px);
        padding-bottom: 3px;
        text-align: right;
        overflow: hidden;
        .main-btn {
          .all-btn-small{
            overflow: hidden;
          }
          .record{
            float: right;
            text-align: center;
            background: #fff;
            border: 1px solid #15B7DD;
            color: #15B7DD;
            margin-left: 10px;
            border-radius: 14px;
            line-height: 26px;
            font-size: 12px;
            width: 80px;
            height: 26px;
          }
        }
      }
    }
  }

  .bottom-box {
    margin-bottom: 50px;
    text-align: center;
    font-size: 10px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #999999;
    line-height: 15px;
  }

  /* 遮罩内容 */
  .uni-popup {
    z-index: 1000;
  }

  .popup-content {
    background: #ffffff;
    width: 290px;
    border-radius: 10px;
    padding: 20px 20px 0;
    position: relative;

    .close-icon {
      position: absolute;
      right: 150px;
      top: 350px;
    }

    .cover-img {
      width: 100%;
      border-radius: 10px;
    }

    .title {
      // width: calc(100% - 50px);
      // margin: 0 auto;
      margin-top: 7px;
      font-size: 22px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }

    .specs {
      margin-top: 18px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      text-align: center;
      a {
        font-weight: 500;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #666666;
        margin: 0 8px;
        border-radius: 1px;
      }
    }

    .tips {
      display: flex;
      align-items: center;
      margin-top: 18px;

      .icon {
        width: 20px;
        margin-right: 6px;
      }

      .font {
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
      }
    }

    .btn-inner {
      border-radius: 32px;
      text-align: center;
      height: 60px;
      .div {
        display: inline-block;
        position: relative;
        top: 20px;
        border-radius: 32px;
        padding: 10px;
        background: #fff;

        .all-btn-middle {
          width: 143px;
          height: 47px;
          line-height: 47px;
        }
      }
    }
  }
}
</style>
