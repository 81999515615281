<template>
  <!-- 进班指南 -->
  <div class="guide">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="进班指南"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <!-- banner -->
    <div class="banner_box">
      <div class="banner">
        <img class="banner_bg" src="../../assets/image/study/guide_banner.png" alt="进班指南">
        <div class="banner_con">孤独症康复教育上岗培训课程</div>
      </div>
    </div>

    <!-- 步骤 -->
    <div class="box">
      <img class="box_img" src="../../assets/image/study/guide_img.png" alt="流程">
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .guide {
    min-height: 100vh;
    background: #A9EFFF;
    .banner_box {
      background-color: #F7F7F9;
      margin-top: -20px;
      .banner {
        display: inline-block;
        position: relative;
        margin: 34px 18px;
        .banner_bg {
          width: 100%;
        }
        .banner_con {
          position: absolute;
          left: 23px;
          bottom: 24px;
          display: inline-block;
          color: #1E97B4;
          border-radius: 3px;
          font-size: 12px;
          background-color: #fff;
          padding: 6px 5px;
        }
      }
    }
    .box {
      border-radius: 10px 10px 0px 0px;
      padding: 14px 18px 0px 20px;
      margin-top: -20px;
      background: #A9EFFF;
      .box_img {
        width: 100%;
      }
    }
  }
</style>
