<template>
  <!-- 机构绑定 -->
  <div class="institutional">
    <div
      :style="{ height: iStatusBarHeight + 'px'}"
      style="position: fixed;left: 0;right: 0;top: 0;background: #FFFFFF;z-index: 10;"
    ></div>
    <div :style="{ height: iStatusBarHeight + 'px'}"></div>
    <div
      class="nav"
      :style="{top: iStatusBarHeight + 'px'}"
      style="position: fixed;left: 0;right: 0;z-index: 10;"
    >
      <img @click="$onReturn($route.query.fromMenu == '1')" src="@/assets/image/blackLeft.png" mode="widthFix" />
      <div class="inputdiv" v-show="searchStatus">
        <img class="find" src="@/assets/image/icon/20220121152703.png" mode="widthFix" />
        <van-field v-model="searchValue" placeholder />
        <van-icon name="clear" size="20" color="#999999" @click="searchValue = ''" />
      </div>
      <span class="title" v-show="!searchStatus">机构绑定</span>
      <span v-show="searchStatus">搜索</span>
      <div class>
        <img
          @click="searchStatus = true"
          v-show="!searchStatus"
          src="@/assets/image/icon/findIcon333.png"
          mode="widthFix"
        />
      </div>
    </div>
    <div class="contentdiv">
      <!-- 绑定方式描述 -->
      <div class="bindType">绑定方式</div>
      <div class="bindType">1、可申请绑定机构，机构同意后可展示</div>
      <div class="bindType">2、通过机构绑定您个人账号</div>
    </div>
    <div class="contentdiv">
      <!-- 机构 -->
      <div class="institutionalMessage">
        <div class="logo">
          <img src="../../assets/image/knowledge/banner_autismSpeaks.png" mode="aspectFill" />
        </div>
        <div class="titleAddress">
          <span class="title all-font-ellipsis">机构名称机构名称机构名称机构名称机构名称</span>
          <div class="addressMessage">
            <img src="@/assets/image/icon/20220222041501.png" mode="widthFix" />
            <span class="address all-font-ellipsis">北京市朝阳区怡和阳光大厦北京市朝阳区怡和阳光大厦</span>
          </div>
        </div>
        <div class="radio">
          <span v-show="radio != 1" @click="radio = 1"></span>
          <img v-show="radio == 1" src="@/assets/image/2022021110551.png" mode="widthFix" />
        </div>
      </div>
    </div>
    <div class="contentdiv">
      <!-- 机构 -->
      <div class="institutionalMessage">
        <div class="logo">
          <img src="../../assets/image/knowledge/banner_autismSpeaks.png" mode="aspectFill" />
        </div>
        <div class="titleAddress">
          <span class="title all-font-ellipsis">机构名称机构名称机构名称机构名称机构名称</span>
          <div class="addressMessage">
            <img src="@/assets/image/icon/20220222041501.png" mode="widthFix" />
            <span class="address all-font-ellipsis">北京市朝阳区怡和阳光大厦北京市朝阳区怡和阳光大厦</span>
          </div>
        </div>
        <div class="radio">
          <span v-show="radio != 2" @click="radio = 2"></span>
          <img v-show="radio == 2" src="@/assets/image/2022021110551.png" mode="widthFix" />
        </div>
      </div>
    </div>
    <div class="binding contentdiv">
      <img class="bindingLogo" src="" mode="aspectFill" />
      <div class="name">欣欣康复机构</div>
      <div class="addressMessage">
        <img src="@/assets/image/icon/20220222041501.png" mode="widthFix" />
        <span class="address all-font-ellipsis">北京市朝阳区怡和阳光大厦</span>
      </div>
      <div class="bindStatus">
        <img src="@/assets/image/icon/bindSuccess.png" mode="widthFix" />
        <span>待绑定</span>
      </div>
      <div class="buts">
        <div class="will_button">
          <span class="all-btn-middle btn1" @click="$refs.popup.close()">取消</span>
          <span class="all-btn-middle btn2" @click="deleteBind()">确认</span>
        </div>
        <div class="btn all-btn-bigCircle" @click="show=true">解 除 绑 定</div>
      </div>
    </div>
    <div class="footer">
      <div class="btn all-btn-bigCircle">申 请 绑 定</div>
    </div>

    <!-- 提示信息 -->
		<van-popup v-model="show">
			<div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="30" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">您是否确认取消机构绑定？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="show=false">取消</span>
          <span class="all-btn-middle btn2" @click="deleteBind()">确认</span>
        </div>
      </div>
		</van-popup>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
			show: false,

      iStatusBarHeight: 0,
      searchValue: "",
      radio: 1,
      searchStatus: false
    };
  },
  // 事件处理器
  methods: {
    // 解除绑定
    deleteBind() {
			this.show = false
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
};
</script>

<style lang="less" scoped>
.van-cell {
  padding: 0;
  background-color: transparent;
  &::after {
    border: 0;
  }
}
.van-popup {
	background-color: transparent;
}
.institutional {
  min-height: 100vh;
  background: #f7f7f9;
  padding: 56px 18px 100px 18px;
  box-sizing: border-box;
  .contentdiv {
    padding: 16px 17px;
    margin-bottom: 8px;
    background: #ffffff;
    border-radius: 8px;
    .institutionalMessage {
      display: flex;
      justify-content: space-between;
      .logo {
        img {
          width: 72px;
          height: 72px;
          border-radius: 50%;
        }
      }
      .titleAddress {
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        flex: 1;
        padding-left: 13px;
        padding-right: 13px;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          -webkit-line-clamp: 1;
        }
        .addressMessage {
          margin-top: 16px;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 16px;
          }
          .address {
            flex: 1;
            margin-left: 1px;
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            -webkit-line-clamp: 1;
          }
        }
      }
      .radio {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          width: 20px;
          height: 20px;
          border: 1px solid #979797;
          box-sizing: border-box;
          border-radius: 50%;
        }
      }
    }
    .bindType {
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
  }
  .binding {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 26px;
    .bindingLogo {
      margin: 0 auto;
      width: 102px;
      height: 102px;
      margin-top: -51px;
      border-radius: 50%;
    }
    .name {
      span-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      margin-top: 12px;
    }
    .addressMessage {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
        height: 16px;
      }
      .address {
        display: inline-block;
        margin-left: 1px;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        -webkit-line-clamp: 1;
      }
    }
    .bindStatus {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
        height: 16px;
      }
      span {
        display: inline-block;
        margin-left: 2px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #15b7dd;
        line-height: 17px;
        -webkit-line-clamp: 1;
      }
    }
    .buts {
      margin-top: 24px;
      height: 40px;
      .will_button {
        display: flex;
        justify-content: space-between;
        .all-btn-middle {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFangMedium;
          font-weight: 500;
        }
        .btn1 {
          color: #666666;
          background: #eeeeee;
          box-shadow: none;
        }
      }
      .btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
      }
      .btn::after {
        border: 0;
      }
    }
  }
}

// 提示信息
.box_popup {
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  box-sizing: border-box;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 22.5px;
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 5px 40px;
  box-sizing: border-box;
  background-color: #f7f7f9;
  .btn {
		width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .btn::after {
    border: 0;
  }
}
.nav {
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  img {
    width: 18px;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: #15b7dd;
    line-height: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
  }
  .inputdiv {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: RGBA(247, 247, 249, 1);
    margin-right: 9.5px;
    margin-left: 5px;
    padding: 4px 8.5px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    img {
      width: 17.5px;
      height: 17.5px;
    }
  }
}
</style>
