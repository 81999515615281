<template>
	<!-- 包裹列表 -->
	<div class="logistics">
		<!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
      title="物流信息"
      left-text=""
      right-text=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
		<!-- 详情 -->
		<div class="box1" v-for="(item,index) in productList" :key="index">
			<img class="box_image" :src="item.productPicture" />
			<div class="box_right">
				<div class="titlePrice">
					<p class="right_title all-font-ellipsis">{{item.productName}}</p>
					<p class="price">¥{{item.payPrice.toFixed(2)}}</p>
				</div>
				<div class="right_info">
					<span></span>
					<span class="info2">x{{item.productCount}}</span>
				</div>
			</div>
		</div>
		
		<!-- 物流 -->
		<div class="box2">
			<div class="box_title" v-if="false">证书物流</div>
			<div class="box_name" v-if="false">孤独症合格证书</div>
			<div class="box_con">
				<logistics :logisticsData="logisticsData" :showType="3"></logistics>
			</div>
		</div>
		
	</div>
</template>

<script>
	import logistics from '@/components/xinyu-logistics/xinyu-logistics.vue'
	export default{
		components: { logistics },
		data(){
			return{
				productList:[],
				logisticsData: null,
				// {
				// 	"logisticsId": 6307,
				// 	"logo": 'https://cdn.kuaidi100.com/images/all/56/yunda.png', //快递logo
				// 	"bookName": "孤独症康复教育人员上岗培训教材（第二版）",
				// 	"bookPicture": "/uploaded/image/20200807/20200807164142_100.png",
				// 	"bookPrice": 35.00,
				// 	"bookCount": 1,
				// 	"name": "李上弟",
				// 	"tellPhone": "18588400538",
				// 	"address": "广东省深圳市龙岗区坂田五和大光勘村3巷13号",
				// 	"logisticsType": null,
				// 	"logisticsNumber": "73176812923694",
				// 	"logisticsCompany": "中通速递",
				// 	"logisticsCode": "zhogntong",
				// 	"logisticsInfo": {
				// 		"message": "ok",
				// 		"nu": "73176812923694",
				// 		"ischeck": "1",
				// 		"com": "zhongtong",
				// 		"status": "200",
				// 		"data": [
				// 			{
				// 				"time": "2022-02-13 12:46:51",
				// 				"conspan": "【深圳市】 快件已在 【龙岗坂田】 签收, 签收人: 中通快递点代收, 如有疑问请电联:（18820482467）, 投诉电话:（0755-28908542）, 您的快递已经妥投。风里来雨里去, 只为客官您满意。上有老下有小, 赏个好评好不好？【请在评价快递员处帮忙点亮五颗星星哦~】",
				// 				"ftime": "2022-02-13 12:46:51",
				// 				"areaCode": null,
				// 				"areaName": null,
				// 				"status": "签收"
				// 			},
				// 			{
				// 				"time": "2022-02-13 09:54:55",
				// 				"conspan": "【深圳市】 【龙岗坂田】 的柏文爽（18820482467） 正在第1次派件, 请保持电话畅通,并耐心等待（95720为中通快递员外呼专属号码，请放心接听）",
				// 				"ftime": "2022-02-13 09:54:55",
				// 				"areaCode": "CN440307013000",
				// 				"areaName": "广东,深圳市,龙岗区,坂田",
				// 				"status": "派件"
				// 			},
				// 			{
				// 				"time": "2022-02-13 03:51:36",
				// 				"conspan": "【深圳市】 快件已经到达 【龙岗坂田】",
				// 				"ftime": "2022-02-13 03:51:36",
				// 				"areaCode": "CN440307013000",
				// 				"areaName": "广东,深圳市,龙岗区,坂田",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-12 20:56:40",
				// 				"conspan": "【深圳市】 快件离开 【深圳中心】 已发往 【龙岗坂田】",
				// 				"ftime": "2022-02-12 20:56:40",
				// 				"areaCode": "CN440300000000",
				// 				"areaName": "广东,深圳市",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-12 20:05:25",
				// 				"conspan": "【深圳市】 快件已经到达 【深圳中心】",
				// 				"ftime": "2022-02-12 20:05:25",
				// 				"areaCode": "CN440300000000",
				// 				"areaName": "广东,深圳市",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-11 02:31:41",
				// 				"conspan": "【保定市】 快件离开 【京南转运中心】 已发往 【深圳中心】",
				// 				"ftime": "2022-02-11 02:31:41",
				// 				"areaCode": "CN130600000000",
				// 				"areaName": "河北,保定市",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-11 02:31:36",
				// 				"conspan": "【保定市】 快件已经到达 【京南转运中心】",
				// 				"ftime": "2022-02-11 02:31:36",
				// 				"areaCode": "CN130681000000",
				// 				"areaName": "河北,保定市,涿州市",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-10 21:36:39",
				// 				"conspan": "【保定市】 快件离开 【京南市场部】 已发往 【京南转运中心】",
				// 				"ftime": "2022-02-10 21:36:39",
				// 				"areaCode": "CN130600000000",
				// 				"areaName": "河北,保定市",
				// 				"status": "在途"
				// 			},
				// 			{
				// 				"time": "2022-02-10 21:36:34",
				// 				"conspan": "【保定市】 【京南市场部】（010-67443268、010-67441084） 的 金尚（13552513375） 已揽收",
				// 				"ftime": "2022-02-10 21:36:34",
				// 				"areaCode": "CN130600000000",
				// 				"areaName": "河北,保定市",
				// 				"status": "揽收"
				// 			}
				// 		],
				// 		"state": "3",
				// 		"condition": "F00"
				// 	},
				// 	"roleType": null,
				// 	"orderId": null,
				// 	"status": 2
				// },
			}
		},
		methods:{
			getParceDetail(){
				this.$ajax({
					url:'/hxclass-mobile/order/parcel/detail',
					method:'get',
					params:{
						orderId: this.$route.query.orderId, // 订单id
						logisticsCode: this.$route.query.logisticsCode, // 物流公司编码
						logisticsNumber: this.$route.query.logisticsNumber, // 物流单号
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.logisticsData = JSON.parse(JSON.stringify(res.data));
						this.logisticsData.address =  JSON.parse(JSON.stringify(res.data.address.provinceName + res.data.address.cityName + res.data.address.districtName + res.data.address.address))
						this.logisticsData.tellPhone = JSON.parse(JSON.stringify(res.data.address.phone))
						this.productList = res.data.productList;
					}
				})
			}
		},
		created(){
			if(this.$route.query.orderId){
				this.getParceDetail();
			}
		}
	}
</script>

<style>
	page{
		background-color: #F7F7F9;
	}
</style>
<style lang="less" scoped>
	* {box-sizing: border-box;}
	.logistics {
		min-height: 100vh;
		background: #F7F7F9;
	}
	
	// 详情
	.box1 {
		display: flex;
		background: #FFFFFF;
		border-radius: 8px;
		margin: 12px 18px;
		padding: 16px 12px;
		.box_image {
			flex-shrink: 0;
			width: 124.5px;
			height: 88px;
			border-radius: 8.5px;
			margin-right: 12px;
		}
		.box_right {
			display: flex;
			flex:1;
			flex-direction: column;
			// justify-content: space-between;
			padding: 10px 0 4px 0;
			.titlePrice{
				display: flex;
				align-items: center;
				.right_title{
					flex: 1;
				}
				.price{
					text-align: right;
					width: 60px;
					font-size: 10px;
					font-family: PingFangMedium;
					font-weight: bold;
					color: #333333;
					line-height: 22px;
				}
			}
			.right_title {
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #333333;
				line-height: 22px;
				-webkit-line-clamp: 1 !important;
			}
			.right_info {
				margin-top: 11px;
				display: flex;
				justify-content: space-between;
				.info1 {
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
					color: #EC6C01;
				}
				.info2 {
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #999999;
				}
			}
		}
	}

	// 物流
	.box2 {
		background: #FFFFFF;
		border-radius: 9px 9px 0 0;
		margin: 2px 18px  12px 18px;
		padding: 16.5px 18px;
		.box_title {
			font-size: 16.5px;
			font-family: PingFangMedium;
			font-weight: bold;
			color: #333333;
			&::before {
				display: inline-block;
				content: '';
				width: 3px;
				height: 13px;
				background: #15B7DD;
				border-radius: 4px;
				margin-right: 3.5px;
			}
		}
		.box_name {
			font-size: 14.5px;
			font-family: PingFangMedium;
			font-weight: 500;
			color: #333333;
			margin-top: 12.5px;
		}
	}
	/deep/.van-nav-bar{
		z-index: 3!important;
	}
</style>
