//资讯列表
export default [
  { //资讯速递
    path: 'information',
    component: () =>
        import ('../views/info/information.vue')
  },
  { // 分享跳转资讯速递
    path: 'shareInformation',
    component: () =>
        import ('../views/info/shareInformation.vue')
  }
]
