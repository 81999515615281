<template>
  <div class="container-box">
    <van-nav-bar
      title="常见问题"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <van-list
      v-model="newLoading"
      :finished="finished"
      :immediate-check="true"
      @load="getList()"
    >
      <!-- <div
        v-for="item in questionList"
        :key="item.problemId"
        :class="item.isOpen ? 'content-boxopen' : 'content-box'"
      > -->
      <div
        v-for="item in questionList"
        :key="item.problemId"
        class="content-boxopen"
      >
        <div class="content-first">
          <div class="question">问：{{ item.name }}</div>
          <!-- <div
            class="answer"
            :class="item.isOpen ? '' : 'all-font-ellipsis'"
            :ref="'content'+ item.problemId"
          > -->
          <div
            class="answer"
            :ref="'content'+ item.problemId"
          >
            答：{{item.context}}
          </div>
          <!-- <div class="header-closebtn" v-if="item.showOpenButton" @click="item.isOpen = !item.isOpen">
            {{ item.isOpen ? "收起" : "展开" }}
            <van-icon
              :name="item.isOpen ? 'arrow-up' : 'arrow-down'"
              size="10"
              color="#0888A6"
            />
          </div> -->
        </div>
        <img
          class="content-icon"
          src="@/assets/image/examination/icon-question.svg"
        />
      </div>
    </van-list>
    <noMessage v-if="questionList.length==0" type=1></noMessage>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  components: { noMessage },
  data() {
    return {
      id: this.id,
      newCurrent: 1, //当前页数
      newSize: 5, //条数
      newLoading: false,
      finished: false,
      questionList: [],
    };
  },
  methods: {
    // showOpenButton(problemId){
    //   // let height = window.getComputedStyle(this.$refs.content).height;
    //   // console.log(this.$refs['content'+problemId]);
    //   return true;
    // },

    getList() {
      this.$ajax({
        url:
          "/hxclass-mobile/exam/mock/config/problem/list?configId=" +
          this.id +
          "&current=" +
          this.newCurrent +
          "&size=" +
          this.newSize,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.records.forEach((element) => {
            element.isOpen = false;
          });
          this.questionList = this.questionList.concat(res.data.records);
          this.newCurrent++;
          this.newLoading = false;
          // 加载完成
          if (this.questionList.length == res.data.total) {
            this.finished = true;
          }
          // 无数据
          if (this.questionList.length == 0) {
            this.finished = true;
          }
        }
      });
    },
  },
  created() {
    this.id = this.$route.query.configId;
    // this.getList();
  },
  updated(){
    this.questionList.forEach(item => {
      let height = this.$refs['content' + item.problemId][0].offsetHeight;
      if(height>=61){
        this.$set(item,'showOpenButton',true);
      }else{
        this.$set(item,'showOpenButton',false);
      }
    }) 
  }
};
</script>

<style>
page {
  background: #f7f7f9;
}
</style>

<style lang="less" scoped>
/deep/.van-nav-bar--fixed {
  z-index: 3;
}
/deep/.nomessage{
  margin-top: 100px;
}
.container-box {
  overflow: hidden;

  .content-box {
    position: relative;
    margin: 12.5px 18px;
    // height: 120px;
    background: #ffffff;
    border-radius: 9px 9px 9px 9px;
    overflow: hidden;
  }

  .content-boxopen {
    position: relative;
    margin: 12.5px 18px;
    background: #ffffff;
    border-radius: 9px 9px 9px 9px;
    overflow: hidden;
  }

  .content-first {
    position: relative;
    margin: 11px 12.5px 18px;

    .question {
      margin-bottom: 8px;
      font-size: 16.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 24.5px;
    }

    .answer {
      font-size: 13.5px;
      font-family: PingFangMedium;
      color: #666666;
      line-height: 20.5px;
    }

    .all-font-ellipsis {
      -webkit-line-clamp: 3;
    }

    .header-closebtn {
      position: absolute;
      right: 0px;
      bottom: -17px;
      font-size: 12.5px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #0888a6;
      line-height: 19px;
      z-index: 2;
    }
  }

  .content-icon {
    position: absolute;
    right: 0;
    bottom: -6px;
    width: 53px;
    height: 53px;
    z-index: 1;
  }
}
</style>
