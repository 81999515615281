<template>
  <div class="container-box">
    <van-nav-bar 
      class="nav-circular" 
      title="更换考试场次" 
      left-arrow 
      :fixed="true" 
      :placeholder="true" 
      @click-left="$onReturn($route.query.fromMenu == '1')" />
    <div class="main-box">
      <div class="main-list">
        <div class="list-title">
          <div class="title-text title-left">当前报名场次</div>
        </div>
        <div class="list-content">
          <div class="content-list">
            <div class="content-top">
              <img class="content-pic" src="@/assets/image/enroll/pic_number.png">
              <div class="title-text content-text">{{examObj.timeName}}场</div>
            </div>
            <div class="title-text content-center">
              考试时间：{{examObj.examDateTime}}
            </div>
            <div class="title-text content-center">
              考试时长：{{manageDuration(examObj.duration)}}
            </div>
          </div>
        </div>
      </div>
      <div 
        class="main-list">
        <div class="list-title">
          <div class="title-text title-left">
            <!-- {{manageDate(examList)}}考试 -->
            以下是可更换的考试场次
          </div>
        </div>
        <div 
          class="list-content list-content1" 
          :class="{'list_pick': examListIndex==index}"
          v-for="(item,index) in examList" 
          :key="index"
          @click="changeExam(index)">
          <div 
            class="content-list content-list1">
            <div class="content-top">
              <img class="content-pic" v-if="examListIndex==index" src="@/assets/image/enroll/pic_number1.png">
              <img class="content-pic" v-else src="@/assets/image/enroll/pic_number.png">
              <div class="title-text content-text">
                {{item.timeName}}场
              </div>
            </div>
            <div class="title-text content-center">
              考试时间：{{item.examDateTime}}
            </div>
            <div class="title-text content-center">
              考试时长：{{manageDuration(item.duration)}}
            </div>
          </div>
        </div>
      </div>
      <div class="box_button">
        <div class="all-btn-big btn" @click="changeSession()">确定更换</div>
      </div>
    </div>
  </div>
</template>

<script>
import { refreshExam } from '@/assets/js/NativeWebKit.js';
  export default {
    data() {
      return {
        signupId: '', //考试报名id
        signupUserId: '', //考试用户id
        examObj: {}, //当前考试
        examList: [], //考试列表
        examListIndex: -1, //选中的场次
      }
    },
    created() {
      this.signupId = this.$route.query.signupId
      this.signupUserId = this.$route.query.signupUserId
      // 当前考试场次
      this.getNowExam();
      // 考试列表
      this.getExamList();
    },
    methods: {
      // 当前考试场次
      getNowExam() {
        this.$ajax({
          url: '/hxclass-mobile/exam/user_signup/time?signupUserId='+this.signupUserId,
          method: "get",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.examObj = res.data[0]
          } else {
            this.$alert(res.message);
          }
        })
      },
      // 考试列表
      getExamList() {
        this.$ajax({
          url: '/hxclass-mobile/exam/time/chanage/list?signupId='+this.signupId+'&signupUserId='+this.signupUserId,
          method: "get",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.examList = res.data
          } else {
            this.$alert(res.message);
          }
        })
      },
      // 选中考试场次
      changeExam(index) {
        this.examListIndex = index
      },
      // 更换考试场次
      changeSession() {
        let timeId = this.examList[this.examListIndex].timeId
        this.$Dialog.confirm({
          title: '提示',
          message: '确定更换考试场次吗？',
        }).then(() => {
          this.$ajax({
            url: '/hxclass-mobile/exam/signup/edit',
            method: "PUT",
            params: {
              signupUserId: this.signupUserId,
              timeId: timeId,
            }
          }).then((res) => {
            // console.log(res)
            if (res.code == 200 && res.success) {
              this.$Toast.success('场次更换成功！');
              refreshExam();
              this.$onReturn(this.$route.query.fromMenu == '1')
            } else {
              this.$alert(res.message);
            }
          })
        })
        .catch(() => {
        });
      },  
      // 考试时长处理
      manageDuration(con) {
        if (!con) {
          return ''
        }
        let time = ''
        let hour = con.split(':')[0]
        let minute = con.split(':')[1]
        let second = con.split(':')[2]
        if (hour !== '00') {
          if (hour.charAt(0) == 0) {
            time += hour.substring(1,2)+'小时'
          } else {
            time += hour+'小时'
          }
        }
        if (minute !== '00') {
          // time += minute+'分'
          if (minute.charAt(0) == 0) {
            time += minute.substring(1,2)+'分钟'
          } else {
            time += minute+'分钟'
          }
        }
        if (second !== '00') {
          time += second+'秒'
        }
        return time
      },
      // 日期处理
      manageDate(date) {
        if (!date[0]) {return}
        let con = date[0].examDate.split('-')
        let year = con[0]
        let mouth = con[1]
        let day = con[2]
        return year + '年' + mouth + '月' + day + '日'
      },
    },
	}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .container-box {
    overflow: hidden;

    .main-box {
      padding: 0px 18px 0px;

      .title-box {
        margin: 16.5px 18px 0;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 24.5px;

        .title-border {
          display: flex;
          display: inline-block;
          margin-right: 4px;
          width: 3px;
          height: 13px;
          border-radius: 4px;
          background-color: #15B7DD;
        }
      }

      .title-text {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 21.5px;
      }
      .title-left {
        font-size: 16px;
      }

      .main-list {
        margin-top: 10px;
        padding: 14px 18px;
        background: #FFFFFF;
        border-radius: 9px;
        overflow: hidden;

        .list-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // height: 40px;

          .title-left {
            // line-height: 40px;

            .title-img {
              margin-right: 5.5px;
              width: 14.5px;
              height: 14.5px;
            }
          }
        }
      }

      .list-content {
        .content-list {
          overflow: hidden;
          // border-top: 1px solid #EEEEEE;
          padding-top: 12px;

          .content-top {
            margin-bottom: 8px;

            .content-pic {
              display: inline-block;
              margin-right: 5.5px;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }

            .content-text {
              display: inline-block;
            }
          }

          .content-center {
            margin-bottom: 2px;
            font-weight: 500;
            &:nth-child(2) {
              margin-bottom: 6px;
            }
          }

          .content-bottom {
            display: flex;
            align-items: center;
            margin: 9px 0 12.5px;

            .content-img {
              margin-left: 12.5px;
              width: 15.5px;
              height: 15.5px;
              vertical-align: middle;
            }

            .content-num {
              margin-left: 2px;
              font-size: 12.5px;
              color: #000000;
              line-height: 19px;
            }

            .fill {
               background-color: #a5a5a5;
            }

          }
        }
        
      }
      .list-content1 {
        border-radius: 8px;
        opacity: 1;
        border: 1px solid #e5e5e5;
        padding: 6px 12px;
        margin-top: 12px;
        &.list_pick {
          background: #15B7DD;
          border: 1px solid #15B7DD;
          .title-text {
            color: #FFFFFF;
          }
        }
        .content-list1 {
          padding-top: 0;
          .content-top {
            margin-bottom: 8px;
          }
        }
      }

      .box_button {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 26px;
        padding: 0 40px;
        .btn {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          background: url('~@/assets/image/icon/2022082401.png');
          background-size: 100%;
          background-position: bottom;
          background-color: #15B7DD;
        }
      }

    }

  }
</style>
