<template>
  <div id="window">
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="申请发票"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div id="content">
      <div class="box">
        <div v-for="item in productList" :key="item.id">
          <div class="titleH4">
            <div class="left">
              <div class="icon"></div>
              <div class="text">{{ item.typeTxt }}</div>
            </div>
          </div>

          <div class="Switch">
            <div
              class="item"
              @click="onAddProductID(subItem.id)"
              v-for="subItem in item.childList"
              :key="subItem.id + 'subID'"
            >
              <div class="checkbox">
                <img
                  class="icon"
                  v-if="orderProductIds.indexOf(subItem.id) != -1"
                  src="@/assets/image/2022021110551.png"
                />
                <img
                  class="icon"
                  v-else
                  src="@/assets/image/2022021110552.png"
                />
              </div>
              <div class="content">
                <div class="image" v-if="subItem.productPicture">
                  <img class="img" :src="subItem.productPicture" />
                </div>
                <div class="data">
                  <div class="data_box">
                    <div class="name">{{ subItem.productName }}</div>
                    <div class="price">
                      ¥
                      {{
                        subItem.payPrice ? subItem.payPrice.toFixed(2) : "0.00"
                      }}
                    </div>
                  </div>
                  <div class="num">x{{ subItem.productCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 运费 -->
        <div v-if="this.form.freightPrice && this.form.freightPrice > 0">
          <div class="titleH4">
            <div class="left">
              <div class="icon"></div>
              <div class="text">运费</div>
            </div>
          </div>

          <div class="Switch">
            <div class="item" @click="haveFreight = !haveFreight">
              <div class="checkbox">
                <img
                  class="icon"
                  v-if="haveFreight"
                  src="@/assets/image/2022021110551.png"
                />
                <img
                  class="icon"
                  v-else
                  src="@/assets/image/2022021110552.png"
                />
              </div>
              <div class="content">
                <div class="data" style="margin-top: 0">
                  <div class="data_box">
                    <div class="name">运费</div>
                    <div class="price">¥ {{ form.freightPrice.toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 订单编号 -->
        <div class="code">订单编号：{{ form.code }}</div>

        <!-- 抬头信息 -->
        <div class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">抬头信息</div>
          </div>
          <div class="right">
            <router-link to="/invoice/InvoiceHeader" tag="div" class="btn">
              <van-icon name="plus" />
              <span>添加抬头</span>
            </router-link>
          </div>
        </div>
        <!-- 抬头列表 -->
        <div class="riseList">
          <div
            class="item"
            :class="zIndex == index ? 'click' : ''"
            @click="zIndex = index"
            v-for="(item, index) in invoiceData"
            :key="item.userInvoiceId"
          >
            <div class="label">
              {{ item.headerType == 1 ? "企业" : "个人" }}
            </div>
            <div class="name">{{ item.invoiceHeader }}</div>
            <div class="mailbox">
              <span>{{ item.dutyParagraph }}</span>
            </div>
            <div class="mailbox" style="margin-top: 5px">
              <span>{{ item.email }}</span>
            </div>
          </div>
          <noMessage
            class="nomessage"
            v-if="!invoiceData.length"
            type="5"
          ></noMessage>
        </div>
      </div>
      <van-popup :round="true" v-model="onPopup">
        <div class="box_popup">
          <div class="popup_title">
            <van-icon name="warning-o" color="#EB3C31" size="26" />
            <span class="title_con">提示信息</span>
          </div>
          <div class="popup_con">
            发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。
          </div>
          <div class="popup_button">
            <span class="all-btn-middle btn2" @click="$router.back()"
              >我知道了</span
            >
          </div>
        </div>
      </van-popup>
      <!-- 底部操作栏 -->
      <div class="btnSave">
        <div v-if="zIndex == null||(orderProductIds.length===0 && !form.freightPrice)" class="all-btn-bigCircle disdale">提交</div>
        <div v-else class="all-btn-bigCircle" @click="onSave()">提交</div>
      </div>
      <div class="heiSave"></div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      onPopup: false,
      zIndex: null,
      invoiceData: [],
      productList: [], // 商品
      form: {},
      orderProductIds: [], //订单产品id
      isSave: true,
      haveFreight: false, //是否开运费
    };
  },
  // 事件处理器
  methods: {
    // 申请
    onSave() {
      return this.$Toast("请联系工作人员线下开具发票，联系电话：400-800-9002转3。");
      if (!this.orderProductIds.length && !this.haveFreight) {
        this.$Toast("请选择需要申请发票的商品");
        return;
      }
      if (this.zIndex == null) {
        this.$Toast("请选择发票抬头");
        return;
      }
      if (this.isSave) {
        this.isSave = false;
        this.$ajax({
          url: "/hxclass-mobile/invoice/apply",
          method: "post",
          params: {
            orderId: this.form.orderId,
            orderProductIds: this.orderProductIds.length
              ? this.orderProductIds
              : [],
            userInvoiceId: this.invoiceData[this.zIndex].userInvoiceId,
            haveFreight: this.haveFreight, //是否开运费
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.onPopup = true;
          } else {
            this.$Toast(res.message);
          }
        });
      } else {
        this.$Toast("您已经提交，请勿重复提交");
      }
    },
    // 选中的开票产品
    onAddProductID(id) {
      // console.log(id)
      let i = this.orderProductIds.indexOf(id);
      if (i < 0) {
        this.orderProductIds.push(id);
      } else {
        this.orderProductIds.splice(i, 1);
      }
    },
    // 获取商品详情列表
    getInvoiceDetail() {
      this.$ajax({
        url: "/hxclass-mobile/invoice/apply/" + this.$route.query.orderId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data;
          let productType = [
            "课程",
            "图书",
            "教具",
            "模拟考试",
            "电子照片",
            "延期",
            "补考",
            "直播",
            "其他",
          ];
          let map = res.data.productMap;
          for (let item in map) {
            this.productList.push({
              id: item,
              typeTxt: productType[item - 1],
              childList: map[item],
            });
          }
        }
      });
    },
    // 获取发票抬头列表
    getInvoiceList() {
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getInvoiceDetail();
    this.getInvoiceList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/invoice.less";

.box {
  padding: 0 18px;
}
.titleH4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 25px;
  margin-top: 10px;
  .left {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      background-color: #15b7dd;
      display: block;
      width: 3px;
      height: 13px;
      border-radius: 1.5px;
      margin-right: 3.5px;
    }
    .text {
      font-size: 16.5px;
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      font-size: 12px;
      color: #999;
    }
    .btn {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #333;
      .icon {
        margin-right: 2px;
      }
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  overflow: hidden;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
// 底部操作栏
.heiSave {
  height: 50px;
}
.btnSave {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 40px;
  z-index: 9;
  .all-btn-bigCircle {
    width: 100%;
  }
}
.riseList {
  padding-top: 17px;
  .item {
    background-color: #fff;
    position: relative;
    padding: 47px 16px 16px 16px;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    border-radius: 12px;
    margin-bottom: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    &.click {
      border-color: #15b7dd;
      background-image: url("../../assets/image/address/icon2.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 20px;
    }
    .label {
      position: absolute;
      left: 0;
      top: 0;
      width: 56px;
      height: 24px;
      background: #15b7dd;
      box-shadow: 2px 0px 0px 0px rgba(26, 164, 196, 1);
      border-radius: 8px 0px 8px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .name {
      font-size: 16px;
      margin-bottom: 11px;
    }
    .mailbox {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      color: #333;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
.code {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  line-height: 28px;
  margin: 16px 0;
}
.Switch {
  margin-top: 8px;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 8px;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    &:last-child {
      border: none;
      .content {
        border: 0;
      }
    }
    .checkbox {
      .icon {
        height: 20px;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      margin-left: 9px;
      padding: 8px 12px 8px 0;
      border-bottom: 1px solid #eee;
      flex: 1;
      .image {
        .img {
          width: 100px;
          height: 70px;
          background-repeat: no-repeat;
          object-fit: cover;
          border-radius: 8.5px;
        }
      }
      .data {
        width: 100%;
        margin-left: 9px;
        margin-top: 3px;
        .data_box {
          display: flex;
          justify-content: space-between;
          .name {
            display: inline-block;
            flex: 1;
            margin-right: 10px;
            font-size: 13px;
            line-height: 17px;
            font-family: PingFangSC-Medium, PingFangMedium;
            font-weight: 500;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .price {
            display: inline-block;
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
          }
        }
        .num {
          font-weight: 400;
          color: #999;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 12px;
          line-height: 17px;
        }
      }
    }
  }
}
.nomessage {
  margin-top: 30px;
  margin-bottom: 30px;
}
.disdale {
  background-color: #c5c5c5;
}
</style>
