<template>
  <div>
    <div class="col-li" v-for="(item,index) in list" :key="index" @click="getDetail(item.name, item.url)">
      <div class="side-l">
        <div class="flex">
          <img class="icon1" src="@/assets/image/icon/icon-course-01.png" alt="">
          <span class="name">{{item.name}}</span>
          <img class="icon2" v-show="item.materialType == 1" src="@/assets/image/icon/icon-course-02.png" alt="">
        </div>
        <p class="selectName" v-show="item.materialType == 2">{{item.sectionName}}</p>
      </div>
      <van-icon name="arrow" size="14" color="#999" />
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    list: {
			type: Array,
			default: ()=>[]
    },
    // 课程是否拥有
		have: {
			type: Number,
			default: null
		},
  },
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    getDetail(name, url) {
      if(!this.have){
        this.$alert('请先购买该课程');
        return
      }
      this.$router.push({
        path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
      });
    },
  },
}
</script>

<style lang="less" scoped>
.col-li{
  background: #FAFAFA;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .side-l{
    max-width: 85%;
    .flex{
      .icon1{
        margin-right: 5px;
        width: 14px;
        margin-bottom: -2px;
      }
      .icon2{
        margin-left: 5px;
        width: 32px;
        margin-bottom: -3px;
      }
      .name{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
  }
  .selectName{
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
  }
}
</style>
