// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/202201261157.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".homeTabs[data-v-1b24f8c9]{line-height:22.5px;overflow:hidden;padding-top:3.5px;border-radius:11px 0 11px 0}.homeTabs[data-v-1b24f8c9],.homeTabs .item[data-v-1b24f8c9]{display:flex;flex-direction:row;position:relative}.homeTabs .item[data-v-1b24f8c9]{min-width:60px;background:linear-gradient(0deg,rgba(21,183,221,.64),#15b7dd,rgba(21,183,221,.64));color:hsla(0,0%,100%,.9);font-size:14px;justify-content:center;font-family:PingFangMedium;align-items:center;padding:0 20px}.homeTabs .item[data-v-1b24f8c9]:first-child{font-weight:400;border-top-left-radius:9px}.homeTabs .item[data-v-1b24f8c9]:nth-child(2){font-weight:400;border-bottom-right-radius:9px}.column[data-v-1b24f8c9]{width:80px;height:100%;position:absolute;font-family:PingFangMedium;left:0;top:0;background-color:#1e97b4;display:flex;flex-direction:row;justify-content:center;align-items:center;color:#fff}.column[data-v-1b24f8c9]:after,.column[data-v-1b24f8c9]:before{content:\"\";width:22px;display:block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;top:0;right:-21px;background-size:100%;z-index:9;height:100%}.column[data-v-1b24f8c9]:before{left:-21px;transform:rotateY(180deg)}.click span[data-v-1b24f8c9]{font-weight:500;font-size:16px}", ""]);
// Exports
module.exports = exports;
