// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../components/LoopTab/img/tablink.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-f8c812ba] .swiper-slide-active{bottom:7px!important;font-size:16px!important;font-weight:700!important}.collection[data-v-f8c812ba]{max-height:100vh;overflow:hidden}.collection .menu-list[data-v-f8c812ba]{margin-top:12px}.collection .menu-list .menuSwiper-box[data-v-f8c812ba]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.collection .menu-list .menuSwiper-box[data-v-f8c812ba] .swiper-slide{margin-top:13px;color:#333}.collection .list[data-v-f8c812ba]{box-sizing:border-box;background:#fff;padding:18px 0;height:100%;overflow-y:scroll}.collection .list[data-v-f8c812ba]::-webkit-scrollbar{display:none}.collection .listBg[data-v-f8c812ba]{background:transparent}", ""]);
// Exports
module.exports = exports;
