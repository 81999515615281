<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="书课商城"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      z-index="0"
    />
    <div class="content-box">
      <!-- 左侧菜单 -->
      <div class="menuList-box">
        <div
          @click="changeType(index)" 
          class="menuItem" 
          :class="{'menuItem-active' : menuIndex==index}" 
          v-for="(item,index) in categoryList"
          :key="index">
          {{item.name}}
        </div>
      </div>
       <div class="right-box">
        <div class="scroll-div" v-if="categoryList[menuIndex]">
        	<div
            class="scroll-item" 
            :class="{'score-hover' : scoreIndex==index}" 
            @click="changeChildType(index)"
            v-for="(item,index) in categoryList[menuIndex].children"
            :key="index">{{item.name}}</div>
        </div>
        <div class="layout">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedText"
            @load="onLoad"
          >
            <ShopList :shopList="shopList" @getShopCarList="getShopCarList" @addCart="addCart"/>
            <div class="ball-container">
              <!--小球-->
              <transition name="drop" @before-enter="beforeDrop" @enter="dropping" @after-enter="afterDrop">
                <div class="ball" v-show="ball.show">
                  <div class="inner inner-hook">1</div>
                </div>
              </transition>
            </div>
          </van-list>
        </div>
        <ShpCarModel :shopList="shopList" :offShelfItems='offShelfItems' :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
      </div>
    </div>
  </div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue';
import ShopList from '@/components/shopMall/shopList.vue';
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
export default {
  // 可用组件的哈希表
  components: { nTransition,ShopList,ShpCarModel },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      ball: {
        show: false,
        el:null
      },
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
      shopList:[],
      menuIndex:0,
      scoreIndex:0,
      pageNum:1,
      pageSize:10,
      total:0,
      categoryList: [],
      loading: false,
      finished: false,
      shopCar:{
        pageNum:1,
        pageSize:10,
        total:0,
        loading: false,
        finished: false,
      },
      scrollTop: 0,
      handleItem:null, // 当前操作对象
      finishedText:'',
    }
  },
  // 事件处理器
  methods: {
    addCart(event,item){
      this.drop(event.target)
    },
    drop(el) {
      //抛物
      let ball = this.ball;
      if (!ball.show) {
        //当小球显示状态为隐藏时
        ball.show = true; //将这个小球的显示状态设置为true
        ball.el = el; // 将cartControl传过来的对象挂载到ball的el属性上
        return;
      }
    },
    beforeDrop(el) {
      /* 购物车小球动画实现 */
      let ball = this.ball;
      if (ball.show) {
        let rect = ball.el.getBoundingClientRect(); //元素相对于视口的位置
        let x = rect.left - 32; //x初始值  // 小球x方向位移= 小球距离屏幕左侧的距离-外层盒子距离水平的距离
        let y = -(window.innerHeight - rect.top - 22); //获取y
        el.style.display = "";
        el.style.webkitTransform = "translateY(" + y + "px)"; //translateY  // 设置外层盒子，即小球垂直方向的位移
        el.style.transform = "translateY(" + y + "px)";
        let inner = el.getElementsByClassName("inner-hook")[0];
        inner.style.webkitTransform = "translateX(" + x + "px)"; // 设置内层盒子，即小球水平方向的距离
        inner.style.transform = "translateX(" + x + "px)";
      }
    },
    dropping(el, done) {
      /*重置小球数量 样式重置*/
      el.offsetHeight;
      el.style.webkitTransform = "translate3d(20px,-30px,0)"; // 设置小球掉落后最终的位置
      el.style.transform = "translate3d(20px,-30px,0)";
      let inner = el.getElementsByClassName("inner-hook")[0];
      inner.style.webkitTransform = "translate3d(0,0,0)";
      inner.style.transform = "translate3d(0,0,0)";
      el.addEventListener("transitionend", done); // Vue为了知道过渡的完成，必须设置相应的事件监听器。它可以是transitionend或 animationend
    },
    afterDrop(el) {
      /*初始化小球*/
      this.ball.show = false;
      el.style.display = "none"; //完成一次之后就让小球隐藏
    },
    onLoad() {
      if(this.flag){
        if(this.pageNum * this.pageSize >= this.total){
          this.finished = true;
        }else{
          this.pageNum++;
          this.loading = true;
          this.getManageList();
        }
      }
    },
    // 切换分类
    changeType(e){
      if(this.menuIndex == e){
        return false;
      }
      this.flag = false;
      this.finished = false;
      this.menuIndex = e;
      this.shopList = [];
      this.scoreIndex = 0;
      this.pageNum = 1;
      this.total = 0;
      this.finishedText = '';
      this.getManageList();
    },
    // 切换二级分类
    changeChildType(e){
      if(this.scoreIndex == e){
        return false;
      }
      this.flag = false;
      this.finished = false;
      this.scoreIndex = e;
      this.shopList = [];
      this.pageNum = 1;
      this.total = 0;
      this.finishedText = '';
      this.getManageList();
    },
    // 查询左侧分类
    getTabList() {
      this.$ajax({
        url: "/hxclass-mobile/app-mall/category/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.categoryList = res.data;
          this.getManageList();
        }
      });
    },
    // 获取商城列表
    getManageList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/product/list',
        method:'get',
        params:{
          categoryCode:this.categoryList[this.menuIndex].children && this.categoryList[this.menuIndex].children.length?this.categoryList[this.menuIndex].children[this.scoreIndex].code:this.categoryList[this.menuIndex].code,
          name:'',
          page:this.pageNum,
          size:this.pageSize
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.flag = true;
          if(this.pageNum == 1){
            this.$set(this,'shopList',[])
          }
          this.shopList.push(...res.data.records);
          this.$nextTick(() => {
            let contentHeight = document.getElementsByClassName('layout')[0].clientHeight;
            let listHeight = document.getElementsByClassName('van-list')[0].scrollHeight;
            if(contentHeight - this.$store.state.stateHeight > listHeight){
              this.finishedText = '';
            }else{
              this.finishedText = '没有更多了';
            }
          })
          this.total = res.data.total;
          this.loading = false;
          if(this.pageNum * this.pageSize >= this.total){
            this.finished = true;
          }
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 获取购物车列表
    getShopCarList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
    },
    // 页面滚动监听方法
    FunHandleScroll() {
      const scrollTop = document.getElementsByClassName('layout')[0].scrollTop;
      sessionStorage.setItem('scrollTop-shoppingMall',scrollTop)
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.$store.commit('updateopenTimeId',null);
    this.getTabList();
    this.$nextTick(()=>{
      this.FunHandleScroll();
      // 挂载滚动监听
      document.getElementsByClassName('layout')[0].addEventListener("scroll", this.FunHandleScroll);
    })
  },
  activated(){
    let _this = this;
    window.refreshShopCar = function () {
      if(_this.$store.state.userInfo.userId){
        _this.getShopCarList(); // 获取购物车列表
      }else{
        _this.shopCarList = [];
        _this.offShelfItems = [];
      }
    };
    if(this.$store.state.userInfo.userId){
      this.getShopCarList(); // 获取购物车列表
    }else{
      this.shopCarList = [];
      this.offShelfItems = [];
    }
    // 进入页面后赋值滚动位置
    this.$nextTick(()=>{
      document.getElementsByClassName('layout')[0].scrollTop = sessionStorage.getItem('scrollTop-shoppingMall')
    })
  },
  // 销毁滚动条绑定
  destroyScroll() {
    if (document.getElementsByClassName('layout')[0]) {
      document
        .getElementsByClassName('layout')[0]
        .removeEventListener("scroll", this.FunHandleScroll);
    }
    window.removeEventListener('scroll', this.scrollToTop)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {}
}
</script>

<style lang="less" scoped>
  .ball {
    position: fixed;
    // 小球落下来的位置
    left: 32px;
    bottom: 22px;
    // margin-top: 32px;
    // margin-left: 22px;
    z-index: 200;
    transition: all 1s cubic-bezier(0.49, -0.29, 0.75, 0.41); /*贝塞尔曲线*/
  }
  .inner {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #EC6C01;
    transition: all 1s linear;
    text-align: center;
    color: #FFFFFF;
  }
  .cart {
    position: fixed;
    bottom: 22px;
    left: 32px;
    width: 30px;
    height: 30px;
    background-color: #EC6C01;
    color: rgb(255, 255, 255);
  }
  .content-box{
    margin-top: 20px;
    // height: 100vh;
    position: relative;
    .menuList-box{
      position: fixed;
      width: 100px;
      overflow-y: scroll;
      min-height: 400px;
      max-height: 100vh;
      .menuItem{
        text-align: center;
        margin-bottom: 28px;
        height: 33px;
        width: 91.5px;
        font-size: 15px;
        font-family: PingFangMedium;
        // font-weight: 500;
        color: #333333;
        line-height: 33px;
        border: 1px solid rgba(0,0,0,0);
        border-left: none;
      }
      .menuItem-active{
        font-weight: 600;
        background: #FFFFFF;
        box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
        border-radius: 0px 28.5px 28.5px 0px;
        border: 1px solid #FFFFFF;
        border-left: none;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          left: -5px;
          top: 11px;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: #15B3DD;
        }
      }
    }
    .right-box{
      width: calc(100% - 125px);
      position: fixed;
      min-height:100vh;
      // top: 0;
      // bottom: 0px;
      right: 0;
      padding: 8px 14px;
      border-radius: 10px 0px 0px 0px;
      background: #FFFFFF;
      .scroll-div{
        width: 100%;
        overflow-y: scroll;
      	white-space: nowrap;
      	.scroll-item{
      		display: inline-block;
      		text-align: center;
          padding-bottom: 7.5px;
          margin-right: 24px;
          font-size: 13px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 19.5px;
        }
        .scroll-item:last-child{
          margin-right: 0;
        }
        .score-hover{
          font-weight: 500;
          color: #15B7DD;
          position: relative;
        }
        .score-hover::after{
          content: '';
          display: inline-block;
          background: url('../../assets/image/icon/20220218041506.png') no-repeat;
          background-size:100%; 
          width: 5.5px;
          height: 6.5px;
          position: absolute;
          right: -4px;
          bottom: 7px;
        }
      }
      .layout{
        height: 78vh;
        overflow-y: auto;
      }
      // .layout{
      //   margin-top: 15px;
      //   padding-bottom: 25px;
      //   overflow-y: scroll;
      //   max-height: 80vh;
      //   .layout-item{
      //     position: relative;
      //     display: flex;
      //     margin-bottom: 14px;
      //     padding-right: 4px;
      //     margin-right: 2px;
      //     box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
      //     border-radius: 5px;
      //     font-family: PingFangMedium;
      //     .side-right{
      //       margin-left: 14px;
      //     }
      //     .h4{
      //       font-size: 12px;
      //       font-weight: 500;
      //       color: #333333;
      //       line-height: 18px;
      //     }
      //     .tag{
      //       font-size: 10px;
      //       font-weight: 500;
      //       color: #666666;
      //       line-height: 15px;
      //     }
      //     .price{
      //       margin-top: 4px;
      //       font-size: 12px;
      //       font-weight: bold;
      //       color: #EC6C01;
      //       line-height: 14px;
      //       .present{
      //         font-size: 11px;
      //         font-family: PingFangMedium;
      //       }
      //       .original{
      //         margin-left: 8px;
      //         font-size: 12px;
      //         font-weight: 500;
      //         color: #999999;
      //         text-decoration: line-through;
      //       }
      //     }
      //     .add-btn{
      //       position: absolute;
      //       right: 10px;
      //       bottom: 8px;
      //       .icon{
      //         width: 20px;
      //       }
      //       .num{
      //         position: absolute;
      //         font-style: normal;
      //         text-align: center;
      //         width: 14px;
      //         height: 14px;
      //         border-radius: 50%;
      //         top: -6px;
      //         right: -6px;
      //         background: #EC6C01;
      //         font-size: 10px;
      //         font-weight: bold;
      //         color: #FFFFFF;
      //         line-height: 14px;
      //       }
      //       // text{
      //       //   display: inline-block;
      //       //   color: #FFFFFF;
      //       //   font-weight: bold;
      //       //   border-radius: 50%;
      //       //   background: #15B7DD;
      //       //   box-shadow: 0px 1.5px 1.5px 1px rgba(93,131,167,0.2);
      //       //   width: 20px;
      //       //   height: 20px;
      //       //   text-align: center;
      //       //   line-height: 20px;
      //       // }
      //     }
      //   }
      // }
      ::-webkit-scrollbar {
          display: none;
      }
      // .layout-one{
      //   .layout-item{
      //     margin-left: 25px;
      //     padding: 12px 14px 12px 0;
      //     .side-left{
      //       width: 45px;
      //       height: 68.5px;
      //       .cover-img{
      //         position: relative;
      //         right: 25px;
      //         border-radius: 5px;
      //         width: 70px;
      //         height: 70px;
      //       }
      //     }
          
      //   }
      // }
      // .layout-two{
      //   .layout-item{
      //     display: flex;
      //     flex-wrap: wrap;
      //     align-content: space-between;
      //     .cover-img{
      //       width: 70px;
      //       height: 85px;
      //       border-radius: 4px;
      //     }
      //     .side-right{
      //       flex: 1;
      //       padding: 7px 0;
      //     }
      //   }
      // }
    }
  }
  
  /deep/ .van-checkbox__icon .van-icon{
    border: 1px solid #15B3DD;
  }
  
  // &.pick {
  //   background: #15B7DD;
  //   box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  //   .up{
  //     .left,.right{
  //       color: #FFFFFF
  //     }
  //   }
  //   .center,.down,.num{
  //     color: #FFFFFF !important;
  //   }
  // }
</style>
