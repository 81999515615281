// 提示语判断早中晚
export function FunTimePrompt(){
  const now = new Date(),hour = now.getHours();
  let text = ''
  if(hour < 6){
    text = '凌晨好'
  }else if (hour < 9){
    text = '早上好'
  }else if (hour < 12){
    text = '上午好'
  }else if (hour < 14){
    text = '中午好'
  }else if (hour < 17){
    text = '下午好'
  }else if (hour < 19){
    text = '傍晚好'
  }else if (hour < 22){
    text = '晚上好'
  }else{
    text = '夜里好'
  }
  return text
}

// 金额增加千位符
export function onThousand(e){
  e = Number(e)
  return e.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

//判断当前设备终端（ios/android）
export function FunJudgDevice(){
  const u = navigator.userAgent;
  let type = ''
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if(isAndroid){ // android终端
    type = 'android'
  }else if(isiOS){ // ios终端 
    type = 'ios'
  }else{ // 其他设备
    type = 'else'
  }
  return type
}

// 时间转换 - 秒转时分秒
export function formatSeconds(value) {
  if(!value)return '00:00'
  let result = parseInt(value)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  if(h !== '00') res += `${h}:`;
  res += `${m}:`;
  res += `${s}`;
  return res;
}

//base64转为对象
export function dataURLtoFile(dataurl, filename){
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// 提交资质ios 文件地址转文件格式
export function getFileFromUrl(url, fileName) {
  return new Promise((resolve, reject) => {
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader('Accept', 'image/png');
      xhr.responseType = "blob";
      // 加载时处理
      xhr.onload = () => {
        // 获取返回结果
          blob = xhr.response;
          let file= new File([blob], fileName, { type: 'image/png' });
          // 返回结果
          resolve(file);
      };
      xhr.onerror = (e) => {
          reject(e)
      };
      // 发送
      xhr.send();
  });
}

//获取页面视图高度
export function getClientHeight(){
  var clientHeight=0;
  if(document.body.clientHeight&&document.documentElement.clientHeight)
  {
    var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
  }
  else
  {
    var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
  }
  return clientHeight;
}