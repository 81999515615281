<template>
  <div class="tab">
    <div
      class="tab_item" 
      :class="{'pick': tabIndex==index}"
      v-for="(item, index) in menuArry" 
      :key="index"
      @click="changeTab(index)">{{item.name}}</div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    menuArry: {
			type: Array,
			default: ()=>[]
    },
    // 正在播放视频id
		tabIndex: {
			type: Number,
			default: 0
    },
  },
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    changeTab(index){
      this.$emit('setTab',index)
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
// 切换框
.tab {
  line-height: 47px;
  padding: 0 18px;
  height: 47px;
  font-family:  PingFangMedium;
  // width: 100%;
  overflow-y: scroll;
  white-space: nowrap;
  .tab_item {
    margin-right: 22px;
    display: inline-block;
    font-size: 14px;
    color: #666666;
    line-height: 27px;
    &.pick {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      background-image: url(../assets/image/examination/certificate_icon1.png);
      background-size: 36.5px;
      line-height: 20px;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
