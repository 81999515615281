<template>
  <!-- 云课头条 -->
  <div class="container-box">
    <div class="tops" v-for=" i in listData" :key="i.xuanId">
    	<div class="left" @click="i.audioUrl?goDetails(i.xuanId):null">
    		<div class="title" v-html="i.title"></div>
    		<div class="time" >
    			{{i.audioTime}}
    		</div>
    	</div>
    	<div class="right" v-if="i.audioUrl" @click="onPlay(i)">
    		<div class="playIcon">
				<img v-if="!$store.state.audio.paused && $store.state.audio.src === i.audioUrl && i.xuanId == $store.state.audio.id" class="playIconImg" src="@/assets/image/audio/playIcon.png" />
				<img v-else class="playIconImg" src="@/assets/image/audio/suspendIcon.png" />
			</div>
    		<div class="name">
    			{{i.lecturer}}
    		</div>
    	</div>
      <!-- 无音频 -->
      <div class="right" v-else >
    		<div class="playIcon">
				  <img class="playIconImg" src="@/assets/image/audio/suspendIcon2.png" />
		  	</div>
    		<div class="name">
          {{i.lecturer}}
    		</div>
    	</div>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
    	props: {
    		// 渲染数据
    		listData: {
    			type: Array,
    			default: ()=>[]
    		},
    	},
		// 数据对象
		data() {
			return {
				audioTime: '',
			}
		},
		// 事件处理器
		methods: {
			goDetails(xuanId) {
				if(!this.$store.state.userInfo.userId){
          return this.$router.push('/login/login');
        }
				this.$router.push('/propagandaHeadlines/audioDetails?xuanId=' + xuanId)
			},
			//播放或暂停
			onPlay(item) {
        if(!this.$store.state.userInfo.userId){
          return this.$router.push('/login/login');
        }
				if(this.$store.state.audio.id != item.xuanId){ // 不属于同一个音频
					this.$store.commit('updateAudio',{
						title: item.titleCopy,
						src: item.audioUrl,
						show: true
					})
					this.$store.state.audio.id = item.xuanId
				}else{ // 属于同一个音频
					if(this.$store.state.audio.paused){
						// 暂停中
						if(!this.$store.state.audio.src){
							this.$store.commit('updateAudio',{
								title: item.titleCopy,
								src: item.audioUrl,
								show: true
							})
						}
						this.$store.state.audio.id = item.xuanId
						this.$store.commit('play')
					}else{
						// 播放中
						this.$store.commit('pause')
					}
				}
			},
		},
		// 页面生命周期-页面加载
		onLoad() {},
		// 页面生命周期-页面显示
		onShow() {},
		// 页面生命周期-页面初次渲染完成
		onReady() {},
		// 页面生命周期-页面隐藏
		onHide() {},
		// 页面生命周期-页面卸载
		onUnload() {},
		destroyed() {
			// //卸载不需要的业务和获取播放状态的业务,提高页面性能
			// this.$zaudio.syncStateOff('page-index-get-state');
		},
	}
</script>

<style lang="less" scoped>
  @import "./searchSeo.less";
</style>
