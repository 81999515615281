
<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="模拟考试"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box" style="flex: 1;">
      <!-- 切换框 -->
      <SwichTab v-if="tabList.length" @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />

      <!-- <div class="tabNav" v-if="tabList.length!==0">
        <div 
          class="tabItem" 
          :class="{'pick': tabIndex==index}"
          v-for="(item, index) in tabList"
          :key="item.id"
          @click="tabIndex=index">{{item.name}}</div>
      </div> -->
      <div class="tabContent" :style="{marginTop: tabList.length ? '0px' :   '20px'}">
        <van-list class="layout" v-model="newLoading" :finished="finished" :immediate-check="false" @load="getList">
          <div class="layout-item" @click="goDetail(item)" v-for="item in dataList" :key="item.productId">
            <div class="side-left">
              <img class="cover-img" :src="item.listPhoto" />
            </div>
            <div class="side-right">
              <div>
                <div class="h4 all-font-ellipsis">
                  {{ item.name }}
                </div>
                <div class="tag all-font-ellipsis">
                  {{ item.answerNumber }}
                </div>
              </div>
              <div class="foo">
                <div class="price">
                  <span v-if="!!item.couponPrice">￥</span>
                  <span class="present" v-if="item.couponPrice">{{item.couponPrice.toFixed(2)}}</span>
                  <span class="free" v-else>免费</span>
                  <span class="original" v-if="!!item.original">￥{{ item.originalPrice ? item.originalPrice.toFixed(2) : "0.00"}}</span>
                </div>
              </div>
            </div>
          </div>
          
          <!-- <van-loading
            type="spinner"
            v-if="loading"
            class="loading"
            color="#15B7DD"
          /> -->
          <noMessage v-if="dataList.length==0" type=1></noMessage>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwichTab from "@/components/SwichTab.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage , SwichTab },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      newCurrent: 0, //当前页数
      newSize: 10, //条数
      newLoading: false,
      finished: false,

      loading: false,

      code: '',
      tabIndex: 0,
      tabList: [],
      dataList: [],
    }
  },
  // 事件处理器
  methods: {
    changeTab(e) {
      this.tabIndex = e
    },
    // 页面滚动监听方法
    FunHandleScroll() {
      const scrollTop = document.getElementsByClassName("layout")[0].scrollTop;
      sessionStorage.setItem('scrollTop-mock',scrollTop)
    },
    goDetail(x) {
      this.$router.push({path: "/examination/examDetail?id=" + x.configId});
    },
    getClass(x) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-mobile/app-mall/app/exam-config"
      }).then((res) => {
        if (res.code == 200 && res.success) {
           this.tabList = res.data.children ? res.data.children : [];
           if(x==1){
            this.code = this.tabList.length?this.tabList[0].code:res.data.code;
           }
           this.newCurrent = 0
           this.dataList = []
           this.getList();
        }else{
          this.loading = false
        }
      });
    },
    getList(){
      this.newCurrent++
      this.newLoading = true
      this.loading = true
      this.finished = false
      this.$ajax({
        url: "/hxclass-mobile/app-mall/app/book-product",
        params: {
          code: this.code,
          page: this.newCurrent,
          size: this.newSize,
          type: 4,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.dataList = this.dataList.concat(res.data.records);
          // 加载完成
          if (this.dataList.length >= res.data.total) {
            this.finished = true
          }
          // 无数据
          if (this.dataList.length == 0) {
            this.finished = true
          }
          this.newLoading = false
        }
        this.loading = false
      });
    },
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#f7f7f9')
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getClass(1)
    // 挂载滚动监听
    this.$nextTick(()=>{
      document.getElementsByClassName("layout")[0].addEventListener("scroll", this.FunHandleScroll);
    })
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { 
    document.getElementsByClassName("layout")[0].removeAttribute('style')
    if (document.getElementById("content")) {
      document
        .getElementsByClassName("layout")[0]
        .removeEventListener("scroll", this.FunHandleScroll);
    }
    window.removeEventListener('scroll', this.scrollToTop)
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    tabIndex(newval) {
      this.newCurrent = 1;
      this.dataList = [];
      this.code = this.tabList[newval].code;
      this.getClass(2)
    },
  },
  activated(){
    // 进入页面后赋值滚动位置
    this.$nextTick(()=>{
      document.getElementsByClassName("layout")[0].scrollTop = sessionStorage.getItem('scrollTop-mock')
    })
  }
}
</script>

<style lang="less" scoped>
/deep/.van-list__finished-text{
  margin: auto;
}
/deep/.nomessage{
  margin-top: 100px;
}
.content-box {
  padding: 0 12px 0 18px;
  display: flex;
  flex-direction: column;
  // 切换框
  .tab {
    padding: 0;
  }
  // .tabNav {
  //   // line-height: 47px;
  //   // padding: 0 18px;
  //   // height: 47px;
  //   // font-family:  PingFangMedium;
  //   // // width: 100%;
  //   // overflow-y: scroll;
  //   // white-space: nowrap;
  //   // .tabItem {
  //   //   margin-right: 15px;
  //   //   display: inline-block;
  //   //   font-size: 14px;
  //   //   color: #666666;
  //   //   line-height: 27px;
  //   //   &.pick {
  //   //     font-size: 16px;
  //   //     color: #333333;
  //   //     font-weight: bold;
  //   //     background-image: url(../../assets/image/examination/certificate_icon1.png);
  //   //     background-size: 36.5px;
  //   //     background-repeat: no-repeat;
  //   //     background-position: bottom center;
  //   //   }
  //   //   &:last-child{
  //   //     margin-right: 0;
  //   //   }
  //   // }
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
  .tabContent{
    flex: 1;
    position: relative;
  }
.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
//   height: calc(100vh - 180px);
  overflow-y: scroll;
//   margin-top: 15px;
//   padding: 0 18px;
//   padding-bottom: 25px;
  .layout-item {
    position: relative;
    display: flex;
    margin-bottom: 14px;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px 1px rgba(93,131,167,0.1);
    border-radius: 5px;
    font-family: PingFangMedium;
    margin-left: 25px;
    padding: 12px 14px 12px 14px;
    .side-left {
      width: 60px;
      height: 91px;
      position: relative;
      .cover-img {
        position: absolute;
        right: 3px;
        border-radius: 5px;
        width: 94px;
        height: 94px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
    }
    .side-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 14px;
    }
    .h4 {
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }
    .all-font-ellipsis {
      -webkit-line-clamp: 2;
    }
    .tag {
      margin: 8px 0;
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #15B7DD;
      line-height: 18px;
    }
    .foo {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        font-size: 12px;
        font-weight: 500;
        color: #EC6C01;
        line-height: 14px;
        font-family: PingFangMedium;
        .present {
          font-weight: bold;
          font-size: 14px;
        }
        .free {
          font-size: 14px;
        }
        .original {
          margin-left: 8px;
          font-size: 12px;
          color: #999999;
          font-weight: 400;
          text-decoration: line-through;
          font-family: PingFangMedium;
        }
      }
    }
  }
}
}
.loading {
  position: absolute;
  // height: 150px;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  left: 50%;
  top: 50%;
  // color: #fff;
  transform: translate(-50%, -50%)
}
/deep/ .van-list__finished-text{
  display: block;
  width: 100%;
}
.container-box{
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
