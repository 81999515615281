<template>
	<div class="container-box">

		<!-- 导航栏 -->
		<van-nav-bar
			class="nav-circular"
			title="小节自测"
			:fixed="true"
			:placeholder="true"
			left-arrow
			:right-text="ViewResults?'重新答题':''"
			@click-left="onReturn()"
			@click-right="ViewResults?onReset():null"
		/>
		<div class="main-box">
			<h4 class="sectionName" v-if="sectionName">{{sectionName}}</h4>
			<!-- 占位 -->
			<div class="seat">{{sectionName}}</div>
			<div class="main-list" v-for="(item,index) in list" :key="index">
				<div class="header-box">
					<div class="header-left">
						<span class="header-num">{{index + 1}}</span>
						<span>/{{list.length}}</span>
						<span v-if="item.type == 1">【单项选择题】</span>
						<span v-else-if="item.type == 2">【多项选择题】</span>
						<span v-else-if="item.type == 3">【判断题】</span>
					</div>
				</div>
				<div class="content-box">
					<div class="content-question">
						<div class="question-span">
							<span style="white-space: pre-wrap">{{item.title}}</span>
							<!-- <span class="question-score">(2分)</span> -->
						</div>
						<div class="question-option">
							<div
								class="option-list"
								v-for="(items,indexs) in item.optionList"
								:key="item.libraryId+'-'+indexs"
								@click="onOptionClick(item,items)"
							>
								<!-- 
									默认黑色
									option-default 选中蓝色
									option-wrong 错误红色
									option-correct 正确绿色
								 -->
								<div class="option-left"
									:class="optionClass(item,items)"
								>
									{{ABC[indexs]}}
								</div>
								<div :class="optionQuestionClass(item,items)" class="question-span option-right">
									{{items.name}}
								</div>
							</div>
						</div>
					</div>
					<div class="answer-inner" v-if="ViewResults">
						<img src="@/assets/image/icon/paper.png" alt="">
						答题结果：<span :class="funAnalysis(item.optionList) ? 'TrueSpan' : 'FalseSpan' ">{{funAnalysis(item.optionList) ? '正确' : '错误'}}</span>
						<div class="analysis" v-show="item.answer">
              <span>题目解析：</span>
              <p>{{item.answer}}</p>
            </div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-box">
			<!-- <div class="footer-btn all-btn-bigCircle" v-if="ViewResults && result.isPass != 1" @click="onReset();ViewResults=false">重新答题</div> -->
			<div class="footer-btn all-btn-bigCircle" v-if="ViewResults && result.isPass == 1" @click="goStudy()">继续学习</div>
			<div class="footer-btn all-btn-bigCircle" v-else-if="!ViewResults" @click="onSave()">提交</div>
		</div>
		<!-- 提交弹窗 -->
		<van-popup v-model="submitPopup" round :close-on-click-overlay="false">
			<div class="popup-content">
				<div class="popup-box">
					<div class="title title-noimg">答对<span class="right">{{result.correctNum}}</span>题，答错<span class="error">{{result.incorrectNum}}</span>题。
						<template v-if="result.isPass != 1">正确率需达到{{result.correctRate}}%可解锁下一视频。</template>
						<template v-else>恭喜您解锁下一视频。</template>
					</div>
				</div>
				<div class="btn-inner">
					<div class="div">
						<span class="all-btn-middle" v-if="result.isPass == 1" @click="goStudy()">继续学习</span>
						<span class="all-btn-middle" v-else @click="onReturn(true)">重新学习</span>
					</div>
					<div class="div">
						<span class="all-btn-middle" @click="toSelfTest()">答题情况</span>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import {FunReturnPlay,FunNextVideo,FunBackNextVideo} from '@/assets/js/NativeWebKit.js';
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			sectionName: "",
			ABC: ['A','B','C','D','E','F','G','H','I','J','K','L'],
			submitPopup: false,
			list: [],
			checkedList: {},
			result: {
				isPass: 0
			},
      ViewResults: false,
			
			BooleArry:[], // 用于存放是否作答
			isOrder: 1,
			video: 1,
		}
	},
	// 事件处理器
	methods: {
		// 课程继续学习
    goStudy(){
			if(this.isOrder){
				FunNextVideo()
			} else {
				this.$ajax({
					url: "/hxclass-mobile/course/getNextSectionVod",
					params: {
						courseId: this.$route.query.courseId,
						sectionId: this.$route.query.sectionId,
					},
				}).then((res) => {
					if (res.code == 200 && res.success) {
						FunBackNextVideo({
							title: res.data.sectionName, // 章节名称
							courseId: res.data.courseId, // 课程id
							isFast: res.data.isFast, // 是否能快进 1.能快进，0.不能快进
							isSpeed: res.data.isSpeed, // 是否能快 1.能倍速，0.不能倍速
							currStudyFileId: res.data.fileId, // 当前学习的云点播fileid
							currPcfg: res.data.pcfg, // 云点播的播放器签名
							currStudySeconds: res.data.currSeconds, // 当前学习的视频历史进度秒数
							currStudySectionId: res.data.sectionId, // 当前学习的章节id
							SectionTest: 0, // 是否试看 1/0
							isHaveSelfTest: res.data.isSelfTest, // 是否有小节自测 1/0
							// res.data.passStatus 章节自测状态1.未开始 2.未看完 3.已看完 4.自测通过 5.自测失败
							// res.data.nextSectionId 下一个的章节id
							// (有下一章节id && 自测状态通过) || (有下一章节id && 没有小节自测)
							isNextSectionId: 0, // 是否有下一个章节 1/0
						})
      			FunReturnPlay()
					} else {
						this.$alert(res.message)
					}
				})
			}
    },
    // 手动处理返回
    onReturn(e){
			if(this.video){
				FunReturnPlay(e)
			} else {
				this.$onReturn(this.$route.query.fromMenu == '1')
			}
    },

		// 查看答题情况
		toSelfTest() {
			this.ViewResults = true
			this.submitPopup = false
		},

		// 查询数据
		formData(){
			this.$ajax({
				url: '/hxclass-mobile/library/section/test-list',
				params: {
					courseId: this.$route.query.courseId,
					sectionId: this.$route.query.sectionId,
					// courseId: 99,
					// sectionId: 161
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.list = [
						...res.data.radioList,
						...res.data.checkedList,
						...res.data.switchList
					]

					// 查询自测解析
          let arry = []
          this.list.forEach(item => {
            arry.push(item.libraryId)
          });
          this.getAnalysisList(arry)
				}
			})
		},

		// 查询题目解析
    getAnalysisList(arry){
      this.$ajax({
        url: '/hxclass-mobile/library/getLibraryAnswer',
				method: 'post',
        params: {
          libraryIdList: arry,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          res.data.forEach(item => {
            this.list.forEach(child => {
              if(item.libraryId == child.libraryId){
								child.answer = item.answer
              }
            });
          });
        }
      })
    },

		// 点击选中
		onOptionClick(item,items){
			// 如果是查看答题详情页，则不做选中处理
			if(this.ViewResults){return}
			
			if(item.type == 2){ // 多选
				let arr = []
				item.optionList.forEach(res=>{
					res.value = (res.value==undefined?0:res.value)
					if(res.optionId == items.optionId){
						res.value = items.value==1?0:1
					}
					arr.push(res)
				})
				this.$set(item, 'optionList', arr)
			}else{ // 单选/判断
				item.optionList.forEach(res=>{
					this.$set(res, 'value', 0)
				})
				this.$set(items, 'value', 1)
			}
		},

		// 点击提交
		onSave(){
			let msg = ''
			for(let i=0;i<this.list.length;i++){
				const item = this.list[i]
				if(item.optionList[0].value == undefined){
					// console.log(item)
					msg = '请选择第'+(i+1)+'题'
					break
				}
			}
			if(msg != ''){
				this.$alert(msg)
				return false
			}


			if(this.isOrder){
				this.$ajax({
					url: '/hxclass-mobile/course/change-pass-status',
					method: 'put',
					params: {
						courseId: this.$route.query.courseId,
						sectionId: this.$route.query.sectionId,
						// courseId: 99,
						// sectionId: 161,
						userId: this.$store.state.userInfo.userId,
						libraryList: this.list
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.result = res.data
						sessionStorage.setItem('result',JSON.stringify(res.data))
						this.submitPopup = true
					}else{
						this.$alert(res.message)
					}
				})
			} else {
				// 随机播放课程，正确率自算
        this.result = {
          correctNum:0,
          incorrectNum:0,
          passRate:0,
          isPass:1
        }
        this.BooleArry = []
        this.list.forEach(uItem => {
          let rightArry = []  // 标准答案
          let studentArry = []  // 作答答案
          uItem.optionList.forEach((childItem,key) => {
            if(childItem.isCorrect){
              rightArry.push(key)
            }
            if(childItem.value){
              studentArry.push(key)
            }
          });
          this.funCorrect(rightArry,studentArry)
        });
        // 计算答对数、答错数、通过率
        this.BooleArry.forEach(element => {
          if(element){
            this.result.correctNum = this.result.correctNum + 1
          } else {
            this.result.incorrectNum = this.result.incorrectNum + 1
          }
        });
        this.result.passRate = this.result.correctNum / this.BooleArry.length
				this.submitPopup = true
				sessionStorage.setItem('result',JSON.stringify(res.data))
			}
		},

		funCorrect(a,b){
      // a 标准答案  b 学员答案
      //先判断两个数组长度要一致，如果不一致那不需要判断直接就不对了。
      if (a.length == b.length) {
        //every() 每一个都是true则返回true，如果有一个是false，那么直接返回false；
        let p = a.every((item, index) => {
          //判断的条件为a数组的每一项在b数组内查找不等于-1就代表有，有就为true，所有都是true就返回true
          this.BooleArry.push(b.indexOf(item) !== -1)
        });
      } else {
        //长度不一致也是错误的
        this.BooleArry.push(false)
      }
    },

		// 重置
		onReset(){
			this.ViewResults=false;
			this.list.forEach(item=>{
				item.optionList.forEach(items=>{
					items.value = 0
				})
			})
		},
		// 解析
    funAnalysis(arry){
      let isAnswer = false
      let trueCount = 0  // 正确答案
      let selectCount = 0   // 所选答案
      let answerCount = 0  // 选对的数量
      arry.forEach(element => {
        if(element.isCorrect){ trueCount++ }
        if(element.value){ selectCount++ }
        if(element.isCorrect && element.value){ 
          answerCount++ 
        }
      });
      if((trueCount == answerCount) && (trueCount == selectCount)){
        isAnswer = true
      }
      return isAnswer
    },
    
	},
	// 生命周期-实例创建完成后调用
	created () {
    this.sectionName = decodeURI(this.$route.query.sectionName)
    this.isOrder = this.$route.query.isOrder ? Number(this.$route.query.isOrder) : 1
    this.video = this.$route.query.video ? Number(this.$route.query.video) : 1
		this.formData()
	},
	// 生命周期-实例挂载后调用
	mounted () { },
	// 计算属性监听
	computed: {
		// 当前的选中的
		optionClass(){
			return function(item,items){
				if(items.value == 1){
					if(this.ViewResults){ // 查看答案
						if(items.isCorrect == 1){
							return 'option-correct'
						}else{
							return 'option-wrong'
						}
					}else{ // 答题中
						return 'option-default'
					}
				}else{
					return ''
				}
			}
		},
		// 当前的选中的
		optionQuestionClass(){
			return function(item,items){
				if(items.value == 1){
					if(this.ViewResults){ // 查看答案
						if(items.isCorrect == 1){
							return 'question-correct'
						}else{
							return 'question-wrong'
						}
					}else{ // 答题中
						return 'question-default'
					}
				}else{
					return ''
				}
			}
		}
	},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	.container-box {
		/deep/.van-nav-bar__text {
			color: #666666;
			font-size: 13px;
		}
		.main-box {
			// position: absolute; //绝对定位
			// padding-bottom: 107px;
			// overflow: auto;
			// overflow-y: scroll; //纵向滑动
			// -webkit-overflow-scrolling: touch; //实现快速滚动和回弹的效果
		}

		.sectionName{
			position: fixed;
			background: #f7f7f9;
      width: calc(100% - 36px);
      z-index: 12;
			padding: 12.5px 18px;
		}
		.seat{
			padding: 12.5px 18px;
			opacity: 0;
		}
		.header-box {
			margin: 12.5px 18px;
			height: 16.5px;
			overflow: hidden;

			.header-left {
				float: left;
				font-size: 11px;
				font-family: PingFangMedium;
				font-weight: bold;
				color: #666666;
				line-height: 16.5px;

				.header-num {
					color: #15B7DD;
				}
			}

		}

		.content-box {
			width: 100%;
			background: #FFFFFF;
			border-radius: 9px;
			overflow: hidden;

			.question-span {
				font-size: 14.5px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #333333;
				line-height: 21.5px;

				.question-score {
					color: #666666;
				}
			}

			.question-default {
				color: #1BB9DE;
			}

			.question-correct {
				color: #8CBD0B;
			}

			.question-wrong {
				color: #EA3C31;
			}

			.content-question {
				margin: 16.5px 18px;

				.question-option {
					.option-list {
						margin: 27px 0 5.5px 0;

						.option-left {
							display: inline-block;
							float: left;
							margin-right: 9px;
							width: 18px;
							height: 18px;
							background: #FFFFFF;
							opacity: 1;
							border: .5px solid #999999;
							border-radius: 50% 50%;
							font-size: 12.5px;
							font-family: PingFangMedium;
							font-weight: 400;
							color: #666666;
							text-align: center;
							line-height: 19px;
						}

						.option-default {
							border: .5px solid #1BB9DE;
							color: #1BB9DE;
						}

						.option-correct {
							border: .5px solid #8CBD0B;
							color: #8CBD0B;
						}

						.option-wrong {
							border: .5px solid #EA3C31;
							color: #EA3C31;
						}

						.option-right {
							display: inline-block;
							width: calc(90% - 18px);
						}
					}
				}
			}
			.answer-inner{
				color: #333333;
				border-top: 1px solid #EBEBEB;
				padding: 12px 0;
				margin: 0 18px;
				font-size: 15px;
				img{
					width: 15px;
				}
				.TrueSpan{
					color: #15B7DD;
				}
				.FalseSpan{
					color: #F90000;
				}
				.analysis{
					margin-top: 20px;
					font-size: 14px;
					line-height: 21px;
					color: #444444;
					span{
						display: inline-block;
						margin-bottom: 5px;
						color: @theme;
					}
				}
			}
		}

		.footer-box {
			box-sizing: border-box;
			// position: fixed;
			// bottom: 0;
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;
			height: 107px;
			background-color: #F7F7F9;
			padding: 0 40px;
			.footer-btn {
				width: 100%;
				height: 36px;
				line-height: 36px;
			}
		}

		/* 遮罩内容 */
		.uni-popup {
			z-index: 1000;
		}

		.popup-content {
			background: #FFFFFF;
			width: 269.5px;
			border-radius: 10px;
			padding: 18px 24px;
			// position: relative;
			.popup-box {
				display: flex;
				flex-direction: column;
				align-items: center;

				.title {
					margin: 0 auto;
					font-size: 20px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #333333;
					line-height: 30px;
					.right {
						color: #8CBD0B;
					}
					.error {
						color: #EA3C31;
					}
				}

				.title-noimg {
					font-size: 20px;
					line-height: 30px;
				}
			}

			.btn-inner {
				display: flex;
				justify-content: space-around;
				border-radius: 32px;

				.div {
					display: inline-block;
					border-radius: 32px;
					margin-top: 18px;
					background: #fff;

					.all-btn-middle {
						width: 110.5px;
						height: 35.5px;
						line-height: 35.5px;
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
