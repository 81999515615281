// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/study/bgc_delay1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/image/study/pic_delay.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "*[data-v-03a15db2]{box-sizing:border-box}.container-box .header-box[data-v-03a15db2]{position:relative;width:100%;height:262px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100%;overflow:hidden}.container-box .header-box .header-pic[data-v-03a15db2]{position:relative;margin:86.5px auto 0;width:96.5px;height:96.5px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100%;font-size:14px;font-family:PingFangMedium;font-weight:500;color:#fff;text-align:center;line-height:121.5px}.container-box .header-box .header-image[data-v-03a15db2]{position:absolute;left:0;bottom:0;width:100%;height:39px}.container-box .head .title[data-v-03a15db2]{text-align:center;font-size:16px;font-family:PingFang SC-Regular,PingFang SC;font-weight:400;color:#666;line-height:24px;margin:28px auto 20px}.container-box .head .name[data-v-03a15db2]{font-size:20px;font-family:PingFang SC-Medium,PingFang SC;font-weight:500;color:#333;line-height:23px;text-align:center}.container-box .head img[data-v-03a15db2]{display:block;margin:0 auto}.container-box .main-box[data-v-03a15db2]{position:relative;padding:34px 10px;background:#fff;border-radius:0 0 10px 10px;margin:20px 10px;margin-top:62px}.container-box .main-box .tab[data-v-03a15db2]{position:absolute;top:-42px;left:0;height:42px;width:100%;z-index:0;display:flex}.container-box .main-box .tab .item[data-v-03a15db2]{width:50%;display:flex;align-items:center;text-align:center;justify-content:center;background:#f0f0f0;border-radius:10px 10px 0 0}.container-box .main-box .tab .item img[data-v-03a15db2]{width:70px}.container-box .main-box .tab .active[data-v-03a15db2]{background:#fff}.container-box .main-box .flow[data-v-03a15db2]{width:100%}", ""]);
// Exports
module.exports = exports;
