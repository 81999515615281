<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="支付方式"
      left-span=""
      right-span=""
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="onPopupShow = true"
    />
    <div class="content-price">
      ￥
      {{
        $route.query.payPrice
          ? Number($route.query.payPrice).toFixed(2)
          : "0.0"
      }}
    </div>
    <div class="content-wrap">
      <template v-if="tipsShow">
        <!-- 微信支付 -->
        <div class="bg line-box" @click="payIndex = 1">
          <div class="select-icon"><span v-show="payIndex == 1"></span></div>
          <div class="pay-icon">
            <span>微信</span>
            <img class="icon" src="@/assets/image/icon/20220211041507.png" />
          </div>
        </div>
        <!-- 支付宝 -->
        <div class="bg line-box" @click="payIndex = 2">
          <div class="select-icon"><span v-show="payIndex == 2"></span></div>
          <div class="pay-icon">
            <span>支付宝</span>
            <img class="icon" src="@/assets/image/icon/20220211041506.png" />
          </div>
        </div>
      </template>
      <template v-else>
        <!-- 支付宝 -->
        <div class="bg line-box" @click="payIndex = 2">
          <div class="select-icon"><span v-show="payIndex == 2"></span></div>
          <div class="pay-icon">
            <span>支付宝</span>
            <img class="icon" src="@/assets/image/icon/20220211041506.png" />
          </div>
        </div>
        <!-- 微信支付 -->
        <div class="bg line-box" @click="payIndex = 1">
          <div class="select-icon"><span v-show="payIndex == 1"></span></div>
          <div class="pay-icon">
            <span>微信</span>
            <img class="icon" src="@/assets/image/icon/20220211041507.png" />
          </div>
        </div>
      </template>
      <!-- 对公转账 -->
      <div class="bg line-box" @click="payIndex = 3">
        <div class="select-icon"><span v-show="payIndex == 3"></span></div>
        <div class="pay-icon">
          <span>对公转账</span>
          <img class="icon" src="@/assets/image/icon/20220215041515.png" />
        </div>
      </div>
      <div class="pay-tips" v-if="tipsShow && payIndex == 2 && orderType != 1">
        <span
          >支付提示：建议使用微信完成支付。如使用支付宝支付完成后，仍提示继续支付，请联系工作人员（联系方式：400-800-9002）。</span
        >
      </div>

      <!-- 对公转账提示 -->
      <div class="tips" v-show="payIndex == 3">
        <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
        <div class="right">
          <p class="title"><span>请向以下账户转账：</span><span class="copy" @click="copyMsg">复制</span></p>
          <p>*收款户名：华夏出版社有限公司</p>
          <p>*收款银行：中国工商银行北京东四支行</p>
          <p>*银行账号：0200 0041 0900 4621 597</p>
        </div>
      </div>

      <!-- <router-link to="/curriculum/courseDelay?orderType=2&type=2">电子照点我</router-link>
      <router-link to="/curriculum/courseDelay?orderType=3&type=2">补考点我</router-link> -->
    </div>
    <!-- 提交按钮 -->
    <div class="submit-box">
      <span class="all-btn-bigCircle" @click="onCheckProduct">{{
        payIndex != 3 ? "立即支付" : "填写支付信息"
      }}</span>
    </div>
    <van-popup v-model="onPopupShow" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">是否放弃本次支付?</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="hiddenModel">否</span>
          <span class="all-btn-middle btn2" @click="giveUpPay()">是</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { FunJudgDevice } from "@/unit/fun.js";
import { FunPayType, refreshStudyList } from '@/assets/js/NativeWebKit.js';
const DeviceType = FunJudgDevice();
export default {
  // 可用组件的哈希表
  components: {},
  // 数据对象
  data() {
    return {
      back:false,
      payIndex: 2,
      sceneType: "", // 手机型号
      tipsShow:false,
      onPopupShow: false,
      orderId: 0,
      returnUrl:null,//传给后端的返回url
      orderType: 0, //订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
      service:false,  // 是否有客服方式 
    };
  },
  // 生命周期-实例创建完成后调用
	created () {
    // 是否是原生确认订单页 - 存储从哪进入支付
    if(this.$route.query.paySignupObj){
      localStorage.setItem('payReturnsign',1)
    }
    
    this.orderId = this.$route.query.orderId
    this.orderType = this.$route.query.orderType
    this.getcustomer();
    this.timeout = setInterval(() => {
      // 补考
      if (this.orderType == 3) {
        this.getMakePayStatus()
      } else {
        this.getPayStatus()
      }
    }, 2500);
  },
  mounted(){
    if(DeviceType == "android"){
      this.tipsShow = true
      this.payIndex = 1
      FunPayType(this.payIndex) // 通知原生使用的支付方式
    } else {
      this.payIndex = 2
      this.tipsShow = false
    }
  },
  // 生命周期-实例销毁离开前调用
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  beforeRouteLeave(to, from, next) {
    if(to.path == '/order/confirmOrder'){
      this.onPopupShow = true;
      next(this.back)
    }else{
      next()
    }
  },
  // 事件处理器
  methods: {
    // 复制收款人信息
    copyMsg() {
      let msg = '收款户名：华夏出版社有限公司 收款银行：中国工商银行北京东四支行 银行账号：0200004109004621597'
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = msg //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$Toast('复制成功');
    },
    hiddenModel(){
      this.onPopupShow = false;
    },
    goBack(){
      // console.log(111)
      // this.onPopupShow = true; 
    },
    giveUpPay(){
      this.back = true;
      this.$router.push({name:'keepAliveMyOrder',params:{keepAlive:true}})
    },
    // 获取当前订单的支付状态
    getPayStatus(){ 
      this.$ajax({ 
          url: '/hxclass-mobile/order/is-pay/' + this.orderId,
      }).then((res) => {
                // console.log(res)
        if (res.code == 200 && res.success) {
          // 刷新学习列表
          refreshStudyList();
          if(this.service){ // 有客服方式
            this.$router.push({
              path: "/curriculum/courseQrode",
              query: {
                type: 2,
                orderId: this.orderId,
              }
            })
          } else {
            this.$router.push({
              path: "/curriculum/courseDelay",
              query: {
                // orderType  4是延期订单   其他都是支付商品订单
                type: this.orderType == 4 ? 1 : 2,
                orderType: this.orderType,
                orderId: this.orderId,
              }
            })
          }
          clearTimeout(this.timeout)
        }
      });
    },
    // 获取支付订单有无专属客服
    // 查询有无客服
    getcustomer() {
      this.$ajax({
        url: "/hxclass-mobile/order/product/getCustomer?orderId=" + this.orderId,
      }).then((res) => {
        // console.log(res)
        if (res.code == "200" && res.success) {
          if (res.data==null) {
            this.service = false
            this.returnUrl=  this.orderType == 1 ? window.location.origin + '/curriculum/courseDelay?type=2&orderId=' + this.orderId + '&orderType=' + this.orderType : undefined
          }else{
            this.service = true
            this.returnUrl=window.location.origin + '/curriculum/courseQrode?type=2&orderId=' + this.orderId

          }
        }
      });
    },
    // 获取补考的支付状态
    getMakePayStatus() {
      // 获取用户报名id
      let signUpUserId
      if(this.$route.query.signupUserId) {
        signUpUserId = this.$route.query.signupUserId;
      }else{
        let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
        signUpUserId = payMakeObj.signupUserId;
      }
       
      this.$ajax({ 
        url: '/hxclass-mobile/exam/signUp/selMakUpOrderIsPay',
        params: {
          orderId: this.orderId,
          signUpUserId: signUpUserId,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$router.push({
            path: "/curriculum/courseDelay",
            query: {
              // orderType  4是延期订单   其他都是支付商品订单
              type: this.orderType == 4 ? 1 : 2,
              orderType: this.orderType,
              orderId: this.orderId,
              signUpUserId: this.signUpUserId, //用户报名id
            }
          })
          clearTimeout(this.timeout)
        }
      });
    },

    // 检查订单商品是否含有不能购买的商品
    onCheckProduct(){ 
      this.$ajax({ 
        url: '/hxclass-mobile/order/order/purchase',
        params: {
          orderId: this.orderId,
        }
      }).then((res) => {
        if (res.data) {
          this.onPay()
        } else {
          this.$toast('该订单含有已下架商品或订单超时已关闭,请重新选择!')
        }
      });
    },

    // 支付
    onPay() {
      if (this.payIndex == 3) {
        return this.$router.push(
          "/order/orderPayConfirm?orderId=" +
            this.orderId +
            "&payPrice=" +
            this.$route.query.payPrice + 
            "&orderType=" +
            this.orderType
        );
      }
      // 检测手机型号
      var u = navigator.userAgent,
      app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        this.sceneType = "Android";
      }
      if (isIOS) {
        this.sceneType = "iOS";
      }

      let params = {}

      if(isIOS || (isAndroid && this.payIndex == 1) || this.orderType != 1){
        params = {
          orderId: this.orderId,
          payMethod: this.payIndex,
          sceneType: this.sceneType
        }
      } else if(isAndroid && this.payIndex == 2){
        params = {
          orderId: this.orderId,
          payMethod: this.payIndex,
          sceneType: this.sceneType,
          returnUrl: this.returnUrl,
          quitUrl: window.location.origin + '/order/orderPayAll?orderType=' + this.orderType +'&orderId=' + this.orderId + '&payPrice=' + this.$route.query.payPrice
        }
      }
      // 唤起微信或支付宝
      this.$ajax({
        url: "/hxclass-mobile/order/h5/pay",
        method: "post",
        params: params
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (this.payIndex == 1) {
            // 微信支付
            let obj = JSON.parse(res.data.body);
            window.location.href = obj.h5_url;
            console.log(obj.h5_url);
          } else if (this.payIndex == 2) {
            // 支付宝支付
            let htmls = res.data.body; //后台返回的from表单
            const div = document.createElement("div");
            div.innerHTML = htmls;
            document.body.appendChild(div);
            document.forms[0].acceptCharset = "utf-8";
            document.forms[0].submit();
            // 唤起支付宝后刷新下页面， 清缓存
            setTimeout(()=>{
              this.$router.go(0)
            },3000)
          }
        } else {
          this.$Toast(res.message)
        }
      });
    },

    // 安卓支付回调
    onReturnUrl(){
      let oUrl = ''
      if (this.orderType == 3) {
        oUrl = window.location.origin + '/curriculum/courseDelay?type='+ (this.orderType == 4 ? 1 : 2) + '&orderId=' + this.orderId + '&orderType=' + this.orderType + '&signUpUserId=' + this.signUpUserId
      } else {
        oUrl = window.location.origin + '/curriculum/courseDelay?type=' + (this.orderType == 4 ? 1 : 2) + '&orderId=' + this.orderId + '&orderType=' + this.orderType
      }
      return oUrl
    }

  },
  // 自定义的侦听器
  watch: {
    payIndex(){
      FunPayType(this.payIndex)
    }
  }
};
</script>

<style lang="less" scoped>
.content-price {
  font-size: 45px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #333333;
  line-height: 53px;
  text-align: center;
  margin: 24px 0 10px 0;
}
.content-wrap {
  padding: 9px 18px;
  .bg {
    border-radius: 4.5px 4.5px 4.5px 4.5px;
    padding: 12.5px;
    background: #ffffff;
  }
  .line-box {
    margin-top: 12.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 36px;
    .select-icon {
      width: 16.5px;
      height: 16.5px;
      border: 1px solid #15b7dd;
      position: relative;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        border-radius: 50%;
        width: 9px;
        height: 9px;
        background: #15b7dd;
      }
    }
    .pay-icon {
      display: flex;
      align-items: center;
      font-size: 16.5px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 19px;
      .icon {
        width: 40px;
        margin-left: 12.5px;
      }
    }
  }
  .pay-tips {
    color: #999999;
    margin-top: 10px;
    line-height: 17px;
    font-size: 12px;
  }
  .tips {
    overflow: hidden;
    margin-top: 16px;
    .tips-icon {
      float: left;
      position: relative;
      top: 3px;
      width: 12px;
    }
    .right {
      width: calc(100% - 16px);
      float: right;
      .title {
        font-size: 13px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy{
          color: #15B7DD;
        }
      }
      p {
        font-size: 11px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}

.submit-box {
  position: fixed;
  box-sizing: border-box;
  text-align: center;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
  height: 85.5px;
  // background: #FFFFFF;
  // box-shadow: 0px -2px 3.5px .5px rgba(0,0,0,0.05);
  .all-btn-bigCircle {
    width: 100%;
  }
  .all-btn-middle {
    width: 267px;
    font-size: 14.5px;
  }
}

// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>