<template>
	<!-- 包裹列表 -->
	<div class="parcelList">
		<!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
      title="包裹列表"
      left-span=""
      right-span=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
		<!-- 列表 -->
		<div class="box">
			<div 
				class="box_item" 
				v-for="(item, index) in parceList" 
				:key="index">
				<div class="item_top">
					<span class="top_type">{{item.kuaidiStatusTxt}}</span>
					<span class="top_num" v-if="item.logisticsNumber">{{item.logisticsName}}：{{item.logisticsNumber}}</span>
					<div class="top_num" v-else><span class="info_con">暂无运单</span></div>
				</div>
				<div class="item_con" v-if="item.productPicture">
					<img id="demo1" v-for="(ite,ind) in item.productPicture.split(',')" :key="ind" class="con_image" :src="ite" />
				</div>
				<div class="item_bottom">
					<span class="bottom_num">共{{item.productCount}}件商品</span>
					<div class="bottom_info" v-if="item.logisticsNumber" @click="$router.push('/order/logistics?orderId='+item.orderId+'&logisticsCode='+item.logisticsCode+'&logisticsNumber='+item.logisticsNumber)">
						<span class="info_con">查看物流</span>
						<van-icon name="arrow" class="info_icon" color="#333333" size="16"/>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return{
				parceList:[]
			}
		},
		methods:{
			getParceList(e){
				this.$ajax({
					url:'/hxclass-mobile/order/parcel',
					method:'get',
					params:{
						orderId:e
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.parceList = res.data;
					}else{
						
					}
				})
			}
		},
		created(){
			if(this.$route.query.orderId){
				this.getParceList(this.$route.query.orderId);
			}
		}
	}
</script>

<style>
	page{
		background-color: #F7F7F9;
	}
</style>
<style lang="less" scoped>
	* {box-sizing: border-box;}
	.parcelList {
		background: #F7F7F9;
		padding-bottom: 60px;
		min-height: 100vh;
	}
	
	.box {
		padding: 0 18px;
		.box_item {
			// width: 339px;
			background: #FFFFFF;
			border-radius: 8px;
			margin-top: 12px;
			padding: 16px 12px;
			.item_top {
				display: flex;
				justify-content: space-between;
				.top_type {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFangMedium;
					font-weight: 500;
					color: #15B7DD;
				}
				.top_num {
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
				}
			}
			.item_con {
				overflow: scroll;
				margin-top: 12px;
				white-space: nowrap;
				.con_image {
					width: 125px;
					height: 88px;
					border-radius: 8.5px;
					margin-right: 12px;
				}
			}
			.item_bottom {
				display: flex;
				justify-content: space-between;
				margin-top: 12px;
				.bottom_num {
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
				}
				.bottom_info {
					display: flex;
					align-items: center;
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}

</style>
