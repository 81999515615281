export default [
  {
    path: '/',
    redirect: 'list'
  },
  // 发票列表
  {
    path: 'list',
    component: () => import('@/views/invoice/list.vue'),
    props: {a:1,b:2}
  },
  // 添加发票
  {
    path: 'InvoiceHeader',
    component: () => import('@/views/invoice/InvoiceHeader.vue')
  },
  // 发票中心
  {
    path: 'InvoiceCenter',
    component: () => import('@/views/invoice/InvoiceCenter.vue')
  },
  // 申请发票
  {
    path: 'apply',
    component: () => import('@/views/invoice/apply.vue')
  },
  // 发票详情
  {
    path: 'details',
    component: () => import('@/views/invoice/details.vue')
  },
  // 换开发票
  {
    path: 'Change', 
    component: () => import('@/views/invoice/Change.vue')
  },
  // 换开发票(改版)
  {
    path: 'replaceVoice', 
    component: () => import('@/views/invoice/replaceVoice.vue')
  },
  // 发票信息-选择是否开具发票
  {
    path: 'invoiceMessage',
    component: () => import('@/views/invoice/invoiceMessage.vue')
  }
]
