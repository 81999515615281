<template>
	<div class="collage">
		<!-- 导航栏 -->
		<van-nav-bar
			class="nav-circular"
			title="亲友拼团"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<!-- <div class="collageHeader">
			<img class="back" src="@/static/image/selected/back.png" />
		</div> -->
		<div class="collageContent">
			<div class="item" v-for="(item,index) in 4" :key="index">
				<div class="up">
					<img class="titleImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng69bdcd4b4baf9adb9885c2bcd7e3967e1cf04bc05d11dca10ce3e164c33abc3f" />
				</div>
				<div class="down">
					<div class="up">
						<div class="left">
							<span class="price"><span class="moneySign">¥</span>1000.00</span>
							<span class="label">团购</span>
							<span class="originalPrice">
								原价：
								<span style="span-decoration:line-through;">¥1324.00</span>
							</span>
						</div>
						<div class="right">
							<span>3人拼团</span>
						</div>
					</div>
					<div class="center">
						<span>孤独症康复教育上岗培训课程</span>
					</div>
					<div class="downButton">
						<div class="button">
							马上拼
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {}
	},
	// 事件处理器
	methods: {},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	.collage{
		.collageHeader{
			position: relative;
			.back{
				position: absolute;
				left: 16px;
				top: -5px;
				z-index: 999;
				width: 32px;
				height: 32px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.collageContent{
			padding: 0 18px;
			margin-top: 9px;
			.item{
				background: #FFFFFF;
				border-radius: 17px;
				border: 1px solid #EEEEEE;
				margin-bottom: 13px;
				.titleImg{
					width: 100%;
				}
				.down{
					padding: 16px 18px 16px 18px;
					.up{
						line-height: 14px;
						display: flex;
						.label{
							display: inline-block;
							width: 25.5px;
							height: 14px;
							padding: 2.5px 5.5px;
							background: #E79F33;
							border-radius: 0px 8px 8px 8px;
							font-size: 11px;
							font-weight: 400;
							color: #FFFFFF;
							text-align: center;
							vertical-align: bottom;
							margin-right: 20px;
						}
						.price{
							font-size: 20px;
							font-weight: 500;
							color: #EC6C01;
							font-family: PingFangMedium;
							line-height: 16px;
							margin-right: 8px;
						}
						.originalPrice{
							font-size: 11px;
							font-weight: 400;
							color: #666666;
							line-height: 16px;
							font-family: PingFangMedium;
							margin-left: 4px;
							vertical-align: top;
							display: inline-block;
						}
						.left{
							flex: 1;
						}
						.right{
							font-size: 11px;
							font-weight: 400;
							color: #333333;
							line-height: 16px;
						}
					}
					.center{
						font-size: 16px;
						font-weight: bold;
						color: #333333;
						line-height: 28px;
						margin-top: 8px;
					}
					.downButton{
						padding-top: 16px;
						.button{
							text-align: center;
							width: 100%;
							height: 34px;
							background: linear-gradient(151deg, rgba(21, 183, 221, 0.65) 0%, #15B7DD 52%, rgba(21, 183, 221, 0.65) 100%);
							border-radius: 17px;
							font-size: 14px;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 34px;
						}
					}
				}
			}
		}
	}
</style>
