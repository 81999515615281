<template>
  <div class="main">
    <div >
      <van-field
        v-model="phone"
        rows="3"
        autosize
        label="手机号集合"
        type="textarea"
        placeholder="请输入手机号，多个手机号用','分割。注：使用英文逗号"
      />
    </div>
    <van-button :loading="loadding" loading-text="生成中..." class="btn" @click="onCheck" type="primary">一键生成</van-button>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      index: 0,
      phone:'',
      phoneList:[],
      loadding:false
    }
  },
  // 事件处理器
  methods: {
    onCheck(){
      if(!this.phone) {
        this.$Toast('请输入手机号');
        return
      }
      var phoneList = this.phone.split(',')
      var isTure = true
      var msg = ''
      for(let i=0; i < phoneList.length; i++){
        if(!this.$regular.phone.reg.test(phoneList[i])){
          isTure = false
          msg = '格式有误：' + phoneList[i]
          break
        }
      }
      if(!isTure){
        this.$Toast(msg);
        return false
      }
      this.index = 0
      this.loadding = true
      this.phoneList = phoneList
      this.onCreate()
    },
    onCreate(){
      this.$ajax({
        url: '/hxclass-mobile/security/one/click/login?mobile=' + this.phoneList[this.index] + '&loginType=4',
        method: "POST",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.index = this.index + 1
          if(this.index < this.phoneList.length){
            this.onCreate()
          } else {
            this.$Toast('导入成功');
            this.phone = ''
            this.phoneList = []
          }
        } else {
          this.$Toast(es.message);
        }
        this.loadding = false
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main{
  text-align: center;
  padding: 20px;
  .btn{
    margin-top: 20px;
  }
}
.load{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;

}
</style>
