<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="成绩查询"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 查询条件输入框 -->
    <div class="content-box">
      <div class="all-white-bg">
        <div class="input-box">
          <van-field 
            class="input" 
            :border='false' 
            :value="columns[columnsIndex]&&columns[columnsIndex].courseName" 
            :readonly='true' 
            @click="addressShow = true" 
            placeholder="请选择考试课程" />
          <van-icon color="#999999" name="arrow" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入姓名" v-model="name" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入身份证号" v-model="idcard" />
        </div>
      </div>
      <div class="select-btn">
        <span class="all-btn-bigCircle" @click="queryData()">立即查询</span>
      </div>
      
      <!-- 查询结果 -->
      <div class="result-box" v-if="isShowData">
        <span class="all-title">查询结果</span>
        <div 
          class="result-item" 
          v-for="(item, index) in dataList" 
          :key="index">
          <span class="name">{{item.courseName}}</span>
          <div 
            class="child-item" 
            v-for="(itemI, indexI) in item.examList" 
            :key="indexI">
            <div class="p">考试时间：{{itemI.examDate}}</div>
            <div class="p">考试成绩：<span class="num">{{itemI.totalScore}}</span></div>
            <span class="type" :class="itemI.examStatus == '已通过'? 'type-pass':'type-fail'">{{itemI.examStatus}}</span>
          </div>
        </div>
        <noMessage class="empty" v-if="dataList.length==0" type=1 message="暂无成绩信息"></noMessage>
      </div>

      <van-popup v-model="addressShow" position="bottom" >
        <van-picker
          title="请选择考试课程"
          show-toolbar
          value-key="courseName"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="addressShow = false"
        />
      </van-popup>

    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {noMessage},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns: [],
      columnsIndex: -1,
      addressShow: false,

      name: '', //姓名 zyn122
      idcard: '', //身份证号 12345678X

      dataList: [], //查询结果
      isShowData: false, //搜索结果
    }
  },
  // 事件处理器
  methods: {
    // 考试课程
    getCourse() {
      this.$ajax({
        url: '/hxclass-mobile/course/exam-course',
        method: "get",
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.columns = res.data
        } else {
          this.$alert(res.message);
        }
      })
    },
    // 立即查询
    queryData() {
      if (this.$store.state.userInfo.isLogin) {
        if (this.columnsIndex == -1) {
          this.$Toast('请选择考试课程进行查询');
          return
        } else if (this.name == '') {
          this.$Toast('请输入姓名进行查询');
          return
        } else if (this.idcard == '') {
          this.$Toast('请输入身份证号进行查询');
          return
        } 
        // else if (!this.$regular.idCard.reg.test  (this.idcard)) {
        //   this.$Toast('请输入正确的身份证号');
        //   return
        // }

        this.$ajax({
          url: '/hxclass-mobile/exam/score/query',
          method: "get",
          params: {
            name: this.name, //姓名
            idcard: this.idcard, //身份证
            courseId: this.columns[this.columnsIndex].  courseId, //课程id
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // this.$Toast.success('查询成功');
            this.dataList = res.data
            this.isShowData = true //搜索结果
            // 数据为空
            if (this.dataList.length == 0) {
              // this.$Toast('暂无数据');
              this.dataList = []
            }
          } else {
            this.dataList = []
            this.$alert(res.message);
          }
        })
      }else{
        this.$router.push('/login/login');
      }
    },
    onConfirm(item, index) {
      // console.log(item, index)
      this.columnsIndex = index
      this.addressShow = false
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 考试课程
    this.getCourse()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  @import "./select.less";
  .input-box{
    display: flex;
    align-items: center;
  }
</style>
