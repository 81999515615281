<template>
  <!-- 全局loading -->
  <div class="appLoading">
    <div class="box">
      <van-loading size="50px" color="#2a2a2a" />
      <!-- color="#0094ff" -->
      <!-- <img class="box_load" src="@/assets/image/stateHint/icon_error.png" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .appLoading {
    position: fixed;
    z-index: 2022;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: 20px;
      background-color: #eaeaea;
      .box_load {
        width: 80px;
        height: 50px;
        animation:fadenum 1s linear infinite;
      }
    }

    @keyframes fadenum{
      100% {
        transform:rotate(360deg);
      }
    }
  }
</style>
