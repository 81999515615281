export default [
  /*
    子模块的路由前面不需要添加‘/’
  */
  {
    path: 'index', // 设置页
    component: () => import('../views/setUp/index.vue')
  },
  {
    path: 'watchToken', // 监听token情况
    component: () => import('../views/setUp/watchToken.vue')
  },
  {
    path: 'account', // 账号安全
    component: () => import('../views/setUp/account.vue')
  },
  {
    path: 'modifyPhone', // 修改手机号
    component: () => import('../views/setUp/modifyPhone.vue')
  },
  {
    path: 'modifyPassword', // 重置密码
    component: () => import('../views/setUp/modifyPassword.vue')
  },
  {
    path: 'verification', // 注销账户-验证码校验
    component: () => import('../views/setUp/verification.vue')
  },
  {
    path: 'verificationConfirm', // 注销账户-确认页
    component: () => import('../views/setUp/verificationConfirm.vue')
  },
  {
    path: 'interest', // 偏好设置
    component: () => import('../views/setUp/interest.vue')
  },
  {
    path: 'aboutUs', // 关于我们
    component: () => import('../views/setUp/aboutUs.vue')
  },
  // 购物车
  {
    path: 'myCopy',
    component: () => import('@/views/home/myCopy.vue')
  },
]
