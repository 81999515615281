<template>
	<!-- 状态提示 -->
	<div class="stateHint">
		<!-- 暂无数据 -->
		<div class="nomessage" v-if="type==1">
			<img src="../assets/image/stateHint/icon_no.png" />
			<div class="no_info">{{message || '空空荡荡的~'}}</div>
		</div>

		<!-- 404 -->
		<div class="nomessage" v-if="type==2">
			<img src="../assets/image/stateHint/icon_error.png" />
			<div class="no_info">{{message || '系统出小差'}}</div>
		</div>

		<!-- 网络崩溃 -->
		<div class="nomessage" v-if="type==3">
			<img src="../assets/image/stateHint/icon_crash.png" />
			<div class="no_info">{{message || '网络崩溃'}}</div>
			<div class="no_button" @click="onRefresh()">刷新</div>
		</div>

		<!-- 暂无学习记录 -->
		<div class="nomessage" v-if="type==4">
			<img src="../assets/image/stateHint/icon_nodata.png" />
			<div class="no_info">{{message || '暂无学习记录~'}}</div>
			<div class="no_button" >
        <router-link style="color:#fff" to="/curriculum/courseList?source=selected">去选课</router-link>
      </div>
		</div>

		<!-- 暂无发票抬头 -->
		<div class="nomessage" v-if="type==5">
			<img src="../assets/image/stateHint/icon_no.png" />
			<div class="no_info">{{message || '还没有发票抬头~'}}</div>
		</div>

		<!-- 暂无证书 -->
		<div class="nomessage" v-if="type==6">
			<img src="../assets/image/stateHint/icon_no.png" />
			<div class="no_info">{{message || '暂无证书信息'}}</div>
		</div>

		<!-- 暂无优惠券 -->
		<div class="nomessage" v-if="type==7">
			<img src="../assets/image/stateHint/noCoupon.png" />
			<div class="no_info">{{message || '暂无优惠券'}}</div>
		</div>
		
		<!-- 暂无作业 -->
		<div class="nomessage" v-if="type==8">
			<img src="../assets/image/task/noHomeWork.png" />
			<div class="no_info">{{message || '暂无作业'}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['message','type'],
		data(){
			return {
				
			}
		},
		methods: {
			onRefresh() {
				this.$router.go(0)
			}
		}
	}
</script>

<style scoped lang="less">
	.stateHint{
		display: flex;
		flex-direction: column;
		text-align: center;
		// 暂无数据
		.nomessage {
			text-align: center;
			img {
				margin: 0 auto;
				width: 113px;
			}
			.no_info {
				margin-top: 9px;
				font-size: 14px;
				font-weight: 400;
				color: #999999;
				line-height: 22px;
			}
			.no_button {
				width: 111px;
				height: 35px;
				border-radius: 23px;
				line-height: 35px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
				background-color: #15B7DD;
				margin: 13px auto 0 auto;
			}
		}
		
	}
</style>
