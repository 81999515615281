<template>
  <!-- 教育课程 -->
  <div class="container-box">
    <template v-if="listType == '1'">
      <van-swipe-cell
        :disabled="listType == '2'"
        v-for="(i, index) in listData"
        :key="index"
      >
        <div class="classItem" @click="goDetail(i.courseId)">
          <div class="classItemBorder" :class="{ classItemFirst: index == 0 }">
            <img :src="i.listPhoto" />
            <div class="itemMessage">
              <span class="title all-font-ellipsis">{{ i.courseName }}</span>
              <template v-if="i.courseCategoryId == 3">
                <div class="tag term all-font-ellipsis lineDownCourse" v-if="setTime(i)">
                  <img class="icon_courseTime" src="@/assets/image/icon/icon_courseTime.png" alt=""><span class="time">{{ setTime(i) }}</span>
                </div>
                <div class="tag term all-font-ellipsis lineDownCourse">
                  <img class="icon_address" src="@/assets/image/icon/icon_address.png" alt=""><span class="address">{{ i.province }} {{ i.city }} | {{ i.district }}</span>
                </div>
              </template>
              <template v-else>
                <div class="labels">
                  <span class="label">上岗时间</span>
                  <span class="label">视频数量：{{ i.classHour }}个</span>
                </div>
                <span class="describe">{{ i.abstracts }}</span>
              </template>
              <div class="priceNum">
                <span class="price">
                  <!-- ￥{{i.couponPrice}} -->
                  <span v-if="i.couponPrice != 0">￥</span>
                  <span class="present" v-if="i.couponPrice">{{
                    i.couponPrice.toFixed(2)
                  }}</span>
                  <span class="free" v-else>免费</span>
                  <!-- <span class="original" v-if="i.originalPrice">￥{{Number.toFixed(i.originalPrice)}}</span> -->
                  <span class="original" v-if="i.originalPrice"
                    >￥{{ i.originalPrice.toFixed(2) }}</span
                  >
                </span>
                <span class="num">{{ i.studyNum }}人已学习</span>
              </div>
            </div>
          </div>
        </div>
        <template #right>
          <div class="all_cancel" @click="cancelOk(i.courseId, index)">
            取消收藏
          </div>
        </template>
      </van-swipe-cell>
    </template>

    <template v-if="listType == '2'">
      <div
        v-for="(i, index) in listData"
        :key="index"
        class="classItem"
        @click="goDetail(i.courseId)"
      >
        <div class="classItemBorder" :class="{ classItemFirst: index == 0 }">
          <img :src="i.picture ? i.picture : i.photo" />
          <div class="itemMessage">
            <span v-html="i.productName" class="title all-font-ellipsis"></span>
            <div class="labels">
              <span class="label">{{ i.categoryName }}</span>
              <span class="label">视频数量：{{ i.classHour }}个</span>
            </div>
            <span class="describe">{{ i.abstracts }}</span>
            <div class="priceNum">
              <span class="price">
                <span v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >￥</span
                >
                <span
                  class="present"
                  v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >{{ i.couponPrice }}</span
                >
                <span class="free" v-else>免费</span>
                <span class="original" v-if="i.original"
                  >￥{{ i.originalPrice.toFixed(2) }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
import { onThousand } from "@/unit/fun.js";
export default {
  // 可用组件的哈希表
  components: {},
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 类型 1收藏 2搜索
    listType: {
      type: String,
      default: "",
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    // 返回时间
    setTime(e) {
      if(!e.openStartTime){
        return false;
      }
      let start = e.openStartTime.substring(0,10).split('-').join('/');
      let end = e.openEndTime.substring(0,10).split('-').join('/');
      return start + ' - ' + end
    },
    goDetail(id) {
      jumpToShopDetail(1,id);
      // this.$router.push({ path: "/curriculum/course", query: { id: id } });
    },
    // 取消收藏
    cancelOk(id, index) {
      this.$emit("cancelOk", id, index);
    },
  },
  // 页面生命周期-页面加载
  onLoad() {},
  // 页面生命周期-页面显示
  onShow() {},
  // 页面生命周期-页面初次渲染完成
  onReady() {},
  // 页面生命周期-页面隐藏
  onHide() {},
  // 页面生命周期-页面卸载
  onUnload() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";
.lineDownCourse{
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666; 
  line-height: 15px;
  .icon_courseTime{
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }
  .icon_address{
    width: 8px;
    height: 10px;
    margin-left: 1px;
    margin-right: 8px;
  }
}
</style>
