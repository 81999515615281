<template>
  <div class="content-box">
    <van-nav-bar
      title="更换手机号"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="single-menu-list">
      <div class="single-menu-item">
        <span class="p side-left-title">输入您需要绑定的新手机号</span>
      </div>

      <!-- <div class='form'>
        <p class='phoneType' @click='showPopup'>{{
          popCode[popIndex].code
        }}</p>
        <img
          src='@/assets/image/login/icon1.png'
          @click='showPopup'
        />
        <van-field v-model="phone" label="" placeholder="请输入手机号" />
      </div>
      <div class='form'>
        <van-field v-model="code" label="" placeholder="请输入验证码" />
        <div @click='onDxCode()'>
          <p class='getCode' v-if='isDxCode' style='color: #00b3c5'
            >获取验证码</p
          >
          <p class='getCode' v-else style='color: #999'
            >重新发送 {{ countdown }}</p
          >
        </div>
      </div> -->

      <div class="single-menu-item">
        <div class="side-left">
          <div class="left_phone">
            <p class="phoneType" @click="showPopup">
              {{ popCode[popIndex].code }}
            </p>
            <img
              class="phoneType_icon"
              src="@/assets/image/login/icon1.png"
              @click="showPopup"
            />
          </div>
          <van-field
            class="input"
            v-model="phone"
            maxlength="11"
            type="tel"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div class="single-menu-item">
        <div class="side-left">
          <van-field
            class="input"
            v-model="code"
            maxlength="6"
            type="tel"
            placeholder="请输入验证码"
          />
        </div>
        <div class="get_code">
          <span v-show="!show">重新发送 {{ count }} 秒</span>
          <span v-show="show" @click="funCode()">获取验证码</span>
        </div>
      </div>
    </div>
    <div class="onSave-foo">
      <span class="all-btn-bigCircle" @click="submit">确认更换</span>
    </div>

    <!-- 图形拖拽校验 -->
    <div class="puzzle-box" v-if="verificationShow">
      <div class="puzzle_content" style="width: 295px; height: 500px">
        <puzzleVerification
          :verificationShow="true"
          blockType="puzzle"
          :onSuccess="handleSuccess"
          :onError="handleError"
          :puzzleImgList="puzzleImgList"
          style="display: block"
        ></puzzleVerification>
      </div>
    </div>

    <!-- 区号选择 -->
    <n-transition
      ref="pop"
      speed="ease-in-out"
      :height="popHeight"
      :maskVal="0.35"
    >
      <div class="pop_box">
        <div class="box_list">
          <div
            class="list_item"
            v-for="(item, index) in popCode"
            :key="index"
            @click="changeCode(index)"
          >
            <p class="item_txt">{{ item.country }}</p>
            <p class="item_txt">{{ item.code }}</p>
          </div>
        </div>
        <div class="box_button" @click="hidePopup()">取消</div>
      </div>
    </n-transition>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
export default {
  // 可用组件的哈希表
  components: { nTransition },
  // 数据对象
  data() {
    return {
      phone: "", //手机号
      code: "", //验证码
      verificationShow: false,
      // 推拽图片列表
      puzzleImgList: [
        require("@/assets/image/puzzleImg/back1.jpg"),
        require("@/assets/image/puzzleImg/back2.jpg"),
        require("@/assets/image/puzzleImg/back3.jpg"),
      ],
      show: true,
      count: "",
      timer: null,

      // 区号
      popHeight: 260, //弹框高度
      popIndex: 0,
      popCode: [
        {
          id: "4",
          country: "中国",
          code: "+86",
        },
        {
          id: "1",
          country: "中国香港",
          code: "+852",
        },
        {
          id: "2",
          country: "中国澳门",
          code: "+853",
        },
        {
          id: "3",
          country: "中国台湾",
          code: "+886",
        },
      ],
    };
  },
  // 事件处理器
  methods: {
    // 获取用户信息
    // getUserInfo() {
    //   this.$ajax({
    //     url: '/hxclass-mobile/user/info',
    //     method: "get"
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.obj = res.data
    //       this.obj.isLogin = true;
    //       this.$store.commit("updateUserInfo", this.obj);
    //       // let tolink = this.$store.state.userInfo.tolink
    //       // if (tolink == '/login/login' || tolink == '/login/clause' || tolink == '/login/retrievePassword') {
    //       //   this.$router.push('/home');
    //       //   return
    //       // }
    //       // this.$router.push(this.$store.state.userInfo.tolink);
    //     }
    //   })

    // },
    // 推动图形校验
    // 成功回调
    handleSuccess() {
      this.verificationShow = false;
      // 发送短信获取验证码
      this.$ajax({
        url: "/hxclass-mobile/user/update/phone/sms",
        method: "get",
        params: {
          phone: this.phone, //手机号
          mobileType: this.popCode[this.popIndex].id, //区号
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success("发送成功");
          // 倒计时
          this.funSMS();
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 错误回调
    handleError() {
      this.verificationShow = false;

    },
    // 获取验证码
    funCode() {
      // console.log(this.popCode[this.popIndex].id)
      // return
      if (!this.phone) {
        this.$Toast("请输入手机号");
        return;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$Toast("请输入正确的手机号");
        return;
      }
      // 打开图形校验
      this.verificationShow = true;
      return;
      // 获取验证码
      this.$ajax({
        url: "/hxclass-mobile/user/update/phone/sms",
        method: "get",
        params: {
          phone: this.phone, //手机号
          mobileType: this.popCode[this.popIndex].id, //区号
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success("发送成功");
          // 倒计时
          this.funSMS();
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 确认更换
    submit() {
      if (!this.phone) {
        this.$Toast("请输入手机号");
        return;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$Toast("请输入正确的手机号");
        return;
      } else if (!this.code) {
        this.$Toast("请输入验证码");
        return;
      } else if (!this.$regular.dxcode.reg.test(this.code)) {
        this.$Toast("请输入正确的验证码");
        return;
      }

      // 更换手机号
      this.$ajax({
        // url: '/hxclass-mobile/user/upmobile',
        url:
          "/hxclass-mobile/user/upmobile?mobile=" +
          this.phone +
          "&verCode=" +
          this.code +
          "&userId=" +
          this.$store.state.userInfo.userId,
        method: "put",
        // params: {
        //   mobile: this.phone,
        //   verCode: this.code,
        //   userId:
        // }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success("手机号更换成功");
          // 更换为新手机号
          localStorage.setItem("loginPhone", this.phone);

          // let obj = JSON.parse(localStorage.getItem('userInfo'))
          // obj.mobile = this.phone
          // this.$store.state.userInfo = obj
          // localStorage.setItem('userInfo', JSON.stringify(obj))
          // // 保存手机号
          // localStorage.setItem('loginPhone', obj.mobile)
          // this.$router.go('-1')

          setTimeout(() => {
            // 退出登录
            this.$store.commit("clearUserInfo");
            this.$router.replace("/login/login");
            localStorage.setItem("searchInfo", JSON.stringify(""));

            // 音频关闭
            if (this.$store.state.audio.src) {
              this.$store.commit("updateAudio", {
                src: "",
                currentTime: 0,
                show: false,
              });
            }
          }, 1000);
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 倒计时
    funSMS() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 打开弹框
    showPopup() {
      this.$refs["pop"].show();
    },
    // 关闭弹框
    hidePopup() {
      this.$refs["pop"].hide();
    },
    // 选择区号
    changeCode(index) {
      this.popIndex = index;
      this.hidePopup();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./style.less";
.single-menu-item:first-child {
  border-bottom: none;
}
.side-left-title {
  font-weight: 500 !important;
}
.side-left {
  display: flex;
  align-items: center;
  .left_phone {
    display: flex;
    align-items: center;
    .phoneType {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
    }
    .phoneType_icon {
      width: 8px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .input {
    // margin-left: 11px;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
}

.get_code {
  border-left: 2px solid #15b7dd;
  padding-left: 7.5px;
  font-size: 12px;
  font-family: PingFangMedium;
  font-weight: 400;
  color: #15b7dd;
  line-height: 15px;
}
// 保存按钮
.onSave-foo {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 35px;
  width: 100%;
  text-align: center;
  padding: 0 40px;
  .all-btn-bigCircle {
    width: 100%;
  }
}
// 选择区号
.pop_box {
  .box_list {
    padding: 0 18px;
    .list_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      padding: 14px 0;
      &:last-child {
        border: 0;
      }
      .item_txt {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .box_button {
    font-size: 16px;
    font-weight: bold;
    color: #15b7dd;
    text-align: center;
    border-top: 10px solid #f7f7f9;
    padding: 14px 0;
  }
}

// 滑动解锁
.drag_box {
  margin-top: 10px;
}
.drag_verify .dv_handler i {
  // background-image: url('@assets/image/datas/Banner.png');
  background: #15b7dd;
}
// 推拽图形校验样式
.puzzle-box{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  .puzzle_content{
    margin: 0 auto;
  }
}
/deep/.van-cell{
  padding: 0;
  flex: 1;
}
/deep/.close-btn{
  display: none !important;
}
</style>

