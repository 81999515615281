import { render, staticRenderFns } from "./specialArea.vue?vue&type=template&id=287d60bf&scoped=true&"
import script from "./specialArea.vue?vue&type=script&lang=js&"
export * from "./specialArea.vue?vue&type=script&lang=js&"
import style0 from "./specialArea.vue?vue&type=style&index=0&id=287d60bf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287d60bf",
  null
  
)

export default component.exports