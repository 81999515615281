<template>
  <!-- 行业考试 -->
  <div class="container-box">
    
    <van-swipe-cell  v-for=" i in 15" :key="i" :disabled='true'>
      <div class="sign-item" @click="goDetail">
        <img class="right-bottom-icon" src="@/assets/image/examination/20220217041501.png" />
        <img class="left-top-icon" src="@/assets/image/examination/20220217041502.png" />
        <div class="title">孤独症康复教育上岗培训课程</div>
        <div class="time">报名时间：2022-01-01 至 2022-01-20</div>
        <div class="time">考试时间：2022-01-21 </div>
      </div>
      <template #right>
        <van-button square type="danger" text="删除" />
      </template>
    </van-swipe-cell>
  </div>
</template>


<script>
	export default {
  // 可用组件的哈希表
		components: {},
    props: {
    	// 渲染数据
    	listData: {
    		type: Array,
    		default: ()=>[]
    	},
    },
		// 数据对象
		data() {
			return {}
		},
		// 事件处理器
		methods: {
      goDetail(){
        this.$router.push({path:'/examEnroll/registration',query:{id:0}});
      }
    },
		// 页面生命周期-页面加载
		onLoad() {},
		// 页面生命周期-页面显示
		onShow() {},
		// 页面生命周期-页面初次渲染完成
		onReady() {},
		// 页面生命周期-页面隐藏
		onHide() {},
		// 页面生命周期-页面卸载
		onUnload() {}
	}
</script>

<style lang="less" scoped>
  @import "./searchSeo.less";
</style>
