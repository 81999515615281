<template>
  <div id="window">
    <van-nav-bar
      title="发票中心"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1'),$store.commit('updateinvoiceIndex',0)"
    />
    <!-- 切换框 -->
		<!-- <div class="tab">
			<div 
				class="tab_item" 
				:class="{'pick': tabIndex==index}"
				v-for="(item, index) in tabList" 
				:key="index"
				@click="changeTab(index)">{{item}}</div>
		</div> -->
    <SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />


    <div id="content">
      <!-- 全部 -->
      <template v-if="tabIndex==0">
        <div class="Switch whole">
          <van-list
              v-if="invoiceAllData.length"
              v-model="loadingAll"
              :finished="finishedAll"
              finished-text="没有更多了"
              @load="getInvoiceAllList"
            >
               <div class="item" v-for="item in invoiceAllData" :key="item.invoiceRecordId">
                <div class="content">
                  <div class="title">
                    <span>订单编号：{{item.code}}</span>
                    <span class="color">{{item.invoiceStatus==1?'未开票' : item.invoiceStatus== 2 ? '已开票' : item.invoiceStatus==3 ? '部分开票' : item.invoiceStatus==4 ? '开票中' : ''}}</span>
                  </div>
                  <!-- 图片 -->
                  <div class="images" >
                    <img class="img" :class="{'imgOne' : index == 2}" v-for="(subItem,index) in item.productList" v-show="index < 3 && subItem.productPicture" :src="subItem.productPicture" :key="subItem.id + '-' + item.invoiceRecordId"/>
                    <div class="name" v-show="item.productList.length==1" v-for="(subItem,index) in item.productList" :key="index"><span>{{subItem.productName}}</span></div>
                  </div>
                  <div class="operations">
                    <div class="left"></div>
                    <div class="right">
                      <router-link v-show="item.invoiceStatus==1" :to="'/invoice/apply?orderId='+ item.orderId">
                        <div class="btn" @click.stop.prevent="submit">申请发票</div>
                      </router-link>
                      <router-link v-show="item.invoiceStatus==2 && item.isMoreInvoice" :to="'/invoice/list?orderId=' + item.orderId">
                        <div class="btn" v-if="item.isMoreInvoice">发票明细</div>
                      </router-link>
                      <router-link v-show="item.invoiceStatus==2 && !item.isMoreInvoice" :to="'/invoice/details?invoiceRecordId=' + item.invoiceRecordId">
                        <div class="btn" v-if="!item.isMoreInvoice">查看发票</div>
                      </router-link>
                      <router-link v-show="item.invoiceStatus==3" :to="'/invoice/list?orderId=' + item.orderId">
                        <div class="btn">发票明细</div>
                      </router-link>
                      <div v-show="item.invoiceStatus==4" @click="Invoicing">
                        <div class="btn">查看发票</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <noMessage class="nomessage" v-if="!invoiceAllData.length" type=1></noMessage>
        </div>
      </template>
      <!-- 换开发票 -->
      <template v-else-if="tabIndex==1">
        <div class="Switch">
          <div class="item" v-for="item in invoiceSwitchData[0].list" :key="item.invoiceRecordId">
            <div class="checkbox" @click="onSlectChange(item)">
              <img class="icon" v-if="item.isSelect" src="@/assets/image/2022021110551.png"/>
              <img class="icon" v-else src="@/assets/image/2022021110552.png"/>
            </div>
            <div class="content">
              <div class="title">
                <span>开票时间：{{item.applyTime}}</span>
                <span class="color">已开票</span>
              </div>
              <div class="commodity" v-for="subItem in item.productList" :key="subItem.id + 'sub'">
                <div class="image" v-show="subItem.productPicture">
                  <img class="img" :src="subItem.productPicture"/>
                </div>
                <div class="data">
                  <div class="name">{{subItem.productName}}</div>
                  <div class="num">x{{subItem.productCount}}</div>
                </div>
              </div>
              <div class="invoice">
                <div class="title">电子普通发票</div>
                <div class="tips">
                  <span>抬头名称：{{item.title}}</span>
                  <span class="money">¥{{item.price.toFixed(2)}}</span>
                </div>
              </div>
            </div>
          </div>
          <noMessage class="nomessage" v-if="!invoiceSwitchData[0].list.length" type=1></noMessage>
        </div>
      </template>
      <!-- 换开记录 -->
      <!-- <template v-else-if="tabIndex==2">
        <div class="Switch record">
          <div class="item" v-for="item in invoiceSwitchData[1].list" :key="item.invoiceRecordId">
            <div class="content">
              <div class="title">
                <span>开票时间：{{item.applyTime}}</span>
                <span class="color">{{item.status == 1 ? '换开中' : '已换开'}}</span>
              </div>
              <div class="commodity" v-for="subItem in item.productList" :key="subItem.id + 'sub'">
                <div class="image" v-show="subItem.productPicture">
                  <img class="img" :src="subItem.productPicture"/>
                </div>
                <div class="data">
                  <div class="name">{{subItem.productName}}</div>
                  <div class="num">x{{subItem.productCount}}</div>
                </div>
              </div>
              <div class="operations">
                <div class="left">
                  <span>开票金额：</span>
                 <span class="money">¥{{item.price.toFixed(2)}}</span>
                </div>
                <div class="right">
                  <div class="btn" @click="onSwitching(item)">查看发票</div>
                </div>
              </div>
            </div>
          </div>
        <noMessage class="nomessage" v-if="!invoiceSwitchData[1].list.length" type=1></noMessage>
        </div>
      </template> -->
      <!-- 抬头管理 -->
      <template v-else-if="tabIndex==2">
        <div class="rise" v-for="(item,index) in invoiceData" :key="item.userInvoiceId">
          <div class="label">{{item.headerType == 1 ? '企业' : '个人'}}</div>
          <div class="name">{{item.invoiceHeader}}</div>
          <div class="mailbox">
            <span>{{item.dutyParagraph}}</span>
          </div>
          <div class="mailbox" style="margin-top: 5px;">
            <span>{{item.email}}</span>
          </div>
          <div class="operations">
            <router-link :to="'/invoice/InvoiceHeader?userInvoiceId=' + item.userInvoiceId">
              <div class="btn">
                <van-icon class="icon" name="edit" />
                <span>编辑</span>
              </div>
            </router-link>
            <div class="btn" @click="openPopup(item,index)">
              <van-icon class="icon" name="delete-o" />
              <span>删除</span>
            </div>
          </div>
        </div>
        <noMessage class="nomessage" v-if="!invoiceData.length" type=5></noMessage>
      </template>
      <!-- 暂无数据 -->
      <!-- <no-message/> -->
      <!-- 底部操作栏 -->
      <div class="operation" :class="tabIndex!=3 && invoiceSwitchData[0].list.length==0?'center':''" v-if="tabIndex==1||tabIndex==3">
        <!-- 换开发票 -->
        <template v-if="tabIndex==1 && invoiceSwitchData[0].list.length">
          <!-- <div class="label" @click="onSelectAll">
            <img class="icon" v-if="isSlectAll" src="@/assets/image/2022021110551.png"/>
            <img class="icon" v-else src="@/assets/image/2022021110552.png"/>
            <span>全选</span>
          </div>
          <div>
            <span>已选</span>
            <span>{{selectIdArry.length}}</span>
            <span>个订单</span>
          </div> -->
          <div class="save" @click="goChange()">
            <div class="btn" >去换开</div>
          </div>
        </template>
        
        <!-- 抬头管理 -->
        <template v-else-if="tabIndex==3">
          <router-link to="/invoice/InvoiceHeader" tag="div" style="flex: 1;text-align: center; padding:0 22px;">
            <div class="all-btn-bigCircle btn">添加发票抬头</div>
          </router-link>
        </template>
      </div>
    </div>
    <van-popup v-model="onPopup" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">确认要删除该发票抬头？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onPopup = false">取消</span>
          <span class="all-btn-middle btn2" @click="onRemove()">确认</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwichTab from "@/components/SwichTab.vue";

export default {

  // 可用组件的哈希表
  components: {noMessage, SwichTab},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      onPopup:false,
      invoiceForm: {
        item: {},
        index: 0
      },
			tabList: [{name:'全部'},{name:'可换开发票'},{name:'抬头管理'}],
      tabIndex: 0,
      invoiceData:[],
      invoiceAllForm:{ // 查询全部
        pageNum: 1,
        pageSize: 10,
      },
      loadingAll:false,
      finishedAll:false,
      invoiceAllData:[],

      invoiceForm:{
        isChange: '',  // 是否换开：1.是  0.否
        status: '',  // 开票状态（默认：1和2）：1.开票中/换开中 2.已开票/已换开 3.已作废
        pageNum: 1,
        pageSize: 100,
      },
      isSlectAll:false,
      selectIdArry:[],
      invoiceSwitchData: [
        { list:[] },
        { list:[] }
      ]
    }
  },
  // 事件处理器
  methods: {
    submit() {
      return this.$Toast("请联系工作人员线下开具发票，联系电话：400-800-9002转3。");
    },
		// 切换tab
		changeTab(index) {
      this.$store.commit('updateinvoiceIndex',index);
      this.tabIndex = index
      if(index == 1 || index == 2){
        this.invoiceForm.isChange = index == 1 ? 0 : 1
        this.invoiceForm.status = index == 1 ? 2 : ''
        this.getInvoiceSwitchList()
      }
    },
    // 获取发票中心的全部列表
    getInvoiceAllList(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/app/order-invoice",
        params: this.invoiceAllForm 
        }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceAllData = [...this.invoiceAllData,...res.data.records]
          this.invoiceAllForm.pageNum ++ 
          this.loadingAll = false

          if (this.invoiceAllData.length >=  res.data.total) {
            this.finishedAll = true
          }
        }
      });
    },
    // 获取发票中心的全部列表
    getInvoiceSwitchList(){
      if(this.invoiceSwitchData[this.tabIndex - 1].list.length){
        return
      }
      this.$ajax({
        url: "/hxclass-mobile/invoice/",
        params: this.invoiceForm
        }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.records.forEach(element => {
            element.isSelect = false
          });
          this.invoiceSwitchData[this.tabIndex - 1].list = res.data.records
        }
      });
    },
    // 获取发票抬头列表
    getInvoiceList(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/list",
        }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data
        }
      });
    },
    // 换开全选
    onSelectAll(){
      if(!this.invoiceSwitchData[0].list.length){
        this.$Toast("暂无数据可选");
        return
      }
      if(!this.isSlectAll){
        this.isSlectAll = true
        this.selectIdArry = []
        this.invoiceSwitchData[0].list.forEach(element => {
          element.isSelect = true
          this.selectIdArry.push(element.invoiceRecordId)
        });
      } else {
        this.selectIdArry = []
        this.isSlectAll = false
        this.invoiceSwitchData[0].list.forEach(element => {
          element.isSelect = false
        });
      }
    },
    // 换开发票选择
    onSlectChange(e){
      e.isSelect = !e.isSelect
      let i = this.selectIdArry.indexOf(e.invoiceRecordId)

      // 禁止多选
      this.invoiceSwitchData[0].list.map(item=>{
        if(item.invoiceRecordId != e.invoiceRecordId) {
          item.isSelect = false;
        }
      })
      // 将选中的发票暂存到已选中数组
      if(i < 0){
        // this.selectIdArry.push(e.invoiceRecordId)
        this.$set(this,'selectIdArry',[e.invoiceRecordId])
      } else {
        this.selectIdArry.splice(i, 1)
      }

      // 判断选择的数组和列表数组长度是否一样， 长度一样代表全选
      if(this.invoiceSwitchData[0].list.length == this.selectIdArry.length){
        this.isSlectAll = true
      } else {
        this.isSlectAll = false
      }
    },
    // 开票中
    Invoicing(){
      this.$Dialog.alert({
        title: '提示信息',
        message: '发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。',
      })
    },
    // 去换开
    goChange(){
      if(!this.selectIdArry.length){
        this.$Toast('请至少选择一个发票');
        return
      }
      this.$router.push({
        // path: '/invoice/Change?invoiceRecordIds=' + this.selectIdArry
        path: '/invoice/replaceVoice?invoiceRecordIds=' + this.selectIdArry  // 改版
      })
    },
		onSwitching(e){
      if(e.status == 1){ // 换开中
        this.$Dialog.alert({
          title: '提示信息',
          message: '发票正在换开中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。',
        })
      } else {
        this.$router.push({
          path: '/invoice/details?invoiceRecordId=' + e.invoiceRecordId
        })
      }
    },
    // 发票抬头弹窗
    openPopup(item,i){
      this.invoiceForm={
        item: item,
        index: i,
      }
      this.onPopup = true
    },
		// 删除发票抬头
		onRemove(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/" + this.invoiceForm.item.userInvoiceId,
        method: 'DELETE',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.onPopup = false
          this.$alert('删除成功');
          this.invoiceData.splice(this.invoiceForm.index, 1)
        }
      });

      // this.$Dialog.confirm({
      //   title: '温馨提醒',
      //   message: '确认删除该发票抬头？',
      // })
      // .then(() => {
      //   this.$ajax({
      //     url: "/hxclass-mobile/invoice/header/" + e.userInvoiceId,
      //     method: 'DELETE',
      //   }).then((res) => {
      //     if (res.code == 200 && res.success) {
      //       this.invoiceData.splice(i, 1)
      //     }
      //   });
      // })
      // .catch(() => {
      // });
		}
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getInvoiceAllList()
    this.getInvoiceList() // 获取发票抬头
  },
  // 生命周期-实例挂载后调用
  mounted () {
     this.tabIndex = this.$store.state.invoiceIndex
     if(this.tabIndex == 1 || this.tabIndex == 2){
        this.invoiceForm.isChange = this.tabIndex == 1 ? 0 : 1
        this.invoiceForm.status = this.tabIndex == 1 ? 2 : ''

        this.getInvoiceSwitchList()
     }
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import '../../assets/css/invoice.less';
#window{
  display: flex;
  flex-direction: column;
  flex: 1;
  #content{
    font-family:  PingFangMedium;
    background-color: #F7F7F9;
    flex: 1;
    position: relative;
  }
}
// 切换框
.tab {
  font-family:  PingFangMedium;
  padding: 11.5px 18px;
  height: 27px;
  line-height: 27px;
}

.operation{
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  &.center{
    background-color: rgba(255,255,255,0);
    justify-content: center;
  }
  .label{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
    .icon{
      height: 20px;
      margin-right: 5px;
    }
  }
  .save{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    .btn{
      width: 100%;
      height: 36px;
      background: @theme;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      color: #fff;
      align-items: center;
      justify-content: center;
    }
  }
  .all-btn-bigCircle {
    width: 100%;
  }
}
.Switch{
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .item{
    padding: 8px 0 16px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    // align-items: center;
    margin-bottom: 15px;
    .checkbox{
      padding: 5px 9px 0 10px;
      max-height: 20px;
      .icon{
        height: 20px;
      }
    }
    .content{
       font-family:  PingFangMedium;
      padding-right: 12px;
      flex: 1;
      .title{
        font-size: 12px;
        color: #666;
        line-height: 28px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .color{
          color: @theme;
          font-weight: 500;
        }
      }
      .commodity{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        margin-bottom: 10px;
        .image{
          width: 100px;
          height: 70px;
          border-radius: 8px;
          overflow: hidden;
          .img{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            object-fit: cover;
          }
        }
        .data{
          flex: 1;
          margin-left: 5px;
          .name{
            font-size: 14px;
            color: #333;
            font-weight: 500;
            line-height: 22px;
          }
          .num{
            font-weight: 400;
            color: #999;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 12px;
            line-height: 17px;
          }
        }
      }
      .invoice{
        margin-top: 16px;
        background-color: #eee;
        border-radius: 8px;
        padding: 12px;
        .title{
          margin-bottom: 0;
          font-weight: 500;
          font-size: 14px;
          color: #333;
        }
        .tips{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #666;
          font-size: 12px;
          >span:nth-child(1){
            display: inline-block;
            max-width: 200px;
            line-height: 18px;
          }
          .money{
            font-size: 16px;
            font-weight: 500;
            color: #333;
          }
        }
      }
    }
  }
}
.record{
  .item{
    .content{
      padding-left: 12px;
      .operations{
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        align-items: center;
        justify-content: space-between;
        .btn{
          width: 72px;
          height: 29px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #333;
          font-weight: 400;
        }
      }
    }
  }
}
.whole{
  .item{
    .content{
      padding-left: 12px;
          font-family: PingFangMedium;

      .images{
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .img{
          width: calc((100% - 24px) / 3);
          height: 70px;
          background-repeat: no-repeat;
          object-fit: cover;
          border-radius: 7px;
          margin-right: 12px;
        }
        .imgOne{
          margin-right: 0;
        }
        .name{
          flex: 1;
          font-family: PingFangMedium;
          span{
            font-weight: 500;
            margin-top: 5px;
            white-space: normal;
            max-width: 90%;
            line-height: 18px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;  //使用自适应布局
            -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
            -webkit-box-orient: vertical;
          }
        }
      }
      .operations{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        align-items: center;
        justify-content: space-between;
        .btn{
          width: 72px;
          height: 29px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #333;
          font-weight: 400;
        }
      }
    }
  }
}
.rise{
  background-color: #fff;
  position: relative;
  padding: 47px  26px 16px 16px;
  margin: 0 18px;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #333;
  border-radius: 8px;
  margin-bottom: 8px;
  .label{
    position: absolute;
    left: 0;
    top: 0;
    width: 56px;
    height: 24px;
    background: @theme;
    box-shadow: 2px 0px 0px 0px rgba(26,164,196,1);
    border-radius: 8px 0px 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .name{
    font-size: 16px;
    margin-bottom: 11px;
  }
  .mailbox{
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: #333;
  }
  .operations{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: .5px solid #eee;
    padding-top: 16px;
    margin-top: 16px;
    .btn{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 40px;
      .icon{
        margin-right: 2px;   
         font-size: 17px;
      }
    }
  }
}
.nomessage{
  position: relative;
  // top: 20%;
  margin-top: 100px;
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>
