<template>
  <van-popup v-model="show" :close-on-click-overlay="false">
    <div class="sharePageModel">
      <p class="title">此功能需访问华夏云课堂APP</p>
      <div class="btns">
        <p class="btn" @click="closeSharePageModel">继续逛逛</p>
        <p class="btn right" @click="installApp">下载华夏云课堂APP</p>
      </div>
      <!-- <p class="message">开发者:北京华夏元道文化科技有限公司丨版本:1.1.1</p>
      <p class="message">更新时间:2022-06-21 应用权限与隐私政策信息:<span>点击查看</span></p> -->
      <!-- <a class="IOSbtn" @click="goDownLoad" :href="'hxykt://?openType=' + openType + '&openId=' + openId">立即打开</a> -->
    </div>
  </van-popup>
</template>

<script>
// import { downloadApp } from '@/assets/js/NativeWebKit.js'
// import { getAppVersion } from '@/assets/js/NativeWebKit.js'
// import { FunJudgDevice } from "@/unit/fun.js";
// const DeviceType = FunJudgDevice();
export default {
  props:['show'],
  data(){
    return {
      // AppVersion: getAppVersion(),
      isAndroidType: 0, //1华为市场 0其他
    }
  },
  created() {
    // this.getVersionscore(); // 获取线上最新版本信息
  },
  methods: {
    openApp() {
      var u = window.navigator.userAgent.toLowerCase();
      var isWeixin = u.match(/MicroMessenger/i) == 'micromessenger'; //判断是不是微信浏览器
      //如果是微信,直接下载
      if (isWeixin) {
        window.location.href = "http://app.download.hxclass.cn/"; /***Android移动端下载地址***/
      } else {
      /***打开app的协议***/
        window.location.href = "hxclass://alpha.mobile.hxclass.cn/open?openType=1&openId=1"; 
        window.setTimeout(function() {
        /***Android移动端下载地址***/
          window.location.href = "http://app.download.hxclass.cn/"; 
        }, 2000);
      }
    },
    installApp() {

      // 判断是否下载过app
      // 下面的代码，楼主楼主是在Vue2中使用的，如果语言不通，根据自己所写的语言稍微改下即可。
    // handleOpenApp() {
      const channel = this.channel
      const t = 1000
      const t1 = Date.now()
      const ifr = document.createElement('iframe')
      // 下面的this.url 是需要跳转到app的schema链接
      // console.log(this.url)
      // // ifr.setAttribute('src', this.url)
      ifr.setAttribute('style', 'display:none')
      document.body.appendChild(ifr)
      setTimeout(function() {
      	// 启动app时间较长处理
        const t2 = Date.now()
        document.body.removeChild(ifr)
        if (t2 - t1 < t + 100) {
          console.log('hasApp', false)
          // 没有安装App，跳转到对应的App下载页面。
          // 下面的例子App下载页面也是H5页面。
          window.location.href = `http://app.download.hxclass.cn/`
        } else {
          console.log('hasApp', true)
          // document.getElementsByClassName("IOSbtn")[0].click();
          // 已经安装App，下面的this.url是需要跳转到app的schema链接。
          window.location.href = 'hxykt://?openType=1&openId=1'
          //  <a href="awaken://h5/open?openType=1&openId=123">打开app</a>
 
          // 其实就是<a href="scheme://host/pathPrefix?openType=1&openId=123">打开app</a>
        }
      }, t)
    },
    
    // 点击下载地址，有上架手机商店的直接打开应用商店，没有上架商店打开下载地址
    download(){
      var u = navigator.userAgent
      if (u.indexOf('iPhone') > -1) {
        //苹果手机
        window.location.href = 'https:xxxxxx' //ios下载地址
      } else {
        if (u.match(/(vivo|VIVO);?/i)) {
          window.location.href = "vivomarket://details?id=com.xxxxx"  // vivo手机  com.xxxx包名
        } else if (u.match(/(OPPO|oppo);?/i)) {
          window.location.href = "oppomarket://details?packagename=com.xxxxxx" // oppo手机
        } else if (u.match(/(HUAWEI|HONOR);?/i)) {
          window.location.href = "appmarket://details?id=com.xxxxx" // 华为手机
        } else {
          //安卓其他设备
          window.location.href = 'https:xxxxxxx'   // 下载地址
        }
      }
    },

    getVersionscore() {
      this.$ajax({
        url: '/hxclass-mobile/score/new/version',
        method: 'get',
        params: {
          phoneSystem: 1 //this.AppVersion.phoneSystem // 1.android 2.ios
        }
      })
    },
    closeSharePageModel() {
      this.$emit('closeSharePageModel')
    }
  }
}
</script>

<style lang="less" scoped>
  ::v-deep.van-popup{
    background-color: inherit;
  }
  .sharePageModel{
    width: 280px;
    box-sizing: border-box;
    padding: 37px 15px 15px;
    background: #FFFFFF;
    border-radius: 10px;
    .title{
      text-align: center;
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .btns{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 16px;
      .btn{
        width: 120px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #15B7DD;
        text-align: center;
        color: #15B7DD;
        line-height: 32px;
      }
      .right{
        font-size: 12px;
        color: #FFFFFF;
        background: #15B7DD;
      }
    }
    .message{
      -webkit-text-size-adjust:none;
      font-size: 9px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #8B8B8B;
      line-height: 11px;
      text-align: center;
      // transform: scale(0.8);
    }
  }
</style>