<template>
  <div v-show="$store.state.userInfo.userId && $store.state.audio.src" class="audioMin" :style="'bottom:'+btmPx+'px;'" :class="$store.state.audio.show?'fade-in-bottom':'fade-out-bottom'" @click="goDetails()">
    <!-- 播放内容 -->
    <div class="content">
      <div class="title">{{$store.state.audio.title?$store.state.audio.title:'-'}}</div>
      <div class="time">
        <span>{{formatSeconds($store.state.audio.currentTime)}}</span>
        <span> / </span>
        <span>{{formatSeconds($store.state.audio.duration)}}</span>
      </div>
    </div>
    
    <!-- 操作区 -->
    <div class="operation">
      <!-- 上一首 -->
      <div class="swichIcon left" @click.stop="onSwitch('up')">
        <img src="./image/icon4.png" alt="">
      </div>
      <!-- 播放/暂停 -->
      <div class="play" @click.stop="onPausd(!$store.state.audio.paused)">
        <img v-if="!$store.state.audio.paused" src="./image/Playing2.png" alt="">
        <img v-else src="./image/Suspend2.png" alt="">
      </div>
      <!-- 下一首 -->
      <div class="swichIcon right" @click.stop="onSwitch('down')">
        <img  src="./image/icon5.png" alt="">
      </div>
    </div>

    <!-- 关闭按钮 -->
    <div class="close" @click.stop="onClose()">
      <img src="./image/close.png" alt="">
    </div>

    <img class="leftFoot" src="./image/icon1.png" alt="">
    <img class="leftTop" src="./image/icon2.png" alt="">
  </div>
</template>

<script>
let bottomList = [
  { src: '/home', btm: 50, window: true },
  { src: '/selected', btm: 50, window: true },
  { src: '/study', btm: 50, window: true },
  { src: '/my', btm: 50, window: true },
  { src: '/curriculum/course', btm: 50 },
  { src: '/curriculum/BookDdetails', btm: 50 },
  { src: '/shop/shoppingMall', btm: 90 },
]
import { formatSeconds } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      formatSeconds,
      bottomList,
      Audio: {},
      btmPx: 0,
      scrollTop: 0,
      win: false
    }
  },
  // 事件处理器
  methods: {
    goDetails() {
      this.$router.push('/propagandaHeadlines/audioDetails?xuanId=' + this.$store.state.audio.id)
    },
    // 上下首切换
    onSwitch(e){
      this.$ajax({
        url: '/hxclass-mobile/speak/details',
        params: {
          xuanId: this.$store.state.audio.id
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          // 如果点击是上一首并且有上一首的id
          if(e=='up' && res.data.upXuan) {
            this.$store.commit('updateAudio',{
              title: res.data.upTitle,
              src: res.data.upAudioUrl,
            })
            this.$store.state.audio.id = res.data.upXuan
          } else if (e=='up' ) {
            this.$Toast('已经是第一首啦');
          } else if (e=='down' && res.data.downXuan) {
            this.$store.commit('updateAudio',{
              title: res.data.downTitle,
              src: res.data.downAudioUrl,
            })
            this.$store.state.audio.id = res.data.downXuan
          } else if (e=='down') {
            this.$Toast('已经是最后一首啦');
          }
        }
      })
    },
    // 设置距离底部的高度
    setBtm(src){
      let px = 0
      bottomList.forEach(item=>{
        if(item.src == src){
          px = item.btm
          this.win = item.window
        }
      })
      this.$set(this, 'btmPx', px)
      this.$nextTick(()=>{
        this.onAddEventListener()
      })
    },

    // 播放/暂停
    onPausd(e){
      if(e){
        this.$store.commit('pause')
      }else{
        if(!this.$store.state.audio.src){
          this.$store.commit('updateAudio',{
            title: '123',
            src: 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-81f7990b-dfbd-4e32-8eea-8efea594cb26/83d4bfac-5cee-497f-b3a4-4dcd10186e7c.mp3'
          })
        }
        this.$store.commit('play')
      }
    },

    // 关闭音频播放
    onClose(){
      this.$store.commit('updateAudio',{
        src: '',
        currentTime: 0,
        show: false
      })
    },

    FunScroll(){
      let scrollTop = 0
      if(this.win){
        scrollTop = document.getElementById('content').scrollTop
      }else{
        scrollTop = document.getElementsByTagName('html')[0].scrollTop
      }
      if(scrollTop < 0) {
        return
      }
      // 判断当前是向下滚动还是向上滚动
      if(scrollTop >= this.scrollTop){
        // 向下
        this.$store.commit('updateAudio',{show: false})
      }else{
        // 向上
        if(this.$store.state.audio.src && this.$store.state.userInfo.userId){
          this.$store.commit('updateAudio',{show: true})
        }
      }
      this.scrollTop = scrollTop
    },

    // 绑定监控
    onAddEventListener(){
      // 监听页面滚动
      try {
        if(document.getElementById('content')){
          document.getElementById('content').removeEventListener('scroll',this.FunScroll)
          document.getElementById('content').addEventListener('scroll',this.FunScroll)
        }else{
          window.removeEventListener('scroll',this.FunScroll)
          window.addEventListener('scroll',this.FunScroll)
        }
      } catch (error) {
        throw error
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.setBtm(this.$route.path)
  },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(e){
      // 如果是登录页  不展示播放按钮
      // if(e.path == '/login/login' || e.path == '/shop/shoppingMall' || e.path == '/setUp/index' || this.$store.state.audio.paused){
      //   this.$store.state.audio.show = false
      // } else if(!this.$store.state.audio.paused && this.$store.state.userInfo.userId) {  // 否则 如果有音频在播放， 那么在底部显示
      //   this.$store.state.audio.show = true
      // }

      if(e.path != '/propagandaHeadlines/audioDetails' && e.path != '/propagandaHeadlines/audioList'){
        if(this.$store.state.audio.src){
          this.$store.commit('updateAudio',{
            src: '',
            currentTime: 0,
            show: false
          })
        }
      } else if(e.path == '/propagandaHeadlines/audioList') {
        if(this.$store.state.audio.src){
          this.$store.state.audio.show = true
        }
      }
      
      this.win = false
      if(e.path != '/propagandaHeadlines/audioDetails'){
        this.setBtm(e.path)
      }else{
        window.removeEventListener('scroll',this.FunScroll)
        if(document.getElementById('content')){
          document.getElementById('content').removeEventListener('scroll',this.FunScroll)
        }
        this.$store.state.audio.show = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.audioMin{
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0px -3px 5px 1px rgba(0,0,0,0.1);
  padding: 0 18px;
  // background-image: url('./image/backimg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 20px;
  transform: translateY(50px);
  opacity: 0;
  .content{
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    .title{
      position: relative;
      z-index: 999;
      font-size: 14px;
      line-height: 21px;
      color: #333;
      font-weight: 500;
      display: -webkit-box;//对象作为弹性伸缩盒子模型显示
      overflow: hidden;//溢出隐藏
      -webkit-box-orient: vertical;//设置伸缩盒子对象的子元素的排列方式
      -webkit-line-clamp: 1;//设置 块元素包含的文本行数
    }
    .time{
      position: relative;
      z-index: 999;
      font-size: 12px;
      color: #999;
      line-height: 18px;
      margin-top: 6px;
      font-weight: 400;
    }
  }

  .operation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    height: 50px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('./image/icon3.png');
    .right{
      margin-right: 15px;
      z-index: 100;
    }
    .left{
      margin-left: 15px;
      z-index: 100;
    }
    .swichIcon{
      width: 12px;
      height: 12px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .play{
      img{
        width: 34px;
        height: 34px;
      }
    }
  }

  
  .close{
    margin-left: 18px ;
    img{
      width: 13px;
      height: 13px;
    }
  }

  .leftFoot{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 48px;
    height: 22px;
    z-index: 1px;
  }
  .leftTop{
    position: absolute;
    left: 100px;
    top: 0;
    width: 70px;
    height: 20px;
    z-index: 1px;
  }
}
// 淡入
.fade-in-bottom {
	animation: fade-in-bottom 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
// 淡出
.fade-out-bottom {
	animation: fade-out-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes fade-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
    z-index: -99;
  }
}
</style>
