<template>
  <div class="box">
    <van-nav-bar 
      class="nav-circular" 
      :title="name" 
      left-arrow 
      :fixed="true" 
      :placeholder="true"
      @click-left="onReturn()" />
    <!-- pdf -->
    <iframe :src="url" frameborder="0" class="box_iframe"></iframe>
  </div>
</template>

<script>
import { FunJudgDevice } from "@/unit/fun.js";
const DeviceType = FunJudgDevice();

import {FunReturnPlay} from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      name: '', //pdf名称
      url: '', //pdf链接
    }
  },
  // 事件处理器
  methods: {
    // 手动处理返回
    onReturn(){
      // 安卓
      if (DeviceType == "android") {
        this.$onReturn(this.$route.query.fromMenu == '1')
      } else if (DeviceType == "ios" && window.webkit) {
        this.$onReturn(this.$route.query.fromMenu == '1')
        FunReturnPlay()
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 取文件后缀
    const url = this.$route.query.url
    const m = url.match(/\.(\w+)(#|\?|$)/);
    const fileType = (m && m[1]).toString().toLowerCase();

    if(fileType == 'epub'){ // 电子书格式
      return this.$router.replace({
        path: "/myStudy/eBook?url=" + url,
      });
    }
    
    this.name = this.$route.query.name //pdf名称
    this.url = url + '?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJwZGZPcHRpb25zIjp7ImlzSW5TYWZlTW9kZSI6dHJ1ZX19' //pdf链接
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .box_iframe {
    width: 100%;
    height: 90vh;
  }
</style>
