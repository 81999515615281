//直播
export default [{ //直播列表
    path: 'liveList',
    component: () =>
        import('../views/live/liveList.vue')
}, { //直播详情
    path: 'liveDetail',
    component: () =>
        import('../views/live/liveDetail.vue')
}, { //直播分享
    path: 'shareLive',
    component: () =>
        import('../views/live/shareLive.vue')
},]