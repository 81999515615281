<template>
  <div class="globalSearch">
    <!-- 搜索结果 -->
    <div class="searchResult">
      <div class="head-box" ref="headBox">
        <div class="state-box" :style="{height: stateHeight+'px'}"></div>
        <div class="search-inner" :style="{paddingTop: 10+stateHeight+'px'}">
          <van-icon size="19" @click="$onReturn($route.query.fromMenu == '1')" poa name="arrow-left" />
          <div class="center">
            <van-icon size="18" class="search" color="#999" name="search" />
            <!-- <van-field ref="inputfocus" placeholder="请输入搜索内容" clearable :border="false" @focus="onFocus()" v-model="keyWord"/> -->
            <form style="width: 100%" action="" @submit.prevent="onSearchKey()">
              <input
                class="inputdemo"
                @focus="onFocus()"
                @keyup.13="onTextareaKeyDown"
                ref="inputfocus"
                placeholder="请输入搜索内容"
                v-model="keyWord"
              />
            </form>
          </div>

          <span
            v-if="isHistory"
            class="search-btn"
            @click="(tabIndex = 0), onSearch()"
            >搜索</span
          >
          <span v-if="!isHistory" class="search-btn" @click="onFocus()"
            >取消</span
          >
        </div>
        <!-- <div class="menu-box" v-if="!isHistory&&!showEmpty">
          <SwiperMenu @setMenuCurrent="getMenuCurrent" :listCurren="listCurren" :menuArry="menuArry"/>
        </div> -->
        <!-- <div class="tab" v-if="!isHistory&&!showEmpty&&menuArry.length">
          <div 
            class="tab_item" 
            :class="{'pick': tabIndex==index}"
            v-for="(item, index) in menuArry" 
            :key="index"
            @click="changeTab(index)">{{item.name}}</div>
        </div> -->
        <div v-if="!isHistory && !showEmpty">
          <SwichTab
            @setTab="changeTab"
            :tabIndex="tabIndex"
            :menuArry="menuArry"
          />
        </div>
      </div>

      <!-- 列表结果 -->
      <div class="list-box" v-if="!isHistory">
        <noMessage v-if="showEmpty" type="1"></noMessage>
        <van-swipe
          :style="{ height: swiperHeight + 'px' }"
          class="swiper-list"
          ref="refSwiper"
          :initial-swipe="current"
          :loop="true"
          :show-indicators="false"
          :touchable="false"
          :lazy-render="true"
          @change="swiperListChange"
        >
          <!-- 教育课程 -->
          <van-swipe-item v-if="this.listArry[0].listDate.length">
            <div class="scroll-Y edu-Y" @scroll="scrollEvent">
              <EduClass-List
                listType="2"
                :listData="this.listArry[0].listDate"
              />
            </div>
          </van-swipe-item>
          <!-- 图书 -->
          <van-swipe-item v-if="this.listArry[1].listDate.length">
            <div class="scroll-Y book-Y" @scroll="scrollEvent">
              <Book-List listType="2" :listData="this.listArry[1].listDate" />
            </div>
          </van-swipe-item>
          <!-- 教具 -->
          <van-swipe-item v-if="this.listArry[2].listDate.length">
            <div class="scroll-Y book-Y" @scroll="scrollEvent">
              <Book-List listType="2" :listData="this.listArry[2].listDate" />
            </div>
          </van-swipe-item>
          <!-- 模拟考试 -->
          <van-swipe-item v-if="this.listArry[3].listDate.length">
            <div class="scroll-Y" @scroll="scrollEvent">
              <MockExam-List
                listType="2"
                :listData="this.listArry[3].listDate"
              />
            </div>
          </van-swipe-item>
          <!-- 云课头条 -->
          <!-- <van-swipe-item v-if="this.listArry[3].listDate.length">
            <div class="scroll-Y" @scroll="scrollEvent">
              <LeadNewsList :listData="this.listArry[3].listDate" />
            </div>
          </van-swipe-item> -->
          <!-- 百问百答 -->
          <!-- <van-swipe-item v-if="this.listArry[4].listDate.length">
            <div class="scroll-Y" @scroll="scrollEvent">
              <QAList listType="1" :listData="this.listArry[4].listDate" />
            </div>
          </van-swipe-item> -->
          <!-- 资讯速递 -->
          <!-- <van-swipe-item v-if="this.listArry[5].listDate.length">
            <div class="scroll-Y" @scroll="scrollEvent">
              <InfomationList :listData="this.listArry[5].listDate" />
            </div>
          </van-swipe-item> -->
          <!-- 自闭之声 -->
          <!-- <van-swipe-item v-if="this.listArry[6].listDate.length">
            <div class="scroll-Y" @scroll="scrollEvent">
              <AutisticList :listData="this.listArry[6].listDate" />
            </div>
          </van-swipe-item> -->
          <!-- <van-swipe-item>
            教育直播
            <div class="scroll-Y">
              <EduVideo-List />
            </div>
          </van-swipe-item> -->
          <!-- <van-swipe-item>
            行业考试
            <div class="scroll-Y">
              <ExamList />
            </div>
          </van-swipe-item> -->
        </van-swipe>
      </div>
    </div>

    <!-- 搜索历史 -->
    <div class="findHistory" v-if="isHistory && findHistoryList.length != 0">
      <div class="title">
        <span class="leftTitle">搜索历史</span>
        <van-icon name="delete" color="#c1c1c1" @click="clearPopup = true" />
      </div>
      <div class="historyList">
        <span
          class="historyItem"
          v-for="(item, index) in findHistoryList"
          :key="index"
          @click="onHistory(item)"
          >{{ item }}</span
        >
      </div>
    </div>

    <van-popup v-model="clearPopup" :close-on-click-overlay="false">
      <div class="popup-content">
        <div class="popup-box">
          <div class="title title-noimg">确认清空搜索历史?</div>
        </div>
        <div class="btn-inner">
          <div class="div">
            <span class="all-btn-middle" @click="onEmpty()">确认</span>
          </div>
          <div class="div">
            <span class="all-btn-middle" @click="clearPopup = false">取消</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwiperMenu from "@/components/swiperMenu.vue"; // 导航组件
import BookList from "@/components/searchSeo/BookList.vue"; // 图书教具
import EduClassList from "@/components/searchSeo/eduClassList.vue"; // 教育课程
import MockExamList from "@/components/searchSeo/mockExamList.vue"; // 模拟考试
import LeadNewsList from "@/components/searchSeo/leadNewsList.vue"; // 云课头条
import QAList from "@/components/searchSeo/QAList.vue"; // 百问百答
import InfomationList from "@/components/searchSeo/infomationList.vue"; // 资讯速递
import AutisticList from "@/components/searchSeo/autisticList.vue"; // 自闭之声
import SwichTab from "@/components/SwichTab.vue";
// import PreachHeadlines from "@/components/searchSeo/preachHeadlines.vue"; // 云课头条
// import EduVideoList from "@/components/searchSeo/eduVideoList.vue"; // 教育直播
// import ExamList from "@/components/searchSeo/examList.vue"; // 行业考试
export default {
  components: {
    SwiperMenu, // 导航组件
    BookList, // 图书教具
    EduClassList, // 教育课程
    MockExamList, // 模拟考试
    LeadNewsList, // 云课头条
    QAList, // 百问百答
    InfomationList, // 资讯速递
    AutisticList, // 自闭之声
    // PreachHeadlines, // 云课头条
    noMessage,
    SwichTab,
  },
  data() {
    return {
      tabIndex: 0,
      clearPopup: false,
      showEmpty: false,
      stateHeight: 0, //信息条高度
      i: "",
      isHistory: true,
      keyWord: "",
      newCurrent: 1, //当前页数
      newSize: 10, //条数
      // newLoading: false,
      // finished: false,

      windowHeight: document.documentElement.clientHeight, // 屏幕的高度
      swiperHeight: 0, // swiper盒子的高度
      listCurren: 0, // 传递给菜单的位置
      current: 0, // 列表坐标
      menuArry: [],
      listArry: [
        // 菜单
        { id: 1, name: "证书课程", listDate: [] },
        { id: 2, name: "图书", listDate: [] },
        { id: 3, name: "教具", listDate: [] },
        { id: 4, name: "模拟考试", listDate: [] },
        { id: 5, name: "云课头条", listDate: [] },
        { id: 6, name: "常见问答", listDate: [] },
        { id: 7, name: "要闻速递", listDate: [] },
        { id: 8, name: "新知共享", listDate: [] },
      ],
      background: ["color1", "color2", "color3"],
      interval: 2000,
      duration: 500,
      text: '<span style="color:rgba(21, 183, 221, 1)">孤独症</span>康复教育人员上岗培训课程',
      searchValue: "",
      iStatusBarHeight: 0,
      findHistoryList: [],
    };
  },
  // 页面生命周期-页面加载
  mounted() {
    // 获取轮播区域高度
    this.swiperHeight =this.windowHeight - this.$refs.headBox.offsetHeight ;
  },
  methods: {
    scrollEvent(e) {
      // var scrollTop = e.target.scrollTop; // 变量scrollTop是滚动条滚动时，距离顶部的距离
      // var clientHeight = e.target.clientHeight; // 变量windowHeight是可视区的高度
      // var scrollHeight = e.target.scrollHeight; // 变量scrollHeight是滚动条的总高度
      // if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      //   clearTimeout(this.tiemID);
      //   this.tiemID = setTimeout(() => {
      //     this.getList();
      //     // console.log("到底了");
      //   }, 1000);
      // }
    },

    // 接收菜单的位置，并定位到对应菜单
    getMenuCurrent(e) {
      this.current = e;
      this.$store.commit("updateIndex", this.current);
    },

    changeTab(e) {
      this.current = e;
      this.tabIndex = e;
      this.$store.commit("updateIndex", this.current);
    },
    // 获取list列表位置,传递给菜单
    swiperListChange(event) {
      // console.log(event)
      let current = event;
      this.listCurren = event;
    },

    goDetails(key) {
      this.$zaudio.setAudio(this.list);
      this.$router.push("/propagandaHeadlines/audioDetails?key=" + key);
    },

    //播放或暂停
    play(key) {
      this.isShow = true;
      this.$zaudio.setAudio(this.list);
      this.$zaudio.operate(key);
    },

    // 返回
    back() {
      this.$onReturn(this.$route.query.fromMenu == '1');
    },

    onFocus() {
      this.isHistory = true;
      this.current = 0;
      this.listCurren = 0;
    },

    // 键盘收起
    onTextareaKeyDown() {
      this.$refs.inputfocus.blur();
    },

    // 键盘搜索
    onSearchKey() {
      (this.tabIndex = 0), this.onSearch();
    },

    onHistory(x) {
      this.keyWord = x;
      this.tabIndex = 0;
      this.onSearch();
    },

    onSearch() {
      this.showEmpty = false;
      if (this.keyWord) {
        this.isHistory = false;
        this.menuArry = [];
        for (let i = 0; i < this.listArry.length; i++) {
          this.listArry[i].listDate = [];
        }
        this.$ajax({
          url: "/hxclass-mobile/app-mall/fuzzy/query",
          method: "get",
          params: {
            keyword: this.keyWord,
           
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // this.current = 0
            this.$store.commit("updateword", this.keyWord);
            // 如果没有
            if (res.data == "") {
              this.showEmpty = true;
            }
            res.data.forEach((item, index) => {
              this.menuArry.push({
                id: index + 1,
                name: this.listArry[item.type - 1].name,
                i: this.listArry[item.type - 1].id,
              });
            });

            this.listArry.forEach((item, index) => {
              res.data.forEach((subItem, i) => {
                if (subItem.type - 1 == index) {
                  item.listDate = subItem.productLsit;
                  item.current = 2;
                }
              });
            });

            let i = this.findHistoryList.findIndex((item) => {
              return this.keyWord == item;
            });

            if (i == -1) {
              if (this.findHistoryList.length < 10 && this.keyWord != "") {
                this.findHistoryList.unshift(this.keyWord);
              } else if (
                this.findHistoryList.length > 9 &&
                this.keyWord != ""
              ) {
                this.findHistoryList.unshift(this.keyWord);
                this.findHistoryList.pop();
              }
            } else {
              this.findHistoryList.splice(i, 1);
              this.findHistoryList.unshift(this.keyWord);
            }
            localStorage.setItem(
              "searchInfo",
              JSON.stringify(this.findHistoryList)
            );
          }
        });
      }
    },

    getList() {
      let i = this.menuArry[this.current].i;
      this.$ajax({
        url: "/hxclass-mobile/elastic/list",
        params: {
          keyWord: this.keyWord,
          type: i,
          from: this.listArry[i - 1].current,
          size: this.newSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.listArry[i - 1].current++;
          this.listArry[i - 1].listDate = this.listArry[i - 1].listDate.concat(
            res.data[0].productLsit
          );
        }
      });
    },

    onEmpty() {
      this.findHistoryList = [];
      localStorage.setItem("searchInfo", JSON.stringify(this.findHistoryList));
      this.clearPopup = false;
    },
  },

  created() {
    this.findHistoryList = JSON.parse(localStorage.getItem("searchInfo"));
    if (!this.findHistoryList) {
      this.findHistoryList = [];
      localStorage.setItem("searchInfo", JSON.stringify(this.findHistoryList));
    }
    if (!!this.$store.state.findKeyWord) {
      this.keyWord = this.$store.state.findKeyWord;
      this.current = this.$store.state.searchIndex;
      this.tabIndex = this.$store.state.searchIndex;
      this.listCurren = this.$store.state.searchIndex;
      // console.log(this.tabIndex)
      this.onSearch();
    } else {
      this.$nextTick(() => {
        this.$refs.inputfocus.focus();
      });
    }
    // 获取信息条高度
    this.stateHeight = parseInt(this.$store.state.stateHeight);
  },
  watch: {
    tabIndex(e, old) {
      this.current = e;
      this.$store.commit("updateIndex", this.current);
      // console.log(old)
    },
  },
};
</script>

<style scoped lang="less">
// 切换框
.tab {
  .tab_item {
    margin-right: 15px;
  }
}
/deep/.nomessage {
  margin-top: 236px;
}
/deep/.swiper-slide-active {
  // bottom: 7px !important;
  // font-size: 16px !important;
  font-weight: bold !important;
}
.globalSearch {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  overflow: hidden;
  .searchResult {
    height: 100%;
    overflow: hidden;
    .head-box {
      // 信息条
      .state-box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        // background-color: #15b7dd;
        background-color: #ffffff;
      }
      .search-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        // background-color: #15b7dd;
        background-color: #ffffff;
        color: #333;
        .center {
          padding: 0 15px;
          margin: 0 5px;
          flex: 1;
          border-radius: 20px;
          // background: #ffffff;
          background: #f7f7f9;
          overflow: hidden;
          display: flex;
          align-items: center;
          .search {
            font-weight: bold;
          }
          /deep/.van-cell {
            padding: 7px 8px;
            background: #f7f7f9;
            color: #666666;
          }
          .inputdemo {
            width: 100%;
            padding: 7px 8px;
            background: #f7f7f9;
            color: #666666;
            font-size: 14px;
            line-height: 22px;
            border: none;
          }
        }
        .search-btn {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          // color: #FFFFFF;
          color: #15b7dd;
          line-height: 36px;
        }
      }
    }
    .list-box {
      // margin-top: 10px;
      background: #ffffff;
      padding: 18px 18px 0 18px;
      // border-radius: 10px 10px 0px 0px;
      .scroll-Y {
        flex: 1;
        overflow-y: scroll;
        height: 100%;
        .dataItem {
          padding: 14px 0 19.5px;
          display: flex;
          border-bottom: 1px solid #eeeeee;
          &:last-child {
            border: 0;
          }
          image {
            width: 70px;
            height: 70px;
            border-radius: 5px;
          }
          .itemMessage {
            flex: 1;
            padding-left: 14px;
            display: flex;
            flex-direction: column;
            .title {
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: rgba(51, 51, 51, 1);
              line-height: 21px;
              -webkit-line-clamp: 1;
            }
            .describe {
              font-size: 12px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #666666;
              line-height: 18px;
              display: block;
              margin-top: 4px;
              margin-bottom: 7.5px;
            }
            .labels {
              margin-top: 11px;
              display: flex;
              .label {
                margin-right: 10px;
                padding: 1px 8px;
                border-radius: 5px;
                border: 1px solid #15b7dd;
                font-size: 11px;
                font-family: PingFangMedium;
                font-weight: 500;
                color: #15b7dd;
                line-height: 16.5px;
              }
            }
            .price {
              font-size: 12px;
              font-family: PingFangMedium;
              font-weight: bold;
              color: #ec6c01;
              line-height: 14px;
              margin-top: 4.5px;
            }
          }
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
      // 教育课程
      .edu-Y {
        /deep/ .classItem {
          padding: 0;
          padding-bottom: 18px;
        }
      }
      // 图书教具
      // .book-Y {
      //   /deep/ .bookItem {
      //     padding: 0;
      //   }
      // }
    }
  }
  .findHistory {
    flex: 1;
    background-color: #ffffff;
    margin-top: 15px;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 17.5px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftTitle {
        font-size: 13px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #666666;
        line-height: 19.5px;
        padding-left: 6.5px;
      }
    }
    .historyList {
      padding-top: 17px;
      display: flex;
      flex-wrap: wrap;
      .nomessage {
        width: 100%;
        padding-top: 100px;
      }
      .historyItem {
        padding: 2.5px 14px;
        border-radius: 14.5px;
        border: 1px solid #e5e5e5;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: rgba(153, 153, 153, 0.9);
        line-height: 18px;
        margin-right: 14px;
        white-space: nowrap;
        margin-bottom: 10px;
      }
    }
  }
  .nav {
    background: #15b7dd;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    // border-radius: 0 0 16px 16px;
    // padding-bottom: 4px;
    image {
      width: 18px;
    }
    text {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
    .inputView {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      margin-right: 9.5px;
      margin-left: 5px;
      padding: 7px 8.5px;
      border-radius: 8px;
      image {
        width: 17.5px;
      }
      .input {
        flex: 1;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
      }
    }
  }
}
/*
		修改列表的背景颜色或其他样式
	*/
/deep/ .scroll-view_hold {
  background: #f7f7f9;
  padding-top: 0;
}
/deep/.scroll-view-item {
  margin: 0 16px;
}
/deep/.van-popup {
  background-color: transparent;
}
.popup-content {
  background: #ffffff;
  width: 285.5px;
  border-radius: 10px;
  padding: 18px 10px 0;
  position: relative;

  .popup-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cover-img {
      width: 135px;
      height: 81px;
      border-radius: 9px;
    }

    .title {
      margin: 0 auto;
      margin-top: 21.5px;
      font-size: 20px;
      font-family: PingFangMedium;
      // font-weight: bold;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
    }

    .title-noimg {
      font-size: 20px;
      line-height: 30px;
    }

    .specs {
      // margin-top: 7px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #15b7dd;
      line-height: 24.5px;
    }

    .achievement-box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      .achievement-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33%;

        .achievement-top {
          font-size: 16.5px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 30px;
        }

        .achievement-span {
          font-size: 36px;
          font-family: PingFangMedium;
          font-weight: bold;
          line-height: 54.5px;
        }

        .achievement-pass {
          color: #15b7dd;
        }

        .achievement-fail {
          color: #ea3c31;
        }

        .achievement-bottom {
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 19px;
        }

        .achievement-passtips {
          color: #333333;
          font-size: 14.5px;
          line-height: 21.5px;
        }
      }
    }
  }

  .btn-inner {
    display: flex;
    justify-content: space-around;
    border-radius: 32px;

    .div {
      display: inline-block;
      border-radius: 32px;
      padding: 23.5px 10px;
      background: #fff;

      .all-btn-middle {
        width: 110.5px;
        height: 35.5px;
        line-height: 35.5px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

#app{
  background-color: #ffffff !important;
}
</style>
