// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/examination/Vector14.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".content[data-v-52ec9a70]{min-height:100vh;overflow:hidden;background-color:#fff}.top[data-v-52ec9a70]{font-size:12px;font-family:PingFang SC-Regular,PingFang SC;font-weight:400;color:#666;line-height:18px;padding:0 15px;padding-top:10px}.center[data-v-52ec9a70]{padding:0 15px;padding-top:16px}.gradebox[data-v-52ec9a70]{overflow:hidden;margin-bottom:14px}.gradebox .boxtop[data-v-52ec9a70]{display:flex;align-items:baseline}.gradebox .boxtop .img[data-v-52ec9a70]{width:18px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100%;text-align:center;font-size:11px;flex-shrink:0;font-family:PingFang SC-Regular,PingFang SC;color:#fff;line-height:17px}.gradebox .boxtop .txt[data-v-52ec9a70]{margin-left:5px;font-size:15px;font-family:PingFang SC-Regular,PingFang SC;font-weight:400;color:#000;line-height:27px}.gradebox .dlex[data-v-52ec9a70]{display:flex;align-items:center;justify-content:space-between}.gradebox .dlex .boxconter[data-v-52ec9a70]{margin-left:18px;margin-top:10px;opacity:1;display:flex;align-items:center}.gradebox .dlex .boxconter .fraction[data-v-52ec9a70]{margin:0;padding:0;width:20px;text-align:center;margin-right:16px}.gradebox .dlex .boxbottom[data-v-52ec9a70]{margin-right:35px}.gradebox .dlex .boxbottom ul[data-v-52ec9a70]{display:flex;align-items:center}.gradebox .dlex .boxbottom ul li[data-v-52ec9a70]{text-align:center;display:block;font-size:12px;font-family:PingFang SC-Regular,PingFang SC;color:#999}.gradebox .input[data-v-52ec9a70]{background:#f7f7f9}.gradebox .input[data-v-52ec9a70] .van-cell{background-color:#f7f7f9!important}.next-btn[data-v-52ec9a70]{text-align:center;padding-bottom:30px}.all-btn-bigCircle[data-v-52ec9a70]{border:1px solid #fff}", ""]);
// Exports
module.exports = exports;
