<template>
	<!-- 省市区 -->
	<div class="provinceCity">
    <van-cascader
      v-model="locationName"
      title="请选择所在地区"
      :field-names="{
        text: 'name', value: 'code', children: 'children'
      }"
      :options="fieldArray"
      @close="closeCityPop"
      @finish="onFinish"
      @change="onChangeField"
    />
	</div>
</template>

<script>
import { number } from 'echarts'
export default {
  props: {
    // 1机构查询
    pageType: {
      type: String,
      default: '',
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 选中项
    defaultIndex: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // pCode: 0, // 地址编码(用于查询下一级地址)
      // 选项列表，children 代表子选项，支持多级嵌套
      fieldArray: [],
      locationName: '',

      // 选中的数据
      pickValue: {},
    }
  },
  created () {
    if (!this.defaultIndex[0]) {
      // 省市区
      this.getAddress()
    } else {
      this.defaultIndex.unshift('0')
      // 回显省市区
      this.showAddress(0)
    }
  },
  methods: {
    // 获取省市区
    getAddress (selectedValue) {
      const code = selectedValue ? selectedValue.selectedOptions[selectedValue.tabIndex].code : 0
      // const targetOption = selectedValue.selectedOptions[selectedValue.tabIndex];
      // targetOption.loading = true;
      // targetOption.loading = true
      this.$ajax({
        url: '/hxclass-mobile/province-manage/manage/province/' + code,
        method: 'get'
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // targetOption.loading = false
          if (!selectedValue) {
            this.fieldArray = res.data
          } else if (res.data.length == 0) {
            this.$emit('onConfirm', selectedValue)
          } else {
            if (selectedValue.tabIndex === 0) {
              // 点第一层，给第二层赋值
              this.fieldArray.map((item, index) => {
                if (item.code == code) {
                  item.children = res.data
                }
              })
            } else {
              // 点第二层，给第三层赋值
              this.fieldArray.map(item => {
                if (item.code == selectedValue.selectedOptions[0].code) {
                  item.children.map(addr => {
                    if (addr.code == code) {
                      addr.children = res.data
                    }
                  })
                }
              })
            }
          }
        }
      })
    },
    // 回显省市区
    async showAddress(i) {
      
      // console.log(this.defaultIndex)
      // return

      // this.defaultIndex.map(async (item, index) => {
      //   await this.getNextAddress(item, index, this.defaultIndex.length)
      // })

      // for ( var i = 0 ; i < this.defaultIndex.length ; i++ ) {
      //   await this.getNextAddress(this.defaultIndex[i], i, this.defaultIndex.length).then(() => {
      //     // console.log(i)
      //   })
      // }

      // await this.getNextAddress(this.defaultIndex[i], i, this.defaultIndex.length).then(() => {
      //   // console.log(i)
      //   if (i < this.defaultIndex.length) {
      //     i ++
      //     this.showAddress(i)
      //   }
      // })

      if (this.defaultIndex.length == 3) {
        await this.getNextAddress(this.defaultIndex[0], 0)
        await this.getNextAddress(this.defaultIndex[1], 1)
        await this.getNextAddress(this.defaultIndex[2], 2)
        this.locationName = this.defaultIndex[2]
      } else if (this.defaultIndex.length == 4) {
        await this.getNextAddress(this.defaultIndex[0], 0)
        await this.getNextAddress(this.defaultIndex[1], 1)
        await this.getNextAddress(this.defaultIndex[2], 2)
        await this.getNextAddress(this.defaultIndex[3], 3)
        this.locationName = this.defaultIndex[3]
      }
      
    },
    async getNextAddress(item, index, length) {
      await this.$ajax({
        url: '/hxclass-mobile/province-manage/manage/province/' + item,
        method: 'get'
      }).then((res) => {
        // console.log(res,index)
        if (res.code == 200 && res.success) {
          if (index == 0) {
            // console.log('第一层')
            this.fieldArray = res.data
            // console.log(this.fieldArray)
          } else if (index == 1) {
            this.fieldArray.map((itemI, indexI) => {
              if (itemI.code == this.defaultIndex[1]) {
                itemI.children = res.data
                this.locationName = item
                // console.log(this.locationName,itemI)
              }
            })
          } else if (index == 2) {
            this.fieldArray.map((itemI, indexI) => {
              if (itemI.code == this.defaultIndex[1]) {
                itemI.children.map((itemII, indexII) => {
                  if (itemII.code == this.defaultIndex[2]) {
                    itemII.children = res.data
                  }
                })
              }
            })
          }
        }
      })
    },
    // 选择后改变
    onChangeField (value) {
      // 机构查询
      if (this.pageType == 1) {
        this.pickValue = value
      }
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish (value) {
      this.pickValue = value
      if (value.tabIndex == 2) {
        this.$emit('onConfirm', value)
        return
      }
      // 获取下一级
      // this.pCode = value.selectedOptions[value.tabIndex].code
      this.getAddress(value)
    },
    // 关闭弹框
    closeCityPop() {
      // 机构查询
      if (this.pageType == 1) {
        this.$emit('onConfirm', this.pickValue)
        return
      }
      this.$emit('closeCityPop', false)
    }
  }
}
</script>

<style scoped lang="less">
  .provinceCity {
    background: #fff;
  }
</style>
