export default[
  { // 学员作业列表
    path: 'task',
    component: () => import('@/views/task/index.vue')
  },
  { // 学员作业详情
    path: 'homeWorkDetail',
    component: () => import('@/views/task/homeWorkDetail.vue')
  },
  { // 学员提交作业作业
    path: 'submitHomeWork',
    component: () => import('@/views/task/submitHomeWork.vue')
  },
]