<template>
	<div class="courseOrder">
		<!-- 导航栏 -->
		<van-nav-bar
      class="nav-circular"
      title="订单详情"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
		<div class="orderContent">
			<div class="course">
				<div class="courseTitle">
					<span class="line"></span>
					<span>课程一</span>
				</div>
				<div class="item">
					<div class="left">
						<img class="itemImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0f088716caa5d1e0b583dd8709a9dcf1685f1a852ebe94106facb645d022284d" />
					</div>
					<div class="right">
						<span>孤独症康复教育上岗培训课程</span>
						<span class="price">¥2345.00</span>
					</div>
				</div>
				<div class="choiceTime" @click="popUp">
					<div class="left">
						<span><span style="color: #EC6C01;">*</span>选择开课时间</span>
					</div>
					<div class="right">
						<span>请选择开课时间</span>
						<van-icon name="arrow" size="10" color="#999"/>
					</div>
				</div>
			</div>
			<div class="teachingAids">
				<div class="courseTitle">
					<span class="line"></span>
					<span>图书教具</span>
				</div>
				<div class="item">
					<div class="left">
						<img class="itemImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0f088716caa5d1e0b583dd8709a9dcf1685f1a852ebe94106facb645d022284d" />
					</div>
					<div class="right">
						<span>孤独症康复教育上岗培训课程</span>
						<div class="price">
							<span>¥2345.00</span>
							<van-stepper theme="round" v-model="value" min="1" max="10" />
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 提交按钮 -->
		<div class="orderbutton">
			<span class="btn all-btn-bigCircle">提 交</span>
		</div>
		
		<!-- 选择开课时间 -->
		<n-transition ref="pop" speed="ease-in-out" :height="561" :maskVal="0.35">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">请选择开课时间</span>
						<van-icon name="cross" size="20" color="#999" @click="close"/>
					</div>
					<div class="popContent">
						<div class="popItem">
							<div class="left"  v-for="(item,index) in list" @click="selected(item)" :key="index" :class="{pick: activeName == item}">
								<span class="up">{{item.name}}</span>
								<span class="center">{{item.time}}</span>
								<span class="down">剩余：<span class="num">{{item.num}}</span></span>
							</div>
						</div>
					</div>
				</div>
				<div class="popbutton">
					<span class="btn all-btn-bigCircle">确 定</span>
				</div>
			</div>
		</n-transition>
	</div>
</template>

<script>
	import nTransition from '@/components/n-transition/n-transition.vue'
	export default {
	// 可用组件的哈希表
		components: { nTransition },
	// 接收传值
		props: {},
		// 数据对象
		data () {
			return {
				value: 1,
				activeName: '',
				list:[{
					name:'2021年 第2期：',
					time:'2020/12/15-2020/12/15',
					num:'233'
				},{
					name:'2021年 第2期：',
					time:'2020/12/15-2020/12/15',
					num:'233'
				},{
					name:'2021年 第2期：',
					time:'2020/12/15-2020/12/15',
					num:'233'
				},{
					name:'2021年 第2期：',
					time:'2020/12/15-2020/12/15',
					num:'233'
				},{
					name:'2021年 第2期：',
					time:'2020/12/15-2020/12/15',
					num:'233'
				}]
			}
		},
		// 事件处理器
		methods: {
			popUp () {
				this.$refs['pop'].show()
			},
			close(){
				this.$refs['pop'].hide()
			},
			selected: function(gameName) {
				this.activeName = gameName
			}
		},
	}
</script>

<style lang="less">
	page {
	    background-color: #F7F7F9;
	}
	.courseOrder{
		.orderContent{
			padding: 0 18px;
			.courseTitle{
				margin-top: 16.5px;
				span{
					font-weight: bold;
				}
			}
			.item{
				padding: 12px;
				background: #FFFFFF;
				margin-top: 12px;
				border-radius: 8px;
				display: flex;
				.left{
					width: 124.5px;
					height: 88px;
					.itemImg{
						width: 124.5px;
						height: 88px;
					}
				}
				.right{
					padding: 9px 8px 0 8px;
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					.price{
						display: flex;
						font-size: 16px;
						font-weight: 400;
						font-family: PingFangMedium;
						color: #EC6C01;
						line-height: 16px;
						margin-top: 12px;
						span{
							flex: 1;
						}
					}
				}
			}
			.choiceTime{
				margin-top: 12px;
				padding: 16px 12px;
				background: #FFFFFF;
				border-radius: 8px;
				display: flex;
				line-height: 22px;
				.left{
					flex: 1;
					font-size: 16px;
					font-weight: 400;
					color: #333;
				}
				.right{
					font-size: 12px;
					font-weight: 400;
					color: #666666;
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.popContentBox{
			.popBox{
				.titleBox{
					padding: 28px 34px 0 34px;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 25px;
					display: flex;
					span{
						display: inline-block;
					}
					.title{
						flex: 1;
					}
				}
				.popContent{
					clear: both;
					.popItem{
						padding: 0 18px;
						// display: flex;
						margin-top: 12px;
						clear: both;
						.left{
							float: left;
							text-align: center;
							font-size: 12px;
							font-weight: 400;
							color: #333333;
							width: 165px;
							height: 60px;
							background: #FFFFFF;
							box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
							border-radius: 8px;
							padding: 12px 0px;
							margin-bottom: 12px;
							span{
								display: block;
								line-height: 20px;
							}
							.up{
								font-size: 14px;
								font-weight: bold;
							}
							.center{
								color: #666;
							}
							.down{
								.num{
									display: inline-block;
									color: #15B7DD;
								}
							}
							&.pick {
								background: #15B7DD;
								color: #FFFFFF;
								box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
								.num{
									color: #FFFFFF;
								}
								.center{
									color: #fff;
								}
							}
						}
						.left:nth-child(odd){
							margin-right: 9px;
						}
					}
					.popItem:first-child{
						margin-top: 28px;
					}
				}
			}
			.popbutton{
				box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				padding: 5px 0;
				position: fixed;
				width: 100%;
				bottom: 0;
				.btn{
					width: 295px;
					height: 40px;
					display: block;
					text-align: center;
					margin: 0 auto;
					line-height: 40px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
				}
				
			}
		}
		.orderbutton{
			box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
			padding: 5px 0;
			position: fixed;
			width: 100%;
			bottom: 0;
			.btn{
				width: 295px;
				height: 40px;
				display: block;
				text-align: center;
				margin: 0 auto;
				line-height: 40px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
	}
</style>
