import { render, staticRenderFns } from "./detailedList.vue?vue&type=template&id=318e9af0&scoped=true&"
import script from "./detailedList.vue?vue&type=script&lang=js&"
export * from "./detailedList.vue?vue&type=script&lang=js&"
import style0 from "./detailedList.vue?vue&type=style&index=0&id=318e9af0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318e9af0",
  null
  
)

export default component.exports