<template>
  <div class="container-box" id="container-box">
    <van-nav-bar
      class="nav-circular"
      title="提交资质"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <!-- 提示 -->
      <div class="tips-box">
        <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
        <div class="tips-txt">提示：{{titleMessage}}</div>
      </div>
      <div class="line-item">
        <span class="name required">相关资质：</span>
        <div class="side-right">
          <div class="upload-img">
            <div class="camera">
              <van-uploader v-if="pageType==1 || pageType==3" :before-read="beforeRead" :after-read="uploadImg" @oversize="onOversize">
                <img class="camera-icon" v-if="picture==''" src="@/assets/image/icon/20220215041507.png" />
                <span class="camera-txt" v-if="picture==''">上传图片</span>
                <img class="list_upload_img" :src="picture" v-else />
              </van-uploader>
              <div v-if="pageType==2" @click="onPhoto">
                <img class="camera-icon" v-if="picture==''" src="@/assets/image/icon/20220215041507.png" />
                <span class="camera-txt" v-if="picture==''">上传图片</span>
                <img class="list_upload_img" :src="picture" v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box_list column_box">
        <div class="list_title">上传资质</div>
        <van-uploader :after-read="uploadImg">
          <div class="list_upload" v-if="picture==''">
            <img class="upload_icon" src="@/assets/image/study/cer_upload.png" />
            <span class="upload_txt">上传照片</span>
          </div>
          <img class="list_upload_img" :src="picture" v-else />
        </van-uploader>
      </div> -->
      <!-- <div class="line-item">
        <span class="name required" style="line-height:36px;">资质类型：</span>
        <div class="side-right">
          <div class="right_box" @click="showPicker = true">
            <van-field
              readonly
              :value="value"
              placeholder="选择资质类型"
            />
            <van-icon class="box_icon" name="arrow" size="12px" color="#999999" />
          </div>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </div>
      </div> -->
      <div class="line-item">
        <span class="name">补充说明：</span>
        <div class="side-right">
          <van-field
            rows="3"
            autosize
            class="spanarea"
            label=""
            type="textarea"
            placeholder=""
            v-model="textarea"
          />
        </div>
      </div>
       <div class="line-item">
        <span class="name">证书编号：</span>
        <div class="side-right">
          <van-field
            placeholder="请输入证书编号"
            v-model="certificateCode"
          />
        </div>
      </div>
      <div class="line-item">
        <span class="name" style="width:80px;"> </span>
        <div class="side-right">
          <!-- 安卓、浏览器 -->
          <van-uploader v-if="pageType==1 || pageType==3" :after-read="uploadFile" accept="file">
          <!-- <van-uploader :after-read="uploadFile" accept="file"> -->
            <img v-if="!file" class="upload-image" src="@/assets/image/icon/seniority_icon1.png" alt="">
            <span v-if="!file" class="upload-feil">点击上传文件</span>
            <span v-if="file">{{fileName}}</span>
          </van-uploader>
          <!-- ios -->
          <div v-if="pageType==2" @click="onUploadFile">
            <img v-if="!file" class="upload-image" src="@/assets/image/icon/seniority_icon1.png" alt="">
            <span v-if="!file" class="upload-feil">点击上传文件</span>
            <span v-if="file">{{fileName}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="onSave-foo" :style="{top: (clientHeight-73)+'px'}">
      <span class="all-btn-bigCircle" @click="commitBuyAudit">提交</span>
    </div>
		<van-popup v-model="onPopupOk">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con" v-html="message"></div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="onPopupOk=false,$onReturn($route.query.fromMenu == '1')">我知道了</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { getFileFromUrl, dataURLtoFile, getClientHeight, FunJudgDevice } from "@/unit/fun.js";
  import { onChoosePhoto} from '@/assets/js/NativeWebKit.js';
  const DeviceType = FunJudgDevice()
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        pageType: 0, //1安卓 2ios 3浏览器

        titleMessage:'',
        message:'',
        onPopupOk:false,
        textarea:'', // 补充说明
        certificateCode:'', // 证书编号
        picture:'',
        file:'',
        fileName:'',
        value: '',
        selectIndex: '',
        showPicker: false,
        columns: [],
        optionList: [], // 考试资质列表
        str:'',
        clientHeight: 0, // 视口高度
      }
		},
    created(){
      // 安卓
      if (DeviceType == 'android') {
        this.pageType = 1
      } else if (DeviceType == 'ios' && window.webkit) {     // ios
        this.pageType = 2
      } else {     // 浏览器
        this.pageType = 3
      }
      
      // ios回调文件
      window.returnFileUrl = (url, name,type)=>{
        // console.log('返回文件地址', url, name)
        var file = null
        let _this = this
        getFileFromUrl(url, name)
        .then((response)=>{
          // 上传文件
          _this.uploadFile(response, 1)
        })
        .catch((e)=>{
          console.error(e)
        });
      }

      // 安卓选择照片后回调
      window.returnPhotoUrl = (url) => {
        // console.log(url)
        var file = dataURLtoFile(url, 'fileName.jpg');
        // 上传图片
        this.uploadImg(file, 1);
      };
      this.getTitleMessage();
      this.getOptionList();
    },
    mounted(){
      this.clientHeight = getClientHeight();
    },
		// 事件处理器
		methods: {
      // 获取提示信息
      getTitleMessage(){
        this.$ajax({
          url:'/hxclass-mobile/course/course-buy-audit/buy-prompt-content',
          method:'get',
          params:{
            courseId:this.$route.query.courseId
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.titleMessage = res.message;
          }
        })
      },
      // 打开相册
      onPhoto() {
        // 1裁剪图片
        window.webkit.messageHandlers.photo.postMessage(' ')
        // 1裁剪 0不裁剪
        // 1方的不能随意拖动 0可以随意拖动
        // onChoosePhoto(' ')
      },
      // ios上传文件
      onUploadFile() {
        window.webkit.messageHandlers.file.postMessage('');
      },
      // 提交资质
      commitBuyAudit(){
        // console.log(this.selectIndex)
        if(!this.picture){
          return this.$Toast('请上传相关资质')
        }
        // else if(this.selectIndex === ''){
        //   return this.$Toast('请选择资质类型')
        // }

        // 进阶默认选初阶 高阶选进阶
        let tempId = null;
        this.optionList.map(item=>{
          if(this.$route.query.courseId == 4) {
            if(item.name == 'VB-MAPP评估培训合格证书（初阶）') {
              tempId = item.tempId;
            }
          }else if(this.$route.query.courseId == 40){
            if(item.name == 'VB-MAPP评估培训合格证书（进阶）') {
              tempId = item.tempId;
            }
          }
        })
        // if (!this.certificateCode) {
        //   return this.$Toast('请填写证书编号')
        // }
        this.$ajax({
          url:'/hxclass-mobile/course/course-buy-audit/commit',
          method:'post',
          params:{
            certifTempId: tempId, // 资质模板id
            content: this.textarea, // 内容
            courseId: this.$route.query.courseId, // 课程id
            relevantFile: this.file, // 相关文件
            relevantFileName: this.fileName, // 相关文件名称
            relevantProof: this.picture, // 相关证明
            userId: this.$store.state.userInfo.userId, // 用户id
            code:this.certificateCode, //
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.onPopupOk = true;
            this.message = res.message;
          }else{
            return this.$Toast(res.message);
          }
        })
      },
      // 获取资质列表
      getOptionList(){
        this.$ajax({
          url:'/hxclass-mobile/user-certif/certif-temp/select/option?type=1',
          method:'post',
          params:{
            
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.optionList = res.data;
            this.columns = res.data.map(item=>{
              return item.name;
            })
          }else{
            this.$Toast(res.message);
          }
        })
      },
      afterRead(file) {
        // 此时可以自行将文件上传至服务器
        // console.log(file);
      },
      onConfirm(value,index) {
        this.value = value;
        this.selectIndex = index;
        this.showPicker = false;
      },
      // 上传文件
      uploadFile(e, type) {
        // type 1安卓上传
        let file = type == 1 ? e : e.file
        this.fileName = file.name;
        // return
        this.$upload({
          file: file,
          type: 'file',
          progress: (e)=>{
          },
          success: (e)=>{
            // console.log(e)
            this.file = e;
            // 
          }
        })
      },
      // 上传图片
      uploadImg(e, type) {
        // type 1安卓上传
        let file = type == 1 ? e : e.file
        this.$upload({
          file: file,
          type: 'image',
          progress: (e)=>{
          },
          success: (e)=>{
            this.picture = e;
          }
        })
      },
      beforeRead(file){
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";
        if (!isJpgOrPng) {
          this.$Toast("请上传正确格式图片文件");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$Toast("图片内存大小需在2MB之内");
        }
        return isJpgOrPng && isLt2M;
      },
      // 文件超出大小提示
      onOversize(file) {
        // console.log(file);
        this.$Toast("文件大小不能超过 500kb");
      },
    },
	}
</script>

<style>
  page{
    background: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  .content-box{
    padding: 18px;
    .tips-box{
      display: flex;
      .tips-icon{
        position: relative;
        top: 2.5px;
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
      .tips-txt{
        flex: 1;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
      }
    }
    .line-item{
      display: flex;
      margin-top: 18px;
      font-family: PingFangMedium;
      .name{
        white-space: nowrap;
        width: 90px;
        text-align: right;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }
      .side-right{
        flex: 1;
        align-items: center;
        display: flex;
        .upload-image {
          position: relative;
          top: 3px;
          width: 17px;
          margin-right: 6px;
        }
        .right_box {
          position: relative;
          display: inline-block;
          width: 100%;
          .box_icon {
            position: absolute;
            right: 12px;
            top: 12px;
          }
          /deep/.van-cell {
            border-radius: 5px;
            padding: 5px 10px;
          }
        }
        .spanarea{
          height: auto;
          // width: calc(100% - 36px);
          background: #FFFFFF;
          border-radius: 5px;
          padding: 12px 18px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
        .upload-img{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180.5px;
          height: 135.5px;
          background: #F2F2F2;
          border-radius: 3px;
          overflow: hidden;
          .camera-icon{
            width: 56px;
            height: 56px;
          }
          .camera-txt{
            display: block;
            position: relative;
            bottom: 17.5px;
            right: 2.5px;
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            margin-top: 10px;
          }
          .list_upload_img {
            box-sizing: border-box;
            width: 100%;
            margin-top: 12.5px;
          }
        }
        .upload-feil{
          font-size: 14px;
          font-weight: 500;
          color: #15B7DD;
          line-height: 21px;
        }
      }
    }
  }
  // 提交按钮
  .onSave-foo{
    position: absolute;
    // bottom: 35px;
    width: 100%;
    text-align: center;
  }
  // 必填标识
  .required::before {
    content: "*";
    display: inline-block;
    color: red;
    position: relative;
    top: 2px;
    margin-right: 2px;
  }
  // 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
    }
    .popup_button_center {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
</style>
