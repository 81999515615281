import axios from 'axios'
import config from './config.js'
import store from '../store';
import ajaxWhiteList from '@/unit/ajaxWhiteList.js';
// import { Toast } from 'vant'

let ajaxCount = 0
let payUrl = '/hxclass-mobile/order/is-pay' //支付回调接口

// 是否取消重复的请求
let cancelDuplicated = true
// 存储每个请求的标识和取消的函数
let pendingAjax = new Map()

axios.defaults.timeout = 300000
axios.defaults.headers.post['userId'] = 1
axios.defaults.baseURL = `${window.location.origin}/${config.baseUrl}`

// 检测类型
const isFunction = (obj)=> {return typeof obj === 'function'}

// 生成重复标识的方式
const duplicatedKeyFn = (obj)=> {
  return isFunction(obj) ? obj : (config) => `${config.method}${config.url}`
}

// let duplicatedKeyFn = isFunction(duplicatedKeyFn) ? duplicatedKeyFn : (config) => `${config.method}${config.url}`

// 请求
const ajax = (options) => {
  // console.log(options, ajaxWhiteList.indexOf(options.url))
  // 打开loading
  if (ajaxCount==0 && ajaxWhiteList.indexOf(options.url) == -1 && options.url.indexOf(payUrl) == -1) {
    store.commit('updateAppLoad', true)
  }
  if (ajaxWhiteList.indexOf(options.url) == -1 && options.url.indexOf(payUrl) == -1) {
    ajaxCount++
  }
  // console.log(ajaxCount)
  
  if(!!localStorage.getItem('userInfo')){
    let json = JSON.parse(localStorage.getItem('userInfo'))
    axios.defaults.headers.common['Authorization'] = json.token
  }else{
    axios.defaults.headers.common['Authorization'] = ''
  }

  options.method = options.method || 'get'
  if (options.method.toLocaleLowerCase() === 'get') {
    // get请求
    return new Promise((resolve, reject) => {
      axios.get(options.url, { params: options.params || {} }).then(response => {
        resolve(response)
      }).catch(error => {
        removePending(error.config)
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'post') {
    // post请求
    return new Promise((resolve, reject) => {
      axios.post(options.url, (options.params || {})).then(response => {
        resolve(response)
      }).catch(error => {
        removePending(error.config)
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'put') {
    // put请求
    return new Promise((resolve, reject) => {
      axios.put(options.url, (options.params || {})).then(response => {
        resolve(response)
      }).catch(error => {
        removePending(error.config)
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'delete') {
    // delete请求
    return new Promise((resolve, reject) => {
      axios.delete(options.url, (options.params || {})).then(response => {
        resolve(response)
      }).catch(error => {
        removePending(error.config)
        reject(error)
      })
    })
  }
}

// 格式化参数
const formatParam = (params) => {
  const p = {}
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== 'undefined') {
      p[`${key}`] = params[key]
    }
  }
  return p
}

const removePendingAjax = (request) => {
  if(!cancelDuplicated)return
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    // duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    duplicatedKey: veryConfig.duplicatedKey,
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  // 如果pendingAjax中存在当前请求, 取消当前请求并将其删除
  if (duplicatedKey && pendingAjax.has(duplicatedKey)) {
    // const cancel = pendingAjax.get(duplicatedKey);
    // cancel(duplicatedKey)
    pendingAjax.delete(duplicatedKey);
  }
}

const addPendingAjax = (request) => {
  if(!cancelDuplicated)return
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    // duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    duplicatedKey: veryConfig.duplicatedKey,
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  request.cancelToken = request.cancelToken || new axios.CancelToken((cancel) => {
    // 如果pendingAjax中不存在当前请求，添加进去
    if (duplicatedKey && !pendingAjax.has(duplicatedKey)) {
      pendingAjax.set(duplicatedKey, cancel);
    }
  });
}

const removePending = (request) => {
  if(!request)return
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    // duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    duplicatedKey: veryConfig.duplicatedKey,
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  pendingAjax.delete(duplicatedKey);
}

// 请求拦截器 - 请求前数据处理
axios.interceptors.request.use(request => {
  // 在请求开始之前检查先前的请求
  removePendingAjax(request)
  // 将当前请求添加到pendingAjax
  addPendingAjax(request)

  // get请求添加时间戳，防止IE下缓存
  if (request.method.toLocaleLowerCase() === 'get') {
    request.params = formatParam(request.params)
    request.params._t = new Date().getTime()
  }
  return request
}, error => {
  return Promise.reject(error)
})

// 响应拦截器 - 返回数据处理
axios.interceptors.response.use(response => {
  // console.log(response)
  if (ajaxWhiteList.indexOf(response.config.url) == -1 && response.config.url.indexOf(payUrl) == -1) {
    ajaxCount--
  }
  // console.log(ajaxCount)
  if (ajaxCount==0) {
    store.commit('updateAppLoad', false)
  }

  // 请求完成之后去掉该请求记录
  removePending(response.config)

  if (response.state && (response.state === '405' || response.state === '406')) {
  }
  // token过期
  else if (response.data && (response.data.code === 401)) {
    store.commit('clearUserInfo')
    localStorage.setItem('searchInfo', JSON.stringify(''))
    return response.data
  }
   else {
    // 存入token
    if (!!response.headers.token) {
      store.commit("changeToken", response.headers.token);
    }
    return response.data
  }
}, error => {
  let ajaxUrl = error.response.config.url
  if (ajaxWhiteList.indexOf(ajaxUrl) == -1 && ajaxUrl.indexOf(payUrl) == -1) {
    ajaxCount--
  }
  // ajaxCount--
  if (ajaxCount==0) {
    store.commit('updateAppLoad', false)
  }
  
  const err = error.response
  if(err){
    let obj = {
      data: err.data,
      status: err.status,
      statusText: err.statusText,
      config: JSON.parse(JSON.stringify(err.config))
    }
    throw obj
  }else{
    throw error
  }
  // 类型是否为重复请求
  let isDuplicatedType;
  try {
    const errorType = (JSON.parse(error.message) || {}).type
    isDuplicatedType = errorType === 'REQUEST_TYPE.DUPLICATED_REQUEST'
  } catch (error) {
    isDuplicatedType = false
  }
  if (isDuplicatedType) return false
})

export default ajax
