<template>
  <van-popup v-model="visible" :close-on-click-overlay="true" :close-on-popstate="true" :closeable="true" @close="closeVideoModal">
    <img class="play-video" :src="src" alt=""/>
  </van-popup>
</template>

<script>
export default {
  props:['show','src'],
  data(){
    return {
      visible: false
    }
  },
  created() {
    this.visible = this.show;
  },
  mounted() {
    
  },
  methods: {
    // 子组件中关闭弹窗方法
    closeVideoModal() {
      this.$emit("closeModel", false);
    }
  }
}
</script>

<style lang="less" scoped>
  ::v-deep.van-popup{
    background-color: inherit;
  }
  .play-video {
    object-fit: contain;
    width: 100vw;
    // height: 99.5vh;
  }
</style>