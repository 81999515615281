<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="直播列表"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="onClickLeft"
    />
    <div class="banner-box" >
      <div class="banner-innerbox">
        <div class="banner-header">
          <div class="banner-hleft">
            <span class="text text-bold">最新直播</span>
            <span class="text banner-date">{{data.date}}</span>
          </div>
          <div
            class="text nostart"
            :class="status == 0?'nostart':status == 1?'living':'playback'"
          >
            <div class="live-picbox">
              <img
                v-if="status == 1"
                class="live-pic"
                src="../../assets/image/live/pic_livelist.png"
              >
            </div>
            {{status == 0?'未开播':status == 1?'直播中':'回放'}}
          </div>
        </div>
        <div class="banner-photobox">
          <van-image
            class="banner-photo"
            fit="cover"
            :src="data.photo"
          />
        </div>
        <div class="banner-footer">
          <div class="banner-fleft">
            <div class="text text-bold">
              {{data.title}}
            </div>
            <div class="text banner-bottom">
              {{data.time}}
            </div>
          </div>
          <div class="banner-fright">
            <span class="all-btn-small" @click="toLiveDetail()">观看</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="main-nav">
        <div
          :class="liveTab=='0'?'nav-hover':'nav-default'"
          @click="onLive(0)"
        >直播预告</div>
        <div
          :class="liveTab=='1'?'nav-hover':'nav-default'"
          @click="onLive(1)"
        >直播回放</div>
      </div>

      <div class="main-list">
        <div
          v-for="(item,index) in liveList"
          :key="index"
          class="list"
          @click="toLiveDetail()"
        >
          <div class="list-photobox">
            <van-image
              class="list-photo"
              fit="cover"
              :src="item.photo"
            />
            <div class="list-label">
              <img class="label-img" src="@/assets/image/live/pic_icon1.png" alt="">
              <span class="label-name">未开播</span>
            </div>
          </div>
          <div class="list-content">
            <div class="list-header">
              <div class="list-left">
                <!-- <div class="list-top">
                  <div
                    class="text nostart"
                    :class="liveTab == 0?'nostart':'playback'"
                  >
                    {{liveTab == 0?'未开播':'回放'}}
                  </div>
                </div> -->
                <div class="list-bottom text text-bold">
                  {{item.title}}
                </div>
              </div>
              <!-- <div class="list-right">
                <span class="all-btn-small" @click="toLiveDetail()">{{liveTab == 0?'预约':'回放'}}</span>
              </div> -->
            </div>
            <div class="list-footer text">
              {{item.time}}
            </div>
            <div class="list-price">
              <div class="price-box">
                <span>¥</span>
                <span class="box-p">12.</span>
                <span>00</span>
              </div>
              <div class="list-right">
                <span class="all-btn-small" @click="toLiveDetail()">{{liveTab == 0?'预约':'回放'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示信息 -->
		<van-popup v-model="hintShow">
			<div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="30" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">您是否确认取消机构绑定？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="hintShow=false">取消</span>
          <span class="all-btn-middle btn2" @click="hintShow=false">确认</span>
        </div>
      </div>
		</van-popup>

  </div>
</template>

<script>
  export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        hintShow: false,
        liveTab: 0,
        list: [{
          title: '直播预告'
        }, {
          title: '直播回放'
        }],
        status: 1, //0:未开播，1：直播中，2：回放
        liveList: null,
        data: {
          date: '1月23日20:01',
          photo: null,
          title: '孤独症康复教育',
          time: '2021年12月23日 20:00',
          heraldList: [],
          backList: []

        }
      }
    },
    // 事件处理器
    methods: {
      // 导航返回键
      onClickLeft(){
        this.$onReturn(this.$route.query.fromMenu == '1');
      },
      toLiveDetail(){
        if (this.liveTab == 1) {
          this.hintShow = true
          return
        }
         this.$router.push({
          path: '/live/liveDetail'
        })
      },
      onLive(e) {
        this.liveTab = e
        if (this.liveTab == 0) {
          this.liveList = this.data.heraldList
        } else {
          this.liveList = this.data.backList
        }
      }
    },

    // 生命周期-实例创建完成后调用
    created() {
        this.liveList = this.data.heraldList
    },
    // 生命周期-实例挂载后调用
    mounted() {},
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {}
  }

</script>


<style lang="less" scoped>
.container-box {
  /deep/.van-popup {
    background-color: transparent;
  }

  .text {
    font-size: 12px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 14px;
  }

  .text-bold {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  .all-btn-small {
    width: 60px;
  }

  .nostart {
    width: 66.50px;
    height: 23px;
    // background: rgba(255, 194, 67, 0.2);
    border-radius: 5.5px;
    font-size: 13px;
    color: #f0a300;
    text-align: center;
    line-height: 23px;
  }

  .living {
    // background: rgba(138, 193, 27, 0.2);
    color: #15B7DD;

    .live-picbox {
      display: inline-block;
      margin-left: 4px;
      vertical-align: middle;
    }

    .live-pic {
      width: 12px;
      height: 12px;
    }
  }

  .playback {
    background: #e5e5e5;
    color: #666666;
  }

  .banner-box {
    margin: 0 0 18px;
    width: 100%;
    // background: #ffffff;
    border-radius: 20px;
    overflow: hidden;

    .banner-innerbox {
      margin: 14px 18px;

      .banner-header {
        display: flex;
        justify-content: space-between;

        .banner-hleft {
          .banner-date {
            margin-left: 14px;
          }
        }
      }

      .banner-photobox {
        margin-top: 17.5px;
        box-shadow: 0 2.5px 2.5px 1px rgba(93, 131, 167, 0.1);

        .banner-photo {
          width: 100%;
          height: 221px;
        }
      }

      .banner-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .banner-fleft {
          .banner-bottom {
            margin-top: 2px;
            font-size: 12px;
            font-weight: 500;
            color: #000000;
            line-height: 14px;
          }
        }
      }
    }
  }

  .main-box {
    margin: 0 18px;

    .main-nav {
      .nav-default {
        display: inline-block;
        margin-right: 21.5px;
        height: 16.5px;
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #666666;
        line-height: 24px;
      }

      .nav-hover {
        display: inline-block;
        margin-right: 28px;
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
        background-image: url(../../assets/image/examination/certificate_icon1.png);
        background-size: 40.5px;
        background-repeat: no-repeat;
        background-position: bottom center;
      }
    }

    .main-list {
      .list {
        display: flex;
        margin-top: 18px;
        padding-bottom: 14px;
        border-bottom: 1px solid #eeeeee;

        .list-photobox {
          position: relative;
          margin-right: 12px;

          .list-photo {
            width: 126px;
            height: 89px;
            border-radius: 3px;
            background: #c4c4c4;
            box-shadow: 0 2.5px 2.5px 1px rgba(93, 131, 167, 0.1);
            img {
              border-radius: 3px;
            }
          }
          .list-label {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 3px 0 3px 0;
            background: rgba(0, 0, 0, 0.35);
            opacity: 0.8;
            font-size: 10px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 15px;
            padding: 3px 4px;
            .label-img {
              width: 12px;
              vertical-align: middle;
            }
            .label-name {
              vertical-align: middle;
              margin-left: 2px;
            }
          }
        }

        .list-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          .list-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .list-left {
              margin-right: 8px;

              .list-top {
              }

              .list-bottom {
                // margin-top: 10px;
              }
            }

            .list-right {
            }
          }

          .list-footer {
            margin-top: 8px;
            color: #666666;
          }
          .list-price {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .price-box {
              font-size: 10px;
              font-family: PingFangMedium;
              font-weight: bold;
              color: #FF0000;
              vertical-align: bottom;
              padding-bottom: 3px;
              .box-p {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  // 提示信息
  .box_popup {
    position: relative;
    width: 305px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 16px;
    box-sizing: border-box;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #eeeeee;
        box-shadow: none;
      }
    }
  }
}
</style>
