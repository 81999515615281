<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      :title="$route.query.source == 'selected'?'精品课程':'证书课程'"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <van-tabs color="#F7F7F9" background="#F7F7F9" v-model="tabIndex">
      <van-tab
        v-for="(item, index) in categoryList"
        :title="item.name"
        :key="index"
      >
        <div class="scroll-view" v-if="item.childList && item.childList.length > 0">
          <div
            class="scroll-item"
            :class="{ 'score-hover': scoreIndex == ite.courseCategoryId }"
            @click="changescoreIndex(ite.courseCategoryId)"
            v-for="(ite, ind) in item.childList"
            :key="ind"
          >
            {{ ite.name }}
          </div>
        </div>
        <!-- 课程列表 -->
        <div class="layout" :style="{height: `calc(100vh - ${154.5 + $store.state.stateHeight + (item.childList && item.childList.length > 0?0:-33.5)}px`}">
          <no-message
            v-if="manageList.length == 0 && visible"
            type="1"
          ></no-message>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
          >
            <template v-if="!(manageList.length == 0 && visible)">
              <div
                class="layout-item"
                @click="goDetail(item.courseId)"
                v-for="(item, index) in manageList"
                :key="index"
              >
                <div class="side-left">
                  <img class="cover-img" :src="item.picture" />
                </div>
                <div class="side-right">
                  <div class="h4 all-font-ellipsis">
                    {{ item.courseName }}
                  </div>
                  <template v-if="item.categoryName != '线下培训'">
                    <div class="tag">
                      {{ item.categoryName }}丨视频数量：{{ item.classHour }}个
                    </div>
                    <div class="tag term all-font-ellipsis">
                      {{ item.introduction }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="tag term all-font-ellipsis lineDownCourse" v-if="setTime(item)">
                      <img class="icon_courseTime" src="@/assets/image/icon/icon_courseTime.png" alt=""><span class="time">{{ setTime(item) }}</span>
                    </div>
                    <div class="tag term all-font-ellipsis lineDownCourse">
                      <img class="icon_address" src="@/assets/image/icon/icon_address.png" alt=""><span class="address">{{ item.province }} {{ item.city }} | {{ item.district }}</span>
                    </div>
                  </template>
                  <div class="foo">
                    <div class="price">
                      <!-- {{item.couponPrice === 0?'免费':'￥'}}
                      <span class="present" v-if="item.couponPrice">{{item.couponPrice.toFixed(2)}}</span> -->
                      <span v-if="!!item.couponPrice">￥</span>
                      <span class="present" v-if="item.couponPrice">{{
                        item.couponPrice.toFixed(2)
                      }}</span>
                      <span class="free" v-else>免费</span>
                      <span class="original" v-if="!!item.original"
                        >￥{{
                          item.originalPrice
                            ? item.originalPrice.toFixed(2)
                            : "0.00"
                        }}</span
                      >
                    </div>
                    <span class="learn" v-if="item.categoryName != '线下培训'">{{ item.salesVolume }}人已学习</span>
                  </div>
                </div>
              </div>
            </template>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <!-- <div class="content-box" v-if="categoryList.length">
      <div class="tab">
        <div 
          class="tab_item" 
          :class="{'pick': tabIndex==index}"
          v-for="(item, index) in categoryList" 
          :key="index"
          @click="tabIndex=index">{{item.name}}</div>
        </div>
      <div class="scroll-view">
      	<div class="scroll-item" :class="{'score-hover' : scoreIndex==index}" @click="scoreIndex=index"  v-for="(item,index) in categoryList[tabIndex].childList" :key="index">{{item.name}}</div>
      </div> 
      
    </div> -->
  </div>
</template>

<script>
import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 数据对象
  data() {
    return {
      visible: false,
      tabIndex: undefined,
      scoreIndex: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      categoryList: [], // 分类列表
      manageList: [], // 课程列表
      loading: false,
      finished: true,
    };
  },
  watch: {
    tabIndex(e, old) {
      if (document.getElementsByClassName("layout")[old]) {
        document
          .getElementsByClassName("layout")[old]
          .removeEventListener("scroll", this.FunHandleScroll);
      }
      window.removeEventListener('scroll', this.scrollToTop)

      this.$nextTick(()=>{
        if(!document.getElementsByClassName("layout")[e]){return false}
        document.getElementsByClassName("layout")[e].addEventListener("scroll", this.FunHandleScroll);
      })

      if (this.categoryList.length) {
        this.$store.commit("updatetabIndex", e);
        if (this.categoryList[e].childList && this.categoryList[e].childList.length) {
          this.scoreIndex =
            this.categoryList[e]?.childList[0]?.courseCategoryId;
        } else {
          this.scoreIndex = this.categoryList[e]?.courseCategoryId;
        }
        this.$store.commit("updatescoreIndex", this.scoreIndex);
        this.manageList = [];
        this.pageNum = 1;
        this.total = 0;
        this.getManageList();
      }
    },
  },
  // 事件处理器
  methods: {
    // 更改一级分类
    // changetabIndex(e) {
    //   // console.log(e,'changeTab')
    //   if (this.tabIndex != e) {
    //     this.tabIndex = e;
    //     this.$store.commit('updatetabIndex',e)
    //     // console.log(this.$store.state.tabIndex,'tabtabtab')
    //     if (this.categoryList[e].childList.length) {
    //       this.scoreIndex =
    //         this.categoryList[e]?.childList[0]?.courseCategoryId;
    //     } else {
    //       this.scoreIndex = this.categoryList[e]?.courseCategoryId;
    //     }
    //     this.$store.commit('updatescoreIndex',this.scoreIndex);
    //     this.manageList = [];
    //     this.pageNum = 1;
    //     this.total = 0;
    //     this.getManageList();
    //   }
    // },
    // 返回时间
    setTime(e) {
      if(!e.openStartTime){
        return false;
      }
      let start = e.openStartTime.substring(0,10).split('-').join('/');
      let end = e.openEndTime.substring(0,10).split('-').join('/');
      return start + ' - ' + end
    },
    // 更改二级分类
    changescoreIndex(e) {
      if (this.scoreIndex != e) {
        this.scoreIndex = e;
        this.$store.commit("updatescoreIndex", e);
        this.manageList = [];
        this.pageNum = 1;
        this.total = 0;
        this.getManageList();
      }
    },
    onLoad() {
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.loading = true;
        this.pageNum++;
        this.getManageList();
      }
    },
    goDetail(e) {
      jumpToShopDetail(1,e);
      // this.$router.push({ path: "/curriculum/course", query: { id: e } });
    },
    // 获取课程分类
    getcourseType() {
      this.$ajax({
        url: "/hxclass-mobile/course/category",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.categoryList = res.data;
          if(this.$route.query.courseCategoryId != undefined){
            res.data.forEach((item,index)=>{
              if(item.courseCategoryId == this.$route.query.courseCategoryId){
                this.tabIndex = index
                this.$store.state.tabIndex = index
              }
            })
          } else {
            this.tabIndex = 0
          }
          if (this.$store.state.tabIndex || this.$store.state.tabIndex==0) {
            // ，
            this.scoreIndex = this.$store.state.scoreIndex;
          } else {
            this.$store.commit("updatetabIndex", 0);
            if (res.data[0].childList.length) {
              this.scoreIndex = res.data[0]?.childList[0]?.courseCategoryId;
              this.$store.commit("updatescoreIndex", this.scoreIndex);
            } else {
              this.scoreIndex = res.data[0]?.courseCategoryId;
              this.$store.commit("updatescoreIndex", this.scoreIndex);
            }
          }
          this.getManageList();
          
          // 挂载滚动监听
          this.$nextTick(()=>{
            document.getElementsByClassName("layout")[this.tabIndex].addEventListener("scroll", this.FunHandleScroll);
          })
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 获取教育课程列表
    getManageList() {
      if(!this.scoreIndex){
        return
      }
      this.visible = false;
      this.$ajax({
        url: "/hxclass-mobile/course/",
        method: "get",
        params: {
          courseCategoryId: this.scoreIndex,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.visible = true;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          if (this.pageNum * this.pageSize < this.total) {
            this.finished = false;
          }
          this.manageList.push(...res.data.records);
          this.loading = false;
        }
      });
    },
    // 页面滚动监听方法
    FunHandleScroll() {
      const scrollTop = document.getElementsByClassName("layout")[this.tabIndex].scrollTop;
      sessionStorage.setItem('scrollTop-courseList',scrollTop)
    },
  },
  created() {
    this.$store.commit('updateopenTimeId',null);
    // if (this.$store.state.tabIndex) {
    if (this.$store.state.tabIndex || this.$store.state.tabIndex==0) {
      this.$set(this, "tabIndex", this.$store.state.tabIndex);
    }
    this.getcourseType(); // 获取课程分类
  },
  // 销毁滚动条绑定
  destroyScroll() {
    if (document.getElementsByClassName("layout")[this.tabIndex]) {
      document
        .getElementsByClassName("layout")[this.tabIndex]
        .removeEventListener("scroll", this.FunHandleScroll);
    }
    window.removeEventListener('scroll', this.scrollToTop)
  },
  activated(){
    // 进入页面后赋值滚动位置
    this.$nextTick(()=>{
      if(!document.getElementsByClassName("layout")[this.tabIndex]){
        return false
      }
      document.getElementsByClassName("layout")[this.tabIndex].scrollTop = sessionStorage.getItem('scrollTop-courseList')
    })
  }
};
</script>


<style lang="less" scoped>
/deep/.van-tab{
  // max-width: 90px;
  font-size: 14px;
  font-family: PingFangMedium;
  font-weight: 400;
  color: #666666;
  line-height: 27px;
}
/deep/.van-tabs__wrap {
  height: 55px;
}
/deep/.van-tab--active {
  font-size: 16px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #333333;
  line-height: 27px;
  background-image: url(~@/assets/image/examination/certificate_icon1.png);
  background-size: 41px 6px;
  background-repeat: no-repeat;
  background-position: 50% 33px;
}
/deep/.van-tabs__line {
  z-index: 0;
}
.scroll-view {
  box-sizing: border-box;
  padding:0 18px;
  padding-bottom: 5px;
  width: 100%;
  overflow-y: scroll;
  white-space: nowrap;
  .scroll-item {
    display: inline-block;
    text-align: center;
    padding-bottom: 7.5px;
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    font-family: PingFangMedium;
    color: #666666;
    line-height: 21px;
  }
  .score-hover {
    color: #15b7dd;
    position: relative;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 500;
    line-height: 21px;
  }
  .score-hover::after {
    content: "";
    display: inline-block;
    background: url(../../assets/image/icon/20220218041506.png) no-repeat;
    background-size: 100%;
    width: 5.5px;
    height: 6.5px;
    position: absolute;
    right: -4px;
    bottom: 7px;
  }
}
.layout {
  overflow-y: scroll;
  padding: 0 18px;
  .layout-item {
    position: relative;
    display: flex;
    margin-bottom: 14px;
    background-color: #ffffff;
    box-shadow: 0px 2.5px 5px 1px rgba(93, 131, 167, 0.1);
    border-radius: 5px;
    font-family: PingFangMedium;
    margin-left: 34px;
    padding: 12px 14px 12px 10px;
    .side-left {
      margin-left: -44px;
      height: 91px;
      position: relative;
      .cover-img {
        border-radius: 5px;
        width: 94px;
        height: 94px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
    }
    .side-right {
      flex: 1;
      margin-left: 14px;
    }
    .h4 {
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }
    .all-font-ellipsis {
      -webkit-line-clamp: 1;
    }
    .lineDownCourse{
      display: flex;
      align-items: center;
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 15px;
      .icon_courseTime{
        width: 10px;
        height: 10px;
        margin-right: 7px;
      }
      .icon_address{
        width: 8px;
        height: 10px;
        margin-left: 1px;
        margin-right: 8px;
      }
    }
    .tag {
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      font-family: PingFangMedium;
      color: #15B7DD;
      line-height: 18px;
    }
    .term {
      color: #444444;
    }
    .foo {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        font-size: 12px;
        font-weight: 500;
        color: #EC6C01;
        line-height: 14px;
        font-family: PingFangMedium;
        .present {
          font-weight: bold;
          font-size: 14px;
        }
        .free {
          font-size: 14px;
        }
        .original {
          margin-left: 8px;
          font-size: 12px;
          color: #999999;
          font-weight: 400;
          text-decoration: line-through;
          font-family: PingFangMedium;
        }
      }
      .learn {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 15px;
        font-family: PingFangMedium;
      }
    }
  }
}
.content-box {
  padding: 18px;
  // 切换框
  .tab {
    overflow-x: auto;
    display: flex;
    align-items: center;
    font-family: PingFangMedium;
    padding-bottom: 8px;
    .tab_item {
      width: 200px;
      font-size: 14.5px;
      font-weight: bold;
      color: #666666;
      margin-right: 25.5px;
      &.pick {
        font-size: 16.5px;
        font-weight: bold;
        color: #000000;
        background-image: url(../../assets/image/examination/certificate_icon1.png);
        background-size: 36.5px;
        background-repeat: no-repeat;
        background-position: bottom center;
      }
    }
  }
}
</style>
