<template>
  <div class="container-wrap">
    <van-nav-bar
      title="学习统计"
      left-arrow
      class="nav-circular"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-wrap" :style="{'min-height': `calc(100vh - ${46 + $store.state.stateHeight}px`}">
      <!-- 学习时长 -->
      <div class="duration-box">
        <div class="header-top">
          <span class="all-title">学习时长</span>
          <van-popover
            v-model="showPopover"
            placement="bottom-end"
            trigger="click"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 120px; display: flex; flex-direction: column"
            >
              <van-grid-item
                v-for="(i, index) in datalist"
                :key="index"
                :text="i.label"
                @click="selectitem(i)"
              />
            </van-grid>
            <template #reference>
              <!-- <van-button type="primary">{{tlable?tlable:'选择时间段'}}</van-button> -->
              <div class="selectBtn">
                <p>{{ tlable ? tlable : "选择时间段" }}</p>
                <van-icon name="arrow-down" />
              </div>
            </template>
          </van-popover>
          <!-- <select-lay 
						:value="tval" 
						name="name" 
						:options="datalist" 
						@selectitem="selectitem">
					</select-lay> -->
        </div>
        <!-- echarts -->
        <div id="echarts1" style="width: 100%; height: 220px"></div>
      </div>

      <!-- 学习情况 -->
      <div class="duration-box">
        <div class="header-top" style="margin-bottom: 7px;">
          <span class="all-title">学习情况</span>
        </div>
        <!-- echarts -->
        <!-- <div v-if="isShowEcharts2" id="echarts2" style="width: 100%; height: 325px"></div> -->
        <div v-if="isShowEcharts2" id="echarts2" style="width: 100%;"></div>
        <!-- 暂无数据 -->
        <div v-else class="empty_box">
          <nomessage type=1></nomessage>
        </div>
      </div>

      <!-- 考试情况 -->
      <div class="duration-box">
        <div class="header-top">
          <span class="all-title">考试情况</span>
        </div>
        <!-- 表格 -->
        <div class="table" v-if="examCaseList.length!==0">
          <div class="table_left">
            <div class="item_txt item_main">考试</div>
            <div 
              class="item_txt" 
              v-for="(item, index) in examCaseList" 
              :key="index">{{item.examName}}</div>
            <!-- <div class="item_txt">进阶考试</div>
            <div class="item_txt">itsn考试</div> -->
          </div>
          <div class="table_right" scroll-x="true">
            <div 
              :id="index+1" 
              class="right_item"
              :class="
                gradeList.length == 1 ? 'right_item1' 
                : gradeList.length == 2 ? 'right_item2' 
                : gradeList.length == 3 ? 'right_item3': '' "
              v-for="(item, index) in gradeList"
              :key="index">
              <div class="item_txt item_main">第{{index+1}}次</div>
              <div class="item_txt" v-for="(itemI, indexI) in item" :key="indexI">{{itemI}}</div>
            </div>
          </div>
        </div>
        <!-- 暂无数据 -->
        <div class="empty_box" v-else>
          <nomessage type=1></nomessage>
        </div>
      </div>

      <!-- 加载 -->
      <div class="load_box" v-if="isShowLoad">
        <!-- <van-loading size="24px" vertical>加载中...</van-loading> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import nomessage from "@/components/nomessage.vue";
export default {
  mounted() {
    // 学习时长
    var echarts1 = echarts.init(document.getElementById("echarts1"));
    // 绘制图表
    echarts1.setOption(this.echarts1Data);

    // 学习情况
    this.getStudyCase()
    
  },
  // 可用组件的哈希表
  components: {
    nomessage
  },
  // 数据对象
  data() {
    return {
      showPopover: false,
      echarts2: null, //学习情况

      // 时间选择段
      tval: "",
      tlable: "",
      datalist: [
        {
          label: "一周内",
          value: "1",
        },
        {
          label: "半月内",
          value: "2",
        },
        {
          label: "一月内",
          value: "3",
        },
      ],

      // 学习时长
      echarts1Data: {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          // type: 'value'
          name: "(单位:小时)",
          type: "value",
          // max: 500
        },
        grid: [
          {
            top: '15%',
            bottom: '18%'
          },
        ],
        series: [
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 10,
            left: "center",
            lineStyle: {
              color: "#86D7EB",
              width: 1.5,
              type: "solid",
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#15B7DD",
              color: "#15B7DD",
            },
          },
        ],
      },
      isShowLoad: false,

      // 学习情况
      echarts2Data: {
        series: [
          {
            type: "pie",
            radius: [20, 60],
            top: 0,
            left: "center",
            width: "100%",
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              alignTo: "edge",
              // width: '25%',
              margin: 10,
              // formatter: "{name|{b}}\n{time|{c} }",
              formatter: function (obj) {
                return [
                  obj.name.split(' ')[0],
                  obj.name.split(' ')[1]
                ].join('\n')
              },
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              // overflow: 'breakAll',
              rich: {
                time: {
                  fontSize: 10,
                  color: "#999",
                  width: "100px;"
                },
              },
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 10,
              maxSurfaceAngle: 120,
            },
            labelLayout: {
              moveOverlap: "shiftY",
              width: 60,
            },
            data: [
              // { name: "孤独症书籍", value: 8 },
              // { name: "圣彼得堡来客", value: 1 },
              // { name: "表象与本质", value: 0.8 },
              // { name: "史记精注全译", value: 0.5 },
              // { name: "史记精注全译", value: 0.5 },
              // { name: "圣彼得堡来客", value: 3.8 },
            ],
          },
        ]
      },
      // labelLayout: null,
      isShowEcharts2: true,

      // 考试情况
      examCaseList: [],
      // 考试成绩
      gradeList: [], 
    };
  },
  created() {
    // console.log(this.echarts2Data.series[0].data)
    // 学习时长
    this.tval = this.datalist[0].value
    this.tlable = this.datalist[0].label
    this.getStudyDuration()
    
    // 考试情况
    this.getExamCase();
  },
  // 事件处理器
  methods: {
    // 选择时间段
    selectitem(e) {
      // console.log(e);
      this.isShowLoad = true
      this.tval = e.value;
      this.tlable = e.label;
      this.showPopover = false;
      // 学习时长
      this.getStudyDuration()
    },
    // 学习时长
    getStudyDuration() {
      this.$ajax({
        url: "/hxclass-mobile/course/study-statistics",
        method: "get",
        params: {
          type: this.tval, //1一周 2半月 3一月
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          // 日期去掉年份
          let dateArr = [] 
          let dateItem = ''
          res.data.date.map((item, index) => {
            dateItem = item.split('-')[1] + '-' + item.split('-')[2]
            dateArr.push(dateItem)
          })
          this.echarts1Data.xAxis.data = dateArr //日期
          this.echarts1Data.series[0].data = res.data.time //时长
          // 学习情况
          var echarts1 = echarts.init(document.getElementById("echarts1"));
          // 绘制图表
          echarts1.setOption(this.echarts1Data);
          this.isShowLoad = false
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 学习情况
    getStudyCase() {
      this.$ajax({
        url: "/hxclass-mobile/course/study-situation",
        method: "get",
        // params: {
        //   userId: this.$store.state.userInfo.userId, //用户id
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 暂无数据
          if (res.data.length == 0) {
            this.isShowEcharts2 = false
            return
          }
          // 学习情况
          var echarts2Dom = document.getElementById("echarts2");
          this.echarts2 = echarts.init(echarts2Dom, null, {
            height: res.data.length>10 ? 370 : 200
          });
          this.isShowEcharts2 = true
          this.echarts2Data.series[0].data = res.data
          this.echarts2Data.series[0].data = res.data.map((item) => {
            return {
              name: item.courseName + ' ' + JSON.stringify(item.studyProgress * 100).split('.')[0] + '%',
              value: item.studyClassHour,
              progress: JSON.stringify(item.studyProgress * 100).split('.')[0] + '%'
            }
          })
          this.echarts2Data.series[0].label.width = (this.echarts2.getWidth() / 2) / 1.8
          var that = this;
          this.echarts2Data.series[0].labelLayout = function (params) {
            // console.log('ly-ecahrts', params, that.echarts2.getWidth());
            const isLeft = params.labelRect.x < that.echarts2.getWidth() / 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            points[2][1] = params.labelRect.y + (params.labelRect.height/2-1);
            // points[1][1] = params.labelRect.y + (params.labelRect.height/2-1);
            // points[0][1] = params.labelRect.y + (params.labelRect.height/2+20);
            return {
              labelLinePoints: points
            };
          }
          
          // 绘制图表
          this.echarts2.setOption(this.echarts2Data);
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 考试情况
    getExamCase() {
      this.$ajax({
        url: "/hxclass-mobile/exam/user/study/exam-score",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId, //用户id
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.examCaseList = res.data;
          // 处理考试成绩数据
          this.switchGrade()
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 考试成绩数据处理
    switchGrade() {
      // 最多考试次数
      let maxCount = 0
      this.examCaseList.map((item, index) => {
        // 第一轮
        if (index == 0) {
          maxCount = item.scoreList.length
        } else {
          // 向上一位做对比
          if (item.scoreList.length > this.examCaseList[index-1].scoreList.length) {
            maxCount = item.scoreList.length
          }
        }
      })
      
      let gradeItem = [] //
      for ( var i = 0 ; i < maxCount ; i++ ) {
        for ( var j = 0 ; j < this.examCaseList.length ; j++ ) {
          if (this.examCaseList[j].scoreList[i]) {
            gradeItem.push(this.examCaseList[j].scoreList[i].totalScore)
          } else {
            gradeItem.push('')
          }
        }
        this.gradeList.push(gradeItem)
        gradeItem = []
      }
      // console.log(this.gradeList)
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.nav-circular {
  position: relative;
  z-index: 10;
}
/deep/.uni-select-lay {
  .uni-select-lay-select {
    border: 0;
    border-radius: 5px;
    background-color: #ffffff;
    .uni-select-lay-icon::before {
      display: none;
    }
  }
}
.empty_box {
  padding: 20px 0;
}
.container-wrap {
  background: #f7f7f9;
  .nav-circular {
    background: #ffffff;
  }
}
.content-wrap {
  background: #f7f7f9;
  padding: 18px;
  .all-title {
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    line-height: 27px;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      position: relative;
      top: 1px;
      width: 3px;
      height: 13px;
      margin-right: 4px;
      background: #15b7dd;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .duration-box {
    position: relative;
    z-index: 9;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selectBtn {
        display: flex;
        padding: 4px 8px;
        background: #ffffff;
        border-radius: 5px 5px 5px 5px;
        align-items: center;
        p {
          margin-right: 6px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
      }
    }
    .table {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
      margin-top: 14px;
      .item_txt {
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
        &.pass {
          color: #f79094;
        }
        &.fail {
          color: #8cbd0b;
        }
        &:nth-child(odd) {
          border-right: 0.5px solid #f7f7f9;
          background: #ffffff;
        }
        &:nth-child(even) {
          border-right: 0.5px solid #ffffff;
          background: #f7f7f9;
        }
      }
      .item_main {
        // border-top: 2px solid #F79094;
      }
      .table_left {
        display: inline-block;
        width: 110px;
        // vertical-align: middle;
        vertical-align: text-top;
        .item_main {
          color: #15b7dd;
          border-top: 2px solid #f79094;
        }
      }
      .table_right {
        overflow-x: scroll;
        display: inline-block;
        width: calc(100% - 110px);
        white-space: nowrap;
        // vertical-align: middle;
        vertical-align: text-top;
        .right_item {
          display: inline-block;
          width: 76.5px;
          vertical-align: middle;
          &:last-child {
            .item_txt {
              border-right: 0;
            }
          }
          .item_main {
            color: #666666;
          }
          &:nth-child(1n) {
            .item_main {
              border-top: 2px solid #ffcf11;
            }
          }
          &:nth-child(2n) {
            .item_main {
              border-top: 2px solid #00cfff;
            }
          }
          &:nth-child(3n) {
            .item_main {
              border-top: 2px solid #a0b2f0;
            }
          }
          &:nth-child(4n) {
            .item_main {
              border-top: 2px solid #f79094;
            }
          }
        }
        .right_item1{
          width: 100%;
        }
        .right_item2{
          width: 50%;
        }
        .right_item3 {
          width: 33.33%;
        }
      }
    }
  }
  .load_box {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding-top: 180px;
  }
}
</style>
