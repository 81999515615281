<template>
	<div id="LoopTab" ref="LoopTab">
		<img class="tablink" src="./img/tablink.png"/>
		<div
			class="LoopTabWrapper"
			ref="LoopTabWrapper"
			@touchstart="drag_hstart"
			@touchend="drag_hend"
			@touchmove.prevent="drag_hmove"
			:style="[
				{transform: 'translate3d('+LoopTabOffset+'px, 0px, 0px)'},
				{width: TotalWidth+'px'},
				{transitionDuration: (delayed?'500ms':'0ms')}
			]"
		>
			<div
				class="LoopTabSlide"
				:class="[
					'LoopTabSlide'+index,
					CenterIndex==index?'click':''
				]"
				v-for="(item,index) in dataList"
				:key="index"
				@click="onClickTab(index)"
			>{{item.label}}</div>
		</div>
	</div>
</template>

<script>
import mixins from './js/mixins.js'
export default{
	mixins: [ mixins ],
	data(){
		return{
			CenterIndex: null,
			TotalWidth: 0,
			dataList: [],
			LoopTabOffset: 0,
			delayed: false
		}
	},
	mounted(){
		this.dataList = [
			...this.list,
			...this.list,
			...this.list
		]
		this.FunThisWidth()
	}
}
</script>

<style lang="less" scoped>
@import './css/index.less';
</style>
