<template>
  <!-- 打卡日历页面 -->
  <div class="all" @touchstart="touchStart" @touchend="touchEnd">
    <div class="bar">
      <!-- 上一个月 -->
      <div class="previous" @click="handleCalendar(0)">
        <img class="direction" src="@/assets/image/icon/left-icon.png" />
      </div>
      <!-- 显示年月 -->
      <div class="date">
        {{ cur_year || "--" }} 年 {{ cur_month || "--" }} 月
      </div>
      <!-- 下一个月 -->
      <div class="next" @click="handleCalendar(1)">
        <img class="direction" src="@/assets/image/icon/right-icon.png" />
      </div>
    </div>
    <!-- 显示星期 -->
    <div class="week" v-if="langType == 'ch'">
      <div v-for="(item, index) in weeks_ch" :key="index">{{ item }}</div>
    </div>
    <div class="week" v-else>
      <div v-for="(item, index) in weeks_en" :key="index">{{ item }}</div>
    </div>
    <div class="line"></div>
    <div class="myDateTable">
      <div v-for="(item, j) in days" :key="j" class="dateCell">
        <div v-if="item.date == undefined || item.date == null" class="cell">
          <span :decode="true">&nbsp;&nbsp;</span>
        </div>
        <div v-else>
          <!-- 已签到日期 -->
          <div v-if="item.isSign == true" class="cell greenColor bgWhite">
            <span>{{ item.date }}</span>
          </div>
          <!-- 漏签 -->
          <div
            @click="clickSignUp(item.date, 0)"
            class="cell redColor bgGray"
            v-else-if="
              cur_year < toYear ||
              (cur_year == toYear && cur_month < toMonth) ||
              (cur_year == toYear && cur_month == toMonth && item.date < today)
            "
          >
            <!-- 小程序不兼容这个 v-else-if="(new Date(cur_year+'-'+cur_month+'-'+item.date))<(new Date())"> -->
            <span>{{ item.date }}</span>
          </div>
          <!-- 今日未签到  (item.date,1)-->
          <div
            @click="clickSignUp(item.date, 0)"
            class="cell greenColor bgBlue"
            v-else-if="
              item.date == today && cur_month == toMonth && cur_year == toYear
            "
          >
            <span>今</span>
          </div>
          <!-- 当前日期之后 -->
          <div class="whiteColor cell" v-else>
            <span>{{ item.date }}</span>
          </div>
        </div>
        <span v-if="item.isSign == true" class="tips">已签到</span>
        <span v-else-if="item.date == today && cur_month == toMonth && cur_year == toYear" class="tips" style="color:#666">待签到</span>
      </div>
    </div>
    <!-- <van-loading
      v-show="loading"
      class="loading"
      type="spinner"
      color="#15B7DD"
    /> -->
    <!-- <div class="TipArea">
		Tip:
		<div>打卡成功后需要你<div class="impTip">同步数据给数据库，切换月或重新进入</div>
		页面再向数据库读取记录(不会的建议参考我的Demo,而不是单独下个组件过来瞎折腾)。本地打卡不做任何记录，<div class="impTip">仅仅模拟成功</div>
		</div>
	</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: [],
      SignUp: [],
      cur_year: 0, //当前选的年
      cur_month: 0, //当前选的月
      today: parseInt(new Date().getDate()), //本日
      toMonth: parseInt(new Date().getMonth() + 1), //本月
      toYear: parseInt(new Date().getFullYear()), //本年
      weeks_ch: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      weeks_en: ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"],
      touchStartX: 0, // 触屏起始点x
      touchStartY: 0, // 触屏起始点y
    };
  },
  props: {
    sendYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    sendMonth: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    loading: {
      type: Boolean,
    },
    dataSource: {
      //已签到的数据源
      type: Array,
      default: () => {
        return [];
      },
    },
    langType: {
      //只是示例一个翻译而已，要想所有都翻译自己可以再加加
      type: String,
      default: "ch",
    },
  },
  created() {
    // console.log();
    this.cur_year = this.sendYear;
    this.cur_month = this.sendMonth;
    this.SignUp = this.dataSource;

    this.calculateEmptyGrids(this.cur_year, this.cur_month);
    this.calculateDays(this.cur_year, this.cur_month);
    this.onJudgeSign();
  },
  watch: {
    dataSource: "onResChange",
  },
  methods: {
    // 获取当月共多少天
    getThisMonthDays(year, month) {
      return new Date(year, month, 0).getDate(); // 获取天数
    },
    // 获取当月第一天星期几
    getFirstDayOfWeek(year, month) {
      return new Date(Date.UTC(year, month - 1, 1)).getDay();
    },
    // 计算当月1号前空了几个格子，把它填充在days数组的前面
    calculateEmptyGrids(year, month) {
      //计算每个月时要清零
      this.days = [];
      const firstDayOfWeek = this.getFirstDayOfWeek(year, month);
      if (firstDayOfWeek > 0) {
        for (let i = 0; i < firstDayOfWeek; i++) {
          var obj = {
            date: null,
            isSign: false,
          };
          this.days.push(obj);
        }
      }
    },

    // 绘制当月天数占的格子，并把它放到days数组中
    calculateDays(year, month) {
      const thisMonthDays = this.getThisMonthDays(year, month);
      // this.columnsLen=Math.ceil(thisMonthDays/7);
      // console.log(this.columnsLen);
      for (let i = 1; i <= thisMonthDays; i++) {
        var obj = {
          date: i,
          isSign: false,
        };
        this.days.push(obj);
      }
      //// console.log(this.days);
    },

    onResChange(newD, oldD) {
      this.SignUp = newD;
      this.onJudgeSign();
    },
    //匹配判断当月与当月哪些日子签到打卡
    onJudgeSign() {
      var signs = this.SignUp;
      var daysArr = this.days;
      for (var i = 0; i < signs.length; i++) {
        var current = new Date(signs[i].replace(/-/g, "/"));
        var year = current.getFullYear();
        var month = current.getMonth() + 1;
        var day = current.getDate();
        day = parseInt(day);
        for (var j = 0; j < daysArr.length; j++) {
          //年月日相同则打卡成功
          if (
            year == this.cur_year &&
            month == this.cur_month &&
            daysArr[j].date == day
          ) {
            //&& signs[i].isSign == "今日已打卡"
            // console.log(daysArr[j].date, day);
            daysArr[j].isSign = true;
          }
        }
      }
      this.days = daysArr;
    },

    // 切换控制年月，上一个月，下一个月
    handleCalendar(type) {
      const cur_year = parseInt(this.cur_year);
      const cur_month = parseInt(this.cur_month);
      var newMonth;
      var newYear = cur_year;
      if (type === 0) {
        //上个月
        newMonth = cur_month - 1;
        if (newMonth < 1) {
          newYear = cur_year - 1;
          newMonth = 12;
        }
      } else {
        newMonth = cur_month + 1;
        if (newMonth > 12) {
          newYear = cur_year + 1;
          newMonth = 1;
        }
      }
      this.calculateEmptyGrids(newYear, newMonth);
      this.calculateDays(newYear, newMonth);

      this.cur_year = newYear;
      this.cur_month = newMonth;

      this.SignUp = []; //先清空
      this.$emit("dateChange", this.cur_year + "-" + this.cur_month); //传给调用模板页面去拿新数据
    },

    clickSignUp(date, type) {
      //type=0补签，type=1当日签到

      var str = "签到";
      if (type == 0) {
        //如果不需要补签功能直接在这阻止不执行后面的代码就行。
        str = "补签";
        return; // 阻止补签
      }
      this.$Toast.success("打卡成功");
      // uni.showToast({
      // 	title: str + "成功" + date + "号",
      // 	icon: 'success',
      // 	duration: 2000
      // });
      // this.SignUp.push(this.cur_year + "-" + this.cur_month + "-" + date); //自动加假数据，写了接口就不用了

      // console.log(this.SignUp);
      // this.$forceUpdate();

      this.$emit(
        "clickChange",
        this.cur_year + "-" + this.cur_month + "-" + date
      ); //传给调用模板页面

      //refresh
      this.onJudgeSign();
    },

    // 通过滑动切换月份
    touchStart(e) {
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    touchEnd(e) {
      let deltaX = e.changedTouches[0].clientX - this.touchStartX;
      let deltaY = e.changedTouches[0].clientY - this.touchStartY;
      if (Math.abs(deltaX) > 50 && Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX >= 0) {
          /* 左滑 */
          // this.handleCalendar(0)
        } else {
          /* 右滑 */
          // this.handleCalendar(1)
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  position: relative;
}
.all .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.all .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  justify-content: space-between;
  margin: 15px auto;
  padding: 5px;
}

.bar .direction {
  width: 12px;
}
.bar .date {
  font-size: 18px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #333333;
  line-height: 27px;
}

.all .week {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding-left: 14px;
  // padding-right: 14px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 12px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #666666;
  line-height: 18px;
  >div{
    width: calc(100% / 7);
    text-align: center;
  }
}
.line{
  width: calc(100% - 38px);
  margin: 10px auto 0;
  background: #eeeeee;
  height: 1px;
}
.myDateTable {
  position: relative;
  margin: 0 0 2.5vw;
  /* padding: 2vw; */
  border-radius: 5px;
  display: flex;
  // justify-content: s;
  flex-wrap: wrap;
  /* background: linear-gradient(#74AADA, #94db98); */
}

.myDateTable .dateCell {
  width: calc(100% / 7);
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  >div{
    margin: 8px;
  }
}

.dateCell .cell {
  display: flex;
  border-radius: 50%;
  height: calc(8vw);
  justify-content: center;
  align-items: center;
  position: relative;
}

.dateCell .tips {
  color: #15B7DD;
  font-size: 12px;
  position: relative;
  top: -5px;
}

.whiteColor {
  color: #666;
}

.greenColor {
  color: #ffffff;
  font-weight: bold;
}

.bgWhite {
  background-color: #15b7dd;
  border: 1px solid #15b7dd;
  font-size: 14px;
  line-height: calc(8vw - 2px);
  height: calc(8vw - 2px);
}

.bgGray {
  background-color: rgba(255, 255, 255, 0.42);
}

.bgBlue {
  color: #666666;
  border: 1px solid #666666;
  font-size: 14px;
  line-height: calc(8vw - 2px);
  height: calc(8vw - 2px);

  // background-color: #15b7dd;
}

.bgRed {
  font-size: 7px;
  background-color: #ff0000;
}

.redColor {
  color: #999999;
}

.TipArea {
  word-break: break-all;
  word-wrap: break-word;

  font-size: 7px;
  padding: 5px;
}
.impTip {
  display: inline-block;
  color: #ff0000;
}
</style>
