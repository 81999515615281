<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="群聊二维码"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="onClickLeft"
    />
    <div class="title-box">
      您的审核已通过，请尽快加入企业群聊，及时查看群通知，参与考前串讲等
    </div>
    <img
      class="code-box"
      :src="photoUrl"
    >
    <span
      class="all-btn-bigCircle"
      @click="saveImg()"
    >保存图片</span>
  </div>
</template>

<script>
	export default {
		data() {
			return {
				photoUrl: null,
			}
		},
    created() {
      // 群聊二维码
      this.photoUrl = this.$route.query.url
    },
		methods: {
      // 导航返回键
      onClickLeft(){
        this.$onReturn(this.$route.query.fromMenu == '1');
      },
			saveImg() {
        var alink = document.createElement("a");
        alink.href = this.photoUrl;
        alink.download = "pic"; //图片名
        alink.click();
			},
    }
  }
</script>

<style lang="less" scoped>
.container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 18px;
  overflow: hidden;

  .title-box {
    margin-top: 18px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .code-box {
    margin: 60px 0 37px;
    width: 200px;
  }
}
</style>
