<template>
	<div class="container-box">
		<van-nav-bar
			title="模拟考试"
			left-arrow
			class="nav-circular"
			:fixed="true"
			:placeholder="true"
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>
		<div class="main-first">
			<div class="main-header">
				VB-MAPP语言行为里程碑及安置程序培训课程
			</div>
			<div class="main-content">
				课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育...
			</div>
			<div class="main-bottom">
				<img class="main-btn" src="@/assets/image/examination/btn-examination1.png" />
				<div class="main-btnspan" >现在进入>></div>
			</div>
		</div>
			<div class="main-first main-second">
			<div class="main-header">
				VB-MAPP语言行为里程碑及安置程序培训课程
			</div>
			<div class="main-content">
				课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育...
			</div>
			<div class="main-bottom">
				<img class="main-btn" src="@/assets/image/examination/btn-examination2.png" />
				<div class="main-btnspan" >现在进入>></div>
			</div>
		</div>
		<div class="main-first main-third">
			<div class="main-header">
				VB-MAPP语言行为里程碑及安置程序培训课程
			</div>
			<div class="main-content">
				课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育...
			</div>
			<div class="main-bottom">
				<img class="main-btn" src="@/assets/image/examination/btn-examination3.png" />
				<div class="main-btnspan" >现在进入>></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {},
	}
</script>

<style>
	page{
		background: #F7F7F9;
	}
</style>

<style lang="less" scoped>
	.container-box {
		
		.main-first {
			display: flex;
			flex-direction: column;
			margin: 9.5px 18px 0;
			height: 179px;
			background: url(~@/assets/image/examination/bcg-examination1.png) no-repeat;
			background-size: 100%;

			.main-header {
				margin: 27.5px 12.5px 0 12.5px;
				width: 314px;
				height: 54.5px;
				font-size: 18px;
				font-family: PingFangMedium;
				font-weight: bold;
				color: #333333;
				line-height: 27px;
			}

			.main-content {
				margin: 16.5px 0 0 12.5px;
				width: 314px;
				height: 38px;
				font-size: 12.5px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #999999;
				line-height: 19px;
			}

			.main-bottom {
				position: relative;
				margin: 11.5px 0 0 12.5px;
				width: 81px;
				height: 21.5px;
				font-size: 14.5px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #333333;
				line-height: 21.5px;

				.main-btn {
					position: absolute;
					bottom: 0;
					left: -1px;
					width: 100%;
					z-index: 1;
				}

				.main-btnspan {
					position: absolute;
					z-index: 2;
				}
			}
		}

		.main-second {
			background: url(~@/assets/image/examination/bcg-examination2.png) no-repeat;
			background-size: 100%;
		}

		.main-third {
			background: url(~@/assets/image/examination/bcg-examination3.png) no-repeat;
			background-size: 100%;
		}
	}
</style>
