<template>
<div>
	<div  v-if="listType != '1'">
	  <!-- 白问百答 -->
	  <div class="container-box">
	    <div class="QandAItem" v-for="i in listData" :key="i.qaId" @click="goPage(i.qaId)">
	    	<div class="QandAItemTitle">
	    		<span v-html="i.title">儿童康复教育</span>
	    	</div>
	    	<div class="QandAItemMore">
	    		<!-- <uni-icons type="right" color="#999999"></uni-icons> -->
	    	</div>
	    	<div class="QandAContent">
				<span v-html='i.content'></span>
	    	</div>
	    	<div class="QandAImgBox">
	    		<img class="QandAImg" src="@/assets/image/selected/QandA.png" />
	    	</div>
	    </div>
	  </div>
	</div>

	<div v-if="listType == '1'">
	  <!-- 白问百答 -->
	  <div class="container-box">
	    <div class="QandAItem" v-for="i in listData" :key="i.qaId">
	    	<div class="QandAItemTitle">
	    		<span v-html="i.title">儿童康复教育</span>
					<img
						class="title-bg"
						src="@/assets/image/icon/20220627041502.png"
					/>
	    	</div>
	    	<div class="QandAContent">
					<!-- <TextOverflow :type=2 :height="42" :text="i.content" ref="TextOver" /> -->
					<TextOverflow class="info" :type=2 :height="61" :text="i.content" ref="TextOver" />
					<!-- <div class="info" v-html="i.content"></div> -->
	    	</div>
	    	<!-- <div class="QandAImgBox">
	    		<img class="QandAImg" src="@/assets/image/selected/QandA.png" />
	    	</div> -->
	    </div>
	  </div>
	</div>
</div>
</template>

<script>
import TextOverflow from '@/components/TextOverflow.vue'
	export default {
		// 可用组件的哈希表
		components: {TextOverflow},
    	props: {
    		// 渲染数据
    		listData: {
    			type: Array,
    			default: ()=>[]
    		},
			listType: {
    		  type: String,
    		  default: "",
    		},
    	},
		// 数据对象
		data() {
			return {}
		},
		// 事件处理器
		methods: {
      		// 前往-百问百答列表页
      		goPage(id){
      		  this.$router.push({path:'/selected/problemList',query:{id:id}});
      		},
    	},
		// 页面生命周期-页面加载
		onLoad() {},
		// 页面生命周期-页面显示
		onShow() {},
		// 页面生命周期-页面初次渲染完成
		onReady() {},
		// 页面生命周期-页面隐藏
		onHide() {},
		// 页面生命周期-页面卸载
		onUnload() {}
	}
</script>

<style lang="less" scoped>
  @import "./searchSeo.less";
  .QandAItem{
	height: auto;
  }
  .QandAContent{
	/deep/ .link{
		position: relative;
		z-index: 10;
	}
  }
</style>
