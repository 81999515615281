<template>
	<div class="all-mask-modal">
		<div class="all-mask-content">
      <van-icon name="cross" @click="onCloseMask" size="38" class="close-icon" color="#fff"/>
			<img class="cover-img" src="@/assets/image/study/banner_study.png" />
      <div class="title">孤独症康复教育人员上岗模拟考试（一）</div>
      <div class="specs">
        <span>及格分数：70分</span>
        <span class="line"></span>
        <span>时长：40分钟</span>
      </div>
      <!-- 提示 -->
      <div class="tips">
        <img class="icon" src="../../assets/image/icon/20220127041502.png" />
        <span class="font">注：考试过程中退出当前页面或关闭华夏云课堂均按交卷处理</span>
      </div>
      <!-- 开始考试按钮 -->
      <div class="btn-inner">
        <div class="div">
          <span class="all-btn-middle">开始考试</span>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		methods: {
      //点击mask消失，由于是页面实际相当于返回
			onCloseMask(){
        this.$onReturn(this.$route.query.fromMenu == '1')
			}
    },
    beforeCreate() {
      document.querySelector('body').setAttribute('style', 'background:rgba(0,0,0,0.4);width:100%;height:100%')
    },
	}
</script>

<style>
  /* body {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
  } */
</style>
<style lang="less" scoped>
  /* 遮罩内容 */
  .all-mask-content{
    border-radius: 10px;
    padding: 20px 20px 0;
    position: relative;
    background: #ffffff;
    .close-icon{
      position: absolute;
      right: 7.5px;
      top: -42.5px;
    }
    .cover-img{
      width: 100%;
      border-radius: 10px;
    }
    .title{
      width: calc(100% - 50px);
      margin: 0 auto;
      margin-top: 23.5px;
      font-size: 22px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }
    .specs{
      margin-top: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;
      line-height: 24px;
      text-align: center;
      .line{
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #666666;
        margin: 0 8px;
        border-radius: 1px;
      }
    }
    .tips{
      display: flex;
      align-items: center;
      margin-top: 18px;
      .icon{
        width: 20px;
        margin-right: 6px;
      }
      .font{
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #999999;
        line-height: 21px;
      }
    }
    .btn-inner{
      border-radius: 32px;
      text-align: center;
      .div{
        display: inline-block;
        position: relative;
        top: 30px;
        border-radius: 32px;
        padding: 10px;
        background: #fff;
        .all-btn-middle{
          width: 143px;
          height: 47px;
          line-height: 47px;
        }
      }
    }
  }
</style>