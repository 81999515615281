<template>
	<div class="coupon">
		<van-nav-bar
      class="nav-circular"
      title="优惠券"
      left-text=""
      right-text=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
		<div class="couponItem minusItem">
			<div class="leftType">
				<!-- <span class="typeTitle">兑换券</span> -->
				<div class="">
					<span class="price">￥<span>100</span></span>
					<span class="condition">满500可用</span>
				</div>
				<span class="nav minusNav">满减券</span>
			</div>
			<div class="rightCont">
				<span class="couponTitle">课程 | 优惠券名称文本</span>
				<span class="couponTime">有效期至：2020.04.06</span>
				<span class="couponCode">兑换码：ASDER12345<span @click="copyCouponCode('aaaaaa')">（点击复制）</span></span>
				<div class="select">
					
				</div>
			</div>
		</div>
		
		<div class="couponItem">
			<div class="leftType">
				<span class="typeTitle">兑换券</span>
				<span class="nav">兑换券</span>
				<!-- <div class="">
					<span class="price">￥<span>100</span></span>
					<span class="condition">满500可用</span>
				</div> -->
			</div>
			<div class="rightCont">
				<span class="couponTitle">课程 | 优惠券名称文本</span>
				<span class="couponTime">有效期至：2020.04.06</span>
				<span class="couponCode">兑换码：ASDER12345<span @click="copyCouponCode('aaaaaa')">（点击复制）</span></span>
				<div class="audio">
					
				</div>
			</div>
		</div>
		<van-button plain type="primary" class="submit">确认使用</van-button>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		methods:{
			copyCouponCode(e){
				var input = document.createElement("input");   // 直接构建input
				input.value = e;  // 设置内容
				document.body.appendChild(input);    // 添加临时实例
				input.select();   // 选择实例内容
				document.execCommand("Copy");   // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$Toast('复制成功');
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/.van-button--primary{
		border: 0;
	}
	.coupon{
		min-height: calc( 100vh - 44px );
		background: #F7F7F9;
		padding: 12px 16px;
		box-sizing: border-box;
		.couponItem{
			display: flex;
			height: 104px;
			background-image: url(../../assets/image/coupon/exchange.png);
			background-size: 100% 100%;
			margin-bottom: 12px;
			.leftType{
				width: 90px;
				text-align: center;
				display: flex;
				align-items: center;
				position: relative;
				.nav{
					width: 46px;
					height: 16px;
					position: absolute;
					left: 0;
					top: 0;
					background: linear-gradient(180deg, #FFD66E 0%, #DB9900 100%);
					border-radius: 5px 0px 8px 0px;
					font-size: 10px;
					color: #FFFFFF;
					line-height: 16px;
				}
				.minusNav{
					background: linear-gradient(180deg, #F7A031 0%, #F94510 100%);
				}
				span{
					margin: 0 auto;
				}
				.typeTitle{
					font-size: 22px;
					font-weight: 600;
					color: #845C00;
					line-height: 22px;
				}
				div{
					width: 100%;
					span{
						display: block;
					}
					.price{
						font-size: 13px;
						font-family: PingFangMedium;
						color: #FE5900;
						line-height: 26px;
						span{
							display: inline;
							font-size: 26px;
						}
					}
					.condition{
						margin-top: 6px;
						font-size: 12px;
						font-family: PingFangMedium;
						font-weight: 400;
						color: #333333;
						line-height: 12px;
					}
				}
			}
			.rightCont{
				flex: 1;
				padding: 20px 16px;
				display: flex;
				flex-direction: column;
				position: relative;
				.select{
					width: 20px;
					height: 20px;
					background-image: url(../../assets/image/2022021110551.png);
					background-size: 100%;
					position: absolute;
					right: 15px;
					top: calc( 50% - 10px );
				}
				.audio{
					width: 20px;
					height: 20px;
					border: 1px solid #cccccc;
					background-color: #ffffff;
					border-radius: 50%;
					box-sizing: border-box;
					position: absolute;
					right: 15px;
					top: calc( 50% - 10px );
				}
				.couponTitle{
					font-size: 16px;
					font-weight: bold;
					color: #333333;
					line-height: 16px;
				}
				.couponTime{
					font-size: 12px;
					color: #666666;
					line-height: 12px;
					margin-top: 16px;
				}
				.couponCode{
					font-size: 12px;
					color: #666666;
					line-height: 12px;
					margin-top: 8px;
				}
			}
		}
		.minusItem{
			background-image: url(../../assets/image/coupon/minus.png);
		}
		.submit::after{
			border: 0;
		}
		.submit{
			position: fixed;
			width: 295px;
			height: 40px;
			line-height: 40px;
			margin: 0 auto;
			bottom: 2px;
			left: calc((100vw - 295px)/2);
			background: #15B7DD;
			border-radius: 20px;
			font-size: 14px;
			font-weight: bold;
			color: #FFFFFF;
		}
	}
</style>
