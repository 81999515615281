<template>
  <div class="selectedMain">
    <!-- 信息条 -->
    <div 
      v-if="stateHeight!==0"
      class="box_status" 
      :style="{
        height: stateHeight+'px',
        opacity: stateOpacity,
      }"></div>
    <div
      class="selectedBox"
      :style="{
        background: `linear-gradient(0deg, ${
          bannerList[bannerIndex] && bannerList[bannerIndex].bgColorSecond
        } 0%, ${
          bannerList[bannerIndex] && bannerList[bannerIndex].bgColorFirst
        } 100%)`,
      }"
    >
      <div class="selectedTitle">
        <span>精品推荐</span>
      </div>
      <div class="selectedBanner">
        <van-swipe
          class="my-swipe"
          :autoplay="8000"
          indicator-color="#15B7DD"
          @change="changeSwiper"
        >
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="onSwiperDetail(item)">
            <div class="swiper-item uni-bg-red">
              <img class="swiperImg" :src="item.img" />
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="cardBox">
          <router-link to="/curriculum/courseList?source=selected" class="cardCurriculum">
            <div class="curriculumContent">
              <span class="curriculumTitle">精品课程</span>
              <span class="curriculumExplain">康复教育，权威课程</span>
            </div>
            <div class="CurriculumImgbox">
              <img class="bg-icon3" src="@/assets/image/knowledge/QA-icon3.png" alt="">
              <img
                class="CurriculumImg"
                src="@/assets/image/selected/2022062701.png"
              />
            </div>
          </router-link>
          <router-link to="/shop/bookTeach" class="cardBooks">
            <div class="BooksContent">
              <span class="BooksTitle">图书教具</span>
              <span class="BooksExplain">专业推荐，终身学习</span>
            </div>
            <div class="BooksImgbox">
              <img class="bg-icon3" src="@/assets/image/knowledge/QA-icon3.png" alt="">
              <img class="BooksImg" src="@/assets/image/selected/2022062701.png" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="exclusiveBox">
        <div class="exclusiveTitle">
          <span class="line"></span>
          <span class="texts">
            专属推荐<span class="grey"><!-- /Personalized --></span>
              
          </span>
        </div>
        <div class="exclusiveContent">
          <div
            class="exclusiveList"
            v-for="(item, index) in ExclusiveList"
            :key="'ExclusiveList' + index"
          >
            <div class="listTitle" v-if="item.type == 1">
              <img
                class="exclusiveImg"
                src="@/assets/image/selected/exclusive-icon.png"
              />
              <span>您已购买“{{item.buyProductName.join('，')}}”可享以下购课福利</span>
            </div>
            <router-link
              :to="
                item.type == 1
                  ? '/curriculum/Packagedetails?packageId=' + item.dataId + '&from=1'
                  : '/curriculum/course?id=' + item.dataId
              "
              hover-class="none"
            >
              <div class="listBox">
                <div class="left">
                  <img class="exclusiveImg" :src="item.picture" />
                </div>
                <div class="centre">
                  <span class="centerTitle">{{ item.name }}</span>
                  <span class="Price"
                    ><span class="couponPrice" v-if="item.type == 1">
                      <span v-if="item.couponPrice">￥</span>{{
                      item.couponPrice ?item.couponPrice.toFixed(2) : '免费'
                    }}</span>
                    <span class="couponPrice" v-if="item.type == 2">
                      <span v-if="item.couponPrice">￥</span>{{
                      item.free != 1 ?item.couponPrice.toFixed(2) : '免费'
                    }}</span></span
                  >
                  <span class="primaryPrice" v-if="item.original"
                    >{{
                      item.originalPrice ?'￥' + item.originalPrice.toFixed(2) : '免费'
                    }}</span
                  >
                </div>
                <div class="right">
                  <div class="all-btn-small"><span>{{item.type == 2 && item.free == 1?'加入学习':'立即购买'}}</span></div>
                </div>
              </div>
            </router-link>
            <p class="tips" v-if="item.type == 1">此价格不包含已购买过的商品</p>
          </div>
          <noMessage v-if="ExclusiveList.length == 0" type="1"></noMessage>
        </div>
      </div>
      <div class="collocationBox">
        <div class="collocationTitle">
          <div class="left">
            <span class="line"></span>
            <span class="texts">
              搭配推荐<span class="grey"></span>
              <!-- /Value Package -->
            </span>
            <span class="Tips">组合购买，划算超值</span>
          </div>
          <router-link
            to="/curriculum/setMealList"
            class="seoInput"
            hover-class="none"
          >
            <div class="right">
              <div class="text">更多套餐</div>
              <van-icon name="arrow" color="#999" />
            </div>
          </router-link>
        </div>
        <div class="collocationList">
          <div
            class="courseItem"
            v-for="(item, index) in CollocationList"
            :key="index"
          >
            <router-link
              :to="'/curriculum/Packagedetails?packageId=' + item.packageId + '&from=2'"
              hover-class="none"
            >
              <p class="title">{{ item.packageName }}</p>
              <div class="up">
                <div class="left">
                  <div class="tagbox">
                    <span class="tag"><img src="@/assets/image/selected/tag.png" alt=""></span>
                    <span class="price"
                      ><span class="moneySign">￥</span
                      >{{
                        item.packagePrice ? item.packagePrice.toFixed(2) : 0.0
                      }}</span
                    >
                  </div>
                </div>
                <div class="right">
                  <div class="all-btn-small"><span>立即购买</span></div>
                </div>
              </div>
            </router-link>
            <div class="down">
              <div
                class="item"
                @click="goDetail(items)"
                v-for="(items, i) in item.productList"
                :key="i"
                v-show="
                  i <
                  (isShow === index
                    ? CollocationList[index].productList.length
                    : 2)
                "
              >
                <div class="left">
                  <img class="courseImg" :src="items.picture" />
                </div>
                <div class="right">
                  <span class="title">{{ items.name }}</span>
                  <span class="identification" v-if="items.type == 1"
                    >{{ items.courseCategoryName }}丨视频数量：{{
                      items.classHour
                    }}个</span
                  >
                  <div class="pricestander">
                    ￥<span class="present">{{
                      items.couponPrice ? items.couponPrice.toFixed(2) : 0.0
                    }}</span>
                    <span v-show="items.original == 1" class="original"
                      >￥{{
                        items.originalPrice
                          ? items.originalPrice.toFixed(2)
                          : 0.0
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="more" v-if="item.productList.length > 2" @click="showMore(index)">
                <span>{{ isShow === index ? "收起" : "更多商品" }}</span>
                <van-icon
                  v-if="isShow === index"
                  size="10"
                  color="#999"
                  name="arrow-up"
                />
                <van-icon v-else size="10" color="#999" name="arrow-down" />
              </div>
            </div>
          </div>
          <noMessage style="padding:30px" v-if="CollocationList.length == 0" type="1"></noMessage>
        </div>
      </div>

      <!-- 新知共享 -->
      <div class="autismBox">
        <!-- <div class="autismTitle">
          <span class="line"></span>
          <span>自闭症之声</span>
        </div> -->

        <div class="autismTitle">
          <div class="left">
            <span class="line"></span>
            <span class="texts">
              新知共享<span class="grey"></span>
              <!-- /Resources -->
            </span>

            <!-- <span>新知共享</span> -->
          </div>

          <!-- <router-link
            v-if="speakList.length"
            :to="
              '/knowledge/autismSpeaks?classId=' +
              speakList[scoreIndex].autismClassificationId +
              '&name=' +
              speakList[scoreIndex].name
            "
            class="seoInput"
            hover-class="none"
          >
            <div
              class="right"
              v-if="speakList[scoreIndex].autismVOList.length != 0"
            >
              <div class="text">更多</div>
              <van-icon name="arrow" color="#999" />
            </div>
          </router-link> -->
        </div>

        <!-- 新知共享分类 -->
        <!-- <div class="scroll-view" v-if="speakList.length">
          <div
            class="scroll-item"
            :class="{ 'score-hover': scoreIndex == i }"
            @click="scoreIndex = i"
            v-for="(item, i) in speakList"
            :key="item.autismClassificationId"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="autismContent" v-if="speakList.length">
          <div class="scroll-x">
            <div
              class="item"
              v-for="i in speakList[scoreIndex].autismVOList"
              :key="i.autismId"
              @click="getDetail(i.name, i.fileUrl)"
            >
              
              <div class="imgs">
                <img :src="i.img" alt="" />
              </div>
              <div class="title">{{ i.name }}</div>
            </div>
          </div>
          @click="getDetail(item.autismId)">
          <noMessage
            style="margin-top: 50px; padding-bottom: 50px"
            v-if="speakList[scoreIndex].autismVOList.length == 0"
            type="1"
          ></noMessage>
        </div> -->
        <div class="know-how-list" v-if="speakList.length">
          <van-tabs color="#E8F5FD" background='#E8F5FD' title-inactive-color="#A7B0B6" v-model="active">
            <van-tab class="know-how-item" :title="item.name" v-for="item in speakList" :key="item.autismClassificationId + 'pid'">
              <div class="autismContent">
                <div class="scroll-x" >
                  <div
                    class="item"
                    v-for="i in item.autismVOList"
                    :key="i.autismId"
                    @click="getDetail(i.name, i.fileUrl)"
                  >
                    <div class="imgs">
                      <img :src="i.img" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
          <router-link
            class="course-titler"
            :to="
              '/knowledge/autismSpeaks?classId=' +
              speakList[active].autismClassificationId +
              '&name=' +
              speakList[active].name
            "
          >
            <span>查看更多</span>
            <van-icon name="arrow" />
          </router-link>
        </div>
        <noMessage
          style="margin-top: 50px; padding-bottom: 50px"
          v-if="speakList.length == 0"
          type="1"
        ></noMessage>
      </div>

      <!-- 暂不展示 -->
      <div class="QandABox" v-if="false">
        <div class="QandATitle">
          <span class="line"></span>
          <span>百问百答</span>
        </div>
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="finishedText"
          @load="getQAList()"
        >
          <div
            class="QandAItem"
            v-for="item in qaList"
            :key="item.qaId"
            @click="goPage(item.qaId)"
          >
            <div class="QandAItemTitle">
              <span>{{ item.categoryName }}</span>
            </div>
            <div class="QandAContent">
              <span>{{ item.title }}</span>
            </div>
            <div class="QandAItemMore">
              <van-icon name="arrow" color="#999999" />
            </div>
            <div class="QandAImgBox">
              <img class="QandAImg" src="@/assets/image/selected/QandA.png" />
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      active: 0,
      stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
      bannerList: [], //轮播图
      speakList: [],
      fileUrl: "",

      bannerIndex: 0,
      isShow: "",
      CollocationList: [],
      ExclusiveList: [],

      scoreIndex: 0,

      // 百问百答
      qaList: [], //数据
      answerPage: 1, //页数
      answerSize: 10, //条数
      loading: false,
      finished: false,
      finishedText: "暂无数据",
    };
  },
  // 事件处理器
  methods: {
    // 轮播图点击详情
    onSwiperDetail(e){
      if(e.link){
        let reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
        if(reg.test(e.link)){ // 如果是链接
          this.$router.push('/activity/iframe?url=' + e.link + '&title=' + e.title ? e.title : undefined);
        } else {
          if(e.link.split('/')[0] == 'activity' || e.link.split('/')[1] == 'activity'){
            this.$router.push(e.link + '&title=' + e.title)
          } else {
            this.$router.push(e.link)
          }
        }
      }
    },
    // 轮播图
    getBannerList() {
      this.$ajax({
        url: "/hxclass-mobile/rotation/app/2",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.bannerList = res.data;
        }
      });
    },
    // 轮播图切换
    changeSwiper(index) {
      this.bannerIndex = index;
    },
    changeIndicatorDots(e) {
      this.indicatorDots = !this.indicatorDots;
    },
    changeAutoplay(e) {
      this.autoplay = !this.autoplay;
    },
    intervalChange(e) {
      this.interval = e.target.value;
    },
    durationChange(e) {
      this.duration = e.target.value;
    },
    // 查看详情
    goDetail(item) {
      if(item.type == 1){ //课程
        this.$router.push('/curriculum/course?id=' + item.dataId)
      }else if(item.type == 2){ // 图书
        this.$router.push('/curriculum/BookDdetails?id=' + item.productId)
      }else if(item.type == 3){ // 教具
        this.$router.push('/curriculum/BookDdetails?id=' + item.productId)
      }else if(item.type == 4){ // 模拟考试
        this.$router.push('/examination/exam?id=' + item.dataId)
      }else if(item.type == 5){ // 电子照片
        
      }else if(item.type == 6){ // 延期
        
      }else if(item.type == 7){ // 补考
        
      }else if(item.type == 8){ // 直播
        this.$router.push('/live/liveDetail?id=' + item.productId)
      }else if(item.type == 9){ // 其他
        
      }
    },
    // 前往-百问百答列表页
    goPage(id) {
      this.$router.push({ path: "/selected/problemList?qaTypeId=" + id });
    },
    showMore(index) {
      if (this.isShow === index) {
        this.isShow = "";
      } else {
        this.isShow = index;
      }
    },
    // 自闭症之声
    getSpeakList() {
      this.$ajax({
        url: "/hxclass-mobile/autism/classification/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.speakList = res.data;
        }
      });
    },
    // 百问百答
    getQAList() {
      this.$ajax({
        url: "/hxclass-mobile/information/answer/list",
        params: {
          page: this.answerPage,
          size: this.answerSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // this.qaList = res.data;
          this.qaList = [...this.qaList, ...res.data.records];
          // 加载完成
          if (this.qaList.length == res.data.total) {
            this.finished = true;
            this.finishedText = "没有更多了";
          }
          // 无数据
          if (this.qaList.length == 0) {
            this.finished = true;
            this.finishedText = "暂无数据";
          }
          this.answerPage++;
          this.loading = false;
        }
      });
    },
    getDetail(name, url) {
      this.$router.push({
        path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
      });
    },

    // 查询搭配推荐
    getCollocation() {
      this.$ajax({
        url: "/hxclass-mobile/packages/packages-recommendation",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.CollocationList = res.data;
        }
      });
    },

    // 查询专属推荐
    getExclusive() {
      let userId = this.$store.state.userInfo.userId; //用户id
      this.$ajax({
        url: "/hxclass-mobile/packages/exclusive-recommendation",
        params: {
          userId,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.ExclusiveList = res.data;
          } else {
            this.$alert(res.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 页面滚动监听
    FunHandleScroll() {
      // 信息条渐变值
      this.stateOpacity = document.getElementById('content').scrollTop/100
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 轮播图
    this.getBannerList();
    this.getSpeakList();
    this.getQAList();
    this.getCollocation();
    this.getExclusive();

    // 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.FunHandleScroll();
      document
        .getElementById("content")
        .addEventListener("scroll", this.FunHandleScroll);
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    if (document.getElementById("content")) {
      document
        .getElementById("content")
        .removeEventListener("scroll", this.FunHandleScroll);
    }
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
// 信息条
.box_status {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #fff;
  opacity: 0;
  z-index: 100;
}
// 轮播图
/deep/.van-swipe__indicator {
  width: 15px;
  height: 3px;
  border-radius: 1px;
}
/deep/.van-swipe__indicators {
  left: auto;
  right: 0;
  bottom: 9px;
}
/deep/.van-swipe__indicator {
  opacity: 1;
}
.pricestander {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #FF7A00;
  line-height: 14px;
  font-family: PingFangMedium;
  .present {
    font-size: 14px;
    font-weight: 500;
  }
  .free {
    font-size: 14px;
  }
  .original {
    margin-left: 8px;
    font-size: 12px;
    color: #999999;
    font-weight: 400;
    text-decoration: line-through;
    font-family: PingFangMedium, PingFang S;
  }
}
.selectedMain {
  .selectedBox {
    position: relative;
    padding-top: 50px;
    width: 100%;
    height: 132px;
    // background: #34A4A2;
    border-radius: 0px 0px 24px 24px;
    .selectedTitle {
      margin-bottom: 2px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 27px;
      text-align: center;
      letter-spacing: 2px;
    }
    .selectedBanner {
      padding: 0 18px;
      .my-swipe {
        border-radius: 14px;
      }
      .swiper {
      }
      .swiperImg {
        background-repeat: no-repeat;
        // object-fit: cover;
        width: 100%;
        height: 160px;
      }
      /deep/ .uni-swiper-dot-active {
        background-color: #fed846 !important;
      }
      /deep/ .uni-swiper-dot {
        display: inline-block;
        width: 16px;
        height: 3px;
        cursor: pointer;
        background: #fff;
        border-radius: 0%;
      }
      /deep/ .uni-swiper-dots-horizontal {
        left: 88%;
      }
      .cardBox {
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        .cardCurriculum {
          display: inline-block;
          // flex: 1;
          width: 42%;
          // height: 67px;
          // background: linear-gradient(
          //   133deg,
          //   #15b7dda6 0%,
          //   #15b7dd 52%,
          //   #15b7dda6 100%
          // );
          background: linear-gradient(196deg, rgba(21, 183, 221, 0.65) 0%, #15B7DD 52%, rgba(21, 183, 221, 0.65) 100%);
          // box-shadow: 0px 2.5px 4.5px .5px rgba(28,185,222,0.35);
          // margin-right: 13px;
          border-radius: 13px;
          padding:  13px 10px  11px 10px;
          position: relative;
          .curriculumContent {
            // padding-right: 3px;
            .curriculumTitle {
              font-size: 18px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 21px;
            }
            .curriculumExplain {
              // padding-top: 4.5px;
              display: block;
              font-family: PingFangMedium;
              line-height: 23px;
              color: #fff;
              font-size: 12px;
            }
          }
          .CurriculumImgbox {
            position: absolute;
            right: 0;
            bottom: -2px;
            pointer-events: none;
            .bg-icon3{
              width: 40px;
              height: 22px;
              position: absolute;
              right: 15px;
              top: -6px;
            }
            .CurriculumImg {
              width: 100%;
              max-width: 100px;
            }
          }
        }
        .cardBooks {
          display: inline-block;
          width: 42%;
          // height: 40.5px;
          // background: linear-gradient(
          //   133deg,
          //   #ffc243a6 0%,
          //   #ffc243 52%,
          //   #ffc243a8 100%
          // );
          background: linear-gradient(155deg, #61CCEE 0%,  #7466FF 100%);
          
          // box-shadow: 0px 2.5px 4.5px .5px rgba(255,196,74,0.35);
          border-radius: 13px;
          padding: 13px 10px 11px 10px;
          position: relative;
          .BooksContent {
            // padding-right: 3px;
            .BooksTitle {
              font-size: 18px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 21px;
            }
            .BooksExplain {
              // padding-top: 4.5px;
              display: block;
              line-height: 23px;
              color: #fff;
              font-family: PingFangMedium;
              font-size: 12px;
            }
          }
          .bg-icon3{
            width: 40px;
            height: 22px;
            position: absolute;
            right: 15px;
            top: -6px;
          }
          .BooksImgbox {
            position: absolute;
            right: 0;
            bottom: -2px;
            pointer-events: none;
            .BooksImg {
              width: 100%;
              max-width: 100px;
            }
          }
        }
      }
    }
    .exclusiveBox {
      padding: 0 18px;
      color: #333;
      background: #fff;
      margin-top: 16px;
      .exclusiveTitle {
        padding-top: 14px;
        // display: flex;
        span {
          font-weight: bold;
        }
      }
      .exclusiveContent {
        .exclusiveList {
          border-bottom: 1px solid #EEEEEE;
          background: #ffffff;
          // box-shadow: 0px 2.5px 4.5px .5px rgba(92,118,152,0.2);
          border-radius: 6px;
          padding: 12.5px;
          font-family: PingFangMedium;
          .listTitle {
            font-weight: 400;
            margin-bottom: 9px;
            color: #666666;
            line-height: 16.5px;
            font-size: 11px;
            .exclusiveImg {
              width: 10px;
              height: 10px;
              margin-right: 5.5px;
            }
          }
          .listBox {
            display: flex;
            position: relative;
            .left {
              min-width: 113px;
              width: 113px;
              height: 79.5px;
              // box-shadow: 0px 2px 2px .5px rgba(0,0,0,0.15);
              border-radius: 7.5px;
              .exclusiveImg {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                object-fit: cover;
                border-radius: 8px;
              }
            }
            .centre {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding-left: 12.5px;
              .centerTitle {
                display: flex;
                font-size: 14.5px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .primaryPrice {
                display: flex;
                font-size: 11px;
                font-weight: 400;
                font-family: PingFangMedium;
                color: #999;
                line-height: 16.5px;
                padding-top: 2px;
                text-decoration: line-through;
              }
              .Price {
                padding-top: 10px;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                font-family: PingFangMedium;
                color: #FF7A00;
                // line-height: 20px;
                .couponPrice {
                  font-size: 16px;
                  font-weight: 500;
                  span{
                    font-size: 14px;
                  }
                }
              }
            }
            .right {
              position: absolute;
              bottom: 0px;
              right: 0.5px;
              .all-btn-small{
                width: 84px;
                height: 28px;
                line-height: 28px;
                border-radius: 12.5px;
              }
              .all-btn-small::after{
                background: none;
              }
            }
          }
          .tips {
            font-size: 10px;
            color: #333;
            line-height: 14px;
            margin-top: 10px;
            &::before {
              margin-right: 3px;
              content: "*";
              color: red;
            }
          }
        }
        .exclusiveList:last-child{
          border-bottom: none;
        }
      }
    }

    .collocationBox {
      background: #fff;
      padding: 0 18px;
      margin-top: 8px;
      .collocationTitle {
        display: flex;
        padding-top: 15px;
        font-size: 16.5px;
        font-weight: bold;
        color: #333333;
        line-height: 16.5px;
        .left {
          flex: 1;
        }
        .right {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 400;
          color: #999;
          line-height: 16.5px;
        }
      }
      .Tips {
        display: flex;
        font-size: 10px;
        font-weight: 500;
        color: #999999;
        line-height: 15px;
        margin-top: 2px;
      }
      .collocationList {
        .courseItem {
          margin-top: 11px;
          padding: 12.5px 12.5px 0 12.5px;
          background: #fff;
          border-radius: 18px;
          .title {
            font-family: PingFangMedium;
            font-size: 16.5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-weight: 500;
            color: #333333;
            line-height: 25.5px;
          }
          .up {
            display: flex;
            .left {
              flex: 1;
              .tagbox {
                display: flex;
                align-items: center;
              }
              .tag {
                width: 42px;
                height: 19px;
                margin-right: 4.5px;
                display: inline-block;
            // background: linear-gradient(80deg, #FFC243 0%, rgba(255, 188, 52, 0) 100%);
            // background: url(~@/assets/image/selected/tag.png) no-repeat;
            // border-radius: 0px 7px 7px 7px;
                img{
                  width: 100%;
                  height: 100%;
                }

              }
              .price {
                font-size: 16px;
                font-weight: 500;
                font-family: PingFangMedium;
                color: #FF7A00;
                line-height: 27px;
              }
            }
            .right {
              display: flex;
              // flex-direction: column;
              align-items: flex-end;
              .all-btn-small{
                width: 84px;
                height: 28px;
                line-height: 28px;
                border-radius: 12.5px;
              }
              .all-btn-small::after{
                background: none;
              }
            }
          }
          .down {
            margin-top: 10px;
            .item {
              display: flex;
              padding-bottom: 14.5px;
              .courseImg {
                min-width: 90.5px;
                width: 90.5px;
                height: 63.5px;
                border-radius: 6px 6px 6px 6px;
                background-repeat: no-repeat;
                object-fit: cover;
              }
              .right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2.5px 0 0 11px;
                .title {
                  display: flex;
                  font-size: 14px;
                  font-weight: 400;
                  width: 100%;
                  line-height: 19px;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  // clamp属性并不是css的标准属性
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
                .price {
                  display: flex;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14.5px;
                  span:nth-child(1) {
                    font-weight: bold;
                    font-family: PingFangMedium;
                    color: #FF7A00;
                    margin-right: 5px;
                  }
                  span:nth-child(2) {
                    color: #999999;
                    text-decoration: line-through;
                  }
                }
                .identification {
                  font-size: 11px;
                  font-weight: 400;
                  color: #1e97b4;
                  line-height: 15.5px;
                }
              }
            }
            .more {
              border-top: 1px solid #eeeeee;
              padding: 13.5px 0 14.5px 0;
              text-align: center;
              font-size: 11px;
              font-weight: 400;
              color: #666666;
              line-height: 15.5px;
            }
          }
        }
      }
    }
    .QandABox {
      padding: 0 18px 18px 18px;
      .QandATitle {
        padding-top: 16.5px;
        font-size: 16.5px;
        font-weight: bold;
        color: #333333;
        line-height: 24.5px;
      }
      .QandAItem {
        position: relative;
        margin: 21.5px 0;
        height: 87px;
        background: #ffffff;
        // box-shadow: 0px 2.5px 4.5px .5px rgba(93,131,167,0.25);
        border-radius: 4.5px;
        &:last-child {
          margin-bottom: 0;
        }
        .QandAItemTitle {
          position: absolute;
          width: 92.5px;
          height: 27.5px;
          background: #1bb9de;
          // box-shadow: 0px 2px 2px .5px rgba(27,185,222,0.24);
          border-radius: 9px 4.5px 9px 0;
          top: -8.5px;
          text-align: center;
          span {
            display: flex;
            padding: 3.5px 5.5px;
            font-size: 13.5px;
            font-weight: bold;
            color: #ffffff;
            line-height: 20.5px;
            text-shadow: 0px 2px 2px rgba(9, 137, 167, 0.46);
          }
        }

        .QandAItemMore {
          position: absolute;
          top: 8.5px;
          right: 15px;
        }

        .QandAContent {
          padding: 35.5px 9px 9.5px 12.5px;
          span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            line-height: 17.5px;
            height: 35px;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: normal !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .QandAImgBox {
          position: absolute;
          right: 0;
          bottom: -4px;
          .QandAImg {
            width: 70.5px;
            height: 73.5px;
          }
        }
      }
    }
    .moneySign {
      font-size: 14px;
      font-weight: 500;
    }
    .line {
      display: inline-block;
      width: 3px;
      height: 13px;
      background: #15b7dd;
      border-radius: 4px;
      margin-right: 4px;
    }
    // .rightButton{
    // 	width: 76.5px;
    // 	height: 25.5px;
    // 	background: #15B7DD;
    // 	border-radius: 20.5px;
    // 	// box-shadow: 0px 2px 2px .5px rgba(0,0,0,0.15);
    // 	color: #fff;
    // 	font-size: 12.5px;
    // 	text-align: center;
    // 	line-height: 25.5px;
    // }
  }

  .autismBox {
    margin-top: 8px;
    background: #fff;
    padding-bottom: 18px;
    .autismTitle {
      margin: 0 17px;
      display: flex;
      padding-top: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 24.5px;
      .left {
        flex: 1;
      }
      .right {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: #999;
      }
    }

    .scroll-view {
      box-sizing: border-box;
      padding: 8px 0;
      padding-bottom: 0;
      // width: 100%;
      margin: 0 18px;
      overflow-y: scroll;
      white-space: nowrap;
      .scroll-item {
        display: inline-block;
        text-align: center;
        padding-bottom: 7.5px;
        margin-right: 24px;
        font-size: 13px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
      }
      .score-hover {
        color: #15b7dd;
        font-weight: 500;
        position: relative;
      }
      .score-hover::after {
        content: "";
        display: inline-block;
        background: url(../../assets/image/icon/20220218041506.png) no-repeat;
        background-size: 100%;
        width: 5.5px;
        height: 6.5px;
        position: absolute;
        right: -4px;
        bottom: 7px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    // .autismContent {
    //   margin: 0 18px;
    //   padding: 16px 16px 12px;
    //   background: #E8F5FD;
    //   border-radius: 10px;
    //   .scroll-x {
    //     display: flex;
    //     flex-direction: row;
    //     overflow-x: scroll;
    //     padding-right: 10px;
    //   }
    //   .item {
    //     flex: none;
    //     width: calc((100% - 34px) / 3);
    //     margin-right: 17px;
    //     &:last-child {
    //       margin-right: 0;
    //     }
    //     .imgs {
    //       width: 100%;
    //       height: 135px;
    //       overflow: hidden;
    //       // border-radius: 10px;
    //       box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.12);
    //       img {
    //         width: 100%;
    //         height: 100%;
    //         background-repeat: no-repeat;
    //         object-fit: cover;
    //       }
    //     }
    //     .title {
    //       margin-top: 5px;
    //       font-size: 14px;
    //       line-height: 1.4;
    //       color: #333;
    //       display: -webkit-box;
    //       -webkit-box-orient: vertical;
    //       -webkit-line-clamp: 1;
    //       overflow: hidden;
    //     }
    //   }
    // }
  }
}
.know-how-list{
  margin: 0 auto;
  margin-top: 10px;
  padding-bottom: 14px;
  width: calc(100% - 18px);
  position: relative;
  .course-titler{
    line-height: 44px !important;
    position: absolute;
    top: 0;
    right: 18px;
    font-size: 12px;
    font-family: PingFangMedium;
    // font-weight: 500;
    color: #999999;

    img {
      width: 13px;
      margin-left: 5px;
      vertical-align: sub;
    }
  }
  /deep/.van-tabs{
    background: #E8F5FD;
    border-radius: 10px;
  }
  /deep/.van-tabs__wrap{
    border-top-left-radius: 10px;
    background: #E8F5FD;
    width: calc(100% - 110px);
    .van-tab{
      max-width: 100px;
    }
  }
}
.know-how-item {
  padding: 16px 20px;
  margin-right: 18px;
  padding-top: 0;
  // margin-bottom: 10px;
  .course-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin-bottom: 10px;
  }
  .autismContent {
    .scroll-x {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      padding-right: 10px;
      padding-bottom: 10px;
    }
    .item {
      width: 92.5px;
      flex: none;
      // overflow: hidden;
      margin-right: 17px;
      &:last-child {
        margin-right: 0;
      }
      .imgs {
        width: 100%;
        border-radius: 0px !important;
        height: 135px;
        overflow: hidden;
        box-shadow: 5px 5px 4px 1px rgba(0, 0, 0, 0.05);
        // border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          border-radius: 0px !important;
          object-fit: cover;
        }
      }
      .title {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.4;
        color: #333;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.swiper {
  border-radius: 0 10px 10px 0;
  .swiper-pagination {
    text-align: right;
    padding-right: 10px;
    box-sizing: border-box;
    /deep/.swiper-pagination-bullet {
      height: 3px;
      width: 15px;
      border-radius: 1px;
      background-color: #fff;
      opacity: 1;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #15b7dd;
    }
  }
}
.texts {
  font-size: 16.5px;
  font-family: PingFangMedium;
  color: #333;
  display: inline-block;
  .grey {
    font-size: 10px;
    font-weight: 300 !important;
    font-family: Light;
    color: #999999;
  }
}
</style>
