/*
	登录状态判断白名单
		白名单目的是为了过滤不需要登录也可以进行正常访问的页面，
		如果当前页面是需要登录才能访问的，
		请不要加入白名单
		
		页面路径在控制台有打印，复制到下面的数组即可
*/
export default [
    '/home', // 首页
    '/guidePage', // 引导页
    '/search/globalSearch', //搜索
    '/propagandaHeadlines/audioList', // 云课头条
    '/curriculum/courseList', // 教育课程列表
    '/curriculum/course', // 教育课程详情
    '/curriculum/courseBuy', // 教育课程详情-第三方课程购买
    '/selected', // 精选
    '/curriculum/setMealList', // 更多套餐
    '/curriculum/Packagedetails', // 课程详情
    '/knowledge/autismSpeaks', //新知共享列表
    '/knowledge/specialArea', //新知共享列表
    '/knowledge/specialAreaCon', //孤独症之声详情
    '/study', // 学习
    '/myStudy/studyCopy', // 学习
    '/my', // 我的
    '/login/login', // 登录页面
    '/login/clause', //协议条款
    '/login/retrievePassword', //忘记密码
    '/login/bindPhone', //绑定手机号
    '/examination/examSignList', //考试报名列表
    '/examEnroll/registration', //考试报名
    '/shop/bookTeach', // 图书教具
    '/curriculum/BookDdetails', // 图书教具详情
    '/examination/examDetail', //模拟考试详情
    '/mine/feedback', // 帮助与反馈
    '/curriculum/teacher', // 授课教师
    '/curriculum/certificates', // 相关证书
    '/shop/shoppingMall', // 商城
    '/search/index', // 认证查询
    '/search/meshanQuery', // 机构查询
    '/search/teacherQuery', // 教师查询
    '/search/scoreQuery', // 成绩查询
    '/search/cardQuery', // 证书查询
    '/search/zhongkangQuery', // 中康查询
    '/all/ServiceAgreement', // 华夏云课堂服务协议
    '/all/membershipInterests', // 华夏云课堂服务协议
    '/all/privacyPolicy', // 华夏云课堂隐私协议
    '/shop/mock', // 华夏云课堂隐私协议
    '/all/detailedList', // 三方协议
    '/curriculum/shareCourse', // 分享课程跳转页面
    '/curriculum/shareBookDdetails', // 分享图书教具跳转页面
    '/curriculum/sharePackagedetails', // 分享套餐跳转页面
    '/info/shareInformation', // 分享资讯跳转页面
    '/setUp/watchToken', // 监听用户信息是否存在
    '/examination/shareExamDetail', // 模拟考试分享页面
    '/myStudy/share', // 模拟考试分享页面
    '/live/shareLive', // 直播分享页
    '/search/kfxhTemplateDetail', // 康复协会证书详情
    '/VHTML', // 富文本页面
    // '/myStudy/eBook', // 电子书
    '/login/createUser', // 一键生成用户
    '/info/information', // 资讯详情
    '/curriculum/studentActive', // 学生优惠详情
    '/activity/teacherActive', // 教师活动
    '/activity/teacherGiftPack', // 教师活动分享
]