<template>
	<!-- 证书预览 -->
	<div class="certificatePreview">
		<!-- 导航栏 -->
		<van-nav-bar
			title="证书预览"
			left-arrow
			class="nav-circular"
			:fixed="true"
			:placeholder="true"
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>
		<div class="box">
			<div class="box_title">{{name}}</div>
			<img 
				class="box_image" 
				:src="picture"
				@click="showImg()"/>
			<div class="box_button">
				<span class="all-btn-middle" @click="downImg">下载</span>
			</div>
		</div>
		<a :href="picture"></a>
	</div>
</template>

<script>
	import { FunJudgDevice } from '@/unit/fun.js'
	import { downLoadImg } from '@/assets/js/NativeWebKit.js'
	export default{
		data(){
			return{
				name: '', //姓名
				picture: '', //图片
			}
		},
		created() {
			let params = this.$route.query
			this.name = params.name
			this.picture = params.picture
		},
		methods:{
      // 预览图片
      showImg() {
        this.$ImagePreview([this.picture]);
      },
      // 下载
      downImg() {
        const type = FunJudgDevice()
        // ios 安卓处理
        if((type == 'ios' && window.webkit) || type == 'android'){
          downLoadImg(this.picture)
        } else {
          this.downloadIamge(this.picture, this.name)
        }
      },
      downloadIamge(imgsrc, name) {//下载图片地址和图片名
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
      },
		},
	}
</script>

<style>
	page{
		background-color: #F7F7F9;
	}
</style>
<style lang="less" scoped>
	* {box-sizing: border-box;}
	.certificatePrediv {
		background: #F7F7F9;
	}
	
	.box {
		margin-top: 51.5px;
		padding: 0 18px;
		.box_title {
			text-align: center;
			font-size: 18px;
			font-family: PingFangMedium;
			font-weight: 500;
			color: #333333;
      line-height: 28px;
		}
		.box_image {
			width: 100%;
			margin: 27px 0;
		}
		.box_button {
			text-align: center;
			padding: 0 22px;
			.all-btn-middle {
				width: 100%;
				height: 36px;
				line-height: 36px;
				font-size: 14.5px;
				font-weight: bold;
				font-family: PingFangMedium;
			}
		}
	}
	

</style>
