<template>
	<!-- 优惠券 -->
	<div class="coupon">
		<!-- 导航栏 -->
		<van-nav-bar
			title="优惠券"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

 	  	<!-- <div class="tabNav" v-if="tabList">
      	  <div class="tabItem" :class="{'active': tabIndex==index}" v-for="(item, index) in tabList" :key="index" @click="tabIndex=index,finished = false">{{item.name}}</div>
      	</div> -->
		<SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />
		<!-- 优惠券 -->
		<div class="list">
			<van-list
				v-model="loading"
				:finished="finished"
				:immediate-check="false"
				:finished-text="finishedText"
				@load="getList"
			>
				<div class="couponItem" :class="[item.state==3?'overdue':'']" v-for="item in list" :key="item.couponId">
					<div class="left">
						<template v-if="item.type == 1">
							<span class="price"><span>¥</span>{{item.couponPrice}}</span>
							<span class="condition">满{{item.fullPrice}}元可用</span>
						</template>
						<template v-if="item.type == 3">
							<span class="price">{{item.discount}}<span>折</span></span>
							<span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
						</template>
						<span class="rightTop"></span>
						<span class="rightBottom"></span>
						<img v-show="item.state==3" src="@/assets/image/my/overdue.png" mode="widthFix">
					</div>
					<div class="right">
						<span class="title">{{item.name}}</span>
						<span class="time" v-if="item.times == 2">{{item.startTime}}至{{item.endTime}}</span>
						<span class="time" v-if="item.times == 1">不限使用时间</span>
						<span class="type">[{{item.scope==1?'全场通用':item.scope==2?'指定类型可用':'指定商品可用'}}]</span>
						<div @click="goDetail(item)" v-if="item.state==1">
							<span class="btn">可用商品</span>
						</div>
						<img v-if="item.noReceive == 1 && item.state==2" src="@/assets/image/my/used.png" mode="widthFix">
						<!-- <img v-if="item.noReceive == 1 && item.state==3" src="@/assets/image/my/end.png" mode="widthFix"> -->
					</div>
				</div>
			</van-list>
			<noMessage style="margin-top:50px" v-if="list.length==0 && flag" type=7></noMessage>
		</div>
	</div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwichTab from "@/components/SwichTab.vue";
export default {
	// 可用组件的哈希表
	components: { noMessage , SwichTab },
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			pageNumber: 1,
			pageSize: 10,
			list: [],
			flag: false,
			loading: false,
			finished: false,
			finishedText: '',
			type: 1,
			tabIndex: 0,
	  		tabList: [
				{
					type: 1,
					name: '未使用',
				},
				{
					type: 2,
					name: '已使用',
				},
				{
					type: 3,
					name: '已过期',
				}
	  		]
		}
	},
	// 事件处理器
	methods: {
		 changeTab(e) {
    	  this.tabIndex = e
    	},
		// 优惠券详情跳转
		goDetail(e){
			if(e.scope == 1){
				this.$router.push('/shop/shoppingMall');
			}else{
				this.$router.push('/mine/couponGoods?couponId='+e.couponId);
			}
		},
		// 查询优惠券列表
		getList(x){
			if(x == 1){
				this.list = [];
				this.pageNumber = 1;
			}
			this.$ajax({
				url: '/hxclass-mobile/app-mall/user/coupon-list',
				params: {
					page: this.pageNumber,
					size: this.pageSize,
					type: this.type,
				}
			}).then(res=>{
				this.flag = true;
				if(res.code == 200 && res.success){
					this.list = [...this.list, ...res.data.records]
					// 加载完成
					if (this.list.length == res.data.total) {
						this.finished = true;
						if(document.documentElement.clientHeight-106 > (84+30.5)*res.data.total+this.$store.state.stateHeight){
							this.finishedText = '';
						}else{
							this.finishedText = '没有更多了';
						}
					}else{
						this.finished = false;
					}
					// 无数据
					if (this.list.length == 0) {
						this.finished = true
						this.finishedText = ''
					}
					this.pageNumber++
					this.loading = false
				}else{
					this.$alert(res.message)
				}
			}).catch(err=>{
				console.error(err)
			})
		}
	},
	// 生命周期-实例创建完成后调用
	created () {
		this.getList()
	},
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {
		tabIndex(newval) {
			this.type = this.tabList[newval].type
			this.finished = true;
			this.finishedText = '';
			this.flag = false;
			this.getList(1);
		},
	}
}
</script>

<style lang="less" scoped>
	.coupon{
		min-height: 100vh;
		background: #F7F7F9;
		padding: 21px 18px 0;
		box-sizing: border-box;
    display: flex;
    flex-direction: column;
		.tab {
  			line-height: 0;
  			padding: 0;
		}
		// .tabNav {
   		// 	display: flex;
		// 	justify-content: space-between;
   		// 	padding: 0 0 12px ;
   		// 	.tabItem {
   		// 	  font-size: 14px;
   		// 	  font-family: PingFangMedium;
   		// 	  font-weight: 400;
   		// 	  color: #666666;
   		// 	  line-height: 27px;
   		// 	  position: relative;
   		// 	  padding: 0 12px;
   		// 	}
   		// 	.active {
   		// 	  font-size: 16px;
   		// 	  font-family: PingFangMedium;
   		// 	  font-weight: bold;
   		// 	  color: #333333;
   		// 	  line-height: 27px;
   		// 	  background-image: url(~@/assets/image/examination/certificate_icon1.png);
   		// 	  background-size: 41px 6px;
   		// 	  background-repeat: no-repeat;
   		// 	  background-position: 50% 19px;
   		// 	}
  		// }
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			// border-radius: 6px;
			// border: 1px solid #F93C00;
			display: flex;    
			height: 84px;

			margin-bottom: 30.5px;
			.left{
				display: flex;
				flex-direction: column;
				span-align: center;
				width: 90px;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	// border-left: 1px solid #EC6C01;
				// 	// border-right: 1px solid #EC6C01;
				// 	// border-bottom: 1px solid #EC6C01;
				// 	// border-top: 1px solid #1010dd;
				// 	// border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -1px;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #F7F7F9;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	// border-left: 1px solid #EC6C01;
				// 	// border-right: 1px solid #EC6C01;
				// 	// border-top: 1px solid #EC6C01;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	bottom: -1px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #F7F7F9;
				// }
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 9;
    			border-top-right-radius: 8px;
				}
			}
		}
		.overdue{
			// border-color: #F7F7F9;
			// background-color: rgba(153, 153, 153, 0.08);
			.left {
        border-color: #999999;
        background-image: url(../../assets/image/20220622-154538.png);
        &::before{
          background-image: url(../../assets/image/20220622-154546.png);
        }
        &::after{
          background-image: url(../../assets/image/20220622-154542.png);
        }
      }
      .right{
        background-image: url(../../assets/image/20220622-154538.png);
        &::after{
          background-image: url(../../assets/image/20220622-154518.png);
        }
      }
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
	.list{
		padding-bottom: 21px;
		flex: 1;
		position: relative;
		/deep/.van-list{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: auto;
		}
	}
</style>
