<template>
	<div class="orderDetail" v-if="orderDetail" :style="{ 'paddingTop': ((stateHeight * 1) + 56) + 'px' }">
		<!-- 订单详情（非课程） -->
		<div class="nav" style="position: fixed;top:0;left: 0;right: 0;z-index: 10;"
			:style="{ 'paddingTop': stateHeight + 'px' }">
			<div @click="back">
				<img src="../../assets/image/whiteLeft.png" />
			</div>
			<div class="orderType" v-if="orderDetail.status == 1">
				<span class="typeText">等待付款</span>
				<span class="enclosure" v-if="orderDetail.payMethod != 3">
					<van-count-down :time="time">
						<template #default="time">
							<span class="enclosure">剩余</span>
							<span class="enclosure" v-if="!!time.days">{{ time.days }}天</span>
							<span class="enclosure">{{ time.hours }}小时</span>
							<span class="enclosure">{{ time.minutes }}分</span>
							<span class="enclosure">自动关闭</span>
						</template>
					</van-count-down>
					<!-- <van-count-down :time="time" format="剩余DD天HH小时mm分自动关闭" /> -->
				</span>
			</div>
			<div class="orderType" v-if="orderDetail.status == 2">
				<span class="typeText">等待发货</span>
				<span class="enclosure">商品正在快速打包</span>
			</div>
			<div class="orderType" v-if="orderDetail.status == 3">
				<span class="typeText">等待收货</span>
				<span class="enclosure">物流配送中</span>
			</div>
			<div class="orderType" v-if="orderDetail.status == 4">
				<span class="typeText">部分发货</span>
			</div>
			<div class="orderType" v-if="orderDetail.status == 5">
				<span class="typeText">交易成功</span>
			</div>
			<div class="orderType" v-if="orderDetail.status == 6">
				<span class="typeText">交易关闭</span>
			</div>
		</div>
		<!-- 等待付款-地址 -->
		<div class="addressMess" v-if="orderDetail.address && (orderDetail.status == 1 || orderDetail.status == 2)">
			<div class="userMess">
				<div class="">
					<img src="@/assets/image/address/icon1.png" />
					<span class="userName">{{ orderDetail.address.name }}</span>
					<span class="phone"
						v-if="orderDetail.address.phone">{{ orderDetail.address.phone.substr(0, 3) }}****{{ orderDetail.address.phone.substr(7, 11) }}</span>
				</div>
				<router-link v-if="orderDetail.status == 1" :to="'/address/adjust?orderId=' + orderId"
					class="changeAddress">修改地址</router-link>
				<!-- <span class="changeAddress">修改地址</span> -->
			</div>
			<span
				class="addressText">{{ orderDetail.address.provinceName + orderDetail.address.cityName + (orderDetail.address.districtName ? orderDetail.address.districtName : '') + orderDetail.address.address }}</span>
		</div>

		<!-- 等待收货 -->
		<div class="logistics" v-if="orderDetail.status == 3 || orderDetail.status == 4">
			<div class="logisticstatus" v-if="orderDetail.kuaidiInfo">
				<span class="sign"></span>
				<span class="status">{{ orderDetail.kuaidiInfo.status }}</span>
				<span class="time">{{ orderDetail.kuaidiInfo.ftime.substr(0, 16) }}</span>
			</div>
			<div class="currentAddress" v-if="orderDetail.kuaidiInfo">
				<span>{{ orderDetail.kuaidiInfo.context }}</span>
			</div>
			<div class="logisticstatus logisticstatusbot">
				<span class="addresssign"></span>
				<span
					class="address">{{ orderDetail.address.provinceName + orderDetail.address.cityName + (orderDetail.address.districtName ? orderDetail.address.districtName : '') + orderDetail.address.address }}</span>
			</div>
			<div class="userMessage">
				<span class="name">{{ orderDetail.address.name }}</span>
				<span class="phone"
					v-if="orderDetail.address.phone">{{ orderDetail.address.phone.substr(0, 3) }}****{{ orderDetail.address.phone.substr(7, 11) }}</span>
			</div>
			<!-- <router-link to="/order/logistics" class="check">查看信息</router-link> -->
			<!-- <span class="check">查看信息</span> -->
		</div>
		<!-- 商品信息 -->
		<div class="shopMess" v-if="orderDetail">
			<div class="goods">
				<span class="warning" v-if="orderDetail.status == 4">您的包裹被分为多个包裹寄出，请注意查收。</span>
				<div class="shopItem" v-if="orderDetail.source">
					<div class="commodity">
						<img :src="orderDetail.orderPackage.pcMobileListPicture" />
						<div class="rightMess">
							<div class="titlePrice">
								<span class="title all-font-ellipsis">{{ orderDetail.orderPackage.packageName }}</span>
								<!-- <span class="price" v-if="item.payPrice != item.productPrice">实付：¥ {{item.payPrice.toFixed(2)}}</span> -->
								<span class="price">¥ {{ orderDetail.orderPackage.packagePrice ?
									(orderDetail.orderPackage.packagePrice * 1).toFixed(2) : '0.0'}}</span>
							</div>
							<div class="navNum">
								<span></span>
								<span class="num">x{{ orderDetail.orderPackage.count }}</span>
							</div>
						</div>
					</div>
					<div class="packedList" v-if="orderDetail.source">
						<p class="haveStyle">包含</p>
						<!-- 商品 -->
						<template v-for="(ite, ind) in orderDetail.productList">
							<div class="shopItem" @click="goDetail(item)" v-if="ite.type == 1" :key="ind">
								<img class="shopImg" :src="ite.productPicture" v-if="ite.productPicture" />
								<div class="shopMessage haveNav">
									<div class="shoptielt-price">
										<span class="shopTitle havNav">{{ ite.productName }}</span>
										<span class="shopprice" v-if="!ite.noPay">¥ {{ ite.productPrice ?
											ite.productPrice.toFixed(2) : '0.0'}}</span>
										<span class="isPay" v-else>已购买</span>
									</div>
									<div class="stage_box">
										<div class="stage">
											<div :class="ite.openTimeId ? 'openTime' : 'stage_info'" v-if="ite.type == 1">
												{{ ite.openTime ? ite.openTime : ite.courseDeferralDaysNotes }}</div>
											<div class="stage_info" v-if="ite.type == 1 && ite.address">{{ ite.address }}
											</div>
											<div class="stage_info" v-if="ite.type == 4">{{ ite.examNumberNotes }}</div>
											<div class="stage_info" v-if="ite.type == 5"></div>
											<!--延期 缺少字段 <div class="stage_info" v-if="ite.type == 6">{{ite.makeNum}}次补考次数;<br/>课程延期天数{{ite.delaysNum}}</div> -->
										</div>
										<div class="num" v-if="ite.type == 3 || ite.type == 2 || ite.productCount > 1">
											x{{ ite.productCount }}</div>
									</div>
									<!-- <div class="stage_label">{{ite.productType == 2 || ite.productType == 3?'支持七天无理由退货':'不支持退款及转让'}}</div> -->
									<!-- <div class="priceNum">
										<span class="price">¥12321.00</span>
										<span class="num">x1</span>
									</div>-->

									<!-- <div class="refundButton">
										<span v-show="ite.refundStatus" @click="onPopupOpen(ite)">退款记录</span>
									</div> -->
									<div class="btn-tag" v-show="ite.refundStatus">
										<div class="refund-tag">
											<span>{{ ite.refundStatus == 1 ? '退款中' : ite.refundStatus == 2 ? '部分退款' :
												ite.refundStatus == 3 ? '全部退款' : '' }}</span>
										</div>
										<div class="refundButton">
											<span @click.stop="onPopupOpen(ite)">退款记录</span>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					<!-- <div class="refundButton">
						<span v-if="(item.productType == 2 || item.productType == 3) && orderDetail.status != 6 && orderDetail.status != 1" @click="$Toast('如需退款，请联系客服')">申请退款</span>
					</div> -->
				</div>
				<div class="shopItem" @click="goDetail(item)" v-show="!orderDetail.source"
					v-for="(item, index) in orderDetail.productList.filter(item => { return item.type == 1 })" :key="index">
					<div class="commodity">
						<img v-if="item.productPicture" :src="item.productPicture" />
						<div class="rightMess">
							<div class="titlePrice">
								<span class="title all-font-ellipsis">{{ item.productName }}</span>
								<span class="price" v-if="item.payPrice != item.productPrice">实付：¥ {{ item.payPrice ?
									item.payPrice.toFixed(2) : '0.0'}}</span>
								<span class="price" v-if="item.payPrice == item.productPrice">¥ {{ item.payPrice ?
									item.payPrice.toFixed(2) : '0.0'}}</span>
							</div>
							<div class="titlePrice" v-if="item.payPrice != item.productPrice">
								<span class="title"></span>
								<span class="price" style="color: #999999;"
									v-if="item.productPrice && (item.productType == 2 || item.productType == 3)">¥
									{{ item.productPrice.toFixed(2) }}</span>
							</div>
							<div class="navNum">
								<div v-if="item.productType == 1">
									<!-- <span class="navTime" v-if="item.productType == 1 && (item.openTime || item.otherNotes)">{{item.openTime?item.openTime:item.otherNotes}}</span> -->
									<div class="temp-box navTime" style="display: flex; align-items: center;">
										<img src="@/assets/image/order/validity-icon.png" alt=""
											v-if="!item.openTime && (item.validDayType == 1 || item.validDayType == 2)"
											style="width: 34px; height: 15px; border-radius: 0px; margin-right: 6px;">
										{{ item.openTime ? item.openTime : item.validDayType == 1 ? (item.validDays + '天') :
											item.validDayType
												== 2 ? '长期有效' : item.otherNotes }}
									</div>
									<p class="address" v-if="item.offlineAddress">{{ item.offlineAddress }}</p>
								</div>
								<span class="navTime" v-if="item.productType == 4">{{ item.examNumberNotes }}</span>
								<span class="navTime" v-if="item.productType == 5">{{ item.otherNotes }}</span>
								<span class="navTime" v-if="item.productType == 6">{{ item.courseDeferralDaysNotes }}
									<br />
									{{ item.examNumberNotes }}</span>
								<span class="navTime" v-if="item.productType == 7">{{ item.examNumberNotes }} <br />
									{{ item.courseDeferralDaysNotes }}</span>
								<!-- <span class="reimburse" v-if="item.productType == 2 || item.productType == 3">{{item.productType == 2 || item.productType == 3?'支持七天无理由退货':'不支持退款及转让'}}</span> -->
								<span class="price" style="color: #999999;"
									v-if="item.payPrice != item.productPrice && item.productPrice && item.productType != 2 && item.productType != 3">¥
									{{ item.productPrice.toFixed(2) }}</span>
							</div>
							<div>
								<span class="num"
									v-if="item.productType == 2 || item.productType == 3 || item.productCount > 1">x{{ item.productCount }}</span>
							</div>
							<div v-if="item.productType != 2 && item.productType != 3">
								<!-- <span  class="reimburse">{{item.productType == 2 || item.productType == 3?'支持七天无理由退货':'不支持退款及转让'}}</span> -->
							</div>
							<div class="refund-tag" v-show="item.refundStatus">
								<span>{{ item.refundStatus == 1 ? '待处理' : item.refundStatus == 2 ? '部分退款' :
									item.refundStatus ==
										3 ? '全部退款' : '' }}</span>
							</div>
						</div>
					</div>
					<div class="refundButton">
						<!-- <span><router-link to="/refund/changeType">申请退款</router-link></span> -->
						<span v-show="item.refundStatus" @click.stop="onPopupOpen(item)">退款记录</span>
						<span
							v-if="(item.productType == 2 || item.productType == 3) && orderDetail.status != 6 && orderDetail.status != 1 && item.refundStatus == 0"
							@click.stop="$Toast('如需退款，请联系客服')">申请退款</span>
					</div>
				</div>
				<!-- <div class="shopItem">
					<div class="commodity">
						<img src="../../assets/image/datas/2022012811491.png" />
						<div class="rightMess">
							<div class="titlePrice">
								<span class="title all-font-ellipsis">孤独症康复教育上岗培孤独症康复教育上岗培训</span>
								<span class="price">¥ 64.00</span>
							</div>
							<div class="navNum">
								<span></span>
								<span class="num">x1</span>
							</div>
						</div>
					</div>
					<div class="refundButton">
						<span><router-link to="/refund/changeType">申请退款</router-link></span>
					</div>
				</div> -->
			</div>
		</div>
		<!-- 赠品 -->
		<div class="shopMess" style="display: flex;"
			v-if="orderDetail && orderDetail.productList && orderDetail.productList.filter(item => { return item.type == 2 }).length">
			<div class="left">
				附赠
			</div>
			<div class="right" style="align-items:center;">
				<div @click="goDetail(item)" class="shoplist style"
					v-for="(item, index) in orderDetail.productList.filter(item => { return item.type == 2 })" :key="index">
					<div class="span">{{ item.productName }}</div>
					<van-icon name="arrow" size="10" color="#333" />
				</div>
			</div>
		</div>

		<div class="shopMess" v-if="orderDetail">
			<div class="priceterm" v-if="orderDetail.orderPrice">
				<span class="name">商品总价：</span>
				<span class="value">¥{{ orderDetail.orderPrice.toFixed(2) }}</span>
			</div>
			<div class="priceterm" v-if="getStatus(orderDetail.productList)">
				<span class="name">运费：</span>
				<span class="value">¥{{ orderDetail.freightPrice ? orderDetail.freightPrice.toFixed(2) : '0.0' }}</span>
			</div>
			<div class="priceterm" v-if="couponP">
				<span class="name">优惠金额：</span>
				<span class="importantvalue">- ¥{{ couponP.toFixed(2) }}</span>
			</div>
			<div class="priceterm" v-if="orderDetail.payPrice">
				<span class="allname">实付款：</span>
				<span class="allvalue">¥{{ orderDetail.payPrice.toFixed(2) }}</span>
			</div>
		</div>
		<div class="detailedtitle">
			<span class="colorDiv"></span>
			<span class="title">订单信息</span>
		</div>
		<div class="orderMessage">
			<!-- 订单信息 -->
			<div class="messageItem">
				<span class="title">订单编号：</span>
				<span class="value">{{ orderDetail.code }} | <span class="copy-style"
						@click="copyOrderCode(orderDetail.code)">复制</span></span>
			</div>
			<div class="messageItem">
				<span class="title">创建时间：</span>
				<span class="value">{{ orderDetail.createTime }}</span>
			</div>
			<div class="messageItem">
				<span class="title">订单类型：</span>
				<span class="value">{{ orderDetail.orderTypeTxt }}</span>
			</div>
			<div class="messageItem" v-if="orderDetail.status != 6 && orderDetail.status != 1">
				<span class="title">支付方式：</span>
				<span class="value">{{ orderDetail.payMethodTxt }}</span>
			</div>
			<div class="messageItem" v-if="orderDetail.status != 6 && orderDetail.status != 1">
				<span class="title">支付时间：</span>
				<span class="value">{{ orderDetail.paymentTime }}</span>
			</div>
			<div class="messageItem"
				v-if="orderDetail.payMethod != 4 && orderDetail.payMethodTxt != '对公转账' && orderDetail.status != 6 && orderDetail.status != 1">
				<span class="title">支付交易号：</span>
				<span class="value">{{ orderDetail.tradeId }}</span>
			</div>
			<!-- <div class="messageItem">
				<span class="title">到期时间：</span>
				<span class="value">2022-01-27  11:32:23</span>
			</div> -->
			<div class="messageItem"
				v-if="orderDetail.status != 1 && orderDetail.status != 2 && orderDetail.shipmentTime">
				<span class="title">发货时间：</span>
				<span class="value">{{ orderDetail.shipmentTime }}</span>
			</div>
			<div class="messageItem"
				v-if="(orderDetail.status == 5 || orderDetail.status == 6) && orderDetail.completeTime">
				<span class="title">成交时间：</span>
				<span class="value">{{ orderDetail.completeTime }}</span>
			</div>
			<div class="messageItem" v-if="(orderDetail.status == 5 || orderDetail.status == 6) && orderDetail.address">
				<span class="title">收货地址：</span>
				<span
					class="value">{{ orderDetail.address.name }}，{{ orderDetail.address.phone }}，{{ orderDetail.address.provinceName + '
					'+orderDetail.address.cityName+'
					'+(orderDetail.address.districtName?orderDetail.address.districtName+'
					':'')+orderDetail.address.address}}</span>
			</div>
			<div class="messageItem" v-if="orderDetail.status == 6">
				<span class="title">取消原因：</span>
				<span class="value">{{ orderDetail.closeReason }}</span>
			</div>
		</div>
		<div style="matgin-top:230px;"></div>
		<div class="footer"
			v-if="(orderDetail.status == 1 || orderDetail.status == 2 || orderDetail.status == 3 || orderDetail.status == 4 || orderDetail.status == 5)">
			<div class="leftBtns">
				<!-- <span class="but" v-if="orderDetail.status == 1" @click="popUp()">取消订单</span> -->
				<div class="price-box" v-if="orderDetail.status == 1">
					<div class="total">
						<span><i>￥</i>{{ orderDetail.payPrice ? orderDetail.payPrice.toFixed(2) : '0.0' }}</span>
					</div>
					<!-- 优惠 -->
					<div class="discount" v-if="couponP">
						<span class="p1">优惠减:￥<i>{{ couponP.toFixed(2) }}</i>元</span>
						<span class="p2" @click="couponpopUp">优惠明细</span>
					</div>
				</div>
				<span
					v-if="orderDetail.canInvoice && orderDetail.status != 1 && orderDetail.status != 4 && orderDetail.invoiceBtnStatus == 1"
					class="but" @click="submit">申请发票</span>
				<span
					v-if="orderDetail.canInvoice && orderDetail.status != 1 && orderDetail.status != 4 && orderDetail.invoiceBtnStatus == 2"
					class="but" @click="$router.push('/invoice/list?orderId=' + orderDetail.orderId)">发票明细</span>
				<span
					v-if="orderDetail.canInvoice && orderDetail.status != 1 && orderDetail.status != 4 && orderDetail.invoiceBtnStatus == 3"
					class="but" @click="toDetail(orderDetail)">查看发票</span>
				<router-link
					:to="orderDetail.parcelNumber == 1 ? '/order/logistics?orderId=' + orderDetail.orderId : '/order/parcelList?orderId=' + orderDetail.orderId"
					class="but"
					v-if="(orderDetail.status == 3 || orderDetail.status == 4 || orderDetail.status == 5) && getStatus(orderDetail.productList)">查看物流</router-link>
				<!-- <router-link to="/address/adjust" class="but" v-if="(orderDetail.status == 1 || orderDetail.status == 2) && getStatus(orderDetail.productList)">修改地址</router-link> -->
			</div>
			<div class="rightBtns">
				<span class="goPay" v-if="orderDetail.status == 3 || orderDetail.status == 4"
					@click="showReceiving()">确认收货</span>
				<span @click="onGoPay()" class="goPay"
					v-if="orderDetail.status == 1 && orderDetail.payMethod != 3">去支付</span>
				<span v-if="orderDetail.payMethod == 3 && orderDetail.status == 1 && orderDetail.transferStatus == 0"
					class="goPay crux ordinary all_span" @click="warningModel = true">支付审核中</span>
				<!-- <router-link :to="'/order/evaluate?orderId=' + orderId" class="goPay" v-if="orderDetail.showBtnComment && orderDetail.status == 5 && getpjStatus(orderDetail.productList)">评价</router-link> -->
			</div>
		</div>

		<!-- 提示信息 -->
		<van-popup v-model="warningModel">
			<div class="box_popup">
				<div class="popup_title">
					<van-icon name="info-o" color="#EB3C31" size="25" />
					<span class="title_con">提示信息</span>
				</div>
				<div class="popup_con">您已提交对公转账信息，工作人员确认收款后，会在1个工作日内联系您。</div>
				<div class="popup_button">
					<span class="all-btn-middle btn2" @click="closeModel()">我知道了</span>
				</div>
			</div>
		</van-popup>

		<!-- 退款记录 -->
		<van-popup v-model="refundModel">
			<div class="windowCoupons">
				<van-icon class="close-icon" name="close" @click="onPopupClose" color="#666666" size="24" />
				<h4 class="title">退款记录</h4>
				<div class="detail-panel" :class="{ 'detail-more': refundList.length > 1 }"
					v-for="(item, index) in refundList" :key="index">
					<div class="item-panel">
						<span class="name">退款金额：</span>
						<span class="content">￥{{ item.refundPrice ? item.refundPrice.toFixed(2) : '0.0' }}</span>
					</div>
					<div class="item-panel">
						<span class="name">商品数量：</span>
						<span class="content">{{ item.refundCount }}</span>
					</div>
					<div class="item-panel">
						<span class="name">退款类型：</span>
						<span class="content">{{ item.refundsOnly ? '仅退款' : '退款退商品' }}</span>
					</div>
					<div class="item-panel">
						<span class="name">退款进度：</span>
						<span class="content" :class="{ 'success': item.refundStatus == 2 }">{{ item.refundStatus == 1 ?
							'退款中' :
							item.refundStatus == 2 ? '已退款' : ''}}</span>
					</div>
					<div class="item-panel" v-show="item.refundStatus == 2">
						<span class="name">退款时间：</span>
						<span class="content">{{ item.refundTime }}</span>
					</div>
				</div>
			</div>
		</van-popup>

		<!-- 确认收货 -->
		<n-transition ref="pop_receiving" speed="ease-in-out" :height="283" :maskVal="0.35">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">确认收到货了吗</span>
						<van-icon name="cross" size="20" color="#999" @click="closeReceiving" />
					</div>
					<div class="popBox_con">
						<div class="con_main">
							<img class="main_img" :src="confirmImg" />
							<div class="main_info">共{{ confirmCount }}件</div>
						</div>
						<div class="con_info">请收到商品确认无误后再确认收货</div>
					</div>
				</div>
				<div class="popbutton">
					<span class="btn all-btn-bigCircle" @click="confirmreceive">确 认</span>
				</div>
			</div>
		</n-transition>
		<!-- 取消订单 -->
		<n-transition ref="pop" speed="ease-in-out" :height="401" :maskVal="0.35">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">取消订单</span>
						<van-icon name="cross" size="20" color="#999" @click="close" />
					</div>
					<div class="popContent">
						<div class="question">
							<span class="mess">请选择取消订单的原因</span>
							<span class="warning">取消后将无法恢复，优惠券可退回，有效期内可使用</span>
						</div>
						<div class="option" @click="reason = '我不想要了'">
							<span>我不想要了</span>
							<img v-if="reason == '我不想要了'" src="@/assets/image/2022021110551.png" />
							<img v-if="reason != '我不想要了'" src="@/assets/image/2022021110552.png" />
						</div>
						<div class="option" @click="reason = '价格有点贵'">
							<span>价格有点贵</span>
							<img v-if="reason == '价格有点贵'" src="@/assets/image/2022021110551.png" />
							<img v-if="reason != '价格有点贵'" src="@/assets/image/2022021110552.png" />
						</div>
						<div class="option" @click="reason = '暂时不需要了'">
							<span>暂时不需要了</span>
							<img v-if="reason == '暂时不需要了'" src="@/assets/image/2022021110551.png" />
							<img v-if="reason != '暂时不需要了'" src="@/assets/image/2022021110552.png" />
						</div>
						<div class="option" @click="reason = '其他'">
							<span>其他</span>
							<img v-if="reason == '其他'" src="@/assets/image/2022021110551.png" />
							<img v-if="reason != '其他'" src="@/assets/image/2022021110552.png" />
						</div>
					</div>
				</div>
				<div class="popbutton">
					<span class="btn all-btn-bigCircle" @click="cancelOrder">确认取消</span>
				</div>
			</div>
		</n-transition>
		<!-- 优惠明细 -->
		<n-transition ref="couponpop" speed="ease-in-out" :height="289" :maskVal="0.35" v-if="orderDetail">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">优惠明细</span>
						<van-icon name="cross" size="20" color="#999" @click="couponclose" />
					</div>
					<div class="popContent">
						<div class="option" v-if="orderDetail.orderPrice">
							<span class="title">商品总价：</span>
							<span class="value">¥{{ orderDetail.orderPrice.toFixed(2) }}</span>
						</div>
						<div class="option" v-if="getStatus(orderDetail.productList)">
							<span class="title">运费：</span>
							<span
								class="value">{{ orderDetail.freightPrice ? '￥' + orderDetail.freightPrice.toFixed(2) : '包邮' }}</span>
						</div>
						<div class="option" v-if="couponP">
							<span class="title">优惠金额：</span>
							<span class="discount">- ¥{{ couponP.toFixed(2) }}</span>
						</div>
						<div class="option" v-if="orderDetail.payPrice">
							<span class="fruit">实付款：</span>
							<span class="fruitprice">¥{{ orderDetail.payPrice.toFixed(2) }}</span>
						</div>
					</div>
				</div>
			</div>
		</n-transition>
	</div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
import { CouponReceive, jumpToShopDetail } from "@/assets/js/NativeWebKit.js"

export default {
	// 可用组件的哈希表
	components: { nTransition },
	data() {
		return {
			warningModel: false,
			refundModel: false,
			reason: '我不想要了',
			orderId: null,
			orderDetail: null,
			time: null,
			confirmCount: 0,
			confirmImg: '',
			stateHeight: 0, //信息栏高度
			refundList: [],
		}
	},
	watch: {
		time(newVal, oldVal) {
			if (this.orderDetail.status == 1 && this.orderDetail.payMethod != 3) {
				if (newVal > 0) {
					setTimeout(() => {
						this.time = this.time - 1;
					}, 1000)
				} else {
					// this.getOrderDetail(); // 取消刷新详情  支付之前校验
				}
			}
		}
	},
	computed: {
		couponP() {
			return (this.orderDetail.orderPrice * 100 + this.orderDetail.freightPrice * 100 - this.orderDetail.payPrice * 100) / 100
		}
	},
	created() {
		this.orderId = this.$route.query.id;
		this.getOrderDetail();
		// 获取信息条高度
		this.stateHeight = this.$store.state.stateHeight;

	},
	methods: {
		// 申请发票
		submit() {
			return this.$Toast("请联系工作人员线下开具发票，联系电话：400-800-9002转3。");
			this.$router.push('/invoice/apply?orderId=' + this.orderDetail.orderId)
		},
		// 跳转详情页面
		goDetail(e) {
			// 教育课程
			if (e.productType == 1) {
				jumpToShopDetail(e.productType, e.dataId)
				// this.$router.push('/curriculum/course?id=' + e.dataId);
			}
			// // 图书教具
			else if (e.productType == 2 || e.productType == 3) {
				jumpToShopDetail(e.productType, e.dataId)
				// this.$router.push('/curriculum/BookDdetails?id=' + e.dataId)
			}
			// // 模拟考试
			else if (e.productType == 4) {
				jumpToShopDetail(e.productType, e.productId, 'cart')
				// this.$router.push('/examination/examDetail?productId=' + e.productId)
			}
		},
		// 去支付
		async onGoPay() {
			// 电子照片
			if (this.orderDetail.orderType == 2) {
				// 补考-通过id查询用户报名id
				await this.getPhotoSignUpUserId();
			}

			// 补考类型
			if (this.orderDetail.orderType == 3) {
				// 补考-通过id查询用户报名id
				await this.getMaskSignUpUserId();
			}

			this.$router.push({
				path: "/order/orderPayAll",
				query: {
					orderId: this.orderDetail.orderId,
					payPrice: this.orderDetail.payPrice,
					orderType: this.orderDetail.orderType
				}
			});
		},
		// 电子照-通过id查询用户报名id
		async getPhotoSignUpUserId() {
			await this.$ajax({
				url: "/hxclass-mobile/exam/signUp/getExamSignUpInfoByOrderId",
				method: "get",
				params: {
					orderId: this.orderDetail.orderId
				}
			}).then((res) => {
				if (res.code == 200 && res.success) {
					// 修改用户报名id
					if (sessionStorage.getItem('paySignupObj')) {
						let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
						paySignupObj.signUpId = res.data.signUpId;
						paySignupObj.signupUserId = res.data.signUpUserId;
						paySignupObj.examineeId = res.data.examineeId;
						sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
					} else {
						let paySignupObj = {
							id: null,
							invoice: false,
							pay: false,
							signUpId: res.data.signUpId,
							signupUserId: res.data.signUpUserId,
							examineeId: res.data.examineeId,
						};
						sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj));
					}
				} else {
					this.$alert(res.message);
				}
			});
		},
		// 补考-通过id查询用户报名id
		async getMaskSignUpUserId() {
			await this.$ajax({
				url: "/hxclass-mobile/exam/signUp/getSignUpUserId",
				method: "get",
				params: {
					orderId: this.orderDetail.orderId
				}
			}).then((res) => {
				if (res.code == 200 && res.success) {
					// 修改用户报名id
					if (sessionStorage.getItem('payMakeObj')) {
						let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
						payMakeObj.signupUserId = res.data.signUpUserId;
						payMakeObj.configId = res.data.configId;
						sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
					} else {
						let payMakeObj = {
							configId: res.data.configId,
							id: null,
							invoice: false,
							pay: false,
							signupUserId: res.data.signUpUserId
						};
						sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
					}
				} else {
					this.$alert(res.message);
				}
			});
		},
		// 发票详情
		toDetail(e) {
			if (e.invoiceStatus == 1) {
				this.$Dialog.alert({
					title: "提示信息",
					message: "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。",
				});
			} else {
				this.$router.push(
					"/invoice/details?invoiceRecordId=" + e.invoiceRecordId
				);
			}
		},
		// 优惠明细弹框
		couponpopUp() {
			this.$refs["couponpop"].show();
		},
		// 关闭优惠明细弹框
		couponclose() {
			this.$refs["couponpop"].hide();
		},
		onPopupOpen(e) {
			// 获取退款记录
			this.$ajax({
				url: '/hxclass-mobile/order/refund/app/get',
				params: {
					orderProductId: e.id,
				}
			}).then(res => {
				if (res.code == 200 && res.success) {
					this.refundList = res.data
					this.refundModel = true
				}
			})
		},
		onPopupClose() {
			this.refundModel = false
		},
		// 取消订单
		cancelOrder() {
			if (!this.reason) {
				return this.$Toast('请选择取消原因');
			}
			this.$ajax({
				url: '/hxclass-mobile/order/cancel',
				method: "put",
				params: {
					orderId: this.orderId, // 订单id
					closeReason: this.reason // 用户取消交易关闭原因：我不想要了，价格有点贵，暂时不需要了，其它
				}
			}).then(res => {
				if (res.code == 200 && res.success) {
					// 通知原生刷新优惠券数量
					CouponReceive()

					this.close();
					this.getOrderDetail();
				}
			})
		},
		// 确认收货
		confirmreceive() {
			this.$ajax({
				url: '/hxclass-mobile/order/confirm-receive',
				method: 'put',
				params: {
					orderId: this.orderId
				}
			}).then(res => {
				if (res.code == 200 && res.success) {
					this.closeReceiving();
					this.getOrderDetail();
				}
			})
		},
		// 关闭弹框
		closeModel() {
			this.warningModel = false;
		},
		// 订单是否包含实物
		getStatus(e) {
			for (let i = 0; i < e.length; i++) {
				if (e[i].productType == 2 || e[i].productType == 3) {
					return true;
				}
			}
		},
		// 评价按钮是否展示  商品id 1 2 3 4
		getpjStatus(e) {
			for (let i = 0; i < e.length; i++) {
				if (e[i].productType == 1 || e[i].productType == 2 || e[i].productType == 3 || e[i].productType == 4) {
					return true;
				}
			}
		},
		getOrderDetail() {
			this.$ajax({
				url: '/hxclass-mobile/order/' + this.orderId,
				method: 'get'
			}).then(res => {
				if (res.code == 200 && res.success) {
					if (res.data.kuaidiInfo) {
						res.data.kuaidiInfo = JSON.parse(res.data.kuaidiInfo)
					}
					this.orderDetail = res.data;
					let str = res.data.createTime.split('-').join('/');
					this.time = 1000 * 60 * 60 * 24 * 7 - (new Date().getTime() - new Date(str).getTime())
				} else {
					this.$Toast(res.message);
				}
			})
		},
		// 取消订单弹框
		popUp() {
			this.$refs['pop'].show()
		},
		close() {
			this.reason = '我不想要了';
			this.handleItem = null;
			this.$refs['pop'].hide()
		},
		// 返回
		back() {
			this.$onReturn(this.$route.query.fromMenu == '1')
		},
		copyOrderCode(e) {
			var input = document.createElement("input");   // 直接构建input
			input.value = e;  // 设置内容
			document.body.appendChild(input);    // 添加临时实例
			input.select();   // 选择实例内容
			document.execCommand("Copy");   // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$Toast('复制成功');
		},
		// 确认收货弹框
		showReceiving() {
			this.confirmCount = 0;
			this.confirmImg = '';
			this.orderDetail.productList.map(item => {
				if (item.productType != 1 && item.productType != 4) {
					this.confirmCount += item.productCount;
					if (!this.confirmImg) {
						this.confirmImg = item.productPicture;
					}
				}
			})
			this.$refs['pop_receiving'].show()
		},
		// 关闭确认收货弹框
		closeReceiving() {
			this.$refs['pop_receiving'].hide()
		}
	}
}
</script>

<style scoped lang="less">
.orderDetail {
	background: #F7F7F9;
	padding: 56px 18px 100px;
	box-sizing: border-box;

	// min-height: 100vh;
	.popContentBox {
		.popBox {
			.titleBox {
				padding: 28px 34px 0 34px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				line-height: 25px;
				display: flex;

				span {
					display: inline-block;
				}

				.title {
					flex: 1;
				}
			}

			.popContent {
				clear: both;
				padding: 16px 18px;

				.option {
					display: flex;
					justify-content: space-between;
					margin-top: 28px;
					align-items: center;

					span {
						font-size: 16px;
						color: #333333;
						line-height: 22px;
					}

					img {
						width: 20px;
						height: 20px;
					}

					.title {
						font-size: 14px;
						color: #333333;
						line-height: 22px;
					}

					.value {
						font-size: 12px;
						font-family: PingFangMedium;
						font-weight: bold;
						color: #666666;
						line-height: 22px;
					}

					.discount {
						font-size: 12px;
						font-family: PingFangMedium;
						font-weight: bold;
						color: #EC6C01;
						line-height: 22px;
					}

					.fruit {
						font-size: 14px;
						font-weight: 600;
						color: #333333;
						line-height: 22px;
					}

					.fruitprice {
						font-size: 14px;
						font-family: PingFangMedium;
						font-weight: bold;
						color: #333333;
						line-height: 22px;
					}
				}

				.question {
					span {
						display: block;
					}

					.mess {
						font-size: 16px;
						font-weight: bold;
						color: #666666;
						line-height: 22px;
					}

					.warning {
						margin-top: 3px;
						font-size: 12px;
						color: #EC6C01;
						line-height: 17px;
					}
				}
			}

			.popBox_con {
				text-align: center;

				.con_main {
					position: relative;
					width: 125px;
					margin-top: 24px;
					margin-left: auto;
					margin-right: auto;

					.main_img {
						width: 125px;
						height: 88px;
						border-radius: 8.5px;
					}

					.main_info {
						position: absolute;
						bottom: 1px;
						color: #fff;
						width: 125px;
						height: 24px;
						line-height: 24px;
						background: rgba(0, 0, 0, .34);
						border-radius: 0 0 9px 9px;
					}
				}

				.con_info {
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					margin-top: 24px;
				}
			}
		}

		.popbutton {
			padding: 5px 0;
			position: fixed;
			width: 100%;
			bottom: 0;

			.btn {
				width: 295px;
				height: 40px;
				display: block;
				text-align: center;
				margin: 0 auto;
				line-height: 40px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
	}

	.windowCoupons {
		width: calc(100vw - 70px);
		background-color: #FFFFFF;
		border-radius: 10px;
		padding: 20px 16px;
		position: relative;

		.close-icon {
			position: absolute;
			right: 16px;
		}

		.title {
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: #333333;
			line-height: 21px;
			text-align: center;
		}

		.detail-panel {
			margin-top: 8px;

			.item-panel {
				display: flex;
				align-items: center;
				padding: 8px 0;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;

				.name {
					color: #333333;
					width: 80px;
					display: inline-block;
				}

				.content {
					color: #666666;
				}

				.success {
					color: #34C632;
				}
			}
		}

		.detail-more {
			margin-top: 19px;
			padding: 0 10px;
			border-radius: 5px;
			background-color: #F7F7F9;
			margin-left: 18px;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: -23px;
				display: inline-block;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				z-index: 2;
				border: 3px solid #15B7DD;
			}

			&::after {
				content: '';
				position: absolute;
				display: inline-block;
				width: 4px;
				left: -16px;
				z-index: 1;
				top: 14px;
				height: calc(100% + 5px);
				background-color: #F6F7FB;
			}
		}

		.detail-more:last-child {
			&::after {
				display: none;
			}
		}
	}

	.footer {
		width: 100%;
		height: 50px;
		left: 0;
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 9px 18px;
		box-sizing: border-box;

		.leftBtns {
			display: flex;
			flex: 1;
			justify-content: left;

			.but {
				padding: 7.5px 12px;
				border-radius: 16px;
				border: 1px solid #EEEEEE;
				font-size: 12px;
				color: #333333;
				line-height: 17px;
				margin-right: 20px;
			}

			.but:last-child {
				margin-right: 0;
			}

			/*  合计 */
			.price-box {
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 5px 0;
				margin-left: 18px;
				font-family: PingFangMedium;

				.total {
					width: 100%;
					font-size: 18px;
					font-family: PingFangMedium;
					font-weight: bold;
					color: #333333;
					line-height: 21px;

					span {
						color: #EC6C01;
						font-family: PingFangMedium;
					}

					i {
						font-size: 14px;
						font-style: normal;
					}
				}

				.discount {
					font-size: 8px;
					font-weight: 400;
					color: #333333;
					line-height: 15px;

					i {
						font-style: normal;
						font-family: PingFangMedium;
					}

					.p1 {
						color: #666666;
						margin-right: 8px;
					}

					.p2 {
						display: inline-block;
						text-align: center;
						height: 13px;
						line-height: 13px;
						font-size: 8px;
						color: #000000;
						background: #D8D8D8;
						border-radius: 1px 1px 1px 1px;
					}
				}
			}
		}

		.rightBtns {
			// width: 186px;
			display: flex;
			justify-content: flex-end;

			.goPay {
				padding: 7.5px 18px;
				border-radius: 16px;
				border: 1px solid #15B7DD;
				font-size: 12px;
				font-weight: bold;
				color: #15B7DD;
				line-height: 17px;
			}
		}
	}

	.detailedtitle {
		height: 24.5px;
		display: flex;
		align-items: center;
		margin-top: 16px;
		margin-bottom: 12px;

		.colorDiv {
			width: 3px;
			height: 13px;
			background-color: rgba(21, 183, 221, 1);
			margin-right: 3.5px;
			border-radius: 4px;
		}

		.title {
			font-weight: bold;
			font-size: 18px;
			line-height: 28px;
			color: #333333;
		}
	}

	.orderMessage {
		background-color: #ffffff;
		padding: 16px 12px;
		border-radius: 8px;

		.messageItem {
			display: flex;
			justify-content: space-between;
			margin-bottom: 16px;

			&:last-child {
				margin: 0;
			}

			.title {
				min-width: 70px;
				font-size: 14px;
				color: #333333;
				line-height: 22px;
			}

			.value {
				flex: 1;
				text-align: right;
				font-size: 14px;
				color: #666666;
				line-height: 22px;

				span {
					color: #333333;
					display: inline-block;
					margin-left: 5px;
				}

				.copy-style {
					display: inline-block;
					color: #333333;
					font-size: 12px;
					line-height: 21px;
					padding: 0 9px;
					background-color: #F0F0F0;
					border-radius: 11px;
				}
			}
		}
	}

	.shopMess {
		background-color: #ffffff;
		padding: 16px 12px;
		border-radius: 8px;
		margin-top: 8px;

		.left {
			font-size: 14px;
			font-weight: 500;
			color: #333333;
			line-height: 22px;
			width: 68px;
			text-align: left;
		}

		.right {
			flex: 4;
			margin-left: 12px;
			font-size: 13px;
			font-weight: 400;
			color: #333333;
			line-height: 22px;

			.shoplist {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1;
			}

			.span {
				display: inline-block;
				flex: 1;
			}

			.copy {
				font-size: 13px;
				font-weight: 400;
				color: #15B7DD;
				line-height: 22px;
			}

			.style {
				padding: 16px 0;
				border-bottom: 1px solid #F5F5F5;
			}

			.style:first-child {
				padding-top: 0;
			}

			.style:last-child {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}

		.warning {
			font-size: 12px;
			font-weight: bold;
			color: #333333;
			line-height: 17px;
			display: block;
			margin-bottom: 16px;
		}

		.priceterm {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 17px;

			&:last-child {
				margin: 0;
			}

			.name {
				font-size: 14px;
				color: #333333;
				line-height: 22px;
			}

			.value {
				font-size: 12px;
				font-family: PingFangMedium;
				color: #666666;
				line-height: 22px;
			}

			.importantvalue {
				font-size: 12px;
				font-family: PingFangMedium;
				color: #EC6C01;
				line-height: 22px;
			}

			.allname {
				font-size: 14px;
				font-weight: 600;
				color: #333333;
				line-height: 22px;
			}

			.allvalue {
				font-size: 14px;
				font-family: PingFangMedium;
				color: #333333;
				line-height: 22px;
			}
		}

		.shopItem {
			border-bottom: 1px solid #EEEEEE;
			padding-bottom: 12px;
			margin-bottom: 11px;

			&:last-child {
				border: 0;
				padding: 0;
				margin-bottom: 0;
			}

			.refund-tag {
				text-align: right;
				font-size: 12px;
				color: #15B7DD;
			}

			.num {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				line-height: 17px;
				margin-left: 4px;
			}

			.refundButton {
				margin-top: 11px;
				display: flex;
				justify-content: flex-end;

				span {
					padding: 6px 12px;
					border-radius: 16px;
					border: 1px solid #EEEEEE;
					font-size: 12px;
					color: #333333;
					line-height: 17px;
					margin-left: 10px;
				}
			}

			.packedList {
				margin-top: 17px;
				padding: 0 12px 15px;
				background-color: #ffffff;
				border-radius: 8px;
				border: 1px solid #15B7DD;

				.haveStyle {
					width: 100px;
					text-align: center;
					margin: 0 auto;
					margin-bottom: 12px;
					margin-top: -1px;
					font-size: 12px;
					font-family: PingFangMedium;
					font-weight: bold;
					color: #15B7DD;
					line-height: 24px;
					background: #ffffff;
					background-image: url(~@/assets/image/order/packHave.png);
					background-size: 100% 98%;
				}

				.shopItem {
					padding-bottom: 16px;
					border-bottom: 1px solid rgba(238, 238, 238, 1);
					display: flex;
					margin-bottom: 16px;

					&:last-child {
						border: 0;
						margin-bottom: 0;
						padding-bottom: 0;
					}

					.shopImg {
						flex-shrink: 0;
						width: 100px;
						height: 70px;
						overflow: hidden;
						border-radius: 8.5px;
					}

					.shopMessage {
						position: relative;
						flex: 1;
						padding: 11px 0 4px 10px;
						box-sizing: border-box;

						.shoptielt-price {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.shopTitle {
								flex: 1;
								font-size: 14px;
								font-weight: 400;
								padding-right: 10px;
								color: #333333;
								line-height: 22px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								overflow: hidden;
							}

							.shopprice {
								font-size: 10px;
								font-family: PingFangMedium;
								font-weight: bold;
								color: #333333;
								line-height: 22px;
							}

							.isPay {
								font-size: 10px;
								font-family: PingFangMedium;
								color: #999999;
								line-height: 12px;
								padding: 0 4px;
								border-radius: 10px;
								border: 1px solid #999999;
							}

							// .havNav{
							// 	-webkit-line-clamp: 1;
							// }
						}

						.stage_box {
							display: flex;
							justify-content: space-between;
							align-items: center;
							// width: 152px;
							margin-top: 5px;

							.stage {
								display: inline-block;
								font-size: 10px;
								font-weight: 400;
								color: #666666;
								line-height: 14px;
								box-sizing: border-box;
								white-space: nowrap;

								.stage_info {
									margin-top: 4px;
									white-space: normal;
									line-height: 16px;
								}

								.openTime {
									font-size: 10px;
									color: #999999;
									line-height: 12px;
								}
							}

							.num {
								font-size: 12px;
								font-weight: 400;
								color: #999999;
								line-height: 17px;
								margin-left: 4px;
							}
						}

						.btn-tag {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 11px;

							.refundButton {
								margin-top: 0;
							}
						}

						.stage_label {
							display: inline-block;
							border: 1px solid #15B7DD;
							font-size: 8px;
							font-family: PingFang HK-Regular, PingFang HK;
							font-weight: 400;
							color: #15B7DD;
							margin-top: 12px;
							padding: 2px 4px;
						}

						.priceNum {
							position: absolute;
							bottom: 0;
							display: flex;
							width: calc(100% - 10px);
							justify-content: space-between;

							.price {
								font-size: 12px;
								font-family: PingFangMedium;
								font-weight: 500;
								color: #EC6C01;
								line-height: 17px;
							}

							.num {
								font-size: 12px;
								font-weight: 400;
								color: #999999;
								line-height: 17px;
							}
						}
					}

					.haveNav {
						padding: 4px 0 4px 10px;

						.priceNum {
							bottom: 2px;
						}
					}
				}
			}

			.commodity {
				display: flex;

				img {
					width: 100px;
					height: 70px;
					border-radius: 7px;
				}

				.rightMess {
					display: flex;
					justify-content: space-between;
					position: relative;
					flex-direction: column; //纵向变横向
					flex: 1;
					padding-left: 12px;

					.titlePrice {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.title {
							flex: 1;
							-webkit-line-clamp: 1;
							font-size: 12px;
							font-weight: bold;
							color: #333333;
							line-height: 22px;
						}

						.price {
							width: 90px;
							text-align: right;
							font-size: 10px;
							font-family: PingFangMedium;
							color: #333333;
							line-height: 22px;
						}
					}

					.address {
						font-size: 12px;
						color: #999999;
						line-height: 17px;
					}

					.navNum {
						display: flex;
						justify-content: space-between;

						// margin-top: 12px;
						// margin-bottom: 8px;
						// align-items: center;
						div {
							flex: 1;

						}

						.price {
							width: 60px;
							text-align: right;
							font-size: 10px;
							font-family: PingFangMedium;
							color: #333333;
							line-height: 22px;
						}

						.navTime {
							padding-left: 0;
							border-radius: 2px;
							font-size: 10px;
							color: #999999;
							line-height: 14px;
						}

						.num {
							font-size: 12px;
							color: #999999;
							line-height: 17px;
						}
					}

					.reimburse {
						display: inline-block;
						padding: 1px 4px;
						border-radius: 2px;
						border: 1px solid rgba(0, 179, 197, 0.5);
						font-size: 8px;
						color: #15b7dd;
						line-height: 13px;
						font-family: PingFang HK-Regular;
					}
				}
			}
		}
	}

	.logistics {
		background-color: #ffffff;
		padding: 20px 12px 16px;
		border-radius: 8px;
		position: relative;

		.check {
			padding: 4px 9px;
			border-radius: 12.5px;
			border: 1px solid #15B7DD;
			font-size: 12px;
			font-weight: bold;
			color: #15B7DD;
			line-height: 17px;
			position: absolute;
			right: 12px;
			top: 16px;
		}

		.userMessage {
			padding-left: 26px;
			margin-top: 4px;
			display: flex;
			align-items: center;

			span {
				font-size: 12px;
				color: #666666;
				line-height: 17px;
			}

			.name {
				margin-right: 16px;
			}
		}

		.currentAddress {
			// width: 218px;
			margin-left: 6px;
			padding: 4px 19px 12px 19px;
			border-left: 1px solid #15B7DD;

			span {
				font-size: 12px;
				color: #666666;
				line-height: 13px;
			}
		}

		.logisticstatus {
			display: flex;
			align-items: center;

			.sign {
				width: 13px;
				height: 13px;
				background: #15B7DD;
				border-radius: 50%;
				margin-right: 13px;
			}

			.status {
				font-size: 14px;
				font-weight: bold;
				color: #15B7DD;
				line-height: 14px;
				margin-right: 12px;
			}

			.time {
				font-size: 10px;
				color: #15B7DD;
				line-height: 14px;
			}

			.addresssign {
				width: 13px;
				height: 13px;
				border: 1px solid #15B7DD;
				box-sizing: border-box;
				border-radius: 50%;
				margin-right: 13px;
			}

			.address {
				flex: 1;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				line-height: 14px;
			}
		}

		.logisticstatusbot {
			align-items: initial;
		}
	}

	.addressMess {
		background-color: #ffffff;
		padding: 16px 12px;
		border-radius: 8px;

		.addressText {
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			display: block;
			margin-top: 9px;
		}

		.userMess {
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				display: flex;
				align-items: center;
			}

			img {
				width: 14px;
			}

			.userName {
				font-size: 16px;
				font-weight: bold;
				color: #333333;
				line-height: 22px;
				margin-left: 3px;
			}

			.phone {
				font-size: 12px;
				font-family: PingFangMedium;
				color: #333333;
				line-height: 17px;
				margin-left: 8px;
			}

			.changeAddress {
				font-size: 12px;
				font-weight: bold;
				color: #15B7DD;
				line-height: 17px;
				float: right;
				padding: 4px 9px;
				border-radius: 12.5px;
				border: 1px solid #15B7DD;
			}
		}
	}

	.nav {
		background: #15B7DD;
		padding-left: 16px;
		display: flex;
		align-items: center;
		height: 44px;

		img {
			width: 16px;
		}

		.orderType {
			display: flex;
			flex-direction: column;
			margin-left: 13px;

			.typeText {
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 25px;
			}

			.enclosure {
				font-size: 10px;
				color: #FFFFFF;
				line-height: 14px;
			}
		}
	}
}

/deep/.van-popup--center {
	border-radius: 10px;
}

// 提示信息
.box_popup {
	position: relative;
	width: 305px;
	background: #FFFFFF;
	border-radius: 10px;
	padding: 24px 16px;
	z-index: 10;

	.popup_title {
		display: flex;
		align-items: center;

		.title_con {
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
			color: #333333;
			margin-left: 7px;
		}
	}

	.popup_con {
		font-size: 16px;
		font-family: PingFangMedium;
		font-weight: 400;
		color: #333333;
		margin-top: 22.5px;
	}

	.popup_button {
		display: flex;
		justify-content: center;
		margin-top: 32px;

		.all-btn-middle {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
		}

		.btn1 {
			color: #666666;
			background: #EEEEEE;
			box-shadow: none;
		}
	}
}
</style>
