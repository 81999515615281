<template>
  <!-- 套餐搭配 -->
  <div class="container-box">
    <template v-if="listType == '1'">
      <van-swipe-cell 
        :disabled="listType == '2'"
        v-for="(i, index) in listData" 
        :key="index" >
        <div class="setmeal_box" @click="goDetail(i.packageId)">
          <div class="box_wrap" :class="{'setmealFirst':index==0}">
            <div class="box_left">
              <img class="left_img" :src="i.pcMobileListPicture" alt="套餐搭配">
              <div class="left_label">套餐</div>
            </div>
            <div class="box_right">
              <div class="right_title">{{i.packageName}}</div>
              <div class="price_box">
                  ￥<span class="price">{{i.packagePrice?i.packagePrice.toFixed(2):''}}</span>
                  <span class="original">￥{{i.originalPrice.toFixed(2)}}</span>
              </div>
            </div>
          </div>
        </div>
        <template #right>
          <div class="all_cancel" @click="cancelOk(i.packageId, index)">取消收藏</div>
        </template>
      </van-swipe-cell>
    </template>

     <template v-if="listType == '2'">
        <div 
          v-for="(i, index) in listData" 
          :key="index"
          class="classItem" 
          @click="goDetail(i.packageId)">
          <div class="classItemBorder" :class="{'classItemFirst':index==0}">
            <img :src="i.listPhoto" />
            <div class="itemMessage">
              <span v-html="i.courseName" class="title all-font-ellipsis"></span>
              <div class="labels">
                <span class="label">上岗时间</span>
                <span class="label">视频数量：{{i.classHour}}个</span>
              </div>
              <span class="describe">{{i.abstracts}}</span>
              <div class="priceNum">￥
                <span class="price"
                  >{{i.couponPrice}}<span class="original">￥{{i.originalPrice}}</span></span
                >
              </div>
            </div>
          </div>
        </div>
    </template>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 类型 1收藏 2搜索
    listType: {
      type: String,
      default: "",
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    goDetail(id) {
      this.$router.push({ path: "/curriculum/Packagedetails", query: { packageId: id } });
    },
		// 取消收藏
    cancelOk(id, index) {
      this.$emit('cancelOk', id, index)
    },
  },
  // 页面生命周期-页面加载
  onLoad() {},
  // 页面生命周期-页面显示
  onShow() {},
  // 页面生命周期-页面初次渲染完成
  onReady() {},
  // 页面生命周期-页面隐藏
  onHide() {},
  // 页面生命周期-页面卸载
  onUnload() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";
</style>
