import { render, staticRenderFns } from "./swiperMenu.vue?vue&type=template&id=694676ac&scoped=true&"
import script from "./swiperMenu.vue?vue&type=script&lang=js&"
export * from "./swiperMenu.vue?vue&type=script&lang=js&"
import style0 from "./swiperMenu.vue?vue&type=style&index=0&id=694676ac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "694676ac",
  null
  
)

export default component.exports