export default [
  /*
    子模块的路由前面不需要添加‘/’
  */
  // 音频列表
  {
    path: 'audioList',
    component: () => import('../views/propagandaHeadlines/audioList.vue')
  },
  // 音频播放页
  {
    path: 'audioDetails',
    component: () => import('../views/propagandaHeadlines/audioDetails.vue')
  },
  // 音频介绍页
  {
    path: 'audioContent',
    component: () => import('../views/propagandaHeadlines/audioContent.vue')
  }
]
