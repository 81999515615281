<template>
  <div class="topAlert" v-show="showTopFlag">
    <van-icon @click="close" class="icon" name="cross" color="#C5C5C5" size="9px" />
    <img class="img" src="@/assets/image/icon/shareTopLogo.png" alt="">
    <p class="text">下载华夏云课堂App，查阅更多</p>
    <p class="btn" v-if="true" @click="goDownLoad">立即下载</p>
    <template v-else>
      <a class="btn" @click="goDownLoad" v-if="!isWeixn && isAndroid" :href="'hxclass://open?openType=' + openType + '&openId=' + openId">立即打开</a>
      <a class="btn" @click="goDownLoad" v-else-if="!isWeixn && !isAndroid" :href="'hxykt://?openType=' + openType + '&openId=' + openId">立即打开</a>
      <wx-open-launch-app
        v-else-if="isWeixn"
        id="launch-btn"
        appid="wx0eebea505ccffce5" 
        @error="handleErrorFn"
        @launch="handleLaunchFn"
      >
        <div v-is="'script'" type="text/wxtag-template">
          <div v-is="'style'">
            <!-- .btn { width:100%; height:50px; background:#00b8ec; font-size:12px;
            color:#fff; } -->
          </div>
          <button @click="goDownLoad" class="btn">打开APP</button>
        </div>
      </wx-open-launch-app>
    </template>
    <div class="warnModel" @click="warnModel = false" v-if="warnModel">
      <img src="@/assets/image/shareModelWarn.png" alt="">
    </div>
  </div>
</template>

<script>
import { FunJudgDevice } from "@/unit/fun.js";
const DeviceType = FunJudgDevice();
export default {
  props: ['closeFlag'],
  data(){
    return {
      flag: false, // false非可用浏览器 true可用
      isWeixn: false,
      showTopFlag: true,
      warnModel: false, // 浏览器打开提示信息
      isAndroid: undefined,
      openType: '', // 1:课程 2:图书 3:模拟考试 4:套餐详情 5:资讯详情 5:直播详情
      openId: '',
    }
  },
  created() {
    if(this.$route.path == '/curriculum/shareCourse'){
      this.openType = 1;
      this.openId = this.$route.query.id;
    }else if(this.$route.path == '/curriculum/shareBookDdetails'){
      this.openType = 2;
      this.openId = this.$route.query.id;
    }else if(this.$route.path == '/examination/shareExamDetail'){
      this.openType = 3;
      this.openId = this.$route.query.id;
    }else if(this.$route.path == '/curriculum/sharePackagedetails'){
      this.openType = 4;
      this.openId = this.$route.query.packageId;
    }else if(this.$route.path == '/info/shareInformation'){
      this.openType = 5;
      this.openId = this.$route.query.id;
    }else if(this.$route.path == '/live/shareLive'){
      this.openType = 6;
      this.openId = this.$route.query.id;
    }
    this.is_weixn();
    if (DeviceType == "android") {
      this.isAndroid = true;
    } else if (DeviceType == "ios" && window.webkit) {
      this.isAndroid = false;
    }
  },
  methods:{
    // 跳转下载页
    goDownLoad() {
      // setTimeout(function() {
      //   let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden
      //   if (typeof hidden == "undefined" || hidden == false) {
          //应用宝下载地址
          window.location.href = "http://app.download.hxclass.cn/";
      //   }
      // }, 2000);
    },
    
    getuserAgent() {
      const userAgent = navigator.userAgent.toLocaleLowerCase()
      console.log(userAgent)
      if (userAgent.includes('micromessenger')) {
        this.flag = true;
        if (userAgent.includes('miniprogram')) {
          console.log('isWeixinMP') // 微信小程序
        } else {
          console.log('isWeixin') // 微信
        }
      } else if (userAgent.includes('qq')) {
        this.flag = true;
        console.log('isQQ') // QQ
      } else if (userAgent.includes('aliapp')) {
        this.flag = true;
        console.log('isAlipay') // 支付宝
      } else if (userAgent.includes('crios') || userAgent.includes('chrome')) {
        this.flag = true;
        console.log('isChrome') // 谷歌
      } else if (userAgent.includes('safari')) {
        this.flag = true;
        // 或者别的普通浏览器环境 // Safari
        console.log('isSafari')
      } else {
        this.flag = true;
        // 一个自定义的默认值
        console.log('isMobile')
      }
    },
    // 判断是否微信环境
    is_weixn() {
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.isWeixn = true;
      } else {
        this.isWeixn = false
      }
    },
    handleErrorFn(e) {
      console.log('success');
    },
    handleLaunchFn(e) {
      console.log('fail', e.detail);
      // this.$Toast('当前场景不支持');
    },
    // 隐藏元素
    close(){
      this.$emit('closeFlag');
      this.showTopFlag = false;
    },
    openApp(){

    }
  }
}
</script>

<style lang="less" scoped>
  .topAlert{
    width: 100%;
    height: 46px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon{
      padding-left: 11px;
      padding-right: 15px;
    }
    .img{
      width: 28px;
      height: 28px;
    }
    .text{
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
    }
    .btn{
      padding: 0 19px;
      height: 100%;
      background: #15B7DD;
      line-height: 46px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
  .warnModel{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    padding: 20px 5vw 0 15vw;
    img{
      width: 80vw;
    }
  }
</style>