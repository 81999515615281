<template>
	<div class="container-box">
		<div class="header-box">
			<div class="header-pic">
				支付成功
			</div>
			<img class="header-image" src="@/assets/image/study/bgc_delay2.png"/>
		</div>
    <div class="head">
      <h3 class="title">感谢您的购买，我们会尽快为您处理。</h3>
      <h4 class="name">学习流程</h4>
      <img class="" src="@/assets/image/study/20230704.png" alt="">
    </div>
    <div class="main-box">
      <div class="tab">
        <span class="item" :class="{'active' : Index == 0}" @click="Index = 0">
          <img v-if="Index == 0" src="@/assets/image/study/2023070401.png" alt="">
          <img v-else src="@/assets/image/study/2023070403.png" alt="">
        </span>
        <span class="item" :class="{'active' : Index == 1}" @click="Index = 1">
          <img v-if="Index == 1" src="@/assets/image/study/2023070404.png" alt="">
          <img v-else src="@/assets/image/study/2023070402.png" alt="">
        </span>
      </div>
      <img class="flow" v-show="Index == 0" src="@/assets/image/study/2023070405.png" alt="">
      <img class="flow" v-show="Index == 1" src="@/assets/image/study/2023070406.png" alt="">
    </div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				Index: 0
			}
		},
		
  }
</script>

<style lang="less" scoped>
	* {box-sizing: border-box;}
	.container-box {
		.header-box {
			position: relative;
			width: 100%;
			height: 262px;
			background: url(~@/assets/image/study/bgc_delay1.png) no-repeat;
			background-size: 100%;
			overflow: hidden;

			.header-pic {
				position: relative;
				margin: 86.5px auto 0;
				width: 96.5px;
				height: 96.5px;
				background: url(~@/assets/image/study/pic_delay.png) no-repeat;
				background-size: 100%;
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				line-height: 121.5px;
			}

			.header-image {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 39px;
			}
		}
    .head{
      .title{
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin: 28px auto 20px;
      }
      .name{
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 23px;
        text-align: center;
      }
      img{
        display: block;
        margin: 0 auto;
      }
    }
    
    .main-box{
      position: relative;
      padding: 34px 10px;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      margin: 20px 10px;
      margin-top: 62px;
      .tab{
        position: absolute;
        top: -42px;
        left: 0;
        height: 42px;
        width: 100%;
        z-index: 0;
        display: flex;
        .item{
          width: 50%;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          background: #F0F0F0;
          border-radius: 10px 10px 0px 0px;
          img{
            width: 70px;
          }
        }
        .active{
          background: #FFFFFF;
        }
      }
      .flow{
        width: 100%;
      }
    }
	}
</style>
