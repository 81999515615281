<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="确认信息"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- 步骤 -->
    <div class="step-box">
      <div class="step-item step-hover" >
        <span class="num">1</span>
        <span class="font">证书信息确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex != 1}">
        <span class="num">2</span>
        <span class="font">照片提交方式确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex == 3}">
        <span class="num">3</span>
        <span class="font">证书邮寄地址确认</span>
      </div>
    </div>
    
    <!-- 主功能区 -->
    <div class="content-box">
      <div class="all-title">收货地址</div>
      <div class="all-bg" v-if="!isAddAddress">
        <span class="default" v-if="defaultAddressId==addressId">默认</span>
        <div class="header">
          <span class="name">{{addressObj.consignee}}</span>
          <span class="tel">{{addressObj.phone}}</span>
        </div>
        <div class="address">{{addressObj.proviceName+addressObj.cityName+addressObj.areaName+addressObj.address}}</div>
        <div class="operation">
          <div class="opa_button" @click="editAddress()">修改地址</div>
        </div>
      </div>
      <div class="box_add" v-else @click="addAddress()">
        <img class="add_icon" src="@/assets/image/study/cer_upload.png" alt="上传地址">
        <div class="add_txt">上传地址</div>
      </div>
      <!-- 问卷调查 -->
      <div class="allbox" v-if="false">
        <div style="text-align: center">
          <img
            width="120"
            src="@/assets/image/examination/title136.png"
            alt=""
          />
        </div>

        <div class="boxtitle">
          尊敬的华夏云课堂用户，您好！<br />为了给您提供更加优质的服务，现中国残疾人康复协会面向全体学员开展培训满意度调查问卷活动，感谢您的参与和配合！
        </div>

        <div
          class="gradebox"
          v-for="(item, index) in surveyContent"
          :key="index"
        >
          <div class="boxtop">
            <div class="img">{{ index + 1 }}</div>
            <div class="txt">{{ item.title }}</div>
          </div>
          <div class="boxconter" v-if="item.type == 1">
            <div
              class="fraction"
              v-for="i in 5"
              :key="i"
              :class="6 - i == item.answer ? 'selColor' : ''"
              @click="item.answer = 6 - i"
            >
              {{ 6 - i }}
            </div>
          </div>
          <div class="boxbottom" v-if="item.type == 1">
            <ul>
              <li v-if="item.answer == 5">非常满意</li>
              <li v-if="item.answer == 4">满意</li>
              <li v-if="item.answer == 3">一般</li>
              <li v-if="item.answer == 2">不满意</li>
              <li v-if="item.answer == 1">非常不满意</li>
            </ul>
          </div>
          <div class="input" v-if="item.type == 2">
            <van-field
              v-model="proJect.advice"
              rows="8"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="请输入留言"
              show-word-limit
            />
          </div>
        </div>
      </div>
      <div v-if="false" style="height:90px"></div>
      <!-- 提交按钮 -->
      <div class="btn-foo">
        <span class="btn up" @click="onBackTo()">上一步</span>
        <span class="btn next" @click="onNextTo()">确认</span>
      </div>
    </div>
    <!-- 提示信息 -->
    <van-popup v-model="isTips">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon color="#ec3c31" name="info-o" size="22" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">确认信息后除非有姓名或身份证信息等错误，否则不再接受修改证书内容及邮寄地址</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="isTips=false">取消</span>
          <span class="all-btn-middle btn2" @click="onSubmit()">确认信息</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onGoStudy,refreshExam, onRemoveStorage } from '@/assets/js/NativeWebKit.js'
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        defaultAddressId: '', //默认地址id
        isTips:false,
        stepIndex:3  ,// 控制步骤样式
        signupId: '', //考试id
        examineeId: '', //考生id

        type: 1, //照片提交方式 1纸质 2电子照
        picture: '',  //电子照片
        addressId: '', //地址id
        addressObj: {}, //收货地址
        refundAddressId: '', //退货地址id
        refundAddressObj: {}, //退货地址
        postCompany: '', //快递公司
        postNum: '', //快递单号
        isAddAddress: false, //添加地址

        surveyContent: [], // 问卷列表
        // 问卷提交对象
        proJect: {
          advice: "", // 调查问卷建议
        },
      }
		},
    created() {
      this.signupId = this.$route.query.signupId //考试id
      // this.examineeId = this.$route.query.examineeId //考生id
      // this.type = this.$route.query.type //照片提交方式
      // this.picture = this.$route.query.picture //电子照片
      // 获取存储信息
      this.getConfirm()
      // 退货地址详情
      // this.refundAddressId = this.$route.query.refundAddressId
      this.getRefunDetail()
      
      // 查询问卷列表
      this.exportReport();
    },
		// 事件处理器
		methods: {
      // 获取存储信息
      getConfirm() {
        if (localStorage.getItem('addressId')) {
          this.addressId = localStorage.getItem('addressId')
          // 存储地址
          let confirmObjName = 'confirmObj' + this.signupId
          let confirmObj = JSON.parse(localStorage.getItem(confirmObjName))
          confirmObj.addressId = localStorage.getItem('addressId')
          this.addressId = localStorage.getItem('addressId')
          localStorage.setItem(confirmObjName, JSON.stringify(confirmObj))
          localStorage.removeItem('addressId')
          // 收货地址详情
          this.getAddress()
        }
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = JSON.parse(localStorage.getItem(confirmObjName))
        
        this.examineeId = confirmObj.examineeId || '' //考生id
        this.refundAddressId = confirmObj.refundAddressId || '' //退货地址id
        this.addressId = confirmObj.addressId || '' //收货地址id
        this.defaultAddressId = confirmObj.defaultAddressId || '' //默认地址id
        this.type = confirmObj.type || 1 //提交方式
        this.picture = confirmObj.certifiMobile || '' //电子照片
        this.postCompany = confirmObj.postCompany || '' //快递公司
        this.postNum = confirmObj.postNum || '' //快递单号

        // 收货地址详情
        this.getAddress()
      },
      // 修改存储信息
      setConfirm() {
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = JSON.parse(localStorage.getItem(confirmObjName))

        confirmObj.addressId = this.addressId //收货地址id
        if (this.defaultAddressId !== '') {
          confirmObj.defaultAddressId = this.defaultAddressId //收货地址id
        }

        // 存储
        localStorage.setItem(confirmObjName, JSON.stringify(confirmObj))
      },
      // 上一步
      onBackTo(){
        this.$router.go(-1)
        // 存储信息
        this.setConfirm()
      },
      // 下一步
      onNextTo() {
        // 收货地址校验
        if (JSON.stringify(this.addressObj) == '{}') {
          this.$Toast('请选择您的收货地址')
          return
        }
        this.isTips = true
        // 存储信息
        this.setConfirm()

       
      },
      // 确认信息
      onSubmit() {
        let post = 'postObj'+this.signupId
        let postObj = localStorage.getItem(post)
        if (postObj) {
          this.postCompany = JSON.parse(postObj).postCompany // 快递公司
          this.postNum = JSON.parse(postObj).postNum // 物流单号
        }
        // console.log(this.postObj)

        let params = {
          examineeId: this.examineeId, //考生id
          buyerName: this.refundAddressObj.name, //买方人名称
          buyerMobile: this.refundAddressObj.mobile, //买方人电话
          buyerProvince: this.refundAddressObj.province, //买方地址省
          buyerCity: this.refundAddressObj.city, //买方地址市
          buyerArea: this.refundAddressObj.area, //买方地址区
          buyerAddress: this.refundAddressObj.address, //买方详细地址
          userName: this.addressObj.consignee, //考生收货人名称
          userMobile: this.addressObj.phone, //考生收货人电话
          userProvince: this.addressObj.provice, //考生收货地址省
          userCity: this.addressObj.city, //考生收货地址市
          userArea: this.addressObj.area, //考生收货地址区
          userAddress: this.addressObj.address, //考生收货详细地址

          isConfirm: 1, //是否确认 1.是 0.否
          mode: this.type, //照片提交方式 1.邮寄纸质照片 2.使用上传电子照片	
          logisticsCompany: this.type==1 ? this.postCompany : '', //物流公司
          logisticsNumber: this.type==1 ? this.postNum : '', //	物流单号
          isPay: this.type==2 ? 1 : 0, //是否支付 1.是 0.否
          picture: this.type==2 ? this.picture : '', //	电子照片
          price: this.type==2 ? 20 : 0, //	价格
        }
        // console.log(params)

        // return
        this.$ajax({
					url:'/hxclass-mobile/exam/examinee/submit',
					method:'PUT',
          params
				}).then(res=>{
					if(res.code == 200 && res.success){
            this.$Toast.success('确认信息完成');
            // 删除物流、地址id
            // localStorage.removeItem('addressId')
            localStorage.removeItem('confirmObj'+this.signupId)
            // onRemoveStorage('confirmObj'+this.signupId)
             // 提交问卷
            this.pushAnswer();
            this.isTips = false
            refreshExam()
            onGoStudy()
            // this.$router.push('/examEnroll/registration')
            // this.$router.go(-3)
					}
				})
      },
      // 地址
      getAddress() {
        // let addressId = localStorage.getItem('addressId')
        // console.log(addressId)
        if (this.addressId!=='') {
          // console.log(this.addressId)
          // this.addressId = JSON.parse(addressId)
          // 收货地址详情
          this.getAddressDetail()
        } else {
          // 获取默认地址
          this.getDefaultAddress()
        }
      },
      // 默认地址
      getDefaultAddress() {
        this.$ajax({
					url:'/hxclass-mobile/ship/address/getdefault',
					method:'get',
          params: {
            userId: this.$store.state.userInfo.userId
          }
				}).then(res=>{
					if(res.code == 200 && res.success){
            // 没有地址
            if (!res.data) {
              this.isAddAddress = true
            }
            this.addressId = res.data.id
            this.addressObj = res.data
            // 是否为默认地址
            if (res.data.isDefault == 1) {
              // 默认地址id
              this.defaultAddressId = res.data.id
            } else {
              this.defaultAddressId = null
            }
					}
				})
      },
      // 地址详情
      getAddressDetail() {
        this.$ajax({
					url:'/hxclass-mobile/ship/address/detail',
					method:'get',
					params: {
						id: this.addressId
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.addressObj = res.data
					}
				})
      },
      // 退货地址详情
      getRefunDetail() {
        this.$ajax({
          url: '/hxclass-mobile/admin-address/detail',
          method: 'get',
          params: {
            addressId: this.refundAddressId
          }
        }).then((res) => {
          // console.log(res);
          if (res.code == 200 && res.success) {
            this.refundAddressObj = res.data
          }
        });
      },
      // 修改地址
      editAddress() {
        this.$router.push({
          path: '/address/adjust',
          query: {
            addressId: this.addressId,
            type: 1
          }
        });
      },
      // 添加地址
      addAddress() {
        this.$router.push({
          path: '/address/addAddress'
        })
      },
      // 获取题目
      exportReport() {
        this.$ajax({
          url: "/hxclass-mobile/survey/msg/1",
          method: "get",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            let arrject = JSON.parse(res.data.surveyContent);
            arrject.title_list.forEach((element) => {
              element.answer = 5;
              this.surveyContent.push(element);
            });
            console.log(this.surveyContent);
          }
        });
      },
      // 提交问卷调查答案
      pushAnswer() {
        // 取出sessionStorage
        let proJect = JSON.parse(sessionStorage.getItem("surveyContent"));
        this.$ajax({
          url: "/hxclass-mobile/survey/submit",
          method: "PUT",
          params: proJect,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$Toast(res.message);
          } else {
            this.$Toast(res.message);
          }
        });
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./signUpConfirm.less";
  /deep/.van-popup {
    background-color: transparent;
  }
  .container-wrap {
    height: calc(100vh - 73px);
    background-color: #F7F7F9;
  }
  .content-box{
    padding: 0 18px;
    .all-bg{
      margin-top: 12.5px;
      padding-bottom: 7.5px;
      position: relative;
      box-shadow: 0px 2px 5px 0px rgba(93,131,167,0.1);
      font-family: PingFangMedium;
      font-weight: 500;
      .default{
        position: absolute;
        right: 0;
        top: 0;
        width: 45.5px;
        height: 21.5px;
        border-radius: 0 9px 0px 9px;
        line-height: 21.5px;
        text-align: center;
        background-image: linear-gradient(to right, #FFBD60 , #EAA037);
        font-size: 12px;
        color: #FFFFFF;
        // line-height: 17.5px;
      }
      .header{
        font-size: 14.5px;
        color: #333333;
        line-height: 21.5px;
        .tel{
          margin-left: 12.5px;
          font-size: 11px;
          line-height: 16.5px;
        }
      }
      .address{
        margin-top: 12.5px;
        font-size: 12.5px;
        color: #000000;
        line-height: 19px;
      }
      .operation{
        margin-top: 13px;
        padding-top: 9px;
        text-align: right;
        font-size: 12px;
        color: #333333;
        line-height: 17.5px;
        border-top: 1px solid #EEEEEE;
        .opa_button{
          display: inline-block;
          font-size: 11px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #15B7DD;
          margin-left: 25px;
          padding: 4px 8px;
          border-radius: 15px;
          border: 1px solid #15B7DD;
        }
      }
    }
    .box_add {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 181px;
      background: #F5FDFF;
      margin-top: 13px;
      .add_icon {
        width: 34px;
      }
      .add_txt {
        font-size: 13px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #15B7DD;
        margin-top: 9px;
      }
    }

    .allbox {
      margin-top: 12.5px;
      padding-bottom: 7.5px;
      overflow: hidden;
      // position: relative;
      box-shadow: 0px 2px 5px 0px rgba(93, 131, 167, 0.1);
      font-family: PingFangMedium;
      font-weight: 500;
      background: #ffffff;
      padding: 14px;

      .boxtitle {
        margin-top: 8px;
        margin-bottom: 10px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
      .gradebox {
        overflow: hidden;
        margin-bottom: 14px;
        .boxtop {
          display: flex;
          align-items: baseline;
          .img {
            width: 18px;
            background-image: url(~@/assets/image/examination/Vector14.png);
            background-repeat: no-repeat;
            background-size: 100%;
            text-align: center;
            font-size: 11px;
            flex-shrink: 0;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #ffffff;
            line-height: 17px;
          }
          .txt {
            margin-left: 5px;
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 27px;
          }
        }
        .boxconter {
          margin-top: 10px;
          height: 40px;
          // overflow: hidden;
          // border-radius: 8px 8px 8px 8px;
          opacity: 1;
          // border: 1px solid #d7d7d7;
          display: flex;
          align-items: center;
          .fraction {
            margin: 0;
            padding: 0;
            width: 24%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-left: #d7d7d7 1px solid;
            border-top: #d7d7d7 1px solid;
            border-bottom: #d7d7d7 1px solid;
          }
          .fraction:first-child {
            border-radius: 8px 0px 0px 8px;
          }
          .fraction:last-child {
            border-right: #d7d7d7 1px solid;
            border-radius: 0px 8px 8px 0px;
          }
        }
        .boxbottom {
          margin-top: 6px;
          ul {
            display: flex;
            align-items: center;
            li {
              width: 25%;
              text-align: center;
              display: block;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
            }
          }
        }
        .input {
          /deep/.van-cell {
            background-color: #f7f7f9 !important;
          }
          background: #f7f7f9;
        }
      }
      .selColor {
        background: #15b7dd;
        color: #ffffff !important;
      }
    }

    .btn-foo{
      bottom: 0;
    }
  }
</style>
