<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="证书查询"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 查询条件输入框 -->
    <div class="content-box">
      <div class="all-white-bg">
        <div class="input-box">
          <van-field 
            class="input" 
            :border='false' 
            :value="columns[columnsIndex]&&columns[columnsIndex].name" 
            :readonly='true' 
            @click="addressShow = true" 
            placeholder="请选择证书类型" />
          <van-icon color="#999999" name="arrow" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入姓名" v-model="name" />
        </div>
        <div class="input-box">
          <van-field class="input" placeholder="请输入身份证号" v-model="idcard" />
        </div>
      </div>
      <div class="select-btn">
        <span class="all-btn-bigCircle" @click="queryData()">立即查询</span>
      </div>
      
      <!-- 查询结果 -->
      <div class="result-box" v-if="isShowData">
        <span class="all-title">查询结果</span>
        <div class="result-item">
          <div 
            class="child-item" 
            v-for="(item, index) in dataList" 
            :key="index">
            <div class="p title">{{item.certifName}}</div>
            <div class="p txt">考试时间：{{item.examDate ? item.examDate : '-'}}</div>
            <span class="type detail" @click="goDetail(item)">查看详情</span>
          </div>
          <noMessage class="empty" v-if="dataList.length==0" type=6></noMessage>
        </div>
      </div>

      <van-popup v-model="addressShow" position="bottom" >
        <van-picker
          title="证书列表"
          show-toolbar
          value-key="name"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="addressShow = false"
        />
      </van-popup>

    </div>
  </div>
</template>
<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {noMessage},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns: [],
      columnsIndex: -1,
      addressShow: false,

      name: '', //用户姓名 zyn1
      idcard: '', //身份证号 12345678X

      dataList: [],
      isShowData: false, //搜索结果
    }
  },
  // 事件处理器
  methods: {
    // 证书类型
    getType() {
      this.$ajax({
        url: '/hxclass-mobile/user-certif/certif-temp/select/option?type=1',
        method: "POST",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.columns = res.data
        } else {
          this.$alert(res.message);
        }
      })
    },
    // 选择证书类型
    onConfirm(item, index) {
      this.columnsIndex = index
      this.addressShow = false
    },
    // 立即查询
    queryData() {
      if (this.$store.state.userInfo.isLogin) {
        if (this.columnsIndex == -1) {
          this.$Toast('请选择证书类型进行查询');
          return
        } else if (this.name == '') {
          this.$Toast('请输入姓名进行查询');
          return
        } else if (this.idcard == '') {
          this.$Toast('请输入身份证号进行查询');
          return
        } 
        // else if (!this.$regular.idCard.reg.test  (this.idcard)) {
        //   this.$Toast('请输入正确的身份证号');
        //   return
        // }

        this.$ajax({
          url: '/hxclass-mobile/exam/certif/query',
          method: "get",
          params: {
            tempId: this.columns[this.columnsIndex].  tempId, //证书ID
            // tempId: 2, //证书ID
            name: this.name, //姓名
            idcard: this.idcard, //身份证号
          }
        }).then((res) => {
          // console.log(res)
          if (res.code == 200 && res.success) {
            // this.$Toast.success('查询成功');
            this.dataList = res.data
            this.isShowData = true
            // 数据为空
            // if (this.dataList.length == 0) {
            //   // this.$Toast('暂无数据');
            //   this.isNoMessage = true
            // }
          } else {
            this.$alert(res.message);
          }
        })
      }else{
        this.$router.push('/login/login');
      }
    },
    // 证书详情
    goDetail(e){
      this.$router.push({path:'/search/cardDetail',query:{id: (e.type == 1 ? e.certifId : e.id), type: e.type }});
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 证书类型
    // this.getType()
  },
  // 缓存进入页面
  activated() {
    // console.log(this.$route.params)
    if (this.$route.params.isLoad) {
      // 初始化
      this.columnsIndex = -1
      this.name = ''
      this.idcard = ''
      this.dataList = []
      this.isShowData = false
      // 证书类型
      this.getType()
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  @import "./select.less";
  .input-box{
    display: flex;
    align-items: center;
  }
</style>
