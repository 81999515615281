<template>
	<!-- 寄回信息 -->
	<div class="returnMessage">
		<!-- 导航栏 -->
		<van-nav-bar
			title="填写寄回信息"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<div class="formList">
			<div class="formItem">
				<span class="formType">寄件人</span>
				<van-field v-model="user" placeholder="请填写寄件人姓名" />
			</div>
			<div class="formItem">
				<span class="formType">手机号码</span>
				<van-field v-model="user" placeholder="请填写寄件人姓名" />
			</div>
			<div class="formItem">
				<span class="formType">快递公司</span>
				<van-field v-model="value" placeholder="请填写快递公司" readonly @click="showPicker = true" />
			</div>
			<div class="formItem">
				<span class="formType">运单号</span>
				<van-field v-model="user" placeholder="请填写寄件人姓名" />
			</div>
		</div>

		<!-- 快递公司 -->
		<van-popup v-model="showPicker" round position="bottom">
			<van-picker
				show-toolbar
				:columns="array"
				@cancel="showPicker = false"
				@confirm="onConfirm"
			/>
		</van-popup>

		<div class="footer">
			<div type="default" class="all-btn-bigCircle btn">提  交</div>
		</div>
	</div>
</template>

<script>
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			showPicker: false,
			value: '',
			array: ['中通', '圆通', '申通', '邮政'], // 快递公司
			user:'',  // 寄件人
			phone:'',  // 手机号码
			express:'',  // 快递公司
			waybillNo:'',  // 运单号

		}
	},
	// 事件处理器
	methods: {
		bindPickerChange(e) {
			// console.log('picker发送选择改变，携带值为', e.target.value)
			this.express = e.target.value
		},
		onConfirm(value) {
			// console.log(value)
      this.value = value;
      this.showPicker = false;
    },
	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	/deep/.van-cell {
		padding: 0;
		flex: 1;
	}

	.returnMessage{
		min-height: calc(100vh - 44px);
		background: #F7F7F9;
		padding: 8px 18px;
		box-sizing: border-box;
		.formList{
			background-color: #ffffff;
			border-radius: 8px;
			padding: 0 18px;
			.formItem{
				display: flex;
				align-items: center;
				padding: 16px 0;
				border-bottom: 1px solid #F0F0F0;
				&:last-child{
					border: 0;
				}
				.formType{
					width: 80px;
					font-size: 14px;
					color: #333333;
					line-height: 22px;
				}
				input{
					flex: 1;
					font-size: 12px;
					color: #333333;
					line-height: 22px;
				}
				.uni-input{
					flex: 1;
					font-size: 12px;
					color: #333333;
					line-height: 22px;
				}
				.black{
					color: grey;
				}
			}
		}
		.footer{
			background: #F7F7F9;
			position: fixed;
			text-align: center;
			bottom: 0;
			left: 0;
			width: 100vw;
			padding: 5px 40px;
			box-sizing: border-box;
			.btn{
				width: 295px;
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				font-weight: bold;
				color: #FFFFFF;
			}
		}
	}
</style>
