<template>
	<!-- 退款列表 -->
	<div class="list">
		<van-nav-bar
		class="nav-circular"
			title="退款列表"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>

		<div class="box">
			<div class="box_item">
				<div class="item_con">
					<img class="con_img" src="" />
					<div class="con_main">
						<div class="main_top">
							<div class="top_title">孤独症康复教育上岗培训上岗培训上岗培训上岗培训</div>
							<div class="top_price">¥ 64.00</div>
						</div>
						<div class="main_num">×1</div>
						<div class="main_type">未发货</div>
					</div>
				</div>
				<div class="item_button">
					<router-link to='/order/refundApplyFor'>
						<div class="button_item">申请退款</div>
					</router-link>
				</div>
			</div>
			<div class="box_item">
				<div class="item_con">
					<img class="con_img" src="" />
					<div class="con_main">
						<div class="main_top">
							<div class="top_title">孤独症康复教育上岗培训上岗培训上岗培训上岗培训</div>
							<div class="top_price">¥ 64.00</div>
						</div>
						<div class="main_num">×1</div>
						<div class="main_type">未发货</div>
					</div>
				</div>
				<div class="item_button">
					<router-link to='/order/refundApplyFor'>
						<div class="button_item">查看退款</div>
					</router-link>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	// 可用组件的哈希表
	components: {},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {}
	},
	// 事件处理器
	methods: {},
	beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #f4f4f4;width: 100%;font-size: 0px !important;overflow-x: hidden;')
    document.querySelector('html').setAttribute('style', 'font-size: 13.33333333vw !important;height: 100%;width: 100%;')
  },
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () {
    document.querySelector('body').removeAttribute('style')
    document.querySelector('html').removeAttribute('style')
	 },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
* {box-sizing: border-box;}
.list {
	height: 100vh;
	background: #F7F7F9;
	padding-bottom: 60px;
}

.box {
	padding: 4px 18px;
	// padding: .08rem 0.36rem;
	.box_item {
		// width: 678px;
		background: #FFFFFF;
		border-radius: 8px;
		margin-top: 8px;
		padding: 16px 12px;
		// padding: 32px 14px 32px 24px;
		.item_con {
			display: flex;
			.con_img {
				flex-shrink: 0;
				width: 100px;
				height: 70px;
				border-radius: 7px;
				margin-right: 12px;
			}
			.con_main {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%;
				height: 70px;
				.main_top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 1px;
					.top_title {
						// width: 160px;
						width: 70%;
						font-size: 12px;
						font-weight: 500;
						color: #333333;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.top_price {
						font-size: 10px;
						font-family: PingFangMedium;
						font-weight: bold;
						color: #333333;
					}
				}
				.main_num {
					font-size: 12px;
					font-weight: 400;
					color: #999999;
					text-align: right;
				}
				.main_type {
					font-size: 10px;
					font-weight: 500;
					color: #15B7DD;
				}
			}
		}
		.item_button {
			display: flex;
			justify-content: flex-end;
			margin-top: 4px;
			.button_item {
				display: inline-block;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				border-radius: 16px;
				border: 1px solid #EEEEEE;
				padding: 6px 12px;
			}
		}
	}
}
</style>
