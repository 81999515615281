var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('van-nav-bar',{staticClass:"nav-circular",attrs:{"title":"帮助与反馈","fixed":true,"placeholder":true,"left-arrow":""},on:{"click-left":function($event){return _vm.$onReturn(_vm.$route.query.fromMenu == '1')}}}),_vm._m(0),_c('div',{staticClass:"box2"},[_c('router-link',{staticClass:"box_item",attrs:{"to":{
        name: 'problemList',
        params: {isLoad: true}
      }}},[_c('div',{staticClass:"item_main"},[_c('img',{staticClass:"main_icon",attrs:{"src":require("../../assets/image/my/feedback_icon1.png")}}),_c('div',{staticClass:"main_txt"},[_vm._v("常见问题")])]),_c('van-icon',{attrs:{"name":"arrow","color":"#CCCCCC","size":"20"}})],1),_c('router-link',{staticClass:"box_item",attrs:{"to":"/mine/feedbackSubmit"}},[_c('div',{staticClass:"item_main"},[_c('img',{staticClass:"main_icon",attrs:{"src":require("../../assets/image/my/feedback_icon2.png")}}),_c('div',{staticClass:"main_txt"},[_vm._v("意见反馈")])]),_c('van-icon',{attrs:{"name":"arrow","color":"#CCCCCC","size":"20"}})],1),_c('div',{staticClass:"box_item",on:{"click":function($event){return _vm.CallCustomer()}}},[_vm._m(1),_c('van-icon',{attrs:{"name":"arrow","color":"#CCCCCC","size":"20"}})],1),_c('a',{staticClass:"box_item",on:{"click":function($event){return _vm.FunIosPhone(4008009002)}}},[_vm._m(2),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"info_txt"},[_vm._v("400-800-9002")]),_c('van-icon',{attrs:{"name":"arrow","color":"#CCCCCC","size":"20"}})],1)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1"},[_c('img',{staticClass:"box_img",attrs:{"src":require("../../assets/image/my/feedback_bg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_main"},[_c('img',{staticClass:"main_icon",attrs:{"src":require("../../assets/image/my/feedback_icon3.png")}}),_c('div',{staticClass:"main_txt"},[_vm._v("在线客服")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_main"},[_c('img',{staticClass:"main_icon",attrs:{"src":require("../../assets/image/my/feedback_icon4.png")}}),_c('div',{staticClass:"main_txt"},[_vm._v("客服热线")])])
}]

export { render, staticRenderFns }