<template>
  <div id="window">
    <van-nav-bar
      title="添加发票"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div id="content">
      <!-- 选项列表 -->
      <div class="box">
        <div class="item">
          <div class="left">发票类型</div>
          <div class="right">
            <van-radio-group @change="onRadioChange" class="radioGroup" v-model="form.headerType" icon-size="16" checked-color="#00B3C5">
              <van-radio :name="1" class="radio">企业单位</van-radio>
              <van-radio :name="3" class="radio">个人/非企业单位</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="item">
          <div class="left">发票抬头</div>
          <div class="right">
            <input v-model="form.invoiceHeader" @input="searchInvoiceHeader(form.invoiceHeader)" placeholder="请输入发票抬头" />
            <div class="searchList">
              <p class="searchitem" @click="enterinvoice(item)" v-for="(item,index) in invoiceHeaderList" :key="index">{{item.nsrmc}}</p>
            </div>
          </div>
        </div>
        <template v-if="form.headerType==1">
          <div class="item">
            <div class="left">税号</div>
            <div class="right">
              <input v-model="form.dutyParagraph" placeholder="请输入税号" />
            </div>
          </div>
        </template>
        <div class="item">
          <div class="left">电子邮箱</div>
          <div class="right">
            <input v-model="form.email" placeholder="请输入电子邮箱" />
          </div>
        </div>
      </div>
      <template v-if="form.headerType==1">
        <div class="box">
          <div class="item">
            <div class="left">开户银行</div>
            <div class="right">
              <input v-model="form.bank" placeholder="选填" />
            </div>
          </div>
          <div class="item">
            <div class="left">银行账户</div>
            <div class="right">
              <input v-model="form.bankAccount" placeholder="选填" />
            </div>
          </div>
          <div class="item">
            <div class="left">公司电话</div>
            <div class="right">
              <input v-model="form.companyPhone" placeholder="选填" />
            </div>
          </div>
          <div class="item">
            <div class="left">公司地址</div>
            <div class="right">
              <input v-model="form.companyAddress" placeholder="选填" />
            </div>
          </div>
        </div>
      </template>
      <!-- 操作 -->
      <div class="operation">
        <div class="all-btn-bigCircle btn" @click="onSaveForm()">保存发票</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      tabIndex: '0',
      form: {
        userInvoiceId: '',
        headerType: 1,  //  抬头类型 1企业 3个人
        invoiceHeader: '',  //	发票抬头
        dutyParagraph: '',  //  	税号
        email: '', //	电子邮箱

        bank: '',  // 开户银行
        bankAccount: '',  // 银行账户
        companyPhone: '',  // 	公司电话
        companyAddress: '', // 	公司地址

        userInvoiceId: '',

      },
      isSubmit: true,
      invoiceHeaderList: []
    }
  },
  // 事件处理器
  methods: {
    // 选择抬头
    enterinvoice(e) {
      this.$set(this.form,'invoiceHeader',e.nsrmc)
      if(this.form.headerType == 1){
        this.$set(this.form,'dutyParagraph',e.nsrsbh)
      }
      this.invoiceHeaderList = [];
    },

    // 发票抬头搜索
    searchInvoiceHeader(e){
      // console.log(e)
      if(e.length >= 6){
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$ajax({
            url: '/hxclass-mobile/order/getCompanyInfo',
            method: 'get',
            params: {
              nsrmc: e
            }
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.invoiceHeaderList = res.data;
            }
          })
        }, 200)        
      }else{
        this.invoiceHeaderList = [];
      }
    },

    // 发票类型切换
    onRadioChange(e){
    },
    FunCheck(){
      let isCheck = false
      if(!this.form.invoiceHeader){
        isCheck = true
        this.$Toast("请填写发票抬头");
        return isCheck
      }
      if (!this.$regular.invoiceTitle.reg.test(this.form.invoiceHeader)) {
        isCheck = true
        this.$Toast(this.$regular.invoiceTitle.msg);
        return isCheck
      }
      if(this.form.headerType == 1 && !this.form.dutyParagraph){
        isCheck = true
        this.$Toast("请填写税号");
        return isCheck
      }
      if (this.form.headerType == 1 && !this.$regular.TaxNumber.reg.test(this.form.dutyParagraph)) {
        isCheck = true
        this.$Toast(this.$regular.TaxNumber.msg);
        return isCheck
      }
      // if(!this.form.email){
      //   isCheck = true
      //   this.$Toast("请填写电子邮箱");
      //   return isCheck
      // }
      if (this.form.email && !this.$regular.email.reg.test(this.form.email)) {
        isCheck = true
        this.$Toast(this.$regular.email.msg);
        return isCheck
      }
      if(this.form.companyPhone){
        if (!this.$regular.phoneTell.reg.test(this.form.companyPhone)) {
          isCheck = true
          this.$Toast(this.$regular.phoneTell.msg);
          return isCheck
        }
      }
    },
    // 保存表单
    onSaveForm(){
      if(this.FunCheck())return
      this.form.invoiceHeader = this.form.invoiceHeader.replace(/\s+/g, '') // 税号去除空格
      this.form.dutyParagraph = this.form.dutyParagraph.replace(/\s+/g, '') // 税号去除空格
      if(this.form.email) {
        this.form.email = this.form.email.replace(/\s+/g, '') // 邮箱去除空格
      }
      this.form.companyPhone = this.form.companyPhone.replace(/\s+/g, '') // 电话去除空格
      let formObj = {
        userInvoiceId: this.form.userInvoiceId,
        headerType: 3,
        invoiceHeader: this.form.invoiceHeader,
        email: this.form.email,
      }
      if(this.isSubmit){
        this.isSubmit = false
        this.$ajax({
          url: "/hxclass-mobile/invoice/header/",
          method: this.form.userInvoiceId ? 'put' : 'post',
          params: this.form.headerType == 1 ? this.form : formObj
        }).then((res) => {
          this.isSubmit = true
          if (res.code == 200 && res.success) {
            this.$Toast(this.form.userInvoiceId ? '修改成功' : '添加成功');
             this.$store.commit('updateShortInvoiceId',res.data);
            this.$router.back()
          } else {
            this.$Toast(res.message);
          }
        });
      }
    },
    // 获取发票详情
    getInvoiceDetail(){
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/" + this.form.userInvoiceId,
        method: 'get',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.form.userInvoiceId = this.$route.query.userInvoiceId
    if(this.form.userInvoiceId ){
      this.getInvoiceDetail()
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import '../../assets/css/invoice.less';
#window{
  height: 100vh;
  display: flex;
  flex-direction: column;
  #content{
    flex: 1;
  }
}
.box{
  background-color: #fff;
  margin: 12px 18px;
  border-radius: 8px;
  padding: 0 12px;
  .item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    border-bottom: 1px solid #f5f5f5;
    &:last-child{
      border: none;
    }
    .left{
      min-width: 65px;
      font-size: 16px;
      color: #333333;
      font-weight: 400;
    }
    .right{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      position: relative;
      input{
        flex: 1;
        height: 100%;
        text-align: right;
        font-size: 12px;
        border: none;
      }
      .searchList{
        max-height: 200px;
        overflow: auto;
        position: absolute;
        top: 54px;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #f5f5f5;
        .searchitem{
          border-bottom: 1px solid #f5f5f5;
          padding: 10px;
          line-height: 20px;
          font-size: 13px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .radioGroup{
        font-size: 12px;
        color: #333;
        margin-left: 15px;
        display: flex;
        .radio{
          margin-right: 20px;
          &:last-child{
            margin-right: 0;
          }
          /deep/.van-radio__label{
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.operation{
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  .btn{
    width: 100%;
    height: 40px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
}
</style>
