<template>
  <div class="container-box">
    <van-nav-bar
      title="关于我们"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <!-- 版本号检查 -->
      <div class="Version-number">
        <img class="logo-img" src="@/assets/image/icon/logo.png" />
        <template v-if="AppVersion.phoneSystem">
          <span class="number">v{{AppVersion.name}}(build {{AppVersion.code}})</span>
          <span class="check-btn" @click="ViewNewVersion()">检查更新</span>
          <!-- <span class="check-btn" @click="judgeHuawei()">检查更新</span> -->
        </template>
      </div>

      <div class="single-menu-list">
        <router-link to="/all/privacyPolicy" class="single-menu-item">
          <span class="p side-left-title">华夏云课堂隐私协议</span>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link>
        <router-link to="/all/ServiceAgreement" class="single-menu-item">
          <span class="p side-left-title">华夏云课堂服务协议</span>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link>
        <!-- <router-link to="" class="single-menu-item">
          <span class="p side-left-title">支付协议</span>
          <van-icon name="arrow" color="#cccccc" size="18" />
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getAppVersion, downloadApp } from '@/assets/js/NativeWebKit.js'
import { FunJudgDevice } from "@/unit/fun.js";
const DeviceType = FunJudgDevice();
export default {
  // 可用组件的哈希表
  components: {},
  // 数据对象
  data() {
    return {
      AppVersion: getAppVersion(),
      isAndroidType: 0, //1华为市场 0其他
    };
  },
  created() {
    // 是否为华为应用市场包
    window.returnAndroidType = (type)=>{
      this.isAndroidType = type
      // 检查是否有新版本
      this.ViewNewVersion()
    }
  },
  // 事件处理器
  methods: {
    // 判断是否为华为应用市场包
    judgeHuawei() {
      // 安卓
      if (DeviceType == "android") {
        // 判断是否为华为应用市场包
        Android.getAndroidType();
      } else if (DeviceType == "ios" && window.webkit) {
        this.ViewNewVersion()
      }
    },
    // 检查是否有新版本
    ViewNewVersion(){
      this.$ajax({
        url: '/hxclass-mobile/score/app/newest',
        params: {
          phoneSystem: this.AppVersion.phoneSystem, // 1.android 2.ios
          // versionNo: this.AppVersion.code,
          // versionName: this.AppVersion.name
          // phoneSystem: 1, // 1.android 2.ios
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          if(this.AppVersion.code >= res.data.versionNo) {
            return this.$Toast('已经是最新版本啦');
          }else {
            // 华为应用包
            if (this.isAndroidType == 1) {
              return Promise.reject('发现新版本，请去应用商店更新版本。')
            }
            return this.$Dialog.confirm({
              title: '发现新版本',
              message: '是否更新最新版本？',
            }).then(() => {
              // android机 提示下载   ios跳商城
              if(this.AppVersion.phoneSystem == 1){
                this.$alert('开始下载')
              }
              return this.$ajax({
                url: '/hxclass-mobile/score/new/version',
                params: {
                  phoneSystem: this.AppVersion.phoneSystem
                }
              })
            }).then(res=>{
              if(res.code == '200' && res.success){
                downloadApp(res.data)
              }
            }).catch(err=>{
              this.$alert(err == 'cancel' ? '取消更新' : err == 'undefined' ? '更新错误' : err)
            })
          }
        }
      })
      return
      // ios  1.0 版本的所有用户需手动去商店更新
      if(this.AppVersion.name == '1.0' && DeviceType == 'ios'){
        this.$Dialog.alert({
          title: '发现新版本',
          message: '请手动前往App Store应用商店更新安装包',
        })
        return
      } else {
        this.$ajax({
          url: '/hxclass-mobile/score/newest',
          params: {
            phoneSystem: this.AppVersion.phoneSystem, // 1.android 2.ios
            versionNo: this.AppVersion.code,
            versionName: this.AppVersion.name
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            return Promise.reject(res.message)
          }else{
            // 华为应用包
            if (this.isAndroidType == 1) {
              return Promise.reject('发现新版本，请去应用商店更新版本。')
            }
            return this.$Dialog.confirm({
              title: '发现新版本',
              message: '是否更新最新版本？',
            })
          }
        }).then(() => {
          return this.$ajax({
            url: '/hxclass-mobile/score/new/version',
            params: {
              phoneSystem: this.AppVersion.phoneSystem
            }
          })
        }).then(res=>{
          if(res.code == '200' && res.success){
            downloadApp(res.data)
          }
        }).catch(err=>{
          this.$alert(err == 'cancel' ? '取消更新' : err == 'undefined' ? '更新错误' : err)
        })
      }
      
    }
  },
};
</script>


<style lang="less" scoped>
@import "./style.less";
.Version-number {
  text-align: center;
  padding: 76px 0 16px;
  .logo-img {
    width: 90px;
    border-radius: 10px;
  }
  .number {
    display: block;
    margin-top: 15px;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .check-btn {
    display: inline-block;
    margin-top: 16px;
    width: 122px;
    height: 40px;
    background: #15b7dd;
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
  }
}
</style>
