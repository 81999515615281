<template>
  <!-- 常见问题列表-->
  <div class="problemList">
    <!-- 导航栏 -->
    <div style="overflow: hidden; background-color: #ffffff">

    <van-nav-bar
      title="常见问题"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    </div>

    <SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />

    <!-- <div class="tab">
      <van-tabs v-model="tabIndex" @change="changeTab" swipe-threshold="3">
        <van-tab
          v-for="(item, index) in tabList"
          :key="index"
          :title="item.categoryName"
        >
        </van-tab>
      </van-tabs>
    </div> -->

    <!-- 列表 -->
    <div class="box">
      <van-list
        v-model="loading"
        :immediate-check="false"
        :finished="finished"
        :finished-text="finishedText"
        @load="getdataList"
      >
        <div
          class="box_item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goDetail(item.qaId)"
        >
          {{ item.title }}
        </div>
      </van-list>

      <noMessage type="1" v-if="isNoMessage" message="暂无数据"></noMessage>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwichTab from "@/components/SwichTab.vue";
export default {
  // 可用组件的哈希表
  components: {
    noMessage,
    SwichTab
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 分类
      tabList: [],
      tabIndex: 0,

      isGet:true,

      // 详情
      dataList: [],
      page: 1, //当前页
      size: 25, //条数
      loading: false,
      finished: false,
      finishedText: "",
      isNoMessage: false, //暂无数据

      // tableData: [],
    };
  },
  // 事件处理器
  methods: {
    changeTab(index) {
      // console.log(index)
      this.tabIndex = index;
      this.page = 1;
      this.dataList = [];
      this.finished = false
      this.loading = false
      this.getdataList();
    },
    // 查看问题详情
    goDetail(e, r) {
      this.$router.push("/mine/problemDetail?id=" + e);
    },
    // 获取分类数据
    async gettabList() {
      await this.$ajax({
        url: "/hxclass-mobile/information/problem/category/list",
        method: "get",
        // params: {
        //   // keyWord: this.name,
        //   page: 1,
        //   size: 99,
        // },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.tabList = res.data.map(item=>{
           	return {
              ...item,
				    	name:item.categoryName,
				    }
          })
          // this.total = res.data.total;
          this.getdataList(this.tabList[0].cpId);
        }
      });
    },
    // 获取数据
    getdataList(e) {
      if(!this.isGet){return}
      this.isGet = false
      this.$ajax({
        url: "/hxclass-mobile/information/problem/list",
        method: "get",
        params: {
          qaTypeId: this.tabList[this.tabIndex].cpId,
          page: this.page,
          size: this.size,
        },
      }).then((res) => {
        this.isGet = true

        if (res.code == 200 && res.success) {
          this.dataList = [...this.dataList, ...res.data.records];

          this.isNoMessage = false;
          // 加载完成
          if (this.dataList.length == res.data.total) {
            // if (this.dataList.length >= 9) {
            //   this.finished = true;
            // }
            this.finished = true;
            this.finishedText = "没有更多了";
            this.$nextTick(() => {
              // 屏幕高度
              let clientHeight = document.documentElement.clientHeight;
              // 页面高度
              let pageHeight = document.getElementsByClassName('problemList')[0].clientHeight;
              if (pageHeight > clientHeight) {
                this.finishedText = "没有更多了";
              } else {
                this.finishedText = "";
              }
              // console.log(clientHeight, pageHeight)
            })
          }

          // 无数据
          if (this.dataList.length == 0) {
            this.finished = true;
            // this.finishedText = "";
            this.isNoMessage = true;
          }
          this.page++;
          this.loading = false;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  async created() {
    // await this.gettabList();
    // this.getdataList(1);
  },
  async activated() {
    let isLoad = this.$route.params.isLoad
    if (!!isLoad) {
      this.isGet = true
      this.tabIndex = 0;
      this.page = 1;
      this.dataList = [];
      await this.gettabList();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.nomessage {
  margin-top: 36px;
}
.problemList {
  background: #f7f7f9;
  // padding-bottom: 60px;
}
// .tab {
//   padding: 11.5px 18px;
//   background-color: #fff;
//   margin-top: 1px;
//   /deep/.van-tabs--line .van-tabs__wrap {
//     height: 21px;
//   }
//   /deep/.van-tab {
//     font-size: 14px;
//     font-weight: 400;
//     color: #666666;
//   }
//   /deep/.van-tab--active {
//     font-size: 16px;
//     font-weight: bold;
//     color: #000000;
//     background-image: url(../../assets/image/examination/certificate_icon1.png);
//     background-size: 36.5px;
//     background-repeat: no-repeat;
//     background-position: bottom center;
//   }
//   /deep/.van-tabs__line {
//     display: none;
//   }
// }

// 切换框
// .tab {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   font-family: PingFangMedium;
//   background: #ffffff;
//   margin-top: 1px;
//   padding: 11.5px 18px;
//   // overflow-x: scroll;
//   .tab_item {
//     display: inline-block;
//     font-size: 14.5px;
//     font-weight: bold;
//     color: #666666;
//     &.pick {
//       font-size: 16.5px;
//       font-weight: bold;
//       color: #000000;
//       background-image: url(../../assets/image/examination/certificate_icon1.png);
//       background-size: 36.5px;
//       background-repeat: no-repeat;
//       background-position: bottom center;
//     }
//   }
// }

// 列表
.box {
  background: #ffffff;
  padding: 0 18px 12px 18px;
  .box_item {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #f5f5f5;
    padding: 16px 0 12px 0;
    &:last-child {
      border: 0;
    }
  }
}
</style>
