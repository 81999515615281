<template>
  <div class="container-box">
    <!-- :title="type == 2? examName :'考试报名'" -->
    <van-nav-bar
      class="nav-circular"
      :title="navTitle"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="goBack"
      @click-right="toRegistration"
    >
      <template #right v-if="type == 2 && isShowSignup">
        <!-- v-if="type==2 && myExamList[myExamList.length-1] && myExamList[myExamList.length-1].auditStatus==3"> -->
        <van-icon
          class-prefix="iconfont"
          name="tianxie"
          size="13"
          style="margin-right: 3px"
        />
        重新报名
      </template>
    </van-nav-bar>

    <!-- 考试指南 -->
    <examCourse :configId="configId"></examCourse>

    <div class="main-box">
      <div class="box_wrap">
        <img
          class="box_img"
          src="@/assets/image/examination/regis_bg.png"
          alt="考试安排"
          title="考试安排"
        />
        <!-- tab -->
        <div class="box_tab" v-if="isSignup == 0 && examList.length !== 0">
          <div
            class="tab_item"
            :class="{ pick: tabIndex == index }"
            v-for="(item, index) in tabList"
            :key="index"
            @click="changeTab(index)"
          >
            <span class="item_txt">{{ item }}</span>
            <img
              v-if="tabIndex == index"
              class="item_icon"
              src="@/assets/image/icon/20220218041506.png"
            />
          </div>
        </div>
        <div class="box_tab" v-if="isSignup == 0 && examList.length == 0">
          <div class="tab_item">全年</div>
        </div>

        <!-- 考试报名 -->
        <div v-if="isSignup == 0 && tabIndex == 0" class="main-box1">
          <div v-for="(item, index) in examList" :key="index" class="main-list">
            <div class="list-title">
              <div class="title-text title-left">
                {{ item.examDate }}
              </div>
              <div class="title-right" @click="showList(index)">
                <van-icon
                  :name="item.currentExam ? 'arrow-down' : 'arrow'"
                  size="17"
                />
              </div>
            </div>
            <div v-if="item.currentExam" class="list-content">
              <div
                v-for="(i, inx) in item.timeList"
                :key="inx"
                class="content-list"
              >
                <div class="content-top">
                  <img
                    class="content-pic"
                    src="@/assets/image/enroll/pic_number.png"
                  />
                  <div class="title-text content-text">{{ i.timeName }}场</div>
                </div>
                <div class="title-text content-center">
                  考试时间：{{ i.examDate }}
                </div>
                <div class="title-text content-center">
                  考试时长：{{ manageDuration(i.duration) }}
                </div>
                <div class="content-bottom">
                  <div v-if="i.signFlag == 1" class="content-btn title-text">已报名</div>
                  <div
                    v-else-if="i.signupNum >= i.totalSignupNum"
                    class="content-btn title-text"
                  >
                    已报满
                  </div>
                  <!-- :loading="verifiLoading"
                  :loading-text="verifiLoadingTxt" -->
                  <van-button
                    v-else
                    loading-size="9px"
                    class="all-btn-small"
                    @click="onEnroll(index, inx)"
                    >报名考试</van-button
                  >
                  <!-- <div
                    v-if="i.signupNum < i.totalSignupNum"
                    class="content-num title-text"
                  >
                    <img
                      class="content-img"
                      src="@/assets/image/enroll/pic_personnel.png"
                    />
                    {{ i.signupNum }}/{{ i.totalSignupNum }}
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 我的考试 -->
        <div v-if="isSignup == 1 && tabIndex == 0" class="main-box2">
          <div
            v-for="(item, index) in myExamList"
            :key="index"
            class="main-list"
          >
            <div class="list-title">
              <div class="title-text title-left">
                <img
                  class="title-img"
                  src="../../assets/image/enroll/pic_course.png"
                />
                <!-- 2021年12月05日考试 -->
                {{ item.examDate }}
                <p v-if="item.orgId">
                  <img src="@/assets/image/examination/myExam_icon3.png" />
                  机构报名
                </p>
              </div>
              <div
                class="title-right"
                @click="item.myCurrentExam = !item.myCurrentExam"
              >
                <van-icon
                  :name="item.myCurrentExam ? 'arrow-down' : 'arrow'"
                  size="17"
                />
              </div>
            </div>
            <div class="list_box" v-if="item.myCurrentExam">
              <!-- 待审核 -->
              <div
                v-if="item.auditStatus == 1 && studyTime > 0 && item.studyStatus!=3"
                class="list_remind"
                ref="listremind">
                <img
                  class="remind-img"
                  src="../../assets/image/enroll/pic_remind.png"/>
                <span class="title-text remind-text">您需在</span>
                <div class="remind-box">
                  <van-count-down :time="studyTime" format="HH:mm:ss:SS">
                    <template #default="timeData">
                      <span class="remind-time">{{ timeData.days }}天</span>
                      <span class="remind-time">{{timeData.hours > 9 ? timeData.hours : "0" + timeData.hours}}时</span>
                      <span class="remind-time">{{timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes}}分</span>
                      <span class="remind-time">{{timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds}}秒</span>
                    </template>
                  </van-count-down>
                </div>
                <span class="title-text remind-text">内完成课程学习</span>
              </div>
              <div class="list-order">
                <!-- v-if="item.isExamEnd == 1 && item.auditStatus != 3 && item.auditStatus != 1" -->
                <div
                  v-if="item.auditStatus==2"
                  class="order-result"
                >
                  <div class="title-text">
                    <div class="order-circle"></div>
                    考试结果
                  </div>
                  <!-- examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过 -->
                  <div class="order-line title-text order-text">
                    <div
                      :class="{
                        'order-pass': item.examStatus == 3,
                        'order-fail': item.examStatus == 4,
                      }"
                    >
                      <!-- {{item.examStatus == 3?'已通过':'未通过'}}
                      {{item.examStatus == 1?'未考试':'未通过'}} -->
                      {{ toolExamStatus(item.examStatus) }}
                    </div>
                  </div>
                </div>
                <div class="order-result">
                  <div class="title-text">
                    <img
                      class="order-img"
                      src="../../assets/image/enroll/pic_order.png"
                    />
                    审核状态
                  </div>
                  <!-- auditStatus  审核状态 1未审核 2已审核 3已驳回 4待完善 -->
                  <div class="order-line title-text order-text">
                    <div
                      :class="
                        item.auditStatus == 1
                          ? ''
                          : item.auditStatus == 2
                          ? 'order-pass'
                          : item.auditStatus == 3
                          ? 'order-fail' : ''
                      "
                    >
                      {{item.auditStatustText}}
                    </div>
                    <div
                      v-if="item.auditStatus == 3 && item.rejecReason !== ''"
                    >
                      驳回原因：{{ item.rejecReason }}
                    </div>
                  </div>
                </div>
                <div class="order-result">
                  <div class="title-text">
                    <div class="order-circle"></div>
                    系统审核时间
                  </div>
                  <div class="order-line title-text order-text">
                    <div>
                      <span class="order-left">
                        <!-- 2022-05-08 18:00:00 -->
                        {{ item.advanceTime }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="order-result">
                  <div class="title-text">
                    <div class="order-circle"></div>
                    报名信息
                  </div>
                  <div class="order-line order-noline title-text order-text">
                    <div>{{ item.examDate }}</div>
                    <div class="order-pass">
                      <span class="order-left"
                        >考试时间：{{ item.timeDate }}</span
                      >
                      <span class="order-right"
                        >考试时长：{{ manageDuration(item.duration) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="list-btn" ref="listbtn">
                  <!-- auditStatus  审核状态 1未审核 2已审核 3已驳回 4.待完善 -->
                  <!-- examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过 -->
                  <!-- isExamEnd 考试是否结束  1.是 0.否 -->
                  <!-- isHideTimes 是否隐藏场次按钮 1.是  0.否 -->
                  <!-- orgId 是否机构报名 有值：机构报名   空值：个人报名 -->
                  <!-- mailingMode 机构确认状态  0.个人确认 1.统一邮寄 2.机构未确认 -->
                  <span
                    class="button_item"
                    @click="toMessage(item.signupUserId, 2)"
                    v-if="item.auditStatus == 1 && item.isExamEnd==0 && !item.orgId"
                    >修改信息</span>
                  <span
                    class="button_item"
                    @click="toMessage(item.signupUserId, 3)"
                    v-if="item.auditStatus == 3 || (item.auditStatus==1 && item.isExamEnd==1) || (item.auditStatus == 2 && item.examStatus!==3)"
                    >查看报名信息</span>
                  <span
                    class="button_item"
                    @click="toChangeExam(item.signupId, item.signupUserId)"
                    v-if="item.auditStatus == 1 && item.isHideTimes !== 1 && !item.orgId"
                    >更换场次</span>
                  <span
                    class="button_item" 
                    @click="toGroupMessage(item.signupUserId,item.signupId, 2)" 
                    v-if="(item.auditStatus==1 || item.auditStatus==4) && item.isExamEnd==0 && item.orgId">{{item.auditStatus==1 ? '修改信息' : '完善信息'}}</span>
                  <span
                    class="button_item"
                    v-if="item.examStatus == 3 && item.isConfirm == 0 && !item.orgId"
                    @click="toConfirm(item.signupUserId, item.examineeId,item.configId)"
                    >确认信息</span>
                  <span
                    class="button_item"
                    v-if="item.examStatus == 3 && item.isConfirm == 0 && item.orgId && item.mailingMode == 0"
                    @click="toConfirmOk(item.signupUserId, item.examineeId)"
                    >确认信息</span>
                  <span
                    class="button_item"
                    v-if="item.examStatus == 3 && item.orgId && item.mailingMode == 2"
                    >待机构确认</span>
                  <span
                    class="button_item"
                    v-if="item.isConfirm == 1 || (item.orgId && item.mailingMode == 1)"
                    @click="toConfirmDetail(item.signupUserId)"
                    >查看证书信息</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 全年 -->
        <div class="main-box3" v-if="tabIndex == 1">
          <img :src="yearDetail" alt="全年" title="全年" />
        </div>
      </div>

      <div v-if="type == 2" class="title-box">
        <div class="title-border"></div>
        报名须知
      </div>

      <div v-if="type == 2" class="tips-box">
        <span v-html="appExamDesc"></span>
        <!-- <span>①考试为远程在线考试。<br>
          ②考试网址：https://test.hxclass.cn。<br>
          ③输入报名时填写的身份证号和手机号即可登录考试系统，如登录失败，请到“账号与资料”中查看。<br>
          ④请在正式考试开始前30分钟，进入网站根据系统提示进行考生身份确认。<br>
          ⑤考生需自备考试中所需要的用到的电脑（带摄像头的电脑、平板均可），建议屏幕分辨率为 800*600以上。<br>
          ⑥考试中系统将随机收集图像和声音，严禁翻阅资料、截屏考试题目、替考行为，一经发现将取消 考试成绩和补考资格。<br>
          ⑦考试期间不可打开其他网站，退出当前页面考试即中止，自动提交试卷。<br>
          1、审核之前可更换考试场次，审核通过后将无法更换。<br>
          2、请您务必在前学完在线视频课程，否则考试申请将被驳回。<br>
          3、已审核通过的考生无法是否参与考试，均算是使用了一次考试次数。<br>
          4、如有疑问，请拨打010-64672273（工作日9~16点），或18511031681（微信同号）</span> -->
      </div>

      <!-- 确认补考弹窗 -->
      <van-popup v-model="confirmPopup" :round="true">
        <div class="popup-content">
          <div class="popup-box">
            <div class="title title-noimg">{{ confirmTxt }}</div>
          </div>
          <div class="btn-inner">
            <div class="div">
              <span class="all-btn-middle" @click="goPay()">确认</span>
            </div>
            <div class="div">
              <span class="all-btn-middle" @click="confirmPopup = false"
                >取消</span
              >
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 报名须知弹窗 -->
      <van-popup v-model="notice" :round="true" :close-on-click-overlay="false">
        <div class="popup-content">
          <div class="popup-box">
            <div class="title title-noimg">《{{ examName }}》</div>
            <div class="title title-noimg">报名须知</div>
          </div>
          <div class="popup-tips">
            <!-- <div class="font">1.《孤独症康复教育上岗培训课程》在读学员均可报名</div>
            <div class="font">2.考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请</div>
            <div class="font">3.2020线下半年考试将以远程考试的形式进行</div>
            <div class="font">4.考生可根据需要自行选择考试场次和时间</div>
            <div class="font">5.报考审核通过后未按要求参加考试，按弃考处理，考试成绩为零分</div> -->
            <div v-html="appSignupDesc"></div>
          </div>
          <div class="btn-innertips">
            <div class="div" @click="onRead()">
              <span class="all-btn-middle">{{
                secondNum > 0 ? "我已阅读（" + secondNum + "s）" : "我已阅读"
              }}</span>
            </div>
          </div>
          <van-icon
            name="close"
            @click="closeRead()"
            size="38"
            class="close-icon"
            color="#fff"
          />
        </div>
      </van-popup>

      <!-- 提示信息 -->
      <van-popup v-model="showPopup">
        <div class="box_popup">
          <div class="popup_title">
            <van-icon name="warning-o" color="#EB3C31" size="26" />
            <span class="title_con">提示信息</span>
          </div>
          <div class="popup_con">{{ popupTxt }}</div>
          <div class="popup_button middle" v-if="verifiType==1">
            <span class="all-btn-middle btn2" @click="showPopup = false">知道了</span>
          </div>
          <div class="popup_button" v-if="verifiType==2">
            <span class="all-btn-middle btn1" @click="showPopup=false">取消</span>
            <span class="all-btn-middle btn2" @click="goBuyClass()">去购买</span>
          </div>
          <div class="popup_button" v-if="verifiType==3">
            <span class="all-btn-middle btn1" @click="showPopup=false">取消</span>
            <span class="all-btn-middle btn2" @click="goIdent()">去认证</span>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import examCourse from "@/components/examination/examCourse.vue";
import { onGoStudy,onGoMyExam,jumpToShopDetail, onRemoveStorage } from '@/assets/js/NativeWebKit.js'
export default {
  components: {
    examCourse,
  },
  data() {
    return {
      type: 1, //1首页进入 2课程进入
      isSignup: 1, //是否已报名
      isShowSignup: false, //是否显示重新报名
      isMake: true, //是否允许补考
      navTitle: '', //导航栏名称

      configId: 0, //考试id
      courseId: 0, //课程id
      examName: "", //考试名称
      signupId: 0, //考试报名id
      timeId: 0, //场次id

      // studyTime: (11*24+20) * 60 * 60 * 1000, //倒计时
      // studyTime: (11*24+20) * (60) * 60 * 1000, //倒计时
      studyTime: 1022300 * 1000, //倒计时
      timer: null, //设置定时器
      // localNum: 0, //0:考试报名页面，1:我的考试页面
      examNum: 2, //考试次数 0:不是补考，不为0:补考
      secondNum: 3, //设置倒计时
      confirmPopup: false, //补考弹框
      confirmTxt: "", //补考提示语
      notice: false,

      // tab
      tabList: ["最新", "全年"],
      tabIndex: 0,
      yearDetail: "", //全年
      // 可报名列表
      examList: [],
      myExamList: [],
      appExamDesc: "", //考试须知
      appSignupDesc: "", //报名须知
      // 审核时间
      auditTime: "",

      // 提示信息
      showPopup: false,
      popupTxt: "",
      // 校验状态
      verifiType: 1, //1接口校验未通过 2未购买 3未认证
      verifiLoading: false, //校验loading
      verifiLoadingTxt: '', //loading文字
    };
  },
  async created() {
    this.configId = this.$route.query.id; //课程id
    // this.examName = this.$route.query.name //考试名称

    // 首页进入-添加校验
    this.type = this.$route.query.type; //1首页进入 2课程进入
    // this.isSignup = this.$route.query.isSignup || 0 //是否已报名

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'; // 改为manual之后，就不会记录滚动位置
    }

    // 补考支付跳转查看报名信息
    if (sessionStorage.getItem('payMakeObj')) {
      let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
      // console.log(payMakeObj, payMakeObj.pay)
      // id相同、支付成功
      if (!!payMakeObj.pay) {
        // 删除支付成功标识
        sessionStorage.removeItem('payMakeObj');
        // onRemoveStorage('payMakeObj')
        // 查看报名信息
        // this.toMessage(payMakeObj.signupUserId, 3);
        // return;
      } else {
        // 删除电子照支付标识
        sessionStorage.removeItem('payMakeObj');
        // onRemoveStorage('payMakeObj')
      }
    }

    // 首页进入
    if (this.type == 1) {
      // 报名列表
      await this.getExamList();
      // 默认无报名
      this.isSignup = 0;
    }
    if (this.type == 2) {
      // 考试列表
      await this.getSignupList();
    }

    this.$nextTick(() => {
      // 监听页面返回
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.goBack, false);
      }
    })
  },
  destroyed() {
    // 离开页面去掉监听
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    // 返回
    goBack() {
      if(this.$route.query.pay){
        onGoStudy()
      } else {
        this.$onReturn(this.$route.query.fromMenu == '1');
      }
    },
    // 考试列表
    async getExamList() {
      this.navTitle = '考试报名'; //导航栏标题
      let userId = this.$store.state.userInfo.isLogin
        ? this.$store.state.userInfo.userId
        : "";
      await this.$ajax({
        url:
          "/hxclass-mobile/exam/signup/time/list?configId=" +
          this.configId +
          "&userId=" +
          userId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examList = res.data;
          // 添加展开收起标志
          this.examList.map((item, index) => {
            let examList = this.examList[index];
            this.$set(examList, "currentExam", false);
          });
          if (this.examList.length !== 0) {
            this.$set(this.examList[0], "currentExam", true);
          }
          // 全年
          this.getYearDetail();
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 获取该课程的报名信息
    getSignupList() {
      let userId = this.$store.state.userInfo.isLogin
        ? this.$store.state.userInfo.userId
        : "";
      this.$ajax({
        url:
          "/hxclass-mobile/exam/user/signup-info?userId=" +
          userId +
          "&configId=" +
          this.configId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 无报名信息
          if (res.data.length == 0) {
            this.isSignup = 0;
            // 报名列表
            this.getExamList();
            return;
          }
          this.navTitle = '我的考试'; //导航栏标题
          // 有报名信息
          this.isSignup = 1;
          // 获取考试名称
          this.getYearDetail();

          this.isShowSignup = true; //是否展示重新报名按钮
          this.myExamList = res.data;
          this.myExamList.map((item, index) => {
            // 添加打开状态
            let myExamList = this.myExamList[index];
            this.$set(myExamList, "myCurrentExam", false);
            this.$set(this.myExamList[0], "myCurrentExam", true); //展开第一条
            // auditStatus  审核状态 1未审核 2已审核 3已驳回 4待完善
            // examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过
            // isExamEnd 考试是否结束  1.是 0.否
            // isHideTimes 是否隐藏场次按钮 1.是  0.否
            // 判断是否展示重新报名按钮
            // 未审核
            if (item.auditStatus == 1 && item.isExamEnd == 0) {
              this.isShowSignup = false;
            }
            // 已审核 && (未考试||考试中||已通过)
            else if (item.auditStatus == 2 && ((item.examStatus == 1 && item.isExamEnd !== 1) || item.examStatus == 2 || item.examStatus == 3)
            ) {
              this.isShowSignup = false;
            }
            // 待完善状态
            if (item.auditStatus == 4) {
              this.isShowSignup = false;
            }
          });
          // 未学完课程-倒计时
          this.manageCountDown();
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 展开收起
    showList(index) {
      let currentExam = this.examList[index].currentExam;
      this.$set(this.examList[index], "currentExam", !currentExam);
    },
    // 全年详情
    async getYearDetail() {
      await this.$ajax({
        url: "/hxclass-mobile/exam/exam-config-info?configId=" + this.configId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.yearDetail = res.data.yearlyPlanPicture; //全年
          this.appExamDesc = res.data.appExamDesc; //考试须知
          this.appSignupDesc = res.data.appSignupDesc; //报名须知
          this.courseId = res.data.courseId //课程id
          this.examName = res.data.name; //考试名称
          // 报名列表为空 无报名信息
          if (this.examList == "" && this.isSignup == 0) {
            this.tabIndex = 1;
          }
        }
      });
    },
    // 未学完课程-倒计时
    manageCountDown() {
      let countDown = this.myExamList[0].surplusStudyTime.split(" ");
      countDown = countDown.map((item) => {
        return (item = parseInt(item));
      });
      let day = countDown[0] * 24 * 60 * 60 * 1000;
      let hours = countDown[1] * 60 * 60 * 1000;
      let mouth = countDown[2] * 60 * 1000;
      let second = countDown[3] * 1000;
      this.studyTime = day + hours + mouth + second;
    },
    // 考试时长处理
    manageDuration(con) {
      if (!con) {
        return ''
      }
      let time = "";
      let hour = con.split(":")[0];
      let minute = con.split(":")[1];
      let second = con.split(":")[2];
      if (hour !== "00") {
        if (hour[0] == "0") {
          time += hour.substring(1, hour.length) + "小时";
        } else {
          time += hour + "小时";
        }
      }
      if (minute !== "00") {
        if (minute[0] == "0") {
          time += minute.substring(1, minute.length) + "分钟";
        } else {
          time += minute + "分钟";
        }
        // time += minute+'分钟'
      }
      if (second !== "00") {
        time += second + "秒";
      }
      return time;
    },
    // tab切换
    changeTab(index) {
      this.tabIndex = index;
    },
    // 修改个人-报名信息、查看报名信息
    toMessage(signupUserId, type) {
      this.$router.push({
        path: "/examEnroll/enrollMessage",
        query: {
          type: type, //1报名 2修改 3详情
          signupUserId: signupUserId,
        },
      });
    },
    // 机构-完善、查看、修改报名信息
    toGroupMessage(signupUserId,signupId, type) {
      this.$router.push({
        path: "/examEnroll/enrollMessageGroup",
        query: {
          type: type, //2修改/完善  3详情
          signupUserId: signupUserId,
          signupId: signupId,
          configId: this.configId,
        },
      });
    },
    // 确认信息
    toConfirm(signupUserId, examineeId,configId) {
      this.$router.push({
        path: "/myStudy/questionnaire",
        query: {
          signupUserId: signupUserId,
          examineeId: examineeId,
          id: configId
        },
      });
    },
    // 机构个人确认信息
    toConfirmOk(signupUserId, examineeId) {
      this.$router.push({
        path: "/examEnroll/signUpConfirmOk",
        query: {
          signupUserId: signupUserId,
          examineeId: examineeId,
        },
      });
    },
    // 已确认消息
    toConfirmDetail(signupUserId, examineeId) {
      this.$router.push({
        path: "/examEnroll/signUpConfirmDetail",
        query: {
          signupUserId: signupUserId,
          // examineeId: examineeId,
        },
      });
    },
    // 更换考试场次
    toChangeExam(signupId, signupUserId) {
      this.$router.push({
        path: "/examEnroll/changeExam",
        query: { signupId: signupId, signupUserId: signupUserId },
      });
    },
    // 重新报名
    async toRegistration() {
      this.type = 1;
      // 报名列表
      await this.getExamList();
      // 默认无报名
      this.isSignup = 0;
    },
    // 倒计时
    onNotice() {
      if (this.timer != null) {
        return
      }
      (this.notice = true),
        (this.timer = setInterval(() => {
          this.secondNum--;
          if (this.secondNum <= 0) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000));
    },
    // 关闭报名须知弹框
    closeRead() {
      // 关闭倒计时弹框
      this.notice = false;
      let timer = setTimeout(() => {
        // 倒计时重置
        this.onReset();
        clearTimeout(timer);
        this.timer = null;
      }, 400);
    },
    // 倒计时重置
    onReset(e) {
      // if (!e.show) {
      clearInterval(this.timer);
      this.timer = null;
      this.secondNum = 3;
      // }
    },
    // 我已阅读
    onRead() {
      if (this.secondNum > 0) {
        return;
      } else {
        (this.confirmPopup = false),
          (this.notice = false),
          this.$router.push({
            path: "/examEnroll/enrollMessage",
            query: {
              configId: this.configId,
              signupId: this.signupId,
              timeId: this.timeId,
              auditTime: this.auditTime, //审核时间
            },
          });
      }
    },
    // 报名考试
    onEnroll(index, inx) {
      if (!!this.verifiLoading) {
        return
      }
      // 校验loading打开
      this.verifiLoading = true
      this.verifiLoadingTxt = '报名考试'
      // 未登录
      let isLogin = this.$store.state.userInfo.isLogin;
      if (!isLogin) {
        this.$router.push('/login/login');
      }

      let timeItem = this.examList[index].timeList[inx];
      this.auditTime = this.examList[index].auditTime; //审核时间
      // console.log(timeItem);
      this.signupId = this.examList[index].signupId;
      this.timeId = timeItem.timeId; //报名id
      let userId = this.$store.state.userInfo.userId; //用户id
      this.isMake = this.examList[index].timeList[inx].isMake; //1.允许补考 0.不允许补考

      // 报名前验证
      this.$ajax({
        url:
          "/hxclass-mobile/exam/signup/list/verifi?signupId=" +
          this.signupId +
          "&timeId=" +
          this.timeId +
          "&userId=" +
          userId,
        method: "get",
      }).then((res) => {
        // console.log(res);
        // 校验loading关闭
        this.verifiLoading = false
        this.verifiLoadingTxt = ''
        if (res.code == 200 && res.success) {
          // 报名须知
          this.notice = true;
          // 倒计时
          this.onNotice();
        }
        // 校验未通过
        else if (res.code == 500) {
          // let certified = this.$store.state.userInfo.certified //是否认证
          // 未买课
          if (res.message.indexOf('仅限') > -1) {
            // this.courseId = '' //课程id
            this.verifiType = 2
            this.showPopup = true;
            this.popupTxt = res.message;
            return
          }
          // 未认证
          // if (!certified) {
          if (res.message.indexOf('认证') > -1) {
            this.verifiType = 3
            this.showPopup = true;
            this.popupTxt = '请先去身份认证';
            return
          }
          // 不允许补考
          if (res.message.indexOf('不允许补考') > -1) {
            this.verifiType = 1
            this.showPopup = true;
            this.popupTxt = res.message;
            return
          }
          // 补考 是否收费 1是 2否（补考校验挪到报名提交页）
          // if (timeItem.isFees == 1 || res.message.indexOf("补考") > -1) {
          if (res.message.indexOf("补考") > -1) {
            // this.confirmPopup = true;
            // this.confirmTxt = res.message;
            // 报名须知
            this.notice = true;
            // 倒计时
            this.onNotice();
            return;
          }
          this.verifiType = 1
          this.showPopup = true;
          this.popupTxt = res.message;
        }
      });
    },
    // 去认证
    goIdent() {
      this.showPopup = false;
      this.$router.push({
        path:'/mine/identityAuthentication',
        query:{url:'/examination/examSignList'}
      });
    },
    // 去买课
    goBuyClass() {
      jumpToShopDetail(1,this.courseId)
      // this.$router.push({
      //   path:'/curriculum/course',
      //   query:{'id': this.courseId}
      // });
    },
    // 支付补考费用
    goPay() {
      // isMake 1.允许补考 0.不允许补考
      if (this.isMake === 0) {
        this.confirmPopup = false;
        return
      }
      // payReturnsign 1跳回考试报名页 2跳到首页
      localStorage.setItem('payReturnsign', 1)
      let productList = [
        {
          count: 1, //数量
          noGift: 0, //是否为赠品 0否1是
          productId: this.configId, //商品id-考试id
          type: 7, //商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        },
      ];
      // console.log(productList);
      // return
      this.$store.commit("updatelist", productList); //订单列表
      this.$store.commit("updateType", 3); // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
      this.$store.commit("updateInvoiceId", null); //发票id
      this.$store.commit('updateShortInvoiceId',null);
      this.$store.commit("updateAddress", null); //收货地址id
      this.$store.commit("updateCouponList", null);
      this.$router.push("/order/confirmOrder");
    },
    // 考试状态
    toolExamStatus(type) {
      // examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过
      if (type == 1) {
        return "未考试";
      } else if (type == 2) {
        return "成绩待更新";
      } else if (type == 3) {
        return "已通过";
      } else if (type == 4) {
        return "未通过";
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-popup {
  background-color: transparent;
}
.container-box {
  // overflow: hidden;
  .nav-circular {
    position: relative;
    z-index: 2;
  }
  .banner-box {
    position: relative;
    padding: 0 20px;
    margin: 16.5px auto;
    width: calc(100% - 72px);
    background-color: #15b7dd;
    border-radius: 5px;

    .banner-img {
      width: 100%;
      // height: 118.5px;
    }
    .banner-title {
      position: absolute;
      bottom: 20px;
      left: 20px;
      padding: 2px 6px;
      height: 20px;
      background: #feffff;
      border-radius: 2px;
      font-size: 11px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #1e97b4;
      text-align: center;
      line-height: 20px;
    }
  }

  .title-box {
    margin: 16.5px 18px 0;
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 24.5px;

    .title-border {
      display: flex;
      display: inline-block;
      margin-right: 4px;
      width: 3px;
      height: 13px;
      border-radius: 4px;
      background-color: #15b7dd;
    }
  }

  .main-box {
    box-sizing: border-box;
    padding: 0 0 22px;
    width: 100%;
    margin-top: 18px;
    .box_wrap {
      margin: 0 18px;
      border: 1px solid #abeaf9;
      border-radius: 9px 9px 0 0;
    }
    .box_tab {
      display: flex;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      margin-top: 12px;
      padding: 0 19px;
      .tab_item {
        position: relative;
        margin-right: 29px;
        &.pick {
          color: #15b7dd;
        }
        .item_icon {
          position: absolute;
          right: -6px;
          bottom: -3px;
          width: 8px;
        }
      }
    }
    .box_img {
      width: 100%;
      border-radius: 9px 9px 0 0;
    }
    .title-text {
      font-size: 14.5px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    .main-list {
      margin-top: 10px;
      padding: 0 18px;
      background: #ffffff;
      border-radius: 0;
      overflow: hidden;

      .list-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        .title-left {
          line-height: 40px;
          display: flex;
          align-items: center;

          .title-img {
            margin-right: 5.5px;
            width: 14.5px;
            height: 14.5px;
          }
          p{
            display: inline-block;
            margin-left: 8px;
            height: 16px;
            line-height: 16px;
            background: #D6F7FF;
            font-size: 10px;
            color: #025B70;
            padding-right: 3px;
            border-radius: 10px 6px 6px 10px;
            img{
              vertical-align:middle;
              margin-top:-1px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .main-box1 {
      .list-content {
        .content-list {
          overflow: hidden;
          border-top: 1px solid #eeeeee;
          padding-top: 12.5px;

          .content-top {
            margin-bottom: 12.5px;

            .content-pic {
              display: inline-block;
              margin-right: 5.5px;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }

            .content-text {
              display: inline-block;
            }
          }

          .content-center {
            margin-bottom: 2px;
            font-weight: 400;
          }

          .content-bottom {
            display: flex;
            align-items: center;
            margin: 9px 0 12.5px;
            .all-btn-small {
              width: auto;
              min-width: 80px;
            }
            .van-loading__circular circle {
              stroke-width: 8px;
            }
            .van-button__content {
              flex-flow: row-reverse;
            }
            .van-button__loading {
              margin-left: 5px;
            }
            .van-button__text {
              margin-left: 0;
            }

            .content-btn {
              width: 67px;
              height: 25.5px;
              background-color: #e5e5e5;
              border-radius: 1.5px 1.5px 5px 1.5px;
              font-size: 12.5px;
              font-weight: 500;
              text-align: center;
              line-height: 25.5px;
              border-radius: 14px;
            }

            .content-num {
              margin-left: 12.5px;
              font-size: 12.5px;
              color: #000000;
              line-height: 19px;

              .content-img {
                margin-right: 2px;
                width: 15.5px;
                height: 15.5px;
                vertical-align: middle;
              }
            }
          }
        }

        .content-list:nth-child(1) {
          padding: 0;
          border: none;
        }
      }
    }

    .main-box2 {
      .list_remind {
        .remind-img {
          margin-right: 3.5px;
          width: 13.5px;
          height: 13.5px;
          vertical-align: middle;
        }

        .remind-text {
          font-size: 12.5px;
          font-weight: 500;
          line-height: 19px;
        }

        .remind-time {
          display: inline-block;
          margin-right: 6px;
          width: 41px;
          height: 19px;
          border-radius: 15.5px;
          border: 1px solid #15b7dd;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #15b7dd;
          text-align: center;
          line-height: 19px;
        }

        .remind-box {
          display: inline-block;

          .remind-time:nth-child(1) {
            margin-left: 7px;
          }
        }
      }
      .list_box {
        position: relative;
      }
      .list-order {
        position: relative;
        margin-top: 14px;
        .order-result {
          // margin-top: 7.5px;

          // .order-circle::before {
          // 	content: '';
          // 	display: inline-block;
          // 	margin-right: 7px;
          // 	width: 11px;
          // 	height: 11px;
          // 	border: 1px solid #15B7DD;
          // 	border-radius: 50%;
          // }

          .order-circle {
            display: inline-block;
            margin-right: 7px;
            width: 11px;
            height: 11px;
            border: 1px solid #15b7dd;
            border-radius: 50%;
            position: relative;
            top: 1px;
          }

          .order-img {
            margin-right: 7px;
            width: 13.5px;
            height: 13.5px;
          }

          .order-line {
            margin-left: 6px;
            margin-top: 7.5px;
            margin-bottom: 7.5px;
            padding-left: 18px;
            min-height: 39px;
            border-left: 1px solid #c4c4c4;
          }

          .order-noline {
            border-left: none;
          }

          .order-left {
            margin-right: 12.5px;
          }

          .order-text {
            font-size: 12.5px;
            font-weight: 400;
            line-height: 19px;
          }

          .order-pass {
            font-weight: 400;
            color: #15b7dd;
          }

          .order-fail {
            color: #ea3c31;
          }
        }
      }

      .list-btn {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 18px;
        .button_item {
          position: relative;
          display: inline-block;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #15B7DD;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 22px;
          padding: 0 7px;
          margin-right: 18px;
          &::after {
            position: absolute;
            top: 4px;
            right: -10px;
            content: '';
            width: 1px;
            height: 14px;
            line-height: 22px;
            background: rgba(21,183,221,0.65);
          }
          &:nth-last-child(1) {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .main-box3 {
      img {
        width: 100%;
        margin-top: 14px;
      }
    }
  }

  .tips-box {
    margin: 12.5px 18px 21.5px;
    // font-size: 11px;
    // font-family: PingFangMedium;
    // font-weight: 500;
    // color: #ea3c31;
    // line-height: 16.5px;
    /deep/strong {
      font-weight: 500;
    }
    /deep/em {
      font-style: italic;
    }
    /deep/img {
      max-width: 100%;
    }
    /deep/.remarks {
      max-width: 100%;
      height: auto !important;
      -webkit-line-clamp: 10000 !important;
    }
  }

  /* 遮罩内容 */

  /deep/.van-popup {
    overflow-y: initial;
  }

  .popup-content {
    background: #ffffff;
    width: 300.5px;
    border-radius: 10px;
    padding: 18px 15px 0;
    position: relative;

    .close-icon {
      position: absolute;
      right: 150px;
      // top: 450px;
      bottom: -80px;
    }

    .popup-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin: 0 auto;
        // margin-top: 11px;
        font-size: 20px;
        font-family: PingFangMedium;
        // font-weight: 500;
        color: #333333;
        line-height: 30px;
      }

      .title-noimg {
        font-size: 16px;
        line-height: 30px;
      }
    }

    .popup-tips {
      margin-top: 16.5px;
      max-height: 280px;
      overflow-y: auto;
      .font {
        margin-top: 16.5px;
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 21.5px;
      }
    }

    .btn-inner {
      display: flex;
      justify-content: space-around;
      border-radius: 32px;

      .div {
        display: inline-block;
        border-radius: 32px;
        padding: 23.5px 10px;
        background: #fff;

        .all-btn-middle {
          width: 110.5px;
          height: 35.5px;
          font-size: 14px;
          line-height: 35.5px;
        }
      }
    }

    .btn-innertips {
      border-radius: 32px;
      text-align: center;

      .div {
        display: inline-block;
        position: relative;
        top: 25px;
        border-radius: 32px;
        padding: 8px;
        background: #fff;

        .all-btn-middle {
          width: 187px;
          height: 35.5px;
          line-height: 35.5px;
        }
      }
    }
  }

  // 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
      line-height: 25px;
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #eeeeee;
        box-shadow: none;
      }
    }
  }
}
</style>
