// 原生返回方法/返回并刷新方法
import { FunClosePage } from '@/assets/js/NativeWebKit.js';
// import store from './../store'

export function onReturn(v){
  // 当需要返回原生页面时，用原生方法
  if(v){
    FunClosePage()
  } else {
    this.$router.go(-1)
  }
}