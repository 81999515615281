<template>
  <div id="window">
    <van-nav-bar
      title="发票列表"
      right-text="发票中心"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="$router.push('/invoice/InvoiceCenter')"
    />
    <div id="content">
      <div class="item" v-for="item in invoiceList" :key="item.invoiceRecordId">
        <div class="content">
          <div class="title">
            <span class="color" v-if="item.invoiceStatus==0">未开票</span>
            <span class="color" v-else>{{item.isChange == 1 ? invoiceTypeChange[item.invoiceStatus] : invoiceTypeNoChange[item.invoiceStatus]}}</span>
            <div>
              <span>开票金额：</span>
              <span class="money">¥{{ item.price.toFixed(2) }}</span>
            </div>
          </div>
          <!-- 图片 -->
          <div class="images">
            <img class="img" :class="{'imgOne' : item.productList.length == 1}" v-for="(subItem,index) in item.productList" v-show="index<2 && subItem.productPicture" :src="subItem.productPicture" :key="subItem.id + '-' + item.invoiceRecordId"/>
                    <div class="name" v-show="item.productList.length==1" v-for="(subItem,index) in item.productList" :key="index"><span>{{subItem.productName}}</span></div>
            <!-- <img
              class="img"
              v-for="subItem in item.productList"
              :key="subItem.id + 'subID'"
              :src="subItem.productPicture"
            /> -->
          </div>
          <div class="operations">
            <div class="left"></div>
            <div class="right">
              <a @click="goChange(item)" v-show="item.invoiceStatus == 2 && item.isChange == 0">
                <div class="btn">申请换开</div>
              </a>
              <a @click="goApply(item)" v-show="item.invoiceStatus == 0">
                <div class="btn">申请开票</div>
              </a>
              <a @click="goDetail(item)" v-show="item.invoiceStatus != 0">
                <div class="btn">发票详情</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- 运费 -->
      <div class="item" v-if="freightPrice">
        <div class="content">
          <div class="title">
            <span class="color">未开票</span>
            <div>
              <span>开票金额：</span>
              <span class="money">¥{{ freightPrice.toFixed(2) }}</span>
            </div>
          </div>
          <!-- 图片 -->
          <div class="images">
            <div class="name"><span>其他服务</span></div>
          </div>
          <div class="operations">
            <div class="left"></div>
            <div class="right">
              <a @click="goApply()">
                <div class="btn">申请开票</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      invoiceList: [],
      invoiceTypeChange: ["未开票", "换开中", "已换开", "已作废"],   // ischange 1
      invoiceTypeNoChange: ["未开票", "开票中", "已开票", "已作废"],  // ischange 0
      freightPrice: null, // 运费
    };
  },
  // 事件处理器
  methods: {
    // 获取发票列表
    getInvoiceList() {
      this.$ajax({
        url: "/hxclass-mobile/invoice/order-invoice/list",
        params: {
          orderId: this.$route.query.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceList = res.data;
        }
      });
    },
    // 跳转详情
    goDetail(e) {
      if (e.invoiceStatus == 1) {
        // 开票中
        this.$Dialog.alert({
          title: "提示信息",
          message: e.isChange == 1 ?  "发票正在换开中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。" : "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。",
        });
      } else if (e.invoiceStatus == 2) {
        this.$router.push({
          path: "/invoice/details?invoiceRecordId=" + e.invoiceRecordId,
        });
      }
    },
    // 申请换开
    goChange(e) {
      this.$router.push({
        // path: "/invoice/Change?invoiceRecordIds=" + e.invoiceRecordId,
        path: "/invoice/replaceVoice?invoiceRecordIds=" + e.invoiceRecordId, // 改版
      });

      // if (e.isChange) {
      //   // 如果已在换开中
      //   this.$Dialog.alert({
      //     title: "提示信息",
      //     message: "发票正在换开中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。",
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/invoice/Change?invoiceRecordIds=" + e.invoiceRecordId,
      //   });
      // }
    },
    // 申请开票
    goApply() {
      return this.$Toast("请联系工作人员线下开具发票，联系电话：400-800-9002转3。");
      this.$ajax({
        url: "/hxclass-mobile/invoice/apply/" + this.$route.query.orderId,
        }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$router.push({
            path: "/invoice/apply?orderId=" + this.$route.query.orderId,
          });
        } else {
          this.$Dialog.alert({
            title: "提示信息",
            message: "请先确认收货后再申请发票",
          });
        }
      });
    },

    // 获取商品详情列表
    getInvoiceDetail() {
      this.$ajax({
        url: "/hxclass-mobile/invoice/apply/" + this.$route.query.orderId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.freightPrice) {
            this.freightPrice = res.data.freightPrice;
          }
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getInvoiceDetail();
    this.getInvoiceList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/invoice.less";

.item {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 18px;
  padding: 0 18px;
  .checkbox {
    padding: 0 9px 0 10px;
    .icon {
      height: 20px;
    }
  }
  .content {
    padding: 8px 0 16px 0;
    flex: 1;
    overflow: hidden;
    .title {
      font-size: 12px;
      color: #666;
      line-height: 28px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .color {
        color: @theme;
      }
      .money {
        font-family: PingFangMedium;
        font-size: 12px;
        color: #EC6C01;
      }
    }
    .commodity {
      display: flex;
      flex-direction: row;
      align-items: center;
      .image {
        .img {
          width: 125px;
          border-radius: 8.5px;
        }
      }
      .data {
        margin-left: 5px;
        .name {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .num {
          font-weight: 400;
          color: #999;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 12px;
          line-height: 17px;
        }
      }
    }
    .invoice {
      margin-top: 16px;
      background-color: #eee;
      border-radius: 8px;
      padding: 12px;
      .title {
        font-weight: 500;
        font-size: 14px;
        color: #333;
      }
      .tips {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 12px;
        .money {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
}
.images{
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .img{
    width: calc((100% - 10px) / 2);
    height: 88px;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 7px;
    margin-right: 12px;

  }
  .imgOne{
    width: 100px;
    height: 70px;
  }
  .name{
    flex: 1;
    span{
      margin-top: 5px;
      display: inline-block;
      white-space: normal;
      max-width: 90%;
      line-height: 16px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;  //使用自适应布局
      -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
      -webkit-box-orient: vertical;
    }
  }
}
.operations {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .right {
    display: flex;
    flex-direction: row;
    .btn {
      width: 72px;
      height: 29px;
      border-radius: 16px;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #333;
      font-weight: 400;
      margin-left: 10px;
    }
  }
}
</style>
