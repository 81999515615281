<template>
  <!-- 学分说明 -->
  <div class="explain-box">
    <van-nav-bar
			title="学分说明"
			left-arrow
			:fixed="true"
			:placeholder="true"
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>
    <!-- 文字介绍 -->
    <div class="content-txt">
      <div class="item-box">
        <p>什么是华夏云课堂学习积分？</p>
        <span>华夏云课堂学习积分是华夏云课堂的注册用户，通过在华夏云课堂APP参与打卡、学习（后期开放，敬请期待），或者参与其它华夏云课堂互动活动等获得的积分奖励。华夏云课堂学习积分仅供华夏云课堂用户使用，可用于指定页面的“积分商城”（后期开放，敬请期待）兑换及参与各种学习积分活动权益（后期开放，敬请期待）兑换。</span>
        <span>华夏云课堂学习积分不可转让，赠送，售卖及提现（即兑换为人民币或其他货币)。</span>
      </div>
      <div class="item-box">
        <p>谁能获得华夏云课堂学习积分？</p>
        <span>所有的华夏云课堂注册用户。</span>
      </div>
      <div class="item-box">
        <p>如何获得华夏云课堂学习积分？</p>
        <span>一、参与华夏云课堂学习积分打卡</span>
        <span>用户登录华夏云课堂学习APP进行签到操作，按照用户当日打卡情况，可获得对应的学习积分奖励（具体以华夏云课堂指定页面打卡规则为准)。</span>
        <span style="margin-top:8px">二、参与华夏云课堂任务（后期开放，敬请期待）</span>
        <span>用户参与华夏云课堂举办的学习积分相关任务，如观看视频、发布评价、阅读文章等，可以根据任务规则获得相应学习积分奖励（具体以华夏云课堂指定页面展示规则为准。）</span>
        <span>华夏云课堂可能会不时调整华夏云课堂学习积分的获得方式（例如可获得学习积分的任务，某一个可以获取学习积分的条件与数量等），具体应以华夏云课堂相关页面提示为准。</span>
      </div>
      <div class="item-box">
        <p>最终解释权归北京华夏元道文化科技有限公司所有。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content-txt{
  padding: 18px;
  .item-box{
    margin-bottom: 14px;
  }
  p{
    margin-bottom: 3px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 21px;
  }
  span{
    display: block;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}
</style>
