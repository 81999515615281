<template>
  <div id="window">
    <van-nav-bar
      :fixed="true"
      :placeholder="true"
      title="申请发票"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div id="content">
      <div class="box">
        <div class="title">已选择{{invoiceRecordIds.length}}个订单</div>
        <!-- 抬头信息 -->
        <div class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">抬头信息</div>
          </div>
          <div class="right">
            <router-link to="/invoice/InvoiceHeader" tag="div" class="btn">
              <van-icon name="plus" />
              <span>添加抬头</span>
            </router-link>
          </div>
        </div>
        <!-- 抬头列表 -->
        <div class="riseList">
          <div
            class="item"
            :class="zIndex == index ? 'click' : ''"
            @click="zIndex = index"
            v-for="(item, index) in invoiceData"
            :key="item.userInvoiceId"
          >
            <div class="label">
              {{ item.headerType == 1 ? "企业" : "个人" }}
            </div>
            <div class="name">{{ item.invoiceHeader }}</div>
            <div class="mailbox">
              <span>{{ item.dutyParagraph }}</span>
            </div>
            <div class="mailbox" style="margin-top: 5px">
              <span>{{ item.email }}</span>
            </div>
          </div>
          <noMessage
            class="nomessage"
            v-if="!invoiceData.length"
            type="5"
          ></noMessage>
        </div>
      </div>
      <van-popup :round="true" v-model="onPopup">
        <div class="box_popup">
          <div class="popup_title">
            <van-icon name="warning-o" color="#EB3C31" size="26" />
            <span class="title_con">提示信息</span>
          </div>
          <div class="popup_con">
            发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。
          </div>
          <div class="popup_button">
            <span class="all-btn-middle btn2" @click="$router.back()"
              >我知道了</span
            >
          </div>
        </div>
      </van-popup>
      <!-- 底部操作栏 -->
      <div class="btnSave">
        <div class="all-btn-bigCircle" @click="onSave()">提交</div>
      </div>
      <div class="heiSave"></div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      onPopup: false,
      zIndex: null,
      invoiceData: [],
      invoiceRecordIds: [],
      isSubmit:false
    };
  },
  // 事件处理器
  methods: {
    // 申请
    onSave() {
      if (this.zIndex == null) {
        this.$Toast("请选择发票抬头");
        return;
      }

      if(this.isSubmit){
        return
      }
      this.isSubmit = true

      let obj = this.invoiceData[this.zIndex]

      obj.invoiceRecordIds = this.invoiceRecordIds;
      // 调整传参字段
      let params = {
        type: obj.headerType, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
        title: obj.invoiceHeader, //抬头
        taxNo: obj.dutyParagraph, //税号
        email: obj.email, //电子邮箱
        openBank: obj.bank, //开户银行
        bankNo: obj.bankAccount, //银行账号
        companyAddress: obj.companyAddress, //企业地址
        companyPhone: obj.companyPhone, //企业电话
        invoiceRecordIds: obj.invoiceRecordIds,
        userInvoiceId: obj.userInvoiceId,
      };

      this.$ajax({
        url: "/hxclass-mobile/invoice/change",
        method: 'post',
        params
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Dialog.alert({
            title: '提示信息',
            message: '发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。',
          }).then(() => {
            this.$router.back()
          });
        } else {
          this.$Toast(res.message);
        }
        this.isSubmit = false
      });
    },
    // 获取发票抬头列表
    getInvoiceList() {
      this.$ajax({
        url: "/hxclass-mobile/invoice/header/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.invoiceRecordIds =this.$route.query.invoiceRecordIds.split(",")
    this.getInvoiceList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/invoice.less";

.box {
  padding: 0 18px;
}
.title{
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 12px 0 0;
  line-height: 28px;
}
.titleH4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 25px;
  margin-top: 10px;
  .left {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      background-color: #15b7dd;
      display: block;
      width: 3px;
      height: 13px;
      border-radius: 1.5px;
      margin-right: 3.5px;
    }
    .text {
      font-size: 16.5px;
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      font-size: 12px;
      color: #999;
    }
    .btn {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #333;
      .icon {
        margin-right: 2px;
      }
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  overflow: hidden;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
// 底部操作栏
.heiSave {
  height: 50px;
}
.btnSave {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 40px;
  z-index: 9;
  .all-btn-bigCircle {
    width: 100%;
  }
}
.riseList {
  padding-top: 17px;
  .item {
    background-color: #fff;
    position: relative;
    padding: 47px 16px 16px 16px;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    border-radius: 12px;
    margin-bottom: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    &.click {
      border-color: #15b7dd;
      background-image: url("../../assets/image/address/icon2.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 20px;
    }
    .label {
      position: absolute;
      left: 0;
      top: 0;
      width: 56px;
      height: 24px;
      background: #15b7dd;
      box-shadow: 2px 0px 0px 0px rgba(26, 164, 196, 1);
      border-radius: 8px 0px 8px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .name {
      font-size: 16px;
      margin-bottom: 11px;
    }
    .mailbox {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      color: #333;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
.nomessage {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
