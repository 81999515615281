<template>
  <div class="active-wrap">
    <div 
      class="back" 
      @click="onBack()">
      <img  src="@/assets/image/blackLeft.png" />
    </div>
    <div 
      class="share" 
      @click="shareFile()">
      <img src="@/assets/image/selected/shareFileNews.png" />
    </div>
    <div class="banner-top">
      <img class="banner-bg" src="@/assets/image/activity/20240617-163121.png" alt="">
      <img class="banner-01" src="@/assets/image/activity/20240617-163409.png" alt="">
      <img class="banner-02" src="@/assets/image/activity/20240703-123503.png" alt="">
      <img class="banner-03" src="@/assets/image/activity/20240617-163412.png" alt="">
      <img class="banner-04" src="@/assets/image/activity/20240617-165532.png" alt="">
    </div>
    <!-- 活动说明 -->
    <div class="active-des">
      <img class="title-img" src="@/assets/image/activity/20240617-001.png" alt="">
      <!-- 活动安排 -->
      <div class="step-list">
        <div class="step-item">
          <div class="arrange-flex">
            <i class="icon">1</i>
            <p>活动时间：</p>
          </div>
          <div class="content">
            <p class="span"><b>2024年7月1日</b> 至 <b>2024年8月31日</b></p>
          </div>
        </div>
        <div class="step-item">
          <div class="arrange-flex">
            <i class="icon">2</i>
            <p>参与对象：</p>
          </div>
          <div class="content">
            <p class="span">全国范围内特殊教育、教育康复学等相关专业的高等院校在校大学生。</p>
          </div>
        </div>
        <div class="step-item">
          <div class="arrange-flex">
            <i class="icon">3</i>
            <p>活动规则：</p>
          </div>
          <div class="content">
            <p class="span"><img class="num" src="@/assets/image/activity/num01.png" alt="">登录<b>“华夏云课堂”</b>APP，注册账号并完成身份认证；</p>
            <p class="span"><img class="num" src="@/assets/image/activity/num02.png" alt="">进入“大学生职业赋能提升计划”页面；</p>
            <div class="btn-flex" v-if="!studenDetail">
              <div @click="goSumbitForm">
                <img class="submit-img"  src="@/assets/image/activity/20240618-094911.png" alt="">
              </div>
              <img class="tips-img" src="@/assets/image/activity/20240617-171652.png" alt="">
            </div>
            <p class="span"><img class="num" src="@/assets/image/activity/num03.png" alt="">资料审核，优惠券自动下发；</p>
            <p class="span"><img class="num" src="@/assets/image/activity/num04.png" alt="">选择相应课程，提交订单并勾选优惠券购买；</p>
          </div>
        </div>
        <div class="step-item">
          <div class="arrange-flex">
            <i class="icon">4</i>
            <p>使用时间：</p>
          </div>
          <div class="content">
            <p class="span">优惠券使用有效期至<b>2024年8月31日</b>。</p>
          </div>
        </div>
      </div>
      <img class="price-bg" src="@/assets/image/activity/20240617-171621.png" alt="">
      <img class="title-img" src="@/assets/image/activity/20240617-171123.png" alt="">
      <div class="tips">
        <img src="@/assets/image/activity/start.png" alt="">
        <span>此阶段聚焦于特殊教育专业技能的培训，以岗位培训课程和技能培训课程为主。</span>
      </div>
      <div class="course-list">
        <div class="course-item" v-for="item in courseList" :key="item.courseId">
          <div class="course-inner" @click="goDetail(item.courseId)">
            <img class="cover-icon icon1" src="@/assets/image/activity/20240617-121.png" alt="">
            <img class="cover-icon icon2" src="@/assets/image/activity/20240617-121.png" alt="">
            <img class="cover-img" :src="item.cover" alt="">
            <div class="side-r">
              <p class="name">{{item.name}}</p>
              <div>
                <p class="del-price">原价：¥{{item.price}}</p>
                <div class="btn">
                  <span>优惠价</span>
                  <p>
                    <i>¥</i>{{item.disprice}}
                  </p>
                </div>
              </div>
            </div>            
          </div>
          <div class="course-bg"></div>
        </div>
      </div>
      <div class="auth-tips">
        *本次活动对象为<b>在校大学生</b>，需提交相关证件进行审核，审核通过后系统将会自动发放优惠券自账户中，优惠券使用有效期至<b>8月31日</b>。学生身份验证问题请咨询华夏云课堂客服电话：<b>400-800-9002</b>。
      </div>
      <div class="type-btn" v-if="show">
        <div @click="goSumbitForm" v-if="!studenDetail">
          <img class="title-img" src="@/assets/image/activity/20240618-094121.png" alt="">
        </div>
        <div class="bohui" @click="goSumbitForm" v-if="studenDetail && studenDetail.status == 2">
          <div class="reason">
            <img src="@/assets/image/activity/20240618-094941.png" alt="">
            <p @click.stop="seeReason">审核未通过，查看原因</p>
          </div>
          <img class="btn-img" src="@/assets/image/activity/20240618-094912.png" alt="">
        </div>
        <img class="title-img" v-if="studenDetail && studenDetail.status == 1" src="@/assets/image/activity/20240618-09121.png" alt="">
        <img class="title-img" v-if="studenDetail && studenDetail.status == 3" src="@/assets/image/activity/20240618-093211.png" alt="">
      </div>
    </div>

    <!-- 驳回原因弹窗 -->
    <van-popup v-model="onPopupOk" :round="true">
      <div class="box_popup" v-if="studenDetail">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">驳回原因</span>
        </div>
        <div class="popup_con">{{studenDetail.reason}}</div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="onPopupOk=false">我知道了</span>
        </div>
      </div>
    </van-popup>

    <!-- 未登录 或 未认证提示窗 -->
    <van-popup v-model="onPopupTipsOk" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">温馨提示</span>
        </div>
        <div class="popup_con">{{tips}}</div>
        <div class="popup_button_center">
          <span class="all-btn-middle btn2" @click="onBack()">我知道了</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getAppVersion, FunSendShare, jumpToShopDetail } from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice  } from "@/unit/fun.js";
const DeviceType = FunJudgDevice()
import config from '@/unit/config.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      config,
      tips:'',
      show:false,
			onPopupOk: false, // 驳回详情弹窗
			onPopupTipsOk: false, // 驳回详情弹窗
			AppVersion: getAppVersion(),  //获取版本号
      courseList:[
        { courseId:1, cover:'https://cos.hxclass.cn/prod/ueditor/product/20240419/7ba5c150dcfd4673ae4dd455dde36c46.jpg', name:'孤独症康复教育岗位培训课程', price:'1580.00', disprice:'1090.00' },
        { courseId:30, cover:'https://cos.hxclass.cn/prod/ueditor/product/20240418/bd263fabc0bd498587c551cc9c5a4ec0.jpg', name:'智力障碍康复教育岗位培训课程', price:'1580.00', disprice:'1090.00' },
        { courseId:2, cover:'https://cos.hxclass.cn/prod/management/20220707/35267323078b4cbf8607597d271de134.jpg', name:'VB-MAPP评估初阶培训课程', price:'1200.00', disprice:'828.00' },
        { courseId:64, cover:'https://cos.hxclass.cn/prod/ueditor/product/20231221/0c9d58abe9a1493784b045cf3c9f6054.jpg', name:'卡罗来纳特殊教育课程（0-5岁）', price:'1200.00', disprice:'828.00' },
      ],
      studenDetail:null, // 学历信息详情
      changecertified:false, // 用户是否认证
    }
  },
  // 事件处理器
  methods: {
    // 分享
		shareFile() {
      // 不同设备版本限制
      if((DeviceType == 'ios' && window.webkit && this.AppVersion.code > 77) || (DeviceType == 'android' && this.AppVersion.code > 107)){
        let json = {
          WebUrl: this.config.shareWxUrl + '/pages/active/studentActive?channelType=3',
          title: '大学生暑期职业赋能活动',
          description: '点击查看详情',
          imageUrl: 'https://cos.hxclass.cn/prod/ueditor/product/20240619/f0cd4fee18d04872b8af6bb431d532cd.jpg'
        }
        FunSendShare(JSON.stringify(json));
      } else{
        this.$Toast('请更新app版本')
      }
		},
    // 跳转详情页
    goDetail(courseId) {
      jumpToShopDetail(1, courseId);
    },

    // 查看驳回原因
    seeReason(){
      this.onPopupOk = true
    },

    // 判断已提交资质
    getDetail() {
      this.$ajax({
        url: "/hxclass-mobile/qualification/detail",
        method: "get",
        params: {
          applyType: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.studenDetail = res.data
        }
        this.$nextTick(() => {
          this.show = true
        });
      });
    },

    onBack(){
      if(DeviceType == 'android'){
        this.$onReturn(true)
      } else {
        this.$onReturn(this.$route.query.fromMenu == '1')
      }
    },

    // 记录用户访问轨迹
    setUserTrackSave() {
      let os = 0
      if(DeviceType == 'android'){
        os = 2
      } else if(DeviceType == 'ios' && window.webkit){
        os = 1
      } else {
        os = 4
      }
      this.$ajax({
        url: "/hxclass-mobile/user/userTrack/save",
        method: "post",
        params: {
          actionDetail: 20240701,  // 访问资源id（如对应的banner、商品和文章id，路径触发先设置首页为1）	
          actionType: 2,  // 触发分类：1、banner触发(轮播图、弹窗)、2 路径触发(启动访问首页)、3商品详情、4 文章详情  5.栏目分享
          os: os,   //1、ios2、安卓 3、PC 4、H5
          userId: this.$store.state.userInfo.userId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
        }
      });
    },

    // 查询是否已身份认证
    checkIdent() {
      this.$ajax({
        url: '/hxclass-mobile/user/anthen/' + this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.changecertified = (res.data == 1 ? true : false)
        }
      })
    },

    // 跳转学历认证页
    goSumbitForm(){
      if(!this.$store.state.userInfo.isLogin){
        this.tips = '请到【我的】模块进行登录！'
        this.onPopupTipsOk = true
        return
      }
      if(!this.changecertified){
        this.tips = '请到【我的】-【身份认证】模块进行身份认证后再操作！'
        this.onPopupTipsOk = true
        return
      }
      this.$router.push({path:'/curriculum/studentAuth',query:{id: this.studenDetail ? this.studenDetail.id : undefined}});
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$store.state.userInfo.userId){
      this.getDetail()
      this.checkIdent()
    } else {
      this.show = true
    }
   },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.setUserTrackSave()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.active-wrap{
  background-image: url(~@/assets/image/activity/20240617-144435.png);
  padding-bottom: 10px;
  position: relative;
  b{
    display: contents;
  }
  .back{
    position: absolute;
    left: 26px;
    top: 60px;
    z-index: 9;
    img{
      width: 24px;
    }
  }
  .share{
    position: absolute;
    right: 26px;
    top: 55px;
    z-index: 9;
    img{
      width: 20px;
    }
  }
  .banner-top{
    position: relative;
    .banner-bg{
      width: 100%;
    }
    .banner-01{
      position: absolute;
      top: 10%;
      right: 0;
      width: 80%;
      animation: banner2ing ease-in-out 1s alternate forwards;
    }
    @keyframes banner2ing{
      0% {
        transform: rotate(0deg);
        opacity: 0;
      }
      100% {
        transform: rotate(360deg);
        opacity: 1;
      }
    }

    .banner-02{
      position: absolute;
      bottom: 23%;
      left: 2%;
      width: 54%;
      animation: banner4ing 1s linear infinite;
    }
    @keyframes banner4ing {
    	0%{
        transform: scale(0.88);
      }
      50%{
        transform: scale(1);
      }
      100%{
        transform: scale(0.88);
      }
    }
    
    .banner-03{
      position: absolute;
      bottom: 7%;
      left: 0;
      width: 50%;
      animation: banner3ing ease-in-out 1s alternate forwards ;
    }
    @keyframes banner3ing{
      0% {
        left: -100%;
        opacity: 0;
      }
      100% {
        left: 0;
        opacity: 1;
      }
    }
    .banner-04{
      position: absolute;
      bottom: 40%;
      width: 20%;
      animation: banner1ing ease-in-out 1s alternate forwards ;
    }
    @keyframes banner1ing{
      0% {
        right: -100%;
        opacity: 0;
      }
      100% {
        right: 22%;
        opacity: 1;
      }
    }
  }
  .active-des{
    position: relative;
    top: -3px;
    margin: 0 10px;
    border-radius: 15px;
    background-color: #F9DF57;
    border: 1px solid #000000;
    padding: 20px 22px;
    .title-img{
      width: 100%;
    }
    .step-list{
      margin-top: 11px;
      .step-item{
        margin-bottom: 16px;
        .arrange-flex{
          display: flex;
          .icon{
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url(~@/assets/image/activity/20240617-003.png);
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            font-style: normal;
            color: #ffffff;
            font-weight: 400;
            line-height: 18px;
            font-size: 12px;
            color: #FFFFFF;
            margin-right: 4px;
          }
          p{
            font-weight: 500;
            font-size: 15px;
            color: #000000;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }
        }
        .content{
          margin-top: 6px;
          padding-left: 22px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            left: 9px;
            display: inline-block;
            width: 2px;
            height: 100%;
            background: #FFF1A6;
            border: 1px solid #000000;
          }
          .span{
            display: flex;
            align-items: flex-start;
            margin-bottom: 4px;
            font-weight: 400;
            font-size: 13px;
            color: #000000;
            line-height: 20px;
            text-align: left;
            .num{
              width: 15px;
              margin-right: 4px;
            }
          }
          .btn-flex{
            display: flex;
            align-items: center;
            margin: 5px 0 10px;
            .submit-img{
              width: 149px;
            }
            .tips-img{
              margin-left: 20px;
              width: 40%;
              animation: banner5ing 1s linear infinite;
            }
            @keyframes banner5ing {
              0%{
                margin-left: 8px;
              }
              100%{
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
    .price-bg{
      margin-left: -22px;
      left: 0;
      width: 100%;
      margin-bottom: 20px;
    }
    .tips{
      margin-top: 10px;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      img{
        position: relative;
        margin-right: 2px;
        top: 2px;
        width: 14px;
      }
    }
    .auth-tips{
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      line-height: 18px;
    }
    .type-btn{
      margin-top: 12px;
      .bohui{
        display: flex;
        align-items: center;
        .reason{
          flex: 1;
          text-align: center;
          p{
            text-decoration: underline;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            line-height: 18px;
          }
          img{
            width: 20px;
          }
        }
        .btn-img{
          width: 60%;
        }
      }
    }
    .course-list{
      .course-item{
        position: relative;
        margin-bottom: 17px;
        .course-inner{
          position: relative;
          background: #FFF1A6;
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #000000;
          padding: 15px 15px 15px 25px;
          display: flex;
          z-index: 2;
        }
        .course-bg{
          background: #E4AB3C;
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #000000;
          position: absolute;
          z-index: 1;
          top: 3px;
          left: 3px;
          width: 100%;
          height: 100%;
        }
        .cover-icon{
          position: absolute;
          top: 25px;
          left: -13px;
          width: 25px;
        }
        .icon2{
          top: 75px;
        }
        .cover-img{
          width: 86px;
          min-width: 86px;
          border: 1px solid #000000;
        }
        .side-r{
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          margin-left: 15px;
          text-align: right;
          .name{
            text-align: left;
            font-weight: 500;
            font-size: 15px;
            color: #000000;
            line-height: 23px;
          }
          .del-price{
            text-decoration: line-through;
            font-weight: 500;
            font-size: 11px;
            color: #E4AB3C;
            line-height: 13px;
            margin-bottom: 5px;
          }
          .btn{
            display: inline-flex;
            align-items: center;
            background: #E4AB3C;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #000000;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            span{
              display: inline-block;
              background: #16ADD1;
              width: 60px;
              text-align: center;
              line-height: 24px;
              font-size: 12px;
              border-radius: 12px 12px 12px 12px;
              border-right: 1px solid #000000;
            }
            p{
              flex: 1;
              text-align: center;
              padding: 0 10px;
              i{
                font-style: normal;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

// 提示信息
  .box_popup {
    box-sizing: border-box;
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      line-height: 25px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 12.5px;
    }
		.popup_button_center {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      &.middle {
        justify-content: center;
      }
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
</style>
