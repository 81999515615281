<template>
  <div class="container-wrap">
    <!-- <div class="nav-bar">
      <p>中国残疾人康复协会</p>
    </div> -->
    <!-- 占位 -->
    <!-- <div style="height:60px"></div> -->
    <!-- 查询条件输入框 -->
    <div class="content-box" v-if="!isNoMessage" >
      <div class="all-white-bg">
        <div class="input-box">
          <span>证书名称：</span>
          <p>{{tempObj.certifName}}</p>
        </div>
        <div class="input-box">
          <span>姓名：</span>
          <p>{{tempObj.name}}</p>
        </div>
        <div class="input-box">
          <span>证书编号：</span>
          <p>{{tempObj.code}}</p>
        </div>
        <div class="input-box">
          <span>发证时间：</span>
          <p>{{tempObj.getcertifTime}}</p>
        </div>
        <div class="input-box">
          <span>发证单位：</span>
          <p>{{!unitType || unitType == 1 ? '中国残疾人康复协会' : '华夏出版社' }}</p>
        </div>
        <div class="input-box" @touchstart="touchStart()" @touchend="touchEnd()">
          <img :src="tempObj.url" alt="">
          <i>请长按图片保存您的证书。</i>
        </div>
      </div>
    </div>
    <!-- 暂无数据 -->
    <noMessage v-else style="margin-top:100px" :type=6></noMessage>

    <!-- 微信打开提示去浏览器 -->
    <div v-if="iosApp" id="guideZfbPayPage">
      <div class="wx-open">
        <img src="@/assets/image/guide.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {
    noMessage
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      tempObj: {},
      isNoMessage: false,
      imgUrl64: '',
      iosApp: false,
      unitType:null,
    }
  },
  // 事件处理器
  methods: {
    isWeixin() {
      const u = navigator.userAgent.toLowerCase();
      return (
        /micromessenger/i.test(u) ||
        u.indexOf("weibo") > -1 ||
        u.indexOf("qq") > -1 ||
        u.indexOf("mqqbrowser") > -1
      );
    },

    // 证书信息
    queryData() {
      this.$ajax({
        url: '/hxclass-mobile/exam/certif/query',
        method: 'get', 
        params:{
          idcard: this.$route.query.idcard,
          name: this.$route.query.name,
          tempId: this.$route.query.tempId,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 证书获取时间格式改为，年/月
          let time = res.data[0].getcertifTime
          var now = new Date((time).replace(/-/g,'/'))
          var year = now.getFullYear() // 得到年份
          var month = now.getMonth() + 1 // 得到月份

          res.data[0].getcertifTime = year + '-' + (month < 10 ? '0' + month : month )
          this.tempObj = res.data[0]
          this.getBase64(res.data[0].url)
        } else {
          this.isNoMessage = true
        }
      })
    },

    //手指离开
    touchEnd() {
      clearTimeout(this.Loop);
    },
    //手指触摸
    touchStart() {
      clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
      this.Loop = setTimeout(() => {
        this.downloadIamge(this.imgUrl64, "证书图片");
      }, 1000);
    },

    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },

    // 需将图片转为base64地址
    getBase64(file) {
    // 一定要设置为let，不然图片不显示
    let image = new Image();
    // 解决跨域问题
    image.setAttribute('crossOrigin', 'anonymous');
    let imageUrl = file
    image.src = imageUrl
    let that = this;
    let dataURL = ''
    // image.onload为异步加载
    image.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, image.width, image.height);
      var quality = 0.8;
      // 这里的dataurl就是base64类型
      dataURL = canvas.toDataURL('image/jpeg', quality); // 使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
      this.imgUrl64 = dataURL
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.unitType = this.$route.query.unitType
    this.queryData()
  },
  // 生命周期-实例挂载后调用
  mounted () {
    if (this.isWeixin()) {
      this.iosApp = true;
    } else {
      // 显示手动打开外置浏览器提示
      this.iosApp = false;
      // this.submitFn()
    }
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
 @import "./select.less";
  /* 提示浏览器打开 */
  #guideZfbPayPage {
    img{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 777;
      width: 100%;
    }
  }
  .nav-bar{
    background: url('https://cos.hxclass.cn/test/management/20230331/d4d946c3c9e74e6e9199fe2b932c7713.png') repeat;
    background-size: 100% 100%;
    width: 100%;
    height:60px;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    position: fixed;
    top: 0;
    p{
      padding-top: 28px;
    }
  }
  .input-box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 400;
    >span{
      color: #666666;
    }
    >p{
      color: #333333;
    }
    >img{
      max-width: 100%;
    }
    i{
      display: block;
      color: #666666;
      font-size: 12px;
      font-style: normal;
      margin-top: 5px;
      &::before{
        content: '*';
        color: #F90000;
        margin-right: 4px;
        margin-top: 6px;
      }
    }
  }
</style>
