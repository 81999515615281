<template>
  <!-- 打卡转发分享页 -->
  <div class="container-wrap">
    <!-- canvas画布 -->
    <!-- <canvas
    	class="canvas"
    	canvas-id="cardPoster"
    	:style="[posterStyle]"></canvas> -->
    <!-- 生成的图片 -->
    <div class="poster" id="posterHtml">
    <img class="share-img" :src="imgSrc" alt="">
    </div>
    <div class="share-box">
      <div class="share-inner">
        <div class="share-top-left">
          <div class="share-icon">
            <img class="icon" @click="onShare(1)" src="@/assets/image/icon/20220211041502.svg" />
          </div>
          <span class="span">微信</span>
        </div>
        <div class="share-top-right">
          <div class="share-icon">
            <img class="icon" @click="onShare(2)" src="@/assets/image/icon/20220211041503.svg" />
          </div>
          <span class="span">朋友圈</span>
        </div>
      </div>
      <div class="btn-cancel" @click="onCancel()">取消</div>
    </div>
  </div>
</template>

<script>
  // import { saveImageToPhotosAlbum } from 'sakura-canvas'
  // import Draw from 'sakura-canvas'
  import html2canvas from "html2canvas";  // 页面转图片组件
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        posterStyle: {
        	width: '10px',
        	height: '10px'
        },
        imgSrc: null, // 页面生成的图片路径
        img: null,  // 背景模板图
        years:'',  // 年月
        day:'', // 日
        dayNum:2,   // 连续打卡天数
        posterImg: '', // 生成的图片
        monthEnglish: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"],
      }
		},
    mounted() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth() + 1;//得到月份
      var day = now.getDate();//得到日期
      var date = year + '/' + month + '/' + day
      this.day = day
      this.years = this.monthEnglish[new Date(new Date(date)).getMonth()] + '.' + year
    	this.$nextTick(() => {
    		// this.createDraw()
        this.createPoster();
    	})
    },
		// 事件处理器
		methods: {
      // 生成海报
      createPoster() {
        const vm = this;
        // vm.$loading.show({
        //   text: '加载中'
        // })
        const domObj = document.getElementById('posterHtml');
        // console.log('canvas----------->生成海报');

        // html2canvas(this.$refs.imageWrapper, {
        html2canvas(domObj, {
          useCORS: true,
          allowTaint: false,
          logging: false,
          letterRendering: true,
          onclone(doc) {
            let e = doc.querySelector('#posterHtml')
            e.style.display = 'block'
          }
        }).then(function (canvas) {
          // console.log('canvas----------->', canvas);

          // 在微信里,可长按保存或转发
          vm.posterBase = canvas.toDataURL('image/png');
          // console.log("canvas.toDataURL('image/png')", canvas.toDataURL('image/png'));
          vm.imgSrc = canvas.toDataURL('image/png');
          // vm.$loading.hide();
        })
      },
      // 创建draw绘制对象
      createDraw() {
      	this.draw = new Draw({
      		width: 750,
      		height: 1216,
      		unit: 'px',
      		_this: this,
          delayTime: 100,
          drawDelayTime: 100,
      		canvasId: 'cardPoster',
      	})
      	this.$nextTick(() => {
      		this.drawCanvas()
      	})
      },
      // 绘制内容
      async drawCanvas() {
      	let { day,years,dayNum,draw, img } = this
      	let res = await draw.createdSharePoster(({ ctxObj, style }) => {
      		let { width, height } = ctxObj
      		this.posterStyle = style
      		// 距离x轴的距离
      		let offsetX = 0
      		let offsetY = 0
          
          // 背景图
          let BackgroundImg = {
          	name: 'BackgroundImg',
          	type: 'image',
          	drawType: 'rect',
          	src: img,
          	r: 0,
            w:750,
            h:1218,
            x: offsetX,
            y: offsetY
          }
          
          // 日
          let Day = {
          	type: 'span',
          	span: day,
          	font: {
          		size: 40,
              family:'PingFangMedium',
          		weight: 'bold'
          	},
            color:'#ffffff',
          	line: {
          		height: 42,
          	},
            x: offsetX + 36,
            y: offsetY + 118
          }
          
          // 年月
          let Years = {
          	type: 'span',
          	span: years,
          	font: {
          		size: 28,
              family:'PingFangMedium',
          	},
          	color:'#ffffff',
          	line: {
          		height: 42,
          	},
          	x: offsetX + 36,
          	y: offsetY + 168
          }
          
          // 打卡天数
          let dayNums = {
          	type: 'span',
          	span: dayNum,
            font: {
            	size: 40,
            	weight: 'bold'
            },
          	color:'#ffffff',
          	line: {
          		height: 42,
          	},
            spanAlign:'center',
            w:100,
          	x: 553,
          	y: 930
          }
         return [BackgroundImg, Day, Years, dayNums]
      	})
      	if (!res.success) return
      	this.posterImg = res.data
      },
      // 取消分享
      onCancel(){
        this.$onReturn(this.$route.query.fromMenu == '1')
      },
      onShare(e){
        // uni.share({
        //   provider: "weixin",
        //   scene: e == 1? "WXSceneSession" : "WXSceneTimeline",
        //   type: 2,
        //   imageUrl: this.posterImg,
        //   success: function (res) {
        //       // console.log("success:" + JSON.stringify(res));
        //   },
        //   fail: function (err) {
        //       // console.log("fail:" + JSON.stringify(err));
        //   }
        // });
      }
    },
	}
</script>

<style>
  page{
    background: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  .poster{
    width: 100vw;
    height: 162.4vw;
    // background-image: url(~@/assets/image/datas/20220216041401.png);
    background-size: 100%;
    position: relative;
    .date{
      position: absolute;
      top: 59px;
      left: 18px;
      .dateDay{
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 30px;
      }
      .dateMouse{
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;
      }
    }
    .dayNum{
      position: absolute;
      bottom: 125px;
      right: 68px;
      font-size: 18px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.9);
      line-height: 27px;
    }
  }
  .canvas{
  	position: fixed;
    top: -49999999999.5px;
    left: -49999999999.5px;
    z-index: -99999999999;
    opacity: 0;
  }
  .share-img{
    width: 100%;
  }
  .share-box{
    position: relative;
    top: -15px;
    height: 190px;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    background: #F7F7F9;
    box-sizing: border-box;
    padding-top: 20px;
    .share-inner{
      display: flex;
      justify-content: space-between;
      width: 235px;
      margin: 0 auto;
      .share-top-left,.share-top-right{
        .share-icon{
          width: 70px;
          height: 70px;
          background: #FFFFFF;
          box-shadow: 0px 2.5px 2px 1px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          display: table-cell;
          vertical-align: middle;
          .icon{
            width: 44px;
            height: 44px;
            margin: auto;
          }
        }
        .span{
          display: inline-block;
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
      }
    }
    .btn-cancel{
      margin: 14px auto 0;
      border-radius: 20px;
      width: 70%;
      height: 40px;
      background: #FFFFFF;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
    }
  }
</style>
