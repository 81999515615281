// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/icon/menu-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".menuSwiper-box[data-v-694676ac]{width:100vw;height:47px;position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:340px 75%!important;background-position-x:center!important;background-repeat:no-repeat!important;will-change:transform}.menuSwiper-box .swiper-slide[data-v-694676ac]{text-align:center;font-size:15px;font-weight:500;color:#666;line-height:22.5px;position:relative;margin-top:25px;will-change:transform}.menuSwiper-box[data-v-694676ac] .swiper-slide-active{transform:scale(1.1);bottom:9.5px;transition:all .2s ease-in 0s;font-size:18px;color:#fff;will-change:transform}", ""]);
// Exports
module.exports = exports;
