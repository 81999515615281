<template>
	<div class="classOrderDetail">
		<van-nav-bar
      class="nav-circular"
      title="订单详情"
      left-text=""
      right-text=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
		<!-- 课程订单详情 -->
		<div class="shopMessage">
			<!-- 商品信息 -->
			<div class="shopItem">
				<img class='shopImg' src="" />
				<div class="shopMessage">
					<span class="shopTitle">商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称</span>
					<div class="priceNum">
						<span class="profit">课程延期：60天</span>
						<span class="num">x1</span>
					</div>
				</div>
			</div>
			<div class="payment">
				<span class="title">实付款：</span>
				<span class="price">¥3424.00</span>
			</div>
		</div>
		<div class="detailedtitle">
			<span class="colorDiv"></span>
			<span class="title">订单信息</span>
		</div>
		<div class="orderMessage">
			<!-- 订单信息 -->
			<div class="messageItem">
				<span class="title">订单编号：</span>
				<span class="value">21312342343255426<span @click="copyOrderCode('21312342343255426')">｜复制</span></span>
			</div>
			<div class="messageItem">
				<span class="title">创建时间：</span>
				<span class="value">2022-01-27  11:32:23</span>
			</div>
			<div class="messageItem">
				<span class="title">订单类型：</span>
				<span class="value">图书教具</span>
			</div>
			<div class="messageItem">
				<span class="title">支付方式：</span>
				<span class="value">微信</span>
			</div>
			<div class="messageItem">
				<span class="title">支付时间：</span>
				<span class="value">2022-01-27  11:32:23</span>
			</div>
			<div class="messageItem">
				<span class="title">支付订单号：</span>
				<span class="value">2131231232131231245</span>
			</div>
			<div class="messageItem">
				<span class="title">到期时间：</span>
				<span class="value">2022-01-27  11:32:23</span>
			</div>
		</div>
		<van-button class="invoicing" plain type="primary">申请开票</van-button>
		<van-button class="invoicing" plain type="primary">查看开票</van-button>
		<!-- <button type="default" class="invoicing">申请开票</button>
		<button type="default" class="invoicing">查看开票</button> -->
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		methods:{
			copyOrderCode(e){
				var input = document.createElement("input");   // 直接构建input
				input.value = e;  // 设置内容
				document.body.appendChild(input);    // 添加临时实例
				input.select();   // 选择实例内容
				document.execCommand("Copy");   // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$Toast('复制成功');
			}
		}
	}
</script>

<style scoped lang="less">
	.classOrderDetail{
		background: #F7F7F9;
		padding: 12px 18px 0;
		box-sizing: border-box;
		min-height: 100vh;
		.invoicing{
			position: fixed;
			bottom: 6px;
			width: 271px;
			left: calc((100vw - 271px)/2);
			height: 32px;
			line-height: 32px;
			border-radius: 16px;
			border: 1px solid #15B7DD;
			font-size: 12px;
			color: #15B7DD;
		}
		.invoicing::after{
			border: 0;
		}
		.orderMessage{
			background-color: #ffffff;
			padding: 16px 12px;
			border-radius: 8px;
			.messageItem{
				display: flex;
				justify-content: space-between;
				margin-bottom: 16px;
				&:last-child{
					margin: 0;
				}
				.title{
					font-size: 14px;
					color: #333333;
					line-height: 22px;
				}
				.value{
					font-size: 14px;
					color: #666666;
					line-height: 22px;
					span{
						color: #333333;
						display: inline-block;
						margin-left: 5px;
					}
				}
			}
		}
		.shopMessage{
			background-color: #ffffff;
			padding: 16px 12px;
			border-radius: 8px;
			.payment{
				margin-top: 16px;
				display: flex;
				justify-content: space-between;
				.title{
					font-size: 14px;
					font-weight: 600;
					color: #333333;
					line-height: 22px;
				}
				.price{
					font-size: 14px;
					font-family: PingFangMedium;
					font-weight: bold;
					color: #333333;
					line-height: 22px;
				}
			}
			.shopItem{
				padding-bottom: 16px;
				border-bottom: 1px solid rgba(238, 238, 238, 1);
				display: flex;
				.shopImg{
					width: 124.5px;
					height: 88px;
					overflow: hidden;
					border-radius: 8.5px;
				}
				.shopMessage{
					position: relative;
					flex: 1;
					padding: 11px 0 4px 10px;
					.shopTitle{
						font-size: 14px;
						font-weight: 400;
						padding-right: 10px;
						color: #333333;
						line-height: 22px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.priceNum{
						position: absolute;
						bottom: 0;
						display: flex;
						width: calc( 100% - 10px );
						justify-content: space-between;
						.profit{
							font-size: 12px;
							font-weight: bold;
							color: #15B7DD;
							line-height: 17px;
						}
						.num{
							font-size: 12px;
							font-weight: 400;
							color: #999999;
							line-height: 17px;
						}
					}
				}
			}
		}
		.detailedtitle{
			height: 24.5px;
			display: flex;
			align-items: center;
			margin-top: 16px;
			margin-bottom: 12px;
			.colorDiv{
				width: 3px;
				height: 13px;
				background-color: rgba(21, 183, 221, 1);
				margin-right: 3.5px;
				margin-top: 3.5px;
				border-radius: 4px;
			}
			.title{
				font-weight: bold;
				font-size: 18px;
				line-height: 28px;
				color: #333333;
			}
		}
	}
</style>
