<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      :title="title"
      :fixed="true"
      :placeholder="true"
      @click-left="$onReturn($route.query.fromMenu == '1')"
      left-arrow
    >
      <!-- <template #right>
        <van-icon name="search" size="18" color="#b3b3b3" />
      </template> -->
    </van-nav-bar>
    <div class="content-box">
      <div class="box_title">
        {{ info }}
        <img class="img" src="@/assets/image/icon/20220627041501.png" alt="" />
      </div>
      <div class="big-title">
        <img
          class="question-icon"
          src="@/assets/image/icon/20220218041501.png"
        />
        <span>{{ total }}个问答</span>
      </div>
      <div class="question-list">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="finishedText"
          @load="getQAList"
        >
          <div class="question-item" v-for="i in qaList" :key="i.qaId">
            <div class="title-box">
              <!-- <img class="title-icon" src="@/assets/image/icon/20220218041502.png" /> -->
              <div class="title-txt">{{ i.title }}</div>
              <!-- <div class="num">Q{{index + 1}}</div> -->
              <img
                class="title-bg"
                src="@/assets/image/icon/20220627041502.png"
              />
            </div>
            <div class="answer-box">
              <!-- <img class="icon" src="@/assets/image/icon/20220218041505.png" /> -->
              <div class="info" v-html="i.content"></div>
            </div>
          </div>
        </van-list>
      </div>
      <noMessage v-if="qaList.length == 0" type="1"></noMessage>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 数据对象
  data() {
    return {
      title: "",
      info: "",

      qaList: [], //百问百答
      page: 1, //页数
      size: 10, //条数
      loading: false,
      finished: false,
      finishedText: "暂无数据",
      total: 0, //总数
    };
  },
  // 事件处理器
  methods: {
    async getQAList() {
      await this.$ajax({
        url: "/hxclass-mobile/information/answer/list",
        params: {
          qaTypeId: this.id,
          page: this.page,
          size: this.size,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.qaList = [...this.qaList, ...res.data.records];
          this.total = res.data.total;
          // 加载完成
          if (this.qaList.length == res.data.total) {
            this.finished = true;
            this.finishedText = "没有更多了";
          }
          // 无数据
          if (this.qaList.length == 0) {
            this.finished = true;
            this.finishedText = "";
          }
          this.page++;
          this.loading = false;
        }
      });
    },
  },
  async created() {
    this.id = this.$route.query.qaTypeId;
    await this.getQAList();
    this.title = this.qaList[0].categoryName;
    this.info = this.qaList[0].categoryRemark;
    // this.title = this.qaList[0].title;
    // this.info = this.qaList[0].content;
  },
};
</script>



<style lang="less" scoped>
/deep/.nomessage {
  margin-top: 48px;
}
.content-box {
  padding: 14px 18px;
  .box_title {
    position: relative;
    min-height: 40px;
    font-size: 15px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    margin-bottom: 14px;
    border-bottom: 6px solid #15b7dd;
    padding: 14px 17px;
    background-color: #ffffff;
    box-shadow: 0px 2.5px 5px 1px rgba(93, 131, 167, 0.1);
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      bottom: -10px;
      right: 50px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid #fff;
      z-index: 3;
    }
    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      bottom: -15px;
      right: 50px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid #15b7dd;
      z-index: 2;
    }
    .img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 61px;
      height: 59px;
    }
  }
  .big-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    .question-icon {
      margin-right: 2.5px;
      width: 18px;
    }
  }
  .question-list {
    margin-top: 18px;
    .question-item {
      box-shadow: 0px 2.5px 5px 1px rgba(93, 131, 167, 0.1);

      margin-bottom: 15px;
      .title-box {
        min-height: 70px;
        box-sizing: border-box;
        background: #15b7dd;
        padding: 0 12px;
        min-height: 30px;
        display: flex;
        align-items: center;
        background: linear-gradient(
          151deg,
          rgba(21, 183, 221, 0.65) 0%,
          #15b7dd 52%,
          rgba(21, 183, 221, 0.65) 100%
        );
        box-shadow: 0px 5px 10px 1px rgba(93, 131, 167, 0.1);
        // border-radius: 20px 20px 0px 0px;
        opacity: 1;
        padding: 22px 12px;
        border-radius: 10px 10px 0 0 ;
        overflow: hidden;
        position: relative;
        .title-icon {
          width: 27px;
        }
        .title-txt {
          text-align: justify;
          font-size: 16px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
        }
        .num {
          position: absolute;
          right: -4px;
          top: -4px;
          background: url(../../assets/image/icon/20220218041503.png);
          background-repeat: no-repeat;
          background-size: 100%;
          width: 38.5px;
          height: 38.5px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #15b7dd;
          line-height: 34.5px;
        }
        .title-bg {
          position: absolute;
          bottom: -15px;
          // right: 20px;
          right: 0;
          width: 75px;
          height: 75px;
        }
      }
      .answer-box {
        padding: 18px 14px;
        background: #ffffff;
        // border-radius: 10px;
        .icon {
          width: 27px;
        }
        .info {
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
          /deep/strong {
            font-weight: bold;
          }
          /deep/em {
            font-style: italic;
          }
          /deep/img {
            max-width: 100%;
          }
          /deep/.remarks {
            max-width: 100%;
            height: auto !important;
            -webkit-line-clamp: 10000 !important;
          }
        }
      }
    }
  }
}

.container-box {
  height: 100vh;
  overflow: auto;
  /* 隐藏滚动条 */
  * ::-webkit-scrollbar {
    width: 0 !important;
  }

  * ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  * ::-webkit-scrollbar {
    display: none;
  }
}
</style>
