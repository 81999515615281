<template>
  <!-- 我的学习 -->
  <div class="container-box">
    <!-- 信息条 -->
    <div 
      v-if="stateHeight!==0"
      class="box_status" 
      :style="{
        height: stateHeight+'px',
        opacity: stateOpacity,
      }"></div>
    <div class="banner-box">
      <div class="banner-photo">我的学习</div>
      <div class="banner-menu">
        <router-link to="../myStudy/myExam" class="banner-menubox">
          <img
            class="banner-icon"
            src="../../assets/image/study/icon_exam.png"
          />
          <div class="banner-menutitle">考试</div>
        </router-link>
        <router-link to="../myStudy/certificate" class="banner-menubox">
          <img
            class="banner-icon"
            src="../../assets/image/study/icon_certificate.png"
          />
          <div class="banner-menutitle">证书</div>
        </router-link>
        <router-link to="../myStudy/studyCensus" class="banner-menubox">
        <!-- <router-link to="/task/task" class="banner-menubox"> -->
          <img
            class="banner-icon"
            src="../../assets/image/study/icon_statistics.png"
          />
          <div class="banner-menutitle">统计</div>
        </router-link>
        <!-- <router-link
          to="../myStudy/studyCode"
          class="banner-menubox"
        >
          <img
            class="banner-icon"
            src="../../assets/image/study/icon_credit.png"
          >
          <div class="banner-menutitle">学分</div>
        </router-link> -->
      </div>
    </div>

    <div class="clock-box">
      <div class="clock-left">
        <img class="clock-pic" src="../../assets/image/study/pic_clock.png" />
        <span class="clock-msg">已连续学习打卡{{ clockObj.punchDay }}天</span>
      </div>
      <div class="clock-right">
        <router-link  to="../myStudy/clockLearn"
          >学习打卡</router-link
        >
      </div>
    </div>

    <div class="score-box">
      <!-- 原样式 -->
      <!-- <router-link to="../myStudy/studyCode" class="score-points">
        <div class="score-pointsnum">
          {{ clockObj.integral ? clockObj.integral : 0
          }}<span class="small">分</span>
        </div>
        <div class="score-pointstitle">学习积分</div>
      </router-link> -->
      <!-- <router-link to="../myStudy/studyCensus" class="score-hours"> -->
      <!-- <div class="score-hours">
        <div class="score-hoursnum">
          {{ studyHour.classHour ? studyHour.classHour : 0
          }}<span class="score-hourstext">课时</span>
        </div>
        <div class="score-hourstitle">学习课时</div>
      </div> -->
      <!-- </router-link> -->
      <!-- <router-link to="../myStudy/studyCensus" class="score-minutes">
        <div class="score-minutesmsg">
          <div class="score-hoursnum" v-html="studyHour.time"></div>
          <div class="score-minutestitle">学习时间</div>
        </div>
        <img class="score-pic" src="../../assets/image/study/pic_score.png" />
      </router-link> -->

      <!-- 新样式 -->
      <router-link to="../myStudy/studyCode" class="score-minutes">
        <div class="score-minutesmsg">
          <div class="score-minutestitle">学习积分</div>
          <div class="score-hoursnum">
            {{ clockObj.integral ? clockObj.integral : 0 }}<span class="small">分</span>
          </div>
        </div>
        <div class="colorBox"></div>
        <img class="score-pic" src="../../assets/image/study/studyCodeLogo.png" />
      </router-link>

      <router-link to="../myStudy/studyCensus" class="score-minutes" style="margin-left: 13px;">
        <div class="score-minutesmsg">
          <div class="score-minutestitle">学习课时</div>
          <!-- <div class="score-hoursnum" v-html="studyHour.time"></div> -->
          <div class="score-hoursnum">
            {{ studyHour.classHour ? studyHour.classHour : 0
            }}<span class="score-hourstext">课时</span>
          </div>
        </div>
        <div class="colorBox"></div>
        <img class="score-pic" src="../../assets/image/study/studyTimeLogo.png" />
      </router-link>
    </div>

    <!-- 无数据 -->
    <!-- <div class="emptylist">
			<img class="emptyimage" src="../../assets/image/study/noCourse.png">
			<span class="emptyspan">暂无课程哦~</span>
			<span class="goclass">去选课</span>
		</div> -->
    <!-- <no-message type="4" /> -->
    <div class="remind-box" v-show="JSON.stringify(remindList) != '{}'">
      <!-- <div class="title">
        <div class="title-border"></div>
        主要提醒
      </div> -->
      <div class="remind-content">
        <div class="uni-padding-wrap">
          <div class="page-section swiper">
            <div class="page-section-spacing">
              <van-swipe
                class="my-swipe"
                :autoplay="80000"
                indicator-color="#1CB9DE"
              >
                <!-- 考试提醒：12月15日有xx考试，记得按时参加考试！考试网址：www.xx -->
                <van-swipe-item v-if="remindList.examRemind">
                  <div class="swiper-item uni-bg-red title">
                    <div class="swiper-title">
                      <img
                        class="swiper-img"
                        src="../../assets/image/study/pic_remind3.png"
                      />
                      <span class="swiper-text">考试提醒</span>
                      <span class="swiper-date">{{ remindList.examRemind.examDate.split(" ")[0] }}</span>
                    </div>
                    <div class="swiper-content">
                      <div class="">{{ remindList.examRemind.examName }}记得按时参加考试！考试网址：https://exam.hxclass.cn</div>
                      <!-- <div class="website">考试网址：https://alpha.exam.hxclass.cn/</div> -->
                      <img
                        class="remind-bcg3"
                        src="@/assets/image/study/bcg_remind3.png"
                        alt=""
                      />
                    </div>
                  </div>
                </van-swipe-item>
                <!-- <van-swipe-item v-if="remindList.qrcodeRemind"> -->
                <van-swipe-item v-if="false">
                  <div class="swiper-item uni-bg-red title">
                    <div class="swiper-title">
                      <img
                        class="swiper-img"
                        src="@/assets/image/study/pic_remind2.png"
                      />
                      <span class="swiper-text">进群提醒</span>
                    </div>
                    <div class="swiper-content swiper-flex">
                      <div class="swiper-left">
                        <div>
                          <!-- 报名审核成功，请尽快介入考试群，会随时更新考试情况，及时参与考前串讲 -->
                          {{remindList.qrcodeRemind.examName}}报名审核通过，请联系教务老师加入考试群，及时接收考试相关通知
                        </div>
                      </div>
                      <div class="swiper-right swiper-course">
                        <img
                          class="remind-bcg2"
                          src="@/assets/image/study/bcg_remind2.png"
                          alt=""
                        />
                        <!-- <div
                          @click="toQRcode()"
                          v-if="remindList.qrcodeRemind.qrcode !== ''"
                        >
                          查看群聊二维码>>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </van-swipe-item>
                <van-swipe-item v-if="remindList.signupRemind">
                  <!-- 报名提醒：x月的xx考试开始报名啦 -->
                  <div
                    class="swiper-item uni-bg-red title"
                    @click="goSignUpTo()"
                  >
                    <div class="swiper-title">
                      <img
                        class="swiper-img"
                        src="@/assets/image/study/pic_remind1.png"
                      />
                      <span class="swiper-text">报名提醒</span>
                    </div>
                    <div class="swiper-content swiper-flex">
                      <div class="swiper-left">

                      {{ remindList.signupRemind.examDate.split(" ")[0] }}的
                      <span class="swiper-course">
                        {{ remindList.signupRemind.examName }} </span
                      >考试开始报名啦
                      </div>
                    </div>
                    <img
                      class="remind-bcg"
                      src="@/assets/image/study/bcg_remind.png"
                    />
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="course-box">
      <div class="course-title">
        <div class="course-titlel">
          <div class="title-border"></div>
          <!-- /My Study -->
          <span class="texts"
            >我的课程<span class="grey"> </span></span
          >
        </div>
        <!-- <router-link
          class="course-titler"
          to=""
        >
          <span>更多 </span>
          <van-icon name="arrow" />
        </router-link> -->
      </div>

      <!-- 课程分类 -->
      <div class="tabNav">
        <div class="tabItem" v-for="(item,index) in tabList" :key="index" :class="{ active: tabIndex == index }" @click="tabChange(index)">
          {{item.name}}
        </div>
      </div>

      <!-- <div class="nav">
        <div :class="courseTab=='0'?'hover':'default'" @click="onCourse(0)">未结课</div>
        <div :class="courseTab=='1'?'hover':'default'" @click="onCourse(1)" >已结课</div>
        <div :class="courseTab=='2'?'hover':'default'" @click="onCourse(2)">附赠视频</div>
      </div> -->

      <div
        v-for="(item, index) in courseList"
        :key="index"
        class="course-content"
      >
        <div
          class="course-never"
          :class="{ 'course-never-open': item.isOpen }"
          @click.stop="goStudy(item)"
        >
          <div class="course-leftbox">
            <img class="course-left" :src="item.coursePicture" />
            <div class="course-speed" v-if="item.offline==0">
              <div class="speed-top" v-show="item.studyProgress != 0">
                已学{{ (item.studyProgress * 100).toFixed(0) }}%
              </div>
              <div class="speed-top" v-show="item.studyProgress == 0">
                不足1%
              </div>
              <!-- <van-progress class="speed-bot" color="#EC6C01" :percentage="30" :show-pivot="false"/> -->
              <!-- <van-progress class="speed-bot" color="#15B7DD" :percentage="60" :show-pivot="false"/> -->
            </div>
          </div>
          <div class="course-neverright">
            <div class="course-nevertop">
              {{ item.courseName }}
            </div>
            <div class="course-nevercenter">
              <span class="course-nevertime" v-if="item.validDayType == 2"
                ><img
                  class="course-pic"
                  src="@/assets/image/study/pic_date.png"
                  alt=""
                />长期</span>
              <span class="course-nevertime" v-else-if="item.startTime"
                ><img
                  class="course-pic"
                  src="@/assets/image/study/pic_date.png"
                  alt=""
                />{{ item.startTime.substring(0,10) }} 至 {{ item.endTime.substring(0,10) }}
              </span>
              <span class="course-nevertime" v-show="item.className">
                <img
                  class="course-pic"
                  src="@/assets/image/study/pic_stage.png"
                  alt=""
                />{{ item.className }}</span
              >
              <span class="course-nevertime" v-show="item.provinceTxt">
                <img
                  class="course-pic"
                  src="@/assets/image/study/pic_province.png"
                  alt=""
                />{{item.provinceTxt}}{{item.cityTxt}}{{item.districtTxt}}{{item.address}}</span
              >
            </div>
            <div class="course-neverbot">
              <!-- offline 1 线下课  0 线上 -->
              <span class="all-btn-small" v-show="item.offline==0">{{item.isOpenClass == 1 ? '待开课' : item.status == 1 ? '去学习' : item.status == 2 ? '已过期' : ''}}</span>
              <!-- <span class="all-btn-small" @click="toCourseDelay()">延期</span>
              <span v-if="courseTab == '0'" class="all-btn-small">进班</span> -->
            </div>
          </div>
          <span class="label" :class="{'label-blue' : item.type == 3}" v-if="item.type && item.type != 1">{{
            item.type == 2 ? "免费" : item.type == 3 ? "附赠" : null
          }}</span>
          <!-- <img class="open" v-show="!item.isOpen && item.isHidden!=1" src="@/assets/image/study/open_icon.png" alt=""> -->
          <div class="openbox" @click.stop="onPop(item)">
            <img
              class="open"
              v-if="item.type == 2 && item.isHidden == 1"
              src="@/assets/image/study/open_icon.png"
              alt=""
            />
          </div>
        </div>

        <div
          v-if="item.isOpen == true && item.isHidden != 1"
          class="course-open"
        >
          <!-- <router-link
            v-if="item.isClassGuide"
            to="/myStudy/classGuide"
            class="course-openlist"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_group1.png"
              />
            </div>
            <div class="course-opentitle">进班指南</div>
          </router-link> -->
          <div
            v-if="item.isExam"
            class="course-openlist"
            @click="toGrade(item.courseId)"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_achievement.png"
              />
            </div>
            <div class="course-opentitle">成绩单</div>
          </div>
          <div
            v-if="item.isMockExam"
            class="course-openlist"
            @click="toExam(item.courseId)"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_simulation.png"
              />
            </div>
            <div class="course-opentitle">模拟考试</div>
          </div>
          <!-- <div class="course-openlist">
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_group.png"
              >
            </div>
            <div class="course-opentitle">班级群</div>
          </div> -->
          <div
            v-if="item.isExam"
            class="course-openlist"
            @click="toRegistration(item.examConfigId)"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_signup.png"
              />
            </div>
            <div class="course-opentitle">{{item.isSignUpExam==0?"考试报名":"我的考试"}}</div>
          </div>
          <div
            v-if="item.isChangeOpenTime"
            class="course-openlist"
            @click="toClass(item)"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_change.png"
              />
            </div>
            <div class="course-opentitle">更换班级</div>
          </div>
          <div
            v-if="item.isDelay"
            class="course-openlist"
            @click="toCourseDelay(item)"
          >
            <div class="course-openicon">
              <img
                class="course-opicon"
                src="@/assets/image/study/icon_course.png"
              />
            </div>
            <div class="course-opentitle">课程延期</div>
          </div>
          <div class="openbox" @click.stop="onPop(item)">
            <img
              class="open"
              v-if="item.type == 2 && item.isHidden == 0"
              src="@/assets/image/study/open_icon.png"
              alt=""
            />
          </div>
          <!-- <div v-if="courseTab == '1'" class="course-openlist">
            <div class="course-openicon">
              <img class="course-opicon" src="@/assets/image/study/icon_student.png">
            </div>
            <div class="course-opentitle">学员异动</div>
          </div> -->
        </div>

        <!-- <div class="course-nbonus">
          <div class="course-leftbox">
            <img class="course-left" src="@/assets/image/datas/banner_course.jpg">
          </div>
          <div class="course-nbonusright">
            <div class="course-nbonustop">
              {{item.title}}
            </div>
            <div class="course-nbonuscenter">
              <span class="course-nbonustime">主讲人：{{item.speaker}}</span>
              <span class="course-nbonustime">时长：{{item.duration}}</span>
            </div>
            <div class="course-nbonusbot">关联课程：{{item.relation}}</div>
          </div>
        </div> -->
      </div>
      <!-- <van-loading
        type="spinner"
        v-show="loading"
        class="loading"
        color="#15B7DD"
      /> -->
      <noMessage
        style="margin-top: 35px"
        v-if="courseList.length == 0 && !loading"
        type="4"
      ></noMessage>
    </div>

    <!-- <div class="live-box">
      <div class="title">
        <div class="title-border"></div>
        我的直播
      </div>
      <div class="nav">
        <div
          :class="liveTab=='0'?'hover':'default'"
          @click="onLive(0)"
        >直播</div>
        <div
          :class="liveTab=='1'?'hover':'default'"
          @click="onLive(1)"
        >回放</div>
      </div>
      <div class="live-content">
        <div class="live-scroll">
          <div
            v-for="(item,index) in liveList"
            :key="index"
            class="live-list"
            @click="toLiveDetail()"
          >
            <div class="live-image">
              <img
                v-if="liveTab == '0'"
                class="live-living"
                src="@/assets/image/study/icon_living.png"
              >
              <img
                v-if="liveTab == '1'"
                class="live-living"
                src="@/assets/image/study/icon_playback.png"
              >
              <img
                v-if="liveTab == '0'"
                class="live-wave"
                src="@/assets/image/study/pic_wave.png"
              >
              <img
                class="live-play"
                src="@/assets/image/study/button_play.png"
              >
            </div>
            <div class="live-txet">{{item.title}}</div>
            <div class="live-date">
              <span class="live-time">{{item.date}}</span>
              <span class="live-time">{{item.time}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <van-popup v-model="onPopup" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">确认移出我的学习课程吗？</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onPopup = false">取消</span>
          <span class="all-btn-middle btn2" @click="onConfirm()">确认</span>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="onDelayPopupShow" :round="true">
      <div class="box_popup Delay">
        <h4 class="title">申请延期须知</h4>
        <!-- 免费延期 -->
        <template v-if="delayCode == 1005">
          <p>1.本次延期为您首次对本课程进行延期，无需缴纳费用；</p>
          <p>2.申请延期后课程有效期顺延{{delayForm.days}}天，申请成功后即生效，有效期最后一天23:59:59秒失效；</p>
          <p>3.有效期内课程观看次数、章节自测题答题次数均不受限制；</p>
          <p>4.超过有效期后如需再次学习，每次延期需缴纳{{delayForm.nextPrice}}元延期费，请您合理安排学习时间；</p>
          <p>5.支付成功后不支持退款。</p>
        </template>
        <!-- 收费延期 -->
        <template v-else>
          <p>1.本次延期为您第{{delayForm.delayCount}}次对课程进行延期，延期费为{{delayForm.price}}元，缴纳费用后课程有效期顺延{{delayForm.days}}天；</p>
          <p>2.支付成功后即生效，有效期最后一天23:59:59秒失效;</p>
          <p>3.有效期内课程观看次数、章节自测题答题次数均不受限制；</p>
          <p>
            4.超过有效期后如需再次学习，则需以本计费规则再次缴纳延期费用，请您合理安排学习时间；
          </p>
          <p>5.支付成功后不支持退款。</p>
        </template>
        <!-- <div class="popup_con">您的课程于{{delayForm.date}}到期，本次延期{{delayCode==1006 ? ('费用为' + delayForm.price) + '元' : '为免费'}}，延期有效期为{{delayForm.days}}天，从付费时算起，是否申请？</div> -->
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="onDelayPopupShow = false"
            >取消</span
          >
          <span class="all-btn-middle btn2" @click="onDelay">申请</span>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="PromptShow" @close="popUpClose" :round="true">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">{{PromptCode == '1002'?'您已成功提交申请，1-3个工作日内将完成审核，审核结果将以消息的方式通知，请留意。':PromptCode == '1003'?'您提交的证书未通过审核，请重新提交。':Prompt}}</div>
        <div class="popup_button" style="justify-content: space-around;">
          <span class="all-btn-middle" :class="PromptCode == '-2' || PromptCode == '1003'?'btn1':'btn2'" @click="PromptShow = false">我知道了</span>
          <span class="all-btn-middle btn2" v-if="PromptCode == '-2' || PromptCode == '1003'" @click="PromptShow = false,$router.push(
                '/curriculum/seniority?courseId=' + handleItem.courseId
              )">提交证书</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
// import { FunPlay } from "@/assets/js/NativeWebKit.js";
import { courseVideo } from "@/unit/courseVideo.js";
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      handleItem: null,
      Prompt: '',
      PromptShow: false,
      PromptCode: '',
      stateHeight: 0, //信息条高度
      stateOpacity: 0, //信息条渐变值
      onPopup: false,
      onDelayPopupShow: false,
      isFirstDelay: true,
      delayLoad:false,
      delayForm: {},
      delayCode: 0,
      popItem: {},
      indicatorDots: true,
      // indicatorColor: rgba(0, 0, 0, .3),
      autoplay: true,
      interval: 3000,
      duration: 500,
      courseTab: 0, //0：未结课，1：已结课，2：附赠视频
      courseList: [],

      tabIndex: 0,
      tabList: [],
      liveTab: 0, //0：直播，1：回放
      liveList: null,
      remindList: {},
      loading: false,
      clockObj: {
        punchDay: 0,
        integral: 0,
      },
      studyHour: {},
      data: {
        clock: "21",
        pointsnum: "1000",
        coursenum: "50",
        hoursnum: "1000",
        livingList: [
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训书",
            date: "09月20日",
            time: "15:00",
          },
        ],
        backList: [
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
          {
            photo: "",
            title: "孤独症康复教育人员上岗培训",
            date: "09月20日",
            time: "15:00",
          },
        ],
      },
    };
  },
  // 事件处理器
  methods: {
    PromptConfirm(){
      this.PromptShow = false
    },

    popUpClose() {
      setTimeout(()=>{
        this.PromptCode = '';
      },500)
    },

    // 消息提醒
    getRemind() {
      this.$ajax({
        url: "/hxclass-mobile/exam/user/reminder",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId, //用户id
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.remindList = res.data;
        } else {
          // this.$alert(res.message);
          this.$alert(res.message);
        }
      });
    },
    // 去报名
    goSignUpTo() {
      let id = this.remindList.signupRemind.configId;
      // let name = this.remindList.examRemind.courseName;
      this.$router.push({
        path: "/examEnroll/registration",
        query: {
          id: id,
          type: 1,
        },
      });
    },
    // 获取连续打卡 天数/积分
    getDays() {
      this.$ajax({
        url: "/hxclass-mobile/clock/punch/days",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.clockObj = res.data;
        }
      });
    },
    // 获取学习课时和时长
    getStudyHour() {
      this.$ajax({
        url: "/hxclass-mobile/course/study-info",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.studyHour = res.data;
        }
      });
    },
    tabChange(i){
      this.tabIndex = i
      this.courseList = this.tabList[i].courseList;
    },
    // 获取课程列表
    getChiefList() {
      this.tabIndex = 0
      this.loading = true;
      this.$ajax({
        url: "/hxclass-mobile/course/my-classification",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          res.data.forEach((item) => {
            item.courseList.forEach(element => {
              element.isOpen = true;
            });
          });
          this.tabList = res.data;
          this.courseList = res.data[this.tabIndex].courseList;
        }
      });
    },
    FunFormatTime(time) {
      var d = time ? new Date(time) : new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var hours = d.getHours();
      var min = d.getMinutes();
      var seconds = d.getSeconds();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (hours < 0) hours = "0" + hours;
      if (min < 10) min = "0" + min;
      if (seconds < 10) seconds = "0" + seconds;

      // return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
      return year + "-" + month + "-" + day;
    },
    // 课程学习
    goStudy(e) {
      this.handleItem = e;
      // offline 1 是线下课，  不可看视频
      if(e.offline==1){
        this.Prompt = '请在学习时间内前往指定地点参与学习'
        this.PromptShow = true
        return
      }
      if(e.status==2){
        this.Prompt = '请申请延期后学习'
        this.PromptShow = true
        return
      }
      if(e.isOpenClass == 1){
        let arr = e.startTime.split('-')
        this.Prompt = '课程学习尚未开始，开始时间为'+ arr[0] +'年'+ arr[1] +'月'+ arr[2] +'日'
        this.PromptShow = true
      } else {
        this.$ajax({
          url: "/hxclass-mobile/course/go-study",
          params: {
            courseId: e.courseId,
          },
        }).then((res) => {
          if (res.code == "200" && res.success) {
            courseVideo({
              courseId: res.data.courseId,
              sectionId: res.data.sectionId,
              isOrder: e.watchByCatalogueOrder
            })
          }else{
            this.Prompt = res.message
            this.PromptShow = true
            this.PromptCode = res.code
          }
        });
      }
    },
    toRegistration(id) {
      // 考试报名入口
      // this.$router.push({path: '/examEnroll/registration?id=0'})// 考试报名页面
      this.$router.push({
        path: "/examEnroll/registration",
        query: {
          id: id,
          type: 2, //1首页进入 2课程进入
        },
      });
    },

    toExam(id) {
      // 模拟考试入口
      this.$router.push({
        path: "/examination/exam?courseId=" + id,
      });
    },

    toGrade(id) {
      // 成绩单入口
      this.$router.push({
        path: "/examination/grade?courseId=" + id,
      });
    },

    toClass(e) {
      // 班级单入口
      this.$router.push({
        path:
          "/examination/changeClassTime?courseId=" +
          e.courseId +
          "&openTimeId=" +
          e.openTimeId,
      });
    },

    toQRcode() {
      // 群聊二维码入口
      if (remindList.qrcodeRemind.qrcode !== "") {
        this.$router.push({
          path: "/curriculum/QRcode?url=" + this.remindList.qrcodeRemind.qrcode,
        });
      }
    },
    // 延期查询
    toCourseDelay(e) {
      this.$ajax({
        url: "/hxclass-mobile/course/check-delay-course",
        params: {
          courseId: e.courseId,
        },
      }).then((res) => {
        if (res.success) {
          this.delayCode = res.code;
          this.delayForm = res.data;
          this.delayForm.courseId = e.courseId;
          this.onDelayPopupShow = true;
        } else {
          this.$alert(res.message);
        }
      });
    },
    // 延期
    onDelay() {
      if (this.delayCode == 1005) {
        if(this.delayLoad || !this.isFirstDelay) return  // 延期防抖
        this.delayLoad = true
        // 免费延期直接走接口
        this.$ajax({
          url: "/hxclass-mobile/course/delay-course",
          method: "POST",
          params: {
            courseDelayId: this.delayForm.courseDelayId,
            courseId: this.delayForm.courseId,
            days: this.delayForm.days,
            price: this.delayForm.price,
          },
        }).then(res=>{
          this.delayLoad = false
          if(res.code == 200 && res.success){
            this.onDelayPopupShow = false
            this.isFirstDelay = false
            this.$alert('申请成功');
            this.getChiefList()
          } else {
            this.$alert(res.message);
          }
        })
      } else { // 付费延期走订单
        let list = [];
        list.push({
          productId: this.delayForm.courseDelayId, // 商品id
          count: 1, // 数量")
          openTimeId: "", // 班级id
          type: 6, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province: "", // 省
          city: "", // 市
          noGift: 0, // 是否为赠品 0否1是t
          productList: [],
        });
        this.$store.commit("updatelist", list);
        this.$store.commit("updateType", 4);
        this.$store.commit("updateInvoiceId", null);
        this.$store.commit('updateShortInvoiceId',null);
        this.$store.commit("updateAddress", null);
        this.$store.commit("updateCouponList", null);
        this.$router.push("/order/confirmOrder");
      }
    },
    toLiveDetail() {
      this.$router.push({
        path: "/live/liveDetail",
      });
    },
    onLive(e) {
      this.liveTab = e;
      if (this.liveTab == 0) {
        this.liveList = this.data.livingList;
      } else {
        this.liveList = this.data.backList;
      }
    },
    onCourse(e) {
      this.courseTab = e;
      if (this.courseTab == 0) {
        // this.courseList = this.data.classList
      } else if (this.courseTab == 1) {
        // this.courseList = this.data.overList
      } else {
        // this.courseList = this.data.giftList
      }
    },
    handleVisiable(e) {

      if (e.target.visibilityState == "visible") {
        // 获取课程
        // console.log("回到当前tab标签");
        this.getChiefList();
      } else {
        // console.log("离开当前tab标签");
      }
    },

    onPop(x) {
      this.popItem = x;
      this.onPopup = true;
    },

    onConfirm() {
      this.loading = true
      clearTimeout(this.qa);
      this.qa = setTimeout(() => {
        // 防抖
        this.$ajax({
          url: "/hxclass-mobile/course/remove-study",
          method: "put",
          params: {
            courseId: this.popItem.courseId,
            openTimeId: this.popItem.openTimeId,
            userId: this.$store.state.userInfo.userId,
          },
        }).then((res) => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.$alert('移除成功')
            this.getChiefList();
            this.onPopup = false;
          }
        });
      }, 600);
    },

    // 页面滚动监听
    FunHandleScroll() {
      // 信息条渐变值
      this.stateOpacity = document.getElementById('content').scrollTop/100
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.liveList = this.data.livingList;
    // 消息提醒
    this.getRemind();
    // 打卡积分
    this.getDays();
    // 获取课程
    this.getChiefList();
    // 学习课时和时长
    this.getStudyHour();
    // 添加监听事件
    document.addEventListener("visibilitychange", this.handleVisiable);
    // 获取信息条高度
    this.stateHeight = this.$store.state.stateHeight;
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.FunHandleScroll();
      document
        .getElementById("content")
        .addEventListener("scroll", this.FunHandleScroll);
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    if (document.getElementById("content")) {
      document
        .getElementById("content")
        .removeEventListener("scroll", this.FunHandleScroll);
    }
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
};
</script>

<style lang="less" scoped>
.container-box {
  background-color: #f7f7f9;
  overflow: hidden;
  min-height: 100%;
  .box_status {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    opacity: 0;
    z-index: 100;
  }

  /deep/.tabBlock {
    padding-left: 0;

    .tab__item {
      margin-right: 12px;
      padding: 0 3px;
    }
  }

  .title {
    margin-bottom: 12.5px;
    font-size: 16.5px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
    line-height: 24.5px;
  }

  .title-border {
    display: flex;
    display: inline-block;
    margin-right: 4px;
    width: 3px;
    height: 13px;
    border-radius: 4px;
    background-color: #15b7dd;
  }

  .nav {
    margin-bottom: 12.5px;

    .default {
      display: inline-block;
      margin-right: 21.5px;
      height: 16.5px;
      font-size: 13.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #666666;
      line-height: 20.5px;
    }

    .hover {
      display: inline-block;
      margin-right: 21.5px;
      height: 16.5px;
      font-size: 13.5px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 20.5px;
      box-shadow: 0px -2px 0px 0px rgba(43, 216, 212, 0.35) inset;
    }
  }

  .banner-box {
    position: relative;
    margin-bottom: 12.5px;

    .banner-photo {
      width: 100%;
      height: 199px;
      font-weight: bold;
      background: url(../../assets/image/study/banner_study.png) no-repeat;
      background-size: 100%;
      font-size: 18px;
      font-family:  PingFangMedium;
      color: #ffffff;
      line-height: 125px;
      text-align: center;
    }

    .banner-menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 96px;
      right: 0;
      margin: 0 18px;
      width: calc(100% - 36px);
      height: 103px;
      background: url(../../assets/image/study/bcg_study.png) no-repeat;
      background-size: 100%;
      border-radius: 13.5px;

      .banner-menubox {
        .banner-icon {
          width: 38.5px;
          height: 38.5px;
        }

        .banner-menutitle {
          font-size: 12.5px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 19px;
        }
      }
    }
  }

  .clock-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px auto 12.5px;
    width: calc(100% - 36px);
    height: 54.5px;
    box-shadow: 0px 2px 2px 1px rgba(26, 63, 98, 0.05);
    background-color: #ffffff;
    border-radius: 13.5px;

    .clock-left {
      .clock-pic {
        width: 27.5px;
        height: 26px;
        vertical-align: middle;
      }

      .clock-msg {
        margin-left: 17.5px;
        font-size: 14.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 21.5px;
      }
    }

    .clock-right {
      width: 94px;
      height: 31px;
      background: url(../../assets/image/study/bcg_clock.png) no-repeat;
      background-size: 100%;
      font-size: 14.5px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 31px;
      text-align: center;
      a{
        color: #ffffff;
      }
    }
  }

  .score-box {
    display: flex;
    justify-content: space-between;
    margin: 15px 18px 0px;
    width: calc(100% - 36px);
    // height: 65px;
    // overflow: hidden;

    .score-points {
      position: relative;
      flex: 1;
      border-radius: 5px;
      height: 60px;
      background: url(../../assets/image/study/bcg_score1.png) no-repeat;
      background-size: 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      .score-pointsnum {
        line-height: 41.5px;
          font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    font-size: 18px;
        text-align: center;
         color: #ffffff;
        .small {
          font-size: 14px;
          // font-weight: 400;
        }
      }

      .score-pointstitle {
        position: absolute;
        bottom: 5px;
        width: 100%;
        padding-top: 6px;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 17.5px;
        text-align: center;
      }
    }

    .score-hours {
      position: relative;
      border-radius: 5px;
      margin: 0 12.8px;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      width: 24%;
      height: 70px;
      background: url(../../assets/image/study/bcg_score2.png) no-repeat;
      background-size: 100% 100%;
      background-color: #ffffff;

      .score-hourstitle {
        position: absolute;
        bottom: 5px;
        width: 100%;
        padding-top: 6px;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #ffffff;
        line-height: 17.5px;
        text-align: center;
      }
    }

    .score-hoursnum {
      position: absolute;
      top: 24px;
      left: 16px;
      border-radius: 5px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
      font-size: 20px;
      text-align: center;
      /deep/span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .score-minutes {
      position: relative;
      // width: 36%;
      box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
      flex: 1;
      height: 60px;
      background-color: #ffffff;
      border-radius: 4.5px;
      .colorBox{
        width: 40%;
        height: 14px;
        position: absolute;
        top: -6px;
        left: 0;
        z-index: 0;
        background: #CEF2FF;
        border-radius: 5px;
      }

      .score-minutesmsg {
        position: relative;
        z-index: 1;
        float: left;
        min-width: 78px;
        padding-left: 5px;
        background: #ffffff;
        border-radius: 4.5px;
        height: 100%;
        .score-minutestitle {
          position: absolute;
          top: 8px;
          left: 16px;
          width: 100%;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
          // text-align: center;
        }
      }

      .score-pic {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 55px;
        height: 43px;
        // 原样式
        // bottom: -7px;
        // right: -4px;
        // width: 50.5px;
        // height: 43px;
      }
    }
  }

  .emptylist {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 135px;

    .emptyimage {
      margin: 0 auto;
      width: 105px;
    }

    .emptytext {
      font-size: 14.5px;
      margin-top: -3px;
      font-weight: 500;
      color: #666666;
      line-height: 21.5px;
    }

    .goclass {
      display: block;
      width: 110.5px;
      height: 35.5px;
      margin: 0 auto;
      margin-top: 26.5px;
      background-color: rgba(21, 183, 221, 1);
      border-radius: 22.5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 35.5px;
    }
  }

  .remind-box {
    margin: 18px auto 0px;
    width: calc(100% - 36px);
    min-height: 75px;
    box-shadow: 0px 2px 5px 1px rgba(26, 63, 98, 0.05);
    display: block;

    .remind-content {
      width: 100%;
      min-height: 75px;
      background: #ffffff;
      border-radius: 4.5px;
      .title {
        margin-bottom: 0px;
      }
      .uni-bg-red {
        position: relative;
        padding: 10.5px 18px;
        min-height: 54.5px;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        margin-bottom: 0 !important;
        // overflow: hidden;
        .swiper-title {
          display: flex;
          align-items: center;
          .swiper-img {
            width: 14.5px;
            height: 14.5px;
            vertical-align: sub;
          }

          .swiper-text {
            margin: 0 12.5px 0 3.5px;
          }
          .swiper-date {
            font-size: 11px;
            font-weight: 400;
          }
        }

        .swiper-content {
          margin-top: 6px;
          width: calc(100% - 60px);
          height: 44px;
          font-size: 11px;
          font-weight: 400;
          line-height: 18px;

          .swiper-course {
          font-weight: 500;
            color: #1cb9de;
          }
        }

        .swiper-flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .swiper-left {
            width: calc(100% - 78px);
          }

          .swiper-right {
            position: relative;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // width: 104px;
            width: 72px;
            text-align: right;
          }
        }

        .remind-bcg {
          position: absolute;
          bottom: 0px;
          right: 22px;
          width: 59.5px;
          height: 49px;
        }

        .remind-bcg2 {
          margin-bottom: 4px;
          width: 50px;
          height: 40px;
        }

        .website {
          position: relative;
          z-index: 9999;
        }
        .remind-bcg3 {
          position: absolute;
          right: -10px;
          bottom: -5px;
          // top: 25px;
          width: 264px;
          height: 66px;
        }
      }
    }

    /deep/.van-swipe {
      overflow: inherit;
    }

    /deep/.van-swipe__indicator {
      background-color: #666666;
    }

    /deep/.van-swipe__indicators {
      bottom: -14px;
    }

    /deep/.van-swipe__indicator:not(:last-child) {
      margin-right: 13px;
    }
  }

  .course-box {
    margin: 0 18px 16.5px;
    position: relative;
    .course-title {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 10px;
      .course-titlel {
        display: inline-block;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 24.5px;
      }

      .course-titler {
        display: inline-block;
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #999999;
        line-height: 17.5px;

        image {
          width: 10px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }

    .tabNav {
      padding-bottom: 12px;
      width: 100%;
      overflow-x: scroll;
      display: inline-block;
      white-space: nowrap;
      .tabItem {
        display: inline-block;
        vertical-align: middle;
        margin-right: 22px;
        font-size: 14px;
        color: #666666;
        line-height: 27px;
        position: relative;
        padding: 0 4px;
      }
      .active {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: 2px;
          background-color: rgba(21, 183, 221, 0.4);
          width: 80%;
          height: 4px;
          border-radius: 3.5px;
          filter: blur(2px);
          padding: 0;
          margin: 0 auto;
          margin-top: -4px;
        }
      }
    }

    .loading {
      // position: absolute;
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // left: 50%;
      // top: 50%;
      color: #fff;
      // transform: translate(-50%, -50%)
    }
    .course-content {
      margin-bottom: 14px;
      .course-leftbox {
        position: relative;
        margin: 0 10px;
        height: 76px;
        height: 76px;
        overflow: hidden;
        .course-left {
          width: 76px;
          height: 76px;
          border-radius: 5px;
          background-repeat: no-repeat;
          object-fit: cover;
        }

        .course-speed {
          position: absolute;
          // left: 10px;
          bottom: 0px;
          width: 76px;
          height: 18px;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.35) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          border-radius: 0 0 5px 5px;
          overflow: hidden;

          .speed-top {
            margin: 4px 4px 0 0;
            font-size: 9px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #ffffff;
            line-height: 9px;
            text-align: right;
          }
          .speed-bot {
            position: relative;
            bottom: -3px;
          }
        }
      }

      .course-never {
        display: flex;
        align-items: center;
        margin: 0 auto;
        // height: 105px;
        background-color: #ffffff;
        border-radius: 10px 10px 10px 10px;
        padding-top: 12px;
        position: relative;
        .course-neverright {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 74px;
          .course-nevertop {
            // margin-bottom: 9px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
          }

          .course-nevercenter {
            // margin-bottom: 8.5px;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            .course-pic {
              margin-right: 3px;
              width: 9px;
              height: 9px;
            }

            .course-nevertime {
              margin-right: 6px;
              font-size: 11px;
              font-family:  PingFangMedium;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
            }
            .course-nevertime:nth-child(2) {
              margin-right: 0;
            }
          }

          .course-neverbot {
            height: 25px;

            .all-btn-small {
              margin-right: 18px;
              height: 25px;
              line-height: 25px;
              width: 53px;
            }
          }
        }
        .label {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          font-size: 10px;
          width: 36px;
          color: #fff;
          text-align: center;
          line-height: 17px;
          height: 17px;
          border-radius: 5px 0 10px 0;
          background: linear-gradient(180deg, #ffbd60 0%, #eaa037 100%);
        }
        .label-blue {
          background: linear-gradient(180deg, #3ac5e5 0%, #64e2ff 100%);
        }
        .openbox {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 0;
          .open {
            width: 42px;
          }
        }
      }
      .course-never-open {
        border-radius: 0 10px 0px 0;
        padding-right: 10px;
        padding-bottom: 10px;
        border-top-left-radius: 10px;
      }

      .course-open {
        display: flex;
        flex-wrap: wrap;
        // height: 132px;
        background: linear-gradient(180deg, #ffffff 0%, #ffecbb 100%);
        border-radius: 0px 0px 13.5px 13.5px;
        position: relative;
        .openbox {
          position: absolute;
          right: 0;
          bottom: 0;
          .open {
            width: 42px;
          }
        }

        .course-openlist {
          margin: 6.5px 0 10px 0;
          width: 33%;

          .course-openicon {
            text-align: center;

            .course-opicon {
              width: 28px;
              height: 28px;
            }
          }

          .course-opentitle {
            font-size: 11px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 16.5px;
            text-align: center;
          }
        }
      }

      .course-nbonus {
        display: flex;
        align-items: center;
        height: 107px;
        background-color: #ffffff;
        border-radius: 0px 0px 13.5px 13.5px;

        .course-nbonusright {
          .course-nbonustop {
            margin-bottom: 4px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
          }

          .course-nbonuscenter {
            margin-bottom: 20px;

            .course-nbonustime {
              margin-right: 21.5px;
              font-size: 11px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #999999;
              line-height: 12.5px;
            }
          }

          .course-nbonusbot {
            font-size: 11px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 12.5px;
          }
        }
      }
    }
  }

  .live-box {
    margin: 0 18px 16.5px;

    .live-content {
      white-space: nowrap;
      padding: 9px 0 0;
      overflow: auto;

      .live-scroll {
        white-space: nowrap;

        .live-list {
          display: inline-block;
          width: 138.5px;
          margin-right: 12.5px;

          .live-image {
            position: relative;
            margin-bottom: 11px;
            width: 138.5px;
            height: 169px;
            // background: url(../../assets/image/datas/banner_live.jpg) no-repeat;
            background-size: 100%;
            border-radius: 11.5px;

            .live-living {
              width: 50.5px;
              height: 22px;
            }

            .live-wave {
              position: absolute;
              top: 5.5px;
              right: 13.5px;
              width: 12px;
              height: 11px;
            }

            .live-play {
              position: absolute;
              top: 68px;
              right: 53px;
              width: 33px;
              height: 33px;
            }
          }

          .live-txet {
            margin-bottom: 5.5px;
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 19px;
            white-space: normal;
            text-overflow: ellipsis;
          }

          .live-date {
            .live-time {
              margin-right: 18px;
              font-size: 11px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #333333;
              line-height: 16.5px;
            }
          }
        }
      }
    }
  }
  /deep/.van-popup {
    background-color: transparent;
  }
  .popup-content {
    background: #ffffff;
    width: 285.5px;
    border-radius: 10px;
    padding: 18px 10px 0;
    position: relative;

    .popup-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cover-img {
        width: 135px;
        height: 81px;
        border-radius: 9px;
      }

      .title {
        margin: 0 auto;
        margin-top: 21.5px;
        font-size: 22px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 33px;
      }

      .title-noimg {
        font-size: 20px;
        line-height: 30px;
      }

      .specs {
        margin-top: 7px;
        font-size: 16.5px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15b7dd;
        line-height: 24.5px;
      }

      .achievement-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .achievement-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0px 18px;

          .achievement-top {
            font-size: 16.5px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 24.5px;
          }

          .achievement-span {
            font-size: 36px;
            font-family: PingFangMedium;
            font-weight: bold;
            line-height: 54.5px;
          }

          .achievement-pass {
            color: #15b7dd;
          }

          .achievement-fail {
            color: #ea3c31;
          }

          .achievement-bottom {
            font-size: 12.5px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 19px;
          }

          .achievement-passtips {
            color: #333333;
            font-size: 14.5px;
            line-height: 21.5px;
          }
        }
      }
    }

    .btn-inner {
      display: flex;
      justify-content: space-around;
      border-radius: 32px;

      .div {
        display: inline-block;
        border-radius: 32px;
        padding: 23.5px 10px;
        background: #fff;

        .all-btn-middle {
          width: 110.5px;
          height: 35.5px;
          line-height: 35.5px;
          font-size: 18px;
        }
      }
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 80vw;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}

.Delay {
  .title {
    text-align: center;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #333333;
  }
  p {
    margin-top: 10px;
    font-size: 13px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 19.5px;
  }
}
.texts {
  font-size: 16.5px;
  .grey {
    font-size: 10px;
    font-weight: 300;
    font-family: Light;
    color: #999999;
  }
}
</style>
