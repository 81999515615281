<template>
  <div class="container-box">
    <!-- <uni-nav-bar title="相关图书教具" rightIcon="search"  fixed=true left-icon="left"></uni-nav-bar> -->
    <van-nav-bar
      class="nav-circular"
      title="相关图书教具"
      :fixed="true"
      :placeholder="true"
      rightIcon="search"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    >
      <template #right>
        <van-icon name="search" />
      </template>
    </van-nav-bar>
    <div class="content-box">
      <div class="bookAids-list">
        <div class="bookAids-item" v-for="(i,index) in 3" :key="index">
          <img class="cover-img" src="" />
          <div class="side-right">
            <div class="title all-font-ellipsis">孤独症康复教育人员上岗培训教材</div>
            <div class="source">编者丨中国残疾人康复协会</div>
            <div class="source">出版丨华夏出版社</div>
            <div class="price">￥<span>60.00</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
      }
		},
		// 事件处理器
		methods: {
    },
		// 页面生命周期-页面加载
		onLoad() {},
		// 页面生命周期-页面显示
		onShow() {},
		// 页面生命周期-页面初次渲染完成
		onReady() {},
		// 页面生命周期-页面隐藏
		onHide() {},
		// 页面生命周期-页面卸载
		onUnload() {}
	}
</script>

<style>
  page{
  	background-color: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  .content-box{
    padding: 0 18px;
    .bookAids-list{
      .bookAids-item{
        display: flex;
        margin-top: 21.5px;
        background: #FFFFFF;
        box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
        border-radius: 5px;
        height: 118px;
        .cover-img{
          position: relative;
          bottom: 8px;
          width: 96px;
          height: 125.5px;
          border-radius: 5px 5px 0 5px;
        }
        .side-right{
          flex: 1;
          padding: 11px 15px;
          font-family: PingFangMedium;
          position: relative;
          .title{
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
          }
          .source{
            line-height: 19px;
            font-size: 12px;
            font-weight: 500;
            color: #666666;
          }
          .price{
            position: absolute;
            right: 15px;
            bottom: 11px;
            font-size: 12px;
            font-weight: bold;
            color: #EC6C01;
            line-height: 14px;
            span{
              font-family: PingFangMedium;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
</style>
