<template>
	<!-- 新增地址 -->
	<div class="addAddress">
		<!-- 导航栏 -->
		<div class="nav">
			<van-nav-bar
				class="nav-circular"
				:title="id?'编辑地址':'添加新地址'"
				left-text=""
				:fixed="true"
				:placeholder="true"
				left-arrow
				@click-left="$onReturn($route.query.fromMenu == '1')"
			/>
		</div>

		<van-form @submit="onSubmit" @failed="failed">
			<div class="box">
				<div class="box_item">
					<div class="item_title">收货人</div>
					<van-field 
						name="consignee"
						class="item_con"
						v-model="consignee"
						placeholder="请填写收货人姓名"
						:rules="[{ required: true }]" />
				</div>
				<div class="box_item">
					<div class="item_title">手机号码</div>
					<van-field 
						name="phone"
						class="item_con"
						v-model="phone"
						maxlength="11"
						placeholder="请填写收货人手机号码"
						:rules="[{ pattern: $regular.phone.reg, message: $regular.phone.msg }]" />
						<!-- :rules="[{ required: true }]" /> -->
				</div>
				<div class="box_item">
					<div class="item_title">所在地区</div>
          <div class="picker_box" @click="showPicker2 = true">
            <van-field
              readonly
              name="locationName"
              class="placehold"
              :value="locationName"
              input-align="left"
              placeholder="请选择所在地区"
              :rules="[{ required: true }]"
            />
            <van-icon name="arrow" color="#CCCCCC" size="8" />
					</div>
					<van-popup v-model="showPicker2" round position="bottom">
            <provinceCity
              :defaultIndex="[addressProvincial, addressCity, addressArea]"
              :title="picker2Title"
              @onConfirm="onConfirm5"
							@closeCityPop='closeCityPop'></provinceCity>
          </van-popup>
				</div>
				<div class="box_item">
					<div class="item_title">详细地址</div>
					<van-field 
						name="address"
						class="item_con"
						v-model="address"
						placeholder="请填写详细地址"
						:rules="[{ required: true}]" />
				</div>
				<div class="box_info" @click="defaultAddress=!defaultAddress">
					<div class="info_circle" v-if="!defaultAddress"></div>
					<img class="info_icon" v-else src="../../assets/image/address/index_icon3.png" />
					<span class="info_con">设为默认收货地址</span>
				</div>
				<div class="box_info1">提醒：每次下单会默认推荐使用该地址</div>
			</div>
			
			<div class="box_button">
				<!-- <span class="all-btn-bigCircle" native-type="submit">确认</span> -->
				<van-button class="all-btn-bigCircle" block type="info" native-type="submit">提交</van-button>
			</div>
		</van-form>
		
	</div>
</template>

<script>
	import provinceCity from '@/components/provinceCity.vue'
	export default{
		components: {provinceCity},
		data(){
			return{
				consignee: '',  //姓名
				phone: '',  //手机号码
      	locationName: '', // 省市区
				address: '',  //地址
				showPicker2:false, // 收货地址弹框状态
      	picker2Title: '请选择所在地区',
				addressProvincial: '', // 省编码
				addressCity: '', // 市编码
				addressArea: '', // 区编码
				defaultAddress: false,
				id:'',
				isSubmit: true
			}
		},
		methods:{
			// 关闭省市区弹框
			closeCityPop() {
				this.showPicker2 = false
			},
			// 关闭所在地区
			onConfirm5 (value) {
				this.addressProvincial = value.selectedOptions[0].code // 省编码
				this.addressCity = value.selectedOptions[1].code // 市编码
				this.addressArea = value.selectedOptions[2]?.code // 区编码
				this.showPicker2 = false
				this.locationName = value.selectedOptions.map((option) => option.name).join('/')
			},
			// 提交表单验证不通过
			failed(errorInfo){
				// console.log(errorInfo)
				if(errorInfo.errors[0].name == "consignee"){
					this.$Toast('请填写收货人姓名')
				}else if(errorInfo.errors[0].name == "phone"){
					if(errorInfo.values.phone){
						this.$Toast('手机号格式不正确')
					}else{
						this.$Toast('请填写收货人手机号码')
					}
				}else if(errorInfo.errors[0].name == "locationName"){
					this.$Toast('请选择所在地区')
				}else if(errorInfo.errors[0].name == "address"){
					this.$Toast('请填写详细地址')
				}
			},
			// 提交
			onSubmit(values) {
				// 防多次添加
				if(!this.isSubmit){
					return
				}
				this.isSubmit = false
				let _params = {
					consignee:values.consignee, // 收货人
					phone:values.phone, // 手机号
					provice:this.addressProvincial, // 省
					proviceName	:values.locationName.split('/')[0], // 省名称
					city:this.addressCity, // 市
					cityName:values.locationName.split('/')[1], // 市名称
					area:this.addressArea, // 区
					areaName:values.locationName.split('/')[2], // 区名称
					address:values.address, // 详细地址
					isDefault:this.defaultAddress?1:0, // 是否默认-0否1是
					userId:this.$store.state.userInfo.userId
				}
				if(this.id){
					_params['id'] = this.id;
				}
				this.$ajax({
					url:this.id?'/hxclass-mobile/ship/address/update':'/hxclass-mobile/ship/address/add',
					method:this.id?'put':'post',
					params:_params
				}).then(res=>{
					this.isSubmit = true
					if(res.code == 200 && res.success){
						this.$Toast(res.message);
						this.$onReturn(this.$route.query.fromMenu == '1');
					}else{
						this.$Toast(res.message);
					}
				})
			},
			// 获取地址详情
			getAddressDetail(){
				this.$ajax({
					url:'/hxclass-mobile/ship/address/detail',
					method:"get",
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.consignee = res.data.consignee;  //姓名
						this.phone = res.data.phone;  //手机号码
						if (res.data.areaName) {
							this.locationName = res.data.proviceName+'/'+res.data.cityName+'/'+res.data.areaName; // 省市区
						} else {
							this.locationName = res.data.proviceName+'/'+res.data.cityName; // 省市
						}
						this.address = res.data.address;  //地址
						this.addressProvincial = res.data.provice; // 省编码
						this.addressCity = res.data.city; // 市编码
						this.addressArea = res.data.area; // 区编码
						this.defaultAddress = res.data.isDefault == 1?true:false;
					}else{
						this.$Toast(res.message);
					}
				})
			}
		},
		created(){
			if(!!this.$route.query.id){
				this.id = this.$route.query.id;
				this.getAddressDetail();
			}
		}
	}
</script>

<style>
	page{
		background-color: #F7F7F9;
	}
</style>
<style lang="less" scoped>

	.picker_box {
		display: flex;
		align-items: center;
		flex: 1;
		/deep/.van-field__control{
			font-size: 12px;
		}
	}
	* {box-sizing: border-box;}
	/deep/.van-button--info {
		border: 0;
	}
	.addAddress {
		background: #F7F7F9;
		padding-bottom: 60px;
		min-height: 100vh;
	}
	/deep/.van-cell{
		padding: 0;
		flex: 1;
	}
	.box {
		background: #FFFFFF;
		border-radius: 8px;
		margin-top: 10px;
		padding: 7px 18px 24px 18px;
		.box_item {
			display: flex;
			align-items: center;
			padding: 16px 0 14px 0;
			border-bottom: 1px solid #F0F0F0;
			.item_title {
				width: 80px;
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #333333;
			}
			.item_con {
				color: #333333;
				font-size: 12px;
				font-family: PingFangMedium;
				font-weight: 400;
			}
		}
		.box_info {
			margin-top: 16px;
			.info_circle {
				display: inline-block;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid #979797;
				vertical-align: middle;
			}
			.info_icon {
				width: 16px;
				height: 16px;
				vertical-align: middle;
			}
			.info_con {
				font-size: 12px;
				font-family: PingFangMedium;
				font-weight: 400;
				color: #333333;
				margin-left: 6px;
				vertical-align: middle;
			}
		}
		.box_info1 {
			font-size: 10px;
			font-family: PingFangMedium;
			font-weight: 400;
			color: #666666;
			line-height: 12px;
			margin-top: 4px;
			margin-left: 22px;
		}
	}
	
	.box_button {
		position: fixed;
		width: 100%;
		// left: calc((100vw - 295px)/2);
		bottom: 10px;
		padding: 0 40px;
		.all-btn-bigCircle {
			display: inline-block;
			width: 100%;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFangMedium;
			font-weight: 500;
			color: #FFFFFF;
		}
	}
</style>
