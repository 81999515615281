<template>
  <!-- <van-pull-refresh v-model="isRefreshLoading" @refresh="onRefresh"> -->
    <div>
      <!-- 顶部快捷功能栏 -->
      <div class="topBanner">
        <!-- 背景图片 -->
          <van-swipe 
            id="topSwipe"
            ref="topSwipe"
            class="my-swipe" 
            :autoplay="5000" 
            indicator-color="white">
            <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="onSwiperDetail(item)">
              <img 
                class="banner" 
                :style="'opacity:'+((thisTop<=0?0:thisTop)/100)"
                :src="item.img" />
            </van-swipe-item>
          </van-swipe>
        <!-- 空白占位 -->
        <div class="bannerHei"></div>

        <!-- 搜索/通知 -->
        <div class="tops" ref="topCom">
          <!-- <div class="topCom" :class="(thisTop<=0?0:thisTop)<=0?'top':''" -->
            <!-- paddingTop: (thisTop<=0?0:thisTop)<=0 ? stateHeight +'px' : '' -->
          <div 
            class="topCom" 
            :class="(thisTop<=0?0:thisTop)<=0?'top':''"
            :style="{
              opacity:(((LoopTabTop-topComClientHeight)<=0?0:LoopTabTop-topComClientHeight)/100),
              paddingTop: (thisTop<=0?0:thisTop)<=0 ? stateHeight +'px' : ''
            }">
            <div class="seo">
              <div class="seoInput">
                <img @click="gosearch" class="magnifier" src="@/assets/image/icon/20220121152703.png" />
                <input @click="gosearch" :readonly="true" class="input" placeholder="请输入搜索内容" />
                <!-- <img class="scan" src="@/assets/image/icon/20220121152702.png" /> -->
              </div>

              <div v-if="messageNum == 0" class="news">
                <router-link to @click.native="goMyNews" tag="div">
                  <img src="@/assets/image/icon/20220121152701.png" />
                </router-link>
              </div>

              <van-badge 
                v-else 
                :content="messageNum < 100 ? messageNum : '99+'" 
                class="news pick">
                <!-- <router-link :to="!!messageNum?'/all/myNews':''" tag="div"> -->
                <router-link to @click.native="goMyNews" tag="div">
                  <img src="@/assets/image/icon/20220121152701.png" />
                </router-link>
              </van-badge>
            </div>
          </div>
        </div>

        <!-- 分类入口 -->
        <div class="class_box">
          <div class="ul nthChild">
            <router-link class="li" hover-class="none" @click.native="goCourseList" to>
              <div class="icon">
                <img src="@/assets/image/icon/20220121151201.png" />
              </div>
              <div class="title">证书课程</div>
            </router-link>
            <!-- <router-link class="li" hover-class="none" to="/shop/bookTeach">
              <div class="icon">
                <img src="@/assets/image/icon/20220121151205.png" />
              </div>
              <div class="title">图书教具</div>
            </router-link> -->
            <router-link class="li" hover-class="none" to="/knowledge/specialArea">
              <div class="icon">
                <img src="@/assets/image/icon/icon_share.png" />
              </div>
              <div class="title">新知共享</div>
              <!-- <div class="text">新知共享<span class="grey">/New</span></div> -->
            </router-link>
            <router-link class="li" hover-class="none" to="/shop/shoppingMall">
              <div class="icon">
                <img src="@/assets/image/icon/2022021016301.png" />
              </div>
              <div class="title">书课商城</div>
            </router-link>
          </div>

          <div class="ul" style="padding-bottom:9px;">
            <router-link class="li" hover-class="none" to="/shop/mock">
              <div class="icon">
                <img src="@/assets/image/icon/icon_teach.png" />
              </div>
              <div class="title">模拟考试</div>
            </router-link>
            <router-link class="li" hover-class="none" to="/examination/examSignList">
              <div class="icon">
                <img src="@/assets/image/icon/2022021016302.png" />
              </div>
              <div class="title">考试报名</div>
            </router-link>
            <router-link class="li" hover-class="none" to="/search/index">
              <div class="icon">
                <img src="@/assets/image/icon/20220121151206.png" />
              </div>
              <div class="title">认证查询</div>
            </router-link>
          </div>
        </div>
        </div>
        
      <!-- 最近直播 -->
      <!-- <div class="box">
        <div class="titleH4">
          <div class="left">
            <div class="icon"></div>
            <div class="text">最近直播</div>
          </div>
          <div class="right" @click="toLiveList()">
            <div class="text">更多</div>
            <van-icon name="arrow" color="#999" size="14" />
          </div>
        </div>
        <div class="liveBroadcast">
          <div class="left">
            <img class="image" src="@/assets/image/datas/2022012811491.png" />
          </div>
          <div class="right">
            <div class="branch">
              <div class="left">
                <div class="state">未开播</div>
                <div class="name">孤独症康复教育</div>
              </div>
              <div class="right">
                <div class="all-btn-small" style="width: 55px;">预约</div>
              </div>
            </div>
            <div class="time">2021年12月12日 19:00-20:00</div>
          </div>
        </div>
      </div> -->
      
      <div class="box" style="padding-bottom: 0;margin-bottom: 0;">
        <!-- 宣讲头条 -->
        <div class="whiteBgc speak">
          <div class="titleH4" style="padding: 0;">
            <div class="left">
              <div class="icon"></div>
              <!-- <div class="text" style="font-weight: bold;">云课头条<span class="grey">/Highlights</span></div> -->
              <div class="text" style="font-weight: bold;">云课头条</div>
            </div>
            <router-link to="/propagandaHeadlines/audioList" class="seoInput" hover-class="none">
              <div class="right">
                <div class="text">更多</div>
                <van-icon name="arrow" color="#999" size="14" />
              </div>
            </router-link>
          </div>

          <!-- 音频占位 @click="onPlay()" -->
          <!-- <template to="/propagandaHeadlines/audioList" class="seoInput"> -->
          <div class="frequency" @click="onAudioList()">
            <!-- 名称 -->
            <div class="words">
              <div class="title">{{audioList.title}}</div>
              <div class="tree"><img src="@/assets/image/icon/icon_audio.png" />主讲老师：{{audioList.lecturer}}</div>
              <div class="num" v-if="audioList.xjType == 2">时长：{{audioList.audioTime}}</div>
            </div>
            <!-- 播放 -->
            <div class="player" @click.stop="onPlayer()">
              <img class="back" src="@/assets/image/202202101101.png" />
              <img class="icon" v-if="!$store.state.audio.paused && $store.state.audio.src === audioList.audioUrl && audioList.xuanId == $store.state.audio.id" src="@/assets/image/2022021011131.png" />
              <img class="icon" v-else src="@/assets/image/2022021011132.png" />
              <img class="rod" src="@/assets/image/202202101123.png" />
            </div>

            <img class="speakf" src="@/assets/image/icon/pic_speak1.png" />
            <img class="speaks" src="@/assets/image/icon/pic_speak2.png" /> 
            <img class="speakt" src="@/assets/image/icon/pic_speak3.png" />
          
          </div>
        <!-- </router-link> -->
        </div>
        <homeNewTab defaultValue="0" key="homeNewTab1" @change="changeHomeNewTab($event,'homeNewTab1Value')"
          :link="{
            title: '更多',
            to: '/curriculum/courseList?source=selected'
          }"
          :tabList="[
            { label: '重磅课程', value: 0 },
            { label: '热门课程', value: 1 }
        ]">
          
          <template v-if="homeNewTab1Value == 0">
            <div v-for="(item, indexP) in commonList.list1" :key="indexP">
              <div class="CourseCategory">{{item.title}}</div>
              <div class="CourseList" :id="'CourseList' + indexP">
                <div>
                  <div @click="goDetail(subItem)" class="CourseData" :id="index==0 ? 'CourseItem'+indexP : ''" v-for="(subItem, index) in item.productVOList" :key="index + 'product'">
                    <div class="img">
                      <img :src="subItem.mobilePhoto" />
                    </div>
                    <div class="namebox">
                      <div class="name">
                        <span class="courseName" v-if="subItem.courseName">{{subItem.courseName}}</span>
                        <span class="productName">{{subItem.productName}}</span>
                      </div>
                      <!-- <div class="title">
                        <div class="txt" v-if="subItem.productType==4">{{subItem.isExam==1?subItem.examNum +'次答题次数': '不限答题次数'}}</div>
                      </div> -->
                      <div class="money">
                        <div class="txt" v-if="subItem.productType==1">视频数量：{{subItem.classHour}}个</div>
                        <span class="price">
                          <span v-if="subItem.couponPrice != 0">￥</span>
                          <span class="present" v-if="subItem.couponPrice">{{subItem.couponPrice.toFixed(2)}}</span>
                          <span class="free" v-else>免费</span>
                          <!-- <span class="original" v-if="subItem.originalPrice && subItem.original == 1">￥{{subItem.originalPrice.toFixed(2)}}</span> -->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <noMessage class="CourseNo" v-if="commonList.list1.length==0" type=1></noMessage>
          </template>
          <template v-else-if="homeNewTab1Value == 1">
            <div class="CourseList hotCourse" v-for="(itemChild, index) in commonList.list2" :key="index">
              <!-- 热门课程 -->
              <router-link :to="'/curriculum/course?id=' + item.courseId" class="CourseData" v-for="(item, index) in itemChild" :key="index" >
                <div class="img">
                  <img :src="item.picture" />
                </div>
                <div class="namebox">
                  <div class="name">
                    <span class="courseName" v-if="item.categoryName">{{item.categoryName}}</span>
                    <span class="productName">{{item.courseName}}</span>
                  </div>
                  <div class="money">
                    <div class="txt">视频数量：{{item.classHour}}个</div>
                    <span class="price">
                      <span v-if="item.price != 0">￥</span>
                      <span class="present" v-if="item.price">{{item.price.toFixed(2)}}</span>
                      <span class="free" v-else>免费</span>
                      <!-- <span class="original" v-if="subItem.originalPrice && subItem.original == 1">￥{{subItem.originalPrice.toFixed(2)}}</span> -->
                    </span>
                  </div>
                </div>
              </router-link>
            </div>
            <noMessage class="CourseNo" style="padding-bottom:50px;" v-if="commonList.list2.length==0" type=1></noMessage>
          </template>
          <!-- <template #link>更多按钮插槽</template> -->
        </homeNewTab>
        <homeNewTab defaultValue="0" key="homeNewTab2" @change="changeHomeNewTab($event,'homeNewTab2Value')"
          :link="homeNewTab2Value == 0 && MicroClassArry[homeNewTab2Value].type == 1 ? {
            title: '更多',
            to: '/knowledge/specialArea'
          } : (homeNewTab2Value == 0 || homeNewTab2Value == 1) && MicroClassArry[homeNewTab2Value].type == 2 ? {
            title: '更多',
            to: '/curriculum/courseList?source=selected&courseCategoryId=1'
          } : {}"
          :tabList="MicroClassArry">
          <template v-if="homeNewTab2Value == 0 && MicroClassArry[homeNewTab2Value].type == 1">
            <div class="know-how-list">
              <van-tabs color="#E8F5FD" background='#E8F5FD' title-inactive-color="#A7B0B6" v-model="active">
                <van-tab class="know-how-item" :title="item.name" v-for="item in newShareData" :key="item.autismClassificationId + 'pid'">
                  <div class="autismContent">
                    <div class="scroll-x" >
                      <div
                        class="item"
                        v-for="i in item.autismVOList"
                        :key="i.autismId"
                        @click="getDetail(i.name, i.fileUrl)"
                      >
                        <div class="imgs">
                          <img :src="i.img" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </van-tab>
              </van-tabs>
              <router-link
                class="course-titler"
                :to="
                  '/knowledge/autismSpeaks?classId=' +
                  newShareData[active].autismClassificationId +
                  '&name=' +
                  newShareData[active].name
                "
              >
                <span>查看更多</span>
                <van-icon name="arrow" />
              </router-link>
            </div>
          </template>
          <template v-if="(homeNewTab2Value == 0 || homeNewTab2Value == 1) && MicroClassArry[homeNewTab2Value].type == 2">
            <div class="realList">
              <router-link :to="'/curriculum/course?id=' + item.courseId"  class="item" v-for="(item, index) in MicroClassData" :key="index">
                <div class="left" style="flex: 1;margin-right: 20px;">
                  <div class="title">{{item.courseName}}</div>
                  <div class="content">{{item.categoryName}} | 视频数量：{{item.classHour}}个</div>
                  <div class="money" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                    <span>免费</span>
                    <span class="join-btn" :class="{'join-btn-active' : item.have}">{{item.have?'已加入学习':'加入学习'}} </span>
                  </div>
                </div>
                <div class="right">
                  <img class="img" :src="item.picture" />
                </div>
              </router-link>
            </div>
          </template>
        </homeNewTab>

        <!-- 主编力推/都在看/都在学 -->
        <div class="titleH4" v-if="false">
          <div class="left">
            <homeTabs key="homeTabs1" @setValue="getClassValue" :defaultIndex="0" :arr="[
              { label: '重磅课程', value: 0 },
              { label: '热门课程', value: 1 },
            ]" />
            <!-- 都在看为电子书，暂且不上 -->
            <!-- { label: '都在看', value: 2 }, -->
          </div>
          <div class="right">
            <div class="text"> <router-link to="/curriculum/courseList?source=selected">更多</router-link></div>
            <van-icon name="arrow" color="#999" size="14" />
          </div>
        </div>

        <!-- 重磅课程 -->
        <template v-if="classIndex==0 && false">
          <div 
            v-for="(item, indexP) in commonList.list1" 
            :key="indexP" 
            style="padding: 0 18px;" 
            :class="{'arrowLeft': item.arrowLeft, 'arrowRight': item.arrowRight}">
            <div class="before" @click="onScrollLfet(-200, 'CourseList' + indexP, indexP, item.productVOList.length)"></div>
            <div class="after" @click="onScrollLfet(200, 'CourseList' + indexP, indexP, item.productVOList.length)"></div>
            <!-- arrowLeft -->
            <!-- :class="item.productVOList.length>2?'arrow':''"> -->
            <div class="CourseCategory">
              <!-- <div class="icon"></div> -->
              <div class="text">{{item.title}}</div>
            </div>
            <div class="CourseList" :id="'CourseList' + indexP" @touchend="touchendCourse(indexP, item.productVOList.length)">
              <div>
                <div @click="goDetail(subItem)" class="CourseData" :id="index==0 ? 'CourseItem'+indexP : ''" v-for="(subItem, index) in item.productVOList" :key="index + 'product'">
                  <div class="img">
                    <img :src="subItem.mobilePhoto" />
                  </div>
                  <div class="namebox">
                    <div class="name">{{subItem.productName}}</div>
                    <div class="title">
                      <div class="txt" v-if="subItem.productType==1">{{subItem.courseName}}丨视频数量：{{subItem.classHour}}个</div>
                      <div class="txt" v-if="subItem.productType==4">{{subItem.isExam==1?subItem.examNum +'次答题次数': '不限答题次数'}}</div>
                    </div>
                    <div class="money">
                      <span class="price">
                        <span v-if="subItem.couponPrice != 0">￥</span>
                        <span class="present" v-if="subItem.couponPrice">{{subItem.couponPrice.toFixed(2)}}</span>
                        <span class="free" v-else>免费</span>
                        <span class="original" v-if="subItem.originalPrice && subItem.original == 1">￥{{subItem.originalPrice.toFixed(2)}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <noMessage class="CourseNo" v-if="commonList.list1.length==0" type=1></noMessage>
        </template>

        <div style="padding: 0 18px;" v-if="false">
          <div class="hotCourseList" v-if="classIndex==1" style="margin-top:10px;">
            <!-- 热门课程 -->
            <router-link :to="'/curriculum/course?id=' + item.courseId" class="CourseData dlex" v-for="(item, index) in commonList.list2" :key="index">
              <div class="img">
                <img :src="item.picture" />
              </div>
              <div class="name">{{item.courseName}}</div>
              <div class="title">
                <div class="txt">{{item.categoryName}}丨视频数量：{{item.classHour}}个</div>
              </div>
              <div class="money">
                <span class="price">
                  <span v-if="item.price != 0">￥</span>
                  <span class="present" v-if="item.price">{{item.price.toFixed(2)}}</span>
                  <span class="free" v-else>免费</span>
                  <span class="original" v-if="item.originalPrice && item.original == 1">￥{{item.originalPrice.toFixed(2)}}</span>
                </span>
              </div>
            </router-link>
            <noMessage class="CourseNo" v-if="commonList.list2.length==0" type=1></noMessage>
          </div>
        </div>

        <!-- 新知共享/免费课程 -->
        <template v-if="MicroClassArry.length == 2 && false">
          <div class="titleH4">
            <div class="left">
              <homeTabs key="homeTabs2" @setValue="getMicroClassValue" :defaultIndex="0" :arr="MicroClassArry" />
            </div>
            <template v-if="MicroClassIndex == 0 ">
              <router-link to="/knowledge/specialArea" hover-class="none" tag="div" class="right">
                <div class="text">更多</div>
                <van-icon name="arrow" color="#999" size="14" />
              </router-link>
            </template>
            <template v-else-if="MicroClassIndex == 1">
              <router-link to="/curriculum/courseList?source=selected&courseCategoryId=1" hover-class="none" tag="div" class="right">
                <div class="text">更多</div>
                <van-icon name="arrow" color="#999" size="14" />
              </router-link>
            </template>
          </div>
          <div class="scroll-div" v-if="MicroClassShow && MicroClassArry[MicroClassIndex].childList.length">
            <template v-if="MicroClassIndex==1">
              <div class="scroll-item" :class="{'score-hover' : scoreIndex==index}" @click="onClass(index,item)"
              v-for="(item,index) in MicroClassArry[MicroClassIndex].childList" :key="index">{{item.categoryName}}</div>
            </template>
          </div>

          <!-- 新知共享 -->
          <div class="realList"  v-if="MicroClassShow && MicroClassIndex==0">
            <NewShareList :speakList="newShareData" />
          </div>
          <!-- 免费课程 -->
          <div class="realList"  v-if="MicroClassShow && MicroClassIndex==1">
            <router-link :to="'/curriculum/course?id=' + item.courseId"  class="item" v-for="(item, index) in MicroClassData" :key="index">
              <div class="left" style="flex: 1;margin-right: 20px;">
                <div class="title">{{item.courseName}}</div>
                <div class="content" style="color: #00B3C5;">{{item.categoryName}} | 视频数量：{{item.classHour}}个</div>
                <div class="money" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                  <span>免费</span>
                  <!-- <span>¥</span>
                  <span class="text">{{item.price?item.price.toFixed(2):'0.0'}}</span> -->
                  <span class="all-btn-small " :class="{'have':item.have}" style="height:20px;line-height:20px;font-size:12px;">{{item.have?'已加入学习':'加入学习'}} </span>
                </div>
              </div>
              <div class="right">
                <img class="img" :src="item.picture" />
              </div>
            </router-link>
            <noMessage v-if="MicroClassData.length==0" type=1></noMessage>
          </div>
        </template>

        <template v-else-if="MicroClassArry.length && false">
          <div class="titleH4">
            <div class="left">
              <homeTabs key="homeTabs3" @setValue="getMicroClassValue" :defaultIndex="0" :arr="MicroClassArry" />
            </div>
            <template v-if="MicroClassArry[0] && MicroClassArry[0].type == 1">
              <router-link to="/knowledge/specialArea" hover-class="none" tag="div" class="right">
                <div class="text">更多</div>
                <van-icon name="arrow" color="#999" size="14" />
              </router-link>
            </template>
            <template v-else-if="MicroClassArry[0] && MicroClassArry[0].type == 2">
              <router-link to="/curriculum/courseList?source=selected&courseCategoryId=1" hover-class="none" tag="div" class="right">
                <div class="text">更多</div>
                <van-icon name="arrow" color="#999" size="14" />
              </router-link>
            </template>
            
          </div>
          <template v-if="MicroClassArry[0] && MicroClassArry[0].type == 1">
            <div class="realList" >
              <NewShareList :speakList="newShareData" />
            </div>
          </template>
          <template v-if="MicroClassArry[0] && MicroClassArry[0].type == 2">
            <div class="scroll-div" v-if="MicroClassShow && MicroClassArry[MicroClassIndex] && MicroClassArry[MicroClassIndex].childList.length">
              <div class="scroll-item" :class="{'score-hover' : scoreIndex==index}" @click="onClass(index,item)"
                v-for="(item,index) in MicroClassArry[MicroClassIndex].childList" :key="index">{{item.categoryName}}</div>
            </div>

            <!-- 新知共享/免费课程 -->
            <div class="realList">
              <router-link :to="'/curriculum/course?id=' + item.courseId"  class="item" v-for="(item, index) in MicroClassData" :key="index">
                <div class="left" style="flex: 1;margin-right: 20px;">
                  <div class="title">{{item.courseName}}</div>
                  <div class="content" style="color: #00B3C5;">{{item.categoryName}} | 视频数量：{{item.classHour}}个</div>
                  <div class="money" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                    <span>免费</span>
                    <!-- <span>¥</span>
                    <span class="text">{{item.price?item.price.toFixed(2):'0.0'}}</span> -->
                    <span class="all-btn-small " :class="{'have':item.have}" style="height:20px;line-height:20px;font-size:12px;">{{item.have?'已加入学习':'加入学习'}} </span>
                  </div>
                </div>
                <div class="right">
                  <img class="img" :src="item.picture" />
                </div>
              </router-link>
              <noMessage v-if="MicroClassData.length==0" type=1></noMessage>
            </div>
          </template>
        </template>

        <!-- 资讯速递 -->
        <div class="titleH4 whiteBgc" style="margin-top: 8px;">
          <div class="left">
            <div class="icon"></div>
            <!-- /News -->
              <div class="text" style="font-weight: bold;">资讯速递<span class="grey"></span></div>
          </div>
        </div>
      </div>

      <!-- 要闻速递 -->
      <div style="min-height: 25vh;">
        <!-- 资讯分类 -->
        <!-- <div 
          class="LoopTab" 
          :style="{
            height:LoopTabTop<=0?54+stateHeight+'px':'',
            paddingTop:LoopTabTop<=0?stateHeight+'px':''
          }">
          <div class="loopTab_states" :style="{height:LoopTabTop<=0?stateHeight+'px':''}"></div>
          <SwiperMenu 
            v-if="visibel && tabList && tabList.length > 0" 
            :class="(LoopTabTop<=0?'top':'')" 
            :style="{top:LoopTabTop<=0?stateHeight+'px':0}"
            @setMenuCurrent="onChangeLoopTab" 
            :menuArry="tabList"
            :pageType="1"
            :listCurren="listCurren" />
        </div> -->

        <!-- 资讯分类 -->
        <div 
          class="LoopTab"
          :style="{
            height:LoopTabTop-10<=0?54+stateHeight+'px':'',
            top:LoopTabTop-10<=0?stateHeight+'px':0
          }">
          <div 
            class="newType"
            :class="(LoopTabTop<=0?'top':'')" 
            :style="{
              paddingTop:(LoopTabTop-10)<=0?10+stateHeight+'px':'',
            }">
            <van-tabs 
              ref="tabs" 
              :ellipsis="false"
              :swipe-threshold="2"
              @click="onchangeType">
              <van-tab 
                v-for="(item, index) in tabList"
                :key="index"
                :title="item.name">
              </van-tab>
            </van-tabs>
          </div>
        </div>

        <!-- 资讯列表 -->
        <div 
          ref="newList"
          v-if="tabList.length!==0"
          class="box whiteBgc" 
          :style="'min-height:'+minHeight+'px'"
          style="margin:0;">
          <!-- min-height: 100vh; -->
          <!-- 资讯列表 -->
          <div class="newList">
            <van-list
              v-model="newLoading"
              :finished="finished"
              :immediate-check="false"
              :finished-text="finishedText"
              @load="getNewList"
            >
              <div 
                class="item" 
                v-for="(i,index) in infoList" 
                :key="index"
                @click="toInformation(i)">
                <div class="title">{{i.title}}</div>
                <div class="newsContent">
                  <div class="left">
                    <div class="content">{{i.summary}}</div>
                    <div class="date">{{i.createTime.split(' ')[0]}}</div>
                  </div>
                  <div class="right" v-if="i.appImg!==''">
                    <img class="img" :src="i.appImg" />
                  </div>
                </div>
              </div>
            </van-list>
          </div>
        </div>
        <div v-if="tabList.length==0" class="box whiteBgc" style="margin:0; min-height: 40vh;">
          <div class="newList">
            <noMessage class="newNoMessage" v-if="infoList.length==0 || newLoading" type=1></noMessage>
          </div>
        </div>
      </div>

      <!-- 宣讲头条播放 -->
      <div class="" style="position: fixed;bottom: 50px;z-index: 999; width: 100%;">
        <!-- <zaudio theme="theme2"></zaudio> -->
      </div>

      <!-- 协议弹框 -->
      <van-popup v-model="showAgree" :close-on-click-overlay="false">
        <div class="agree_box">
          <div class="box_title">欢迎使用华夏云课堂</div>
          <div class="box_con">
            <p>我们非常重视您的个人信息和隐私保护，为了更好地保障您的个人权益，请充分阅读并理解<span class="light" @click="toUrl(1)">《华夏云课堂服务协议》</span>和<span
                class="light" @click="toUrl(2)">《华夏云课堂隐私协议》</span>，帮助您了解我们收集、使用、存储和共享个人信息的情况。在您使用本应用时，我们会向您申请或获取定位、相机、相册、通知等权限，请知悉。本弹窗内容相应设备权限并不会默认开启，我们会在您使用到相应业务功能时，另行弹窗征得您的同意后开启。
            </p>
            <p>此外，我们承诺会采取业界先进的安全措施保护您的信息安全。未经您的同意，我们不会主动向任何第三方泄漏您的个人信息。如您同意，请点击“同意”开始我们的服务。</p>
          </div>
          <div class="box_button">
            <div class="button_item" @click="onAgree(1)">不同意</div>
            <div class="button_item" @click="onAgree(2)">同意</div>
          </div>
        </div>
      </van-popup>

      <!-- 版本更新弹框 -->
      <van-popup v-model="showVersionUpdate" :close-on-click-overlay="false">
        <div class="versionUpdate" v-if="versionObj">
          <div class="top_box"></div>
          <div class="content_box">
            <p class="title">发现新版本<span>({{versionObj.versionName}})</span></p>
            <p class="message" v-for="(item,index) in (versionObj && versionObj.content) ? (versionObj.content.split('/')) : []" :key="index">{{index+1}}. {{item}}</p>
          </div>
          <div class="bottom_box">
            <p class="button" @click="versionUpDate">立即升级</p>
          </div>
          <div v-if="versionObj && versionObj.closeFlag" class="closeFunc" @click="showVersionUpdate = false">
            <img src="@/assets/image/closeIcon.png" alt="">
          </div>
        </div>
      </van-popup>

      <!-- 返回顶部 -->
      <!-- href="#topSwipe" -->
      <!-- v-if="LoopTabTop <= 0" -->
      <!-- @click="goTop()" -->
      <a 
        class="gotopBox" 
        v-if="LoopTabTop <= 0"
        @click="goTop()">
        <van-icon name="arrow-up" color="#fff" size="20px" />
      </a>

    </div>


  <!-- </van-pull-refresh> -->
</template>

<script>
  import { exitApp, downloadApp } from '@/assets/js/NativeWebKit.js'
  import homeTabs from "@/components/homeTabs.vue";
  // import zaudio from '@/components/uniapp-zaudio/zaudio';
  import LoopTab from "@/components/LoopTab/index.vue";
  import SwiperMenu from '@/components/swiperMenu.vue'  // 导航组件
  import noMessage from "@/components/nomessage.vue";
  import NewShareList from "./newShareList.vue";
  import homeNewTab from '@/components/homeNewTab.vue'
  import { getAppVersion } from '@/assets/js/NativeWebKit.js'

  export default {
    // 可用组件的哈希表
    components: {
      LoopTab,
      SwiperMenu, //导航组件
      homeTabs,
      noMessage,
      NewShareList,
      homeNewTab
    },
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        versionObj: null,
        AppVersion: getAppVersion(),
        active: 0,
        minHeight: 0,
        isRefreshLoading: false, //下拉加载
        stateHeight: 0, //信息条高度
        showAgree: false,
        showVersionUpdate: false,
        statusBarHeight: 0,
        thisTop: 0,
        tabIndex: 0,
        infoTab: 0,
        // audiolist: this.$zaudio.audiolist, //当前音频列表
        // playIndex: this.$zaudio.playIndex, //当前播放的索引
        // paused: this.$zaudio.paused, //当前是否暂停
        // playinfo: this.$zaudio.playinfo, //当前播放的信息
        isShow: false,
        scoreIndex: 0,
        LoopTabTop: 0,
        topComClientHeight: 0,
        bannerList:[],
        listCurren: 0, //资讯分类初始化索引
        tabList: [],
        infoList: [],
        visibel:false,

        classIndex:0, // 主编力荐 都在看学
        commonList:{
          list1:[],
          list2:[],
        },
        couserItemWidth: 0, //单个商品宽度

        MicroClassArry:[{type:0}], //专题微课 分类
        MicroClassIndex:0, //专题微课 分类
        MicroClassShow:false,
        courseCategoryId:'',  // 微课和分类 id
        MicroClassData:[], 
        newShareData: [
          {autismVOList:[]}
        ],  // 新知共享

        newCurrent: 1, //当前页数
        newSize: 10, //条数
        newLoading: false,
        finished: false,
        finishedText: '',
        messageNum:0, // 未读消息数量
        showGoTop: true, //返回顶部

        audioList: [],
        homeNewTab1Value: 0,
        homeNewTab2Value: 0,
      };
    },
    activated() {
      if(!this.$store.state.userInfo.userId){
        this.messageNum = 0;
      }
      // 判断是否需要获取缓存信息
      if (!!sessionStorage.getItem('infoObj')) {
        // 获取新闻资讯缓存信息
        this.getInfoLocalStorage()
        return
      } else {
        this.getBannerList();
      
        // 获取未读消息数量
        this.getMessageNum();

        this.getClassValue(this.classIndex)

        this.getMicroClassList(this.MicroClassIndex) // 获取专题微课 公开课分类

        this.getAudioList();
        // 资讯分类
        this.getTabList();
        
        this.$nextTick(()=>{
          document.getElementById("content").scrollTop = sessionStorage.getItem('scrollTop-home') || 0

          
          this.FunHandleScroll();
          document.getElementById("content").addEventListener("scroll", this.FunHandleScroll);

          // for(let i=0;i<document.getElementsByClassName('CourseList').length;i++){
          //   document.getElementById('CourseList'+i).scrollLeft = this.commonList.list1[i].scrollLeft
          // }
        })

      }
    },
    // 事件处理器
    methods: {
      // 轮播图点击详情
      onSwiperDetail(e){
        if(e.link){
          let reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
          if(reg.test(e.link)){ // 如果是链接
            this.$router.push('/activity/iframe?url=' + e.link + '&title=' + e.title ? e.title : undefined);
          } else {
            if(e.link.split('/')[0] == 'activity' || e.link.split('/')[1] == 'activity'){
              this.$router.push(e.link + '&title=' + e.title)
            } else {
              this.$router.push(e.link)
            }
          }
        }
      },
      // 原生跳转应用市场
      versionUpDate(){
        downloadApp(this.versionObj)
      },

      changeHomeNewTab(data,name){
        this[name] = data
        if('homeNewTab1Value' == name){
          this.getClassValue(data)
        }else if('homeNewTab2Value' == name && this.MicroClassArry[data].type == 2){
          this.getMicroClassValue(data)
        }
      },


      // 设置位置平行位移
      // onScrollLfet(leftpx, id, index, len){
      //   this.$nextTick(()=>{
      //     let sl = document.getElementById(id).scrollLeft
      //     document.getElementById(id).scrollLeft = sl + leftpx
      //     this.touchendCourse(index,len)
      //   })
      // },

      // 下拉刷新
      async onRefresh() {
        document.getElementById('styles').innerHTML = 
        `.van-pull-refresh__track {
          transform: translate3d(0px, ${this.stateHeight + 50}px, 0px) !important;
        }`
        await this.getBannerList();
        // 获取未读消息数量
        await this.getMessageNum();
        await this.getClassValue(this.classIndex)
        await this.getMicroClassList(this.MicroClassIndex) // 获取专题微课 公开课分类
        await this.getAudioList();
        // 资讯分类
        await this.getTabList();
        this.isRefreshLoading = false
        this.$Toast('刷新成功')
        document.getElementById('styles').innerHTML = 
        `.van-pull-refresh__track {
          transform: none !important;
        }`
        // 设置导航栏信息条高度
        if (this.stateHeight !== 0) {
          document.getElementById('styles').innerHTML = 
          `.van-nav-bar::before {
            display: inline-block;
            content: '';
            width: 100%;
            height: ${this.stateHeight}px;
            background-color: #fff;
          }`
        }
      },
      // 销毁滚动条绑定
      destroyScroll() {
        if (document.getElementById("content")) {
          document
            .getElementById("content")
            .removeEventListener("scroll", this.FunHandleScroll);
        }
        window.removeEventListener('scroll', this.scrollToTop)
      },
      toUrl(x){
        if(x == 1){
          this.$router.push('/all/ServiceAgreement');
        }else if(x == 2){
          this.$router.push('/all/privacyPolicy');
        }
      },
      goCourseList(){
        this.$store.commit('updatetabIndex','')
        this.$store.commit('updatescoreIndex','');
        this.$router.push('/curriculum/courseList');
      },
      onAgree(x){
        if(x==1){
          exitApp();
        }else{
          this.showAgree = false;
          let firstEntry = JSON.parse(localStorage.getItem('firstEntry'));
          localStorage.setItem('firstEntry', JSON.stringify(1));
          // 检查更新
          // this.checkForUpdates();
        }
      },
      // 跳转我的消息页面
      goMyNews(){
        if(this.$store.state.userInfo.userId){
          this.$store.commit('updatenewsActive','');
          this.$router.push('/all/myNews');
        }else{
          this.$router.push('/login/login');
        }
      },
      
      // 去音频列表
      onAudioList(){
        this.$router.push({
          path: "/propagandaHeadlines/audioDetails?xuanId=" + this.audioList.xuanId
        });
      },
      onPlayer(){
        if(!this.$store.state.userInfo.userId){
          return this.$router.push('/login/login');
        }
        if(this.$store.state.audio.id != this.audioList.xuanId){ // 不属于同一个音频
					this.$store.commit('updateAudio',{
						title: this.audioList.title,
						src: this.audioList.audioUrl,
						show: true
					})
					this.$store.state.audio.id = this.audioList.xuanId
				}else{ // 属于同一个音频
					if(this.$store.state.audio.paused){
						// 暂停中
						if(!this.$store.state.audio.src){
							this.$store.commit('updateAudio',{
								title: this.audioList.title,
								src: this.audioList.audioUrl,
								show: true
							})
						}
						this.$store.state.audio.id = this.audioList.xuanId
						this.$store.commit('play')
					}else{
						// 播放中
						this.$store.commit('pause')
					}
				}
      },
      // 音频播放
      onPlay(e){
        this.$store.commit('updateAudio',{show:true})
      },
      // 切换tab
      changeTab(index) {
        this.tabIndex = index;
        // console.log(this.tabIndex)
      },

      //模拟考试分类入口
      // toPageUrl(){
      // 	uni.navigateTo({
      // 	    url: '/pages/examination/index'
      // 	});
      // },

      toLiveList() {
        //直播列表入口
        this.$router.push({
          path: "/live/liveList"
        });
      },
      // 获取状态栏高度
      onBarHeight() {
        uni.getSystemInfo({
          success: (e) => {
            this.statusBarHeight = e.statusBarHeight;
          },
        });
      },
      // 获取主编力荐 都在看 都在学 类型
      async getClassValue(e){
        this.classIndex = e
        let url = ''
        if(e==0){
          url = "/hxclass-mobile/chief/list"
          if(this.commonList.list1.length){
            return
          }
        }
        if(e==1){
          url = "/hxclass-mobile/course/index/all-study"
          if(this.commonList.list2.length){
            return
          }
        }
        await this.$ajax({  // 都在学
          url: url,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            if(e == 0){
              this.commonList.list1 = res.data.records
              this.$nextTick(() => {
                //  单个商品宽度
                this.couserItemWidth = document.getElementsByClassName('CourseList')[0].clientWidth/2
                this.commonList.list1.map((item, index) => {
                  if (item.productVOList.length <= 2) {
                    this.$set(this.commonList.list1[index], 'arrowLeft', false)
                    this.$set(this.commonList.list1[index], 'arrowRight', false)
                  } else {
                    this.$set(this.commonList.list1[index], 'arrowLeft', false)
                    this.$set(this.commonList.list1[index], 'arrowRight', true)
                  }
                })
              })
            } else if(e == 1){
              // 将数组拆分成每三个对象为一组
              for(var i = 0; i < res.data.length; i+=3){
                this.commonList.list2.push(res.data.slice(i,i+3));
              }
           }
          }
        });
      },
      touchendCourse(index, length) {
        let courseListWidth = this.couserItemWidth * length - this.couserItemWidth * 2
        // let couserId = 'CourseList' + index //父元素
        // let courseLeft = document.getElementById(couserId).scrollLeft //距左距离
        // 隐藏向左滑动标识
        if (courseLeft == 0) {
          this.$set(this.commonList.list1[index], 'arrowLeft', false)
        } else {
          this.$set(this.commonList.list1[index], 'arrowLeft', true)
        }
        // 隐藏向右滑动标识
        if (courseLeft >= courseListWidth) {
          this.$set(this.commonList.list1[index], 'arrowRight', false)
        } else {
          this.$set(this.commonList.list1[index], 'arrowRight', true)
        }
      },
      // 主编力荐详情
      goDetail(item){
        // console.log(item.productType)
        if(item.productType == 1) {
          this.$router.push({  // 课程
            path: "/curriculum/course?id="+item.courseId
          });
        } else if (item.productType == 2 || item.productType == 3) { // 图书或教具
          this.$router.push({
            path: "/curriculum/BookDdetails?id="+item.productId
          });
        }else if (item.productType == 4) { // 图书或教具
          this.$router.push({
            path: "/examination/examDetail?id=" + item.configId
          });
        }
        // :to="'/curriculum/course?id=' + subItem.courseId"
      },
      // 商品类型处理
      // FunsproductType(type){
      //   let arry = ['课程','图书','教具','模拟考试','电子照片','延期','补考','直播','其他']
      //   return arry[type - 1]
      // },
      // 获取专题微课分类
      async getMicroClassList(e){
        await this.$ajax({ 
          url: '/hxclass-mobile/course/ztwk-gkk/tab',
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.MicroClassShow = false
            if(res.data.length){
              
              let arry = []
              res.data.forEach(element => {
                if(element.haveCourse == 1){
                  arry.push(element)
                }
              });
              this.MicroClassArry = []
              arry.forEach((element,i) => {
                this.MicroClassArry.push({
                  type: element.type,
                  label:element.categoryName,
                  value: i,
                  courseCategoryId:element.courseCategoryId,
                  childList: element.childList ? element.childList : []
                })
              });

              if(this.MicroClassArry[this.MicroClassIndex].childList.length > 0){
                this.courseCategoryId = this.MicroClassArry[this.MicroClassIndex].childList[0].courseCategoryId  // 有子集用子集id
              } else {
                this.courseCategoryId = this.MicroClassArry[this.MicroClassIndex].courseCategoryId  // 无子集用父级id
              }

              // if(this.MicroClassArry[0].childList.length > 0){
              //   this.courseCategoryId = this.MicroClassArry[0].childList[0].courseCategoryId  // 有子集用子集id
              // } else {
              //   this.courseCategoryId = this.MicroClassArry[0].courseCategoryId  // 无子集用父级id
              // }
              
              this.$nextTick(()=>{
                  this.MicroClassShow = true
              })

              if (this.MicroClassIndex == 0) {
                this.getNewShareData() // 查询新知共享
              } else if (this.MicroClassIndex == 1) {
                this.getMicroClassData()  // 查询课程列表
              }
              // if(arry.length == 2){  // 如果有新知共享
              //   this.getNewShareData()
              // } else if (arry.length == 1) {
              //   if(arry[0].categoryName == '免费课程') {
              //     this.getMicroClassData()  // 查询课程列表
              //   } else if (arry[0].categoryName == '新知共享') {
              //     this.getNewShareData() // 查询新知共享
              //   }
              // }
            } else {
              this.MicroClassShow = false
            }
          }
        });
      },
      getDetail(name, url) {
        this.$router.push({
          path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
        });
      },
      // 新知共享
      getNewShareData(){
        this.$ajax({ 
          url: '/hxclass-mobile/autism/classification/first/list',
        }).then((res) => {
          if (res.code == 200 && res.success && res.data && res.data.length > 0) {
            this.newShareData = res.data
          } else {
            this.newShareData = [{autismVOList:[]}]
          }
        });
      },
      // 免费课程
      getMicroClassData(){
        this.$ajax({ 
          url: '/hxclass-mobile/course/ztwk-gkk',
          params:{
            courseCategoryId: this.courseCategoryId
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.MicroClassData = res.data
          } else {
            this.MicroClassData = []
          }
        });
      },
      
      onClass(i, e){
        this.scoreIndex = i
        this.courseCategoryId = e.courseCategoryId
        this.getMicroClassData()  // 查询课程列表
      },
      getMicroClassValue(e){
        this.MicroClassIndex = e
        this.scoreIndex = 0
        if(this.MicroClassArry[e].childList.length){
          this.courseCategoryId = this.MicroClassArry[e].childList[e].courseCategoryId  // 有子集用子集id
        } else {
          this.courseCategoryId = this.MicroClassArry[e].courseCategoryId  // 无子集用父级id
        }

        if(this.MicroClassArry.length == 2 && e==1){
          this.getMicroClassData()  // 查询课程列表
        } else if ( this.MicroClassArry.length == 1 && e==0 ) {
          this.getMicroClassData()  // 查询课程列表
        }
      },
      // 初始化
      init() {
        this.$nextTick(() => {
          // const query = uni.createSelectorQuery().in(this);
          // query.select('.tops').boundingClientRect(data => {
          // 	this.$set(this,'thisTop',data.top)
          // }).exec();
        });
      },
      // 轮播图
      async getBannerList(){
        await this.$ajax({
          url: "/hxclass-mobile/rotation/app/1",
        }).then((res) => {
          if (res.code == 200 && res.success) {
           this.bannerList = res.data
          }
        });
      },
      // 资讯分类tab
      async getTabList() {
        await this.$ajax({
          url: "/hxclass-mobile/news/type/app/1",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.tabList = res.data.map((item) => {
              return {
                name: item.newsTypeName,
                id: item.newsTypeId
              };
            });
            // this.tabList = res.data
            this.visibel = true
            // 判断是否需要获取缓存信息
            if (!!sessionStorage.getItem('infoObj')) {
              // 获取新闻资讯缓存信息
              this.getInfoLocalStorage()
              return
            }
          }
        });
      },
      // 资讯分类切换
      onchangeType(e) {
        // console.log(e)
        // 页面滚到指定位置
        const newListTop = this.$refs.newList.offsetTop + -47
        const docTop = document.getElementById("content").scrollTop
        // const top = docTop < newListTop ? docTop : newListTop
        if(newListTop > docTop){
          document.getElementById("content").scrollTop = docTop
        }else{
          document.getElementById("content").scrollTop = newListTop
        }
        
        this.infoTab = e;
        // 初始化
        this.infoList = []
        this.newCurrent = 1
        this.finished = false
        this.loading = false
        // 处理切换tab不加载情况
        this.$nextTick(() => {
          document.getElementById("content").scrollTop = document.getElementById("content").scrollTop - 1
          let timerScroll = setTimeout(() => {
            document.getElementById("content").scrollTop = document.getElementById("content").scrollTop + 1
          }, 0);
        })
      },
      // 资讯分类切换
      // onChangeLoopTab(e) {
      //   // 页面滚到指定位置
      //   const newListTop = this.$refs.newList.offsetTop + -47
      //   const docTop = document.getElementById("content").scrollTop
      //   // const top = docTop < newListTop ? docTop : newListTop
      //   if(newListTop > docTop){
      //     document.getElementById("content").scrollTop = docTop
      //   }else{
      //     document.getElementById("content").scrollTop = newListTop
      //   }
        
      //   this.infoTab = e;
      //   // clearTimeout(this.tiemID);
      //   // this.tiemID = setTimeout(() => {
      //     // 初始化
      //     this.infoList = []
      //     this.newCurrent = 1
      //     this.finished = false
      //     this.loading = false
      //   // }, 500);
      // },
      // 资讯列表
      getNewList() {
        let hei = this.$refs.newList.offsetHeight
        const contentHei = document.getElementById('content').offsetHeight
        if(this.minHeight < hei){
          this.minHeight = hei
        }else{
          this.minHeight = contentHei + -47
        }
        

        // const scrollTops = this.$refs.newList.offsetTop;
        this.$ajax({
          url: "/hxclass-mobile/news/app",
          params: {
            newsTypeId: this.tabList[this.infoTab].id, //分类id
            // newsTypeId: 5, //分类id
            current: this.newCurrent, //页数
            size: this.newSize, //条数
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.infoList = this.infoList.concat(res.data.records)
            this.newCurrent ++
            this.newLoading = false
            // 加载完成
            if (this.infoList.length == res.data.total) {
              this.finished = true
              this.finishedText = '没有更多了'
            }
            // 无数据
            if (this.infoList.length == 0) {
              this.finished = true
              this.finishedText = ''
            }
          }
          this.$nextTick(()=>{
            let hei = this.$refs.newList.offsetHeight
            const contentHei = document.getElementById('content').offsetHeight
            if(this.minHeight < contentHei){
              this.minHeight = hei
            }else{
              this.minHeight = contentHei + -47
            }
          })
        });
      },
      // 资讯详情
      toInformation(i) {
        // 记录资讯列表、资讯分类
        // infoTab this.newCurrent, //页数  this.newSize, //条数
        let infoObj = {
          infoTab: this.infoTab, //分类index
          newCurrent: this.newCurrent, //列表页数
          finished: this.finished, //是否加载完成
          finishedText: this.finishedText, //加载完成文案
          infoList: this.infoList, //列表
          infoTop: document.getElementById("content").scrollTop, //距离顶部高度
        }
        sessionStorage.setItem('infoObj', JSON.stringify(infoObj))
        //资讯速递入口
        this.$router.push({
          path: "/info/information?id="+i.newsId
        });
      },
      // 获取资讯缓存信息
      getInfoLocalStorage() {
        let infoObj = JSON.parse(sessionStorage.getItem('infoObj'))
        this.infoTab = infoObj.infoTab //分类index
        this.listCurren = infoObj.infoTab //分类index
        this.finished = infoObj.finished //是否加载完成
        this.finishedText = infoObj.finishedText //加载文案
        this.newCurrent = infoObj.newCurrent //加载页数
        this.infoList = infoObj.infoList //列表
        this.$nextTick(() => {
          // let infoTimer = setTimeout(() => {
            document.getElementById("content").scrollTop = infoObj.infoTop //距离顶部高度
          // }, 300)
        })
        // 删除缓存
        sessionStorage.removeItem('infoObj')
      },
      // 跳转搜索页面
      gosearch() {
        this.$store.commit('updateword','');
        this.$store.commit('updateIndex','');
        this.$router.push({
          path: "/search/globalSearch",
        });
      },
      // 页面滚动监听
      FunHandleScroll() {
        this.$nextTick(()=>{
          const topsOffsetTop = document.getElementsByClassName("tops")[0].offsetTop;
          const topComClientHeight = document.getElementsByClassName("topCom")[0].clientHeight;
          const scrollTop = document.getElementById("content").scrollTop;
          const LoopTab = document.getElementsByClassName("LoopTab")[0].offsetTop;
          sessionStorage.setItem('scrollTop-home',scrollTop)
          if(this.tabList[this.infoTab]){
            this.tabList[this.infoTab].scrollTop = scrollTop
          }
          this.$set(this, "thisTop", topsOffsetTop - scrollTop);
          const LoopTabTop = LoopTab - scrollTop;
          this.$set(this, "topComClientHeight", topComClientHeight);
          this.$set(this, "LoopTabTop", LoopTabTop);

          // for(let i=0;i<document.getElementsByClassName('CourseList').length;i++){
          //   this.commonList.list1[i].scrollLeft = document.getElementById('CourseList'+i).scrollLeft
          // }

          // 返回顶部按钮
          if (LoopTabTop <= 0) {
            this.showGoTop = true
          }
        })
      },
      // 获取未读消息数量
      async getMessageNum(){
        if(!this.$store.state.userInfo.userId){
          return
        }
        await this.$ajax({
          url:'/hxclass-mobile/user/msg/app/num',
          method:'get'
        }).then(res=>{
          if (res.code == 200 && res.success) {
            this.messageNum = res.data;
          }else{
            this.$Toast(res.message);
          }
        })
      },
      // 返回顶部
      goTop() {
        document.getElementById("content").scrollTop = 0
      },
      async getAudioList(){
				await this.$ajax({
					url: '/hxclass-mobile/speak/app/index',
				}).then(res=>{
					if(res.code == 200 && res.success){
						this.audioList= res.data
					}
				})
			},
      // 检查更新
      // checkForUpdates() {
      //   this.$ajax({
      //     url: '/hxclass-mobile/score/app/newest',
      //     params: {
      //       phoneSystem: this.AppVersion.phoneSystem, // 1.android 2.ios
      //       versionNo: this.AppVersion.code,
      //       versionName: this.AppVersion.name
      //     }
      //   }).then(res=>{
      //     if(res.code == 200 && res.success){
      //       if(!res.data.newest){
      //         this.showVersionUpdate = true;
      //       } else {
      //         return ;
      //       }
      //       this.versionObj = res.data;
      //     }
      //   })
      // }
    },
    // 生命周期-实例创建完成后调用
    created() {
      sessionStorage.removeItem('scrollTop-home')

      this.getBannerList();
      
      // 获取未读消息数量
      this.getMessageNum();

      this.getClassValue(0)

      this.getMicroClassList() // 获取专题微课 公开课分类

      this.getAudioList();
      
      // 信息条高度
      this.stateHeight = parseInt(this.$parent._data.stateHeight)
      
    },
    // 开启缓存后离开页面
    deactivated() {
      // 销毁滚动条滚动
      this.destroyScroll()
    },
    // 生命周期-实例挂载后调用
    mounted() {
      // 资讯分类
      this.getTabList();
      // 首次登录
      let firstEntry = JSON.parse(localStorage.getItem('firstEntry'));
      if(!firstEntry && !this.$store.state.userInfo.isLogin){
        this.showAgree = true;
        // localStorage.setItem('networkPlayState', 1) //允许移动网络下播放视频 1允许 0不允许
      }else{
        // 检查更新
        // this.checkForUpdates();
      }
      this.FunHandleScroll();
      document
        .getElementById("content")
        .addEventListener("scroll", this.FunHandleScroll);
    },
    // beforeDestroy(){
    //   // 销毁滚动条滚动
    //   this.destroyScroll()
    // },
    // 生命周期-实例销毁离开后调用
    destroyed() {
      // 销毁滚动条滚动
      this.destroyScroll()
    },
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
  };

</script>

<style>
  page{
  	background-color: #F7F7F9;
  }
</style>
<style lang="less" scoped>
  .have{
    background: #8ADBEE;
  }
  /deep/.van-popup {
    background-color: transparent;
  }

  /deep/.van-overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }

  // 轮播图
  /deep/.van-swipe {
    position: absolute;
    // overflow: visible;
    overflow: hidden;
    width: 100%;
    // top: 0;
    // left: 0;
  }
  /deep/.van-swipe__indicators {
    bottom: 96px;
  }
  /deep/.van-swipe__indicator {
    width: 14px;
    height: 3px;
    border-radius: 1px;
  }
  /deep/.LoopTabWrapper{
    padding: 7px 0 0 !important;
  }
  /deep/.tablink{
    top: 0 !important;
  }

  // 切换框
  .tab {
    font-family: PingFangMedium;
    border-radius: 0 0 22.5px 22.5px;
    padding: 11.5px 0;
    overflow: auto;

    >view {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .tab_item {
        font-size: 14.5px;
        font-weight: bold;
        color: #666666;
        margin-right: 23.5px;

        &.pick {
          font-size: 16.5px;
          font-weight: bold;
          color: #000000;
          background-image: url(../../assets/image/examination/certificate_icon1.png);
          background-size: 36.5px;
          background-repeat: no-repeat;
          background-position: bottom center;
        }
      }
    }
  }

  .topBanner {
    // background-color: #fff;
    position: relative;
    max-width: 100%;
    overflow: hidden;

    .banner {
      width: 100%;
      // height: 220px;
      height: 270px;
      background-repeat: no-repeat;
      object-fit: cover;
    }

    .bannerHei {
      height: 166px;
      background-color: #fff;
      display: block;
    }

    .tops {
      // height: 78px;
      position: relative;

      .topCom {
        height: 58px;
        width: 100%;
        z-index: 99;
        position: relative;

        &.top {
          top: 0;
          position: fixed;
          left: 0;
          right: 0;
          box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
          transition: all 0.3s;
          background-color: #fff;
        }

        .seo {
          padding: 0 14px 0 18px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
          display: flex;
          flex-direction: row;
          z-index: 3;

          .seoInput {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: row;
            margin-right: 13px;

            .magnifier {
              width: 16px;
              position: absolute;
              left: 14px;
              top: 50%;
              transform: translateY(-50%);
            }

            .input {
              border: 0.5px solid rgba(#333, 0.1);
              box-sizing: border-box;
              background-color: rgba(#fff, 0.75);
              border-radius: 20px;
              box-shadow: 0px 0.5px 2px 0.5px rgba(0, 0, 0, 0.05);
              height: 36px;
              font-size: 12px;
              padding-left: 38.5px;
              flex: 1;
              appearance:none;
            }

            .scan {
              width: 23px;
              position: absolute;
              right: 13px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .news {
            position: relative;
            width: 35px;
            height: 35px;
            background-color: rgba(#fff, 0.75);
            box-shadow: 0px 0.5px 2px 0.5px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
              width: 25px;
            }

            // &.pick::after {
            //   position: absolute;
            //   top: 4px;
            //   right: 6px;
            //   display: inline-block;
            //   content: "";
            //   width: 6px;
            //   height: 6px;
            //   border-radius: 50%;
            //   background: #EC6C01;
            // }
          }
        }
      }
    }

    .class_box {
      position: relative;
      z-index: 10;
      background: #fff;
      padding-top: 10px;
      // display: none;
      border-top-right-radius: 41px;
      &::after {
        position: absolute;
        top: -40px;
        content: '';
        display: inline-block;
        width: 41px;
        height: 41px;
        background-image: url(../../assets/image/20220616-143235.png);
        background-position: left bottom;
        background-size: 100%;
        // border-bottom-left-radius: 41px;
        // background-image: radial-gradient(41px at top right, transparent 41px, #fff)
      }
    }
    .ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 5px 20px 4px 20px;

      &.nthChild {
        padding-top: 0;
        margin-top: calc(20px + var(--status-bar-height));
      }

      .li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
          img {
            width: 34px;
          }
        }

        .title {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #333;
        }
      }
    }
  }

  .box {
    margin: 9px 0;
  }

  .whiteBgc{
    background-color: #fff;
  }
  .speak{
    padding: 13px 18px 17px;
    margin-top: 8px;
  }

  .titleH4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 18px 6px;
    line-height: 25px;
    font-weight: bold;

    .left {
      flex: 1;
      display: flex;
      flex-direction: row;
    font-family:  PingFangMedium;
      align-items: baseline ;
      font-weight: 400;
      .icon {
        background-color: @theme;
        display: block;
        width: 3px;
        height: 13px;
        border-radius: 1.5px;
        margin-right: 4px;
      }

      .text {
        font-size: 16.5px;
        color: #333;

        .grey{
          font-size: 10px;
          font-weight: 300;
          font-family: Light;
          color: #999999;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text {
    font-family:  PingFangMedium;
        font-size: 12px;
        color: #999;
        font-weight: 400;
        a{
          color: #999;
        }
      }
    }
  }

  .CourseCategory {
    font-size: 14px;
    line-height: 22px;
    font-family:  PingFangMedium;
    // font-family:  PingFang SC-Medium, PingFang SC;;
    font-weight: bold;
    color: #333;
    padding: 0px 5px 0;
  }

  .CourseNo{
    margin: 20px auto;
  }

  .hotCourseList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .CourseData{
      margin: 6px 0px;
    }
  }

  .arrowLeft{
    position: relative;
    .before{
      position: absolute;
      left: 0;
      top: 50%;
      width: 18px;
      height: 46px;
      transform: translateY(-50%);
      background-image: url('./../../assets/image/Union2.png');
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: 9;
      // animation: fade-in-right 1.6s infinite ;
    }
  }
  .arrowRight {
    position: relative;
    .after{
      position: absolute;
      width: 18px;
      height: 46px;
      transform: translateY(-50%);
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 50%;
      left: auto;
      right: 0;
      background-image: url('./../../assets/image/Union1.png');
      z-index: 9;
      // animation: fade-in-left 1.6s infinite ;
    }
  }
  @keyframes fade-in-right {
    0% {
      transform: translateX(10px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-left {
    0% {
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .CourseList {
    padding: 0px 5px 10px 5px;
    width: 100%;
    overflow-y: scroll;
    white-space: nowrap;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    box-sizing: border-box;
  }
  .hotCourse{
    padding: 0 5px 10px 5px;
    .CourseData{
      margin: 0 7px 5px 7px
    }
  }
  .CourseList::-webkit-scrollbar { width: 0 !important }

  .CourseData {
    vertical-align: top;
     box-shadow: 0px 2.5px 2px 0.5px rgba(0, 0, 0, 0.05);
     border-radius: 5px 5px 9px 9px;
     display: inline-block;
     width: 54.4vw;
     margin: 5px 7px;
     background-color: #fff;
     .img{
       width: 100%;
       height: 117px;
      //  margin-bottom: 7px;
       img {
         width: 100%;
         height: 100%;
         background-repeat: no-repeat;
         object-fit: cover;
         border-radius: 5px 5px 0 0;
       }
     }
     .namebox{
       display: flex;
       flex-direction: column;
      //  justify-content: space-between;
     }
     .title {
       margin: 0 9px;
       color: #666666;
       font-size: 10px;
       display: flex;
       flex-direction: row;
       white-space: normal; 
       flex: 1;
       .icon {
         width: 40px;
         height: 17px;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
         font-size: 11px;
         background-image: url(../../assets/image/2022012815191.png);
         background-position: center;
         background-size: 100% 100%;
         color: #f0a300;
       } 
       .txt {
         line-height: 17px;
       }
     } 
     .name {
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #333333;
      // min-height: 32px;
      line-height: 18px;
      //  font-weight: bold;
      //  margin: 12px 9px 4px 9px;
      padding: 8px;
       white-space: normal;
       overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       /*! autoprefixer: off */
       -webkit-box-orient: vertical;
       display: flex;
       flex-direction: row;
       .courseName{
        font-size: 9px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #37C6E9;
        // line-height: 14px;
        height: 16px;
        background: #EEFCFF;
        box-shadow: 1px 1px 1px 0px rgba(191,230,236,0.5);
        border-radius: 1px 1px 1px 1px;
        display: inline-block;
        padding: 0 2px;
        margin-right: 4px;
        flex: none;
        box-sizing: border-box;
       }
       .productName{
          font-size: 13px;
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
       }
     } 
     .money {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       font-size: 12px;
       margin :2px 10px 10px;
       align-items: center;
      //  margin-top: 3.5px;
       line-height: 19px;
       font-weight: 500;
       font-family: PingFangMedium; 
       // .icon {
       //   font-size: 10px;
       // } 
       .txt {
         font-size: 11px;
         font-weight: 400;
         font-family: PingFangMedium;
         color: #333;
        font-family: PingFang SC-Regular, PingFang SC;
       }
       .price_box {
         display: flex;
         margin-top: 12px;
       }

      .price{
        font-size: 12px;
        font-weight: 400;
        color: #EC6C01;
        line-height: 14px;
        font-family: PingFangMedium;
        .present{
          font-family: PingFang SC-Regular, PingFang SC;
          font-size: 16px;
          font-weight: 400;
        }
        .free{
          font-size: 14px;
        }
        .original{
          margin-left: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          text-decoration: line-through;
          font-family: PingFangMedium;
        }
      }
    }
  }
  .CourseData:first-child{
    margin-left: 0;
  }
  .CourseData:last-child{
    margin-right: 18px;
  }

  // 资讯分类
  .newType {
    transition: all .4s;
    position: relative;
    padding: 10px 18px;
    background-color: #fff;
    z-index: 199;
    &.top {
      top: 0;
      position: fixed !important;
      left: 0;
      right: 0;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
      transition: all 0.3s;
      background-color: #fff;
      z-index: 99 !important;
    }
    /deep/.van-tabs--line .van-tabs__wrap {
      height: auto;
    }
    /deep/.van-tab {
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;
      // flex: inherit;
      .van-tab__text {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7B797A;
        line-height: 20px;
        background-color: #F8F8F8;
        border-radius: 13px;
        margin-right: 13px;
        padding: 4px 17px;
      }
      &.van-tab--active {
        .van-tab__text {
          position: relative;
          z-index: 10;
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 23px;
          background-color: #E8F5FD;
          padding: 2px 17px;
          &::after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 50%;
            margin-left: -13.5px;
            display: inline-block;
            width: 27px;
            height: 7px;
            background: #A0E0F2;
            border-radius: 3px 3px 3px 3px;
            z-index: -1;
          }
        }
      }
    }
    /deep/.van-tabs__line {
      display: none;
    }
    /deep/.van-tabs__nav--line {
      padding: 0;
    }
    // /deep/.van-tabs__nav--line {
    //   overflow: auto;
    // }
  }

  .newList {
    padding: 0 18px;
    .item {
      box-sizing: border-box;
      padding: 12.5px 0;
      border-bottom: 0.5px solid #eee;

      &:last-child {
        border-bottom: none;
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #444444;
        font-family: PingFang SC-Regular, PingFang SC;
        line-height: 22px;
        margin-bottom: 9px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .newsContent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .content {
            font-size: 11px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            // word-wrap: break-word;
            word-break:break-all; 
            width: 100%;
          }

          .date {
            margin-top: 5px;
            font-size: 9px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }
        }

        .right {
          .img {
            width: 89px;
            height: 67px;
            border-radius: 5px;
          }
        }
      }
    }
    .newNoMessage {
      // height: 100vh;
      padding-top: 30px;
    }
  }

  .liveBroadcast {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10.5px;

    >.left {
      margin-right: 12.5px;

      .image {
        // width: 114px;
        width: 126px;
        height: 89px;
        // height: 74.5px;
        background: #c4c4c4;
        box-shadow: 0px 2.5px 2px 0.5px rgba(0, 0, 0, 0.1);
        border-radius: 2.5px;
      }
    }

    >.right {
      flex: 1;

      .branch {
        display: flex;
        flex-direction: row;
        align-items: center;

        >.left {
          flex: 1;

          .state {
            border-radius: 5px;
            width: 60px;
            height: 21px;
            background-color: rgba(#ffc243, 0.2);
            color: #f0a300;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }

          .name {
            font-size: 14.5px;
            color: #333;
            line-height: 21.5px;
            margin: 9px 0 7px;
            font-weight: bold;
          }
        }
      }

      .time {
        font-size: 11px;
        color: #666;
      }
    }
  }

  .frequency {
    position: relative;
    height: 91.5px;
    // width: 339px;
    // background: #f7fbfc;
    box-shadow: 0px 2.5px 2.5px 0.5px rgba(0, 0, 0, 0.1);
    margin-top: 14px;
    background: #fff9ea;


    // &::before {
    //   content: "";
    //   position: absolute;
    //   z-index: 9;
    //   display: block;
    //   left: 90px;
    //   width: 0;
    //   height: 0;
    //   border-top: 91.5px solid transparent;
    //   border-left: 24px solid #f7fbfc;
    // }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   right: 0;
    //   bottom: 0;
    //   top: 0;
    //   left: 90px;
    //   background: #fff9ea;
    //   display: block;
    // }

    // .user {
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   height: 98.5px;
    // }

    .words {
      position: absolute;
      right: 70px;
      left: 16px;
      top: 12px;
      bottom: 11px;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      font-weight: 500;
      font-family:  PingFangMedium;
      z-index: 2;

      .title {
        max-width: 90%;
        font-family:  PingFangMedium;
        font-size: 14px;
        color: #444444;
        line-height: 22px;  
        display: -webkit-box;//对象作为弹性伸缩盒子模型显示
        overflow: hidden;//溢出隐藏
        -webkit-box-orient: vertical;//设置伸缩盒子对象的子元素的排列方式
        -webkit-line-clamp: 1;//设置 块元素包含的文本行数
      }

      .tree {
        font-weight: 400;
        font-size: 13px;
        color: #15B7DD;
        margin: 6px 0 10px 0;
        // line-height: 19px;
        align-items: center;
        display: flex;
        img{
          margin-right: 2px;
          width: 12px;
          vertical-align: baseline;
        }
      }
      .num {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;
        line-height: 16px;
      }
    }

    .player {
      position: absolute;
      z-index: 9;
      right: 0;
      top: 0;
      overflow: hidden;
      width: 72px;
      height: 91px;

      .back {
        width: 51px;
      }

      .icon {
        height: 15px;
        position: absolute;
        left: 21px;
        bottom: 39px;
      }

      .rod {
        width: 42.5px;
        position: absolute;
        right: -3px;
        bottom: 5px;
      }
    }
     .speakf{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 62px;
        height: 53px;
      }

       .speaks{
        position: absolute;
        left: 173px;
        bottom: 0px;
        width: 90px;
        height: 42px;
      }

       .speakt{
        position: absolute;
        right: -6px;
        top: 0px;
        width: 80px;
        height: 42px;
      }
  }

   /deep/.imgs {
      width: 100%;
      max-height: 135px;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }

  .realList {
    padding: 0px 35px 30px 18px;
    .autismContent {
      padding: 8px 17px;
      .scroll-x {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
      }
      .item {
        flex: none;
        width: calc((100% - 34px) / 3);
        overflow: hidden;
        margin-right: 17px;
        &:last-child {
          margin-right: 0;
        }
        .imgs {
          width: 100%;
          overflow: hidden;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          margin-top: 10px;
          font-size: 14px;
          line-height: 14px;
          color: #333;
          font-weight: 500;
          font-family:  PingFangMedium;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 0.5px solid #eee;
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .left {
        margin-right: 5px;
        .title {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 5px;
          color: #333;
          font-weight: 500;
          font-family:  PingFangMedium;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .content {
          color: #ACB1B2;
          font-size: 11px;
          margin-bottom: 8px;
          line-height: 19px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .money {
          margin-top: 5.5px;
          color: #EC6C01;
          font-size: 16px;
          line-height: 15px;
          font-weight: 500;
          .join-btn{
            font-size: 12px;
            line-height: 27px;
            text-align: center;
            font-weight: 500;
            width: 78px;
            height: 27px;
            color: #fff;
            background: #15B7DD;
            border-radius: 14px;
          }
          .join-btn-active{
            background: #82D8ED;
          }
        }
      }

      .right {
        position: relative;
        width: 130px;
        height: 80px;
        border-radius: 1px 7px 7px 1px;
        background-color: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        .img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }

  .know-how-list{
    padding-bottom: 14px;
    width: calc(100% - 18px);
    position: relative;
    .course-titler{
      line-height: 44px !important;
      position: absolute;
      top: 0;
      right: 18px;
      font-size: 12px;
      font-family: PingFangMedium;
      // font-weight: 500;
      color: #999999;

      img {
        width: 13px;
        margin-left: 5px;
        vertical-align: sub;
      }
    }
    /deep/.van-tabs{
      background: #E8F5FD;
      border-radius: 10px;
    }
    /deep/.van-tabs__wrap{
      border-top-left-radius: 10px;
      background: #E8F5FD;
      width: calc(100% - 110px);
      .van-tab{
        max-width: 100px;
      }
    }
  }
  .know-how-item {
    padding: 16px 20px;
    margin-right: 18px;
    padding-top: 0;
    // margin-bottom: 10px;
    .course-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      margin-bottom: 10px;
    }
    .autismContent {
      .scroll-x {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        padding-right: 10px;
        padding-bottom: 10px;
      }
      .item {
        width: 92.5px;
        flex: none;
        // overflow: hidden;
        margin-right: 17px;
        &:last-child {
          margin-right: 0;
        }
        .imgs {
          width: 100%;
          border-radius: 0px !important;
          height: 135px;
          overflow: hidden;
          box-shadow: 5px 5px 4px 1px rgba(0, 0, 0, 0.05);
          // border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            border-radius: 0px !important;
            object-fit: cover;
          }
        }
        .title {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.4;
          color: #333;
          font-weight: 400;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  .scroll-div {
    white-space: nowrap;
    padding: 10px 0 0;
    overflow: auto;
    .scroll-item {
      display: inline-block;
      text-align: center;
      padding-bottom: 7.5px;
      margin-right: 24px;
      font-size: 13px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 19.5px;
    }

    .score-hover {
      color: #15b7dd;
      position: relative;
    }

    .score-hover::after {
      content: "";
      display: inline-block;
      background: url(../../assets/image/icon/20220218041506.png) no-repeat;
      background-size: 100%;
      width: 5.5px;
      height: 6.5px;
      position: absolute;
      right: -4px;
      bottom: 7px;
    }
  }

  // .LoopTab {
  //   background-color: #fff;
  //   z-index: 999;
  //   position: relative;
  //   height: 36px;
  //   .menuSwiper-box{
  //     background: url('../../components/LoopTab/img/tablink.png');
  //     /deep/.swiper-slide{
  //       margin-top: 13px;
  //       font-size: 14px;
  //       color: #333333;
  //     }
  //     /deep/.swiper-slide-active {
  //       margin-top: 18px;
  //       font-size: 15px;
  //       font-family: PingFangMedium;
  //       font-weight: bold;
  //       color: #333333;
  //     }
  //   }

  //   .top {
  //     top: 0;
  //     position: fixed !important;
  //     left: 0;
  //     right: 0;
  //     box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  //     transition: all 0.3s;
  //     background-color: #fff;
  //     z-index: 99 !important;
  //   }
  // }
  .loopTab_states {
    position: fixed;
    top: 0;
    width: 100%;
    height: 0;
    background-color: #fff;
  }

  /deep/.van-badge--fixed {
    right: 5px;
    top: 5px;
  }

  // 版本更新
  .versionUpdate{
    box-sizing: border-box;
    min-width: 305px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // background: #ffffff;
    .top_box{
      width: 100%;
      height: 83px;
      background-image: url('~@/assets/image/versionTop.png');
      background-size: 100% 100%;
    }
    .content_box{
      flex: 1;
      background-image: url('~@/assets/image/versionContent.png');
      background-size: 100% 100%;
      padding: 0 22px;
      margin-top: -1px;
      margin-bottom: -1px;
      .title{
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
        // margin-bottom: 6px;
        span{
          font-size: 13px;
        }
      }
      .message{
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-top: 7px;
      }
      .message:nth-child(2){
        margin-top: 0;
      }
    }
    .bottom_box{
      height: 140px;
      background-image: url('~@/assets/image/versionBottom.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .button{
        width: 184px;
        height: 45px;
        background: #FFFFFF;
        border-radius: 24px 24px 24px 24px;
        border-radius: 22.5px;
        text-align: center;
        line-height: 45px;
      }
    }
    .closeFunc{
      padding-top: 44px;
      text-align: center;
      img{
        width: 29px;
        height: 29px;
      }
    }
  }

  // 协议条款
  .agree_box {
    box-sizing: border-box;
    min-width: 305px;
    background: #ffffff;
    border-radius: 10px;
    padding: 14px 16px;

    .box_title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
      text-align: center;
    }

    .box_con {
      font-size: 13px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-top: 11px;

      p {
        text-indent: 2rem;

        .light {
          color: #15b7dd;
        }
      }
    }

    .box_button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 11px;
      // padding-left: 13px;

      .button_item {
        font-size: 14px;
        font-weight: 500;
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        &:nth-child(1) {
          color: #434343;
          background-color: #F5F5F5;
        }

        &:nth-child(2) {
          color: #ffffff;
          background: #15b7dd;
        }
      }
    }
  }

  // 返回顶部
  .gotopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .box_con {
      font-size: 14px;
      color: #fff;
      margin-top: px;
    }
  }
.dlex{
    display: flex;
    flex-direction: column;
}
</style>
