<template>
  <!-- 学习视频 -->
  <div class="courseVideo">
    <div class="left-close">
      <img src="@/assets/image/audio/close.png" alt="">
    </div>
    <!-- 目录 -->
    <forTree class="tree" :tree="tree" @nodeClick="treeClick" :have="this.form.have" :sectionId="idHover" />
  </div>
</template>

<script>
import { courseVideo } from '@/unit/courseVideo.js'
import forTree from "@/components/videoTree/index.vue";
import {FunPlay,toast, FunGetSectionId} from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: { forTree },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      courseId: null,
      tree: [],
      form: {},
      sectionId: '',
      idHover: 0,
    };
  },
  // 事件处理器
  methods: {
    // 目录点击
    treeClick(e){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/login');
      }

      if(e.sectionId == this.idHover){ return }

      if(this.form.have == 0 && e.isFreeTrial == 1){
        // 当前没买课，并且此章节是试看
        clearTimeout(this.getSectionTimer)
        this.getSectionId()
        this.sectionId = e.sectionId
				this.$ajax({
					url: '/hxclass-mobile/course/freetrial-section-vod-info',
					params: {
						courseId: this.courseId,
						sectionId: e.sectionId
					}
				}).then(res=>{
					if(res.code == 200 && res.success){
						FunPlay({
							courseId: this.courseId,
							currStudySectionId: e.sectionId,
              isFast: 1,
              isSpeed: 1,
							currStudyFileId: res.data.fileId,
							currPcfg: res.data.pcfg,
							currStudySeconds: 0,
							SectionTest: res.data.isFreeTrial,
							isHaveSelfTest: 0,
							freeTrialSeconds: res.data.freeTrialSeconds,
              title: res.data.sectionName,
  						isOrder: 1,
              
						})
					}else{
      			this.$alert('未找到视频源信息')
					}
				})
				return false
			}
      // 未购买
      if(this.form.have == 0){
        if(e.isFreeTrial == 1){
          clearTimeout(this.getSectionTimer)
					this.getSectionId()
          this.sectionId = e.sectionId
          this.idHover = e.sectionId
          courseVideo({
						courseId: this.courseId,
						sectionId: this.sectionId
					})
        } else if (e.lock == 1 && this.form.isSetFreeTrial == 1){
          this.$alert('该课程需要购买后学习')
        } else if (e.lock == 1 && this.form.isSetFreeTrial == 0){
          this.$alert('该课程需要购买后学习')
				}
      } else if(this.form.have == 1) {  // 已购买
        if(e.lock == 1 && e.isHaveSelfTest == 1){  // 章节加锁  有小节自测
          this.$alert('请先学习上一节课程并通过小节测试')
        } else if(e.lock == 1 && e.isHaveSelfTest == 0) {
          this.$alert('请先学习完上一节课程')
        } else {
          clearTimeout(this.getSectionTimer)
					this.getSectionId()
          this.sectionId = e.sectionId
          this.idHover = e.sectionId
          courseVideo({
						courseId: this.courseId,
						sectionId: this.sectionId
					})
        }
      }
    },

		// 获取课程目录
		getCourseTree(){
			this.$ajax({
				url:'/hxclass-mobile/course/course-section/tree',
				method:'get',
				params:{
					courseId: this.courseId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.tree = this.dataListhandle(res.data);
				}
			})
    },
    
    getCourseDetail(){
			this.$ajax({
				url:"/hxclass-mobile/course/" + this.courseId,
				method:'get'
			}).then(res=>{
				if(res.code == 200 && res.success){
					this.form = res.data
				}
			})
    },

    // 数据处理
		dataListhandle(e){
			if(!e || !e.length){
				return null;
			}
			let arr = e.map(item=>{
				return {
          ...item,
					id:item.sectionId,
					name:item.sectionName,
					code:null,
					speaker:null,
					duration:null,
					isHaveQuestion:0,
					isWatch:0,
					isPass:null,
					hourId:null,
					children:item.childList?this.dataListhandle(item.childList):null
				}
			})
			return arr
    },
    // 检测页面是否在当前
    handleVisiable(e) {
        // console.log("回到当前tab标签");
      if (e.target.visibilityState == "visible") {
        // 获取目录
        this.getCourseTree();
      } else {
        // console.log("离开当前tab标签");
      }
    },
    // 切换当前视频播放
		onChangeVideo(e){
      // this.$alert(e)
			if(typeof e != 'object'){
				e = JSON.parse(e)
			}
			let sectionId = null
			this.$ajax({
				url: '/hxclass-mobile/course/section-vod-info',
				params: {
					courseId: e.courseId,
					sectionId: e.sectionId,
					userId: this.$store.state.userInfo.userId
				}
			}).then(res=>{
				sectionId = res.data.nextSectionId
        // this.$alert('章节id'+sectionId)
				return this.$ajax({
					url: '/hxclass-mobile/course/section-vod-info',
					params: {
						courseId: e.courseId,
						sectionId: res.data.nextSectionId,
						userId: this.$store.state.userInfo.userId
					}
				})
			}).then(res=>{
				// 查询下一章节的id
				if(res.code == 200 && res.success && res.data.fileId){ // 下一章节视频有内容
      
					courseVideo({
						courseId: res.data.courseId,
						sectionId: sectionId
					})
				}else{
					this.$alert('没有可播放的视频')
				}
			}).catch(err=>{
        // console.log(err)
      })
    },
    // 获取章节id
		getSectionId(){
			this.getSectionTimer = setInterval(()=>{
        if(FunGetSectionId()){
					this.idHover = FunGetSectionId()
				}
			},1000)
		}
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:transparent')
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取当前播放章节id
    this.getSectionId()

    // 播放下一节视频内容
    window.NextVideo = (res)=>{
      this.onChangeVideo(res)
    }
		if(this.$route.query.courseId){
			this.courseId = this.$route.query.courseId;
      this.getCourseTree();
      this.getCourseDetail()
      document.addEventListener("visibilitychange", this.handleVisiable);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  beforeDestroy () {
    clearTimeout(this.getSectionTimer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    document.querySelector('body').removeAttribute('style')
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
};
</script>

<style lang="less" scoped>
  .courseVideo {
    display: flex;
    .left-close{
      width: 22px;
      display: flex;
      align-items: center;
      img{
        width: 19px;
        height: 15px;
        margin-left: 4px;
      }
    }
    .box_title {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      margin-top: 18px;
      margin-bottom: 14px;
    }
    .tree{
      flex: 1;
    }
  }
</style>
