<template>
  <van-popup v-model="visible" :close-on-click-overlay="true" :closeable="true" :close-on-popstate="true" @close="closeVideoModal">
    <video
      ref="video"
      class="play-video"
      controls="controls"
      autoplay="autoplay"
    >
      <source :src="src" type="video/mp4" />
    </video>
  </van-popup>
</template>

<script>
export default {
  props:['show','src'],
  data(){
    return {
      visible: false
    }
  },
  created() {
    this.visible = this.show;
  },
  mounted() {
    this.$nextTick(() => {
      // 播放完毕自动关闭弹窗
      const eleVideo = document.querySelector(".play-video");
      eleVideo.addEventListener("ended", () => {
          this.closeVideoModal();
      }, false);
    });
  },
  methods: {
    // 子组件中关闭弹窗方法
    closeVideoModal() {
      this.$emit("closeModel", false);
    }
  }
}
</script>

<style lang="less" scoped>
  ::v-deep.van-popup{
    background-color: inherit;
  }
  .play-video {
    object-fit: contain;
    width: 100vw;
    // height: 99.5vh;
  }
</style>