export default [
  // {
  //   path: '/',
  //   redirect: 'home'
  // },
  // 我的消息
  {
    path: 'myNews',
    component: () => import('@/views/all/myNews.vue')
  },
  // 华夏云课堂隐私协议
  {
    path: 'privacyPolicy',
    component: () => import('@/views/all/privacyPolicy.vue')
  },
  // 服务协议
  {
    path: 'ServiceAgreement',
    component: () => import('@/views/all/ServiceAgreement.vue')
  },
  // 协议补充
  {
    path: 'detailedList',
    component: () => import('@/views/all/detailedList.vue')
  },
  // 注销协议
  {
    path: 'cancellation',
    component: () => import('@/views/all/cancellation.vue')
  },
  // 会员权益
  {
    path: 'membershipInterests',
    component: () => import('@/views/all/membershipInterests.vue')
  }
]
