<template>
  <div class="collection">
    <!-- 导航栏 -->
		<div ref="headBox">
      <van-nav-bar
      class="nav-circular"
			title="收藏"
			:fixed="true"
			:placeholder="true"
			left-arrow
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>
    <div class="menu-list" v-if="menuArry.length">
      <SwiperMenu 
        v-if="showMenuArry"
        @setMenuCurrent="getMenuCurrent" 
        :menuArry="menuArry" 
        :pageType="2"
        :listCurren="showIndex" />
		</div>
    </div>
    <div class="list" :class="{'listBg':isShowNo}" :style="{ height: swiperHeight + 'px' }" >
      <van-list
        v-if="menuArry.length"
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        :finished-text="finishTxt"
        @load="getData"
      >
        <!-- 教育课程 -->
        <div class="item" v-show="this.menuArry[this.showIndex].collTypeId==1">
          <EduClass-List 
            :listData="listData" 
            listType="1" 
            @cancelOk="cancelOk" />
        </div>
        <!-- 图书教具 -->
        <div class="item" v-show="this.menuArry[this.showIndex].collTypeId==2">
          <Book-List 
            :listData="listData" 
            listType="1"
            @cancelOk="cancelOk" />
        </div>
        <!-- 模拟考试 -->
        <div class="item" v-show="this.menuArry[this.showIndex].collTypeId==4">
          <MockExam-List 
            :listData="listData" 
            listType="1"
            @cancelOk="cancelOk" />
        </div>
        <!-- 套餐搭配 -->
        <div class="item" v-show="this.menuArry[this.showIndex].collTypeId==5">
          <SetMeal-List 
            :listData="listData" 
            listType="1"
            @cancelOk="cancelOk" />
        </div>

        <!-- 教育直播 -->
        <!-- <div class="item" v-if="showIndex==2">
          <EduVideo-List 
            :listData="listData" 
            listType="1"
            @cancelOk="cancelOk" />
        </div> -->

        <!-- <div class="item" v-show="showIndex==4">
          <LeadNews-List />
        </div>
        <div class="item" v-show="showIndex==5">
          <QA-List />
        </div>
        <div class="item" v-show="showIndex==6">
          <Infomation-List />
        </div>
        <div class="item" v-show="showIndex==7">
          <Autistic-List />
        </div>
        <div class="item" v-show="showIndex==8">
          <Exam-List />
        </div> -->
      </van-list>

      <!-- 暂无数据 -->
      <noMessage type=1 style="position: relative;top: 40%;margin-top:-58px" v-if="isShowNo"></noMessage>
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import SwiperMenu from '@/components/swiperMenu.vue'  // 导航组件
import EduClassList from '@/components/searchSeo/eduClassList.vue'  // 教育课程
import BookList from '@/components/searchSeo/BookList.vue'  // 图书教具
import MockExamList from '@/components/searchSeo/mockExamList.vue'  // 模拟考试
import SetMealList from '@/components/searchSeo/setMealList.vue'  // 套餐搭配
import { refreshCollection } from '@/assets/js/NativeWebKit.js'
// import EduVideoList from '@/components/searchSeo/eduVideoList.vue'  // 教育直播
// import LeadNewsList from '@/components/searchSeo/leadNewsList.vue'  // 云课头条
// import QAList from '@/components/searchSeo/QAList.vue'  // 百问百答
// import InfomationList from '@/components/searchSeo/infomationList.vue'  // 资讯速递 
// import AutisticList from '@/components/searchSeo/autisticList.vue'  // 自闭之声
// import ExamList from '@/components/searchSeo/examList.vue'  // 行业考试
export default {
  // 可用组件的哈希表
  components: {
    noMessage,  //暂无数据
    SwiperMenu,
    EduClassList,   // 教育课程
    BookList,       // 图书教具
    MockExamList,   // 模拟考试
    SetMealList,    // 套餐搭配
    // EduVideoList,   // 教育直播
    // LeadNewsList,   // 云课头条
    // QAList,         // 百问百答
    // InfomationList, // 资讯速递
    // AutisticList,   // 自闭之声
    // ExamList,       // 行业考试
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      windowHeight: document.documentElement.clientHeight, // 屏幕的高度
      swiperHeight: 0, // swiper盒子的高度
      isShowNo: false, //是否展示暂无数据
      menuArry:[],
      showMenuArry: false, //展示分类
      // menuArry:[    // 菜单
      //   {id:1, name:'证书课程'},
      //   {id:2, name:'图书教具'},
      //   // {id:3, name:'教育直播'},
      //   {id:4, name:'模拟考试'},
      //   {id:5, name:'套餐搭配'},
      //   // {id:1,name:'图书教具'},
      //   // {id:2,name:'教育课程'},
      //   // {id:3,name:'模拟考试'},
      //   // {id:4,name:'教育直播'},
      //   // {id:5,name:'云课头条'},
      //   // {id:6,name:'百问百答'},
      //   // {id:7,name:'资讯速递'},
      //   // {id:8,name:'自闭之声'},
      //   // {id:9,name:'行业考试'},
      // ],
      showIndex: 0,
      // 列表
      listData: [],
      pageNum: 1, //页数
      pageSize: 10, //条数
      pages: 1, //总页数
      pageSizeNow: 0, //当前页数条数

      loading: false,
      finished: false,
      finishTxt: '', 
      refreshing: false,

      isGet:true,
    }
  },
  // 事件处理器
  methods: {
    // 收藏列表
    async getData() {
      if(!this.isGet){return}
      this.isGet = false
      await this.$ajax({
        url: '/hxclass-mobile/collection/app/get?type='+ this.menuArry[this.showIndex].collTypeId + '&pageNum=' + this.pageNum + '&pageSize=' + this.pageSize,
        method: "get",
      }).then((res) => {
        this.isGet = true
        // console.log(res)
        if (res.code == 200 && res.success) {
          this.isShowNo = false
          this.pageSizeNow = res.data.records.length
          this.listData = this.listData.concat(res.data.records)
          this.pageNum ++
          if (res.data.records.length == 0) {
            this.pageNum--
          }
          // console.log(res.data.total , this.listData.length)
          if (res.data.total == this.listData.length) {
            this.loading = false
            this.finished = true
            // this.finishTxt = '没有更多了'
            this.$nextTick(() => {
              // 页面高度
              let pageHeight = document.getElementsByClassName('van-list')[0].clientHeight;
              if (pageHeight > this.swiperHeight) {
                this.finishTxt = "没有更多了";
              } else {
                this.finishTxt = "";
              }
            })
          } else {
            this.finished = false
          }
          
          // 无数据
          if (this.listData.length == 0) {
            this.finishTxt = ''
            this.isShowNo = true
          }
          this.loading = false
        }
      })
    },
    // 切换tab
    getMenuCurrent(e){
      // 保存tab类型
      let collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
      collectionObj.showIndex = e
      sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
      // 初始化
      this.showIndex = e
      this.listData = []
      this.loading = false
      this.finished = false
      this.finishTxt = ''
      this.pageNum = 1
      this.isShowNo = false //关闭暂无数据
      // 收藏列表
      this.getData()
      // this.getDataMore(1)
    },
    // 取消收藏
		cancelOk(id, index) {
      // console.log(id, index)
      this.$ajax({
        url: '/hxclass-mobile/collection/app?objectId=' + id + '&type=' + this.menuArry[this.showIndex].collTypeId + '&key=0',
        method: "POST",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 通知原生刷新收藏列表
          refreshCollection();
          this.$Toast.success('取消收藏成功！');
          this.listData.splice(index, 1)
          this.listData.splice(this.listData.length-this.pageSizeNow+1, this.pageSizeNow-1)
          this.pageNum--
          this.getData()
        }
      })
		},
    async getMenu() {
      await this.$ajax({
        url: '/hxclass-mobile/collection/list',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.showMenuArry = true
          this.menuArry = res.data;
          if(!res.data.length){
            this.isShowNo = true;
          }else{
            this.getData()
          }
        }
      })
		},
    // 页面滚动监听
    FunHandleScroll() {
      // 收藏页面高度记录
      let collectionTop = document.getElementsByClassName('list')[0].scrollTop
      let collectionObj = {}
      if (sessionStorage.getItem('collectionObj')) {
        collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
      }
      collectionObj.collectionTop = collectionTop
      sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
    },
    // 是否刷新页面
    async isLoad() {
      let isLoad = false //是否加载数据
      let showIndex = 0 //tab索引
      // 传参
      if (this.$route.query.isLoad) {
        isLoad = this.$route.query.isLoad
        // 默认tab索引存0
        let collectionObj = {}
        if (sessionStorage.getItem('collectionObj')) {
          collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
        }
        collectionObj.showIndex = 0
        sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
        // 去掉isload
        this.$router.replace({
          path: '/mine/collection',
          query: {
            fromMenu: this.$route.query.fromMenu
          }
        })
      } else {
        // 存储
        if (sessionStorage.getItem('collectionObj')) {
          let collectionObj = JSON.parse(sessionStorage.getItem('collectionObj'))
          isLoad = collectionObj.isLoad || false
          showIndex = collectionObj.showIndex || 0
          // 存储要刷新
          if (!!isLoad) {
            collectionObj.isLoad = false
            sessionStorage.setItem('collectionObj', JSON.stringify(collectionObj))
          }
        }
      }
      
      // 刷新页面数据
      if (!!isLoad) {
        this.isGet = true
        this.showIndex = showIndex
        this.listData = []
        this.showMenuArry = false //隐藏分类
        this.finishTxt = ''
        this.pageNum = 1
        this.isShowNo = false //关闭暂无数据
        // 获取数据
        await this.getMenu()
      } else {
        // 回到离开页面位置
        if (!!sessionStorage.getItem('collectionObj')) {
          let collectionTop = JSON.parse(sessionStorage.getItem('collectionObj')).collectionTop
          document.getElementsByClassName('list')[0].scrollTop = JSON.parse(collectionTop)
        }
      }
    },
  },
  // 缓存进入
  activated() {
    // 是否刷新页面
    this.isLoad()
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 获取数据
    // this.getMenu()
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // this.swiperHeight = this.windowHeight - this.$refs.headBox.offsetHeight - 46
    this.$nextTick(() => {
      this.FunHandleScroll();
      document.getElementsByClassName('list')[0].addEventListener("scroll", this.FunHandleScroll);
    })
  },
  updated() {
    this.swiperHeight = this.windowHeight - this.$refs.headBox.offsetHeight
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
/deep/.swiper-slide-active {
    bottom: 7px !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
.collection{
  max-height: 100vh;
  overflow: hidden;
  .menu-list{
    margin-top: 12px;
    .menuSwiper-box{
      background: url('../../components/LoopTab/img/tablink.png');
      /deep/.swiper-slide{
        margin-top: 13px;
        color: #333333;
      }
    }
  }
  .list{
    box-sizing: border-box;
    background: #ffffff;
    padding: 18px 0;
    height: 100%;
    overflow-y: scroll;
    // .item{
    //   height: 100%;
    //   overflow-y: scroll;
    // }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .listBg {
    background: transparent;
  }
}
</style>
