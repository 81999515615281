<template>
  <div class="container-wrap">
    <!-- 机构报名-个人信息确认 -->
    <van-nav-bar
      class="nav-circular"
      title="确认信息"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 主功能区 -->
    
    <!-- 第二步 -->
    <div class="content-box" v-show="stepIndex==2">
      <div class="all-bg second-box">
        <div class="all-title">{{verifyType == 1 ? '证书照片确认' : '照片提交方式：'}}</div>
        <div class="tips" v-if="verifyType == 1">
          <span>您收到的证书上的照片为以下照片，请确认，确认后将开始制作证书，不再支持修改。</span>
        </div>
        <div class="tips" v-else>
          <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
          <span>提示：提交证书照片为您提供了两种方式：1.邮寄纸质照片（无需缴纳费用）；2.使用已上传的电子照片（需支付代为冲印费20元）。</span>
        </div>
        <!-- 报名照片选择方式 -->
        <div class="child-item" v-if="verifyType == 0">
          <div class="item-R-select">
            <div class="sex" @click="ImgIndex=2">
              <div class="select-icon"><span v-show="ImgIndex==2"></span></div>使用已上传的电子照片
            </div>
            <div class="sex" @click="ImgIndex=1">
              <div class="select-icon"><span v-show="ImgIndex==1"></span></div>邮寄纸质照片
            </div>
          </div>
        </div>
        <!-- 邮寄照片的要求 -->
        <div class="ask-inner" v-show="ImgIndex==1">
          <div class="h4">请按以下要求邮寄照片：</div>
          <div class="p">1.一张1寸蓝底或白底纸质照片</div>
          <div class="p">2.照片后面用圆珠笔写您的姓名和手机号。</div>
          <div class="p">3.附一张白纸写您的姓名、手机号、身份证号、考试时间及场次。</div>
          <div class="p">4.邮寄地址如下：</div>
          <div class="p area">{{addressObj.pcaName}}</div>
          <div class="p address">{{addressObj.proviceName}}{{addressObj.cityName}}{{addressObj.areaName}}{{addressObj.address}}</div>
          <div class="p phone">{{addressObj.name}} {{addressObj.mobile}}</div>
        </div>
      </div>
      <div class="second-box" v-show="ImgIndex==1">
        <div class="tips second-tips">
          <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
          <span>提示：邮寄后请填写以下信息</span>
        </div>
      </div>
      <div class="all-bg second-box" v-show="ImgIndex==1">
        <div class="all-title">填写物流信息：</div>
        <div class="child-item">
          <span class="item-L-name">快递公司</span>
            <van-field 
              class="input" 
              :border='false' 
              v-model="postCompany"
              placeholder="请填写快递公司" />
        </div>
        <div class="child-item" style="border-bottom: none;padding: 25rpx 0 0;">
          <span class="item-L-name">物流单号</span>
            <van-field 
              class="input" 
              :border='false' 
              v-model="postNum"
              placeholder="请填写物流单号" />
        </div>
      </div>
      
      <!-- 上传电子照片 -->
      <div class="upload-box" v-show="ImgIndex==2">
        <div class="all-title">我的电子照片：</div>
        <div class="upload-tips">点击图片可重新上传一寸蓝底或白底证件照</div>
        <div class="upload-click">
          <van-uploader :after-read="uploadCertifiMobile">
            <div class="bg-img" v-if="!certifiMobile">
              <img class="icon" src="@/assets/image/icon/20220215041507.png" />
            </div>
            <div class="bg-info" v-else>
              <img class="icon" :src="certifiMobile" />
            </div>
          </van-uploader>
        </div>
        <!-- <div class="pay-btn">
          <span class="all-btn-bigCircle">支付</span>
        </div> -->
      </div>

      <div 
        class="btn-foo" 
        :style="{top: (clientHeight - buttonHeight) + 'px'}"
        id="btnFoo">
        <span></span>
        <span class="btn next" @click="onNextTo" v-show="ImgIndex==1">确认</span>
        <span class="btn next" @click="goPay" v-show="ImgIndex==2">{{isPay?'确认':'支付'}}</span>
      </div>
    </div>
    <!-- 提示信息 -->
    <van-popup v-model="isTips">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon color="#ec3c31" name="info-o" size="22" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">确认信息后除非有姓名或身份证信息等错误，否则不再接受修改证书内容及邮寄地址</div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="isTips=false">取消</span>
          <span class="all-btn-middle btn2" @click="onSubmit()">确认信息</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { dataURLtoFile, getClientHeight } from '@/unit/fun.js'
import { onGoStudy,refreshExam } from '@/assets/js/NativeWebKit.js'
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        examineeId: '', //考生id
        signupId: '', //考试id
        signupUserId: '', //用户报名id
        refundAddressId: '', //退货地址id
        addressObj: {}, //退货地址
        certifiMobile: '', //电子照片

        stepIndex: 2,// 控制步骤样式
        ImgIndex: 2, //控制上传照片方式 1纸质 2电子照片
        isPay: false, //是否已支付

        postCompany: '', //快递公司
        postNum: '', //快递单号

        clientHeight: 0, // 视口高度
        buttonHeight: 0, //按钮高度

        isTips:false, // 确认提示
        verifyType: 0,  // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式
      }
		},
    created() {
      this.examineeId = this.$route.query.examineeId //考生id
      this.signupUserId = this.$route.query.signupUserId //用户报名id
      // 获取报名信息
      this.getSignUp()

      // 安卓选择照片后回调
      window.returnPhotoUrl = (url)=>{
        var file = dataURLtoFile(url, 'fileName.jpg')
        // 上传图片
        this.uploadCertifiMobile(file, 1)
      }
      
      let _this = this;
      // 原生刷新页面支付状态
      window.refreshH5 = function () {
        _this.getIsPay()
        return ;
      };
    },
    mounted(){
      // 获取页面视图高度
      this.clientHeight = getClientHeight()
      // 获取按钮高度
      this.buttonHeight = document.getElementById("btnFoo").clientHeight
    },
		// 事件处理器
		methods: {
      // 获取报名信息
      async getSignUp() {
        await this.$ajax({
          url: '/hxclass-mobile/exam/user-signup-info',
          method: 'GET',
          params: {
            signupUserId: this.signupUserId
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.refundAddressId = res.data.address //退货地址id
            this.certifiMobile = res.data.certifiMobile //电子照片
            this.signupId = res.data.signupId

            this.verifyType = res.data.verifyType

            // 退货地址
            this.getAddress()


          if(this.verifyType == 1){  // 证书提交方式为1时不需要查询是否支付
            this.isPay = true
            this.ImgIndex = 2
          } else {
            // 是否支付
            this.getIsPay()
          }
          }
        })
      },
      // 收货地址
      getAddress() {
        this.$ajax({
          url: '/hxclass-mobile/admin-address/detail',
          method: 'get',
          params: {
            addressId: this.refundAddressId
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.addressObj = res.data
          }
        });
      },
      // 是否已支付电子照片费用
      getIsPay() {
        this.$ajax({
          url: '/hxclass-mobile/exam/examinee-pay',
          method: 'get',
          params: {
            examineeId: this.examineeId
          }
        }).then((res) => {
          if (res) {
            this.isPay = res
            // 已付款
            if (!!res) {
              this.ImgIndex = 2
            }
          }
        });
      },
      // 上传电子照片
      uploadCertifiMobile(e, type) {
        // console.log(e)
        // type 1安卓上传
        let file = type == 1 ? e : e.file
        this.$upload({
          file: file,
          type: 'image',
          progress: (e)=>{
            this.loading = true
          },
          success: (e)=>{
            this.loading = false
            this.certifiMobile = e
          }
        })
      },
      // 下一步
      onNextTo(){
        // 判空
        if (this.postCompany == '') {
          this.$Toast('请填写快递公司')
          return
        } else if (this.postNum == '') {
          this.$Toast('请填写快递单号')
          return
        } 
        // else if (!this.$regular.postNum.reg.test(this.postNum)) {
        //   this.$Toast("快递单号格式不正确");
        //   return
        // }
        this.isTips = true
      },
      // 存储信息
      setConfirm() {
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = localStorage.getItem(confirmObjName)
        
        // 存储
        if (!confirmObj) {
          localStorage.setItem(confirmObjName, JSON.stringify({
            signupId: this.signupId, //考试id
            examineeId: this.examineeId, //考生id
            refundAddressId: this.refundAddressId,  //退货地址id
            certifiMobile: this.certifiMobile //电子照片
          }))
        }
      },
      // 支付
      goPay() {
        // 已支付
        if (this.isPay) {
          this.isTips = true
        } 
        // 未支付
        else {
          // payReturnsign 1跳回个人考试报名页 2跳到首页 3跳回机构考试报名页
          localStorage.setItem('payReturnsign', 1)
          // isOrgOrder  是否机构报名-照片冲印订单
          sessionStorage.setItem('isOrgOrder', true)

          // 电子照的考试id
          sessionStorage.setItem('paySignupObj', JSON.stringify({
            id: this.signupId,
            signupUserId: this.signupUserId,
            examineeId: this.examineeId,
            pay: false,
            picture: this.certifiMobile, //电子照
            invoice: false, //是否跳了发票页
          }))

          // 存储信息
          this.setConfirm()
          
          // console.log(localStorage.getItem('payReturnsign'))
          let productList = [
            {
              count: 1, //数量
              noGift: 0, //是否为赠品 0否1是
              productId: this.examineeId, //商品id-考生id
              type: 5, //商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            }
          ]
          this.$store.commit('updatelist', productList) //订单列表
          this.$store.commit('updateType', 2) // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
          this.$store.commit('updateShortInvoiceId',null);
          this.$store.commit('updateInvoiceId', null) //发票id
          this.$store.commit('updateAddress', null) //收货地址id
          this.$store.commit('updateCouponList',null);
          this.$router.push('/order/confirmOrder')
        }
      },
      // 确认信息
      onSubmit() {
        let params = {
          examineeId: this.examineeId, //考生id
          isConfirm: 1, //是否确认 1.是 0.否
          mode: this.ImgIndex, //照片提交方式 1.邮寄纸质照片 2.使用上传电子照片	
          logisticsCompany: this.ImgIndex==1 ? this.postCompany : '', //物流公司
          logisticsNumber: this.ImgIndex==1 ? this.postNum : '', //	物流单号
          isPay: this.ImgIndex==2 ? 1 : 0, //是否支付 1.是 0.否
          picture: this.ImgIndex==2 ? this.certifiMobile : '', //	电子照片
          price: this.ImgIndex==2 ? 20 : 0, //	价格
        }
        // console.log(params)
        // return
        this.$ajax({
					url:'/hxclass-mobile/exam/examinee/submit',
					method:'PUT',
          params
				}).then(res=>{
					if(res.code == 200 && res.success){
            this.$Toast.success('确认信息完成');
            this.isTips = false
            refreshExam()
            onGoStudy()

            // this.$router.push('/examEnroll/registration')
            // this.$router.go(-3)
					}
				})
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./signUpConfirm.less";
  .container-wrap {
    // height: calc(100vh - 100px);
    padding-bottom: 100px;
    background-color: #F7F7F9;
  }
</style>
