<template>
  <!-- 意见反馈 -->
  <div class="feedbackSubmit">
    <!-- 导航栏 -->
    <div style="overflow: hidden; background-color: #ffffff">
      <van-nav-bar
        title="意见反馈"
        :fixed="true"
        :placeholder="true"
        left-arrow
        @click-left="$onReturn($route.query.fromMenu == '1')"
      />
    </div>
    <!-- 补充描述 -->
    <div class="box1">
      <div class="item_txt">
        <textarea
          v-model="content"
          class="txt_con"
          placeholder="请补充描述，有助于为您更好的处理问题。"
          maxlength="200"
        ></textarea>
        <div class="txt_info" :class="{ light: content.length == 200 }">
          {{ content.length }}/200
        </div>
        <div class="txt_imgs">
          <!-- :max-size="500 * 500"  -->
          <van-uploader :before-read="beforeRead" :after-read="uploadImg" @oversize="onOversize">
            <div class="imgs_item">
              <img
                class="item_img"
                src="../../assets/image/refund/applyFor_icon1.png"
              />
            </div>
          </van-uploader>
          <!-- <div class="imgs_item">
            <img class="item_img" src="../../assets/image/refund/applyFor_icon1.png" />
          </div> -->
          <div
            class="imgs_item"
            v-for="(item, index) in imgUrlList"
            :key="index"
            @click="showImg(index)"
          >
            <img class="item_img" :src="item" />
            <van-icon
              class="item_icon"
              name="clear"
              size="16"
              @click.stop="deleImg(index)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 联系方式 -->
    <!-- <div class="box2">
      <div class="box_title">联系方式</div>
      <div class="box_con">
        <div class="con_item">
          <div class="item_name">姓名</div>
          <van-field placeholder="请输入联系人" class="item_input" input-align="right" />
        </div>
        <div class="con_item">
          <div class="item_name">联系方式</div>
          <van-field placeholder="请输入手机、邮箱等联系方式" class="item_input" input-align="right" />
        </div>
      </div>
    </div> -->

    <!-- 提示语 -->
    <div class="box3">
      若您提出的问题需要回答，请填下您的联系方式，我们会第一时间处理
    </div>

    <!-- 提交 -->
    <div class="footer">
      <div class="btn all-btn-bigCircle" @click="submit">提 交</div>
    </div>
  </div>
</template>

<script>
import { dataURLtoFile } from "@/unit/fun.js";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      content: "",
      imgUrlList: [],
    };
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 安卓选择照片后回调
    window.returnPhotoUrl = (url) => {
      var file = dataURLtoFile(url, "fileName.jpg");
      // 上传图片
      this.uploadImg(file, 1);
    };
  },
  // 事件处理器
  methods: {
    // 提交
    submit() {
      if (this.content == "") {
        this.$Toast("请补充描述，有助于为您更好的处理问题");
        return;
      }
      // 提交
      this.$ajax({
        url: "/hxclass-mobile/problem/feedback/app",
        method: "POST",
        params: {
          content: this.content,
          imgUrlList: this.imgUrlList,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success("提交成功");
          let timer = setTimeout(() => {
            clearTimeout(timer);
            this.$onReturn(this.$route.query.fromMenu == '1')
          }, 1000);
        }
      });
    },
    beforeRead(file){
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$Toast("请上传正确格式图片文件");
      }
      const isLt2M = file.size / 1024 / 1024 < 2.5;
      if (!isLt2M) {
        this.$Toast("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 上传图片
    uploadImg(e, type) {
      // type 1安卓上传
      let file = type == 1 ? e : e.file;
      this.$upload({
        file: file,
        type: 'image',
        progress: (e) => {},
        success: (e) => {
          this.imgUrlList.push(e);
        },
      });
    },
    // 删除图片
    deleImg(index) {
      this.imgUrlList.splice(index, 1);
    },
    // 预览图片
    showImg(index) {
      this.$ImagePreview({
        images: this.imgUrlList,
        startPosition: index,
      });
    },
    // 文件超出大小提示
    onOversize(file) {
      // console.log(file);
      this.$Toast("文件大小不能超过 500kb");
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.van-cell {
  padding: 0;
}
* {
  box-sizing: border-box;
}
.feedbackSubmit {
  min-height: 100vh;
  background: #f7f7f9;
}

// 补充描述
.box1 {
  display: inline-block;
  width: 100%;
  background: #ffffff;
  margin-top: 1px;
  padding: 16px 18px;
  .item_txt {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #cccccc;
    margin-top: 12px;
    padding: 16px;
    .txt_con {
      width: 100%;
      min-height: 107px;
      line-height: 18px;
      border: 0;
      resize: none;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .txt_info {
      font-size: 12px;
      // font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      margin-top: 19px;
      &.light {
        color: red;
      }
    }
    .txt_imgs {
      display: flex;
      margin-top: 8px;
      .imgs_item {
        position: relative;
        margin-right: 9px;
        .item_img {
          width: 44px;
          height: 44px;
          border-radius: 8px;
        }
        .item_icon {
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}

// 联系方式
.box2 {
  margin-top: 8px;
  padding: 12px 18px;
  background-color: #ffffff;
  .box_title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    &::before {
      content: "";
      display: inline-block;
      width: 3.5px;
      height: 14.5px;
      background: #15b7dd;
      border-radius: 2px;
      margin-right: 4px;
    }
  }
  .box_con {
    .con_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      padding: 19px 10px 12px 10px;
      &:last-child {
        border: 0;
      }
      .item_name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
      .item_input {
        width: 200px;
        font-size: 12px;
        font-weight: 400;
        text-align: right;
      }
    }
  }
}

// 提示语
.box3 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  padding: 0 26px 18px 26px;
  background-color: #fff;
}

// 提交
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 5px 40px;
  box-sizing: border-box;
  background-color: #ffffff;
  .btn {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFangMedium;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
  }
  .btn::after {
    border: 0;
  }
}
</style>
