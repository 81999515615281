let obj = new Object()

if(process.env.NODE_ENV == 'development'){
  // 开发环境
  obj = {
    // 代理前缀
    baseUrl: 'api',
    // 请求地址
    target: '',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
    // aes密钥
    key: 'W7gS6i8oPQm3Rs9k',
    // vconsole调试模式
    vconsole: false,
    // 分享页面域名 - h5测试地址
    shareUrl: 'https://mobile.hxclass.cn',
    // 分享页面域名 - wxh5测试地址
    shareWxUrl: 'https://wx.hxclass.cn'
  }
}else{ 
  // 生产环境
  obj = {
    // 代理前缀
    baseUrl: '',
    // 请求地址
    target: '',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
    // aes密钥
    key: 'W7gS6i8oPQm3Rs9k',
    // vconsole调试模式
    vconsole: false,
    // 分享页面域名 - h5生产地址(非原生)
    shareUrl: 'https://mobile.hxclass.cn',
    // 分享页面域名 - wxh5测试地址
    shareWxUrl: 'https://wx.hxclass.cn'
  }
}

export default obj