var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classOrderDetail"},[_c('van-nav-bar',{staticClass:"nav-circular",attrs:{"title":"订单详情","left-text":"","right-text":"","fixed":true,"placeholder":true,"left-arrow":""},on:{"click-left":function($event){return _vm.$onReturn(_vm.$route.query.fromMenu == '1')}}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"orderMessage"},[_c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("订单编号：")]),_c('span',{staticClass:"value"},[_vm._v("21312342343255426"),_c('span',{on:{"click":function($event){return _vm.copyOrderCode('21312342343255426')}}},[_vm._v("｜复制")])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('van-button',{staticClass:"invoicing",attrs:{"plain":"","type":"primary"}},[_vm._v("申请开票")]),_c('van-button',{staticClass:"invoicing",attrs:{"plain":"","type":"primary"}},[_vm._v("查看开票")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopMessage"},[_c('div',{staticClass:"shopItem"},[_c('img',{staticClass:"shopImg",attrs:{"src":""}}),_c('div',{staticClass:"shopMessage"},[_c('span',{staticClass:"shopTitle"},[_vm._v("商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称")]),_c('div',{staticClass:"priceNum"},[_c('span',{staticClass:"profit"},[_vm._v("课程延期：60天")]),_c('span',{staticClass:"num"},[_vm._v("x1")])])])]),_c('div',{staticClass:"payment"},[_c('span',{staticClass:"title"},[_vm._v("实付款：")]),_c('span',{staticClass:"price"},[_vm._v("¥3424.00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailedtitle"},[_c('span',{staticClass:"colorDiv"}),_c('span',{staticClass:"title"},[_vm._v("订单信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("创建时间：")]),_c('span',{staticClass:"value"},[_vm._v("2022-01-27 11:32:23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("订单类型：")]),_c('span',{staticClass:"value"},[_vm._v("图书教具")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("支付方式：")]),_c('span',{staticClass:"value"},[_vm._v("微信")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("支付时间：")]),_c('span',{staticClass:"value"},[_vm._v("2022-01-27 11:32:23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("支付订单号：")]),_c('span',{staticClass:"value"},[_vm._v("2131231232131231245")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messageItem"},[_c('span',{staticClass:"title"},[_vm._v("到期时间：")]),_c('span',{staticClass:"value"},[_vm._v("2022-01-27 11:32:23")])])
}]

export { render, staticRenderFns }