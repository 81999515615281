/*
    ajax请求去掉loading
    页面路径在控制台有打印，复制到下面的数组即可
*/
export default [
    '/hxclass-mobile/order/is-pay', // 课程支付结果回调
    '/hxclass-mobile/course/change-progress',   // 更新用户观看进度
    '/hxclass-mobile/course/course-study-record/to-update',   // 更新用户课程进度
    '/hxclass-mobile/course/section-vod-info',  // 获取下一章节id
    '/hxclass-mobile/app-mall/shop/list',  // 获取购物车列表
    '/hxclass-mobile/app-mall/shop/coupon-list',  // 获取优惠券列表
    '/hxclass-mobile/app-mall/shop/coupon-receive',  // 获取优惠券列表
    '/hxclass-mobile/course/check-delay-course',  // 延期查询
    '/hxclass-mobile/course/delay-course',  // 免费延期
    '/hxclass-mobile/exam/signUp/selMakUpOrderIsPay', // 获取补考订单状态
]