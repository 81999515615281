<template>
	<div class="container-box">
    <van-nav-bar 
      title="资讯详情" 
      left-arrow right-arrow :fixed="true" 
      :placeholder="true" 
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="shareFile">
      <template #right>
				<img class="tryImg" src="@/assets/image/selected/shareFileNews.png" />
      </template>
    </van-nav-bar>
    <!-- {{AppVersion}} -->
		<informationList v-if="JSON.stringify(list)!=='{}'" :type="type" :data="list"></informationList>
	</div>
</template>

<script>
  import informationList from '@/components/information-list/information-list.vue'
  import {getAppVersion, FunSendShare} from '@/assets/js/NativeWebKit.js';
import config from '@/unit/config.js'
  export default {
    components: {
      informationList
    },
    data() {
      return {
        config,
			  AppVersion: getAppVersion(),
        list: {},
        type: false, // false: 老版本  true: 版本
      }
    },
    beforeCreate() {
      document.querySelector('body').setAttribute('style', 'background: #ffffff;')
    },
    created() {
      this.type = !!this.$route.query.type
      // 咨询详情
      this.getDetail()
    },
    methods: {
      // 资讯详情
      getDetail() {
        let id = this.$route.query.id
        let url = ''
        if(this.type) {
          url = '/hxclass-mobile/Article/detail/'+id
        }else{
          url = '/hxclass-mobile/news/app/details/'+id
        }
        this.$ajax({
          url
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // console.log(res)
            this.list = res.data
            // 来源限制15字
            this.$set(this.list, 'source', this.list.source?.substring(0, 10))
            // 处理预览人数、超过一万显示单位为万
            if (this.list.views >= 10000) {
              let views = (this.list.views/10000).toFixed(0)+'万'
              this.$set(this.list, 'views', views)
            }
            if (this.list.pageviews >= 10000) {
              let pageviews = (this.list.pageviews/10000).toFixed(0)+'万'
              this.$set(this.list, 'pageviews', pageviews)
            }
            // 处理年月日
            let createTime = this.list.createTime?.split(' ')[0]
            this.$set(this.list, 'createTime', createTime)
            let publishTime = this.list.publishTime?.split(' ')[0]
            this.$set(this.list, 'publishTime', publishTime)
            // info.replace( new RegExp('style="max-width:100%;"', 'g'), 'style="width:100%;"')
          }
        });
        // 增加浏览量接口
        this.$ajax({
          url: '/hxclass-mobile/Article/up/' + id,
          method: 'get',
        }).then((res) => {});
      },
      
      // 分享
      shareFile() {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          if(true || this.AppVersion.code > 77){
            let json = {
              WebUrl: this.config.shareUrl + '/info/shareInformation?id='+this.$route.query.id+"&share=true&type=" + this.type,
              title: this.list.title || this.list.articleTitle,
              description: this.list.summary || this.list.overview,
              imageUrl: this.list.banner || 'https://cos.hxclass.cn/test/mobile/20220914/8cf7263fbb154c45b466da4266f681d3.png' // 默认图片 - 处理微博分享没反应问题
            }
            FunSendShare(JSON.stringify(json));
          }else{
            this.$Toast('请更新app版本')
          }
        }else{
          if(true || this.AppVersion.code > 107){
            let json = {
              WebUrl: this.config.shareUrl + '/info/shareInformation?id='+this.$route.query.id+"&share=true&type=" + this.type,
              title: this.list.title || this.list.articleTitle,
              description: this.list.summary || this.list.overview,
              imageUrl: this.list.banner || ''
            }
            FunSendShare(JSON.stringify(json));
          }else{
            this.$Toast('请更新app版本')
          }
        }
      },
    },
    // 生命周期-实例销毁离开后调用
    destroyed () { 
      document.querySelector('body').removeAttribute('style')
    },
  }
</script>

<style lang="less" scoped>
  .tryImg{
    width: 18px;
    height: 18px;
  }
</style>
