<template>
	<div class="myCourse">
		<div style="padding-bottom: 50px;">
			<!-- 顶部课程轮播图 -->
			<div class="courseHeader">
				<!-- <uni-icons class="back" custom-prefix="iconfont" type="icon-Arrowleftback" size="40"></uni-icons> -->
				<img class="back" @click="goBack" src="@/assets/image/selected/back.png" />
				<!-- <img class="share" src="@/assets/image/selected/share.png" /> -->

				<van-swipe @change="SwiperTab" :autoplay="5000" class="swiper">
					<van-swipe-item v-for="(image, index) in 4" :key="index">
						<div class="swiper-item uni-bg-red">
							<img class="swiperImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4ae582d00db630ee0ac119c7bf1b8b3c25ccbca8c55c4ad3277899163cfc140a" />
						</div>
					</van-swipe-item>
				</van-swipe>

				<div class="btnGroup">
					<!-- <div class="tryBox">
						<img class="tryImg" src="@/assets/image/selected/tryIcon.svg" />
						<span class="tryspan">试看</span>
					</div> -->
					<div class="collectBox">
						<img class="collect" src="@/assets/image/selected/collected.svg" />
						<span class="collectspan">已收藏</span>
						<!-- <img class="collect" src="@/assets/image/selected/collect.svg" />
						<span class="collectspan">收藏</span> -->
					</div>
				</div>
				<!-- <block class="number">
					<div class="u-indicator-item-number">{{ current + 1 }}/{{ swiperList }}</div>
				</block> -->
			</div>
			<!-- 课程标题以及介绍 -->
			<div class="courseExplain">
				<div class="explainUp">
					<div class="left">
						<!-- <span class="label">套餐</span> -->
						<span class="price"><span class="moneySign">¥</span>1000.00</span>
						<!-- <span class="originalPrice">
							<span style="span-decoration:line-through;">¥1324.00</span>
						</span> -->
					</div>

				</div>
				<div class="explainCenter">
					孤独症康复教育上岗培训课程
				</div>
				<div class="explainDown">
					<div class="courseType">
						<div class="left">
							直播时间：2021年12月12日 19:00
						</div>
						<div class="right">
							<span>21312人已预约</span>
						</div>
					</div>
					<!-- <div class="fload">课程内容分为三部容分为三部容分为三部容分为三部分。第一部分为理论课，介课程内容分为三部分。第一部分为理论课，介课程内容分为三部分。第一部分为理论课，介课程内容分为三部分。第一部分为理论课，介</div> -->
				</div>
			</div>
			<!-- 优惠卷 -->
			<!-- <div class="couponBox">
				<div class="item" @click="popUp1">
					<div class="left">
						优惠券
					</div>
					<div class="right">
						<div class="rightBox">
							<div class="downitem" v-for="(item,index) in 1" :key="index">
								满100减10
							</div>

							<div class="tag">
								领劵
							</div>
						</div>
						<van-icon name="arrow" size="10" color="#999" />
					</div>
				</div>
			</div> -->
			<!-- 套餐组合 -->
			<!-- <div class="combination">
				<div class="combinationTitle">
					<span class="line"></span>
					<span>套餐组合</span>
				</div>
				<div class="combinationBox">
					<div class="item" v-for="(items,i) in 2" :key="i">
						<div class="left">
							<img class="courseImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng15e093fc9f889d38975bf98b1d484440e6581999ca1f3fb89ec3f86acbfe377a" />
						</div>
						<div class="right">
							<span class="title">孤独症康复教育上岗培训课程</span>
							<span class="identification">上岗课程丨共209节课</span>
							<span class="price">原价：￥1324.00</span>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 线下课程培训地址 -->
			<!-- <div class="relatedIntroduction">
				<div class="item">
					<div class="left">
						培训地点
					</div>
					<div class="right" style="display: flex;" @click="popUp">
						<div class="span">北京市朝阳区已和阳光大厦2001</div>
						<div class="copy">复制</div>
					</div>
				</div>
			</div> -->
			<!-- 相关证书、图书教具、附赠 -->
			<!-- <div class="relatedIntroduction">
				<div class="item">
					<div class="left">
						相关证书
					</div>
					<div class="right" style="display: flex;align-items:center;">
						<div class="span">《孤独症康复教育上岗培训合格证书》</div>
						<van-icon name="arrow" size="10" color="#999" />
					</div>
				</div>
				<div class="item">
					<div class="left">
						图书教具
					</div>
					<div class="right">
						<div v-for="(item,index) in 2" :key="index">
							<div style="display: flex;align-items:center;">
								<div class="span">孤独症康复教育人员上岗培训教材（第二版）</div>
								<van-icon name="arrow" size="10" color="#999" />
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="left">
						附赠
					</div>
					<div class="right" style="display: flex;align-items:center;">
						<div class="span">孤独症康复教育人员上岗培训教材（第二版）</div>
						<van-icon name="arrow" size="10" color="#999" />
					</div>
				</div>
			</div> -->
			<!-- 讲师信息部分 -->
			<!-- <div class="teacher">
				<div class="title">
					<div class="left">授课老师</div>
					<router-link to="/curriculum/teacher">
						<div class="right">
							<div class="span">查看全部</div>
							<van-icon name="arrow" size="10" color="#999" />
						</div>
					</router-link>
				</div>
				<div class="scroll-div">
					<div class="scroll-item" v-for="(item,index) in 10" :key="index">
						<div class="item">
							<div class="portrait">
								<img class="portraitImg" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9b6e553d8c8cb6790a9a5f9689fd2dc966c1b6063319994f8a0aa8a4297f75cd" />
							</div>
							<div class="name">
								宋美丽
							</div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 详情介绍部分 -->
			<div class="details">
				<div class="detailsTitle">
					<div class="up">
						<img class="detailsLeft" src="@/assets/image/selected/detailsLeft.png" />
						详情介绍
						<img class="detailsRight" src="@/assets/image/selected/detailsRight.png" />
					</div>
					<div class="down">
						课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育的基础理论、实际操作流程及上岗须知等。第二部分为实操课，包括回合操作示范、辅助方式、提要求、听者理解、命名、配对、动作模仿等内容。第三部分专家课，分别由贾美香教授、郭课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育的基础理论、实际操作流程及上岗须知等。第二部分为实操课，包括回合操作示范、辅助方式、提要求、听者理解、命名、配对、动作模仿等内容。第三部分专家课，分别由贾美香教授、郭课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育的基础理论、实际操作流程及上岗须知等。第二部分为实操课，包括回合操作示范、辅助方式、提要求、听者理解、命名、配对、动作模仿等内容。第三部分专家课，分别由贾美香教授、郭
					</div>
				</div>
			</div>
			<!-- 课程介绍、目录、评论... -->
			<!-- <div class="tabsBox">
				<van-tabs v-model="active" class="van-tabs-default">
					<van-tab 
						v-for="(item, index) in list" 
						:key="index" 
						:title="item.title"></van-tab>
				</van-tabs>
				<div class="tabsContent">
					<div class="" v-show="active == 0">
						介绍
					</div>
					<div class="catalogue" v-show="active == 1">
						<div class="catalogueList">
							<forTree :tree="tree" @nodeClick="treeClick"/>
						</div>
					</div>
					<div class="" v-show="active == 2">
						学员
					</div>
					<div class="" v-show="active == 3">
						报名
					</div>
					<div class="" v-show="active == 4">
						评价
					</div>
				</div>
			</div> -->
		</div>
		<div class="footBtn">
			<!-- <div class="collect">
				<img class="collectImg" src="@/assets/image/selected/2022062610411.png" />
				<span>客服</span>
			</div>
			<div class="freediving">
				<img class="freedivingImg" src="@/assets/image/selected/2022062610412.png" />
				<span>购物车</span>
			</div> -->
        <span class="nowbtn all-btn-bigCircle">立即预约</span>
      
			<!-- <div class="btnGroup">
				<div class="shoppingCart">

					<span class="buyspan">加入购物车</span>
				</div>
				<div class="purchase">
					<span class="buyspan">立即购买</span>
				</div>
			</div> -->

		</div>
		<!-- 请选择培训场次 -->
		<n-transition ref="trainPop" speed="ease-in-out" :height="612" :maskVal="0.35">
			<div class="popContentBox">
				<div class="popBox">
					<div class="titleBox">
						<span class="title">请选择培训场次</span>
						<van-icon name="cross" size="20" color="#999" @click="close" />
					</div>
					<div class="popContent">
						<div class="popItem"   v-for="(item,index) in NumberList" @click="selected(item)" :key="index" :class="{pick: activeName == item}">
							<div class="up">
								<div class="left">
									<span>{{item.name}}</span>
								</div>
								<div class="right">
									<span>剩余：<span class="num">{{item.num}}</span></span>
								</div>
							</div>
							<div class="center">
								<span>{{item.time}}</span>
							</div>
							<div class="down">
								<span>{{item.address}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="popbutton">
					<span class="btn all-btn-bigCircle">确 定</span>
				</div>
			</div>
		</n-transition>

		<!-- 优惠券弹窗 -->
		<n-transition ref="trainPop1" speed="ease-in-out" :height="415" :maskVal="0.35">
			<div class="windowCoupons">
				<div class="navBar">
					<div class="center">领取优惠券</div>
					<div class="right" @click="close1">
						<van-icon name="cross" color="#666" size="20" />
					</div>
				</div>
				<div class="coupon">
					<div class="couponItem">
						<div class="left">
							<!-- <span class="price"><span>¥</span>100</span> -->
							<span class="price">8.2<span>折</span></span>
							<span class="condition">满500可用</span>
							<span class="rightTop"></span>
							<span class="rightBottom"></span>
							<img v-show="false" src="@/assets/image/my/overdue.png" />
						</div>
						<div class="right">
							<span class="title">孤独症课程优惠券</span>
							<span class="time">2022.04.06.23:59:59</span>
							<span class="type">[全场使用]</span>
							<span class="btn">可用商品</span>
							<img src="@/assets/image/my/Received.png" />
						</div>
					</div>
					<div class="couponItem">
						<div class="left">
							<span class="price"><span>¥</span>100</span>
							<span class="condition">满500可用</span>
							<span class="rightTop"></span>
							<span class="rightBottom"></span>
							<img v-show="false" src="@/assets/image/my/overdue.png" />
						</div>
						<div class="right">
							<span class="title">孤独症课程优惠券</span>
							<span class="time">2022.04.06.23:59:59</span>
							<span class="type">[全场使用]</span>
							<span class="btn">可用商品</span>
						</div>
					</div>
					<div class="couponItem">
						<div class="left">
							<span class="price"><span>¥</span>100</span>
							<span class="condition">满500可用</span>
							<span class="rightTop"></span>
							<span class="rightBottom"></span>
							<img v-show="false" src="@/assets/image/my/overdue.png" />
						</div>
						<div class="right">
							<span class="title">孤独症课程优惠券</span>
							<span class="time">2022.04.06.23:59:59</span>
							<span class="type">[全场使用]</span>
							<span class="btn">可用商品</span>
						</div>
					</div>
				</div>
			</div>
		</n-transition>
	</div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
import forTree from '@/components/forTree/index.vue'
export default {
	// 可用组件的哈希表
	components: {nTransition, forTree},
	// 接收传值
	props: {},
	// 数据对象
	data () {
		return {
			activeName: '',
			autoplay: false,
			duration: 500,
			current: 0,
			swiperCurrent: 0,
			swiperList:4,
			list: [{
					title: '课程介绍'
				}, {
					title: '目录'
				},{
					title: '学员须知'
				}, {
					title: '报名流程'
				},{
					title: '评价'
				}
			],
			NumberList:[{
				name:'2021年 第2期：',
				time:'2020/12/15-2020/12/15',
				num:'233',
				address:'北京市朝阳区已和阳光大厦北京市朝阳区'
			},{
				name:'2021年 第2期：',
				time:'2020/12/15-2020/12/15',
				num:'233',
				address:'北京市朝阳区已和阳光大厦北京市朝阳区'
			},{
				name:'2021年 第2期：',
				time:'2020/12/15-2020/12/15',
				num:'233',
				address:'北京市朝阳区已和阳光大厦北京市朝阳区'
			},{
				name:'2021年 第2期：',
				time:'2020/12/15-2020/12/15',
				num:'233',
				address:'北京市朝阳区已和阳光大厦北京市朝阳区'
			}],
			active: 0,
			tree:[{
					"id":1,
					"name":"第一部分：上岗培训",
					"code":null,
					"speaker":null,
					"duration":null,
					"isHaveQuestion":0,
					"isWatch":0,
					"isPass":null,
					"hourId":null,
					"children":[
							{
									"id":7,
									"name":"第一章 孤独症康复教育人员上岗须知",
									"code":null,
									"speaker":null,
									"duration":null,
									"isHaveQuestion":0,
									"isWatch":0,
									"isPass":null,
									"hourId":null,
									"children":[
											{
													"id":8,
													"name":"01 孤独症康复教育人员上岗须知（一）",
													"code":null,
													"speaker":"张萱",
													"duration":"11:59",
													"isHaveQuestion":1,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":null,
													"pid":7
											},
									],
									"pid":1
							},
							{
									"id":12,
									"name":"第二章 孤独症的基本知识",
									"code":null,
									"speaker":null,
									"duration":null,
									"isHaveQuestion":0,
									"isWatch":0,
									"isPass":null,
									"hourId":null,
									"children":[
											{
													"id":17,
													"name":"第三节 孤独症的特点",
													"code":null,
													"speaker":null,
													"duration":null,
													"isHaveQuestion":0,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":[
															{
																	"id":18,
																	"name":"01 孤独症的特点（一）",
																	"code":null,
																	"speaker":"张萱",
																	"duration":"13:07",
																	"isHaveQuestion":1,
																	"isWatch":0,
																	"isPass":null,
																	"hourId":null,
																	"children":null,
																	"pid":17
															},
													],
													"pid":12
											},
									],
									"pid":1
							},
							{
									"id":28,
									"name":"第三章 应用行为分析",
									"code":null,
									"speaker":null,
									"duration":null,
									"isHaveQuestion":0,
									"isWatch":0,
									"isPass":null,
									"hourId":null,
									"children":[
											{
													"id":29,
													"name":"第一节 应用行为分析学的历史",
													"code":null,
													"speaker":null,
													"duration":null,
													"isHaveQuestion":0,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":[
															{
																	"id":30,
																	"name":"01 应用行为分析学的历史（一）",
																	"code":null,
																	"speaker":"张萱",
																	"duration":"08:18",
																	"isHaveQuestion":1,
																	"isWatch":0,
																	"isPass":null,
																	"hourId":null,
																	"children":null,
																	"pid":29
															},
															{
																	"id":31,
																	"name":"02 应用行为分析学的历史（二）",
																	"code":null,
																	"speaker":"张萱",
																	"duration":"12:19",
																	"isHaveQuestion":1,
																	"isWatch":0,
																	"isPass":null,
																	"hourId":null,
																	"children":null,
																	"pid":29
															}
													],
													"pid":28
											},
									],
									"pid":1
							},
					],
					"pid":0
			},
			{
					"id":2,
					"name":"第二部分：实操课",
					"code":null,
					"speaker":null,
					"duration":null,
					"isHaveQuestion":0,
					"isWatch":0,
					"isPass":null,
					"hourId":null,
					"children":[
							{
									"id":169,
									"name":"第一章 实操课",
									"code":null,
									"speaker":null,
									"duration":null,
									"isHaveQuestion":0,
									"isWatch":0,
									"isPass":null,
									"hourId":null,
									"children":[
											{
													"id":170,
													"name":"01 实操课（一） ",
													"code":null,
													"speaker":"张苗苗",
													"duration":"06:55",
													"isHaveQuestion":1,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":null,
													"pid":169
											},
											{
													"id":171,
													"name":"02 实操课（二） ",
													"code":null,
													"speaker":"张苗苗",
													"duration":"04:11",
													"isHaveQuestion":1,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":null,
													"pid":169
											},
									],
									"pid":2
							}
					],
					"pid":0
			},
			{
					"id":3,
					"name":"第三部分：专家课",
					"code":null,
					"speaker":null,
					"duration":null,
					"isHaveQuestion":0,
					"isWatch":0,
					"isPass":null,
					"hourId":null,
					"children":[
							{
									"id":186,
									"name":"第一章 孤独症谱系障碍临床概况",
									"code":null,
									"speaker":null,
									"duration":null,
									"isHaveQuestion":0,
									"isWatch":0,
									"isPass":null,
									"hourId":null,
									"children":[
											{
													"id":187,
													"name":"第一节 孤独症的历史及特例",
													"code":null,
													"speaker":null,
													"duration":null,
													"isHaveQuestion":0,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":[
															{
																	"id":188,
																	"name":"01 孤独症的历史及特例 ",
																	"code":null,
																	"speaker":"贾美香",
																	"duration":"11:45",
																	"isHaveQuestion":1,
																	"isWatch":0,
																	"isPass":null,
																	"hourId":null,
																	"children":null,
																	"pid":187
															}
													],
													"pid":186
											},
											{
													"id":189,
													"name":"第二节 孤独症谱系障碍",
													"code":null,
													"speaker":null,
													"duration":null,
													"isHaveQuestion":0,
													"isWatch":0,
													"isPass":null,
													"hourId":null,
													"children":[
															{
																	"id":190,
																	"name":"01 孤独症谱系障碍 ",
																	"code":null,
																	"speaker":"贾美香",
																	"duration":"14:06",
																	"isHaveQuestion":1,
																	"isWatch":0,
																	"isPass":null,
																	"hourId":null,
																	"children":null,
																	"pid":189
															}
													],
													"pid":186
											},
									],
									"pid":3
							},
					],
					"pid":0
			}],
		}
	},
	// 事件处理器
	methods: {
		// 选择优惠券
		onSelectCoupons(){
			this.$refs.Coupons.open()
			this.Coupons = show
		},
		treeClick(e){
			this.$emit('nodeClick', e)
		},
		goBack(){
			this.$onReturn(this.$route.query.fromMenu == '1')
		},
		SwiperTab(e){
			this.current = e
		},
		popUp () {
			this.$refs['trainPop'].show()
		},
		close(){
			this.$refs['trainPop'].hide()
		},

		popUp1 () {
			this.$refs['trainPop1'].show()
		},
		close1(){
			this.$refs['trainPop1'].hide()
		},
		selected: function(gameName) {
			// console.log(gameName)
			this.activeName = gameName
		}
	},
	// 生命周期-实例创建完成后调用
	created () { },
	// 生命周期-实例挂载后调用
	mounted () { },
	// 生命周期-实例销毁离开后调用
	destroyed () { },
	// 计算属性监听
	computed: {},
	// 自定义的侦听器
	watch: {}
}
</script>

<style lang="less" scoped>
	.myCourse{
		.courseHeader{
			height: 265px;
			// border-radius: 0 0 17px 17px;
			background: #fff;
			position: relative;
			.btnGroup{
				position: absolute;
				right: 18px;
				// top: 0;
				display: flex;
				bottom: -40px;
				align-items: center;
				.collectBox{
					text-align: center;
					.collect{
						width: 32px;
						height: 32px;
					}
					.collectspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
					}
				}
				.tryBox{
					text-align: center;
					margin-right: 20px;
					.tryImg{
						width: 32px;
						height: 32px;
					}
					.tryspan{
						display: block;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						line-height: 17px;
					}
				}

			}
			.back{
				position: absolute;
				left: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.share{
				position: absolute;
				right: 16px;
				top: 50px;
				z-index: 999;
				width: 32px;
				height: 32px;
			}
			.swiper{
				height: 265px;
				.swiperImg{
					width: 100%;
					height: 265px;
					// border-radius: 0 0 17px 17px;
				}
				/deep/ .uni-swiper-dot-active {
					width: 16px !important;
					height: 4px !important;
				    background-color: #fff !important;
					border-radius: 2px;
				}
				/deep/ .uni-swiper-dot {
				    display: inline-block;
				    width: 4px;
				    height: 4px;
				    cursor: pointer;
				    background: rgba(255, 255, 255, 0.6);
				    // border-radius: 0%;
				}
			}
			/* 指示器样式 */
			.u-indicator-item-number {
				width: 20px;
				padding: 7.5px;
				line-height: 1;
				background-color: rgba(0, 0, 0, 0.35);
				border-radius: 50px;
				font-size: 11px;
				color: rgba(255, 255, 255, 0.8);
				position: absolute;
				right: 20px;
				bottom: 15px;
				text-align: center;
				letter-spacing: 1.5px;
			}
		}
		.courseExplain{
			padding: 18px;
			// height: 116.5px;
			background: #fff;
			// margin-top: 8px;
			.explainUp{
				display: flex;
				margin-top: 10px;
				.left{
					flex: 1;
					line-height: 19px;
					align-items: center;
					display: flex;
					.label{
						width: 42.5px;
						height: 19px;
						padding: 2px 6px;
						display: inline-block;
						background: url(~@/assets/image/selected/tag.svg) no-repeat;
						background-size:100% 100%;
						font-size: 10px;
						font-weight: 400;
						color: #F0A300;
						text-align: center;
						margin-right: 9px;
						align-items: center;
					}
					.price{
						font-size: 20px;
						font-weight: 500;
						color: #EC6C01;
						font-family: PingFangMedium;
					}
					.originalPrice{
						font-size: 11px;
						font-weight: 400;
						color: #666666;
						font-family: PingFangMedium;
						margin-left: 10px;
						align-items: center;
					}
				}
				.right{
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}
			.explainCenter{
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				line-height: 28px;
				margin-top: 12px;
			}
			.explainDown{
				.courseType{
					display: flex;
					margin: 12px 0 10px 0;
					font-family: PingFangMedium;
					.left{
						flex: 1;
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						line-height: 20px;
					}
					.right{
						font-size: 11px;
						font-weight: 500;
						color: #15B7DD;
						line-height: 16px;
					}

				}
				.fload{
					display: -webkit-box;
					word-break: break-all;
					-webkit-box-orient: vertical;
					/* 要显示多少行就改变line-clamp的数据,此处折叠起来显示一行*/
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 6px;
					font-family: PingFangMedium;
					font-weight: 400;
					color: #666666;
					line-height: 15px;
				}
			}
		}
		.couponBox{
			padding: 18px;
			font-family: PingFangMedium;
			background: #fff;
			margin-top: 8px;
			.item{
				display: flex;
				.left{
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
					flex: 1;
				}
				.right{
					flex: 5;
					// margin-right: 12px;
					display: flex;
					align-items: center;
					.rightBox{
						display: flex;
						justify-content: space-between;
						flex: 1;
						padding-right: 12px;
						.tag{
							width: 28px;
							height: 16px;
							background: rgba(#F93C00, 0.1);
							border-radius: 2px;
							font-size: 10px;
							font-weight: bold;
							color: #F93C00;
							line-height: 16px;
							text-align: center;
							padding: 1px 4px;
						}
						.downitem{
							margin: 1px 10px 5px;
							font-size: 10px;
							font-weight: 400;
							color: #F93C00;
							line-height: 18px;
							height: 18px;
							text-align: center;
							position: relative;
							padding: 0 5px;
							background-image: url(~@/assets/image/selected/20220610-145020.png);
							background-position: center;
							background-size: 1px 18px;
							&::before,&::after{
								content: '';
								width: 4px;
								display: block;
								position: absolute;
								top: 0;
								left: -4px;
    						height: 100%;
								background-image: url(~@/assets/image/selected/left.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 4px 18px;
								transform: rotate(180deg);
							}
							&::after{
								content: '';
								left: auto;
								right: -4px;
								transform: rotate(0deg);
							}
						}
					}
				}
			}
		}
		.combination{
			padding: 18px;
			padding-bottom: 0;
			background: #fff;
			margin-top: 8px;
			.combinationTitle{
				span{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					font-weight: bold;
				}
			}
			.combinationBox{
				margin-top: 22px;
				.item{
					display: flex;
					padding-bottom: 16px;
					.courseImg{
						width: 100px;
						height: 70px;
					}
					.right{
						padding: 2.5px 0 0 11px;
						.title{
							display: flex;
							font-size: 14.5px;
							font-weight: 400;
							color: #333333;
							line-height: 25.5px;
						}
						.price{
							display: flex;
							font-size: 10px;
							font-weight: 400;
							color: #999999;
							line-height: 14.5px;
							margin-top: 6px;
						}
						.identification{
							font-size: 11px;
							font-weight: 400;
							color: #1E97B4;
							line-height: 15.5px;
						}
					}
				}
			}

		}
		.relatedIntroduction{
			padding: 18px;
			background: #fff;
			margin-top: 8px;
			.item{
				display: flex;
				padding-bottom: 16px;
				border-bottom: 1px solid #F5F5F5;
				.left{
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
					flex: 1;
					text-align: right;
				}
				.right{
					flex: 4;
					margin-left: 12px;
					font-size: 13px;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					.span{
						flex: 1;
					}
					.copy{
						font-size: 13px;
						font-weight: 400;
						color: #15B7DD;
						line-height: 22px;
					}
				}
			}
			.item:not(:first-child) {
				padding-top: 18px;
			}
			.item:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
		.teacher{
			padding: 18px;
			background: #fff;
			margin-top: 8px;
			.title{
				display: flex;
				.left{
					flex: 1;
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
				}
				.right{
					display: flex;
					align-items: center;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 17px;
					.span{
						margin-right: 3px;
					}
				}
			}
			.scroll-div{
				width: 100%;
				overflow-x: scroll;
				white-space: nowrap;
				.scroll-item{
					display: inline-block;
					text-align: center;
					.item{
						margin-top: 8px;
						padding-right: 24px;
						.portrait{
							width: 56px;
							height: 56px;
							border-radius: 100%;
							.portraitImg{
								width: 56px;
								height: 56px;
								border-radius: 100%;
							}
						}
						.name{
							font-size: 14px;
							font-weight: 400;
							color: #333333;
							line-height: 20px;
							margin-top: 8px;
						}
					}
				}
			}
		}
		.details{
			// padding: 18px;
			background: #fff;
			margin-top: 8px;
			.detailsTitle{
				.up{
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
					padding: 16px 0 0 0;
					text-align: center;
					img{
						width: 56px;
						height: 8px;
						vertical-align: middle;
					}
				}
				.down{
					padding: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
				}
			}
		}
		.tabsBox{
			margin-top: 24px;
			.tabsContent{
				padding: 0 18px;
				background: #fff;
				.catalogue{
					.catalogueList{
						padding: 10px 0;
					}
				}
			}
		}
		.footBtn{
			box-sizing: border-box;
			width: 100%;
			position: fixed;
			bottom: 0;
			z-index: 10;
			padding: 10px 40px;
			overflow: hidden;
			background: #FFFFFF;
			display: flex;
			box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
			.all-btn-bigCircle {
				width: 100%;
			}
      .nowbtn{
				margin: 0 auto;
          
      }
			.collect{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				margin-left: 5px;
				padding: 8px 0;
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
				}
				.collectImg{
					height: 18px;
				}
			}
			.freediving{
				flex: 1;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 12px;
				padding: 8px 0;
				.freedivingImg{
					height: 18px;
				}
				span{
					display: block;
					margin-top: 2px;
					font-size: 10px;
					line-height: 15px;
					color: #666;
        }
			}
			.btnGroup{
				flex: 4;
				display: flex;
				background: url(~@/assets/image/selected/20220626-112349.png) no-repeat;
				background-size:100% 100%;
				height: 40px;
				line-height: 40px;
				margin: 2px 7.5px 0 7.5px;
				.shoppingCart{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyspan{
						font-size: 10px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
				.purchase{
					flex: 1;
					text-align: center;
					line-height: 36px;
					.buyspan{
						font-size: 10px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 14px;
					}
				}
			}
		}
		.line{
			display: inline-block;
			width: 3px;
			height: 13px;
			background: #15B7DD;
			border-radius: 4px;
			margin-right: 4px;
		}
		.moneySign{
			font-size: 11px;
		}
		// 培训地点选择
		.popContentBox{
			.popBox{
				.titleBox{
					padding: 28px 34px 0 34px;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					line-height: 25px;
					display: flex;
					span{
						display: inline-block;
					}
					.title{
						flex: 1;
					}
				}
				.popContent{
					height: 503px;
					overflow-y: scroll;
					padding: 0 18px;
					.popItem{
						padding: 16px 12px;
						box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
						border-radius: 8px;
						margin-bottom: 8px;
						.up{
							display: flex;
							.left{
								font-size: 14px;
								font-weight: bold;
								color: #333333;
								line-height: 20px;
								flex: 1;
							}
							.right{
								font-size: 12px;
								font-weight: 400;
								color: #666666;
								line-height: 17px;
								.num{
									color: #15B7DD;
								}
							}
						}
						.center{
							padding: 8px 0;
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 17px;
						}
						.down{
							font-size: 12px;
							font-weight: 400;
							color: #666666;
							line-height: 20px;
						}
						&.pick {
							background: #15B7DD;
							box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
							.up{
								.left,.right{
									color: #FFFFFF
								}
							}
							.center,.down,.num{
								color: #FFFFFF !important;
							}
						}
					}
					.popItem:first-child{
						margin-top: 16px;
					}
				}
			}
			.popbutton{
				box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
				padding: 5px 0;
				position: fixed;
				width: 100%;
				bottom: 0;
				.btn{
					width: 295px;
					height: 40px;
					display: block;
					text-align: center;
					margin: 0 auto;
					line-height: 40px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
				}
				
			}
		}
	}
	.windowCoupons{
		background-color: #fff;
		border-radius: 24px 24px 0 0;
		padding: 0 18px;
		.navBar{
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 18px 0;
			.center{
				font-size: 18px;
				line-height: 25px;
				color: #333;
			}
			.right{
				position: absolute;
				right: 0;
			}
		}
	}
	.coupon{
		padding-bottom: 25px;
		.couponItem{
			background: rgba(249, 0, 0, 0.03);
			border-radius: 6px;
			border: 1px solid #F93C00;
			display: flex;
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
				flex-direction: column;
				text-align: center;
				align-items: center;
				padding: 15px 19px 15px 14px;
				border-right: 1px dashed #F93C00;
				position: relative;
				.rightTop{
					width: 20px;
					height: 10px;
					border-left: 1px solid #EC6C01;
					border-right: 1px solid #EC6C01;
					border-bottom: 1px solid #EC6C01;
					// border-top: 1px solid #F7F7F9;
					border-top: 0;
					box-sizing: border-box;
					position: absolute;
					right: -11px;
					top: -1px;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					background-color: #F7F7F9;
				}
				.rightBottom{
					width: 20px;
					height: 10px;
					border-left: 1px solid #EC6C01;
					border-right: 1px solid #EC6C01;
					border-top: 1px solid #EC6C01;
					border-bottom: 0;
					box-sizing: border-box;
					position: absolute;
					right: -11px;
					bottom: -1px;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					background-color: #F7F7F9;
				}
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: -1px;
					top: -1px;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
	/deep/.van-swipe__indicators{
		.van-swipe__indicator{
			background-color: #fff;
			opacity: 0.6;
			border-radius: 3px;
			transition: width 0.3s;
			height: 4px;
			width: 4px;
		}
		.van-swipe__indicator--active{
			opacity: 1;
			width: 16px;
		}
	}
</style>
