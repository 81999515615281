<template>
  <div class="evaluate">
    <van-nav-bar
      class="nav-circular"
      title="商品评价"
      left-text=""
      right-text=""
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="goBack"
    />
    <div class="evaluateItem" v-for="(item, index) in productList" :key="index">
      <div class="shopItem">
        <img :src="item.productPicture" />
        <div class="rightMess">
          <div class="titlePrice">
            <span class="title all-font-ellipsis">{{ item.productName }}</span>
            <span class="price">¥ {{ item.payPrice.toFixed(2) }}</span>
          </div>
          <div class="navNum">
            <span
              class="navTime"
              v-if="item.productType == 1 && (item.openTime || item.otherNotes)"
              >{{ item.openTime ? item.openTime : item.otherNotes }}</span
            >
            <span class="navTime" v-if="item.productType == 4">{{
              item.examNumberNotes
            }}</span>
            <span class="navTime" v-if="item.productType == 5">{{
              item.otherNotes
            }}</span>
            <span
              class="navTime"
              v-if="item.productType == 6 || item.productType == 7"
              >{{ item.examNumberNotes }} <br v-if="!item.productPicture" />
              {{ item.courseDeferralDaysNotes }}</span
            >
            <!-- <div
            class="reimburse"
            v-if="item.productType != 2 && item.productType != 3"
          >
            {{
              item.productType == 2 || item.productType == 3
                ? "支持七天无理由退货"
                : "不支持退款及转让"
            }}
          </div> -->
          <div class="box" v-if="item.productType == 2 || item.productType == 3" style="height:20px"><!-- 占位盒子 --></div>
            <span
              class="num"
              v-if="item.productType == 2 || item.productType == 3"
              >x{{ item.productCount }}</span
            >
          </div>
          <!-- <div
            class="reimburse"
            v-if="item.productType != 2 && item.productType != 3"
          >
            {{
              item.productType == 2 || item.productType == 3
                ? "支持七天无理由退货"
                : "不支持退款及转让"
            }}
          </div> -->
          <div class="box" v-if="item.productType == 2 || item.productType == 3" style="height:20px"><!-- 占位盒子 --></div>
        </div>
      </div>
      <div class="score">
        <span class="title"><span>*</span>描述相符：</span>
        <div class="scoreRight">
          <div
            class=""
            v-for="indexs in 5"
            :key="indexs"
            @click="edit(indexs, index)"
          >
            <!-- 加1仅作判断使用，不参与任何传值 -->
            <img
              v-if="item.evaluation + 1 <= indexs"
              src="../../assets/image/icon/good1_icon.png"
            />
            <img v-else src="../../assets/image/icon/good_icon.png" />
          </div>
        </div>
      </div>
      <van-field
        v-model="item.comment"
        rows="1"
        autosize
        class="evaluateMessage"
        label=""
        type="textarea"
        placeholder="说两句，帮助更多想买的人"
      />
      <div class="anonymous" v-if="false">
        <span>匿名评价：</span>
        <van-switch
          v-model="checked"
          active-color="#15B7DD"
          inactive-color="#dcdee0"
          style="transform: scale(0.56)"
        />
      </div>
    </div>
    <div class="footerBtn">
      <van-button
        plain
        type="primary"
        class="btn"
        :loading="visible"
        @click="submitEvaluate"
        >提交评价</van-button
      >
      <!-- <button type="default" class="btn">提交评价</button> -->
    </div>
    <van-popup v-model="onPopup">
      <div class="box_popup">
        <div class="popup_title">
          <van-icon name="warning-o" color="#EB3C31" size="26" />
          <span class="title_con">提示信息</span>
        </div>
        <div class="popup_con">您已成功发表评价</div>
        <div class="popup_button_center">
          <span
            class="all-btn-middle btn2"
            @click="$onReturn(this.$route.query.fromMenu == '1'), (onPopup = false)"
            >返回上一页</span
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onPopup: false,
      orderId: null,
      productList: null,
      visible: false,
      // 最后需要传输的评价状态
      numevaluation: 0,
    };
  },
  methods: {
    // 选择评价
    edit(e, r) {
      this.productList[r].evaluation = e;
      // console.log(this.productList[r].evaluation);
    },
    // 获取订单详情
    getOrderDetail() {
      this.$ajax({
        url: "/hxclass-mobile/order/" + this.orderId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = res.data.productList.filter(item=>{
            return item.productType != 4 && item.type == 1;
          })
          this.productList = arr.map((item) => {
            item["comment"] = ""; //点评
            item["evaluation"] = 5; //评价 1.差评,2.中评,3.中评,4.好评,5.好评
            item["orderId"] = this.orderId; //订单id
            return item;
          });
        } else {
          this.$Toast(res.message);
        }
      });
    },
    // 提交评价
    submitEvaluate() {
      this.visible = true;
      // console.log(111111111)
      // let status = true;
      // this.productList.map(item=>{
      // 	if(!item.comment){
      // 		status = false;
      // 	}
      // })
      // if(!status){
      // 	this.visible = false;
      // 	return this.$Toast('请填写评价信息');
      // }
      let _params = this.productList.map((item) => {
        return {
          comment: item.comment,
          evaluation: item.evaluation,
          orderId: item.orderId,
          productId: item.productId,
        };
      });
      this.$ajax({
        url: "/hxclass-mobile/order-comment/",
        method: "post",
        params: _params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // this.onPopup = true;
          this.$Toast("您已成功发表评价");
          setTimeout(() => {
            // this.$onReturn(this.$route.query.fromMenu == '1')
            this.$router.replace({name:"keepAliveMyOrder"});
          }, 1000);
        } else {
          this.visible = false;
          this.$Toast(res.message);
        }
      });
    },
    goBack(){
      this.$router.replace({name:"keepAliveMyOrder"});
    },
  },
  created() {
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.getOrderDetail();
      // 监听页面返回
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.goBack, false);
      }
    }
  },
  beforeDestroy(){
    // 离开页面去掉监听
    window.removeEventListener('popstate', this.goBack, false);
  },
};
</script>

<style scoped lang="less">
/deep/.van-button--primary {
  border: 0;
}
.evaluate {
  min-height: calc(100vh - 44px);
  background: #f7f7f9;
  padding: 12px 18px 62px;
  box-sizing: border-box;
  .footerBtn {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    padding: 5px 40px;
    box-sizing: border-box;
    background: #f7f7f9;
    .btn {
      width: 100%;
      background-image: url("~@/assets/image/icon/20220215041505.png");
      background-size: 100% 100%;
      color: #ffffff;
      border-radius: 20px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
    .btn::after {
      border: 0;
    }
  }
  .evaluateItem {
    margin-bottom: 8px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 12px 29px;
    .evaluateMessage {
      min-height: 100px;
      margin-top: 24px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #cccccc;
      font-size: 12px;
      color: #666666;
      line-height: 17px;
      box-sizing: border-box;
    }
    .anonymous {
      display: flex;
      align-items: center;
      margin-top: 16px;
      span {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
    }
    .score {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        color: rgba(102, 102, 102, 1);
        line-height: 22px;
        span {
          color: rgba(249, 0, 0, 1);
        }
      }
      .scoreRight {
        margin-left: 4px;
        display: flex;
        flex: 1;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          span {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            line-height: 20px;
          }
          .active {
            color: rgba(21, 183, 221, 1);
          }
        }
      }
    }
    .shopItem {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 12px;
      padding-top: 12px;
      display: flex;
      &:first-child {
        padding-top: 8px;
      }
      img {
        width: 100px;
        height: 70px;
        border-radius: 7px;
      }
      .rightMess {
        flex: 1;
        padding-left: 12px;
        .titlePrice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            flex: 1;
            -webkit-line-clamp: 1;
            font-size: 12px;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
          }
          .price {
            width: 60px;
            text-align: right;
            font-size: 10px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 22px;
          }
        }
        .navNum {
          display: flex;
          justify-content: space-between;
          margin-top: 9px;
          align-items: center;
          .navTime {
            padding: 4px 8.5px;
            padding-left: 0;
            border-radius: 2px;
            font-size: 10px;
            color: #999999;
            line-height: 14px;
          }
          .num {
            font-size: 12px;
            color: #999999;
            line-height: 17px;
          }
        }
        .reimburse {
        	display: inline-block;
          padding: 1px 4px;
          border-radius: 2px;
          border: 1px solid rgba(0, 179, 197, 0.5);
          font-size: 8px;
          color: #15b7dd;
          line-height: 13px;
          font-family: PingFang HK-Regular;
        }
      }
    }
  }
}
// 提示信息
.box_popup {
  box-sizing: border-box;
  position: relative;
  width: 305px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 16px;
  z-index: 10;
  .popup_title {
    display: flex;
    align-items: center;
    .title_con {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .popup_con {
    line-height: 25px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-top: 12.5px;
  }
  .popup_button_center {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
  .popup_button {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    &.middle {
      justify-content: center;
    }
    .all-btn-middle {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFangMedium;
      font-weight: 500;
    }
    .btn1 {
      color: #666666;
      background: #eeeeee;
      box-shadow: none;
    }
  }
}
</style>
