<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="证书详情"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <div class="all-title">基本信息：</div>
      <div class="all-white-bg">
        <div class="detail-box">
          <div class="header-inner">
            <div class="side-left"></div><div class="name"><span class="span">{{dataObj.name}}</span>教师</div>
            <!-- <div class="side-right"></div><div class="card">130582********121X</div> -->
            <div class="side-right"></div><div class="card">{{managedateIdcard(dataObj.idcard)}}</div>
          </div>
          <span class="company">工作单位：{{dataObj.workUnit}}</span>
        </div>
      </div>
      
      <!-- 证书信息 -->
      <div class="information-box">
        <div class="all-title">证书信息：</div>
        <img 
          class="cover-img" 
          :src="dataObj.url"
          @click="showImg()" />
        <div class="foo-inner">
          <div class="certificate-name">{{ type == 1 ? dataObj.examName : dataObj.certifName}}</div>
          <div class="flex">
            <span class="number">证书编号：{{dataObj.code}}</span>
            <span class="time">发证时间：{{managedate(dataObj.getcertifTime)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      certifId: '', //证书id
      type: '',  //证书类型 1.查本系统证书  2.查导入证书
      dataObj: {},
    }
  },
  // 事件处理器
  methods: {
    // 证书详情
    getDetail() {
      this.$ajax({
        url: '/hxclass-mobile/exam/certif/query/get',
        method: "get",
        params: {
          certifId: this.certifId, //证书ID
          type: this.type, //证书类型 1.查本系统证书  2.查导入证书
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.dataObj = res.data[0]
        } else {
          this.$alert(res.message);
        }
      })
    },
    // 预览证书
    showImg(index) {
      this.$ImagePreview([this.dataObj.picture]);
    },
    // 日期处理
    managedate(date) {
      if (!date) { return }
      let year = date.split('-')[0]
      let month = date.split('-')[1]
      return year+'年'+month+'月'
    },
    // 身份证处理
    managedateIdcard(con) {
      if (!con) { return }
      return con.substring(0,6)+'********'+con.substring(14,con.length)
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.certifId = this.$route.query.id
    this.type = this.$route.query.type
    // 证书详情
    this.getDetail()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import "./select.less";
  .information-box{
    margin-top: 18px;
    .all-title{
      margin-bottom: 14px;
    }
    .cover-img{
      width: 100%;
    }
    .foo-inner{
      margin-top: 14px;
      font-family: PingFangMedium;
      .certificate-name{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
      }
      .flex{
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        font-size: 10px;
        font-weight: 500;
        color: #666666;
        line-height: 15px;
        span:nth-child(1){
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
</style>
