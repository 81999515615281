<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="证书查询"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 查询条件输入框 -->
    <div class="content-box">
      <div class="all-white-bg">
        <div class="input-box">
          <van-field class="input" :border='false' :value="form.courseName" :readonly='true' @click="addressShow = true" placeholder="请选择班期" />
          <van-icon color="#999999" name="arrow" />
        </div>
        <div class="input-box">
          <van-field class="input" v-model="form.name" placeholder="请输入姓名" />
        </div>
        <div class="input-box">
          <van-field class="input" v-model="form.idNumber" placeholder="请输入身份证号" />
        </div>
      </div>
      <div class="select-btn">
        <span class="all-btn-bigCircle" @click="getSearch">立即查询</span>
      </div>
      
      <!-- 查询结果 -->
      <div class="result-box" v-if="isShowData">
        <span class="all-title">查询结果</span>
        <div class="result-item">
          <div class="child-item" v-for="(item) in dataList" :key="item.id">
            <div class="p title">{{item.certificateName}}</div>
            <div class="p">发证日期：{{item.certificateTime}}</div>
            <span class="type detail" @click="goDetail(item.id)">查看详情</span>
          </div>
          <noMessage class="empty" v-if="dataList.length==0" type=6></noMessage>
        </div>
      </div>

      <van-popup v-model="addressShow" position="bottom" >
        <van-picker
          title="班期列表"
          show-toolbar
          value-key="name"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="addressShow = false"
        />
      </van-popup>
      
    </div>
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: {noMessage},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading:false,
      columns: [],
      addressShow: false,
      form:{
        courseName: '',
        idNumber: '',
        name: '',
      },
      dataList:[],
      isShowData: false, //搜索结果
    }
  },
  // 事件处理器
  methods: {
    // 证书详情
    goDetail(id){
      this.$router.push({path:'/search/zhongkangDetail',query:{id:id}});
    },
    // 选择证书类型
    onConfirm(item, index) {
      this.addressShow = false
      this.form.courseName = item
    },
    // 查询班期列表
    getScheduleList(){
      this.$ajax({
        url: '/hxclass-mobile/cncard/certificate/query/schedule',
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.columns = res.data
        }
      })
    },
    // 查询证书
    getSearch(){
      if (this.$store.state.userInfo.isLogin) {
        if (!this.form.courseName) {
          this.$Toast('请选择班期');
          return
        } else if (!this.form.name) {
          this.$Toast('请输入姓名');
          return
        } else if (!this.form.idNumber) {
          this.$Toast('请输入身份证号');
          return
        } 
        this.$ajax({
          url: '/hxclass-mobile/cncard/certificate/query',
          method: "get",
          params: this.form
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.dataList = res.data
            this.isShowData = true
            // 数据为空
            // if (this.dataList.length == 0) {
            //   this.$Toast('暂无数据');
            //   this.dataList = []
            // }
          } else {
            this.dataList = []
          }
        })
      }else{
        this.$router.push('/login/login');
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getScheduleList()
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>


<style lang="less" scoped>
@import "./select.less";
  .input-box{
    display: flex;
    align-items: center;
  }
  .result-item{
    position: relative;
  }
  .child-item{
    .idcard{
      font-size: 12px !important;
      color: #999999 !important;
    }
  }
</style>
