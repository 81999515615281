import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import config from './unit/config.js'
// ajax
import ajax from './unit/ajax.js'
import upload from './unit/upload.js'
// 共用js方法
import common from './assets/js/common.js'
// 微信配置
// import './assets/js/WeChat.js'
// ui库样式
import Vant from 'vant'
import { Toast, Dialog, ImagePreview, PullRefresh } from 'vant'
// 正则表达式
import regular from './assets/js/regular.js'
import 'vant/lib/index.less'
// 样式重置
import '@/assets/css/reset.css'
// 全局css
import '@/assets/css/common.less'
// 字体图标 
import "@/assets/font/iconfont.css"
// 无数据样式组件
import Nomessage from './components/nomessage.vue'
// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import infiniteScroll from "vue-infinite-scroll";
// vconsole调试
import VConsole from 'vconsole'
// 弹窗js/跳转登录页
import { toast,goLoginPage } from '@/assets/js/NativeWebKit.js';

// 页面返回
import { onReturn } from '@/unit/backPage.js'
// 拖拽图形校验
import puzzleVerification from 'vue-puzzle-verification'

// markDown编译器展示插件
import mavonEditor from 'mavon-editor'

import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)

if (process.env.NODE_ENV == 'development' && config.vconsole) {
    new VConsole()
}

Vue.use(infiniteScroll);
Vue.use(puzzleVerification)
Vue.use(VueAwesomeSwiper)

Vue.use(Vant)
Vue.use(PullRefresh)

Vue.config.ignoredElements = ['wx-open-launch-app'];

Vue.config.productionTip = false
Vue.prototype.$regular = regular
Vue.prototype.$Dialog = Dialog // 弹出框
Vue.prototype.$Toast = Toast // 轻提示ImagePreview
Vue.prototype.$ImagePreview = ImagePreview // 图片预览
Vue.prototype.$ajax = ajax
Vue.prototype.$upload = upload
Vue.prototype.$common = common //共用js方法
Vue.prototype.$moment = moment
Vue.prototype.$alert = toast
Vue.prototype.$onReturn = onReturn
Vue.prototype.$goLoginPage = goLoginPage



Vue.component('no-message', Nomessage) // 无数据样式组件
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')