// 我的学习
export default [{ // 考试
        path: 'myExam',
        component: () =>
            import ('../views/myStudy/myExam.vue')
    }, { // 学分
        path: 'studyCode',
        component: () =>
            import ('../views/myStudy/studyCode.vue')
    },
    { // 学分说明
        path: 'creditExplain',
        component: () =>
            import ('../views/myStudy/creditExplain.vue')
    },
    { // 学分说明
        path: 'share',
        component: () =>
            import ('../views/myStudy/share.vue')
    },
    { // 学习统计
        path: 'studyCensus',
        component: () =>
            import ('../views/myStudy/studyCensus.vue')
    },
    { //打卡分享页面
        path: 'clockShare',
        component: () =>
            import ('../views/myStudy/clockShare.vue')
    },
    { //上传证书
        path: 'certificateUpload',
        component: () =>
            import ('../views/myStudy/certificateUpload.vue')
    },
    { //证书预览
        path: 'certificatePreview',
        component: () =>
            import ('../views/myStudy/certificatePreview.vue')
    },
    { //证书列表
        path: 'certificate',
        component: () =>
            import ('../views/myStudy/certificate.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    { // 学习打卡
        path: 'clockLearn',
        component: () =>
            import ('../views/myStudy/clockLearn.vue')
    },
    { // 打卡记录
        path: 'clockRecord',
        component: () =>
            import ('../views/myStudy/clockRecord.vue')
    },
    { // 入班指南
        path: 'classGuide',
        component: () =>
            import ('../views/myStudy/classGuide.vue')
    },
    { // 入班指南
        path: 'studyCopy',
        component: () =>
            import ('../views/home/studyCopy.vue')
    },
    { // 满意度调查问卷
        path: 'questionnaire',
        component: () =>
            import ('../views/myStudy/questionnaire.vue')
    },
    { // 电子书
        path: 'eBook', // - 纯h5
        component: () =>
            import ('../views/myStudy/eBook.vue')
    },
    { // 电子书 - 原生交互版
        path: 'appeBook',
        component: () =>
            import ('../views/myStudy/appeBook.vue')
    }
]