<template>
  <div class="box" :class="{ 'overflow-hidden': !isShowMore }" :style="{'max-height': !isShowMore ? height+'px' : 'initial'}">
    <!-- 渲染文本类型 -->
    <p class="textOver" ref="TextOver" :class="{'textMore' : isShowMore}" v-if="type==1">
      {{text}}
      <span v-if="isShowMore" class="link stow" @click="handleHideMoreClick">收起<van-icon name="arrow-up" size="10" color="#333" /></span>
      <span v-else-if="isOverShow" class="show-more-btn" @click="handleShowMoreClick">
        <span style="color:#666"> ...</span>
        <span class="link"> 展开<van-icon name="arrow" size="10" color="#333" /></span>
      </span>
    </p>
    <!-- 渲染富文本文本类型 -->
    <template v-else-if="type==2">
      <p class="textOver" ref="TextOver" :class="{'textMore' : isShowMore}" v-html="text"></p>
      <span v-if="isShowMore" class="link stow txtRight" @click="handleHideMoreClick">收起<van-icon name="arrow-up" size="10" color="#333" /></span>
      <span v-else-if="isOverShow" class="show-more-btn" @click="handleShowMoreClick">
        <span class="link"> 展开<van-icon name="arrow" size="10" color="#333" /></span>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "TextOverflow",
  props: {
    // 文本
    text: {
			type: String,
			default: ''
    },
    // 设定展示的高度
    height: {
      type: Number,
			default: 0
    },
    type: {  // 1文本，2富文本
      type: Number,
			default: 0
    },
    
  },
  data() {
    return {
      isOverShow: false,  // 是否展示 展开收起
      isShowMore: false,
    };
  },
  mounted(){
    // 获取文字的高度
    if(this.type == 1){
      this.$nextTick(()=>{
        if(this.$refs.TextOver.clientHeight > this.height){
          this.isOverShow = true
        }
      })
    } else if(this.type == 2){
      setTimeout(()=>{
        if(this.$refs.TextOver.clientHeight > this.height){
          this.isOverShow = true
        }
      },100)
    }
  },
  methods: {
    handleShowMoreClick() {
      this.isShowMore = true;
    },

    handleHideMoreClick() {
      this.isShowMore = false;
    },
  },
};
</script>

<style>
.overflow-hidden {
  position: relative;
  overflow: hidden;
  max-height: 45px;
}

.box{
  position: relative;
}

.show-more-btn {
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  width: 65px;
  line-height: 15px;
  text-align: right;
  /* 背景从透明到白色，过渡 */
  background-image: linear-gradient(to right,  transparent, rgba(255, 255, 255, 0),  #ffffff 26.4%);
}

.link {
  font-size: 12px;
  line-height: 15px;
  color: #15B7DD;
  cursor: pointer;
}
.stow{
  white-space:nowrap;
}
.txtRight{
  position: absolute;
  right: 0;
  bottom: 0;
}
.textOver{
  color: #666666;
  display: inline-block;
  overflow: hidden;
  text-align: justify;
  font-size: 12px;
  line-height: 15px;
  /* 设置为行高的整倍数，此处显示两行: 2 * 20px */
  /* max-height: 45px; */
}
.textMore{
  max-height: 100%;
  overflow: scroll;
}
</style>