<template>
	<div class="container-box">
		<div class="header-box">
			<div class="header-left" @click="toPageUrl()">
				<!-- <uni-icons type="left" color="#fff" size="30"></uni-icons> -->
				<van-icon color="#fff" size="15" name="arrow-left" />
			</div>
			<div class="header-pic">
				提交成功
			</div>
			<img class="header-image" src="@/assets/image/study/bgc_delay2.png"/>
		</div>
		<div class="main-box">
			<span>工作人员确认收款后，会在一个工作日内联系您。</span>
		</div>
		<div class="btn-box">
			<span class="all-btn-bigCircle" @click="toPageUrl()">返回</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
        
			}
		},
		methods: {
			toPageUrl(){
        this.$router.push({name:'keepAliveMyOrder',params:{keepAlive:true}})
      },
    },
    created () {
      
    },
    // 生命周期-实例销毁离开前调用
    beforeDestroy () {
      
    },
	}
</script>

<style lang="less" scoped>
	.container-box {
		.header-box {
			position: relative;
			width: 100%;
			height: 262px;
			background: url(~@/assets/image/study/bgc_delay1.png) no-repeat;
			background-size: 100%;
			overflow: hidden;
			
			.header-left{
				position: absolute;
				top: 32px;
				left: 18px;
			}

			.header-pic {
				position: relative;
				margin: 86.5px auto 0;
				width: 96.5px;
				height: 96.5px;
				background: url(~@/assets/image/study/pic_delay.png) no-repeat;
				background-size: 100%;
				font-size: 14px;
				font-family: PingFangMedium;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				line-height: 121.5px;
			}

			.header-image {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 39px;
			}
		}

		.main-box {
			margin: 46px 63px 0;
			font-size: 16px;
			font-family: PingFangMedium;
			font-weight: 500;
			color: #666666;
			line-height: 24px;
      text-align: center;
      a{
        color: #15B7DD;
      }
		}
		
		.btn-box{
			position: fixed;
			bottom: 0;
			display: flex;
			justify-content: space-around;
			width: 100%;
			height: 75px;
			.all-btn-bigCircle{
				width: 323.5px;
			}
		}
	}
</style>
