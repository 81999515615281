// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/icon/20220218041506.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".scroll-view[data-v-15670ea5]{box-sizing:border-box;padding-bottom:0;width:100%;overflow-y:scroll;white-space:nowrap}.scroll-view .scroll-item[data-v-15670ea5]{display:inline-block;text-align:center;padding-bottom:7.5px;margin-right:24px;font-size:13px;font-family:PingFangMedium;font-weight:500;color:#666;line-height:19.5px}.scroll-view .score-hover[data-v-15670ea5]{color:#15b7dd;position:relative}.scroll-view .score-hover[data-v-15670ea5]:after{content:\"\";display:inline-block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100%;width:5.5px;height:6.5px;position:absolute;right:-4px;bottom:7px}.scroll-view[data-v-15670ea5]::-webkit-scrollbar{display:none}.autismContent[data-v-15670ea5]{padding:8px 0}.autismContent .scroll-x[data-v-15670ea5]{display:flex;flex-direction:row;overflow-x:scroll;padding-bottom:8px}.autismContent .scroll-x[data-v-15670ea5]::-webkit-scrollbar{display:none}.autismContent .item[data-v-15670ea5]{flex:none;width:calc(33.33333% - 11.33333px);overflow:hidden;margin-right:17px}.autismContent .item[data-v-15670ea5]:last-child{margin-right:0}.autismContent .item .imgs[data-v-15670ea5]{width:100%;height:134px;overflow:hidden;border-radius:10px}.autismContent .item .imgs img[data-v-15670ea5]{width:100%;height:100%;background-repeat:no-repeat;-o-object-fit:cover;object-fit:cover}.autismContent .item .title[data-v-15670ea5]{margin-top:10px;font-size:14px;line-height:1.4;color:#333;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden}", ""]);
// Exports
module.exports = exports;
