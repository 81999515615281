<template>
  <div>
    <van-nav-bar
      class="nav-circular"
      title="选择班级"
      left-text=""
      right-text=""
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="courseList">
      <div class="courseItem" v-for="(item,index) in packProductList.filter(item=>item.type == 1 && !item.isBuy)" :key="index">
        <div class="typetitle">
          <span></span>
          课程{{changeNumToHan(index+1)}}
        </div>
        <div class="shopMessage">
          <img :src="item.picture" alt="">
          <div class="message">
            <div class="name_price">
              <p class="name">{{item.productName}}</p>
            </div>
            <p class="courseType">{{item.courseCategoryName}} | 视频数量：{{item.classHour}}个</p>
            <p class="price">￥{{item.price.toFixed(2)}}</p>
          </div>
        </div>
        <div class="select" v-if="item.haveClass">
          <p class="title">选择班级</p>
          <p class="value" @click="getClassList(item)">{{item.className?item.className:'请选择班级'}}<van-icon name="arrow" /></p>
        </div>
      </div>
    </div>
    <!-- 底部操作栏 -->
    <div class="btnSave">
      <div class="all-btn-bigCircle" @click="onSave()">确认</div>
    </div>
    <div class="heiSave"></div>
    <!-- 请选择培训场次 -->
    <n-transition ref="trainPop" speed="ease-in-out" :height="612" :maskVal="0.35" v-if="handleItem">
      <div class="popContentBox">
        <div class="popBox">
          <div class="titleBox">
            <span class="title">请选择培训场次</span>
            <van-icon name="cross" size="20" color="#999" @click="close" />
          </div>
          <div class="popContent">
            <div class="popItem" v-for="(item,index) in classList" @click="selected(item)" :key="index" :class="{pick: handleItem.openTimeId == item.openTimeId,disabled:!item.currentNumber}">
              <div class="up">
                <div class="left">
                  <span>{{item.className}}</span>
                </div>
                <div class="right">
                  <span>剩余：<span class="num">{{item.currentNumber?item.currentNumber:0}}</span></span>
                </div>
              </div>
              <div class="center">
                <span>{{item.startTime+' - '+item.endTime}}</span>
              </div>
              <div class="down" v-if="item.provinceTxt">
                <span><van-icon style="margin-right:5px;" :color="handleItem.openTimeId == item.openTimeId?'#ffffff':'#666666'" name="location-o" />{{item.provinceTxt}}{{item.cityTxt}}{{item.districtTxt}}{{item.address}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="popbutton">
          <span class="btn all-btn-bigCircle" @click="changeOpenTime(handleItem)">确 定</span>
        </div>
      </div>
    </n-transition>
  </div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue'
export default {
	// 可用组件的哈希表
	components: {nTransition},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      from:null,
      classList:null, // 班期列表
      fromObj:null, // 套餐详情
      handleItem:null, // 当前操作对象
      packProductList:[
        // {
        //   classHour: 100,
        //   code: "AK0046",
        //   count: 1,
        //   courseCategoryName: "评估课程",
        //   examNum: null,
        //   isExam: null,
        //   packageProductId: 274,
        //   picture: "https://cos.hxclass.cn/test/management/20220523/666bea1152944a0baa36d16fe2387c2a.jpg",
        //   price: 1780,
        //   productId: 283,
        //   productName: "卡罗来纳特殊教育评估培训课程CCITSN",
        //   type: 1
        // }
      ] // 套餐商品列表
    }
  },
  // 事件处理器
  methods: {
    // 提交
    onSave(){
      let status = false;
      // console.log(this.packProductList)
      this.packProductList.map(item=>{
        if(item.haveClass && !item.openTimeId && !item.isBuy){
          status = true;
        }
      })
      if(status){
        return this.$Toast('请选择班期！');
      }
      let productList = [];
			let list = [];
      // console.log(this.packProductList)
			this.packProductList.map(item=>{
				productList.push({
					productId:item.productId, // 商品id
					count:item.count, // 数量")
					openTimeId:item.openTimeId, // 班级id
					type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
					province:'', // 省
					city:'',// 市
					noGift:1, // 是否为赠品 0否1是t
				})
			})
			list.push({
				productId:this.fromObj.packageId, // 商品id
				count:1, // 数量")
				openTimeId:'', // 班级id
				type:9, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:productList
			})
      // console.log(list)
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
      this.$store.commit('updateInvoiceId',null);
      this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateAddress',null);
			this.$store.commit('updateCouponList',null);
			this.$router.push('/order/confirmOrder')
    },

    changeNumToHan(num) {
      var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
      if (!num || isNaN(num)) return '零'
      var english = num.toString().split('')
      var result = ''
      for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i// 倒序排列设值
        result = arr2[i] + result
        var arr1_index = english[des_i]
        result = arr1[arr1_index] + result
      }
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
      result = result.replace(/零+$/, '') // 移除末尾的零
      // 将【一十】换成【十】
      result = result.replace(/^一十/g, '十')
      return result
    },

    // 选择班期
    selected(e){
      if(e.currentNumber && e.status == 1){
        this.$set(this.handleItem,'openTimeId',e.openTimeId);
        this.$set(this.handleItem,'className',e.className);
      }else{
        this.$Toast({message:'该班级已报满，请选择其他班级',className:'toastClass'})
      }
    },
    
    // 班期弹框点击确定
    changeOpenTime(e){
      // console.log(e)
      if(!e.openTimeId){
        return this.$Toast('请选择班期！')
      }
      this.packProductList.map(item=>{
        if(item.productId == e.productId){
          this.$set(item,'openTimeId',e.openTimeId);
          this.$set(item,'className',e.className);
        }
      })
      this.handleItem = null;
      this.close();
    },

    // 获取班级期数列表
    getClassList(e){
      this.handleItem = JSON.parse(JSON.stringify(e));
      this.$ajax({
        url:'/hxclass-mobile/course/course-open-time/by-code',
        method:'get',
        params:{
          productCode:e.code
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.classList = res.data;
          this.popUp();
        }else{
          this.$Toast(res.message)
        }
      })
    },
    // 班期弹框
    popUp () {
			this.$refs['trainPop'].show()
		},
		close(){
			this.$refs['trainPop'].hide()
		},

    // 获取套餐详情
		getPackageDetail(e){
			this.loadingBg = true
			this.$ajax({
				url:"/hxclass-mobile/packages/" +  e + "?from="+this.from,
        method:'get',
			}).then(res=>{
				this.loadingBg = false
				if(res.code == 200 && res.success){
          this.fromObj = res.data;
          this.packProductList = res.data.productList;
				}else{
          this.$Toast(res.message)
        }
			})
		},
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.from = this.$route.query.from;
    if(this.$route.query.id){
      // 获取套餐详情
      this.getPackageDetail(this.$route.query.id);
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style>
	.toastClass{
		z-index: 100000 !important;
	}
</style>

<style lang="less" scoped>
  .courseList{
    padding-bottom: 50px;
    .courseItem{
      padding: 12px 18px 8px;
      .typetitle{
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        display: flex;
        align-items: center;
        span{
          width: 4px;
          height: 15px;
          background: #15B7DD;
          border-radius: 2px;
          margin-right: 4px;
        }
      }
      .select{
        background: #FFFFFF;
        border-radius: 8px;
        margin-top: 8px;
        padding: 16px 12px;
        display: flex;
        justify-content: space-between;
        .title{
          font-size: 16px;
          font-family: PingFangMedium;
          color: #333333;
          line-height: 19px;
        }
        .value{
          font-size: 12px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 19px;
        }
      }
      .shopMessage{
        display: flex;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 16px 12px;
        margin-top: 12px;
        img{
          width: 100px;
          height: 70px;
          border-radius: 7px;
        }
        .message{
          width: calc(100vw - 172px);
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          .name_price{
            display: flex;
            justify-content: space-between;
            height: 22px;
            .name{
              flex: 1;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: bold;
              color: #333333;
              // line-height: 22px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .price{
            font-size: 10px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
          }
          .courseType{
            color: #15B7DD;
            font-size: 11px;
            font-family: PingFangMedium;
            // font-weight: bold;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  // 培训地点选择
  .popContentBox{
    .popBox{
      .titleBox{
        padding: 28px 34px 0 34px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        display: flex;
        span{
          display: inline-block;
        }
        .title{
          flex: 1;
        }
      }
      .popContent{
        height: 503px;
        overflow-y: scroll;
        padding: 0 18px;
        .popItem{
          padding: 16px 12px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          margin-bottom: 8px;
          .up{
            display: flex;
            .left{
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              line-height: 20px;
              flex: 1;
            }
            .right{
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
              .num{
                color: #15B7DD;
              }
            }
          }
          .center{
            padding: 8px 0;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
          }
          .down{
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
          &.pick {
            background: #15B7DD;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
            .up{
              .left,.right{
                color: #FFFFFF
              }
            }
            .center,.down,.num{
              color: #FFFFFF !important;
            }
          }
        }
        .popItem:first-child{
          margin-top: 16px;
        }
        .disabled{
          .up{
            .left{
              span{
                color: #666666;
              }
            }
            .right{
              color: #666666;
              .num{
                color: #666666;
              }
            }
          }
          .center,.down{
            span{
              color: #666666;
            }
          }
        }
      }
    }
    .popbutton{
      // box-shadow: 0px -1px 1px 0px rgba(153,153,153,0.3);
      padding: 5px 0;
      position: fixed;
      width: 100%;
      bottom: 0;
      .btn{
        width: 295px;
        height: 40px;
        display: block;
        text-align: center;
        margin: 0 auto;
        line-height: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
      }
      
    }
  }
  // 底部操作栏
  .heiSave{
    height: 50px;
  }
  .btnSave{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    z-index: 9;
  }
</style>
