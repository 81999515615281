<template>
  <!-- 新知共享 -->
  <div class="container-box">
    <div class="autistic-box">
      <div
        v-for="item in listData"
        :key="item.autismId"
        class="main-list"
        @click="getDetail(item.copyName, item.fileUrl)"
      >
        <div target="_blank">
          <van-image class="list-cover" fit="cover" :src="item.img" />
          <!-- <div class="rectangle"></div> -->
          <div class="list">
            <div class="list-first all-font-ellipsis" v-html="item.name">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 数据对象
  data() {
    return {
      fileUrl: "",
      newCurrent: 1, //当前页数
      newSize: 6, //条数
      newLoading: false,
      finished: false,
    };
  },
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },
  },
  // 事件处理器
  methods: {
    getDetail(name, url) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/login');
      }
      this.$router.push({
        path: "/knowledge/specialAreaCon?name=" + name + "&url=" + url,
      });
    },
    // getDetail(id) {
    // 	this.$ajax({
    // 	  url: "/hxclass-mobile/autism/details" ,
    // 	  params: {
    // 	  	autismId: id,
    // 	  }
    // 	}).then((res) => {
    // 	  if (res.code == 200 && res.success) {
    // 	    this.fileUrl = res.data.fileUrl;
    // 	  }
    // 	});
    // },
    // getList() {
    //   this.$ajax({
    //     url:"/hxclass-mobile/elastic/list",
    // 	params: {
    // 	  keyWord: '海南',//124567：海南 3：模拟
    // 	  type: 7,
    // 	  from: this.newCurrent,
    // 	  size: this.newSize,
    // 	},
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.autisticList = this.autisticList.concat(res.data.records);
    //       this.newCurrent++;
    //       this.newLoading = false;
    //       // 加载完成
    //       if (this.autisticList.length == res.data.total) {
    //         this.finished = true;
    //       }
    //       // 无数据
    //       if (this.autisticList.length == 0) {
    //         this.finished = true;
    //       }
    //       // console.log(this.autisticList);
    //     }
    //   });
    // },
  },
  // 页面生命周期-页面加载
  onLoad() {},
  // 页面生命周期-页面显示
  onShow() {},
  // 页面生命周期-页面初次渲染完成
  onReady() {},
  // 页面生命周期-页面隐藏
  onHide() {},
  // 页面生命周期-页面卸载
  onUnload() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";

// .container-box {
	   // background-color: rgb(247 247 249);
// }
.list {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 57px;
  background: #3189C9;
  border-radius: 0px 0px 20px 20px;
  .list-first {
    padding: 3px 10px;
    font-size: 15px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #ffffff;
    line-height: 23px;
  }
  .list-second {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
  }
}

.rectangle {
  position: absolute;
  bottom: 57.5px;
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.09);
}
</style>
