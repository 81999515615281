<template>
  <div class="homeTabs" v-if="arr && arr.length > 0">
    <div
      class="item"
      :style="'width:' + (item.label.length + 0.3) + 'em;'"
      v-for="(item, index) in arr"
      :key="index"
      @click="onClickTabs(index, $event)"
      :class="selindex == index ? 'click' : ''"
    >
      <div class="back"></div>
      <span style="white-space: nowrap">{{ item.label }}</span>
    </div>
    <div class="column" :style="MarginLeft">
      <span style="white-space: nowrap">{{ columnTxt }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeTabs",
  props: {
    // 默认选中项
    defaultIndex: {
      type: Number,
      default: 0,
    },
    // tab数组
    arr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MLeft: 0,
      columnTxt: "",
      selindex: 0,
    };
  },
  methods: {
    // 点击切换tab
    onClickTabs(e, event) {
      this.selindex = e;
      this.$set(this, "MLeft", event.currentTarget.offsetLeft);
      this.$emit("setValue", e);
      // setTimeout(()=>{
      this.$set(this, "columnTxt", this.arr[e].label);
      // },100)
    },
  },
  computed: {
    // 计算距离
    MarginLeft() {
      if(this.arr && this.arr.length > 0){
        const wid = this.arr[this.defaultIndex].label.length * 1;
        return `width:calc(20px + ${wid}em);margin-left: calc(9px + ${this.MLeft}px)`;
      }
    },
  },
  created() {
    if(this.arr && this.arr.length > 0){
      this.$set(this, "columnTxt", this.arr[this.defaultIndex].label);
    }
  },
  watch:{
    arr(e){
      // if(this.arr && this.arr.length > 0){
      this.$set(this, "columnTxt", e[this.selindex].label);
      // }
    }
  }
};
</script>

<style lang="less" scoped>
.homeTabs {
  line-height: 22.5px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-top: 3.5px;
  border-radius: 11px 0 11px 0;
  // border-radius: 2.5px 9px 2.5px 9px;
  // border-bottom-right-radius: 2.5px;
  // border-bottom-left-radius: 9px;
  // border-top-left-radius: 2.5px;
  // border-top-right-radius: 9px;
  position: relative;
  .item {
    min-width: 60px;
    background: linear-gradient(
      to top,
      rgba(@theme, 0.64),
      @theme,
      rgba(@theme, 0.64)
    );
    color: rgba(#fff, 0.9);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: PingFangMedium;
    align-items: center;
    position: relative;
    padding: 0 20px;
  }
  .item:nth-child(1) {
    font-weight: 400;
    border-top-left-radius: 9px;
  }
  .item:nth-child(2) {
    font-weight: 400;
    border-bottom-right-radius: 9px;
  }
}
.column {
  width: 80px;
  height: 100%;
  position: absolute;
  font-family: PingFangMedium;
  left: 0;
  top: 0;
  background-color: @btnColor3;
  // transition: margin-left 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  &::after,
  &::before {
    content: "";
    width: 22px;
    display: block;
    background-image: url("../assets/image/202201261157.png");
    position: absolute;
    top: 0;
    right: -21px;
    background-size: 100%;
    z-index: 9;
    height: 100%;
  }
  &::before {
    left: -21px;
    transform: rotateY(180deg);
  }
}
.click {
  span {
    font-weight: 500;
		font-size: 16px;
  }
}
</style>
