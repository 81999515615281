<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="确认信息"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <!-- 步骤 -->
    <div class="step-box">
      <div class="step-item step-hover" >
        <span class="num">1</span>
        <span class="font">证书信息确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex != 1}">
        <span class="num">2</span>
        <span class="font">照片提交方式确认</span>
        <span class="arrow"></span>
      </div>
      <div class="step-item" :class="{'step-hover' : stepIndex == 3}">
        <span class="num">3</span>
        <span class="font">证书邮寄地址确认</span>
      </div>
    </div>
    <!-- 主功能区 -->
    
    <!-- 第二步 -->
    <div class="content-box" v-show="stepIndex==2">
      <div class="all-bg second-box">
        <div class="all-title">{{verifyType == 1 ? '证书照片确认' : '照片提交方式：'}}</div>
        <div class="tips" v-if="verifyType == 1">
          <span>您收到的证书上的照片为以下照片，请确认，确认后将开始制作证书，不再支持修改。</span>
        </div>
        <div class="tips" v-else>
          <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
          <span>提示：提交证书照片为您提供了两种方式：1.邮寄纸质照片（无需缴纳费用）；2.使用已上传的电子照片（需支付代为冲印费20元）。</span>
        </div>
        <!-- 报名照片选择方式 -->
        <div class="child-item" v-if="verifyType == 0">
          <div class="item-R-select">
            <div class="sex" @click="ImgIndex=2">
              <div class="select-icon"><span v-show="ImgIndex==2"></span></div>使用已上传的电子照片
            </div>
            <div class="sex" @click="ImgIndex=1">
              <div class="select-icon"><span v-show="ImgIndex==1"></span></div>邮寄纸质照片
            </div>
          </div>
        </div>
        <!-- 邮寄照片的要求 -->
        <div class="ask-inner" v-show="ImgIndex==1">
          <div class="h4">请按以下要求邮寄照片：</div>
          <div class="p">1.一张1寸蓝底或白底纸质照片</div>
          <div class="p">2.照片后面用圆珠笔写您的姓名和手机号。</div>
          <div class="p">3.附一张白纸写您的姓名、手机号、身份证号、考试时间及场次。</div>
          <div class="p">4.邮寄地址如下：</div>
          <div class="p area">{{addressObj.pcaName}}</div>
          <div class="p address">{{addressObj.proviceName}}{{addressObj.cityName}}{{addressObj.areaName}}{{addressObj.address}}</div>
          <div class="p phone">{{addressObj.name}} {{addressObj.mobile}}</div>
        </div>
      </div>
      <div class="second-box" v-show="ImgIndex==1">
        <div class="tips second-tips">
          <img class="tips-icon" src="@/assets/image/icon/20220215041501.png" />
          <span>提示：邮寄后请填写以下信息</span>
        </div>
      </div>
      <div class="all-bg second-box" v-show="ImgIndex==1">
        <div class="all-title">填写物流信息：</div>
        <div class="child-item">
          <span class="item-L-name">快递公司</span>
            <van-field 
              class="input" 
              :border='false' 
              v-model="postCompany"
              placeholder="请填写快递公司" />
        </div>
        <div class="child-item" style="border-bottom: none;padding: 25rpx 0 0;">
          <span class="item-L-name">物流单号</span>
            <van-field 
              class="input" 
              :border='false' 
              v-model="postNum"
              placeholder="请填写物流单号" />
        </div>
      </div>
      
      <!-- 上传电子照片 -->
      <div class="upload-box" v-show="ImgIndex==2">
        <div class="all-title">我的电子照片：</div>
        <div class="upload-tips">点击图片可重新上传一寸蓝底或白底证件照</div>
        <div class="upload-click">
          <van-uploader :after-read="uploadCertifiMobile">
            <div class="bg-img" v-if="certifiMobile==''">
              <img class="icon" src="@/assets/image/icon/20220215041507.png" />
            </div>
            <div class="bg-info" v-else>
              <img class="icon" :src="certifiMobile" />
            </div>
          </van-uploader>
        </div>
        <!-- <div class="pay-btn">
          <span class="all-btn-bigCircle">支付</span>
        </div> -->
      </div>

      <div 
        class="btn-foo" 
        :style="{top: (clientHeight - buttonHeight) + 'px'}"
        id="btnFoo">
        <span class="btn up" @click="onLastTo">上一步</span>
        <span class="btn next" @click="onNextTo" v-show="ImgIndex==1">下一步</span>
        <span class="btn next" @click="goPay" v-show="ImgIndex==2">{{isPay?'下一步':'支付'}}</span>
      </div>
    </div>
    <!-- 选项弹窗 -->
    <!-- <van-popup v-model="educationShow" position="bottom" >
      <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="addressShow = false"
      />
    </van-popup> -->
  </div>
</template>

<script>
  import { dataURLtoFile, getClientHeight } from '@/unit/fun.js'
  import { onStartOrder, onRemoveStorage } from '@/assets/js/NativeWebKit.js'
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        examineeId: '', //考生id
        signupId: '', //考试id
        signupUserId: '', //用户报名id
        refundAddressId: '', //退货地址id
        addressObj: {}, //退货地址
        certifiMobile: '', //电子照片

        stepIndex: 2,// 控制步骤样式
        ImgIndex: 2, //控制上传照片方式 1纸质 2电子照片
        isPay: false, //是否已支付

        postCompany: '', //快递公司
        postNum: '', //快递单号

        clientHeight: 0, // 视口高度
        buttonHeight: 0, //按钮高度

        verifyType: 0,  // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式
      }
		},
    created() {
      // this.examineeId = this.$route.query.examineeId //考生id
      this.signupId = this.$route.query.signupId //考试id
      this.signupUserId = this.$route.query.signupUserId //用户报名id
      // this.addressId = this.$route.query.addressId //退货地址id
      // this.certifiMobile = this.$route.query.certifiMobile //电子照片
      this.verifyType = this.$route.query.verifyType ? this.$route.query.verifyType : 0 

      // 判断是否为电子照支付成功后返回
      if (sessionStorage.getItem('paySignupObj')) {
        let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
        // id相同、支付成功
        if ((paySignupObj.id == this.signupId && !!paySignupObj.pay) || this.$route.query.pay) {
          // 删除支付成功标识
          sessionStorage.removeItem('paySignupObj');
          // onRemoveStorage('paySignupObj')
          // 下一页
          this.$router.push({
            path:'/examEnroll/signUpConfirm3',
            query:{
              signupId: this.signupId, //考试id
            }
          });
          return;
        } else {
          // 删除电子照支付标识
          sessionStorage.removeItem('paySignupObj');
          // onRemoveStorage('paySignupObj')
        }
      }

      // 获取存储信息
      this.getConfirm()
      if(this.verifyType == 1){  // 证书提交方式为1时不需要查询是否支付
        this.isPay = true
        this.ImgIndex = 2
      } else {
        // 是否支付
        this.getIsPay()
      }
      // 退货地址
      this.getAddress()
      // 物流信息
      // this.getPost()

      // 安卓选择照片后回调
      window.returnPhotoUrl = (url)=>{
        var file = dataURLtoFile(url, 'fileName.jpg')
        // 上传图片
        this.uploadCertifiMobile(file, 1)
      }
      
      let _this = this;
      // 原生刷新页面支付状态
      window.refreshH5 = function () {
        _this.getIsPay()
        return ;
      };
    },
    mounted(){
      // 获取页面视图高度
      this.clientHeight = getClientHeight()
      // 获取按钮高度
      this.buttonHeight = document.getElementById("btnFoo").clientHeight
      // console.log(this.clientHeight, this.buttonHeight)
    },
		// 事件处理器
		methods: {
      // 获取存储信息
      getConfirm() {
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = JSON.parse(localStorage.getItem(confirmObjName))
        
        this.examineeId = confirmObj.examineeId || '' //考生id
        this.refundAddressId = confirmObj.refundAddressId || '' //退货地址id
        if(this.verifyType == 1){  // 1.电子证书
          this.ImgIndex == 2
        } else {
          this.ImgIndex = confirmObj.type || 2 //提交方式
        }
        this.certifiMobile = confirmObj.certifiMobile || '' //电子照片
        this.postCompany = confirmObj.postCompany || '' //快递公司
        this.postNum = confirmObj.postNum || '' //快递单号
      },
      // 修改存储信息
      setConfirm() {
        // 获取
        let confirmObjName = 'confirmObj' + this.signupId
        let confirmObj = JSON.parse(localStorage.getItem(confirmObjName))

        confirmObj.type = this.ImgIndex //提交方式
        confirmObj.certifiMobile = this.certifiMobile //电子照片
        confirmObj.postCompany = this.postCompany //快递公司
        confirmObj.postNum = this.postNum //快递单号

        // 存储
        localStorage.setItem(confirmObjName, JSON.stringify(confirmObj))
      },
      // 收货地址
      getAddress() {
        this.$ajax({
          url: '/hxclass-mobile/admin-address/detail',
          method: 'get',
          params: {
            addressId: this.refundAddressId
          }
        }).then((res) => {
          // console.log(res);
          if (res.code == 200 && res.success) {
            this.addressObj = res.data
          }
        });
      },
      // 获取快递公司
      // getPost() {
      //   let post = 'postObj'+this.signupId
      //   let postObj = localStorage.getItem(post)
      //   // console.log(postObj)
      //   if (postObj) {
      //     postObj = JSON.parse(postObj)
      //     this.postCompany = postObj.postCompany // 快递公司
      //     this.postNum = postObj.postNum // 物流单号
      //     // console.log(this.postObj)
      //   }
      // },
      // 是否已支付电子照片费用
      getIsPay() {
        this.$ajax({
          url: '/hxclass-mobile/exam/examinee-pay',
          method: 'get',
          params: {
            examineeId: this.examineeId
          }
        }).then((res) => {
          // console.log(res);
          if (res.code !== 500) {
            this.isPay = res
            // 已付款
            if (!!res) {
              this.ImgIndex = 2
            }
          }
        });
      },
      // 上传电子照片
      uploadCertifiMobile(e, type) {
        // console.log(e)
        // type 1安卓上传
        let file = type == 1 ? e : e.file
        this.$upload({
          file: file,
          type: 'image',
          progress: (e)=>{
            this.loading = true
          },
          success: (e)=>{
            this.loading = false
            this.certifiMobile = e
          }
        })
      },
      // 存储快递公司、快递单号
      setPost() {
        let post = 'postObj'+this.signupId
        let postObj = {
          signupId: this.signupId,
          postCompany: this.postCompany,
          postNum: this.postNum,
        }
        localStorage.setItem(post, JSON.stringify(postObj)) //存储
      },
      // 上一步
      onLastTo() {
        // 存储信息
        this.setConfirm()
        this.$onReturn(this.$route.query.fromMenu == '1')
      },
      // 下一步
      onNextTo(){
        // 判空
        if (this.postCompany == '') {
          this.$Toast('请填写快递公司')
          return
        } else if (this.postNum == '') {
          this.$Toast('请填写快递单号')
          return
        } 
        // else if (!this.$regular.postNum.reg.test(this.postNum)) {
        //   this.$Toast("快递单号格式不正确");
        //   return
        // }

        // 存储信息
        this.setConfirm()

        // 第三步
        this.$router.push({
          path:'/examEnroll/signUpConfirm3',
          query:{
            signupId: this.signupId, //考试id
          }
        });
      },
      // 支付
      goPay() {
        // 存储信息
        this.setConfirm()
        // 已支付
        if (this.isPay) {
          this.$router.push({
            path:'/examEnroll/signUpConfirm3',
            query:{
              signupId: this.signupId, //考试id
            }
          });
        } 
        // 未支付
        else {
          // payReturnsign 1跳回考试报名页 2跳到首页
          localStorage.setItem('payReturnsign', 1)
          // isOrgOrder  是否机构报名-照片冲印订单
          sessionStorage.setItem('isOrgOrder', false)
          // 电子照的考试id
          sessionStorage.setItem('paySignupObj', JSON.stringify({
            id: this.signupId,
            signupUserId: this.signupUserId,
            examineeId: this.examineeId,
            pay: false,
            picture: this.certifiMobile, //电子照
            invoice: false, //是否跳了发票页
          }))
          // console.log(localStorage.getItem('payReturnsign'))
          let productList = [
            {
              count: 1, //数量
              noGift: 0, //是否为赠品 0否1是
              productId: this.examineeId, //商品id-考生id
              type: 5, //商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            }
          ]
          this.$store.commit('updatelist', productList) //订单列表
          this.$store.commit('updateType', 2) // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
          this.$store.commit('updateShortInvoiceId',null);
          this.$store.commit('updateInvoiceId', null) //发票id
          this.$store.commit('updateAddress', null) //收货地址id
          this.$store.commit('updateCouponList',null);
          this.$router.push('/order/confirmOrder')
        }
      },
    },
	}
</script>

<style lang="less" scoped>
  @import "./signUpConfirm.less";
  .container-wrap {
    // height: calc(100vh - 100px);
    padding-bottom: 100px;
    background-color: #F7F7F9;
  }
</style>
