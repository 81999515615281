export default{
  // 公共的状态
  state: {
    isLogin: false, // 当前是否登录
    token: '', //用户token
    certified: false, // 用户是否认证 已认证：true  未认证：false
    messageNum: '0', //消息数量
    couponTotal: '0', //优惠券数量
    collectionTotal: '0', //收藏数量
  },
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {
    // 更新数据
    updateUserInfo(state, props){
      if(typeof(props) == 'object'){
        for(let key in props){
          state[key] = props[key]
        }
        localStorage.setItem('userInfo', JSON.stringify(state))
      }else{
        console.error('updateUserInfo()类型错误，期望为object，实际为'+typeof(props))
      }
    },

    // 清除数据，包括
    clearUserInfo(state){
      // state = new Object()
      // state.account = null
      // state.age =  null
      // state.address = null
      // state.birthday = null
      // state.isLogin = null
      // state.password = null
      // state.phone = null
      // state.photo = null
      // state.sex = null
      // state.tolink = null
      // state.userName = null
      // state.userType = null

      for(let key in state){
        delete state[key]
      }

      state.isLogin = false
      localStorage.removeItem('userInfo')
    },

    // 更新认证状态
    changecertified(state, params) {
      state.certified = params;
      localStorage.setItem('userInfo', JSON.stringify(state))
    },

    // 更新消息数量
    changeMessageNum(state, params) {
      state.messageNum = params;
      localStorage.setItem('userInfo', JSON.stringify(state))
    },

    // 更新优惠券数量
    changeCouponTotal(state, params) {
      state.couponTotal = params;
      localStorage.setItem('userInfo', JSON.stringify(state))
    },

    // 更新收藏数量
    changeCollectionTotal(state, params) {
      state.collectionTotal = params;
      localStorage.setItem('userInfo', JSON.stringify(state))
    },

    // 更新token
    changeToken(state, params) {
      state.token = params;
      localStorage.setItem('userInfo', JSON.stringify(state))
    },

  },
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {
    // 异步更新数据
    actionsUpdateUserInfo (context, props) {
      context.commit('updateUserInfo', props)
    }
  },
  // 嵌套模块
  modules: {}
}