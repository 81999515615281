<template>
  <div class="container-wrap">
    <!-- 头部打卡天数 -->
    <div class="header-box">
      <div>
        <div class="back" @click="$onReturn($route.query.fromMenu == '1')">
          <van-icon name="arrow-left" />
          <!-- <img src="@/assets/image/selected/back.png"/> -->
        </div>
        <div class="title">打卡记录</div>
      </div>

      <div class="auto">
        <div>已经连续打卡</div>
        <div>
          <span class="num">{{ sumCount }}</span
          >天
        </div>
      </div>
    </div>
    <!-- 打卡日历 -->
    <div class="all-bg date-box">
      <img class="bg-img" src="@/assets/image/icon/20220216041504.png" />
      <model-calendar
        :sendYear="toYear"
        :sendMonth="toMonth"
        :dataSource="signData"
        :loading="loading"
        :totalNum="sumCount"
        @dateChange="getClockList"
        @clickChange="clickSign"
      >
      </model-calendar>
      <!-- <div class='count'>
      	<span>截至目前，已坚持打卡</span>
      	<div class='daynumber'>
      		<span class='number'>{{sumCount}}</span>
      		<span class='day'>天</span>
      	</div>
      
      	<div>本月累积打卡<span class="monthSum">{{signData.length}}</span>天</div>
      	<span>请再接再厉，继续努力!</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import modelCalendar from "@/components/Calendar/index.vue";

export default {
  data() {
    return {
      toYear: parseInt(new Date().getFullYear()), //本日
      toMonth: parseInt(new Date().getMonth() + 1), //本月
      sumCount: 0,
      signData: [],
      formParms: {
        startTime: "",
        endTime: "",
      },
      loading: false,
    };
  },
  components: {
    modelCalendar,
  },
  created() {
    //获取当前用户当前任务的签到状态
    this.getClockList(this.toYear + "-" + this.toMonth);

    this.getDays();
  },
  methods: {
    clickSign(day) {
      //   this.signData.push(day);
      //   this.sumCount++;
    },

    //获取当前用户该任务的签到数组
    getClockList(val) {
      let arry = val.split("-");
      let daysNum = new Date(arry[0], arry[1], 0).getDate();
      this.formParms.startTime =
        arry[0] + "-" + (arry[1] < 10 ? "0" + arry[1] : arry[1]) + "-" + "01";
      this.formParms.endTime =
        arry[0] +
        "-" +
        (arry[1] < 10 ? "0" + arry[1] : arry[1]) +
        "-" +
        daysNum;
      this.loading = true;
      this.$ajax({
        url: "/hxclass-mobile/clock/days",
        params: this.formParms,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.signData = res.data;
        }
      });
    },
    // 获取连续打卡天数
    getDays() {
      this.$ajax({
        url: "/hxclass-mobile/clock/punch/days",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.sumCount = res.data.punchDay;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header-box {
  background: url(../../assets/image/icon/20220216041503.png);
  background-repeat: repeat-x;
  background-size: 100%;
  height: 219px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangMedium;
  font-weight: bold;
  color: #ffffff;
  line-height: 13.5px;
  // display: flex;
  // align-items: center;
  position: relative;
  padding-top: 60px;
  .back {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 20px;
    top: 68px;
    z-index: 9999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .auto {
    margin-top: 28px;
    line-height: 30px;

    .num {
      margin-right: 5px;
      font-size: 25px;
    }
  }
}
.all-bg {
  padding-top: 1px;
  margin: 0 18px;
  padding-bottom: 5px;
  border-radius: 10px;
  background: #ffffff;
  // padding: 14px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  top: -82px;
}
.date-box {
  .bg-img {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
  }
  .count .daynumber {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .count .daynumber .day {
    margin-top: 25px;
  }

  .count {
    margin: 10px;
    padding: 15px;
    display: flex;
    text-align: center;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    align-items: center;
  }

  .count .number {
    color: #fff;
    font-size: 30px;
    background-color: #94db98;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
  }
  .monthSum {
    color: red;
    font-size: 20px;
  }
  .count text {
    margin: 5px;
  }
}
.title {
  position: relative;
  top: 10px;
  font-size: 18px;
}
</style>
