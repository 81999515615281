<template>
  <div class="container-box" id="container-box">
    <van-nav-bar
      class="nav-circular"
      title="提交材料"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="form">
      <!-- 活动名称 -->
      <div class="identityItem">
        <span class="title">活动名称：特殊教育大学生职业赋能提升计划</span>
      </div>
      <!-- 就读院校 -->
      <div class="identityItem">
        <span class="title">就读院校：</span>
        <van-field
          v-model="entityName"
          maxlength="30"
          class="inputtext"
          placeholder="请输入就读院校"
          input-align="right"
        />
      </div>
      <div class="identityItem">
        <span class="title">学历：</span>
        <div class="picker" @click="degreeShowPicker = true">
          <van-field
            readonly
            v-model="degree"
            class="inputtext"
            placeholder="请选择学历"
            input-align="right"
          />
          <van-icon name="arrow" color="#CCCCCC" size="12" />
        </div> 
        <van-popup v-model="degreeShowPicker" round position="bottom">
          <van-picker
            show-toolbar
            value-key="name"
            :columns="degreeArray"
            @cancel="degreeShowPicker = false"
            @confirm="onDegreeConfirm"
          />
        </van-popup>
      </div>
      <!-- 专业 -->
      <div class="identityItem">
        <span class="title">专业：</span>
        <van-field
          v-model="major"
          maxlength="15"
          class="inputtext"
          placeholder="请输入专业"
          input-align="right"
        />
      </div>
      <!-- 年级 -->
      <div class="identityItem">
        <span class="title">年级：</span>
        <div class="picker" @click="gradeShowPicker = true">
          <van-field
            readonly
            v-model="grade"
            class="inputtext"
            placeholder="请选择年级"
            input-align="right"
          />
          <van-icon name="arrow" color="#CCCCCC" size="12" />
        </div> 
        <van-popup v-model="gradeShowPicker" round position="bottom">
          <van-picker
            show-toolbar
            value-key="name"
            :columns="gradeArray"
            @cancel="gradeShowPicker = false"
            @confirm="onGradeConfirm"
          />
        </van-popup>
      </div>
      <!-- 预计毕业时间 -->
      <div class="identityItem">
        <span class="title">预计毕业时间：</span>
        <div class="picker" @click="timeShowPicker = true">
          <van-field
            readonly
            v-model="expirationDate"
            class="inputtext"
            placeholder="请选择毕业时间"
            input-align="right"
          />
          <van-icon name="arrow" color="#CCCCCC" size="12" />
        </div> 
        <van-popup v-model="timeShowPicker" round position="bottom">
          <van-datetime-picker
            type="date"
            v-model="currentDate"
            :show-toolbar="true"
            @cancel="timeShowPicker = false"
            @confirm="onTimeConfirm"
          />
        </van-popup>
      </div>
      <!-- 学信网学籍截图 -->
      <div class="identityItem">
        <span class="title">学信网学籍截图：</span>
      </div>
      <div class="upload-box">
        <!-- 安卓、浏览器 -->
        <van-uploader v-if="pageType==1 || pageType==3" :before-read="beforeRead" :after-read="uploadImg">
          <img class="camera-icon" v-if="evidence==''" src="@/assets/image/icon/202202150415072.png" />
          <p class="camera-txt" v-if="evidence==''">上传截图</p>
          <img class="list_upload_img" :src="evidence" v-else />
        </van-uploader>
        <div  v-if="pageType==2" @click="onPhoto">
          <img class="camera-icon" v-if="evidence==''" src="@/assets/image/icon/202202150415072.png" />
          <p class="camera-txt" v-if="evidence==''">上传截图</p>
          <img class="list_upload_img" :src="evidence" v-else />
        </div>
      </div>
      <p class="tips" @click="toPage()">
        <img src="@/assets/image/examination/icon-exam.png" />
        如何获取学籍验证截图？
      </p>
    </div>
    <span class="all-btn-bigCircle" @click="commitBuyAudit">立即申请</span>
  </div>
</template>

<script>
import { getFileFromUrl, FunJudgDevice, dataURLtoFile } from "@/unit/fun.js";
const DeviceType = FunJudgDevice()
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      currentDate: new Date(), // 初始化为当前日期时间
      loading:false,
      entityName:'', // 就读院校
      degree:'', // 学历
      major:'', // 专业
      grade:'', // 年级
      expirationDate:'', // 毕业时间
      evidence:'', // 学历凭证
      pageType: 0, //1安卓 2ios 3浏览器
      degreeArray:[  // 学历列表
        {name:'博士后'},
        {name:'博士研究生'},
        {name:'硕士研究生'},
        {name:'本科'},
        {name:'专科'},
      ],
      gradeArray:[  // 年级列表
        {name:'五年级'},
        {name:'四年级'},
        {name:'三年级'},
        {name:'二年级'},
        {name:'一年级'},
      ],
      degreeShowPicker:false,
      gradeShowPicker:false,
      timeShowPicker:false,
    }
  },
  // 事件处理器
  methods: {
    commitBuyAudit(){
      if(!this.entityName){
        this.$Toast("请输入就读院校");
        return
      }
      if(!this.degree){
        this.$Toast("请选择学历");
        return
      }
      if(!this.major){
        this.$Toast("请输入专业");
        return
      }
      if(!this.grade){
        this.$Toast("请输入年级");
        return
      }
      if(!this.expirationDate){
        this.$Toast("请输入毕业时间");
        return
      }
      if(!this.evidence){
        this.$Toast("请上传学历凭证");
        return
      }

      if(this.loading) return
      this.loading = true
      this.$ajax({
        url: "/hxclass-mobile/qualification/apply",
        method: "put",
        params: {
          id: this.$route.query.id ? this.$route.query.id : undefined,
          operationName: '大学生暑期职业赋能活动',
          applyType:1,
          entityName: this.entityName,
          degree: this.degree,
          major: this.major,
          grade: this.grade,
          expirationDate: this.expirationDate + ' 23:59:59',
          evidence: this.evidence,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast("提交成功");
          this.$router.go(-1)
        } else {
          this.$Toast(res.message);
        }
        this.loading = false
      });
    },
    toPage(){
      this.$router.push('/curriculum/educationStep')
    },
    // 判断已提交资质
    getDetail() {
      this.$ajax({
        url: "/hxclass-mobile/qualification/detail",
        method: "get",
        params: {
          applyType: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.entityName = res.data.entityName
          this.degree = res.data.degree
          this.major = res.data.major
          this.grade = res.data.grade
          this.evidence = res.data.evidence
          this.expirationDate = (res.data.expirationDate).substring(0,10)
        }
      });
    },
    onDegreeConfirm(e){
      this.degree = e.name
      this.degreeShowPicker = false
    },
    onGradeConfirm(e){
      this.grade = e.name
      this.gradeShowPicker = false
    },
    onTimeConfirm(time){
      this.expirationDate = this.formatter(time)
      console.log(this.formatter(time));
      this.timeShowPicker = false
    },
    formatter(time) {
      let nowdata =time == null ?  new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    // 上传图片
    uploadImg(e, type) {
      // type 1安卓上传
      let file = type == 1 ? e : e.file
      this.$upload({
        file: file,
        type: 'image',
        progress: (e)=>{
        },
        success: (e)=>{
          this.evidence = e;
        }
      })
    },
    // 打开相册
    onPhoto() {
      // 1裁剪图片
      window.webkit.messageHandlers.photo.postMessage(' ')
      // 1裁剪 0不裁剪
      // 1方的不能随意拖动 0可以随意拖动
      // onChoosePhoto(' ')
    },
    beforeRead(file){
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$Toast("请上传正确格式图片文件");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$Toast("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 安卓
    if (DeviceType == 'android') {
      this.pageType = 1
    } else if (DeviceType == 'ios' && window.webkit) {     // ios
      this.pageType = 2
    } else {     // 浏览器
      this.pageType = 3
    }

    // ios回调文件
    window.returnFileUrl = (url, name,type)=>{
      var file = null
      let _this = this
      getFileFromUrl(url, name)
      .then((response)=>{
        // 上传文件
        _this.uploadImg(response, 1)
      })
      .catch((e)=>{
        console.error(e)
      });
    }

    // 安卓选择照片后回调
    window.returnPhotoUrl = (url) => {
      var file = dataURLtoFile(url, 'fileName.jpg');
      // 上传图片
      this.uploadImg(file, 1);
    };
  },
  // 生命周期-实例挂载后调用
  mounted () {
    if(this.$route.query.id){
      this.getDetail()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.form{
  margin: 16px 16px 90px 16px;
  padding: 4px 15px 15px;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
}
.identityItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  &:nth-child(7) {
    border-bottom: 0;
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 21px;
  }
  .picker{
    display: flex;
    align-items: center;
  }
  .inputtext {
    flex: 1;
    margin-left: 20px;
    padding: 0;
  }
}
.upload-box{
  background: #F9F9FB;
  text-align: center;
  min-height: 110px;
  .camera-icon{
    width: 50px;
    margin-top: 25px;
  }
  .camera-txt{
    font-weight: 400;
    font-size: 12px;
    color: #BEBECE;
    line-height: 18px;
  }
  .list_upload_img{
    width: 100%;
  }
}
.tips{
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #15B7DD;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-decoration: underline;
  img{
    width: 14px;
    margin-right: 2px;
  }
}

.all-btn-bigCircle{
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  width: 85%;
  bottom: 30px;
}
</style>
