
<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="图书教具"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <!-- 切换框 -->
      <!-- <div class="tab">
        <div class="tab_item" :class="{'pick': tabIndex==index}" v-for="(item, index) in tabList"  :key="index" @click="tabIndex=index">{{item.name}}</div>
      </div> -->
      <SwichTab @setTab="changeTab" :tabIndex="tabIndex" :menuArry="tabList" />
      <div class="scroll-view">
        <div class="scroll-item" :class="{'score-hover' : index == classIndex}" @click="onClass(item,index)" v-for="(item,index) in classList" :key="item.id+'-'">{{item.name}}</div>
      </div>
      <van-list class="list-box" v-model="newLoading" :finished="finished" :immediate-check="false" @load="getList">
        <div class="item-box" v-for="i in dataList" :key="i.productId" @click="goDetail(i.productId)">
          <img class="cover-img" :src="i.listPhoto" />
          <div class="bottom-box">
            <div class="title all-font-ellipsis">{{i.name}}</div>
            <div class="source">{{i.abstracts}}</div>
            <div class="price">￥<span class="present">{{i.couponPrice?i.couponPrice:'0.00'}}</span><span v-if="i.original == 1" class="grey">￥{{i.originalPrice?i.originalPrice:'0.00'}}</span></div>
          </div>
        </div>
      </van-list>
    </div>
    <noMessage v-if="dataList.length==0" type=1></noMessage>
  </div>
</template>

<script>
import SwichTab from "@/components/SwichTab.vue";
import noMessage from "@/components/nomessage.vue";
export default {
  // 可用组件的哈希表
  components: { noMessage,SwichTab },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      newCurrent: 1, //当前页数
      newSize: 10, //条数
      newLoading: false,
      finished: false,

      code: '',
      tabIndex: 0,
      classIndex: 0,
      tabList: [],
      classList: [],
      dataList: [],
    }
  },
  // 事件处理器
  methods: {
    changeTab(e){
      this.tabIndex = e
    },
    goDetail(id) {
      this.$router.push('/curriculum/BookDdetails?id=' + id)
    },
    getClass() {
      this.$ajax({
        url: "/hxclass-mobile/app-mall/app/book-category"
      }).then((res) => {
        if (res.code == 200 && res.success) {
           this.tabList = res.data;
           this.classList = this.tabList[this.tabIndex].children;
           this.onClass(this.tabList && this.tabList.length?this.tabList[this.tabIndex]:'',0)
        }
      });
    },
    onClass(x,i){
      this.finished = false;
      this.newLoading = true;
      this.dataList = [];
      this.classIndex = i;
      this.code = x?x.code:(this.tabList[this.tabIndex].children?this.tabList[this.tabIndex].children[0].code:this.code);
      this.newCurrent = 1;
			this.getList();
    },
    getList(){
      this.$ajax({
        url: "/hxclass-mobile/app-mall/app/book-product",
        params: {
          code: this.code,
          page: this.newCurrent,
          size: this.newSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.dataList = this.dataList.concat(res.data.records);
          this.newCurrent ++
          this.newLoading = false
          // 加载完成
          if (this.dataList.length == res.data.total) {
            this.finished = true
          }
          // 无数据
          if (this.dataList.length == 0) {
            this.finished = true
          }
        }
      });
    },
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #f7f7f9;')
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getClass()
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () {
    document.querySelector('body').removeAttribute('style')
   },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    tabIndex(newval) {
      this.code = this.tabList[newval].code
      this.classList = this.tabList[newval].children;
      this.getClass();
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.van-list__finished-text{
  margin: auto;
}
/deep/.nomessage{
  margin-top: 100px;
}
.content-box {
  padding: 18px;
  // 切换框
	.tab {
		display: flex;
		align-items: center;
    line-height: 27px;
		padding:  0 0 11px;
    height: 27px;
	}
  .condition {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .item {
      flex: none;
      padding: 0 12px;
      font-size: 12px;
      margin-right: 18px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #999999;
      line-height: 18px;
      border-radius: 5px;
      border: 1px solid #999999;
    }
    .itemActive {
      border: 1px solid #15b7dd;
      color: #15b7dd;
    }
  }
   .scroll-view{
      box-sizing: border-box;
      padding-bottom: 5px;
      width: 100%;
      overflow-y: scroll;
      white-space: nowrap;
      .scroll-item{
        display: inline-block;
        text-align: center;
        padding-bottom: 7.5px;
        margin-right: 24px;
        font-size: 13px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 19.5px;
      }
      .score-hover{
        color: #15B7DD;
        font-weight: 500;
        position: relative;
      }
      .score-hover::after{
        content: '';
        display: inline-block;
        background: url(../../assets/image/icon/20220218041506.png) no-repeat;
        background-size:100%;
        width: 5.5px;
        height: 6.5px;
        position: absolute;
        right: -4px;
        bottom: 7px;
      }
    }
  .list-box {
    display: flex;
    flex-wrap: wrap;
    .item-box {
      width: calc((100% - 14px) / 2);
      border-radius: 10px;
      background: #ffffff;
      margin-right: 14px;
      margin-bottom: 14px;
      overflow: hidden;
      .cover-img {
        width: 100%;
        height: 162.5px;
      }
      .bottom-box {
        padding: 10px 10px 10px;
        .title {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          -webkit-line-clamp: 1;
        }
        .source {
          margin-top: 5px;
          font-size: 10px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 15px;
          display: -webkit-box;
          overflow: hidden;
          min-height: 30px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .price {
          margin-top: 5px;
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #EC6C01;
          line-height: 13px;
          // text {
          //   font-size: 14px;
          //   font-family: PingFangMedium;
          // }
          .present{
            font-size: 14px;
            font-weight: bold;
          }
          .grey{
            padding-left: 8px;
            color: #999999;
            font-weight: 400;
            text-decoration: line-through;
            font-family: PingFangMedium;
          }
        }
      }
    }
    .item-box:nth-child(2n) {
      margin-right: 0;
    }
  }
}
/deep/ .van-list__finished-text{
  display: block;
  width: 100%;
}
</style>
