<template>
  <div class="content-box">
    <van-nav-bar
      class="nav-circular nav-shadow" 
      v-if="type==1"
      title="偏好设置"
      :fixed='true'
      :placeholder='true'
      :border="false"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <!-- 跳过 -->
    <div class="skip_box" v-if="type==2">
      <div class="box_con">
        <div class="con_txt">从兴趣出发，体验更多好课</div>
        <div class="con_info">
          <div class="info_con">个性<span class="info_light">设置</span>、专业<span class="info_light">分类</span>、精准<span class="info_light">推荐</span></div>
          <div class="info_long"></div>
          <div class="info_short"></div>
        </div>
      </div>
      <div class="box_button" @click="goSkip()">跳过</div>
    </div>

    <!-- 内容 -->
    <div class="box1" v-if="showTagsList">
      <div class="box_title">请选择您感兴趣的内容</div>
      <div class="box_con">
        <div 
          id="conItem"
          class="con_item" 
          :class="{'shake': tagsPickIndex == index}"
          :style="{height: conItemHeight+'px'}"
          v-for="(item, index) in tagsList" 
          :key="index"
          @click="pickItem(index)">
          <img class="item_bg" :src="item.bgImg" alt="背景图">
          <img class="item_img" v-if="item.img" :src="item.img" alt="偏好">
        </div>
      </div>
    </div>

    <!-- 标签 -->
    <div class="box2">
      <div class="box_title" v-if="tagsPickList.length!==0">当前已选中偏好：</div>
      <div class="box_con">
        <div class="box_wrap" v-for="(item, index) in tagsPickList" 
            :key="index">
          <div 
            class="box_item" 
            >
            <span class="item_txt">{{item.likeLabelName}}</span>
            <van-icon 
              class="item_icon" 
              name="close" 
              color="#14BFE4" 
              size="14px"
              @click="cacleItem(index)" />
          </div>
        </div>
        
      </div>
    </div>

    <div class="onSave-foo">
      <!-- <span class="all-btn-bigCircle" :class="{'gray':tagsPickList.length==0}" @click="onSave()">下一步</span> -->
      <span class="all-btn-bigCircle" @click="onSave()">{{type==1?'确认':'下一步'}}</span>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        type: 1, //1正常进入 2注册进入
        showTagsList: false, //隐藏偏好
        tagsListAll: [],  //所有标签
        conItemHeight: null, //图片高度

        // 备用初始化标签
        tagsListCopy: [{
            order: 1,
            bgImg: require('@/assets/image/interest/bg_icon1.png'),
          },
          {
            order: 2,
            bgImg: require('@/assets/image/interest/bg_icon2.png'),
          },
          {
            order: 3,
            bgImg: require('@/assets/image/interest/bg_icon3.png'),
          },
          {
            order: 4,
            bgImg: require('@/assets/image/interest/bg_icon4.png'),
          },
          {
            order: 5,
            bgImg: require('@/assets/image/interest/bg_icon5.png'),
          },
          {
            order: 6,
            bgImg: require('@/assets/image/interest/bg_icon6.png'),
          },
          {
            order: 7,
            bgImg: require('@/assets/image/interest/bg_icon7.png'),
          },
          {
            order: 8,
            bgImg: require('@/assets/image/interest/bg_icon8.png'),
          },
          {
            order: 9,
            bgImg: require('@/assets/image/interest/bg_icon9.png'),
        },],
        // 列表展示标签
        tagsList: [],
        emptyOrderList: [], //空着的序号列表

        //选中的标签
        tagsPickList: [], 
        tagsPickIndex: -1,
        isDisabled: false,

      }
    },
    created() {
      // 注册进入
      this.type = this.$route.query.type || 1
      
      this.getData()
    },
		// 事件处理器
		methods: {
      // 跳过
      goSkip() {
        // 进入身份认证
        this.$router.push('/mine/identityAuthentication?type=2');
      },
      // 偏好列表
      getData() {
        this.$ajax({
          url: '/hxclass-mobile/like/app',
          method: "get"
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.tagsListAll = res.data
            this.tagsList = JSON.parse(JSON.stringify(this.tagsListCopy))
            // 初始化
            this.setRandomList()
          }
        })
      },
      // 初始化 - 随机展示标签
      setRandomList() {
        let orderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9] //序号
        let tagsList = [] //偏好列表
        let randomNum = 0 //随机数

        // 拿出已关注偏好展示
        let tagsListAll = []
        this.tagsListAll.map((item, index) => {
          // 已关注
          if (item.status == 1) {
            this.tagsPickList.push(item)
          } 
          // 未关注
          else {
            tagsListAll.push(item)
          }
        })
        this.tagsListAll = tagsListAll
        
        // 选中偏好添加随机order
        this.tagsPickList.map((item, index) => {
          // 生成随机数
          randomNum = parseInt((8 * Math.random())+1)
          item.order = randomNum
        })

        // 随机取九个
        this.tagsListAll.sort((a,b)=>Math.random()-Math.random()); //打乱全部列表
        if (this.tagsListAll.length >= 9) {
          tagsList = this.tagsListAll.splice(0, 9)
        } else {
          // orderArr = orderArr.splice(0, this.tagsListAll.length)
          tagsList = this.tagsListAll.splice(0, this.tagsListAll.length)
        }
        // 打乱序号顺序
        orderArr.sort((a,b)=>Math.random()-Math.random());
        // 添加序号
        tagsList.map((item, index) => {
          item.order = orderArr[index]
        })
        // 按照order排序
        tagsList = this.sortDataList(tagsList, 'order')
        
        // 合并到展示列表
        this.tagsList.map((item, index) => {
          if (tagsList.length !== 0) {
            if (item.order == tagsList[0].order) {
              this.$set(this.tagsList[index], 'order', tagsList[0].order) //序号
              this.$set(this.tagsList[index], 'likeId', tagsList[0].likeId) //id
              this.$set(this.tagsList[index], 'img', tagsList[0].img) //图片
              this.$set(this.tagsList[index], 'likeLabelName', tagsList[0].likeLabelName) //标签名称
              tagsList.splice(0, 1)
            } else {
              // 空序号
              this.emptyOrderList.push(index+1)
            }
          } else {
            // 空序号
            this.emptyOrderList.push(index+1)
          }
        })
        // 打开偏好
        this.showTagsList = true

        // 处理图片宽高
        this.setImgHeight()
      },
      // 处理图片宽高
      setImgHeight() {
        this.$nextTick(() => {
          this.conItemHeight = document.getElementById('conItem').clientWidth / 100 * 89
        })
      },
      // 选中标签
      pickItem(index) {
        // 无偏好
        if (this.tagsList[index].img == '' || !this.tagsList[index].img) {
          this.$Toast('此处无偏好')
          return
        }
        if (this.isDisabled) {
          return
        }
        // 动画
        this.tagsPickIndex = index
        this.isDisabled = true //禁用
        let timer = setTimeout(() => {
          this.tagsPickIndex = -1
          this.isDisabled = false
          clearTimeout(timer)
        }, 300);

        // 添加选中标签
        let tagItem = JSON.parse(JSON.stringify(this.tagsList[index]))
        this.tagsPickList.push(tagItem)
        // 替换选中标签
        if (this.tagsListAll.length !== 0) {
          let tagsList = this.tagsListAll.splice(0, 1)
          // this.$set(this.tagsList[index], 'order', tagsList[index].order) //序号
          this.$set(this.tagsList[index], 'img', tagsList[0].img) //图片
          this.$set(this.tagsList[index], 'likeId', tagsList[0].likeId) //id
          this.$set(this.tagsList[index], 'likeLabelName', tagsList[0].likeLabelName) //标签名称
        }
        // 删除选中标签
        else {
          this.$set(this.tagsList[index], 'img', '') //图片
          this.$set(this.tagsList[index], 'likeLabelName', '') //标签名称
          this.emptyOrderList.push(this.tagsList[index].order)
        }
      },
      // 取消标签
      cacleItem(index) {
        // 标签
        let tagsPickItem = this.tagsPickList[index]
        
        // 移出选中列表
        this.tagsPickList.splice(index, 1)
        
        // 无空位置 - 放入原位置
        if (this.emptyOrderList.length == 0) {
          this.tagsList.map((itemI, indexI) => {
            if (itemI.order == tagsPickItem.order) {
              this.tagsListAll.unshift(itemI) //放入备用偏好
              this.$set(this.tagsList[indexI], 'likeId', tagsPickItem.likeId) //id
              this.$set(this.tagsList[indexI], 'img', tagsPickItem.img) //图片
              this.$set(this.tagsList[indexI], 'likeLabelName', tagsPickItem.likeLabelName) //标签名称
            }
          })
        }
        // 有空位置
        else {
          // 生成随机数
          let randomNum = parseInt((this.emptyOrderList.length-1) * Math.random())
          let showIndex = this.emptyOrderList[randomNum] - 1
          // 放入展示列表
          this.$set(this.tagsList[showIndex], 'likeId', tagsPickItem.likeId) //id
          this.$set(this.tagsList[showIndex], 'img', tagsPickItem.img) //图片
          this.$set(this.tagsList[showIndex], 'likeLabelName', tagsPickItem.likeLabelName) //标签名称
          // 从空序号移出
          this.emptyOrderList.splice(randomNum, 1)
        }
      },
      // 保存
      onSave() {
        if (this.tagsPickList.length == 0) {
          this.$Toast('请选中偏好后保存')
          return
        }
        // 选中id
        let likeId = []
        this.tagsPickList.map((item, index) => {
          likeId.push(item.likeId)
        })
        likeId = likeId.toString()
        
        this.$ajax({
          url: '/hxclass-mobile/like/app/set?likeId=' + likeId,
          method: "put",
        }).then((res) => {
          // console.log(res)
          if (res.code == 200 && res.success) {
            this.tagsPickList = [] //选中偏好清空
            this.emptyOrderList = [] //空白位置序号清空
            this.$Toast.success('修改成功！');
            // 正常进入
            if (this.type == 1) {
              this.$onReturn(this.$route.query.fromMenu == '1')
            } 
            // 注册进入
            else if (this.type == 2) {
              // 进入身份认证
              this.$router.push('/mine/identityAuthentication?type=2');
            }
            // 偏好列表
            // this.getData()
          }
        })
      },
      // 排序
      sortDataList(arr, order) {
        for( var i = 0; i < arr.length-1; i++ ){
          for( var j = 0; j < arr.length-i-1; j++ ) {
            if ( arr[j][order] > arr[j+1][order] ) {
              var temp = arr[j]
              arr[j] = arr[j+1]
              arr[j+1] = temp
            }
          }
        }
        return arr
      }
    },
		// 页面生命周期-页面加载
		mounted(){ }
	}
</script>

<style lang="less" scoped>
.content-box{
  min-height: 100vh;
  background-color: #fff;
  // padding-bottom: 80px;
}
  // 跳过
  .skip_box {
    margin-top: 10px;
    position: relative;
    padding-top: 95px;
    // padding-bottom: 30px;
    .box_con {
      text-align: center;
      .con_txt {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15B7DD;
        line-height: 30px;
      }
      .con_info {
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #C4C4C4;
        line-height: 23px;
        margin-top: 3px;
        .info_con {
          position: relative;
          z-index: 3;
          .info_light {
            color: #79DEFF;
          }
        }
        .info_long {
          position: absolute;
          left: -6px;
          bottom: -3px;
          width: 102%;
          height: 18px;
          border-left: 1px solid rgba(121, 222, 255, 0.5);
          border-bottom: 1px solid rgba(121, 222, 255, 0.5);
          background-color: #ffffff;
          z-index: 2;
        }
        .info_short {
          position: absolute;
          left: -10px;
          bottom: -6px;
          width: 7px;
          height: 7px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          border: 1px solid #79DEFF;
          z-index: 1;
        }
      }
    }
    .box_button {
      position: absolute;
      top: 55px;
      right: 18px;
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #999999;
      border: 1px solid #D2D2D2;
      border-radius: 19px;
      padding: 6px 10px;
    }
  }

  // 内容
  .box1 {
    margin-top: 50px;
    padding: 0 50px;
    .box_title {
      font-size: 16px;
      font-family: Inter-Regular, Inter;
      font-weight: 400;
      color: #333333;
    }
    .box_con {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 13px;
      border-radius: 20px;
      overflow: hidden;
      .con_item {
        position: relative;
        display: inherit;
        width: calc(33.33% - 2px);
        margin-top: 4px;
        &:nth-child(-n+3) {
          margin-top: 0;
        }
        .item_bg {
          width: 100%;
          height: auto;
        }
        .item_img {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // 标签
  .box2 {
    margin-top: 36px;
    padding: 0 45px;
    .box_title {
      color: #666;
      margin-bottom: 10px;
      font-size: 15px;
    }
    .box_con {
      display: flex;
      flex-wrap: wrap;
      .box_wrap {
        box-sizing: border-box;
        width: 33%;
        padding: 0 5px;
      }
      .box_item {
        box-sizing: border-box;
        position: relative;
        line-height: 25px;
        text-align: center;
        border: 1px solid #14BFE4;
        border-radius: 15px;
        // padding: 0px 10px;
        padding: 0px 2px;
        margin-top: 18px;
        .item_txt {
          color: #0AB0D8;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .item_icon {
          position: absolute;
          left: -7px;
          top: -11px;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }
  }

  // 摇晃标签
  .shake {
    animation: shake-bottom 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  }
  @keyframes shake-bottom {
    0%,
    100% {
      transform: rotate(0deg);
      transform-origin: 50% 100%;
    }
    20%,
    60% {
      transform: rotate(-4deg);
    }
    30%
    {
      transform: rotate(4deg);
    }
    80% {
      transform: rotate(-2deg);
    }
    90% {
      transform: rotate(2deg);
    }
  }

  // 下一步
  .onSave-foo{
    // position: fixed;
    box-sizing: border-box;
    bottom: 35px;
    width: 100%;
    text-align: center;
    margin-top: 45px;
    padding: 0 40px;
    .gray {
      background: #E5E5E5;
    }
    .all-btn-bigCircle {
      width: 100%;
    }
  }
</style>
