<template>
  <div class="container-wrap">
    <van-nav-bar
      class="nav-circular"
      title="认证查询"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="content-box">
      <!-- 查询入口 -->
      <div class="entrance-list">
        <div
          class="entrance-item"
          @click="onGoPage(item.url)"
          v-for="(item,index) in arryList" :key="index"
        >
          <div class="side-left">
            <span class="p title">{{item.title}}</span>
            <span class="p txt">{{item.txt}}</span>
          </div>
          <img
            class="bg-img" :src="item.bgImg" />
        </div>
      </div>
    </div>
    <!-- 机构选择 -->
    <van-overlay :show="maskShow">
      <div class="wrapper">
        <div class="popup-content">
          <span class="title">选择机构类型</span>
          <div class="meshan-list">
            <div
              class="meshan-item"
              @click="goMeshan(item.show, item.type)"
              v-for="(item, index) in organizationList"
              :key="index"
            >
            <van-image
              class="cover-img"
              fit="cover"
              :src="item.bgImg"
            />
              <div class="side-right" :class="{'disabled': !item.show}">
                <div class="name">
                  <span class="all-font-ellipsis">{{item.title}}</span>
                  <van-icon name="arrow" />
                </div>
                <div class="info">{{item.txt}}</div>
              </div>
            </div>
          </div>
          <van-icon class="close-icon" @click="maskShow = false" color="#ffffff" size="30" name="close" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      maskShow: false,
      arryList:[
        {title:'成绩查询',txt:'查询华夏云课堂成绩',url:'/search/scoreQuery',bgImg:require('@/assets/image/icon/20220225041503.png')},
        {title:'证书查询',txt:'根据课程类别查询所有认证课程证书',url:'/search/cardQuery',bgImg:require('@/assets/image/icon/20220225041504.png')},
        {title:'中康协证书查询',txt:'查询中国残疾人康复协会线下培训班证书',url:'/search/zhongkangQuery',bgImg:require('@/assets/image/icon/20220225041505.png')},
        {title:'机构查询',txt:'查询全国助残儿童康复救助定点服务机构、经办机构',url:'/mechanism',bgImg:require('@/assets/image/icon/20220225041501.png')},
        {title:'教师查询',txt:'根据个人信息查询在华夏云课堂的所有证书信息',url:'/search/teacherQuery',bgImg:require('@/assets/image/icon/20220225041502.png')},
      ],
      organizationList: [
        {title: '康复救助经办机构', txt: '查询2021年全国残疾儿童康复救助经办机构信息', bgImg: require('@/assets/image/icon/2022040601.png'), show: true, type: 2,},
        {title: '康复救助定点服务机构', txt: '查询2021年全国残疾儿童康复救助定点服务机构信息', bgImg: require('@/assets/image/icon/2022040602.png'), show: true, type: 1,},
        // {title: '平台机构', txt: '查询2021年全国残疾儿童康复救助定点服务机构信息', bgImg: require('@/assets/image/icon/2022040603.png'), show: false},
      ],
    }
  },
  // 事件处理器
  methods: {
    // 跳对应查询
    onGoPage(oUrl){
      if( oUrl== '/mechanism'){ /* 机构查询时 */
        this.maskShow = true
      } else if( oUrl== '/search/cardQuery'){ /* 证书查询时 */
        this.$router.push({
          name: 'cardQuery',
          params: {
            isLoad: true
          }
        });
      } else {
        this.$router.push(oUrl);
      }
    },
    // 跳机构查询
    goMeshan(show, type){
      // console.log(show, type)
      if (show) {
        this.$router.push('/search/meshanQuery?type=' + type);
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>


<style lang="less" scoped>
  @import "./select.less";
  .entrance-list{
    .entrance-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 19px;
      padding: 0 30px;
      background: #FFFFFF;
      box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
      border-radius: 5px;
      .side-left{
        width: 60%;
        position: relative;
        .p{
          display: block;
          font-family: PingFangMedium;
        }
        .title{
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
        }
        .txt{
          font-size: 12px;
          // font-weight: 500;
          color: #666666;
          line-height: 18px;
        }
      }
      .bg-img{
        width: 83px;
      }
    }
    .entrance-item .side-left::before{
      content: '';
      position: absolute;
      left: -11px;
      top: 7.5px;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background: #006EFD;
    }
    .entrance-item:nth-child(2){
      .side-left::before{
        background: #FFB700;
      }
    }
    .entrance-item:nth-child(3){
      .side-left::before{
        background: #FF74DF;
      }
    }
    .entrance-item:nth-child(4){
      .side-left::before{
        background: #15B7DD;
      }
    }
    .entrance-item:nth-child(5){
      .side-left::before{
        background: #45455F;
      }
    }
  }
  /* 遮罩内容 */
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .popup-content {
  	background: #FFFFFF;
  	width: 270px;
  	border-radius: 9px;
  	padding: 21px 18px 18px;
  	position: relative;
    line-height: 21.5px;
    text-align: center;
    font-family: PingFangMedium;
    .title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
    }
    .meshan-list{
       .meshan-item{
         padding: 14px;
         margin-top: 14px;
         background: #F7F7F9;
         display: flex;
         .cover-img{
           width: 57px;
           height: 57px;
         }
         .side-right{
           flex: 1;
           margin-left: 14px;
           text-align: left;
           font-family: PingFangMedium;
           .name{
             display: flex;
             justify-content: space-between;
             align-items: center;
             font-size: 14px;
             font-weight: 500;
             color: #333333;
             .all-font-ellipsis{
              //  max-width: 80%;
             }
           }
           .info{
             margin-top: 5px;
             font-size: 10px;
             font-weight: 400;
             color: #666666;
             line-height: 15px;
           }
           &.disabled {
             .all-font-ellipsis{
               color: #999999;
             }
             .info{
               color: #999999;
              }
           }
         }
       } 
    }
    .close-icon{
      position: absolute;
      bottom: -50px;
      left: 50%;
      width: 245px;
      margin-left: -122.5px;
    }
  }
</style>
