<template>
  <div class="tree-wrap">
    <h3 class="title">课程列表</h3>
    <div class="tree-list">
      <!-- 一级 -->
      <div class="first-item" v-for="item in arr" :key="item.sectionId + 'first'">
        <div class="line" :class="{'activeBg' : sectionId == item.sectionId}" @click="onClick(item)">
          <p class="treeName">{{item.sectionName}}</p>
          <!-- 购买 -->
          <template v-if="have==1">
            <img class="img" v-if="item.fileId && item.lock" :src="lockImg" alt="">
            <img class="img" v-if="item.fileId && sectionId == item.sectionId" :src="playIcon" alt="">
          </template>
          <!-- <img class="img" v-if="item.fileId && item.isFreeTrial && sectionId == item.sectionId && item.lock" :src="lockImg" alt="">
          <img class="img" v-else-if="item.fileId && item.lock == 1" :src="lockImg" alt="">
          <img class="img" v-else-if="sectionId == item.sectionId" :src="playIcon" alt=""> -->
        </div>
        <!-- 二级 -->
        <div class="scond-item" v-for="item in item.childList" :key="item.sectionId + 'scond'">
          <div class="line" :class="{'activeBg' : sectionId == item.sectionId}" @click="onClick(item)">
            <p class="treeName">{{item.sectionName}}</p>
            <!-- 购买 -->
            <template v-if="have==1">
              <img class="img" v-if="item.fileId && item.lock" :src="lockImg" alt="">
              <img class="img" v-if="item.fileId && sectionId == item.sectionId" :src="playIcon" alt="">
            </template>
          </div>
          <!-- 三级 -->
          <div class="third-item" v-for="item in item.childList" :key="item.sectionId+ 'third'">
            <div class="line" :class="{'activeBg' : sectionId == item.sectionId}" @click="onClick(item)">
              <p class="treeName">{{item.sectionName}}</p>
              <!-- 购买 -->
              <template v-if="have==1">
                <img class="img" v-if="item.fileId && item.lock" :src="lockImg" alt="">
                <img class="img" v-if="item.fileId && sectionId == item.sectionId" :src="playIcon" alt="">
              </template>
            </div>
            <!-- 四级 -->
            <div class="third-item" v-for="item in item.childList" :key="item.sectionId+ 'four'">
              <div class="line" :class="{'activeBg' : sectionId == item.sectionId}" @click="onClick(item)">
                <p class="treeName">{{item.sectionName}}</p>
                 <template v-if="have==1">
                  <img class="img" v-if="item.fileId && item.lock" :src="lockImg" alt="">
                  <img class="img" v-if="item.fileId && sectionId == item.sectionId" :src="playIcon" alt="">
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import playIcon from '@/assets/image/audio/icon1.png'
import lockImg from '@/assets/image/audio/icon2.png'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    arr: {
			type: Array,
			default: ()=>[]
    },
    // 正在播放视频id
		sectionId: {
			type: Number,
			default: 0
    },
    have: {
			type: Number,
			default: 0
    }
  },
  // 数据对象
  data () {
    return {
      playIcon,
      lockImg,
    }
  },
  // 事件处理器
  methods: {
    onClick(e){
      if(!e.fileId) return
      let json = JSON.stringify(e)
      json = JSON.parse(json)
			this.$emit('nodeClick', json)
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    window.addEventListener('message', function(e){
      // console.log(e.data.data);
    })
  }
  ,
  beforeDestroy() {
    clearInterval(this.timer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>
<style lang="less" scoped>
.tree-wrap {
  color: #FFFFFF;
  .title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .tree-list{
    padding-right: 10px;
    .first-item{
      .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .treeName{
          width: 80%;
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .img{
          width: 12px;
          height: 12px;
        }
      } 
      .activeBg{
        .treeName{
          color: #15B7DD !important;
        }
      }
      .scond-item{
        padding-left: 12px;
        .line .treeName{
          font-size: 15px;
        }
      }
      .third-item{
        padding-left: 12px;
        .line .treeName{
          color: rgba(255, 255, 255, 0.9);
          font-size: 14px;
        }
      }
    }
  }
}
</style>
