<template>
  <div class="content-box">
    <van-nav-bar
      title="账号安全"
      :fixed='true'
      :placeholder='true'
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="single-menu-list">
      <div class="single-menu-item">
        <div class="side-left">
          <span class="p side-left-title">手机号</span>
        </div>
        <div class="side-right">
          <span class="phone">{{$store.state.userInfo.mobile}}</span>
          <router-link to="/setUp/modifyPhone" class="replace-btn">更换</router-link>
        </div>
      </div>
      <div class="single-menu-item" @click="goSet()">
        <div class="side-left">
          <span class="p side-left-title">登录密码</span>
        </div>
        <div class="side-right">
          <span class="modify">{{type==1?'修改':'设置'}}</span>
          <van-icon name="arrow" color="#cccccc" size="16" />
        </div>
      </div>
      <div class="single-menu-item" @click="onCancellation()">
        <div class="side-left">
          <span class="p side-left-title">注销账户</span>
        </div>
        <div class="side-right">
          <van-icon name="arrow" color="#cccccc" size="16" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        type: 0, //1修改密码 2设置密码
      }
		},
    created() {
      let password = this.$store.state.userInfo.password
      // console.log(password)
      if (password) {
        // 修改密码
        this.type = 1
      } else {
        // 设置密码
        this.type = 2
      }
      // console.log(this.type)
    },
		// 事件处理器
		methods: {
      // 设置密码/修改密码
      goSet() {
        this.$router.push({
          path: '/setUp/modifyPassword',
          query: {
            type: this.type
          }
        })
      },
      // 注销账户
      onCancellation(){
        this.$router.push({
          path: '/setUp/verification'
        })
      }
    },
	}
</script>


<style lang="less" scoped>
  @import "./style.less";
  .side-right{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: PingFangMedium;
    .phone{
      color: #999999;
    }
    .replace-btn{
      display: inline-block;
      text-align: center;
      margin-left: 5px;
      color: #FFFFFF;
      width: 30px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      font-weight: 400;
      background: linear-gradient(337deg, rgba(255, 194, 67, 0.65) 0%, #FFC243 52%, rgba(255, 194, 67, 0.66) 100%);
      border-radius: 7.5px;
      border: 1px solid #EFAF2C;
    }
    .modify{
      color: #333333;
    }
  }
</style>
