<template>
  <div class="confirmOrder">
    <van-nav-bar
      class="nav-circular"
      title="确认订单"
      left-text
      right-text
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />
    <div class="address" v-if="addressFlag && !addressObj && visible" @click="$router.push('/address/adjust?type=2')">
      <span>+</span>添加收货地址
    </div>

    <!-- 地址 -->
    <div class="addressMess" v-if="addressFlag && addressObj">
      <div class="userMess">
        <div class>
          <img src="@/assets/image/address/icon1.png" />
          <span class="userName">{{addressObj.consignee}}</span>
          <span class="phone">{{addressObj.phone.substr(0,3)}}****{{addressObj.phone.substr(7,11)}}</span>
        </div>
        <router-link :to="'/address/adjust?confirmaddressId='+addressId+'&type=2'" class="changeAddress">修改地址</router-link>
        <!-- <span class="changeAddress">修改地址</span> -->
      </div>
      <span class="addressText">{{addressObj.proviceName+addressObj.cityName+(addressObj.areaName?addressObj.areaName:'')+addressObj.address}}</span>
    </div>

    <!-- 订单信息 -->
    <div class="orderMessage" v-if="list && list.length">
      <!-- 商品 -->
      <div class="shopItem" v-for="(item,index) in list" :key="index">
        <img class="shopImg" :src="item.listPhoto" v-if="item.listPhoto" />
        <div class="shopMessage haveNav">
          <div class="shoptielt-price">
            <span class="shopTitle havNav">{{item.name}}</span>
          </div>
          <div class="stage_box">
            <div class="stage">
              <div :class="item.openTimeId?'openTime':'stage_info'" v-if="item.type == 1">{{item.openTimeId?item.className+'：'+item.startTime.split('-').join('/')+'-'+item.endTime.split('-').join('/'):item.courseCategory+' | '+item.courseHour}}</div>
              <div class="stage_info" v-if="item.type == 1 && item.address">{{item.provinceTxt}}{{item.cityTxt}}{{item.districtTxt?item.districtTxt:''}}{{item.address}}</div>
              <div class="stage_info" v-if="item.type == 4">{{item.answerNumber}}</div>
              <div class="stage_info" v-if="item.type == 5">代为冲印费</div>
              <div class="stage_info" v-if="item.type == 6">
                <p>课程延期天数：{{item.days}}天</p>
                <p v-if="item.examNumber">赠送考试次数：{{item.examNumber}}次</p>
              </div>
              <div class="stage_info" v-if="item.type == 7">
                <span>补考次数：{{item.makeNum}}次</span>
                <span style="margin-left:15px;" v-if="item.delaysNum!==0">课程延期天数：{{item.delaysNum}}天</span>
              </div>
              <p class="shopprice">¥ <span>{{item.couponPrice.toFixed(2)}}</span></p>
              <!--延期 缺少字段 <div class="stage_info" v-if="item.type == 6">{{item.makeNum}}次补考次数;<br/>课程延期天数{{item.delaysNum}}</div> -->
            </div>
            <div class="num" v-if="item.type == 3 || item.type == 2">x{{item.count}}</div>
          </div>
          <!-- <div class="priceNum">
						<span class="price">¥12321.00</span>
						<span class="num">x1</span>
          </div>-->
        </div>
        <div class="packedList" v-if="item.type == 9 && packedList && packedList.length">
          <p class="haveStyle">包含</p>
          <!-- 商品 -->
          <div class="shopItem" style="flex-wrap:nowrap !important;" v-for="(ite,ind) in packedList" :key="ind">
            <img class="shopImg" :src="ite.listPhoto" v-if="ite.listPhoto" />
            <div class="shopMessage haveNav" style="width:calc(100% - 124px)">
              <div class="shoptielt-price">
                <span class="shopTitle havNav">{{ite.name}}</span>
              </div>
              <div class="stage_box">
                <div class="stage">
                  <div :class="ite.openTimeId?'openTime':'stage_info'" v-if="ite.type == 1">{{ite.openTimeId?ite.className + '：' + ite.startTime+'-'+ite.endTime:ite.courseCategory+' | '+ite.courseHour}}</div>
                  <div class="stage_info" v-if="ite.type == 1 && ite.address">{{ite.address}}</div>
                  <div class="stage_info" v-if="ite.type == 4">{{ite.answerNumber}}</div>
                  <div class="stage_info" v-if="ite.type == 5">代为冲印费</div>
                  <div class="stage_info" v-if="ite.type == 6">
                    <p>课程延期天数：{{ite.days}}天</p>
                    <p v-if="ite.examNumber">赠送考试次数：{{ite.examNumber}}次</p>
                  </div>
                  <div class="stage_info" v-if="ite.type == 7">
                    <span>补考次数：{{ite.makeNum}}次</span>
                    <span style="margin-left:15px;">课程延期天数{{ite.delaysNum}}</span>
                  </div>
                  <p class="shopprice" v-if="!ite.noPay">¥ <span>{{ite.couponPrice.toFixed(2)}}</span></p>
                  <p class="isPay" v-else>已购买</p>
                  <!--延期 缺少字段 <div class="stage_info" v-if="ite.type == 6">{{ite.makeNum}}次补考次数;<br/>课程延期天数{{ite.delaysNum}}</div> -->
                </div>
                <div class="num" v-if="ite.type == 3 || ite.type == 2">x{{ite.count}}</div>
              </div>
              <!-- <div style="height:20px;">
                
              </div> -->
              <!-- <div class="priceNum">
                <span class="price">¥12321.00</span>
                <span class="num">x1</span>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!-- 运费 -->
      <!-- <div class="freight">
        <span>运费:</span>
        <span class="price">¥64.00</span>
      </div> -->
    </div>

    <div class="orderMessage" v-if="giftList && giftList.length">
      <!-- <div class="favourableItem">
        <span class="title">商品金额：</span>
        <span class="price">¥123.00</span>
      </div> -->
      <!-- 附赠商品 -->
      <div class="donated">
        <span class="moduleName">赠品：</span>
        <div class="donatedList">
          <div class="donatedItem" v-for="(item,index) in giftList" :key="index">
            <span class="donatedTitle">{{item.name}}</span>
            <span class="num">
              x{{item.count}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="favourable" v-if="orderMessage">
      <div class="favourableItem">
        <span class="title">商品金额：</span>
        <span class="price">¥ <span>{{price.toFixed(2)}}</span></span>
      </div>
      <div class="favourableItem" v-if="addressFlag && orderMessage.noFreight != 2">
        <span class="title">运费：</span>
        <span class="price"><template v-if="orderMessage.noFreight == 3">¥ </template><span>{{orderMessage.noFreight == 3?orderMessage.freightPrice.toFixed(2):'包邮'}}</span></span>
      </div>
      <div class="favourableItem" v-if="type == 1" @click="popUp1">
        <span class="title">优惠券：<span v-if="false && bestOffer == currentOffer && isUsecouponList && isUsecouponList.length">已选最优优惠券</span></span>
        <span class="currentOffer" v-if="currentOffer">-¥{{currentOffer.toFixed(2)}}<van-icon name="arrow" color="#666666"/></span>
        <span class="value" v-else>
          <span>{{couponList && couponList.length?(couponList.length+'张可用'):'暂无可用优惠券'}}</span>
          <van-icon name="arrow" />
        </span>
      </div>
    </div>
    <div v-if="!$route.query.source">
      <div class="invoicecoupon">
        <span class="title">开具发票</span>
        <span class="value" @click="goInvoice">
          <span>{{invoiceId?'电子发票'+(invioceType == 1?'-企业':invioceType == 3?'-个人':''):'不开发票'}}</span>
          <van-icon name="arrow" />
        </span>
      </div>
    </div>
    
    <!-- 提示文案 -->
    <div class="warnmessage" style="padding-bottom:94px;">
      <p class="p" v-if="kfxhVisible" @click="kfxhAgree=!kfxhAgree">
        <van-icon v-if="kfxhAgree" class="icon" name="passed" color="#2CBEE0" size="15" />
        <van-icon v-else class="icon" name="circle" color="#858585" size="15" />
        同意成为康复协会会员，查看<a @click="$router.push('/all/membershipInterests')" style="color:#999999">《会员权益》</a>
      </p>
      <span class="span">课程、服务类商品为虚拟内容服务，购买后不支持出售、退订、转让、退换，请斟酌确认。</span>
      <span  class="span">图书、教具等实物商品满足相应条件（未发货、未拆封且不影响二次销售）时可申请退款，但产生的退货运费由您承担。</span>
      <span  class="span">购买后可在 ⌈我的—我的订单⌋ 中查看。</span>
    </div>

    <div class="footerView" v-if="orderMessage">
      <div class="footerleft">
        <span class="price">
          <span>¥</span> {{((price*100-orderMessage.couponPrice*100+orderMessage.freightPrice*100)/100).toFixed(2)}}
        </span>
        <!-- <span class="price onlyOne">21321.<span>00</span></span> -->
        <div class="include" v-if="!!orderMessage.couponPrice">
          <span class="discount">优惠减：¥{{orderMessage.couponPrice.toFixed(2)}}</span>
          <span class="nav" @click="popUp">优惠明细</span>
        </div>
      </div>
      <!-- <button type="default" class="buttonsubmit">提交</button> -->
      <van-button plain type="primary" class="buttonsubmit" :loading="submitLoading" @click="submitOrder">提交订单</van-button>
    </div>

    <!-- 优惠明细 -->
    <n-transition ref="pop" speed="ease-in-out" :height="289" :maskVal="0.35" v-if="orderMessage">
      <div class="popContentBox">
        <div class="popBox">
          <div class="titleBox">
            <span class="title">优惠明细</span>
            <van-icon name="cross" size="20" color="#999" @click="close" />
          </div>
          <div class="popContent">
            <div class="option">
              <span class="title">商品总价：</span>
              <span class="value">¥{{price.toFixed(2)}}</span>
            </div>
            <div class="option" v-if="addressFlag">
              <span class="title">运费：</span>
              <span class="value">{{orderMessage.noFreight == 3?'¥'+orderMessage.freightPrice.toFixed(2):'包邮'}}</span>
            </div>
            <div class="option">
              <span class="title">优惠金额：</span>
              <span class="discount">- ¥{{orderMessage.couponPrice.toFixed(2)}}</span>
            </div>
            <div class="option">
              <span class="fruit">实付款：</span>
              <span class="fruitprice">¥{{((price*100-orderMessage.couponPrice*100+orderMessage.freightPrice*100)/100).toFixed(2)}}</span>
            </div>
          </div>
        </div>
      </div>
    </n-transition>

    <!-- 优惠券弹窗 -->
    <n-transition ref="trainPop1" speed="ease-in-out" :height="515" :maskVal="0.35">
      <div class="windowCoupons">
        <div class="navBar">
          <div class="center">使用优惠券</div>
          <div class="right" @click="close1">
            <van-icon name="cross" color="#666" size="20" />
          </div>
        </div>
        <div class="couponTab" v-if="couponList">
          <div class="tabItem" :class="{tabActive:couponTabIndex == 1}" @click="changeCouponIndex(1)">可用优惠券（{{couponList.length}}）</div>
          <div class="tabItem" :class="{tabActive:couponTabIndex == 2}" @click="changeCouponIndex(2)">不可用优惠券（{{uncouponList.length}}）</div>
        </div>
        <p class="couponMessage" v-if="modelcouponList && couponTabIndex == 1 && couponList.length">您已选中优惠券{{modelcouponList.length}}张，共可抵用<span>¥{{modelcurrentOffer.toFixed(2)}}</span></p>
        <div class="coupon" :style="{height:couponTabIndex == 2?'323px':'280px'}">
          <no-message style="margin-top:50px;" v-if="(couponTabIndex == 1 && !couponList.length) || (couponTabIndex == 2 && !uncouponList.length)" type="7"/>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
          >
            <div class="couponItem" :class="{overdue:couponTabIndex == 2}" v-for="(item,index) in couponTabIndex == 1?couponList:uncouponList" :key="index">
              <div class="left">
                <span v-if="item.type == 1" class="price"><span>¥</span>{{item.couponPrice}}</span>
                <span v-if="item.type == 3" class="price">
                  {{item.discount}}
                  <span>折</span>
                </span>
                <span class="condition" v-if="item.type == 3">{{item.discountType == 1?'满'+item.discountPrice+'件可用':'满'+item.discountPrice+'可用'}}</span>
                <span class="condition" v-else>{{'满'+item.fullPrice+'元可用'}}</span>
                <span class="rightTop"></span>
                <span class="rightBottom"></span>
                <img v-show="false" src="@/assets/image/my/overdue.png" />
              </div>
              <div class="right" v-if="modelcouponList">
                <span class="title">{{item.name}}</span>
                <span class="time">{{item.times == 1?'不限使用时间':couponTabIndex == 1?'有效期至 '+item.lapseTime:item.startTime+'~'+item.lapseTime}}</span>
                <span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':item.scope == 3?'指定商品':''}}]<span v-if="couponTabIndex == 1 && item.overly === 0 && modelcouponList.length && item.couponId == modelcouponList[0].couponId && false">此券暂不可和已勾选券叠加使用</span></span>
                <!-- <img src="@/assets/image/my/Received.png" /> -->
                <van-icon class="checkbox" @click="changeCoupon(item)" v-if="couponTabIndex == 1 && modelcouponList.filter(ite=>{return ite.drawId == item.drawId}).length" name="checked" color="#EC6C01" />
                <van-icon class="checkbox" @click="changeCoupon(item)" v-show="couponTabIndex == 1 && !modelcouponList.filter(ite=>{return ite.drawId == item.drawId}).length" name="circle" color="#F93C00" />
              </div>
            </div>
          </van-list>
        </div>
        <p class="button" @click="changeCouponList" v-if="(couponTabIndex == 1 && couponList.length)">确定</p>
      </div>
    </n-transition>
  </div>
</template>

<script>
import nTransition from "@/components/n-transition/n-transition.vue";
import { shopCarChanged, toast } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: { nTransition },
  data() {
    return {
      packedList:null, // 套餐包含商品列表
      couponTabIndex: 1, // 1可用  2不可用
      couponList:[], // 可用优惠券列表
      uncouponList:[], // 不可用优惠券列表
      visible:false,
			footerShow: false,
      loading: false,
      finished: true,
      addressFlag: false,
      addressId:'',
      addressObj:null,
      type:null, // 订单类型
      pageNum:1,
      pageSize:10,
      total:0,
      price:0, // 商品金额
      invoiceId:null, // 发票id
      invioceType:null, // 发票类型
      parameters:null, // 数据列表请求参数
      orderMessage:null, // 订单信息
      giftList:null, // 赠品列表
      list:null, // 商品列表
      bestOffer:'', // 最优优惠价格
      bestCouponList:null, // 最优优惠列表
      currentOffer:'', // 当前优惠价格
      isUsecouponList:null, // 当前优惠券列表
      modelcouponList:[], // 弹框未确认已选优惠券列表
      modelcurrentOffer:0, // 弹框优惠价格
      modelProduceList:[], // 弹框商品列表
      submitLoading:false,
      kfxhVisible:false,  // 康复协会协议是否展示
      kfxhAgree:true, // 是否同意加入康复协会会员   /* 默认同意 */
		};
  },
  watch:{
    addressFlag(newVal,oldVal){
      if(newVal){
        if(this.addressId === ''){
          this.getDefaultAddress();
        }else{
          this.getAddressByid();
        }
      }
    },
    modelcurrentOffer(newVal,oldVal){
      // console.log(newVal)
    }
  },
  methods: {
    // 返回
    goBack() {
      if(this.$route.query.fromMenu){
        this.$onReturn(this.$route.query.fromMenu == '1');
      }else{
        this.$onReturn(this.$route.query.fromMenu == '1');
      }
    },
    // 开具发票
    goInvoice() {
      // 电子照
      if (this.type == 2) {
        let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
				paySignupObj.invoice = true;
				sessionStorage.setItem('paySignupObj', JSON.stringify(paySignupObj))
      }
      // 补考
      if (this.type == 3) {
        let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
        payMakeObj.invoice = true;
        sessionStorage.setItem('payMakeObj', JSON.stringify(payMakeObj));
      }
      this.$router.push('/invoice/invoiceMessage');
    },
    changeCouponIndex(e){
      this.$set(this,'couponTabIndex',e);
      // this.couponTabIndex = e;
      // console.log(this.couponTabIndex)
    },
    // 优惠明细弹框
    popUp() {
      this.$refs["pop"].show();
    },
    // 优惠券打开
    popUp1() {
      // if(this.couponList && this.couponList.length){
        this.modelcouponList = this.isUsecouponList?JSON.parse(JSON.stringify(this.isUsecouponList)):[];
        this.modelcurrentOffer = this.currentOffer?this.currentOffer:0;
        this.modelProduceList = JSON.parse(JSON.stringify(this.list));
        this.$refs["trainPop1"].show();
			  this.footerShow = true
      // }
    },
    // 优惠券关闭
    close1() {
      this.modelcouponList = [];
      this.modelcurrentOffer = '';
			this.$refs["trainPop1"].hide();
			this.footerShow = false
    },
    // 关闭优惠明细弹框
    close() {
      this.$refs["pop"].hide();
    },
    // 获取用户默认地址
    getDefaultAddress(){
      this.$ajax({
        url:'/hxclass-mobile/ship/address/getdefault',
        method:'get',
        params:{
          userId:this.$store.state.userInfo.userId
        }
      }).then(res=>{
        this.visible = true;
        if(res.code == 200 && res.success && res.data){
          this.$store.commit('updateAddress',res.data.id);
          this.addressId = res.data.id;
          this.addressObj = res.data;
          let list = this.parameters.map(item=>{
            item['province'] = res.data.provice; // 省
            item['city'] = res.data.city; // 市
            return item;
          })
          this.$set(this,'parameters',list);
          this.getOrderMessage();
        }else{
          this.getOrderMessage();
        }
      })
    },
    // 获取地址信息
    getAddressByid(){
      this.$ajax({
        url:'/hxclass-mobile/ship/address/detail',
        method:'get',
        params:{
          id:this.addressId
        }
      }).then(res=>{
        this.visible = true;
        if(res.code == 200 && res.success){
          this.addressObj = res.data;
          let list = this.parameters.map(item=>{
            item['province'] = res.data.provice; // 省
            item['city'] = res.data.city; // 市
            return item;
          })
          this.$set(this,'parameters',list);
          this.getOrderMessage();
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 获取优惠卷
    getCoupons(){
      let productIds = this.list.map(item=>{
        return item.productId
      })
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type:1,
          page:this.pageNum,
          size:this.pageSize,
          productIds:productIds.join(',')
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          // this.couponList = res.data.records;
        }
      })
    },
    onLoad() {
      if(this.pageNum * this.pageSize >= this.total){
        this.finished = true;
      }else{
        this.pageNum++;
        this.loading = true;
        this.getCouponList();
      }
    },
    // 根据优惠券列表和 商品列表 计算优惠价格
    discountAmount(){
      let arr = this.isUsecouponList.map(item=>{
        return item.drawId;
      })
      this.$ajax({
        url:'/hxclass-mobile/app-mall/app/verifyCouponUsage',
        method:'post',
        params:{
          userDrawId: arr,
          products: this.parameters
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.currentOffer = res.data;
          this.orderMessage.couponPrice = res.data;
        }else{
          this.$Toast(res.message);
        }
      })


      // this.$ajax({
      //   url:'/hxclass-mobile/app-mall/calculate/discount/amount',
      //   method:'post',
      //   params:{
      //     couponList:this.modelcouponList.length || this.modelcurrentOffer?this.modelcouponList:this.isUsecouponList,
      //     productList:this.list
      //   }
      // }).then(res=>{
      //   if(res.code == 200 && res.success){
      //     if(this.modelcouponList.length || this.modelcurrentOffer){
      //       this.modelcurrentOffer = res.data.price?res.data.price:0;
      //       this.modelProduceList = res.data.data?res.data.data:this.modelProduceList;
      //     }else{
      //       this.currentOffer = res.data.price?res.data.price:0;
      //       this.orderMessage.couponPrice = res.data.price?res.data.price:0;
      //     }
      //   }else{
      //     this.$Toast(res.message);
      //   }
      // })
    },
//     modelcouponList
// modelcurrentOffer
    // 优惠券弹框确认
    changeCouponList(){
      
      this.isUsecouponList = this.modelcouponList;
      this.$store.commit('updateCouponList',this.modelcouponList);
      if(this.modelcouponList.length) {
        this.discountAmount();
      }else{
        this.currentOffer = 0;
        this.orderMessage.couponPrice = 0;
      }
      // console.log(this.modelProduceList)
      let list = this.modelProduceList.filter(item=>{
        return item.noGift === 0;
      })
      // console.log(list)
      this.$set(this,'list',list)
      this.price = 0;
      list.map(item=>{
        this.price += item.totalPrice;
      })
      this.close1();
    },

    // 计算抵用价格
    nummodelcurrentOffer(){
      let num = 0;
      this.modelcouponList.map(item=>{
        num += item.couponPrice
      })
      this.modelcurrentOffer = num;
    },
    // 选择优惠券
    changeCoupon(e){
      // 无选中优惠券时
      if(!this.modelcouponList || !this.modelcouponList.length) {
        this.modelcouponList = [e];
        this.nummodelcurrentOffer();
        return
      }

      let stopCheck = false; // 已选择列表中含有同一批次优惠券
      let flag = false; // 是否是取消选中
      // 可叠加
      this.modelcouponList.some((item,index)=>{
        // 是否取消选中
        if(item.drawId == e.drawId) {
          flag = true;
          this.modelcouponList.splice(index,1);
          return true;
        }

        // 判断已选中优惠卷列表中是否含有同一批次优惠券
        if(item.couponId == e.couponId) {
          stopCheck = true;
          return true;
        }

        // 判断是否可叠加使用
        if(!item.overly) {
          this.modelcouponList.splice(index,1);
          return true;
        }
      })

      console.log(flag,stopCheck,e.overly)
      // 取消选中
      if(flag) {
        this.nummodelcurrentOffer();
        return 
      }
      
      if(stopCheck) {
        return this.$Toast({message: '当前优惠券不可叠加使用！', position: 'top'})
      }

      // 不可叠加使用
      if(!e.overly) {
        this.modelcouponList = [e];
        this.nummodelcurrentOffer();
        return
      }else{
        this.modelcouponList.push(e);
        this.nummodelcurrentOffer();
      }

      // let status = true;
      // let ind = '';
      // let arr = this.modelcouponList.filter((item,index) =>{
      //   if(item.drawId == e.drawId){
      //     ind = index;
      //     return true;
      //   }
      // })
      // if(arr.length){
      //   this.modelcouponList.splice(ind,1);
      // }else{
      //   this.modelcouponList = []; // 只选择一张
      //   this.modelcouponList.push(JSON.parse(JSON.stringify(e)));
      // }

      // this.discountAmount();  this.modelcurrentOffer
    },
    // 获取选中发票抬头
    getInvoiceDetail(){
      this.$ajax({
        url:'/hxclass-mobile/invoice/header/'+this.invoiceId,
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.invioceType = res.data.headerType;
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 提交订单
    submitOrder(){
      if(this.addressFlag && !this.addressId){
        return this.$Toast('请选择地址')
      }
      if(this.addressFlag && this.orderMessage.noFreight == 2){
        return this.$Toast('不配送')
      }
      this.submitLoading = true;
      let couponIds = [];
      let couponName = [];
      if(this.isUsecouponList){
        couponIds = this.isUsecouponList.map(item=>{
          couponName.push(item.name);
          return item.drawId;
        })
      }
      let list = null;
      let arr = this.list.filter(item=>{
        return item.type != 9;
      })
      let packArr = this.list.filter(item=>{
        return item.type == 9;
      })
      // 电子照
      let picture = '';
      if (this.type == 2) {
        let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj')); //电子照支付信息
        let paySignupId = paySignupObj.id; //考生id
        let confirmObj = JSON.parse(localStorage.getItem('confirmObj'+paySignupId)) //考生信息
        picture = confirmObj.certifiMobile; //照片
      }
      list = [...arr,...this.giftList,...this.packedList].map(item=>{
        return {
          noPay:item.noPay?1:0,
          courseDeferralDaysNotes:item.type == 7?item.delaysNum?`课程延期天数：${item.delaysNum}天`:'':item.type == 6?item.days?`课程延长天数：${item.days}天`:'':'', // 课程延期天数说明
          discountPrice:'', // 优惠价
          examNumberNotes:item.type == 7?item.makeNum?`补考次数：${item.makeNum}次；`:'':item.type == 4?`${item.answerNumber}`:item.type == 6?item.examNumber?`赠送考试机会：${item.examNumber}次`:'':'', // 考试次数说明
          openTimeId:item.openTimeId, // 开班时间id
          otherNotes:item.type == 1?`${item.openTimeId?item.openTimeId:item.courseCategory+' | '+item.courseHour}`:item.type == 5?'代为冲印费':'', // 其他说明
          payPrice:item.actualPrice?item.actualPrice:item.totalPrice, // 实付价
          productCount:item.count, // 产品数量
          productId:item.productId, // 产品id
          productPrice:item.couponPrice, // 产品价格
          productType:item.type, // 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他
          type:this.packedList.length?1:item.noGift+1, // 类型：1.正常，2.赠品
          picture: picture, //电子照片
          kfxhMumber: (item.kfxhMumber == 1 ? (this.kfxhAgree ? 1 : 0) : 0),  // 显示加入康复会员商品时判断是否勾选加入，加入传1，非0
        }
      })

      let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'))
      let signupUserId = payMakeObj ? payMakeObj.signupUserId : undefined
      
      let params = {
        couponIds:couponIds, // 优惠卷id
        couponName:couponName.join(','), // 优惠卷名称
        couponPrice:this.currentOffer, // 优惠价
        freightPrice:this.orderMessage.freightPrice, // 运费
        invoiceId:this.invoiceId, // 发票id
        isInvoice:this.invoiceId?1:0, // 是否开具发票1.是 0.否
        orderPrice:this.price, // 订单价
        orderType:this.type, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
        payPrice:(this.price*100-this.orderMessage.couponPrice*100+this.orderMessage.freightPrice*100)/100, // 实付价
        productList:list, // 产品信息
        receiveId:this.addressId, // 收货id
        source: this.packedList.length?1:'',//活动来源:1.套餐
        sourceId:packArr.length?packArr[0].productId:'', // 活动来源id
        signupUserId:signupUserId?signupUserId:undefined // 补考考生id
      }
      this.$ajax({
        url:'/hxclass-mobile/order/',
        method:'post',
        params:params
      }).then(async res=>{
        if(res.code == 200 && res.success){
          if(couponIds && couponIds.length) {
            // 通知原生刷新优惠券数量
            CouponReceive()
          }
          // 通知原生刷新购物车
          shopCarChanged()
          if(this.$route.query.fromMenu){
            // 清除服务器存储的数据
            this.$ajax({
              url:"/hxclass-mobile/order/deleteOrderJSON?userId="+this.$store.state.userInfo.userId,
              method:"delete"
            })
          }
          // 电子照
          if (this.type == 2) {
            let paySignupObj = JSON.parse(sessionStorage.getItem('paySignupObj'));
            let signupUserId = paySignupObj.signupUserId;
            // 关联用户报名id和订单id
            await this.onRelevanceId(res.data.orderId, signupUserId);
          }
          // 补考
          if (this.type == 3) {
            let payMakeObj = JSON.parse(sessionStorage.getItem('payMakeObj'));
            let signupUserId = payMakeObj.signupUserId;
            // 关联用户报名id和订单id
            await this.onRelevanceId(res.data.orderId, signupUserId);
          }
          let url = this.$route.query.source ? '/order/orderPayAllOther' : '/order/orderPayAll'  // 区分是否第三方支付
          this.$router.push(url +'?orderType=' + this.type +'&orderId=' + res.data.orderId + '&payPrice=' + ((this.price*100-this.orderMessage.couponPrice*100+this.orderMessage.freightPrice*100)/100))
        }else{
          toast(res.message)
          this.submitLoading = false;
        }
      })
    },
    // 关联id
    async onRelevanceId(orderId, signupUserId) {
      await this.$ajax({
        url:"/hxclass-mobile/exam/signUp/saveSignUpUserAndOrder",
        method:'put',
        params:{
          orderId: Number(orderId),
          signUpUserId: signupUserId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          // console.log('关联id')
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 获取确认订单信息
    getOrderMessage(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/app/confirm/order',
        method:'POST',
        params:this.parameters
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderMessage = res.data;
          // 储存最优优惠状态
          this.bestOffer = res.data.couponPrice;
          this.bestCouponList = res.data.couponList;
          // 优惠券列表
          // if(res.data.couponList){
          //   this.couponList = res.data.couponList;
          // }
          this.list = res.data.productList.filter(item=>{
            return item.noGift === 0;
          })
          let arr = res.data.productList.filter(item=>{
            return item.type == 9;
          })

          // 检索是否有康复会员商品
          let kfxhMumberList = res.data.productList.filter(item=>{
            return item.kfxhMumber == 1;
          })
          if(kfxhMumberList.length){
            this.kfxhVisible = true
          } else {
            this.kfxhVisible = false
          }

          if(arr.length){
            this.giftList = [];
            this.packedList = res.data.productList.filter(item=>{
              return item.noGift === 1;
            })
          }else{
            this.packedList = [];
            this.giftList = res.data.productList.filter(item=>{
              return item.noGift === 1;
            })
          }
          
          this.list.map(item=>{
            this.price += item.totalPrice;
          })
          // 新优惠券逻辑
          if(this.$store.state.confirmOrder.couponList && this.$store.state.confirmOrder.couponList.length) {
            this.isUsecouponList = this.$store.state.confirmOrder.couponList;
            this.discountAmount();
          }

          // vuex 中有没有存储优惠券列表  有：不存储调取获取优惠价格接口  没有：存储
          // if(this.$store.state.confirmOrder.couponList){
          //   this.isUsecouponList = this.$store.state.confirmOrder.couponList;
          //   this.discountAmount();
          // }else{
          //   this.currentOffer = res.data.couponPrice;
          //   this.isUsecouponList = res.data.couponList;
          //   this.$store.commit('updateCouponList',res.data.couponList)
          // }
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 获取可用/不可用优惠券列表
    getCouponList(){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/app/mall-shop/new',
        method:'post',
        params:{
          products:this.parameters, // 确认订单信息
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.couponList = res.data;
          this.$ajax({
            url:'/hxclass-mobile/app-mall/app/mall-shop/new',
            method:'post',
            params:{
              notAvailable: 1,
              products:this.parameters, // 确认订单信息
            }
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.uncouponList = res.data;
            }
          })
        }
      })

      // 旧版获取优惠券逻辑
      // this.$ajax({
      //   url:'/hxclass-mobile/app-mall/app/confirm/coupon',
      //   method:'post',
      //   params:{
      //     page:1, // 页数
      //     size:100000, // 每页条数
      //     type:1, // 1可用 2，不可用
      //     productList:this.parameters, // 确认订单信息
      //   }
      // }).then(res=>{
      //   if(res.code == 200 && res.success){
      //     this.couponList = res.data.records;
      //     this.$ajax({
      //       url:'/hxclass-mobile/app-mall/app/confirm/coupon',
      //       method:'post',
      //       params:{
      //         page:1, // 页数
      //         size:100000, // 每页条数
      //         type:2, // 1可用 2，不可用
      //         productList:this.parameters, // 确认订单信息
      //       }
      //     }).then(res=>{
      //       if(res.code == 200 && res.success){
      //         this.uncouponList = res.data.records;
      //       }
      //     })
      //   }
      // })
    },
    fun(){
      // console.log("监听到了");
      //  逻辑
      if(this.parameters && this.parameters[0].type == 1){
        if(this.parameters[0].openTimeId){
          this.$store.commit('updateopenTimeId',this.parameters[0].openTimeId);
        }
      }
      this.$onReturn(this.$route.query.fromMenu == '1');
    }
  },
  async created(){
    if(this.$route.query.fromMenu){
      let obj = JSON.parse(localStorage.getItem('userInfo'))
      let json = await this.$ajax({
        url:"/hxclass-mobile/order/getOrderJSON",
        method:"get",
        params:{
          userId: obj.userId
        }
      })
      let data = JSON.parse(json.data);
      this.$store.commit('updatelist',data.list);
      this.$store.commit('updateType',data.type);
      if(!this.$store.state.confirmOrder.address) {
        this.$store.commit('updateAddress',data.address);
      }
      if(!this.$store.state.confirmOrder.InvoiceId) {
        this.$store.commit('updateInvoiceId',data.InvoiceId);
      }
      if(!this.$store.state.confirmOrder.shortInvoiceId) {
        this.$store.commit('updateShortInvoiceId',data.shortInvoiceId);
      }
      this.$store.commit('updateCouponList',data.couponList);
    }
    // this.list = this.$store.state.confirmOrder.list;
    let list = null;
    let giftList = [];
    
		let isPack = false; // 套餐不查询优惠券列表
    // console.log(this.$store.state.confirmOrder.list)
    list = this.$store.state.confirmOrder.list.map(item=>{
      if(item.type == 9) {
        isPack = true;
      }
      if(item.productList){
        item.productList.map(ite=>{
          giftList.push({
            productId:ite.productId, // 商品id
            count:ite.count?ite.count:1, // 数量")
            openTimeId:ite.openTimeId, // 班级id
            type:ite.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            province:'', // 省
            city:'',// 市
            noGift:1, // 是否为赠品 0否1是
          })
        })
      }
      return {
        productId:item.productId, // 商品id
        count:item.count, // 数量")
        openTimeId:item.openTimeId, // 班级id
        type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        province:'', // 省
        city:'',// 市
        noGift:0, // 是否为赠品 0否1是
      }
    })
    this.parameters = [...list,...giftList];
    this.type = this.$store.state.confirmOrder.type; // 订单类型
    this.invoiceId = this.$store.state.confirmOrder.InvoiceId; // 发票id
    if(this.invoiceId){
      this.getInvoiceDetail();
    }
    if(this.type == 1 && !isPack){
      this.getCouponList();
    //   this.getCoupons(); // 获取优惠券列表，商品订单类型
    }
    if(this.$store.state.confirmOrder.address){
      this.addressId = this.$store.state.confirmOrder.address;
    }
    this.parameters.map(item=>{
      if(item.type == 2 || item.type == 3){
        // 有实物
        this.addressFlag = true;
      }
    })
    // 如果没有实物直接获取订单信息
    if(!this.addressFlag){
      this.getOrderMessage();
    }
  },
  mounted(){
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.fun, false);//false阻止默认事件
    }
  },
  destroyed(){
    window.removeEventListener('popstate', this.fun, false);//false阻止默认事件
  },
};
</script>

<style scoped lang="less">
/deep/.van-button--primary {
  border: 0;
}
.confirmOrder {
  background-color: rgba(247, 247, 249, 1);
  min-height: calc(100vh - 55px);
  padding: 12px 0 60px;
  box-sizing: border-box;
  .popContentBox {
    .popBox {
      .titleBox {
        padding: 28px 34px 0 34px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        display: flex;
        span {
          display: inline-block;
        }
        .title {
          flex: 1;
        }
      }
      .popContent {
        clear: both;
        padding: 32px 30px;
        .option {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          align-items: center;
          &:last-child {
            margin: 0;
          }
          .title {
            font-size: 14px;
            color: #333333;
            line-height: 22px;
          }
          .value {
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #666666;
            line-height: 22px;
          }
          .discount {
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #EC6C01;
            line-height: 22px;
          }
          .fruit {
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }
          .fruitprice {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
          }
        }
        .question {
          span {
            display: block;
          }
          .mess {
            font-size: 16px;
            font-weight: bold;
            color: #666666;
            line-height: 22px;
          }
          .warning {
            margin-top: 3px;
            font-size: 12px;
            color: #EC6C01;
            line-height: 17px;
          }
        }
      }
      .popBox_con {
        text-align: center;
        .con_main {
          position: relative;
          width: 125px;
          margin-top: 24px;
          margin-left: auto;
          margin-right: auto;
          .main_img {
            width: 125px;
            height: 88px;
            border-radius: 8.5px;
          }
          .main_info {
            position: absolute;
            bottom: 0;
            color: #fff;
            width: 125px;
            height: 24px;
            line-height: 24px;
            background: rgba(0, 0, 0, 0.34);
            border-radius: 0 0 9px 9px;
          }
        }
        .con_info {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-top: 24px;
        }
      }
    }
    .popbutton {
      padding: 5px 0;
      position: fixed;
      width: 100%;
      bottom: 0;
      .btn {
        width: 295px;
        height: 40px;
        display: block;
        text-align: center;
        margin: 0 auto;
        line-height: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .addressMess {
    background-color: #ffffff;
    padding: 16px 12px;
    border-radius: 8px;
    margin: 0 18px;
    .addressText {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      display: block;
      margin-top: 9px;
    }
    .userMess {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
      }
      img {
        width: 14px;
      }
      .userName {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        margin-left: 3px;
      }
      .phone {
        font-size: 12px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 17px;
        margin-left: 8px;
      }
      .changeAddress {
        font-size: 12px;
        font-weight: bold;
        color: #15b7dd;
        line-height: 17px;
        float: right;
        padding: 4px 9px;
        border-radius: 12.5px;
        border: 1px solid #15b7dd;
      }
    }
  }
  .address {
    margin: 0 18px;
    padding: 13px 16px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    span {
      font-size: 25px;
      color: rgba(153, 153, 153, 1);
      float: left;
      margin-right: 8px;
    }
  }
  .packedList{
    width: calc(100% - 24px);
    margin-top: 17px;
    padding: 0 12px 15px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #15B7DD;
    .haveStyle{
      width: 100px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 12px;
      margin-top: -1.4px;
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #15B7DD;
      line-height: 24px;
      background: #ffffff;
      background-image: url(~@/assets/image/order/packHave.png);
      background-size: 100% 98%;
    }
    .shopItem {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(238, 238, 238, 1);
      display: flex;
      margin-bottom: 16px;
      &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .shopImg {
        flex-shrink: 0;
        width: 100px;
        height: 70px;
        overflow: hidden;
        border-radius: 8.5px;
      }
      .shopMessage {
        position: relative;
        flex: 1;
        padding: 11px 0 4px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .isPay{
          display: inline-block;
          font-size: 10px;
          font-family: PingFangMedium;
          color: #999999;
          line-height: 12px;
          padding: 0 4px;
          border-radius: 10px;
          border: 1px solid #999999;
        }
        .shopprice {
          font-size: 12px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          span{
            font-size: 14px;
          }
        }
        .shoptielt-price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .shopTitle {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            padding-right: 10px;
            color: #333333;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          // .havNav{
          // 	-webkit-line-clamp: 1;
          // }
        }
        .stage_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // width: 152px;
          // margin-top: 5px;
          .stage {
            width: 100%;
            display: inline-block;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            line-height: 14px;
            box-sizing: border-box;
            white-space: nowrap;
            .stage_info {
              margin-top: 4px;
              white-space: normal;
              line-height: 16px;
            }
            .openTime{
              font-size: 10px;
              color: #999999;
              line-height: 12px;
              white-space: pre-wrap;
            }
          }
          .num {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-left: 4px;
          }
        }
        .stage_label {
          display: inline-block;
          border: 1px solid #15B7DD;
          font-size: 8px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #15B7DD;
          margin-top: 12px;
          padding: 2px 4px;
        }
        .priceNum {
          position: absolute;
          bottom: 0;
          display: flex;
          width: calc(100% - 10px);
          justify-content: space-between;
          .price {
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #EC6C01;
            line-height: 17px;
          }
          .num {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
        }
      }
      .haveNav {
        padding: 4px 0 4px 10px;
        .priceNum {
          bottom: 2px;
        }
      }
    }
  }
  .orderMessage {
    margin: 0 18px 12px;
    padding: 16px 12px;
    background-color: #ffffff;
    border-radius: 8px;
    .shopItem {
      // position: relative;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(238, 238, 238, 1);
      display: flex;
      margin-bottom: 16px;
      flex-wrap: wrap;
      // .packedList{
      //   position: absolute;
      // }
      &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .shopImg {
        flex-shrink: 0;
        width: 100px;
        height: 70px;
        overflow: hidden;
        border-radius: 8.5px;
      }
      .shopMessage {
        position: relative;
        flex: 1;
        padding: 11px 0 4px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .shoptielt-price {
          display: flex;
          justify-content: space-between;
          .shopTitle {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            padding-right: 10px;
            color: #333333;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          // .havNav{
          // 	-webkit-line-clamp: 1;
          // }
        }
        .shopprice {
          font-size: 12px;
          font-family: PingFangMedium !important;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          span{
            font-size: 14px;
          }
        }
        .stage_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // width: 152px;
          // margin-top: 5px;
          .stage {
            display: inline-block;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            line-height: 14px;
            box-sizing: border-box;
            white-space: nowrap;
            .stage_info {
              margin-top: 7px;
              white-space: normal;
              line-height: 16px;
              margin-bottom: 8px;
            }
            .openTime{
              font-size: 10px;
              color: #999999;
              line-height: 12px;
              margin-bottom: 14px;
            }
          }
          .num {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-left: 4px;
          }
        }
        .stage_label {
          display: inline-block;
          border: 1px solid #15B7DD;
          font-size: 8px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #15B7DD;
          // margin-top: 7px;
          padding: 2px 4px;
        }
        .priceNum {
          position: absolute;
          bottom: 0;
          display: flex;
          width: calc(100% - 10px);
          justify-content: space-between;
          .price {
            font-size: 12px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #EC6C01;
            line-height: 17px;
          }
          .num {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
        }
      }
      .haveNav {
        padding: 0px 0 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .priceNum {
          bottom: 2px;
        }
      }
    }
    .freight {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 13px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      .price {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    .donated {
      display: flex;
      .moduleName {
        width: 43px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .donatedList {
        flex: 1;
        .donatedItem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          .donatedTitle {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
          .num {
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 23px;
          }
        }
      }
    }
  }
  .warnmessage{
    padding: 4px 18px 0 26px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    .p{
      position: relative;
      margin-bottom: 14px;
      .icon{
        position: absolute;
        left: -16px;
      }
    }
    .span{
      display: block;
      margin-bottom: 14px;
      position: relative;
    }
    .span::before{
      content: '·';
      color: #999999;
      font-size: 25px;
      line-height: 17px;
      position: absolute;
      // top: -2px;
      left: -12px;
    }
  }
  .favourable {
    padding: 16px 12px;
    background-color: #ffffff;
    margin: 12px 18px;
    border-radius: 8px;
    .favourableItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      &:last-child {
        margin: 0;
      }
      .currentOffer{
        font-size: 14px;
        font-family: 'PingFangMedium';
        font-weight: 400;
        line-height: 22px;
        color:#EC6C01;
      }
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        span{
          font-size: 10px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #15B7DD;
          line-height: 14px;
          padding: 0 5px;
          border: 1px solid #15B7DD;
        }
      }
      .price {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        span{
          font-size: 14px;
        }
      }
      .value {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        display: flex;
        align-items: center;
      }
    }
  }
  .invoicecoupon {
    padding: 16px 12px;
    background-color: #ffffff;
    margin: 12px 18px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .value {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
  .footerView {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 18px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
		z-index: 1000;
		&.footerStyle {
			display: none;
		}
    .footerleft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .price {
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #EC6C01;
        line-height: 22px;
        span {
          font-size: 14px;
        }
      }
      .onlyOne {
        line-height: 40px;
      }
      .include {
        display: flex;
        height: 13px;
        .discount {
          font-size: 12px;
          color: #666666;
          line-height: 11px;
        }
        .nav {
          margin-left: 12px;
          padding: 1px 5px;
          background-color: rgba(216, 216, 216, 1);
          font-size: 8px;
          color: #000000;
          line-height: 11px;
          border-radius: 1px;
          // transform : scale(0.83,0.83);
        }
      }
    }
    /deep/.buttonsubmit {
      height: 40px;
      width: 136px;
      line-height: 40px;
      color: #ffffff;
      background-color: #15b7dd;
      border-radius: 20px;
      margin: 0;
    }
    /deep/.buttonsubmit:after {
      border: 0;
    }
  }
  // 优惠券
  .windowCoupons {
    background-color: #fff;
    border-radius: 24px 24px 0 0;
    padding: 0 18px;
    .couponTab{
      display: flex;
      justify-content: space-around;
      .tabItem{
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        padding-bottom: 15px;
      }
      .tabActive{
        font-weight: 500;
        color: #333333;
        background-image: url('~@/assets/image/examination/btn-examination1.png');
        background-size: 41px 4px;
        background-repeat: no-repeat;
        background-position: 24% 16px;
      }
    }
    .couponMessage{
      margin-bottom: 23px;
      font-size: 12px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 20px;
      span{
        color: #FA3E3E;
      }
    }
    .button{
      letter-spacing: 1em;
      margin: 20px 22px;
      width: calc(100% - 44px);
      height: 40px;
      background: #15B7DD;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
    }
    .navBar {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 18px 0;
      .center {
        font-size: 18px;
        line-height: 25px;
        color: #333;
      }
      .right {
        position: absolute;
        right: 0;
      }
    }
  }
  .coupon {
    height: 320px;
    overflow-y: scroll;
    .couponItem {
      // background: rgba(249, 0, 0, 0.03);
      border-radius: 6px;
      // border: 1px solid #f93c00;
      display: flex;
      margin-bottom: 30.5px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 90px;
        padding: 15px 19px 15px 14px;
        // border-right: 1px dashed #f93c00;
        position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
        // .rightTop {
        //   width: 20px;
        //   height: 10px;
        //   // border-left: 1px solid #EC6C01;
        //   // border-right: 1px solid #EC6C01;
        //   // border-bottom: 1px solid #EC6C01;
        //   // border-top: 1px solid #F7F7F9;
        //   border-top: 0;
        //   box-sizing: border-box;
        //   position: absolute;
        //   right: -11px;
        //   top: -2px;
        //   border-bottom-left-radius: 10px;
        //   border-bottom-right-radius: 10px;
        //   background-color: #fff;
        // }
        // .rightBottom {
        //   width: 20px;
        //   height: 10px;
        //   // border-left: 1px solid #EC6C01;
        //   // border-right: 1px solid #EC6C01;
        //   // border-top: 1px solid #EC6C01;
        //   border-bottom: 0;
        //   box-sizing: border-box;
        //   position: absolute;
        //   right: -11px;
        //   bottom: -2px;
        //   border-top-left-radius: 10px;
        //   border-top-right-radius: 10px;
        //   background-color: #f7f7f9;
        // }
        .price {
          font-size: 28px;
          font-family: PingFangMedium;
          color: #f93c00;
          line-height: 26px;
          span {
            font-size: 13px;
          }
        }
        .condition {
          width: 62px;
          font-size: 12px;
          font-family: PingFangMedium;
          color: #f93c00;
          line-height: 12px;
          white-space: nowrap;
          margin-top: 8px;
        }
        img {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 11px 13px 8px 19px;
        position: relative;
        box-sizing: border-box;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
        .title {
          font-size: 14px;
          font-weight: 500;
          color: #f93c00;
          line-height: 16px;
        }
        .time {
          font-size: 10px;
          font-family: PingFangMedium;
          color: #f93c00;
          line-height: 15px;
          margin-top: 6px;
        }
        .type {
          width: 100px;
          white-space: nowrap;
          font-size: 10px;
          font-family: PingFangMedium;
          color: #f93c00;
          line-height: 15px;
          margin-top: 5px;
          span{
            display: inline-block;
            transform: scale(0.8);
            color: #333333;
          }
        }
        .btn {
          padding: 1px 8px;
          background: rgba(249, 0, 0, 0.08);
          border-radius: 10px;
          border: 1px solid #f93c00;
          position: absolute;
          right: 13px;
          bottom: 10px;
          font-size: 12px;
          font-family: PingFangMedium;
          color: #f93c00;
          line-height: 17px;
        }
        .checkbox{
          display: block;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 15px;
          top: calc( 50% - 9px );
        }
        img {
          width: 44px;
          height: 35px;
          position: absolute;
          right: -1px;
          top: -1px;
        }
      }
    }
    .overdue {
      border-color: #666666;
      // background-color: rgba(153, 153, 153, 0.08);
      .left {
        border-color: #999999;
        background-image: url(../../assets/image/20220622-154538.png);
        &::before{
          background-image: url(../../assets/image/20220622-154546.png);
        }
        &::after{
          background-image: url(../../assets/image/20220622-154542.png);
        }
      }
      .right{
        background-image: url(../../assets/image/20220622-154538.png);
        &::after{
          background-image: url(../../assets/image/20220622-154518.png);
        }
      }
      span {
        color: #999999 !important;
      }
      .rightTop {
        border-color: #999999 !important;
        // border-top: 1px solid #F7F7F9 !important;
      }
      .rightBottom {
        border-color: #999999 !important;
        // border-bottom: 1px solid #F7F7F9 !important;
      }
      .btn {
        background: rgba(153, 153, 153, 0.16) !important;
        border: 1px solid RGBA(225, 225, 227, 1) !important;
      }
    }
  }
}
 /* for Chrome */
  ::-webkit-scrollbar {
      display: none;
  }
</style>
