<template>
  
</template>

<script>
import wx from 'weixin-js-sdk'
export default {
  created() {
    if (this.is_weixn()) {
      this.authSDK()
    }
  },
  methods: {
    // 判断是否微信环境
    is_weixn() {
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    authSDK() {
      // app01({
      //   signurl: window.location.href.split('#')[0],
      // }).then((data) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx02d6411e63400822', // 必填，公众号的唯一标识
          timestamp: 1672223493, // 必填，生成签名的时间戳
          nonceStr: '8d4c6d82-d069-4ec6-9356-2436758e3ed9', // 必填，生成签名的随机串
          signature: '0863eda655e260188ee39ae8ef563c6e8232e996',// 必填，签名
          jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp'] // 这里要配置一下
        })
        wx.ready(function() {
          wx.checkJsApi({
            jsApiList: [
              'getLocation',
              'wx-open-launch-app',
              'wx-open-launch-weapp'
            ], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
            success: function(res) {
              console.log('wxJsApi success:', res)
            },
            fail: (err) => {
              console.log('wxJsApi fail:', err)
            }
          })
          // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        })
      // })
    }
  }
}
</script>

<style>

</style>