var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"window",attrs:{"id":"window"}},[_c('div',{attrs:{"id":"content"}},[_c('router-view')],1),(_vm.isBottomBar)?_c('van-tabbar',{attrs:{"active-color":"#00B3C5","inactive-color":"#999","fixed":false},on:{"nav-bar-height":function($event){75}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ?
          require('@/assets/image/tabBar/icon_home_cked.png') :
          require('@/assets/image/tabBar/icon_home.png')}})]}}],null,false,1696722931)},[_c('span',[_vm._v("首页")])]),_c('van-tabbar-item',{attrs:{"to":"/selected"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ?
          require('@/assets/image/tabBar/icon_selected_cked.png') :
          require('@/assets/image/tabBar/icon_selected.png')}})]}}],null,false,1414220787)},[_c('span',[_vm._v("精选")])]),_c('van-tabbar-item',{on:{"click":function($event){return _vm.toUrl()}},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ?
          require('@/assets/image/tabBar/icon_study_cked.png') :
          require('@/assets/image/tabBar/icon_study.png')}})]}}],null,false,732416179)},[_c('span',[_vm._v("学习")])]),_c('van-tabbar-item',{attrs:{"to":"/my","dot":_vm.messageNum},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ?
          require('@/assets/image/tabBar/icon_my_cked.png') :
          require('@/assets/image/tabBar/icon_my.png')}})]}}],null,false,3582520563)},[_c('span',[_vm._v("我的")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }