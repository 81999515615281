export default {
	props: {
		// tab列表
		list: {
			type: Array,
			default: ()=>[]
		},
		// tab显示的个数
		showCount: {
			type: Number,
			default: 5
		}
	}
}