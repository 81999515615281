<template>
  <div class="container-box">
    <van-nav-bar
			title="报考指南"
			left-arrow
			class="nav-circular"
			:fixed="true"
			:placeholder="true"
			@click-left="$onReturn($route.query.fromMenu == '1')"
		/>
    <div class="container-wrap">
      <!-- 课程名称 -->
      <div class="header-inner">
        <div class="course-name">
          <span class="text">孤独症康复上岗培训课程报考指南</span>
        </div>
        <img class="icon" src="@/assets/image/icon/20220208041501.png" />
        <div class="skew"></div>
        
      </div>
      <!-- 报考流程 -->
      <div class="list-inner">
        <div class="list-item" v-for="(item,index) in listArry" :key="index" :class="{'item-open':item.isOpen}">
          <div class="header" @click="item.isOpen=!item.isOpen">
            <div class="side-left">
              <span class="num">{{item.key}}</span>
              <span class="name">{{item.name}}</span>
            </div>
            <span :class="item.isOpen ? 'triangleD' : 'triangleR'"></span>
          </div>
          <div class="textAear" v-show="item.isOpen">
            <div v-for="(children,index) in item.content" :key="index + 'index'">{{children}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		// 可用组件的哈希表
		components: {},
		// 数据对象
		data() {
			return {
        isOpen:false,
        listArry:[
          {key:1,isOpen:false,name:'组织架构',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:2,isOpen:false,name:'报考条件',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:3,isOpen:false,name:'报考注意事项',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:4,isOpen:false,name:'考前注意事项',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:5,isOpen:false,name:'考试注意事项',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:6,isOpen:false,name:'考试通过确认证书信息',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:7,isOpen:false,name:'成绩及证书查询',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:8,isOpen:false,name:'补考',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:9,isOpen:false,name:'证书获取时间',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:10,isOpen:false,name:'证书补办及更换',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
          {key:11,isOpen:false,name:'证书样例',content:['《孤独症康复教育上岗培训课程》学员均可报名考试。学员完成全部视频课程以及课后小节自测题，方可参加考试。','注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。']},
        ]
      }
		},
		// 事件处理器
		methods: {},
	}
</script>


<style lang="less" scoped>          
  .container-wrap{
    padding: 14px 18px;
    .header-inner{
      position: relative;
      margin: 0 12px;
      .course-name{
        background: #15B3DD;
        padding: 10px 40px;
        text-align: center;
        .text{
          font-size: 25px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 37.5px;
        }
      }
      .skew {
        height: 10px;
        position: relative;
        left: 3px;
        background: #1E97B4;
        color: #000;
        font-size: 8.5px;
        line-height: 17px;
        text-align: center;
        transform: skew(30deg);
      }
      .icon{
        position: absolute;
        z-index: 99;
        width: 50px;
        right: -22px;
        bottom: -20px;
      }
    }
    
    .list-inner{
      margin-top: 25px;
      .list-item{
        margin-bottom: 18px;
        .header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 18px 6px 6px;
          border-radius: 22px;
          background: #fff;
          .side-left{
            .num{
              display: inline-block;
              border-radius: 50%;
              border: 1px solid #15B3DD;
              text-align: center;
              line-height: 32px;
              width: 32px;
              height: 32px;
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: bold;
              color: #15B3DD;
            }
            .name{
              margin-left: 21.5px;
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #333333;
            }
          }
          // 右三角
          .triangleR{
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 13px solid #999999;
            border-bottom: 7px solid transparent;
          }
          // 下三角
          .triangleD{
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 12px solid #FFFFFF;
          }
        }
        .textAear{
          padding: 10px 20px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
      }
      // 打卡后的状态
      .item-open{
        border-radius: 22px 22px 22px 0;
        background: #fff;
        .header{
          background: #15B3DD;
          color: #FFFFFF;
          padding: 0 18px 0 0;
          .side-left{
            .num{
              position: relative;
              right: 1px;
              line-height: 40px;
              font-size: 20px;
              width: 40px;
              height: 40px;
              border: 1px solid #fff;
            }
            .name{
              margin-left: 17px;
            }
            .num,.name{
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
