<template>
  <div class="container-box">
    <van-nav-bar
      class="nav-circular"
      title="购物车"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right=""
    >
      <template #right>
        <div class="right">
          <div class="collectCoupons" v-show="couponList.length" @click="popUp1()">
            <img src="@/assets/image/icon/20220412154432.png" alt="">
            领券
          </div>
          <div class="empty" v-if="shopCarList.length" @click="deleteModel = true;deleteModeltxt=true">
            <img src="@/assets/image/icon/20220221041501.png" />
            清空
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="shopList">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <no-message style="margin-top:50px;" v-if="!btnloading && !shopCarList.length && !offShelfItems.length" type="1" message="您还没有添加任何商品" />
        <van-swipe-cell class="shopItem" v-for="item in shopCarList" :key="item.shopId">
          <div class="car-item">
            <van-checkbox
              checked-color="#15B7DD"
              @click="changeStatus(item)"
              :name="item.productId">
              <template #icon="props">
                <img class="img-icon" :src="props.checked ? require('@/assets/image/icon/checkoutActive.png') : require('@/assets/image/icon/checkoutUnActive.png')" />
              </template>
            </van-checkbox>
            <div class="shop">
              <van-image
                @click="goDetail(item)"
                class="car-img"
                fit="cover"
                :src="item.listPhoto"
              />
              <div class="car-right">
                <div class="car-title" @click="goDetail(item)">
                  {{item.name}}
                </div>
                <p class="openTime" v-if="item.openTimeId">{{item.startTime}}-{{item.endTime}}</p>
                <p class="openTime timeLable" v-if="item.type == 1 && !item.openTimeId">{{item.courseCategory}} | {{item.courseHour}}</p>
                <p class="openTime" v-if="item.type == 4">{{item.answerNumber}}</p>
                <div class="car-foo">
                  <div class="price" @click="goDetail(item)">
                    ￥<span class="present">{{item.couponPrice.toFixed(2)}}</span>
                    <span class="original" v-if="!!item.original">￥{{item.originalPrice.toFixed(2)}}</span>
                  </div>
                  <van-stepper v-model="item.count" @change="changecount(item)" :min="0" :max="item.type == 1 || item.type == 4?1:10000" theme="round" button-size="22" />
                  <!-- <van-stepper v-model="item.count" @change="changecount(item)" :min="0" :max="item.type == 1 || item.type == 4?1:10000" :integer="true" button-size="17" /> -->
                </div>
              </div>
            </div>
          </div>
          <template #right>
            <van-button square text="删除" @click="delshopitemShelf(item)" type="danger" class="delete-button" />
          </template>
        </van-swipe-cell>
      </van-checkbox-group>
    </div>
    <!-- 购物车底部 -->
    <div class="shopping-Cart" :class="{'shopping-hover' : shopCarList.length}" v-show="shopCarList.length">
      <div class="select-all">
        <van-checkbox
          v-model="isCheckedAll"
          checked-color="#15B7DD"
          @click="checkAll"
          >全选
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('@/assets/image/icon/checkoutActive.png') : require('@/assets/image/icon/checkoutUnActive.png')" />
          </template>
        </van-checkbox>
      </div>
      <div class="price-box">
        <div class="total">
          合计<span><i>￥</i>{{price.toFixed(2)}}</span>
        </div>
        <!-- 优惠 -->
        <!-- <div class="discount" v-show="num > 0">
          <span class="p1">优惠减：￥<i>13</i>元</span>
          <span class="p2">优惠明细</span>
        </div> -->
      </div>
      <span class="submit-btn" :class="{'submit-btn-dis':num==0}" @click="goConfirmOrder">{{num > 0 ? '去结算':'不可结算'}}</span>
    </div>

    <!-- 删除确认框 -->
    <van-popup v-model="deleteModel" round position="bottom" @close="cancelDelete" :style="{ height: '200' }">
      <p class="deleteMessage">{{deleteModeltxt==true?"确认清空购物车？":"确认不需要该商品？"}}</p>
      <p class="deleteEnter" @click="submitDelete">确定</p>
      <div class="interval"></div>
      <p class="deleteCancel" @click="cancelDelete">取消</p>
    </van-popup>

    <!-- 优惠券弹窗 -->
		<n-transition ref="trainPop1" class="couponModel" speed="ease-in-out" :height="415" :maskVal="0.35">
			<div class="windowCoupons">
				<div class="navBar">
					<div class="center">领取优惠券</div>
					<div class="right" @click="closeCouponModel">
						<van-icon name="cross" color="#666" size="20" />
					</div>
				</div>
				<div class="coupon">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
          >
            <div class="couponItem" v-for="(item,index) in couponList" :key="index">
              <div class="left">
                <template v-if="item.type == 1">
                  <span class="price"><span>¥</span>{{item.couponPrice}}</span>
                  <span class="condition">满{{item.fullPrice}}元可用</span>
                </template>
                <template v-if="item.type == 3">
                  <span class="price">{{item.discount}}<span>折</span></span>
                  <span class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</span>
                </template>
                <span class="rightTop"></span>
                <span class="rightBottom"></span>
                <img v-show="false" src="@/assets/image/my/overdue.png" />
              </div>
              <div class="right">
                <span class="title">{{item.name}}</span>
                <span class="time" v-if="item.times == 2">{{item.startTime.split('-').join('.')}}-{{item.endTime.split('-').join('.')}}</span>
                <span class="time" v-if="item.times == 1">不限使用时间</span>
                <span class="type">[{{item.scope == 1?'全场通用':item.scope == 2?'指定商品类型':'指定商品'}}]</span>
                <span class="btn" @click="couponReceive(item)">{{!!item.noReceive?'可用商品':'点击领取'}}</span>
                <img v-show="!!item.noReceive" src="@/assets/image/my/Received.png" />
              </div>
            </div>
          </van-list>
				</div>
			</div>
		</n-transition>
  </div>
</template>

<script>
import nTransition from '@/components/n-transition/n-transition.vue';
import { FunClosePopup, FunConfirmOrder,goShopDetail,shopCarChanged,CouponReceive,ViewAvailableProducts,jumpToShopDetail } from '@/assets/js/NativeWebKit.js';
export default {
  components: { nTransition },
  data() {
     return {
      shopCarList:[], // 购物车列表
      offShelfItems:[], // 购物车下架商品
      price:0, // 价格
      num:0, // 选中商品数量
      handleItem:null,
      btnloading: false,
      loading: false,
      finished: false,
      pageNum:1,
      pageSize:10,
      total:0,
      couponList:[], // 优惠券列表
      isCheckedAll: true,
      result:[],
      deleteModel:false, // 删除弹框
      deleteModeltxt:false,
      carListLength:0,
      unCheckList:[], // 未选中购物车商品id
      timer:null,
      getCoupon: false
     }
  },
  
  created() {
    let _this = this;
    window.refreshShopCar = function () {
      _this.getShopCarList(); // 获取购物车列表
    };
    this.getShopCarList();
  },

  methods: {
    // 优惠券弹框显隐
    popUp1 () {
      // 记录领券弹框状态
      this.getCoupon = true;
			this.$refs['trainPop1'].show()
		},
    closeCouponModel(){
      this.getCoupon = false;
			this.$refs['trainPop1'].hide()
		},
    onLoad() {
      if(this.pageNum * this.pageSize >= this.total){
        this.finished = true;
      }else{
        this.pageNum++;
        this.loading = true;
        this.getCoupons();
      }
    },
    // 领取优惠券/查看可用商品
    couponReceive(e){
      if(!e.noReceive){
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/coupon-receive',
          method:'post',
          params:{
            couponId:e.couponId,
            receiver:this.$store.state.userInfo.userId,
            receiverTime:this.selectTime(),
            state:1
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            e.noReceive = 1;
            // 原生刷新我的页面优惠券数量
            CouponReceive();
            this.$Toast('领取成功')
          }
        })
      }else{
        if(e.scope == 1){
          this.getCoupon = false;
          this.$refs['trainPop1'].hide()
          this.$router.push("/shop/shoppingMall");
				}else{
          this.$router.push('/mine/couponGoods?couponId='+e.couponId);
				}
      }
    },

    // 删除商品-无提示
    delOffShelf(e){
      e.count = 0;
      this.handleItem = e;
      this.submitDelete();
    },
    // 删除商品-无提示
    delshopitemShelf(e){
      e.count = 0;
      this.handleItem = e;
      this.submitDelete();
    },
    // 购物车删除
    submitDelete(){
      if(this.handleItem){
        let itemId = this.handleItem.productId;
        this.shopCarList.map((item,index)=>{
          if(item.productId == this.handleItem.productId){
            this.shopCarList.splice(index,1);
          }
        })
        this.shopCarList
        this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/update',
          method:'put',
          params:{
            count:this.handleItem.count, // 数量
            couponCode:'', // 优惠劵编码
            openTimeId:this.handleItem.openTimeId, // 开班设置id
            price:this.handleItem.couponPrice, // 价格
            productId:this.handleItem.productId, // 商品id
            shopId:this.handleItem.shopId, // 购物车
            type:this.handleItem.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
            userNo:this.$store.state.userInfo.userId, // 用户
          }
        }).then((res)=>{
          if(res.code == 200 && res.success){
            this.getShopCarList();
            this.shopList?.map(item=>{
              if(item.productId == itemId){
                this.$set(item,'noSHop',0);
              }
            })
            this.handleItem = null;
            this.deleteModel = false;
          }else{
            this.$Toast(res.message);
          }
        })
      }else{
        this.$ajax({
          url:"/hxclass-mobile/app-mall/shop/delete",
          method:"delete"
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.shopList?.map(item=>{
              this.$set(item,'noSHop',0);
            })
            this.getShopCarList();
            this.deleteModel = false;
            // 原生关闭弹框
            if(this.$route.path == "/setUp/myCopy"){
              FunClosePopup(1)
            }
          }
        })
      }
      this.deleteModel = false;
    },
    // 购物车取消删除
    cancelDelete(){
      if(this.handleItem){
        this.handleItem.count = 1;
      }
      this.deleteModel = false;
    },

    // 获取购物车列表
    getShopCarList(){
      this.btnloading = true;
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        this.btnloading = false;
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })
        }
      })
    },
    changeList(newVal){
      let num = 0;
      this.price = 0;
      this.result = [];
      newVal.map(item=>{
        let index = this.unCheckList.indexOf(item.productId)
        if(index != '-1'){
          
        }else{
          this.result.push(item.productId);
          this.price += item.couponPrice * item.count;
          num+=item.count;
        }
      });
      this.num = num;
      if(newVal.length !== this.carListLength){
        this.carListLength = newVal.length;
        this.total = 0;
        this.pageNum = 1;
        this.couponList = [];
        if(newVal.length)this.getCoupons();
      }
    },
    // 更改复选框状态
    changeStatus(e){
      // if(!e.noGround){
      //   return 
      // }
      let index = this.unCheckList.indexOf(e.productId)
      if(index == -1){
        this.unCheckList.push(e.productId);
        this.price -= e.couponPrice * e.count;
        console.log(this.price)
        if(this.price <= 0){
          this.price = 0;
        }
        this.num -= e.count;
      }else{
        this.unCheckList.splice(index,1);
        this.price += e.couponPrice * e.count;
        this.num += e.count;
      }
    },
    // 获取优惠卷
    getCoupons(){
      let productIds = this.shopCarList.map(item=>{
        return item.productId
      })
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/coupon-list',
        method:'get',
        params:{
          type:1,
          page:this.pageNum,
          size:this.pageSize,
          productIds:productIds.join(',')
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          if(this.pageNum == 1){
            this.couponList = [];
            this.couponList.push(...res.data.records);
          }else{
            this.couponList.push(...res.data.records);
          }
        }
      })
    },
    // 修改购物车数量
    changecount(e){
      if(e.count === 0){
        this.handleItem = e;
        this.deleteModel = true;
        // 做文字提示的判断
        this.deleteModeltxt = false;
      }else{
        if(this.handleItem){
          return this.handleItem = null;
        }
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
          this.$ajax({
            url:'/hxclass-mobile/app-mall/shop/update',
            method:'put',
            params:{
              count:e.count, // 数量
              couponCode:'', // 优惠劵编码
              openTimeId:e.openTimeId, // 开班设置id
              price:e.couponPrice, // 价格
              productId:e.productId, // 商品id
              shopId:e.shopId, // 购物车
              type:e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
              userNo:this.$store.state.userInfo.userId, // 用户
            }
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.getShopCarList();
            }else{
              this.$Toast(res.message);
            }
          })
        },500)
      }
    },
    // 全选
    checkAll() {
      if(this.isCheckedAll){
        this.$refs.checkboxGroup.toggleAll(true);
        this.unCheckList = [];
        this.price = 0;
        this.num = 0;
        this.shopCarList.map(item=>{
          this.price += item.couponPrice * item.count;
          this.num+=item.count;
        });
      }else{
        this.$refs.checkboxGroup.toggleAll();
        this.price = 0;
        this.num = 0;
        this.unCheckList = this.shopCarList.map(item=>{
          return item.productId;
        });
      }
    },
    // 跳转详情页面
    goDetail(e) {
      // 教育课程
      if (e.type == 1) {
        jumpToShopDetail(e.type,e.courseId)
        // if(this.$route.query.nativeRoute){
        //   goShopDetail('/curriculum/course?id='+e.courseId)
        // }else{
        //   this.$router.push('/curriculum/course?id='+e.courseId)
        // }
        // this.$router.push('/curriculum/course?id='+e.courseId);
      }
      // // 图书教具
      else if (e.type == 2 || e.type == 3) {
        jumpToShopDetail(e.type,e.productId)
        // if(this.$route.query.nativeRoute){
        //   goShopDetail('/curriculum/BookDdetails?id='+e.productId)
        // }else{
        //   this.$router.push('/curriculum/BookDdetails?id='+e.productId)
        // }
      }
      // // 模拟考试
      else if (e.type == 4) {
        jumpToShopDetail(e.type,e.productId,'cart')
        // if(this.$route.query.nativeRoute){
        //   goShopDetail('/curriculum/BookDdetails?id='+e.productId)
        // }else{
        //   this.$router.push('/curriculum/BookDdetails?id='+e.productId)
        // }
      }
    },
    // 跳转确认订单页面
    async goConfirmOrder(){
      if(this.num > 0){
        let productList = [];
        let productIds = [];
        this.shopCarList.map(item=>{
          if(this.result.indexOf(item.productId) !== -1){
            productList.push(item);
            productIds.push(item.productId);
          }
        })
        // console.log(productIds)
        await this.$ajax({
          url:'/hxclass-mobile/app-mall/shop/ground',
          method:'get',
          params:{
            productIds:productIds.join(',')
          }
        }).then(async res=>{
          if(res.code == 200 && res.success){
            // 原生跳转
            if(this.$route.query.nativeRoute){
              let obj = {
                list:productList, // 确认订单商品列表
                type:1, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
                address:null, // 地址id
                InvoiceId:null, // 发票id
                shortInvoiceId:null, // 暂存发票id
                couponList:null, // 优惠券列表
              }
              await this.$ajax({
                url:'/hxclass-mobile/order/saveOrderJSON',
                method:'put',
                params:{
                  orderJSON: JSON.stringify(obj),
                  userId: this.$store.state.userInfo.userId
                }
              })
              // 调原生方法去结算
              FunConfirmOrder()
            }else{
              this.$store.commit('updatelist',productList);
              this.$store.commit('updateType',1);
              this.$store.commit('updateInvoiceId',null);
              this.$store.commit('updateShortInvoiceId',null);
              this.$store.commit('updateAddress',null);
              this.$store.commit('updateCouponList',null);
              this.$router.push('/order/confirmOrder')
            }
          }else{
            this.getShopCarList();
            this.$Toast('当前购物车包含已下架商品，请重新选择')
          }
        })
      }
    },
  },
  watch:{
    // 处理购物车数据
    shopCarList(newVal,oldVal){
      // 通知原生购物车变化
      shopCarChanged();
      this.changeList(newVal);
    },
    unCheckList(newVal,oldVal){
      if(newVal.length){
        this.isCheckedAll = false;
      }else{
        this.isCheckedAll = true;
      }
    }
  },
}
</script>

<style lang="less" scoped>
  .shopList {
    padding-bottom: 60px;
  }
  .goods-card {
    margin: 0;
    background-color: #ffffff;
  }

  .delete-button {
    height: 100%;
  }
  .right{
    display: flex;
    justify-content: center;
  }
  .empty{
    display: flex;
    align-items: center;
    margin-left: 4px;
    color: rgba(153, 153, 153, 1);
    img{
      width: 15px;
      margin-right: 2px;
    }
  }
  
  .collectCoupons{
    display: flex;
    align-items: center;
    color: rgba(249, 0, 0, 0.6);
    margin-right: 4px;
    img{
      width: 11px;
      margin-right: 4px;
    }
  }
  .deleteMessage{
    font-size: 12px;
    text-align: center;
    line-height: 50px;
    color: rgba(153, 153, 153, 1);
  }
  .deleteEnter{
    border-top: 1px solid rgba(229, 229, 229, 1);
    margin: 0 50px;
    color: rgba(249, 0, 0, 1);
    font-size: 16px;
    line-height: 50px;
    font-weight: bold;
    text-align: center;
  }
  .interval{
    margin: 0 20px;
    height: 10px;
    background: rgba(247, 247, 249, 1);
  }
  .deleteCancel{
    margin: 0 50px;
    color: rgba(21, 183, 221, 1);
    font-size: 16px;
    line-height: 50px;
    font-weight: bold;
    text-align: center;
  }
  
  /* 购物车 */
  .shopping-Cart{
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 0 15px;
    .select-all{
      /deep/.van-checkbox{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        .van-checkbox__icon{
          font-size: 15px !important;
        }
        .van-checkbox__label{
          margin-left: 2px;
        }
        .img-icon{
          width: 15px;
          height: 15px;
        }
      }
    }
    /* 购物车图标 */
    .card-box{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: #FFFFFF;
      box-shadow: 0px 2.5px 5px 1px rgba(93,131,167,0.1);
      position: relative;
      .car-icon{
        position: relative;
        top: 5px;
        width: 33px;
      }
    }
    /*  合计 */ 
    .price-box{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 5px 0;
      margin-left: 18px;
      font-family: PingFangMedium;
      .total{
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        i{
          font-size: 10px;
          font-style: normal;
        }
        span{
          color: #EC6C01;
          font-family: PingFangMedium;
        }
      }
      .discount{
        font-size: 10px;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
        i{
          font-style: normal;
          font-family: PingFangMedium;
        }
        .p1{
          color: #999999;
          margin-right: 8px;
        }
        .p2{
          display: inline-block;
          text-align: center;
          border-radius: 10px;
          width: 54.5px;
          height: 19.5px;
          line-height: 19.5px;
          background: #F4F5F7;
        }
      }
    }
    /* 结算按钮 */
    .submit-btn{
      display: inline-block;
      height: 40px;
      border-radius: 20px;
      position: relative;
      left: 1px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      width: 85px;
      background: #15B3DD;
    }
    .submit-btn-dis{
      opacity: 0.5;
    }
  }
  // 当购物车添加商品后
  .shopping-hover{
    height: 50px;
    z-index: 9000;
    background: #FFFFFF;
    .card-box{
      box-shadow: none;
      .num{
        position: absolute;
        font-style: normal;
        text-align: center;
        // width: 14px;
        // height: 14px;
        min-width: 14px;
        padding: 0 3px;
        border-radius: 999px;
        top: 10px;
        right: 4px;
        background: #EC6C01;
        font-size: 10px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 15px;
      }
    }
  }

  .shopItem{
    margin: 15px 15px 0;
    .nomessageButton{
      width: 100px;
      line-height: 30px;
      border: 1px solid #15B3DD;
      text-align: center;
      border-radius: 15px;
      color: #15B3DD;
      margin: 0 auto;
      margin-top: 20px;
    }
    /deep/.van-checkbox{
      width: 15px;
      height: 15px;
      .van-checkbox__icon{
        font-size: 15px !important;
      }
      .img-icon{
        width: 15px;
        height: 15px;
      }
    }
    /deep/.van-icon{
      border-color: #15B7DD;
    }
    .car-item{
      display: flex;
      align-items: center;
      .shop{
        flex: 1;
        display: flex;
        margin-left: 14px;
        background: #FFFFFF;
        box-shadow: 0px 3px 5px 0px rgba(93,131,167,0.05);
        border-radius: 5px;
        padding: 12px;
      }
      .car-img{
        width: 70px;
        height: 70px;
        border-radius: 5px;
        /deep/.van-image__img, .van-image__error, .van-image__loading {
          border-radius: 5px;
        }
      }
      .car-right{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        height: 76px;
        margin-left: 14px;
        .openTime{
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #666666;
          line-height: 15px;
        }
        .timeLable {
          margin-top: 4px;
        }
        .car-title{
          // height: 30px;
          min-height: 30px;
          width: 80%;
          font-size: 13px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          margin-top: -1px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .car-foo{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 4px;
          .offShelf{
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            color: #999999;
            font-family: PingFangMedium;
          }
          .but{
            font-size: 12px;
            font-weight: 500;
            color: rgb(21, 183, 221);
            line-height: 14px;
            font-family: PingFangMedium;
            padding: 5px 10px;
            border: 1px solid rgb(21, 183, 221);
            border-radius: 12px;
          }
          .price{
            // margin-top: 4px;
            font-size: 10px;
            font-weight: 500;
            color: #EC6C01;
            line-height: 14px;
            font-family: PingFangMedium;
            .present{
              font-size: 14px;
              // font-weight: bold;
            }
            .free{
              font-size: 14px;
            }
            .original{
              margin-left: 8px;
              font-size: 12px;
              color: #999999;
              font-weight: 400;
              text-decoration: line-through;
              font-family: PingFangMedium;
            }
          }
          /deep/.van-stepper--round .van-stepper__input {
            height: 18px !important;
          }
          /deep/ .van-stepper--round .van-stepper__minus{
            width:16px !important;
            height: 16px !important;
            color: #fff;
            background-color: rgb(21, 183, 221);
            border: 1px solid rgb(21, 183, 221);
          }
          /deep/ .van-stepper--round .van-stepper__plus{
            width:16px !important;
            height: 16px !important;
            color: #fff;
            background-color: rgb(21, 183, 221);
          }
        }
      }
    }
    .car-item:last-child{
      border-bottom: none;
    }
    .shopItem:last-child{
      padding-bottom: 14px;
    }
  }
  .windowCoupons{
		background-color: #fff;
		border-radius: 24px 24px 0 0;
		padding: 0 5px;
		.navBar{
      padding: 0 13px;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 18px 0;
			.center{
				font-size: 18px;
				line-height: 25px;
				color: #333;
			}
			.right{
				position: absolute;
				right: 8px;
			}
		}
	}
	.coupon{
    padding: 0 13px;
    padding-bottom: 25px;
    overflow-y: scroll;
    height: 320px;
		.couponItem{
			// background: rgba(249, 0, 0, 0.03);
			border-radius: 6px;
			// border: 1px solid #F93C00;
			display: flex;    
			margin-bottom: 30.5px;
			&:last-child{
				margin-bottom: 0;
			}
			.left{
				display: flex;
        width: 90px;
				flex-direction: column;
				text-align: center;
				align-items: center;
				padding: 15px 19px 15px 14px;
				// border-right: 1px dashed #F93C00;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 6px 0;
				background-size: calc(100% - 16px) 100%;
				background-repeat: no-repeat;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191104.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -13px;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191056.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				// .rightTop{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-bottom: 1px solid #EC6C01;
				// 	// border-top: 1px solid #F7F7F9;
				// 	border-top: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	top: -1px;
				// 	border-bottom-left-radius: 10px;
				// 	border-bottom-right-radius: 10px;
				// 	background-color: #fff;
				// }
				// .rightBottom{
				// 	width: 20px;
				// 	height: 10px;
				// 	border-left: 1px solid #EC6C01;
				// 	border-right: 1px solid #EC6C01;
				// 	border-top: 1px solid #EC6C01;
				// 	border-bottom: 0;
				// 	box-sizing: border-box;
				// 	position: absolute;
				// 	right: -11px;
				// 	bottom: -1px;
				// 	border-top-left-radius: 10px;
				// 	border-top-right-radius: 10px;
				// 	background-color: #fff;
				// }
				.price{
					font-size: 28px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 26px;
					span{
						font-size: 13px;
					}
				}
				.condition{
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 12px;
					white-space: nowrap;
					margin-top: 8px;
				}
				img{
					width: 60px;
					height: 60px;
					position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
				}
			}
			.right{
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 11px 13px 8px 19px;
				position: relative;
				background-image: url(../../assets/image/20220621-191114.png);
				background-position: 13px 0;
				background-size: calc(100% - 20px) 100%;
				background-repeat: no-repeat;
				&::after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
					background-image: url(../../assets/image/20220621-191109.png);
					background-position: top right;
					background-repeat: no-repeat;
					background-size: auto 100%;
				}
				.title{
					font-size: 14px;
					font-weight: 500;
					color: #F93C00;
					line-height: 16px;
				}
				.time{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 6px;
				}
				.type{
					font-size: 10px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 15px;
					margin-top: 5px;
				}
				.btn{
					padding: 1px 8px;
					background: rgba(249, 0, 0, 0.08);
					border-radius: 10px;
					border: 1px solid #F93C00;
					position: absolute;
					right: 13px;
					bottom: 10px;
					font-size: 12px;
					font-family: PingFangMedium;
					color: #F93C00;
					line-height: 17px;
				}
				img{
					width: 44px;
					height: 35px;
					position: absolute;
					right: 0;
					top: 0;
          z-index: 9;
				}
			}
		}
		.overdue{
			border-color: #F7F7F9;
			background-color: rgba(153, 153, 153, 0.08);
			span{
				color: #999999 !important;
			}
			.rightTop{
				border-color: #999999 !important;
				// border-top: 1px solid #F7F7F9 !important;
			}
			.rightBottom{
				border-color: #999999 !important;
				// border-bottom: 1px solid #F7F7F9 !important;
			}
			.left{
				border-color: #999999;
			}
			.btn{
				background: rgba(153, 153, 153, 0.16)!important;
				border: 1px solid RGBA(225, 225, 227, 1)!important;
			}
		}
	}
</style>