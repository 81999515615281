<template>
  <!-- 教育直播 -->
  <div class="container-box">
    <van-swipe-cell 
      :disabled="listType == '2'"
      v-for="(i, index) in listData" 
      :key="index">
      <div class="liveBroadcast">
        <div class="liveBroadcastborder" :class="{'liveBroadFirst':index==0}">
          <div class="left">
            <img class="image" :src="i.cover" />
            <div class="left-label">
              <img class="label-img" src="@/assets/image/live/pic_icon1.png" alt="">
              <span class="label-name">{{onStateType(i.status)}}</span>
            </div>
          </div>
          <div class="right">
            <div class="branch">
              <div class="left">
                <!-- <div class="state">{{onStateType(i.status)}}</div> -->
                <div class="name">{{i.title}}</div>
              </div>
            </div>
            <div class="time">{{i.liveDuration}}</div>
            <div class="price">
              <div class="price-box" v-if="i.couponPrice==0">
                <span class="box-free">免费</span>
                <span class="original" v-if="i.original==1">￥{{i.originalPrice}}</span>
              </div>
              <div class="price-box" v-else>
                <span>
                  <span>¥</span>
                  <span class="box-p">{{i.couponPrice}}.</span>
                  <span class="box-point">00</span>
                </span>
                <span class="original" v-if="i.original==1">￥{{i.originalPrice}}</span>
              </div>
              <!-- <div class="all-btn-small" style="width: 110rpx">{{i.subscribe==1 ? '已预约' : '预约'}}</div> -->
              <div class="all-btn-small" style="width: 110rpx">{{subscribeType(i.subscribe, i.couponPrice)}}</div>
            </div>
          </div>
        </div>
      </div>
      <template #right>
        <div class="all_cancel" @click="cancelOk(i.liveId, index)">取消收藏</div>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },  
    // 类型 1收藏 2搜索
    listType: {
      type: String,
      default: "1",
    },
  },
  // 数据对象
  data() {
    return {};
  },
  created() {
  },
  // 事件处理器
  methods: {
    // 开播状态
    onStateType(type) {
      if (type == 1) {
        return '未开播'
      } else if (type == 2) {
        return '已开播'
      } else if (type == 3) {
        return '等待回放'
      } else if (type == 4) {
        return '回放'
      }
    },
    // 预约状态
    subscribeType(subscribe, couponPrice) {
      // 已预约
      if (subscribe == 1) {
        if (couponPrice == 0) {
          return '已预约'
        } else {
          return '已购买'
        }
      } 
      // 未预约
      else {
        if (couponPrice == 0) {
          return '预约'
        } else {
          return '购买'
        }
      }
    },
    // 取消收藏
    cancelOk(id, index) {
      this.$emit('cancelOk', id, index)
    },
  },
  // 页面生命周期-页面加载
  onLoad() {},
  // 页面生命周期-页面显示
  onShow() {},
  // 页面生命周期-页面初次渲染完成
  onReady() {},
  // 页面生命周期-页面隐藏
  onHide() {},
  // 页面生命周期-页面卸载
  onUnload() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";
</style>
