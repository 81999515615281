<template>
	<div class="container-box" :style="{paddingTop: flag?'46px':'0'}">
    <!-- <van-nav-bar 
      title="资讯详情" 
      :fixed="true" 
      :placeholder="true" /> -->
		<informationList v-if="JSON.stringify(list)!=='{}'" :type="type" :data="list"></informationList>
			
    <!-- 分享顶部提示 -->
    <TopAlert @closeFlag="closeFlag" />
	</div>
</template>

<script>
  import informationList from '@/components/information-list/information-list.vue'
  import TopAlert from '@/components/share/topAlert.vue';
  export default {
    components: {
      TopAlert, 
      informationList
    },
    data() {
      return {
        list: {},
        flag: true,
        type: false, // false: 老版本  true: 版本
      }
    },
    beforeCreate() {
      document.querySelector('body').setAttribute('style', 'background: #ffffff;')
    },
    created() {
      this.type = !!this.$route.query.type
      // 咨询详情
      this.getDetail()
    },
    methods: {
      // 关闭顶部提示组件回调
      closeFlag() {
        this.flag = false;
      },
      // 资讯详情
      getDetail() {
        let id = this.$route.query.id
        let url = ''
        if(this.type) {
          url = '/hxclass-mobile/Article/detail/'+id
        }else{
          url = '/hxclass-mobile/news/app/details/'+id
        }
        this.$ajax({
          url
        }).then((res) => {
          if (res.code == 200 && res.success) {
            // console.log(res)
            this.list = res.data
            // 来源限制15字
            this.$set(this.list, 'source', this.list.source.substring(0, 10))
            // 处理预览人数、超过一万显示单位为万
            if (this.list.views >= 10000) {
              let views = (this.list.views/10000).toFixed(0)+'万'
              this.$set(this.list, 'views', views)
            }
            if (this.list.pageviews >= 10000) {
              let pageviews = (this.list.pageviews/10000).toFixed(0)+'万'
              this.$set(this.list, 'pageviews', pageviews)
            }
            // 处理年月日
            let createTime = this.list.createTime?.split(' ')[0]
            this.$set(this.list, 'createTime', createTime)
            let publishTime = this.list.publishTime?.split(' ')[0]
            this.$set(this.list, 'publishTime', publishTime)
            // info.replace( new RegExp('style="max-width:100%;"', 'g'), 'style="width:100%;"')
          }
        });
      },
    },
    // 生命周期-实例销毁离开后调用
    destroyed () { 
      document.querySelector('body').removeAttribute('style')
    },
  }
</script>

<style lang="less" scoped>
</style>
