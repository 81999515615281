<template>
  <!-- 模拟考试 -->
  <div class="container-box">
    <template v-if="listType == '1'">
      <van-swipe-cell
        :disabled="listType == '2'"
        v-for="(i, index) in listData"
        :key="index"
      >
        <div class="bookItem" @click="goDetail(i)">
          <div class="bookborder" :class="{ bookfirst: index == 0 }">
            <img :src="i.listPhoto" />
            <div class="itemMessage">
              <span class="exam all-font-ellipsis">{{ i.name }}</span>
              <!-- <span class="examdescribe">{{i.abstracts}}</span> -->
              <span class="examdescribe"
                >{{ i.isExam == 0 ? "无限" : i.examNum }}次答题次数</span
              >
              <span class="examprice" v-if="i.couponPrice">
                <i>￥</i>{{ i.couponPrice ? i.couponPrice.toFixed(2) : "" }}
                <span class="original" v-if="i.original == 1"
                  >￥{{ i.originalPrice.toFixed(2) }}</span
                >
              </span>
              <span v-else class="examprice" style="font-weight: 400"
                >免费</span
              >
            </div>
          </div>
        </div>
        <template #right>
          <div class="all_cancel" @click="cancelOk(i.configId, index)">
            取消收藏
          </div>
        </template>
      </van-swipe-cell>
    </template>

    <template v-if="listType == '2'">
      <div
        class="bookItem"
        v-for="(i, index) in listData"
        :key="index"
        @click="goDetail(i)"
      >
        <div class="bookborder" :class="{ bookfirst: index == 0 }">
          <img :src="i.picture ? i.picture : i.photo" />
          <div class="itemMessage">
            <span class="exam all-font-ellipsis" v-html="i.productName"></span>
            <span class="examdescribe"
              >{{ i.isExam == 0 ? "无限" : i.examNum }}次答题次数</span
            >
            <!-- <span class="examprice">￥{{i.couponPrice}}</span> -->
            <span class="pricebox">
              <span class="price">
                <span v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >￥</span
                >
                <span
                  class="present"
                  v-if="i.couponPrice != 0 && i.couponPrice != '0.00'"
                  >{{ i.couponPrice }}</span
                >
                <span class="free" v-else>免费</span>
                <span class="original" v-if="i.original"
                  >￥{{ i.originalPrice.toFixed(2) }}</span
                >
              </span>
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { jumpToShopDetail } from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {},
  props: {
    // 渲染数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 类型 1收藏 2搜索
    listType: {
      type: String,
      default: "1",
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    // 取消收藏
    cancelOk(id, index) {
      this.$emit("cancelOk", id, index);
    },
    goDetail(e) {
      jumpToShopDetail(4,e.configId || e.mockConfigId)
      // if (e.configId) {
      //   this.$router.push("/examination/examDetail?id=" + e.configId);
      // } else {
      //   this.$router.push("/examination/examDetail?id=" + e.mockConfigId);
      // }
    },
  },
  // 页面生命周期-页面加载
  onLoad() {},
  // 页面生命周期-页面显示
  onShow() {},
  // 页面生命周期-页面初次渲染完成
  onReady() {},
  // 页面生命周期-页面隐藏
  onHide() {},
  // 页面生命周期-页面卸载
  onUnload() {},
};
</script>

<style lang="less" scoped>
@import "./searchSeo.less";
</style>
