export default [
  {
    path: 'page1', // 活动页1
    component: () => import('../views/activity/page1.vue')
  },
  {
    path: 'iframe', // 外联
    component: () => import('../views/activity/iframe.vue')
  },
  {
    path: 'couponActivity', // 外链
    component: () => import('../views/activity/couponActivity.vue')
  },
  {
    path: 'teacherActive', // 教师活动
    component: () => import('../views/activity/teacherActive.vue')
  },
  {
    path: 'teacherShare', // 教师活动分享
    component: () => import('../views/activity/teacherShare.vue')
  },
  {
    path: 'teacherGiftPack', // 教师活动礼包
    component: () => import('../views/activity/teacherGiftPack.vue')
  }
]