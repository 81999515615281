<template>
  <div class="active-wrap">
    <div 
      class="back" 
      @click="onBack()">
      <img  src="@/assets/image/blackLeft.png" />
    </div>
    <div 
      class="share" 
      @click="shareFile()">
      <img src="@/assets/image/activity/20240826-113341.png" />
    </div>
		<div class="banner-top">
			<img class="img-bg" src="@/assets/image/activity/20240822-094459.jpg">
			<img class="img-font1" src="@/assets/image/activity/20240823-145339.png">
			<img class="img-font2" src="@/assets/image/activity/20240823-145336.png">
		</div>
		<div class="module-item">
			<img class="img-bg" src="@/assets/image/activity/20240821-173405.png">
		</div>
		<div class="module-item">
			<img class="img-bg" src="@/assets/image/activity/20240821-173747.png">
			<div @click="goShopping" class="img-btn" style="width: 70%;bottom:-1%">
        <img  src="@/assets/image/activity/20240821-173847.png">
      </div>
			<img class="img-hand" src="@/assets/image/activity/20240821-174716.png">
			<!-- 撒红包特效 -->
			<div class="red-packets">
				<img class="red-packet pake1" src="@/assets/image/activity/20240821-180313.png">
				<img class="red-packet pake2" src="@/assets/image/activity/20240821-180316.png">
			</div>
		</div>
		<div class="module-item">
			<img class="img-bg" src="@/assets/image/activity/20240821-183803.png">
			<img class="img-cheap-price cheap1" src="@/assets/image/activity/20240821-183953.png">
			<img class="img-cheap-price cheap2" src="@/assets/image/activity/20240821-183953.png">
      <div class="img-btn img-price1" @click="goDetail(1, 96)">
			  <img src="@/assets/image/activity/20240821-184631.png">
      </div>
      <div class="img-btn img-price2" @click="goDetail(1, 90)">
        <img src="@/assets/image/activity/20240821-184634.png">
      </div>
		</div>
		<div class="module-item">
			<img class="img-bg" src="@/assets/image/activity/20240821-185225.png">
			<img class="img-cheap-price cheap3" src="@/assets/image/activity/20240821-185232.png">
			<img class="img-cheap-price cheap4" src="@/assets/image/activity/20240821-185229.png">
      <div class="img-btn img-price3" @click="goDetail(3, 11)">
			  <img src="@/assets/image/activity/20240821-185324.png">
      </div>
      <div  class="img-btn img-price4" @click="goDetail(3, 238)">
			  <img src="@/assets/image/activity/20240821-185320.png">
      </div>
		</div>
		<div class="module-item">
			<img class="img-bg" src="@/assets/image/activity/20240821-185842.png">
		</div>
		<div class="foot">
			<div class="waves">
				<i class="public-i first"></i>
				<i class="public-i second"></i>
			</div>
		</div>
	</div>
</template>

<script>
import { getAppVersion, jumpToShopDetail, FunSendShare, onStartActivity } from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice  } from "@/unit/fun.js";
const DeviceType = FunJudgDevice()
import config from '@/unit/config.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      config,
			AppVersion: getAppVersion(),  //获取版本号
    };
  },
  // 事件处理器
  methods: {
    // 分享
		shareFile() {
      const userId = this.$store.state.userInfo.userId
      // 不同设备版本限制
      if((DeviceType == 'ios' && window.webkit && this.AppVersion.code > 77) || (DeviceType == 'android' && this.AppVersion.code > 107)){
        let json = {
          WebUrl: this.config.shareWxUrl + '/pages/active/teacherActive?source=' + (userId ? userId : 0),
          title: '教师节专属福利，只等你来领！',
          description: '活动期间购课，立省百元优惠，快来参与吧~',
          imageUrl: 'https://cos.hxclass.cn/test/ueditor/banner/db89474b4ebc4da1bd67d83f9054b15d.jpg'
        }
        FunSendShare(JSON.stringify(json));
      } else{
        this.$Toast('请更新app版本')
      }
		},
    // 跳转商场
    goShopping(){
      onStartActivity(3)
    },
    // 跳转详情页
    goDetail(type, id) {
      // type 1.课程详情  2.教具详情
      jumpToShopDetail(type, id);
    },
    onBack(){
      if(DeviceType == 'android'){
        this.$onReturn(true)
      } else {
        this.$onReturn(this.$route.query.fromMenu == '1')
      }
    },
  },
};
</script>

<style lang="less" scoped>
.active-wrap {
  background: #ffd3b6;

  .back{
    position: absolute;
    left: 24px;
    top: 60px;
    z-index: 9;
    img{
      width: 26px;
    }
  }

  .share{
    position: absolute;
    right: 4%;
    top: 60px;
    z-index: 9;
    width: 24%;
    img{
      width: 100%;
    }
  }
  
  .banner-top {
    position: relative;
    .img-bg {
      width: 100%;
      height: auto;
    }
    .img-font1{
      position: absolute;
      top: 23%;
      left: -100%;
      width: 82%;
      animation: banner1ing ease-in-out 1s alternate forwards ;
    }
    @keyframes banner1ing{
      0% {
        left: -100%;
        top: 33%;
        opacity: 0;
      }
      100% {
        left: 5%;
        top: 23%;
        opacity: 1;
      }
    }
    .img-font2{
      position: absolute;
      top: 35%;
      left: 120%;
      width: 93%;
      animation: banner2ing ease-in-out 1s alternate forwards ;
    }
    @keyframes banner2ing{
      0% {
        left: 120%;
        top: 25%;
        opacity: 0;
      }
      100% {
        left: -1%;
        top: 35%;
        opacity: 1;
      }
    }
  }

  .module-item {
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0 21px 15px;

    .img-bg {
      width: 100%;
      height: auto;
    }

    .img-btn {
      position: absolute;
      bottom: 1%;
      z-index: 9;
      left: 50%;
      width: 60%;
      transform: translate(-50%, -50%);
      img{
        width: 100%;
      }
    }

    .img-hand {
      position: absolute;
      right: -3%;
      top: 18%;
      width: 22%;
      animation: swing 1s ease-in-out infinite;
    }

    @keyframes swing {
      0%,
      100% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(20deg);
        /* 调整角度来改变摇摆幅度 */
      }
    }

    .red-packets {
      position: absolute;
      top: 36%;
      right: 0;
      width: 20%;
      height: 18%;
    }

    .red-packet {
      position: absolute;
      width: 40%;
      height: auto;
    }

    .pake1 {
      top: 0;
      right: 60%;
      animation: fall 2s linear infinite;
    }

    .pake2 {
      right: 15%;
      animation: fall 3s linear infinite;
    }

    @keyframes fall {
      0% {
        transform: translateY(0) scale(1.2);
        opacity: 1;
      }

      100% {
        transform: translateY(50px) scale(0.7);
        opacity: 0;
      }
    }

    .img-cheap-price {
      position: absolute;
      right: calc(11% - 2px);
      width: 23%;
      height: auto;
      animation: shakeAndZoom 2s linear forwards infinite;
    }

    .cheap1 {
      top: calc(27% - 1px);
    }

    .cheap2 {
      top: calc(65% - 1px);
    }

    .cheap3 {
      top: calc(23%);
    }

    .cheap4 {
      top: calc(67% - 1px);
    }

    // 晃动特效
    @keyframes shakeAndZoom {
      0% {
        transform: scale(0.9);
      }

      25% {
        transform: scale(1.1);
      }

      50% {
        transform: scale(0.9);
      }

      75% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(0.9);
      }
    }

    .img-price1 {
      bottom: calc(42% + 1px);
    }

    .img-price2 {
      bottom: calc(5% - 3px);
    }

    .img-price3 {
      bottom: calc(48% + 3px);
    }

    .img-price4 {
      bottom: calc(5% - 2px);
    }
  }

  .foot {
    height: 30px;
    width: 100%;
    margin-top: 30px;
    .waves {
      height: 100%;
      overflow: hidden;
      position: relative;

      .public-i {
        display: block;
        height: 100%;
        position: absolute;
        width: 1000px;
        z-index: 3;
        left: 0;
      }

      .first {
        background: url(~@/assets/image/activity/20240821-190210.png) top/cover;
        animation: freames1 10s cubic-bezier(0.14, 1, 0.87, 0.13) infinite
          alternate;
      }

      .second {
        background: url(~@/assets/image/activity/20240821-190213.png) top/cover;
        animation: freames2 10s cubic-bezier(0.14, 1, 0.87, 0.13) infinite
          alternate;
        left: auto;
        right: 0;
      }

      @keyframes freames1 {
        0% {
          transform: translateZ(0);
        }

        100% {
          transform: translate3d(-50%, 0, 0);
        }
      }

      @keyframes freames2 {
        0% {
          transform: translateZ(0);
        }

        100% {
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }
}
</style>
