<template>
  <!-- 帮助与反馈 -->
  <div class="feedback">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="帮助与反馈"
      :fixed="true"
      :placeholder="true"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
    />

    <!-- 背景图 -->
    <div class="box1">
      <img class="box_img" src="../../assets/image/my/feedback_bg.png" />
    </div>

    <!-- 列表 -->
    <div class="box2">
      <router-link 
        :to="{
          name: 'problemList',
          params: {isLoad: true}
        }" 
        class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="../../assets/image/my/feedback_icon1.png"
          />
          <div class="main_txt">常见问题</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <router-link to="/mine/feedbackSubmit" class="box_item">
        <div class="item_main">
          <img
            class="main_icon"
            src="../../assets/image/my/feedback_icon2.png"
          />
          <div class="main_txt">意见反馈</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </router-link>
      <div class="box_item" @click="CallCustomer()">
        <div class="item_main">
          <img
            class="main_icon"
            src="../../assets/image/my/feedback_icon3.png"
          />
          <div class="main_txt">在线客服</div>
        </div>
        <van-icon name="arrow" color="#CCCCCC" size="20" />
      </div>
      <a class="box_item" @click="FunIosPhone(4008009002)">
        <div class="item_main">
          <img
            class="main_icon"
            src="../../assets/image/my/feedback_icon4.png"
          />
          <div class="main_txt">客服热线</div>
        </div>
        <div class="item_info">
          <div class="info_txt">400-800-9002</div>
          <van-icon name="arrow" color="#CCCCCC" size="20" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { FunPhone } from '@/assets/js/NativeWebKit.js'
import {CallCustomer} from '@/assets/js/NativeWebKit.js';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    CallCustomer,
    // 唤起原生打电话
    FunIosPhone(e){
      FunPhone({phone:e})
    }
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.feedback {
  min-height: 100vh;
  background: #f7f7f9;
  padding-bottom: 60px;
}

// 背景图
.box1 {
  border-radius: 5px;
  overflow: hidden;
  .box_img {
    width: 100%;
  }
}

// 列表
.box2 {
  padding: 2px 18px;
  .box_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 12px;
    padding: 16px 12px;
    .item_main {
      display: flex;
      align-items: center;
      .main_icon {
        width: 20px;
      }
      .main_txt {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
      }
    }
    .item_info {
      display: flex;
      align-items: center;
      .info_txt {
        font-size: 12px;
        font-weight: 500;
        color: #15b7dd;
        margin-right: 3px;
      }
    }
  }
}
</style>
