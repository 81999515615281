/**
 * 原生工具包，汇总js调取原生app的方法集
 * 1.唤起原生拨打电话功能
 * 2.跳转原生视频播放页面
 * 3.原生视频播放页面跳转h5页面
 * 4.调用原生，判断当前登录环境是否支持
 * 5.调用原生，进行第三方登录
 */

import { Toast } from 'vant'
import { FunJudgDevice } from '@/unit/fun.js'
import $store from '@/store'
import $router from '@/router'

const type = FunJudgDevice()

/**
 * 唤起原生拨打电话功能
 * @param {String} phone // 需要拨打的手机号码
 */
export function FunPhone({phone}){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.call.postMessage(phone);
  }else if(type == 'android'){ // android端
    // console.log('唤起原生拨打电话功能：Android.openCall('+String(phone)+')')
    Android.openCall(String(phone));
  }else{ // 其他端

  }
}

/**
 * 跳转原生视频播放页面
 * @param {String} Object // 传递给APP的JSON串参数
 * isFast 是否能快进 1.能快进，0.不能快进
 * currPcfg 云点播的播放器签名
 * currStudyFileId 当前学习的云点播fileid
 * currStudySeconds 当前学习的视频历史进度秒数
 * currStudySectionId 当前学习的章节id
 * SectionTest 是否试看 1/0
 * isHaveSelfTest 是否有小节自测 1/0
 * isNextSectionId 是否有下一个章节 1/0
 * freeTrialSeconds 试看时长
 * wwanplay 是否运行移动网络播放 1/0
 * title 章节名称
 */
export function FunPlay(obj){
  if(type == 'ios' && window.webkit){
    // ios端
    obj.wwanplay = $store.state.userInfo.enableMobileNetwork ? 1 : 0
    // console.log(obj)
    window.webkit.messageHandlers.play.postMessage(JSON.stringify(obj));
  }else if(type == 'android'){
    // android端
    Android.openNewVideoPage(JSON.stringify(obj));
  }else{
    // 其他端
  }
}

/**
 * 原生视频播放页面跳转h5页面
 * @param {String} url // 离开视频播放页后需要打开的h5页面链接，可以携带参数
 */
export function FunLeavePlay(url){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.open.postMessage(url);
  }else if(type == 'android'){
    // android端
    Android.openNewPage(url)
  }else{
    // 其他端
  }
}

/**
 * 购物车弹窗关闭时回调原生
 * 1 关闭  2 打开优惠券弹框
 */
export function FunClosePopup(e){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.FunClosePopup.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.FunClosePopup(e)
  }else{
    // 其他端
  }
}

/**
 * 延期窗关闭时回调原生
 */
export function FunCloseDelay(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.FunCloseDelay.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.FunCloseDelay()
  }else{
    // 其他端
  }
}


/**
 * 退出登录、清除缓存
 */
export function FunLogOut(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onLogOut.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.onLogOut()
  }else{
    // 其他端
  }
}


/**
 * 购物车弹窗结算
 */
export function FunConfirmOrder(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.FunConfirmOrder.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.FunConfirmOrder()
  }else{
    // 其他端
  }
}


/**
 * h5页面返回原生页面
 */
export function FunClosePage(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onBackPage.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.onBackPage()
  }else{
    // 其他端
  }
}

/**
 * 电子书原生关闭控制栏
 */
export function FunCloseControl(show){
  console.log('这是什么状态' + show)
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onCloseControl.postMessage(show);
  }else if(type == 'android'){
    // android端
    Android.onCloseControl(show)
  }else{
    // 其他端
  }
}
/**
 * 电子书阅读进度条进度
 */
export function FunGetProgress(progress){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onGetProgress.postMessage(progress);
  }else if(type == 'android'){
    // android端
    Android.onGetProgress(progress)
  }else{
    // 其他端
  }
}
/**
 * 电子书原生加载窗口
 */
export function FunBookShow(show){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onBookShow.postMessage(show);
  }else if(type == 'android'){
    // android端
    Android.onBookShow(show)
  }else{
    // 其他端
  }
}

/**
 * 电子书原生获取目录列表
 */
export function FunCatalogueList(list){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onGetCatalogue.postMessage(list);
  }else if(type == 'android'){
    // android端
    Android.onGetCatalogue(list)
  }else{
    // 其他端
  }
}
// /**
//  * 电子书原生获取总页数
//  */
// export function FunBookTotalPages(total){
//   if(type == 'ios' && window.webkit){
//     // ios端
//     window.webkit.messageHandlers.onCloseControl.postMessage('');
//   }else if(type == 'android'){
//     // android端
//     Android.onGetTotalPages(total)
//   }else{
//     // 其他端
//   }
// }
/**
 * 电子书原生获取笔记内容
 */
export function FunGetNote(content){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onGetNote.postMessage(content);
  }else if(type == 'android'){
    // android端
    Android.onGetNote(content)
  }else{
    // 其他端
  }
}


/**
 * 刷新原生页面
 */
 export function FunRefreshPage(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onRefreshPage.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.onRefreshPage()
  }else{
    // 其他端
  }
}

/**
 * 跳到原生首页
 */
 export function FunGoHome(index){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onGoMain.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.onGoMain(index)
  }else{
    // 其他端
  }
}

/**
 * 原生返回
 */
export function goBack(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.goBack.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.goBack()
  }else{
    // 其他端
  }
}


/**
 * 从h5页面历史记录返回原生视频播放页面
 */
export function FunReturnPlay(e){
  if(type == 'ios' && window.webkit){
    // ios端
    try {
      window.webkit.messageHandlers.backtoplay.postMessage('');
    } catch (error) {
      console.error(error)
    }
  }else if(type == 'android'){
    // android端
    if(e) {
      // 重新学习
      return Android.goAgainBack()
    }
    // 继续学习
    Android.goBack()
  }else{
    // 其他端
  }
}

/**
 * 调用原生，判断当前登录环境是否支持
 * @param {Number} loginType // 登录方式1.微信登录，2.apple登录，3.一键登录 
 * @returns // 返回登录环境的标识1.环境支持，2.不支持
 */
export function FunLoginEnvironment({loginType}){
  let text = 0
  if(type == 'ios' && window.webkit){
    // ios端
    if(loginType == 1){ // 微信登录
      text = window.prompt("canwxlogin")
    }else if(loginType == 2){ // apple登录
      text = window.prompt("canapplelogin")
    }else if(loginType == 3){ // 一键登录
      text = window.prompt("canoneclick")
      // text = window.returnLoginOneState()
    }
  }else if(type == 'android'){
    // android端
    if(loginType == 1){ // 微信登录
      text = 1
    }else if(loginType == 2){ // apple登录
      text = 2
    }else if(loginType == 3){ // 一键登录
      text = 1
      // text = window.returnLoginOneState()
    }
  }else{
    // 其他端
  }
  return text
}

/**
 * 调用原生，进行第三方登录
 * @param {Number} loginType // 登录方式1.微信登录，2.apple登录，3.一键登录 
 */
export function FunLogin({loginType}){
  // console.log('调用第三方'+loginType,type)
  if(type == 'ios' && window.webkit){
    // ios端
    if(loginType == 1){ // 微信登录
      window.webkit.messageHandlers.wxlogin.postMessage('');
    }else if(loginType == 2){ // apple登录
      window.webkit.messageHandlers.applelogin.postMessage('')
    }else if(loginType == 3){ // 一键登录
      window.webkit.messageHandlers.oneclicklogin.postMessage('')
    }
  }else if(type == 'android'){
    // android端
    if(loginType == 1){ // 微信登录
      // console.log('调用微信登录：Android.openWXLogin()')
      Android.openWXLogin();
    }else if(loginType == 3){ // 一键登录
      // console.log('调用一键登录：Android.openNVSlogin()')
      Android.openNVSlogin();
    }
  }else{
    // 其他端
  }
}

/**
 * h5调用原生方法进入课程下一章节
 */
export function FunNextVideo(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.backtoplaynext.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.selfTestFinish();
  }else{
    // 其他端
  }
}

/**
 * h5调用原生方法进入课程下一章节(注：目录无序播放课程)
 */
export function FunBackNextVideo(obj){
  if(type == 'ios' && window.webkit){
    // ios端
    obj.wwanplay = $store.state.userInfo.enableMobileNetwork
    window.webkit.messageHandlers.onBackNextVideo.postMessage(JSON.stringify(obj));
  }else if(type == 'android'){
    // android端
    Android.onBackNextVideo(JSON.stringify(obj));
  }else{
    // 其他端
  }
}

/**
 * h5调用原生方法跳转自测页面
 */
export function FunSelfTest(courseId, sectionId,sectionName, isOrder, isType){
  if(type == 'ios' && window.webkit){
    // ios端
  }else if(type == 'android'){
    // android端
    if(isType == 1){
      Android.onGoSelfTest(courseId, sectionId,sectionName, isOrder);
    }else{
      Android.onGoSelfTest(courseId, sectionId,sectionName);
    }
  }else{
    // 其他端
  }
}


/**
 * h5支付时，通知原生支付方式
 */
export function FunPayType(payIndex){
  if(type == 'ios' && window.webkit){
    // ios端
  }else if(type == 'android'){
    // android端
    Android.getPayType(payIndex);
  }else{
    // 其他端
  }
}


/**
 * 原生消息提示框 - 不带操作按钮
 * @param {String} msg 提示消息文案
 */
export function toast(msg){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.toast.postMessage(String(msg));
  }else if(type == 'android'){
    // android端
    Android.toast(msg)
  }else{
    // 其他端
    Toast(msg)
  }
}

/**
 * 强制退出app
 */
export function exitApp(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.exitApp.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.exitApp()
  }else{
    // 其他端
  }
}

/**
 * 调用客服功能
 */
 export function CallCustomer(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.kefu.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.kefu()
  }else{
    // 其他端
  }
}

/**
 * 获取当前app版本信息
 * @returns 返回版本Object
 */
export function getAppVersion(){
  if(type == 'ios' && window.webkit){
    const getappversion = window.prompt("getappversion")
    const getbuildversion = window.prompt("getbuildversion")
    // ios端
    return {
      name: getappversion,
      code: getbuildversion,
      phoneSystem: 2
    }
  }else if(type == 'android'){
    // android端
    return {
      name: Android.getAndroidAppVersionName(),
      code: Android.getAndroidAppVersionCode(),
      phoneSystem: 1
    }
  }else{
    // 其他端
    return {
      name: null,
      code: null,
      phoneSystem: 0
    }
  }
}

/**
 * 苹果支付回调信息
 * @returns 返回支付成功/失败
 */
export function FunApplePay(jsonStr){
  if(type == 'ios' && window.webkit){
    // ios端
    return window.prompt("FunApplePay",jsonStr)
  }else if(type == 'android'){
    // android端
  }else{
    // 其他端
  }
}


/**
 * 给原生传递用户登录信息
 * @param {String} msg 提示消息文案
 */
export function FunSetUserInfo(jsonUserinfo){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.FunSetUserInfo.postMessage(jsonUserinfo);
  }else if(type == 'android'){
    // android端
    Android.FunSetUserInfo(jsonUserinfo)
  }else{
    // 其他端
  }
}



/**
 * 下载最新版本app
 * @param {String} url 下载或跳转地址
 */
export function downloadApp(data){
  if(type == 'ios' && window.webkit){
    // ios端
    // window.location.href = data.iosLink
    window.webkit.messageHandlers.downloadApp.postMessage(String(data.iosLink));
  }else if(type == 'android'){
    // android端
    Android.downloadApp(data.androidLink)
  }else{
    // 其他端
  }
}


/**
 * 页面分享
 * @param {String} url 分享json字符串
 */
export function FunSendShare(json){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onSendShare.postMessage(json);
  }else if(type == 'android'){
    // android端
    Android.onSendShare(json)
  }else{
    // 其他端
  }
}


/**
 * 清除缓存
 */
export function clearCache(){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.clearCache.postMessage('');
  }else if(type == 'android'){ // android端
    Android.clearCache();
  }else{ // 其他端
    localStorage.clear();
  }
}


/**
 * 清除用户token
 */
export function FunClearToken(){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.clearToken.postMessage('');
  }else if(type == 'android'){ // android端
    Android.clearToken();
  }else{ // 其他端
    localStorage.clear();
  }
}


/**
 * 下载图片
 * @param {url} 图片地址
 */
 export function downLoadImg(url){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.downloadimage.postMessage(url);
  }else if(type == 'android'){ // android端
    Android.downloadImage(url);
  }else{ // 其他端
    
  }
}

/**
 * h5调用原生方法获取当前视频播放的章节id
 */
export function FunGetSectionId(){
  if(type == 'ios' && window.webkit){
    // ios端
    return window.prompt("getSectionId")
  }else if(type == 'android'){
    // android端
    return Android.getSectionId();
  }else{
    // 其他端
  }
}

/**
 * 拍照
 * @param {isCrop} 是否裁剪 1裁剪 0不裁剪
 * @param {square} 是否可随意拖动 1方形不能随意拖动 0可随意拖动
 */
 export function onTakePhoto(isCrop, square){
  if(type == 'ios' && window.webkit){ // ios端
    if (!!isCrop) {
      isCrop = isCrop.toString()
    } else {
      isCrop = ''
    }
    window.webkit.messageHandlers.camera.postMessage(isCrop);
  }else if(type == 'android'){ // android端
    // console.log('android拍照')
    Android.takePhoto(isCrop, square);
  }else{ // 其他端
    
  }
}

/**
 * 选择图片
 * @param {isCrop} 是否裁剪 1裁剪 0不裁剪
 * @param {square} 是否可随意拖动 1方形不能随意拖动 0可随意拖动
 */
 export function onChoosePhoto(isCrop, square){
  if(type == 'ios' && window.webkit){ // ios端
    isCrop = isCrop.toString()
    window.webkit.messageHandlers.photo.postMessage(isCrop);
  }else if(type == 'android'){ // android端
    // console.log('android选择照片')
    Android.choosePhoto(isCrop, square);
  }else{ // 其他端
    
  }
}


/**
 * 我的页面购物车跳转详情
 * @param {url} 页面地址
 */
 export function goShopDetail(url){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.goShopDetail.postMessage(url);
  }else if(type == 'android'){ // android端
    // console.log('android选择照片')
    Android.goShopDetail(url);
  }else{ // 其他端
    
  }
}

/**
 * h5返回原生我的页面
 */
 export function popToMineTab(){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.popToMineTab.postMessage('');
  }else if(type == 'android'){ // android端
    Android.popToMineTab();
  }else{ // 其他端
    
  }
}

/**
 * 免费课程课程延期后通知原生刷新列表并关闭弹框
 */
 export function studyListRefresh(){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.studyListRefresh.postMessage('');
  }else if(type == 'android'){ // android端
    Android.studyListRefresh();
  }else{ // 其他端
    
  }
}

/**
 * 改变个性化消息推送 1：允许   2：不允许
 */
 export function Personalized(e){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.Personalized.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.Personalized(e);
  }else{ // 其他端
    
  }
}

/**
 * 购物车数据变化
 */
 export function shopCarChanged(){
  if(type == 'ios' && window.webkit){ // ios端
    // 版本号控制
    const AppVersion = getAppVersion();
    // 原生跳转详情页
    if(true || AppVersion.code > 94) {
      // ios端
      window.webkit.messageHandlers.shopCarChanged.postMessage('');
    }
  }else if(type == 'android'){ // android端
    Android.shopCarChanged();
  }else{ // 其他端
    
  }
}

/**
 * 原生刷新我的页面优惠券数量
 */
 export function CouponReceive(){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.CouponReceive.postMessage('');
  }else if(type == 'android'){ // android端
    Android.CouponReceive();
  }else{ // 其他端
    
  }
}

/**
 * 我的页面查看可用商品
 */
 export function ViewAvailableProducts(e){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.ViewAvailableProducts.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.ViewAvailableProducts(e);
  }else{ // 其他端
    
  }
}

/**
 * 搜索页面点击历史原生交互
 */
 export function onGetSearchKey(e){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.onGetSearchKey.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.onGetSearchKey(e);
  }else{ // 其他端
    
  }
}

/**
 * 搜索页面跳转详情原生交互
 */
 export function hiddenSearchInput(e){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.hiddenSearchInput.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.hiddenSearchInput(e);
  }else{ // 其他端
    
  }
}



/**
 * 原生跳到登录页
 */
 export function goLoginPage(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.goLoginPage.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.goLoginPage()
  }else{
    // 其他端
  }
}

/**
 * 原生跳到学习页
 */
 export function onGoStudy(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onGoStudy.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.onGoStudy()
  }else{
    // 其他端
  }
}

/**
 * 原生跳到我的考试页
 */
export function onGoMyExam(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.goBackOriginal.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.goBackOriginal()
  }else{
    // 其他端
  }
}

/**
 * 流量播放视频交互
 */
 export function onMobileView(e){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onMobileView.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.onMobileView(e)
  }else{
    // 其他端
  }
}

/**
 * 登录返回关闭一键登录方法
 */
 export function onInitOnLogin(){
  if(type == 'ios' && window.webkit){
    // ios端
    // window.webkit.messageHandlers.onInitOnLogin.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.onInitOnLogin()
  }else{
    // 其他端
  }
}

/**
 * 跳转原生身份认证页
 */
 export function goIdentityAuthentication(e){
  if(type == 'ios' && window.webkit){ // ios端
    window.webkit.messageHandlers.goIdentityAuthentication.postMessage(e);
  }else if(type == 'android'){ // android端
    // android端
    Android.goIdentityAuthentication(e)
  }else{ // 其他端
    
  }
}

/**
 * 通知原生刷新收藏列表
 */
 export function refreshCollection(){
  if(type == 'ios' && window.webkit){ // ios端
    // window.webkit.messageHandlers.refreshCollection.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.refreshCollection()
  }else{ // 其他端
    
  }
}

/**
 * 通知原生刷新考试报名
 */
export function refreshExam(){
  if(type == 'ios' && window.webkit){ // ios端
    // window.webkit.messageHandlers.refreshCollection.postMessage(e);
  }else if(type == 'android'){ // android端
    Android.refreshExam()
  }else{ // 其他端
    
  }
}

/**
 * 文件上传
 */
export function uploadFile(e){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.uploadFile.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.uploadFile(e)
  }else{
    // 其他端
  }
}

/**
 * 获取视口高度
 */
export function getIOSClientHeight(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.getClientHeight.postMessage('');
  }else if(type == 'android'){
   
  }else{
    // 其他端
  }
}

/**
 * 原生跳转商品详情页
 */
export function jumpToShopDetail(productType,id,idType){
  if(type == 'ios' && window.webkit){
    // 版本号控制
    const AppVersion = getAppVersion();
    // 原生跳转详情页
    if(true || AppVersion.code > 93) {
      let str = '';
      if(idType){
        str = productType+','+id+','+idType
      }else{
        str = productType+','+id
      }
      // ios端
      window.webkit.messageHandlers.jumpToShopDetail.postMessage(str);
    }else{
      // h5跳转详情页
      // 课程
      if(productType == 1) {
        $router.push({ path: "/curriculum/course", query: { id: id } })
      }else if(productType == 2 || productType == 3) {
        $router.push({ path: "/curriculum/BookDdetails", query: { id: id } })
      }else if(productType == 4) {
        if(idType) {
          $router.push("/examination/examDetail?productId=" + id);
        }else{
          $router.push("/examination/examDetail?id=" + id);
        }
      }
    }
  }else if(type == 'android'){
    // 版本号控制
    const AppVersion = getAppVersion();
    // 原生跳转详情页
    if(true || AppVersion.code > 116) {
      // android端
      Android.jumpToShopDetail(productType,id,idType)
    }else{
      // h5跳转详情页
      // 课程
      if(productType == 1) {
        $router.push({ path: "/curriculum/course", query: { id: id } })
      }else if(productType == 2 || productType == 3) {
        $router.push({ path: "/curriculum/BookDdetails", query: { id: id } })
      }else if(productType == 4) {
        if(idType) {
          $router.push("/examination/examDetail?productId=" + id);
        }else{
          $router.push("/examination/examDetail?id=" + id);
        }
      }
    }
  }else{
    // 其他端
  }
}

/**
 * 刷新更换班级
 */
export function refreshChangeClass(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.refreshChangeClass.postMessage('');
  }else if(type == 'android'){
   // android端
   Android.refreshChangeClass()
  }else{
    // 其他端
  }
}

/**
 * 获取原生存储信息（登录信息，订单支付成功所需信息）
 * @param name
 */
export function onGetStorage(name){
  if(type == 'ios' && window.webkit){
    // ios端
    return null;
  }else if(type == 'android'){
    // android端
    return Android.onGetStorage(name)
  }else{
    // 其他端
  }
}

/**
 * 原生存储信息（登录信息，订单支付成功所需信息）
 * @param name
 */
export function onSaveStorage(name,json){
  if(type == 'ios' && window.webkit){
    // ios端
    return null;
  }else if(type == 'android'){
    // android端
    return Android.onSaveStorage(name,json)
  }else{
    // 其他端
  }
}

/**
 * 电子照跳转确认订单页面
 * @param json
 * @param type
 */
export function onStartOrder(json, orderType){
  if(type == 'ios' && window.webkit){
    let str = json + '_' + orderType
    // ios端
    window.webkit.messageHandlers.onStartOrder.postMessage(str);
  }else if(type == 'android'){
   // android端
   Android.onStartOrder(json, orderType)
   }else{
    // 其他端
  }
}

/**
 * 跳转原生确认订单
 * @param e  1：支付成功查看订单
 */
export function onStartActivity(e){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onStartActivity.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.onStartActivity(e)
   }else{
    // 其他端
  }
}

/**
 * 删除原生存储信息
 * @param e  存储信息name
 */
export function onRemoveStorage(e){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.onRemoveStorage.postMessage(e);
  }else if(type == 'android'){
    // android端
    Android.onRemoveStorage(e)
   }else{
    // 其他端
  }
}

/**
 * 刷新课程学习列表
 */
export function refreshStudyList(){
  if(type == 'ios' && window.webkit){
    // ios端
    // 版本号控制
    const AppVersion = getAppVersion();
    // 原生跳转详情页
    if(AppVersion.code >= 100) {
      window.webkit.messageHandlers.refreshStudyList.postMessage('');
    }
  }else if(type == 'android'){
    // android端
    // 版本号控制
    const AppVersion = getAppVersion();
    // 原生跳转详情页
    if(AppVersion.code >= 120) {
      Android.refreshStudyList()
    }
   }else{
    // 其他端
  }
}

/**
 * 原生电子照支付成功返回交互
 */
export function backPicturePage(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.backPicturePage.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.backPicturePage()
   }else{
    // 其他端
  }
}

/**
 * 原生补考支付成功返回交互
 */
export function backExamPage(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.backExamPage.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.backExamPage()
   }else{
    // 其他端
  }
}

/**
 * 原生补考支付成功返回交互
 */
export function receiveACoupon(){
  if(type == 'ios' && window.webkit){
    // ios端
    window.webkit.messageHandlers.receiveACoupon.postMessage('');
  }else if(type == 'android'){
    // android端
    Android.receiveACoupon()
   }else{
    // 其他端
  }
}