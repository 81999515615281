<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="工作单位"
      ref="nav"
      :fixed="true"
      :placeholder="true"
      left-arrow
      :right-text="workUnit==''?'添加':''"
      @click-left="goBack()"
      @click-right="showPopup=true"
    />

    <div class="work_box">
      <!-- 搜索 -->
      <div class="box_search">
        <van-search 
          v-model="keyword" 
          show-action
          placeholder="请输入工作单位名称">
          <template #action>
            <div @click="getWorkUnit">搜索</div>
          </template>
        </van-search>
      </div>
      <!-- 添加工作单位 -->
      <div class="add_box">
        <div class="box_info" v-if="workUnit==''">
          <span>若没有找到您的工作单位，请点击添加</span>
          <!-- <span class="info_light">添加</span> -->
        </div>
        <div v-else>
          <div class="box_list">
            <span>{{workUnit}}</span>
            <van-icon name="success" color="#15B7DD" />
          </div>
          <div class="box_operate">
            <van-icon class="operate_icon" name="edit" size="18px" @click="editWorkUnit()" />
            <van-icon class="operate_icon" name="delete-o" size="18px" @click="deleteWorlUnit()" />
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <van-index-bar :index-list="indexList" :sticky-offset-top="46">
        <div 
          v-for="(item, index) in dataList" 
          :key="index">
          <van-index-anchor :index="item.letter">{{item.letter}}</van-index-anchor>
          <div v-for="(itemI, indexI) in item.sortDetailVOList" :key="indexI">
            <van-cell 
              class="list_item"
              :title="itemI.name" 
              @click="onPickWorkUnit(index, indexI)">
              <van-icon 
                v-if="itemI.pick" 
                class="item_pick"
                name="success" 
                color="#15B7DD" 
                size="12px" />
            </van-cell>
          </div>
        </div>
      </van-index-bar>
      <!-- 确定 -->
      <div class="footer">
        <van-button class="all-btn-bigCircle btn" @click="goConfirm()">确定</van-button>
      </div>
    </div>
    
    <!-- 提示信息 -->
    <van-popup v-model="showPopup">
      <div class="box_popup">
        <div class="popup_title">
          <span class="title_con">请输入您的工作单位</span>
        </div>
        <div class="popup_con">
          <van-field v-model="workUnitInput" placeholder="请输入您的工作单位" />
        </div>
        <div class="popup_button">
          <span class="all-btn-middle btn1" @click="showPopup=false,workUnitInput=''">取消</span>
          <span class="all-btn-middle btn2" @click="addWorkUnit(2)">确认</span>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      // initWork: {}, //初始化公司名称
      keyword: '', //关键字
      workUnit: '', //添加的公司名称
      showPopup: false, //弹框
      workUnitInput: '', //输入的公司名称

      // 选中的公司index
      pickIndex: -1,
      pickIndexI: -1,

      // 序号
      indexList: [],
      // 列表
      dataList: [],
    }
  },
  // 事件处理器
  methods: {
    // 工作单位
    async getWorkUnit() {
      if (this.keyword == '') {
        this.$Toast('请输入工作单位名称进行搜索')
        return
      }
      await this.$ajax({
        url: '/hxclass-mobile/work/sort/list',
        method: 'get', 
        params: {
          keyword: this.keyword
        }
      }).then((res) => {
        // console.log(res)
        if (res.code == 200 && res.success) {
          // 列表
          this.dataList = res.data
          if (this.dataList.length == 0) {
            if (this.workUnit == '') {
              this.$Toast('暂无此公司，请手动添加')
            } else {
              this.$Toast('暂无此公司，请修改添加的公司名称')
            }
          }
          // 序号
          this.indexList = []
          this.dataList.map((item, index) => {
            this.indexList.push(item.letter)
            let dataList = this.dataList[index]
            this.$set(dataList, 'pick', false)
          })
          // console.log(this.dataList)
        }
      })
    },
    // 添加公司
    addWorkUnit(type) {
      // 判空
      if (this.workUnitInput == '') {
        this.$Toast('请输入您的工作单位')
        return
      }

      let lock = false
      // console.log(this.dataList)
      // 查看列表
      this.dataList.map((item, index) => {
        item.sortDetailVOList.map((itemI, indexI) => {
          // 公司名称相同
          if (itemI.name == this.workUnitInput) {
            lock = true
            // type 1认证查询进入 2添加公司
            if (type == 2) {
              // 关闭输入框
              this.showPopup = false
              this.$Dialog.alert({
                message: '您添加的公司与列表公司重复',
              }).then(() => {
                // 赋值-用于下次取消公司
                this.pickIndex = index
                this.pickIndexI = indexI
                // 选中公司
                this.$set(this.dataList[index].sortDetailVOList[indexI], 'pick', true)
              });
            } else if (type == 1) {
              // console.log(222)
              // 赋值-用于下次取消公司
              this.pickIndex = index
              this.pickIndexI = indexI
              // console.log(this.pickIndex, this.pickIndexI)
              // 选中公司
              this.$set(this.dataList[index].sortDetailVOList[indexI], 'pick', true)
              // console.log(this.dataList[index].sortDetailVOList[indexI])
            }
          }
        })
      })
      // 没有重复公司
      if (!lock) {
        this.workUnit = this.workUnitInput
        this.showPopup = false
        // 取消列表选中项
        if (this.pickIndex!==-1 && this.pickIndexI!==-1) {
          this.$set(this.dataList[this.pickIndex].sortDetailVOList[this.pickIndexI], 'pick', false)
          this.pickIndex = -1 
          this.pickIndexI = -1
          // console.log(this.pickIndex, this.pickIndexI)
        }
      }
      this.workUnitInput = ''
    },
    // 编辑公司
    editWorkUnit() {
      this.workUnitInput = this.workUnit
      this.showPopup = true
    },
    // 删除公司
    deleteWorlUnit() {
      this.$Dialog.confirm({
        title: '提示',
        message: '确定删除该公司吗？',
      })
      .then(() => {
        this.workUnit = ''
      })
    },
    // 选中公司
    onPickWorkUnit(index, indexI) {
      // 已手动添加公司
      if (this.workUnit !== '') {
        this.$Dialog.alert({
          // title: '标题',
          message: '您已手动添加公司，请先删除后再选择~',
        })
        return
      }
      // 取消上次的选中公司
      if (this.pickIndex!==-1 && this.pickIndexI!==-1) {
        this.$set(this.dataList[this.pickIndex].sortDetailVOList[this.pickIndexI], 'pick', false)
      }
      // 赋值-用于下次取消公司
      this.pickIndex = index
      this.pickIndexI = indexI
      this.$set(this.dataList[index].sortDetailVOList[indexI], 'pick', true)
      // console.log(this.dataList[index].sortDetailVOList[indexI])
    },
    // 确定
    goConfirm() {
      // 返回列表选中项
      let pickItem = {name: ''}
      if (this.pickIndex!==-1 && this.pickIndexI!==-1) {
        pickItem = this.dataList[this.pickIndex].sortDetailVOList[this.pickIndexI]
        // console.log(pickItem)
      } 
      // 返回用户添加公司
      else if (this.workUnit !== '') {
        pickItem.name = this.workUnit
      }
      // console.log(pickItem)
      localStorage.setItem('workUnitObj', JSON.stringify(pickItem)) //存储
      this.$onReturn(this.$route.query.fromMenu == '1')
    },
    // 返回上一页
    goBack() {
      let pickItem = {flag: true}
      localStorage.setItem('workUnitObj', JSON.stringify(pickItem)) //存储
      this.$onReturn(this.$route.query.fromMenu == '1')
    },
  },
  // 生命周期-实例创建完成后调用
  async created () { 
    // 工作单位列表
    // await this.getWorkUnit()
    // 工作单位
    this.workUnitInput = this.$route.query.name || ''
    if (this.workUnitInput !== '') {
      this.addWorkUnit(1)
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .nav-circular {
    position: relative;
    z-index: 10;
  }
  /deep/.van-popup {
    background-color: transparent;
  }
  /deep/.van-cell__title, .van-cell__value {
    flex: inherit;
    -webkit-box-flex: inherit;
  }
  /deep/.van-cell__value {
    flex-shrink: 0;
    margin-left: 10px;
  }
  /deep/.van-nav-bar__text {
    color: #15b7dd;
  }
  .work_box {
    padding: 0 18px;
    padding-bottom: 80px;
    .add_box {
      padding: 10px 5px;
      .box_info {
        color: #797979;
        font-size: 14px;
        line-height: 20px;
        .info_light {
          color: #EA3C31;
          margin-left: 2px;
        }
      }
      .box_list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0;
        padding: 12px 10px;
        font-size: 15px;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #d4d4d4;
      }
      .box_operate {
        text-align: right;
        .operate_icon {
          margin-left: 10px;
        }
      }
    }
    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 10px 0;
      background-color: #fff;
    }
  }
  .list_item {
    display: flex;
    justify-content: space-between;
    .item_pick {
      flex: 1;
      flex-shrink: 0;
    }
  }
  // 提示信息
  .box_popup {
    position: relative;
    width: 305px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px;
    z-index: 10;
    .popup_title {
      display: flex;
      align-items: center;
      .title_con {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .popup_con {
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      margin-top: 22.5px;
      /deep/.van-cell{
        border-radius: 10px;
        border: 1px solid #c4c4c4;
      }
    }
    .popup_button {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      .all-btn-middle {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFangMedium;
        font-weight: 500;
      }
      .btn1 {
        color: #666666;
        background: #EEEEEE;
        box-shadow: none;
      }
    }
  }
</style>
