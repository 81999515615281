<template>
  <div class="main">
    <img class="foot-car" src="@/assets/image/foot-car.png" alt="">
    <img class="detai-bg" src="@/assets/image/detai-bg.png" alt="">
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main{
  height: 100vh;
  position: relative;
  overflow: hidden;
  .detai-bg{
    width: 100%;
  }
  .foot-car{
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 80px;
  }
}
</style>
