<template>
  <div class="my">
    <!-- <div class="shopCarIcon" @click="showShopCar" v-if="isShow">
      <img src="@/assets/image/icon/shopCarIcon.png" alt="">
      <span class="num" v-show="shopCarList.length">{{num > 99 ? '99+' : num}}</span>
    </div> -->
    <ShpCarModel ref="ShpCarModel" :shopCarFlag="shopCarFlag" :shopList="shopList" :offShelfItems='offShelfItems' :shopCarList="shopCarList" @getShopCarList="getShopCarList" />
  </div>
</template>

<script>
import ShpCarModel from '@/components/shopMall/shopCarModel.vue';
export default {
  
  // 可用组件的哈希表
  components: { ShpCarModel },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 类型：01.家长，02从业者，03.机构，04.家长+从业者，05.家长+机构，06.从业者+机构，07 家长+从业者+机构
      userType: 0, //用户类型
      src: "",
      messageNum:0, // 未读消息数量
      couponTotal: 0, // 优惠券数量
      collectionTotal:0, // 收藏数量
      isShow: true,
      offShelfItems:[], // 购物车下架商品
      shopList:[], // 商品列表
      shopCarList:[], // 购物车列表
      num: 0, // 购物车数量
			shopCarFlag:true, // 购物车弹框状态
      // showShopCarStatus: false, // 商城弹框展开
    };
  },
  beforeCreate() {
    // document.querySelector('body').setAttribute('style', 'background-color:transparent')
  },
  // 生命周期-实例创建完成后调用
  created() {
    window.onOpenPopup = ()=>{
      if(this.shopCarList.length){
        this.shopCarFlag = !this.shopCarFlag;
        this.getShopCarList()
        this.$nextTick(()=>{
          this.$refs.ShpCarModel.showPopup = true;
        })
      } else {
        // 获取购物车列表
        this.getShopCarList('FunPopup')
      }
    }
    
    if (this.$store.state.userInfo.isLogin) {
      // this.userType = this.$store.state.userInfo.userType
      // 获取个人信息
      // this.getUserInfo()
      
    }else if (!this.$store.state.userInfo.isLogin){
      this.isShow = false;
    }
  },
  // 事件处理器
  methods: {
    showShopCar(){
      if(this.shopCarList.length || this.offShelfItems.length){
        this.shopCarFlag = !this.shopCarFlag;
        this.$nextTick(()=>{
          console.log(this.$refs.ShpCarModel)
          this.$refs.ShpCarModel.showPopup = true;
        })
      }else{
        this.$Toast('请添加商品')
      }
    },
    // 获取购物车列表
    getShopCarList(popup){
      this.$ajax({
        url:'/hxclass-mobile/app-mall/shop/list',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data.filter(item=>{
            return item.noGround && (((item.type == 1 && !item.openTimeId) || (item.type == 1 && item.openTimeId && item.classStatus == 1)) || item.type != 1);
          })
          this.offShelfItems = res.data.filter(item=>{
            return !item.noGround || (item.type == 1 && item.openTimeId && item.classStatus != 1);
          })

          if( popup == 'FunPopup'){
            if(this.shopCarList.length || this.offShelfItems.length){
              this.shopCarFlag = !this.shopCarFlag;
              this.$nextTick(()=>{
                this.$refs.ShpCarModel.showPopup = true;
              })
            }else{
              this.$Toast('请添加商品')
            }
          }
          
        }
      })
		},
    // 获取个人信息
    getUserInfo() {
      this.$ajax({
        url: '/hxclass-mobile/user/info',
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let obj = res.data
          obj.isLogin = true;
          this.$store.commit("updateUserInfo", obj);
          this.userType = res.data.userType
        }
      })
    },
    // 去主页
    goHomePage() {
      if (this.$store.state.userInfo.isLogin) {
        this.$router.push("/mine/personal");
      } else {
        this.$router.push('/login/login');
      }
    },

    goOrderDetail() {
      this.$router.push("/order/orderDetail");
    },
    onRemove() {
      this.showModal({
        title: "提示信息",
        content: "确定要删除该发票抬头？",
        success: (e) => {
          if (e.confirm) {
            console.log("点击了确定");
          } else {
            console.log("点击了取消");
          }
        },
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    document.querySelector('body').setAttribute('style', 'background-color:transparent')
  },
  updated() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {
    document.querySelector('body').removeAttribute('style')
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    sessionStorage.setItem('handleorderID','')
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    shopCarList(newVal,oldVal){
      this.num = 0
      console.log(newVal)
      newVal.map(item=>{
        this.num += item.count;
      })
    }
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.my {
  min-height: 100%;
  background: #f7f7f9;
  // padding-bottom: 55px;
}
/deep/.van-badge--fixed {
	top: 6px;
	right: 6px;
}

.shopCarIcon{
  padding: 16px 12px 12px 7px;
  position: fixed;
  bottom: 98px;
  right: 18px;
  background: #ffffff;
  border-radius: 50%;
  img{
    width: 31px;
    height: 22px;
  }
  .num{
    position: absolute;
    top: 0;
    right: 0;
    font-style: normal;
    text-align: center;
    // width: 15px;
    // height: 15px;
    min-width: 15px;
    padding: 0 3px;
    border-radius: 999px;
    background: #FF7A00;
    font-size: 10px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 15px;
  }
}
</style>