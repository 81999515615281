<template>
	<!-- 学分 -->
	<div class="studyCode">
		<van-nav-bar
			title="学分"
			right-text="学分说明"
			left-arrow
			:fixed="true"
			:placeholder="true"
			@click-left="$onReturn($route.query.fromMenu == '1')"
			@click-right="onRight"
		/>
    <div class="header-box">
      <div class="grade-inner">
        <div class="center">
          <span class="grade">{{totalIntegral}}</span>学分
        </div>
      </div>
    </div>
		<div class="detailed">
			<div class="detailedtitle">
				<span class="colorDiv"></span>
				<span class="title">学分明细</span>
			</div>
			<!-- <no-message v-show='false' /> -->
			<div class="detailList" v-for="(item,index) in integralList" :key="index + 'j'">
				<div class="left">{{item.date.substr(3,6)}}</div>
				<div class="right">
					<div class="item" v-for="(subItem,i) in item.scoreVOList" :key="subItem.scoreId">
						<span class="time">{{subItem.time}}<span class="border" v-if="i != (item.scoreVOList.length - 1)"></span><span class="floorColor" :style="{'background-color':randomColor16()}"></span></span>
						<span class="content">{{subItem.description}}</span>
						<span class="code"><span>{{subItem.scored}}</span>学分</span>
					</div>
				</div>
			</div>
			<noMessage
        style="margin-top: 35px"
        v-if="integralList.length == 0"
        type="1"
      ></noMessage>
		</div>
    
    <!-- 学分说明弹窗 -->
		<van-popup v-model="popshow" :close-on-click-overlay="false">
			<div class="popup-content">
        <div class="header">学分说明</div>
        <span class="tips">1.学习积分是用户在云课堂参与学习活动、奖品兑换的直接依据，可通过在云课堂-学习模块进行学习、打卡等获得。</span>
        <span class="tips">2.积分依照规则递增，并不可转让、赠送、贩卖等;学习积分可兑换成积分，参与积分商城(后期开放，敬请期待）奖品兑换。目前学习积分不设使用期限。</span>
        <!-- <uni-icons type="close" @click="$refs.popup.close()" size="38" class="close-icon" color="#fff"></uni-icons> -->
				<van-icon name="close"  @click="popshow = false" size="28" class="close-icon" color="#fff"/>
    	</div>
		</van-popup>
    <!-- <uni-popup ref='popup' :isMaskClick="false">
    	<div class="popup-content">
        <div class="header">学分说明</div>
        <span class="tips">1.学习积分是用户在云课堂参与学习活动、奖品兑换的直接依据，可通过在云课堂-学习模块进行学习、打卡等获得。</span>
        <span class="tips">2.积分依照规则递增，并不可转让、赠送、贩卖等;学习积分可兑换成积分，参与积分商城(后期开放，敬请期待）奖品兑换。目前学习积分不设使用期限。</span>
        <uni-icons type="close" @click="$refs.popup.close()" size="38" class="close-icon" color="#fff"></uni-icons>
    	</div>
    </uni-popup> -->
	</div>
</template>

<script>
	import noMessage from "@/components/nomessage.vue";
	export default {
		data(){
			return{
				popshow:false,
				totalIntegral:0, //总积分
				integralList:[]
			}
		},
		// 可用组件的哈希表
  	components: { noMessage },
		computed:{
			color(){
				let r = Math.floor(Math.random()*256);
				let g = Math.floor(Math.random()*256);
				let b = Math.floor(Math.random()*256);
				let color = '#'+r.toString(16)+g.toString(16)+b.toString(16);
				return color;
			}
		},
		methods:{
			// 获取总积分
			getTotalIntegral(){
        this.$ajax({
          url: "/hxclass-mobile/score/app",
        }).then((res) => {
          if (res.code == 200 && res.success) {
						this.totalIntegral = res.data ? res.data : 0
          }
        });
			},
			// 获取总积分
			getIntegralList(){
        this.$ajax({
          url: "/hxclass-mobile/score/app/details",
        }).then((res) => {
          if (res.code == 200 && res.success) {
						this.integralList = res.data
          }
        });
      },
			// 十六进制颜色函数
			randomColor16(){
				let r = Math.floor(Math.random()*256).toString(16);
				let g = Math.floor(Math.random()*256).toString(16);
				let b = Math.floor(Math.random()*256).toString(16);
				//255数字转化为16进制
				if(r.length<2) r = "0"+r;
				if(g.length<2) g = "0"+g;
				if(b.length<2) b = "0"+b;
				return "#" + r + g + b;
			},
			onRight(){
        this.$router.push("/myStudy/creditExplain");

				// this.$refs.popup.open()
				// this.popshow = true
			}
		},
		// 生命周期-实例创建完成后调用
    created() {},
    // 生命周期-实例挂载后调用
    mounted() {
			this.getTotalIntegral()
			this.getIntegralList()
		},
	}

</script>

<style>
  page{
    background-color: #F7F7F9;
  }
</style>
<style lang="less" scoped>
	/deep/.van-popup{
		overflow-y: revert;
  	border-radius: 9px;
	}
	.studyCode{
		.backgro{
			height: 75px;
			// background-color: #34A4A2;
			border-radius: 0 0 24px 24px;
			padding: 0 24px;
			position: relative;
			.codeNumber{
				width: calc(100% - 49px);
				height: 90.5px;
				border-radius: 13.5px;
				position: absolute;
				z-index: 3;
				line-height: 92px;
				top: 12.5px;
				text-align: center;
				background-image: url(../../assets/image/study/codeBack.png);
				background-size: 100% 100%;
				background-color: #fff;
				font-size: 14.5px;
				.number{
					font-weight: bold;
					font-size: 36px;
					margin-right: 11px;
					color: rgba(28, 185, 222, 1);
				}
				span{
					color: rgba(102, 102, 102, 1);
				}
			}
		}
    .header-box{
      margin: 16.5px 18px;
      background: #FFFFFF;
      border-radius: 13px;
      .grade-inner{
        height: 90.5px;
        background: url(~@/assets/image/icon/20220215041508.png) ;
        background-repeat: repeat-x;
        background-size:cover;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .center{
          color: #666666;
          font-size: 14px;
          .grade{
            position: relative;
            top: 2.5px;
            margin-right: 5px;
            // color: $theme;
            font-size: 31px;
						color:#15B7DD ;
            font-family: PingFangMedium;
          }
        }
      }
    }
		.detailed{
			padding: 0 18px;
			.detailedtitle{
				// height: 24.5px;
				// line-height: 24.5px;
				display: flex;
				align-items: center;
				.colorDiv{
					display: inline-block;
					width: 3px;
					height: 13px;
					background-color: rgba(21, 183, 221, 1);
					margin-right: 3.5px;
					// margin-top: 3.5px;
					border-radius: 4px;
				}
				.title{
					display: inline-block;
					font-weight: bold;
					font-size: 16.5px;
					// line-height: 24.5px;
				}
			}
			.detailList{
				margin-top: 12.5px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.left{
					width: 51px;
					height: 51px;
					text-align: center;
					line-height: 51px;
					background-color: rgba(28, 185, 222, 1);
					border-radius: 4.5px;
					color: #fff;
					font-size: 14.5px;
					font-weight: bold;
				}
				.right{
					width: 295px;
					background-color: #ffffff;
					padding: 11px 12.5px;
					box-sizing: border-box;
					span{
						color: rgba(102, 102, 102, 1);
						font-size: 12.5px;
						line-height: 19px;
						// font-weight: bold;
						position: relative;
						span{
							color: rgba(28, 185, 222, 1);
						}
						.floorColor{
							position: absolute;
							width: 5.5px;
							height: 5.5px;
							left: 44px;
							top: 7px;
							border-radius: 50%;
						}
						.border{
							position: absolute;
							height: 26.5px;
							width: 0px;
							border-left: 2px dashed #eeeeee;
							border-radius: 50%;
							left: 46px;
							top: 11px;
						}
					}
					.item{
						margin-bottom: 12.5px;
						display: flex;
            font-weight: 400;
						justify-content: space-between;
						.time{
							width: 34px;
							color: rgba(51, 51, 51, 1);
						}
						.content{
							width: 108.5px;
						}
						&:last-child{
							margin-bottom: 0px;
						}
						.code{
              font-weight: 400;
						}
					}
				}
			}
		}
	}
	/deep/.uni-navbar__header{
		border-radius: 0;
		// background-color: #34A4A2 !important;
		.uni-ellipsis-1{
			color: #333 !important;
			font-weight: bold;
		}
		.uni-icons{
			color: #666666 !important;
		}
		.uni-nav-bar-right-span{
			color: #666666 !important;
		}
	}
	// /deep/.uni-navbar__header{
	// 	border-radius: 0;
	// 	background-color: #34A4A2 !important;
	// 	.uni-ellipsis-1{
	// 		color: #fff !important;
	// 	}
	// 	.uni-icons{
	// 		color: #fff !important;
	// 	}
	// }
  
  /* 遮罩内容 */
  .uni-popup {
  	z-index: 1000;
  }
  /* 学分说明 */
  .popup-content {
  	background: #FFFFFF;
  	width: 245px;
  	border-radius: 9px;
  	padding: 20px;
  	position: relative;
    .header{
      text-align: center;
      font-size: 20px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }
    .tips{
      display: block;
      margin-top: 11px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    /deep/.close-icon{
      position: absolute;
      text-align: center;
      bottom: -50px;
      left: 50%;
      width: 245px;
      margin-left: -122px;
    }
  }
</style>
