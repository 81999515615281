<template>
  <!-- 找回密码 -->
  <div class='retrievePassword'>
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-circular"
      title="找回密码"
      left-text=""
      right-text=""
      :fixed="true"
			:placeholder="true"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class='content' v-show='!nextStatus'>
      <div class='describe'>请输入您要找回密码的手机号</div>
      <div class='form'>
        <p class='phoneType' @click='showPopup'>{{
          popCode[popIndex].code
        }}</p>
        <img
          src='@/assets/image/login/icon1.png'
          @click='showPopup'
        />
        <van-field v-model="phone" label="" placeholder="请输入手机号" clearable />
      </div>
      <div class='form'>
        <van-field v-model="code" label="" maxlength="6" placeholder="请输入验证码" />
        <div @click='onDxCode()'>
          <p class='getCode' v-if='isDxCode' style='color: #00b3c5'
            >获取验证码</p
          >
          <p class='getCode' v-else style='color: #999'
            >重新发送 {{ countdown }}</p
          >
        </div>
      </div>
      <van-button plain
        @click='gosetPassword'
        class='btn'
        ref="gosetButton"
        :style='{"background": status?"linear-gradient(120deg, #15B7DD 0%, rgba(21, 183, 221, 0.83) 100%)"
            : "#eeeeee",
            color: status ? "#FFFFFF" : "#666666"}' type="">下一步</van-button>

      <!-- 滑动解锁 -->
      <div class="drag_box" v-show="dragShow">
        <drag-verify
          ref="dragVerify7"
          :width="pageWidth"
          :isPassing.sync="isPassing7"
          handlerBg="#C7C7C7"
          text="请按住滑块拖动"
          successText="验证通过"
          @passcallback="passcallback"
        ></drag-verify>
      </div>
        
    </div>
    <div class='content' v-show='nextStatus'>
      <div class='describe'>请为您的账号设置新密码</div>
      <div class='form'>
        <!-- :type="inputStatus?'password':'text'"  -->
        <van-field 
          :class="{'item_password':inputStatus}"
          maxlength='16' 
          v-model="password" 
          label="" 
          placeholder="8-16位数字、字母的组合密码" />
        <div @click='inputStatus = !inputStatus'>
          <img
            v-show='inputStatus'
            class='inputStatus'
            src='@/assets/image/login/password_icon1.png'
          />
          <img
            v-show='!inputStatus'
            class='inputStatus'
            src='@/assets/image/login/password_icon2.png'
          />
        </div>
      </div>
      <div class='form'>
        <van-field 
          :class="{'item_password':inputStatus}"
          maxlength='16' 
          v-model="enterpassword" 
          label="" 
          placeholder="再次输入密码" />
      </div>
      <button
        type='default'
        @click='changePassword'
        class='btn'
        :style='{
          background: passwordStatus
            ? "linear-gradient(120deg, #15B7DD 0%, rgba(21, 183, 221, 0.83) 100%)"
            : "#eeeeee",
          color: passwordStatus ? "#FFFFFF" : "#666666"
        }'
      >
        确定
      </button>
    </div>

    <!-- 图形拖拽校验 -->
    <div class="puzzle-box" v-if="verificationShow">
      <div class="puzzle_content" style="width: 295px;height: 500px">
        <puzzleVerification 
          :verificationShow="true" 
          blockType="puzzle"
          :onSuccess="handleSuccess" 
          :onError="handleError" 
          :puzzleImgList="puzzleImgList" 
          style="display: block"
        ></puzzleVerification>
      </div>
    </div>

    <!-- 区号选择 -->
    <n-transition
      ref='pop'
      speed='ease-in-out'
      :height='popHeight'
      :maskVal='0.35'
    >
      <div class='pop_box'>
        <div class='box_list'>
          <div
            class='list_item'
            v-for='(item, index) in popCode'
            :key='index'
            @click='changeCode(index)'
          >
            <p class='item_txt'>{{ item.country }}</p>
            <p class='item_txt'>{{ item.code }}</p>
          </div>
        </div>
        <div class='box_button' @click='hidePopup()'>取消</div>
      </div>
    </n-transition>

    <!-- 加载 -->
    <!-- <van-loading size="24px">加载中...</van-loading> -->

  </div>
</template>

<script>
import dragVerify from 'vue-drag-verify2'
import nTransition from '@/components/n-transition/n-transition.vue'
export default {
	// 可用组件的哈希表
	components: { nTransition, dragVerify },
  data () {
    return {
      // 推拽图片列表
      puzzleImgList: [
        require("@/assets/image/puzzleImg/back1.jpg"),
        require("@/assets/image/puzzleImg/back2.jpg"),
        require("@/assets/image/puzzleImg/back3.jpg"),
      ],
      verificationShow: false,

      popHeight : 260, //弹框高度
      phone: '',
      code: '',
      password: '',
      enterpassword: '',
      inputStatus: true,
      nextStatus: false,
      isDxCode: true,
      countdown: 60, // 验证码时间
      popIndex: 0,
      popCode: [
        {
          id: '4',
          country: '中国',
          code: '+86'
        },
        {
          id: '1',
          country: '中国香港',
          code: '+852'
        },
        {
          id: '2',
          country: '中国澳门',
          code: '+853'
        },
        {
          id: '3',
          country: '中国台湾',
          code: '+886'
        }
      ],

      isPassing7: false,
      pageWidth: 300,
      dragShow: false, //滑动解锁
    }
  },
  computed: {
    // 下一步按钮样式
    status () {
      const phoneStatus = this.$regular.phone.reg.test(this.phone)
      const codeStatus = this.$regular.dxcode.reg.test(this.code)
      return phoneStatus && codeStatus
    },
    // changeShowPass() {
    //   // console.log(111)
    //   let inputStatus = this.inputStatus
    //   this.$set(this, 'inputStatus', !inputStatus)
    // },
    // 修改密码按钮样式
    passwordStatus () {
      const passwordStatus = this.$regular.password.reg.test(this.password)
      const enterpasswordStatus = this.$regular.password.reg.test(
        this.enterpassword
      )
      return passwordStatus && enterpasswordStatus
    },
    // 宽度
    // pageWidth() {
    //   return this.$refs.gosetButton.clientWidth
    // }
  },
  mounted() {
    this.pageWidth = this.$refs.gosetButton.clientWidth
  },
  methods: {
    // 推动图形校验
    // 成功回调
    handleSuccess() {
      this.verificationShow = false;
      // 发送短信
      this.$ajax({
        url: '/hxclass-mobile/user/upPsSend',
        method: "get",
        params: {
          phone: this.phone, //手机号
          mobileType: this.popCode[this.popIndex].id, //区号
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success('发送成功');
          // 倒计时
          this.sendemail(this.countdown);
        } else {
          this.$alert(res.message);
        }
      })
    },
    // 错误回调
    handleError() {

    },

    passcallback() {
      this.nextStatus = true;
    },

    // 导航返回键
    onClickLeft(){
      this.$onReturn(this.$route.query.fromMenu == '1');
    },
    // 打开弹框
    showPopup () {
      this.$refs["pop"].show()
    },
    // 关闭弹框
    hidePopup() {
      this.$refs['pop'].hide();
    },
    // 选择区号
    changeCode(index) {
      this.popIndex = index;
      this.hidePopup();
    },
    // 下一步
    gosetPassword() {
      if (!this.phone) {
        this.$Toast('请输入手机号');
        return
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$Toast('请输入正确的手机号');
        return
      } else if (!this.code) {
        this.$Toast('请输入验证码');
        return
      } else if (!this.$regular.dxcode.reg.test(this.code)) {
        this.$Toast('请输入正确的验证码');
        return
      }

      // 校验验证码
      this.$ajax({
        url: '/hxclass-mobile/user/check',
        method: "get",
        params: {
          phone: this.phone,
          verCode: this.code,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 打开滑块
          // this.dragShow = true
          // 下一步
          this.nextStatus = true;
        } else {
          this.$alert(res.message);
        }
      })
      
    },
    // 修改密码
    changePassword() {
      if (!this.password) {
        this.$Toast('请输入密码');
        return 
      } else if (!this.$regular.password.reg.test(this.password)) {
        this.$Toast('请输入正确的密码');
        return 
      } else if (!this.enterpassword) {
        this.$Toast('请输入确认密码');
        return 
      } else if (this.password !== this.enterpassword) {
        this.$Toast('您输入的密码不一致，请重新输入');
        return 
      }

      // md5加密
      // var md5 = require('md5');

      this.$ajax({
        url: '/hxclass-mobile/user/updatePassword',
        method: "POST",
        params: {
          phone: this.phone,
          newPassword: this.password,
          confirm: this.enterpassword,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$Toast.success('修改成功');
          this.$onReturn(this.$route.query.fromMenu == '1')
        } else {
          this.$alert(res.message);
        }
      })        
    },
    // 倒计时60s
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },
    // 发送给验证码
    onDxCode() {
      if (this.isDxCode) {
        if (!this.phone) {
          this.$Toast('请输入手机号');
          return false;
        } else if (!this.$regular.phone.reg.test(this.phone)) {
          this.$Toast('请输入正确的手机号');
          return false;
        }

        // 打开图形校验
        this.verificationShow = true;

        // $ajax({
        // 	url: '/member/app/findPassword/sendCertificateCode',
        // 	method: 'post',
        // 	params: {
        // 		tellPhone: this.tellPhone
        // 	}
        // }).then(res => {
        // 	if (res.code == 1) {
        // this.sendemail(this.countdown);
        // 	} else {
        // 		uni.showModal({
        // 			title: '提示',
        // 			content: res.msg
        // 		})
        // 	}
        // })
      }
    }
  }
}
</script>

<style scoped lang='less'>
/deep/.van-cell{
  padding: 0;
  flex: 1;
}
/deep/.close-btn{
  display: none !important;
}
// 推拽图形校验样式
.puzzle-box{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  .puzzle_content{
    margin: 0 auto;
  }
}

.retrievePassword {
  min-height: 100vh;
  background-color: #f7f7f9;
  .content {
    padding: 9px 34px;
    height: calc(100vh - 60px);
    margin-top: 14px;
    border-radius: 10px 10px 0px 0px;
    background: #ffffff;
    box-sizing: border-box;
    .btn {
      height: 45px;
      margin-top: 67px;
      background: linear-gradient(
        120deg,
        #15b7dd 0%,
        rgba(21, 183, 221, 0.83) 100%
      );
      border-radius: 25px;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #ffffff;
      border: 0;
      width: 100%;
    }
    .describe {
      padding: 9px 0 7px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .form {
      display: flex;
      align-items: center;
      padding: 11px 0;
      border-bottom: 1px solid #eeeeee;
      .item_password {
        /deep/.van-field__control {
          -webkit-text-security: disc !important;
        }
      }
      .inputStatus {
        width: 20px;
        height: 13.5px;
      }
      .phoneType {
        font-size: 16px;
        font-family:  PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 19px;
      }
      img {
        width: 8px;
        height: 6px;
        margin-left: 5px;
        margin-right: 9px;
      }
      input {
        flex: 1;
      }
      .getCode {
        font-size: 14px;
        font-family:  PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        padding-left: 6px;
        border-left: 1px solid #c4c4c4;
      }
      /deep/.uni-input-placeholder {
        font-size: 16px;
        font-family:  PingFangMedium;
        font-weight: 500;
        color: #cccccc;
        line-height: 24px;
      }
      /deep/.uni-input-input {
        font-size: 16px;
        font-family:  PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
// 选择区号
.pop_box {
  .box_list {
    padding: 0 18px;
    .list_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      padding: 14px 0;
      &:last-child {
        border: 0;
      }
      .item_txt {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .box_button {
    font-size: 16px;
    font-weight: bold;
    color: #15b7dd;
    text-align: center;
    border-top: 10px solid #f7f7f9;
    padding: 14px 0;
  }
}
// 滑动解锁
.drag_box {
  margin-top: 10px;
}
.drag_verify .dv_handler i {
  // background-image: url('@assets/image/datas/Banner.png');
  background: #15b7dd;
}
</style>
