<template>
  <div class="audioList">
    <div class="banner-box">
      <div class="banner-photo"></div>
      <div class="banner-circle" @click="$onReturn($route.query.fromMenu == '1')">
        <van-icon class="icons" name="arrow-left" />
      </div>
      <div class="banner-title text">云课头条</div>
      <div class="banner-content" v-if="introduce">
        <p>{{ introduce }}</p>
      </div>
    </div>

    <div class="audioContent">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="tops">
          <div
            class="left"
            @click="item.audioUrl ? goDetails(item.xuanId) : null"
          >
            <div
              class="title"
              :class="
                !$store.state.audio.paused &&
                $store.state.audio.src === item.audioUrl &&
                item.xuanId == $store.state.audio.id
                  ? 'blueText'
                  : ''
              "
            >
              {{ index + 1 }}.{{ item.title }}
            </div>
            <div class="time">
              <span class="lecturer" v-show="item.lecturer">
                <img
                  class="lecturer-icon"
                  src="@/assets/image/audio/icon_lecturer.png"
                  alt=""
                />
                <span>{{ item.lecturer }}  {{ item.xjType == 2 ? item.audioTime : "" }}</span>
              </span>
            </div>
          </div>
          <div class="right" v-if="item.audioUrl" @click="onPlay(item)">
            <div class="playIcon" v-if="item.xjType == 2">
              <img
                v-if="
                  !$store.state.audio.paused &&
                  $store.state.audio.src === item.audioUrl &&
                  item.xuanId == $store.state.audio.id
                "
                class="playIconImg"
                src="@/assets/image/audio/playIcon.png"
              />
              <img
                v-else
                class="playIconImg"
                src="@/assets/image/audio/suspendIcon.png"
              />
            </div>
          </div>
        </div>

        <div class="content" v-if="item.isOpen">
          <div class="listAll">
            <div
              class="children"
              :class="
                !$store.state.audio.paused &&
                $store.state.audio.src === items.audioUrl &&
                items.xuanId == $store.state.audio.id
                  ? 'click'
                  : ''
              "
              v-for="(items, indexs) in item.children"
              :key="items.xuanId"
            >
              <div class="second">
                <div
                  class="left"
                  @click="items.audioUrl ? goDetails(items.xuanId) : null"
                >
                  {{ index + 1 }}.{{ indexs + 1 }}.{{ items.title }}
                  <div class="lecturer lecturer-text" v-show="item.lecturer">
                    <img
                      class="lecturer-icon"
                      src="@/assets/image/audio/icon_lecturer.png"
                      alt=""
                    />
                    {{ items.lecturer }}
                    <span class="audioTime" v-if="items.xjType == 2">{{
                      items.audioTime
                    }}</span>
                  </div>
                </div>
                <div
                  class="right"
                  @click="onPlay(items)"
                  v-if="items.xjType == 2"
                >
                  <img
                    v-if="
                      !$store.state.audio.paused &&
                      $store.state.audio.src === items.audioUrl &&
                      items.xuanId == $store.state.audio.id
                    "
                    class="img playMini2"
                    src="@/assets/image/audio/playIcon.png"
                  />
                  <img
                    v-else
                    class="img playMini2"
                    src="@/assets/image/audio/suspendIcon.png"
                  />
                </div>
                <div v-else class="right">
                  <img
                    class="img playMini2"
                    style="width: 20px"
                    src="@/assets/image/audio/suspendIcon2.png"
                  />
                </div>
              </div>

              <div class="third">
                <div
                  class="childrens"
                  :class="
                    !$store.state.audio.paused &&
                    $store.state.audio.src === i.audioUrl &&
                    i.xuanId == $store.state.audio.id
                      ? 'click'
                      : ''
                  "
                  v-for="(i, x) in items.children"
                  :key="i.xuanId"
                >
                  <div
                    class="left"
                    @click="i.audioUrl ? goDetails(i.xuanId) : null"
                  >
                    {{ index + 1 }}.{{ indexs + 1 }}.{{ x + 1 }}{{ i.title }}
                    <div class="lecturer lecturer-text" v-show="item.lecturer">
                      <img
                        class="lecturer-icon"
                        src="@/assets/image/audio/icon_lecturer.png"
                        alt=""
                      />
                      {{ i.lecturer }}
                      <span class="audioTime" v-if="i.xjType == 2">{{
                        i.audioTime
                      }}</span>
                    </div>
                  </div>
                  <div class="right" @click="onPlay(i)" v-if="i.xjType == 2">
                    <img
                      v-if="
                        !$store.state.audio.paused &&
                        $store.state.audio.src === i.audioUrl &&
                        i.xuanId == $store.state.audio.id
                      "
                      class="img playMini2"
                      src="@/assets/image/audio/playIcon.png"
                    />
                    <img
                      v-else
                      class="img playMini2"
                      src="@/assets/image/audio/suspendIcon.png"
                    />
                  </div>
                  <div v-else class="right">
                    <img
                      class="img playMini2"
                      style="width: 20px"
                      src="@/assets/image/audio/suspendIcon2.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="arrowdown"
          v-if="item.children && item.children.length > 0"
          @click="changeOpen(item)"
        >
          <van-icon
            v-if="item.isOpen"
            name="arrow-up"
            color="#999999"
            size="11px"
          />
          <van-icon
            v-if="!item.isOpen"
            name="arrow-down"
            color="#999999"
            size="11px"
          />
        </div>
      </div>
    </div>

    <div class="" style="position: fixed; bottom: 0; width: 100%">
      <!-- <zaudio theme="theme2"></zaudio> -->
    </div>
  </div>
</template>

<script>
// import zaudio from '@/components/uniapp-zaudio/zaudio';
export default {
  data() {
    return {
      list: [],
      // audiolist: this.$zaudio.audiolist, //当前音频列表
      // playIndex: this.$zaudio.playIndex, //当前播放的索引
      playinfo: "playinfo", // this.$zaudio.playinfo, //当前播放的信息
      isShow: false,
      isOpen: false, //是否打开列表
      introduce: "",
    };
  },
  // components: { zaudio: zaudio },
  methods: {
    goDetails(xuanId) {
      if(this.$store.state.audio.id != xuanId){
        this.$store.commit("pause");
      }
      this.$router.push("/propagandaHeadlines/audioDetails?xuanId=" + xuanId);
    },
    //播放或暂停
    onPlay(item) {
      if (!this.$store.state.userInfo.userId) {
        return this.$router.push('/login/login');
      }
      if (this.$store.state.audio.id != item.xuanId) {
        // 不属于同一个音频
        this.$store.commit("updateAudio", {
          title: item.title,
          src: item.audioUrl,
          show: true,
        });
        this.$store.state.audio.id = item.xuanId;
      } else {
        // 属于同一个音频
        if (this.$store.state.audio.paused) {
          // 暂停中
          if (!this.$store.state.audio.src) {
            this.$store.commit("updateAudio", {
              title: item.title,
              src: item.audioUrl,
              show: true,
            });
          }
          this.$store.state.audio.id = item.xuanId;
          this.$store.commit("play");
        } else {
          // 播放中
          this.$store.commit("pause");
        }
      }
    },
    // 展开/收起
    changeOpen(e) {
      this.$set(e, "isOpen", !e.isOpen);
    },

    // 查询列表
    getList() {
      this.$ajax({
        url: "/hxclass-mobile/speak/list",
        params: {
          page_num: this.pageNumber,
          page_size: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.list = res.data;
        }
      });
    },

    getIntroduce() {
      this.$ajax({
        url: "/hxclass-mobile/speak/introduce",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = res.data.content;
        }
      });
    },
  },
  created() {
    this.getIntroduce();
    this.getList();
  },
  destroyed() {
    // //卸载不需要的业务和获取播放状态的业务,提高页面性能
    // this.$zaudio.syncStateOff('page-index-get-state');
  },
};
</script>

<style lang="less">
page {
  background-color: #f7f7f9;
}
.updown {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.audioList {
  .banner-box {
    position: relative;
    // margin-bottom: 12.5px;

    .text {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #ffffff;
    }
    .banner-photo {
      width: 100%;
      height: 254px;
      background: url(../../assets/image/audio/banner_audio.jpg) no-repeat;
      background-size: 100% 100%;
    }
    .banner-circle {
      position: absolute;
      top: 50px;
      left: 11px;
      width: 27px;
      height: 27px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
      z-index: 2;
    }
    .banner-title {
      position: absolute;
      display: inline-block;
      max-width: 240px;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 18px;
      font-family: PingFangMedium;
      color: #333333;
      text-align: center;
      z-index: 2;
    }
    .banner-introduce {
      position: absolute;
      top: 106px;
      left: 25.5px;
      line-height: 27px;
      z-index: 2;
    }
    .banner-content {
      
      position: absolute;
      top: 102px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 10px 14px;
      width: calc(100% - 72px);
      // height: 189px;
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      // border: 1px solid;
      // border-image: linear-gradient(180deg, rgba(255, 255,   255, 0.58), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: PingFangMedium;
      font-weight: 300;
      color: #ffffff;
      line-height: 21px;
      z-index: 2;
      p {
        display: -webkit-box; //对象作为弹性伸缩盒子模型显示
        overflow: hidden; //溢出隐藏
        -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的 排列方式
        -webkit-line-clamp: 9; //设置 块元素包含的文本行数
      }
    }
  }
  .audioContent {
    padding: 18px 18px;
    padding-bottom: 50px;

    .blueText {
      color: #15b7dd !important;
    }

    .item {
      margin-bottom: 18px;
      background: #ffffff;
      box-shadow: 0px 2.5px 5px 0.5px rgba(93, 131, 167, 0.1);
      border-radius: 5px;
      padding: 14px 14px 14px 18px;
      position: relative;

      .lecturer {
        margin-right: 20px;
        font-size: 12px;
        font-weight: 300;
        color: #666666;
        display: flex;
        align-items: center;
        .lecturer-icon {
          position: relative;
          top: -1px;
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
        .audioTime {
          margin-left: 20px;
        }
      }

      .lecturer-text {
        margin-top: 6px;
        font-size: 10px;
        // font-weight: 500;
        color: #666666;
        line-height: 15px;
      }

      .tops {
        display: flex;
        align-items: center;
        .left {
          flex: 1;
          .title {
            font-size: 15px;
            // font-weight: bold;
            color: #333333;
            line-height: 21px;
            font-family: PingFangMedium;
          }
          .time {
            font-size: 10px;
            font-weight: 500;
            color: #999999;
            line-height: 15px;
            margin-top: 8px;
          }
        }
        .right {
          width: 27px;
          .playIcon {
            width: 27px;
            margin: 0 auto;
            .playIconImg {
              width: 27px;
              height: 27px;
            }
          }
          .name {
            margin-top: 4px;
            font-size: 10px;
            font-weight: 500;
            color: #666666;
            line-height: 15px;
          }
        }
      }

      .content {
        position: relative;
        border-top: 0.5px solid #eee;
        margin-top: 14px;
        .left {
          flex: 1;
          font-size: 14px;
          font-family: PingFangMedium;
          // font-weight: bold;
          color: #333333;
          line-height: 23px;
        }
        .right {
          width: 27px;
          text-align: center;
          .img {
            width: 19px;
            height: 19px;
          }
          .playMini1 {
            display: none;
          }
        }

        .children {
          margin-top: 10px;
          line-height: 18px;
          margin-bottom: 5px;

          .second {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .third {
            .childrens {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 10px 0 0 8px;

              .left {
                flex: 1;
                font-size: 12px;
                font-family: PingFangMedium;
                font-weight: bold;
                color: #333333;
                line-height: 18px;
              }

              &.click {
                .left {
                  color: #15b7dd;
                }
              }
            }
          }

          &.click {
            .left {
              color: #15b7dd;
            }
          }
        }
      }
      .arrowdown {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 4px rgba(#000, 0.1);
        border: 2px solid #f7f7f9;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 0);
        background-color: #fff;
      }
    }
  }
}

.icons {
  color: #ffffff;
}
</style>
