export default [
  /*
    子模块的路由前面不需要添加‘/’
  */
  {
    path: 'bookTeach', // 图书教具
    component: () => import('../views/shop/bookTeach.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: 'shoppingMall', // 综合商城
    component: () => import('../views/shop/shoppingMall.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
   // 模拟考试
   {
    path: 'mock',
    component: () => import('@/views/home/mock.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  // 购物车
  {
    path: 'myCart',
    component: () => import('@/views/shop/myCart.vue'),
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  }
]
