<template>
  <div>
    <div class="btn" @click="submitFn">
      <div>打开APP</div>
    </div>
    <div v-if="iosApp" id="guideZfbPayPage">
      <div class="wx-open">
        <p>1.请点击右上角 • • •</p>
        <p>2.选择在浏览器中打开</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      iosApp: false,
    };
  },
  mounted() {
    if (this.isWeixin()) {
      this.iosApp = true;
    } else {
      // 显示手动打开外置浏览器提示
      this.iosApp = false;
      // this.submitFn()
    }
  },
  methods: {
    isWeixin() {
      const u = navigator.userAgent.toLowerCase();
      return (
        /micromessenger/i.test(u) ||
        u.indexOf("weibo") > -1 ||
        u.indexOf("qq") > -1 ||
        u.indexOf("mqqbrowser") > -1
      );
    },
    submitFn() {
      var t = 1000;
      var hasApp = true;
      const u = navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端或者uc浏览器
      setTimeout(function () {
        if (hasApp) {
          //    alert('安装了app');
          // return false;
        } else {
          //    alert('未安装app');
          if (isAndroid) {
            window.location.href = "https://app.download.hxclass.cn/"; // 下载网址
          } else {
					  window.location.href = 'itms-apps://itunes.apple.com/app/id1623509922'
          }
        }
        document.body.removeChild(ifr);
      }, 2000);

      var t1 = Date.now();
      var ifr = document.createElement("iframe");
      if (isAndroid) {
        ifr.setAttribute("src", "hxclass://open"); // 安卓链接 不是https:
        ifr.setAttribute("style", "display:none");
        document.body.appendChild(ifr);
      } else {
        window.location.href = "hxykt://"; // ios链接 不是https:
      }

      setTimeout(function () {
        var t2 = Date.now();
        if (!t1 || t2 - t1 < t + 100) {
          hasApp = false;
        }
      }, t);
    },
  },
};
</script>

<style lang="less" scoped>
/* 提示浏览器打开 */
#guideZfbPayPage {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 777;
}
.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > div {
    font-size: 0.27rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #6588d1;
    line-height: 0.41rem;
    padding: 0.2rem 0.41rem;
    background: #ffffff;
    border-radius: 0.14rem;
    border: 0.02rem solid #6588d1;
  }
}
.wx-open {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 888888;
}
.wx-open p {
  position: relative;
  top: 38%;
  margin-block-start: 0;
  color: white;
  font-size: 18px;
  line-height: 20px;
}
.wx-open img {
  width: 25%;
  border-style: none;
  position: absolute;
  transform: rotateZ(246deg);
  top: 6%;
  right: 28px;
}
</style>

