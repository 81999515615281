<template>
  <div>
    <div 
      class="back" 
      @click="onBack()">
      <img  src="@/assets/image/blackLeft.png" />
    </div>
    <div class="popup-center" v-show="show">
      <img class="bg-img" src="@/assets/image/activity/20240822-155159.png" />
      <div @click="goLogin" class="bg-btn">
        <img src="@/assets/image/activity/20240822-155733.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { goLoginPage } from '@/assets/js/NativeWebKit.js';
import { FunJudgDevice  } from "@/unit/fun.js";
const DeviceType = FunJudgDevice()
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      userId:0,
      show:true,
      timer:null,
      isLoginAndriod: false,
    }
  },
  // 事件处理器
  methods: {
    getUserInfo(){
      this.timer = setInterval(()=>{
        const userinfo = JSON.parse(localStorage.getItem("userInfo"))
        if((userinfo && userinfo.userId) || this.isLoginAndriod){
          this.show = false
          clearInterval(this.timer)
          this.$Toast("您已是老用户，快邀请好友注册，享同等福利！");
          setTimeout(()=>{
            this.$onReturn(true)
          },2500)
        }else{
          this.show = true
        }
      }, 500)
    },
    // 获取登录状态
    getLoginType(){
      this.isLoginAndriod = true
    },
    // 去登录
    goLogin(){
      goLoginPage()
    },

    onBack(){
      if(DeviceType == 'android'){
        this.$onReturn(true)
      } else {
        this.$onReturn(this.$route.query.fromMenu == '1')
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    let _this = this;
    // Android登录后通知提醒
    window.setLoginType = function () {
      _this.getLoginType()
      return ;
    };
  },
  // 生命周期-实例挂载后调用
  mounted () {
    clearInterval(this.timer)
    this.getUserInfo()
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style>
body{
  background: linear-gradient( 180deg, #2DCCEC 0%, #4197FF 100%);
}
</style>

<style lang="less" scoped>
  .back{
    position: absolute;
    left: 26px;
    top: 63px;
    z-index: 9;
    img{
      width: 24px;
    }
  }
	.popup-center{
		position: absolute;
    width: 99%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -47%);
		.bg-img{
			width: 100%;
			height: auto;
		}
		.bg-btn{
			width: 65%;
			position: absolute;
			bottom: calc(5% + 3px);
			left: 50%;
      z-index: 99;
			transform: translate(-50%);
      img{
        width: 100%;
      }
		}
	}
</style>
