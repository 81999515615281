<template>
  <div id="window">
     <van-nav-bar
      title="我的消息"
      left-arrow
      @click-left="$onReturn($route.query.fromMenu == '1')"
      @click-right="clearAll"
    />
    <div id="content">
      <van-tabs v-model="active" @change="changeTab" class="van-tabs-default">
        <van-tab title="学习通知">
          <van-pull-refresh style="min-height:50vh" v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-if="list.StudyData.length != 0" 
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <div v-for="(item,index) in list.StudyData" :key="index" @click="routerLink(item)">
                <div class="center" :key="'c'+index">
                  <div class="time" v-if="item.pushTime">{{item.pushTime.substr(0,16)}}</div>
                </div>
                <div class="item" :key="'d'+index">
                  <div class="title">
                    <div class="left">
                      <span>{{item.title}}</span>
                    </div>
                    <div class="right">
                      <van-icon v-if="item.objectType !== 0" name="arrow" color="#ccc" />
                    </div>
                  </div>
                  <div class="content">
                    {{item.content}}
                  </div>
                </div>
              </div>
            </van-list>
            <noMessage v-if="list.StudyData.length == 0" type=1></noMessage>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="系统通知">
          <van-pull-refresh style="min-height:50vh" v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-if="list.SystemData.length != 0" 
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <div v-for="(item,index) in list.SystemData" :key="index" @click="routerLink(item)">
                <div class="center" :key="'c'+index">
                  <div class="time" v-if="item.pushTime">{{item.pushTime.substr(0,16)}}</div>
                </div>
                <div class="item" :key="'d'+index">
                  <div class="title">
                    <div class="left">
                      <span>{{item.title}}</span>
                    </div>
                    <div class="right">
                      <van-icon v-if="item.objectType !== 0" name="arrow" color="#ccc" />
                    </div>
                  </div>
                  <div class="content">
                    {{item.content}}
                  </div>
                </div>
              </div>
            </van-list>
            <noMessage v-if="list.SystemData.length == 0" type=1></noMessage>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
     
    
  </div>
</template>

<script>
import noMessage from "@/components/nomessage.vue";
import { onGoStudy, jumpToShopDetail } from '@/assets/js/NativeWebKit.js'
export default {
  // 可用组件的哈希表
  components: { noMessage },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      finishedText:'',
      active: 0,
      list: {  
        StudyData:[],  //学习通知
        SystemData:[]  //消息通知
      },
      loading: false,
      finished: false,
      refreshing: false,

      isRequest: true
    }
  },
  // 事件处理器
  methods: {
    // 消息跳转
    routerLink(e){
      this.$store.commit('updatenewsActive',this.active)
      // msgSource 消息来源，1自定义消息，2默认消息，3其他
      if(e.msgSource == 1){ // 自定义消息
        if(e.objectType == 1){ //课程
          jumpToShopDetail(e.objectType,e.objectId);
          // this.$router.push('/curriculum/course?id=' + e.objectId)
        }else if(e.objectType == 2){ // 图书
          jumpToShopDetail(e.objectType,e.objectId);
          // this.$router.push('/curriculum/BookDdetails?id=' + e.objectId)
        }else if(e.objectType == 3){ // 教具
          jumpToShopDetail(e.objectType,e.objectId);
          // this.$router.push('/curriculum/BookDdetails?id=' + e.objectId)
        }else if(e.objectType == 4){ // 模拟考试
          // jumpToShopDetail(e.objectType,e.objectId);
          this.$router.push('/examination/exam?id=' + e.objectId)
        }else if(e.objectType == 5){ // 电子照片
          
        }else if(e.objectType == 6){ // 延期
          
        }else if(e.objectType == 7){ // 补考
          
        }else if(e.objectType == 8){ // 直播
          this.$router.push('/live/liveDetail?id=' + e.objectId)
        }else if(e.objectType == 9){ // 其他
          
        }
      }else if(e.msgSource == 2){
        if(e.objectType === 0){ // 不跳转

        }else if(e.objectType == 1){ // 考试确认信息页
          this.$router.push('/examEnroll/signUpConfirm1?id=' + e.objectId)
        }else if(e.objectType == 2){ // 课程考试报名页
          // this.$router.push('/examination/examCourse?id=' + e.objectId)
          this.$router.push('/examEnroll/registration?type=2&id=' + e.objectId)
        }else if(e.objectType == 3){ // 课程详情页
          jumpToShopDetail(1,e.objectId);
          // this.$router.push('/curriculum/course?id=' + e.objectId)
        }else if(e.objectType == 4){ // 课程观看页
          this.$router.push('/curriculum/courseVideo?id=' + e.objectId)
        }else if(e.objectType == 5){ // 直播详情
          this.$router.push('/live/liveDetail?id=' + e.objectId)
        }else if(e.objectType == 6){ // 学习首页
          // this.$router.push('/study')
          onGoStudy();
        }else if(e.objectType == 7){ // 学习打卡
          this.$router.push('/myStudy/clockLearn')
        }else if(e.objectType == 8){ // 完善密码页
          this.$router.push('/login/retrievePassword')
        }else if(e.objectType == 9){ // 身份认真页
          this.$router.push('/mine/identityAuthentication')
        }else if(e.objectType == 10){ // 个人证书页
          this.$router.push('/myStudy/certificate')
        }else if(e.objectType == 11){ // 设置页
          this.$router.push('/setUp')
        }else if(e.objectType == 12){ // 发票列表页
          this.$router.push('/invoice/InvoiceCenter')
        }
      }else if(e.msgSource == 3){

      }
    },
    // 清空
    clearAll(){
      if(!this.list.StudyData.length && !this.list.SystemData.length){
        return 
      }
      this.$ajax({
        url:'/hxclass-mobile/user/msg/app',
        method:'delete'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$Toast(res.message);
          this.getManageList('onRefresh');
        }else{
          this.$Toast(res.message);
        }
      })
    },
    changeTab(e){
      this.getManageList();
    },
    onLoad() {
      // setTimeout(() => {
      //   if (this.refreshing) {
      //     this.list = [];
      //     this.refreshing = false;
      //   }
      //   // for (let i = 0; i < 10; i++) {
      //   //   this.list.push(this.list.length + 1);
      //   // }
        this.loading = false;

      //   if (this.list.length >= 40) {
          this.finished = true;
      //   }
      // }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
      this.getManageList('onRefresh');
    },
    // 获取消息列表
    getManageList(type){
      // 下拉刷新判断
      if(type != 'onRefresh'){
        // 判断当前列表如果有值将不查询
        if(this.active == 0 && this.list.StudyData.length != 0){
          return
        } else if (this.active == 1 && this.list.SystemData.length != 0) {
          return
        }
      }

      if(!this.isRequest){return}
      this.isRequest = false
      this.$ajax({
        url:'/hxclass-mobile/user/msg/app/'+(!!this.active?1:2),
        method:'get'
      }).then(res=>{
        this.isRequest = true
        this.refreshing = false;
        if(res.code == 200 && res.success){

          // 将数据对应列表赋值
          if(this.active == 0){  // 赋学习通知列表
            this.list.StudyData = res.data;
          } else if (this.active == 1) {  // 赋系统通知列表
            this.list.SystemData = res.data;
          }
          if(res.data.length){
            this.changeMsgStatus();
          }
          this.$nextTick(() => {
            let contentHeight = document.getElementsByClassName('van-tabs__content')[0].clientHeight;
            let listHeight = document.getElementsByClassName('van-list')[0].scrollHeight;
            if(contentHeight - this.$store.state.stateHeight > listHeight){
              this.finishedText = '';
            }else{
              this.finishedText = '没有更多了';
            }
          })
        }else{
          this.$Toast(res.message);
        }
      })
    },
    // 所有消息已读
    changeMsgStatus(){
      this.$ajax({
        url:'/hxclass-mobile/user/msg/app',
        method:'put'
      }).then(res=>{
        if(res.code == 200 && res.success){
          
        }else{
          this.$Toast(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$store.state.newsActive !== ''){
      this.active = this.$store.state.newsActive;
    }
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
/deep/.nomessage{
  margin-top: 66px;
}
#window{
  height: 100vh;
  display: flex;
  flex-direction: column;
  #content{
    flex: 1;
    background-color: #f5f5f5;
    overflow: auto;
    display: flex;
    flex-direction: column;
    /deep/.van-tabs{
      flex: 1;
    }
    /deep/.van-tabs__wrap{
      .van-tab{
        flex: none;
        padding: 0 20px;
      }
    }
    /deep/.van-tabs__content{
      // height: calc(100vh - 90px);
      overflow: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 44px;
    }
  }
}
.center{
  text-align: center;
  padding-bottom: 8px;
}
.time{
  margin: 0 auto;
  background-color: #eee;
  color: #999;
  display: inline-block;
  border-radius: 10px;
  line-height: 15px;
  height: 15px;
  font-size: 10px;
  padding: 0 10px;
}
.item{
  background-color: #FFFFFF;
  margin: 0 18px;
  margin-bottom: 12px;
  border-radius: 10px;
  padding: 14px;
  font-family: PingFangMedium;
  .title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .left{
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
  }
  .content{
    font-size: 14px;
    line-height: 21px;
    color: #333;
    margin-top: 14px;
  }
}
</style>
