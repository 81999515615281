// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/examination/bcg-examination1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/image/examination/bcg-examination2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/image/examination/bcg-examination3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".container-box .main-first[data-v-d45f5fae]{display:flex;flex-direction:column;margin:9.5px 18px 0;height:179px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100%}.container-box .main-first .main-header[data-v-d45f5fae]{margin:27.5px 12.5px 0 12.5px;width:314px;height:54.5px;font-size:18px;font-family:PingFangMedium;font-weight:700;color:#333;line-height:27px}.container-box .main-first .main-content[data-v-d45f5fae]{margin:16.5px 0 0 12.5px;width:314px;height:38px;font-size:12.5px;font-family:PingFangMedium;font-weight:500;color:#999;line-height:19px}.container-box .main-first .main-bottom[data-v-d45f5fae]{position:relative;margin:11.5px 0 0 12.5px;width:81px;height:21.5px;font-size:14.5px;font-family:PingFangMedium;font-weight:500;color:#333;line-height:21.5px}.container-box .main-first .main-bottom .main-btn[data-v-d45f5fae]{position:absolute;bottom:0;left:-1px;width:100%;z-index:1}.container-box .main-first .main-bottom .main-btnspan[data-v-d45f5fae]{position:absolute;z-index:2}.container-box .main-second[data-v-d45f5fae]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100%}.container-box .main-third[data-v-d45f5fae]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:100%}", ""]);
// Exports
module.exports = exports;
